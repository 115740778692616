/* eslint-disable no-undef */
import React from 'react'

import {
  TAPICustomer,
  TAPILubricant,
  TAPIMachine,
  TAPIPicklistItem,
  TAPIPlant,
  TAPIProfileDivision,
  TAPIRoute,
  TAPISample,
  TAPITestGroup,
} from 'types/api'

import { VirtualSelectFilter } from '../BaseFilters/VirtualSelectFilter'

export enum TCondition {
  Unknown,
  Normal,
  Marginal,
  Critical,
}

export type TestOilType =
  | number
  | string
  | TAPIPicklistItem
  | TAPICustomer
  | TAPILubricant
  | TAPIProfileDivision
  | TCondition
  | TAPIMachine
  | TAPIPlant
  | TAPIRoute
  | TAPITestGroup
  | TAPISample
  | Date

export interface TFilterState {
  reportTypes: TAPIPicklistItem[]
  customerEquipmentIDs: string[]
  customers: TAPICustomer[]
  divisions: TAPIProfileDivision[]
  lubricantConditions: TCondition[]
  lubricantManufacturers: TAPIPicklistItem[]
  lubricantTypes: TAPILubricant[]
  machineConditions: TCondition[]
  machineManufacturers: TAPIPicklistItem[]
  machineModels: string[]
  machineNames: string[]
  machineNumbers: number[]
  machinePicklistTypes: TAPIPicklistItem[]
  machines: TAPIMachine[]
  plants: TAPIPlant[]
  routes: TAPIRoute[]
  testPackages: TAPITestGroup[]
  samples: TAPISample[]
}

export enum Filters {
  CustomerEquipmentID,
  Customers,
  Divisions,
  ReportType,
  LubricantCondition,
  LubricantType,
  LubricantManufacturer,
  MachineCondition,
  MachineType,
  MachineManufacturer,
  MachineModel,
  MachineName,
  MachineNumber,
  Machine,
  Plant,
  Route,
  TestPackage,
  Sample,
}

export type TSmartFilterProps = {
  filterOptions: Partial<TFilterState>
  autoSelectSingleValues?: boolean
  initialState?: Partial<TFilterState>
  disableOptionFiltering?: boolean
  /** Specifying your desired filters increases speed by reducing unnecessary state checks and unused filter renders */
  filters?: Filters[]
}

export enum TFilterType {
  Smart,
  Select,
  VirtualSelect,
  Number,
  Text,
}

export type TAdditionalSelectProps = {
  TooltipText?: string
  heirarchicalFiltering?: boolean
}

export type TSelectProps = Partial<
  React.ComponentPropsWithoutRef<typeof VirtualSelectFilter>
> &
  TAdditionalSelectProps

export interface TFilters {
  SmartDivisionFilter: (_props: TSelectProps) => JSX.Element
  SmartCustomerFilter: (_props: TSelectProps) => JSX.Element
  SmartCustomerEquipmentIDFilter: (_props: TSelectProps) => JSX.Element
  SmartMachineFilter: (_props: TSelectProps) => JSX.Element
  SmartSampleFilter: (_props: TSelectProps) => JSX.Element
  // SmartStartDateFilter: (_props: TSelectProps) => JSX.Element
  // SmartEndDateFilter: (_props: TSelectProps) => JSX.Element
  SmartLubricantFilter: (_props: TSelectProps) => JSX.Element
  SmartPlantFilter: (_props: TSelectProps) => JSX.Element
  SmartMachineConditionFilter: (_props: TSelectProps) => JSX.Element
  SmartLubricantConditionFilter: (_props: TSelectProps) => JSX.Element
  SmartReportTypeFilter: (_props: TSelectProps) => JSX.Element
  SmartRouteFilter: (_props: TSelectProps) => JSX.Element
  SmartMachineTypeFilter: (_props: TSelectProps) => JSX.Element
  SmartMachineNameFilter: (_props: TSelectProps) => JSX.Element
  SmartLubricantManufacturerFilter: (_props: TSelectProps) => JSX.Element
  SmartMachineManufacturerFilter: (_props: TSelectProps) => JSX.Element
  SmartMachineModelFilter: (_props: TSelectProps) => JSX.Element
  SmartMachineNumberFilter: (_props: TSelectProps) => JSX.Element
  SmartTestPackageFilter: (_props: TSelectProps) => JSX.Element
}

export type TSmartFilter = {
  filterState: TFilterState
  filterOptions: TFilterState
  reset: () => void
  filters: TFilters
}
