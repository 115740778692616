import React from 'react'

import { TAPIProfileDivision } from 'types/api'

import { VirtualSelectFilter } from '../BaseFilters/VirtualSelectFilter'

type TProps = {
  single?: boolean
  required?: boolean
  divisions: TAPIProfileDivision[]
  value?: TAPIProfileDivision[]
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange?: (props: number | number[]) => void
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setState?: (props: any) => void
  variant?: 'filled' | 'outlined' | 'standard'
}
export const DivisionFilter = ({
  single,
  required,
  divisions,
  setState,
  onChange,
  value,
  variant,
}: TProps) => {
  if (setState == null && onChange == null) return

  const handleChange = (
    divisions: TAPIProfileDivision | TAPIProfileDivision[]
  ) => {
    if (onChange != null) {
      if (single === true) {
        onChange((divisions as TAPIProfileDivision)?.iD)
      } else {
        onChange((divisions as TAPIProfileDivision[]).map(d => d.iD))
      }
    } else {
      if (single === true) {
        setState({ selectedDivisionID: (divisions as TAPIProfileDivision)?.iD })
      } else {
        setState({
          selectedDivisionIDs: (divisions as TAPIProfileDivision[])?.map(
            p => p.iD
          ),
        })
      }
    }
  }

  return (
    <VirtualSelectFilter
      label="Division"
      htmlFor="divisionIDs"
      required={required}
      multiple={!single}
      id="divisionIDs"
      value={value}
      variant={variant}
      getOptionSelected={(option, value) => option.iD === value.iD}
      renderValue={(selected: TAPIProfileDivision) =>
        divisions.find(d => d.iD === selected.iD)?.name
      }
      onChange={handleChange}
      options={divisions}
    ></VirtualSelectFilter>
  )
}
