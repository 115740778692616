import { Popper, Fade } from '@material-ui/core'
import React, { useState } from 'react'
import { useThemeContext } from 'Contexts/ThemeContext'
import InfoIcon from '@mui/icons-material/Info'

import * as Styles from './index.module.css'
import { PopperPlacementType } from '@mui/material'

export type TInformationIconProps = {
  icon?: typeof InfoIcon
  element?: JSX.Element
  placement?: PopperPlacementType
} & React.ComponentProps<typeof InfoIcon>

export const InformationIcon: React.FC<TInformationIconProps> = props => {
  const { children, ...iconProps } = props
  const ref = React.useRef<HTMLDivElement>(null)
  const [showTooltip, setShowTooltip] = useState(false)

  const classes = useThemeContext()

  const ChartInformationPopper = () => {
    return (
      <>
        <Popper
          open={showTooltip}
          anchorEl={ref.current}
          placement={
            (props.placement as PopperPlacementType & 'Auto') ||
            ('top' as PopperPlacementType & 'Auto')
          }
          transition
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <div className={Styles.Popper}>{children}</div>
            </Fade>
          )}
        </Popper>
      </>
    )
  }

  return (
    <>
      <ChartInformationPopper />
      <div
        className={`${classes.cardHeaderLink} ${Styles.PickerHeaderIcon}`}
        ref={ref}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {props.icon ? (
          <props.icon {...iconProps} />
        ) : props.element ? (
          props.element
        ) : (
          <InfoIcon {...iconProps} />
        )}
      </div>
    </>
  )
}
