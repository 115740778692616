import React from 'react'
import MaterialScreen from 'models/material-ui/MaterialScreen'

import MachineCard, {
  TProps as TMachineCardProps,
  TSampleSnapshot,
} from 'components/MachineCard'

import { Slider } from './Slider'
import useWidth from '../../hooks/useWidth'

import 'keen-slider/keen-slider.min.css'
import './MachineCardSlider.css'

type TProps = {
  classes: { [style: string]: string }
  sampleSnapShots: TSampleSnapshot[]
} & Omit<TMachineCardProps, 'sampleSnapShot' | 'cardColumns'>

const MachineCardSlider: React.FC<TProps> = React.memo(
  props => {
    const { sampleSnapShots, ...passedProps } = props
    const [width, ref] = useWidth()

    if (!sampleSnapShots?.length) return null

    const cardColumns = MaterialScreen.GetMachineCardCount(
      MaterialScreen.GetMaterialBreakpoint(width)
    )

    const cardsPerView = 12 / cardColumns

    // map the cards to cards constant
    const cards = sampleSnapShots.map((data, i) => (
      <MachineCard
        sampleSnapShot={data}
        key={i}
        cardColumns={cardColumns}
        {...passedProps}
      />
    ))
    // create an array for every card set
    const cardList: React.ReactNode[][] = []

    // add to list of lists. Every interval, change to the next tier in the list array.
    for (let index = 0; index < cards.length; index++) {
      const cardListLength = Math.floor(index / cardsPerView)
      if (cardList.length <= cardListLength) {
        cardList.push([])
      }
      cardList[cardListLength].push(cards[index])
    }
    // Map lists into jsx elements
    return <Slider outerRef={ref} cardList={cardList} />
  },
  (prev, next) => {
    return (
      prev.sampleSnapShots?.length === next.sampleSnapShots?.length ||
      !next.sampleSnapShots?.length
    )
  }
)

export default MachineCardSlider
