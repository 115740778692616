import React from 'react'
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import PropTypes from 'prop-types'

import Layout from 'components/FullScreenLayout'
import Loader from 'components/Loader'
import SEO from 'components/SEO'
import APIService from 'services/api-service'
import { ProfileContext } from 'Contexts/ProfileContext'
import { CancellablePromise } from 'utils/CancellablePromise'

const initialStartDate = moment().subtract(1, 'year').toDate()
const initialEndDate = new Date()

const defaults = {
  displayName: '',
  dateRangeTitle: '',
  selectedCustomerIDs: [],
  fiscalYearStartMonth: 1,
  exceptionCount: 2,
  endDate: initialEndDate,
  startDate: initialStartDate,
}

const styles = theme => ({
  table: {
    '& thead': {
      '& tr': {
        '& th': {
          backgroundColor: '#a8afba',
          color: '#fff',
          padding: '5px',
        },
      },
    },
    '& tbody': {
      '& tr': {
        '&:nth-child(odd)': {
          '& td': {
            backgroundColor: '#e8eaeb',
          },
        },
        '&:nth-child(even)': {
          '& td': {
            backgroundColor: '#f2f4f5',
          },
        },
        '& td': {
          padding: '5px',
        },
      },
    },
  },
  totalsRow: {
    '& td': {
      backgroundColor: '#d2dff4 !important',
      fontWeight: 'bold',
    },
  },
  reportPage: {
    border: '1px solid #eee',
    width: '11in',
    height: '8in',
    padding: '20px',
    pageBreakAfter: 'always',
    position: 'relative',
  },
  displayName: {
    marginTop: '250px',
    fontSize: '60px',
    fontWeight: 600,
  },
  programSnapshotTitle: {
    marginTop: '50px',
    fontSize: '40px',
    fontWeight: 300,
  },
  dateRangeTitle: {
    marginTop: '25px',
    fontSize: '22px',
    fontWeight: 300,
    fontStyle: 'italic',
  },
  logo: {
    marginTop: '190px',
    float: 'right',
    height: '50px',
  },
  sectionTitle: {
    fontSize: '30px',
    fontWeight: 'bold',
  },
  sectionSubTitle: {
    fontSize: '22px',
  },
})

const Footer = () => {
  return (
    <Box style={{ position: 'absolute', top: '7.5in', width: '100%' }}>
      <Box style={{ textAlign: 'center', fontWeight: 600, fontSize: '14px' }}>
        TestOil | 20338 Progress Drive | Strongsville, OH 44149 | Testoil.com |
        Phone: 216-251-2510 | Fax: 216-251-2515
      </Box>
      <Box style={{ textAlign: 'center', fontSize: '12px', marginTop: '5px' }}>
        Copyright Insight Services, Inc. {new Date().getFullYear()} All Rights
        Reserved.
      </Box>
    </Box>
  )
}

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

class ProgramSnapshotResultReport extends React.Component {
  state = {
    ...defaults,
    loaded: false,
    dataLoaded: false,
    data: [],
  }

  static contextType = ProfileContext

  async componentWillUnmount() {
    while (ongoingCancellablePromises.length > 0) {
      const promise = ongoingCancellablePromises.pop()
      promise.abortController?.abort()
    }
  }

  async componentDidMount() {
    if (!this.state.loaded) {
      let displayName = ''
      let dateRangeTitle = ''
      let selectedCustomerIDs = []
      let fiscalYearStartMonth = 0
      let exceptionCount = 0
      let startDate = new Date()
      let endDate = new Date()

      if (window.location.search !== null) {
        const params = new URLSearchParams(window.location.search)

        if (params.has('displayName')) displayName = params.get('displayName')
        if (params.has('dateRangeTitle'))
          dateRangeTitle = params.get('dateRangeTitle')
        if (params.has('custIDs'))
          selectedCustomerIDs = params.get('custIDs').split(',')
        if (params.has('fiscalYearStartMonth'))
          fiscalYearStartMonth = params.get('fiscalYearStartMonth') * 1
        if (params.has('exceptionCount'))
          exceptionCount = params.get('exceptionCount') * 1
        if (params.has('startDate'))
          startDate = moment(params.get('startDate')).toDate()
        if (params.has('endDate'))
          endDate = moment(params.get('endDate')).toDate()
      }

      this.setState(
        {
          loaded: true,
          displayName: displayName,
          dateRangeTitle: dateRangeTitle,
          selectedCustomerIDs: selectedCustomerIDs,
          fiscalYearStartMonth: fiscalYearStartMonth,
          exceptionCount: exceptionCount,
          startDate: startDate,
          endDate: endDate,
          ...this.context.profile,
        },
        () => {
          this.loadReport()
        }
      )
    }
  }

  async loadReport() {
    this.setState({ dataLoaded: false }, async () => {
      try {
        const promise = APIService.getProgramSnapshot(
          this.state.customerIDs,
          this.state.fiscalYearStartMonth,
          this.state.exceptionCount,
          this.state.startDate,
          this.state.endDate
        )

        ongoingCancellablePromises.push(promise)
        const data = await promise

        ongoingCancellablePromises.filter(p => p != promise)

        this.setState({
          data: data.analytics,
          dataLoaded: true,
        })
      } catch (ex) {
        this.props.onError(ex)
      }
    })
  }

  render() {
    const year = this.state.endDate.getFullYear()
    const years = []
    for (let i = year - 3; i <= year; i++) {
      years.push(i)
    }

    return (
      <React.Fragment>
        <SEO title="Program Snapshot" />
        {!this.state.dataLoaded ? (
          <Loader />
        ) : (
          <>
            <Box className={this.props.classes.reportPage}>
              <Box className={this.props.classes.displayName}>
                {this.state.displayName}
              </Box>
              <Box className={this.props.classes.programSnapshotTitle}>
                Program Snapshot
              </Box>
              <Box className={this.props.classes.dateRangeTitle}>
                {this.state.dateRangeTitle}
              </Box>
              <img
                src="/static/report-images/logo.jpg"
                alt="TestOil"
                className={this.props.classes.logo}
              />
            </Box>
            <Box className={this.props.classes.reportPage}>
              <Box className={this.props.classes.sectionTitle}>
                Sample Volume for Top 10 - {this.state.dateRangeTitle}
              </Box>

              <Grid
                container
                direction="row"
                spacing={4}
                style={{ marginTop: '35px' }}
              >
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <div style={{ height: '350px' }}>
                    <ResponsivePie
                      data={this.state.data.sampleVolume.map(x => {
                        return {
                          id: x.plantID.toString(),
                          label: x.plantName,
                          value: x.count,
                        }
                      })}
                      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                      innerRadius={0.5}
                      padAngle={0.7}
                      cornerRadius={3}
                      colors={{ scheme: 'category10' }}
                      borderWidth={1}
                      borderColor={{
                        from: 'color',
                        modifiers: [['darker', 0.2]],
                      }}
                      radialLabel={function (e) {
                        return e.label + ' (' + e.value + ')'
                      }}
                      radialLabelsSkipAngle={0}
                      radialLabelsTextXOffset={6}
                      radialLabelsTextColor="#333333"
                      radialLabelsLinkOffset={0}
                      radialLabelsLinkDiagonalLength={16}
                      radialLabelsLinkHorizontalLength={24}
                      radialLabelsLinkStrokeWidth={1}
                      radialLabelsLinkColor={{ from: 'color' }}
                      slicesLabelsSkipAngle={10}
                      slicesLabelsTextColor="#333333"
                      animate={true}
                      motionStiffness={90}
                      motionDamping={15}
                      defs={[
                        {
                          id: 'dots',
                          type: 'patternDots',
                          background: 'inherit',
                          color: 'rgba(255, 255, 255, 0.3)',
                          size: 4,
                          padding: 1,
                          stagger: true,
                        },
                        {
                          id: 'lines',
                          type: 'patternLines',
                          background: 'inherit',
                          color: 'rgba(255, 255, 255, 0.3)',
                          rotation: -45,
                          lineWidth: 6,
                          spacing: 10,
                        },
                      ]}
                      legends={[
                        {
                          anchor: 'bottom',
                          direction: 'row',
                          translateY: 56,
                          itemWidth: 100,
                          itemHeight: 24,
                          itemTextColor: '#999',
                          symbolSize: 18,
                          symbolShape: 'circle',
                          effects: [
                            {
                              on: 'hover',
                              style: {
                                itemTextColor: '#000',
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <div style={{ height: '350px' }}>
                    <ResponsiveBar
                      data={this.state.data.sampleVolume.map(x => {
                        return {
                          plantID: x.plantID,
                          plantName: x.plantName,
                          count: x.count,
                        }
                      })}
                      keys={['count']}
                      indexBy="plantName"
                      margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
                      padding={0.3}
                      groupMode="grouped"
                      colors={{ scheme: 'category10' }}
                      defs={[
                        {
                          id: 'dots',
                          type: 'patternDots',
                          background: 'inherit',
                          color: '#38bcb2',
                          size: 4,
                          padding: 1,
                          stagger: true,
                        },
                        {
                          id: 'lines',
                          type: 'patternLines',
                          background: 'inherit',
                          color: '#eed312',
                          rotation: -45,
                          lineWidth: 6,
                          spacing: 10,
                        },
                      ]}
                      borderColor={{
                        from: 'color',
                        modifiers: [['darker', 1.6]],
                      }}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Plant',
                        legendPosition: 'middle',
                        legendOffset: 32,
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Samples',
                        legendPosition: 'middle',
                        legendOffset: -40,
                      }}
                      labelSkipWidth={12}
                      labelSkipHeight={12}
                      labelTextColor={{
                        from: 'color',
                        modifiers: [['darker', 1.6]],
                      }}
                      legends={[]}
                      animate={true}
                      motionStiffness={90}
                      motionDamping={15}
                    />
                  </div>
                </Grid>
              </Grid>

              <Table
                className={this.props.classes.table}
                style={{ marginTop: '35px' }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Plants</TableCell>
                    {years.map((x, i) => (
                      <TableCell style={{ textAlign: 'center' }} key={i}>
                        {x === year ? 'FYTD' : 'FY'}
                        {x.toString().substring(2, 4)}
                      </TableCell>
                    ))}
                    <TableCell style={{ textAlign: 'center' }}>
                      {moment(this.state.startDate).format('l')} -{' '}
                      {moment(this.state.endDate).format('l')}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.data.sampleVolume.map((x, i) => (
                    <TableRow key={i}>
                      <TableCell>{x.plantName}</TableCell>
                      {years.map((year, yearIndex) => (
                        <TableCell
                          style={{ textAlign: 'center' }}
                          key={yearIndex}
                        >
                          {x.years
                            .filter(y => y.year === year)
                            .map(y => y.count)
                            .join('')}
                        </TableCell>
                      ))}
                      <TableCell style={{ textAlign: 'center' }}>
                        {x.dateRangeCount}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {x.count}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell style={{ textAlign: 'right', fontWeight: 600 }}>
                      Total
                    </TableCell>
                    {years.map((year, yearIndex) => (
                      <TableCell
                        style={{ textAlign: 'center', fontWeight: 600 }}
                        key={yearIndex}
                      >
                        {this.state.data.sampleVolume
                          .map(x => x.years)
                          .reduce((prev, cur) => prev.concat(cur), [])
                          .filter(x => x.year === year)
                          .map(x => x.count)
                          .reduce((prev, cur) => prev + cur, 0)}
                      </TableCell>
                    ))}
                    <TableCell style={{ textAlign: 'center', fontWeight: 600 }}>
                      {this.state.data.sampleVolume
                        .map(x => x.dateRangeCount)
                        .reduce((prev, cur) => prev + cur, 0)}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', fontWeight: 600 }}>
                      {this.state.data.sampleVolume
                        .map(x => x.count)
                        .reduce((prev, cur) => prev + cur, 0)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <Footer />
            </Box>
            <Box className={this.props.classes.reportPage}>
              <Box className={this.props.classes.sectionTitle}>
                Fiscal Metrics of Top 10
              </Box>

              <Box
                className={this.props.classes.sectionSubTitle}
                style={{ marginTop: '10px' }}
              >
                Total Sample Volume
              </Box>
              <div style={{ height: '175px' }}>
                <ResponsiveBar
                  data={this.state.data.sampleVolume.map(x => {
                    const result = {
                      plantID: x.plantID,
                      plantName: x.plantName,
                    }

                    for (const year of x.years) {
                      result[year.year] = year.count
                    }

                    return result
                  })}
                  keys={years}
                  indexBy="plantName"
                  margin={{ top: 10, right: 130, bottom: 50, left: 60 }}
                  padding={0.3}
                  groupMode="grouped"
                  colors={{ scheme: 'category10' }}
                  defs={[
                    {
                      id: 'dots',
                      type: 'patternDots',
                      background: 'inherit',
                      color: '#38bcb2',
                      size: 4,
                      padding: 1,
                      stagger: true,
                    },
                    {
                      id: 'lines',
                      type: 'patternLines',
                      background: 'inherit',
                      color: '#eed312',
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10,
                    },
                  ]}
                  borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Plant',
                    legendPosition: 'middle',
                    legendOffset: 32,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Samples',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      dataFrom: 'keys',
                      anchor: 'bottom-right',
                      direction: 'column',
                      justify: false,
                      translateX: 120,
                      translateY: 0,
                      itemsSpacing: 2,
                      itemWidth: 100,
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 20,
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                  animate={true}
                  motionStiffness={90}
                  motionDamping={15}
                />
              </div>

              <Box
                className={this.props.classes.sectionSubTitle}
                style={{ marginTop: '10px' }}
              >
                % Sample Exceptions
              </Box>
              <div style={{ height: '175px' }}>
                <ResponsiveBar
                  data={this.state.data.sampleExceptionVolume.map(x => {
                    const result = {
                      plantID: x.plantID,
                      plantName: x.plantName,
                    }

                    for (const year of x.years) {
                      result[year.year] = Math.round(year.exceptionPercent)
                    }

                    return result
                  })}
                  keys={years}
                  indexBy="plantName"
                  margin={{ top: 10, right: 130, bottom: 50, left: 60 }}
                  padding={0.3}
                  groupMode="grouped"
                  colors={{ scheme: 'category10' }}
                  defs={[
                    {
                      id: 'dots',
                      type: 'patternDots',
                      background: 'inherit',
                      color: '#38bcb2',
                      size: 4,
                      padding: 1,
                      stagger: true,
                    },
                    {
                      id: 'lines',
                      type: 'patternLines',
                      background: 'inherit',
                      color: '#eed312',
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10,
                    },
                  ]}
                  borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Plant',
                    legendPosition: 'middle',
                    legendOffset: 32,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '% Samples',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      dataFrom: 'keys',
                      anchor: 'bottom-right',
                      direction: 'column',
                      justify: false,
                      translateX: 120,
                      translateY: 0,
                      itemsSpacing: 2,
                      itemWidth: 100,
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 20,
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                  animate={true}
                  motionStiffness={90}
                  motionDamping={15}
                />
              </div>

              <Box
                className={this.props.classes.sectionSubTitle}
                style={{ marginTop: '10px' }}
              >
                % Recurring Exceptions
              </Box>
              <div style={{ height: '175px' }}>
                <ResponsiveBar
                  data={this.state.data.sampleRecurringExceptionVolume.map(
                    x => {
                      const result = {
                        plantID: x.plantID,
                        plantName: x.plantName,
                      }

                      for (const year of x.years) {
                        result[year.year] = Math.round(year.exceptionPercent)
                      }

                      return result
                    }
                  )}
                  keys={years}
                  indexBy="plantName"
                  margin={{ top: 10, right: 130, bottom: 50, left: 60 }}
                  padding={0.3}
                  groupMode="grouped"
                  colors={{ scheme: 'category10' }}
                  defs={[
                    {
                      id: 'dots',
                      type: 'patternDots',
                      background: 'inherit',
                      color: '#38bcb2',
                      size: 4,
                      padding: 1,
                      stagger: true,
                    },
                    {
                      id: 'lines',
                      type: 'patternLines',
                      background: 'inherit',
                      color: '#eed312',
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10,
                    },
                  ]}
                  borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Plant',
                    legendPosition: 'middle',
                    legendOffset: 32,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '% Samples',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      dataFrom: 'keys',
                      anchor: 'bottom-right',
                      direction: 'column',
                      justify: false,
                      translateX: 120,
                      translateY: 0,
                      itemsSpacing: 2,
                      itemWidth: 100,
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 20,
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                  animate={true}
                  motionStiffness={90}
                  motionDamping={15}
                />
              </div>

              <Footer />
            </Box>
            {this.state.data.customerSampleVolumeSummary.map((x, i) => (
              <Box className={this.props.classes.reportPage} key={i}>
                <Box className={this.props.classes.sectionTitle}>
                  {x.custName} Summary - {this.state.dateRangeTitle}
                </Box>

                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box
                      className={this.props.classes.sectionSubTitle}
                      style={{ marginTop: '10px' }}
                    >
                      Alarm Rate
                    </Box>
                    <div style={{ height: '300px' }}>
                      <ResponsiveBar
                        data={x.plants.map(x => {
                          const result = {
                            plantID: x.plantID,
                            plantName: x.plantName,
                            alarmRate:
                              x.samplesReceived === 0
                                ? 0
                                : Math.round(
                                    ((x.alarmedSamples * 1.0) /
                                      (x.samplesReceived * 1.0)) *
                                      100.0
                                  ),
                          }

                          return result
                        })}
                        keys={['alarmRate']}
                        indexBy="plantName"
                        margin={{ top: 10, right: 130, bottom: 50, left: 60 }}
                        padding={0.3}
                        groupMode="grouped"
                        colors={{ scheme: 'category10' }}
                        defs={[
                          {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: '#38bcb2',
                            size: 4,
                            padding: 1,
                            stagger: true,
                          },
                          {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: '#eed312',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10,
                          },
                        ]}
                        borderColor={{
                          from: 'color',
                          modifiers: [['darker', 1.6]],
                        }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: 'Plant',
                          legendPosition: 'middle',
                          legendOffset: 32,
                        }}
                        axisLeft={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: '%',
                          legendPosition: 'middle',
                          legendOffset: -40,
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{
                          from: 'color',
                          modifiers: [['darker', 1.6]],
                        }}
                        legends={[]}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div style={{ height: '300px' }}>
                      <ResponsivePie
                        data={x.plants
                          .map(x => {
                            return {
                              ferrousWear: x.ferrousWear,
                              wearMetals: x.wearMetals,
                              particleCount: x.particleCount,
                              water: x.water,
                              viscosity: x.viscosity,
                              degradation: x.degradation,
                              other: x.other,
                            }
                          })
                          .reduce(
                            (prev, cur) => {
                              for (const key of Object.keys(cur)) {
                                prev.find(x => x.id === key).value += cur[key]
                              }

                              return prev
                            },
                            [
                              {
                                id: 'ferrousWear',
                                label: 'Ferrous Wear',
                                value: 0,
                              },
                              {
                                id: 'wearMetals',
                                label: 'Wear Metals',
                                value: 0,
                              },
                              {
                                id: 'particleCount',
                                label: 'Particle Count',
                                value: 0,
                              },
                              {
                                id: 'water',
                                label: 'Water',
                                value: 0,
                              },
                              {
                                id: 'viscosity',
                                label: 'Viscosity',
                                value: 0,
                              },
                              {
                                id: 'degradation',
                                label: 'Degradation',
                                value: 0,
                              },
                              {
                                id: 'other',
                                label: 'Other',
                                value: 0,
                              },
                            ]
                          )}
                        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        colors={{ scheme: 'category10' }}
                        borderWidth={1}
                        borderColor={{
                          from: 'color',
                          modifiers: [['darker', 0.2]],
                        }}
                        radialLabel={function (e) {
                          return e.label + ' (' + e.value + ')'
                        }}
                        radialLabelsSkipAngle={0}
                        radialLabelsTextXOffset={6}
                        radialLabelsTextColor="#333333"
                        radialLabelsLinkOffset={0}
                        radialLabelsLinkDiagonalLength={16}
                        radialLabelsLinkHorizontalLength={24}
                        radialLabelsLinkStrokeWidth={1}
                        radialLabelsLinkColor={{ from: 'color' }}
                        slicesLabelsSkipAngle={10}
                        slicesLabelsTextColor="#333333"
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        defs={[
                          {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: 'rgba(255, 255, 255, 0.3)',
                            size: 4,
                            padding: 1,
                            stagger: true,
                          },
                          {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: 'rgba(255, 255, 255, 0.3)',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10,
                          },
                        ]}
                        legends={[
                          {
                            anchor: 'bottom',
                            direction: 'row',
                            translateY: 56,
                            itemWidth: 100,
                            itemHeight: 24,
                            itemTextColor: '#999',
                            symbolSize: 18,
                            symbolShape: 'circle',
                            effects: [
                              {
                                on: 'hover',
                                style: {
                                  itemTextColor: '#000',
                                },
                              },
                            ],
                          },
                        ]}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Table className={this.props.classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell style={{ textAlign: 'center' }} colSpan={7}>
                        Exceptions
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        Sample Compliance
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Plants</TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        Samples Due
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        Samples Received
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        Alarmed Samples
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        Ferrous Wear
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        Wear Metals
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        Particle Count
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        Water
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        Viscosity
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        Degradation
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        Other
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        Percent Compliance
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {x.plants.map((p, j) => (
                      <TableRow key={j}>
                        <TableCell>{p.plantName}</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          {p.samplesDue}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          {p.samplesReceived}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          {p.alarmedSamples}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          {p.ferrousWear}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          {p.wearMetals}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          {p.particleCount}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          {p.water}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          {p.viscosity}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          {p.degradation}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          {p.other}
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          {p.samplesDue === 0
                            ? 'n/a'
                            : Math.round(p.percentCompliance) + '%'}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>
                        {x.custName} Total
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 'bold', textAlign: 'center' }}
                      >
                        {x.plants
                          .map(x => x.samplesDue)
                          .reduce((prev, cur) => prev + cur, 0)}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 'bold', textAlign: 'center' }}
                      >
                        {x.plants
                          .map(x => x.samplesReceived)
                          .reduce((prev, cur) => prev + cur, 0)}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 'bold', textAlign: 'center' }}
                      >
                        {x.plants
                          .map(x => x.alarmedSamples)
                          .reduce((prev, cur) => prev + cur, 0)}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 'bold', textAlign: 'center' }}
                      >
                        {x.plants
                          .map(x => x.ferrousWear)
                          .reduce((prev, cur) => prev + cur, 0)}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 'bold', textAlign: 'center' }}
                      >
                        {x.plants
                          .map(x => x.wearMetals)
                          .reduce((prev, cur) => prev + cur, 0)}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 'bold', textAlign: 'center' }}
                      >
                        {x.plants
                          .map(x => x.particleCount)
                          .reduce((prev, cur) => prev + cur, 0)}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 'bold', textAlign: 'center' }}
                      >
                        {x.plants
                          .map(x => x.water)
                          .reduce((prev, cur) => prev + cur, 0)}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 'bold', textAlign: 'center' }}
                      >
                        {x.plants
                          .map(x => x.viscosity)
                          .reduce((prev, cur) => prev + cur, 0)}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 'bold', textAlign: 'center' }}
                      >
                        {x.plants
                          .map(x => x.degradation)
                          .reduce((prev, cur) => prev + cur, 0)}
                      </TableCell>
                      <TableCell
                        style={{ fontWeight: 'bold', textAlign: 'center' }}
                      >
                        {x.plants
                          .map(x => x.other)
                          .reduce((prev, cur) => prev + cur, 0)}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Footer />
              </Box>
            ))}
          </>
        )}
      </React.Fragment>
    )
  }
}

ProgramSnapshotResultReport.propTypes = {
  classes: PropTypes.object.isRequired,
  onError: PropTypes.func,
}

function Page({ classes }) {
  return (
    <Layout>
      <ProgramSnapshotResultReport classes={classes} />
    </Layout>
  )
}

Page.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Page)
