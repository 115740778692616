import React, { ReactNode } from 'react'
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core'

interface Props {
  item: any
  showDivisions: boolean
  renderPlantName: (_data: any) => ReactNode
}
const MobileCard: React.FC<Props> = ({
  item,
  showDivisions,
  renderPlantName,
}: Props) => {
  return (
    <Card variant="outlined" style={{ marginBottom: '10px' }}>
      <CardHeader title={item.plant.plantName} />
      <CardContent>
        <Grid container direction="row" spacing={1}>
          {showDivisions && (
            <Grid item xs={6} md={3}>
              Division Name:
            </Grid>
          )}
          {showDivisions && (
            <Grid item xs={6} md={3}>
              {item.plant.customer.division.name}
            </Grid>
          )}
          <Grid item xs={6} md={3}>
            Customer:
          </Grid>
          <Grid item xs={6} md={3}>
            {item.plant.customer.custName}
          </Grid>
          <Grid item xs={6} md={3}>
            Plant:
          </Grid>
          <Grid item xs={6} md={3}>
            {renderPlantName(item)}
          </Grid>
          <Grid item xs={6} md={3}>
            Total Samples
          </Grid>
          <Grid item xs={6} md={3}>
            {item.totalSamples === 0 ? '' : item.totalSamples}
          </Grid>
          <Grid item xs={6} md={3}>
            Total Alarmed Samples:
          </Grid>
          <Grid item xs={6} md={3}>
            {item.totalAlarmedSamples === 0 ? '' : item.totalAlarmedSamples}
          </Grid>
          <Grid item xs={6} md={3}>
            Ferrous Wear:
          </Grid>
          <Grid item xs={6} md={3}>
            {item.ferrousWear === 0 ? '' : item.ferrousWear}
          </Grid>
          <Grid item xs={6} md={3}>
            Wear Metals:
          </Grid>
          <Grid item xs={6} md={3}>
            {item.wearMetals === 0 ? '' : item.wearMetals}
          </Grid>
          <Grid item xs={6} md={3}>
            Particle Count:
          </Grid>
          <Grid item xs={6} md={3}>
            {item.particleCount === 0 ? '' : item.particleCount}
          </Grid>
          <Grid item xs={6} md={3}>
            Water:
          </Grid>
          <Grid item xs={6} md={3}>
            {item.water === 0 ? '' : item.water}
          </Grid>
          <Grid item xs={6} md={3}>
            Viscosity:
          </Grid>
          <Grid item xs={6} md={3}>
            {item.viscosity === 0 ? '' : item.viscosity}
          </Grid>
          <Grid item xs={6} md={3}>
            Degradation:
          </Grid>
          <Grid item xs={6} md={3}>
            {item.degradation === 0 ? '' : item.degradation}
          </Grid>
          <Grid item xs={6} md={3}>
            Other:
          </Grid>
          <Grid item xs={6} md={3}>
            {item.other === 0 ? '' : item.other}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default MobileCard
