import React, { ReactNode } from 'react'

import './LargeIcon.css'

const LargeIcon = ({ children }: { children: ReactNode }) => {
  return (
    <div className="container">
      <p className="icon">{children}</p>
    </div>
  )
}

export default LargeIcon
