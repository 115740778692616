import React, { useEffect, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import {
  Box,
  createTheme,
  Grid,
  ThemeProvider,
  Typography,
} from '@material-ui/core'
import moment from 'moment'
import { stringify } from 'query-string'
import { encodeQueryParams } from 'use-query-params'
import AppLayout from 'components/AppLayout'
import { ExportButton } from 'components/buttons'
import ViewReportButton from 'components/buttons/ViewReportButton'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import { IReportParametersSummary } from 'components/ReportViewer/TelerikReportViewer'
import {
  compactReportParams,
  reportViewerQueryParamConfig,
} from 'components/ReportViewerFromURLParams'
import SEO from 'components/SEO'
import { SummaryReportTable } from 'components/tables/SummaryReport'
import APIService, { TProfile } from 'services/api-service'
import { exportData } from 'services/export-service'
import {
  lubeConditionKeys,
  lubeConditions,
  machineConditions,
  reportTypes,
} from 'services/lookup-service'
import { getSelectedCustomerIDs } from 'services/selection-service'
import coreTheme from '../../components/theme'
import { useProfile } from 'Contexts/ProfileContext'
import FilterAccordion, {
  TAccordionFilterValues,
} from 'components/FilterAccordion'
import { mapToPageParameters } from 'components/FilterAccordion/Utilities'
import {
  ANDOR2,
  DateFilterSet,
  LubricantFilterSet,
  MachineFilterSet,
  OptionsFilterSet,
  OrganizationalFilterSet,
  SampleFilterSet,
} from 'components/FilterAccordion/types'
import { CancellablePromise } from 'utils/CancellablePromise'

const initialStartDate = moment()
  .subtract(3, 'months')
  .startOf('month')
  .startOf('day')
  .toDate()
const initialEndDate = moment().endOf('month').endOf('day').toDate()

const defaults = {
  page: 1,
  pageSize: 10,
  orderConfig: [{ field: 'sampleDate', dir: 'desc' }],
  sampleDate: true,
  reportDate: false,
  startDate: initialStartDate,
  endDate: initialEndDate,
  selectedCustomerIDs: [] as number[],
  selectedPlants: [] as number[],
  selectedRoutes: [] as number[],
  selectedMachineConditions: [] as number[],
  selectedLubeConditions: [] as number[],
  selectedReportTypes: [] as string[],
  selectedDivisionIDs: [] as number[],
  exportFormat: '',
  samplesLoaded: false,
  count: 0,
  searching: false,
  queryStringLoaded: false,
  loadedDefaults: false,
  machineLubeConditionANDOR: 'AND' as ANDOR2,
}

type TProps = {
  onError?: (_err: Error) => void
  classes?: unknown
  offline?: boolean
}

type TState = {
  samplesLoaded: boolean
  count: number
  searching: boolean
} & typeof defaults &
  Partial<TProfile>

const getDataSummary = (data, startTime: Date, endTime: Date): string[][] => {
  const startDate: Date = new Date(startTime)
  const endDate: Date = new Date(endTime)
  return [
    [
      `Date Range: ${
        startDate.getMonth() + 1
      }/${startDate.getDate()}/${startDate.getFullYear().toString()} - ${
        endDate.getMonth() + 1
      }/${endDate.getDate()}/${endDate.getFullYear().toString()}`,
    ],
    [`Total Samples: ${data.pagedSamples.items.length}`],
    [
      `Normal: ${
        data.pagedSamples.items.filter(
          x => x.lubCond === lubeConditionKeys.normal
        ).length
      }`,
    ],
    [
      `Marginal: ${
        data.pagedSamples.items.filter(
          x => x.lubCond === lubeConditionKeys.marginal
        ).length
      }`,
    ],
    [
      `Critical: ${
        data.pagedSamples.items.filter(
          x => x.lubCond === lubeConditionKeys.critical
        ).length
      }`,
    ],
    [
      `Unknown: ${
        data.pagedSamples.items.filter(
          x => x.lubCond === lubeConditionKeys.unknown
        ).length
      }`,
    ],
  ]
}

const unique = (value, index, self) => self.indexOf(value) === index

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

export const SummaryReport: React.FC<TProps> = ({ onError, offline }) => {
  const [state, setState] = useState<TState>(defaults)

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  const profileContext = useProfile()

  const formatter = sample => {
    return [
      ...(profileContext.dependentData.userDetails.isDivisionUser
        ? [sample.machine.plant.customer.division.name]
        : []),
      sample.machine.plant.customer.custName,
      sample.machine.plant.plantName,
      sample.machine.machNo,
      sample.machine.machineName,
      sample.machine.custEquID,
      sample.sampleTests
        .map(x => x.testGroup.testGroupName)
        .filter(unique)
        .join(', '),
      sample.sampleTests
        .map(x =>
          x.testGroup.testGroupDefs
            .map(y => y.testCode.testCodeName)
            .reduce((prev, cur) => prev.concat(cur), [])
        )
        .reduce((prev, cur) => prev.concat(cur), [])
        .filter(unique)
        .join(', '),
      machineConditions.find(x => x.value === sample.machCond).text,
      lubeConditions.find(x => x.value === sample.lubCond).text,
      moment(sample.sampleDate).format('l'),
      sample.labID,
      sample.reportType !== null
        ? reportTypes.find(y => y.value === sample.reportType).text
        : '',
      sample.problems,
      sample.recommendations,
    ]
  }

  useEffect(() => {
    if (
      !state.samplesLoaded &&
      profileContext.dependentData.filters.minimumFilterOptionsLoaded
    ) {
      fetchProfile()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.samplesLoaded,
    profileContext.dependentData.filters.minimumFilterOptionsLoaded,
  ])

  useEffect(() => {
    if (state.searching) {
      unstable_batchedUpdates(() => {
        performSearch()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searching])

  const fetchProfile = () => {
    if (typeof window !== 'undefined') {
      const queryStringState: any = {}

      if (window.location.search !== null) {
        const params = new URLSearchParams(window.location.search)

        if (params.has('custID')) {
          queryStringState.selectedCustomerIDs =
            profileContext.profile.customers
              .filter(c => c.custID === parseInt(params.get('custID'), 10))
              .map(c => c.custID)
        }

        if (params.has('plantID')) {
          queryStringState.selectedPlants = profileContext.profile.plants
            .filter(x => x.plantID === parseInt(params.get('plantID'), 10))
            .map(x => x.plantID)
        }

        if (params.has('startDate')) {
          queryStringState.startDate = moment(params.get('startDate')).toDate()
        }

        if (params.has('endDate')) {
          queryStringState.endDate = moment(params.get('endDate')).toDate()
        }

        if (params.has('machineCondition')) {
          queryStringState.selectedMachineConditions = machineConditions
            .filter(
              x => x.value === parseInt(params.get('machineCondition'), 10)
            )
            .map(x => x.value)
        }

        if (params.has('lubeCondition')) {
          queryStringState.selectedLubeConditions = lubeConditions
            .filter(x => x.value === parseInt(params.get('lubeCondition'), 10))
            .map(x => x.value)
        }
      }

      onError(null)

      setState(prev => ({
        ...prev,
        ...queryStringState,
        selectedCustomerIDs: profileContext.dependentData.userDetails
          .isMultiCustomerUser
          ? state.selectedCustomerIDs
          : !state.loadedDefaults
          ? profileContext.dependentData.filters.initialFilterState.customers.map(
              c => c.custID
            )
          : state.selectedCustomerIDs,
        selectedDivisionIDs:
          !state.loadedDefaults && state.selectedDivisionIDs.length === 0
            ? profileContext.dependentData.filters.initialFilterState.divisions.map(
                d => d.iD
              )
            : state.selectedDivisionIDs,
        searching: true,
        queryStringLoaded: true,
      }))
    }
  }

  const performSearch = () => {
    setState(prev => ({ ...prev, samplesLoaded: false }))
    fetchSamples(
      {
        machineLubeConditionANDOR: state.machineLubeConditionANDOR,
        page: state.page,
        pageSize: state.pageSize,
        orderList: state.orderConfig.map(x => x.dir),
        orderByList: state.orderConfig.map(x => x.field),
        startDate: state.startDate,
        endDate: state.endDate,
        customerIDs: getSelectedCustomerIDs(
          profileContext.profile.customers,
          profileContext.profile.divisions,
          state.selectedDivisionIDs,
          state.selectedCustomerIDs
        ),
        plantIDs: state.selectedPlants,
        routeIDs: state.selectedRoutes,
        machineConditions: state.selectedMachineConditions,
        lubeConditions: state.selectedLubeConditions,
        reportTypes: state.selectedReportTypes,
      },
      () =>
        setState(prev => ({ ...prev, samplesLoaded: true, searching: false }))
    )
  }

  const fetchSamples = async (variables, cb) => {
    let data = null
    let promise
    try {
      promise = APIService.getSummaryReport(variables)
      ongoingCancellablePromises.push(promise)
      data = await promise
    } catch (ex) {
      onError(ex)
      return
    } finally {
      ongoingCancellablePromises.filter(p => p != promise)
    }

    if (data.pagedSamples !== null) {
      onError(null)
      setState(prev => ({
        ...prev,
        samples: data.pagedSamples.items,
        count: data.pagedSamples.count,
      }))

      cb()
    }
  }

  const handleResetClick = () => {
    setState(prev => ({
      ...prev,
      ...defaults,
      count: 0,
      samplesLoaded: false,
      loadedDefaults: false,
    }))
  }

  const handleExportFormatChange = async (_event, format) => {
    const headerValues = [
      ...(profileContext.dependentData.userDetails.isDivisionUser
        ? ['Division Name']
        : []),
      'Customer',
      'Plant',
      'Mach No',
      'Machine Name',
      'Equipment ID',
      'Test Packages',
      'Tests',
      'Mach',
      'Lube',
      'Sample Date',
      'Lab No',
      'Report Type',
      'Problems',
      'Recommendations',
    ]

    let data = null
    let promise
    try {
      promise = await APIService.getSummaryReport({
        page: 1,
        pageSize: 100000,
        orderList: state.orderConfig.map(x => x.dir),
        orderByList: state.orderConfig.map(x => x.field),
        startDate: state.startDate,
        endDate: state.endDate,
        customerIDs: getSelectedCustomerIDs(
          profileContext.profile.customers,
          profileContext.profile.divisions,
          state.selectedDivisionIDs,
          state.selectedCustomerIDs
        ),
        plantIDs: state.selectedPlants,
        routeIDs: state.selectedRoutes,
        machineConditions: state.selectedMachineConditions,
        lubeConditions: state.selectedLubeConditions,
        reportTypes: state.selectedReportTypes,
      })
      ongoingCancellablePromises.push(promise)
      data = await promise
    } catch (ex) {
      onError(ex)
      return
    } finally {
      ongoingCancellablePromises.filter(p => p != promise)
    }

    const summaryData: string[][] = getDataSummary(
      data,
      state.startDate,
      state.endDate
    )

    const formatted = [
      headerValues,
      ...(data.pagedSamples.items.map(x => formatter(x)) as string[][]),
    ]

    exportData('summary-report', formatted, format.key, summaryData)
  }

  const handlePageChange = event => {
    setState(prev => ({
      ...prev,
      page: event.page.skip / state.pageSize + 1,
      pageSize: event.page.take,
      samplesLoaded: false,
      searching: true,
    }))
  }

  const handleSortChange = event => {
    const isFieldRemoved = state.orderConfig.length > event.sort.length
    let isLastFieldRemoved = false

    if (isFieldRemoved) {
      const fieldRemoved = state.orderConfig.filter(
        x => !event.sort.includes(x)
      )[0]

      isLastFieldRemoved =
        state.orderConfig.indexOf(fieldRemoved) === state.orderConfig.length - 1
    }

    setState(prev => ({
      ...prev,
      orderConfig: [...event.sort],
      samplesLoaded: !(event.sort.length > 0 && !isLastFieldRemoved),
    }))
  }

  const handlePageChangeMobile = (event, newPage) => {
    setState(prev => ({ ...prev, page: newPage + 1, samplesLoaded: false }))
  }

  const handlePageSizeChangeMobile = event => {
    setState(prev => ({
      ...prev,
      page: 1,
      pageSize: parseInt(event.target.value, 10),
      samplesLoaded: false,
    }))
  }

  const getReportQueryParams = () => {
    const {
      selectedCustomerIDs,
      selectedPlants,
      selectedRoutes,
      selectedLubeConditions,
      selectedMachineConditions,
      startDate,
      endDate,
      orderConfig,
      selectedReportTypes,
    } = state

    const reportParams: IReportParametersSummary = {
      Customers:
        selectedCustomerIDs.length === 0
          ? profileContext.profile.customers.map(c => c.custID)
          : selectedCustomerIDs,
      Plants: selectedPlants,
      Routes: selectedRoutes,
      LubeConditions: selectedLubeConditions,
      MachineConditions: selectedMachineConditions,
      To: endDate,
      From: startDate,
      SortColumn: orderConfig.length > 0 ? orderConfig[0].field : '',
      SortDirection: orderConfig.length > 0 ? orderConfig[0].dir : '',
      CorporateContactID: 0,
      ReportTypes: selectedReportTypes,
    }

    return encodeQueryParams(reportViewerQueryParamConfig, {
      dbg: false,
      r: 'summary',
      ...compactReportParams(reportParams),
    })
  }

  const theme = createTheme(
    {
      overrides: {
        MuiGrid: {
          root: {
            justifyContent: 'flex-end',
            [coreTheme.breakpoints.down('sm')]: {
              justifyContent: 'space-between',
            },
          },
        },
      },
    },
    coreTheme
  )

  const handleFilterSubmission = (data: TAccordionFilterValues) => {
    const mappedParameters = mapToPageParameters(data)

    setState(prev => ({
      ...prev,
      ...mappedParameters,
      page: 1,
      searching: true,
      samplesLoaded: false,
    }))
  }

  return (
    <React.Fragment>
      <SEO title="Summary Report" />
      <Box mb={2}>
        <Typography variant="h1">Summary Report</Typography>
      </Box>
      {offline ? <OfflineBanner /> : <></>}
      <FilterAccordion
        pageName="Summary Report"
        defaultValues={profileContext.dependentData.filters.initialFilterState}
        onSubmit={handleFilterSubmission}
        onReset={handleResetClick}
        filters={{
          options: [OptionsFilterSet.Prefiltering],
          date: [DateFilterSet.FromDate, DateFilterSet.ToDate],
          organization: [OrganizationalFilterSet.All],
          machine: [MachineFilterSet.Conditions],
          lubricant: [LubricantFilterSet.Conditions],
          sample: [SampleFilterSet.ReportTypes],
          miscellaneous: [],
        }}
      />
      <form>
        <ThemeProvider theme={theme}>
          <Grid
            container
            direction="row"
            xs={12}
            spacing={1}
            style={{ marginTop: '15px' }}
          >
            <Grid container item direction="row" spacing={1}>
              <Grid item style={{ maxWidth: 242, marginLeft: 15 }}>
                <ViewReportButton
                  disabled={!state.samples?.length}
                  url={`/reports/viewer?${stringify(getReportQueryParams())}`}
                />
              </Grid>

              <Grid item>
                <ExportButton
                  onChange={handleExportFormatChange}
                  disabled={!state.samples?.length}
                />
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
      </form>
      <Box mt={4}>
        {!state.samplesLoaded ? (
          <Loader />
        ) : (
          <React.Fragment>
            <SummaryReportTable
              samples={state.samples}
              orderConfig={state.orderConfig}
              offline={offline}
              page={state.page}
              pageSize={state.pageSize}
              count={state.count}
              onSortChange={handleSortChange}
              onPageChange={handlePageChange}
              onPageChangeMobile={handlePageChangeMobile}
              onPageSizeChangeMobile={handlePageSizeChangeMobile}
            />
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  )
}

export default function Page() {
  return (
    <AppLayout tab="reports">
      <SummaryReport />
    </AppLayout>
  )
}
