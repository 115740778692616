import React from 'react'
import { Grid } from '@material-ui/core'

import MobileCardContent from 'components/MobileCardContent'
import MobileCardItem from 'components/MobileCardItem'

import { RenderMonthsDynamicColumns } from './RenderMonthsDynamicColumns'
import { Link } from 'react-router-dom'

interface OwnProps {
  data: any
  showDivisions: boolean
  months: any[]
}

export const MobileCard: React.FC<OwnProps> = ({
  data,
  showDivisions,
  months,
}: OwnProps) => {
  return (
    <MobileCardContent title={data.machine.plant.plantName}>
      <Grid container direction="row" spacing={1}>
        {showDivisions && (
          <MobileCardItem title="Division">
            {data.machine.plant.customer.division.name}
          </MobileCardItem>
        )}
        <MobileCardItem title="Customer">
          {data.machine.plant.customer.custName}
        </MobileCardItem>
        <MobileCardItem title="Plant Name">
          {data.machine.plant.plantName}
        </MobileCardItem>
        <MobileCardItem title="Route">
          {data.route && `${data.route.routeNo} - ${data.route.name}`}
        </MobileCardItem>
        <MobileCardItem title="Mach No.">{data.machine.machNo}</MobileCardItem>
        <MobileCardItem title="Machine Name">
          <div style={{ color: '#6200EE' }}>
            <Link
              to={`/dart/data?labID=${data.latestLabID}`}
              target="_blank"
              rel="noreferrer"
            >
              {data.machine.machineName}
            </Link>
          </div>
        </MobileCardItem>
        <MobileCardItem title="Equipment ID">
          {data.machine.custEquID}
        </MobileCardItem>
        <MobileCardItem title="Test Package">
          {data.testGroup.testGroupName}
        </MobileCardItem>
        <MobileCardItem title="Tests">
          {data.testGroup.testGroupDefs
            .map(x => x.testCode.testCodeName)
            .join(', ')}
        </MobileCardItem>
        {months.map(({ month, year, columnTitle }, i) => (
          <MobileCardItem key={i} title={columnTitle} isScheduleCompliance>
            <RenderMonthsDynamicColumns data={data} month={month} year={year} />
          </MobileCardItem>
        ))}
      </Grid>
    </MobileCardContent>
  )
}
