import {
  LoadingState,
  LoadingStateWithoutReset,
} from 'pages/sample/registration/Cards/BottleInformationEntry/CreateMachine/types'
import { useState, useEffect } from 'react'
const DEFAULT_STATE: LoadingStateWithoutReset<Cache, Error> = {
  error: null,
  data: null,
  loading: true,
  loaded: false,
}

export function useWebCache(cacheName: string) {
  const [state, setState] = useState<LoadingStateWithoutReset<Cache, Error>>({
    ...DEFAULT_STATE,
  })

  async function addToCache(url: string, data: any, timeoutMS: number) {
    if (!state.data) return
    const expirationDate = new Date()
    expirationDate.setMilliseconds(timeoutMS)

    const dataString = JSON.stringify([expirationDate.getTime(), data])
    await state.data.put(
      url,
      new Response(dataString, {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
          'Content-Length': dataString.length.toString(),
        },
      })
    )
  }

  useEffect(() => {
    function openCache() {
      return caches.open(cacheName)
    }
    async function initializeWebCache() {
      if (!state.loading) return

      try {
        const browserSupportsCaches = !!window && 'caches' in window

        if (browserSupportsCaches) {
          const cache = await openCache()
          setState(_ => ({
            error: null,
            loading: false,
            data: cache,
            loaded: true,
          }))
        } else {
          setState(_ => ({
            loading: false,
            loaded: true,
            data: null,
            error: new Error('Cache not supported.'),
          }))
        }
      } catch (error) {
        setState(_ => ({
          loading: false,
          loaded: true,
          data: null,
          error: new Error('Error initializing cache.', { cause: error }),
        }))
      }
    }

    async function removeAllFromCache(cache: Cache) {
      if (!cache) {
        cache = await openCache()
      }

      const keys = await cache.keys()
      for (const key of keys) {
        cache.delete(key)
      }
    }

    initializeWebCache()

    return () => {
      removeAllFromCache(state.data)
    }
  }, [state.loading])

  function reset() {
    setState({
      ...DEFAULT_STATE,
    })
  }

  return { state: { ...state, reset }, addToCache }
}

export default useWebCache
