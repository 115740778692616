import React, { useEffect, useState } from 'react'
import { Box, Hidden, TablePagination } from '@material-ui/core'
import {
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'

import TableGrid from 'components/TableGrid'

import {
  RenderDate,
  RenderLabNo,
  RenderLubeCondition,
  RenderMachineCondition,
  RenderMachineName,
  RenderReportType,
  RenderRoute,
} from '../KendoUIGridRenders'

import { MobileCard } from './MobileCard'

export type TProps = {
  offline?: boolean
  samples: any
  orderConfig: any[]
  page: number
  pageSize: number
  count: number
  onPageChange?: (_event: GridPageChangeEvent) => void
  onSortChange?: (_event: GridSortChangeEvent) => void
  onPageChangeMobile: (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    _page: number
  ) => void
  onPageSizeChangeMobile?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >
}

export const SummaryReportTable: React.FC<TProps> = ({
  offline,
  samples,
  orderConfig,
  page,
  pageSize,
  count,
  onPageChange,
  onSortChange,
  onPageChangeMobile,
  onPageSizeChangeMobile,
}: TProps) => {
  const [samplesCopy, setSamplesCopy] = useState(samples)

  useEffect(() => {
    setSamplesCopy(samples)
  }, [samples])

  const showDivisions = samples.some(s => s.machine.plant.customer.division)

  return (
    <>
      {offline && (
        <Box mb={2}>Your last 100 reports are available while offline.</Box>
      )}

      <Hidden mdUp>
        {!samples?.length && (
          <p style={{ textAlign: 'center' }}>No records available</p>
        )}
        {samples.map(sample => (
          <MobileCard
            key={sample.labID}
            sample={sample}
            showDivisions={showDivisions}
          />
        ))}

        {!offline ? (
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100, 250, 500]}
            component="div"
            count={count}
            rowsPerPage={pageSize}
            page={page > 0 ? page - 1 : page}
            onChangePage={onPageChangeMobile}
            onChangeRowsPerPage={onPageSizeChangeMobile}
          />
        ) : (
          ''
        )}
      </Hidden>

      <Hidden smDown>
        <TableGrid
          clearSortingButtonId="summary_report-clear_sorting_button"
          offline={offline}
          data={samplesCopy}
          sort={orderConfig}
          skip={(page - 1) * pageSize}
          take={pageSize}
          total={count}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
        >
          {showDivisions && (
            <Column
              field="machine.plant.customer.division.name"
              title="Division Name"
            />
          )}
          <Column
            field="machine.plant.customer.custName"
            title="Customer Name"
          />
          <Column field="machine.plant.plantName" title="Plant Name" />
          <Column
            field="machine.route.routeNo"
            title="Route"
            cell={props => <RenderRoute {...props} />}
          />
          <Column field="machine.machNo" title="Mach No." width={50} />
          <Column
            field="machine.machineName"
            title="Machine Name"
            cell={props => <RenderMachineName {...props} />}
          />
          <Column field="machine.custEquID" title="Equipment ID" width={160} />

          <Column
            field="machCond"
            title="Mach"
            cell={props => <RenderMachineCondition {...props} />}
          />
          <Column
            field="lubCond"
            title="Lube"
            cell={props => <RenderLubeCondition {...props} />}
          />
          <Column
            field="sampleDate"
            title="Sample Date"
            cell={props => <RenderDate {...props} />}
          />
          <Column
            field="labID"
            title="Lab No."
            cell={props => <RenderLabNo {...props} />}
          />
          <Column
            field="reportType"
            title="Report Type"
            cell={props => <RenderReportType {...props} />}
          />
          <Column field="problems" title="Problems" sortable={false} />
          <Column
            field="recommendations"
            title="Recommendations"
            sortable={false}
          />
        </TableGrid>
      </Hidden>
    </>
  )
}
