import React, { useContext, useState } from 'react'
import { Checkbox } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'

import ControlContext from '../../../Contexts/ControlContext'
import coreTheme from '../../theme'

const theme = createTheme(
  {
    overrides: {
      MuiFormLabel: {
        root: {
          color: '#222',
          zIndex: 99,
          pointerEvents: 'none',
        },
      },
      MuiCheckbox: {
        root: {
          color: '#222',
        },
      },
    },
  },
  coreTheme
)

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

type TProps = {
  options: any[]
  required: boolean
  htmlFor: string
  label: string
  id: string
  defaultValue?: any
  value?: any
  onClose?: (_value: any) => void
  onInput?: (_value: any) => void
  getOptionSelected?: (_option: any, _value: any) => boolean
  onChange: (_value: any) => void
  multiple: boolean
  renderValue: (_value: any) => string
  variant?: 'filled' | 'outlined' | 'standard'
  autoCompletefilterOptions?: (option) => string
}

export const SelectFilter = (props: TProps) => {
  const {
    required,
    label,
    options,
    onChange,
    renderValue,
    multiple,
    defaultValue,
    value,
    onInput,
    onClose,
    variant,
    autoCompletefilterOptions,
    ...rest
  } = props

  // const loadedOptions = options == null ? [] : options
  const controlContext = useContext(ControlContext)

  const [controlledValue, setControlledValue] = useState(
    defaultValue ?? value ?? null
  )
  const [inputValue, setInputValue] = React.useState('')

  const handleChange = vals => {
    onChange(vals)
    setControlledValue(vals)
  }

  return (
    <>
      {options === undefined ? null : (
        <ThemeProvider theme={theme}>
          <Autocomplete
            inputValue={inputValue}
            onInputChange={(event, value) => {
              onInput && onInput(event)
              if (event && event.type === 'blur') {
                setInputValue('')
              } else if (!multiple) {
                setInputValue(value)
              } else if (event && event.type === 'change') {
                setInputValue(value)
              }
            }}
            onClose={onClose}
            includeInputInList
            openOnFocus
            key={controlContext.resetKey}
            multiple={multiple}
            defaultValue={defaultValue}
            value={controlledValue}
            {...rest}
            disableCloseOnSelect={multiple}
            noOptionsText={'There are no options available.'}
            options={options}
            limitTags={1}
            getOptionLabel={option => renderValue(option) ?? ''}
            filterOptions={
              autoCompletefilterOptions
                ? createFilterOptions({
                    stringify: autoCompletefilterOptions,
                  })
                : undefined
            }
            renderInput={params => (
              <TextField
                required={required}
                onClick={onClose}
                {...params}
                label={label}
                variant={variant || 'outlined'}
                size="small"
              />
            )}
            renderOption={(option, { inputValue, selected }) => {
              if (options?.length > 100) return renderValue(option) ?? ''

              const matches = match(renderValue(option) ?? '', inputValue)
              const parts = parse(renderValue(option) ?? '', matches)

              return (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected}
                  />
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                          width: '445px',
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </React.Fragment>
              )
            }}
            onChange={(event, value) => handleChange(value)}
          />
        </ThemeProvider>
      )}
    </>
  )
}
