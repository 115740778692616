import Button from '@material-ui/core/Button'
import Loader from 'components/Loader'
import React, { useCallback } from 'react'
import * as PageStyles from '../page-styles.module.css'
import { PageCard, PageBody } from '../Pages/PageContainers'
import TroubleshootIcon from '@mui/icons-material/Troubleshoot'
import { useNavigate } from 'react-router-dom'
import { getBottleQueryPath } from '../formatters'
import { getSampleRegistrationBaseURL } from '../constants'

type TProps = {
  canLoginToAssign: boolean
  encryptedBottleIdentifier?: string
}

export const NoCustomerCardModule = ({
  canLoginToAssign,
  encryptedBottleIdentifier,
}: TProps) => {
  const navigate = useNavigate()
  const handleNavigate = useCallback(() => {
    const extension = encryptedBottleIdentifier
      ? getBottleQueryPath({ encryptedID: encryptedBottleIdentifier })
      : ''

    const navURL = `/Login?PostLoginURL=${getSampleRegistrationBaseURL()}${extension}`

    navigate(navURL)
  }, [getSampleRegistrationBaseURL(), encryptedBottleIdentifier])

  return (
    <>
      <div>
        <header className={PageStyles.MainTitle}>No Customer Assigned</header>
        <sub className={PageStyles.SubTitle}>
          <span className={PageStyles.BlockSpan}>
            This bottle is missing crucial information!
          </span>
        </sub>
      </div>
      <div className={PageStyles.LargeIconBox}>
        <TroubleshootIcon
          className={PageStyles.TroubleshootIcon}
          style={{ fontSize: '9rem' }}
        />
      </div>
      <div>
        <section className={PageStyles.DetailSection}>
          <p className={PageStyles.FinePrintParagraph}>
            A bottle may not be assigned sample information if there is no
            customer information assigned to the bottle.
          </p>
          <p className={PageStyles.FinePrintParagraph}>
            While the process of assigning customer information is handled by
            TestOil before any bottle is shipped, it seems this one was missed
            in the process! We apologize for any inconvenience.
          </p>
          {canLoginToAssign && (
            <p className={PageStyles.FinePrintParagraph}>
              To remedy this problem, please log in.
            </p>
          )}

          {!canLoginToAssign && (
            <p className={PageStyles.FinePrintParagraph}>
              If you have access to the QR code accompanying this identifier,
              please scan this code and log in. This will assign the bottle
              identifier to the logged-in customer account. If you do not have
              access to this identifier, please contact customer support at{' '}
              <a
                href={`mailto: ${process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL}`}
              >
                {process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL!}
              </a>{' '}
              for further help.
            </p>
          )}
        </section>

        {canLoginToAssign && (
          <Button
            variant="contained"
            color="primary"
            data-cancel
            fullWidth
            onClick={handleNavigate}
          >
            I'll Log In
          </Button>
        )}
      </div>
    </>
  )
}
