import { createTheme } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

/*
$mdc-shape-small-component-radius: 9px;
$mdc-shape-medium-component-radius: 9px;
$mdc-shape-large-component-radius: 9px;

$mdc-theme-primary: #505F74;
$mdc-theme-secondary: #FF6600;

$mdc-theme-surface: #FFFFFF;
$mdc-theme-background: #FFFFFF;
$mdc-theme-error: #b00020;

$mdc-theme-on-primary: #F7F7FA;
$mdc-theme-on-secondary: #F7F7FA;
$mdc-theme-on-error: #F7F7FA;
$mdc-theme-on-surface: #222222;
 * */

const breakpoints = createBreakpoints({})

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        fontFamily: "'Fira Sans Condensed', sans-serif",
      },
    },
    MuiButton: {
      root: {
        borderRadius: 3,
        fontSize: '0.75rem',
        height: '36px',
      },
    },
    MuiSnackbar: {
      root: {
        backgroundColor: '#222',
        color: '#fff',
        minWidth: '300px',
      },
    },
    MuiAlert: {
      standardError: {
        backgroundColor: '#222',
        color: '#fff',
      },
      standardInfo: {
        backgroundColor: '#222',
        color: '#fff',
      },
      icon: {
        color: '#FF6600 !important',
      },
    },
    MuiAutocomplete: {
      root: {
        [breakpoints.down('md')]: {
          fontSize: '1rem',
        },
        [breakpoints.down('md')]: {
          width: '225px',
        },
        [breakpoints.up('md')]: {
          width: '225px',
        },
      },
    },
  },

  typography: {
    fontFamily: "'Fira Sans Condensed', sans-serif",
    h1: {
      fontSize: '2rem',
      fontWeight: 400,
      [breakpoints.down('md')]: {
        fontSize: '1.5rem',
      },
    },
    h2: {
      fontSize: '1.75rem',
      color: '#444444',
      fontWeight: 400,
      [breakpoints.down('md')]: {
        fontSize: '1.25rem',
      },
    },
    h3: {
      fontSize: '1.5rem',
      [breakpoints.down('md')]: {
        fontSize: '1.1rem',
      },
    },
    body2: {
      fontFamily: "'Fira Sans Condensed', sans-serif",
    },
  },
  palette: {
    primary: {
      main: '#505F74',
      contrastText: '#F7F7FA',
    },
    secondary: {
      main: '#FF6600',
      contrastText: '#F7F7FA',
    },
    error: {
      main: '#b00020',
    },
    text: {
      primary: '#222222',
      secondary: '#FFFFFF',
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
    },
  },
})

export default theme
