import React, { useMemo } from 'react'
import { Button, Modal } from '@material-ui/core'

import { TShipmentInformation } from 'types/api'
import { shippingAnchorFromOrder } from 'utils/api-utilities'
import { DistinctSort } from 'utils/ArrayMethods'
import {
  CapitalizeFirst,
  EndInPeriod,
  RemoveThankYou,
} from 'utils/string-utilities'

import * as Styles from './index.module.css'
import { useProfile } from 'Contexts/ProfileContext'
import {
  downloadInvoiceUrlWithNotifications,
  getOrderInvoiceNumberLink,
  invoiceNumbersToDownloadLink,
} from 'components/tables/OrderInformationTable/order-information-tools'
import { toast, ToastContainer } from 'react-toastify'

type OwnProps = {
  open: boolean
  handleClose: () => void
  shipmentInformation: TShipmentInformation
}

const ShipmentInformationModal: React.FC<OwnProps> = (props: OwnProps) => {
  const invoiceNumbers = DistinctSort(
    props.shipmentInformation?.orderDetails
      ?.map(details => details?.invoiceNo)
      .filter(i => i)
      .sort((a, b) => a - b) ?? []
  )
  const areInvoices = invoiceNumbers.length > 0

  const handleModalClose = () => {
    props.handleClose()
  }

  const profileContext = useProfile()
  const profile = profileContext.profile
  const customerIDs = useMemo(
    () => profile.customers.map(c => c.custID),
    [profile.customers]
  )

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div id={Styles.ShippingDialog}>
        <p id={Styles.DialogTitle}>
          Order {'   '}
          {props.shipmentInformation?.orderId || 'Unknown'}
        </p>
        <div className={Styles.Information}>
          <div
            id={Styles.OrderInformation}
            className={Styles.InformationContainer}
          >
            <div className={`${Styles.DialogSubtitle} ${Styles.BlueTheme}`}>
              Order Information
            </div>

            <p className={Styles.DialogHeaderText}>Order Date</p>
            <p className={Styles.DialogSubText}>
              {props.shipmentInformation?.orderDate
                ? new Date(
                    props.shipmentInformation?.orderDate
                  ).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })
                : 'N/A'}
            </p>
            <p className={Styles.DialogHeaderText}>Shipped On</p>
            <p className={Styles.DialogSubText}>
              {props.shipmentInformation?.shipDate
                ? new Date(
                    props.shipmentInformation?.shipDate
                  ).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })
                : 'N/A'}
            </p>
            <p className={Styles.DialogHeaderText}>Shipping Method</p>
            <p className={Styles.DialogSubText}>
              {props.shipmentInformation?.shippingMethod
                ? props.shipmentInformation?.shippingMethod
                : 'N/A'}
            </p>
            <p className={Styles.DialogHeaderText}>Tracking Number</p>
            <p className={Styles.DialogSubText}>
              {shippingAnchorFromOrder(props.shipmentInformation)}
            </p>
          </div>
          <div
            id={Styles.ContactInformation}
            className={Styles.InformationContainer}
          >
            <div className={`${Styles.DialogSubtitle} ${Styles.OrangeTheme}`}>
              Contact Information
            </div>

            <p className={Styles.DialogHeaderText}>Company</p>
            <p className={Styles.DialogSubText}>
              {props.shipmentInformation?.shipCompany
                ? props.shipmentInformation?.shipCompany
                : 'Unknown'}
            </p>
            <p className={Styles.DialogHeaderText}>Shipping Address</p>
            <p className={Styles.DialogSubText}>
              {props.shipmentInformation?.shipAddress
                ? props.shipmentInformation?.shipAddress
                : 'Unknown'}
            </p>

            <p className={Styles.DialogHeaderText}>Contact</p>
            <p className={Styles.DialogSubText}>
              {props.shipmentInformation?.shipFirstName
                ? `${props.shipmentInformation?.shipFirstName} ${
                    props.shipmentInformation?.shipLastName ?? ''
                  }`
                : 'Unknown'}
            </p>
            <p className={Styles.DialogHeaderText}>Contact Email</p>
            <p className={Styles.DialogSubText}>
              {props.shipmentInformation?.shipEmail
                ? props.shipmentInformation?.shipEmail
                : 'Unknown'}
            </p>
          </div>
        </div>
        <div
          className={`${Styles.Information} ${Styles.TableContainer}`}
          style={{ padding: '0 20px 0 20px' }}
        >
          <table className={Styles.OrderDetailsTable}>
            <thead>
              <tr>
                <th>Product</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {props.shipmentInformation?.orderDetails
                .sort((a, b) => a.product.localeCompare(b.product))
                .map(od => (
                  <tr>
                    <td className={Styles.Product}>{od.product}</td>
                    <td className={Styles.Quantity}>{od.quantity}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div
          className={`${Styles.Information} ${Styles.InformationLeft}`}
          style={{ padding: '0 20px 0 20px' }}
        >
          <p className={Styles.DialogHeaderText} style={{ textAlign: 'left' }}>
            Invoices
          </p>
          {areInvoices ? (
            <ul>
              {invoiceNumbers.map(invNo => {
                if (!invNo) return null
                const link = invoiceNumbersToDownloadLink(invNo, customerIDs)

                const onClick = (text: string, event: React.MouseEvent) => {
                  event.preventDefault()
                  downloadInvoiceUrlWithNotifications(
                    link,
                    `invoice ${text}`,
                    text
                  )
                }

                return (
                  <li style={{ textAlign: 'left' }}>
                    <a href="#" onClick={e => onClick(invNo.toString(), e)}>
                      {invNo}
                    </a>
                  </li>
                )
              })}
            </ul>
          ) : (
            <p className={Styles.Subtext}>N/A</p>
          )}
          <p className={Styles.DialogHeaderText} style={{ textAlign: 'left' }}>
            Notes
          </p>
          <p className={Styles.Subtext}>
            {EndInPeriod(
              CapitalizeFirst(props.shipmentInformation?.notes || 'None')
            )}
          </p>

          <p className={Styles.DialogHeaderText} style={{ textAlign: 'left' }}>
            Special Instructions
          </p>
          <p className={Styles.Subtext}>
            {EndInPeriod(
              CapitalizeFirst(
                RemoveThankYou(
                  props.shipmentInformation?.specialInstructions || 'None'
                )
              )
            )}
          </p>
        </div>

        <div
          className={`${Styles.Actions} ${Styles.ActionsCenter}`}
          style={{ padding: '10px 20px 10px 20px' }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ width: '200px' }}
            onClick={handleModalClose}
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ShipmentInformationModal
