import React, { useCallback, useEffect, useRef, useState } from 'react'
import { stringify } from 'querystring'
import {
  Box,
  createTheme,
  FormControl,
  Grid,
  ThemeProvider,
  Typography,
} from '@material-ui/core'
import { SortDescriptor } from '@progress/kendo-data-query'
import { GridSortChangeEvent } from '@progress/kendo-react-grid'
import {
  LabelType,
  PageBreakType,
  PrintLabelQuery,
  TAPIMachine,
} from 'types/api.d'
import moment from 'moment'
import { encodeQueryParams } from 'use-query-params'
import AppLayout from 'components/AppLayout'
import { ExportButton } from 'components/buttons'
import { SelectFilter, TextFieldFilter } from 'components/filters'
import HelpPopupLink from 'components/HelpPopupLink'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import {
  IReportParametersLabelsList,
  IReportParametersLabelsSheet,
} from 'components/ReportViewer/TelerikReportViewer'
import {
  compactReportParams,
  reportViewerQueryParamConfig,
} from 'components/ReportViewerFromURLParams'
import SEO from 'components/SEO'
import {
  StandardLabelsTable,
  tableFormatter,
} from 'components/tables/StandardLabels'
import APIService from 'services/api-service'
import { exportData } from 'services/export-service'
import { getSelectedCustomerIDs } from 'services/selection-service'
import coreTheme from '../../../components/theme'
import UPSLink from './UPSLink/UPSLink'
import { useNavigate } from 'react-router-dom'
import { useProfile } from 'Contexts/ProfileContext'
import FilterAccordion, {
  TAccordionFilterValues,
} from 'components/FilterAccordion'
import { mapToPageParameters } from 'components/FilterAccordion/Utilities'
import {
  OptionsFilterSet,
  OrganizationalFilterSet,
  MachineFilterSet,
  DateFilterSet,
  MiscellaneousFilterSet,
} from 'components/FilterAccordion/types'
import { CancellablePromise } from 'utils/CancellablePromise'
import { DistributedSampleLabelsStateButton } from './DistributedSampleLabelsStateButton'
import { DistributedStateContextProvider as StandardSampleLabelsDistributedStateContextProvider } from 'components/tables/StandardLabels/DistributedStateContext'

const skip = []
for (let i = 0; i <= 9; i++) skip.push(i)

const printFormats = [
  {
    value: 'avery',
    text: 'Avery 2" x 4" mailing label 5163',
  },
  {
    value: 'dymo',
    text: 'Dymo 2 1/8" x 4" label 30323',
  },
]

const printOptions = [
  {
    value: 'one-label-per-machine',
    text: 'One label per machine',
  },
  {
    value: '10-labels-per-machine',
    text: '10 labels per machine',
  },
  {
    value: 'no-date',
    text: 'Labels printed without date',
  },
]

const pageBreakOptions = [
  {
    value: 'none',
    text: 'None',
  },
  {
    value: 'plant',
    text: 'Plant',
  },
  {
    value: 'route',
    text: 'Route',
  },
]

const defaults = {
  page: 1,
  pageSize: 100,
  orderConfig: [
    { field: 'machNo', dir: 'asc' },
    { field: 'plant.plantName', dir: 'asc' },
  ] as SortDescriptor[],
  selectedDivisionIDs: [],
  selectedCustomerIDs: [],
  selectedPlants: [],
  selectedRoutes: [],
  selectedCustEquIDs: [],
  selectedMachineNames: [],
  selectedMachineNumbers: [],
  onlyForSelectedMonth: false,
  selectedSampleDate: null,
  selectedPrintFormat: 'avery',
  selectedPrintOptions: 'one-label-per-machine',
  selectedSkip: '0',
  selectedPageBreak: 'none',
  printOptions: printOptions,
  printFormats: printFormats,
  pageBreakOptions: pageBreakOptions,
  showGlobalSelectionBanner: false,
  globalAllSelected: false,
  OmitDay: true,
}

interface OwnProps {
  onError?: (_err: Error) => void // passed in from layout wrapper
  offline?: boolean
  classes?: any
}

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

const StandardSampleLabels: React.FC<OwnProps> = ({
  onError,
  offline,
  classes,
}: OwnProps) => {
  const navigate = useNavigate()
  const [state, setState] = useState({
    ...defaults,
    loaded: false,
    omitDay: true,
    omitFullDate: false,
    profileUpdated: false,
    count: 0,
    performedInitialLoad: false,
    searching: false,
    loadedDefaults: false,
    showViewPrintLabels: false,
    data: null,
  })

  const profileContext = useProfile()

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  useEffect(() => {
    if (profileContext.dependentData.filters.minimumFilterOptionsLoaded) {
      setState(prev => ({
        ...prev,
        selectedCustomerIDs:
          profileContext.dependentData.filters.initialFilterState.customers.map(
            c => c.custID
          ),
        selectedDivisionIDs:
          profileContext.dependentData.filters.initialFilterState.divisions.map(
            d => d.iD
          ),
        loadedDefaults: true,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext.dependentData.filters.minimumFilterOptionsLoaded])

  const selectedSkipRef = useRef<number>(0)

  const globalAllSelectedRef = useRef(false)
  const selectedMachineRefs = useRef([])

  const handleClick = event => {
    if (
      event.target.type === 'checkbox' &&
      !event.target.checked &&
      selectedMachineRefs.current.length !==
        selectedMachineRefs.current.filter(x => x?.checked).length &&
      globalAllSelectedRef.current
    ) {
      globalAllSelectedRef.current = false

      setState(prev => ({
        ...prev,
        showGlobalSelectionBanner: false,
        globalAllSelected: false,
      }))
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleClick)

    // cleanup this component
    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [])

  useEffect(() => {
    if (!state.performedInitialLoad) {
      const selectedDivisionIDs =
        profileContext.profile?.divisions?.length > 0 &&
        profileContext.profile?.customers?.length > 0
          ? [
              profileContext.profile.divisions?.find(x =>
                x.customers.some(
                  y => y.custID === profileContext.profile.customers[0].custID
                )
              ).iD,
            ]
          : []
      const selectedCustomerIDs =
        profileContext.profile.customers?.length > 0
          ? [profileContext.profile.customers[0].custID]
          : []
      setState(prev => ({
        ...prev,
        selectedDivisionIDs,
        selectedCustomerIDs,
        performedInitialLoad: true,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.performedInitialLoad])

  useEffect(() => {
    if (state.searching) {
      setState(prev => ({ ...prev, loaded: false }))
      performSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searching])

  useEffect(() => {
    if (!state.loaded && !state.searching && state.loadedDefaults) {
      fetchProfile().then(() => performSearch())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loaded, state.profileUpdated, state.loadedDefaults])

  useEffect(() => {
    const selectionList = document.querySelectorAll(
      ".k-grid-container input[type='checkbox'], .mobile-cards input[type='checkbox']"
    )

    selectionList.forEach((item, index) => {
      item.checked = selectedMachineRefs.current[index].checked
    })

    const selectAll = document.querySelector('.select-all')

    if (selectAll)
      selectAll.checked =
        selectedMachineRefs.current.length ===
        selectedMachineRefs.current.filter(x => x?.checked).length
  }, [state])

  useEffect(() => {
    handleGlobalAllSelectedClear()
  }, [state.page, state.pageSize, state.orderConfig])

  const fetchProfile = async () => {
    if (typeof window !== 'undefined') {
      if (window.location.search !== null) {
        const params = new URLSearchParams(window.location.search)

        if (params.has('divisionIDs') && params.get('divisionIDs')) {
          defaults.selectedDivisionIDs = params
            .get('divisionIDs')
            .split(',')
            .map(x => x * 1)
        }

        if (params.has('customerIDs') && params.get('customerIDs')) {
          defaults.selectedCustomerIDs = params
            .get('customerIDs')
            .split(',')
            .map(x => x * 1)
        }

        if (params.has('plantIDs') && params.get('plantIDs')) {
          defaults.selectedPlants = params
            .get('plantIDs')
            .split(',')
            .map(x => x * 1)
        }

        if (params.has('routeIDs') && params.get('routeIDs')) {
          defaults.selectedRoutes = params
            .get('routeIDs')
            .split(',')
            .map(x => x * 1)
        }

        if (params.has('machineNames') && params.get('machineNames')) {
          defaults.selectedMachineNames = params.get('machineNames').split(',')
        }

        if (params.has('custEquIDs') && params.get('custEquIDs')) {
          defaults.selectedCustEquIDs = params.get('custEquIDs').split(',')
        }

        if (params.has('machineNumbers') && params.get('machineNumbers')) {
          defaults.selectedMachineNumbers = params
            .get('machineNumbers')
            .split(',')
        }

        if (params.has('sampleDate') && params.get('sampleDate')) {
          defaults.selectedSampleDate = params.get('sampleDate')
        }

        if (params.has('onlyForSelectedMonth')) {
          defaults.onlyForSelectedMonth =
            params.get('onlyForSelectedMonth') === 'true'
        }
      }
    }

    setState(prev => ({
      ...prev,
    }))
  }

  const performSearch = async () => {
    fetchSamples({
      page: state.page,
      pageSize: state.pageSize,

      orderList: state.orderConfig?.map(x => x.dir) ?? [],
      orderByList: state.orderConfig?.map(x => x.field) ?? [],
      customerIDs: getSelectedCustomerIDs(
        profileContext.profile.customers,
        profileContext.profile.divisions,
        state.selectedDivisionIDs,
        state.selectedCustomerIDs
      ),
      plantIDs: state.selectedPlants,
      routeIDs: state.selectedRoutes,
      machineNumbers: state.selectedMachineNumbers,
      machineNames: state.selectedMachineNames,
      custEquIDs: state.selectedCustEquIDs,
      ...(state.onlyForSelectedMonth && state.selectedSampleDate !== null
        ? {
            scheduledMonth: moment(state.selectedSampleDate).month() + 1,
          }
        : {}),
    })
  }

  const performSearchReturnAllPages = async () => {
    return await fetchSamples({
      page: 1,
      pageSize: 10000,
      get: true,
      orderList: state.orderConfig?.map(x => x.dir) ?? [],
      orderByList: state.orderConfig?.map(x => x.field) ?? [],
      customerIDs: getSelectedCustomerIDs(
        profileContext.profile.customers,
        profileContext.profile.divisions,
        state.selectedDivisionIDs,
        state.selectedCustomerIDs
      ),
      plantIDs: state.selectedPlants,
      routeIDs: state.selectedRoutes,
      machineNumbers: state.selectedMachineNumbers,
      machineNames: state.selectedMachineNames,
      custEquIDs: state.selectedCustEquIDs,
      ...(state.onlyForSelectedMonth && state.selectedSampleDate !== null
        ? {
            scheduledMonth: moment(state.selectedSampleDate).month() + 1,
          }
        : {}),
    })
  }

  const fetchSamples = async variables => {
    let data = null
    let promise
    try {
      promise = APIService.getMachinesForLabels(variables)
      ongoingCancellablePromises.push(promise)
      data = await promise
    } catch (ex) {
      onError(ex)
      return
    } finally {
      ongoingCancellablePromises.filter(p => p != promise)
      setState(prev => ({
        ...prev,
        searching: false,
      }))
    }

    if (data.pagedMachines !== null) {
      onError(null)

      if (variables.get) return data.pagedMachines

      setState(prev => ({
        ...prev,
        data: data.pagedMachines.items,
        count: data.pagedMachines.count,
        selectedMachines: data.pagedMachines.items.map(x => x.pointID),
        loaded: true,
      }))
    }
  }

  const handleSortChange = useCallback(
    (event: GridSortChangeEvent) => {
      const isFieldRemoved = state.orderConfig.length > event.sort.length
      let isLastFieldRemoved = false

      if (isFieldRemoved) {
        const fieldRemoved = state.orderConfig.filter(
          x => !event.sort.includes(x)
        )[0]

        isLastFieldRemoved =
          state.orderConfig.indexOf(fieldRemoved) ===
          state.orderConfig.length - 1
      }

      setState(prev => ({
        ...prev,
        orderConfig: [...event.sort],
        loaded: !(event.sort.length > 0 && !isLastFieldRemoved),
      }))
    },
    [state.orderConfig]
  )

  const handlePageChange = event => {
    setState(prev => ({
      ...prev,
      page: event.page.skip / state.pageSize + 1,
      pageSize: event.page.take,
      loaded: false,
    }))
  }

  const handlePageChangeMobile = (event, newPage) => {
    setState(prev => ({ ...prev, page: newPage + 1, loaded: false }))
  }

  const handlePageSizeChangeMobile = event => {
    setState(prev => ({
      ...prev,
      page: 1,
      pageSize: parseInt(event.target.value, 10),
      loaded: false,
    }))
  }

  const handleSampleDateChange = value => {
    setState(prev => ({ ...prev, selectedSampleDate: value }))
  }

  const handleOnlyForSelectedMonthChange = event => {
    setState(prev => ({
      ...prev,
      onlyForSelectedMonth: event?.target?.checked,
    }))
  }

  const handlePrintFormatChange = value => {
    setState(prev => ({
      ...prev,
      selectedPrintFormat: value?.value,
      selectedPrintOptions: printOptions[0].value,
    }))
  }

  const handlePrintOptionsChange = value => {
    setState(prev => ({ ...prev, selectedPrintOptions: value?.value }))
  }

  const handleSkipChange = event => {
    let value = Number.parseInt(event.target.value)

    value = Number.isNaN(value) ? 0 : value > 9 ? 9 : value < 0 ? 0 : value

    selectedSkipRef.current = value
  }

  const handlePageBreakChange = value => {
    setState(prev => ({ ...prev, selectedPageBreak: value?.value }))
  }

  const handleSelectAllChange = useCallback(event => {
    document.querySelector('.select-all').checked = event.target.checked
    globalAllSelectedRef.current = event.target.checked

    const selectionList = document.querySelectorAll(
      ".k-grid-container input[type='checkbox'], .mobile-cards input[type='checkbox']"
    )

    selectionList.forEach((item, index) => {
      item.checked = event.target.checked
      selectedMachineRefs.current[index].checked = event.target.checked
    })

    setState(prev => ({
      ...prev,
      showGlobalSelectionBanner: event.target.checked,
      globalAllSelected: false,
    }))
  }, [])

  const handleSelectedChange = useCallback(() => {
    const allSelected =
      selectedMachineRefs.current.length ===
      selectedMachineRefs.current.filter(x => x?.checked).length
    setState(prev => ({
      ...prev,
      showGlobalSelectionBanner: allSelected,
      globalAllSelected: false,
    }))
  }, [])

  const handleExportFormatChange = async (event, format) => {
    let data = null
    let promise
    try {
      promise = APIService.getMachinesForLabels({
        page: 1,
        pageSize: 100000,
        orderList: state.orderConfig?.map(x => x.dir) ?? [],
        orderByList: state.orderConfig?.map(x => x.field) ?? [],
        customerIDs: getSelectedCustomerIDs(
          profileContext.profile.customers,
          profileContext.profile.divisions,
          state.selectedDivisionIDs,
          state.selectedCustomerIDs
        ),
        plantIDs: state.selectedPlants,
        routeIDs: state.selectedRoutes,
        machineNumbers: state.selectedMachineNumbers,
        machineNames: state.selectedMachineNames,
        custEquIDs: state.selectedCustEquIDs,
        scheduledMonth:
          state.onlyForSelectedMonth && state.selectedSampleDate !== null
            ? moment(state.selectedSampleDate).month() + 1
            : null,
      })

      ongoingCancellablePromises.push(promise)
      data = await promise
    } catch (ex) {
      onError(ex)
      return
    } finally {
      ongoingCancellablePromises.filter(p => p != promise)
    }

    const formatted = tableFormatter(
      data.pagedMachines.items,
      profileContext.dependentData.userDetails.isDivisionUser
    )
    exportData('standard-sample-labels', formatted, format.key)
  }

  useEffect(() => {
    if (!state.showViewPrintLabels) return

    const viewPrintLabels = async () => {
      let machinesToPrint: TAPIMachine[]

      if (state.globalAllSelected) {
        machinesToPrint = (await performSearchReturnAllPages()).items
      } else {
        machinesToPrint = state.data.filter(y =>
          selectedMachineRefs.current.find(
            x => !!x?.checked && Number(x?.id) === y.pointID
          )
        )
      }

      if (!machinesToPrint.some(m => m)) {
        onError(new Error('Please select at least one machine'))
        return
      }

      if (
        !(
          state.selectedPrintFormat === 'dymo' ||
          state.selectedPrintFormat === 'avery'
        )
      ) {
        onError(new Error('Unsupported print format provided'))
      }

      // SECTION Create query
      let today = new Date()
      today = new Date(Date.now() + today.getTimezoneOffset())

      const printLabelQuery = {
        labelType:
          state.selectedPrintFormat === 'avery'
            ? LabelType.Avery
            : LabelType.Dymo,
        numberOfLabels:
          state.selectedPrintOptions === printOptions[1].value ? 10 : 1,
        omitDate:
          state.selectedPrintOptions === printOptions[2].value ||
          state.omitFullDate,
        onlyCurrentMonth: state.omitDay,
        pageBreak:
          state.selectedPageBreak === pageBreakOptions[1].value
            ? PageBreakType.Plant
            : state.selectedPageBreak === pageBreakOptions[2].value
            ? PageBreakType.Route
            : PageBreakType.None,
        sampleDate: state.selectedSampleDate ?? today,
        skip: selectedSkipRef.current || 0,
        pointIDs: machinesToPrint.map(machine => machine.pointID),
      } as PrintLabelQuery

      // !SECTION
      let promise
      try {
        promise = APIService.generateMachineLabelBatch(printLabelQuery)

        ongoingCancellablePromises.push(promise)
        const batchID = await promise

        const reportParams: IReportParametersLabelsSheet = {
          batchID,
          isAvery: printLabelQuery.labelType === LabelType.Avery,
        }

        const encodedReportParams = encodeQueryParams(
          reportViewerQueryParamConfig,
          {
            dbg: false,
            r: reportParams.isAvery ? 'labelsSheet' : 'labelsDymo',
            ...compactReportParams(reportParams),
          }
        )

        const reportViewerURI = `/reports/viewer?${stringify(
          encodedReportParams
        )}`
        if (window) {
          window.open(reportViewerURI, '_blank', 'noopener,noreferrer')
        } else {
          navigate(reportViewerURI)
        }
      } catch (ex) {
        onError(ex)
      } finally {
        ongoingCancellablePromises.filter(p => p != promise)
      }
    }

    viewPrintLabels()
    setState(prev => ({
      ...prev,
      showViewPrintLabels: false,
    }))
  }, [
    navigate,
    onError,
    performSearchReturnAllPages,
    state,
    state.data,
    state.globalAllSelected,
    state.omitDay,
    state.omitFullDate,
    state.selectedPageBreak,
    state.selectedPrintFormat,
    state.selectedPrintOptions,
    state.selectedSampleDate,
    state.showViewPrintLabels,
  ])

  const mapFilterDataToState = (data: TAccordionFilterValues) => {
    const mappedParameters = mapToPageParameters(data)

    setState(prev => ({
      ...prev,
      ...mappedParameters,
    }))
  }

  const handleViewPrintLabels = async (
    accordionData: TAccordionFilterValues
  ) => {
    const mappedParameters = mapToPageParameters(accordionData)

    setState(prev => ({
      ...prev,
      ...mappedParameters,
      showViewPrintLabels: true,
    }))
  }

  const handleFilterSubmission = (data: TAccordionFilterValues) => {
    const mappedParameters = mapToPageParameters(data)

    setState(prev => ({
      ...prev,
      ...mappedParameters,
      page: 1,
      searching: true,
      showGlobalSelectionBanner: false,
      globalAllSelected: false,
    }))
  }

  const handleResetClick = useCallback(() => {
    setState(prev => ({
      ...prev,
      ...defaults,
      count: 0,
      loaded: false,
      searching: true,
      selectedCustomerIDs:
        profileContext.dependentData.filters.initialFilterState.customers.map(
          c => c.custID
        ),
      selectedDivisionIDs:
        profileContext.dependentData.filters.initialFilterState.divisions.map(
          d => d.iD
        ),
      performedInitialLoad: false,
      showGlobalSelectionBanner: false,
      globalAllSelected: false,
    }))
  }, [
    profileContext.dependentData.filters.initialFilterState.customers,
    profileContext.dependentData.filters.initialFilterState.divisions,
  ])

  const handleGlobalAllSelectedClear = () => {
    globalAllSelectedRef.current = false

    const selectionList = document.querySelectorAll(
      ".k-grid-container input[type='checkbox'], .mobile-cards input[type='checkbox']"
    )
    selectionList.forEach((item, index) => {
      item.checked = false
      selectedMachineRefs.current[index].checked = false
    })

    setState(prev => ({
      ...prev,
      showGlobalSelectionBanner: false,
      globalAllSelected: false,
    }))
  }

  const handleGlobalAllSelectedClick = () => {
    setState(prev => ({
      ...prev,
      globalAllSelected: true,
    }))
  }

  const showUPSOption: boolean =
    profileContext.dependentData.userDetails.isUPSUser

  const theme = createTheme(
    {
      overrides: {
        MuiGrid: {
          root: {
            [coreTheme.breakpoints.up('sm')]: {
              justifyContent: 'flex-end',
              alignItems: 'center',
            },
            [coreTheme.breakpoints.down('sm')]: {
              justifyContent: 'center',
              alignItems: 'flex-start',
            },
          },
        },
        MuiButton: {
          root: {
            width: '225px',
          },
        },
      },
    },
    coreTheme
  )

  const handleViewReportButtonClick = () => {
    const { selectedDivisionIDs, selectedCustomerIDs, orderConfig } = state

    const selectedPointIDs = selectedMachineRefs.current
      ?.filter(p => !!p.checked)
      ?.map(input => Number(input?.id))

    const reportParams: IReportParametersLabelsList = {
      Customers: getSelectedCustomerIDs(
        profileContext.profile.customers,
        profileContext.profile.divisions,
        selectedDivisionIDs,
        selectedCustomerIDs
      ),
      machines: selectedPointIDs,
      SortColumn: orderConfig[0]?.field,
      SortDirection: orderConfig[0]?.dir,
      month: state.onlyForSelectedMonth
        ? moment(state.selectedSampleDate).month()
        : -1,
    }

    const encodedQueryParams = encodeQueryParams(reportViewerQueryParamConfig, {
      dbg: false,
      r: 'labelsList',
      ...compactReportParams(reportParams),
    })

    const url = `/reports/viewer?${stringify(encodedQueryParams)}`
    navigate(url)
  }

  return (
    <React.Fragment>
      <SEO title="Standard Sample Labels" />
      <Box mb={2}>
        <Typography variant="h1">Standard Sample Labels</Typography>
      </Box>
      {offline ? <OfflineBanner /> : <></>}
      <Grid container direction="column" xs={12} spacing={1} justify="flex-end">
        <Grid container item direction="row" justify="flex-end"></Grid>
      </Grid>
      <FilterAccordion
        pageName="Standard Sample Labels"
        defaultValues={profileContext.dependentData.filters.initialFilterState}
        onSubmit={handleFilterSubmission}
        onReset={handleResetClick}
        filters={{
          options: [OptionsFilterSet.Prefiltering],
          date: [DateFilterSet.SampleDate],
          organization: [OrganizationalFilterSet.All],
          machine: [
            MachineFilterSet.Name,
            MachineFilterSet.EquipmentIDs,
            MachineFilterSet.Number,
          ],
          lubricant: [],
          sample: [],
          miscellaneous: [
            MiscellaneousFilterSet.OmitDayOfMonth,
            MiscellaneousFilterSet.OmitFullDate,
            MiscellaneousFilterSet.LoadAllMachines,
          ],
        }}
      >
        {getFilterAccordionData => (
          <form>
            <ThemeProvider theme={theme}>
              <Grid
                container
                direction="row"
                justify="space-between"
                spacing={4}
              >
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  direction="column"
                  justify="center"
                  alignItems="flex-start"
                  spacing={2}
                  style={{ marginTop: '30px' }}
                >
                  <Grid item md={3} style={{ textAlign: 'right' }}>
                    <SelectFilter
                      id="printFormat"
                      required={true}
                      multiple={false}
                      label="Print Format"
                      htmlFor="printFormat"
                      onChange={handlePrintFormatChange}
                      options={state.printFormats}
                      defaultValue={{
                        value: state.selectedPrintFormat,
                        text: state.printFormats.find(
                          f => f.value === state.selectedPrintFormat
                        )?.text,
                      }}
                      getOptionSelected={(
                        option: { value: string; text: string },
                        value: { value: string; text: string }
                      ) => option?.value === value?.value}
                      renderValue={selected => selected?.text}
                    ></SelectFilter>
                  </Grid>
                  {state.selectedPrintFormat === 'avery' ? (
                    <React.Fragment>
                      <Grid item md={3} style={{ textAlign: 'right' }}>
                        <SelectFilter
                          id="printOptions"
                          required={false}
                          multiple={false}
                          label="Print Options"
                          htmlFor="printOptions"
                          options={state.printOptions}
                          getOptionSelected={(option, value) =>
                            option?.value.includes(value?.value)
                          }
                          defaultValue={{
                            value: state.selectedPrintOptions,
                            text: state.printOptions.find(
                              f => f.value === state.selectedPrintOptions
                            )?.text,
                          }}
                          onChange={handlePrintOptionsChange}
                          renderValue={selected => selected?.text}
                        />
                      </Grid>
                      <Grid item md={3} style={{ textAlign: 'right' }}>
                        <TextFieldFilter
                          ref={selectedSkipRef}
                          label="Skip Used Labels"
                          htmlFor="skipUsedLabels"
                          name="skipUsedLabels"
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                          required
                          style={{ width: 225 }}
                          id="skip"
                          onChange={handleSkipChange}
                          type="number"
                        />
                      </Grid>
                      <Grid item md={3} style={{ textAlign: 'right' }}>
                        <SelectFilter
                          id="pageBreak"
                          required={false}
                          multiple={false}
                          options={state.pageBreakOptions}
                          getOptionSelected={(option, value) =>
                            option?.value === value?.value
                          }
                          defaultValue={{
                            value: state.selectedPageBreak,
                            text: state.pageBreakOptions.find(
                              f => f.value === state.selectedPageBreak
                            )?.text,
                          }}
                          label="Page Break On"
                          htmlFor="pageBreakOn"
                          onChange={handlePageBreakChange}
                          renderValue={selected => selected?.text}
                        />
                      </Grid>
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  spacing={1}
                  direction="column"
                  justify="flex-end"
                  alignItems="flex-end"
                >
                  <Grid item style={{ marginBottom: '20px' }}>
                    {showUPSOption ? <UPSLink /> : null}
                  </Grid>
                  <Grid item>
                    <ExportButton
                      disabled={!state.loaded || state.data?.length <= 0}
                      onChange={handleExportFormatChange}
                    />
                  </Grid>
                  <Grid item>
                    <FormControl>
                      {/* <ThemeButton
                        disabled={
                          state.selectedPrintFormat === '' ||
                          (state.selectedPrintFormat === 'avery' &&
                            (state.selectedPrintOptions === '' ||
                              state.selectedSkip === '' ||
                              state.selectedPageBreak === ''))
                        }
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          handleViewPrintLabels(getFilterAccordionData())
                        }
                      >
                        View/Print Labels
                      </ThemeButton> */}
                      <DistributedSampleLabelsStateButton
                        disabled={
                          state.selectedPrintFormat === '' ||
                          (state.selectedPrintFormat === 'avery' &&
                            (state.selectedPrintOptions === '' ||
                              state.selectedSkip === '' ||
                              state.selectedPageBreak === ''))
                        }
                        onClick={() =>
                          handleViewPrintLabels(getFilterAccordionData())
                        }
                      >
                        View/Print Labels
                      </DistributedSampleLabelsStateButton>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl>
                      <DistributedSampleLabelsStateButton
                        onClick={handleViewReportButtonClick}
                      >
                        View/Print List
                      </DistributedSampleLabelsStateButton>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl>
                      <HelpPopupLink />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </ThemeProvider>
          </form>
        )}
      </FilterAccordion>
      <Box mt={4}>
        {!state.loaded ? (
          <Loader />
        ) : (
          <React.Fragment>
            {state.showGlobalSelectionBanner && (
              <Grid container justify="center" alignItems="center">
                <Grid
                  md={6}
                  item
                  style={{
                    padding: 15,
                    backgroundColor: 'lightgray',
                    textAlign: 'center',
                  }}
                >
                  {state.globalAllSelected ? (
                    <>
                      All <b>{state.count}</b> machines are selected.{' '}
                      <a href="#" onClick={handleGlobalAllSelectedClear}>
                        Clear selection.
                      </a>
                    </>
                  ) : (
                    <>
                      All{' '}
                      <b>
                        {state.page * state.pageSize > state.count
                          ? state.count - (state.page - 1) * state.pageSize
                          : state.pageSize}{' '}
                      </b>
                      machines on this page are selected.{' '}
                      {state.count > state.pageSize && (
                        <a href="#" onClick={handleGlobalAllSelectedClick}>
                          Select all {state.count} machines.
                        </a>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            )}
            <StandardLabelsTable
              ref={selectedMachineRefs}
              readOnly={profileContext.profile?.user?.readOnly}
              isDivision={
                profileContext.dependentData.userDetails.isDivisionUser
              }
              offline={offline}
              machines={state.data}
              page={state.page}
              pageSize={state.pageSize}
              count={state.count}
              orderConfig={state.orderConfig}
              onSortChange={handleSortChange}
              onSelectAllChange={handleSelectAllChange}
              onSelectedChange={handleSelectedChange}
              onPageChange={handlePageChange}
              onPageChangeMobile={handlePageChangeMobile}
              onPageSizeChangeMobile={handlePageSizeChangeMobile}
            />
          </React.Fragment>
        )}
      </Box>

      <div id="label-image-container" style={{ display: 'none' }}>
        <img
          src="/static/label-images/logo.jpg"
          id="label-logo"
          alt="Label Logo"
        />
      </div>
      <div id="label-container" style={{ display: 'none' }}>
        <canvas id="label-barcode" />
      </div>
    </React.Fragment>
  )
}

export default function Page() {
  return (
    <StandardSampleLabelsDistributedStateContextProvider>
      <AppLayout tab="program-management">
        <StandardSampleLabels />
      </AppLayout>
    </StandardSampleLabelsDistributedStateContextProvider>
  )
}
