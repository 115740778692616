import React from 'react'

interface TFormFieldError {
  id: string
  message: string
}

interface TFormFieldProps {
  children: JSX.Element
  id: string
  formErrors: TFormFieldError[]
  errorMessageStyle?: React.CSSProperties
  fullWidth?: boolean
}

export const FormField = ({
  children,
  id,
  formErrors,
  errorMessageStyle,
  fullWidth,
}: TFormFieldProps) => {
  const errorMessage = formErrors.find(x => x.id === id)?.message

  return (
    <div style={fullWidth && { width: '100%', height: '100%' }}>
      {children}
      {errorMessage && (
        <p style={{ ...errorMessageStyle, color: 'red' }}>{errorMessage}</p>
      )}
    </div>
  )
}
