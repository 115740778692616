import PageStyles from '../../page-styles.module.css'

const NotLoggedInForbiddenHeader = () => {
  return (
    <>
      <div>
        <header className={PageStyles.MainTitle}>Unauthorized</header>
        <sub className={PageStyles.SubTitle}>
          <span className={PageStyles.BlockSpan}>
            You must be logged in as an administrative user to access this page.
          </span>
        </sub>
      </div>
    </>
  )
}

export default NotLoggedInForbiddenHeader
