import React from 'react'
import { Button } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

import coreTheme from '../theme'

const theme = createTheme(
  {
    overrides: {
      MuiButton: {
        root: {
          [coreTheme.breakpoints.down('sm')]: {
            width: '150px',
            alignSelf: 'flex-end',
          },
          [coreTheme.breakpoints.up('sm')]: {
            width: '150px',
          },
        },
      },
    },
  },
  coreTheme
)

const GenericButton: React.FC<any> = props => {
  const { children } = props

  return (
    <ThemeProvider theme={theme}>
      <Button {...props} variant="contained" color="secondary">
        {children}
      </Button>
    </ThemeProvider>
  )
}

export default GenericButton
