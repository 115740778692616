import React, { ReactNode, useMemo } from 'react'

import IncompatibleBrowser from 'components/Errors/IncompatibleBrowser'

type CompatibilityTest = {
  name: string
  test: () => boolean
}
const tests: CompatibilityTest[] = [
  {
    name: 'url',
    test: () => {
      let url: URL
      try {
        // have to actually try use it, because Safari defines a dud constructor
        url = new URL('http://modernizr.com/')
        return url.href === 'http://modernizr.com/'
      } catch (e) {
        return false
      }
    },
  },
  {
    name: 'url search params',
    test: () => typeof window !== 'undefined' && 'URLSearchParams' in window,
  },
  {
    name: 'abort controller',
    test: () => typeof window !== 'undefined' && 'AbortController' in window,
  },
]

export const WithCompatibilityCheck = (props: { children: ReactNode }) => {
  const { children } = props

  const passed = useMemo<boolean>(() => {
    if (typeof window === 'undefined') {
      return true
    }
    return tests.every(t => {
      const testPassed = t.test()
      if (!testPassed) {
        console.warn(`CompatibilityCheck: Test "${t.name}" failed.`)
      }
      return testPassed
    })
  }, [])

  // add ! to work appropriately
  if (!passed) {
    return <IncompatibleBrowser />
  }
  return <>{children}</>
}

export default WithCompatibilityCheck
