import { CSSProperties, useRef } from 'react'

import styles from './UPSLink.module.css'

type TProps = {
  jsStyle?: CSSProperties
  swap?: boolean
}

const UPSLink = ({ jsStyle, swap }: TProps) => {
  const upsLogoPath = '/static/images/ups.png'
  const buttonRef = useRef<HTMLButtonElement>()
  if (!swap) {
    return (
      <div
        className={styles.UPSLinkBody}
        style={jsStyle}
        onClick={() => buttonRef.current.click()}
      >
        <img src={upsLogoPath} className={styles.logo}></img>
        <div className={styles.form}>
          <form
            method="POST"
            action="https://row.ups.com/GuestAccess/Guest/Access"
            target="_blank"
          >
            <input type="hidden" name="Company" value="Q00="></input>
            <span className={styles.label}>Print Shipping Labels</span>
            <button
              type="submit"
              ref={buttonRef}
              style={{ visibility: 'hidden', height: '0px', width: '0px' }}
            ></button>
          </form>
        </div>
      </div>
    )
  } else {
    return (
      <div
        className={styles.UPSLinkBody}
        style={jsStyle}
        onClick={() => buttonRef.current.click()}
      >
        <div className={styles.form}>
          <form
            method="POST"
            action="https://row.ups.com/GuestAccess/Guest/Access"
            target="_blank"
          >
            <input type="hidden" name="Company" value="Q00="></input>
            <span className={styles.label}>Print Shipping Labels</span>
            <button
              type="submit"
              ref={buttonRef}
              style={{ visibility: 'hidden', height: '0px', width: '0px' }}
            ></button>
          </form>
        </div>
        <img src={upsLogoPath} className={styles.logo}></img>
      </div>
    )
  }
}

export default UPSLink
