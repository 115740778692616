import React from 'react'
import { Button as MuiButton } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

import coreTheme from '../theme'

const theme = createTheme(
  {
    overrides: {
      MuiButton: {
        root: {
          [coreTheme.breakpoints.down('sm')]: {
            width: '200px',
          },
          [coreTheme.breakpoints.up('sm')]: {
            width: '225px',
          },
        },
      },
    },
  },
  coreTheme
)

// const ThemeButton = (props) => {
//   return (
//     <ThemeProvider theme={theme}>
//       <MuiButton {...props} />
//     </ThemeProvider>
//   );
// };

const ThemeButton = props => {
  const { buttonRef, ...rest } = props

  return (
    <div ref={buttonRef}>
      <ThemeProvider theme={theme}>
        <MuiButton {...rest} />
      </ThemeProvider>
    </div>
  )
}

export default ThemeButton
