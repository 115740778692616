import React, { useState } from 'react'
import { createStyles, Hidden, makeStyles } from '@material-ui/core'
import { orderBy } from '@progress/kendo-data-query'
import { GridColumn as Column } from '@progress/kendo-react-grid'

import ConditionElement from 'components/ConditionElement'
import TableGrid from 'components/TableGrid'
import {
  lubeConditionKeys,
  machineConditionKeys,
} from 'services/lookup-service'

import {
  RenderLubeConditionSummaryLink,
  RenderMachineConditionSummaryLink,
  RenderSamplesSummaryLink,
} from '../KendoUIGridRenders'

import MobileCard from './MobileCard'
import MobileCardTotals from './MobileCardTotals'

const useTableStyles = makeStyles(() =>
  createStyles({
    table: {
      '& .k-grid-header tr:nth-child(1) .k-header': {
        padding: 5,
        margin: 0,
        verticalAlign: 'middle',
        backgroundColor: '#A8AFBA',
        color: 'white',
        height: '40px',
        '& .k-cell-inner': {
          margin: 0,
        },
        '& .k-link': {
          margin: 0,
          padding: 0,
          display: 'block',
          '& span': {
            whiteSpace: 'initial',
          },
        },
        '& .MuiSvgIcon-root': {
          color: 'black',
          cursor: 'pointer',
        },
      },
      '& .k-grid-header tr:nth-child(1) .k-header:nth-last-child(-n+3)': {
        '& .k-link': {
          textAlign: 'center',
        },
      },
      '& .k-grid-header tr:nth-child(2) .k-header': {
        backgroundColor: '#A8AFBA',
        textAlign: 'center',
        '& .k-link ': {
          display: 'flex',
          '& .k-icon': {
            placeSelf: 'center',
          },
          '& .k-sort-order': {
            placeSelf: 'center',
          },
        },
      },
      '& .k-grid-container td': {
        padding: 5,
        margin: 0,
      },
      '& .k-grid-container td:nth-last-child(-n+9)': {
        textAlign: 'center',
      },
      '& .k-grid-footer tr': {
        backgroundColor: 'lightgray',
        color: 'black',
      },
      '& .k-grid-footer tr td:nth-last-child(-n+10)': {
        padding: 0,
      },
      '& .k-grid-footer tr td:nth-last-child(-n+9)': {
        textAlign: 'center',
      },
      '& .k-grid-header thead > tr:not(:only-child) > th': {
        borderColor: 'transparent',
      },
      marginTop: 15,
    },
  })
)

export type TProps = {
  data: any[]
  startDate: Date
  endDate: Date
  onOrderChanged: (orderConfig: any[]) => void
}

export const ConditionSummary: React.FC<TProps> = ({
  data,
  startDate,
  endDate,
  onOrderChanged,
}: TProps) => {
  const tableClasses = useTableStyles()

  const showDivisions = data.some(d => d.plant.customer.division)

  const [orderConfig, setOrderConfig] = useState([])

  const [dataCopy] = useState(
    data.map(x => ({
      ...x,
      plantName: x.plant.plantName,
      custName: x.plant.customer.custName,
      custId: x.plant.customer.custID,
      plantId: x.plant.plantID,
      division: x.plant.customer.division?.name,
    }))
  )

  const [dataTotals] = useState({
    samples: data.map(x => x.samples).reduce((prev, cur) => prev + cur, 0),
    machineNormal: data
      .map(x => x.machineNormal)
      .reduce((prev, cur) => prev + cur, 0),
    machineMarginal: data
      .map(x => x.machineMarginal)
      .reduce((prev, cur) => prev + cur, 0),
    machineCritical: data
      .map(x => x.machineCritical)
      .reduce((prev, cur) => prev + cur, 0),
    machineUnknown: data
      .map(x => x.machineUnknown)
      .reduce((prev, cur) => prev + cur, 0),
    lubeNormal: data
      .map(x => x.lubeNormal)
      .reduce((prev, cur) => prev + cur, 0),
    lubeMarginal: data
      .map(x => x.lubeMarginal)
      .reduce((prev, cur) => prev + cur, 0),
    lubeCritical: data
      .map(x => x.lubeCritical)
      .reduce((prev, cur) => prev + cur, 0),
    lubeUnknown: data
      .map(x => x.lubeUnknown)
      .reduce((prev, cur) => prev + cur, 0),
  })

  const renderTotalCell = (total: Number) => {
    return <td>{total}</td>
  }

  const renderMachineConditionHeader = (condition: number) => {
    return <ConditionElement condition={condition} />
  }

  const renderLubeConditionHeader = (condition: number) => {
    return <ConditionElement condition={condition} />
  }

  const handleSortChange = event => {
    setOrderConfig([...event.sort])
    onOrderChanged(event.sort)
  }

  const renderSortableMachineConditionHeaderCell = (
    props,
    machineCondition
  ) => (
    <a className="k-link condition-image" href="#" onClick={props.onClick}>
      {renderMachineConditionHeader(machineCondition)}
      {props.children}
    </a>
  )

  const renderSortableLubeConditionHeaderCell = (props, lubeCondition) => (
    <a className="k-link" href="#" onClick={props.onClick}>
      {renderLubeConditionHeader(lubeCondition)}
      {props.children}
    </a>
  )

  return (
    <>
      <Hidden mdUp>
        {!dataCopy?.length && (
          <p style={{ textAlign: 'center' }}>No records available</p>
        )}
        {dataCopy.map((item, index) => (
          <MobileCard
            key={`mobile-card-${index}`}
            item={item}
            startDate={startDate}
            endDate={endDate}
          />
        ))}
        {dataCopy.length > 0 && <MobileCardTotals item={dataTotals} />}
      </Hidden>

      <Hidden smDown>
        <TableGrid
          className={tableClasses.table}
          data={orderBy(dataCopy, orderConfig)}
          sort={orderConfig}
          onSortChange={handleSortChange}
          pageable={false}
        >
          {showDivisions ? <Column field="division" title="Division" /> : null}
          <Column field="custName" title="Customer" />
          <Column
            field="plantName"
            title="Plant"
            footerCell={() => <td>Totals</td>}
          />
          <Column
            field="samples"
            title="Samples"
            cell={props => (
              <RenderSamplesSummaryLink
                {...props}
                startDate={startDate}
                endDate={endDate}
              />
            )}
            footerCell={() => renderTotalCell(dataTotals.samples)}
          />

          <Column title="Machine Condition">
            <Column
              field="machineNormal"
              title="Normal"
              cell={props => (
                <RenderMachineConditionSummaryLink
                  {...props}
                  startDate={startDate}
                  endDate={endDate}
                  machineCondition={machineConditionKeys.normal}
                />
              )}
              headerCell={props =>
                renderSortableMachineConditionHeaderCell(
                  props,
                  machineConditionKeys.normal
                )
              }
              footerCell={() => renderTotalCell(dataTotals.machineNormal)}
            />
            <Column
              field="machineMarginal"
              title="Marginal"
              cell={props => (
                <RenderMachineConditionSummaryLink
                  {...props}
                  startDate={startDate}
                  endDate={endDate}
                  machineCondition={machineConditionKeys.marginal}
                />
              )}
              headerCell={props =>
                renderSortableMachineConditionHeaderCell(
                  props,
                  machineConditionKeys.marginal
                )
              }
              footerCell={() => renderTotalCell(dataTotals.machineMarginal)}
            />
            <Column
              field="machineCritical"
              title="Critical"
              cell={props => (
                <RenderMachineConditionSummaryLink
                  {...props}
                  startDate={startDate}
                  endDate={endDate}
                  machineCondition={machineConditionKeys.critical}
                />
              )}
              headerCell={props =>
                renderSortableMachineConditionHeaderCell(
                  props,
                  machineConditionKeys.critical
                )
              }
              footerCell={() => renderTotalCell(dataTotals.machineCritical)}
            />
            <Column
              field="machineUnknown"
              title="Unknown"
              cell={props => (
                <RenderMachineConditionSummaryLink
                  {...props}
                  startDate={startDate}
                  endDate={endDate}
                  machineCondition={machineConditionKeys.unknown}
                />
              )}
              headerCell={props =>
                renderSortableMachineConditionHeaderCell(
                  props,
                  machineConditionKeys.unknown
                )
              }
              footerCell={() => renderTotalCell(dataTotals.machineUnknown)}
            />
          </Column>
          <Column title="Lubricant Condition">
            <Column
              field="lubeNormal"
              title="Normal"
              cell={props => (
                <RenderLubeConditionSummaryLink
                  {...props}
                  startDate={startDate}
                  endDate={endDate}
                  lubeCondition={lubeConditionKeys.normal}
                />
              )}
              headerCell={props =>
                renderSortableLubeConditionHeaderCell(
                  props,
                  lubeConditionKeys.normal
                )
              }
              footerCell={() => renderTotalCell(dataTotals.lubeNormal)}
            />
            <Column
              field="lubeMarginal"
              title="Marginal"
              cell={props => (
                <RenderLubeConditionSummaryLink
                  {...props}
                  startDate={startDate}
                  endDate={endDate}
                  lubeCondition={lubeConditionKeys.marginal}
                />
              )}
              headerCell={props =>
                renderSortableLubeConditionHeaderCell(
                  props,
                  lubeConditionKeys.marginal
                )
              }
              footerCell={() => renderTotalCell(dataTotals.lubeMarginal)}
            />
            <Column
              field="lubeCritical"
              title="Critical"
              cell={props => (
                <RenderLubeConditionSummaryLink
                  {...props}
                  startDate={startDate}
                  endDate={endDate}
                  lubeCondition={lubeConditionKeys.critical}
                />
              )}
              headerCell={props =>
                renderSortableLubeConditionHeaderCell(
                  props,
                  lubeConditionKeys.critical
                )
              }
              footerCell={() => renderTotalCell(dataTotals.lubeCritical)}
            />
            <Column
              field="lubeUnknown"
              title="Unknown"
              cell={props => (
                <RenderLubeConditionSummaryLink
                  {...props}
                  startDate={startDate}
                  endDate={endDate}
                  lubeCondition={lubeConditionKeys.unknown}
                />
              )}
              headerCell={props =>
                renderSortableLubeConditionHeaderCell(
                  props,
                  lubeConditionKeys.unknown
                )
              }
              footerCell={() => renderTotalCell(dataTotals.lubeUnknown)}
            />
          </Column>
        </TableGrid>
      </Hidden>
    </>
  )
}
