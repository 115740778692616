import React, { useState } from 'react'
import { Hidden } from '@material-ui/core'
import { SortDescriptor } from '@progress/kendo-data-query'
import { orderBy } from '@progress/kendo-react-data-tools'
import {
  GridColumn as Column,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'

import TableGrid from 'components/TableGrid'

import {
  RenderDate,
  RenderEmptyIfZero,
  RenderLabNo,
  RenderMachineName,
} from '../KendoUIGridRenders'

import MobileCard from './MobileCard'

type OwnProps = {
  recurringExceptions: any[]
  showDivisions?: boolean
  showOthers?: boolean
  showCustomers?: boolean
  onOrderChanged?: (_a: any[]) => void,
  loaded: boolean
}

const defaultSort: SortDescriptor = { field: 'lastSampleDate', dir: 'desc' }

export const RecurringExceptionsTable: React.FC<OwnProps> = React.memo((props) => {

  const {
    recurringExceptions,
    showDivisions,
    showOthers,
    showCustomers,
    onOrderChanged
  } = props

  const [recurringExceptionsCopy, setRecurringExceptionsCopy] = useState(
    orderBy(recurringExceptions, [defaultSort], '')
  )
  const [sort, setSort] = React.useState<Array<SortDescriptor>>([defaultSort])

  const handleOnSortChange = (e: GridSortChangeEvent) => {
    setSort(e.sort)
    setRecurringExceptionsCopy(orderBy(recurringExceptionsCopy, e.sort, ''))
  }

  return (
    <>
      <Hidden mdUp>
        {!recurringExceptionsCopy?.length && (
          <p style={{ textAlign: 'center' }}>No records available</p>
        )}
        {recurringExceptionsCopy?.map(x => (
          <MobileCard
            key={x.labID}
            recurringException={x}
            showDivisions={showDivisions}
          />
        ))}
      </Hidden>
      <Hidden smDown>
        <TableGrid
          clearSortingButtonId="recurring_exceptions-clear_sorting_button"
          data={recurringExceptionsCopy}
          pageable={false}
          pageSize={100}
          onSortChange={handleOnSortChange}
          sort={sort}
        >
          {showDivisions && (
            <Column field="divisionName" title="Division Name" />
          )}
          {showCustomers && <Column field="customerName" title="Customer" />}
          <Column field="plantName" title="Plant Name" />
          <Column field="machineNumber" title="Mach No." />
          <Column
            field="machineName"
            title="Machine Name"
            cell={props => <RenderMachineName {...props} />}
          />
          <Column field="custEquID" title="Equipment ID" />
          <Column
            field="lastSampleDate"
            title="Last Sample Date"
            cell={props => <RenderDate {...props} />}
          />
          <Column
            field="labID"
            title="Test Lab No"
            cell={props => <RenderLabNo {...props} />}
          />
          <Column field="tests" title="Tests" />
          <Column field="testPackages" title="Test Packages" />
          <Column field="lubricant" title="Lubricant" />
          <Column
            field="wearMetalCount"
            title="Wear Metal"
            cell={props => <RenderEmptyIfZero {...props} />}
          />
          <Column
            field="ferrousCount"
            title="Ferrous Wear"
            cell={props => <RenderEmptyIfZero {...props} />}
          />
          <Column
            field="particleCountCount"
            title="Particle Count"
            cell={props => <RenderEmptyIfZero {...props} />}
          />
          <Column
            field="waterCount"
            title="Water"
            cell={props => <RenderEmptyIfZero {...props} />}
          />
          <Column
            field="viscosityCount"
            title="Viscosity"
            cell={props => <RenderEmptyIfZero {...props} />}
          />
          <Column
            field="degredationCount"
            title="Degradation"
            cell={props => <RenderEmptyIfZero {...props} />}
          />
          {showOthers && (
            <Column
              field="otherCount"
              title="Other"
              cell={props => <RenderEmptyIfZero {...props} />}
            />
          )}
        </TableGrid>
      </Hidden>
    </>
  )
}, (p, n) => p.loaded === n.loaded && p.recurringExceptions?.length === n.recurringExceptions?.length);
