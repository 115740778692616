import React, { useContext, useState } from 'react'

type TDistributedStateSetter = {
  setDistributedState: (
    _callback: (previous: TDistributedStateContext) => TDistributedStateContext
  ) => void
}
export type TDistributedStateContext = {
  selectedPointIDs: number[]
}

const DistributedStateContext = React.createContext<
  TDistributedStateContext & TDistributedStateSetter
>({ selectedPointIDs: [], setDistributedState: () => {} })

export const useStandardSampleLabelsDistributedContext = () =>
  useContext(DistributedStateContext)

export const DistributedStateContextProvider = props => {
  const [state, setState] = useState<TDistributedStateContext>({
    selectedPointIDs: [],
  })

  return (
    <DistributedStateContext.Provider
      value={{ ...state, setDistributedState: setState }}
    >
      {props.children}
    </DistributedStateContext.Provider>
  )
}
