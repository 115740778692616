import { InformationIcon } from 'components/InformationIcon'
import React, { CSSProperties } from 'react'
import * as Styles from './index.module.css'

type TToolTipInformation = {
  title?: string
  text?: string
}
type TScoreCardProps = {
  toolTip?: TToolTipInformation
  style?: CSSProperties
}
export const ScoreCard: React.FC<TScoreCardProps> = props => {
  const [showTooltip, setShowTooltip] = React.useState(false)

  return (
    <>
      <div
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        className={Styles.CardBody}
        style={props.style}
      >
        <div className={Styles.ToolTipBar}>
          <InformationIcon
            className={Styles.ToolTipIcon}
            style={{
              display: props.toolTip && showTooltip ? undefined : 'none',
            }}
          >
            <h5>{props.toolTip.title}</h5>
            <hr />
            <p>{props.toolTip.text}</p>
          </InformationIcon>
        </div>
        {props.children}
      </div>
    </>
  )
}
