import {
  Chart,
  ChartTitle,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartCategoryAxis,
  ChartCategoryAxisTitle,
  ChartCategoryAxisItem,
  ChartAxisDefaults,
} from '@progress/kendo-react-charts'
import React, { CSSProperties } from 'react'
import { formatNumber } from '@telerik/kendo-intl'
import { Button, Grid, Modal, TextField } from '@material-ui/core'

export type TStackChartSerie = {
  name: string
  color: String
  values: number[]
}

export type TStackChartProps = {
  dataCategories: string[]
  dataSeries: TStackChartSerie[]
  title: string
  showLegend?: boolean
  labelsFormat?: string
}

export const StackChart: React.FC<TStackChartProps> = (
  props: TStackChartProps
) => {
  const { title, dataSeries, dataCategories, showLegend, labelsFormat } = props

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      style={{ width: '100%', paddingBottom: '20px' }}
    >
      {dataSeries.some(x => x.values.some(y => y)) ? (
        <Chart style={{ width: '100%' }}>
          <ChartAxisDefaults
            labels={{
              format: labelsFormat,
              rotation: -45,
              step: 2,
              visible: false,
            }}
          />
          <ChartTooltip shared={true} format={'c0'} />
          <ChartLegend position="bottom" visible={showLegend ?? false} />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              labels={{ skip: 2, step: 2, visible: false }}
              categories={dataCategories}
            ></ChartCategoryAxisItem>
          </ChartCategoryAxis>
          <ChartSeries>
            {dataSeries.map((serie, index) => (
              <ChartSeriesItem
                type="bar"
                name={serie.name}
                data={serie.values}
                color={serie.color}
                labels={{
                  visible: true,
                  position: 'insideEnd',
                  format: labelsFormat,
                  content: props =>
                    formatNumber(props.stackValue, labelsFormat),
                  background: 'transparent',
                }}
              />
            ))}
          </ChartSeries>
        </Chart>
      ) : (
        <strong>No Data Available</strong>
      )}
    </Grid>
  )
}
