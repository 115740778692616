import { TEncrypted, TListType } from 'types/api'
import { DatalessQuery, Query } from './Query'
import { serialize } from 'utils/api-utilities'

export class AllFluidManufacturersEncryptedQuery extends Query<
  TEncrypted,
  TListType[]
> {
  public get Url(): string {
    return `${
      process.env.REACT_APP_DS_EXPERIENCE_API_URL
    }/v1/Fluid/Manufacturers/All/Encrypted${serialize(this.Data)}`
  }
}
