import React from 'react'
import { SortDescriptor } from '@progress/kendo-data-query'
import { orderBy } from '@progress/kendo-react-data-tools'
import {
  GridColumn,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'

import { TOrder } from 'types/api'
import TableGrid from 'components/TableGrid'

import { RenderDate } from '../KendoUIGridRenders'
import { Hidden, TablePagination } from '@material-ui/core'
import MobileCard from './MobileCard'

interface OwnProps {
  salesOrders: TOrder[]
  offline?: boolean
  orderConfig: SortDescriptor[]
  page: number
  pageSize: number
  count: number
  onPageChange?: (_event: GridPageChangeEvent) => void
  onSortChange?: (_event: GridSortChangeEvent) => void
  onPageChangeMobile: (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    _page: number
  ) => void
  onPageSizeChangeMobile?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >
}

const CustomerOrdersTable: React.FC<OwnProps> = ({
  salesOrders,
  offline,
  orderConfig,
  page,
  pageSize,
  count,
  onPageChange,
  onSortChange,
  onPageChangeMobile,
  onPageSizeChangeMobile
}: OwnProps) => {
  const renderStatus = (salesOrders: TOrder) => (
    <td
      style={{
        color:
          salesOrders.status === 'Open'
            ? 'green'
            : salesOrders.status === 'Pending'
              ? 'darkgoldenrod'
              : 'red',
      }}
    >
      {salesOrders.status}
    </td>
  )
  const renderCustomers = (salesOrders: TOrder) => <td>{salesOrders.customers.join(', ')}</td>
  const renderPlants = (salesOrders: TOrder) => <td>{salesOrders.plants.join(', ')}</td>
  const renderCurrency = (value: number) => (
    <td style={{ textAlign: 'center' }}>
      {Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(Number(value.toFixed(2)))}
    </td>
  )

  return (
    <>
      <Hidden mdUp>
        {!salesOrders?.length && (
          <p style={{ textAlign: 'center' }}>No records available</p>
        )}
        {salesOrders.map((salesOrder, index) => (
          <MobileCard
            key={`mobile-card-${index}`}
            salesOrder={salesOrder}
          />
        ))}
        {!offline ? (
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100, 250, 500]}
            component="div"
            count={count}
            rowsPerPage={pageSize}
            page={page > 0 ? page - 1 : page}
            onChangePage={onPageChangeMobile}
            onChangeRowsPerPage={onPageSizeChangeMobile}
          />
        ) : (
          ''
        )}
      </Hidden>
      <Hidden smDown>
        <TableGrid
          clearSortingButtonId="customer_orders_table-clear_sorting_button"
          data={orderBy(salesOrders, orderConfig, '')}
          offline={offline}
          sort={orderConfig}
          pageable={false}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
        >
          <GridColumn title="PO #" field="purchaseOrderNumber" />
          <GridColumn
            title="Status"
            field="status"
            cell={({ dataItem }) => renderStatus(dataItem)}
          />
          <GridColumn
            title="Customer Name"
            field="customers"
            cell={({ dataItem }) => renderCustomers(dataItem)}
          />
          <GridColumn
            title="Location"
            field="plants"
            cell={({ dataItem }) => renderPlants(dataItem)}
          />
          <GridColumn
            title="Expiration Date"
            field="expirationDate"
            cell={props => <RenderDate {...props} />}
          />
          <GridColumn
            title="Initial Balance"
            field="initialBalance"
            cell={({ dataItem, field }) => renderCurrency(dataItem[field])}
          />
          <GridColumn
            title="Available Balance"
            field="availableBalance"
            cell={({ dataItem, field }) => renderCurrency(dataItem[field])}
          />
        </TableGrid>
      </Hidden>
    </>
  )
}

export default CustomerOrdersTable
