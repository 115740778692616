import React from 'react'
import { Box, Typography } from '@material-ui/core'

import AppLayout from 'components/AppLayout'
import { ProgramSnapshot } from 'components/ProgramSnapshot'
import SEO from 'components/SEO'

export default function Page() {
  return (
    <AppLayout tab="reports">
      <SEO title="Program Snapshot" />
      <Box mb={2}>
        <Typography variant="h1">Program Snapshot</Typography>
      </Box>
      <ProgramSnapshot />
    </AppLayout>
  )
}
