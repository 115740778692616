import React, { createContext, useContext } from 'react'

export type TUserPreferences = {
  languagePreference: string
}

const defaults: TUserPreferences = {
  languagePreference: 'en-US',
}

export const useUserPreferences = () => {
  return useContext(EnvironmentContext)
}

export const EnvironmentContext = createContext({
  ...defaults,
} as TUserPreferences)

export const UserPreferencesContextProvider: React.FC = props => {
  const languagePreference =
    navigator.languages != null
      ? navigator.languages[0]
      : navigator.language
      ? navigator.language
      : defaults.languagePreference

  const context = { languagePreference }
  return (
    <EnvironmentContext.Provider value={context}>
      {props.children}
    </EnvironmentContext.Provider>
  )
}
