import {
  TAPICGProblem,
  TBillingContact,
  TBillingCustomer,
  TPerson,
} from 'types/api'
import { string } from 'yargs'

export function CapitalizeFirstEachWord(s: string) {
  return s.replace(/\b\w/g, function (l) {
    return l.toUpperCase()
  })
}

const currencySymbols = ['$', '€']
export function removeFirstIfCurrencySymbol(s: string) {
  if (!s || s.length === 0) return s

  if (currencySymbols.includes(s[0])) return s.slice(1)
  return s
}

export class StringFormatter {
  private static usdCurrencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  static formatAsUSD(value: number) {
    return this.usdCurrencyFormatter.format(value)
  }

  private static standardCurrencyFormatters = new Map<
    string,
    Intl.NumberFormat
  >()

  static formatAsCurrency(
    value: number,
    currencyCode: string = 'USD',
    languageFormat: string = 'en-US'
  ) {
    if (value === 0) return 'Free'
    if (currencyCode === 'none') return 'None'

    const code = currencyCode + languageFormat

    if (this.standardCurrencyFormatters.has(code))
      return this.standardCurrencyFormatters.get(code).format(value)

    const formatter = new Intl.NumberFormat(languageFormat, {
      style: 'currency',
      currency: currencyCode,
    })
    this.standardCurrencyFormatters.set(code, formatter)

    return formatter.format(value)
  }
}

export function nameToFirstAndLast(
  name: string
): [firstName: string, lastName?: string] {
  const def: [firstName: string, lastName?: string] = [name, undefined]

  if (!name) return def
  const split = name.split(' ')
  if (split.length == 2) {
    return [split[0], split[1]]
  }
  return def
}

export function CapitalizeFirst(s: string) {
  return !s ? s : s[0].toUpperCase() + s.slice(1).toLowerCase()
}

export function EndsInPunctuation(s: string) {
  return s && ['!', '?', '.'].includes(s.slice(-1))
}

export function EndInPeriod(s: string) {
  return !s
    ? s
    : s.slice(-1) === '.'
    ? s
    : EndsInPunctuation(s)
    ? s.slice(0, -1) + '.'
    : s + '.'
}

export function breakUpSampleProblemsString(problems: string | null): string[] {
  if (problems == null) return []
  const results = problems.split('***')
  return results
    .map(result => result.trim())
    .filter(s => s)
    .map(s => EndInPeriod(CapitalizeFirst(s)))
}

export function formatCGProblems(cgProblems: TAPICGProblem[]): string[] {
  if (cgProblems == null || cgProblems.length === 0) return []
  return cgProblems
    .filter(cgProblem => cgProblem && cgProblem.problem)
    .map(cgProblem => cgProblem.problem.trim())
    .filter(s => s)
    .map(s => EndInPeriod(CapitalizeFirst(s)))
}

export function RemoveThankYou(specialInstructions: string) {
  return specialInstructions
    .replace('Thank you for your order!', '')
    .replace('Thank you for your order.', '')
    .trim()
}

export function getContactName(contact: TPerson) {
  return (
    contact.name ||
    `${contact.prefix} ${contact.firstName} ${contact.lastName} ${contact.suffix}`
  )
}

export function stringIsNullOrWhitespace(str: string) {
  return stringIsNullOrEmpty(str) || Array.from(str).every(char => char === ' ')
}

export function stringIsNullOrEmpty(str: string) {
  return str === null || str === ''
}
