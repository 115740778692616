import {
  Route,
  TCustomerTestGroupPricing,
  TEncrypted,
  TListType,
} from 'types/api'
import { DatalessQuery, Query } from './Query'
import { serialize } from 'utils/api-utilities'

export class GetAllCustomerTestGroupPricingEncryptedQuery extends Query<
  TEncrypted,
  TCustomerTestGroupPricing[]
> {
  public get Url(): string {
    return `${
      process.env.REACT_APP_DS_EXPERIENCE_API_URL
    }/v1/Customer/TestGroupPricing/All/Encrypted${serialize(this.Data)}`
  }
}
