import React, { useRef } from 'react'
import { Helmet } from 'react-helmet'
import { Box, Grid } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import ImageAppLogo from 'components/ImageAppLogo'
import ImageAppName from 'components/ImageAppName'
import theme from 'components/theme'
import WithCompatibilityCheck from 'components/UserDetail/WithCompatibilityCheck'

const Layout = ({ children }) => {
  return (
    <>
      <CssBaseline />
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <style>
          {'body { font-family: "Fira Sans Condensed", sans-serif; } '}
        </style>
        <link
          href="https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@300;400;600;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <WithCompatibilityCheck>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Box
              bgcolor={theme.palette.primary.main}
              px={5}
              py={5}
              minHeight="75vh"
              width="95%"
              maxWidth="400px"
              style={{
                borderBottomLeftRadius: '15px',
                borderBottomRightRadius: '15px',
              }}
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                justify="flex-start"
              >
                <Grid item xs>
                  <ImageAppLogo
                    style={{
                      height: '72px',
                      width: '72px',
                      marginBottom: '10px',
                    }}
                  />
                </Grid>
                <Grid item xs style={{ width: '100%', maxWidth: '300px' }}>
                  <ImageAppName />
                </Grid>
                <Grid item xs>
                  <Box marginTop="50px">
                    <main>{children}</main>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </WithCompatibilityCheck>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
