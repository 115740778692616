import { TAPIConditionSummary } from 'types/api'

export const tableFormatter = (
  data: TAPIConditionSummary[],
  hasDivisions
): string[][] => {
  const headerValues = [
    ...(hasDivisions ? ['Division Name'] : []),
    'Customer Name',
    'Plant Name',
    'Sample Count',
    'Machine Normal',
    'Machine Marginal',
    'Machine Critical',
    'Machine Unknown',
    'Lube Normal',
    'Lube Marginal',
    'Lube Critical',
    'Lube Unknown',
  ]

  const dataTotals = {
    samples: data.map(x => x.samples).reduce((prev, cur) => prev + cur, 0),
    machineNormal: data
      .map(x => x.machineNormal)
      .reduce((prev, cur) => prev + cur, 0),
    machineMarginal: data
      .map(x => x.machineMarginal)
      .reduce((prev, cur) => prev + cur, 0),
    machineCritical: data
      .map(x => x.machineCritical)
      .reduce((prev, cur) => prev + cur, 0),
    machineUnknown: data
      .map(x => x.machineUnknown)
      .reduce((prev, cur) => prev + cur, 0),
    lubeNormal: data
      .map(x => x.lubeNormal)
      .reduce((prev, cur) => prev + cur, 0),
    lubeMarginal: data
      .map(x => x.lubeMarginal)
      .reduce((prev, cur) => prev + cur, 0),
    lubeCritical: data
      .map(x => x.lubeCritical)
      .reduce((prev, cur) => prev + cur, 0),
    lubeUnknown: data
      .map(x => x.lubeUnknown)
      .reduce((prev, cur) => prev + cur, 0),
  }

  const footerValues = [
    [
      ...(hasDivisions ? [null] : []),
      'Totals',
      null,
      dataTotals.samples?.toString(),
      dataTotals.machineNormal?.toString(),
      dataTotals.machineMarginal?.toString(),
      dataTotals.machineCritical?.toString(),
      dataTotals.machineUnknown?.toString(),
      dataTotals.lubeNormal?.toString(),
      dataTotals.lubeMarginal?.toString(),
      dataTotals.lubeCritical?.toString(),
      dataTotals.lubeUnknown?.toString(),
    ],
  ]

  const formattedData = data.map(datum => [
    ...(hasDivisions ? [datum.plant.customer.division.name] : []),
    datum.plant.customer.custName,
    datum.plant.plantName,
    datum.samples?.toString(),
    datum.machineNormal?.toString(),
    datum.machineMarginal?.toString(),
    datum.machineCritical?.toString(),
    datum.machineUnknown?.toString(),
    datum.lubeNormal?.toString(),
    datum.lubeMarginal?.toString(),
    datum.lubeCritical?.toString(),
    datum.lubeUnknown?.toString(),
  ])

  return [headerValues, ...formattedData, ...footerValues]
}
