import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, createTheme, Grid, ThemeProvider } from '@material-ui/core'
import moment from 'moment'

import { TAPIPicklistItem, TPdf } from 'types/api'
import { ExportButton, ThemeButton } from 'components/buttons'
import FeedbackModal from 'components/FeedbackModal'
import Loader from 'components/Loader'
import {
  OilAnalysisReportTable,
  tableFormatter,
} from 'components/tables/OilAnalysis'
import APIService, { TProfile } from 'services/api-service'
import { exportData } from 'services/export-service'
import { reportTypes } from 'services/lookup-service'
import { downloadPdfsInZip, mergePdfs } from 'services/pdf-service'
import { getSelectedCustomerIDs } from 'services/selection-service'

import { defaultFilterState } from './filters/SmartFilter/constants'
import Toggle from './Toggles/Toggle'
import coreTheme from './theme'
import { useProfile } from 'Contexts/ProfileContext'
import FilterAccordion, { TAccordionFilterValues } from './FilterAccordion'
import {
  DateFilterSet,
  LubricantFilterSet,
  MachineFilterSet,
  MiscellaneousFilterSet,
  OptionsFilterSet,
  OrganizationalFilterSet,
  SampleFilterSet,
} from './FilterAccordion/types'
import { CancellablePromise } from 'utils/CancellablePromise'

type TQueryParameters = {
  serialNumber?: string
  machineName?: string
}

interface OwnProps {
  profile?: TProfile // passed in from layout wrapper
  onError?: (_err: Error) => void // passed in from layout wrapper
  offline?: boolean
}

type StateProps = {
  loaded?: boolean
  showSampleDate?: boolean
  showReportDate?: boolean
  startDate: Date
  endDate: Date
  page: number
  // selectAllSamples?: boolean
  samplesLoaded?: boolean
  feedbackOpen?: boolean
  feedbackSample: unknown
  count: number
  samples: any[]
  // selectedSamples: any[]
  samplesCache: any[]
  sampleCache: any[]
  edit: boolean
  pageSize: number
  orderConfig: any[]
  exportFormat: string
  showCorrectiveActions: boolean
  searching: boolean
  loadedDefaults: boolean
} & TAccordionFilterValues

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

export const OilAnalysisReport: React.FC<OwnProps> = ({ onError, offline }) => {
  const profileContext = useProfile()

  const defaults: Partial<StateProps> = {
    page: 1,
    pageSize: 100,
    orderConfig: [{ field: 'sampleDate', dir: 'desc' }],
    exportFormat: '',
    // selectAllSamples: false,
    loaded: false,
    samplesLoaded: false,
    feedbackOpen: false,
    feedbackSample: null,
    count: 0,
    samples: [],
    // selectedSamples: [],
    samplesCache: [],
    edit: false,
    sampleCache: [],
    showCorrectiveActions: false,
    searching: false,
    loadedDefaults: false,
    ...defaultFilterState,
    ...profileContext.dependentData.filters.defaultValues,
    ...profileContext.dependentData.filters.initialFilterState,
  }

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  const [state, setState] = useState<Partial<StateProps>>(defaults)

  useEffect(() => {
    if (profileContext.dependentData.filters.minimumFilterOptionsLoaded) {
      setState(prev => ({
        ...prev,
        customers:
          profileContext.dependentData.filters.initialFilterState.customers,
        divisions:
          profileContext.dependentData.filters.initialFilterState.divisions,
        loadedDefaults: true,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext.dependentData.filters.minimumFilterOptionsLoaded])

  //Get query parameters and search immediately based upon those.

  /**Override the query state upon initial manual search */
  const [queryState, setQueryState] = useState<TQueryParameters>({})
  const [loadedQueryParameters, setLoadedQueryParameters] = useState(false)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const defaultMachineName = decodeURIComponent(
      urlParams.get('machineName') ?? ''
    )

    if (defaultMachineName) {
      if (profileContext.dependentData.userDetails.isSullairUser) {
        setQueryState(prev => ({ ...prev, serialNumber: defaultMachineName }))
      } else {
        setQueryState(prev => ({ ...prev, machineName: defaultMachineName }))
      }
    }
    setLoadedQueryParameters(true)
  }, [profileContext.dependentData.userDetails.isSullairUser])

  const theme = createTheme(
    {
      overrides: {
        MuiGrid: {
          root: {
            justifyContent: 'flex-end',
            [coreTheme.breakpoints.down('sm')]: {
              justifyContent: 'space-between',
            },
          },
        },
      },
    },
    coreTheme
  )

  const themeViewPDFsOverride = createTheme(
    {
      overrides: {
        MuiButton: {
          root: {
            [coreTheme.breakpoints.down('sm')]: {
              width: '175px',
            },
            [coreTheme.breakpoints.up('sm')]: {
              width: '225px',
            },
          },
        },
      },
    },
    theme
  )

  useEffect(() => {
    if (!state.loaded && !state.searching && state.loadedDefaults) {
      performStandardSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loaded, state.loadedDefaults])

  useEffect(() => {
    if (state.searching) {
      performStandardSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searching])

  const performSearch = () => {
    if (offline) {
      performOfflineSearch()
    } else {
      performStandardSearch()
    }
  }

  const performStandardSearch = async () => {
    const customerIDs = getSelectedCustomerIDs(
      profileContext.profile?.customers,
      profileContext.profile?.divisions,
      state.divisions?.map(d => d.iD),
      state.customers?.map(c => c.custID)
    )

    const machineQueryEffective =
      (!state.machineNames || state.machineNames.length === 0) &&
      !!queryState?.machineName
    const serialNumberQueryEffect =
      !state.serialNumber && !!queryState?.serialNumber

    const fetchData = {
      machineLubeConditionANDOR: state.machineLubeConditionANDOR,
      page: state.page,
      pageSize: state.pageSize,
      orderList: state.orderConfig.map(x => x.dir),
      orderByList: state.orderConfig.map(x => x.field),
      startDate:
        serialNumberQueryEffect || machineQueryEffective
          ? new Date(1900, 0)
          : state.startDate ||
            profileContext.dependentData.filters.defaultValues.startDate,
      endDate:
        serialNumberQueryEffect || machineQueryEffective
          ? (() => {
              const now = new Date(Date.now())
              return new Date(now.getFullYear(), now.getFullYear() + 100, 0)
            })()
          : state.endDate,
      sampleDate: state.showSampleDate,
      reportDate: state.showReportDate,
      customerIDs: customerIDs,
      plantIDs: state.plants.map(p => p.plantID),
      machineNames: machineQueryEffective
        ? [queryState.machineName]
        : state.machineNames,
      custEquIDs: state.customerEquipmentIDs,
      routeIDs: state.routes.map(r => r.routeID),
      machineTypes: (state.machinePicklistTypes as TAPIPicklistItem[]).map(
        mt => mt.listValue
      ),
      lubeTypes: state.lubricantTypes.map(lt => lt.lubeTypeID),
      machineConditions: state.machineConditions,
      lubeConditions: state.lubricantConditions,
      reportTypes: state.reportTypes.map(rt => rt.listValue),
      labNo: state.labNumber,
      serialNo: state.serialNumber || queryState?.serialNumber || '',
      reviewed: state.hideReviewed ? false : null,
    }

    await fetchSamples(fetchData)
  }

  const fetchSamples = async variables => {
    try {
      const promise = APIService.getOilAnalysis(variables)

      ongoingCancellablePromises.push(promise)
      const data = await promise

      ongoingCancellablePromises.filter(p => p != promise)

      if (data.pagedSamples !== null) {
        onError(null)
        setState(prev => ({
          ...prev,
          samples: data.pagedSamples.items,
          sampleCache: data.pagedSamples.items,
          count: data.pagedSamples.count,
          samplesLoaded: true,
          loaded: true,
          searching: false,
        }))
      }
    } catch (ex) {
      onError(ex)
    }
  }

  const performOfflineSearch = () => {
    const customerIDs = getSelectedCustomerIDs(
      profileContext.profile.customers,
      profileContext.profile.divisions,
      state.divisions.map(d => d.iD),
      state.customers.map(c => c.custID)
    )

    let results = [].concat(state.sampleCache)

    if (customerIDs.length > 0) {
      results = results.filter(
        x => customerIDs.indexOf(x.machine.plant.customer.custID) !== -1
      )
    }

    if (state.startDate) {
      const startDate = moment(state.startDate).format('yyyy-MM-DD')
      results = results.filter(x =>
        state.showSampleDate
          ? x.sampleDate >= startDate
          : x.reportDate >= startDate
      )
    }

    if (state.endDate) {
      const endDate = moment(state.endDate).format('yyyy-MM-DD')
      results = results.filter(x =>
        state.showSampleDate ? x.sampleDate <= endDate : x.reportDate <= endDate
      )
    }

    if (state.plants.length > 0) {
      results = results.filter(
        x =>
          state.plants.map(p => p.plantID).indexOf(x.machine.plant.plantID) !==
          -1
      )
    }

    if (state.machineNames.length > 0) {
      results = results.filter(
        x => state.machineNames.indexOf(x.machine.machineName) !== -1
      )
    }

    if (state.customerEquipmentIDs.length > 0) {
      results = results.filter(
        x => state.customerEquipmentIDs.indexOf(x.machine.custEquID) !== -1
      )
    }

    if (state.routes.length > 0) {
      results = results.filter(
        x =>
          x.machine.route !== null &&
          state.routes.map(r => r.routeID).indexOf(x.machine.route.routeID) !==
            -1
      )
    }

    if (state.machinePicklistTypes.length > 0) {
      results = results.filter(
        x =>
          state.machinePicklistTypes
            .map(mt => mt.listValue)
            .indexOf(x.machine.machType) !== -1
      )
    }

    if (state.lubricantTypes.length > 0) {
      results = results.filter(
        x =>
          state.lubricantTypes
            .map(lt => lt.lubeTypeID)
            .indexOf(
              Number(
                state.lubricantTypes
                  .filter(
                    y =>
                      y?.lubricantName === x.machine?.lubricant?.lubricantName
                  )
                  .map(y => y.lubeTypeID)
                  .join('')
              )
            ) !== -1
      )
    }

    if (state.machineConditions.length > 0) {
      results = results.filter(
        x => state.machineConditions.indexOf(x.machCond) !== -1
      )
    }

    if (state.lubricantConditions.length > 0) {
      results = results.filter(
        x => state.lubricantConditions.indexOf(x.lubCond) !== -1
      )
    }

    if (state.reportTypes.length > 0) {
      results = results.filter(
        x => state.reportTypes.indexOf(x.reportType) !== -1
      )
    }

    if (state.labNumber) {
      results = results.filter(
        x => Number.parseInt(state.labNumber) === x.labID
      )
    }

    if (state.serialNumber) {
      results = results.filter(
        x => state.serialNumber === x.machine.machineName
      )
    }

    if (state.hideReviewed) {
      results = results.filter(x => !x.reviewed)
    }

    if (state.orderConfig.length > 0) {
      state.orderConfig.forEach(orderItem => {
        let func = null

        switch (orderItem.field) {
          case 'sampleDate':
            func = x => x.sampleDate
            break
          case 'reportDate':
            func = x => x.reportDate
            break
          case 'receivedDate':
            func = x => x.receivedDate
            break
          case 'machine.plant.customer.division.name':
            func = x =>
              x.machine.plant.customer.division === null
                ? ''
                : x.machine.plant.customer.division.name
            break
          case 'machine.plant.customer.custName':
            func = x => x.machine.plant.customer.custName
            break
          case 'machine.plant.plantName':
            func = x => x.machine.plant.plantName
            break
          case 'machine.route.routeNo':
            func = x =>
              x.machine.route === null ? '' : x.machine.route.routeNo
            break
          case 'machine.route.name':
            func = x => (x.machine.route === null ? '' : x.machine.route.name)
            break
          case 'machine.machNo':
            func = x => x.machine.machNo
            break
          case 'machine.machineName':
            func = x => x.machine.machineName
            break
          case 'machine.custEquID':
            func = x => x.machine.custEquID
            break
          case 'machine.machType':
            func = x =>
              (state?.machinePicklistTypes as TAPIPicklistItem[]).find(
                y => y.listValue === x.machine.machType
              ).listDesc
            break
          case 'machine.lubricant.lubricantName':
            func = x => x.machine.lubricant.lubricantName
            break
          case 'labID':
            func = x => x.labID
            break
          case 'reportType':
            func = x =>
              x.reportType === null
                ? ''
                : reportTypes.find(y => y.value === x.reportType).text
            break
          case 'machCond':
            func = x => x.machCond
            break
          case 'lubCond':
            func = x => x.lubCond
            break
          case 'sampleStatusCode':
            func = x => x.sampleStatus
            break
          case 'problems':
            func = x => x.problems
            break
          case 'recommendations':
            func = x => x.recommendations
            break
          default:
            break
        }

        if (func === null) {
          console.error('Unsupported orderBy parameter provided')
        } else {
          switch (orderItem.dir) {
            case 'asc':
              results.sort((a, b) => {
                const x = func(a)
                const y = func(b)

                if (x < y) return -1
                if (x > y) return 1
                return 0
              })
              break
            case 'desc':
              results.sort((a, b) => {
                const x = func(a)
                const y = func(b)

                if (x < y) return 1
                if (x > y) return -1
                return 0
              })
              break
            default:
              console.error('Unsupported order parameter provided')
              break
          }
        }
      })
    } else {
      results.sort((a, b) => {
        const x = a.sampleDate
        const y = b.sampleDate

        if (x < y) return 1
        if (x > y) return -1
        return 0
      })
    }

    setState(prev => ({ ...prev, samples: results, samplesLoaded: true }))
  }

  // const handleSelectAllSampleChange = event => {
  //   if (event.target.checked) {
  //     setState(prev => ({
  //       ...prev,
  //       selectAllSamples: true,
  //       selectedSamples: state.samples.map(x => x.labID),
  //     }))
  //   } else {
  //     setState(prev => ({
  //       ...prev,
  //       selectAllSamples: false,
  //       selectedSamples: [],
  //     }))
  //   }
  // }

  // const handleSelectedSampleChange = (labID: string) => {
  //   const selectedSamples = [].concat(state.selectedSamples)
  //   const index = selectedSamples.indexOf(labID)
  //   if (index === -1) selectedSamples.push(labID)
  //   else selectedSamples.splice(index, 1)
  //   setState(prev => ({ ...prev, selectedSamples }))
  // }

  const handleResetClick = async () => {
    setQueryState({})
    setState(prev => ({
      ...prev,
      ...defaults,
      loaded: false,
      searching: true,
      samplesLoaded: false,
    }))
  }

  const handleExportFormatChange = async (event, format) => {
    try {
      let resolvedData = null

      if (offline) {
        resolvedData = state.samples
      } else {
        const customerIDs = getSelectedCustomerIDs(
          profileContext.profile.customers,
          profileContext.profile.divisions,
          state.divisions.map(d => d.iD),
          state.customers.map(c => c.custID)
        )

        const promise = APIService.getOilAnalysis({
          page: 1,
          pageSize: 100000,
          orderList: state.orderConfig.map(x => x.dir),
          orderByList: state.orderConfig.map(x => x.field),
          startDate: state.startDate,
          endDate: state.endDate,
          sampleDate: state.showSampleDate,
          reportDate: state.showReportDate,
          customerIDs: customerIDs,
          plantIDs: state.plants.map(p => p.plantID),
          machineNames: state.machineNames,
          custEquIDs: state.customerEquipmentIDs,
          routeIDs: state.routes.map(r => r.routeID),
          machineTypes: state.machinePicklistTypes.map(mt => mt.listValue),
          lubeTypes: state.lubricantTypes.map(lt => lt.lubeTypeID),
          machineConditions: state.machineConditions,
          lubeConditions: state.lubricantConditions,
          reportTypes: state.reportTypes.map(x => x.listValue),
          labNo: state.labNumber,
          serialNo: state.serialNumber,
          reviewed: state.hideReviewed ? false : null,
        })
        ongoingCancellablePromises.push(promise)
        const data = await promise

        ongoingCancellablePromises.filter(p => p != promise)

        resolvedData = data.pagedSamples.items
      }

      const formatted = tableFormatter(
        profileContext.profile.correctiveActions,
        resolvedData,
        profileContext.profile.machineTypeOptions,
        profileContext.dependentData.userDetails.isDivisionUser
      )

      exportData('view-your-reports', formatted, format.key)
    } catch (ex) {
      onError(ex)
    }
  }

  const handlePageChange = event => {
    setState(prev => ({
      ...prev,
      page: event.page.skip / state.pageSize + 1,
      pageSize: event.page.take,
      samplesLoaded: false,
      searching: true,
    }))
  }

  const handleSortChange = event => {
    const isFieldRemoved = state.orderConfig.length > event.sort.length
    let isLastFieldRemoved = false

    if (isFieldRemoved) {
      const fieldRemoved = state.orderConfig.filter(
        x => !event.sort.includes(x)
      )[0]

      isLastFieldRemoved =
        state.orderConfig.indexOf(fieldRemoved) === state.orderConfig.length - 1
    }

    setState(prev => ({
      ...prev,
      orderConfig: [...event.sort],
      samplesLoaded: !(event.sort.length > 0 && !isLastFieldRemoved),
      searching: event.sort.length > 0 && !isLastFieldRemoved,
    }))
  }

  const handlePageChangeMobile = (event, newPage) => {
    setState(prev => ({ ...prev, page: newPage + 1, samplesLoaded: false }))
  }

  const handlePageSizeChangeMobile = event => {
    setState(prev => ({
      ...prev,
      page: 1,
      pageSize: parseInt(event.target.value, 10),
      samplesLoaded: false,
    }))
  }
  const handleCorrectiveActionToggle = event => {
    setState(prev => ({ ...prev, showCorrectiveActions: event.target.checked }))
  }

  const handleFeedbackOpen = sample => {
    setState(prev => ({ ...prev, feedbackOpen: true, feedbackSample: sample }))
  }

  const handleFeedbackClose = () => {
    setState(prev => ({ ...prev, feedbackOpen: false, feedbackSample: null }))
    performSearch()
  }

  const checkboxesRef = useRef<TCheckboxCollectionRef>({
    checkboxes: new Map(),
    allSelected: false,
  })

  const handleViewPDFSClick = async () => {
    let selectedTargets = state.samples

    if (!checkboxesRef.current.allSelected) {
      selectedTargets = state.samples.filter(
        s =>
          checkboxesRef.current.checkboxes.has(s.labID) &&
          checkboxesRef.current.checkboxes.get(s.labID).checked
      )
    }

    let targetsWithReports = state.samples
    if (!checkboxesRef.current.allSelected) {
      targetsWithReports = state.samples.filter(
        s =>
          s.batchID > 0 &&
          checkboxesRef.current.checkboxes.has(s.labID) &&
          checkboxesRef.current.checkboxes.get(s.labID).checked
      )
    }

    const pdfs: TPdf[] = []

    if (selectedTargets.length === 0) {
      onError(new Error('Please select at least 1 report.'))
    } else if (targetsWithReports.length === 0) {
      onError(new Error('No report found.'))
    } else {
      let filesNotFound = 0
      let filesEvaluated = 0
      for (const target of targetsWithReports) {
        filesEvaluated += 1
        const pdf: TPdf = { fileName: null, buffer: null }
        try {
          pdf.buffer = await APIService.getFile(
            target.machine.plant.customer.custID,
            `${target.labID}.pdf`
          )
          pdf.fileName = `${target.labID}.pdf`
          pdfs.push(pdf)
        } catch (ex) {
          filesNotFound += 1
          onError(
            new Error(
              filesNotFound === filesEvaluated
                ? 'No reports found.'
                : `${filesNotFound}/${filesEvaluated} reports not found.`
            )
          )
        }
      }
    }

    if (pdfs.length > 0) {
      //@ts-ignore
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        downloadPdfsInZip(pdfs)
      } else {
        mergePdfs(pdfs)
      }
    }
  }

  function clearCheckedBoxes() {
    if (!checkboxesRef.current) return

    if (!!checkboxesRef.current.selectAllCheckbox) {
      checkboxesRef.current.selectAllCheckbox?.reset()
    }

    for (const [_, checkbox] of checkboxesRef.current.checkboxes) {
      checkbox?.reset()
    }
  }

  function getCheckedBoxes() {
    return !checkboxesRef.current
      ? []
      : Array.from(checkboxesRef.current.checkboxes.entries())
          .filter(([_, checkbox]) => checkbox?.checked)
          .map(([labID, _]) => labID)
  }

  const handleMarkReviewedClick = async () => {
    try {
      const checkedLabIDs = getCheckedBoxes()

      if (checkedLabIDs.length < 1)
        throw 'At least one line item must be checked.'
      setState(prev => ({ ...prev, samplesLoaded: false }))
      await APIService.markSamplesReviewed({
        labIDs: checkedLabIDs,
      })
    } catch (ex) {
      onError(ex)
    } finally {
      clearCheckedBoxes()
      performSearch()
    }
  }

  const handleMarkUnreviewedClick = async () => {
    try {
      const checkedLabIDs = getCheckedBoxes()

      if (checkedLabIDs.length < 1)
        throw 'At least one line item must be checked.'
      setState(prev => ({ ...prev, samplesLoaded: false }))
      await APIService.markSamplesUnreviewed({
        labIDs: checkedLabIDs,
      })
    } catch (ex) {
      onError(ex)
    } finally {
      clearCheckedBoxes()
      performSearch()
    }
  }

  const handleCorrectiveActionSave = async (sample, data) => {
    try {
      setState(prev => ({ ...prev, samplesLoaded: false }))
      await APIService.saveCorrectiveAction({
        labID: sample.labID,
        cACode: data.action,
        oCDate: data.oilChangeDate,
        newOil: data.newOil,
        cANote: data.note,
        workOrderNumber: data.workOrderNumber,
      })
    } catch (ex) {
      onError(ex)
    } finally {
      performSearch()
    }
  }

  const handleCorrectiveActionDelete = async (sample, cbIfAccept) => {
    let result = false
    if (typeof window !== 'undefined') {
      result = window.confirm(
        'Are you sure you want to delete this corrective action?'
      )
    }

    if (!result) return

    cbIfAccept()

    try {
      setState(prev => ({ ...prev, samplesLoaded: false }))
      await APIService.deleteCorrectiveAction({
        labID: sample.labID,
      })
    } catch (ex) {
      onError(ex)
    } finally {
      performSearch()
    }
  }

  const handleFilterSubmission = (data: TAccordionFilterValues) => {
    setQueryState({})

    setState(prev => ({
      ...prev,
      ...data,
      page: 1,
      searching: true,
      samplesLoaded: false,
    }))
  }

  const reportTable = useMemo(
    () => (
      <OilAnalysisReportTable
        offline={offline}
        ref={checkboxesRef}
        readOnly={profileContext.dependentData?.userDetails?.isReadonlyUser}
        samples={state.samples}
        machineTypeOptions={profileContext.profile.machineTypeOptions}
        correctiveActions={profileContext.profile.correctiveActions}
        orderConfig={state.orderConfig}
        page={state.page}
        pageSize={state.pageSize}
        count={state.count}
        onSortChange={handleSortChange}
        onPageChange={handlePageChange}
        onFeedbackOpen={handleFeedbackOpen}
        onCorrectiveActionSave={handleCorrectiveActionSave}
        onCorrectiveActionDelete={handleCorrectiveActionDelete}
        onPageChangeMobile={handlePageChangeMobile}
        onPageSizeChangeMobile={handlePageSizeChangeMobile}
        showCorrectiveActions={state.showCorrectiveActions}
      />
    ),
    [
      offline,
      profileContext.dependentData?.userDetails?.isReadonlyUser,
      state.samples,
      profileContext.profile.machineTypeOptions,
      profileContext.profile.correctiveActions,
      state.orderConfig,
      state.page,
      state.pageSize,
      state.count,
      state.showCorrectiveActions,
    ]
  )

  return (
    <>
      <FilterAccordion
        pageName="View Your Reports"
        defaultValues={profileContext.dependentData.filters.initialFilterState}
        onSubmit={handleFilterSubmission}
        onReset={handleResetClick}
        filters={{
          options: [
            OptionsFilterSet.Prefiltering,
            OptionsFilterSet.HideReviewed,
          ],
          date: [
            DateFilterSet.ReportDateSampleDateSwitch,
            DateFilterSet.FromDate,
            DateFilterSet.ToDate,
          ],
          organization: [OrganizationalFilterSet.All],
          machine: [
            MachineFilterSet.Name,
            MachineFilterSet.Conditions,
            MachineFilterSet.Type,
            MachineFilterSet.EquipmentIDs,
            !profileContext.minimumProfileLoaded ||
            profileContext.dependentData.userDetails.isSullairUser
              ? MachineFilterSet.SerialNumber
              : undefined,
          ],
          lubricant: [LubricantFilterSet.Type, LubricantFilterSet.Conditions],
          sample: [SampleFilterSet.LabNumber, SampleFilterSet.ReportTypes],
          miscellaneous: [],
          financials: [],
        }}
      />
      <form>
        <ThemeProvider theme={themeViewPDFsOverride}>
          <Grid
            container
            direction="row"
            xs={12}
            spacing={1}
            style={{ marginTop: '15px' }}
          >
            <Grid container item direction="row" spacing={1}>
              <Grid item>
                <Button
                  id="oil_analysis-pdf_click_button"
                  onClick={handleViewPDFSClick}
                  variant="contained"
                  color="primary"
                  disabled={state.samples.length < 1}
                  style={{ marginLeft: 15, textTransform: 'none' }}
                >
                  VIEW PDFs
                </Button>
              </Grid>
              <Grid item>
                <ExportButton
                  onChange={handleExportFormatChange}
                  disabled={state.samples.length < 1}
                />
              </Grid>
              <Grid container item direction="row" spacing={1}>
                <Grid item>
                  <ThemeButton
                    id="oil_analysis-mark_as_viewed_button"
                    onClick={handleMarkReviewedClick}
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: 15 }}
                    disabled={state.samples.length < 1}
                  >
                    Mark as Reviewed
                  </ThemeButton>
                </Grid>
                <Grid item>
                  <ThemeButton
                    id="oil_analysis-mark_as_unviewed_button"
                    onClick={handleMarkUnreviewedClick}
                    variant="contained"
                    color="primary"
                    disabled={state.samples.length < 1}
                  >
                    Mark as Unreviewed
                  </ThemeButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
      </form>

      <Toggle onChange={handleCorrectiveActionToggle}>
        Show All Corrective Actions
      </Toggle>
      {state.samplesLoaded ? reportTable : <Loader />}

      <FeedbackModal
        modalOpen={state.feedbackOpen}
        modalSample={state.feedbackSample}
        onModalClose={handleFeedbackClose}
      />
    </>
  )
}

export default OilAnalysisReport
