import { PageCard } from '../Pages/PageContainers'
import * as PageStyles from '../page-styles.module.css'
import { LoadingErrorCardModule } from '../CardModules/LoadingErrorCardModule'
import { useIsLoggedIn } from 'hooks/useIsLoggedIn'

export const BottleDataLoadingError = () => {
  const isLoggedIn = useIsLoggedIn()
  return (
    <PageCard>
      <div
        className={`${PageStyles.EntryScreen}  ${PageStyles.WithBottleButton} ${
          isLoggedIn.loggedIn ? PageStyles.LoggedIn : ''
        }`}
      >
        <LoadingErrorCardModule />
      </div>
    </PageCard>
  )
}
