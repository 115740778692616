import { Button } from '@material-ui/core'
import { formatDateString } from '../formatters'
import * as PageStyles from '../page-styles.module.css'
import { PageBody, PageCard } from '../Pages/PageContainers'
import { TWarhammerSampleWithoutIdentifier } from '../types-and-enums'
import { useIsLoggedIn } from 'hooks/useIsLoggedIn'

type TProps = {
  bottleData: TWarhammerSampleWithoutIdentifier
  navigateToSampleSubmissionPage: () => void
}
export const BottleLinked = (props: TProps) => {
  const isLoggedIn = useIsLoggedIn()
  return (
    <PageCard>
      <div
        className={`${PageStyles.EntryScreen} ${PageStyles.WithBottleButton} ${
          isLoggedIn.loggedIn ? PageStyles.LoggedIn : ''
        }`}
      >
        <div>
          <header className={PageStyles.MainTitle}>Bottle Linked</header>
          <sub className={PageStyles.SubTitle}>
            Your details have been received by the team! <br />
            We're waiting to receive your sample.
          </sub>
          <section>
            <ul
              className={`${PageStyles.Details} ${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer} ${PageStyles.Wrapper}`}
            >
              <li className={`${PageStyles.Wrappable}`}>
                <span className={PageStyles.PropertyLabel}>Customer</span>
                <span className={PageStyles.Property}>
                  {props.bottleData?.customerID}
                  {' - '}
                  {props.bottleData?.customerName}
                </span>
              </li>

              <li className={`${PageStyles.Wrappable}`}>
                <span className={PageStyles.PropertyLabel}>Plant</span>
                <span className={PageStyles.Property}>
                  {props.bottleData?.plantNumber}
                  {' - '}
                  {props.bottleData?.plantName}
                </span>
              </li>

              <li className={`${PageStyles.Wrappable}`}>
                <span className={PageStyles.PropertyLabel}>Machine</span>
                <span className={PageStyles.Property}>
                  {props.bottleData?.pointID}
                  {' - '}
                  {props.bottleData?.machineName}
                </span>
              </li>

              <li className={`${PageStyles.Wrappable}`}>
                <span className={PageStyles.PropertyLabel}>
                  Lubricant Hours
                </span>
                <span className={PageStyles.Property}>
                  {props.bottleData?.lubeHours}
                </span>
              </li>

              <li className={`${PageStyles.Wrappable}`}>
                <span className={PageStyles.PropertyLabel}>Machine Hours</span>
                <span className={PageStyles.Property}>
                  {props.bottleData?.machineHours}
                </span>
              </li>

              <li className={`${PageStyles.Wrappable}`}>
                <span className={PageStyles.PropertyLabel}>Sampled On</span>
                <span className={PageStyles.Property}>
                  {formatDateString(props.bottleData?.sampledAt)}
                </span>
              </li>

              <li className={`${PageStyles.Wrappable}`}>
                <span className={PageStyles.PropertyLabel}>Oil Changed At</span>
                <span className={PageStyles.Property}>
                  {formatDateString(props.bottleData?.oilChangedAt)}
                </span>
              </li>

              <li className={`${PageStyles.FullWidth}`}>
                <span className={PageStyles.PropertyLabel}>Comments</span>
                <span
                  className={`${PageStyles.Property} ${PageStyles.Comments}`}
                >
                  {props.bottleData?.comments}
                </span>
              </li>
            </ul>
          </section>
        </div>

        <div>
          <section className={PageStyles.DetailSection}>
            <p className={PageStyles.FinePrintParagraph}>
              Your sample details have been submitted and received by the
              laboratory. Until your sample has been received by the laboratory
              team you may edit the sample details at any time. When your sample
              arrives and testing begins, the provided sample details will be
              locked in by the laboratory team and can no longer be changed.
              When testing is complete, navigate to this page to view the
              analysis details and download your sample report.
            </p>
          </section>

          <div className={PageStyles.ButtonContainer}>
            <Button
              variant="contained"
              color="primary"
              data-cancel
              onClick={props.navigateToSampleSubmissionPage}
              className={`${PageStyles.Button} ${PageStyles.Left}`}
            >
              Modify
            </Button>
          </div>
        </div>
      </div>
    </PageCard>
  )
}
