import React from 'react'

import {
  Sample,
  TAPISample,
  TShipmentInformation,
  TShipmentStatus,
} from 'types/api'
import { TProfile } from 'services/api-service'

import {
  breakUpSampleProblemsString,
  formatCGProblems,
} from './string-utilities'

export function serialize<T extends Partial<object>>(
  obj: T | undefined
): string {
  if (typeof obj === 'undefined') {
    return ''
  }
  const str: string[] = []
  Object.keys(obj).forEach(p => {
    if (obj[p] && Array.isArray(obj[p])) {
      obj[p]?.forEach(val => {
        str.push(`${p}=${val}`)
      })
    } else {
      if (obj[p]) {
        str.push(`${p}=${obj[p]}`)
      }
    }
  })
  return `?${str.join('&')}`
}

const isNotSameAsLast = (
  current: any,
  last: any,
  comparator?: (_v1: any, _v2: any) => boolean
) => {
  return comparator ? comparator(current, last) : current !== last
}

const stringCompare = (a, b) => a?.localeCompare(b)

export const getSampleProblems = (sample: TAPISample | Sample) => {
  if (sample?.sampleStatus !== 'COM') {
    return []
  }

  if ('cGProblem' in sample && sample?.cGProblem?.length > 0) {
    return formatCGProblems(sample.cGProblem)
  }

  if (sample?.problems) {
    return breakUpSampleProblemsString(sample.problems)
  }

  return []
}

const recommendationSeparator = /\*\s[^\*]*/gm
export const getFormattedSampleRecommendations = (sample: Sample) => {
  const matches: string[] = []

  if (sample?.sampleStatus !== 'COM') {
    return matches
  }

  if (!sample.recommendations) {
    return matches
  }

  let match: RegExpExecArray
  while ((match = recommendationSeparator.exec(sample.recommendations))) {
    const value = match[0]?.replace('*', '').trim()
    matches.push(value)
  }

  return matches
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function parseMachineLists(
  profile: Partial<TProfile>
): Promise<Partial<TProfile>> {
  return {
    ...profile,
    custEquIDs: profile.machines
      .map(x => x.custEquID)
      .sort()
      .filter((x, index, arr) => {
        return index === 0 || isNotSameAsLast(x, arr[index - 1])
      }),
    machineNames: profile.machines
      .map(x => x.machineName)
      .sort()
      .filter((x, index, arr) => {
        return index === 0 || isNotSameAsLast(x, arr[index - 1])
      }),
    machineTypes: profile.machines
      .map(x => x.machType)
      .sort()
      .filter((x, index, arr) => {
        return index === 0 || isNotSameAsLast(x, arr[index - 1])
      }),
    machineNos: profile.machines
      .map(x => x.machNo)
      .sort()
      .filter((x, index, arr) => {
        return index === 0 || isNotSameAsLast(x, arr[index - 1])
      }),
    machineMfgs: profile.machines
      .map(x => x.machMFG)
      .sort()
      .filter((x, index, arr) => {
        return index === 0 || isNotSameAsLast(x, arr[index - 1])
      }),
    machineModels: profile.machines
      .map(x => x.modelName)
      .sort()
      .filter((x, index, arr) => {
        return index === 0 || isNotSameAsLast(x, arr[index - 1])
      }),
    lubeTypes: profile.machines
      .map(x => x.lubricant)
      .sort((a, b) => stringCompare(a.lubricantName, b.lubricantName))
      .filter((x, index, arr) => {
        return (
          index === 0 ||
          isNotSameAsLast(x.lubricantName, arr[index - 1].lubricantName)
        )
      }),
  }
}

export const ParseProfileData = async data => {
  const getPlants = customers => {
    return customers
      .flatMap(x => x.plants)
      .sort((a, b) => stringCompare(a.plantName, b.plantName))
      .filter((plant, index, arr) => {
        return (
          index === 0 || isNotSameAsLast(plant.plantID, arr[index - 1].plantID)
        )
      })
  }

  const getRoutes = customers => {
    return customers
      .flatMap(x => x.routes)
      .sort((a, b) => a.routeNo - b.routeNo)
      .filter((route, index, arr) => {
        return (
          index === 0 || isNotSameAsLast(route.routeID, arr[index - 1].routeID)
        )
      })
  }

  const getMachines = plants => {
    return plants.flatMap(x => x.machines)
  }

  if (data.customers !== null) {
    const plants = getPlants(data.customers)
    const machines = getMachines(plants)

    const correctiveActions = data.correctiveActions
      .sort((a, b) =>
        stringCompare(
          a.listDesc.trim().toLowerCase(),
          b.listDesc.trim().toLowerCase()
        )
      )
      .map(x => {
        return { listValue: x.listValue * 1, listDesc: x.listDesc.trim() }
      })

    const machineMfgOptions = data.machineMfgs
      .sort((a, b) =>
        stringCompare(
          a.listDesc.trim().toLowerCase(),
          b.listDesc.trim().toLowerCase()
        )
      )
      .map(x => {
        return { listValue: x.listValue, listDesc: x.listDesc.trim() }
      })

    const lubeMfrOptions = data.lubeMfrs
      .sort((a, b) =>
        stringCompare(
          a.lubemfr.trim().toLowerCase(),
          b.lubemfr.trim().toLowerCase()
        )
      )
      .map(x => {
        return { pickListValue: x.pickListValue, lubemfr: x.lubemfr.trim() }
      })

    const machineTypeOptions = data.machineTypes
      .sort((a, b) =>
        stringCompare(
          a.listDesc.trim().toLowerCase(),
          b.listDesc.trim().toLowerCase()
        )
      )
      .map(x => {
        return { listValue: x.listValue, listDesc: x.listDesc.trim() }
      })

    let profile: Partial<TProfile> = {
      user: data.user,
      correctiveActions: correctiveActions.filter((x, index, arr) => {
        return (
          index === 0 || isNotSameAsLast(x.listDesc, arr[index - 1].listDesc)
        )
      }),
      machineMfgOptions: machineMfgOptions.filter((x, index, arr) => {
        return (
          index === 0 || isNotSameAsLast(x.listDesc, arr[index - 1].listDesc)
        )
      }),
      lubeMfrOptions: lubeMfrOptions.filter((x, index, arr) => {
        return index === 0 || isNotSameAsLast(x.lubemfr, arr[index - 1].lubemfr)
      }),
      machineTypeOptions: machineTypeOptions.filter((x, index, arr) => {
        return (
          index === 0 || isNotSameAsLast(x.listDesc, arr[index - 1].listDesc)
        )
      }),
      divisions: data.divisions,
      customers: data.customers,
      plants: plants,
      routes: getRoutes(data.customers),
      machines: machines,
      testGroups: data.testGroups.filter((x, index, arr) => {
        return (
          index === 0 ||
          isNotSameAsLast(x.testGroupName, arr[index - 1].testGroupName)
        )
      }),
      error: null,
    }

    profile = await parseMachineLists(profile)

    return profile
  }
  return null
}

export const shippingAnchorFromOrder = (order: TShipmentInformation) => {
  if (order?.trackingNumber) {
    if (order?.shippingMethod?.toLowerCase()?.includes('ups')) {
      return (
        <a
          target="_blank"
          href={`https://www.ups.com/track?loc=null&tracknum=${order.trackingNumber}&requester=WT/trackdetails`}
        >
          <span style={{ color: '#4990E2' }}>{order.trackingNumber}</span>
        </a>
      )
    }

    if (order?.shippingMethod?.toLowerCase()?.includes('dhl')) {
      return (
        <a
          target="_blank"
          href={`https://www.dhl.com/us-en/home/tracking.html?tracking-id=${order.trackingNumber}&submit=1`}
        >
          <span style={{ color: '#4990E2' }}>{order.trackingNumber}</span>
        </a>
      )
    }

    return order.trackingNumber
  }

  return 'N/A'
}

export const deliveryDateFromTrackingNumber = (
  trackingNumber: string
): Date => {
  return new Date()
}

export const ShippingStatusTitleFromEnum = (status: TShipmentStatus) => {
  switch (status) {
    case 'CNX': {
      return 'Cancelled'
    }
    case 'SHP': {
      return 'Shipped/Received'
    }
    case 'INC': {
      return 'Incomplete'
    }
    case 'PEN': {
      return 'Pending Validation'
    }
    default: {
      return ''
    }
  }
}
