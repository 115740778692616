/* eslint-disable no-undef */
import useDisableFiltersBox from './useDisableFiltersBox'

type TProps = {
  children?: (_useFilters, _FiltersInfoBox) => JSX.Element
}

const WithDisableFiltersBox = (props: TProps): JSX.Element => {
  const [useFilters, FiltersInfoBox] = useDisableFiltersBox()
  return props.children(useFilters, FiltersInfoBox)
}

export default WithDisableFiltersBox
