import React from 'react'
import { Button, Grid, Modal, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'

import APIService from 'services/api-service'

import Loader from './Loader'

export default function AnalystFeedbackModal({
  modalOpen,
  modalSample,
  onModalClose,
}) {
  const feedbackDefaults = {
    firstName: '',
    lastName: '',
    email: '',
    subject:
      modalSample.analystIsiUser !== null
        ? modalSample.analystIsiUser.name
        : modalSample.analyst,
    feedback: '',
  }

  const [feedback, setFeedback] = React.useState(feedbackDefaults)
  const [modalSubmitting, setModalSubmitting] = React.useState(false)
  const [modalError, setModalError] = React.useState(null)

  function getModalStyle() {
    const top = 50
    const left = 50

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    }
  }

  const useStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: 600,
      backgroundColor: '#fff',
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '25px',
    },
  }))

  const modalClasses = useStyles()
  const [modalStyle] = React.useState(getModalStyle)

  const handleFeedbackClose = () => {
    setModalError(null)
    setFeedback(feedbackDefaults)
    setModalSubmitting(false)
    onModalClose()
  }

  const handleFeedbackChange = event => {
    const update = Object.assign({}, feedback)
    update[event.target.name] = event.target.value
    setFeedback(update)
  }

  const handleFeedbackSave = event => {
    setModalError(null)
    setModalSubmitting(true)
    ;(async (feedback, sample, cb) => {
      let data = null
      try {
        data = await APIService.createAnalystFeedback({
          labID: sample.labID,
          custID: sample.machine.plant.customer.custID,
          plantNo: sample.machine.plant.plantNo,
          machNo: sample.machine.machNo,
          firstName: feedback.firstName,
          lastName: feedback.lastName,
          email: feedback.email,
          subject: feedback.subject,
          feedback: feedback.feedback,
        })
        cb(data)
      } catch (ex) {
        setModalSubmitting(false)
        setModalError(ex.message)
      }
    })(feedback, modalSample, (_data: any) => {
      setFeedback(feedbackDefaults)
      handleFeedbackClose()
    })
  }

  return (
    <Modal open={modalOpen} onClose={handleFeedbackClose}>
      <div style={modalStyle} className={modalClasses.paper}>
        {modalSubmitting ? (
          <React.Fragment>
            <Loader />
            Submitting...
          </React.Fragment>
        ) : (
          <form>
            <Grid container direction="column" spacing={2}>
              <Grid
                container
                item
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <Grid item xs={2}>
                  First Name
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    name="firstName"
                    variant="outlined"
                    value={feedback.firstName}
                    onChange={handleFeedbackChange}
                    style={{ width: '100%' }}
                    required
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <Grid item xs={2}>
                  Last Name
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    name="lastName"
                    variant="outlined"
                    value={feedback.lastName}
                    onChange={handleFeedbackChange}
                    style={{ width: '100%' }}
                    required
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <Grid item xs={2}>
                  Email
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    name="email"
                    variant="outlined"
                    value={feedback.email}
                    onChange={handleFeedbackChange}
                    style={{ width: '100%' }}
                    required
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <Grid item xs={2}>
                  Subject
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    name="email"
                    variant="outlined"
                    value={feedback.subject}
                    onChange={handleFeedbackChange}
                    style={{ width: '100%' }}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                Feedback
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="feedback"
                  variant="outlined"
                  multiline={true}
                  rows={10}
                  value={feedback.feedback}
                  onChange={handleFeedbackChange}
                  style={{ width: '100%' }}
                  required
                />
              </Grid>
              <Grid container item direction="row" spacing={2}>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ width: '100%' }}
                    onClick={handleFeedbackSave}
                    id="analyst_feedback_modal-save_button"
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: '100%' }}
                    onClick={handleFeedbackClose}
                    id="analyst_feedback_modal-close_button"
                  >
                    Close
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}

        {modalError !== null ? (
          <Alert severity="error" style={{ marginTop: '15px' }} id="analyst_feedback_modal-alert">
            {modalError}
          </Alert>
        ) : (
          ''
        )}
      </div>
    </Modal>
  )
}
