import React, { useCallback, useEffect, useState } from 'react'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import CenteredContainer from 'components/CenteredContainer'
import Loader from 'components/Loader'
import APIService from 'services/api-service'
import { handleDownloadFile } from 'utils/file-utilities'
import FileNotFoundError from 'components/FileNotFoundError'

export default () => {
  const [loading, setloading] = useState(true)
  const [error, setError] = useState(false)
  const downloadFile = useCallback(async (targetFile: string) => {
    try {
      const { buffer } = await APIService.getFileByPath(targetFile)
      const targetFileArray = targetFile.toString().split('\\')
      const fileName = targetFileArray[targetFileArray.length - 1]

      handleDownloadFile({ buffer, fileName })
    } catch (ex) {
      setError(true)
    } finally {
      setloading(false)
    }
  }, [])

  useEffect(() => {
    if (window) {
      const targetFile = decodeURIComponent(
        window.location.search.replace(/^\?targetfile=/gi, '')
      )
      downloadFile(targetFile)
    }
  }, [downloadFile])

  return error ? (
    <CenteredContainer>
      <FileNotFoundError />
    </CenteredContainer>
  ) : loading ? (
    <CenteredContainer>
      <Loader />
    </CenteredContainer>
  ) : (
    <CenteredContainer>
      <CheckCircleOutlineIcon
        style={{ color: 'rgb(25, 225, 25)', fontSize: '350px' }}
      />
      <p>Download Complete</p>
    </CenteredContainer>
  )
}
