import React from 'react'
import { Button } from '@material-ui/core'
import { GridColumn as Column } from '@progress/kendo-react-grid'

import { GridToolbar } from '@progress/kendo-react-grid'

import { ProductGroup } from 'types/api'
import TableGrid from 'components/TableGrid'
import { State } from '@progress/kendo-data-query'

import { GridEditContext } from 'Contexts/GridEditContext'
import {
  CommandCell,
  DropDownListCell,
  rowRender,
} from 'components/KendoUIGridEditForm'

export type TProps = {
  customerProductGroups: ProductGroup[]
  productGroups: ProductGroup[]
  offline?: boolean
  onProductGroupChange: (newProductGroups: ProductGroup[]) => void
  onError?: (error: Error) => void
}

const ProductGroupEditingTable: React.FC<TProps> = ({
  customerProductGroups,
  productGroups,
  offline,
  onProductGroupChange,
  onError,
}: TProps) => {
  const [dataState] = React.useState<State>({
    sort: [{ field: 'productGroup', dir: 'asc' }],
  })

  // Form data index is used as an alternative to ID for rows after data operations
  const [editingCustomerProductGroups, setEditingCustomerProductGroups] =
    React.useState(
      customerProductGroups?.map((dataItem, idx) => ({
        productGroup: dataItem,
        formDataIndex: idx,
      }))
    )

  const [editIndex, setEditIndex] = React.useState<number | undefined>(
    undefined
  )

  const onRowAction = React.useCallback(
    (options: {
      rowIndex: number
      operation: 'save' | 'remove' | 'add'
      dataItem?: any
    }) => {
      const newEditingCustomerProductGroups = [...editingCustomerProductGroups]
      switch (options.operation) {
        case 'remove':
          newEditingCustomerProductGroups.splice(options.rowIndex, 1)
          break
        case 'save':
          newEditingCustomerProductGroups[options.rowIndex] = options.dataItem
          setEditIndex(undefined)
          break
        case 'add':
          newEditingCustomerProductGroups.push({
            productGroup: null,
            formDataIndex: options.rowIndex,
          })
          setEditIndex(options.rowIndex)
          break
        default:
      }
      if (['remove', 'save'].includes(options.operation))
        onProductGroupChange(
          newEditingCustomerProductGroups
            .map(pg => pg.productGroup)
            .filter(pg => pg)
        )

      const newEditingCustomerProductGroupsReindexed =
        newEditingCustomerProductGroups.map((dataItem, idx) => ({
          productGroup: dataItem.productGroup,
          formDataIndex: idx,
        }))

      setEditingCustomerProductGroups(newEditingCustomerProductGroupsReindexed)
    },
    [editingCustomerProductGroups]
  )

  const onAddClick = React.useCallback(() => {
    if (editingCustomerProductGroups.find(product => !product.productGroup)) {
      onError(new Error('You must add the empty line before add a new one'))
      return
    }
    onRowAction({
      rowIndex: editingCustomerProductGroups.length,
      operation: 'add',
    })
  }, [onRowAction, editingCustomerProductGroups])

  const requiredGroupValidator = (
    value: any,
    valueGetter: (name: string) => any
  ) => {
    const formDataIndex = valueGetter('formDataIndex')
    return value &&
      !editingCustomerProductGroups.find(
        p =>
          p.productGroup?.groupID === value.groupID &&
          p.formDataIndex !== formDataIndex
      )
      ? ''
      : 'required and must be unique'
  }

  return (
    <>
      <GridEditContext.Provider
        value={{
          onRowAction,
          editIndex,
          setEditIndex,
          dataItemKey: 'productGroup',
        }}
      >
        <TableGrid
          clearSortingButton={<></>}
          offline={offline}
          data={editingCustomerProductGroups}
          style={{
            lineHeight: 1,
            cursor: 'pointer',
            maxHeight: '700px',
          }}
          sortable={false}
          filterable={false}
          groupable={false}
          reorderable={true}
          pageable={false}
          {...dataState}
          dataItemKey={'groupID'}
          rowRender={rowRender}
        >
          <GridToolbar>
            <Button variant="contained" color="secondary" onClick={onAddClick}>
              Add new
            </Button>
          </GridToolbar>

          <Column
            field="productGroup"
            title="Group Name"
            cell={props =>
              DropDownListCell({
                ...props,
                validators: [requiredGroupValidator],
                data: productGroups,
                textField: 'groupDescription',
                dataItemKey: 'groupID',
              })
            }
          />

          <Column title="Command" cell={CommandCell} />
        </TableGrid>
      </GridEditContext.Provider>
    </>
  )
}

export default ProductGroupEditingTable
