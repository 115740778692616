import { usePrimaryHubContext } from 'Contexts/PrimaryHubContext'
import { useUserHub } from 'Contexts/UserHubContext'
import React, { useCallback, useEffect, useRef } from 'react'
import { ProblemDetails, Product, TCustomerProductPricing } from 'types/api'
import { ConnectionStatus, SocketResponse } from 'types/custom'
import { FetchError } from 'utils/Errors/FetchError'

const productUpdatedMessageName = 'productPricingUpdated'
const productDeletedMessageName = 'productPricingDeleted'
const productAddedMessageName = 'productPricingPosted'
const productReversionRequestName = 'productReversionRequested'
const updateProductPricingMethodName = 'editProductPricing'
const postProductPricingMethodName = 'postProductPricing'
const deleteProductPricingMethodName = 'deleteProductPricing'
const editProductErrorMessageName = 'productUpdateError'
const postProductErrorMessageName = 'productPostError'
const deleteProductErrorMessageName = 'productDeleteError'

type TConfig = {
  /**Invoked any time the product pricing has a row that has been updated. */
  onProductPricingUpdate?: (
    productPricing: SocketResponse<TCustomerProductPricing>
  ) => void
  /**Invoked any time the product pricing has a new row posted */
  onProductPricingPosted?: (
    productPricing: SocketResponse<TCustomerProductPricing>
  ) => void
  /**Invoked any time the product pricing has had an existing row deleted */
  onProductPricingDeleted?: (
    productPricing: SocketResponse<TCustomerProductPricing>
  ) => void
  /**An alert to all clients that the updates last received should be reverted. */
  onProductReversionRequested?: () => void
  /**An alert to the caller that something has gone wrong and the update was not performed. */
  onProductUpdateError?: (error: FetchError) => void
  /**An alert to the caller that something has gone wrong and the post was not performed. */
  onProductPostError?: (error: FetchError) => void
  /**An alert to the caller that something has gone wrong and the delete was not performed. */
  onProductDeleteError?: (error: FetchError) => void
}

type TReturn = {
  /**A promise that completes when the request has been sent or rejected. */
  requestProductPricingUpdate: (
    productPricing: TCustomerProductPricing
  ) => Promise<void>
  requestProductPricingDelete: (
    productPricing: TCustomerProductPricing
  ) => Promise<void>
  requestProductPricingPost: (
    productPricing: TCustomerProductPricing
  ) => Promise<void>
  status: ConnectionStatus
}

export const useProductSocket = (config: TConfig = {}): TReturn => {
  const { connection, status } = useUserHub()

  const {
    onProductPricingUpdate,
    onProductPricingPosted,
    onProductPricingDeleted,
    onProductReversionRequested,
    onProductUpdateError,
    onProductPostError,
    onProductDeleteError,
  } = config

  const handleProductPricingPosted = useCallback(
    (productPricing: SocketResponse<TCustomerProductPricing>) => {
      if (onProductPricingPosted) onProductPricingPosted(productPricing)
    },
    [onProductPricingPosted]
  )

  const handleProductPricingDeleted = useCallback(
    (productPricing: SocketResponse<TCustomerProductPricing>) => {
      if (onProductPricingDeleted) onProductPricingDeleted(productPricing)
    },
    [onProductPricingDeleted]
  )

  const handleProductPricingUpdated = useCallback(
    (productPricing: SocketResponse<TCustomerProductPricing>) => {
      if (onProductPricingUpdate) onProductPricingUpdate(productPricing)
    },
    [onProductPricingUpdate]
  )

  const handleProductUpdateError = useCallback(
    (problemDetails: ProblemDetails) => {
      const fetchError = new FetchError(problemDetails)
      if (onProductUpdateError) onProductUpdateError(fetchError)
    },
    [onProductUpdateError]
  )

  const handleProductPostError = useCallback(
    (problemDetails: ProblemDetails) => {
      const fetchError = new FetchError(problemDetails)
      if (onProductPostError) onProductPostError(fetchError)
    },
    [onProductPostError]
  )

  const handleProductDeleteError = useCallback(
    (problemDetails: ProblemDetails) => {
      const fetchError = new FetchError(problemDetails)
      if (onProductDeleteError) onProductDeleteError(fetchError)
    },
    [onProductDeleteError]
  )

  const handleProductReversionRequested = useCallback(() => {
    if (onProductReversionRequested) onProductReversionRequested()
  }, [onProductReversionRequested])

  const requestProductPricingUpdate = useCallback(
    (pricing: TCustomerProductPricing) => {
      return connection.send(updateProductPricingMethodName, pricing)
    },
    [connection]
  )

  const requestProductPricingDelete = useCallback(
    (pricing: TCustomerProductPricing) => {
      return connection.send(deleteProductPricingMethodName, pricing)
    },
    [connection]
  )

  const requestProductPricingPost = useCallback(
    (pricing: TCustomerProductPricing) => {
      return connection.send(postProductPricingMethodName, pricing)
    },
    [connection]
  )

  useEffect(() => {
    if (connection) {
      connection.on(productUpdatedMessageName, handleProductPricingUpdated)
      connection.on(
        productReversionRequestName,
        handleProductReversionRequested
      )
      connection.on(editProductErrorMessageName, handleProductUpdateError)
      connection.on(postProductErrorMessageName, handleProductPostError)
      connection.on(deleteProductErrorMessageName, handleProductDeleteError)

      connection.on(productDeletedMessageName, handleProductPricingDeleted)
      connection.on(productAddedMessageName, handleProductPricingPosted)
    }

    return () => {
      if (connection) {
        connection.off(productUpdatedMessageName, handleProductPricingUpdated)
        connection.off(
          productReversionRequestName,
          handleProductReversionRequested
        )
        connection.off(editProductErrorMessageName, handleProductUpdateError)
        connection.off(postProductErrorMessageName, handleProductPostError)
        connection.off(deleteProductErrorMessageName, handleProductDeleteError)
        connection.off(productDeletedMessageName, handleProductPricingDeleted)
        connection.off(productAddedMessageName, handleProductPricingPosted)
      }
    }
  }, [
    handleProductPricingUpdated,
    onProductReversionRequested,
    handleProductUpdateError,
    handleProductPostError,
    handleProductDeleteError,
    handleProductPricingPosted,
    handleProductReversionRequested,
    handleProductPricingDeleted,
  ])

  return {
    status,
    requestProductPricingDelete,
    requestProductPricingPost,
    requestProductPricingUpdate,
  }
}
