import React, { forwardRef, ReactNode, useImperativeHandle } from 'react'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core'
import { TSupplyOrderItem } from 'types/api'
import { StringFormatter } from 'utils/string-utilities'
import { useUserPreferences } from 'Contexts/UserPreferencesContext'
import styled from '@emotion/styled'
import { breakDownPricing, getLowestNonFreePricing } from 'utils/products'

interface OwnProps {
  supplies: TSupplyOrderItem[]
}

const SuppliesList: React.FC<OwnProps> = ({ supplies }: OwnProps, ref) => {
  useImperativeHandle(ref, () => ({
    getTotal: () => {
      return (
        supplies
          .map(x => (x.selectedProductPrice?.unitPrice || 0) * x.quantity)
          .reduce((prev, curr) => prev + curr, 0) || 0
      )
    },
  }))

  const userPreferences = useUserPreferences()

  const suppliesWithPricing = supplies.filter(s => s.selectedProductPrice)
  let currencyCode =
    suppliesWithPricing.length > 0
      ? suppliesWithPricing[0].selectedProductPrice.currencyCode
      : 'USD'
  const getTotalPriceFormatted = (total: number) =>
    StringFormatter.formatAsCurrency(
      total,
      currencyCode,
      userPreferences.languagePreference
    )

  let total = 0

  const rows = supplies.map((x, i) => {
    const pricingBreakdown = breakDownPricing(x.productPricing, x.quantity)
    const showFreeRow = pricingBreakdown.freePricing.quantity > 0
    let freeRow = null
    if (showFreeRow) {
      freeRow = (
        <TableRow key={i}>
          <StyledTableCell style={{ padding: '8px' }}>
            {x.productName}
          </StyledTableCell>
          <StyledTableCell style={{ padding: '8px' }}>
            {pricingBreakdown.freePricing.quantity}
          </StyledTableCell>
          <StyledTableCell style={{ padding: '8px' }}>{'Free'}</StyledTableCell>
          <StyledTableCell style={{ padding: '8px' }}>{'Free'}</StyledTableCell>
        </TableRow>
      )
    }

    const formattedUnitPrice = x.selectedProductPrice
      ? StringFormatter.formatAsCurrency(
          x.selectedProductPrice.unitPrice,
          x.selectedProductPrice.currencyCode,
          userPreferences.languagePreference
        )
      : StringFormatter.formatAsUSD(0)

    const productPrice =
      pricingBreakdown.paidPricing.quantity * pricingBreakdown.paidPricing.price
    total += productPrice
    const formattedProductPrice = x.selectedProductPrice
      ? StringFormatter.formatAsCurrency(
          productPrice,
          x.selectedProductPrice.currencyCode,
          userPreferences.languagePreference
        )
      : StringFormatter.formatAsUSD(0)

    let paidRow = null
    if (pricingBreakdown.paidPricing.quantity > 0) {
      paidRow = (
        <TableRow key={i}>
          <StyledTableCell style={{ padding: '8px' }}>
            {x.productName}
          </StyledTableCell>
          <StyledTableCell style={{ padding: '8px' }}>
            {pricingBreakdown.paidPricing.quantity}
          </StyledTableCell>
          <StyledTableCell style={{ padding: '8px' }}>
            {formattedUnitPrice}
          </StyledTableCell>
          <StyledTableCell style={{ padding: '8px' }}>
            {formattedProductPrice}
          </StyledTableCell>
        </TableRow>
      )
    }

    return (
      <>
        {freeRow}
        {paidRow}
      </>
    )
  })

  return (
    <>
      <Grid container direction="column" style={{ overflowY: 'auto' }}>
        <h3
          style={{ margin: '0 0 15px 0', width: '100%', textAlign: 'center' }}
        >
          Supplies
        </h3>
        <Grid item>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ fontWeight: 'bold' }}>
                    Item
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ fontWeight: 'bold' }}
                    width={'68px'}
                  >
                    Quantity
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ fontWeight: 'bold' }}
                    width={'82px'}
                  >
                    Unit Price
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ fontWeight: 'bold' }}
                    width={'71px'}
                  >
                    Subtotal
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>{rows}</TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid container direction="row" style={{ marginTop: 10 }}>
          <Grid item xs={6}>
            <strong style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
              Total
            </strong>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
              {getTotalPriceFormatted(total)}
            </span>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default forwardRef(SuppliesList)

const StyledTableCell = styled(TableCell)`
  padding: 8px;
  border-bottom: thin solid rgba(0, 0, 0, 0.25);
`
