import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Snackbar,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import * as Sentry from '@sentry/react'

import Link from 'components/landing/LandingLink'
import TextField from 'components/landing/LandingTextField'
import LandingLayout from 'components/landing/Layout'
import Password from 'components/landing/PasswordInput'
import SEO from 'components/SEO'
import LoginService from 'services/login-service'
import { DisconnectedBanner } from 'components/Banners/DisconnectedBanner'
import { ConnectionStatusMonitor } from 'components/ConnectionStatusMonitor'
import { FetchError } from 'utils/Errors/FetchError'

const LoginPage = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [sessionExpired, setSessionExpired] = useState('')
  const [autoSubmit, setAutoSubmit] = useState(false)
  const [error, setError] = useState(null)

  const navigate = useNavigate()

  // attempt to get username and password. If both are defined, submit automatically.
  useEffect(() => {
    if (window) {
      const urlParams = new URLSearchParams(window.location.search)
      const urlUsername = urlParams?.get('username')
      const urlPassword = urlParams?.get('password')
      const urlSessionExpired = urlParams?.get('sessionexpired')

      if (urlUsername && urlPassword) {
        setUsername(urlUsername)
        setPassword(urlPassword)
        setAutoSubmit(true)
      }

      if (urlSessionExpired) {
        setSessionExpired(urlSessionExpired)
      }
    }
  }, [])

  useEffect(() => {
    if (sessionExpired) {
      setError('Your session has expired. Please log in again.')
      navigate('/login')
    }
  }, [sessionExpired])

  const handleSubmit = useCallback(
    async event => {
      event?.preventDefault()

      if (window && window.gtag) {
        window.gtag('set', 'user_id', username)
      }

      Sentry.setContext('User', {
        LoginId: username,
      })

      setError(null)

      try {
        if (LoginService.isLoggedIn) await LoginService.logOut()

        const tokens = await LoginService.requestTokens(username, password)
        const urlParams = new URLSearchParams(window.location.search)
        const postLoginURL = urlParams.get('PostLoginURL')

        if (tokens === false) {
          throw new Error('Unable to login')
        }
        let navString = `/auth?a=${tokens.access_token}&r=${tokens.refresh_token}&e=${tokens.expires}`

        if (postLoginURL) {
          navString += `&PostLoginURL=${encodeURIComponent(postLoginURL)}`
        }

        navigate(navString)
      } catch (ex) {
        if (ex instanceof FetchError) {
          navigate(`/reset-password`, { state: { username } })
          return
        }

        if (ex.message.indexOf('Failed to fetch') !== -1) {
          setError(
            'An error occurred while communicating with the server.  Please try your request again.'
          )
        } else {
          if (window && window.location?.search) {
            setAutoSubmit(false)
            navigate(`/login${window.location?.search}`)
          }
          setError(ex.message)
        }
      }
    },
    [navigate, password, username]
  )

  // Handle autosubmission of url-provided credentials after credential update
  useEffect(() => {
    if (username && password && autoSubmit) handleSubmit(null)
  }, [username, password, autoSubmit, handleSubmit])

  const showPage = !autoSubmit

  function handleUsernameChange(event) {
    setUsername(event.target.value)
  }

  function handlePasswordChange(event) {
    setPassword(event.target.value)
  }

  return (
    showPage && (
      <LandingLayout>
        <DisconnectedBanner />
        <SEO title="Log in" />

        <form method="post" onSubmit={e => handleSubmit(e)}>
          <Grid
            item
            xs
            container
            direction="column"
            alignItems="center"
            justify="flex-start"
            spacing={1}
          >
            <Grid item container>
              <FormControl>
                <TextField
                  id="username"
                  name="username"
                  label="Username"
                  variant="filled"
                  required
                  value={username}
                  onChange={handleUsernameChange}
                />
              </FormControl>
            </Grid>

            <Grid item container>
              <FormControl>
                <Password
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  required
                  value={password}
                  onChange={handlePasswordChange}
                />
                <FormHelperText style={{ textAlign: 'right' }}>
                  <Link to="/forgot-password">Forgot your password?</Link>
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs>
              <FormControl>
                <Button type="submit" variant="contained" color="secondary">
                  Login
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </form>

        <Box marginTop="150px">
          <a
            href="https://testoil.com/registration/"
            style={{
              textDecoration: 'none',
              fontStyle: 'italic',
              color: '#ffffff',
            }}
          >
            New to DataSight? Sign up here.
          </a>
        </Box>

        <Snackbar open={error !== null} onClose={() => setError(null)}>
          <Alert severity="error">{error}</Alert>
        </Snackbar>
        <ConnectionStatusMonitor />
      </LandingLayout>
    )
  )
}

export default LoginPage
