import React from 'react'

import { TAPILubricant } from 'types/api'

import { VirtualSelectFilter } from '../BaseFilters/VirtualSelectFilter'
import FilterTooltip from '../FilterTooltip/FilterTooltip'

type TProps = {
  single: boolean
  required: boolean
  lubeTypes: Array<TAPILubricant>
  onChange?: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    props: TAPILubricant | Array<TAPILubricant>
  ) => void
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setState?: (props: any) => void
  label?: string
  disabled?: boolean
  value?: null | number | number[] | TAPILubricant | TAPILubricant[]
  tooltip?: boolean
}
export const LubeTypeFilter = ({
  single,
  lubeTypes,
  onChange,
  setState,
  required,
  label,
  disabled,
  value,
  tooltip = true,
}: TProps) => {
  if (setState == null && onChange == null) return

  const handleLubeTypeChange = (types: TAPILubricant | TAPILubricant[]) => {
    if (onChange != null) {
      if (single === true) {
        onChange(types as TAPILubricant)
      } else {
        onChange(types as TAPILubricant[])
      }
    } else {
      if (single === true) {
        setState({ selectedLubeType: (types as TAPILubricant)?.lubeTypeID })
      } else {
        setState({
          selectedLubeTypes: (types as TAPILubricant[]).map(
            type => type.lubeTypeID
          ),
        })
      }
    }
  }

  const getValue = (
    val: number | number[] | TAPILubricant | TAPILubricant[]
  ) => {
    if (typeof val === 'number') {
      const lube =
        lubeTypes?.find(x => x.lubeTypeID === (val as number)) ?? undefined
      return {
        lubeTypeID: lube?.lubeTypeID,
        lubricantName: lube?.lubricantName,
      } as TAPILubricant
    } else if (Array.isArray(val)) {
      const lubes = lubeTypes.filter(x =>
        (val as number[]).includes(x.lubeTypeID)
      )
      return lubes
    } else {
      return val
    }
  }

  const filter = (
    <VirtualSelectFilter
      label={label ?? 'Lube Types'}
      htmlFor="lubeTypes"
      multiple={!single}
      required={required}
      disabled={disabled}
      id="lubeTypes"
      value={getValue(value)}
      getOptionSelected={(option, value) =>
        option?.lubeTypeID === value?.lubeTypeID
      }
      renderValue={(selected: TAPILubricant) =>
        lubeTypes.find(type => type?.lubeTypeID === selected?.lubeTypeID)
          ?.lubricantName ?? null
      }
      onChange={handleLubeTypeChange}
      options={lubeTypes}
    />
  )

  return tooltip ? (
    <FilterTooltip title="Fluid used by a machine. NOTE: Various manufacturers may produce a similarly or identically named fluid.">
      {filter}
    </FilterTooltip>
  ) : (
    filter
  )
}
