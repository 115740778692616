import {
  stringIsNullOrEmpty,
  stringIsNullOrEmpty as stringIsNullOrWhitespace,
} from './string-utilities'

export const allOldValuesAreInNewObject = (
  oldObject: Record<any, any>,
  newObject: Record<any, any>
) => {
  let parametersAreIdentical = true

  for (const key in newObject) {
    const newValue = newObject[key]
    const oldValue = oldObject[key]

    if (Array.isArray(newValue) && Array.isArray(oldValue)) {
      newValue.forEach(nv => {
        parametersAreIdentical = oldValue.includes(nv)
        if (!parametersAreIdentical) return
      })

      oldValue.forEach(nv => {
        parametersAreIdentical = newValue.includes(nv)
        if (!parametersAreIdentical) return
      })
    } else if (newValue !== oldValue) {
      parametersAreIdentical = false
    }

    if (!parametersAreIdentical) break
  }

  return parametersAreIdentical
}

/**
 *Validates any value as null (literal null), undefined, or, if it is a string, empty.
 */
export const isNullOrEmpty = (value: any) => {
  if (value == null) return true

  if (typeof value === 'string') {
    return stringIsNullOrEmpty(value)
  }
}
