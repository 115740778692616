import React from 'react'

import { VirtualSelectFilter } from '../BaseFilters/VirtualSelectFilter'
import FilterTooltip from '../FilterTooltip/FilterTooltip'

type TMachineType = {
  listValue: string
  listDesc: string
}

type TProps = {
  single: boolean
  required: boolean
  machineMfgs: TMachineType[]
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange?: (props: TMachineType | TMachineType[]) => void
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setState?: (props: any) => void
  defaultValue?: string | TMachineType | TMachineType[]
  value?: string | TMachineType | TMachineType[]
  tooltip?: boolean
}

export const MachineManufacturerFilter = ({
  single,
  required,
  machineMfgs,
  onChange,
  setState,
  defaultValue,
  value,
  tooltip = true,
}: TProps) => {
  if (setState == null && onChange == null) return

  const handleMachineManufacturerChange = (
    machineManufacturer: TMachineType | TMachineType[]
  ) => {
    if (onChange != null) {
      if (single === true) {
        onChange(machineManufacturer as TMachineType)
      } else {
        onChange(machineManufacturer as TMachineType[])
      }
    } else {
      if (single === true) {
        setState({
          selectedMachineMfg: (machineManufacturer as TMachineType)?.listValue,
        })
      } else {
        setState({
          selectedMachineMfgs: (machineManufacturer as TMachineType[]).map(
            p => p.listValue
          ),
        })
      }
    }
  }

  const getValue = (val: string | string[] | TMachineType | TMachineType[]) => {
    if (typeof val === 'string') {
      const mach =
        machineMfgs.find(x => x.listValue === (val as string)) ?? null
      return {
        listValue: mach?.listValue,
        listDesc: mach?.listDesc,
      } as TMachineType
    } else if (Array.isArray(val)) {
      const machs = machineMfgs.filter(x =>
        (val as string[]).includes(x.listValue)
      )
      return machs
    } else {
      return val
    }
  }

  const filter = (
    <VirtualSelectFilter
      label="Machine Manufacturer"
      htmlFor="machineMfgs"
      multiple={!single}
      required={required}
      id="machineMfgs"
      defaultValue={getValue(defaultValue)}
      getOptionSelected={(option, value) =>
        option?.listValue === value?.listValue
      }
      value={getValue(value)}
      options={machineMfgs}
      renderValue={(selected: TMachineType) =>
        machineMfgs.find(mach => mach.listValue === selected.listValue)
          ?.listDesc ?? ''
      }
      onChange={handleMachineManufacturerChange}
    ></VirtualSelectFilter>
  )

  return tooltip ? (
    <FilterTooltip title="The manufacturer of a machine.">
      {filter}
    </FilterTooltip>
  ) : (
    filter
  )
}
