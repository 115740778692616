import { Capacitor, Plugins } from '@capacitor/core'
import moment from 'moment'

import CustomerFiles from '../pages/downloads/customer'
import OilAnalysisReport from '../pages/reports/oil-analysis'

import APIService from './api-service'
import LoginService from './login-service'
import SQLiteService from './sqlite-service'

const { Storage } = Plugins
const constantMock = typeof window !== 'undefined' ? window.fetch : null

let offlineCbRef = null
let syncCbRef = null
let notificationCbRef = null
let errorCbRef = null

let _isOffline = false
let heartbeatRunning = false
let prefetchRunning = false
let syncRunning = false

const blobToBase64 = blob => {
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })
}

const base64ToBlob = async base64 => {
  const response = await constantMock(base64)
  const blob = await response.blob()

  return blob
}

const isOffline = () => {
  return _isOffline
}

const setOfflineStatus = offline => {
  // console.log(`Set offline status to ${offline}`);

  _isOffline = offline
  if (offlineCbRef) offlineCbRef(_isOffline)
}

// Catch online/offline window events:
const updateOnlineStatus = () => {
  if (typeof navigator !== 'undefined') {
    const offline = !navigator.onLine
    setOfflineStatus(offline)
  }
}

if (typeof window !== 'undefined') {
  updateOnlineStatus()
  window.addEventListener('offline', updateOnlineStatus)
  window.addEventListener('online', updateOnlineStatus)
}

// Hearbeat:
const timeout = (ms, promise) => {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error('TIMEOUT'))
    }, ms)

    promise
      .then(value => {
        clearTimeout(timer)
        resolve(value)
      })
      .catch(reason => {
        clearTimeout(timer)
        reject(reason)
      })
  })
}

let heartBeatTimer = null
const heartbeat = async poll => {
  try {
    const response = await timeout(
      1000 * 5,
      fetch(`${process.env.REACT_APP_API_URL}/heartbeat`)
    )
    const offline = response.status !== 200
    setOfflineStatus(offline)
  } catch (ex) {
    setOfflineStatus(true)
  }

  if (poll) {
    heartBeatTimer = setTimeout(async () => {
      await heartbeat(poll)
    }, 1000 * 60)
  }
}

const forceOfflineCheck = async () => {
  if (!Capacitor.isNative) return _isOffline

  return await heartbeat(false)
}

const init = async (offlineCb, notificationCb, syncCb, errorCb) => {
  offlineCbRef = offlineCb
  notificationCbRef = notificationCb
  syncCbRef = syncCb
  errorCbRef = errorCb

  if (Capacitor.isNative) {
    if (!heartbeatRunning) {
      heartbeatRunning = true
      await heartbeat(true)
    }

    await SQLiteService.init()
  }
}

const registerFetchInterceptor = async () => {
  if (Capacitor.isNative && typeof window !== 'undefined') {
    window.fetch = function () {
      const args = new Array(arguments.length)
      for (let i = 0; i < arguments.length; i++) {
        args[i] = arguments[i]
      }

      const url = args[0]
      const method =
        args.length <= 1 || typeof args[1] === 'undefined'
          ? 'GET'
          : typeof args[1].method !== 'undefined'
          ? args[1].method
          : 'GET'

      // console.log(`Fetch intercept: ${method} ${url}`)

      // Queue failed API POST requests:
      if (method === 'POST' && url.indexOf('graphql') !== -1) {
        const queueRequest = async args => {
          await SQLiteService.enqueue(JSON.stringify(args))
          if (notificationCbRef)
            notificationCbRef({
              type: 'ENQUEUE',
              title: 'You are currently offline',
              message:
                'Your request has been queued and will sync with the server once you are back online.',
            })
        }

        return new Promise((resolve, reject) => {
          ;(async () => {
            if (_isOffline) {
              await queueRequest(args)
              reject(new Error('Failed to fetch'))
              return
            }

            constantMock
              .apply(this, args)
              .then(response => {
                resolve(response.clone())
              })
              .catch(err => {
                ;(async () => {
                  await queueRequest(args)
                  reject(err)
                })()
              })
          })()
        })
      }
      // Caching for graphql and datafiles:
      else if (
        method === 'GET' &&
        (url.indexOf('graphql') !== -1 || url.indexOf('datafiles') !== -1)
      ) {
        return new Promise((resolve, reject) => {
          ;(async () => {
            if (_isOffline) {
              // See if it's in the cache:
              const row = await SQLiteService.getFromCache(url)
              if (row !== null) {
                // console.log('Returning cached result...');

                try {
                  const init = JSON.parse(row.init)
                  const blob = await base64ToBlob(row.body)
                  const response = new Response(blob, init)
                  resolve(response)
                  return
                } catch (ex) {
                  console.error(ex)
                  reject(ex)
                  return
                }
              }

              // If not, reject it:
              reject(new Error('Failed to fetch'))
              return
            }

            // console.log('Making live request...');
            constantMock
              .apply(this, args)
              .then(response => {
                const mockResultClone = response.clone()
                const jsonClone = response.clone()

                ;(async () => {
                  // Only store in cache if it's a successful response without errors:
                  if (response.status === 200) {
                    const contentType = response.headers.get('Content-Type')
                    const body = await response.blob()
                    let doCache = body !== null

                    if (contentType === 'application/json') {
                      const jsonBody = await jsonClone.json()
                      doCache =
                        jsonBody !== null &&
                        typeof jsonBody.errors === 'undefined'
                    }

                    if (doCache) {
                      const headers = {}
                      for (const p of response.headers) {
                        headers[p[0]] = p[1]
                      }

                      const init = JSON.stringify({
                        status: response.status,
                        statusText: response.statusText,
                        headers: headers,
                      })

                      const base64 = await blobToBase64(body)

                      // Store it in cache:
                      await SQLiteService.putInCache(
                        url,
                        init,
                        base64,
                        contentType
                      )
                    }
                  }
                })()

                resolve(mockResultClone)
              })
              .catch(err => {
                ;(async () => {
                  // See if it's in the cache:
                  const row = await SQLiteService.getFromCache(url)
                  if (row !== null) {
                    // console.log('Returning cached result after error...');

                    try {
                      const init = JSON.parse(row.init)
                      const blob = await base64ToBlob(row.body)
                      const response = new Response(blob, init)
                      resolve(response)
                      return
                    } catch (ex) {
                      console.error(ex)
                      reject(ex)
                      return
                    }
                  }

                  // If not, reject it:
                  reject(err)
                })()
              })
          })()
        })
      }
      // Pass-through:
      else {
        // console.log('Fetch pass-through...');

        return constantMock.apply(this, args)
      }
    }
  } else {
    // Handled by workbox service worker extension
  }
}

const prefetch = async profile => {
  if (!Capacitor.isNative) return
  if (prefetchRunning) return
  if (_isOffline) return

  prefetchRunning = true

  const storageKey = 'prefetchTimestamp'

  // Because prefetching is an expensive operation, it should only be run to initially prime the
  // cache.  After that, the cache should update based on usage and/or until the prefetch cache
  // expires.  Prefetches rely on the fetch interceptor to cache responses, and so this method
  // must be called AFTER registering the fetch interceptor.
  const { value: prefetchTimestamp } = await Storage.get({ key: storageKey })

  // 7 days
  if (
    prefetchTimestamp !== null &&
    moment().unix() - prefetchTimestamp * 1 < 60 * 60 * 24 * 7
  ) {
    // console.log('NOT prefetching...');
    prefetchRunning = false
    return
  }

  // console.log('prefetching...');

  if (syncCbRef) syncCbRef(true)

  if (notificationCbRef)
    notificationCbRef({
      title: 'Performing data update...',
      message:
        "DataSight is currently updating your local copies of reports and downloads so they'll be available for you when you're offline.  This may take a few moments.",
      type: 'PREFETCH',
    })

  let manifest = []
  try {
    manifest = await APIService.getPrefetchManifest()
  } catch (ex) {
    console.error(ex)
  }

  const batches = []
  const batchSize = 10
  for (let i = 0; i < manifest.length; i += batchSize) {
    const files = []
    for (let j = i; j < i + batchSize && j < manifest.length; j++) {
      files.push(manifest[j])
    }

    batches.push(
      new Promise((resolve, reject) => {
        ;(async () => {
          for (let p = 0; p < files.length; p++) {
            const file = files[p]

            try {
              // Skip it if it's in the cache already; otherwise, let the fetch interceptor handle it:
              const url = APIService.getURLForFile(file.CustID, file.Name)

              const cache = await SQLiteService.getFromCache(url)
              if (!cache) {
                await APIService.getFile(file.CustID, file.Name)
              }
            } catch (ex) {
              console.error(ex)
            }
          }

          resolve(true)
        })()
      })
    )
  }

  const pages = [
    OilAnalysisReport({ prefetch: true }),
    CustomerFiles({ prefetch: true }),
  ]

  const pagePromises = pages.map(func => {
    return new Promise((resolve, reject) => {
      ;(async () => {
        try {
          const data = await func(profile)
          resolve(data)
        } catch (ex) {
          console.error(ex)
          reject(ex)
        }
      })()
    })
  })

  const promises = [].concat(batches).concat(pagePromises)

  Promise.allSettled(promises)
    .then(results => {
      ;(async () => {
        await Storage.set({
          key: storageKey,
          value: moment().unix().toString(),
        })
      })()
    })
    .catch(err => {
      if (errorCbRef) errorCbRef(err)
    })
    .finally(() => {
      prefetchRunning = false
      if (notificationCbRef) notificationCbRef(null)
      if (syncCbRef) syncCbRef(false)
    })
}

const hasSyncWorkToDo = async () => {
  if (!Capacitor.isNative) return false

  const rows = await SQLiteService.getQueue()
  return rows && rows.length > 0
}

const forceSync = async () => {
  if (syncTimer) {
    clearTimeout(syncTimer)
    syncRunning = false
    await sync()
  }
}

let syncTimer = null
const sync = async () => {
  if (Capacitor.isNative) {
    const syncRunner = async () => {
      if (!_isOffline) {
        const rows = await SQLiteService.getQueue()
        if (rows && rows.length > 0) {
          if (syncCbRef) syncCbRef(true)
          if (notificationCbRef)
            notificationCbRef({
              type: 'START_SYNC',
              title: 'Syncing requests...',
              message:
                'Any offline requests you have made are being sent to the server.',
            })

          for (let i = 0; i < rows.length; i++) {
            if (_isOffline) break

            const row = rows[i]

            // Do we need to evict this one yet (5 days)?
            if (moment().unix() - row.created > 60 * 60 * 24 * 5) {
              await SQLiteService.dequeue(row.id)
            } else {
              // Let's try replaying it...
              try {
                // Swap out the Authorization Bearer token with the current one:
                if (
                  row.args &&
                  row.args.length >= 2 &&
                  row.args[1] &&
                  typeof row.args[1].headers !== 'undefined' &&
                  typeof row.args[1].headers.Authorization !== 'undefined'
                ) {
                  const authToken = await LoginService.getStoredAccessToken()
                  row.args[1].headers.Authorization = `Bearer ${authToken}`
                }

                const response = await fetch(row.args[0], row.args[1])
                if (response.status === 200) {
                  await SQLiteService.dequeue(row.id)
                } else {
                  await SQLiteService.requeue(row.id)
                }
              } catch (ex) {
                console.error(ex)
                await SQLiteService.requeue(row.id)
              }
            }
          }

          if (syncCbRef) syncCbRef(false)
          if (notificationCbRef) notificationCbRef(null)
        }
      }

      syncTimer = setTimeout(async () => {
        syncRunner()
      }, 1000 * 60 * 5)
    }

    if (!syncRunning) {
      syncRunning = true
      await syncRunner()
    }
  } else {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', event => {
        if (notificationCbRef)
          notificationCbRef({
            title: event.data.title,
            message: event.data.message,
            type: event.data.type,
          })

        if (event.data.type === 'START_SYNC') {
          if (syncCbRef) syncCbRef(true)

          syncTimer = setTimeout(() => {
            if (syncCbRef) syncCbRef(false)
            if (notificationCbRef) notificationCbRef(null)
          }, 1000 * 5)
        }
      })
    }
  }
}

const purge = async () => {
  if (!Capacitor.isNative) return

  await SQLiteService.init()
  await Storage.remove({ key: 'prefetchTimestamp' })
  await SQLiteService.purge()

  return true
}

const cancelAll = () => {
  if (typeof window !== 'undefined') {
    window.removeEventListener('offline', updateOnlineStatus)
    window.removeEventListener('online', updateOnlineStatus)
  }

  if (heartBeatTimer) {
    clearTimeout(heartBeatTimer)
    heartBeatTimer = null
  }

  if (syncTimer) {
    clearTimeout(syncTimer)
    syncTimer = null
  }

  offlineCbRef = null
  syncCbRef = null
  notificationCbRef = null
  errorCbRef = null

  heartbeatRunning = false
  prefetchRunning = false
  syncRunning = false
}

export default {
  init,
  registerFetchInterceptor,
  forceOfflineCheck,
  isOffline,
  prefetch,
  hasSyncWorkToDo,
  sync,
  forceSync,
  purge,
  cancelAll,
}
