import React from 'react'
import { Grid } from '@material-ui/core'

import MobileCardContent from 'components/MobileCardContent'
import MobileCardItem from 'components/MobileCardItem'
import { Link } from 'react-router-dom'

interface OwnProps {
  data: any
  showDivisions: boolean
}

export const MobileCard: React.FC<OwnProps> = ({
  data,
  showDivisions,
}: OwnProps) => {
  return (
    <MobileCardContent title={data.plantName}>
      <Grid container direction="row" spacing={1}>
        {showDivisions && (
          <MobileCardItem title="Division">
            {data.machine.plant.customer.division.name}
          </MobileCardItem>
        )}
        <MobileCardItem title="Customer">
          {data.machine.plant.customer.custName}
        </MobileCardItem>
        <MobileCardItem title="Plant">
          {data.machine.plant.plantName}
        </MobileCardItem>
        <MobileCardItem title="Route">{`${data.machine?.route?.routeNo} ${data.machine?.route?.name}`}</MobileCardItem>
        <MobileCardItem title="Mach No.">{data.machine.machNo}</MobileCardItem>
        <MobileCardItem title="Machine Name">
          <Link
            to={`/dart/data?labID=${data.lastSampleLabID}`}
            target="_blank"
            rel="noreferrer"
            style={{ color: '#6200EE' }}
          >
            {data.machine.machineName}
          </Link>
        </MobileCardItem>
        <MobileCardItem title="Equipment ID">
          {data.machine.custEquID}
        </MobileCardItem>
        <MobileCardItem title="Date Last Sampled">
          {data.lastSampleDate}
        </MobileCardItem>
        <MobileCardItem title="Days Since Last Sample">
          {data.daysSinceLastSample}
        </MobileCardItem>
      </Grid>
    </MobileCardContent>
  )
}
