import { GetRoutes, Route } from 'types/api'
import { Query } from './Query'
import { serialize } from 'utils/api-utilities'

export class GetRoutesQuery extends Query<GetRoutes, Route[]> {
  public get Url(): string {
    return `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Route${serialize(
      this.Data
    )}`
  }
}
