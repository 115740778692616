import React from 'react'

import { VirtualSelectFilter } from '../BaseFilters/VirtualSelectFilter'
import FilterTooltip from '../FilterTooltip/FilterTooltip'

type TProps = {
  single: boolean
  required: boolean
  machineNumbers: number[]
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange?: (props: number | number[]) => void
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setState?: (props: any) => void
  tooltip?: boolean
  value: number
}

export const MachineNumberFilter = ({
  machineNumbers,
  setState,
  single,
  required,
  onChange,
  value,
  tooltip = true,
}: TProps) => {
  if (setState == null && onChange == null) return

  const handleNumberChange = (selectedNumbers: number | number[]) => {
    if (onChange != null) {
      if (single === true) {
        onChange(selectedNumbers as number)
      } else {
        onChange(selectedNumbers as number[])
      }
    } else {
      if (single === true) {
        setState({ selectedMachineNo: selectedNumbers as number })
      } else {
        setState({ selectedMachineNos: selectedNumbers as number[] })
      }
    }
  }
  machineNumbers = machineNumbers.sort((a, b) => (a < b ? -1 : a === b ? 0 : 1))
  const filter = (
    <VirtualSelectFilter
      label="Machine Number"
      htmlFor="machineNumbers"
      multiple={!single}
      required={required}
      id="machineNumbers"
      value={value}
      renderValue={selected => selected?.toString()}
      onChange={handleNumberChange}
      options={machineNumbers}
    ></VirtualSelectFilter>
  )

  return tooltip ? (
    <FilterTooltip title="A machine's assigned numeric identifier.">
      {filter}
    </FilterTooltip>
  ) : (
    filter
  )
}
