import React from 'react'
import * as Styles from './index.module.css'
export type TFilterBoxProps = {
  title: string
  centered?: boolean
}

export const FilterBox = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<TFilterBoxProps>
>((props, ref) => {
  const renderElement = element => {
    if (!element) return null
    else return <div className={Styles.Filter}>{element}</div>
  }

  //NOTE: This statement prevents rendering of empty sections when all statements evaluate to false or all childen are null or undefined.
  if (
    (Array.isArray(props.children) && props.children.every(child => !child)) ||
    !props.children
  )
    return null

  return (
    <div className={Styles.FilterBox} ref={ref}>
      <h5 className={Styles.Title}>{props.title}</h5>
      <hr style={{ marginTop: '5px', marginBottom: '15px' }} />
      <div
        className={Styles.FilterList}
        style={
          props.centered ? { display: 'flex', justifyContent: 'center' } : {}
        }
      >
        {Array.isArray(props.children)
          ? props.children.map(child => {
              return renderElement(child)
            })
          : renderElement(props.children)}
      </div>
    </div>
  )
})
