import moment from 'moment'

import { TAPICorrectiveAction, TAPISample } from 'types/api'
import {
  lubeConditions,
  machineConditions,
  reportTypes,
} from 'services/lookup-service'
import getMachineTypeFromOptions, {
  TMachineTypeList,
} from 'utils/getMachineTypeFromOptions'

export const tableFormatter = (
  correctiveActions: TAPICorrectiveAction[],
  samples: TAPISample[],
  machineTypeOptions: TMachineTypeList[],
  hasDivisions: boolean
): string[][] => {
  const headerValues = [
    'Customer Review',
    'Sample Date',
    ...(hasDivisions ? ['Division Name'] : []),
    'Customer Name',
    'Plant Name',
    'Route Name',
    'Machine Number',
    'Machine Name',
    'Equipment ID',
    'Machine Type',
    'Lube Type',
    'Lab No.',
    'Report Type',
    'Machine Condition',
    'Lube Condition',
    'Feedback',
    'Notes',
    'Corrective Action Type',
    'Corrective Action',
    'Work Order Number',
    'New Oil',
    'Corrective Action Date',
    'Report Date'
  ]

  const excelMaxLength = 32500

  var maxLengthForFeedbackFieldFound = samples.map(s => s.latestFeedback?.feedback?.length ?? 0).sort((a, b) => b - a)[0]

  var columnsRequiredForFeedbackField = maxLengthForFeedbackFieldFound/excelMaxLength

  if (columnsRequiredForFeedbackField > 1)
    {
      var columnsToAdd = Math.ceil(columnsRequiredForFeedbackField)-1

      for (let i=1; i<columnsToAdd; i++)
      {
        headerValues.push(`Feedback ${i+1}`)
      }
    }

  return [
    headerValues,
    ...samples.map(sample => {
      
      var ret = [
      sample.reviewed ? 'Reviewed' : '',
      moment(sample.sampleDate).format('l'),
      ...(hasDivisions ? [sample.machine.plant.customer.division.name] : []),
      sample.machine.plant.customer.custName,
      sample.machine.plant.plantName,
      sample.machine.route !== null
        ? `${sample.machine.route.routeNo} - ${sample.machine.route.name}`
        : '',
      sample.machine.machNo,
      sample.machine.machineName,
      sample.machine.custEquID,
      getMachineTypeFromOptions({
        machineType: sample.machine.machType,
        machineTypeOptions: machineTypeOptions,
      }),
      sample.machine.lubricant?.lubricantName,
      sample.labID?.toString(),
      sample.reportType !== null
        ? reportTypes.find(y => y.value === sample.reportType).text
        : '',
      machineConditions.find(x => x.value === sample.machCond).text,
      lubeConditions.find(x => x.value === sample.lubCond).text,
      sample.latestFeedback === null ||
      sample.latestFeedback.feedback === null ||
      sample.latestFeedback.feedback === ''
        ? ''
        : sample.latestFeedback.feedback.substring(0, excelMaxLength),
      sample.rptNotes,
      sample.cuCorrAct !== null &&
      correctiveActions.findIndex(
        x => x.listValue === sample.cuCorrAct.cACode
      ) !== -1
        ? correctiveActions.find(x => x.listValue === sample.cuCorrAct.cACode)
            .listDesc
        : '',
      sample.cuCorrAct?.cANote,
      sample.cuCorrAct?.workOrderNumber,
      sample.cuCorrAct?.newOil,
      sample.cuCorrAct !== null ? moment(sample.cuCorrAct?.cADate).format('l') : '',
      moment(sample.reportDate).format('l'),
    ]

    var columnsRequiredForFeedbackField = (sample.latestFeedback?.feedback?.length ?? 0) /excelMaxLength
    if (columnsRequiredForFeedbackField > 1)
    {
      var columnsToAdd = Math.ceil(columnsRequiredForFeedbackField)-1

      for (let i=1; i<columnsToAdd; i++)
      {
        ret.push(sample.latestFeedback.feedback.substring(i*excelMaxLength, (i+1)*excelMaxLength))
      }
    }

    return ret
  }
    ),
  ]
}
