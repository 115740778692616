import * as PageStyles from '../page-styles.module.css'
import { PageCard } from '../Pages/PageContainers'
import { NoCustomerCardModule } from '../CardModules/NoCustomerCardModule'
import { useIsLoggedIn } from 'hooks/useIsLoggedIn'

type TProps = {
  canLoginToAssign: boolean
  encryptedBottleIdentifier?: string
}
export const BottleDataNoCustomer = ({
  canLoginToAssign,
  encryptedBottleIdentifier,
}: TProps) => {
  const isLoggedIn = useIsLoggedIn()
  return (
    <PageCard>
      <div
        className={`${PageStyles.EntryScreen} ${PageStyles.WithBottleButton} ${
          isLoggedIn.loggedIn ? PageStyles.LoggedIn : ''
        }`}
      >
        <NoCustomerCardModule
          canLoginToAssign={canLoginToAssign}
          encryptedBottleIdentifier={encryptedBottleIdentifier}
        />
      </div>
    </PageCard>
  )
}
