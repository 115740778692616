import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'

export const DashboardConditionDateFilter = props => {
  const { options, value, onChange, className, ...other } = props

  return (
    <FormControl className={className}>
      <InputLabel
        htmlFor="dashboardConditionDate"
        id="label-condition-select"
      ></InputLabel>
      <Select
        labelId="label-condition-select"
        id="dashboardConditionDate"
        value={value === null ? null : value.id}
        style={{ textAlign: 'left' }}
        renderValue={selected =>
          selected === null || options.findIndex(x => x.id === selected) === -1
            ? ''
            : options.find(x => x.id === selected)?.text
        }
        onChange={onChange}
        displayEmpty
        {...other}
      >
        {options.map(x => (
          <MenuItem key={x.id} value={x.id}>
            {x.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
