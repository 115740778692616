import XLSX from 'xlsx'

const CapacitorCore = require('@capacitor/core')

const Capacitor = CapacitorCore.Capacitor
const CapacitorFileService = require('./capacitor-file-service').default
const moment = require('moment')

const triggerDownload = (
  filename: string,
  contentType: string,
  data: BlobPart
): void => {
  if (Capacitor.isNative) {
    ;(async () => {
      await CapacitorFileService.saveAndOpen(filename, contentType, data)
    })()
  } else {
    if (typeof window === 'undefined') return
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(new Blob([data]), filename)
    } else {
      const url = window.URL.createObjectURL(
        new Blob([data], { type: contentType })
      )
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = filename
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    }
  }
}

export type TStylingRule = {
  columnName: string
  styler: (
    _cellValue: string | number,
    _cell: XLSX.CellObject
  ) => XLSX.CellObject
}

const exportExcel = (
  filename: string,
  data: string[][],
  summaryData?: (string | number)[][],
  footerData?: (string | number)[][],
  stylingRules?: TStylingRule[]
): void => {
  const input = [...(summaryData ?? []), ...data, ...(footerData ?? [])]

  const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(input)

  applyStylingRules(ws, stylingRules)
  ws['!cols'] = fitToColumn([data[0]])

  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'export')

  if (Capacitor.isNative) {
    const base64 = XLSX.write(wb, { type: 'base64' })
    ;(async () => {
      await CapacitorFileService.saveAndOpenBase64(
        filename,
        'application/vnd.openxmlformat',
        base64
      )
    })()
  } else {
    XLSX.writeFile(wb, filename)
  }
}

function fitToColumn(arrayOfArray): XLSX.ColInfo[] {
  return arrayOfArray[0].map((a, i) => ({
    wch: Math.max(...arrayOfArray.map(a2 => a2[i]?.toString().length ?? 0)),
  }))
}

function applyStylingRules(
  sheet: XLSX.WorkSheet,
  styleRules?: TStylingRule[]
): XLSX.WorkSheet {
  if (!styleRules || styleRules.length === 0) return sheet
  const range: XLSX.Range = XLSX.utils.decode_range(sheet['!ref'])

  // Each style rule applies to a header. Loop through each style rule to apply it
  styleRules.forEach(rule => {
    // Loop through each column in the range and compare its header to the styleRule's specified header.
    for (
      let columnIndex: number = range.s.c;
      columnIndex <= range.e.c;
      columnIndex++
    ) {
      const cell: XLSX.CellObject =
        sheet[XLSX.utils.encode_cell({ c: columnIndex, r: 0 })]
      if (cell && cell.t) {
        const headerName: string = XLSX.utils.format_cell(cell)

        // If the headers match, apply styling rule to each row.
        if (headerName === rule.columnName) {
          // Loop through each row in the column and apply styling rules to the cell.
          for (let row: number = range.s.r + 1; row < range.e.r; row++) {
            const cell: XLSX.CellObject =
              sheet[XLSX.utils.encode_cell({ c: columnIndex, r: row })]
            if (cell && cell.t) {
              const value: string = XLSX.utils.format_cell(cell)
              rule.styler.call(null, value, cell)
              // cell.s = {
              //   font: {
              //     underline: true,
              //     strikethrough: true,
              //     bold: true,
              //     color: "rgb(255, 0, 0)",
              //   }
              // }
              console.table(cell.s)
            }
          }
        }
        // if they do not match, continue to the next column.
      }
    }
  })
  return sheet
}

// Now we need to loop throw all rows in this column and push each value into the array.

// function getColumnNames(worksheet): string[]
//   {
//     const colNames: string[] = [];
//     const cells = Object.keys(worksheet);
//     for (let i = 0; i < Object.keys(cells).length; i++) {
//         if( cells[i].indexOf('1') > -1)
//         {
//           colNames.push(worksheet[cells[i]].v)
//         }
//     }
//     return colNames
//  }

function exportCSV(
  data: string[][],
  summaryData?: (string | number)[][],
  footerData?: (string | number)[][]
): BlobPart {
  let ret = ''

  ret = summaryData
    ? summaryData
        .map(x => x.map(y => `"${y?.toString().trim()}"`).join(','))
        .join('\n') + '\n'
    : ''

  ret += data
    .map(x => x.map(y => (y ? `"${y?.toString().trim()}"` : '')).join(','))
    .join('\n')

  ret += footerData
    ? '\n' +
      footerData
        .map(x => x.map(y => `"${y?.toString().trim() ?? ''}"`).join(','))
        .join('\n')
    : ''

  return ret
}

export const exportData = async (
  name: string,
  data: string[][],
  format: 'excel' | 'csv',
  summaryData?: (string | number)[][],
  footerData?: (string | number)[][],
  stylingRules?: TStylingRule[]
): Promise<void> => {
  switch (format) {
    case 'excel':
      exportExcel(
        `${name}-${moment().format('YYYYMMDDHHmmss')}.xlsx`,
        data,
        summaryData,
        footerData,
        stylingRules
      )
      break
    case 'csv':
      triggerDownload(
        `${name}-${moment().format('YYYYMMDDHHmmss')}.csv`,
        'text/csv',
        exportCSV(data, summaryData, footerData)
      )
      break
    default:
      break
  }
}
