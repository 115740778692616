type SiteMetaData = {
  title: string
  description: string
  author: string
  package: {
    name: string
    version: string
  }
  shortHash?: string
  branch?: string
}
export const useSiteMetadata = (): SiteMetaData => {
  const site = {
    siteMetadata: {
      description: 'TestOil DataSight',
      title: 'DataSight',
      author: 'Insight Services, Inc.',
      package: { name: 'ds', version: process.env.REACT_APP_SENTRY_BUILD },
    },
  }
  return site.siteMetadata
}

export default useSiteMetadata
