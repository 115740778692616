import Styles from './progress-stepper.module.css'
import { useMemo } from 'react'
import assert from 'assert'

export type TProgressMarker = {
  body: string
  label: string
  loading?: boolean
}

export type TProgressStepperProps = {
  markers: TProgressMarker[]
  step: number
}

const sliderSpeedMultiplier = 1000000
export const ProgressStepper = ({ markers, step }: TProgressStepperProps) => {
  assert(markers != null)
  assert(step >= 1 && step <= markers.length + 1)

  const barFillStyles = {
    width: indexToWidth(step, markers.length) + '%',
  }

  const indicators = useIndicators(markers, step)
  const sliders = useSliders(markers, step)
  return (
    <div className={Styles.barBody}>
      {indicators}
      <div className={Styles.bar}>
        <div className={Styles.barFill} style={barFillStyles}>
          <div className={Styles.fillContainer}>{sliders}</div>
        </div>
      </div>
    </div>
  )
}

function indexToWidth(index: number, maxIndex: number, maxWidth: number = 100) {
  return (index / (maxIndex + 1)) * maxWidth
}

//! Use memo may be slower here as there is no other state change occurring besides markers changing an step changing.
function useIndicators(markers: TProgressMarker[], step: number) {
  return useMemo(
    () =>
      markers.map((marker, index) => {
        const selected = index + 1 < step
        const active = index + 1 == step
        const indicatorClassName = `${Styles.indicator} ${
          selected ? Styles.selected : ''
        }`
        const labelClassName = `${Styles.indicatorLabel} ${
          active ? Styles.active : ''
        }`
        const indicatorStyle = {
          left: indexToWidth(index + 1, markers.length) + '%',
        }

        const labelStyle = {
          ...indicatorStyle,
          maxWidth: (1 / (markers.length + 1)) * 100 + '%',
        }

        const bod = !!marker.loading ? (
          <div className={Styles.loader} />
        ) : (
          marker.body
        )

        return (
          <div key={marker.body}>
            <div className={indicatorClassName} style={indicatorStyle}>
              {bod}
            </div>
            <div className={labelClassName} style={labelStyle}>
              {marker.label}
            </div>
          </div>
        )
      }),
    [markers, step]
  )
}

//! Use memo may be slower here as there is no other state change occurring besides markers changing an step changing.
function useSliders(markers: TProgressMarker[], step: number) {
  const sliderArr = new Array(markers.length + 1)
  sliderArr.fill(null)

  const sliderSpeed = sliderSpeedMultiplier / indexToWidth(step, markers.length)
  const animationDuration = sliderSpeed / sliderArr.length
  const sliderStyles = { animationDuration: animationDuration + 'ms' }
  return useMemo(
    () =>
      sliderArr.map((_, index) => {
        const animationDelay =
          (animationDuration * index) / sliderArr.length + 'ms'
        return (
          <div
            key={index}
            style={{ ...sliderStyles, animationDelay }}
            className={`${Styles.barSlider} ${Styles.barSlider}-${index + 1}`}
          />
        )
      }),
    [markers, step]
  )
}
