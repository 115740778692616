import { ScoreCard } from 'components/ScoreCard'
import React from 'react'
import { TSpendingEntity } from 'types/api'
import { useThemeContext } from 'Contexts/ThemeContext'
import { StringFormatter } from 'utils/string-utilities'
import { getYearToDateSpending } from 'utils/financial-utilities'

type TopSpendersCardProps = {
  spendingData: TSpendingEntity[]
  spenderType: 'Plant' | 'Customer'
  top: number
}

export const TopSpendingCard: React.FC<TopSpendersCardProps> = props => {
  const theme = useThemeContext()
  const now = new Date()

  const sortedSpendingData = props.spendingData
    .map(datum => ({
      ...datum,
      yearToDateSpending: getYearToDateSpending([datum]),
    }))
    .sort((a, b) => b.yearToDateSpending - a.yearToDateSpending)
    .slice(0, props.top)
  const actualTop =
    props.top >= sortedSpendingData.length
      ? sortedSpendingData.length
      : props.top

  const title = `Top ${actualTop} ${props.spenderType} Spenders`

  return (
    <ScoreCard
      toolTip={{
        title: title,
        text: `The top ${actualTop} highest spending ${props.spenderType.toLocaleLowerCase()}s. This includes all spending for the year of ${now.getFullYear()}.`,
      }}
    >
      <h2>
        <strong>{title}</strong>
      </h2>
      <table>
        <thead>
          <tr style={{textAlign: 'left'}}>
            <th style={{paddingLeft: '7px'}}>{props.spenderType}</th>
            <th style={{paddingLeft: '7px'}}>Amount Spent</th>
          </tr>
        </thead>
        <tbody>
          {sortedSpendingData.map((datum, index) => {
            return (
              <tr key={datum.id}>
                <td>{datum.name}</td>
                <td>{StringFormatter.formatAsUSD(datum.yearToDateSpending)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </ScoreCard>
  )
}
