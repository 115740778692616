import { TExceptionSet } from 'types/api'

export const tableFormatter = (
  data: TExceptionSet[],
  hasDivision: boolean
): string[][] => {
  const headerValues = [
    ...(hasDivision ? ['Division Name'] : []),
    'Customer Name',
    'Plant Name',
    'Total Samples',
    'Total Alarmed Samples',
    'Total Alarmed Machines',
    'Ferrous Wear',
    'Wear Metals',
    'Particle Count',
    'Water',
    'Viscosity',
    'Degradation',
    'Other',
  ]

  const formattedSampleSet = data.map(datum => [
    ...(hasDivision ? [datum.plant.customer.division.name] : []),
    datum.plant.customer.custName,
    datum.plant.plantName,
    datum.totalSamples?.toString(),
    datum.totalAlarmedSamples?.toString(),
    datum.totalAlarmedMachines?.toString(),
    datum.ferrousWear?.toString(),
    datum.wearMetals?.toString(),
    datum.particleCount?.toString(),
    datum.water?.toString(),
    datum.viscosity?.toString(),
    datum.degradation?.toString(),
    datum.other?.toString(),
  ])
  return [headerValues, ...formattedSampleSet]
}
