import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'
import AppLayout from 'components/AppLayout'
import { CustomerFilter } from 'components/filters'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import APIService from 'services/api-service'
import FilterLabel from 'components/filters/FilterLabel/FilterLabel'
import { useProfile } from 'Contexts/ProfileContext'
import { CancellablePromise } from 'utils/CancellablePromise'
import { MAXIMUM_SIGNED_INT } from '../constants'

const defaults = {
  selectedCustomers: [],
  routeID: null,
  routeNo: '',
  name: '',
  nameError: false,
  numberError: false,
  startingName: '',
  startingRouteNo: '',
}

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

const ManageRoute = ({ onError, offline }: any) => {
  const navigate = useNavigate()
  const [state, setState] = useState({
    ...defaults,
    mode: 'new',
    loaded: false,
    dataLoaded: false,
    customers: [],
    routes: [],
    custID: null,
    submitting: false,
  })

  const profileContext = useProfile()

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  const fetchRoute = useCallback(
    async routeID => {
      let promise
      try {
        promise = APIService.getRoute(routeID)

        ongoingCancellablePromises.push(promise)
        const data = await promise

        if (data.route !== null) {
          onError(null)
          setState(prev => ({
            ...prev,
            custID: data.route.customer.custID,
            routeID: routeID,
            routeNo: data.route.routeNo,
            name: data.route.name,
            startingName: data.route.name,
            startingRouteNo: data.route.routeNo,
            dataLoaded: true,
          }))
        }
      } catch (ex) {
        onError(ex)
        if (ex.message == 'UNAUTHORIZED_ACCESS') {
          navigate('/login?sessionexpired=true')
        }
      } finally {
        ongoingCancellablePromises.filter(p => p != promise)
      }
    },
    [onError]
  )

  const performSearch = useCallback(() => {
    if (window.location.search !== null) {
      const params = new URLSearchParams(window.location.search)
      if (params.has('routeID')) {
        const routeID = params.get('routeID')
        fetchRoute(routeID)
        return
      }

      setState(prev => ({
        ...prev,
        ...defaults,
        routeID: -1,
        dataLoaded: true,
      }))
    }
  }, [fetchRoute])

  useEffect(() => {
    if (!state.dataLoaded) {
      if (window.location.search !== null) {
        const params = new URLSearchParams(window.location.search)
        if (params.has('routeID')) {
          setState(prev => ({ ...prev, mode: 'edit' }))
        }
      }
      const custID: number =
        profileContext.profile.customers?.length <= 1
          ? profileContext.profile.customers[0]?.custID
          : null

      setState(prev => ({ ...prev, custID: custID, ...profileContext.profile }))
      performSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext.profile, state.loaded])

  useEffect(() => {
    setState(prev => ({
      ...prev,
      ...profileContext.profile,
      profileUpdated: profileContext.fullProfileLoaded,
    }))
  }, [profileContext])

  const handleCustIDChange = custID => {
    setState(prev => ({ ...prev, custID }))
  }

  useEffect(() => {
    validateRouteNumber(state.routeNo)
    validateRouteName(state.name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.custID])

  const validateRouteNumber = (routeNo: string) => {
    const numberError = state.routes
      .filter(r => r.custID === state.custID)
      .some(
        r =>
          r.routeNo.toString() !== state.startingRouteNo.toString() &&
          r.routeNo.toString().trim() === routeNo.toString().trim()
      )

    setState(prev => ({ ...prev, routeNo, numberError }))
  }

  const handleRouteNumberChange = event => {
    validateRouteNumber(event.target.value)
  }

  const validateRouteName = (routeName: string) => {
    const nameError = state.routes
      .filter(r => r.custID === state.custID)
      .some(
        r =>
          r.name.toLowerCase().trim() !== state.startingName.toLowerCase() &&
          r.name.toLowerCase().trim() === routeName.toLowerCase().trim()
      )

    setState(prev => ({ ...prev, name: routeName, nameError }))
  }
  const handleRouteNameChange = event => {
    validateRouteName(event.target.value)
  }

  const handleCancelClick = _event => {
    navigate('/program-management/routes')
  }

  const handleSubmit = async event => {
    event.preventDefault()

    if (state.submitting === false) {
      if (!state.numberError && !state.nameError) {
        setState(prev => ({ ...prev, submitting: true }))
        try {
          await APIService.saveRoute({
            route: {
              custID: state.custID ?? state.customers[0].custID,
              routeID: state.routeID,
              routeNo: state.routeNo,
              name: state.name,
            },
          })
          profileContext.utilities.reloadProfile() // Should edit profile here instead of reloading entire thing.

          navigate('/program-management/routes')
        } catch (ex) {
          onError(ex)
          setState(prev => ({ ...prev, submitting: false }))
        }
      }
    }
  }

  const handleDeleteClick = async _event => {
    let result = false
    if (typeof window !== 'undefined') {
      result = window.confirm('Are you sure you want to delete this route?')
    }

    if (!result) return

    try {
      await APIService.deleteRoute(state.routeID)
      profileContext.utilities.reloadProfile()
      navigate('/program-management/routes')
    } catch (ex) {
      onError(ex)
    }
  }

  const isValidNumber = event => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault()
    }
  }

  return (
    <Grid container justify="center">
      <Grid item md={4} lg={2}>
        <Box mb={2}>
          <Grid container>
            <Grid item xs={8}>
              <Typography variant="h1">
                {state.mode === 'new' ? 'Add New Route' : 'Edit Route'}
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              {state.mode === 'edit' ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDeleteClick}
                  disabled={!state.routeID}
                >
                  Delete
                </Button>
              ) : (
                ''
              )}
            </Grid>
          </Grid>

          {offline ? <OfflineBanner /> : <></>}

          {!state.dataLoaded || state.submitting ? (
            <Loader />
          ) : (
            <form method="post" onSubmit={handleSubmit}>
              <Grid
                container
                item
                style={{ marginTop: '15px' }}
                direction="column"
                spacing={2}
              >
                {state.customers.length > 1 ? (
                  <Grid item>
                    <CustomerFilter
                      single={true}
                      required={true}
                      customers={state.customers}
                      onChange={handleCustIDChange}
                      defaultValue={state.customers.find(
                        c => c.custID === state.custID
                      )}
                      value={
                        state.customers.find(
                          c => c.custID === state.custID
                        ) ?? {
                          custID: 0,
                          custName: '',
                        }
                      }
                    />
                  </Grid>
                ) : null}
                <Grid item>
                  <TextField
                    value={state.routeNo}
                    onChange={handleRouteNumberChange}
                    onKeyPress={isValidNumber}
                    style={{ width: 225 }}
                    required
                    type="number"
                    inputProps={{
                      min: 0,
                      max: MAXIMUM_SIGNED_INT,
                    }}
                    error={state.numberError}
                    label="Route Number"
                  />
                  {state.numberError && (
                    <FilterLabel error>
                      Route number must be unique
                      {state.customers.length > 1 ? ' within a customer' : ''}.
                    </FilterLabel>
                  )}
                  {Number.parseInt(state.routeNo) > MAXIMUM_SIGNED_INT && (
                    <FilterLabel error>
                      {'Route number must be less than or equal to ' +
                        MAXIMUM_SIGNED_INT}
                    </FilterLabel>
                  )}
                </Grid>
                <Grid item>
                  <TextField
                    value={state.name}
                    onChange={handleRouteNameChange}
                    style={{ width: 225 }}
                    required
                    error={state.nameError}
                    label="Route Name"
                  />
                  {state.nameError && (
                    <FilterLabel error>
                      Route name must be unique
                      {state.customers.length > 1 ? ' within a customer' : ''}.
                    </FilterLabel>
                  )}
                </Grid>
                <Grid
                  container
                  item
                  direction="row"
                  style={{ marginTop: '15px' }}
                  spacing={2}
                >
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      style={{ width: '100%' }}
                      color="secondary"
                      type="submit"
                      disabled={
                        !profileContext.minimumProfileLoaded ||
                        state.numberError ||
                        state.nameError ||
                        state.name === '' ||
                        state.routeNo === ''
                      }
                    >
                      Save
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      style={{ width: '100%' }}
                      color="secondary"
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default function Page() {
  return (
    <AppLayout tab="program-management">
      <ManageRoute />
    </AppLayout>
  )
}
