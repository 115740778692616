import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import ListIcon from '@material-ui/icons/List'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import NoteIcon from '@material-ui/icons/Note'
import PropTypes from 'prop-types'

import AppLayout from 'components/AppLayout'
import NavCard from 'components/NavCard'
import SEO from 'components/SEO'

const pages = [
  {
    url: '/technical-library/glossary',
    title: 'Glossary',
    description:
      'This glossary contains definitions for all of terms related to your oil analysis program that you may see.',
    background: '#1d2b3e',
    icon: <ListIcon />,
  },
  {
    url: '/technical-library-assets/Oil-Analysis-102.pdf',
    title: 'Oil Analysis Guide',
    description:
      'This document contains the fundamentals of lubrication and wear.',
    background: '#c18949',
    icon: <InfoIcon />,
    useHref: true,
  },
  {
    url: '/technical-library/oil-sampling-instructions',
    title: 'Oil Sampling Instructions',
    description: 'This guide will instruct you on sampling best practices.',
    background: '#d8d8d8',
    icon: <MenuBookIcon />,
  },
  {
    url: '/technical-library-assets/report%20key.pdf',
    title: 'Reading Your Report',
    description:
      'This document contains information on how to read the standard Industrial report.',
    background: '#83590a',
    icon: <NoteIcon />,
    useHref: true,
  },
  {
    url: '/technical-library-assets/Varnish%20Report%20Key.pdf',
    title: 'Reading A Varnish Report',
    description:
      'This document contains information on how to read the standard Varnish report.',
    background: '#d85611',
    icon: <NoteIcon />,
    useHref: true,
  },
  {
    url: '/technical-library-assets/DataSight%202.5%20User%20Documentation.pdf',
    title: 'DataSight User Guide',
    description:
      'This document contains information on how to find the information you need within the DataSight application.',
    background: '#efcd3c',
    icon: <LibraryBooksIcon />,
    useHref: true,
  },
]

function TechnicalLibrary() {
  return (
    <React.Fragment>
      <SEO title="Technical Library" />
      <Grid container direction="row" spacing={4}>
        <Grid item md={3}>
          <Typography variant="h1">Technical Library</Typography>
          <Box style={{ marginTop: '15px' }}>
            <Typography paragraph>
              You will find instructions and guides that will help you get the
              most out of your oil analysis program.
            </Typography>
          </Box>
        </Grid>
        <Grid container item direction="row" md={9} spacing={4}>
          {pages.map((page, i) => (
            <NavCard
              key={i}
              icon={page.icon}
              title={page.title}
              description={page.description}
              url={page.url}
              background={page.background}
              useHref={page.useHref}
            ></NavCard>
          ))}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

TechnicalLibrary.propTypes = {
  profile: PropTypes.object,
  onError: PropTypes.func,
}

export default function Page() {
  return (
    <AppLayout tab="technical-library">
      <TechnicalLibrary />
    </AppLayout>
  )
}
