import React, { useState } from 'react'
import { createStyles, Hidden, makeStyles } from '@material-ui/core'
import { orderBy, SortDescriptor } from '@progress/kendo-data-query'
import {
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'

import { TSalesInvoiceItem } from 'types/api'
import TableGrid from 'components/TableGrid'

import {
  RenderDate,
  RenderSalesInvoiceNumberLink,
  RenderSalesInvoiceOperations,
  RenderSalesInvoiceStatusLink,
} from '../KendoUIGridRenders'
import { DataUsageRounded } from '@material-ui/icons'
import MobileCard from './MobileCard'

const useTableStyles = makeStyles(() =>
  createStyles({
    table: {
      '& .k-grid-header tr:nth-child(1) .k-header': {
        padding: 5,
        margin: 0,
        verticalAlign: 'middle',
        backgroundColor: '#A8AFBA',
        color: 'white',
        height: '40px',
        textAlign: 'center',
        '& .k-cell-inner': {
          margin: 0,
        },
        '& .k-link': {
          margin: 0,
          padding: 0,
          display: 'block',
          '& span': {
            whiteSpace: 'initial',
          },
        },
        '& .MuiSvgIcon-root': {
          color: 'black',
          cursor: 'pointer',
        },
      },
      '& .k-grid-header tr:nth-child(2) .k-header': {
        backgroundColor: '#A8AFBA',
        textAlign: 'center',
        '& .k-link ': {
          display: 'flex',
          width: 'fit-content',
          '& .k-icon': {
            placeSelf: 'center',
          },
          '& .k-sort-order': {
            placeSelf: 'center',
          },
        },
      },
      '& .k-grid-container td': {
        padding: 5,
        margin: 0,
        textAlign: 'center',
      },
      '& .k-grid-header thead > tr:not(:only-child) > th': {
        borderColor: 'transparent',
      },
      '& a': {
        color: '#6200EE',
      },
      '& a:hover': {
        textDecoration: 'underline',
      },
      '& .highlight': {
        color: 'red',
        fontWeight: 600,
      },
      marginTop: 15,
    },
  })
)

interface TState {
  orderConfig: SortDescriptor[]
}

const defaults: TState = {
  orderConfig: [{ field: 'date', dir: 'desc' }],
}

export type TProps = {
  data: TSalesInvoiceItem[]
  selectedInvoices: TSalesInvoiceItem[]
  isDivision: boolean
  isMultiCustomer: boolean
  onSelectInvoice: (invoice: TSalesInvoiceItem, checked: boolean) => void
  onViewReceiptClick: (invoiceNumber: string) => void
  onInvoiceClick: (invoiceNumber: string) => void
}

interface PageState {
  skip: number
  take: number
}

const initialPageState: PageState = { skip: 0, take: 10 }

const SalesInvoices: React.FC<TProps> = React.memo(
  ({
    data,
    isDivision,
    onSelectInvoice,
    selectedInvoices,
    onViewReceiptClick,
    onInvoiceClick
  }: TProps) => {
    const tableClasses = useTableStyles()

    const [state, setState] = useState<TState>(defaults)
    const [page, setPage] = useState<PageState>(initialPageState)

    const handleSortChange = event => {
      setState(prev => ({ ...prev, orderConfig: [...event.sort] }))
    }

    const handlePageChange = (event: GridPageChangeEvent) => {
      setPage(event.page)
    }

    return (
      <>
        <Hidden mdUp>
        {!data?.length && (
          <p style={{ textAlign: 'center' }}>No invoices available</p>
        )}
          {data.map((invoice, index) => (
            <MobileCard
              key={`mobile-card-${index}`}
              data={invoice}
            />
          ))}
        </Hidden>
        <Hidden smDown>
          <TableGrid
            clearSortingButtonId="sales_invoices-clear_sorting_button"
            className={tableClasses.table}
            data={orderBy(data, state.orderConfig).slice(
              page.skip,
              page.take + page.skip
            )}
            sort={state.orderConfig}
            onSortChange={handleSortChange}
            rowHeight={50}
            skip={page.skip}
            take={page.take}
            total={data.length}
            onPageChange={handlePageChange}
          >
            {process.env.REACT_APP_ENVIRONMENT !== 'production' ? (
              <Column
                title="Select to Pay"
                cell={props =>
                  RenderSalesInvoiceOperations({
                    ...props,
                    selectedInvoices,
                    onSelectInvoice,
                    onViewReceiptClick,
                  })
                }
                filterable={false}
              />
            ) : null}
            <Column
              field="status"
              title="Status"
              cell={props => RenderSalesInvoiceStatusLink(props)}
              filterable={false}
            />

            {isDivision && <Column field="divisionName" title="Division" />}
            <Column field="customerName" title="Customer" />

            <Column
              field="invoiceNumber"
              title="Invoice Number"
              cell={props => RenderSalesInvoiceNumberLink({...props, onInvoiceClick: onInvoiceClick})}
            />
            <Column
              field="dueDate"
              title="Due Date"
              cell={props => RenderDate(props)}
              filterable={false}
            />
            <Column
              field="date"
              title="Date"
              cell={props => RenderDate(props)}
              filterable={false}
            />
            <Column
              field="amountDue"
              title="Amount Due"
              format="${0:n2}"
              filterable={false}
            />
            <Column
              field="amount"
              title="Amount"
              format="${0:n2}"
              filterable={false}
            />
            <Column field="customerPONumber" title="PO" />
          </TableGrid>
        </Hidden>
      </>
    )
  }
)

export default SalesInvoices
