import React from 'react'
import { Hidden } from '@material-ui/core'
import { GridColumn as Column } from '@progress/kendo-react-grid'

import { TAPISample } from 'types/api'
import TableGrid from 'components/TableGrid'

import {
  RenderDate,
  RenderLabNo,
  RenderLubeCondition,
  RenderMachineCondition,
  RenderMachineName,
  RenderMachineType,
} from '../KendoUIGridRenders'

import MobileCard from './MobileCard'
import { TMachineTypeList } from 'utils/getMachineTypeFromOptions'

export type TProps = {
  samples: TAPISample[]
  machineTypeOptions: TMachineTypeList[]
  loaded: boolean
}

const LastHundredSamples: React.FC<TProps> = React.memo(
  ({ samples, machineTypeOptions }: TProps) => {
    return (
      <>
        <Hidden mdUp>
          {!samples?.length && (
            <p style={{ textAlign: 'center' }}>No records available</p>
          )}
          {samples.map(x => (
            <MobileCard key={x.labID} sample={x} />
          ))}
        </Hidden>

        <Hidden smDown>
          <TableGrid
            clearSortingButtonId="last_hundred_samples-clear_sorting_button"
            data={samples}
            style={{ lineHeight: 1 }}
            clearSortingButton={<></>}
            pageable={false}
            pageSize={100}
          >
            <Column
              field="sampleDate"
              title="Sample Date"
              cell={props => <RenderDate {...props} />}
            />
            <Column field="machine.plant.plantName" title="Plant Name" />
            <Column field="machine.machNo" title="Mach No." />
            <Column
              field="machine.machineName"
              title="Machine Name"
              cell={props => <RenderMachineName {...props} />}
            />
            <Column field="machine.custEquID" title="Equipment ID" />
            <Column
              field="machCond"
              title="Mach"
              cell={props => <RenderMachineCondition {...props} />}
            />
            <Column
              field="lubCond"
              title="Lube"
              cell={props => <RenderLubeCondition {...props} />}
            />
            <Column
              field="labID"
              title="Lab No."
              cell={props => <RenderLabNo {...props} />}
            />
            <Column
              field="machine.machType"
              title="Machine Type"
              cell={props => (
                <RenderMachineType
                  {...props}
                  machineTypeOptions={machineTypeOptions}
                />
              )}
            />
            <Column field="machine.lubricant.lubricantName" title="Lube Type" />
          </TableGrid>
        </Hidden>
      </>
    )
  },
  (p, n) => n.loaded === p.loaded && p.samples?.length === n.samples?.length
)

export default LastHundredSamples
