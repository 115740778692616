import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { Capacitor } from '@capacitor/core'
import {
  Box,
  Button,
  debounce,
  FormControl,
  Grid,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import { Product, TAPIPlantContact, TSupplyOrderFree } from 'types/api'
import AppLayout from 'components/AppLayout'
import { CustomerFilter, PlantNameFilter } from 'components/filters'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import CapacitorFileService from 'services/capacitor-file-service'
import ShipmentInformationAlert from 'components/ShipmentInformationAlert'
import APIService from 'services/api-service'
import { useProfile } from 'Contexts/ProfileContext'
import ComponentMask from 'components/ComponentMask'
import { SupplyQuery } from 'models/api/SupplyQuery'
import { CancellablePromise } from 'utils/CancellablePromise'
import { nameToFirstAndLast, StringFormatter } from 'utils/string-utilities'
import { ProductForm, TrackedProduct } from 'components/ProductForm'
import {
  breakDownPricing,
  getApplicableProductPricing,
  getProductPrice,
} from 'utils/products'
import { useUserPreferences } from 'Contexts/UserPreferencesContext'
import { emptyPricing, freePricing } from 'utils/constants'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { ConnectionStatus } from 'types/custom.d'
import { FetchError } from 'utils/Errors/FetchError'
import { toast } from 'react-toastify'
import { Cancelable } from '@mui/utils/debounce'
import CustomerProductGroupEditingModal from 'components/Modals/CustomerProductGroupEditingModal'
import { useProductSocket } from 'hooks/useProductSocket'
import SuppliesPaymentModal from 'components/Modals/SuppliesPaymentModal/SuppliesPaymentModal'

const defaults = {
  plantID: 0,
  customerID: 0,
  contact: '',
  notes: '',
  defaultShippingAddress: true,
  email: '',
  address1: '',
  address2: '',
  address3: '',
  city: '',
  state: '',
  zip: '',
  submitting: false,
  submitted: false,
  addressLoaded: true,
  items: [],
  paymentModalOpened: false,
  productEditTargetCustomerID: null,
  editMode: false,
  groupsModalOpened: false,
}

const imageStore: Map<string, Blob> = new Map<string, Blob>()
const urlCreator = window.URL || window.webkitURL

const getSupplyImageAsync = async (fileName: string) => {
  if (!fileName) {
    return null
  }
  try {
    if (imageStore.has(fileName))
      return urlCreator.createObjectURL(imageStore.get(fileName))

    const image = await APIService.getSupplyImage(fileName)
    imageStore.set(fileName, image)

    return urlCreator.createObjectURL(image)
  } catch (err) {
    return null
  }
}

interface IState {
  [key: string]: any
  items: TrackedProduct[]
  productSearch: string
  productEditTargetCustomerID: number | null
  editMode: boolean
  groupsModalOpened: boolean
}

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

const Supplies: React.FC = ({ onError, offline }: any) => {
  const [state, setState] = useState<IState>({
    ...defaults,
    loaded: false,
    suppliesLoaded: false,
    plants: [],
    machineNames: [],
    productSearch: '',
  })

  const navigate = useNavigate()

  // Handle shipping information load.
  const [isShippingAlert, setIsShippingAlert] = useState(false)
  const profileContext = useProfile()
  const userPreferences = useUserPreferences()
  const [orderID, setOrderID] = useState<number | null>(null)

  const onOrderResponse = (orderID: number) => {
    setOrderID(orderID)
  }
  useEffect(() => {
    if (profileContext.minimumProfileLoaded) {
      // eslint-disable-next-line prefer-const
      let fewDaysAgo = new Date()
      fewDaysAgo.setDate(fewDaysAgo.getDate() - 3)
      fewDaysAgo.setHours(0, 0, 0, 0)

      //TODO: Rework api call to ds-xapi as cancellable promise.
      APIService.getCustomerShipmentInformation({
        customerIDs: profileContext.profile.customers?.map(c => c.custID),
        statuses: ['SHP'],
        from: fewDaysAgo.toISOString(), // currently alerts if shipment within last 3 days
      })
        .then(response => {
          setIsShippingAlert(!!response.items)
          setState(prev => ({ ...prev, loaded: true }))
        })
        .catch(_ex => {
          setIsShippingAlert(false)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext.minimumProfileLoaded])

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  useEffect(() => {
    connectionStatus
  }, [])

  const loadCustomerSupplies = async () => {
    if (!profileContext.minimumProfileLoaded) return
    const query: SupplyQuery = {
      customerIDs:
        state.editMode && state.productEditTargetCustomerID
          ? [state.productEditTargetCustomerID]
          : profileContext.profile.customers.map(c => c.custID),
      pricedOnly: !state.editMode,
    }
    let promise: CancellablePromise<Product[]>
    let trackedProducts: TrackedProduct[] = []
    try {
      promise = APIService.getSupplies(query)
      ongoingCancellablePromises.push(promise)
      const products = await promise

      const productImagePromises = products.map(p => ({
        product: p,
        image: getSupplyImageAsync(p.imageFileName),
      }))
      for (const result of productImagePromises) {
        let image: string = null
        try {
          image = await result.image
        } catch (err) {
          console.warn(err)
        }
        trackedProducts.push({
          product: result.product,
          allowInstructions: result.product.productName
            .toLowerCase()
            .includes('label'),
          instructions: '',
          quantity: '',
          image: image || '',
          value: result.product.productName,
        })
      }
      trackedProducts = trackedProducts.sort((p1, p2) =>
        p1.product.productName.localeCompare(p2.product.productName)
      )
    } catch (ex) {
    } finally {
      ongoingCancellablePromises.filter(p => p != promise)
      setState(prev => ({
        ...prev,
        suppliesLoaded: true,
        items: trackedProducts,
      }))
    }
  }

  useEffect(() => {
    if (!state.suppliesLoaded) loadCustomerSupplies()
  }, [profileContext.minimumProfileLoaded, state.suppliesLoaded])

  const loadedUnpricedSupplies = useRef(false)
  useEffect(() => {
    if (profileContext.minimumProfileLoaded && state.editMode) {
      if (!loadedUnpricedSupplies.current) {
        setState(p => ({ ...p, suppliesLoaded: false }))
        loadedUnpricedSupplies.current = true
      }
    }
  }, [state.productEditTargetCustomerID, state.editMode])

  const handleProductUpdateError = (error: FetchError) => {
    toast.error(
      <span>
        {error.status}: {error.name ?? error.message}
      </span>,
      {
        containerId: 'global',
      }
    )
  }

  useEffect(() => {
    ;(() => {
      if (!profileContext.minimumProfileLoaded) return
      const customers = profileContext.profile.customers
      const noCustomer = !customers || customers.length === 0

      if (noCustomer) {
        throw new Error('No customer data available!')
      }

      setState(p => ({
        ...p,
        productEditTargetCustomerID: profileContext.profile.customers[0].custID,
      }))
    })()
  }, [profileContext.minimumProfileLoaded])

  const { status: connectionStatus } = useProductSocket({
    onProductDeleteError: handleProductUpdateError,
    onProductPostError: handleProductUpdateError,
    onProductUpdateError: handleProductUpdateError,
    onProductPricingUpdate(_) {
      setState(prev => ({ ...prev, items: [...prev.items] }))
    },
  })

  const previousStatus = useRef(null)

  useEffect(() => {
    ;(() => {
      if (
        profileContext.dependentData.userDetails.isInternalUser &&
        previousStatus.current === ConnectionStatus.connected &&
        connectionStatus === ConnectionStatus.disconnected
      ) {
        toast.error(
          <span>Uh oh, you've been disconnected from the update server!</span>,
          {
            containerId: 'global',
          }
        )
      }

      if (
        profileContext.dependentData.userDetails.isInternalUser &&
        previousStatus.current === ConnectionStatus.disconnected &&
        connectionStatus === ConnectionStatus.connected
      ) {
        toast.success(<span>Reconnected to update server!</span>, {
          containerId: 'global',
        })
      }

      previousStatus.current = connectionStatus
    })()
  }, [connectionStatus])

  useEffect(() => {
    const loadedNothing =
      state.suppliesLoaded && (!state.items || state.items.length === 0)

    if (loadedNothing && connectionStatus === ConnectionStatus.connected) {
      setState(prev => ({ ...prev, suppliesLoaded: false })) //reload on reconnect if failed to load anything.
    }
  }, [connectionStatus])

  const canEditProducts =
    profileContext.dependentData.userDetails.isInternalUser

  const productsEditable =
    profileContext.dependentData.userDetails.isInternalUser && state.editMode

  const contactMap = useRef(new Map<number, TAPIPlantContact>())
  let promise =
    useRef<CancellablePromise<{ plantContactsByIDs: TAPIPlantContact[] }>>(null)

  useEffect(() => {
    const fetchAddresses = async () => {
      let result: { plantContactsByIDs: TAPIPlantContact[] } = null

      let plantContact = contactMap.current.get(state.plantID)

      if (!plantContact) {
        try {
          try {
            try {
              promise.current = APIService.getPlantShippingAddresses([
                state.plantID,
              ])
              ongoingCancellablePromises.push(promise.current)
              result = await promise.current

              plantContact =
                result.plantContactsByIDs?.length > 0
                  ? result.plantContactsByIDs[0]
                  : null
            } catch (ex) {
              setState(prev => ({
                ...prev,
                addressLoaded: true,
              }))
            } finally {
              ongoingCancellablePromises.filter(p => p != promise.current)
            }
          } catch (ex) {}
        } catch (ex) {}

        contactMap.current.set(state.plantID, plantContact)
      }

      setState(prev => ({
        ...prev,
        plantContact,
        email: plantContact?.email ?? '',
        address1: plantContact?.address1 ?? '',
        address2: plantContact?.address2 ?? '',
        address3: plantContact?.address3 ?? '',
        city: plantContact?.city ?? '',
        state: plantContact?.state ?? '',
        zip: plantContact?.zip ?? '',
        addressLoaded: true,
      }))
    }
    if (!state.addressLoaded && !state.plantID) {
      setState(prev => ({
        ...prev,
        addressLoaded: true,
        email: '',
        address1: '',
        address2: '',
        address3: '',
        city: '',
        state: '',
        zip: '',
      }))
    }

    if (!state.addressLoaded && state.plantID) fetchAddresses()
  }, [
    profileContext.profile.plants,
    state.plantID,
    state.addressLoaded,
    state.plants,
  ])

  const handleQuantityChange = (event, item) => {
    const value = event.target.value
    const remove = isNaN(value) || value <= 0
    const max = Math.round(Number.MAX_SAFE_INTEGER / Math.pow(2, 22))
    const hitMax = !remove && value >= max

    if (hitMax) {
      onError(
        'Quantity is too high, so we kept the last quantity. Bear in mind it should not be greater than ' +
          max
      )
    }

    setState(prev => ({
      ...prev,
      items: prev.items.map(x => {
        if (x.value === item.value && !hitMax) {
          x.quantity = remove ? '' : value
        }
        return x
      }),
    }))
  }

  function getSelectedProducts() {
    return state.items.filter(
      p => !isNaN(Number(p.quantity)) && Number(p.quantity) > 0
    )
  }

  const [disabledItemIDs, setDisabledItemIDs] = useState<Set<number>>(new Set())
  const [availableCustomerIDs, setAvailableCustomerIDs] = useState<Set<number>>(
    new Set()
  )

  const orderContainsInvalidItemsForSelectedCustomer =
    (state.items?.length > 0 &&
      (availableCustomerIDs == null || availableCustomerIDs.size === 0)) ||
    ((!!state.customerID || !!state.plantID) &&
      getSelectedProducts().some(sp =>
        disabledItemIDs.has(sp.product.productID)
      ))

  useEffect(() => {
    let customerIDList: number[] = null

    if (!state.customerID && !state.plantID) {
      const selectedItems = getSelectedProducts()

      const selectedProductGroupCustomerIDSets = selectedItems.map(
        i => new Set(i.product.productGroups.flatMap(g => g.customerIDs))
      )

      const customers = profileContext.profile.customers.filter(c =>
        selectedItems.every((p, i) =>
          selectedProductGroupCustomerIDSets[i].has(c.custID)
        )
      )

      customerIDList = customers.map(c => c.custID)
    } else if (!!state.customerID) {
      customerIDList = [state.customerID]
    } else {
      const customers = profileContext.profile.customers.filter(c =>
        c.plants.map(c => c.plantID).includes(state.plantID)
      )

      customerIDList = customers.map(c => c.custID)
    }

    const customerIDs = new Set<number>(customerIDList)

    setAvailableCustomerIDs(customerIDs)

    const itemIDSets = state.items.map(
      i => new Set(i.product.productGroups.flatMap(g => g.customerIDs))
    )

    const disabledItemList = state.items
      .filter(
        (_i, index) => !customerIDList.some(c => itemIDSets[index].has(c))
      )
      .map(i => i.product.productID)

    setDisabledItemIDs(new Set<number>(disabledItemList))
  }, [state.items, state.customerID, state.plantID])

  const handleInstructionsChange = (event, item) => {
    setState(prev => ({
      ...prev,
      items: prev.items.map(x => {
        if (x.value === item.value) {
          x.instructions = event.target.value
        }
        return x
      }),
    }))
  }

  const handleCustomerChange = async (customerID: number) => {
    setState(prev => ({
      ...prev,
      customerID: customerID ?? 0,
      plantID: 0,
      addressLoaded: false,
    }))
  }

  const handlePlantChange = async plant => {
    promise.current?.abortController?.abort()

    setState(prev => ({
      ...prev,
      plantID: plant?.plantID,
      addressLoaded: false,
    }))
  }

  const handleChange = event => {
    setState(prev => ({ ...prev, [event.target.name]: event.target.value }))
  }

  const handleSubmitOrder = async event => {
    event.preventDefault()

    const [total, _] = getTotalItemPriceAndCurrencyCode()
    if (total === 0) {
      handlePaymentDone()
    } else if (total > 0 && state.paymentModalOpened === false) {
      setState(prev => ({ ...prev, paymentModalOpened: true }))
    }
  }

  const handlePaymentDone = () => {
    if (state.submitting === false) {
      setState(prev => ({ ...prev, submitting: true }))
    }
  }

  const handlePaymentModalClose = (paymentProcessed: boolean) => {
    setState(prev => ({ ...prev, paymentModalOpened: false }))
  }

  const generateFreeSupplyOrderFromState = () => {
    const customerID = Array.from(availableCustomerIDs)[0]

    const plantNo = profileContext.profile.customers
      .find(cust => cust.custID === customerID)
      ?.plants.find(p => p.plantID === state.plantID)?.plantNo

    const [firstName, lastName] = nameToFirstAndLast(state.contact)
    return {
      customerID,
      customerSupplyNote: state.notes,
      plantNo,
      shipmentEmail: state.email,
      items: selectedItems.map(i => ({
        productID: i.product.productID,
        quantity: Number(i.quantity),
        instructions: i.instructions,
      })),
      firstName,
      lastName,
      shippingAddress: {
        address1: state.address1,
        address2: state.address2,
        address3: state.address3,
        city: state.city,
        state: state.state,
        zip: state.zip,
        country: state.country || 'USA',
        salesTaxCode: state.salesTaxCode,
      },
    } as TSupplyOrderFree
  }

  useEffect(() => {
    if (state.submitting) {
      ;(async () => {
        const supplyOrder = generateFreeSupplyOrderFromState()
        try {
          const orderInformation = await APIService.orderSuppliesFree(
            supplyOrder
          )
          onOrderResponse(orderInformation.orderID)

          setState(prev => ({
            ...prev,
            submitting: false,
            submitted: true,
          }))
        } catch (ex) {
          setState(prev => ({
            ...prev,
            submitting: false,
            submitted: false,
            items: prev.items.map(i => ({ ...i, quantity: '' })),
          }))

          onError(ex.message)
        }
      })()
    }
  }, [state.submitting])

  const selectedItems = state.items.filter(x => x.quantity && x.quantity != '0')
  const anItemIsSelected = selectedItems.length > 0
  const showDisclaimer =
    anItemIsSelected &&
    selectedItems.some(i => i.product.productPricing?.length === 0)

  const getTotalItemPriceAndCurrencyCode = (): [number, string] => {
    let currencyCode: string

    const total = state.items
      .filter(x => x.quantity)
      .map((x, i) => {
        const hasQuantitySelected = x.quantity && x.quantity != '0'

        const quantity = hasQuantitySelected ? Number.parseInt(x.quantity) : 0
        const productPrice = hasQuantitySelected
          ? getProductPrice(x.product, quantity)
          : 0

        if (currencyCode && currencyCode !== freePricing.currencyCode) {
          throw new Error(
            'Multiple currency codes detected. These items may not be combined together under one currency total.'
          )
        }

        currencyCode = freePricing.currencyCode
        return productPrice
      })
      .reduce((acc, next) => acc + next, 0)

    return [total, currencyCode]
  }

  const handleProductPricingEditClick = () => {
    setState(p => ({ ...p, editMode: true }))
  }

  const handleFinishEditingProductPricingClick = () => {
    setState(p => ({ ...p, editMode: false }))
  }

  const handleEdittedCustomerIDChange = (cid: number | undefined) => {
    if (typeof cid === 'undefined') return
    setState(p => ({ ...p, productEditTargetCustomerID: cid }))
  }

  const handleOpenEditGroupsModal = () => {
    setState(p => ({ ...p, groupsModalOpened: true }))
  }

  const productSearchDebounceRef = useRef<Cancelable | null>(null)
  const handleProductSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const func = () => {
      setState(prev => ({
        ...prev,
        productSearch: event.target.value?.toLowerCase(),
      }))
    }
    if (productSearchDebounceRef.current) {
      productSearchDebounceRef.current.clear()
    }

    const debounceFunc = debounce(func, 100)
    debounceFunc()
    productSearchDebounceRef.current = debounceFunc
  }

  const getTotalItemPriceFormatted = () => {
    try {
      const [total, currencyCode] = getTotalItemPriceAndCurrencyCode()
      return StringFormatter.formatAsCurrency(
        total,
        currencyCode,
        userPreferences.languagePreference
      )
    } catch (err) {
      const error = err as Error
      return ''
    }
  }
  const handleWhichBottleSizeClick = event => {
    const url = '/static/general-files/How%20Much%20is%20enough.pdf'

    if (Capacitor.isNative) {
      ;(async () => {
        const response = await fetch(url)
        const blob = await response.blob()
        await CapacitorFileService.saveAndOpen(
          'How Much is enough.pdf',
          'application/pdf',
          blob.arrayBuffer()
        )
      })()
    } else {
      if (typeof window !== 'undefined') {
        window.open(url, '_blank')
      }
    }
  }

  const customerIsSelected =
    state.customers?.length > 0 ? !!state.customerID : true

  const totalPrice = getTotalItemPriceAndCurrencyCode()[0]
  const totalPriceFormatted = getTotalItemPriceFormatted()

  const defaultContact = profileContext.billingCustomersLoaded
    ? profileContext.profile.billingCustomers?.filter(
        x => x.billToContact?.address !== null
      )[0]
    : null

  const handleGroupsModalClose = (hasChanges: boolean) => {
    setState(prev => ({
      ...prev,
      groupsModalOpened: false,
    }))

    if (hasChanges) {
      if (!state.editMode) loadedUnpricedSupplies.current = false

      setState(prev => ({
        ...prev,
        suppliesLoaded: false,
      }))
    }
  }

  const billingCustomerNotFound =
    profileContext.billingCustomersLoaded &&
    (!profileContext.profile.billingCustomers ||
      profileContext.profile.billingCustomers.length === 0)

  const billingCustomerNotAvailable =
    !profileContext.billingCustomersLoaded || billingCustomerNotFound

  return (
    <div style={{ position: 'relative' }}>
      <CustomerProductGroupEditingModal
        open={state.groupsModalOpened}
        onClose={handleGroupsModalClose}
        onError={onError}
        customer={
          customerIsSelected
            ? profileContext.profile.customers.find(
                c => c.custID === state.productEditTargetCustomerID
              )
            : profileContext.profile.customers.length > 0
            ? profileContext.profile.customers[0]
            : null
        }
      />
      {isShippingAlert ? <ShipmentInformationAlert /> : null}
      {state.submitting ? (
        <Grid container justify="center">
          <Grid item xs={12}>
            <Loader />
            <div
              style={{
                marginTop: '-115px',
                marginLeft: '15px',
                textAlign: 'center',
              }}
            >
              Submitting order...
            </div>
          </Grid>
        </Grid>
      ) : state.submitted ? (
        <Grid container justify="center">
          <Grid item md={6} lg={4}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h1">
                  Order Confirmation{orderID ? ` - ${orderID}` : ''}
                </Typography>
              </Grid>
              <Grid item>
                <Box my={2}>
                  We appreciate your continued business. Your order will be
                  shipped the next business day.
                </Box>
              </Grid>
              <Grid container item direction="row" spacing={2} xs={12}>
                <Grid container item direction="column" spacing={2} md={6}>
                  <Grid item>
                    <div>
                      {state.plants
                        .filter(x => x.plantID === state.plantID)
                        .map(x => x.plantName)
                        .join('')}
                    </div>
                    <div style={{ color: '#999' }}>Plant</div>
                  </Grid>
                  <Grid item>
                    <div>{state.contact}</div>
                    <div style={{ color: '#999' }}>Contact</div>
                  </Grid>
                  <Grid item>
                    <div>{state.notes || 'N/A'}</div>
                    <div style={{ color: '#999' }}>Notes</div>
                  </Grid>
                  <Grid item>
                    <div style={{ color: '#999' }}>Shipping Address</div>
                  </Grid>
                </Grid>
                <Grid container item direction="column" spacing={2} md={6}>
                  <Grid item>
                    <div>{state.email}</div>
                    <div style={{ color: '#999' }}>Email</div>
                  </Grid>
                  <Grid item>
                    <div>{state.address1}</div>
                    <div style={{ color: '#999' }}>Address 1</div>
                  </Grid>
                  {state.address2 ? (
                    <Grid item>
                      <div>{state.address2}</div>
                      <div style={{ color: '#999' }}>Address 2</div>
                    </Grid>
                  ) : (
                    ''
                  )}
                  {state.address3 ? (
                    <Grid item>
                      <div>{state.address3}</div>
                      <div style={{ color: '#999' }}>Address 3</div>
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Grid item>
                    <div>{state.city}</div>
                    <div style={{ color: '#999' }}>City</div>
                  </Grid>
                  <Grid item>
                    <div>{state.state}</div>
                    <div style={{ color: '#999' }}>State</div>
                  </Grid>
                  <Grid item>
                    <div>{state.zip}</div>
                    <div style={{ color: '#999' }}>Zip</div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            fontWeight: 'bold',
                            padding: '8px',
                          }}
                        >
                          Item
                        </TableCell>
                        <TableCell
                          width={'68px'}
                          style={{
                            fontWeight: 'bold',
                            padding: '8px',
                          }}
                        >
                          Quantity
                        </TableCell>
                        <TableCell
                          width={'82px'}
                          style={{
                            fontWeight: 'bold',
                            padding: '8px',
                          }}
                        >
                          Unit Price
                        </TableCell>
                        <TableCell
                          width={'71px'}
                          style={{
                            fontWeight: 'bold',
                            padding: '8px',
                          }}
                        >
                          Subtotal
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state.items.filter(x => x.quantity && x.quantity != '0')
                        .length > 0 ? (
                        <>
                          {state.items
                            .filter(x => x.quantity && x.quantity != '0')
                            .map((x, i) => {
                              const hasQuantitySelected =
                                x.quantity && x.quantity != '0'

                              let quantity = hasQuantitySelected
                                ? Number.parseInt(x.quantity)
                                : 0
                              const productPricing = hasQuantitySelected
                                ? getApplicableProductPricing(
                                    x.product?.productPricing,
                                    quantity
                                  ) || emptyPricing
                                : null

                              const pricingBreakdown = breakDownPricing(
                                x.product?.productPricing,
                                quantity
                              )

                              const showFreeRow =
                                pricingBreakdown.freePricing.quantity > 0
                              let freeRow = null
                              if (showFreeRow) {
                                freeRow = (
                                  <TableRow key={i}>
                                    <TableCell
                                      style={{
                                        padding: '8px',
                                      }}
                                    >
                                      {x.product.productName}
                                      {x.allowInstructions ? (
                                        <div
                                          style={{
                                            fontSize: '12px',
                                            color: '#999',
                                            textAlign: 'justify',
                                          }}
                                        >
                                          <strong>Instructions:</strong>{' '}
                                          {x.instructions}
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </TableCell>
                                    <TableCell style={{ padding: '8px' }}>
                                      {pricingBreakdown.freePricing.quantity}
                                    </TableCell>
                                    <TableCell style={{ padding: '8px' }}>
                                      {'Free'}
                                    </TableCell>
                                    <TableCell style={{ padding: '8px' }}>
                                      {'Free'}
                                    </TableCell>
                                  </TableRow>
                                )
                              }

                              const formattedUnitPrice = productPricing
                                ? StringFormatter.formatAsCurrency(
                                    productPricing.unitPrice,
                                    productPricing.currencyCode,
                                    userPreferences.languagePreference
                                  )
                                : StringFormatter.formatAsUSD(0)

                              const productPrice =
                                pricingBreakdown.paidPricing.quantity *
                                pricingBreakdown.paidPricing.price
                              const formattedProductPrice = productPricing
                                ? StringFormatter.formatAsCurrency(
                                    productPrice,
                                    productPricing.currencyCode,
                                    userPreferences.languagePreference
                                  )
                                : StringFormatter.formatAsUSD(0)

                              let paidRow = null
                              if (pricingBreakdown.paidPricing.quantity > 0) {
                                paidRow = (
                                  <TableRow key={i}>
                                    <TableCell
                                      style={{
                                        padding: '8px',
                                      }}
                                    >
                                      {x.product.productName}
                                      {x.allowInstructions ? (
                                        <div
                                          style={{
                                            fontSize: '12px',
                                            color: '#999',
                                            textAlign: 'justify',
                                          }}
                                        >
                                          <strong>Instructions:</strong>{' '}
                                          {x.instructions}
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </TableCell>
                                    <TableCell style={{ padding: '8px' }}>
                                      {pricingBreakdown.paidPricing.quantity}
                                    </TableCell>
                                    <TableCell style={{ padding: '8px' }}>
                                      {formattedUnitPrice}
                                    </TableCell>
                                    <TableCell style={{ padding: '8px' }}>
                                      {formattedProductPrice}
                                    </TableCell>
                                  </TableRow>
                                )
                              }

                              return (
                                <>
                                  {freeRow}
                                  {paidRow}
                                </>
                              )
                            })}
                          {anItemIsSelected && (
                            <TotalPriceDisplay>
                              {totalPriceFormatted}
                            </TotalPriceDisplay>
                          )}
                        </>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4}>No items selected</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <form
          method="post"
          onSubmit={handleSubmitOrder}
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
          id="order_supplies-form"
        >
          <Grid container justify="center">
            <Grid item md={12} lg={12}>
              <Box mb={4}>
                <Typography variant="h1">Order Supplies</Typography>
                <p>
                  {billingCustomerNotFound && (
                    <p style={{ color: 'red' }}>
                      No billing information found. Please contact customer
                      support at{' '}
                      <a
                        href={`mailto: ${process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL}?Subject=Billing%20Information%20Registration`}
                      >
                        {process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL}
                      </a>{' '}
                      before placing an order.
                    </p>
                  )}
                </p>
              </Box>
              {offline ? <OfflineBanner /> : <></>}

              <Grid
                container
                direction="row"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid
                  container
                  item
                  md={12}
                  lg={4}
                  xl={3}
                  direction="column"
                  spacing={2}
                >
                  <Grid item>
                    <Box mb={2}>
                      <strong>
                        Please indicate the quantity of selected items. Your
                        order will be shipped the next business day.
                      </strong>
                    </Box>

                    <Button
                      style={{ width: 225 }}
                      variant="contained"
                      color="primary"
                      onClick={handleWhichBottleSizeClick}
                    >
                      Which Bottle Size?
                    </Button>
                  </Grid>
                  <Grid item>
                    <Box mt={4} mb={0}>
                      <strong>Place Your Order</strong>
                    </Box>
                    {profileContext.dependentData.userDetails
                      .isMultiCustomerUser && (
                      <Grid item>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            marginBottom: '20px',
                          }}
                        >
                          <ComponentMask
                            loaded={profileContext.minimumProfileLoaded}
                            childRenderHeight={'50px'}
                            childRenderWidth="225px"
                            loaderType="line"
                          >
                            <CustomerFilter
                              single={true}
                              tooltip
                              required={true}
                              disabled={
                                !state.suppliesLoaded ||
                                billingCustomerNotAvailable
                              }
                              variant={'standard'}
                              customers={profileContext.profile.customers ?? []}
                              value={profileContext.profile.customers.find(
                                c => c.custID === state.customerID
                              )}
                              onChange={handleCustomerChange}
                            />
                          </ComponentMask>
                        </div>
                      </Grid>
                    )}
                    <Grid item>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <ComponentMask
                          loaded={profileContext.minimumProfileLoaded}
                          childRenderHeight={'50px'}
                          childRenderWidth="225px"
                          loaderType="line"
                        >
                          <PlantNameFilter
                            key={state.customerID}
                            disabled={
                              (!!!state.customerID &&
                                profileContext.dependentData.userDetails
                                  .isMultiCustomerUser) ||
                              billingCustomerNotAvailable
                            }
                            single={true}
                            required={true}
                            variant={'standard'}
                            plants={
                              profileContext.profile.plants.filter(p => {
                                const matchingCustomer =
                                  profileContext.profile.customers.find(
                                    c => c.custID === state.customerID
                                  )
                                return matchingCustomer
                                  ? matchingCustomer.plants
                                      .map(p => p.plantID)
                                      .includes(p.plantID)
                                  : true
                              }) ?? []
                            }
                            value={profileContext.profile.plants.find(p => {
                              const found = p.plantID === state.plantID
                              return customerIsSelected && found
                            })}
                            onChange={handlePlantChange}
                          />
                        </ComponentMask>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <FormControl>
                      <TextField
                        id="contactInput"
                        required
                        name="contact"
                        value={state.contact}
                        onChange={handleChange}
                        style={{ width: 225 }}
                        disabled={billingCustomerNotAvailable}
                        label="Contact"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl>
                      <TextField
                        multiline
                        rows={2}
                        variant="outlined"
                        name="notes"
                        value={state.notes}
                        onChange={handleChange}
                        label="Notes"
                        style={{ width: 335 }}
                        inputProps={{ maxLength: 255 }}
                        disabled={billingCustomerNotAvailable}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item></Grid>

                  <React.Fragment>
                    <Grid item>
                      <FormControl>
                        <TextField
                          id="emailInput"
                          required
                          name="email"
                          type="email"
                          value={state.email}
                          onChange={handleChange}
                          label="Email"
                          style={{ width: 225 }}
                          disabled={
                            !state.addressLoaded || billingCustomerNotAvailable
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl>
                        <TextField
                          id="addressOneInput"
                          required
                          name="address1"
                          value={state.address1}
                          onChange={handleChange}
                          label="Address 1"
                          style={{ width: 225 }}
                          disabled={
                            !state.addressLoaded || billingCustomerNotAvailable
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl>
                        <TextField
                          id="addressTwoInput"
                          name="address2"
                          value={state.address2}
                          onChange={handleChange}
                          label="Address 2"
                          style={{ width: 225 }}
                          disabled={
                            !state.addressLoaded || billingCustomerNotAvailable
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl>
                        <TextField
                          id="addressThreeInput"
                          name="address3"
                          value={state.address3}
                          onChange={handleChange}
                          label="Address 3"
                          style={{ width: 225 }}
                          disabled={
                            !state.addressLoaded || billingCustomerNotAvailable
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl>
                        <TextField
                          id="cityInput"
                          required
                          name="city"
                          value={state.city}
                          onChange={handleChange}
                          label="City"
                          style={{ width: 225 }}
                          disabled={
                            !state.addressLoaded || billingCustomerNotAvailable
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl>
                        <TextField
                          id="stateInput"
                          required
                          name="state"
                          value={state.state}
                          onChange={handleChange}
                          label="State"
                          style={{ width: 225 }}
                          disabled={
                            !state.addressLoaded || billingCustomerNotAvailable
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl>
                        <TextField
                          id="zipInput"
                          required
                          name="zip"
                          value={state.zip}
                          onChange={handleChange}
                          label="Zip"
                          style={{ width: 225 }}
                          disabled={
                            !state.addressLoaded || billingCustomerNotAvailable
                          }
                        />
                      </FormControl>
                    </Grid>
                  </React.Fragment>
                  <Grid item>
                    <TableContainer className="supplies-pricing-table">
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                fontWeight: 'bold',
                                padding: '8px',
                              }}
                            >
                              Item
                            </TableCell>
                            <TableCell
                              width={'68px'}
                              style={{
                                fontWeight: 'bold',
                                padding: '8px',
                              }}
                            >
                              Quantity
                            </TableCell>
                            <TableCell
                              width={'82px'}
                              style={{
                                fontWeight: 'bold',
                                padding: '8px',
                              }}
                            >
                              Unit Price
                            </TableCell>
                            <TableCell
                              width={'71px'}
                              style={{
                                fontWeight: 'bold',
                                padding: '8px',
                              }}
                            >
                              Subtotal
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {state.items.filter(x => x.quantity).length > 0 ? (
                            state.items
                              .filter(x => x.quantity && x.quantity != '0')
                              .map((x, i) => {
                                const hasQuantitySelected =
                                  x.quantity && x.quantity != '0'

                                let quantity = hasQuantitySelected
                                  ? Number.parseInt(x.quantity)
                                  : 0
                                const productPricing = hasQuantitySelected
                                  ? getApplicableProductPricing(
                                      x.product?.productPricing,
                                      quantity
                                    ) || emptyPricing
                                  : null

                                const pricingBreakdown = breakDownPricing(
                                  x.product?.productPricing,
                                  quantity
                                )

                                const showFreeRow =
                                  pricingBreakdown.freePricing.quantity > 0

                                let freeRow = null
                                if (showFreeRow) {
                                  freeRow = (
                                    <TableRow key={i}>
                                      <TableCell
                                        style={{
                                          padding: '8px',
                                        }}
                                      >
                                        {x.product.productName}
                                        {x.allowInstructions ? (
                                          <div
                                            style={{
                                              fontSize: '12px',
                                              color: '#999',
                                              textAlign: 'justify',
                                            }}
                                          >
                                            <strong>Instructions:</strong>{' '}
                                            {x.instructions}
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </TableCell>
                                      <TableCell style={{ padding: '8px' }}>
                                        {pricingBreakdown.freePricing.quantity}
                                      </TableCell>
                                      <TableCell style={{ padding: '8px' }}>
                                        {'Free'}
                                      </TableCell>
                                      <TableCell style={{ padding: '8px' }}>
                                        {'Free'}
                                      </TableCell>
                                    </TableRow>
                                  )
                                }

                                const formattedUnitPrice = productPricing
                                  ? StringFormatter.formatAsCurrency(
                                      pricingBreakdown.paidPricing.price,
                                      productPricing.currencyCode,
                                      userPreferences.languagePreference
                                    )
                                  : StringFormatter.formatAsUSD(0)

                                const productPrice =
                                  pricingBreakdown.paidPricing.quantity *
                                  pricingBreakdown.paidPricing.price
                                const formattedProductPrice = productPricing
                                  ? StringFormatter.formatAsCurrency(
                                      productPrice,
                                      productPricing.currencyCode,
                                      userPreferences.languagePreference
                                    )
                                  : StringFormatter.formatAsUSD(0)

                                let paidRow = null
                                if (pricingBreakdown.paidPricing.quantity > 0) {
                                  paidRow = (
                                    <TableRow key={i}>
                                      <TableCell
                                        style={{
                                          padding: '8px',
                                        }}
                                      >
                                        {x.product.productName}
                                        {x.allowInstructions ? (
                                          <div
                                            style={{
                                              fontSize: '12px',
                                              color: '#999',
                                              textAlign: 'justify',
                                            }}
                                          >
                                            <strong>Instructions:</strong>{' '}
                                            {x.instructions}
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </TableCell>
                                      <TableCell style={{ padding: '8px' }}>
                                        {pricingBreakdown.paidPricing.quantity}
                                      </TableCell>
                                      <TableCell style={{ padding: '8px' }}>
                                        {formattedUnitPrice}
                                      </TableCell>
                                      <TableCell style={{ padding: '8px' }}>
                                        {formattedProductPrice}
                                      </TableCell>
                                    </TableRow>
                                  )
                                }

                                return (
                                  <>
                                    {freeRow}
                                    {paidRow}
                                  </>
                                )
                              })
                          ) : (
                            <TableRow>
                              <TableCell colSpan={4}>
                                No items selected
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                      {anItemIsSelected && (
                        <TotalPriceDisplay id="total-price_selected-items">
                          {totalPriceFormatted}
                        </TotalPriceDisplay>
                      )}
                    </TableContainer>
                  </Grid>
                  <Grid item>
                    <FormControl style={{ marginTop: '10px' }}>
                      <Button
                        disabled={
                          orderContainsInvalidItemsForSelectedCustomer ||
                          state.items.filter(
                            x => x.quantity && x.quantity != '0'
                          ).length <= 0
                        }
                        style={{ width: 225 }}
                        variant="contained"
                        color="secondary"
                        type="submit"
                        id="supplies-action_payment_method_submit-button"
                      >
                        {totalPrice ? 'Select Payment Method' : 'Submit Order'}
                      </Button>
                    </FormControl>
                  </Grid>
                  {showDisclaimer && (
                    <StyledDisclaimerText>
                      *Free products are subject to order limitations determined
                      by customer contracts or customer needs per the discretion
                      of the Eurofins TestOil team.
                    </StyledDisclaimerText>
                  )}

                  {orderContainsInvalidItemsForSelectedCustomer && (
                    <StyledWarningText>
                      This order contains products that are not available to the
                      selected customer. If this is a mistake, please contact
                      the{' '}
                      <a
                        href={`mailto: ${process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL}?Subject=Equipment%20Registration`}
                      >
                        Eurofins TestOil support team
                      </a>
                      {profileContext.dependentData.userDetails
                        .isMultiCustomerUser &&
                        ' or change the selected customer to a customer with access to the selected products'}
                      .
                    </StyledWarningText>
                  )}
                </Grid>

                <Grid
                  container
                  item
                  md={12}
                  lg={8}
                  xl={9}
                  direction="row"
                  spacing={4}
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <Grid
                    container
                    xs={12}
                    justifyContent="flex-start"
                    spacing={2}
                    style={{ padding: '16px' }}
                  >
                    {canEditProducts && (
                      <Grid container item xs={12} sm={6} md={3}>
                        <Button
                          style={{ width: 225 }}
                          variant="contained"
                          color="secondary"
                          disabled={
                            !state.suppliesLoaded ||
                            connectionStatus !== ConnectionStatus.connected
                          }
                          onClick={
                            state.editMode
                              ? handleFinishEditingProductPricingClick
                              : handleProductPricingEditClick
                          }
                        >
                          {state.editMode
                            ? 'Finish Editing'
                            : 'Edit Product Pricing'}
                        </Button>
                      </Grid>
                    )}
                    {canEditProducts && (
                      <Grid container item xs={12} sm={6} md={3}>
                        <Button
                          style={{ width: 225 }}
                          variant="contained"
                          color="secondary"
                          disabled={!state.suppliesLoaded}
                          onClick={handleOpenEditGroupsModal}
                        >
                          Edit Product Groups
                        </Button>
                      </Grid>
                    )}
                    {canEditProducts &&
                      profileContext.dependentData.userDetails
                        .isMultiCustomerUser && (
                        <Grid container item xs={12} sm={6} md={3}>
                          <CustomerFilter
                            customers={profileContext.profile.customers ?? []}
                            defaultValue={profileContext.profile.customers[0]}
                            onChange={handleEdittedCustomerIDChange}
                            required={true}
                            variant={'standard'}
                            single
                            value={
                              state.productEditTargetCustomerID
                                ? profileContext.profile.customers.find(
                                    c =>
                                      c &&
                                      c.custID ==
                                        state.productEditTargetCustomerID
                                  )
                                : null
                            }
                          />
                        </Grid>
                      )}
                    <Grid container item xs={12} sm={6} md={3}>
                      <Input
                        name={'Product Search'}
                        type="text"
                        style={{ width: 225, height: 36 }}
                        disabled={!state.suppliesLoaded}
                        onChange={handleProductSearchChange}
                        placeholder={'Product Search'}
                        color="secondary"
                      />
                      {/* <Button
                          style={{ width: 225 }}
                          variant="contained"
                          color="secondary"
                          disabled={!state.suppliesLoaded}
                          onClick={
                            state.editMode
                              ? handleFinishEdittingProductPricingClick
                              : handleProductPricingEditClick
                          }
                        >
                          {state.editMode
                            ? 'Finish Editting'
                            : 'Edit Product Pricing'}
                        </Button> */}
                    </Grid>
                  </Grid>

                  {state.suppliesLoaded ? (
                    <>
                      {state.items
                        .filter(
                          i =>
                            i.product.productName
                              .toLowerCase()
                              .includes(state.productSearch) ||
                            i.product.productDescription
                              .toLowerCase()
                              .includes(state.productSearch)
                        )
                        .map(product => {
                          const productHasPricing =
                            product.product.productPricing &&
                            product.product.productPricing.length > 0
                          const showProduct =
                            productHasPricing || productsEditable

                          if (showProduct) {
                            return (
                              <ProductForm
                                disabled={
                                  ((!state.customerID && !state.plantID) ||
                                    Number(product.quantity) == 0) &&
                                  disabledItemIDs.has(product.product.productID)
                                }
                                warning={
                                  (!!state.customerID || !!state.plantID) &&
                                  disabledItemIDs.has(product.product.productID)
                                }
                                key={product.product.productID}
                                customerID={state.productEditTargetCustomerID}
                                productIsEditable={productsEditable}
                                showPricingTable={true}
                                trackedProduct={product}
                                onProductInstructionChange={
                                  handleInstructionsChange
                                }
                                onProductQuantityChange={handleQuantityChange}
                              />
                            )
                          }
                        })}
                    </>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}

      <SuppliesPaymentModal
        modalOpen={
          state.paymentModalOpened &&
          state.items.filter(x => x.quantity && x.quantity != '0').length > 0
        }
        supplies={state.items
          .filter(x => x.quantity)
          .map(x => {
            const quantityOrdered = Number(x.quantity)
            return {
              productName: x.product.productName,
              productDescription: x.product.productDescription,
              productID: x.product.productID,
              productPricing: x.product.productPricing,
              quantity: Number(x.quantity),
              selectedProductPrice: getApplicableProductPricing(
                x.product?.productPricing,
                quantityOrdered
              ),
            }
          })}
        supplyOrder={generateFreeSupplyOrderFromState()}
        onModalClose={handlePaymentModalClose}
        onOrderResponse={onOrderResponse}
        onPaymentDone={() =>
          setState(prev => ({
            ...prev,
            submitting: false,
            submitted: true,
          }))
        }
        billingCustomer={defaultContact}
      />
    </div>
  )
}

Supplies.propTypes = {
  profile: PropTypes.object,
  offline: PropTypes.bool,
  onError: PropTypes.func,
}

export default function Page() {
  return (
    <AppLayout tab="program-management">
      <Supplies />
    </AppLayout>
  )
}

type TTotalPriceDisplay = {
  id?: string
  children: ReactNode
}
const TotalPriceDisplay = (props: TTotalPriceDisplay) => {
  return (
    <TotalPriceContainer>
      <TotalText id={props.id}>Total: {props.children}</TotalText>
    </TotalPriceContainer>
  )
}

const StyledDisclaimerText = styled('p')`
  margin: 20px 0 0 0;
`

const StyledWarningText = styled('p')`
  color: red;
  margin: 20px 0 0 0;
`

const TotalText = styled('span')`
  font-size: 1.5rem;
  font-weight: 600;
`

const TotalPriceContainer = styled('div')`
  margin: 15px 0 0 0;
`
