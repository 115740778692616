import React from 'react'
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import AppLayout from 'components/AppLayout'
import SEO from 'components/SEO'

const useStyles = makeStyles({
  root: {
    marginTop: '25px',
  },
  media: {
    height: 150,
    backgroundSize: 'contain',
    marginTop: '15px',
    marginBottom: '15px',
  },
  photoMedia: {
    height: 150,
    marginTop: '15px',
    marginBottom: '15px',
  },
  ol: {
    paddingLeft: '15px',
  },
  li: {
    paddingTop: '20px',
    fontSize: '16px',
  },
})

function OilSamplingInstructions() {
  const classes = useStyles()

  return (
    <React.Fragment>
      <SEO title="Oil Sampling Instructions" />

      <Typography variant="h1">Oil Sampling Instructions</Typography>

      <Grid container direction="row" spacing={4}>
        <Grid item md={6} lg={3}>
          <Card className={classes.root}>
            <CardMedia
              className={classes.media}
              image="/static/technical-library-assets/sample-pump-method.gif"
              title="Sample Pump Method"
            />
            <CardContent>
              <Typography gutterBottom variant="h2" component="h2">
                Sample Pump Method
              </Typography>
              <Typography variant="body2" component="p">
                <ol className={classes.ol}>
                  <li className={classes.li}>
                    Cut new sample tube to desired length
                    <br />
                    <em>
                      Sample tube length should be the same each time sampled,
                      consider recording length for each component
                    </em>
                  </li>
                  <li className={classes.li}>
                    Insert sample tube into silver disk on top of pump so that
                    the tube protrudes from bottom of pump approximately 1/2
                    inch
                  </li>
                  <li className={classes.li}>
                    Tighten silver disk to secure tubing
                  </li>
                  <li className={classes.li}>
                    Remove sample bottle cap and secure the bottle to the sample
                    pump
                  </li>
                  <li className={classes.li}>
                    Insert sample tube into desired sample location so that tip
                    of tubing is in the middle phase of the oil
                  </li>
                  <li className={classes.li}>
                    Pull a vacuum on the pump by pulling the pump handle 1 to 3
                    times
                  </li>
                  <li className={classes.li}>
                    Allow the sample bottle to fill (you will not need to
                    continue to pull handle to maintain sample flow)
                  </li>
                  <li className={classes.li}>
                    Hold pump upright while taking sample
                  </li>
                  <li className={classes.li}>
                    When the sample bottle is 3/4 full, loosen the sample bottle
                    to release the vacuum and stop the sample flow
                  </li>
                  <li className={classes.li}>
                    Remove the sample bottle and re-cap the sample immediately
                  </li>
                  <li className={classes.li}>
                    Remove the used sample tubing from the pump and discard{' '}
                    <strong>(DO NOT RE-USE)</strong>
                  </li>
                  <li className={classes.li}>
                    Wipe the sample pump with a clean cloth to remove any
                    residual oil
                  </li>
                </ol>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} lg={3}>
          <Card className={classes.root}>
            <CardMedia
              className={classes.media}
              image="/static/technical-library-assets/drain-valve-method.gif"
              title="Drain Valve Method"
            />
            <CardContent>
              <Typography gutterBottom variant="h2" component="h2">
                Drain Valve Method
              </Typography>
              <Typography variant="body2" component="p">
                <ol className={classes.ol}>
                  <li className={classes.li}>
                    Drain ports should be used if other methods or ports are
                    unavailable since contamination is concentrated on the
                    bottom of the reservoir or sump.
                  </li>
                  <li className={classes.li}>
                    Open valve and allow oil to flow until any water or sediment
                    is flushed from the valve and pure oil begins to flow
                  </li>
                  <li className={classes.li}>
                    Remove cap from sample bottle and fill bottle to ¾ capacity
                  </li>
                  <li className={classes.li}>Replace cap immediately</li>
                  <li className={classes.li}>
                    Close valve and replace cap or plug
                  </li>
                </ol>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} lg={3}>
          <Card className={classes.root}>
            <CardMedia
              className={classes.media}
              image="/static/technical-library-assets/shipping-sample1.gif"
              title="Shipping Samples to Lab with TestOil's Preprinted Labels"
            />
            <CardContent>
              <Typography gutterBottom variant="h2" component="h2">
                Shipping Samples to Lab with TestOil's Preprinted Labels
              </Typography>
              <Typography variant="body2" component="p">
                <Box my={2}>
                  Click{' '}
                  <Link
                    to="/program-management/sample-labels/standard"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </Link>{' '}
                  to print your own labels
                </Box>
                <ol className={classes.ol}>
                  <li className={classes.li}>
                    Wipe sample bottles to remove residual oil
                  </li>
                  <li className={classes.li}>
                    Verify information on the label is correct:
                    <ol className={classes.ol}>
                      <li className={classes.li}>Customer number and name</li>
                      <li className={classes.li}>Machine name</li>
                      <li className={classes.li}>Machine type</li>
                      <li className={classes.li}>Lube manufacturer</li>
                      <li className={classes.li}>Test package</li>
                    </ol>
                  </li>
                  <li className={classes.li}>
                    Fill in the following areas (If known/applicable):
                    <ol className={classes.ol}>
                      <li className={classes.li}>Hours on oil</li>
                      <li className={classes.li}>Hours on equipment</li>
                      <li className={classes.li}>Oil change date</li>
                      <li className={classes.li}>Sample Date</li>
                    </ol>
                  </li>
                  <li className={classes.li}>
                    Affix label to bottle and plave in sample mailer or sturdy
                    box
                  </li>
                  <li className={classes.li}>
                    Ship to lab using preferred carrier
                  </li>
                </ol>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} lg={3}>
          <Card className={classes.root}>
            <CardMedia
              className={classes.photoMedia}
              image="/static/technical-library-assets/shipping-sample2.jpg"
              title="Shipping Samples to Lab without TestOil's Preprinted Labels"
            />
            <CardContent>
              <Typography gutterBottom variant="h2" component="h2">
                Shipping Samples to Lab without TestOil's Preprinted Labels
              </Typography>
              <Typography variant="body2" component="p">
                <Box my={2}>
                  Click{' '}
                  <Link
                    to="/program-management/sample-labels/standard"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </Link>{' '}
                  to print your own labels
                </Box>
                <Box my={2}>
                  If you are unable to print our labels, please include the
                  following information with each sample. This information is
                  vital to ensure that your samples are completed in a timely
                  manner.
                </Box>
                <ol className={classes.ol}>
                  <li className={classes.li}>Customer number and name</li>
                  <li className={classes.li}>Machine Name</li>
                  <li className={classes.li}>Machine Type</li>
                  <li className={classes.li}>Lube manufacturer</li>
                  <li className={classes.li}>Test package</li>
                </ol>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

OilSamplingInstructions.propTypes = {
  profile: PropTypes.object,
  onError: PropTypes.func,
}

export default function Page() {
  return (
    <AppLayout tab="technical-library">
      <OilSamplingInstructions />
    </AppLayout>
  )
}
