import React, { useCallback, useEffect, useState } from 'react'
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom'
import { Box, createTheme, Grid, Typography } from '@material-ui/core'
import { SortDescriptor } from '@progress/kendo-data-query'
import AppLayout from 'components/AppLayout'
import { ExportButton } from 'components/buttons'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import SEO from 'components/SEO'
import {
  DelinquentSamplesTable,
  tableFormatter,
} from 'components/tables/DelinquentSamples'
import APIService from 'services/api-service'
import { exportData } from 'services/export-service'
import { getSelectedCustomerIDs } from 'services/selection-service'
import coreTheme from '../../components/theme'
import { useProfile } from 'Contexts/ProfileContext'
import FilterAccordion, {
  TAccordionFilterValues,
} from 'components/FilterAccordion'
import { mapToPageParameters } from 'components/FilterAccordion/Utilities'
import {
  OptionsFilterSet,
  DateFilterSet,
  OrganizationalFilterSet,
  SampleFilterSet,
} from 'components/FilterAccordion/types'
import { CancellablePromise } from 'utils/CancellablePromise'
import moment from 'moment'

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

const initialStartDate = moment()
  .subtract(3, 'months')
  .startOf('month')
  .startOf('day')
  .toDate()

const initialEndDate = moment().endOf('month').endOf('day').toDate()
interface OwnProps {
  onError?: (_err: Error) => void // passed in from layout wrapper
  offline?: boolean
}
let isInitialLoad = true
const defaults = {
  daysDelinquent: 90,
  selectedDivisionIDs: [],
  selectedCustomerIDs: [],
  selectedPlants: [],
  selectedRoutes: [],
  exportFormat: '',
  loadedDefaults: false,
  startDate: initialStartDate,
  endDate: initialEndDate,
}

const DelinquentSamplesReport: React.FC<OwnProps> = ({
  onError,
  offline,
}: OwnProps) => {
  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  const [state, setState] = useState({
    ...defaults,
    dataLoaded: false,
    data: [],
    page: 1,
    pageSize: 10,
    count: 0,
    orderConfig: [{ field: 'lastSampleDate', dir: 'desc' }] as SortDescriptor[],
    searching: false as boolean,
  })

  const fetchSamples = useCallback(
    async variables => {
      setState(prev => ({
        ...prev,
        dataLoaded: false,
      }))
      let promise
      try {
        promise = APIService.getDelinquentSamples(variables)
        ongoingCancellablePromises.push(promise)
        const { analytics } = await promise

        if (analytics.delinquentSamples !== null) {
          setState(prev => ({
            ...prev,
            data: analytics.delinquentSamples.items,
            count: analytics.delinquentSamples.count,
            dataLoaded: true,
            searching: false,
          }))
          onError(null)
        }
      } catch (ex) {
        onError(ex)
      } finally {
        ongoingCancellablePromises.filter(p => p != promise)
      }
    },
    [onError]
  )

  const performSearch = useCallback(() => {
    const variables = {
      customerIDs: getSelectedCustomerIDs(
        profileContext.profile.customers,
        profileContext.profile.divisions,
        state.selectedDivisionIDs,
        state.selectedCustomerIDs
      ),
      plantIDs: state.selectedPlants,
      routeIDs: state.selectedRoutes,
      days: state.daysDelinquent,
      startDate: state.startDate,
      endDate: state.endDate,
      page: state.page,
      pageSize: state.pageSize,
      orderList: state.orderConfig.map(x => x.dir),
      orderByList: state.orderConfig.map(x => x.field),
    }
    fetchSamples(variables)
  }, [
    fetchSamples,
    state.daysDelinquent,
    state.orderConfig,
    state.page,
    state.pageSize,
    state.selectedCustomerIDs,
    state.selectedDivisionIDs,
    state.selectedPlants,
    state.selectedRoutes,
    state.startDate,
    state.endDate,
  ])

  useEffect(() => {
    return () => {
      isInitialLoad = true
    }
  }, [])

  useEffect(() => {
    if (!state.dataLoaded && !state.searching && state.loadedDefaults) {
      performSearch()
    }
    if (state.searching) {
      unstable_batchedUpdates(() => {
        performSearch()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dataLoaded, state.loadedDefaults, state.searching])

  const profileContext = useProfile()

  useEffect(() => {
    if (profileContext.dependentData.filters.minimumFilterOptionsLoaded) {
      setState(prev => ({
        ...prev,
        selectedCustomerIDs:
          profileContext.dependentData.filters.initialFilterState.customers.map(
            c => c.custID
          ),
        selectedDivisionIDs:
          profileContext.dependentData.filters.initialFilterState.divisions.map(
            d => d.iD
          ),
        loadedDefaults: true,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext.dependentData.filters.minimumFilterOptionsLoaded])

  const handleResetClick = () => {
    setState(prev => ({
      ...prev,
      ...defaults,
      count: 0,
      searching: true,
      selectedCustomerIDs:
        profileContext.dependentData.filters.initialFilterState.customers.map(
          c => c.custID
        ),
      selectedDivisionIDs:
        profileContext.dependentData.filters.initialFilterState.divisions.map(
          d => d.iD
        ),
      dataLoaded: false,
    }))
  }

  const handleExportFormatChange = async (event, format) => {
    let promise
    try {
      promise = APIService.getDelinquentSamples({
        customerIDs: getSelectedCustomerIDs(
          profileContext.profile.customers,
          profileContext.profile.divisions,
          state.selectedDivisionIDs,
          state.selectedCustomerIDs
        ),
        plantIDs: state.selectedPlants,
        routeIDs: state.selectedRoutes,
        days: state.daysDelinquent,
        startDate: state.startDate,
        endDate: state.endDate,
        orderList: state.orderConfig?.map(x => x.dir) ?? [],
        orderByList: state.orderConfig?.map(x => x.field) ?? [],
      })

      ongoingCancellablePromises.push(promise)
      const data = await promise
      const formatted = tableFormatter(
        data.analytics.delinquentSamples.items,
        profileContext.dependentData.userDetails.isDivisionUser
      )

      exportData('delinquent-samples', formatted, format.key)
    } catch (ex) {
      onError(ex)
    } finally {
      ongoingCancellablePromises.filter(p => p != promise)
    }
  }

  const handleSortChange = event => {
    const isFieldRemoved = state.orderConfig.length > event.sort.length
    let isLastFieldRemoved = false

    if (isFieldRemoved) {
      const fieldRemoved = state.orderConfig.filter(
        x => !event.sort.includes(x)
      )[0]

      isLastFieldRemoved =
        state.orderConfig.indexOf(fieldRemoved) === state.orderConfig.length - 1
    }

    setState(prev => ({
      ...prev,
      orderConfig: [...event.sort],
      searching: true,
      dataLoaded: !(event.sort.length > 0 && !isLastFieldRemoved),
    }))
  }

  const handlePageChange = event => {
    setState(prev => ({
      ...prev,
      page: event.page.skip / state.pageSize + 1,
      pageSize: event.page.take,
      searching: true,
      dataLoaded: false,
    }))
  }

  const handlePageChangeMobile = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage
  ) => {
    setState(prev => ({
      ...prev,
      page: newPage + 1,
      searching: true,
      dataLoaded: false,
    }))
  }

  const handlePageSizeChangeMobile = event => {
    setState(prev => ({
      ...prev,
      page: 1,
      pageSize: parseInt(event.target.value, 10),
      searching: true,
      dataLoaded: false,
    }))
  }

  const theme = createTheme(
    {
      overrides: {
        MuiGrid: {
          root: {
            justifyContent: 'flex-end',
            [coreTheme.breakpoints.down('sm')]: {
              justifyContent: 'space-between',
            },
          },
        },
      },
    },
    coreTheme
  )

  const handleFilterSubmission = (data: TAccordionFilterValues) => {
    const mappedParameters = mapToPageParameters(data)

    setState(prev => ({
      ...prev,
      ...mappedParameters,
      page: 1,
      searching: true,
      dataLoaded: false,
    }))
  }

  if (
    isInitialLoad &&
    ((profileContext.profile.divisions?.length > 0 &&
      state.selectedDivisionIDs.length === 0) ||
      (profileContext.profile.customers?.length > 0 &&
        state.selectedCustomerIDs.length === 0))
  ) {
    isInitialLoad = false
    return null
  }

  return (
    <>
      <SEO title="Delinquent Samples Report" />
      <Box mb={2}>
        <Typography variant="h1">Delinquent Samples</Typography>
      </Box>
      {offline ? <OfflineBanner /> : <></>}
      <FilterAccordion
        pageName="Delinquent Samples"
        defaultValues={profileContext.dependentData.filters.initialFilterState}
        onSubmit={handleFilterSubmission}
        onReset={handleResetClick}
        filters={{
          options: [OptionsFilterSet.Prefiltering],
          date: [DateFilterSet.FromDate, DateFilterSet.ToDate],
          organization: [OrganizationalFilterSet.All],
          machine: [],
          lubricant: [],
          sample: [SampleFilterSet.DaysDelinquent],
          miscellaneous: [],
        }}
      />
      <form>
        <Grid
          container
          direction="row"
          xs={12}
          spacing={1}
          justifyContent="flex-end"
          style={{ marginTop: '15px' }}
        >
          <Grid container item direction="row" spacing={1} justify="flex-end">
            <Grid item>
              <ExportButton onChange={handleExportFormatChange} disabled={state.data.length < 1}/>
            </Grid>
          </Grid>
        </Grid>
      </form>

      <Box mt={4}>
        {!state.dataLoaded ? (
          <Loader />
        ) : (
          <DelinquentSamplesTable
            data={state.data}
            orderConfig={state.orderConfig}
            page={state.page}
            pageSize={state.pageSize}
            count={state.count}
            onSortChange={handleSortChange}
            onPageChange={handlePageChange}
            onPageChangeMobile={handlePageChangeMobile}
            onPageSizeChangeMobile={handlePageSizeChangeMobile}
            offline={offline}
          />
        )}
      </Box>
    </>
  )
}

export default function Page() {
  return (
    <AppLayout tab="reports">
      <DelinquentSamplesReport />
    </AppLayout>
  )
}
