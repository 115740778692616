import { Checkbox, StylesProvider } from '@material-ui/core'
import { PreventableEvent } from '@progress/kendo-react-charts/dist/npm/events/preventable-event'
import { useThemeContext } from 'Contexts/ThemeContext'
import { useNonInitialEffect } from 'hooks/useNonInitialEffect'
import React, { useEffect, useState } from 'react'

import * as Styles from './index.module.css'

export type TCheckboxListPickerProps = {
  values: string[]
  title: string
  onChange: (_s: string[]) => void
}

type TIndexedPair = {
  index: number
  value: string
  selected: boolean
}

export const CheckboxListPicker: React.FC<TCheckboxListPickerProps> = (
  props: TCheckboxListPickerProps
) => {
  const [values, setValues] = useState<TIndexedPair[]>([] as TIndexedPair[])

  useNonInitialEffect(() => {
    if (values) props.onChange(values.filter(v => v.selected).map(v => v.value))
  }, [values])

  //start with full list
  useEffect(() => {
    setValues(
      props.values?.map(
        (v, i) => ({ index: i, value: v, selected: true } as TIndexedPair)
      ) || []
    )
  }, [!!props.values])

  const handleSelect = (index: number) => {
    const value = values?.find(p => p.index === index)
    value.selected = !value.selected
    setValues(prev => [...prev])
  }

  if (
    !props.values ||
    !values ||
    values.length === 0 ||
    props.values?.length === 0
  )
    return null

  const allSelected = values?.every(p => p.selected)

  const handleSelectAll = () => {
    values.forEach(v => {
      v.selected = allSelected ? false : true
    })

    setValues(prev => [...values])
  }

  const theme = useThemeContext()

  return (
    <div className={Styles.TableBox}>
      <div>
        <table className={Styles.TableStyles}>
          <thead>
            <tr>
              <th className={Styles.CenteredTableHeader}>
                <Checkbox
                  checked={allSelected}
                  onClick={() => handleSelectAll()}
                  style={{ color: theme.palette.pickers.pickerBlue }}
                />{' '}
              </th>

              <th className={Styles.CenteredTableHeader}>{props.title}</th>
            </tr>
          </thead>
          <tbody>
            {props.values?.map((v, i) => {
              return (
                <tr>
                  <td className={Styles.CenteredTableDefinition}>
                    <Checkbox
                      checked={values?.find(v => v.index === i).selected}
                      onClick={() => handleSelect(i)}
                      style={{ color: theme.palette.pickers.pickerBlue }}
                    />
                  </td>
                  <td className={Styles.PaddedTableDefinition}>
                    <span>{v.toString()}</span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
