import { Button } from '@material-ui/core'
import { useIsLoggedIn } from 'hooks/useIsLoggedIn'
import * as PageStyles from '../page-styles.module.css'
import { PageState } from '../types-and-enums'

type TProps = {
  page: PageState
  loading: boolean
  moveToInputPage: () => void
}
export const LoggedInNavButtons = (props: TProps) => {
  const { loading, loggedIn } = useIsLoggedIn()

  if (loading || !loggedIn) return null

  function handleNavigate() {
    const indexOfParams = queryParamSelector.exec(window.location.href)?.index
    if (indexOfParams) {
      window.history.pushState(
        null,
        null,
        window.location.href.substring(0, indexOfParams)
      )
    }
    if (props.moveToInputPage) {
      props.moveToInputPage()
    }
  }

  const disabled = props.page === PageState.BottleIdentifierEntry || loading

  return (
    <div className={PageStyles.LoggedInNavButtonContainer}>
      <Button
        variant="contained"
        color="primary"
        data-cancel
        fullWidth
        disabled={disabled}
        onClick={handleNavigate}
      >
        Find Another Bottle
      </Button>
    </div>
  )
}

const queryParamSelector = /\?.*/
