import { Popper, Fade } from '@material-ui/core'
import { styles } from '@material-ui/pickers/views/Calendar/Calendar'
import React, { useRef, useState } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import * as Styles from '../../index.module.css'
import { v4 as uuid } from 'uuid'
import { InformationIcon } from 'components/InformationIcon'

type TIntervalPicker = {
  interval: 'Monthly' | 'Quarterly'
  onIntervalChange: (interval: 'Monthly' | 'Quarterly') => void
}

export const IntervalPicker: React.FC<TIntervalPicker> = props => {
  const intervalAnchorRef = React.useRef<HTMLDivElement>(null)

  const monthlyPickerGUID = useRef(uuid())
  const quarterlyPickerGUID = useRef(uuid())

  const IntervalInformationPopper = () => {
    return (
      <InformationIcon style={{ color: 'rgb(50, 50, 50)' }}>
        <h5>Display Interval</h5>
        <hr />
        <p className={Styles.Text}>
          Adjust the spending averaging of the chart.
        </p>
        <h6 className={Styles.SubTitle}>Monthly</h6>
        <p className={Styles.Text}>
          Display spending for each month in the range.
        </p>
        <h6 className={Styles.SubTitle}>Quarterly</h6>
        <p className={Styles.Text}>
          Display spending for each quarter in the range. Quarters consist of
          three-month intervals, distributed evenly across the year.
        </p>
      </InformationIcon>
    )
  }

  return (
    <>
      <div className={Styles.SpendingChartPicker}>
        <div className={Styles.PickerHeader}>
          <h6 className={Styles.PickerHeaderText}>Display Interval</h6>
          <IntervalInformationPopper />
        </div>

        <div>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div className={Styles.LabeledInput}>
              <input
                type="radio"
                checked={props.interval === 'Monthly'}
                name={`interval-${monthlyPickerGUID.current}`}
                value="Monthly"
                id={monthlyPickerGUID.current}
                onChange={e =>
                  props.onIntervalChange(e.target.value as 'Monthly')
                }
              />{' '}
              <label htmlFor={monthlyPickerGUID.current}>Monthly</label>
            </div>
            <div className={Styles.LabeledInput}>
              <input
                type="radio"
                checked={props.interval === 'Quarterly'}
                name={`interval-${monthlyPickerGUID.current}`}
                value="Quarterly"
                id={quarterlyPickerGUID.current}
                onChange={e =>
                  props.onIntervalChange(e.target.value as 'Quarterly')
                }
              />{' '}
              <label htmlFor={quarterlyPickerGUID.current}>Quarterly</label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
