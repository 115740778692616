export const scheduleColumns = [
  { id: 'allMonths', text: 'All Months', sortable: true },
  { id: 'jan', text: 'Jan', sortable: true },
  { id: 'feb', text: 'Feb', sortable: true },
  { id: 'mar', text: 'Mar', sortable: true },
  { id: 'apr', text: 'Apr', sortable: true },
  { id: 'may', text: 'May', sortable: true },
  { id: 'jun', text: 'Jun', sortable: true },
  { id: 'jul', text: 'Jul', sortable: true },
  { id: 'aug', text: 'Aug', sortable: true },
  { id: 'sep', text: 'Sep', sortable: true },
  { id: 'oct', text: 'Oct', sortable: true },
  { id: 'nov', text: 'Nov', sortable: true },
  { id: 'dec', text: 'Dec', sortable: true },
]
