import * as PageStyles from '../../../../page-styles.module.css'
import { Button } from '@material-ui/core'

import { CustomerResponseDTO, GetPlantsResponseDTO } from 'types/api'

type TProps = {
  customerData: CustomerResponseDTO
  plantData: GetPlantsResponseDTO
  back: () => void
  next: () => void
}

export const AboutMachineCreation = (props: TProps) => {
  const buttons = (
    <div className={PageStyles.ButtonContainer}>
      <Button
        data-cancel
        variant="contained"
        color="primary"
        onClick={props.back}
        className={`${PageStyles.Button} ${PageStyles.Left}`}
      >
        Cancel
      </Button>

      <Button
        data-accept
        variant="contained"
        color="secondary"
        onClick={props.next}
        className={`${PageStyles.Button} ${PageStyles.Right}`}
      >
        Machine Info.
      </Button>
    </div>
  )

  return (
    <>
      <div>
        <h6 className={PageStyles.DetailSectionHeader}>Customer Information</h6>
        <section
          className={`${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer}`}
        >
          <ul className={PageStyles.Details}>
            <li>
              <span className={PageStyles.Property}>
                {props.customerData?.customerID}
                {' - '}
                {props.customerData?.customerName}
              </span>
            </li>
          </ul>
        </section>

        <h6 className={PageStyles.DetailSectionHeader}>Plant Information</h6>
        <section
          className={`${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer}`}
        >
          <ul className={PageStyles.Details}>
            <li>
              <span className={PageStyles.Property}>
                {props.plantData?.plantID}
                {' - '}
                {props.plantData?.plantName}
              </span>
            </li>
          </ul>
        </section>
        <h6 className={PageStyles.DetailSectionHeader}>About Registration</h6>
        <section className={PageStyles.DetailSection}>
          <p className={PageStyles.FinePrintParagraph}>
            Machine registration will register a new machine for the selected
            plant and customer. The registered machine will be treated as any
            other machine and will extend beyond sample registration: it will be
            visible and used for sample classification on Datasight, it will be
            retained for future sampling, and all relevant machine information
            will be available to users on Datasight with appropriate Datasight
            credentials. All tests performed on the machine according to the
            selected schedule will require funding, which will be retrieved from
            the appropriate billable entity. If applicable funding is not
            available, samples will held until such time as funds are made
            available. For questions, concerns, or assistance, please contact
            customer support at{' '}
            <a href={`mailto: ${process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL}`}>
              {process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL!}
            </a>
            {'.'}
          </p>
        </section>
      </div>
      {buttons}
    </>
  )
}
