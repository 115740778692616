import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useProfile } from './../../Contexts/ProfileContext/index'
import { Accordion } from '@material-ui/core'
import { AccordionButtons, AccordionHeader } from './FilterAccordionParts'
import {
  TAccordionFilterSelections,
  FilterSets,
  OptionsFilterSet,
  DateFilterSet,
  OrganizationalFilterSet,
  MachineFilterSet,
  LubricantFilterSet,
  SampleFilterSet,
  MiscellaneousFilterSet,
  FinancialsFilterSet,
  InvoiceStatus,
  ANDOR2,
} from './types'
import ComponentMask from '../ComponentMask'
import useSmartFilters from 'components/filters/SmartFilter/useSmartFilters'
import {
  TFilterState,
  TSmartFilter,
} from 'components/filters/SmartFilter/SmartFilterTypes'

import { FilterBox } from './FilterBox'
import {
  DaysDelinquentFilter,
  ExceptionCountFilter,
  FiscalYearStartMonthFilter,
  FrequencyFilter,
  FromDateFilter,
  LabNumberFilter,
  SampleStatusFilter,
  ToDateFilter,
} from 'components/filters'
import { SerialNumberFilter } from './../filters/ConfiguredFilters/SerialNumberFilter'
import { TSampleStatus } from 'components/filters/ConfiguredFilters/SampleStatusFilter'
import { TFrequency } from 'components/filters/ConfiguredFilters/FrequencyFilter'
import { TMonth } from 'components/filters/ConfiguredFilters/FiscalYearStartMonthFilter'
import { SampleDateFilter } from 'components/filters/ConfiguredFilters/SampleDateFilter'
import ShipmentStatusFilter from 'components/filters/ConfiguredFilters/ShipmentStatusFilter'
import OrderNumberFilter from 'components/filters/ConfiguredFilters/OrderNumberFilter'
import TrackingNumberFilter from 'components/filters/ConfiguredFilters/TrackingNumberFilter'
import { TShipmentStatus } from 'types/api'
import Loader from 'components/Loader'
import * as Styles from './index.module.css'
import { InvoiceNumberFilter } from 'components/filters/ConfiguredFilters/InvoiceNumberFilter'
import { PONumberFilter } from 'components/filters/ConfiguredFilters/PONumberFilter'
import { InvoiceStatusesFilter } from 'components/filters/ConfiguredFilters/invoice-statuses-filter'
import { RefSwitch } from 'components/filters/ConfiguredFilters/RefSwitch'
import {
  defaultFilterState,
  filterStateKeys,
} from 'components/filters/SmartFilter/constants'

export type TFilterAccordionProps = {
  pageName?: string
  defaultValues?: Partial<TAccordionFilterValues>
  onSubmit?: (_selectedValues: TAccordionFilterValues) => void
  onReset?: () => void
  filters?: FilterSets
  children?: (_filterProps: () => TAccordionFilterValues) => JSX.Element
}

export type TAccordionFilterValues = TAccordionFilterSelections & TFilterState

const FilterAccordion = (props: TFilterAccordionProps) => {
  /**The filters to render */
  let filters = props.filters
  let showAllFilters = false

  if (!filters) {
    showAllFilters = true
    filters = new FilterSets()
  }

  const profileContext = useProfile()

  //Set default values for filters from defaults passed in and standard default values.
  const defaultValues = {
    ...profileContext.dependentData.filters.defaultValues,
    ...props.defaultValues,
    machineLubeConditionANDOR: 'AND' as ANDOR2,
  }

  const [resetting, setResetting] = useState(false)
  const [resetTrigger, setResetTrigger] = useState(false)

  const renderInvoiceNumberFilter =
    showAllFilters ||
    filters.financials?.includes(FinancialsFilterSet.InvoiceNumber) ||
    filters.financials?.includes(FinancialsFilterSet.All)

  const renderPONumberFilter =
    showAllFilters ||
    filters.financials?.includes(FinancialsFilterSet.PurchaseOrderNumber) ||
    filters.financials?.includes(FinancialsFilterSet.All)

  const renderOrderNumberFilter =
    showAllFilters ||
    filters.miscellaneous?.includes(MiscellaneousFilterSet.OrderNumber) ||
    filters.miscellaneous?.includes(MiscellaneousFilterSet.All)

  const renderTrackingNumberFilter =
    showAllFilters ||
    filters.miscellaneous?.includes(MiscellaneousFilterSet.TrackingNumber) ||
    filters.miscellaneous?.includes(MiscellaneousFilterSet.All)

  const renderInvoiceStatusFilter =
    showAllFilters ||
    filters.financials?.includes(FinancialsFilterSet.InvoiceStatus) ||
    filters.financials?.includes(FinancialsFilterSet.All)

  const resetRefs = useCallback(() => {
    startDateRef.current = defaultValues.startDate
    endDateRef.current = defaultValues.endDate
    sampleDateRef.current = defaultValues.sampleDate
    onlyForSelectedMonthRef.current = {
      value: defaultValues.onlyForSelectedMonth,
    }
    reportDateSampleDateSwitch.current = {
      value: 'Sample Date' as 'Sample Date' | 'Report Date',
    }
    showReviewedReportsRef.current = { value: defaultValues.hideReviewed }
    hideCompletedSamplesRef.current = { value: defaultValues.hideCompleted }
    labNumberRef.current = defaultValues.labNumber
    serialNumberRef.current = defaultValues.serialNumber
    sampleStatusValueRef.current = defaultValues.sampleStatus
    frequencyRef.current = defaultValues.frequency
    fiscalYearStartMonthRef.current = defaultValues.fiscalYearStartMonth
    daysDelinquentRef.current = defaultValues.daysDelinquent
    exceptionCountRef.current = defaultValues.exceptionCount
    omitDayOfMonthRef.current = { value: defaultValues.omitDayOfMonth }
    omitFullDateRef.current = { value: defaultValues.omitFullDate }
    shipmentStatusRef.current = defaultValues.shipmentStatuses
    orderNumberRef.current = defaultValues.orderNumber
    trackingNumberRef.current = defaultValues.trackingNumber
    invoiceStatusRef.current = defaultValues.invoiceStatus

    if (renderInvoiceNumberFilter)
      invoiceNumberRef.current.value = defaultValues.invoiceNumber ?? ''

    if (renderPONumberFilter)
      purchaseOrderNumberRef.current.value =
        defaultValues.purchaseOrderNumber ?? ''

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const reset = () => {
    setResetting(true)
    smartFilters.reset()
    setResetTrigger(!resetTrigger)
  }
  //Perform this effect after the filter values have been reset.
  useEffect(() => {
    if (resetting) {
      if (props.onReset) props.onReset()

      resetRefs()
      setResetting(false)
    }
  }, [props, resetRefs, resetTrigger, resetting])

  //SECTION: Refs and state values
  const [layoutRendered, setLayoutRendered] = useState(false)
  const organizationRef = useRef<HTMLDivElement>()
  useLayoutEffect(() => {
    setLayoutRendered(true)
  }, [])

  const machineLubeConditionANDORRef = useRef<{ value: ANDOR2 }>({
    value: defaultValues.machineLubeConditionANDOR,
  })
  const startDateRef = useRef<Date>(defaultValues.startDate)
  const endDateRef = useRef<Date>(defaultValues.endDate)
  const sampleDateRef = useRef<Date>(defaultValues.sampleDate)
  const onlyForSelectedMonthRef = useRef({
    value: defaultValues.onlyForSelectedMonth,
  })

  const reportDateSampleDateSwitch = useRef({
    value: 'Sample Date' as 'Sample Date' | 'Report Date',
  })
  const showReviewedReportsRef = useRef({ value: defaultValues.hideReviewed })
  const hideCompletedSamplesRef = useRef({
    value: defaultValues.hideCompleted,
  })
  const labNumberRef = useRef(defaultValues.labNumber)
  const invoiceStatusRef = useRef(defaultValues.invoiceStatus)
  const serialNumberRef = useRef(defaultValues.serialNumber)
  const sampleStatusValueRef = useRef<number | null>(defaultValues.sampleStatus)
  const frequencyRef = useRef<TFrequency | null>(defaultValues.frequency)
  const fiscalYearStartMonthRef = useRef<TMonth>(
    defaultValues.fiscalYearStartMonth
  )
  const daysDelinquentRef = useRef<number | null>(defaultValues.daysDelinquent)
  const exceptionCountRef = useRef<number | null>(defaultValues.exceptionCount)
  const omitDayOfMonthRef = useRef({ value: defaultValues.omitDayOfMonth })
  const omitFullDateRef = useRef({ value: defaultValues.omitFullDate })
  const standardSampleDateRef = useRef<HTMLInputElement>()
  const shipmentStatusRef = useRef(defaultValues.shipmentStatuses)
  const orderNumberRef = useRef(defaultValues.orderNumber)
  const trackingNumberRef = useRef(defaultValues.trackingNumber)

  const invoiceNumberRef = useRef<HTMLInputElement>()
  const purchaseOrderNumberRef = useRef<HTMLInputElement>()

  const [prefiltering, setPrefiltering] = useState(true)
  //!SECTION

  //SECTION Handlers
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const data = getAccordionDataSet()

    if (props.onSubmit) {
      props.onSubmit(data)
    }
  }
  //only get object  values if the original state has this object values

  const smartFilterInitialState = {} as Partial<TFilterState>

  for (const key in defaultValues) {
    if (filterStateKeys.includes(key)) {
      smartFilterInitialState[key] = defaultValues[key]
    }
  }

  const smartFilters: TSmartFilter = useSmartFilters({
    filterOptions: {
      ...defaultFilterState,
      ...profileContext.dependentData.filters.filterOptions,
    },
    initialState: smartFilterInitialState,
    disableOptionFiltering: !prefiltering,
  })

  const handleReset = () => {
    setPrefiltering(true)
    reset()
  }

  const handleFromDateChange = (val: moment.Moment) => {
    const dateVal = val?.toDate()
    startDateRef.current = dateVal
  }

  const handleToDateChange = (val: moment.Moment) => {
    const dateVal = val?.hours(0).minutes(0).toDate() ?? new Date(Date.now())
    endDateRef.current = dateVal
  }

  const handleSampleDateChange = (val: moment.Moment) => {
    const dateVal = val?.toDate()
    sampleDateRef.current = dateVal
  }

  const handleLabNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    labNumberRef.current = event.target.value
  }

  const handleInvoiceStatusChange = (
    selected: InvoiceStatus | InvoiceStatus[]
  ) => {
    if (Array.isArray(selected)) {
      if (selected.length > 0) invoiceStatusRef.current = selected[0]
      else invoiceStatusRef.current = null
    } else {
      invoiceStatusRef.current = selected
    }
  }

  const handleSerialNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    serialNumberRef.current = event.target.value
  }

  const handleSampleStatusChange = (sampleStatus: TSampleStatus) => {
    sampleStatusValueRef.current = sampleStatus?.value ?? null
  }

  const handleFrequencyChange = (frequency: TFrequency) => {
    frequencyRef.current = frequency ?? null
  }

  const handleFiscalYearStartMonthChange = (month: TMonth) => {
    fiscalYearStartMonthRef.current = month
  }

  const handlePrefilteringSwitch = (val: boolean) => {
    smartFilters.reset()
    setPrefiltering(val)
  }

  const handleDaysDelinquentChange = (days: number | null) => {
    daysDelinquentRef.current = days
  }

  const handleExceptionCountChange = (exceptions: number | null) => {
    exceptionCountRef.current = exceptions
  }

  const handleShipmentStatusChange = (shipmentStatus: TShipmentStatus[]) => {
    shipmentStatusRef.current = shipmentStatus
  }

  const handleOrderNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    orderNumberRef.current = event.target.value
  }

  const handleTrackingNoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    trackingNumberRef.current = event.target.value
  }

  //!SECTION

  const getAccordionDataSet = () => {
    const dataset = {
      startDate: startDateRef.current || new Date(1970, 0),
      endDate:
        endDateRef.current ||
        (() => {
          const date = new Date(Date.now())
          return new Date(date.getFullYear() + 1000, 0)
        })(),
      showSampleDate:
        reportDateSampleDateSwitch.current?.value === 'Sample Date',
      showReportDate:
        reportDateSampleDateSwitch.current?.value === 'Report Date',
      labNumber: labNumberRef.current,
      machineLubeConditionANDOR: machineLubeConditionANDORRef.current.value,
      hideCompleted: hideCompletedSamplesRef.current?.value,
      hideReviewed: showReviewedReportsRef.current?.value,
      serialNumber: serialNumberRef.current,

      //SECTION Page specific filters

      //SECTION Sample Status
      sampleStatus: sampleStatusValueRef.current,
      //!SECTION

      //SECTION Change of Conditions
      frequency: frequencyRef.current,
      fiscalYearStartMonth: fiscalYearStartMonthRef.current,
      //start month
      //!SECTION

      //SECTION Days Delinquent
      daysDelinquent: daysDelinquentRef.current,
      //!SECTION

      //SECTION Recurring Exceptions
      exceptionCount: exceptionCountRef.current,
      //!SECTION

      //SECTION Standard Sample Labels
      omitDayOfMonth: omitDayOfMonthRef.current.value,
      omitFullDate: omitFullDateRef.current.value,
      standardSampleLabelDate: standardSampleDateRef.current?.valueAsDate,
      onlyForSelectedMonth: onlyForSelectedMonthRef.current.value,
      //!SECTION

      //SECTION Shipments
      shipmentStatuses: shipmentStatusRef.current,
      sampleDate: sampleDateRef.current,
      orderNumber: orderNumberRef.current,
      trackingNumber: trackingNumberRef.current,
      //!SECTION

      //SECTION Financials
      invoiceNumber: invoiceNumberRef.current?.value,
      purchaseOrderNumber: purchaseOrderNumberRef.current?.value,
      invoiceStatus: invoiceStatusRef.current ?? null,
      //!SECTION

      //!SECTION
      ...smartFilters.filterState,
    } as TAccordionFilterValues
    return dataset
  }

  const labNumberFilter = React.useMemo(
    () => (
      <ComponentMask
        childRenderHeight="40px"
        childRenderWidth="225px"
        loaderType="line"
        loaded={true}
      >
        <LabNumberFilter
          defaultValue={defaultValues.labNumber}
          onChange={handleLabNoChange}
          // @ts-ignore
          key={resetTrigger}
        />
      </ComponentMask>
    ),
    [resetTrigger, defaultValues.labNumber]
  )

  const serialNumberFilter = React.useMemo(
    () => (
      <ComponentMask
        childRenderHeight="40px"
        childRenderWidth="225px"
        loaderType="line"
        loaded={true}
      >
        <SerialNumberFilter
          defaultValue={defaultValues.serialNumber || undefined}
          onChange={handleSerialNoChange}
          // @ts-ignore
          key={resetTrigger}
        />
      </ComponentMask>
    ),
    [resetTrigger, defaultValues.serialNumber]
  )

  const orderNumberFilter = React.useMemo(
    () => (
      <ComponentMask
        childRenderHeight="40px"
        childRenderWidth="225px"
        loaderType="line"
        loaded={true}
      >
        <OrderNumberFilter
          defaultValue={defaultValues.orderNumber || undefined}
          onChange={handleOrderNoChange}
          // @ts-ignore
          key={resetTrigger}
        />
      </ComponentMask>
    ),
    [resetTrigger, defaultValues.orderNumber]
  )

  const trackingNumberFilter = React.useMemo(
    () => (
      <ComponentMask
        childRenderHeight="40px"
        childRenderWidth="225px"
        loaderType="line"
        loaded={true}
      >
        <TrackingNumberFilter
          defaultValue={defaultValues.trackingNumber || undefined}
          onChange={handleTrackingNoChange}
          // @ts-ignore
          key={resetTrigger}
        />
      </ComponentMask>
    ),
    [resetTrigger, defaultValues.trackingNumber]
  )

  const showMachineFilterSet =
    filters.machine?.includes(MachineFilterSet.Conditions) ||
    filters.machine?.includes(MachineFilterSet.All)

  const showLubeFilterSet =
    filters.lubricant?.includes(LubricantFilterSet.Conditions) ||
    filters.lubricant?.includes(LubricantFilterSet.All)

  const showMachineAndLubeFilterSet = showMachineFilterSet && showLubeFilterSet
  return (
    <>
      <form onSubmit={e => handleSubmit(e)}>
        <Accordion id={`FilterAccordion-${props.pageName}`}>
          <AccordionHeader
            showLowerDateRange={filters.date?.includes(
              DateFilterSet.FromDate || DateFilterSet.All
            )}
            showUpperDateRange={filters.date?.includes(
              DateFilterSet.ToDate || DateFilterSet.All
            )}
          />
          {!profileContext.dependentData.filters.initialFilterOptionsLoaded && (
            <Loader />
          )}
          {profileContext.dependentData.filters.initialFilterOptionsLoaded && (
            <div className={Styles.AccordionBody}>
              <FilterBox title={'Options'}>
                {(showAllFilters ||
                  filters.options?.includes(OptionsFilterSet.Prefiltering) ||
                  filters.options?.includes(OptionsFilterSet.All)) && (
                  <RefSwitch
                    // @ts-ignore
                    key={resetTrigger}
                    ref={showReviewedReportsRef}
                    title={'Pre-Filtering'}
                    value1={false}
                    value1Text={'Disabled'}
                    value2={true}
                    value2Text={'Enabled'}
                    defaultChecked
                    onChange={val => handlePrefilteringSwitch(val)}
                    tooltip={''}
                  />
                )}
                {(showAllFilters ||
                  filters.options?.includes(OptionsFilterSet.HideCompleted) ||
                  filters.options?.includes(OptionsFilterSet.All)) && (
                  <RefSwitch
                    // @ts-ignore
                    key={resetTrigger}
                    ref={hideCompletedSamplesRef}
                    value1={true}
                    title={'Completed Samples'}
                    value1Title={'Hide'}
                    value2={false}
                    value2Title={'Show'}
                    defaultChecked={!defaultValues.hideCompleted}
                  />
                )}
                {(showAllFilters ||
                  filters.options?.includes(OptionsFilterSet.HideReviewed) ||
                  filters.options?.includes(OptionsFilterSet.All)) && (
                  <RefSwitch
                    // @ts-ignore
                    key={resetTrigger}
                    ref={showReviewedReportsRef}
                    title={'Reviewed Reports'}
                    value1={true}
                    value1Text={'Hide'}
                    value2={false}
                    value2Text={'Show'}
                    defaultChecked={!defaultValues.hideReviewed}
                  />
                )}

                {(showAllFilters ||
                  showMachineAndLubeFilterSet ||
                  filters.options?.includes(OptionsFilterSet.All)) && (
                  <RefSwitch
                    // @ts-ignore
                    key={resetTrigger}
                    ref={machineLubeConditionANDORRef}
                    value1Text="AND"
                    value1={'AND'}
                    title={'Condition Filter Mode'}
                    value1Title={'And'}
                    value2={'OR'}
                    value2Text={'OR'}
                    value2Title={'Or'}
                    defaultChecked={!defaultValues.machineLubeConditionANDOR}
                  />
                )}

                {(showAllFilters ||
                  filters.miscellaneous?.includes(
                    MiscellaneousFilterSet.OmitDayOfMonth
                  ) ||
                  filters.miscellaneous?.includes(
                    MiscellaneousFilterSet.All
                  )) && (
                  <RefSwitch
                    // @ts-ignore
                    key={resetTrigger}
                    ref={omitDayOfMonthRef}
                    title={'Day of Month on Label'}
                    value1={true}
                    value1Text={'Omit'}
                    value2={false}
                    value2Text={'Include'}
                    tooltip={''}
                  />
                )}
                {(showAllFilters ||
                  filters.miscellaneous?.includes(
                    MiscellaneousFilterSet.OmitFullDate
                  ) ||
                  filters.miscellaneous?.includes(
                    MiscellaneousFilterSet.All
                  )) && (
                  <RefSwitch
                    // @ts-ignore
                    key={resetTrigger}
                    ref={omitFullDateRef}
                    title={'Full Date on Label'}
                    value1={true}
                    value1Text={'Omit'}
                    value2={false}
                    value2Text={'Include'}
                    defaultChecked
                    tooltip={''}
                  />
                )}
                {(showAllFilters ||
                  filters.miscellaneous?.includes(
                    MiscellaneousFilterSet.LoadAllMachines
                  ) ||
                  filters.miscellaneous?.includes(
                    MiscellaneousFilterSet.All
                  )) && (
                  <RefSwitch
                    // @ts-ignore
                    key={resetTrigger}
                    ref={onlyForSelectedMonthRef}
                    title={'Load Machines'}
                    value1={true}
                    value1Text={'Selected Month'}
                    value2={false}
                    value2Text={'All'}
                    defaultChecked
                    tooltip={''}
                  />
                )}
              </FilterBox>
              <FilterBox title={'Date'}>
                {(showAllFilters ||
                  filters.date?.includes(
                    DateFilterSet.ReportDateSampleDateSwitch
                  ) ||
                  filters.date?.includes(DateFilterSet.All)) && (
                  <RefSwitch
                    // @ts-ignore
                    key={resetTrigger}
                    ref={reportDateSampleDateSwitch}
                    title={'Date Search'}
                    value1={'Report Date'}
                    value1Text={'Report'}
                    value2={'Sample Date'}
                    value2Text={'Sample'}
                    defaultChecked
                  />
                )}

                {(showAllFilters ||
                  filters.date?.includes(DateFilterSet.SampleDate) ||
                  filters.date?.includes(DateFilterSet.All)) && (
                  <SampleDateFilter
                    // @ts-ignore
                    key={resetTrigger}
                    onChange={handleSampleDateChange}
                    clearable
                    label="Sample Date"
                    placeholder=""
                    format="MM/DD/yyyy"
                    defaultValue={defaultValues.sampleDate}
                  />
                )}

                {(showAllFilters ||
                  filters.date?.includes(DateFilterSet.FromDate) ||
                  filters.date?.includes(DateFilterSet.All)) && (
                  <FromDateFilter
                    // @ts-ignore
                    key={resetTrigger}
                    onChange={handleFromDateChange}
                    maxDate={new Date(Date.now())}
                    defaultValue={defaultValues.startDate}
                  />
                )}
                {(showAllFilters ||
                  filters.date?.includes(DateFilterSet.ToDate) ||
                  filters.date?.includes(DateFilterSet.All)) && (
                  <ToDateFilter
                    onChange={handleToDateChange}
                    // @ts-ignore
                    key={resetTrigger}
                    // minDate={new Date(Date.now())}
                    defaultValue={defaultValues.endDate}
                  />
                )}
                {(showAllFilters ||
                  filters.date?.includes(DateFilterSet.Frequency) ||
                  filters.date?.includes(DateFilterSet.All)) && (
                  <FrequencyFilter
                    // @ts-ignore
                    key={resetTrigger}
                    single={true}
                    required
                    onChange={handleFrequencyChange}
                    defaultValue={defaultValues.frequency}
                  />
                )}
                {(showAllFilters ||
                  filters.date?.includes(DateFilterSet.FiscalYearStartMonth) ||
                  filters.date?.includes(DateFilterSet.All)) && (
                  <FiscalYearStartMonthFilter
                    // @ts-ignore
                    key={resetTrigger}
                    single={true}
                    required={true}
                    onChange={handleFiscalYearStartMonthChange}
                    defaultValue={defaultValues.fiscalYearStartMonth}
                  />
                )}
              </FilterBox>
              {profileContext.minimumProfileLoaded ? (
                <FilterBox title={'Organization'}>
                  {(showAllFilters ||
                    filters.organization?.includes(
                      OrganizationalFilterSet.Divisions
                    ) ||
                    filters.organization?.includes(
                      OrganizationalFilterSet.All
                    )) &&
                    profileContext.dependentData.userDetails.isDivisionUser && (
                      <ComponentMask
                        childRenderHeight="40px"
                        childRenderWidth="225px"
                        loaderType="line"
                        loaded={profileContext.minimumProfileLoaded}
                      >
                        <smartFilters.filters.SmartDivisionFilter />
                      </ComponentMask>
                    )}
                  {(showAllFilters ||
                    filters.organization?.includes(
                      OrganizationalFilterSet.Customers
                    ) ||
                    filters.organization?.includes(
                      OrganizationalFilterSet.All
                    )) &&
                    profileContext.dependentData.userDetails
                      .isMultiCustomerUser && (
                      <ComponentMask
                        childRenderHeight="40px"
                        childRenderWidth="225px"
                        loaderType="line"
                        loaded={profileContext.minimumProfileLoaded}
                      >
                        <smartFilters.filters.SmartCustomerFilter />
                      </ComponentMask>
                    )}
                  {(showAllFilters ||
                    filters.organization?.includes(
                      OrganizationalFilterSet.Plants
                    ) ||
                    filters.organization?.includes(
                      OrganizationalFilterSet.All
                    )) &&
                    profileContext.dependentData.userDetails
                      .isMultiPlantUser && (
                      <ComponentMask
                        childRenderHeight="40px"
                        childRenderWidth="225px"
                        loaderType="line"
                        loaded={profileContext.minimumProfileLoaded}
                      >
                        <smartFilters.filters.SmartPlantFilter />
                      </ComponentMask>
                    )}
                  {(showAllFilters ||
                    filters.organization?.includes(
                      OrganizationalFilterSet.Routes
                    ) ||
                    filters.organization?.includes(
                      OrganizationalFilterSet.All
                    )) && (
                    <ComponentMask
                      childRenderHeight="40px"
                      childRenderWidth="225px"
                      loaderType="line"
                      loaded={profileContext.minimumProfileLoaded}
                    >
                      <smartFilters.filters.SmartRouteFilter />
                    </ComponentMask>
                  )}
                </FilterBox>
              ) : filters.organization.length > 0 ? (
                <FilterBox title={'Organization'} ref={organizationRef}>
                  {(() => {
                    const maxWidth = (225 + 11) * 4
                    const calculatedWidth = organizationRef.current
                      ? organizationRef.current.clientWidth -
                        0.1 * organizationRef.current.clientWidth
                      : 200
                    return (
                      <ComponentMask
                        childRenderHeight="40px"
                        childRenderWidth={`${Math.min(
                          calculatedWidth,
                          maxWidth
                        )}px`}
                        loaderType="line"
                        loaded={false}
                      ></ComponentMask>
                    )
                  })()}
                </FilterBox>
              ) : null}

              <FilterBox title={'Equipment'}>
                {(showAllFilters ||
                  filters.machine?.includes(MachineFilterSet.Conditions) ||
                  filters.machine?.includes(MachineFilterSet.All)) && (
                  <ComponentMask
                    childRenderHeight="40px"
                    childRenderWidth="225px"
                    loaderType="line"
                    loaded={true}
                  >
                    <smartFilters.filters.SmartMachineConditionFilter />
                  </ComponentMask>
                )}
                {(showAllFilters ||
                  filters.machine?.includes(MachineFilterSet.SerialNumber) ||
                  filters.machine?.includes(MachineFilterSet.All)) &&
                  serialNumberFilter}
                {(showAllFilters ||
                  filters.machine?.includes(MachineFilterSet.Name) ||
                  filters.machine?.includes(MachineFilterSet.All)) && (
                  <ComponentMask
                    childRenderHeight="40px"
                    childRenderWidth="225px"
                    loaderType="line"
                    loaded={profileContext.fullProfileLoaded}
                  >
                    <smartFilters.filters.SmartMachineNameFilter />
                  </ComponentMask>
                )}

                {(showAllFilters ||
                  filters.machine?.includes(MachineFilterSet.Number) ||
                  filters.machine?.includes(MachineFilterSet.All)) && (
                  <ComponentMask
                    childRenderHeight="40px"
                    childRenderWidth="225px"
                    loaderType="line"
                    loaded={profileContext.fullProfileLoaded}
                  >
                    <smartFilters.filters.SmartMachineNumberFilter />
                  </ComponentMask>
                )}
                {(showAllFilters ||
                  filters.machine?.includes(MachineFilterSet.EquipmentIDs) ||
                  filters.machine?.includes(MachineFilterSet.All)) && (
                  <ComponentMask
                    childRenderHeight="40px"
                    childRenderWidth="225px"
                    loaderType="line"
                    loaded={profileContext.fullProfileLoaded}
                  >
                    <smartFilters.filters.SmartCustomerEquipmentIDFilter />
                  </ComponentMask>
                )}
                {(showAllFilters ||
                  filters.machine?.includes(MachineFilterSet.Manufacturers) ||
                  filters.machine?.includes(MachineFilterSet.All)) && (
                  <ComponentMask
                    childRenderHeight="40px"
                    childRenderWidth="225px"
                    loaderType="line"
                    loaded={profileContext.fullProfileLoaded}
                  >
                    <smartFilters.filters.SmartMachineManufacturerFilter />
                  </ComponentMask>
                )}

                {(showAllFilters ||
                  filters.machine?.includes(MachineFilterSet.Models) ||
                  filters.machine?.includes(MachineFilterSet.All)) && (
                  <ComponentMask
                    childRenderHeight="40px"
                    childRenderWidth="225px"
                    loaderType="line"
                    loaded={profileContext.fullProfileLoaded}
                  >
                    <smartFilters.filters.SmartMachineModelFilter />
                  </ComponentMask>
                )}

                {(showAllFilters ||
                  filters.machine?.includes(MachineFilterSet.Type) ||
                  filters.machine?.includes(MachineFilterSet.All)) && (
                  <ComponentMask
                    childRenderHeight="40px"
                    childRenderWidth="225px"
                    loaderType="line"
                    loaded={profileContext.fullProfileLoaded}
                  >
                    <smartFilters.filters.SmartMachineTypeFilter />
                  </ComponentMask>
                )}
              </FilterBox>
              <FilterBox title={'Lubricant'}>
                {(showAllFilters ||
                  filters.lubricant?.includes(LubricantFilterSet.Conditions) ||
                  filters.lubricant?.includes(LubricantFilterSet.All)) && (
                  <ComponentMask
                    childRenderHeight="40px"
                    childRenderWidth="225px"
                    loaderType="line"
                    loaded={true}
                  >
                    <smartFilters.filters.SmartLubricantConditionFilter />
                  </ComponentMask>
                )}
                {(showAllFilters ||
                  filters.lubricant?.includes(LubricantFilterSet.Type) ||
                  filters.lubricant?.includes(LubricantFilterSet.All)) && (
                  <ComponentMask
                    childRenderHeight="40px"
                    childRenderWidth="225px"
                    loaderType="line"
                    loaded={profileContext.fullProfileLoaded}
                  >
                    <smartFilters.filters.SmartLubricantFilter />
                  </ComponentMask>
                )}
                {(showAllFilters ||
                  filters.lubricant?.includes(
                    LubricantFilterSet.Manufacturers
                  ) ||
                  filters.lubricant?.includes(LubricantFilterSet.All)) && (
                  <ComponentMask
                    childRenderHeight="40px"
                    childRenderWidth="225px"
                    loaderType="line"
                    loaded={profileContext.fullProfileLoaded}
                  >
                    <smartFilters.filters.SmartLubricantManufacturerFilter />
                  </ComponentMask>
                )}
              </FilterBox>
              <FilterBox title={'Sample'}>
                {(showAllFilters ||
                  filters.sample?.includes(SampleFilterSet.ReportTypes) ||
                  filters.lubricant?.includes(LubricantFilterSet.All)) && (
                  <ComponentMask
                    childRenderHeight="40px"
                    childRenderWidth="225px"
                    loaderType="line"
                    loaded={true}
                  >
                    <smartFilters.filters.SmartReportTypeFilter />
                  </ComponentMask>
                )}

                {(showAllFilters ||
                  filters.sample?.includes(SampleFilterSet.LabNumber) ||
                  filters.sample?.includes(SampleFilterSet.All)) &&
                  labNumberFilter}
                {(showAllFilters ||
                  filters.sample?.includes(SampleFilterSet.DaysDelinquent) ||
                  filters.sample?.includes(SampleFilterSet.All)) && (
                  <DaysDelinquentFilter
                    // @ts-ignore
                    key={resetTrigger}
                    single={true}
                    required={true}
                    defaultValue={90}
                    onChange={handleDaysDelinquentChange}
                  />
                )}

                {(showAllFilters ||
                  filters.sample?.includes(SampleFilterSet.TestPackages) ||
                  filters.sample?.includes(SampleFilterSet.All)) && (
                  <ComponentMask
                    childRenderHeight="40px"
                    childRenderWidth="225px"
                    loaderType="line"
                    loaded={profileContext.fullProfileLoaded}
                  >
                    <smartFilters.filters.SmartTestPackageFilter />
                  </ComponentMask>
                )}
                {(showAllFilters ||
                  filters.sample?.includes(SampleFilterSet.SampleStatus) ||
                  filters.sample?.includes(SampleFilterSet.All)) && (
                  <SampleStatusFilter
                    // @ts-ignore
                    key={resetTrigger}
                    single={true}
                    required={false}
                    onChange={handleSampleStatusChange}
                  />
                )}
                {(showAllFilters ||
                  filters.sample?.includes(SampleFilterSet.ExceptionCount) ||
                  filters.sample?.includes(SampleFilterSet.All)) && (
                  <ExceptionCountFilter
                    // @ts-ignore
                    key={resetTrigger}
                    required
                    defaultValue={2}
                    single
                    onChange={handleExceptionCountChange}
                  />
                )}

                {(showAllFilters ||
                  filters.miscellaneous?.includes(
                    MiscellaneousFilterSet.ShipmentStatus
                  ) ||
                  filters.miscellaneous?.includes(
                    MiscellaneousFilterSet.All
                  )) && (
                  <ShipmentStatusFilter
                    // @ts-ignore
                    key={resetTrigger}
                    defaultValue={[]}
                    required={false}
                    single={false}
                    onChange={handleShipmentStatusChange}
                  />
                )}

                {renderOrderNumberFilter && orderNumberFilter}
                {renderTrackingNumberFilter && trackingNumberFilter}
              </FilterBox>
              <FilterBox title={'Financials'}>
                {renderInvoiceNumberFilter && (
                  <ComponentMask
                    childRenderHeight="40px"
                    childRenderWidth="225px"
                    loaderType="line"
                    loaded={true}
                  >
                    <InvoiceNumberFilter inputRef={invoiceNumberRef} />
                  </ComponentMask>
                )}
                {renderPONumberFilter && (
                  <ComponentMask
                    childRenderHeight="40px"
                    childRenderWidth="225px"
                    loaderType="line"
                    loaded={true}
                  >
                    <PONumberFilter inputRef={purchaseOrderNumberRef} />
                  </ComponentMask>
                )}
                {renderInvoiceStatusFilter && (
                  <ComponentMask
                    childRenderHeight="40px"
                    childRenderWidth="225px"
                    loaderType="line"
                    loaded={true}
                  >
                    <InvoiceStatusesFilter
                      //@ts-ignore
                      key={resetTrigger}
                      single
                      id={'Accordion-InvoiceStatus'}
                      options={Object.values(InvoiceStatus)}
                      defaultValue={defaultValues.invoiceStatus}
                      onChange={handleInvoiceStatusChange}
                    />
                  </ComponentMask>
                )}
              </FilterBox>
            </div>
          )}
          <AccordionButtons
            // onSearch={handleSubmit}
            onReset={handleReset}
          />
        </Accordion>
      </form>
      {props.children && props.children(getAccordionDataSet)}
    </>
  )
}

export default React.memo(
  FilterAccordion,
  (prev, next) =>
    prev.defaultValues === next.defaultValues && prev.filters === next.filters
)
