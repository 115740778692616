import * as PageStyles from '../page-styles.module.css'
import { PageCard } from '../Pages/PageContainers'
import { NotFoundCardModule } from '../CardModules/NotFoundCardModule'
import Button from '@material-ui/core/Button'
import { useCallback } from 'react'

import { useNavigate } from 'react-router-dom'
import { useIsLoggedIn } from 'hooks/useIsLoggedIn'
import { getSampleRegistrationBaseURL } from '../constants'

type TProps = {
  reset: () => void
}
export const BottleDataNotFound = (props: TProps) => {
  const navigate = useNavigate()
  const { loading: loggedInLoading, loggedIn } = useIsLoggedIn()
  const handleNavigate = useCallback(() => {
    if (!loggedInLoading && !loggedIn) {
      navigate(`/Login?PostLoginURL=${getSampleRegistrationBaseURL()}`)
      return
    }

    if (props.reset) {
      props.reset()
    }
  }, [loggedIn, loggedInLoading])

  const header = (
    <>
      {' '}
      <header className={PageStyles.MainTitle}>Not Found</header>
      <sub className={PageStyles.SubTitle}>
        <span className={PageStyles.BlockSpan}>
          This bottle could not be located.
        </span>
      </sub>
    </>
  )

  const details = (
    <>
      <section className={PageStyles.DetailSection}>
        <p className={PageStyles.FinePrintParagraph}>
          The specified bottle identifier could not be found. Please ensure
          you’ve entered the bottle identifier correctly before proceeding.
        </p>
        <p className={PageStyles.FinePrintParagraph}>
          If you’ve received this message after scanning a bottle QR Code,
          please attempt to log in and enter the bottle identifier manually.
          This issue may be the result of a misprint. If the bottle still cannot
          be found after entering the bottle identifier manually and you are
          certain the identifier is correct, please contact support at{' '}
          <a href={`mailto: ${process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL}`}>
            {process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL!}
          </a>{' '}
          for further help.
        </p>
      </section>

      <Button
        variant="contained"
        color="primary"
        data-cancel
        fullWidth
        onClick={handleNavigate}
      >
        I'll Try Again
      </Button>
    </>
  )

  const isLoggedIn = useIsLoggedIn()
  return (
    <PageCard>
      <div
        className={`${PageStyles.EntryScreen}  ${PageStyles.WithBottleButton} ${
          isLoggedIn.loggedIn ? PageStyles.LoggedIn : ''
        }`}
      >
        <NotFoundCardModule details={details} header={header} />
      </div>
    </PageCard>
  )
}
