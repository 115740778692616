export function formatDateString(dateString: string) {
  if (!dateString) return ''

  const asDateTicks = Date.parse(dateString)
  const date = new Date(asDateTicks)

  return date.toLocaleDateString()
}

export function getBottleQueryPath(query: {
  bottleID?: string
  encryptedID?: string
}) {
  if (!query.bottleID && !query.encryptedID) return ''

  let path = '?'
  if (!!query.bottleID) {
    path += `BottleID=${query.bottleID}`
  }

  if (!!query.encryptedID) {
    if (!!query.bottleID) {
      path += '&'
    }

    path += `EncryptedID=${query.encryptedID}`
  }

  return path
}
