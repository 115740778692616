import React, { Fragment, useEffect, useRef, useState, useContext } from 'react'
import { Box, Typography } from '@material-ui/core'
import { SortDescriptor } from '@progress/kendo-data-query'
import { GridSortChangeEvent } from '@progress/kendo-react-grid'
import OrderedList from 'models/utils/OrderedList'
import moment from 'moment'
import {
  TAPICustomer,
  TAPIPlant,
  TAPIProfileDivision,
  TPagedShipmentInformation,
  TShipmentInformation,
  TShipmentStatus,
} from 'types/api'
import AppLayout from 'components/AppLayout'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import SEO from 'components/SEO'
import OrderInformationTable from 'components/tables/OrderInformationTable'
import APIService from 'services/api-service'
import { getSelectedCustomerIDs } from 'services/selection-service'
import { useProfile } from 'Contexts/ProfileContext'
import FilterAccordion, {
  TAccordionFilterValues,
} from 'components/FilterAccordion'
import { mapToPageParameters } from 'components/FilterAccordion/Utilities'
import {
  DateFilterSet,
  MiscellaneousFilterSet,
  OptionsFilterSet,
  OrganizationalFilterSet,
} from 'components/FilterAccordion/types'
import { CancellablePromise } from 'utils/CancellablePromise'

// import * as Styles from './index.module.css'

const initialStartDate = moment()
  .subtract(3, 'months')
  .startOf('month')
  .startOf('day')
  .toDate()
const initialEndDate = moment().endOf('month').endOf('day').toDate()

const defaults = {
  page: 1,
  pageSize: 10,
  orderConfig: [{ field: 'orderDate', dir: 'desc' }] as SortDescriptor[],
  lastOrderConfig: [] as SortDescriptor[],
  loaded: false,
  selectedDivisions: [] as TAPIProfileDivision[],
  selectedCustomers: [] as TAPICustomer[],
  selectedPlants: [] as TAPIPlant[],
  selectedOrderNo: null,
  selectedTrackingNo: null,
  startDate: initialStartDate,
  endDate: initialEndDate,
  shipmentInformation: [] as TShipmentInformation[],
  orderCount: 0,
  selectedShipmentStatuses: [] as TShipmentStatus[],
}

type OwnProps = {
  offline?: boolean
}

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

const ShipmentInformation: React.FC<OwnProps> = (props: OwnProps) => {
  const profileContext = useProfile()

  const [state, setState] = useState({
    ...defaults,
    loadedDefaults: false,
    loading: false,
  })

  // SECTION UseEffects and state management.

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  useEffect(() => {
    if (profileContext.dependentData.filters.minimumFilterOptionsLoaded) {
      setState(prev => ({
        ...prev,
        selectedCustomers:
          profileContext.dependentData.filters.initialFilterState?.customers,
        selectedDivisions:
          profileContext.dependentData.filters.initialFilterState?.divisions,
        loadedDefaults: true,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext.dependentData.filters.minimumFilterOptionsLoaded])

  // Handle loading.
  useEffect(() => {
    if (!state.loaded && state.loadedDefaults && !state.loading) {
      setState(prev => ({ ...prev, loading: true }))
      const loadShipmentInformation = async () => {
        // Load information. If information, set shipmentInformationAvailable variable in storage context to true to trigger alert on order supplies page.

        // Get appropriate customer IDs
        let customerIDs = getSelectedCustomerIDs(
          profileContext.profile.customers,
          profileContext.profile.divisions,
          state.selectedDivisions?.map(d => d.iD),
          state.selectedCustomers?.map(c => c.custID)
        )
        // NOTE: If there are plants selected but no customers, get the appropriate customers. This endpoint uses plant numbers which is indistinct between customers.
        if (
          state.selectedCustomers.length === 0 &&
          state.selectedPlants.length > 0
        ) {
          customerIDs = profileContext.profile.customers
            .filter(customer =>
              customer.plants
                ?.map(plant => plant.plantID)
                ?.some(plantID =>
                  state.selectedPlants.map(p => p.plantID).includes(plantID)
                )
            )
            ?.map(c => c.custID)
        }

        let pagedShipmentInformation: TPagedShipmentInformation = null
        try {
          const promise = APIService.getCustomerShipmentInformation({
            customerIDs,
            plantNumbers: state?.selectedPlants?.map(p => p.plantNo) || [],
            page: state.page,
            pageSize: state.pageSize,
            orderList: state.orderConfig.map(x => x.dir),
            orderByList: state.orderConfig.map(x => x.field),
            orderIDs: parseInt(state.selectedOrderNo),
            trackingNumber: state.selectedTrackingNo,
            statuses: state.selectedShipmentStatuses,
            from:
              state.selectedOrderNo || state.selectedTrackingNo
                ? null
                : state.startDate?.toISOString(),
            to:
              state.selectedOrderNo || state.selectedTrackingNo
                ? null
                : state.endDate?.toISOString(),
          })

          ongoingCancellablePromises.push(promise)
          pagedShipmentInformation = await promise

          ongoingCancellablePromises.filter(p => p != promise)
        } catch (ex) {
          // Ignore error - simply no results.
        } finally {
          setState(prev => ({
            ...prev,
            shipmentInformation: pagedShipmentInformation?.data,
            orderCount: pagedShipmentInformation?.count || 0,
            loaded: true,
            loading: false,
          }))
        }
      }

      loadShipmentInformation()
    }
  }, [
    state.endDate,
    state.loaded,
    state.orderConfig,
    state.page,
    state.pageSize,
    state.selectedCustomers,
    state.selectedDivisions,
    state.selectedPlants,
    state.selectedShipmentStatuses,
    state.startDate,
    state.loadedDefaults,
    state,
  ])

  // !SECTION

  // SECTION Pagination

  const handlePageChange = event => {
    setState(prev => ({
      ...prev,
      page: event.page.skip / state.pageSize + 1,
      pageSize: event.page.take,
      loaded: false,
    }))
  }

  const getSortDelegate = (
    field: string
  ): ((_sortee: TShipmentInformation) => any) => {
    switch (field) {
      case 'division':
        return x =>
          profileContext.profile.divisions?.find(d =>
            d.customers?.map(c => c.custID).includes(x.custId)
          )?.name ?? ''
      case 'customer':
        return x => x.custId
      case 'plant':
        return x =>
          profileContext.profile.customers
            ?.filter(c => c.custID === x.custId)
            .flatMap(c => c.plants)
            .find(p => p.plantNo === x.plantNo)?.plantName ?? ''
      case 'contact':
        return x => `${x.shipFirstName} ${x.shipLastName}`.toLowerCase().trim()
      case 'orderDate':
        return x => x.orderDate
      case 'orderId':
        return x => x.orderId
      case 'shipDate':
        return x => x.shipDate
      case 'status':
        return x => x.status
      case 'trackingNumber':
        return x => x.trackingNumber
      default:
        return x => x
    }
  }

  const handleSortChange = (event: GridSortChangeEvent) => {
    const isFieldRemoved = state.orderConfig.length > event.sort.length
    let isLastFieldRemoved = false

    if (isFieldRemoved) {
      const fieldRemoved = state.orderConfig.filter(
        x => !event.sort.includes(x)
      )[0]

      isLastFieldRemoved =
        state.orderConfig.indexOf(fieldRemoved) === state.orderConfig.length - 1
    }

    // if number of items equals item count displayed do inline sorting.
    if (
      state.pageSize >= state.orderCount &&
      event.sort.length > 0 &&
      !isLastFieldRemoved
    ) {
      const orderConfig = [...event.sort]

      let orderedEnum = new OrderedList<TShipmentInformation>(
        state.shipmentInformation
      )

      orderedEnum.orderBy(
        getSortDelegate(orderConfig[0].field),
        orderConfig[0].dir === 'desc'
      )

      for (let i = 1; i < orderConfig.length; i++) {
        orderedEnum = orderedEnum.thenBy(
          getSortDelegate(orderConfig[i].field),
          orderConfig[i].dir === 'desc'
        )
      }

      setState(prev => ({
        ...prev,
        orderConfig,
        shipmentInformation: orderedEnum.toArray(),
      }))
    } else {
      setState(prev => ({
        ...prev,
        orderConfig: [...event.sort],
        loaded: !(event.sort.length > 0 && !isLastFieldRemoved),
      }))
    }
  }

  const handlePageChangeMobile = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage
  ) => {
    setState(prev => ({
      ...prev,
      page: newPage + 1,
      loaded: false,
    }))
  }

  const handlePageSizeChangeMobile = event => {
    setState(prev => ({
      ...prev,
      page: 1,
      pageSize: parseInt(event.target.value, 10),
      loaded: false,
    }))
  }

  // !SECTION

  const handleResetClick = () => {
    if (state.loaded) {
      setState(prev => ({
        ...prev,
        ...defaults,
        selectedCustomers:
          profileContext.dependentData.filters.initialFilterState?.customers,
        selectedDivisions:
          profileContext.dependentData.filters.initialFilterState?.divisions,
        loaded: false,
      }))
    }
  }

  const handleFilterSubmission = (data: TAccordionFilterValues) => {
    const mappedParameters = mapToPageParameters(data)

    setState(prev => ({
      ...prev,
      ...mappedParameters,
      page: 1,
      selectedPlants: data.plants,
      selectedDivisions: data.divisions,
      selectedCustomers: data.customers,
      loaded: false,
    }))
  }

  return (
    <Fragment>
      <SEO title="Order Information" />
      <Box mb={2}>
        <Typography variant="h1">Order Information</Typography>
      </Box>
      {props.offline ? <OfflineBanner /> : <></>}

      <FilterAccordion
        pageName="Order Information"
        defaultValues={profileContext.dependentData.filters.initialFilterState}
        onSubmit={handleFilterSubmission}
        onReset={handleResetClick}
        filters={{
          options: [OptionsFilterSet.Prefiltering],
          date: [DateFilterSet.FromDate, DateFilterSet.ToDate],
          organization: [
            OrganizationalFilterSet.Divisions,
            OrganizationalFilterSet.Customers,
            OrganizationalFilterSet.Plants,
          ],
          machine: [],
          lubricant: [],
          sample: [],
          miscellaneous: [
            MiscellaneousFilterSet.ShipmentStatus,
            MiscellaneousFilterSet.OrderNumber,
            MiscellaneousFilterSet.TrackingNumber,
          ],
        }}
      />

      {state.loaded ? (
        <>
          <div style={{ marginTop: '75px' }} />
          <OrderInformationTable
            orders={state.shipmentInformation}
            page={state.page}
            pageSize={state.pageSize}
            count={state.orderCount}
            orderConfig={state.orderConfig}
            onSortChange={handleSortChange}
            onPageChange={handlePageChange}
            onPageChangeMobile={handlePageChangeMobile}
            onPageSizeChangeMobile={handlePageSizeChangeMobile}
            offline={props.offline}
          />
        </>
      ) : (
        <Loader />
      )}
    </Fragment>
  )
}

export default function Page() {
  return (
    <AppLayout tab="program-management">
      {
        // profile and offline are passed when cloning the object in AppLayout. This error is incorrect and should be ignored. See ts-ignore below.

        <ShipmentInformation />
      }
    </AppLayout>
  )
}
