import { Card, CardContent } from '@material-ui/core'
import {
  Chart,
  ChartTitle,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
} from '@progress/kendo-react-charts'
import { InformationIcon } from 'components/InformationIcon'
import { CheckboxListPicker } from 'components/Pickers/CheckboxListPicker/CheckboxListPicker'
import { useThemeContext } from 'Contexts/ThemeContext'
import React, { CSSProperties, useEffect, useState } from 'react'
import { TMachineGroup, TSpendingEntity } from 'types/api'
import { getAggregatedMachineTypeSpending } from 'utils/financial-utilities'
import { PieChart, TPieChartPoint } from '../PieChart'

import * as Styles from './index.module.css'

export type TPieChartWithPicker = {
  spendingData: TMachineGroup[]
} & React.ComponentProps<typeof PieChart>

export const PieChartWithPicker: React.FC<TPieChartWithPicker> = (
  props: TPieChartWithPicker
) => {
  const { containerStyles, ...rest } = props

  const dataSeries: TPieChartPoint[] = props.spendingData.map(spending => ({
    category: spending.machineType,
    value: spending.amount,
  }))

  const [selectedCategories, setSelectedCategories] = useState(
    dataSeries?.map(d => d.category)
  )

  const handleSelectedSeriesValuesChange = (selectedCategories: string[]) => {
    setSelectedCategories(selectedCategories)
  }

  const filteredDataSeries: TPieChartPoint[] = dataSeries.filter(datum =>
    selectedCategories.includes(datum.category)
  )

  const classes = useThemeContext()

  return (
    <Card
      variant="outlined"
      className={classes.card}
      style={{
        display: 'inline-flex',
        flexDirection: 'column',
        minHeight: '500px',
        minWidth: '450px',
        maxWidth: '1100px',
        width: '90%',
      }}
    >
      <CardContent
        className={classes.cardContent}
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          className={classes.cardHeader}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {`${props.title}`}
          <InformationIcon>
            <>
              <h5>Spending by Machine Type</h5>
              <hr />
              <p>
                Evaluate spending on a machine type basis. The cumulative
                spending over the selected date range (the "From" date up until
                present day - this does not include projected spending) is
                displayed next to the slice of the pie that represents that
                machine type. Business units with no spending are not shown.
              </p>
            </>
          </InformationIcon>
        </div>

        <div className={Styles.Container}>
          <div className={`${Styles.TableContainer} ${Styles.RowContainer}`}>
            <CheckboxListPicker
              title="Machine Type"
              values={dataSeries?.map(d => d.category)}
              onChange={handleSelectedSeriesValuesChange}
            />
          </div>
          <div
            className={`${Styles.GraphContainer} ${Styles.RowContainer}`}
            style={containerStyles}
          >
            <PieChart
              key={selectedCategories.length.toString()}
              dataSeries={filteredDataSeries}
              {...rest}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
