import { LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

type TComponentMaskProps = {
  loaderType: 'circle' | 'line'
  loaded: boolean
  childRenderHeight?: string
  childRenderWidth?: string
}

const ComponentMask: React.FC<TComponentMaskProps> = props => {
  // const getBackgroundColor = () =>{
  //     const r = Math.random() * 25;
  //     const g  = Math.random() * 50;
  //     const b = Math.random() * 25;

  //     return `rgb(${230+r},${80+b},${b})`

  // }

  const useStyles = makeStyles({
    root: {
      width: props.childRenderWidth || '200px',
    },
  })

  const classes = useStyles(props)

  return (
    <>
      {!props.loaded && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: props.childRenderHeight || '75px',
          }}
        >
          <LinearProgress classes={classes} />
        </div>
      )}
      {props.loaded && props.children}
    </>
  )
}

export default ComponentMask
