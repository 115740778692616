import React from 'react'
import { Grid } from '@material-ui/core'

import MobileCardContent from 'components/MobileCardContent'
import MobileCardItem from 'components/MobileCardItem'

import {
  RenderRoute,
  RenderTestGroupsAsStringCommas,
} from '../KendoUIGridRenders'

import {
  renderMachineNameMobile,
  renderMonthMobile,
  renderMonthHeader,
} from './customRenders'

interface OwnProps {
  data: any
  readOnly: boolean
  showDivisions: boolean
  months: any[]
}

const MobileCard: React.FC<OwnProps> = ({
  data,
  readOnly,
  showDivisions,
  months,
}: OwnProps) => {
  return (
    <MobileCardContent title={data.machine.plantName}>
      <Grid container direction="row" spacing={1}>
        {showDivisions && (
          <MobileCardItem title="Division Name">
            {data.machine.plant.customer.division.name}
          </MobileCardItem>
        )}
        <MobileCardItem title="Customer Name">
          {data.machine.plant.customer.custName}
        </MobileCardItem>
        <MobileCardItem title="Plant Name">
          {data.machine.plant.plantName}
        </MobileCardItem>
        <MobileCardItem title="Mach No.">{data.machine.machNo}</MobileCardItem>
        <MobileCardItem title="Machine Name">
          {renderMachineNameMobile(data)}
        </MobileCardItem>
        <MobileCardItem title="Equipment ID">
          {data.machine.custEquID}
        </MobileCardItem>
        <MobileCardItem title="Route">
          <RenderRoute dataItem={data.machine} field="route" />
        </MobileCardItem>
        <MobileCardItem title="Test Package">
          {data.testGroup.testGroupName}
        </MobileCardItem>
        <MobileCardItem title="Tests">
          <RenderTestGroupsAsStringCommas
            dataItem={data.testGroup}
            field="testGroupDefs"
          />
        </MobileCardItem>

        <Grid
          container
          style={{
            border: 1,
            borderWidth: 1,
            borderColor: 'lightgray',
            borderStyle: 'solid',
            padding: '15px',
            marginTop: '15px',
          }}
        >
          <Grid container xs={12} sm={12} alignItems="center" justify="center">
            {months.map(month => (
              <div style={{ textAlign: 'center', display: 'grid' }}>
                {renderMonthMobile(data, month)}
                <span>{renderMonthHeader(month)}</span>
              </div>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </MobileCardContent>
  )
}

export default MobileCard
