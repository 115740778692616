import { Box, Grid, Typography } from '@material-ui/core'
import { Description, Person } from '@material-ui/icons'
import DashboardIcon from '@material-ui/icons/Dashboard'

import AppLayout from 'components/AppLayout'
import NavCard from 'components/NavCard'
import { useProfile } from 'Contexts/ProfileContext'
import { Navigate, useNavigate } from 'react-router-dom'

const pages = [
  {
    url: '/analytics/machine-health/dashboard',
    title: 'Machine Health Dashboard',
    description:
      "Your machine health dashboard. See an overview of your equipments' trends.",
    background: '#1d2b3e',
    icon: <DashboardIcon />,
  },
]

function MachineHealth() {
  const profile = useProfile()
  const navigate = useNavigate()
  if (!profile.dependentData.userDetails.isUSSteelUser) {
    navigate('/404')
    return null
  }

  return (
    <Grid container direction="row" spacing={4} xs={12}>
      <Grid item md={3} xs={12}>
        <Typography variant="h1">Machine Health</Typography>
        <Box style={{ marginTop: '15px' }}>
          <Typography paragraph>Asses the health of your equipment.</Typography>
        </Box>
      </Grid>
      <Grid container item direction="row" md={9} spacing={4} xs={12}>
        {pages?.map(page => (
          <NavCard
            keyValue={page.url}
            icon={page.icon}
            title={page.title}
            description={page.description}
            url={page.url}
            background={page.background}
          />
        ))}
      </Grid>
    </Grid>
  )
}

export default function MachineHealthPage() {
  return (
    <AppLayout tab="analytics">
      <MachineHealth />
    </AppLayout>
  )
}
