import React, { ReactNode, useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  Grid,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import {
  Product,
  TCustomerProductPricing,
  TProductEditing,
  TProductPricingEditing,
} from 'types/api'
import AppLayout from 'components/AppLayout'
import { CustomerFilter, PlantNameFilter } from 'components/filters'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import APIService from 'services/api-service'
import { useProfile } from 'Contexts/ProfileContext'
import { SupplyQuery } from 'models/api/SupplyQuery'
import { CancellablePromise } from 'utils/CancellablePromise'
import ProductPricingEditingTable from 'components/tables/ProductPricingEditingTable'
import { ProductPricingEditContext } from 'Contexts/ProductPricingEditContext'
import CustomerProductGroupEditingModal from 'components/Modals/CustomerProductGroupEditingModal'
import { useNavigate } from 'react-router-dom'

interface IState {
  [key: string]: any
  products: TProductEditing[]
  productsToUpdate: TProductEditing[]
  selectedCustomerIDs: number[]
}

const defaults = {
  submitting: false,
  loading: false,
  products: [],
  productsToUpdate: [],
  groupsModalOpened: false,
  initialLoadDone: false,
  selectedCustomerIDs: []
}

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

const ProductPricingEditing: React.FC = ({ onError, offline }: any) => {

  const [state, setState] = useState<IState>({
    ...defaults,
  })

  const profileContext = useProfile()

  const { customers } = profileContext.profile

  const navigate = useNavigate()

  useEffect(() => {
    
    if (profileContext.minimumProfileLoaded) {
      
      const isInternalUser =
        profileContext.dependentData.userDetails.isInternalUser
    
      if (!isInternalUser) {
        navigate('/404')
        return null
      }

      setState(prev => ({
        ...prev,
        initialLoadDone: true,
        selectedCustomerIDs: [customers[0].custID]
      }))
    }

    return () => {
    }
  }, [profileContext.minimumProfileLoaded])

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  const handleModifyGroupsClick = () => {
    setState(prev => ({
      ...prev,
      groupsModalOpened: true
    }))
  }

  const handleGroupsModalClose = (hasChanges: boolean) => {
    setState(prev => ({
      ...prev,
      groupsModalOpened: false
    }))

    if (hasChanges)
      fetchData()
      // make sure to catch any error
      .catch(console.error);
  }

  const handleUpdateChangesClick = async () => {

    const customerIDs = getCurrentCustomerIDs()

    var post = { customerID: customerIDs[0], products: state.productsToUpdate} as TCustomerProductPricing

    let promise: CancellablePromise<TCustomerProductPricing>

    try {
      setState(prev => ({
        ...prev,
        submitting: true
      }))

      promise = APIService.putCustomerProductPricing(post)
      ongoingCancellablePromises.push(promise)
      
      const response = await promise

      setState(prev => ({
        ...prev,
        productsToUpdate: [],
        submitting: false
      }))

      fetchData()
      // make sure to catch any error
      . catch(console.error);

    } catch (ex) {
      console.error(ex)
      setState(prev => ({
        ...prev,
        submitting: false
      }))
      onError('Unable to process the request! Try again later...')
    } finally {
      ongoingCancellablePromises.filter(p => p != promise)
    }

  }

  const getCurrentCustomerIDs = () => state.selectedCustomerIDs.length > 0 ? state.selectedCustomerIDs : customers.map(c => c.custID)

  const getFirstSelectedCustomer = () => customers.find(c => c.custID === getCurrentCustomerIDs()[0])

  const fetchData = async () => {
    const customerIDs = getCurrentCustomerIDs() 

    if (!customerIDs.some(id => id))
    {
      setState(prev => ({
        ...prev,
        products: [],
        loading: false
      }))
      return
    }

    const query: SupplyQuery = {
      customerIDs: customerIDs,
      pricedOnly: false
    }

    let promise: CancellablePromise<Product[]>

    try {
      setState(prev => ({
        ...prev,
        loading: true
      }))

      promise = APIService.getSupplies(query)
      ongoingCancellablePromises.push(promise)
      const products = await promise

      const editingProducts = products.map(p => ({...p, firstGroupID: p.productGroups[0]?.groupID, 
                          firstGroupDescription: p.productGroups[0]?.groupDescription,
                          productPricing: p.productPricing.filter( pp => customerIDs.some(id => id === pp.customerID))
                        }));

      setState(prev => ({
          ...prev,
          products: editingProducts,
          loading: false
        }))

    } catch (ex) {
      console.error(ex)
      setState(prev => ({
        ...prev,
        loading: false
      }))
      onError('Unable to process the request! Try again later...')
    } finally {
      ongoingCancellablePromises.filter(p => p != promise)
    }
  }  

  useEffect(() => {
    if (!profileContext.minimumProfileLoaded ) return

    fetchData()
    // make sure to catch any error
    .catch(console.error);

  }, [profileContext.minimumProfileLoaded, state.selectedCustomerIDs])

  const updateProductPricing = (productID: number, productPricings: TProductPricingEditing[]) =>
  {
    let product = state.products.find(p => p.productID === productID)

    if (product)
      product.productPricing = productPricings

    setState(prev => ({
      ...prev,
      productsToUpdate: [...prev.productsToUpdate?.filter(p => p.productID !== productID), product],
    }))
  }

  return (!state.initialLoadDone ? <Loader /> :
    <Grid container justify="center">
      <CustomerProductGroupEditingModal open={state.groupsModalOpened} onClose={handleGroupsModalClose} onError={onError} customer={getFirstSelectedCustomer()}  /> 
        <Grid item md={12} lg={12}>
            <Box mb={4}>
                <Typography variant="h1">Product Pricing Editing</Typography>
            </Box>
            {offline ? <OfflineBanner /> : <></>}
            <Grid container direction="row" spacing={2}>
              <Grid container direction="row" md={6}>
                {customers.length > 0 ? (
                <Grid item>
                    <FormControl>
                      <CustomerFilter
                        single={true}
                        required={false}
                        customers={customers}
                        onChange={e => setState(prev => ({...prev, selectedCustomerIDs: [e as number]}))}
                        defaultValue={customers[0]}
                        value={
                          customers.find(
                            c => state.selectedCustomerIDs.includes(c.custID)
                          ) ?? {
                            custID: 0,
                            custName: '',
                          }
                        }
                        style={{width: 500}}
                      />
                    </FormControl>
                </Grid>
                ) : (
                ''
                )}
              </Grid>
              <Grid container direction="row" md={6} spacing={2} justifyContent="flex-end">
                <Button
                      style={{ width: 225, marginRight:15 }}
                      variant="contained"
                      color="primary"
                      onClick={handleModifyGroupsClick}
                      disabled={!state.selectedCustomerIDs.some(id => id)}
                    >
                      Modify Groups
                </Button>
                <Button
                      style={{ width: 225 }}
                      variant="contained"
                      color="secondary"
                      disabled={!state.productsToUpdate.some(p => p) || state.submitting}
                      onClick={handleUpdateChangesClick}
                    >
                      Update Changes
                </Button>

              </Grid>              
            </Grid>
            <Grid container direction="row" style={{marginTop: 20}}>
              { state.submitting || state.loading ?                      
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Loader />
                    </div> : 
              <ProductPricingEditContext.Provider value={{ updateProductPricing }}>
                  <ProductPricingEditingTable products={state.products} offline={offline} />
              </ProductPricingEditContext.Provider>
              }
            </Grid>
        </Grid>
    </Grid>
  )
}

ProductPricingEditing.propTypes = {
  profile: PropTypes.object,
  offline: PropTypes.bool,
  onError: PropTypes.func,
}

export default function Page() {

  return (
    <AppLayout tab="program-management">
      <ProductPricingEditing />
    </AppLayout>
  )
}