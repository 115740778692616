import Button from '@material-ui/core/Button'
import Loader from 'components/Loader'
import React, { useCallback } from 'react'
import * as PageStyles from '../page-styles.module.css'
import { PageCard, PageBody } from '../Pages/PageContainers'
import TroubleshootIcon from '@mui/icons-material/Troubleshoot'
import { useNavigate } from 'react-router-dom'
import { sampleRegistrationBaseURL } from '../constants'

type TProps = {}
export const OnIssueCardModule = (props: TProps) => {
  return (
    <>
      {' '}
      <div>
        <header className={PageStyles.MainTitle}>Held For Issue</header>
        <sub className={PageStyles.SubTitle}>
          <span className={PageStyles.BlockSpan}>
            This sample requires customer intervention!
          </span>
        </sub>
      </div>
      <div className={PageStyles.LargeIconBox}>
        <TroubleshootIcon
          className={PageStyles.TroubleshootIcon}
          style={{ fontSize: '9rem' }}
        />
      </div>
      <div>
        <section className={PageStyles.DetailSection}>
          <p className={PageStyles.FinePrintParagraph}>
            This bottle is currently held on issue and requires customer
            intervention. A member of our customer support team has likely
            already reached out to a member of your team to resolve the issue.
          </p>
          <p className={PageStyles.FinePrintParagraph}>
            If you have any questions about this sample hold or have not been
            contacted by a member of our customer support team already, please
            contact customer support at{' '}
            <a href={`mailto: ${process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL}`}>
              {process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL!}
            </a>
            .
          </p>
        </section>
      </div>
    </>
  )
}
