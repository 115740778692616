import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import * as PageStyles from '../../../page-styles.module.css'
import { Button } from '@material-ui/core'
import {
  PlantRegistrationPageState,
  readOnlySignedInErrorHeaderMap,
} from 'pages/sample/registration/types-and-enums'
import { PageCard } from 'pages/sample/registration/Pages/PageContainers'
import {
  ContactDTO,
  CustomerResponseDTO,
  GetPlantsResponse,
  PostPlantsDTO,
  PostPlantsResponse,
} from 'types/api'
import { useError, useLoading, usePromise } from 'hooks/usePromise'
import APIService from 'services/api-service'

import {
  ProgressStepper,
  TProgressMarker,
} from 'components/ProgressStepper/ProgressStepper'

import ReadOnlyUserForbiddenHeader from 'pages/sample/registration/CardModules/CardModuleHeaders/ReadOnlyForbiddenHeader'
import {
  TPlantContactInformation,
  TPlantGeneralInformation,
  TPlantShippingInformation,
  TUseCustomerInformation,
} from './types'
import { FetchError } from 'utils/Errors/FetchError'
import { NotFoundCardModule } from 'pages/sample/registration/CardModules/NotFoundCardModule'
import { ShippingInformation } from './Pages/ShippingInformation'
import { useIsLoggedIn } from 'hooks/useIsLoggedIn'
import { LoadingCardModule } from 'pages/sample/registration/CardModules/LoadingCardModule'
import { ForbiddenCardModule } from 'pages/sample/registration/CardModules/ForbiddenCardModule'
import { LoadingErrorCardModule } from 'pages/sample/registration/CardModules/LoadingErrorCardModule'
import { About } from './Pages/About'
import { ContactInformation } from './Pages/ContactInformation'
import { GeneralInformation } from './Pages/GeneralInformation'
import { CancelButton } from 'pages/sample/registration/CardModules/CancelButton/CancelButton'
import { useIsBoundByWidths } from 'hooks/useIsBoundByWidths'
import { mobileWidthDefinitonMap } from 'pages/sample/registration/Pages/constants'
import { LoadingStateWithoutReset } from '../CreateMachine/types'
import { useProfile } from 'Contexts/ProfileContext'
import { useBottleRegistrationContext } from 'pages/sample/registration/registrationContext'
import { useErrorPage } from '../useErrorPage'
import { useIsReadOnlyError } from 'pages/sample/hooks/useIsReadOnlyError'

type TProps = {
  existingPlants: GetPlantsResponse
  customerData: CustomerResponseDTO
  encryptedBottleIdentifier: string
  cancel: () => void
  submit: (plantID: number) => void
}

type TPageState = {
  page: PlantRegistrationPageState
} & TPlantGeneralInformation &
  TPlantContactInformation &
  TPlantShippingInformation

const defaultPageState: Readonly<TPageState> = {
  email: null,
  firstName: null,
  lastName: null,
  city: null,
  page: PlantRegistrationPageState.About,
  name: null,
  shippingAddress1: null,
  shippingState: null,
  coordinates: null,
  generalNotes: null,
  number: null,
  shippingAddress2: null,
  shippingNotes: null,
  shippingZipCode: null,
  shippingEmail: null,
  shippingFirstName: null,
  shippingLastName: null,
}

export const PlantRegistration = (props: TProps) => {
  //nav to info input with state: new plant selected and at plant page
  const [state, setPageState] = useState<TPageState>({
    ...defaultPageState,
  })

  const header = useRef<HTMLDivElement>()
  const [remainderHeight, setremainderHeight] = useState(0)
  useLayoutEffect(() => {
    setremainderHeight(header.current?.offsetHeight ?? 0)
  }, [])

  const isMobile = useIsBoundByWidths(mobileWidthDefinitonMap)['mobile']
  const isTabView = useIsBoundByWidths(mobileWidthDefinitonMap)['tabView']

  const remainderHeightCalculationMobile = `calc(100% - ${remainderHeight}px)`
  const remainderHeightCalculation = `calc(100vh - ${166}px)`
  const remainderHeightCalculationLoggedIn = `calc(100vh - (${remainderHeight}px + ${
    isTabView ? 182 : 109
  }px))`

  const customerPrimaryContactQuery = useCustomerPrimaryContact({
    customerID: props.customerData?.customerID,
    encryptedBottleIdentifier: props.encryptedBottleIdentifier,
  })

  const plantSubmitRequest = usePlantPost({ ...state, ...props })
  const isReadOnly = useIsReadOnlyError()

  const queries = [plantSubmitRequest, customerPrimaryContactQuery, isReadOnly]
  const loading = useLoading(queries)
  const error = useError(queries)
  const errorPage = useErrorPage(error, readOnlySignedInErrorHeaderMap)

  let page = <LoadingCardModule showHeader={false} />
  let step: number = state.page + 1

  if (!!error) {
    const cancelButton = (
      <div className={PageStyles.ButtonContainer}>
        <Button
          data-cancel
          variant="contained"
          color="primary"
          onClick={props.cancel}
          fullWidth
          className={`${PageStyles.Button} `}
        >
          Cancel
        </Button>
      </div>
    )
    page = (
      <>
        <div>{errorPage}</div>
        {cancelButton}
      </>
    )
  }
  if (!loading && !error) {
    switch (state.page) {
      case PlantRegistrationPageState.About: {
        page = (
          <About
            customerData={props.customerData}
            back={props.cancel}
            next={() => {
              setPageState(prev => ({
                ...prev,
                page: PlantRegistrationPageState.GeneralInformation,
              }))
            }}
          />
        )

        break
      }
      case PlantRegistrationPageState.GeneralInformation: {
        page = (
          <GeneralInformation
            plantName={state.name}
            generalNotes={state.generalNotes}
            location={state.coordinates}
            plantNumber={state.number?.toString() ?? null}
            existingPlants={props.existingPlants}
            back={(generalInformation: TPlantGeneralInformation) => {
              setPageState(prev => ({
                ...prev,
                ...generalInformation,
                page: PlantRegistrationPageState.About,
              }))
            }}
            next={(generalInformation: TPlantGeneralInformation) => {
              setPageState(prev => ({
                ...prev,
                ...generalInformation,
                page: PlantRegistrationPageState.ContactInformation,
              }))
            }}
          />
        )

        break
      }
      case PlantRegistrationPageState.ContactInformation: {
        page = (
          <ContactInformation
            email={state.email ?? customerPrimaryContactQuery.data?.email}
            firstName={
              state.firstName ?? customerPrimaryContactQuery.data?.firstName
            }
            lastName={
              state.lastName ?? customerPrimaryContactQuery.data?.lastName
            }
            back={(contactInformation: TPlantContactInformation) => {
              setPageState(prev => ({
                ...prev,
                ...contactInformation,
                page: PlantRegistrationPageState.GeneralInformation,
              }))
            }}
            next={(contactInformation: TPlantContactInformation) => {
              setPageState(prev => ({
                ...prev,
                ...contactInformation,
                page: PlantRegistrationPageState.ShippingInformation,
              }))
            }}
          />
        )

        break
      }
      case PlantRegistrationPageState.ShippingInformation: {
        page = (
          <ShippingInformation
            email={state.email}
            firstName={state.firstName}
            lastName={state.lastName}
            shippingEmail={state.shippingEmail}
            shippingFirstName={state.shippingFirstName}
            shippingLastName={state.shippingLastName}
            shippingAddress1={
              state.shippingAddress1 ??
              customerPrimaryContactQuery.data?.address?.address1
            }
            shippingAddress2={
              state.shippingAddress2 ??
              customerPrimaryContactQuery.data?.address?.address2
            }
            city={state.city ?? customerPrimaryContactQuery.data?.address?.city}
            shippingNotes={state.shippingNotes}
            shippingState={
              state.shippingState ??
              customerPrimaryContactQuery.data?.address?.state
            }
            shippingZipCode={
              state.shippingZipCode ??
              customerPrimaryContactQuery.data?.address?.zip
            }
            back={(shippingInformation: TPlantShippingInformation) => {
              setPageState(prev => ({
                ...prev,
                ...shippingInformation,
                page: PlantRegistrationPageState.ContactInformation,
              }))
            }}
            next={(shippingInformation: TPlantShippingInformation) => {
              setPageState(prev => ({
                ...prev,
                ...shippingInformation,
                page: PlantRegistrationPageState.Complete,
              }))
            }}
          />
        )

        break
      }
    }
  }

  const stepperMarkers: TProgressMarker[] = [
    {
      body: '1',
      label: 'About',
      loading: false,
    },
    {
      body: '2',
      label: 'General',
      loading: false,
    },
    {
      body: '3',
      label: 'Contact',
      loading: false,
    },
    {
      body: '4',
      label: 'Shipping',
      loading: false,
    },
  ]

  const progressStepper = (
    <>
      <ProgressStepper step={step} markers={stepperMarkers} />
    </>
  )

  const isLoggedIn = useIsLoggedIn()

  return (
    <PageCard>
      <CancelButton onCancel={props.cancel} />
      <div ref={header}>
        <header className={PageStyles.MainTitle}>Register New Plant</header>
        <div className={PageStyles.ProgressStepperBox}>{progressStepper}</div>
      </div>
      <div
        className={`${PageStyles.EntryScreen} ${
          isLoggedIn.loggedIn ? PageStyles.LoggedIn : ''
        }`}
        style={
          isMobile
            ? { minHeight: remainderHeightCalculationMobile }
            : isLoggedIn.loggedIn
            ? {
                height: 'inherit',
                minHeight: remainderHeightCalculationLoggedIn,
              }
            : {
                height: 'inherit',
                minHeight: remainderHeightCalculation,
              }
        }
      >
        {page}
      </div>
    </PageCard>
  )
}

type TUsePlantRegistration = {
  submitted: boolean
  postData: PostPlantsDTO
  callback?: (data: PostPlantsResponse) => void
}

// function usePlantRegistration(props: TUsePlantRegistration) {
//   return usePromise<PostPlantsResponse>({
//     promise: () =>
//       APIService.postPlantsAsync({
//         plants: [props.postData],
//       }).then(data => {
//         if (!!props.callback) props.callback(data)

//         return data
//       }),
//     dependencies: () => [props.submitted],
//     shouldStartPromise: promiseState =>
//       !promiseState.loading && props.submitted,
//   })
// }

function usePlantPost(
  props: TPageState &
    TPlantGeneralInformation &
    TPlantContactInformation &
    TPlantShippingInformation &
    TProps
) {
  const isLoggedIn = useIsLoggedIn()
  const useEncrypted = !!props.encryptedBottleIdentifier
  const loggedInAndInfoAvailable =
    props.customerData?.customerID != null && isLoggedIn.loggedIn
  const issuePromise =
    props.page === PlantRegistrationPageState.Complete &&
    (loggedInAndInfoAvailable || !!props.encryptedBottleIdentifier)

  const getPromise = () =>
    useEncrypted
      ? APIService.postPlantsByEncryptedBottleAsync({
          plants: [
            {
              encryptedBottleIdentifier: props.encryptedBottleIdentifier,
              name: props.name,
              notes: props.generalNotes,
              number: props.number,
              locationID: 1,
              latitude: props.coordinates?.coords?.latitude,
              longitude: props.coordinates?.coords?.longitude,
              supplyNotes: props.shippingNotes,
              shippingContact: {
                firstName: props.shippingFirstName,
                lastName: props.shippingLastName,
                email: props.shippingEmail,
                datafileReport: true,
                summaryReport: true,
                report: true,
                address: {
                  address1: props.shippingAddress1,
                  address2: props.shippingAddress2,
                  city: props.city,
                  country: 'US',
                  state: props.shippingState,
                  zip: props.shippingZipCode,
                },
              },
              plantContact: {
                firstName: props.firstName,
                lastName: props.lastName,
                email: props.email,
                datafileReport: true,
                summaryReport: true,
                report: true,
              },
            },
          ],
        })
      : APIService.postPlantsAsync({
          plants: [
            {
              customerID: props.customerData?.customerID,
              name: props.name,
              notes: props.generalNotes,
              number: props.number,
              locationID: 1,
              latitude: props.coordinates?.coords?.latitude,
              longitude: props.coordinates?.coords?.longitude,
              supplyNotes: props.shippingNotes,
              shippingContact: {
                firstName: props.shippingFirstName,
                lastName: props.shippingLastName,
                email: props.shippingEmail,
                datafileReport: true,
                summaryReport: true,
                report: true,
                address: {
                  address1: props.shippingAddress1,
                  address2: props.shippingAddress2,
                  city: props.city,
                  country: 'US',
                  state: props.shippingState,
                  zip: props.shippingZipCode,
                },
              },
              plantContact: {
                firstName: props.firstName,
                lastName: props.lastName,
                email: props.email,
                datafileReport: true,
                summaryReport: true,
                report: true,
              },
            },
          ],
        })
  return usePromise({
    shouldStartPromise: data => issuePromise,
    dependencies: () => [props.page],
    promise: () =>
      getPromise().then(res => {
        const posted = res[0]
        props.submit(posted.plantID)
        return posted
      }),
  })
}

function useCustomerPrimaryContact(props: TUseCustomerInformation) {
  const useEncrypted = !!props.encryptedBottleIdentifier
  const promise = useEncrypted
    ? () =>
        APIService.getCustomerPrimaryContactByEncryptedBottleIdentifierAsync({
          encryptedBottleIdentifier: props.encryptedBottleIdentifier,
        })
    : () =>
        APIService.getCustomerPrimaryContactAsync({
          customerID: props.customerID,
        })

  return usePromise<ContactDTO>({
    promise,
    dependencies: () => [props.customerID, props.encryptedBottleIdentifier],
    shouldStartPromise: () =>
      !!props.customerID || !!props.encryptedBottleIdentifier,
  })
}
