import React from 'react'
import {
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'
import { Hidden, TablePagination } from '@material-ui/core'
import TableGrid from 'components/TableGrid'
import {
  RenderDate,
  RenderLabNo,
  RenderLubeCondition,
  RenderMachineCondition,
  RenderMachineName,
  RenderMachineType,
  RenderRoute
} from 'components/tables/KendoUIGridRenders'
import { TMachineTypeList } from 'utils/getMachineTypeFromOptions'
import MobileCard from './MobileCard'

export type TProps = {
  samples: any[]
  customers: any[]
  orderConfig: any[]
  page: number
  pageSize: number
  count: number
  onPageChange?: (_event: GridPageChangeEvent) => void
  onSortChange?: (_event: GridSortChangeEvent) => void
  machineTypeOptions: TMachineTypeList[]
}
const DartTable: React.FC<TProps> = ({
  samples,
  customers,
  orderConfig,
  page,
  pageSize,
  count,
  onSortChange,
  onPageChange,
  machineTypeOptions
}: TProps) => {
  return (
    <>
      <Hidden mdUp>
        {samples.map(sample => (
          <MobileCard key={sample.labID} sample={sample} machineTypeOptions={machineTypeOptions} />
        ))}
      </Hidden>

      <Hidden smDown>
      <TableGrid
        clearSortingButtonId="dart_table-clear_sorting_button"
        data={samples}
        sort={orderConfig}
        skip={(page - 1) * pageSize}
        take={pageSize}
        total={count}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onSortChange={onSortChange}
      >
      <Column
        field="reviewed"
        title="Customer Review"
        cell={({ dataItem }) => <td>{dataItem.reviewed ? 'Yes' : 'No'}</td>}
      />
      <Column
        field="custReviewDte"
        title="Date Reviewed"
        cell={props => <RenderDate {...props} />}
      />
      <Column
        field="sampleDate"
        title="Date Last Sampled"
        cell={props => <RenderDate {...props} />}
      />
      <Column
        field="machine.route.routeNo"
        title="Route"
        cell={props => <RenderRoute {...props} />}
      />
      {samples.some(s => s.machine.plant.customer.division) ?
      <Column
        field="machine.plant.customer.division.name"
        title="Division Name"
      /> : null}
      {customers.length > 1 ?
      <Column
        field="machine.plant.customer.custName"
        title="Customer Name"
      /> : null}
      <Column field="machine.plant.plantName" title="Plant Name" />
      <Column field="machine.machNo" title="Mach No." />
      <Column
        field="machine.machineName"
        title="Machine Name"
        cell={props => <RenderMachineName {...props} />}
      />
      <Column field="machine.custEquID" title="Equipment ID" />
      <Column
        field="machCond"
        title="Mach"
        cell={props => <RenderMachineCondition {...props} />}
      />
      <Column
        field="lubCond"
        title="Lube"
        cell={props => <RenderLubeCondition {...props} />}
      />
      <Column
        field="labID"
        title="Lab No."
        cell={props => <RenderLabNo {...props} />}
      />
      <Column
        field="machine.machType"
        title="Machine Type"
        cell={props => (
          <RenderMachineType
            {...props}
            machineTypeOptions={machineTypeOptions}
          />
        )}
      />
          <Column field="machine.lubricant.lubricantName" title="Lube Type" />
        </TableGrid>
      </Hidden>
    </>
  )
}

export default DartTable
