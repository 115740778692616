export type TMachineTypeList = {
  listValue: string
  listDesc: string
}
export default function getMachineTypeFromOptions({
  machineType,
  machineTypeOptions,
}: {
  machineType: string
  machineTypeOptions: TMachineTypeList[]
}): string {
  return machineTypeOptions?.findIndex(x => x.listValue === machineType) !== -1
    ? machineTypeOptions?.find(x => x.listValue === machineType)?.listDesc
    : ''
}
