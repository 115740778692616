import { Capacitor, Plugins } from '@capacitor/core'
import '@capacitor-community/sqlite'
import moment from 'moment'
const { CapacitorSQLite } = Plugins

const handle = CapacitorSQLite
const DB_NAME = 'datasight'
let hasPermission = false
let db = null

const init = async () => {
  if (hasPermission && db !== null) return

  if (Capacitor.platform === 'android') {
    return new Promise((resolve, reject) => {
      handle.removeAllListeners()
      handle.addListener('androidPermissionsRequest', async data => {
        if (data.permissionGranted === 1) {
          //console.log('Permission GRANTED!');
          hasPermission = true
          await open()
          await createTables()
          resolve(true)
        } else {
          //console.log('Permission *NOT* granted!!!');
          resolve(false)
        }
      })

      handle.requestPermissions()
    })
  } else {
    hasPermission = true
    await open()
    await createTables()
  }
}

const open = async () => {
  if (!hasPermission) return

  db = await handle.open({ database: DB_NAME })
  if (!db.result) {
    throw new Error(`Unable to open sqlite database '${DB_NAME}'!`)
  }
}

const createTables = async () => {
  if (!hasPermission || !db) return

  //console.log("Creating tables...");

  await handle.execute({
    statements: `
CREATE TABLE IF NOT EXISTS cache (
    url TEXT UNIQUE NOT NULL,
    init TEXT,
    body TEXT,
    contentType TEXT
);

CREATE TABLE IF NOT EXISTS queue (
    id INTEGER PRIMARY KEY,
    args TEXT,
    created INTEGER,
    attempts INTEGER
);`,
  })
}

const getFromCache = async url => {
  if (!hasPermission || !db) return null

  const result = await handle.query({
    statement: 'SELECT * FROM cache WHERE url = ?;',
    values: [url],
  })

  if (result.values.length > 0) {
    return result.values[0]
  }

  return null
}

const putInCache = async (url, init, body, contentType) => {
  if (!hasPermission || !db) return

  const exists = (await getFromCache(url)) !== null ? true : false
  const sql = exists
    ? 'UPDATE cache SET init = ?, body = ?, contentType = ? WHERE url = ?;'
    : 'INSERT INTO cache (init, body, contentType, url) VALUES (?, ?, ?, ?);'

  const result = await handle.run({
    statement: sql,
    values: [init, body, contentType, url],
  })

  return result
}

const getQueue = async () => {
  if (!hasPermission || !db) return

  // FIFO:
  const result = await handle.query({
    statement: 'SELECT * FROM queue ORDER BY created ASC',
    values: [],
  })

  return result.values
}

const enqueue = async args => {
  if (!hasPermission || !db) return

  const result = await handle.run({
    statement: 'INSERT INTO queue (args, created, attempts) VALUES (?, ?, ?);',
    values: [args, moment().unix(), 0],
  })

  return result
}

const requeue = async id => {
  if (!hasPermission || !db) return

  const result = await handle.run({
    statement: 'UPDATE queue SET attempts = attempts + 1 WHERE id = ?',
    values: [id],
  })

  return result
}

const dequeue = async id => {
  if (!hasPermission || !db) return

  const result = await handle.run({
    statement: 'DELETE FROM queue WHERE id = ?',
    values: [id],
  })

  return result
}

const purge = async () => {
  if (!hasPermission || !db) return

  await handle.run({ statement: 'DELETE FROM cache', values: [] })
  await handle.run({ statement: 'DELETE FROM queue', values: [] })

  return true
}

export default {
  init,
  getFromCache,
  putInCache,
  getQueue,
  enqueue,
  requeue,
  dequeue,
  purge,
}
