import React, { useEffect, useState } from 'react'
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom'
import {
  Box,
  createTheme,
  Grid,
  ThemeProvider,
  Typography,
} from '@material-ui/core'
import { orderBy } from '@progress/kendo-data-query'
import moment from 'moment'
import { stringify } from 'query-string'
import { encodeQueryParams } from 'use-query-params'
import AppLayout from 'components/AppLayout'
import { ExportButton, ResetButton, SearchButton } from 'components/buttons'
import ViewReportButton from 'components/buttons/ViewReportButton'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import { IReportParametersConditionSummary } from 'components/ReportViewer/TelerikReportViewer'
import {
  compactReportParams,
  reportViewerQueryParamConfig,
} from 'components/ReportViewerFromURLParams'
import SEO from 'components/SEO'
import {
  ConditionSummary,
  tableFormatter,
} from 'components/tables/ConditionSummary'
import APIService, { TProfile } from 'services/api-service'
import { exportData } from 'services/export-service'
import { getSelectedCustomerIDs } from 'services/selection-service'
import coreTheme from '../../components/theme'
import { useProfile } from 'Contexts/ProfileContext'
import FilterAccordion, {
  TAccordionFilterValues,
} from 'components/FilterAccordion'
import { mapToPageParameters } from 'components/FilterAccordion/Utilities'
import {
  DateFilterSet,
  OrganizationalFilterSet,
} from 'components/FilterAccordion/types'
import { CancellablePromise } from 'utils/CancellablePromise'

const initialStartDate = moment()
  .subtract(3, 'months')
  .startOf('month')
  .startOf('day')
  .toDate()

const initialEndDate = moment().endOf('month').endOf('day').toDate()

let isInitialLoad = true

const defaults: StateProps = {
  dataLoaded: false,
  data: [],
  page: 1,
  pageSize: 10,
  startDate: initialStartDate,
  endDate: initialEndDate,
  selectedDivisionIDs: [],
  selectedCustomerIDs: [],
  selectedPlants: [],
  exportFormat: '',
  searching: false,
  orderConfig: [],
  loadedDefaults: false,
}

interface OwnProps {
  onError?: (_err: Error) => void // passed in from layout wrapper
  offline?: boolean
  classes?: any
}

interface StateProps extends Partial<TProfile> {
  dataLoaded: boolean
  data: any[]
  page: number
  pageSize: number
  startDate: Date
  endDate: Date
  selectedDivisionIDs: any[]
  selectedCustomerIDs: any[]
  selectedPlants: any[]
  exportFormat: string
  searching: boolean
  orderConfig: any[]
  loadedDefaults: boolean
}

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

export const ConditionSummaryReport: React.FC<OwnProps> = ({
  onError,
  offline,
}) => {
  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  const [state, setState] = useState<StateProps>(defaults)

  useEffect(() => {
    return () => {
      isInitialLoad = true
    }
  }, [])

  const performSearch = () => {
    setState(prev => ({ ...prev, dataLoaded: false }))

    fetchSamples(
      {
        startDate: state.startDate,
        endDate: state.endDate,
        customerIDs: getSelectedCustomerIDs(
          profileContext.profile.customers,
          profileContext.profile.divisions,
          state.selectedDivisionIDs,
          state.selectedCustomerIDs
        ),
        plantIDs: state.selectedPlants,
      },
      () => {
        setState(prev => ({
          ...prev,
          dataLoaded: true,
        }))
      }
    )
  }

  const fetchSamples = async (variables, cb) => {
    let data = null
    let promise = null
    try {
      promise = APIService.getConditionSummary(variables)
      ongoingCancellablePromises.push(promise)
      data = await promise
    } catch (ex) {
      onError(ex)
      return
    } finally {
      ongoingCancellablePromises.filter(p => p != promise)
    }

    if (data.analytics.conditionSummary !== null) {
      onError(null)
      setState(prev => ({
        ...prev,
        data: data.analytics.conditionSummary,
      }))
      cb()
    }
  }

  const profileContext = useProfile()

  useEffect(() => {
    setState(prev => ({
      ...prev,
      profileUpdated: profileContext.fullProfileLoaded,
    }))
  }, [profileContext])

  useEffect(() => {
    if (profileContext.dependentData.filters.minimumFilterOptionsLoaded) {
      setState(prev => ({
        ...prev,
        selectedCustomerIDs:
          profileContext.dependentData.filters.initialFilterState?.customers?.map(
            c => c.custID
          ),
        selectedDivisionIDs:
          profileContext.dependentData.filters.initialFilterState?.divisions?.map(
            d => d.iD
          ),
        loadedDefaults: true,
        profileUpdated: profileContext.fullProfileLoaded,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext.dependentData.filters.minimumFilterOptionsLoaded])

  useEffect(() => {
    if (!state.dataLoaded && !state.searching && state.loadedDefaults) {
      performSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dataLoaded, state.loadedDefaults])

  useEffect(() => {
    if (state.searching) {
      unstable_batchedUpdates(() => {
        performSearch()
        setState(prev => ({ ...prev, searching: false }))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searching])

  const handleResetClick = () => {
    setState(prev => ({
      ...prev,
      ...defaults,
      count: 0,
      dataLoaded: false,
      selectedCustomerIDs:
        profileContext.dependentData.filters.initialFilterState.customers.map(
          c => c.custID
        ),
      selectedDivisionIDs:
        profileContext.dependentData.filters.initialFilterState.divisions.map(
          d => d.iD
        ),
      searching: true,
    }))
  }

  const handleOrderChanged = (orderConfig: any[]) => {
    setState(prev => ({ ...prev, orderConfig }))
  }

  const handleExportFormatChange = async (_event, format) => {
    let data = null
    let promise = null
    try {
      promise = await APIService.getConditionSummary({
        startDate: state.startDate,
        endDate: state.endDate,
        customerIDs: getSelectedCustomerIDs(
          profileContext.profile.customers,
          profileContext.profile.divisions,
          state.selectedDivisionIDs,
          state.selectedCustomerIDs
        ),
        plantIDs: state.selectedPlants,
      })

      ongoingCancellablePromises.push(promise)
      data = await promise
    } catch (ex) {
      onError(ex)
      return
    } finally {
      ongoingCancellablePromises.filter(p => p != promise)
    }

    const formatted = tableFormatter(
      orderBy(
        data.analytics.conditionSummary.map(x => ({
          ...x,
          plantName: x.plant.plantName,
          custName: x.plant.customer.custName,
          custId: x.plant.customer.custID,
          plantId: x.plant.plantID,
          division: x.plant.customer.division?.name,
        })),
        state.orderConfig
      ),
      profileContext.dependentData.userDetails.isDivisionUser
    )

    exportData('condition-summary', formatted, format.key)
  }

  const getReportQueryParams = () => {
    const {
      divisions,
      selectedDivisionIDs,
      selectedCustomerIDs,
      selectedPlants,
      startDate,
      endDate,
      customers,
    } = state

    const reportParams: IReportParametersConditionSummary = {
      Customers: getSelectedCustomerIDs(
        customers,
        divisions,
        selectedDivisionIDs,
        selectedCustomerIDs
      ),
      Plants: selectedPlants.length === 0 ? [-1] : selectedPlants,
      To: endDate,
      From: startDate,
      SortColumn: state.orderConfig[0]?.field,
      SortDirection: state.orderConfig[0]?.dir,
      CorporateContactID: 0,
    }

    return encodeQueryParams(reportViewerQueryParamConfig, {
      dbg: false,
      r: 'conditionSummary',
      ...compactReportParams(reportParams),
    })
  }

  const theme = createTheme(
    {
      overrides: {
        MuiGrid: {
          root: {
            justifyContent: 'flex-end',
            [coreTheme.breakpoints.down('sm')]: {
              justifyContent: 'space-between',
            },
          },
        },
      },
    },
    coreTheme
  )

  const handleFilterSubmission = (data: TAccordionFilterValues) => {
    const mappedParameters = mapToPageParameters(data)

    setState(prev => ({
      ...prev,
      ...mappedParameters,
      page: 1,
      searching: true,
      dataLoaded: false,
    }))
  }

  if (
    isInitialLoad &&
    ((profileContext.profile.divisions?.length > 0 &&
      state.selectedDivisionIDs.length === 0) ||
      (profileContext.profile.customers?.length > 0 &&
        state.selectedCustomerIDs.length === 0))
  ) {
    isInitialLoad = false
    return null
  }
  return (
    <React.Fragment>
      <SEO title="Condition Summary Report" />
      <Box mb={2}>
        <Typography variant="h1">Condition Summary</Typography>
      </Box>
      {offline ? <OfflineBanner /> : <></>}
      <FilterAccordion
        pageName="Condition Summary"
        defaultValues={profileContext.dependentData.filters.initialFilterState}
        onSubmit={handleFilterSubmission}
        onReset={handleResetClick}
        filters={{
          options: [],
          date: [DateFilterSet.FromDate, DateFilterSet.ToDate],
          organization: [
            OrganizationalFilterSet.Divisions,
            OrganizationalFilterSet.Customers,
            OrganizationalFilterSet.Plants,
          ],
          miscellaneous: [],
        }}
      />
      <form>
        <ThemeProvider theme={theme}>
          <Grid
            container
            direction="row"
            xs={12}
            spacing={1}
            style={{ marginTop: '15px' }}
          >
            <Grid container item direction="row" spacing={1}>
              <Grid item style={{ maxWidth: 242, marginLeft: 15 }}>
                <ViewReportButton
                  disabled={state.data.length < 1}
                  url={`/reports/viewer?${stringify(getReportQueryParams())}`}
                />
              </Grid>

              <Grid item>
                <ExportButton
                  onChange={handleExportFormatChange}
                  disabled={state.data.length < 1}
                />
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
      </form>

      <Box mt={4}>
        {!state.dataLoaded ? (
          <Loader />
        ) : (
          <React.Fragment>
            <ConditionSummary
              data={state.data}
              startDate={state.startDate}
              endDate={state.endDate}
              onOrderChanged={handleOrderChanged}
            />
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  )
}

export default function Page() {
  return (
    <AppLayout tab="reports">
      <ConditionSummaryReport />
    </AppLayout>
  )
}
