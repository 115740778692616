import React, { useMemo, useState } from 'react'
import {
  ErrorFormValue,
  FormValue,
  ToErrorFormValues,
} from 'utils/FormUtilities/FormValue'
import { delay } from 'utils/general-utilities'
import * as PageStyles from '../../../../page-styles.module.css'
import { Button } from '@material-ui/core'
import { Autocomplete } from '@mui/material'
import TextField from '@mui/material/TextField'

import { FancyTextArea } from 'components/FancyTextArea/FancyTextArea'

import { useIsBoundByWidths } from 'hooks/useIsBoundByWidths'
import {
  cacheTimeoutMS,
  mobileWidthDefinitonMap,
} from 'pages/sample/registration/Pages/constants'
import { TMachineFluidInformation } from '../types'

import { useIsLoggedIn } from 'hooks/useIsLoggedIn'
import APIService from 'services/api-service'
import { useError, useLoading, usePromise } from 'hooks/usePromise'
import { LoadingCardModule } from 'pages/sample/registration/CardModules/LoadingCardModule'
import { useErrorPage } from '../../useErrorPage'
import { ElementDisplay } from 'pages/sample/registration/Pages/information-entry/ElementDisplay'
import { FluidType, PostMachineTestScheduleDTO, TListType } from 'types/api'
import { MobileSingleSelect } from 'components/MobileSingleSelect/MobileSingleSelect'
import TestSchedule from './TestSchedule/TestSchedule'
import Loader from 'components/Loader'
import {
  getQueryResultFromCacheOrAPI,
  getDatalessQueryResultFromCacheOrAPI,
} from 'services/queries/Query'
import { GetFluidDetailsEncryptedQuery } from 'services/queries/GetFluidDetailsEncryptedQuery'
import { GetFluidDetailsQuery } from 'services/queries/GetFluidDetailsQuery'
import { AllFluidManufacturersEncryptedQuery } from 'services/queries/AllFluidManufacturersEncryptedQuery'
import { AllFluidManufacturersQuery } from 'services/queries/AllFluidManufacturersQuery'
import { AllFluidTypesEncryptedQuery } from 'services/queries/AllFluidTypesEncryptedQuery'
import { AllFluidTypesQuery } from 'services/queries/AllFluidTypesQuery'

const NO_TESTS_SCHEDULED_TEXT = 'No tests scheduled!'

type TProps = {
  back: (machineInformation: TMachineFluidInformation) => void
  next: (machineInformation: TMachineFluidInformation) => void
  encryptedBottleIdentifier: string | null
  customerID: number | null
  cancel: () => void
  cache: Cache
  addToCache: (url: string, data: any, timeoutMS: number) => Promise<void>
} & Partial<TMachineFluidInformation>

type TFixedState = {
  formUpdated: boolean
}

type TFreeFormState = ToErrorFormValues<TMachineFluidInformation>

type TState = TFreeFormState & TFixedState

const debounceTimeMS = 0

export const MachineSamplingInformation = (props: TProps) => {
  const defaultState: Readonly<TState> = useMemo(
    () => ({
      fluidID: new ErrorFormValue<number>(
        props.fluidID ?? null,
        a1 => ErrorFormValue.validateRequired(a1, 'Fluid'),
        !!props.fluidID
      ),
      fluidManufacturerCode: new ErrorFormValue<string>(
        props.fluidManufacturerCode ?? '',
        a1 => ErrorFormValue.validateRequired(a1, 'Fluid manufacturer'),
        !!props.fluidManufacturerCode
      ),
      samplingInstructions: new ErrorFormValue<string>(
        props.samplingInstructions ?? '',
        a1 =>
          ErrorFormValue.validateOptionalString(
            a1,
            'Sampling instructions',
            2 ** 16 - 1
          ),
        !!props.samplingInstructions
      ),
      formUpdated: false,
    }),
    []
  )

  const [state, setState] = useState<TState>({ ...defaultState })
  const isMobile = useIsBoundByWidths(mobileWidthDefinitonMap)['mobile']

  function updateFixedState<
    TKey extends keyof TFixedState,
    TValue extends TFixedState[TKey]
  >(key: TKey, value: TValue) {
    setState(prev => ({ ...prev, [key]: value }))
  }

  function updateFreeFormState<
    TKey extends keyof TFreeFormState,
    TValue extends TFreeFormState[TKey]['Value']
  >(key: TKey, value: TValue, automaticUpdate: boolean = false) {
    const stateValue = state[key]
    stateValue.Value = value

    if (automaticUpdate) {
      stateValue.Modified = false
    }

    setState(prev => ({ ...prev, [key]: stateValue }))
  }

  function updateFreeFormStates<
    TKey extends keyof TFreeFormState,
    TValue extends TFreeFormState[TKey]['Value']
  >(pairs: { key: TKey; value: TValue; automaticUpdate: boolean }[]) {
    setState(prev => {
      const newState = { ...prev }
      for (const pair of pairs) {
        const key = pair.key

        const stateValue = state[key]
        stateValue.Value = pair.value

        if (pair.automaticUpdate) {
          stateValue.Modified = false
        }

        newState[key] = stateValue
      }

      return newState
    })
  }

  const fluidManufacturersRequest = useFluidManufacturers(props)

  const fluidTypesRequest = useFluids(props)
  const fluidDetailsRequest = useFluidDetails(props, state.fluidID.Value) //Do not add to queries

  const queries = [fluidManufacturersRequest, fluidTypesRequest]
  const loading = useLoading(queries)
  const error = useError(queries)
  const errorPage = useErrorPage(error)

  async function issueWithData(
    func: (data: TMachineFluidInformation) => void,
    ignoreErrors = false
  ) {
    await delay(debounceTimeMS)
    FormValue.modifyAllObjects(state)
    if (!ignoreErrors && !FormValue.allObjectParametersAreValid(state)) {
      updateFixedState('formUpdated', !state.formUpdated)
      return
    }

    if (func) {
      const vals =
        FormValue.getFormResultFromObject<TMachineFluidInformation>(state)
      func(vals)
    }
  }

  const buttons = (
    <div className={PageStyles.ButtonContainer}>
      <Button
        data-cancel
        variant="contained"
        color="primary"
        onClick={() => issueWithData(props.back, true)}
        className={`${PageStyles.Button} ${PageStyles.Left}`}
      >
        Machine Info.
      </Button>

      <Button
        data-accept
        disabled={!FormValue.allObjectParametersAreValid(state)}
        variant="contained"
        color="secondary"
        onClick={() => issueWithData(props.next)}
        className={`${PageStyles.Button} ${PageStyles.Right}`}
      >
        Test Plan
      </Button>
    </div>
  )

  let page = <LoadingCardModule showHeader={false} />
  if (!!error) {
    const cancelButton = (
      <div className={PageStyles.ButtonContainer}>
        <Button
          data-cancel
          variant="contained"
          color="secondary"
          onClick={props.cancel}
          fullWidth
          className={`${PageStyles.Button} `}
        >
          Cancel
        </Button>
      </div>
    )
    page = (
      <>
        <div>{errorPage}</div>
        {cancelButton}
      </>
    )
  }

  if (!error && !loading && queries.every(q => !!q.data)) {
    const fluidOptions = fluidTypesRequest.data.filter(
      ft => ft.manufacturerShortCode === state.fluidManufacturerCode.Value
    )

    // const noTests =
    //   !state.testSchedules.Value || state.testSchedules.Value.length === 0
    page = (
      <>
        <div style={{ marginTop: '1em' }}>
          <h6 className={PageStyles.DetailSectionHeader}>Fluid Information</h6>
          <section
            className={`${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer} ${PageStyles.Wrapper}`}
          >
            {isMobile ? (
              <MobileSingleSelect
                error={
                  state.fluidManufacturerCode.Modified &&
                  !!state.fluidManufacturerCode.getError()
                }
                helperText={
                  !!state.fluidManufacturerCode.getError() &&
                  state.fluidManufacturerCode.Modified &&
                  state.fluidManufacturerCode.getValidationMessage()
                }
                default={
                  fluidManufacturersRequest.data.find(
                    s => s.shortCode === state.fluidManufacturerCode?.Value
                  ) ?? null
                }
                value={
                  fluidManufacturersRequest.data.find(
                    s => s.shortCode === state.fluidManufacturerCode?.Value
                  ) ?? null
                }
                required
                options={fluidManufacturersRequest.data}
                stringify={opt => opt.name}
                label={'Manufacturer'}
                elementificate={opt => {
                  const name = `${opt.name} (${opt.shortCode})`
                  return <ElementDisplay key={name}>{name}</ElementDisplay>
                }}
                onChange={opt =>
                  updateFreeFormStates([
                    {
                      key: 'fluidID',
                      value: !!fluidManufacturersRequest.data.find(
                        s => s.shortCode === opt?.shortCode
                      )
                        ? state.fluidID.Value
                        : null,
                      automaticUpdate: true,
                    },
                    {
                      key: 'fluidManufacturerCode',
                      //@ts-ignore //TODO: Fix type error
                      value: opt?.shortCode || null,
                      automaticUpdate: !opt,
                    },
                  ])
                }
              />
            ) : (
              <Autocomplete
                className={PageStyles.Wrappable}
                defaultValue={
                  fluidManufacturersRequest.data.find(
                    s => s.shortCode === state.fluidManufacturerCode?.Value
                  ) ?? null
                }
                value={
                  fluidManufacturersRequest.data.find(
                    s => s.shortCode === state.fluidManufacturerCode?.Value
                  ) ?? null
                }
                options={fluidManufacturersRequest.data}
                renderInput={params => (
                  <TextField
                    color="primary"
                    {...params}
                    label="Manufacturer"
                    required
                  />
                )}
                getOptionLabel={(p: TListType) => {
                  const name = `${p.name} (${p.shortCode})`
                  return `${name}`
                }}
                onChange={(ev, opt: TListType) => {
                  updateFreeFormStates([
                    {
                      key: 'fluidID',
                      value: !!fluidManufacturersRequest.data.find(
                        s => s.shortCode === opt?.shortCode
                      )
                        ? state.fluidID.Value
                        : null,
                      automaticUpdate: true,
                    },
                    {
                      key: 'fluidManufacturerCode',
                      //@ts-ignore
                      value: opt?.shortCode || null,
                      automaticUpdate: !opt,
                    },
                  ])
                }}
              />
            )}

            {isMobile ? (
              <MobileSingleSelect
                error={state.fluidID.Modified && !!state.fluidID.getError()}
                helperText={
                  !!state.fluidID.getError() &&
                  state.fluidID.Modified &&
                  state.fluidID.getValidationMessage()
                }
                default={
                  fluidTypesRequest.data.find(
                    s => s.id === state.fluidID?.Value
                  ) ?? null
                }
                value={
                  fluidTypesRequest.data.find(
                    s => s.id === state.fluidID?.Value
                  ) ?? null
                }
                required
                disabled={!state.fluidManufacturerCode?.Value}
                options={fluidOptions}
                stringify={opt => opt.name}
                label={'Type'}
                elementificate={opt => (
                  <ElementDisplay key={opt.id}>{opt.name}</ElementDisplay>
                )}
                onChange={opt =>
                  updateFreeFormState('fluidID', opt?.id ?? null, !opt)
                }
              />
            ) : (
              <Autocomplete
                className={PageStyles.Wrappable}
                defaultValue={
                  fluidTypesRequest.data.find(
                    s => s.id === state.fluidID?.Value
                  ) ?? null
                }
                value={
                  fluidTypesRequest.data.find(
                    s => s.id === state.fluidID?.Value
                  ) ?? null
                }
                options={fluidOptions}
                disabled={!state.fluidManufacturerCode?.Value}
                renderInput={params => (
                  <TextField
                    color="primary"
                    {...params}
                    label="Type"
                    required
                  />
                )}
                renderOption={(props, opt) => {
                  return (
                    <li {...props} key={opt.id}>
                      {opt.name}
                    </li>
                  )
                }}
                getOptionLabel={(p: FluidType) => `${p.name}`}
                onChange={(ev, opt: FluidType) =>
                  updateFreeFormState('fluidID', opt?.id || null, !opt)
                }
              />
            )}

            <FancyTextArea
              borderColor="#007bff"
              labelColor="#007bff"
              label="Sampling Instructions"
              textAreaProps={{ maxLength: 65535 }}
              value={state.samplingInstructions.Value}
              onChange={event =>
                updateFreeFormState('samplingInstructions', event.target.value)
              }
            />
          </section>

          <section>
            <h6 className={PageStyles.DetailSectionHeader}>
              Fluid Specifications
            </h6>

            {fluidDetailsRequest.loading ? (
              <Loader />
            ) : fluidDetailsRequest.error ? (
              'No fluid information found.'
            ) : !state.fluidID.Value || !fluidDetailsRequest.data ? (
              'Select a fluid to see details.'
            ) : (
              <ul
                className={`${PageStyles.Details} ${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer} ${PageStyles.Wrapper}`}
              >
                <li className={`${PageStyles.Wrappable}`}>
                  <span className={PageStyles.PropertyLabel}>Fluid</span>
                  <span className={PageStyles.Property}>
                    {fluidDetailsRequest.data.id}
                    {' - '}
                    {formatOrUnknown(fluidDetailsRequest.data.name)}
                  </span>
                </li>
                <li className={`${PageStyles.Wrappable}`}>
                  <span className={PageStyles.PropertyLabel}>Type</span>
                  <span className={PageStyles.Property}>
                    {formatOrUnknown(fluidDetailsRequest.data.type)}
                  </span>
                </li>
                <li className={`${PageStyles.Wrappable}`}>
                  <span className={PageStyles.PropertyLabel}>Manufacturer</span>
                  <span className={PageStyles.Property}>
                    {formatOrUnknown(
                      fluidManufacturersRequest.data.find(
                        m =>
                          m.shortCode ===
                          fluidDetailsRequest.data.manufacturerShortCode
                      )?.name ?? null
                    )}
                  </span>
                </li>
                <li className={`${PageStyles.Wrappable}`}>
                  <span className={PageStyles.PropertyLabel}>
                    Brand Information
                  </span>
                  <span className={PageStyles.Property}>
                    {formatOrUnknown(fluidDetailsRequest.data.brand)}
                  </span>
                </li>
                <li className={`${PageStyles.Wrappable}`}>
                  <span className={PageStyles.PropertyLabel}>Grade</span>
                  <span className={PageStyles.Property}>
                    {formatOrUnknown(fluidDetailsRequest.data.grade)}
                  </span>
                </li>
                <li className={`${PageStyles.Wrappable}`}>
                  <span className={PageStyles.PropertyLabel}>
                    Expected Viscosity at 40&deg;C
                  </span>
                  <span className={PageStyles.Property}>
                    {formatOrUnknown(fluidDetailsRequest.data.viscosity40)}
                  </span>
                </li>
                <li className={`${PageStyles.Wrappable}`}>
                  <span className={PageStyles.PropertyLabel}>
                    Expected Viscosity at 100&deg;C
                  </span>
                  <span className={PageStyles.Property}>
                    {formatOrUnknown(fluidDetailsRequest.data.viscosity100)}
                  </span>
                </li>
              </ul>
            )}
          </section>
        </div>
        {buttons}
      </>
    )
  }

  return page
}

function formatOrUnknown(str: string | number | null) {
  if (!str) return 'Unknown'

  if (typeof str === 'string') return str.trim()

  return str.toFixed(2)
}

function useFluids(props: TProps) {
  const isLoggedIn = useIsLoggedIn()
  const useEncrypted = !!props.encryptedBottleIdentifier
  const loggedInAndInfoAvailable =
    props?.customerID != null && isLoggedIn.loggedIn
  const issuePromise =
    loggedInAndInfoAvailable || !!props.encryptedBottleIdentifier
  async function getPromise() {
    if (useEncrypted) {
      const queryData = {
        encryptedIdentifier: props.encryptedBottleIdentifier,
      }

      return await getQueryResultFromCacheOrAPI(
        AllFluidTypesEncryptedQuery,
        queryData,
        props.cache,
        props.addToCache,
        cacheTimeoutMS
      )
    }

    return await getDatalessQueryResultFromCacheOrAPI(
      AllFluidTypesQuery,
      props.cache,
      props.addToCache,
      cacheTimeoutMS
    )
  }
  return usePromise({
    shouldStartPromise: data =>
      issuePromise && !data.data && !data.loading && !data.error,
    dependencies: () => [issuePromise],
    promise: getPromise,
  })
}

function useFluidDetails(props: TProps, fluidID: number | null) {
  const isLoggedIn = useIsLoggedIn()
  const useEncrypted = !!props.encryptedBottleIdentifier
  const loggedInAndInfoAvailable =
    props?.customerID != null && isLoggedIn.loggedIn
  const issuePromise =
    (loggedInAndInfoAvailable || !!props.encryptedBottleIdentifier) && !!fluidID
  async function getPromise() {
    if (useEncrypted) {
      const queryData = {
        encryptedIdentifier: props.encryptedBottleIdentifier,
        id: fluidID,
      }

      return await getQueryResultFromCacheOrAPI(
        GetFluidDetailsEncryptedQuery,
        queryData,
        props.cache,
        props.addToCache,
        cacheTimeoutMS
      )
    }

    return await getQueryResultFromCacheOrAPI(
      GetFluidDetailsQuery,
      {
        id: fluidID,
      },
      props.cache,
      props.addToCache,
      cacheTimeoutMS
    )
  }
  return usePromise({
    shouldStartPromise: data => issuePromise,
    dependencies: () => [fluidID, issuePromise],
    promise: getPromise,
  })
}

function useFluidManufacturers(props: TProps) {
  const isLoggedIn = useIsLoggedIn()
  const useEncrypted = !!props.encryptedBottleIdentifier
  const loggedInAndInfoAvailable =
    props?.customerID != null && isLoggedIn.loggedIn
  const issuePromise =
    loggedInAndInfoAvailable || !!props.encryptedBottleIdentifier
  async function getPromise() {
    if (useEncrypted) {
      const queryData = {
        encryptedIdentifier: props.encryptedBottleIdentifier,
      }

      return await getQueryResultFromCacheOrAPI(
        AllFluidManufacturersEncryptedQuery,
        queryData,
        props.cache,
        props.addToCache,
        cacheTimeoutMS
      )
    }

    return await getDatalessQueryResultFromCacheOrAPI(
      AllFluidManufacturersQuery,
      props.cache,
      props.addToCache,
      cacheTimeoutMS
    )
  }
  return usePromise({
    shouldStartPromise: data =>
      issuePromise && !data.data && !data.loading && !data.error,
    dependencies: () => [issuePromise],
    promise: getPromise,
  })
}
