import { ScoreCard } from 'components/ScoreCard'
import React from 'react'
import { TSalesOrders } from 'types/api'
import { StringFormatter } from 'utils/string-utilities'

type TBudgetCardProps = {
  salesOrders: TSalesOrders
}

export const BudgetCard: React.FC<TBudgetCardProps> = props => {
  const now = new Date()
  const { salesOrders } = props
  const title = `Available Budget as of ${now.toLocaleDateString()}`
  const helpTooltipText =
    'The total available budget based upon open purchase orders.'

  const totalAvailableBalance = salesOrders?.currentBalance ?? 0

  const allAreFreeIssue = salesOrders?.salesOrders?.every(
    so => totalAvailableBalance < 0
  )
  const budgetIsNotApplicable = allAreFreeIssue || totalAvailableBalance < 0
  const hasPartialFreeIssue = salesOrders?.salesOrders?.some(
    so => totalAvailableBalance < 0
  )

  return (
    <ScoreCard
      toolTip={{
        title: title,
        text: helpTooltipText,
      }}
      style={{ maxWidth: '450px' }}
    >
      <div style={{ marginBottom: '25px' }}>
        <h1>
          <strong>
            {budgetIsNotApplicable
              ? 'N/A'
              : `${StringFormatter.formatAsUSD(totalAvailableBalance)}${
                  hasPartialFreeIssue ? '*' : ''
                }`}
          </strong>
        </h1>
        <h6>
          <strong>{title}</strong>
        </h6>
      </div>
    </ScoreCard>
  )
}
