import React from 'react'
import {
  PostMachineTestScheduleDTO,
  TCustomerTestGroupPricingDetails,
  TTestGroupDetails,
} from 'types/api'
import Styles from './index.module.css'

import ModeEditIcon from '@mui/icons-material/ModeEdit'
import ClearIcon from '@mui/icons-material/Clear'

import { frequencyOptions } from 'pages/sample/registration/constants'
import { StringFormatter } from 'utils/string-utilities'

type TProps = {
  onDelete: (schedule: PostMachineTestScheduleDTO) => void
  onEdit: (schedule: PostMachineTestScheduleDTO) => void
  schedule: PostMachineTestScheduleDTO
  organizedCustomerPricing: [number, TCustomerTestGroupPricingDetails][]
} & React.HTMLAttributes<HTMLDivElement>
const TestSchedule = ({
  schedule,
  organizedCustomerPricing,
  onDelete,
  onEdit,
  ...rest
}: React.PropsWithChildren<TProps>) => {
  const testGroup = getTestGroupName(schedule, organizedCustomerPricing)
  const startDate = getStartDateString(schedule)

  const frequencyName = getFrequencyName(schedule)
  const testPackageCost = getPackageCost(schedule, organizedCustomerPricing)

  return (
    <div {...rest} className={`${rest.className} ${Styles.TestPackageCard}`}>
      <div>
        <div>
          <h1>{testGroup}</h1>
        </div>
        <div>
          <ModeEditIcon
            id={Styles.Edit}
            className={Styles.Icon}
            onClick={() => onEdit(schedule)}
          />
          <ClearIcon
            id={Styles.Delete}
            className={Styles.Icon}
            onClick={() => onDelete(schedule)}
          />
        </div>
      </div>

      <p>
        <span>Start Testing:</span> <span>{startDate}</span>
      </p>
      <p>
        <span>Test Frequency:</span> <span>{frequencyName}</span>
      </p>
      <p>
        <span>Test Package Price:</span> <span>{testPackageCost}</span>
      </p>
    </div>
  )
}

const DEFAULT_DISPLAY = 'Unknown'

function getPackageCost(
  schedule: PostMachineTestScheduleDTO,
  organizedCustomerPricing: [number, TCustomerTestGroupPricingDetails][]
) {
  if (!schedule.testGroupID) return DEFAULT_DISPLAY

  const testGroupPair = organizedCustomerPricing.find(
    ([testGroupID, _]) => testGroupID === schedule.testGroupID
  )

  if (!testGroupPair) return DEFAULT_DISPLAY
  const testGroup = testGroupPair[1]

  if (!testGroup) return DEFAULT_DISPLAY

  const cost = testGroup.pricePerUnitUSD
  return StringFormatter.formatAsUSD(cost)
}

function getFrequencyName(schedule: PostMachineTestScheduleDTO) {
  return (
    frequencyOptions.find(f => f.frequency === schedule.frequency)
      ?.frequencyName ?? DEFAULT_DISPLAY
  )
}

function getTestGroupName(
  schedule: PostMachineTestScheduleDTO,
  organizedCustomerPricing: [number, TCustomerTestGroupPricingDetails][]
) {
  const testGroupPair = organizedCustomerPricing.find(
    ([testGroupID, _]) => testGroupID === schedule.testGroupID
  )

  if (!testGroupPair) return DEFAULT_DISPLAY

  return testGroupPair[1]?.testGroup?.testGroup ?? DEFAULT_DISPLAY
}

function getStartDateString(schedule: PostMachineTestScheduleDTO) {
  if (schedule.startMonth == null) return DEFAULT_DISPLAY

  const now = new Date()
  let year = now.getFullYear()
  const thisMonth = now.getMonth() + 1

  if (schedule.startMonth < thisMonth) {
    year += 1
  }

  const startDate = new Date(year, schedule.startMonth - 1, 1)

  return startDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
  })
}

export default TestSchedule
