import moment from 'moment'

import { TAPIIssueSample } from 'types/api'
import {
  lubeConditions,
  machineConditions,
  reportTypes,
} from 'services/lookup-service'

export const tableFormatter = (
  samples: TAPIIssueSample[],
  hasDivisions: boolean
): string[][] => {
  const headers = [
    { id: 'sampleDate', text: 'Sample Date' },
    { id: 'receivedDate', text: 'Received Date' },
    { id: 'sampleStatus', text: 'Status' },
    // ...(hasDivisions ? [{ id: 'division', text: 'Division' }] : []),
    { id: 'custName', text: 'Customer Name' },
    { id: 'plantName', text: 'Plant Name' },
    { id: 'routeNo', text: 'Route' },
    { id: 'machNo', text: 'Mach No.' },
    { id: 'machName', text: 'Machine Name' },
    { id: 'custEquID', text: 'Equipment ID' },
    { id: 'labID', text: 'Lab No.' },
    { id: 'reportType', text: 'Report Type' },
    { id: 'machineCondition', text: 'Mach', sortable: false },
    { id: 'lubeCondition', text: 'Lube', sortable: false },
    { id: 'testPackageList', text: 'Test Package' },
    { id: 'holdDate', text: 'Hold Date' },
    { id: 'holdReason', text: 'Hold Reason' },
    { id: 'holdNotes', text: 'Hold Notes' },
  ]

  const headerValues = headers.map(x => x.text)

  return [
    headerValues,
    ...samples.map(sample => [
      moment(sample.sampleDate).format('l'),
      moment(sample.receivedDate).format('l'),
      sample.sampleStatus,
      // ...(hasDivisions ? [sample.machine.plant.customer.division] : []),
      sample.custName,
      sample.plantName,
      sample.routeNo !== null ? `${sample.routeNo} - ${sample.routeName}` : '',
      sample.machNo?.toString() ?? '',
      sample.machName,
      sample.custEquID,
      sample.labID?.toString() ?? '',
      sample.reportType !== null
        ? reportTypes.find(y => y.value === sample.reportType)?.text
        : '',
      machineConditions.find(x => x.value === sample.machineCondition)?.text ?? '',
      lubeConditions.find(x => x.value === sample.lubeCondition)?.text ?? '',
      sample.testPackageList ? sample.testPackageList.join(',') : '',
      sample.holdDate?.toString() ?? '',
      sample.holdReason ? sample.holdReason : '',
      sample.holdNotes ? sample.holdNotes : '',
    ]),
  ]
}
