import React, { useEffect, useRef, useState } from 'react'
import {
  AppBar,
  Box,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  ElementsConsumer,
} from '@stripe/react-stripe-js'
import {
  Stripe,
  StripeCardElementOptions,
  StripeElements,
} from '@stripe/stripe-js'

import * as Styles from './index.module.css'
import { FormField } from 'components/FormField'
import { TContactAddress } from 'types/api'
import StripeInput from './StripeInput'
import GenericButton from 'components/buttons/GenericButton'
import { nameToFirstAndLast } from 'utils/string-utilities'

export type TStripePayCardProps = {
  ready: boolean
  total: number
  onPaymentMethodCreated: (
    paymentMethodID: string,
    billingAddress: TContactAddress,
    firstName: string,
    lastName?: string
  ) => void
  onError: (message: string) => void
  billingAddress: TContactAddress
  onSubmitClick?: () => void
}

type TStripePayComponentProps = {
  stripe: Stripe
  elements: StripeElements
} & TStripePayCardProps

const defaults = {
  submitting: false,
  tabIndex: 0,
  formErrors: [],
}

interface TState {
  submitting: boolean
  tabIndex: number
  formErrors: any[]
}

const StripePayComponent: React.FC<TStripePayComponentProps> = (
  props: TStripePayComponentProps
) => {
  const {
    stripe,
    elements,
    ready,
    onPaymentMethodCreated,
    total,
    onError,
    billingAddress,
    onSubmitClick,
  } = props
  const [state, setState] = useState<TState>(defaults)

  const formErrorMessageStyle = { fontSize: 12, marginLeft: 5, marginTop: 5 }

  const handleFormValidation = () => {
    const formErrors = []

    if (!billingDetails.name) {
      formErrors.push({ id: 'name', message: 'Required' })
    }
    if (!billingDetails.address1) {
      formErrors.push({ id: 'address1', message: 'Required' })
    }
    if (!billingDetails.city) {
      formErrors.push({ id: 'city', message: 'Required' })
    }
    if (!billingDetails.state) {
      formErrors.push({ id: 'state', message: 'Required' })
    }
    if (!billingDetails.zip) {
      formErrors.push({ id: 'zip', message: 'Required' })
    }

    if (billingDetails.ccNumberValidation.empty)
      formErrors.push({ id: 'ccnumber', message: 'Required' })
    else if (!billingDetails.ccNumberValidation.complete)
      formErrors.push({ id: 'ccnumber', message: 'Invalid' })

    if (billingDetails.ccExpValidation.empty)
      formErrors.push({ id: 'ccexp', message: 'Required' })
    else if (!billingDetails.ccExpValidation.complete)
      formErrors.push({ id: 'ccexp', message: 'Invalid' })

    if (billingDetails.cvcValidation.empty)
      formErrors.push({ id: 'cvc', message: 'Required' })
    else if (!billingDetails.cvcValidation.complete)
      formErrors.push({ id: 'cvc', message: 'Invalid' })

    setState(prev => ({ ...prev, formErrors: formErrors }))

    return !formErrors.some(x => x)
  }

  const handleSubmit = async event => {
    // Block native form submission.
    event.preventDefault()
    onError(null)

    if (onSubmitClick) onSubmitClick()

    if (!handleFormValidation()) return

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.

      // eslint-disable-next-line no-useless-return
      return
    }

    setState(prev => ({ ...prev, submitting: true }))

    const cardNumberElement = elements?.getElement(CardNumberElement)

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,

        billing_details: {
          name: billingDetails.name,
          address: {
            line1: billingDetails.address1,
            line2: billingDetails.address2,
            city: billingDetails.city,
            state: billingDetails.state,
            postal_code: billingDetails.zip,
            country: 'US',
          },
        },
      })

      if (paymentMethod) {
        const { address1, address2, city, state, zip, name } = billingDetails

        onPaymentMethodCreated(
          paymentMethod.id,
          {
            address1,
            address2,
            city,
            state,
            zip,
            country: 'USA',
            salesTaxCode: '',
          },
          ...nameToFirstAndLast(name)
        )
      }
    } catch (err) {
      onError(err)
    } finally {
      setState(prev => ({ ...prev, submitting: false }))
    }
  }

  // Get a reference to a mounted CardElement. Elements knows how
  // to find your CardElement because there can only ever be one of
  // each type of element.

  const [billingDetails, setBillingDetails] = useState({
    name: '',
    address1: billingAddress?.address1,
    address2: billingAddress?.address2,
    city: billingAddress?.city,
    state: billingAddress?.state,
    zip: billingAddress?.zip,

    ccNumberValidation: { empty: true, complete: false },
    ccExpValidation: { empty: true, complete: false },
    cvcValidation: { empty: true, complete: false },
  })

  const cardNumberElement = elements?.getElement(CardNumberElement)

  const handleChange = (event, newValue) => {
    setState(prev => ({ ...prev, tabIndex: newValue }))
  }

  return (
    <div id={Styles.Container} className={Styles.StandardBoxDesign}>
      <form onSubmit={handleSubmit}>
        <Grid container xs={12}>
          <Grid item xs={12} className={Styles.FormFieldContainer}>
            <FormField
              id="name"
              formErrors={state.formErrors}
              errorMessageStyle={formErrorMessageStyle}
            >
              <TextField
                label="Name *"
                name="name"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={billingDetails.name}
                onChange={event =>
                  setBillingDetails(prev => ({
                    ...prev,
                    name: event.target.value,
                  }))
                }
                inputProps={{ maxLength: 50 }}
              />
            </FormField>
          </Grid>
          <Grid item xs={12} className={Styles.FormFieldContainer}>
            <Grid container xs={12} direction="row">
              <Grid item xs={6} className={Styles.SpaceBetweenFields}>
                <FormField
                  id="ccnumber"
                  formErrors={state.formErrors}
                  errorMessageStyle={formErrorMessageStyle}
                >
                  <TextField
                    label="Credit Card Number"
                    name="ccnumber"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={event =>
                      setBillingDetails(prev => ({
                        ...prev,
                        ccNumberValidation: event as any,
                      }))
                    }
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: StripeInput,
                      inputProps: {
                        component: CardNumberElement,
                      },
                    }}
                  />
                </FormField>
              </Grid>
              <Grid item xs={3} className={Styles.SpaceBetweenFields}>
                <FormField
                  id="ccexp"
                  formErrors={state.formErrors}
                  errorMessageStyle={formErrorMessageStyle}
                >
                  <TextField
                    label="Expiration Date"
                    name="ccexp"
                    variant="outlined"
                    required
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    onChange={event =>
                      setBillingDetails(prev => ({
                        ...prev,
                        ccExpValidation: event as any,
                      }))
                    }
                    InputProps={{
                      inputComponent: StripeInput,
                      inputProps: {
                        component: CardExpiryElement,
                      },
                    }}
                  />
                </FormField>
              </Grid>
              <Grid item xs={3}>
                <FormField
                  id="cvc"
                  formErrors={state.formErrors}
                  errorMessageStyle={formErrorMessageStyle}
                >
                  <TextField
                    label="CVC"
                    name="cvc"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={event =>
                      setBillingDetails(prev => ({
                        ...prev,
                        cvcValidation: event as any,
                      }))
                    }
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputComponent: StripeInput,
                      inputProps: {
                        component: CardCvcElement,
                      },
                    }}
                  />
                </FormField>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className={Styles.FormFieldContainer}>
            <Grid container xs={12} direction="row">
              <Grid item xs={6} className={Styles.SpaceBetweenFields}>
                <FormField
                  id="address1"
                  formErrors={state.formErrors}
                  errorMessageStyle={formErrorMessageStyle}
                >
                  <TextField
                    label="Address 1 *"
                    name="address1"
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={billingDetails.address1}
                    onChange={event =>
                      setBillingDetails(prev => ({
                        ...prev,
                        address1: event.target.value,
                      }))
                    }
                  />
                </FormField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Address 2"
                  name="address2"
                  variant="outlined"
                  inputProps={{ maxLength: 50 }}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={billingDetails.address2}
                  onChange={event =>
                    setBillingDetails(prev => ({
                      ...prev,
                      address2: event.target.value,
                    }))
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={Styles.FormFieldContainer}>
            <Grid container xs={12} direction="row">
              <Grid item xs={6} className={Styles.SpaceBetweenFields}>
                <FormField
                  id="city"
                  formErrors={state.formErrors}
                  errorMessageStyle={formErrorMessageStyle}
                >
                  <TextField
                    label="City *"
                    name="city"
                    variant="outlined"
                    inputProps={{ maxLength: 20 }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={billingDetails.city}
                    onChange={event =>
                      setBillingDetails(prev => ({
                        ...prev,
                        city: event.target.value,
                      }))
                    }
                  />
                </FormField>
              </Grid>
              <Grid item xs={3} className={Styles.SpaceBetweenFields}>
                <FormField
                  id="state"
                  formErrors={state.formErrors}
                  errorMessageStyle={formErrorMessageStyle}
                >
                  <TextField
                    label="State *"
                    name="state"
                    variant="outlined"
                    inputProps={{ maxLength: 2 }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={billingDetails.state}
                    onChange={event =>
                      setBillingDetails(prev => ({
                        ...prev,
                        state: event.target.value,
                      }))
                    }
                  />
                </FormField>
              </Grid>
              <Grid item xs={3}>
                <FormField
                  id="zip"
                  formErrors={state.formErrors}
                  errorMessageStyle={formErrorMessageStyle}
                >
                  <TextField
                    label="Zip Code *"
                    name="zip"
                    variant="outlined"
                    inputProps={{ maxLength: 12 }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={billingDetails.zip}
                    onChange={event =>
                      setBillingDetails(prev => ({
                        ...prev,
                        zip: event.target.value,
                      }))
                    }
                  />
                </FormField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container xs={12} justifyContent="flex-end">
          <GenericButton
            disabled={!stripe || !ready || state.submitting}
            type="submit"
          >
            Pay{' '}
            {Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(Number(total))}
            {state.submitting && <CircularProgress color="inherit" size={18} />}
          </GenericButton>
        </Grid>
      </form>
    </div>
  )
}

// const getStyle = (): StripeCardElementOptions => {
//   return {
//     iconStyle: 'solid',
//     style: {
//       base: {
//         iconColor: '#c4f0ff',
//         textAlign: 'left',
//         color: 'black',
//         fontWeight: 500,
//         fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
//         fontSize: '16px',
//         padding: '10px 5px 20px 5px',

//         fontSmoothing: 'antialiased',
//         ':-webkit-autofill': {
//           color: 'black',
//         },
//         '::placeholder': {
//           color: 'grey',
//         },
//       },
//       invalid: {
//         iconColor: 'red',
//         color: 'red',
//       },
//     },
//   }
// }

const StripePayCard = ({
  ready,
  onPaymentMethodCreated,
  total,
  onError,
  billingAddress,
  onSubmitClick,
}: TStripePayCardProps) => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <StripePayComponent
          onSubmitClick={onSubmitClick}
          elements={elements}
          stripe={stripe}
          ready={ready}
          total={total}
          onPaymentMethodCreated={onPaymentMethodCreated}
          onError={onError}
          billingAddress={billingAddress}
        />
      )}
    </ElementsConsumer>
  )
}

export default StripePayCard
