import React from 'react'
import { FormControlLabel, Switch } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

import coreTheme from '../../theme'

const theme = createTheme(
  {
    overrides: {
      MuiFormControlLabel: {
        root: {
          [coreTheme.breakpoints.down('sm')]: {
            // width: '100%'
            width: '225px',
          },
          [coreTheme.breakpoints.up('sm')]: {
            width: '225px',
          },
        },
      },
    },
  },
  coreTheme
)

export const HideCompletedFilter = ({
  hideCompleted,
  onHideCompletedChange,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <FormControlLabel
        control={
          <Switch
            checked={hideCompleted}
            onChange={onHideCompletedChange}
            name="hideCompleted"
            color="secondary"
          />
        }
        label="Hide Completed"
      />
    </ThemeProvider>
  )
}
