import React from 'react'
import { months } from 'services/lookup-service'

import { VirtualSelectFilter } from '../BaseFilters/VirtualSelectFilter'

export type TMonth = {
  id: number
  text: string
}

type TProps = {
  single: boolean
  required: boolean
  onChange?: (_props: TMonth) => void
  defaultValue?: TMonth
  resetKey?: string | number
  variant?: 'filled' | 'outlined' | 'standard'
}

export const FiscalYearStartMonthFilter = (props: TProps) => {
  const { single, required, onChange, defaultValue, variant, resetKey } = props

  return (
    <VirtualSelectFilter
      label="Fiscal Year Start Month"
      htmlFor="fiscalYearStartMonth"
      id="fiscalYearStartMonth"
      defaultValue={months.find(m => m.id === defaultValue.id) || months[0]}
      key={resetKey}
      renderValue={(selected: TMonth | TMonth[]) =>
        months.find(x => x.id === (selected as TMonth).id)?.text ?? ''
      }
      variant={variant}
      getOptionSelected={(option, value) => option.id === value.id}
      onChange={onChange}
      multiple={!single}
      required={required}
      options={[...months]}
    />
  )
}
