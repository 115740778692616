import React from 'react'

class ControlContextModel {
  resetKey: number

  constructor() {
    this.resetKey = 0
  }

  Reset = () => {
    this.resetKey = this.resetKey > 0 ? 0 : 1
  }
}

const ControlContext = React.createContext(new ControlContextModel())

export default ControlContext
