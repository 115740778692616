import { Grid, TextField, Button, InputLabel } from '@material-ui/core'
import { useFinancialIDSelectFilter } from 'components/filters/ConfiguredFilters/useFinancialIDSelectFilter'
import { PaymentStatusEnum } from 'components/Financials/StripeJS/InvoiceList'
import { FormField } from 'components/FormField'
import Loader from 'components/Loader'
import React, { useEffect, useMemo, useState } from 'react'
import { TBillingCustomer, TContactAddress } from 'types/api'
import StripePayComponent from 'components/Financials/StripeJS/StripePayCard'
import { emailRegex } from 'utils/common-regex'
import { useProfile } from 'Contexts/ProfileContext'
import { BooleanFormValue } from 'utils/FormUtilities/FormValue'
import { MissingFinancialsErrorScreen } from 'components/Financials/StripeJS/SuppliesList/MissingFinancialsErrorScreen'
import { getDefaultBillingContact } from 'utils/financial-utilities'
import { FetchError } from 'utils/Errors/FetchError'

type Props = {
  resetPaymentStatusWithError: () => void
  suppliesTotal: number
  //   billingCustomer: TBillingCustomer
  modalSubmitting: boolean
  handleCreditCardPayment: (
    paymentMethodID: string,
    billingAddress: TContactAddress,
    financialID: string,
    billingEmail: string
  ) => void
  handleCreditCardPaymentError: (message: string) => void
  closeModal: () => void
  paymentStatus: PaymentStatusEnum
  onMissingBillingInformation: () => void
  onLoadError: (error: FetchError) => void
}

const formErrorMessageStyle: React.CSSProperties = {
  fontSize: 12,
  marginLeft: 5,
  marginTop: 5,
}

interface FormError {
  id: string
  message: string
}

interface TModifiableData<T> {
  value: BooleanFormValue<T>
  required: boolean
}

const validateEmail = (email: string) => {
  const lengthRegex = /^(?:[^@]){1,64}@(?:.){1,253}$/
  return (
    emailRegex.test(String(email).toLowerCase()) &&
    lengthRegex.test(String(email).toLowerCase())
  )
}

const defaultFormValues = {
  email: {
    required: true,
    value: new BooleanFormValue<string>(
      '',
      value => value && validateEmail(value),
      'Invalid email address.'
    ),
  },
  financialID: {
    required: true,
    value: new BooleanFormValue<string>('', value => !!value),
  },
}

export const CreditCardPaymentScreen = ({
  resetPaymentStatusWithError,
  suppliesTotal,
  //   billingCustomer,
  modalSubmitting,
  handleCreditCardPayment,
  handleCreditCardPaymentError,
  closeModal,
  paymentStatus,
  onMissingBillingInformation,
  onLoadError,
}: Props) => {
  const [formValues, setFormValues] = useState<typeof defaultFormValues>({
    ...defaultFormValues,
  })
  const [formErrors, setFormErrors] = useState<Array<FormError>>([])

  const profileContext = useProfile()

  useEffect(() => {
    return () => {
      setFormValues({ ...defaultFormValues })
    }
  }, [])

  const setFormValuesToModified = () => {
    Object.keys(formValues).forEach((key: keyof typeof formValues) => {
      const formValue = formValues[key].value
      if (formValue) formValue.Modified = true
    })

    setFormValues(prev => ({ ...prev }))
  }

  const handleFormValidation = (
    errorEvenIfNotModified?: boolean | undefined
  ) => {
    const formErrors: Array<FormError> = []
    if (!formValues) return

    const thingsThatRequireValidation = { ...formValues }
    for (let key in thingsThatRequireValidation) {
      let formStateValue: TModifiableData<any>
      let valueIsUnacceptable = false

      formStateValue = thingsThatRequireValidation[
        key
      ] as TModifiableData<unknown>

      valueIsUnacceptable =
        formStateValue.required &&
        (formStateValue.value.Modified || errorEvenIfNotModified) &&
        !formStateValue.value.isValid()

      if (valueIsUnacceptable) {
        formErrors.push({
          id: key,
          message: formStateValue.value.getValidationMessage(),
        })
      }
    }

    if (errorEvenIfNotModified) setFormValuesToModified()
    setFormErrors(formErrors)
    return !(formErrors.length > 0)
  }

  useEffect(() => {
    handleFormValidation()
  }, [formValues])

  const onCreditCardPaymentButtonClick = () => {
    const validationSuccess = handleFormValidation(true)
    if (!validationSuccess) return
  }
  const onCreditCardPaymentSubmit = (
    paymentMethodID: string,
    billingAddress: TContactAddress,
    financialID: string,
    billingEmail: string
  ) => {
    const validationSuccess = handleFormValidation(true)
    if (!validationSuccess) return

    handleCreditCardPayment(
      paymentMethodID,
      billingAddress,
      financialID,
      billingEmail
    )
  }

  const handleChange =
    (key: keyof typeof formValues) =>
    (value: typeof formValues[typeof key]['value']['Value']) => {
      formValues[key].value.Value = value
      setFormValues(prev => ({ ...prev }))
    }

  const financialIDFilterObject = useFinancialIDSelectFilter({
    onChange: handleChange('financialID'),
    value: formValues.financialID.value?.Value,
  })

  useEffect(() => {
    if (financialIDFilterObject.loaded && financialIDFilterObject.isError) {
      financialIDFilterObject.reset()
    }
  }, [])

  const handleModalClose = () => {
    closeModal()
  }

  useEffect(() => {
    const billingInformationLoadingError = financialIDFilterObject.isError

    const noBillingContactInformationAvailable =
      financialIDFilterObject.options.size === 0 &&
      profileContext.minimumProfileLoaded &&
      financialIDFilterObject.loaded

    if (billingInformationLoadingError) {
      onLoadError(financialIDFilterObject.error)
    } else if (noBillingContactInformationAvailable) {
      onMissingBillingInformation()
    }
  }, [
    financialIDFilterObject.options,
    financialIDFilterObject.loaded,
    profileContext.minimumProfileLoaded,
  ])

  const selectedBillingContactAddress = useMemo(
    () =>
      profileContext.profile.billingCustomers
        ?.filter(b => b && b.financialID)
        .sort((a, b) =>
          a.inactive && b.inactive ? 0 : a.inactive && !b.inactive ? -1 : 1
        )
        .find(bc => bc.financialID === formValues.financialID?.value?.Value)
        ?.billToContact?.address ??
      getDefaultBillingContact(profileContext)?.billToContact?.address,
    [
      profileContext.billingCustomersLoaded &&
        formValues.financialID?.value?.Value,
    ]
  )

  useEffect(() => {
    if (financialIDFilterObject.loaded) {
      const keyIterator = financialIDFilterObject.options.keys()
      const next = keyIterator.next()
      const initialFinancialID = !next.done ? next.value : undefined

      if (initialFinancialID) {
        handleChange('financialID')(initialFinancialID)
      }
    }
  }, [financialIDFilterObject.loaded, financialIDFilterObject.options])

  if (!financialIDFilterObject.loaded) {
    return <Loader />
  }

  const showFinancialIDSelector =
    financialIDFilterObject.loaded && financialIDFilterObject.options.size > 1

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        style={{ maxWidth: '600px' }}
      >
        <Grid container direction="row" justifyContent="center">
          <h3 style={{ marginBottom: '55px' }}>Credit Card Information</h3>

          <Grid container xs={12} justifyContent="center">
            {modalSubmitting ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <Loader />
                <strong>Processing supplies payment...</strong>
                <strong>(Please do not close or refresh this page!)</strong>
              </Grid>
            ) : paymentStatus !== PaymentStatusEnum.Done ? (
              <Grid container direction="row" justifyContent="center" xs={12}>
                <StripePayComponent
                  onSubmitClick={onCreditCardPaymentButtonClick}
                  ready={
                    suppliesTotal > 0 && profileContext.minimumProfileLoaded
                  }
                  onPaymentMethodCreated={(
                    paymentMethodID: string,
                    billingAddress: TContactAddress
                  ) =>
                    onCreditCardPaymentSubmit(
                      paymentMethodID,
                      billingAddress,
                      formValues.financialID?.value?.Value,
                      formValues.email?.value?.Value
                    )
                  }
                  onError={handleCreditCardPaymentError}
                  billingAddress={selectedBillingContactAddress}
                  total={suppliesTotal}
                />

                <Grid
                  container
                  direction="row"
                  xs={12}
                  spacing={0}
                  style={{ marginTop: 25, padding: 0 }}
                  justifyContent="space-between"
                >
                  {showFinancialIDSelector && (
                    <Grid item style={{ height: 50 }} xs={5}>
                      <FormField
                        id="financialID"
                        formErrors={formErrors}
                        errorMessageStyle={formErrorMessageStyle}
                      >
                        <>
                          <InputLabel id={financialIDFilterObject.labelID}>
                            Plants *
                          </InputLabel>
                          {financialIDFilterObject.FinancialIDSelectFilter}
                        </>
                      </FormField>
                    </Grid>
                  )}

                  <Grid item style={{ height: 50 }} xs={5}>
                    <FormField
                      id="email"
                      formErrors={formErrors}
                      errorMessageStyle={formErrorMessageStyle}
                    >
                      <TextField
                        label="Receipt Email *"
                        name="email"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        type={'email'}
                        value={formValues.email?.value?.Value}
                        fullWidth
                        onChange={event =>
                          handleChange('email')(event.target.value)
                        }
                        onBlur={e => handleFormValidation()}
                      />
                    </FormField>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <strong>
                  The supplies payment was processed successfully!
                </strong>
                {formValues.email?.value?.Value && (
                  <strong>
                    Receipt sent to {formValues.email.value.Value}.
                  </strong>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: '100px', marginTop: 20 }}
                  onClick={handleModalClose}
                >
                  Close
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
