import {
  TCondition,
  TFilterState,
} from 'components/filters/SmartFilter/SmartFilterTypes'
import { TProfile } from 'services/api-service'
import { reportTypes } from 'services/lookup-service'
import { TAPIPicklistItem } from 'types/api'
import getProfileParser from 'workers/get-profile-parser.js'

export const getOptionsFromProfile = async (
  profile: Partial<TProfile>
): Promise<TFilterState> => {
  const worker = getProfileParser()
  const result = await worker.getOptionsFromProfile(profile)
  return result
}
