import { debounce } from '@mui/material'
import React, { useLayoutEffect, useRef, useState } from 'react'

const useWidth = (
  debounceDelay: number = 200
): [number, React.MutableRefObject<HTMLDivElement>] => {
  const ref = useRef<HTMLDivElement>(undefined)
  const [width, setWidth] = useState(0)

  const resizeEvent =
    // debounce(
    () => setWidth(ref.current?.getBoundingClientRect().width ?? 0)
  // , debounceDelay)

  useLayoutEffect(() => {
    resizeEvent()
    window?.addEventListener('resize', resizeEvent)
    return () => window.removeEventListener('resize', resizeEvent)
  }, [ref])

  return [width, ref]
}

export default useWidth
