import FilterTooltip from 'components/filters/FilterTooltip/FilterTooltip'
import React, { useState, useMemo } from 'react'
import {
  ErrorFormValue,
  BooleanFormValue,
  FormValue,
  FormValueTypes,
} from 'utils/FormUtilities/FormValue'
import * as Styles from '../index.module.css'
import * as PageStyles from '../../../../page-styles.module.css'
import TextField from '@mui/material/TextField'
import LocationSearchingIcon from '@mui/icons-material/LocationSearching'
import { Button, debounce, Input } from '@material-ui/core'
import { stringIsNullOrWhitespace } from 'utils/string-utilities'
import { FancyTextArea } from 'components/FancyTextArea/FancyTextArea'
import { TPlantGeneralInformation } from '../types'
import { GetPlantsResponse } from 'types/api'
import { delay } from 'utils/general-utilities'
import { formatCoordinates } from 'pages/sample/registration/constants'

type TProps = {
  back: (generalInformation: TPlantGeneralInformation) => void
  next: (generalInformation: TPlantGeneralInformation) => void
  existingPlants: GetPlantsResponse
} & Partial<FormValueTypes<TFreeFormState>>

type TFixedState = {
  formUpdated: boolean
}

type TFreeFormState = {
  plantName: ErrorFormValue<string>
  plantNumber: ErrorFormValue<string>
  location?: ErrorFormValue<GeolocationPosition>
  generalNotes: ErrorFormValue<string>
}

type TState = TFreeFormState & TFixedState
const debounceTimeMS = 0

export const GeneralInformation = (props: TProps) => {
  const plantNamesLowercase = useMemo(
    () =>
      new Map(
        props?.existingPlants
          ?.filter(p => !!p.plantName)
          ?.map(p => [p.plantName.toLowerCase(), p.plantName]) ?? []
      ),
    [props.existingPlants]
  )

  const plantNumbers = useMemo(
    () =>
      new Map(
        props?.existingPlants
          ?.filter(p => !!p.plantNumber)
          ?.map(p => [p.plantNumber, p.plantNumber]) ?? []
      ),
    [props.existingPlants]
  )

  const defaultState: Readonly<TState> = useMemo(
    () => ({
      location: new ErrorFormValue<GeolocationPosition>(
        props.location,
        c => null
      ),
      plantName: new ErrorFormValue<string>(
        props.plantName,
        pn => validatePlantName(pn, plantNamesLowercase),
        !!props.plantName
      ),
      plantNumber: new ErrorFormValue<string>(
        props.plantNumber,
        pn => validatePlantNumber(pn, plantNumbers),
        !!props.plantNumber
      ),
      generalNotes: new ErrorFormValue<string>(
        props.generalNotes,
        n => validateNotes(n),
        !!props.generalNotes
      ),
      formUpdated: false,
    }),
    []
  )
  const [state, setState] = useState<TState>({ ...defaultState })

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        loc => {
          updateFreeFormState('location', loc)
        },
        err => {},
        { enableHighAccuracy: true }
      )
    }
  }

  function updateFixedState<
    TKey extends keyof TFixedState,
    TValue extends TFixedState[TKey]
  >(key: TKey, value: TValue) {
    setState(prev => ({ ...prev, [key]: value }))
  }

  function updateFreeFormState<
    TKey extends keyof TFreeFormState,
    TValue extends TFreeFormState[TKey]['Value']
  >(key: TKey, value: TValue, automaticUpdate: boolean = false) {
    const stateValue = state[key]
    stateValue.Value = value

    if (automaticUpdate) {
      stateValue.Modified = false
    }

    setState(prev => ({ ...prev, [key]: stateValue }))
  }

  async function submit() {
    await delay(debounceTimeMS)

    FormValue.modifyAllObjects(state)
    if (!FormValue.allObjectParametersAreValid(state)) {
      updateFixedState('formUpdated', !state.formUpdated)
      return
    }

    if (props.next) {
      props.next({
        name: state.plantName.Value,
        coordinates: state.location.Value,
        generalNotes: state.generalNotes.Value,
        number: state.plantNumber.Value
          ? Number(state.plantNumber.Value)
          : null,
      })
    }
  }

  const buttons = (
    <div className={PageStyles.ButtonContainer}>
      <Button
        data-cancel
        variant="contained"
        color="primary"
        onClick={() =>
          props.back({
            name: state.plantName.Value,
            coordinates: state.location.Value,
            generalNotes: state.generalNotes.Value,
            number: state.plantNumber.Value
              ? Number(state.plantNumber.Value)
              : null,
          })
        }
        className={`${PageStyles.Button} ${PageStyles.Left}`}
      >
        About
      </Button>

      <Button
        data-accept
        disabled={!FormValue.allObjectParametersAreValid(state)}
        variant="contained"
        color="secondary"
        onClick={submit}
        className={`${PageStyles.Button} ${PageStyles.Right}`}
      >
        Contact
      </Button>
    </div>
  )

  const page = (
    <>
      <div>
        <h6 className={PageStyles.DetailSectionHeader}>General Information</h6>
        <section
          className={`${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer} ${PageStyles.Wrapper}`}
        >
          <div className={`${PageStyles.Wrappable}`}>
            <FilterTooltip
              title="The name of the plant."
              style={{ width: '100%' }}
            >
              <TextField
                error={state.plantName.Modified && !!state.plantName.getError()}
                helperText={
                  !!state.plantName.getError() &&
                  state.plantName.Modified &&
                  state.plantName.getValidationMessage()
                }
                label="Plant Name"
                autoComplete="off"
                variant="outlined"
                color="primary"
                required
                inputProps={{ maxLength: 255 }}
                fullWidth
                value={state.plantName.Value}
                onChange={event =>
                  updateFreeFormState('plantName', event.target.value)
                }
              />
            </FilterTooltip>
          </div>
          <div className={`${PageStyles.Wrappable}`}>
            <FilterTooltip
              title="The number of the plant. If not provided, plants are assigned numbers sequentially within the customer."
              style={{ width: '100%' }}
            >
              <TextField
                error={
                  state.plantNumber.Modified && !!state.plantNumber.getError()
                }
                helperText={
                  !!state.plantNumber.getError() &&
                  state.plantNumber.Modified &&
                  state.plantNumber.getValidationMessage()
                }
                label="Plant Number"
                variant="outlined"
                color="primary"
                autoComplete="off"
                inputProps={{ maxLength: 21 }}
                fullWidth
                value={state.plantNumber.Value}
                onChange={event =>
                  updateFreeFormState('plantNumber', event.target.value)
                }
                className={`${PageStyles.Wrappable}`}
              />
            </FilterTooltip>
          </div>
          <div className={`${PageStyles.Flexbox}`}>
            <div style={{ flex: 85 }}>
              <TextField
                label="Coordinates"
                variant="outlined"
                color="primary"
                inputProps={{ maxLength: 21 }}
                fullWidth
                value={formatCoordinates(state.location.Value?.coords)}
                disabled={true}
              />
            </div>
            <div className={`${PageStyles.TargetButton}`} onClick={getLocation}>
              <LocationSearchingIcon />
            </div>
          </div>
          <FancyTextArea
            borderColor="#007bff"
            labelColor="#007bff"
            label="General Notes"
            textAreaProps={{ maxLength: 65535 }}
            debounce={debounceTimeMS}
            value={state.generalNotes.Value}
            onChange={event => {
              updateFreeFormState('generalNotes', event.target.value)
            }}
          />
        </section>
      </div>
      {buttons}
    </>
  )

  return page
}

function validatePlantName(
  plantName: string,
  plantNamesByLowercase: Map<string, string>
) {
  if (!plantName) return new Error('Plant name is required.')

  if (stringIsNullOrWhitespace(plantName))
    return new Error('Plant name cannot be entirely whitespace.')
  if (plantName.length >= 256) {
    return new Error('Plant name must be less than 256 characters in length.')
  }

  if (plantNamesByLowercase.has(plantName.toLowerCase()))
    return new Error(
      `Plant name "${plantNamesByLowercase.get(plantName)}" already exists.`
    )
}
function validatePlantNumber(
  plantNumber: string,
  plantNumbers: Map<number, number>
) {
  if (!plantNumber) return null

  const num = Number(plantNumber)
  if (isNaN(num)) return new Error('The plant number must be a number.')
  if (!Number.isInteger(num))
    return new Error('The number provided must be an integer.')

  if (plantNumbers.has(num))
    return new Error(`Plant number ${plantNumbers.get(num)} already exists.`)
}

function validateNotes(notes: string) {
  if (!notes) return null
  if (notes.length > 2 ** 16 - 1) {
    return new Error('Notes must be fewer than 65535 characters long.')
  }
}
