import React from 'react'

import { VirtualSelectFilter } from '../BaseFilters/VirtualSelectFilter'
import FilterTooltip from '../FilterTooltip/FilterTooltip'

type TMachineType = {
  listValue: string
  listDesc: string
}

type TProps = {
  single: boolean
  required: boolean
  machineTypes: TMachineType[]
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange?: (props: TMachineType | TMachineType[]) => void
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setState?: (props: any) => void
  value?: string | string[] | TMachineType | TMachineType[]
  defaultValue?: string | string[] | TMachineType | TMachineType[]
  tooltip?: boolean
}

export const MachineTypeFilter = ({
  single,
  machineTypes,
  required,
  onChange,
  setState,
  defaultValue,
  value,
  tooltip = true,
}: TProps) => {
  if (setState == null && onChange == null) return

  const handleMachineTypeChange = (
    machineTypes: TMachineType | TMachineType[]
  ) => {
    if (onChange != null) {
      if (single === true) {
        onChange(machineTypes as TMachineType)
      } else {
        onChange(machineTypes as TMachineType[])
      }
    } else {
      if (single === true) {
        setState({
          selectedMachineType: (machineTypes as TMachineType)?.listValue,
        })
      } else {
        setState({
          selectedMachineTypes: (machineTypes as TMachineType[]).map(
            p => p.listValue
          ),
        })
      }
    }
  }

  const getValue = (
    val: string | string[] | TMachineType | TMachineType[]
  ): TMachineType | TMachineType[] => {
    if (typeof val === 'string') {
      const machType =
        machineTypes.find(x => x.listValue === (val as string)) ?? undefined
      return {
        listValue: machType?.listValue,
        listDesc: machType?.listDesc,
      } as TMachineType
    } else if (Array.isArray(val)) {
      const machTypes = machineTypes.filter(x =>
        (val as string[]).includes(x.listValue)
      )
      return machTypes
    } else {
      return val
    }
  }

  const filter = (
    <VirtualSelectFilter
      label="Machine Type"
      htmlFor="machineTypes"
      multiple={!single}
      id="machineTypes"
      required={required}
      value={getValue(value)}
      getOptionSelected={(option: TMachineType, value: TMachineType) =>
        option.listValue === value.listValue
      }
      defaultValue={getValue(defaultValue)}
      renderValue={(selected: TMachineType) =>
        machineTypes.find(mach => mach.listValue === selected.listValue)
          ?.listDesc ?? ''
      }
      onChange={handleMachineTypeChange}
      options={machineTypes}
    ></VirtualSelectFilter>
  )

  return tooltip ? (
    <FilterTooltip title="The manufacturer-designated classification of a machine.">
      {filter}
    </FilterTooltip>
  ) : (
    filter
  )
}
