/**
 * Written using pdfjs module, and code snippets taken from pdf-merger-js.
 * The finally output compiled to browser using parcel.
 *
 * Thanks
 * https://www.npmjs.com/package/pdfjs
 * https://www.npmjs.com/package/pdf-merger-js
 */

import JSZip from 'jszip'
import pdf from 'pdfjs'

import { TPdf } from 'types/api'
import { handleDownloadFile } from 'utils/file-utilities'

const CapacitorCore = require('@capacitor/core')

const Capacitor = CapacitorCore.Capacitor
const CapacitorFileService = require('./capacitor-file-service').default

function downloadBuffer({ buffer, fileName }) {
  if (Capacitor.isNative) {
    ;(async () => {
      await CapacitorFileService.saveAndOpen(
        fileName,
        'application/pdf',
        buffer
      )
    })()
  } else {
    handleDownloadFile({ buffer, fileName })
  }
}

async function readableToBuffer(readable: pdf.Fragment) {
  const chunks = []
  for await (const chunk of (readable as unknown) as Array<string>) {
    chunks.push(chunk)
  }
  return Buffer.concat(chunks)
}

export async function mergePdfs(pdfs: TPdf[]) {
  const doc = new pdf.Document()

  pdfs.forEach(pdfFile => {
    const ext = new pdf.ExternalDocument(pdfFile.buffer)
    doc.setTemplate(ext)
    doc.addPagesOf(ext)
  })
  doc.end()

  const buffer = await readableToBuffer(doc)
  downloadBuffer({
    buffer,
    fileName: 'TestOilReports.pdf',
  })
}
export async function downloadPdfsInZip(pdfs: TPdf[]) {
  const zip = new JSZip()
  pdfs.forEach(pdf =>
    zip.file(pdf.fileName, new Blob([toArrayBuffer(pdf.buffer)]))
  )
  zip.generateAsync({ type: 'blob' }).then(function (content) {
    window.navigator.msSaveOrOpenBlob(content, 'TestOilReports.zip')
  })
}
function toArrayBuffer(buf) {
  const ab = new ArrayBuffer(buf.length)
  const view = new Uint8Array(ab)
  for (let i = 0; i < buf.length; ++i) {
    view[i] = buf[i]
  }
  return ab
}
