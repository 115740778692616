import React, { useCallback, useEffect, useState } from 'react'

import { TAPICustomer } from 'types/api'
import { SampleIssuesModal } from 'components/SampleIssuesModal'
import APIService from 'services/api-service'
import IssueBanner from '../IssueBanner'
import { CancellablePromise } from 'utils/CancellablePromise'

interface OwnProps {
  customers: TAPICustomer[]
}

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

const SampleIssueBanner: React.FC<OwnProps> = (props: OwnProps) => {
  const [showIssueBanner, setShowIssueBanner] = useState(false)
  const [showSampleIssueModal, setShowSampleIssueModal] = useState(false)
  const [sampleIssues, setSampleIssues] = useState([])

  const fetchSampleIssues = useCallback(async () => {
    try {
      const custIDs = props.customers.map(cust => cust.custID)
      const promise = APIService.getSampleIssues(custIDs, [])
      ongoingCancellablePromises.push(promise)
      const response = await promise

      ongoingCancellablePromises.filter(p => p != promise)

      setShowIssueBanner(!!response?.length)
      setSampleIssues(response)
    } catch (error) {
      console.error()
    }
  }, [props.customers])

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise?.abortController?.abort()
      }
    }
  }, [])

  useEffect(() => {
    fetchSampleIssues()
  }, [fetchSampleIssues])

  const handleSampleIssueModalClose = () => setShowSampleIssueModal(false)

  const handleSampleIssueBannerClick = () => setShowSampleIssueModal(true)

  return (
    <IssueBanner
      onBannerClick={handleSampleIssueBannerClick}
      severity="warning"
      title="Issue Samples"
      showBanner={showIssueBanner}
    >
      <>
        <SampleIssuesModal
          customers={props.customers}
          sampleIssues={sampleIssues}
          modalOpen={showSampleIssueModal}
          onModalClose={handleSampleIssueModalClose}
        />
        There are samples with issues that we cannot process.{' '}
        <a
          style={{ cursor: 'pointer', color: '#6200EE' }}
          onClick={handleSampleIssueBannerClick}
        >
          Click here for more information.
        </a>
      </>
    </IssueBanner>
  )
}

export default SampleIssueBanner
