import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import AppLayout from 'components/AppLayout'

const TechnicalLibraryAssets = () => {
  const { fileName } = useParams()

  return (
    <div
      style={{
        textAlign: 'center',
        justifyContent: 'center',
        height: '85vh',
      }}
    >
      <object
        data={`/static/technical-library-assets/${fileName}`}
        width="100%"
        height="100%"
      ></object>
    </div>
  )
}

TechnicalLibraryAssets.propTypes = {
  profile: PropTypes.object,
  onError: PropTypes.func,
}

export default function Page() {
  return (
    <AppLayout tab="technical-library">
      <TechnicalLibraryAssets />
    </AppLayout>
  )
}
