import React from 'react'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import coreTheme from '../theme'

const theme = createTheme(
  {
    overrides: {
      MuiButton: {
        root: {
          [coreTheme.breakpoints.down('sm')]: {
            width: '185px',
          },
          [coreTheme.breakpoints.up('sm')]: {
            width: '185px',
          },
        },
      },
      // MuiButtonGroup: {
      //   [coreTheme.breakpoints.down('sm')]: {
      //     alignSelf: 'flex-end',
      //   },
      // },
    },
  },
  coreTheme
)

const options = [
  { key: '', value: 'Export Table' },
  { key: 'excel', value: 'Microsoft Excel' },
  { key: 'csv', value: 'CSV' },
]
type TExportFormats = 'excel' | 'csv'

export type TProps = {
  isExporting?: boolean
  disabled?: boolean
  onChange: (
    _event: React.MouseEvent<HTMLButtonElement>,
    _val: { key: TExportFormats; value: string }
  ) => void
}
export default function ExportButton({
  onChange,
  isExporting,
  disabled,
}: TProps) {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const handleClick = event => {
    const selected = options[selectedIndex] as {
      key: TExportFormats
      value: string
    }
    if (selected.key.toString() !== '') {
      onChange(event, selected)
    }
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
        className={'buttongroup'}
        disabled={disabled}
      >
        <Button
          onClick={handleClick}
          fullWidth
          color="primary"
          disabled={isExporting}
        >
          {options[selectedIndex].key !== ''
            ? `${isExporting ? 'Exporting' : 'Export'} as ${
                options[selectedIndex].value
              }${isExporting ? '...' : ''}`
            : options[selectedIndex].value}
        </Button>
        <Button
          color="primary"
          style={{ width: '40px' }}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="Select export format"
          aria-haspopup="menu"
          onClick={handleToggle}
          disabled={isExporting}
        >
          <ArrowDropDownIcon />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: 9999 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper className={'dropdown'}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={option.key}
                        disabled={index === 0}
                        selected={index === selectedIndex}
                        onClick={event => handleMenuItemClick(event, index)}
                      >
                        {option.value}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </ButtonGroup>
    </ThemeProvider>
  )
}
