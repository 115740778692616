import React from 'react'
import { Grid } from '@material-ui/core'

import MobileCardContent from 'components/MobileCardContent'
import MobileCardItem from 'components/MobileCardItem'

import {
  RenderMachineManufacturer,
  RenderMachineType,
  RenderRoute,  
  RenderMachineNameEdit
} from '../KendoUIGridRenders'

interface OwnProps {
  machine: any
  machineTypeOptions: any[]
  machineMfgOptions: any[]
  onMachineEditClick: (_pointID: number) => void
  readOnly: boolean
}

const MobileCard: React.FC<OwnProps> = ({
  machine,
  machineTypeOptions,
  machineMfgOptions,
  readOnly,
  onMachineEditClick,
}: OwnProps) => {
  return (
    <MobileCardContent title={machine.plantName}>
      <Grid container direction="row" spacing={1}>
        <MobileCardItem title="Customer Name">
          {machine.plant.customer.custName}
        </MobileCardItem>
        <MobileCardItem title="Plant Name">
          {machine.plant.plantName}
        </MobileCardItem>
        <MobileCardItem title="Mach No.">{machine.machNo}</MobileCardItem>
        <MobileCardItem title="Machine Name">
          <RenderMachineNameEdit
            dataItem={machine}
            readOnly={readOnly}
            onMachineEditClick={onMachineEditClick}
          />
        </MobileCardItem>
        <MobileCardItem title="Equipment ID">
          {machine.custEquID}
        </MobileCardItem>
        <MobileCardItem title="Route">
          <RenderRoute dataItem={machine} field="route" />
        </MobileCardItem>
        <MobileCardItem title="Lubricant">
          {machine.lubricant?.lubricantName}
        </MobileCardItem>

        <MobileCardItem title="Machine Type">
          <RenderMachineType
            dataItem={machine}
            field="machType"
            machineTypeOptions={machineTypeOptions}
          />
        </MobileCardItem>
        <MobileCardItem title="Manufacturer">
          <RenderMachineManufacturer
            dataItem={machine}
            field="machMFG"
            machineMfgOptions={machineMfgOptions}
          />
        </MobileCardItem>

        <MobileCardItem title="Model Name">{machine.modelName}</MobileCardItem>
        <MobileCardItem title="Sump">{machine.sumpCap}</MobileCardItem>
      </Grid>
    </MobileCardContent>
  )
}

export default MobileCard
