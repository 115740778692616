import { sendErrorMessageToSentry } from 'components/Providers/SentryProvider'
import { CancellablePromise } from 'utils/CancellablePromise'
import { getIsInternalUserHeader } from 'utils/customer-utilities'
import LoginService from './login-service'

export default class GraphQLService {
  static query(query: string, variables?: { [k: string]: any }) {
    const abortController = new AbortController()

    const queryParamValue = encodeURIComponent(
      JSON.stringify(query.replace(/[\r\n]/gi, '').replace(/\s+/gi, ' '))
        .replace(/^"/, '')
        .replace(/"$/, '')
    )
    const variablesParam = variables
      ? `&variables=${encodeURIComponent(
          JSON.stringify(variables).replace(/^"/, '').replace(/"$/, '')
        )}`
      : ''

    const promise = new Promise<any>((resolve, reject) => {
      LoginService.getStoredAccessToken()
        .then(token => {
          if (abortController.signal.aborted) {
            reject(new Error('Query aborted by user.'))
          }

          return fetch(
            `${process.env.REACT_APP_API_URL}/graphql?query=${queryParamValue}${variablesParam}`,
            {
              method: 'GET',
              signal: abortController.signal,
              headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
                ...getIsInternalUserHeader(),
              },
            }
          )
            .then(response => {
              if (abortController.signal.aborted) {
                reject(new Error('Query aborted by user.'))
              }

              return response.json().then(data => {
                if (abortController.signal.aborted) {
                  reject(new Error('Query aborted by user.'))
                }

                if (data === null) {
                  resolve(null)
                }

                if (typeof data.errors !== 'undefined') {
                  if (
                    data.errors?.map(x => x.extensions.code).toString() ===
                    'authorization'
                  ) {
                    window.location.replace('/login')
                  }

                  if (
                    query.includes('Profile') &&
                    (process.env.NODE_ENV || 'development').toLowerCase() !==
                      'development'
                  ) {
                    sendErrorMessageToSentry(
                      'User profile load has failed. First 10 errors:\n' +
                        (data.errors as string[]).slice(0, 10).join('\n')
                    )
                  }

                  reject(
                    new Error(
                      data.errors
                        .slice(0, 1)
                        .map(x =>
                          typeof x.extensions !== 'undefined'
                            ? x.extensions.code
                            : x.message
                        )
                        .join(', ')
                    )
                  )
                }

                resolve(data.data)
              })
            })
            .catch(err => {
              //this will catch on every abort.
              if (!(err instanceof DOMException)) {
                reject(new Error(err.message)) //Throw new Error - errors from fetch do not naturally have a stack trace; this provides one.
              }
            })
        })
        .catch(err => {
          //this will catch on every abort.
          if (!(err instanceof DOMException)) {
            reject(new Error(err.message)) //Throw new Error - errors from fetch do not naturally have a stack trace; this provides one.
          }
        })
    }) as CancellablePromise<any>

    promise.abortController = abortController
    return promise
  }

  static async mutate(query: string, variables?: { [k: string]: any }) {
    const token = await LoginService.getStoredAccessToken()
    const response = await fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        ...getIsInternalUserHeader(),
      },
      body: JSON.stringify({
        query: query,
        variables: variables || {},
      }),
    })

    const data = await response.json()
    if (data === null) {
      return
    }

    if (typeof data.errors !== 'undefined') {
      throw new Error(
        data.errors
          .map(x =>
            typeof x.extensions !== 'undefined' ? x.extensions.code : x.message
          )
          .join(', ')
      )
    }

    return data.data
  }
}
