import { accordionTabs } from './constants'
import { MachineFilter } from './../filters/ConfiguredFilters/MachineFilter'
import { daysDelinquentOptions, months } from 'services/lookup-service'
import { TShipmentStatus } from 'types/api'
import { TFrequency } from 'components/filters/ConfiguredFilters/FrequencyFilter'
import { TMonth } from 'components/filters/ConfiguredFilters/FiscalYearStartMonthFilter'
import { DaysDelinquentFilter } from './../filters/ConfiguredFilters/DaysDelinquentFilter'

export enum OptionsFilterSet {
  All,
  Prefiltering,
  HideCompleted,
  HideReviewed,
}

export enum DateFilterSet {
  All,
  ReportDateSampleDateSwitch,
  SampleDate,
  FromDate,
  ToDate,
  Frequency,
  FiscalYearStartMonth,
}

export enum MachineFilterSet {
  All,
  Type,
  Conditions,
  Name,
  Manufacturers,
  EquipmentIDs,
  Models,
  Number,
  SerialNumber,
}

export enum MiscellaneousFilterSet {
  All,
  ShipmentStatus,
  OmitDayOfMonth,
  OmitFullDate,
  LoadAllMachines,
  OrderNumber,
  TrackingNumber,
}

export enum LubricantFilterSet {
  All,
  Type,
  Conditions,
  Manufacturers,
}

export enum OrganizationalFilterSet {
  All,
  Divisions,
  Customers,
  Plants,
  Routes,
}

export enum SampleFilterSet {
  All,
  LabNumber,
  ExceptionCount,
  TestPackages,
  ReportTypes,
  SampleStatus,
  DaysDelinquent,
}

export enum FinancialsFilterSet {
  All,
  InvoiceNumber,
  PurchaseOrderNumber,
  InvoiceStatus,
}

export enum InvoiceStatus {
  Unpaid = 'Unpaid',
  Paid = 'Paid',
  PartialPayment = 'PartialPayment',
}

export const InvoiceStatusDisplayNameDictionary = new Map<
  InvoiceStatus,
  string
>([
  [InvoiceStatus.Unpaid, 'Unpaid'],
  [InvoiceStatus.Paid, 'Paid'],
  [InvoiceStatus.PartialPayment, 'Partial Payment'],
])

export class FilterSets {
  options?: OptionsFilterSet[]
  machine?: MachineFilterSet[]
  lubricant?: LubricantFilterSet[]
  date?: DateFilterSet[]
  organization?: OrganizationalFilterSet[]
  sample?: SampleFilterSet[]
  miscellaneous?: MiscellaneousFilterSet[]
  financials?: FinancialsFilterSet[]
  public constructor() {
    this.options = []
    this.machine = []
    this.lubricant = []
    this.date = []
    this.organization = []
    this.sample = []
    this.financials = []
  }
}

export type TAccordionTab = typeof accordionTabs[number]

export type ANDOR2 = 'AND' | 'OR'
export type TAccordionFilterSelections = {
  startDate: Date
  endDate: Date
  sampleDate: Date
  showSampleDate: boolean
  showReportDate: boolean
  labNumber: string
  hideCompleted: boolean
  onlyForSelectedMonth: boolean
  hideReviewed: boolean
  serialNumber: String
  sampleStatus: number
  frequency: TFrequency
  fiscalYearStartMonth: TMonth
  daysDelinquent: typeof daysDelinquentOptions[number]
  exceptionCount: number
  omitDayOfMonth: boolean
  omitFullDate: boolean
  standardSampleLabelDate: Date
  shipmentStatuses: TShipmentStatus[]
  invoiceNumber: string
  purchaseOrderNumber: string
  invoiceStatus: InvoiceStatus
  orderNumber: string
  trackingNumber: string
  machineLubeConditionANDOR: ANDOR2
}
