import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import CropFreeIcon from '@material-ui/icons/CropFree'
import DateRangeIcon from '@material-ui/icons/DateRange'
import LabelIcon from '@material-ui/icons/Label'
import LocalDrinkIcon from '@material-ui/icons/LocalDrink'
import SettingsIcon from '@material-ui/icons/Settings'
import ViewListIcon from '@material-ui/icons/ViewList'
import PropTypes from 'prop-types'

import AppLayout from 'components/AppLayout'
import NavCard from 'components/NavCard'
import SEO from 'components/SEO'

const pages = [
  {
    url: '/sample/registration',
    title: 'View or Register Samples',
    description:
      'View your registered sample bottles or preregister an unregistered sample bottle.',
    background: '#ef6a2f',
    icon: <LocalDrinkIcon />,
  },
]

function Sample() {
  return (
    <React.Fragment>
      <SEO title="Sample Management" />
      <Grid container direction="row" spacing={4}>
        <Grid item md={3}>
          <Typography variant="h1">Sample Management</Typography>
          <Box style={{ marginTop: '15px' }}>
            <Typography paragraph>
              Waiting for results or ready to send in a sample? Easily find or
              register your sample with the identifier printed on the bottle’s
              label. Scan the QR code to instantly find your bottle and see its
              results.
            </Typography>
          </Box>
        </Grid>
        <Grid container item direction="row" md={9} spacing={4}>
          {pages.map((page, i) => (
            <NavCard
              key={i}
              icon={page.icon}
              title={page.title}
              description={page.description}
              url={page.url}
              background={page.background}
            ></NavCard>
          ))}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default function Page() {
  return (
    <AppLayout tab="sample">
      <Sample />
    </AppLayout>
  )
}
