import { ThemeButton } from "components/buttons"
import { ConnectionStatusMonitor } from "components/ConnectionStatusMonitor"
import { useNavigate } from "react-router-dom"
import './DebugStyles.css'


const Debug = () => {

    const navigate = useNavigate()

    const handleButtonClick = () => {
        navigate(`/`)
    }

    return (
        <div className="debugDiv">
            <h2 id='debug-title'>CONFIGURATION DETAILS</h2>
            <table>
                <tr>
                    <td className="debug-td">
                        <strong>Environment: </strong>
                        <span>{process.env.REACT_APP_ENVIRONMENT}</span>
                    </td>
                </tr>
                <tr>
                    <td className="debug-td">
                        <strong>Latest Jenkins Build: </strong>#{process.env.BUILD_NUMBER}
                    </td>
                </tr>
                <tr>
                    <td className="debug-td">
                        <strong>DataSight API URL: </strong>{process.env.REACT_APP_API_URL}
                    </td>
                </tr>
                <tr>
                    <td className="debug-td">
                        <strong>DataSight XAPI URL: </strong>{process.env.REACT_APP_DS_EXPERIENCE_API_URL}
                    </td>
                </tr>
                <tr>
                    <td className="debug-td">
                        <strong>Reporting API URL: </strong>{process.env.REACT_APP_REPORTING_API_URL}
                    </td>
                </tr>
                <tr>
                    <td className="debug-td">
                        <strong>Public Stripe Key: </strong>{process.env.REACT_APP_STRIPE_API_KEY}
                    </td>
                </tr>
            </table>

            <ThemeButton
                variant="contained"
                color="primary"
                onClick={handleButtonClick}
              >
                Back to Dashboard
            </ThemeButton>
             

            <ConnectionStatusMonitor />
        </div>
    )
}

export default Debug