import { Directory, Filesystem } from '@capacitor/filesystem'


const arrayBufferToBase64 = buffer => {
  var results = null
  if (typeof window !== 'undefined') {
    var binary = ''
    var bytes = new Uint8Array(buffer)
    var len = bytes.byteLength
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    results = window.btoa(binary)
  }

  return results
}

const save = async (fileName, buffer) => {
  const base64 = arrayBufferToBase64(buffer)
  return await saveBase64(fileName, base64)
}

const saveBase64 = async (fileName, base64) => {
  const result = await Filesystem.writeFile({
    path: fileName,
    data: base64,
    directory: Directory.Documents,
  })

  return result
}

const open = async (fileName, mimeType) => {
  const uriResult = await Filesystem.getUri({
    path: fileName,
    directory: Directory.Documents,
  })

  const uri = uriResult.uri

  /*global cordova*/
  cordova.plugins.fileOpener2.open(uri, mimeType, {
    error: function (ex) {
      console.error('file open failed')
      console.error(ex)
    },
    success: function () {},
  })
}

const saveAndOpen = async (fileName, mimeType, buffer) => {
  await save(fileName, buffer)
  await open(fileName, mimeType)
}

const saveAndOpenBase64 = async (fileName, mimeType, base64) => {
  await saveBase64(fileName, base64)
  await open(fileName, mimeType)
}

export default {
  save,
  saveBase64,
  open,
  saveAndOpen,
  saveAndOpenBase64,
}
