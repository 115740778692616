import {
  InvoiceStatus,
  InvoiceStatusDisplayNameDictionary,
} from 'components/FilterAccordion/types'
import { v4 as generateGuid } from 'uuid'
import { VirtualSelectFilter } from '../BaseFilters/VirtualSelectFilter'
import FilterTooltip from '../FilterTooltip/FilterTooltip'

type TProps = {
  single?: boolean
  required?: boolean
  options: InvoiceStatus[]
  onChange?: (_value: InvoiceStatus) => void
  defaultValue?: InvoiceStatus
  value?: InvoiceStatus
  disabled?: boolean
  tooltip?: boolean
  variant?: 'filled' | 'outlined' | 'standard'
  id?: any
}

/** 
Filter requires either onChange handler which accepts an array of plants or setState which sets the currently selected plant or plants.
Key accepts a key that, if changed, resets the filters.
*/
export const InvoiceStatusesFilter = ({
  single,
  required,
  options,
  onChange,
  defaultValue,
  value,
  id,
  tooltip = true,
  ...rest
}: TProps) => {
  const filterId = id ?? generateGuid()
  const filter = (
    <VirtualSelectFilter
      label="Invoice Status"
      htmlFor={filterId}
      required={required}
      multiple={!single}
      id={filterId}
      {...rest}
      getOptionSelected={(option: InvoiceStatus, value: InvoiceStatus) =>
        option === value
      }
      defaultValue={defaultValue}
      value={value}
      renderValue={(selected: InvoiceStatus) =>
        InvoiceStatusDisplayNameDictionary.get(selected) ?? ''
      }
      options={options}
      onChange={onChange}
    ></VirtualSelectFilter>
  )

  return tooltip ? (
    <FilterTooltip title="The current payment status of the invoice.">
      {filter}
    </FilterTooltip>
  ) : (
    filter
  )
}
