import React, { useEffect, useState } from 'react'
import { FormControl, Grid } from '@material-ui/core'
import moment from 'moment'

import { ThemeButton } from 'components/buttons'
import {
  CustomerFilter,
  DivisionFilter,
  ExceptionCountFilter,
  FiscalYearStartMonthFilter,
  FromDateFilter,
  TextFieldFilter,
  ToDateFilter,
} from 'components/filters'
import OfflineBanner from 'components/offline-banner'
import * as LookupService from 'services/lookup-service'

import { IReportParametersProgramSnapshot } from './ReportViewer/TelerikReportViewer'
import { ReportViewer } from './ReportViewer'
import { useProfile } from 'Contexts/ProfileContext'

const months = LookupService.months

export type TProps = {
  offline?: boolean
  onError?: (_err: Error) => void // provided by AppLayout
}

const initialStartDate = moment().subtract(1, 'year').startOf('day').toDate()
const initialEndDate = moment().endOf('day').toDate()

export const ProgramSnapshot = (props: TProps) => {
  const offline = props.offline

  const profileContext = useProfile()

  const { divisions, customers } = profileContext.profile
  const [showReportViewer, setShowReportViewer] = useState<boolean>(false)
  const [displayName, setDisplayName] = useState<string>('')
  const [dateRangeTitle, setDateRangeTitle] = useState<string>('')
  const [fiscalYearStartMonth, setFiscalYearStartMonth] = useState<number>(1)
  const [startDate, setStartDate] = useState<Date>(initialStartDate)
  const [endDate, setEndDate] = useState<Date>(initialEndDate)
  const [selectedCustomerIDs, setSelectedCustomerIDs] = useState<number[]>(
    profileContext.minimumProfileLoaded
      ? [profileContext.profile.customers[0].custID]
      : []
  )

  const [selectedDivisionIDs, setSelectedDivisionIDs] = useState<number[]>(
    profileContext.profile?.divisions?.length > 0
      ? [profileContext.profile.divisions[0].iD]
      : []
  )
  const [exceptionCount, setExceptionCount] = useState<number>(2)
  const [reportParams, setReportParams] =
    useState<IReportParametersProgramSnapshot>({
      DisplayName: displayName,
      TitleDateString: dateRangeTitle,
      CorporateContactID: profileContext.profile?.user?.cCID || 0,
      StartDate: startDate,
      EndDate: endDate,
      FiscalYearStartMonth: fiscalYearStartMonth,
      CustomerID: profileContext.minimumProfileLoaded
        ? profileContext.profile.customers[0].custID
        : null,
      CategoryIDs: [],
      MinimumExceptionCount: exceptionCount,
    })

  useEffect(() => {
    if (profileContext.minimumProfileLoaded) {
      setSelectedCustomerIDs(prev => [
        ...prev,
        profileContext.profile.customers[0].custID,
      ])
      setReportParams(prev => ({
        ...prev,
        CustomerID: profileContext.profile.customers[0].custID,
        CorporateContactID: profileContext.profile.user.cCID || 0,
      }))
    }
  }, [
    profileContext.minimumProfileLoaded,
    profileContext.profile.customers,
    profileContext.profile.user?.cCID,
  ])

  const handleDisplayNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.stopPropagation()
    setDisplayName(event.target.value)
  }

  const handleDateRangeTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDateRangeTitle(event.target.value)
  }

  const handleFiscalYearStartMonthChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (typeof event.target.value === 'number' && event.target.value > 0) {
      setFiscalYearStartMonth(event.target.value)
    }
  }

  const handleExceptionCountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (typeof event.target.value === 'number' && event.target.value > 0) {
      setExceptionCount(event.target.value)
    }
  }

  const handleDivisionIDsChange = (ids: number[]) => {
    setSelectedDivisionIDs(ids)
  }

  const handleCustomerIDsChange = (ids: number[]) => {
    setSelectedCustomerIDs(ids)
  }

  const handleStartDateChange = (d: moment.Moment) => {
    setStartDate(d?.toDate() ?? null)
  }

  const handleEndDateChange = (d: moment.Moment) => {
    setEndDate(d?.toDate() ?? null)
  }

  const handleGenerateReport = () => {
    setShowReportViewer(true)

    let categoryIDs
    let customerID

    if (profileContext.profile?.divisions?.length > 0) {
      categoryIDs = selectedDivisionIDs
      customerID = selectedDivisionIDs[0]
    } else {
      categoryIDs = selectedCustomerIDs
      customerID = selectedCustomerIDs[0]
    }

    setReportParams({
      DisplayName: displayName,
      TitleDateString: dateRangeTitle,
      CorporateContactID: profileContext.profile.user.cCID || 0,
      StartDate: startDate,
      EndDate: endDate,
      FiscalYearStartMonth: fiscalYearStartMonth,
      CustomerID: customerID,
      CategoryIDs: categoryIDs,
      MinimumExceptionCount: exceptionCount,
    })
  }

  const getDivisions = () => {
    return profileContext.profile.divisions?.filter(d =>
      selectedDivisionIDs.includes(d.iD)
    )
  }

  return (
    <div>
      {offline ? <OfflineBanner /> : <></>}
      <form>
        <Grid container direction="row" alignItems="flex-start" spacing={2}>
          <Grid
            container
            item
            direction="column"
            xs={12}
            sm={6}
            md={6}
            lg={3}
            spacing={2}
          >
            {divisions?.length > 1 ? (
              <Grid item>
                <FormControl>
                  <DivisionFilter
                    single={false}
                    value={getDivisions()}
                    required={false}
                    divisions={divisions}
                    onChange={e => setSelectedDivisionIDs(e as number[])}
                  />
                </FormControl>
              </Grid>
            ) : (
              ''
            )}
            {divisions?.length === 0 && customers.length > 0 ? (
              <Grid item>
                <FormControl>
                  <CustomerFilter
                    single={false}
                    required={false}
                    value={profileContext.dependentData.filters.filterOptions.customers.filter(
                      c => selectedCustomerIDs.includes(c.custID)
                    )}
                    customers={customers}
                    onChange={e => setSelectedCustomerIDs(e as number[])}
                  />
                </FormControl>
              </Grid>
            ) : (
              ''
            )}
            <Grid item>
              <TextFieldFilter
                label="Display Name"
                value={displayName}
                onChange={handleDisplayNameChange}
                id="displayNameInput"
              />
            </Grid>
            <Grid item>
              <TextFieldFilter
                label="Date Range Title"
                value={dateRangeTitle}
                onChange={handleDateRangeTitleChange}
                id="dateRangeInput"
              />
            </Grid>
            <Grid item>
              <FiscalYearStartMonthFilter
                single={true}
                variant="standard"
                required={false}
                onChange={e => setFiscalYearStartMonth(e.id)}
                defaultValue={months[0]}
              />
            </Grid>
            <Grid item>
              <ExceptionCountFilter
                single={true}
                required={false}
                variant="standard"
                onChange={e => setExceptionCount(e as number)}
                defaultValue={exceptionCount}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="column"
            xs={12}
            sm={6}
            md={6}
            lg={3}
            spacing={2}
          >
            <Grid item>
              <FromDateFilter
                value={startDate}
                onChange={handleStartDateChange}
                maxDate={endDate}
              />
            </Grid>
            <Grid item>
              <ToDateFilter
                value={endDate}
                onChange={handleEndDateChange}
                minDate={startDate}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="column"
            xs={12}
            sm={6}
            md={6}
            lg={3}
            spacing={2}
          >
            <Grid item>
              <ThemeButton
                id="program_snapshot-generate_report-button"
                type="button"
                variant="contained"
                disabled={
                  !profileContext.minimumProfileLoaded ||
                  startDate?.toString() === 'Invalid Date' ||
                  endDate?.toString() === 'Invalid Date'
                }
                color="secondary"
                style={{ width: 225 }}
                onClick={handleGenerateReport}
              >
                Generate Report
              </ThemeButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {showReportViewer ? (
        <ReportViewer
          reportSource={{
            report: 'programSnapshot',
            parameters: reportParams,
          }}
        />
      ) : (
        ''
      )}
    </div>
  )
}
