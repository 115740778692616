import React, { useCallback, useMemo, useState } from 'react'
import { Hidden, TablePagination } from '@material-ui/core'
import { GridColumn as Column } from '@progress/kendo-react-grid'

import { TShipmentInformation } from 'types/api'
import ShipmentInformationModal from 'components/ShipmentInformationModal'
import TableGrid from 'components/TableGrid'
import 'react-toastify/dist/ReactToastify.css'
import {
  shippingAnchorFromOrder,
  ShippingStatusTitleFromEnum,
} from 'utils/api-utilities'

import { RenderDate } from '../KendoUIGridRenders'

import MobileCard from './MobileCard'
import { useProfile } from 'Contexts/ProfileContext'
import styled from '@emotion/styled'
import { getOrderInvoiceNumberLink } from './order-information-tools'

export type TProps = {
  orders: TShipmentInformation[]
  offline?: boolean
  orderConfig
  page
  pageSize
  count
  onPageChange
  onSortChange
  onPageChangeMobile
  onPageSizeChangeMobile
}

const OrderInformationTable: React.FC<TProps> = ({
  orders,
  orderConfig,
  page,
  pageSize,
  count,
  onPageChange,
  onSortChange,
  onPageChangeMobile,
  onPageSizeChangeMobile,
  offline,
}: TProps) => {
  const profile = useProfile()
  const [tableState, setTableState] = useState({
    order: undefined as TShipmentInformation,
    open: false as boolean,
  })
  const customerIDs: number[] | null = useMemo(() => {
    if (!profile?.profile?.customers) return null
    return profile.profile.customers.map(c => c.custID).filter(c => !!c)
  }, [profile.profile.customers])

  const handleRowclick = (order: TShipmentInformation) => {
    setTableState({ order, open: true })
  }

  const handleClose = () => {
    setTableState(prev => ({ ...prev, open: false }))
  }

  const getPlantNameFromPlantNo = useCallback(
    (custID: number, plantNo: number) => {
      return (
        profile.profile.customers
          .find(c => c.custID === custID)
          ?.plants.find(p => p.plantNo === plantNo)?.plantName ?? ''
      )
    },
    [profile.profile.customers]
  )

  const renderContactColumn = ({
    dataItem: { shipFirstName, shipLastName },
  }: any) =>
    !!shipFirstName ? (
      <td>{`${shipFirstName} ${shipLastName ?? ''}`}</td>
    ) : (
      <td></td>
    )

  return (
    <>
      <ShipmentInformationModal
        shipmentInformation={tableState.order}
        open={tableState.open}
        handleClose={handleClose}
      />
      <Hidden mdUp>
        {!orders?.length && (
          <p style={{ textAlign: 'center' }}>No records available</p>
        )}
        {orders?.map(order => (
          <MobileCard
            key={order?.orderId}
            order={order}
            onClick={() => handleRowclick(order)}
          />
        ))}

        {!offline && (
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100, 250, 500]}
            component="div"
            count={count}
            rowsPerPage={pageSize}
            page={page > 0 ? page - 1 : page}
            onChangePage={onPageChangeMobile}
            onChangeRowsPerPage={onPageSizeChangeMobile}
          />
        )}
      </Hidden>

      <Hidden smDown>
        <TableGrid
          clearSortingButtonId="order_information-clear_sorting_button"
          offline={offline}
          data={orders}
          style={{
            lineHeight: 1.5,
            cursor: 'pointer',
            maxHeight: '700px',
          }}
          sort={orderConfig}
          skip={(page - 1) * pageSize}
          take={pageSize}
          total={count}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
          onRowClick={rowProps => handleRowclick(rowProps.dataItem)}
          rowHeight={50}
          dataItemKey={'orderId'}
        >
          {profile.profile.divisions?.length > 0 && (
            <Column
              field="division"
              title="Division"
              cell={cellProps => (
                <td>
                  {profile.profile.divisions.find(d =>
                    d.customers
                      .map(c => c.custID)
                      .includes(cellProps.dataItem.custId)
                  )?.name || ''}
                </td>
              )}
            />
          )}
          {profile.profile.customers?.length > 0 && (
            <Column
              field="customer"
              title="Customer"
              cell={cellProps => (
                <td style={{ overflowX: 'hidden', textOverflow: 'ellipsis' }}>
                  {profile.profile.customers.find(
                    customer => customer.custID === cellProps.dataItem.custId
                  )?.custName || ''}
                </td>
              )}
            />
          )}
          <Column
            field="plant"
            title="Plant"
            cell={cellProps => (
              <td>
                {getPlantNameFromPlantNo(
                  cellProps.dataItem.custId,
                  cellProps.dataItem.plantNo
                )}
              </td>
            )}
          />
          <Column field="contact" title="Contact" cell={renderContactColumn} />
          <Column
            field="orderId"
            title="Order Number"
            cell={cellProps => (
              <UnderlinedClickableTD>
                <span onClick={() => handleRowclick(cellProps.dataItem)}>
                  {cellProps.dataItem[cellProps.field]}
                </span>
              </UnderlinedClickableTD>
            )}
          />
          <Column
            // field="orderId"
            title="Invoice"
            cell={cellProps => {
              const [element, _] = getOrderInvoiceNumberLink(
                cellProps.dataItem,
                customerIDs
              )

              return <td>{element}</td>
            }}
          />
          <Column
            field="orderDate"
            title="Order Date"
            cell={cellProps => <RenderDate {...cellProps} />}
          />
          <Column
            field="shipDate"
            title="Ship Date"
            cell={cellProps => <RenderDate {...cellProps} />}
          />
          <Column
            field="status"
            title="Status"
            cell={cellProps => (
              <td>
                {ShippingStatusTitleFromEnum(
                  cellProps.dataItem[cellProps.field]
                )}
              </td>
            )}
          />
          <Column
            field="trackingNumber"
            title="Tracking Number"
            cell={cellProps => (
              <td>{shippingAnchorFromOrder(cellProps.dataItem)}</td>
            )}
          />
        </TableGrid>
      </Hidden>
    </>
  )
}

export default OrderInformationTable

const UnderlinedClickableTD = styled('td')`
  text-decoration: underline;
  span:hover {
    pointer-events: all;
    opacity: 0.7;
  }
`
