import React from 'react'
import { Button } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

import coreTheme from '../theme'

const theme = createTheme(
  {
    overrides: {
      MuiButton: {
        root: {
          [coreTheme.breakpoints.down('sm')]: {
            width: '200px',
            alignSelf: 'flex-end',
          },
          [coreTheme.breakpoints.down('xs')]: {
            width: '100px',
          },
          [coreTheme.breakpoints.up('sm')]: {
            width: '225px',
          },
        },
      },
    },
  },
  coreTheme
)

type TProps = {
  onResetClick?: () => void
  disabled?: boolean
  id?: string
}

export default function ResetButton({ onResetClick, disabled, id }: TProps) {
  return (
    <ThemeProvider theme={theme}>
      <Button
        id={id}
        type={onResetClick ? undefined : 'reset'}
        disabled={disabled}
        onClick={onResetClick ? onResetClick : undefined}
        variant="contained"
        color="secondary"
      >
        Reset
      </Button>
    </ThemeProvider>
  )
}
