import FilterTooltip from 'components/filters/FilterTooltip/FilterTooltip'
import React, { useMemo, useState } from 'react'
import {
  ErrorFormValue,
  BooleanFormValue,
  FormValue,
  FormValueTypes,
  ToErrorFormValues,
} from 'utils/FormUtilities/FormValue'
import { delay } from 'utils/general-utilities'
import * as PageStyles from '../../../../page-styles.module.css'
import TextField from '@mui/material/TextField'
import { Button, Checkbox, Input } from '@material-ui/core'
import { MobileSingleSelect } from 'components/MobileSingleSelect/MobileSingleSelect'
import { statesList } from 'utils/constants'
import { TUnitedStatesState } from 'types/custom'
import { ElementDisplay } from 'pages/sample/registration/Pages/information-entry/ElementDisplay'
import { FancyTextArea } from 'components/FancyTextArea/FancyTextArea'
import { TPlantContactInformation, TPlantShippingInformation } from '../types'
import { ContactDTO, GetContactsResponse } from 'types/api'
import { emailRegex } from 'utils/common-regex'
import { Autocomplete } from '@mui/material'
import { useIsBoundByWidths } from 'hooks/useIsBoundByWidths'
import { mobileWidthDefinitonMap } from 'pages/sample/registration/Pages/constants'

type TProps = {
  back: (shippingInformation: TPlantShippingInformation) => void
  next: (shippingInformation: TPlantShippingInformation) => void
} & Partial<TPlantShippingInformation> &
  TPlantContactInformation

type TFixedState = {
  formUpdated: boolean
  usePlantContactForShipping: boolean
}

type TFreeFormState = ToErrorFormValues<TPlantShippingInformation>

type TState = TFreeFormState & TFixedState

const debounceTimeMS = 0
const checkboxID = 'usePlantContactCheckbox'

export const ShippingInformation = (props: TProps) => {
  const defaultState: Readonly<TState> = useMemo(
    () => ({
      usePlantContactForShipping: false,
      shippingFirstName: new ErrorFormValue<string>(
        props.shippingFirstName ?? '',
        a1 => validateFirstName(a1),
        !!props.shippingFirstName
      ),
      shippingLastName: new ErrorFormValue<string>(
        props.shippingLastName ?? '',
        a1 => validateLastName(a1),
        !!props.shippingLastName
      ),
      shippingEmail: new ErrorFormValue<string>(
        props.shippingEmail ?? '',
        a1 => validateEmail(a1),
        !!props.shippingEmail
      ),
      shippingAddress1: new ErrorFormValue<string>(
        props.shippingAddress1 ?? '',
        a1 => validateAddressLine1(a1),
        !!props.shippingAddress1
      ),
      shippingAddress2: new ErrorFormValue<string>(
        props.shippingAddress2 ?? '',
        a1 => validateAddressLine2(a1),
        !!props.shippingAddress2
      ),
      city: new ErrorFormValue<string>(props.city ?? '', a1 =>
        validateCity(a1)
      ),
      shippingState: new ErrorFormValue<string>(
        props.shippingState ?? '',
        state => validateState(state),
        !!props.shippingState
      ),
      shippingZipCode: new ErrorFormValue<string>(
        props.shippingZipCode ?? '',
        z => validateZip(z),
        !!props.shippingZipCode
      ),
      shippingNotes: new ErrorFormValue<string>(
        props.shippingNotes ?? '',
        n => validateNotes(n),
        !!props.shippingNotes
      ),

      formUpdated: false,
    }),
    []
  )

  const [state, setState] = useState<TState>({ ...defaultState })
  const isMobile = useIsBoundByWidths(mobileWidthDefinitonMap)['mobile']

  function updateFixedState<
    TKey extends keyof TFixedState,
    TValue extends TFixedState[TKey]
  >(key: TKey, value: TValue) {
    setState(prev => ({ ...prev, [key]: value }))
  }

  function updateFreeFormState<
    TKey extends keyof TFreeFormState,
    TValue extends TFreeFormState[TKey]['Value']
  >(key: TKey, value: TValue, automaticUpdate: boolean = false) {
    const stateValue = state[key]
    stateValue.Value = value

    if (automaticUpdate) {
      stateValue.Modified = false
    }

    setState(prev => ({ ...prev, [key]: stateValue }))
  }

  function onUsePlantContactChecked(checked: boolean) {
    if (checked) {
      updateFreeFormState('shippingFirstName', props.firstName)
      updateFreeFormState('shippingLastName', props.lastName)
      updateFreeFormState('shippingEmail', props.email)
    }

    updateFixedState('usePlantContactForShipping', checked)
  }

  async function submit() {
    await delay(debounceTimeMS)

    FormValue.modifyAllObjects(state)
    if (!FormValue.allObjectParametersAreValid(state)) {
      updateFixedState('formUpdated', !state.formUpdated)
      return
    }

    if (props.next) {
      props.next({
        shippingAddress1: state.shippingAddress1.Value,
        shippingState: state.shippingState.Value,
        shippingAddress2: state.shippingAddress2.Value,
        shippingNotes: state.shippingNotes.Value,
        shippingZipCode: state.shippingZipCode.Value,
        city: state.city.Value,
        shippingEmail: state.shippingEmail.Value,
        shippingFirstName: state.shippingFirstName.Value,
        shippingLastName: state.shippingLastName.Value,
      })
    }
  }

  const buttons = (
    <div className={PageStyles.ButtonContainer}>
      <Button
        data-cancel
        variant="contained"
        color="primary"
        onClick={() =>
          props.back({
            shippingAddress1: state.shippingAddress1.Value,
            shippingState: state.shippingState.Value,
            shippingAddress2: state.shippingAddress2.Value,
            shippingNotes: state.shippingNotes.Value,
            shippingZipCode: state.shippingZipCode.Value,
            city: state.city.Value,
            shippingEmail: state.shippingEmail.Value,
            shippingFirstName: state.shippingFirstName.Value,
            shippingLastName: state.shippingLastName.Value,
          })
        }
        className={`${PageStyles.Button} ${PageStyles.Left}`}
      >
        Contact Info.
      </Button>

      <Button
        data-accept
        disabled={!FormValue.allObjectParametersAreValid(state)}
        variant="contained"
        color="secondary"
        onClick={submit}
        className={`${PageStyles.Button} ${PageStyles.Right}`}
      >
        Submit
      </Button>
    </div>
  )

  const page = (
    <>
      <div>
        <h6 className={PageStyles.DetailSectionHeader}>
          Shipping Contact Information
        </h6>
        <section
          className={`${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer} ${PageStyles.Wrapper}`}
        >
          <div
            className={`${PageStyles.LabeledCheckboxContainer} ${PageStyles.FullWidth}`}
          >
            <Checkbox
              id={checkboxID}
              onChange={(_, checked) => onUsePlantContactChecked(checked)}
            />
            <label htmlFor={checkboxID}>
              Same as Plant Contact Information
            </label>
          </div>
          <TextField
            disabled={state.usePlantContactForShipping}
            error={
              state.shippingFirstName.Modified &&
              !!state.shippingFirstName.getError()
            }
            helperText={
              !!state.shippingFirstName.getError() &&
              state.shippingFirstName.Modified &&
              state.shippingFirstName.getValidationMessage()
            }
            label="First Name"
            variant="outlined"
            color="primary"
            name={'shippingFirstName'}
            required
            fullWidth
            value={state.shippingFirstName.Value}
            onChange={event =>
              updateFreeFormState('shippingFirstName', event.target.value)
            }
            className={`${PageStyles.Wrappable}`}
          />

          <TextField
            disabled={state.usePlantContactForShipping}
            error={
              state.shippingLastName.Modified &&
              !!state.shippingLastName.getError()
            }
            helperText={
              !!state.shippingLastName.getError() &&
              state.shippingLastName.Modified &&
              state.shippingLastName.getValidationMessage()
            }
            label="Last Name"
            variant="outlined"
            color="primary"
            name={'shippingLastName'}
            required
            fullWidth
            value={state.shippingLastName.Value}
            onChange={event =>
              updateFreeFormState('shippingLastName', event.target.value)
            }
            className={`${PageStyles.Wrappable}`}
          />

          <TextField
            disabled={state.usePlantContactForShipping}
            error={
              state.shippingEmail.Modified && !!state.shippingEmail.getError()
            }
            helperText={
              !!state.shippingEmail.getError() &&
              state.shippingEmail.Modified &&
              state.shippingEmail.getValidationMessage()
            }
            label="Email"
            variant="outlined"
            color="primary"
            name={'shippingEmail'}
            required
            fullWidth
            value={state.shippingEmail.Value}
            onChange={event =>
              updateFreeFormState(
                'shippingEmail',
                event.target.value,
                !state['shippingEmail'].Modified
              )
            }
            onBlur={event =>
              updateFreeFormState('shippingEmail', event.target.value)
            }
            className={`${PageStyles.Wrappable}`}
          />
        </section>
        <h6 className={PageStyles.DetailSectionHeader}>
          Shipping Address Information
        </h6>
        <section
          className={`${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer} ${PageStyles.Wrapper}`}
        >
          <div className={`${PageStyles.Wrappable}`}>
            <FilterTooltip
              title={'Street name and number, building name, etc.'}
            >
              <TextField
                error={
                  state.shippingAddress1.Modified &&
                  !!state.shippingAddress1.getError()
                }
                helperText={
                  !!state.shippingAddress1.getError() &&
                  state.shippingAddress1.Modified &&
                  state.shippingAddress1.getValidationMessage()
                }
                label="Address Line 1"
                variant="outlined"
                color="primary"
                name={'shippingAddress1'}
                required
                fullWidth
                value={state.shippingAddress1.Value}
                onChange={event =>
                  updateFreeFormState('shippingAddress1', event.target.value)
                }
              />
            </FilterTooltip>
          </div>
          <div className={`${PageStyles.Wrappable}`}>
            <FilterTooltip title={'Area Name'}>
              <TextField
                error={
                  state.shippingAddress2.Modified &&
                  !!state.shippingAddress2.getError()
                }
                helperText={
                  !!state.shippingAddress2.getError() &&
                  state.shippingAddress2.Modified &&
                  state.shippingAddress2.getValidationMessage()
                }
                label="Address Line 2"
                name={'shippingAddress2'}
                variant="outlined"
                color="primary"
                fullWidth
                value={state.shippingAddress2.Value}
                onChange={event =>
                  updateFreeFormState('shippingAddress2', event.target.value)
                }
              />
            </FilterTooltip>
          </div>
          <TextField
            className={`${PageStyles.Wrappable}`}
            error={state.city.Modified && !!state.city.getError()}
            helperText={
              !!state.city.getError() &&
              state.city.Modified &&
              state.city.getValidationMessage()
            }
            required
            label="City"
            variant="outlined"
            name={'city'}
            color="primary"
            fullWidth
            value={state.city.Value}
            onChange={event => updateFreeFormState('city', event.target.value)}
          />

          {isMobile ? (
            <MobileSingleSelect
              error={
                state.shippingState.Modified && !!state.shippingState.getError()
              }
              helperText={
                !!state.shippingState.getError() &&
                state.shippingState.Modified &&
                state.shippingState.getValidationMessage()
              }
              default={statesList.find(
                s => s.abbreviation === state.shippingState?.Value
              )}
              value={statesList.find(
                s => s.abbreviation === state.shippingState?.Value
              )}
              required
              options={statesList}
              stringify={opt => opt.name}
              label={'State'}
              elementificate={opt => renderElement(opt, () => opt.name)}
              onChange={opt =>
                opt && updateFreeFormState('shippingState', opt.abbreviation)
              }
            />
          ) : (
            <Autocomplete
              className={`${PageStyles.Wrappable}`}
              defaultValue={statesList.find(
                s => s.abbreviation === state.shippingState?.Value
              )}
              value={statesList.find(
                s => s.abbreviation === state.shippingState?.Value
              )}
              options={statesList}
              renderInput={params => (
                <TextField color="primary" {...params} label="State" />
              )}
              getOptionLabel={(p: TUnitedStatesState) => `${p.name}`}
              onChange={(ev, opt: TUnitedStatesState) =>
                updateFreeFormState('shippingState', opt?.abbreviation || null)
              }
            />
          )}
          <TextField
            className={`${PageStyles.Wrappable}`}
            error={
              state.shippingZipCode.Modified &&
              !!state.shippingZipCode.getError()
            }
            helperText={
              !!state.shippingZipCode.getError() &&
              state.shippingZipCode.Modified &&
              state.shippingZipCode.getValidationMessage()
            }
            required
            label="Zip Code"
            variant="outlined"
            name={'zip'}
            color="primary"
            fullWidth
            value={state.shippingZipCode.Value}
            onChange={event =>
              updateFreeFormState('shippingZipCode', event.target.value)
            }
          />
          <FancyTextArea
            borderColor="#007bff"
            labelColor="#007bff"
            label="Shipping Notes"
            textAreaProps={{ maxLength: 65535 }}
            debounce={debounceTimeMS}
            value={state.shippingNotes.Value}
            onChange={event =>
              updateFreeFormState('shippingNotes', event.target.value)
            }
          />
        </section>
      </div>
      {buttons}
    </>
  )

  return page
}

function validateAddressLine1(a1: string) {
  if (!a1) return new Error('Address line 1 is required.')
  if (a1.length > 50) {
    return new Error(
      'Address line 1 must be less than 50 characters in length.'
    )
  }
}

function validateCity(city: string) {
  if (!city) return new Error('City is required.')
  if (city.length > 255) return new Error('City is too long!')
}

function validateState(state: string) {
  if (!state) return new Error('State is required.')
}

function validateAddressLine2(address2: string) {
  if (!address2) return null
  if (address2.length > 50) {
    return new Error(
      'Address line 2 must be less than 50 characters in length.'
    )
  }
}

function validateZip(zip: string) {
  if (!zip) return new Error('Zip code is required.')

  if (zip.length > 50) {
    return new Error(
      'Address line 2 must be less than 50 characters in length.'
    )
  }

  if (isNaN(Number(zip))) return new Error('Zip code must be a number.')
}

function validateNotes(notes: string) {
  if (!notes) return null
  if (notes.length > 2 ** 16 - 1) {
    return new Error('Notes must be fewer than 65535 characters long.')
  }
}

function renderElement(
  option: TUnitedStatesState,
  toString: (option: TUnitedStatesState) => string
) {
  return <ElementDisplay>{toString(option)}</ElementDisplay>
}

function validateEmail(email: string) {
  if (!email) return new Error('An email address is required.')
  if (email.length > 255) return new Error('Email address is too long!')
  if (!emailRegex.test(email)) return new Error('Email address is invalid.')
}

function validateFirstName(name: string) {
  if (!name) return new Error('First name is required.')
  if (name.length > 255) return new Error('First name is too long!')
}

function validateLastName(name: string) {
  if (!name) return new Error('Last name is required.')
  if (name.length > 255) return new Error('Last name is too long!')
}
