import React, { MutableRefObject, useEffect, useRef } from 'react'
import { Grid, Hidden, TablePagination } from '@material-ui/core'
import {
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'

import TableGrid from 'components/TableGrid'
import { useTableStyles } from 'utils/tableStyles'

import {
  RenderTestGroupCodesAsStringCommas,
  RenderTestGroupNamesAsStringCommas,
} from '../KendoUIGridRenders'

import MobileCard from './MobileCard'
import { useCustomTableStyles } from './tableStyles'
import {
  DistributedStateContextProvider,
  useStandardSampleLabelsDistributedContext,
} from './DistributedStateContext'

interface OwnProps {
  isDivision: boolean
  offline?: boolean
  readOnly: boolean
  machines: any[]
  orderConfig: any[]
  count: number
  page: number
  pageSize: number
  onSortChange?: (_event: GridSortChangeEvent) => void
  onSelectAllChange?: (_event) => void
  onSelectedChange?: (_machine, _checked) => void
  onPageChange?: (_event: GridPageChangeEvent) => void
  onPageChangeMobile: (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    _page: number
  ) => void
  onPageSizeChangeMobile?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >
}

export const StandardLabelsTable: React.FC<OwnProps> = React.memo(
  React.forwardRef(
    (
      {
        isDivision,
        offline,
        readOnly,
        machines,
        orderConfig,
        count,
        page,
        pageSize,
        onSortChange,
        onSelectAllChange,
        onSelectedChange,
        onPageChange,
        onPageChangeMobile,
        onPageSizeChangeMobile,
      }: OwnProps,
      ref: React.MutableRefObject<HTMLInputElement[]>
    ) => {
      const classes = useTableStyles()
      const customClasses = useCustomTableStyles()

      const selectAllCheckedRef = useRef(false)

      selectAllCheckedRef.current = (
        document.querySelector('.select-all') as HTMLInputElement
      )?.checked

      useEffect(() => {
        if (ref) ref.current = []
      }, [ref])

      const distributedContext = useStandardSampleLabelsDistributedContext()

      const handleSelectAllChange = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        let checkedPointIDs = []
        if (event?.target?.checked) {
          checkedPointIDs = ref?.current?.map(v => Number(v.id))
        }

        distributedContext.setDistributedState(prev => ({
          ...prev,
          selectedPointIDs: checkedPointIDs,
        }))

        onSelectAllChange(event)
      }

      const handleCheckboxChecked = (
        hashtagPointID: string,
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        const checkedPointIDs = ref?.current
          ?.filter(p => p.checked && p)
          .map(v => Number(v.id))

        distributedContext.setDistributedState(prev => ({
          ...prev,
          selectedPointIDs: checkedPointIDs,
        }))

        onSelectedChange(hashtagPointID, event.target.checked)
      }

      const renderCheckbox = props => {
        const oldChecked =
          !!ref.current[machines.indexOf(props.dataItem)]?.checked
        return (
          <td>
            <input
              type="checkbox"
              data-indeterminate="false"
              key={props.dataItem.pointID}
              ref={el => {
                if (el) {
                  el.id = props.dataItem.pointID
                  el.checked = oldChecked
                  ref.current[machines.indexOf(props.dataItem)] = el
                }
              }}
              onChange={event => {
                handleCheckboxChecked(props.dataItem, event)
              }}
            />
          </td>
        )
      }

      const renderCheckboxHeader = _props => (
        <>
          <input
            className="select-all"
            type="checkbox"
            data-indeterminate="false"
            checked={selectAllCheckedRef.current}
            onChange={handleSelectAllChange}
          ></input>
        </>
      )

      return (
        <DistributedStateContextProvider>
          <Hidden mdUp>
            <div className={customClasses.mobile}>
              <Grid container justify="flex-start" alignItems="center">
                <span>SELECT ALL</span>
                <input
                  className="select-all"
                  type="checkbox"
                  data-indeterminate="false"
                  checked={selectAllCheckedRef.current}
                  onClick={event => handleSelectAllChange(event)}
                  style={{
                    marginLeft: 10,
                  }}
                ></input>
              </Grid>
              <div className="mobile-cards">
                {!machines?.length && (
                  <p style={{ textAlign: 'center' }}>No records available</p>
                )}
                {machines.map((machine, index) => (
                  <MobileCard
                    index={index}
                    handleCheckboxChecked={handleCheckboxChecked}
                    ref={ref}
                    showDivisions={isDivision}
                    key={machine.labID}
                    machine={machine}
                    readOnly={readOnly}
                  />
                ))}

                {!offline ? (
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50, 100, 250, 500]}
                    component="div"
                    count={count}
                    rowsPerPage={pageSize}
                    page={page > 0 ? page - 1 : page}
                    onChangePage={onPageChangeMobile}
                    onChangeRowsPerPage={onPageSizeChangeMobile}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </Hidden>

          <Hidden smDown>
            <TableGrid
              clearSortingButtonId="standard_labels-_sorting_button"
              className={[classes.table, customClasses.table].join(' ')}
              style={{ lineHeight: 1, maxHeight: window.screen.height * 0.55 }}
              data={machines}
              sort={orderConfig}
              dataItemKey={'pointID'}
              skip={(page - 1) * pageSize}
              take={pageSize}
              total={count}
              pageSize={pageSize}
              onPageChange={onPageChange}
              onSortChange={onSortChange}
              expandField="expanded"
            >
              <Column cell={renderCheckbox} headerCell={renderCheckboxHeader} />

              {isDivision && (
                <Column
                  field="divisionName"
                  title="Division"
                  cell={props => (
                    <td>{props.dataItem.plant.customer.division?.name}</td>
                  )}
                />
              )}
              <Column field="plant.customer.custName" title="Customer Name" />
              <Column field="plant.plantName" title="Plant Name" />
              <Column field="machNo" title="Machine No" />
              <Column field="machineName" title="Machine Name" />
              <Column field="custEquID" title="Equipment ID" />
              <Column
                field="route.routeNo"
                title="Route"
                cell={props => (
                  <td>
                    {props.dataItem.route &&
                      `${props.dataItem.route.routeNo} - ${props.dataItem.route.name}`}
                  </td>
                )}
              />

              <Column
                field="testPackages"
                title="Test Packages"
                cell={props => (
                  <RenderTestGroupNamesAsStringCommas {...props} />
                )}
              />

              <Column
                field="tests"
                title="Tests"
                cell={props => (
                  <RenderTestGroupCodesAsStringCommas {...props} />
                )}
              />
            </TableGrid>
          </Hidden>
        </DistributedStateContextProvider>
      )
    }
  )
)
