import { TAPISampleComplianceRecord } from 'types/api'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { machineConditionKeys } from 'services/lookup-service'

const moment = extendMoment({ ...Moment, default: null })

const getSampleStatus = (count: any): string => {
  type TStatusBlock = {
    sampled: number
    scheduled: number
  }

  if (count === 'undefined') {
    return '-'
  }

  const block: TStatusBlock = {
    sampled: count.tested === 'undefined' ? 0 : count.tested,
    scheduled: count.scheduled === 'undefined' ? 0 : count.scheduled,
  }
  return `(${block.sampled}-${block.scheduled})`
}

export const tableFormatter = (
  samples: TAPISampleComplianceRecord[],
  hasDivisions: boolean,
  startDate: Date,
  endDate: Date
): string[][] => {
  const headerValues = [
    ...(hasDivisions ? ['Division Name'] : []),
    'Customer Name',
    'Plant Name',
    'Route Name',
    'Mach No',
    'Machine Name',
    'Mach Type',
    'Test Package',
    'Tests',
  ]

  const range = moment.range(startDate, endDate)
  for (const month of range.by('month')) {
    headerValues.push(month.format('YY-MMM'))
  }

  headerValues.push('Tests Scheduled')
  headerValues.push('Tests Completed')
  headerValues.push('Compliance')

  const results = samples.map(data => {
    const months = []
    const range = moment.range(startDate, endDate)

    for (const month of range.by('month')) {
      months.push({
        year: month.year(),
        month: month.month() + 1,
      })
    }

    let result = [
      ...(hasDivisions ? [data.machine.plant.customer.division.name] : []),
      data.machine.plant.customer.custName,
      data.machine.plant.plantName,
      data.machine.route !== null
        ? `${data.machine.route.routeNo} - ${data.machine.route.name}`
        : '',
      data.machine.machNo,
      data.machine.machineName,
      data.machine.custEquID,
      data.testGroup.testGroupName,
      data.testGroup.testGroupDefs.map(x => x.testCode.testCodeName).join(', '),
    ]

    result = result.concat(
      months
        .map(x => {
          const count = data.counts.find(
            y => y.month === x.month && y.year === x.year
          )

          const condition: number =
            typeof count === 'undefined'
              ? machineConditionKeys.unknown
              : count.machCond > 2 || count.lubCond > 2
              ? machineConditionKeys.critical
              : count.machCond === 2 || count.lubCond === 2
              ? machineConditionKeys.marginal
              : count.machCond === 1 || count.lubCond === 1
              ? machineConditionKeys.normal
              : machineConditionKeys.unknown

          data = { ...data, condition }
          const status = getSampleStatus(count)

          return [status]
        })
        .reduce((prev, cur) => prev.concat(cur), [])
    )

    result.push(
      data.counts
        .map(x => x.scheduled)
        .reduce((prev, cur) => prev + cur, 0)
        ?.toString() ?? ''
    )
    result.push(
      data.counts
        .map(x => x.tested)
        .reduce((prev, cur) => prev + cur, 0)
        ?.toString() ?? ''
    )
    result.push(
      data.counts.map(x => x.scheduled).reduce((prev, cur) => prev + cur, 0) ===
        0
        ? '-'
        : data.counts.map(x => x.tested).reduce((prev, cur) => prev + cur, 0) >=
          data.counts.map(x => x.scheduled).reduce((prev, cur) => prev + cur, 0)
        ? '100'
        : Math.round(
            (data.counts
              .map(x => x.tested)
              .reduce((prev, cur) => prev + cur, 0) /
              data.counts
                .map(x => x.scheduled)
                .reduce((prev, cur) => prev + cur, 0)) *
              100
          )?.toString() ?? ''
    )
    return result
  })

  return [headerValues, ...results]
}
