import { Card, CardContent } from '@material-ui/core'

import { Link } from 'react-router-dom'
import { ResponsiveLineChart } from 'components/visualizations/brush-line/LineChart'

import React from 'react'

export type TWrappedResponsiveLineChart = {
  classes: Record<string, any>
  lineData: any
  title: string
  loaded: boolean
}

export const WrappedResponsiveLineChart: React.FC<TWrappedResponsiveLineChart> =
  React.memo(
    props => {
      const { classes, lineData } = props

      return (
        <Card variant="outlined" className={classes.card}>
          <CardContent className={classes.cardContent}>
            <div className={classes.cardHeader}>
              <Link
                className={classes.cardHeaderLink}
                to={'/reports/schedule-compliance'}
              >
                {props.title}
                {props.children}
              </Link>
            </div>
            {props.loaded ? (
              <div style={{ height: '350px' }}>
                <ResponsiveLineChart maxDateGroups={20} lineData={lineData} />
              </div>
            ) : (
              ''
            )}
          </CardContent>
        </Card>
      )
    },
    (prev, next) => {
      return prev.loaded === next.loaded
    }
  )
