import React, { useImperativeHandle, useState } from 'react'

import { TShipmentStatus } from 'types/api'
import { ShippingStatusTitleFromEnum } from 'utils/api-utilities'

import { SelectFilter } from '../BaseFilters/SelectFilter'

const ShipmentStatuses = ['INC', 'CNX', 'SHP'] as const

type TProps = {
  single?: boolean
  required?: boolean
  defaultValue: TShipmentStatus | TShipmentStatus[]
  onChange?: (_val: TShipmentStatus | TShipmentStatus[]) => void
}

const ShipmentStatusFilter = (
  { single, required, defaultValue, onChange }: TProps,
  ref
) => {
  return (
    <SelectFilter
      label="Shipment Status"
      htmlFor="ShipmentStatus"
      required={required}
      multiple={!single}
      id="ShipmentStatus"
      getOptionSelected={(option: TShipmentStatus, value: TShipmentStatus) =>
        option === value
      }
      renderValue={
        (selected: TShipmentStatus) =>
          ShippingStatusTitleFromEnum(
            ShipmentStatuses.find(
              c => c === selected
            ) as typeof ShipmentStatuses[number]
          ) // Render using util here
      }
      defaultValue={defaultValue}
      options={Array.from(ShipmentStatuses)}
      onChange={onChange}
    ></SelectFilter>
  )
}

export default ShipmentStatusFilter
