import React, { useEffect, useState } from 'react'
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import {
  TAPICustomer,
  TAPIMachine,
  TAPIMachineSchedule,
  TAPIPlant,
  TAPITestGroup,
} from 'types/api'
import { TestPackageFilter } from 'components/filters'
import { DetailedMachineFilter } from 'components/filters/ConfiguredFilters/DetailedMachineFilter'

const styles = () => ({
  table: {
    '& thead': {
      '& tr': {
        '& th': {
          backgroundColor: '#a8afba',
          color: '#fff',
          padding: '5px',
        },
      },
    },
    '& tbody': {
      '& tr': {
        '&:nth-child(odd)': {
          '& td': {
            backgroundColor: '#e8eaeb',
          },
        },
        '&:nth-child(even)': {
          '& td': {
            backgroundColor: '#f2f4f5',
          },
        },
        '& td': {
          padding: '5px',
        },
      },
    },
  },
})

function NewScheduleTable(props) {
  const {
    customers,
    plants,
    machines,
    machineNos,
    testGroups,
    schedules,
    selectedSchedules,
    classes,
    onScheduleChange,
  } = props

  const headCells = [
    { id: 'machine.machineName', text: 'Machine Selection' },
    { id: 'testGroup.testGroupName', text: 'Test Package' },
    { id: 'allMonths', text: 'All Months' },
    { id: 'jan', text: 'Jan' },
    { id: 'feb', text: 'Feb' },
    { id: 'mar', text: 'Mar' },
    { id: 'apr', text: 'Apr' },
    { id: 'may', text: 'May' },
    { id: 'jun', text: 'Jun' },
    { id: 'jul', text: 'Jul' },
    { id: 'aug', text: 'Aug' },
    { id: 'sep', text: 'Sep' },
    { id: 'oct', text: 'Oct' },
    { id: 'nov', text: 'Nov' },
    { id: 'dec', text: 'Dec' },
  ]
  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              {headCells.map(headCell => (
                <TableCell key={headCell.id}>{headCell.text}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {schedules.map((schedule, i) => (
              <NewScheduleRow
                key={i}
                customers={customers}
                plants={plants}
                machines={machines}
                machineNos={machineNos}
                testGroups={testGroups}
                schedule={schedule}
                selectedSchedule={selectedSchedules[i]}
                index={i}
                onScheduleChange={onScheduleChange}
                classes={classes}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}
type TNewScheduleRowProps = {
  schedule: TAPIMachineSchedule
  customers: TAPICustomer[]
  plants: TAPIPlant[]
  machines: TAPIMachine[]
  testGroups: TAPITestGroup[]
  machineNos: number[]
  selectedSchedule: TAPIMachineSchedule
  index: number
  onScheduleChange: (_a: any, _s: TAPIMachineSchedule, _i: number) => void
  classes?: string
}

function NewScheduleRow(props: TNewScheduleRowProps) {
  const {
    customers,
    plants,
    machines,
    machineNos,
    testGroups,
    schedule,
    selectedSchedule,
    index,
    onScheduleChange,
  } = props

  useEffect(() => {
    if (selectedSchedule)
      handleMachineChange(
        machines.find(
          x =>
            x.machNo === selectedSchedule.machine?.machNo &&
            x.machineName === selectedSchedule.machine?.machineName
        )
      )
  }, [])

  const handleMachineChange = (machine: TAPIMachine) => {
    const newSchedule = Object.assign({}, schedule)
    newSchedule.pointID = machine?.pointID ?? 0

    setSelectedMachine(machine)
    onScheduleChange(machine, newSchedule, index)
  }

  const handleTestGroupChange = (testGroup: TAPITestGroup) => {
    const newSchedule = Object.assign({}, schedule)
    newSchedule.testGroupID = testGroup?.testGroupID ?? 0

    setSelectedTestGroup(testGroup)
    onScheduleChange(testGroup, newSchedule, index)
  }

  const handleScheduleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSchedule = Object.assign({}, schedule)

    const keyName: keyof TAPIMachineSchedule & string = event.target
      .name as keyof TAPIMachineSchedule & string

    const value: boolean = event.target.checked

    newSchedule[event.target.name] = value

    if (keyName === 'allMonths') {
      Object.keys(newSchedule).forEach(key => {
        if (typeof newSchedule[key] === 'boolean') {
          newSchedule[key] = value
        }
      })
    } else {
      const filteredKeys = Object.keys(newSchedule).filter(
        key => typeof newSchedule[key] === 'boolean' && key !== 'allMonths'
      ) as Array<keyof TAPIMachineSchedule & string>

      if (filteredKeys.every(key => newSchedule[key] === true)) {
        newSchedule.allMonths = true
      } else if (filteredKeys.some(key => newSchedule[key] === false)) {
        newSchedule.allMonths = false
      }
    }

    onScheduleChange(event, newSchedule, index)
  }
  const [selectedMachine, setSelectedMachine] = useState(null)
  const [selectedTestGroup, setSelectedTestGroup] = useState(null)

  return (
    <React.Fragment>
      <TableRow>
        <TableCell style={{ display: 'grid', rowGap: '10px' }}>
          <DetailedMachineFilter
            allMachines={machines}
            allMachineNos={machineNos}
            allCustomers={customers}
            allPlants={plants}
            onMachineChange={handleMachineChange}
            initialMachine={selectedSchedule?.machine}
          />
        </TableCell>
        <TableCell>
          <TestPackageFilter
            testGroups={testGroups}
            onChange={handleTestGroupChange}
            value={selectedTestGroup}
            single={true}
            required={false}
            id={`testGroupIDs-${index}`}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            name="allMonths"
            checked={schedule.allMonths}
            onChange={handleScheduleChange}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            name="jan"
            checked={schedule.jan}
            onChange={handleScheduleChange}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            name="feb"
            checked={schedule.feb}
            onChange={handleScheduleChange}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            name="mar"
            checked={schedule.mar}
            onChange={handleScheduleChange}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            name="apr"
            checked={schedule.apr}
            onChange={handleScheduleChange}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            name="may"
            checked={schedule.may}
            onChange={handleScheduleChange}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            name="jun"
            checked={schedule.jun}
            onChange={handleScheduleChange}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            name="jul"
            checked={schedule.jul}
            onChange={handleScheduleChange}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            name="aug"
            checked={schedule.aug}
            onChange={handleScheduleChange}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            name="sep"
            checked={schedule.sep}
            onChange={handleScheduleChange}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            name="oct"
            checked={schedule.oct}
            onChange={handleScheduleChange}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            name="nov"
            checked={schedule.nov}
            onChange={handleScheduleChange}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            name="dec"
            checked={schedule.dec}
            onChange={handleScheduleChange}
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export const NewScheduleReportTable = withStyles(styles)(NewScheduleTable)
