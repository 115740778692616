import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Snackbar,
} from '@material-ui/core'
import TextField from 'components/landing/LandingTextField'
import { Alert } from '@material-ui/lab'
import { DisconnectedBanner } from 'components/Banners/DisconnectedBanner'
import { ConnectionStatusMonitor } from 'components/ConnectionStatusMonitor'
import { FormEvent, useState } from 'react'
import LandingLayout from 'components/landing/Layout'
import APIService from 'services/api-service'

const RequestResetPaswordLinkPage: React.FC = () => {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState('')

  function handleUsernameChange(event) {
    setUsername(event.target.value)
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()
    try {
      setLoading(true)
      await APIService.requestPasswordResetLink(username)
      setSuccess(true)
      setUsername('')
      setError(null)
    } catch (ex) {
      if (ex.message.indexOf('Failed to fetch') !== -1) {
        setError(
          'An error occurred while communicating with the server.  Please try your request again.'
        )
      } else {
        setError(ex.message)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <LandingLayout>
      <DisconnectedBanner />

      <form method="post" onSubmit={e => handleSubmit(e)}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="flex-start"
          spacing={2}
        >
          <Grid item>
            {success && (
              <Alert severity="success">
                Thanks you for requesting a password reset. An email has been
                sent to the email on file for that provided user name. The link
                will be active for 24 hours
              </Alert>
            )}
          </Grid>

          <Grid item>
            <FormControl>
              <TextField
                id="username"
                name="username"
                label="Username"
                variant="filled"
                value={username}
                onChange={handleUsernameChange}
                disabled={loading}
                required
              />
            </FormControl>
          </Grid>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              visibility: loading ? 'visible' : 'hidden',
            }}
          >
            <CircularProgress color="secondary" />
          </div>

          <Grid item>
            <FormControl>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={loading}
              >
                Send link
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </form>

      <Snackbar open={error !== null} onClose={() => setError(null)}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
      <ConnectionStatusMonitor />
    </LandingLayout>
  )
}

export default RequestResetPaswordLinkPage
