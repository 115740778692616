import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Box, Button, Modal, Grid, Typography } from "@material-ui/core";

import {
  ProductGroup,
  TAPICustomer,
  TCustomerProductGroup,
  TCustomerProductGroupEditing,
} from "types/api";

import { CancellablePromise } from "utils/CancellablePromise";
import { CustomerProductGroupQuery } from "models/api/CustomerProductGroupQuery";
import APIService from "services/api-service";
import ProductGroupEditingTable from "components/tables/ProductGroupEditingTable";
import Loader from "components/Loader";

type OwnProps = {
  open: boolean;
  onClose: (hasChanges: boolean) => void;
  onError?: (_err: Error) => void; // passed in from layout wrapper
  customer: TAPICustomer;
};

interface OwnState {
  submitting: boolean;
  loading: boolean;
  productGroups: ProductGroup[];
  customerProductGroups: TCustomerProductGroup;
  productGroupsToUpdate: ProductGroup[];
}

const defaults: OwnState = {
  submitting: false,
  loading: false,
  productGroups: [],
  customerProductGroups: null,
  productGroupsToUpdate: [],
};

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[];

const CustomerProductGroupEditingModal: React.FC<OwnProps> = (
  props: OwnProps
) => {
  const [state, setState] = useState<OwnState>({
    ...defaults,
  });

  const getModalStyle = () => {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  };

  const useStyles = makeStyles((_theme) => ({
    paper: {
      position: "absolute",
      width: "60vw",
      backgroundColor: "#fff",
      border: "1px solid #ddd",
      borderRadius: "8px",
      padding: "25px",
    },
  }));

  const [modalStyle] = useState(getModalStyle);

  const modalClasses = useStyles();

  useEffect(() => {
    ongoingCancellablePromises = [];
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop();
        promise.abortController?.abort();
      }
    };
  }, []);

  useEffect(() => {
    if (props.open)
      fetchData()
        // make sure to catch any error
        .catch(console.error);

    return () => {};
  }, [props.open]);

  const handleProductGroupChange = (newProductGroups: ProductGroup[]) => {
    setState((prev) => ({
      ...prev,
      productGroupsToUpdate: newProductGroups,
    }));
  };

  const handleUpdateChangesClick = async () => {
    var post = {
      customerID: props.customer.custID,
      productGroupIDs: state.productGroupsToUpdate.map((pg) => pg.groupID),
    } as TCustomerProductGroupEditing;

    let promise: CancellablePromise<TCustomerProductGroup>;
    try {
      setState((prev) => ({
        ...prev,
        submitting: true,
      }));

      promise = APIService.putCustomerProductGroups(post);
      ongoingCancellablePromises.push(promise);
      const response = await promise;

      setState((prev) => ({
        ...prev,
        productGroupsToUpdate: [],
        submitting: false,
      }));

      props.onClose(true);
    } catch (ex) {
      console.error(ex);
      setState((prev) => ({
        ...prev,
        submitting: false,
      }));
      props.onError("Unable to process the request! Try again later...");
    } finally {
      ongoingCancellablePromises.filter((p) => p != promise);
    }
  };

  const handleClose = () => {
    setState((prev) => ({
      ...prev,
      productGroupsToUpdate: [],
    }));

    props.onClose(false);
  };

  const fetchData = async () => {
    const query: CustomerProductGroupQuery = {
      customerIDs: [props.customer.custID],
    };

    let promiseCustomerProductGroups: CancellablePromise<
      TCustomerProductGroup[]
    >;
    let promiseProductGroups: CancellablePromise<ProductGroup[]>;

    try {
      setState((prev) => ({
        ...prev,
        loading: true,
      }));

      promiseCustomerProductGroups = APIService.getCustomerProductGroups(query);
      ongoingCancellablePromises.push(promiseCustomerProductGroups);

      const customerProductGroups = await promiseCustomerProductGroups;

      promiseProductGroups = APIService.getProductGroups();
      ongoingCancellablePromises.push(promiseProductGroups);

      const productGroups = await promiseProductGroups;

      setState((prev) => ({
        ...prev,
        customerProductGroups: customerProductGroups[0],
        productGroups,
        loading: false,
      }));
    } catch (ex) {
      console.error(ex);
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
      props.onError("Unable to process the request! Try again later...");
    } finally {
      ongoingCancellablePromises.filter(
        (p) => p != promiseCustomerProductGroups || p != promiseProductGroups
      );
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      id="customer_product_editing-modal"
    >
      <div style={modalStyle} className={modalClasses.paper}>
        {state.loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Loader />
          </div>
        ) : (
          <Grid item md={12} lg={12}>
            <Box mb={4}>
              <Typography variant="h3">Product Groups Edit</Typography>
              {props.customer && (
                <Typography variant="h6">
                  {props.customer.custID} - {props.customer.custName}
                </Typography>
              )}
            </Box>
            <Grid container direction="column" justifyContent="center">
              <Grid item style={{ marginBottom: 20 }}>
                <ProductGroupEditingTable
                  productGroups={state.productGroups}
                  customerProductGroups={
                    state.customerProductGroups?.productGroups
                  }
                  onProductGroupChange={handleProductGroupChange}
                  onError={props.onError}
                />
              </Grid>
              <Grid container justifyContent="flex-end">
                <Button
                  style={{ width: 225, marginRight: 10 }}
                  variant="contained"
                  color="secondary"
                  disabled={
                    !state.productGroupsToUpdate.some((p) => p) ||
                    state.submitting
                  }
                  onClick={handleUpdateChangesClick}
                >
                  Update Changes
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: 225 }}
                  onClick={handleClose}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </Modal>
  );
};

export default CustomerProductGroupEditingModal;
