import React from 'react'
import { WidthDefinitionMap } from 'types/custom.d'
import useWidth from './useWidth'
import useWindowSize from './useWindowSize'

export const useIsBoundByWidths = (widths: WidthDefinitionMap) => {
  const windowSize = useWindowSize()

  const definitionResults: { [key in keyof WidthDefinitionMap]: boolean } = {}
  Object.keys(widths).forEach(key => {
    const widthDefinition = widths[key]

    const greaterThanOrEqualtoMin = widthDefinition.minWidth
      ? widthDefinition.minWidth <= windowSize.width
      : true
    const lessThanOrEqualToMax = widthDefinition.maxWidth
      ? widthDefinition.maxWidth >= windowSize.width
      : true

    definitionResults[key] = greaterThanOrEqualtoMin && lessThanOrEqualToMax
  })

  return definitionResults
}
