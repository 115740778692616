import React, { useEffect, useState } from 'react'
import { Hidden } from '@material-ui/core'
import {
  GridColumn as Column,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'
import { Link } from 'react-router-dom'
import moment from 'moment'

import TableGrid from 'components/TableGrid'

import MobileCard from './MobileCard'
import MobileCardTotals from './MobileCardTotals'

interface OwnProps {
  data: any[]
  startDate: Date
  endDate: Date
  onSortChange?: (_event: GridSortChangeEvent) => void
  orderConfig: any[]
}

export const CommonExceptionsTable: React.FC<OwnProps> = ({
  data,
  startDate,
  endDate,
  onSortChange,
  orderConfig,
}: OwnProps) => {
  const showDivisions = data.some(d => d.plant.customer.division)

  const [dataTotals, setDataTotals] = useState({
    totalSamples: 0,
    totalAlarmedSamples: 0,
    totalAlarmedMachines: 0,
    ferrousWear: 0,
    wearMetals: 0,
    particleCount: 0,
    water: 0,
    viscosity: 0,
    degradation: 0,
    other: 0,
  })

  useEffect(() => {
    const getTotal = (field: string) =>
      data.map(x => x[field]).reduce((prev, cur) => prev + cur, 0)

    setDataTotals({
      totalSamples: getTotal('totalSamples'),
      totalAlarmedSamples: getTotal('totalAlarmedSamples'),
      totalAlarmedMachines: getTotal('totalAlarmedMachines'),
      ferrousWear: getTotal('ferrousWear'),
      wearMetals: getTotal('wearMetals'),
      particleCount: getTotal('particleCount'),
      water: getTotal('water'),
      viscosity: getTotal('viscosity'),
      degradation: getTotal('degradation'),
      other: getTotal('other'),
    })
  }, [data])

  const renderPlantName = (data: any) => (
    <td style={{ color: '#6200EE' }}>
      <Link
        to={`/reports/summary-report?custID=${
          data.plant.customer.custID
        }&plantID=${data.plant.plantID}&startDate=${moment(
          startDate
        ).format()}&endDate=${moment(endDate).format()}`}
      >
        {data.plant.plantName}
      </Link>
    </td>
  )

  const renderPlantNameMobile = (data: any) => (
    <td style={{ color: '#6200EE', border: 'none', paddingLeft: '0px' }}>
      <Link
        to={`/reports/summary-report?custID=${
          data.plant.customer.custID
        }&plantID=${data.plant.plantID}&startDate=${moment(
          startDate
        ).format()}&endDate=${moment(endDate).format()}`}
      >
        {data.plant.plantName}
      </Link>
    </td>
  )

  const renderFooter = (value: string | number) => (
    <td style={{ textAlign: 'center' }}>{value}</td>
  )

  return (
    <>
      <Hidden mdUp>
        {!data?.length && (
          <p style={{ textAlign: 'center' }}>No records available</p>
        )}
        {data.map((item, index) => (
          <MobileCard
            key={`mobile-card-${index}`}
            item={item}
            showDivisions={showDivisions}
            renderPlantName={renderPlantNameMobile}
          />
        ))}
        {data.length > 0 && <MobileCardTotals item={dataTotals} />}
      </Hidden>

      <Hidden smDown>
        <TableGrid
          clearSortingButtonId="common_exceptions-clear_sorting_button"
          data={data}
          sort={orderConfig}
          onSortChange={onSortChange}
          pageable={false}
        >
          {showDivisions && (
            <Column field="plant.customer.division.name" title="Division" />
          )}
          <Column field="plant.customer.custName" title="Customer" />
          <Column
            field="plantName"
            title="Plant"
            cell={({ dataItem: data }) => renderPlantName(data)}
            footerCell={_props => renderFooter('Totals')}
          />
          <Column
            field="totalSamples"
            title="Total Samples"
            footerCell={_props => renderFooter(dataTotals.totalSamples)}
          />
          <Column
            field="totalAlarmedSamples"
            title="Total Alarmed Samples"
            footerCell={_props => renderFooter(dataTotals.totalAlarmedSamples)}
          />
          <Column
            field="totalAlarmedMachines"
            title="Total Alarmed Machines"
            footerCell={_props => renderFooter(dataTotals.totalAlarmedMachines)}
          />
          <Column
            field="ferrousWear"
            title="Ferrous Wear"
            footerCell={_props => renderFooter(dataTotals.ferrousWear)}
          />
          <Column
            field="wearMetals"
            title="Wear Metals"
            footerCell={_props => renderFooter(dataTotals.wearMetals)}
          />
          <Column
            field="particleCount"
            title="Particle Count"
            footerCell={_props => renderFooter(dataTotals.particleCount)}
          />
          <Column
            field="water"
            title="Water"
            footerCell={_props => renderFooter(dataTotals.water)}
          />
          <Column
            field="viscosity"
            title="Viscosity"
            footerCell={_props => renderFooter(dataTotals.viscosity)}
          />
          <Column
            field="degradation"
            title="Degradation"
            footerCell={_props => renderFooter(dataTotals.degradation)}
          />
          <Column
            field="other"
            title="Other"
            footerCell={_props => renderFooter(dataTotals.other)}
          />
        </TableGrid>
      </Hidden>
    </>
  )
}
