import React from 'react'
import { Box, Hidden, TablePagination } from '@material-ui/core'
import {
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'

import { TAPISample } from 'types/api'
import TableGrid from 'components/TableGrid'

import {
  RenderArrayAsStringCommas,
  RenderDate,
  RenderLabNo,
  RenderLubeCondition,
  RenderMachineCondition,
  RenderMachineName,
  RenderReportType,
  RenderRoute,
  RenderSampleStatus,
} from '../KendoUIGridRenders'

import MobileCard from './MobileCard'

interface OwnProps {
  offline?: boolean
  samples: TAPISample[]
  orderConfig: any[]
  page: number
  pageSize: number
  count: number
  onPageChange?: (_event: GridPageChangeEvent) => void
  onSortChange?: (_event: GridSortChangeEvent) => void
  onPageChangeMobile: (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    _page: number
  ) => void
  onPageSizeChangeMobile?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >
}

export const SampleStatusReportTable: React.FC<OwnProps> = ({
  offline,
  samples,
  orderConfig,
  page,
  pageSize,
  count,
  onPageChange,
  onSortChange,
  onPageChangeMobile,
  onPageSizeChangeMobile,
}: OwnProps) => {
  return (
    <>
      {offline && (
        <Box mb={2}>Your last 100 reports are available while offline.</Box>
      )}

      <Hidden mdUp>
        {!samples?.length && (
          <p style={{ textAlign: 'center' }}>No records available</p>
        )}
        {samples.map(sample => (
          <MobileCard key={sample.labID} sample={sample} />
        ))}

        {!offline && (
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100, 250, 500]}
            component="div"
            count={count}
            rowsPerPage={pageSize}
            page={page > 0 ? page - 1 : page}
            onChangePage={onPageChangeMobile}
            onChangeRowsPerPage={onPageSizeChangeMobile}
          />
        )}
      </Hidden>

      <Hidden smDown>
        <TableGrid
          clearSortingButtonId="sample_status_report-clear_sorting_button"
          offline={offline}
          data={samples}
          sort={orderConfig}
          skip={(page - 1) * pageSize}
          take={pageSize}
          total={count}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
          expandField="expanded"
        >
          <Column
            field="sampleDate"
            title="Sample Date"
            cell={props => <RenderDate {...props} />}
          />
          <Column
            field="receivedDate"
            title="Received Date"
            cell={props => <RenderDate {...props} />}
          />
          <Column 
            field="sampleStatus" 
            title="Sample Status" 
            cell={props => <RenderSampleStatus {...props} />} 
          />
          <Column field="custName" title="Customer Name" />
          <Column field="plantName" title="Plant Name" />
          <Column
            field="routeNo"
            title="Route"
            cell={props => <RenderRoute {...props} />}
          />
          <Column field="machNo" title="Mach No." />
          <Column
            field="machName"
            title="Machine Name"
            cell={props => <RenderMachineName {...props} />}
          />
          <Column field="custEquID" title="Equipment ID" />
          <Column
            field="labID"
            title="Lab No."
            cell={props => <RenderLabNo {...props} />}
          />
          <Column
            field="reportType"
            title="Report Type"
            cell={props => <RenderReportType {...props} />}
          />
          <Column
            field="machineCondition"
            title="Mach"
            cell={props => <RenderMachineCondition {...props} />}
          />
          <Column
            field="lubeCondition"
            title="Lube"
            cell={props => <RenderLubeCondition {...props} />}
          />
          <Column
            field="testPackageList"
            title="Test Package"
            cell={props => <RenderArrayAsStringCommas {...props} />}
          />
          <Column
            field="holdDate"
            title="Hold Date"
            cell={props => <RenderDate {...props} />}
          />
          <Column field="holdReason" title="Hold Reason" />
          <Column field="holdNotes" title="Hold Notes" />
        </TableGrid>
      </Hidden>
    </>
  )
}
