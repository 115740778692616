import { ThemeButton } from 'components/buttons'
import {
  DistributedStateContextProvider,
  useStandardSampleLabelsDistributedContext,
} from 'components/tables/StandardLabels/DistributedStateContext'
import React from 'react'
type TProps = {
  disabled?: boolean
  onClick: () => void
  children?: React.ReactNode
}
export const DistributedSampleLabelsStateButton = (props: TProps) => {
  const distributedContext = useStandardSampleLabelsDistributedContext()
  return (
    <ThemeButton
      disabled={
        props.disabled || distributedContext.selectedPointIDs.length === 0
      }
      variant="contained"
      color="secondary"
      onClick={props.onClick}
    >
      {props.children}
    </ThemeButton>
  )
}
