import React from 'react'
import { Tooltip } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'

export const RenderMonthsDynamicColumns = ({
  data,
  month,
  year,
  isMobile = false,
}: any) => {
  const count = data.counts.find(y => y.month === month && y.year === year)
  const condition =
    typeof count === 'undefined'
      ? 0
      : count.machCond > 2 || count.lubCond > 2
      ? 3
      : count.machCond === 2 || count.lubCond === 2
      ? 2
      : count.machCond === 1 || count.lubCond === 1
      ? 1
      : 0

  const renderCheckBoxes = () =>
    count.tested > 0 && count.scheduled === 0 ? (
      <Tooltip title="Tested, not scheduled">
        <CheckIcon />
      </Tooltip>
    ) : count.tested === 0 && count.scheduled > 0 ? (
      <Tooltip title="Scheduled, not tested">
        <CheckBoxOutlineBlankIcon />
      </Tooltip>
    ) : count.tested === 0 && count.scheduled === 0 ? (
      ''
    ) : (
      <Tooltip title="Scheduled and tested">
        <CheckBoxIcon />
      </Tooltip>
    )

  const mobileStyles = {
    border: 'none',
    paddingLeft: '0px',
    backgroundColor:
      condition === 1
        ? '#8af07d'
        : condition === 2
        ? '#ffec12'
        : condition === 3
        ? '#f07d7d'
        : '',
  }

  if (isMobile) {
    if (!count) return <div>No content</div>
    return <div style={mobileStyles}>{renderCheckBoxes()}</div>
  }

  if (!count) return <td>No content</td>

  return <td style={mobileStyles}>{renderCheckBoxes()}</td>
}
