import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import AppLayout from 'components/AppLayout'
import { ExportButton, ThemeButton } from 'components/buttons'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import SEO from 'components/SEO'
import {
  ManageRoutesReportTable,
  tableFormatter,
} from 'components/tables/ManageRoutesReport'
import APIService from 'services/api-service'
import { exportData } from 'services/export-service'
import { useProfile } from 'Contexts/ProfileContext'
import { CancellablePromise } from 'utils/CancellablePromise'

const defaults = {
  page: 1,
  pageSize: 10,
  count: 0,
  orderConfig: [{ field: 'name', dir: 'asc' }],
  selectedCustomers: [],
  exportFormat: '',
}

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

const Routes = ({ offline, onError }: any) => {
  const navigate = useNavigate()

  const [state, setState] = useState({
    ...defaults,
    loaded: false,
    dataLoaded: false,
    data: [],
  })

  const fetchRoutes = useCallback(
    async variables => {
      let promise
      try {
        promise = APIService.getRoutes(variables)

        ongoingCancellablePromises.push(promise)
        const data = await promise

        if (data.routes !== null) {
          onError(null)
          setState(prev => ({
            ...prev,
            count: data.routes.count,
            data: data.routes.items,
            dataLoaded: true,
          }))
        }
      } catch (ex) {
        onError(ex)
      } finally {
        ongoingCancellablePromises.filter(p => p != promise)
      }
    },
    [onError]
  )

  const performSearch = useCallback(() => {
    fetchRoutes({
      page: state.page,
      pageSize: state.pageSize,
      orderList: state.orderConfig.map(x => x.dir),
      orderByList: state.orderConfig.map(x => x.field),
    })
  }, [fetchRoutes, state.orderConfig, state.page, state.pageSize])

  useEffect(() => {
    if (!state.dataLoaded) {
      performSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dataLoaded])

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  const profileContext = useProfile()

  const handleExportFormatChange = async (event, format) => {
    let promise
    try {
      promise = APIService.getRoutes({
        page: state.page,
        pageSize: 0,
        orderList: state.orderConfig?.map(x => x.dir) ?? [],
        orderByList: state.orderConfig?.map(x => x.field) ?? [],
      })

      ongoingCancellablePromises.push(promise)
      const data = await promise

      const formatted = tableFormatter(
        data.routes.items,
        profileContext.dependentData.userDetails.isDivisionUser
      )
      exportData('routes', formatted, format.key)
    } catch (ex) {
      onError(ex)
    } finally {
      ongoingCancellablePromises.filter(p => p != promise)
    }
  }

  const handleAddNewRouteClick = _event => {
    navigate('/program-management/routes/manage')
  }

  const handlePageChange = event => {
    setState(prev => ({
      ...prev,
      page: event.page.skip / state.pageSize + 1,
      pageSize: event.page.take,
      dataLoaded: false,
    }))
  }

  const handleSortChange = event => {
    const isFieldRemoved = state.orderConfig.length > event.sort.length
    let isLastFieldRemoved = false

    if (isFieldRemoved) {
      const fieldRemoved = state.orderConfig.filter(
        x => !event.sort.includes(x)
      )[0]

      isLastFieldRemoved =
        state.orderConfig.indexOf(fieldRemoved) === state.orderConfig.length - 1
    }

    setState(prev => ({
      ...prev,
      orderConfig: [...event.sort],
      dataLoaded: !(event.sort.length > 0 && !isLastFieldRemoved),
    }))
  }

  const handlePageChangeMobile = (event, newPage) => {
    setState(prev => ({ ...prev, page: newPage + 1, dataLoaded: false }))
  }

  const handlePageSizeChangeMobile = event => {
    setState(prev => ({
      ...prev,
      page: 1,
      pageSize: parseInt(event.target.value, 10),
      dataLoaded: false,
    }))
  }

  return (
    <>
      <SEO title="Manage Routes" />
      <Box mb={2}>
        <Typography variant="h1">Manage Routes</Typography>
      </Box>
      {offline ? <OfflineBanner /> : <></>}
      <Box mt={5}>
        {profileContext.profile?.user?.readOnly ? (
          ''
        ) : (
          <Grid
            container
            item
            direction="row"
            alignItems="flex-start"
            justify="flex-end"
            spacing={2}
          >
            <Grid item>
              <ThemeButton
                variant="contained"
                color="primary"
                onClick={handleAddNewRouteClick}
              >
                Add New Route
              </ThemeButton>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          direction="row"
          alignItems="flex-end"
          justify="flex-end"
          spacing={2}
        >
          <Grid item xs={12} sm={6}>
            <Box style={{ textAlign: 'right' }}>
              <ExportButton onChange={handleExportFormatChange} disabled={state.data.length < 1}/>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={4}>
        {!state.dataLoaded ? (
          <Loader />
        ) : (
          <ManageRoutesReportTable
            readOnly={profileContext.profile?.user?.readOnly}
            routes={state.data}
            orderConfig={state.orderConfig}
            page={state.page}
            pageSize={state.pageSize}
            count={state.count}
            onSortChange={handleSortChange}
            onPageChange={handlePageChange}
            onPageChangeMobile={handlePageChangeMobile}
            onPageSizeChangeMobile={handlePageSizeChangeMobile}
            offline={offline}
          />
        )}
      </Box>
    </>
  )
}

export default function Page() {
  return (
    <AppLayout tab="program-management">
      <Routes />
    </AppLayout>
  )
}
