import React from 'react'
import { Box, Hidden, TablePagination } from '@material-ui/core'
import {
  GridColumn as Column,
  GridHeaderCellProps,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'
import Moment from 'moment'
import { extendMoment } from 'moment-range'

import TableGrid from 'components/TableGrid'
import { useTableStyles } from 'utils/tableStyles'

import { RenderTestGroupsAsStringCommas } from '../KendoUIGridRenders'

import {
  renderMachineName,
  renderMonth,
  renderMonthHeader,
} from './customRenders'
import MobileCard from './MobileCard'
import { useCustomTableStyles } from './tableStyles'

interface OwnProps {
  startDate: Date
  endDate: Date
  readOnly: boolean
  offline?: boolean
  data: any[]
  classes: any
  orderConfig: any[]
  page: number
  pageSize: number
  count: number
  onPageChange?: (_event: GridPageChangeEvent) => void
  onSortChange?: (_event: GridSortChangeEvent) => void
  onPageChangeMobile: (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    _page: number
  ) => void
  onPageSizeChangeMobile?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >
}

const moment = extendMoment({ ...Moment, default: null })

export const ScheduledSamplesDueTable: React.FC<OwnProps> = ({
  startDate,
  endDate,
  offline,
  readOnly,
  data,
  orderConfig,
  page,
  pageSize,
  count,
  onPageChange,
  onSortChange,
  onPageChangeMobile,
  onPageSizeChangeMobile,
}: OwnProps) => {
  const classes = useTableStyles()
  const customClasses = useCustomTableStyles()

  const showDivisions = data.some(s => s.machine.plant.customer.division)

  const months = []
  const range = moment.range(startDate, endDate)
  for (const month of range.by('month')) {
    months.push({
      year: month.year(),
      month: month.month(),
    })
  }

  return (
    <>
      {offline && (
        <Box mb={2}>Your last 100 reports are available while offline.</Box>
      )}
      <Hidden mdUp>
        {!data?.length && (
          <p style={{ textAlign: 'center' }}>No records available</p>
        )}
        {data.map(item => (
          <MobileCard
            showDivisions={showDivisions}
            data={item}
            readOnly={readOnly}
            months={months}
          />
        ))}

        {!offline && (
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100, 250, 500]}
            component="div"
            count={count}
            rowsPerPage={pageSize}
            page={page > 0 ? page - 1 : page}
            onChangePage={onPageChangeMobile}
            onChangeRowsPerPage={onPageSizeChangeMobile}
          />
        )}
      </Hidden>

      <Hidden smDown>
        <TableGrid
          clearSortingButtonId="schedule_samples_due_sorting_button"
          className={[classes.table, customClasses.table].join(' ')}
          offline={offline}
          data={data}
          sort={orderConfig}
          skip={(page - 1) * pageSize}
          take={pageSize}
          total={count}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
          expandField="expanded"
        >
          {showDivisions && (
            <Column
              field="machine.plant.customer.division.name"
              title="Division"
            />
          )}
          <Column
            field="machine.plant.customer.custName"
            title="Customer Name"
          />
          <Column field="machine.plant.plantName" title="Plant Name" />
          <Column
            field="machine.route.routeNo"
            title="Route"
            cell={props => (
              <td>
                {props.dataItem.machine.route &&
                  `${props.dataItem.machine.route.routeNo} - ${props.dataItem.machine.route.name}`}
              </td>
            )}
          />
          <Column field="machine.machNo" title="Mach No." width={50} />
          <Column
            field="machine.machineName"
            title="Machine Name"
            cell={props => renderMachineName(props.dataItem)}
          />
          <Column field="machine.custEquID" title="Equipment ID" />

          <Column
            field="testGroup.testGroupName"
            title="Test Package"
            width={60}
          />

          <Column
            field="testGroupDefs"
            title="Tests"
            cell={props => (
              <RenderTestGroupsAsStringCommas
                {...props}
                dataItem={props.dataItem.testGroup}
              />
            )}
            sortable={false}
          />
          {months.map(month => (
            <Column
              cell={props => renderMonth(props.dataItem, month)}
              headerCell={(
                props: React.PropsWithChildren<GridHeaderCellProps>
              ) => renderMonthHeader(month)}
              width={30}
              sortable={false}
            />
          ))}
        </TableGrid>
      </Hidden>
    </>
  )
}
