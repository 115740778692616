import * as PageStyles from '../../../../page-styles.module.css'
import { Button } from '@material-ui/core'

import {
  CustomerResponseDTO,
  GetMachinesResponse,
  GetPlantsResponseDTO,
  MachinesResponseDTO,
} from 'types/api'

type TProps = {
  customerData: CustomerResponseDTO
  plantData: GetPlantsResponseDTO
  machineData: MachinesResponseDTO
  back: () => void
  next: () => void
}

export const AboutMachineEdit = (props: TProps) => {
  const buttons = (
    <div className={PageStyles.ButtonContainer}>
      <Button
        data-cancel
        variant="contained"
        color="primary"
        onClick={props.back}
        className={`${PageStyles.Button} ${PageStyles.Left}`}
      >
        Cancel
      </Button>

      <Button
        data-accept
        variant="contained"
        color="secondary"
        onClick={props.next}
        className={`${PageStyles.Button} ${PageStyles.Right}`}
      >
        Machine Info.
      </Button>
    </div>
  )

  return (
    <>
      <div>
        <h6 className={PageStyles.DetailSectionHeader}>Customer Information</h6>
        <section
          className={`${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer}`}
        >
          <ul className={PageStyles.Details}>
            <li>
              <span className={PageStyles.Property}>
                {props.customerData?.customerID}
                {' - '}
                {props.customerData?.customerName}
              </span>
            </li>
          </ul>
        </section>

        <h6 className={PageStyles.DetailSectionHeader}>Plant Information</h6>
        <section
          className={`${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer}`}
        >
          <ul className={PageStyles.Details}>
            <li>
              <span className={PageStyles.Property}>
                {props.plantData?.plantID}
                {' - '}
                {props.plantData?.plantName}
              </span>
            </li>
          </ul>
        </section>

        <h6 className={PageStyles.DetailSectionHeader}>Machine Information</h6>
        <section
          className={`${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer}`}
        >
          <ul className={PageStyles.Details}>
            <li>
              <span className={PageStyles.Property}>
                {props.machineData?.pointID}
                {' - '}
                {props.machineData?.customerEquipmentID}
              </span>
            </li>
          </ul>
        </section>
        <h6 className={PageStyles.DetailSectionHeader}>About Registration</h6>
        <section className={PageStyles.DetailSection}>
          <p className={PageStyles.FinePrintParagraph}>
            Machine editing will edit machine information for the selected
            machine. The edits made during sample registration extend beyond
            sample registration; these changes will permeate the entirety of
            Datasight, AssetLink, internal systems, and any other applications
            referencing machine information. For questions, concerns, or
            assistance, please contact customer support at{' '}
            <a href={`mailto: ${process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL}`}>
              {process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL!}
            </a>
            {'.'}
          </p>
        </section>
      </div>
      {buttons}
    </>
  )
}
