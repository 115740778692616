let timer: NodeJS.Timeout
export function debounce<TArgs, TReturn>(
  func: (args?: TArgs) => TReturn,
  timeoutInMS = 500
) {
  return (args?: TArgs) => {
    clearTimeout(timer)
    timer = setTimeout(() => func(args), timeoutInMS)
  }
}
