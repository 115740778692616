import {
  useEffect,
  useState,
  useContext,
  useRef,
  useMemo,
  useCallback,
} from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import AppLayout from 'components/AppLayout'
import AddEquipmentBanner from 'components/Banners/AddEquipmentBanner/AddEquipmentBanner'
import {
  MachineManufacturerFilter,
  MachineTypeFilter,
  PairedFluidTypeManufacturerFilter,
  PlantNameFilter,
  TestPackageFilter,
  RouteNameFilter,
} from 'components/filters'
import FilterLabel from 'components/filters/FilterLabel/FilterLabel'
import {
  Filters,
  TFilterState,
  TSmartFilter,
} from 'components/filters/SmartFilter/SmartFilterTypes'
import WithSmartFilters from 'components/filters/SmartFilter/WithSmartFilters'
import { FormField } from 'components/FormField'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import APIService, { TProfile } from 'services/api-service'
import { monthAbbreviations } from 'services/lookup-service'
import { useNonInitialEffect } from 'hooks/useNonInitialEffect'
import { ProfileContext, useProfile } from 'Contexts/ProfileContext'
import { binarySearch, IsSorted } from 'utils/ArrayMethods'
import { TAPIPlant } from 'types/api'

const defaults = {
  selectedCustomers: [],
  selectedPlants: [],
  custID: '',
  plantID: '',
  contact: '',
  email: '',
  machineType: '',
  machineTypeDesc: '',
  custEquID: '',
  machineName: '',
  machineMfg: '',
  machineMfgDesc: '',
  modelNumber: '',
  lubeMfg: '',
  lubeManufacturerDesc: '',
  lubeNameAndGrade: '',
  sampleMonths: [],
  noRegularSample: false,
  sumpCap: '',
  notes: '',
  selectedCustomerID: null,
  selectedCustomerName: null,
  testGroupID: null,
  selectedTestGroupID: null,
  selectedTestGroupName: null,
  selectedPlant: null,
  selectedPlantName: '',
  selectedMachineNames: [],
  selectedTestGroup: null,
  selectedMachineType: null,
  selectedMachineMfg: null,
  selectedRoute: -1,
  formErrors: [],
}

const NewEquipment = ({ offline, profile, onError }: any) => {
  const navigate = useNavigate()
  const [state, setState] = useState({
    ...defaults,
    plants: [],
    routes: [],
    customers: [],
    divisions: [],
    machines: [],
    machineTypes: [],
    machineTypeOptions: [],
    machineMfgs: [],
    machineMfgOptions: [],
    lubeMfrOptions: [],
    loaded: false,
    testGroups: [],
    selectedLubeMfg: null,
    selectedLubeType: null,
    selectedLubeNameAndGrade: '',
    loadingFluids: false,
    submitting: false,
    user: {
      firstName: '',
      lastName: '',
    },
    ...profile,
  })

  const profileContext = useProfile()

  const machineNameRef = useRef('')

  useEffect(() => {
    setState(prev => ({
      ...prev,
      ...profileContext.profile,
      profileUpdated: profileContext.fullProfileLoaded,
    }))
  }, [profileContext])

  useNonInitialEffect(() => {
    handleFormValidation()
  }, [
    state.selectedMachineType,
    state.selectedMachineMfg,
    state.selectedLubeNameAndGrade,
    state.selectedTestGroup,
    state.noRegularSample,
    state.sampleMonths,
    state.machineName,
    state.selectedPlantName,
  ])

  const handleChange = event => {
    setState(prev => ({ ...prev, [event.target.name]: event.target.value }))
  }

  const handleMachineTypeChange = machineType => {
    setState(prev => ({ ...prev, selectedMachineType: machineType }))
  }

  const handleMachineMfgChange = machineMfg => {
    setState(prev => ({ ...prev, selectedMachineMfg: machineMfg }))
  }

  const handleTestPackageChange = testPackage => {
    setState(prev => ({ ...prev, selectedTestGroup: testPackage }))
  }

  const handleLubeTypeChange = value => {
    setState(prev => ({
      ...prev,
      selectedLubeType: value.lubeTypeID ?? '',
      selectedLubeMfg: value.lubeMFG ?? '',
      selectedLubeNameAndGrade: value.lubricantName ?? '',
    }))
  }

  const handleNoRegularSampleChange = event => {
    setState(prev => ({
      ...prev,
      noRegularSample: event.target.checked,
      sampleMonths: [],
    }))
  }

  const handleSampleMonthChange = event => {
    const newSampleMonths = [].concat(state.sampleMonths)
    if (event.target.checked) {
      newSampleMonths.push(event.target.name)
    } else {
      const index = newSampleMonths.indexOf(event.target.name)
      if (index !== -1) {
        newSampleMonths.splice(index, 1)
      }
    }

    setState(prev => ({ ...prev, sampleMonths: newSampleMonths }))
  }

  const handleAllSampleMonthChange = event => {
    const newSampleMonths = event.target.checked ? [...monthAbbreviations] : []
    setState(prev => ({ ...prev, sampleMonths: newSampleMonths }))
  }

  const handleCancelClick = event => {
    navigate('/program-management/equipment')
  }

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const handleMachineNameChange = event => {
    machineNameRef.current = event.target.value
  }

  const handlePlantChange = value => {
    setState(prev => ({
      ...prev,
      selectedPlant: value ?? null,
    }))
  }

  const handleRouteChange = value => {
    setState(prev => ({
      ...prev,
      selectedRoute: value ?? null,
    }))
  }

  const getMachineNameExistsInPlant = useCallback(
    (machineName: string, plant: TAPIPlant) => {
      if (
        plant.plantName.toUpperCase().trim() ===
        state.selectedPlant?.plantName.toUpperCase().trim()
      ) {
        const comparator = (a, b) => (a > b ? 1 : a == b ? 0 : -1)

        if (plant.machines?.length > 0) {
          const machineNames = plant.machines.map(machine => {
            return machine.machineName.toUpperCase().trim()
          })

          let sortedMachineNames = machineNames

          if (!IsSorted(machineNames, comparator)) {
            sortedMachineNames = machineNames.sort((a, b) => {
              return comparator(a, b)
            })
          }

          const index = binarySearch(
            sortedMachineNames,
            machineName,
            comparator
          )

          return index !== -1
        }
      }
      return false
    },
    [state.selectedPlant]
  )

  const machineNameRefExistsInAnyPlant = useMemo(() => {
    return profileContext.profile.plants.some(plant =>
      getMachineNameExistsInPlant(
        machineNameRef.current.toUpperCase().trim(),
        plant
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getMachineNameExistsInPlant,
    profileContext.profile.plants,
    machineNameRef.current,
  ])

  const handleFormValidation = () => {
    const formErrors = []

    const machineNameExists = machineNameRefExistsInAnyPlant

    if (!state.email) {
      formErrors.push({ id: 'email', message: 'Required' })
    }
    if (state.selectedPlant === null) {
      formErrors.push({ id: 'plantName', message: 'Required' })
    }
    if (!validateEmail(state.email)) {
      formErrors.push({ id: 'email', message: 'Invalid' })
    }
    if (!state.machineName) {
      formErrors.push({ id: 'machineName', message: 'Required' })
    }
    if (machineNameExists) {
      formErrors.push({
        id: 'machineName',
        message: 'Machine name already exists for this plant.',
      })
    }
    if (!state.selectedMachineType) {
      formErrors.push({ id: 'machineType', message: 'Required' })
    }
    if (!state.selectedMachineMfg) {
      formErrors.push({ id: 'machineMfg', message: 'Required' })
    }
    if (!state.selectedLubeType) {
      formErrors.push({ id: 'lubeType', message: 'Required' })
    }
    if (!state.noRegularSample && state.sampleMonths.length === 0) {
      formErrors.push({
        id: 'sampleMonths',
        message: 'At least one month is required',
      })
    }

    setState(prev => ({ ...prev, formErrors: formErrors }))

    return !formErrors.some(x => x)
  }

  const handleSubmit = async (event, filterState: TFilterState) => {
    event.preventDefault()

    if (!handleFormValidation()) return

    if (!state.submitting) {
      setState(prev => ({ ...prev, submitting: true }))

      await injectSmartFilterState(filterState)

      try {
        const equipmentBody = {
          custID: state.selectedCustomerID ?? state.customers[0].custID,
          plantID: state.selectedPlant.plantID ?? state.plants[0].plantID,
          routeName:
            state.selectedRoute == null || state.selectedRoute < 0
              ? -1
              : state.selectedRoute.name,
          customerName:
            state.selectedCustomerName ?? state.customers[0].custName,
          contact: state.user.firstName + ' ' + state.user.lastName,
          email: state.email,
          machineName: state.machineName,
          custEquID: state.custEquID,
          machineType: state.selectedMachineType.listValue,
          machineTypeDesc: state.selectedMachineType.listDesc ?? '',
          machineManufacturer: state.selectedMachineMfg.listValue,
          machineManufacturerDesc: state.selectedMachineMfg.listDesc ?? '',
          modelNumber: state.modelNumber,
          lubeManufacturer: state.selectedLubeMfg,
          lubeManufacturerDesc:
            state.lubeMfrOptions.find(
              x => x.pickListValue === state.selectedLubeMfg
            ).lubemfr ?? '',
          lubeNameAndGrade: state.selectedLubeNameAndGrade,
          sampleMonths: state.noRegularSample ? [] : state.sampleMonths,
          lubeMFG: state.selectedLubeMfg,
          lubeTypeID: state.selectedLubeType,
          notes: state.notes,
          testGroupName: state.selectedTestGroup?.testGroupName,
        }
        await APIService.createEquipment(equipmentBody)
        navigate('/program-management/equipment')
      } catch (ex) {
        onError(ex)
        setState(prev => ({ ...prev, submitting: false }))
      }
    }
  }

  const injectSmartFilterState = async (smartState: TFilterState) => {
    setState(prev => ({
      ...prev,
      selectedCustomerID:
        smartState.customers.length > 0 ? smartState.customers[0].custID : null,
      selectedCustomerName:
        smartState.customers.length > 0
          ? smartState.customers[0].custName
          : null,
      selectedRoute:
        smartState.routes.length > 0 ? smartState.routes[0].name : null,
      selectedPlant:
        smartState.plants.length > 0 ? smartState.plants[0].plantID : null,
      selectedReportTypes: smartState.reportTypes.map(r => r.listValue),
    }))
  }

  const generateOptions = (): Partial<TFilterState> => {
    return {
      customers: state.customers,
      divisions: state.divisions,
      machines: state.machines,
      plants: state.plants,
      routes: state.routes,
      machineManufacturers: state.machineMfgOptions,
      machinePicklistTypes: state.machineTypeOptions,
      testPackages: state.testGroups,
    } as Partial<TFilterState>
  }

  return (
    <>
      <AddEquipmentBanner />

      <Grid container justify="center">
        <Grid item md={4} lg={2}>
          <Box mb={2}>
            <Typography variant="h1">Add New Equipment</Typography>

            {offline ? <OfflineBanner /> : <></>}

            {!profileContext.fullProfileLoaded ? (
              <Loader />
            ) : (
              <WithSmartFilters
                filterOptions={
                  profileContext.dependentData.filters.filterOptions
                }
                disableOptionFiltering={true}
                filters={[
                  Filters.Divisions,
                  Filters.Customers,
                  Filters.Plant,
                  Filters.MachineName,
                  Filters.MachineNumber,
                  Filters.Route,
                  Filters.MachineType,
                  Filters.LubricantType,
                  Filters.CustomerEquipmentID,
                  Filters.MachineModel,
                  Filters.MachineManufacturer,
                  Filters.TestPackage,
                ]}
              >
                {({
                  filterState,
                  filterOptions,
                  reset,
                  filters,
                }: TSmartFilter) => (
                  <form
                    method="post"
                    noValidate
                    onSubmit={event => handleSubmit(event, filterState)}
                  >
                    <Grid
                      container
                      item
                      style={{ marginTop: '15px' }}
                      direction="column"
                      spacing={2}
                    >
                      <Grid item>
                        <FormField id="email" formErrors={state.formErrors}>
                          <TextField
                            label="Email"
                            id="email"
                            value={state.email}
                            name="email"
                            required
                            type="email"
                            onChange={handleChange}
                            onBlur={handleFormValidation}
                            style={{ width: 225 }}
                          />
                        </FormField>
                      </Grid>
                      {state.customers.length > 1 ? (
                        <Grid item>
                          <filters.SmartCustomerFilter
                            multiple={false}
                            required={true}
                          />
                        </Grid>
                      ) : null}

                      {state.plants.length > 0 ? (
                        <Grid item>
                          <FormField
                            id="plantName"
                            formErrors={state.formErrors}
                          >
                            <PlantNameFilter
                              single={true}
                              required={true}
                              plants={state.plants}
                              onChange={handlePlantChange}
                              onBlur={handleFormValidation}
                              value={state.selectedPlant}
                            />
                          </FormField>
                        </Grid>
                      ) : null}

                      {state.routes.length === 0 ? null : (
                        <Grid item>
                          <FormField
                            id="routeName"
                            formErrors={state.formErrors}
                          >
                            <RouteNameFilter
                              single={true}
                              required={false}
                              routes={state.routes}
                              onChange={handleRouteChange}
                              value={state.selectedRoute}
                            />
                          </FormField>
                        </Grid>
                      )}

                      <Grid item>
                        <FormField
                          id="machineName"
                          formErrors={state.formErrors}
                        >
                          <TextField
                            id="machineNameInput"
                            label="Machine Name"
                            required
                            name="machineName"
                            onChange={handleMachineNameChange}
                            onBlur={() =>
                              setState(prev => ({
                                ...prev,
                                machineName: machineNameRef.current,
                              }))
                            }
                            style={{ width: 225 }}
                            inputProps={{ maxLength: 50 }}
                          />
                        </FormField>
                        <FilterLabel>
                          An identifiable name associated with a machine or a
                          sample point.
                        </FilterLabel>
                      </Grid>
                      <Grid item>
                        <FormField
                          id="machineMfg"
                          formErrors={state.formErrors}
                        >
                          <MachineManufacturerFilter
                            single={true}
                            required={true}
                            machineMfgs={state.machineMfgOptions}
                            onChange={handleMachineMfgChange}
                            value={state.selectedMachineMfg}
                          />
                        </FormField>
                      </Grid>
                      <Grid item>
                        <TextField
                          label="Model"
                          id="modelNumberInput"
                          value={state.modelNumber}
                          name="modelNumber"
                          onChange={handleChange}
                          style={{ width: 225 }}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>
                      <Grid item>
                        <FormField
                          id="machineType"
                          formErrors={state.formErrors}
                        >
                          <MachineTypeFilter
                            single={true}
                            required={true}
                            machineTypes={state.machineTypeOptions}
                            onChange={handleMachineTypeChange}
                            value={state.selectedMachineType}
                          />
                        </FormField>
                      </Grid>
                      <Grid item>
                        <TextField
                          id="equipmentIdInput"
                          label="Equipment ID"
                          value={state.custEquID}
                          name="custEquID"
                          onChange={handleChange}
                          style={{ width: 225 }}
                          inputProps={{ maxLength: 50 }}
                        />
                        <FilterLabel>
                          An alternative name used to identify this equipment.
                        </FilterLabel>
                      </Grid>
                      <Grid item>
                        <FormField id="lubeType" formErrors={state.formErrors}>
                          <PairedFluidTypeManufacturerFilter
                            LubeTypeID={state.selectedLubeType}
                            onLubeTypeChange={handleLubeTypeChange}
                          />
                        </FormField>
                      </Grid>
                      <Grid item>
                        <FormField
                          id="testPackage"
                          formErrors={state.formErrors}
                        >
                          <TestPackageFilter
                            single={true}
                            required={false}
                            testGroups={state.testGroups}
                            onChange={handleTestPackageChange}
                            value={state.selectedTestGroup}
                            id={''}
                          />
                        </FormField>
                      </Grid>
                      <Grid>
                        <Box my={2}>
                          Please select the months you expect to sample
                        </Box>
                        <FormField
                          id="sampleMonths"
                          formErrors={state.formErrors}
                        >
                          <>
                            {monthAbbreviations.map((x, i) => (
                              <FormControlLabel
                                key={i}
                                style={{ width: '50px', marginRight: '25px' }}
                                disabled={state.noRegularSample}
                                control={
                                  <Checkbox
                                    checked={state.sampleMonths.includes(x)}
                                    name={x}
                                    onChange={handleSampleMonthChange}
                                  />
                                }
                                label={x}
                              />
                            ))}
                          </>
                        </FormField>
                      </Grid>
                      <FormControlLabel
                        key={12}
                        control={
                          <Checkbox
                            disabled={state.noRegularSample}
                            checked={
                              state.sampleMonths.length ===
                              monthAbbreviations.length
                            }
                            name="allMonths"
                            onChange={handleAllSampleMonthChange}
                          />
                        }
                        label="All Months"
                      />
                      <Divider />
                      <Grid>
                        <Box my={2}></Box>
                        <FormControlLabel
                          key={13}
                          control={
                            <Checkbox
                              checked={state.noRegularSample}
                              name="noRegularSample"
                              onChange={handleNoRegularSampleChange}
                            />
                          }
                          label="This equipment will not be regularly sampled"
                        />
                      </Grid>
                      <Grid>
                        <Box my={2}>
                          Please provide testing instructions and/or ISO
                          alarming parameters
                        </Box>
                        <TextField
                          multiline={true}
                          rows={5}
                          style={{ width: 255 }}
                          variant="outlined"
                          value={state.notes}
                          name="notes"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        direction="row"
                        style={{ marginTop: '15px' }}
                        spacing={2}
                      >
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            style={{ width: '100%' }}
                            color="secondary"
                            type="submit"
                          >
                            Save
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            style={{ width: '100%' }}
                            color="secondary"
                            onClick={handleCancelClick}
                          >
                            Cancel
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </WithSmartFilters>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default function Page() {
  return (
    <AppLayout tab="program-management">
      <NewEquipment />
    </AppLayout>
  )
}
