import { TAPIMachine } from 'types/api'
import getMachineManufacturerFromOptions, {
  TMachineManufacturersListItem,
} from 'utils/getMachineManufacturerFromOptions'
import getMachineTypeFromOptions, {
  TMachineTypeList,
} from 'utils/getMachineTypeFromOptions'

export const tableFormatter = (
  machines: TAPIMachine[],
  machineTypeOptions: TMachineTypeList[],
  machineMfgOptions: TMachineManufacturersListItem[],
  hasDivisions: boolean
): string[][] => {
  const headers = [
    ...(hasDivisions ? [{ id: 'divisionName', text: 'Division Name' }] : []),
    { id: 'plant.customer.custName', text: 'Customer Name' },
    { id: 'plant.plantName', text: 'Plant Name' },
    { id: 'machNo', text: 'Mach No.' },
    { id: 'machineName', text: 'Machine Name' },
    { id: 'custEquID', text: 'Equipment ID' },
    { id: 'route.routeNo', text: 'Route' },
    { id: 'lubricant.lubricantName', text: 'Lubricant', sortable: false },
    { id: 'machType', text: 'Machine Type' },
    { id: 'machMFG', text: 'Manufacturer', sortable: false },
    { id: 'modelName', text: 'Model Name', sortable: false },
    { id: 'sumpCap', text: 'Sump', sortable: false },
  ]

  const headerValues = headers.map(x => x.text)

  const formattedData = machines.map(machine => [
    ...(hasDivisions ? [machine.plant.customer.division.name] : []),
    machine.plant.customer.custName,
    machine.plant.plantName,
    machine.machNo,
    machine.machineName,
    machine.custEquID,
    machine.route !== null
      ? `${machine.route.routeNo} - ${machine.route.name}`
      : '',
    machine.lubricant !== null ? machine.lubricant?.lubricantName : '',
    getMachineTypeFromOptions({
      machineType: machine.machType,
      machineTypeOptions: machineTypeOptions,
    }),
    getMachineManufacturerFromOptions({
      machineManufacturer: machine.machMFG,
      machineManufacturereOptions: machineMfgOptions,
    }),
    machine.modelName,
    machine.sumpCap?.toString() ?? '0',
  ])

  return [headerValues, ...formattedData]
}
