import React, { useEffect, useState } from 'react'
import Popper from '@material-ui/core/Popper'
import HelpIcon from '@material-ui/icons/Help'

import * as Styles from './graphs.module.css'

type OwnProps = {
  anchorEl: HTMLDivElement
}

const HelpPopper: React.FC<OwnProps> = ({ anchorEl }: OwnProps) => {
  const [helpMenuOpen, setHelpMenuOpen] = useState(false)

  const handleHelpMenuActivate = () => {
    setHelpMenuOpen(true)
  }

  const handleHelpMenuDeactivate = () => {
    setHelpMenuOpen(false)
  }

  useEffect(() => {
    if (anchorEl) {
      anchorEl.onmouseenter = handleHelpMenuActivate
      anchorEl.onmouseleave = handleHelpMenuDeactivate
    }
  }, [anchorEl])

  return (
    <>
      <HelpIcon fontSize={'large'} />
      <Popper id={'help-popper'} open={helpMenuOpen} anchorEl={anchorEl}>
        <div id={Styles.PopperBody}>
          <p className={Styles.PopperTitle}>Features</p>
          <ul>
            <li className={Styles.li}>
              Zoom in and out of the graph using the brush, mouse wheel, or your
              device's touch-zoom feature.
              <ul style={{ marginTop: '5px', marginBottom: '10px' }}>
                <li className={Styles.li} style={{ color: 'rgb(220, 50, 50)' }}>
                  To use the brush, click and drag one of the brush's handles to
                  change the date range.
                </li>
              </ul>
            </li>

            <li className={Styles.li}>
              When zoomed-in, click and drag your mouse to traverse the graph.
              This may be done on both the graph or the brush.
            </li>

            <li className={Styles.li}>
              Hover over a point to view its value and, if applicable, its
              corrective action.
              <ul style={{ marginTop: '5px', marginBottom: '10px' }}>
                <li className={Styles.li} style={{ color: 'rgb(220, 50, 50)' }}>
                  An asterisk indicates a corrective action on sample.
                </li>
              </ul>
            </li>
            <li className={Styles.li}>
              The "Reverse Date Axis" button will change the graph's date axis
              direction, matching the DART data page table's direction.
            </li>
            <li className={Styles.li}>
              To center the chart for printing, allow the chart to expand to its
              full width by increasing the window's width.
            </li>
          </ul>
        </div>
      </Popper>
    </>
  )
}

export default HelpPopper
