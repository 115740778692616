import { Box, Grid, Typography } from '@material-ui/core'
import { Description, Person } from '@material-ui/icons'
import DashboardIcon from '@material-ui/icons/Dashboard'

import AppLayout from 'components/AppLayout'
import Loader from 'components/Loader'
import NavCard from 'components/NavCard'
import SEO from 'components/SEO'
import { useEnvironmentContext } from 'Contexts/EnvironmentContext'
import { useProfile } from 'Contexts/ProfileContext'
import { Navigate, useNavigate } from 'react-router-dom'

const pages = [
  {
    url: '/financials/dashboard',
    title: 'Dashboard',
    description: 'View and monitor trends and historical information.',
    background: '#1d2b3e',
    icon: <DashboardIcon />,
  },
  {
    url: '/financials/customer-orders',
    title: 'Customer Orders',
    description: 'View and monitor available funds.',
    background: '#c18949',
    icon: <Person />,
  },
  {
    url: '/financials/invoices',
    title: 'Invoices',
    description: 'View and pay invoices.',
    background: '#c53333',
    icon: <Description />,
  },
]

function Financials() {
  const profileContext = useProfile()

  const navigate = useNavigate()
  const isFinancialRestricted =
    profileContext.dependentData.userDetails.isFinancialRestricted

  if (isFinancialRestricted) {
    navigate('/404')
    return null
  }

  if (!profileContext.minimumProfileLoaded) {
    return <Loader />
  }

  return (
    <>
      <SEO title="Financials" />
      <Grid container direction="row" spacing={4} xs={12}>
        <Grid item md={3} xs={12}>
          <Typography variant="h1">Financials</Typography>
          <Box style={{ marginTop: '15px' }}>
            <Typography paragraph>
              View and pay invoices, monitor available funds, and view trending
              and spending history.
            </Typography>
          </Box>
        </Grid>
        <Grid container item direction="row" md={9} spacing={4} xs={12}>
          {typeof pages !== 'undefined'
            ? pages.map(page => (
                <NavCard
                  keyValue={page.url}
                  icon={page.icon}
                  title={page.title}
                  description={page.description}
                  url={page.url}
                  background={page.background}
                />
              ))
            : null}
        </Grid>
      </Grid>
    </>
  )
}

export default function FinancialsPage() {
  return (
    <AppLayout tab="financials">
      <Financials />
    </AppLayout>
  )
}
