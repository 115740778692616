import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Hidden, TablePagination } from '@material-ui/core'
import { GridColumn as Column } from '@progress/kendo-react-grid'

import {
    GridDetailRowProps,
    GridDataStateChangeEvent,
    GridExpandChangeEvent,
  } from "@progress/kendo-react-grid";

import { Product, TProductEditing, TProductPricingEditing, TShipmentInformation } from 'types/api'
import TableGrid from 'components/TableGrid'
import { DataResult, process, State } from "@progress/kendo-data-query";

import ProductPricingDetailEditingTable from '../ProductPricingDetailEditingTable';
import { ProductPricingEditContext } from 'Contexts/ProductPricingEditContext';

export type TProps = {
  products: TProductEditing[]
  offline?: boolean
}

const ProductDetailComponent = (props: GridDetailRowProps) => {
  const dataItem = props.dataItem;
  const { updateProductPricing } = React.useContext(ProductPricingEditContext);

  const handlePricingChange = (newPricings: TProductPricingEditing[]) => 
  {
    updateProductPricing(dataItem.productID, newPricings.filter(p => p.minimumQuantity > 0))
  }

  return (
    <Grid container direction="row">
      <Grid container md={4} justifyContent="center" alignItems="center" >
        <Grid item style={{border: 'solid 1px lightgrey', padding: 50, margin: 50, borderRadius: 10}}>
          <p>
            <strong>Description:</strong> {dataItem.productDescription}
          </p>
          <p>
            <strong>Quantity:</strong> {dataItem.quantity}
          </p>
        </Grid>
      </Grid>
      <Grid container direction="column"  md={8} style={{padding: 20}}>
          <ProductPricingDetailEditingTable pricings={dataItem.productPricing} onPricingChange={handlePricingChange} />
      </Grid>
    </Grid>
  );
};

const ProductPricingEditingTable: React.FC<TProps> = ({
    products,
    offline
}: TProps) => {

  const [dataState, setDataState] = React.useState<State>({
    sort: [{ field: "productName", dir: "asc" }],
    group: [{ field: "firstGroupDescription" }],
  });

  const [dataResult, setDataResult] = React.useState<DataResult>(
    process(products, dataState)
  );

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataResult(process(products, event.dataState));
    setDataState(event.dataState);
  };

  const expandChange = (event: GridExpandChangeEvent) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;

    setDataResult({ ...dataResult });
  };

  useEffect(() => {
    setDataResult(process(products, dataState));

    return () => {
    }
  }, [products])

  return (
    <>
     
        <TableGrid
         clearSortingButton={<></>}
          offline={offline}
          data={dataResult}
          style={{
            lineHeight: 1.5,
            cursor: 'pointer',
            maxHeight: '700px',
          }}
          sortable={{allowUnsort: false}}
          filterable={true}
          groupable={false}
          reorderable={true}
          pageable={false}
          {...dataState}
          onDataStateChange={dataStateChange}
          detail={ProductDetailComponent}
          expandField="expanded"
          onExpandChange={expandChange}
          rowHeight={50}
          dataItemKey={'productId'}
        >
          <Column
            field="productName"
            title="Product Name"
          />
        </TableGrid>
     
    </>
  )
}

export default ProductPricingEditingTable