import styled from '@emotion/styled'
import { Grid, TextField } from '@material-ui/core'
import { InformationIcon } from 'components/InformationIcon'
import { useUserPreferences } from 'Contexts/UserPreferencesContext'
import React, { useEffect, useState } from 'react'
import { Product, ProductPricing, TCustomerProductPricing } from 'types/api'
import { emptyPricing, freePricing } from 'utils/constants'
import {
  getApplicableProductPricing,
  getLowestNonFreePricing,
  getProductPrice,
  productHasPrice,
} from 'utils/products'
import { StringFormatter } from 'utils/string-utilities'
import { debounce } from 'utils/throttlings'
import { PricingTable } from './PricingTable'
import NewReleasesIcon from '@mui/icons-material/NewReleases'
import { useProfile } from 'Contexts/ProfileContext'
import { useProductSocket } from 'hooks/useProductSocket'
import { toast } from 'react-toastify'
import { SocketResponse } from 'types/custom'
import { numberNameMap } from 'utils/number-utilities'

const defaultImageUrl = '/static/images/eurofinsFULL.png'

export type TrackedProduct = {
  product: Product
  quantity: string
  instructions: string
  image: string
  value: string
  allowInstructions: boolean
}

type TProps = {
  disabled?: boolean
  warning?: boolean
  customerID: number
  trackedProduct: TrackedProduct
  showPricingTable: boolean
  productIsEditable: boolean
  // onProductChange: (product: Product) => void
  onProductQuantityChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    trackedProduct: TrackedProduct
  ) => void
  onProductInstructionChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    trackedProduct: TrackedProduct
  ) => void
  // onProductPricingUpdateRequest: (
  //   productPricing: TCustomerProductPricing
  // ) => Promise<void>
}

export const ProductForm = (props: TProps) => {
  const {
    trackedProduct,
    customerID,
    showPricingTable,
    onProductQuantityChange,
    onProductInstructionChange,
    // onProductPricingUpdateRequest,
    productIsEditable,
    // onProductChange,
  } = props

  const profileContext = useProfile()
  const userPreferences = useUserPreferences()
  const [product, setProduct] = useState(trackedProduct.product)
  const hasPricingOptions = productHasPrice(product)

  //added to prevent products without prices from showing. To show these as free, comment this.
  // if (!hasPricingOptions) return null

  const showDefaultPricingAlert =
    product.productPricing.some(p => p.customerID === null) &&
    profileContext.dependentData.userDetails.isInternalUser

  const hasQuantitySelected =
    trackedProduct.quantity && trackedProduct.quantity != '0'

  const quantity = hasQuantitySelected
    ? Number.parseInt(trackedProduct.quantity)
    : 0
  const productPricing = hasQuantitySelected
    ? getApplicableProductPricing(product?.productPricing, quantity) ||
      freePricing
    : null
  // const productPrice = productPricing ? productPricing.unitPrice * quantity : 0
  const productPrice = hasQuantitySelected
    ? getProductPrice(trackedProduct.product, quantity)
    : 0

  const formattedProductPrice = productPrice
    ? StringFormatter.formatAsCurrency(
        productPrice,
        productPricing?.currencyCode,
        userPreferences.languagePreference
      )
    : ''

  const handleProductQuantityChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    trackedProduct: TrackedProduct,
    slowMode?: boolean | undefined
  ) => {
    const func = () => onProductQuantityChange(event, trackedProduct)
    if (slowMode) {
      const debounceFunction = debounce(func, 500)
      debounceFunction()
    } else {
      func()
    }
  }

  const formatInput = ({ key, keyCode }) => {
    //Prevent "e", ".", "+", and "-"
    let checkIfNum
    if (!!key) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum = key === 'e' || key === '.' || key === '+' || key === '-'
    } else if (!!keyCode) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum =
        keyCode === 69 || keyCode === 190 || keyCode === 187 || keyCode === 189
    }
    return checkIfNum && event.preventDefault()
  }

  const handleProductInstructionsChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    trackedProduct: TrackedProduct,
    slowMode?: boolean | undefined
  ) => {
    const func = () => onProductInstructionChange(event, trackedProduct)
    if (slowMode) {
      const debounceFunction = debounce(func, 500)
      debounceFunction()
    } else {
      func()
    }
  }

  const handleProductPricingPosted = (
    updatedPricing: SocketResponse<TCustomerProductPricing>
  ) => {
    const matchingProduct = updatedPricing.data.products.find(
      p => p.productID === product.productID
    )
    if (!matchingProduct) return

    const newProduct: Product = {
      ...product,
      productPricing: matchingProduct.productPricing,
    }

    if (updatedPricing.isCallerResponse) {
      toast.dismiss()
      toast.success(<span>Pricing post successful.</span>, {
        containerId: 'global',
      })
    }

    setProduct(newProduct)
  }

  const handleProductPricingDeleted = (
    updatedPricing: SocketResponse<TCustomerProductPricing>
  ) => {
    const matchingProduct = updatedPricing.data.products.find(
      p => p.productID === product.productID
    )
    if (!matchingProduct) return

    if (updatedPricing.isCallerResponse) {
      toast.dismiss()
      toast.success(<span>Pricing delete successful.</span>, {
        containerId: 'global',
      })
    }

    const newProduct: Product = {
      ...product,
      productPricing: matchingProduct.productPricing,
    }
    setProduct(newProduct)
  }

  useEffect(() => {
    if (trackedProduct.product !== product) {
      trackedProduct.product = product
    }
  }, [product])

  const { requestProductPricingDelete, requestProductPricingPost } =
    useProductSocket({
      onProductPricingDeleted: handleProductPricingDeleted,
      onProductPricingPosted: handleProductPricingPosted,
    })

  const handleProductPricingPostRequested = (pricingToPost: ProductPricing) => {
    const newProduct: Product = { ...product, productPricing: [pricingToPost] }

    const updateRequest = {
      customerID: customerID,
      products: [newProduct],
    }

    return requestProductPricingPost(updateRequest)
  }

  const handleProductPricingDeleteRequested = (
    pricingToDelete: ProductPricing
  ) => {
    const newProduct: Product = {
      ...product,
      productPricing: [pricingToDelete],
    }
    const updateRequest = {
      customerID: customerID,
      products: [newProduct],
    }

    return requestProductPricingDelete(updateRequest)
  }

  const quantityNumber = Number.parseFloat(trackedProduct.quantity)
  const quantitySelected =
    !Number.isNaN(quantityNumber) && Number.isInteger(quantityNumber)
      ? quantityNumber
      : 0

  return (
    <StyledGrid
      item
      xs={12}
      md={6}
      lg={6}
      key={product.productName}
      disabled={props.disabled}
      style={{
        position: 'relative',
      }}
    >
      {!(!!props.warning || !!props.disabled) && showDefaultPricingAlert && (
        <InformationIcon
          style={{
            color: 'red',
            position: 'absolute',
            right: '0',
            translate: '-30% -30%',
          }}
          icon={NewReleasesIcon}
          placement={'bottom-end'}
        >
          <h5>Generic Pricing</h5>
          <hr />
          <p>
            Set the customer's pricing via the pricing tool available at the top
            of the page or leave it as-is to keep pricing at default levels.
            End-users will not see this alert.
          </p>
        </InformationIcon>
      )}

      {!!props.warning && (
        <InformationIcon
          style={{
            color: 'red',
            position: 'absolute',
            right: '0',
            translate: '-30% -30%',
          }}
          icon={NewReleasesIcon}
          placement={'bottom-end'}
        >
          <h5>Option Not Available</h5>
          <hr />
          <p>This option is not available to the selected customer.</p>
        </InformationIcon>
      )}

      {!!props.disabled && (
        <InformationIcon
          style={{
            color: 'red',
            position: 'absolute',
            right: '0',
            translate: '-30% -30%',
          }}
          icon={NewReleasesIcon}
          placement={'bottom-end'}
        >
          <h5>Option Not Available</h5>
          <hr />
          <p>
            Adding this product to the order forms a combination of products
            that is not available for any available customer and cannot be
            selected.
          </p>
        </InformationIcon>
      )}
      <div
        style={{
          border: trackedProduct.quantity
            ? '2px solid #FF6600'
            : '1px solid #ddd',
          height: '100%',
          padding: '15px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            gridTemplateColumns: '2fr 1fr',
            gridColumnGap: '15px',
            minHeight: '115px',
            marginBottom: '15px',
            justifyContent: 'space-between',
            whiteSpace: 'normal',
          }}
        >
          <div style={{ flex: 2 }}>
            <div
              style={{
                fontSize: '1.3em',
                marginBottom: '4px',
              }}
            >
              {product.productName}
            </div>
            <div style={{ color: '#666' }}>{product.productDescription}</div>
          </div>
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {trackedProduct.image ? (
              <img
                alt={product.productName}
                src={trackedProduct.image}
                style={{
                  maxHeight: '75px',
                  maxWidth: '120px',
                  borderRadius: '4px',
                }}
              />
            ) : (
              <CenteredCheckeredDiv>
                {/* defaultImageUrl */}
                <img
                  alt={product.productName}
                  src={defaultImageUrl}
                  style={{
                    maxWidth: '130px',
                    borderRadius: '4px',
                  }}
                />
                <LightText>Image Not Available</LightText>
              </CenteredCheckeredDiv>
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
            alignItems: 'space-between',
            height: '100%',
          }}
        >
          <Grid
            xs={12}
            container
            // spacing={4}
            style={{
              marginTop: '5px',
            }}
          >
            {!productIsEditable && (
              <Grid
                item
                container
                xs={6}
                justifyContent="space-between"
                direction="column"
              >
                <div>
                  <TextField
                    onChange={event =>
                      handleProductQuantityChange(event, trackedProduct, true)
                    }
                    onBlur={event =>
                      handleProductQuantityChange(event, trackedProduct, false)
                    }
                    onKeyDown={formatInput}
                    label="Quantity"
                    type="number"
                    size="small"
                    disabled={props.disabled || !hasPricingOptions}
                    inputProps={{
                      min: 0,
                      inputMode: 'numeric',
                      step: 1,
                      max: 2 ** 31 - 1,
                      pattern: 'd+',
                    }}
                    variant="outlined"
                    style={{
                      width: '100%',
                      margin: '5px 0 0 0',
                    }}
                    defaultValue={!quantity ? null : quantity}
                  />
                  <FirstXFree
                    quantitySelected={quantitySelected}
                    pricing={product.productPricing}
                  />
                </div>
                {formattedProductPrice && (
                  <TotalPriceContainer>
                    {formattedProductPrice}
                  </TotalPriceContainer>
                )}
              </Grid>
            )}
            {showPricingTable && !productIsEditable && (
              <Grid item container xs={1} justifyContent="center">
                <div
                  style={{
                    height: '100%',
                    width: '1px',
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  }}
                />
              </Grid>
            )}
            {showPricingTable && (
              <Grid container xs={!productIsEditable ? 5 : 12}>
                <PricingTable
                  product={product}
                  customerID={customerID}
                  isEditable={productIsEditable}
                  onProductPricingDeleteRequest={
                    handleProductPricingDeleteRequested
                  }
                  onProductPricingPostRequest={
                    handleProductPricingPostRequested
                  }
                  selected={productPricing}
                  pricing={hasPricingOptions ? product.productPricing : []}
                />
              </Grid>
            )}
            {trackedProduct.allowInstructions && !productIsEditable ? (
              <Grid
                item
                container
                xs={12}
                spacing={0}
                alignItems="flex-end"
                style={{ margin: '20px 0 0 0' }}
              >
                <TextField
                  onChange={event =>
                    handleProductInstructionsChange(event, trackedProduct, true)
                  }
                  onBlur={event =>
                    handleProductInstructionsChange(
                      event,
                      trackedProduct,
                      false
                    )
                  }
                  style={{ width: '100%' }}
                  label="Label Instructions"
                  size="small"
                  variant="outlined"
                  multiline
                />
              </Grid>
            ) : null}
          </Grid>
        </div>
      </div>
    </StyledGrid>
  )
}

type TTotalPriceContainer = {
  children: React.ReactNode
}
const TotalPriceContainer = (props: TTotalPriceContainer) => {
  return (
    <TotalPriceBox>
      <h5>Subtotal</h5>
      <span>{props.children}</span>
    </TotalPriceBox>
  )
}

type TFirstXFree = {
  pricing: ProductPricing[]
  quantitySelected: number
}
const FirstXFree = (props: TFirstXFree) => {
  const { pricing, quantitySelected } = props

  if (!quantitySelected) return null

  const validPrices = pricing.filter(p => p)
  if (!validPrices || validPrices.length === 0) return null

  const lowestPricing = getLowestNonFreePricing(pricing)
  if (!lowestPricing) return null

  const numberFree = lowestPricing.minimumQuantity - 1

  if (numberFree <= 0) return null

  const numberText =
    numberFree < 10 ? numberNameMap[numberFree] : numberFree.toString()
  let text = `The first ${numberText} ${numberFree === 1 ? 'is' : 'are'} free!`
  return <FirstXFreeContainer>{text}</FirstXFreeContainer>
}

const FirstXFreeContainer = styled.p`
  margin: 5px 0 0 2px;
  font-size: 0.8rem;
  font-style: italic;
`

const TotalPriceBox = styled('div')`
  width: 100%;
  margin: 0 0;
`

const LightText = styled('span')`
  color: 'rgba(0, 0, 0, 0.75)';
  text-align: center;
`

const CenteredCheckeredDiv = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  /* background-image: linear-gradient(45deg, #cfcfcf 25%, transparent 25%),
    linear-gradient(-45deg, #cfcfcf 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #cfcfcf 75%),
    linear-gradient(-45deg, transparent 75%, #cfcfcf 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px; */
`

const StyledGrid = styled(Grid)<{ disabled?: boolean }>`
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  :hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'inherit')};
  }
  :active {
    pointer-events: ${props => (props.disabled ? 'none' : 'all')};
  }
`
