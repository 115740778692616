import React from 'react'
import { Button } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

import coreTheme from '../theme'

const theme = createTheme(
  {
    overrides: {
      MuiButton: {
        root: {
          [coreTheme.breakpoints.down('sm')]: {
            width: '50px',
            alignSelf: 'flex-end',
          },
          [coreTheme.breakpoints.up('sm')]: {
            width: '50px',
          },
        },
        label: {
          lineHeight: '13px',
        },
      },
    },
  },
  coreTheme
)

type TProps = {
  onClick: () => void
}

export default function ReceiptButton({ onClick }: TProps) {
  return (
    <ThemeProvider theme={theme}>
      <Button onClick={onClick} variant="contained" color="primary">
        View Receipt
      </Button>
    </ThemeProvider>
  )
}
