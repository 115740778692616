import styled from '@emotion/styled'
import { useUserPreferences } from 'Contexts/UserPreferencesContext'
import React, { useEffect, useRef, useState } from 'react'
import { Product, ProductPricing, TCustomerProductPricing } from 'types/api'
import { usdRegex } from 'utils/common-regex'
import {
  removeFirstIfCurrencySymbol,
  StringFormatter,
} from 'utils/string-utilities'
import {
  CircularProgress,
  Fade,
  Popper,
  PopperPlacementType,
} from '@material-ui/core'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useProductSocket } from 'hooks/useProductSocket'

import * as Styles from './index.module.css'
import { isEnterKey } from 'utils/event-utilities'
import { disconnect } from 'process'
import { ConnectionStatus, SocketResponse } from 'types/custom.d'
import { toast } from 'react-toastify'
import { InformationIcon } from 'components/InformationIcon'

/**A pricing-based key. Product, min quantity, and pricing combined adjudicates an item's key. */
const generatePricingKey = (pricing: ProductPricing) => {
  if (!pricing) return 0
  const id = pricing.pricingID ?? 0
  const up = pricing.unitPrice ?? 0
  const mq = pricing.minimumQuantity ?? 0

  return id ** 10 + up ** 7 + mq
}

const selectedClassName = 'selected'

const disconnectedToastBody = (
  <div>
    <span>You are disconnected and cannot send requests.</span>
  </div>
)

type Props = {
  customerID: number
  product: Product
  pricing: ProductPricing[]
  price: ProductPricing
  selected: ProductPricing
  isEditable: boolean
  isEmptyPricing: boolean
  onProductPricingDeleteRequest: (
    productPricing: ProductPricing
  ) => Promise<void>
  onProductPricingPostRequest: (productPricing: ProductPricing) => Promise<void>
}

type TState = {
  unitPrice: string
  minimumQuantity: string
  quantityErrors: Error[]
  unitPriceErrors: Error[]
  submittingUpdate: boolean
  submittingPost: boolean
  submittingDelete: boolean
  deleteRequest: ProductPricing | null
  submitted: boolean
  showErrorTooltip: boolean
}
export const PricingEntry = ({
  price,
  selected,
  isEditable,
  //   submittedPricings,
  //   setSubmittedPricings,
  pricing,
  product,
  customerID,
  //   onProductPricingUpdateRequest,
  onProductPricingDeleteRequest,
  onProductPricingPostRequest,
  isEmptyPricing,
}: Props) => {
  const quantityDefaultValue = isEmptyPricing
    ? null
    : price?.minimumQuantity?.toString() ?? '1'
  const priceDefaultValue = isEmptyPricing ? null : price?.unitPrice?.toString()

  const [state, setState] = useState<TState>({
    minimumQuantity: quantityDefaultValue,
    unitPrice: priceDefaultValue,
    quantityErrors: [],
    unitPriceErrors: [],
    showErrorTooltip: false,
    submittingUpdate: false,
    submittingPost: false,
    submittingDelete: false,
    deleteRequest: null,
    submitted: false,
  })

  const submitting =
    state.submittingUpdate || state.submittingDelete || state.submittingPost

  const ref = React.useRef<HTMLTableRowElement>(null)
  const ErrorPopper = (props: React.PropsWithChildren<{}>) => {
    return (
      <>
        <Popper
          open={state.showErrorTooltip}
          anchorEl={ref.current}
          placement={'top-start'}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <div className={Styles.Popper}>{props.children}</div>
            </Fade>
          )}
        </Popper>
      </>
    )
  }

  const handleProductPricingUpdateError = () => {
    if (!state.submittingUpdate && !state.submittingPost) return

    setState(prev => ({
      ...prev,
      unitPrice: isEmptyPricing ? '' : priceDefaultValue,
      minimumQuantity: isEmptyPricing ? '' : quantityDefaultValue,
      submittingUpdate: false,
      submittingPost: false,
      submitted: false,
    }))
  }

  const handleProductPricingPostError = () => {
    //Only caller receives error responses.
    if (!state.submittingUpdate && !state.submittingPost) return

    setState(prev => ({
      ...prev,
      unitPrice: isEmptyPricing ? '' : priceDefaultValue,
      minimumQuantity: isEmptyPricing ? '' : quantityDefaultValue,
      submittingUpdate: false,
      submittingPost: false,
      submitted: false,
    }))
  }

  const handleProductPricingDeleteError = () => {
    //Only caller receives error responses.
    if (!state.submittingDelete) return

    setState(prev => ({
      ...prev,
      submittingDelete: false,
      submitted: false,
    }))
  }

  const handleProductPricingPosted = (
    updatedPricing: SocketResponse<TCustomerProductPricing>
  ) => {
    if (!isEmptyPricing) return

    const matchesThisCustomer =
      updatedPricing.data.customerID === (customerID ?? price.customerID)
    if (!matchesThisCustomer) return

    const matchingProduct = updatedPricing.data.products.find(
      p => p.productID === product.productID
    )

    if (!matchingProduct) return

    setState(prev => ({
      ...prev,
      unitPrice: '',
      minimumQuantity: '',
      submittingUpdate: false,
      submittingPost: false,
      submitted: false,
    }))
  }

  const getMatchingInformation = (updatedPricing: TCustomerProductPricing) => {
    const matchesThisCustomer =
      updatedPricing.customerID === (customerID ?? price.customerID)
    if (!matchesThisCustomer)
      return { valid: false, product: null, pricing: null }

    const matchingProduct = updatedPricing.products.find(
      p => p.productID === product.productID
    )

    if (!matchingProduct) return { valid: false, product: null, pricing: null }

    const matchingPricing = matchingProduct.productPricing.find(
      p => p.pricingID === price.pricingID
    )

    if (!matchingPricing)
      return { valid: false, product: matchingProduct, pricing: null }

    return { valid: true, product: matchingProduct, pricing: matchingPricing }
  }

  const handlePricingUpdate = (
    updatedPricing: SocketResponse<TCustomerProductPricing>
  ) => {
    const { valid, pricing: matchingPricing } = getMatchingInformation(
      updatedPricing.data
    )
    if (!valid) return

    price.minimumQuantity = matchingPricing.minimumQuantity
    price.unitPrice = matchingPricing.unitPrice

    if (updatedPricing.isCallerResponse) {
      toast.dismiss()
      toast.success(<span>Pricing update successful.</span>, {
        containerId: 'global',
      })
    }

    setState(prev => ({
      ...prev,
      minimumQuantity: matchingPricing.minimumQuantity?.toString(),
      unitPrice: matchingPricing.unitPrice?.toString(),
      submittingUpdate: false,
      submittingPost: false,
    }))
  }

  const { requestProductPricingUpdate, status } = useProductSocket({
    onProductPricingUpdate: handlePricingUpdate,
    onProductPricingPosted: handleProductPricingPosted,
    onProductDeleteError: handleProductPricingDeleteError,
    onProductUpdateError: handleProductPricingUpdateError,
    onProductPostError: handleProductPricingPostError,
  })

  const thisKey = price.pricingID ?? -1
  const isADefaultPrice =
    price.pricingID !== null &&
    price.customerID === null &&
    price.pricingID !== null
  const isModifiable = !submitting && !isADefaultPrice && isEditable
  const userPreferences = useUserPreferences()

  const minimumQuantityInput = useRef<HTMLInputElement>()
  const unitPriceInput = useRef<HTMLInputElement>()

  /**Returns validation result and sets validation state */
  useEffect(() => {
    const quantityErrors: Error[] = []
    const unitPriceErrors: Error[] = []

    const quantityValidation = validateMinimumQuantity(state.minimumQuantity)

    const priceValidation = validatePrice(state.unitPrice, quantityValidation)

    if (priceValidation instanceof Error) {
      if (unitPriceInput.current) unitPriceInput.current.style.color = 'red'
      unitPriceErrors.push(priceValidation)
    } else if (!priceValidation) {
      if (unitPriceInput.current) unitPriceInput.current.style.color = 'blue'
    } else {
      if (unitPriceInput.current) unitPriceInput.current.style.color = 'inherit'
    }

    if (quantityValidation instanceof Error) {
      if (minimumQuantityInput.current)
        minimumQuantityInput.current.style.color = 'red'
      quantityErrors.push(quantityValidation)
    } else {
      if (minimumQuantityInput.current)
        minimumQuantityInput.current.style.color = 'inherit'
    }

    setState(prev => ({
      ...prev,
      quantityErrors: quantityErrors,
      unitPriceErrors: unitPriceErrors,
    }))
  }, [state.minimumQuantity, state.unitPrice])

  useEffect(() => {
    ;(() => {
      const shouldSubmit =
        state.submitted &&
        state.quantityErrors.length === 0 &&
        state.unitPriceErrors.length === 0 &&
        state.minimumQuantity &&
        state.unitPrice

      const valuesHaveUpdated =
        Number(state.minimumQuantity) !== Number(quantityDefaultValue) ||
        Number(state.unitPrice) !== Number(priceDefaultValue)

      if (shouldSubmit && valuesHaveUpdated) {
        if (status !== ConnectionStatus.connected) {
          toast.dismiss()
          toast.error(disconnectedToastBody, { containerId: 'global' })
          setState(prev => ({
            ...prev,
            submittingUpdate: false,
            submittingPost: false,
            submitted: false,
            minimumQuantity: quantityDefaultValue,
            unitPrice: priceDefaultValue,
          }))
          return
        }

        setState(prev => ({
          ...prev,
          submittingUpdate: true,
          submittingPost: true,
          submitted: false,
        }))
      }
    })()

    setState(prev => ({ ...prev, submitted: false }))
  }, [
    state.submitted,
    state.quantityErrors.length,
    state.quantityErrors,
    state.unitPriceErrors.length,
    state.unitPriceErrors,
    status,
  ])

  useEffect(() => {
    ;(async () => {
      if (!state.submittingUpdate || status !== ConnectionStatus.connected)
        return

      const updatedPrice = {
        ...price,
        minimumQuantity: Number(state.minimumQuantity),
        unitPrice: Number(state.unitPrice),
      }

      if (state.submittingPost && isEmptyPricing) {
        onProductPricingPostRequest(updatedPrice)
        return
      }

      const newProductPricing = [updatedPrice]
      const newProduct = { ...product, productPricing: newProductPricing }

      const updateRequest = {
        customerID: customerID,
        products: [newProduct],
      }

      await requestProductPricingUpdate(updateRequest)
    })()
  }, [state.submittingUpdate])

  useEffect(() => {
    ;(async () => {
      if (
        !state.submittingDelete ||
        status !== ConnectionStatus.connected ||
        state.deleteRequest == null
      )
        return

      onProductPricingDeleteRequest(state.deleteRequest)
    })()
  }, [state.submittingDelete, state.deleteRequest])

  useEffect(() => {
    if (submitting && status !== ConnectionStatus.connected) {
      setState(prev => ({
        ...prev,
        submittingUpdate: false,
        submittingDelete: false,
        submittingPost: false,
        submitted: false,
        minimumQuantity: quantityDefaultValue,
        unitPrice: priceDefaultValue,
      }))
      toast.dismiss()
      toast.warn(
        <div>
          <h6>You've disconnected!</h6>
          <p>
            Your page data may be out of sync. To correct this, reload the page.
          </p>
        </div>,
        { containerId: 'global' }
      )
    }
  }, [status])

  const validatePrice = (
    priceStr: string | number,
    quantityValidation: boolean | Error
  ): boolean | Error => {
    if (priceStr === null || (typeof priceStr === 'string' && !priceStr))
      return false
    if (typeof priceStr === 'number') return true

    const isValidCurrencyNumber = usdRegex.test(priceStr)
    if (!isValidCurrencyNumber)
      return new Error('Unit price must be a valid currency number.')

    const numberValue = Number.parseFloat(priceStr)
    if (Number.isNaN(numberValue))
      return new Error('Unit price must be a number.')

    if (quantityValidation instanceof Error) return false
    const quantity = Number(state.minimumQuantity)

    const quantityValueIsOne = quantity === 1

    if (!quantityValueIsOne && numberValue === 0) {
      return new Error('Only a quantity of 1 may have a unit price of 0.')
    }

    if (pricing && pricing.length > 1) {
      const smallerQuantities = pricing.filter(
        v =>
          v != price &&
          v.minimumQuantity != null &&
          v.minimumQuantity < quantity &&
          v.unitPrice
      )

      const acceptable = smallerQuantities.every(p => p.unitPrice > numberValue)

      if (!acceptable)
        return new Error(
          "A minimum quantity must have a unit price of less than any other smaller minimum quantity's unit price."
        )
    }

    if (!quantityValueIsOne && pricing && pricing.length > 1) {
      const greaterQuantities = pricing.filter(
        v =>
          v != price &&
          v.minimumQuantity != null &&
          v.minimumQuantity > quantity
      )

      const acceptable = greaterQuantities.every(p => p.unitPrice < numberValue)

      if (!acceptable)
        return new Error(
          "A minimum quantity must have a unit price greater than any other greater minimum quantity's unit price."
        )
    }

    return isValidCurrencyNumber
  }

  const validateMinimumQuantity = (value: string | number): boolean | Error => {
    if (value === null || (typeof value === 'string' && !value)) return false

    const numberValue =
      typeof value === 'string' ? Number.parseFloat(value) : value
    if (Number.isNaN(numberValue))
      return new Error('Quantity must be a number.')
    if (!Number.isInteger(numberValue))
      return new Error('Quantity must be an integer.')

    if (numberValue < 1)
      return new Error('Quantity must be greater than or equal to 1.')

    const newQuantityAlreadyExistsInPricingArray = pricing
      .filter(p => p != price && p.customerID)
      .some(p => p.minimumQuantity == numberValue)
    if (newQuantityAlreadyExistsInPricingArray)
      return new Error('Quantity must be unique among pricings.')

    return true
  }

  const handlePricingDelete = (price: ProductPricing) => {
    if (status !== ConnectionStatus.connected) {
      toast.dismiss()
      toast.error(disconnectedToastBody, { containerId: 'global' })
      return
    }

    setState(prev => ({
      ...prev,
      submittingDelete: true,
      deleteRequest: price,
    }))
  }

  function getQuantityContainer(
    isEditable: boolean,
    price: ProductPricing,
    disabled: boolean
  ) {
    const placeholderValue = isEmptyPricing ? 'Quantity' : null

    if (!isEditable) return <span>{quantityDefaultValue}</span>

    return (
      <>
        <StyledInput
          ref={minimumQuantityInput}
          type={'text'}
          placeholder={placeholderValue}
          value={state.minimumQuantity}
          disabled={disabled}
          onChange={e =>
            setState(prev => ({
              ...prev,
              minimumQuantity: e.target.value,
            }))
          }
          onKeyDown={e => {
            const submit = isEnterKey(e)
            if (submit) {
              e.currentTarget.blur()
            }
          }}
          onBlur={e =>
            setState(prev => ({
              ...prev,
              minimumQuantity: e.target.value,
              submitted: true,
            }))
          }
        />
      </>
    )
  }

  function getPriceContainer(
    isEditable: boolean,
    price: ProductPricing,
    disabled: boolean
  ) {
    const isEmptyPricing = price.pricingID == null
    const placeholderValue = isEmptyPricing ? 'Price' : null

    if (!isEditable) {
      return (
        <span>
          {StringFormatter.formatAsCurrency(
            Number(state.unitPrice),
            price.currencyCode,
            userPreferences.languagePreference
          )}
        </span>
      )
    } else {
      return (
        <>
          <StyledInput
            ref={unitPriceInput}
            type={'text'}
            placeholder={placeholderValue}
            value={state.unitPrice}
            disabled={disabled}
            onKeyDown={e => {
              const submit = isEnterKey(e)
              if (submit) {
                e.currentTarget.blur()
              }
            }}
            onChange={e =>
              setState(prev => ({
                ...prev,
                unitPrice: e.target.value,
              }))
            }
            onBlur={e =>
              setState(prev => ({
                ...prev,
                unitPrice: e.target.value,
                submitted: true,
              }))
            }
          />
        </>
      )
    }
  }

  const showErrorPopper =
    state.quantityErrors.length > 0 || state.unitPriceErrors.length > 0

  const thisPricingSelected =
    price == selected ||
    (!!selected && price.minimumQuantity === 1 && price.unitPrice === 0)
  return (
    <>
      {showErrorPopper && (
        <ErrorPopper>
          <div>
            <h3>Errors</h3>
            {state.quantityErrors.map((err, i) => (
              <div style={{ display: 'flex', width: '100%' }}>
                <p
                  style={{
                    display: 'inline-block',
                    flex: 8,
                  }}
                >
                  {i + 1}.
                </p>
                <p style={{ flex: 92 }}>{err.message}</p>
              </div>
            ))}
            {state.unitPriceErrors.map((err, i) => (
              <div style={{ display: 'flex', width: '100%' }}>
                <p
                  style={{
                    display: 'inline-block',
                    flex: 8,
                  }}
                >
                  {i + state.quantityErrors.length + 1}.
                </p>
                <p style={{ flex: 92 }}>{err.message}</p>
              </div>
            ))}
          </div>
        </ErrorPopper>
      )}
      <tr
        ref={ref}
        className={thisPricingSelected ? selectedClassName : undefined}
        onMouseEnter={() =>
          setState(prev => ({ ...prev, showErrorTooltip: true }))
        }
        onMouseLeave={() =>
          setState(prev => ({ ...prev, showErrorTooltip: false }))
        }
        key={thisKey} //Unit price ^ 7 makes 1/1000 of a cent not cover the likely range of minimum quantities
      >
        <TDef>
          {getQuantityContainer(isEditable, price, !isModifiable)}
          {!isEditable && pricing.filter(p => p.pricingID).at(-1) === price && (
            <EmphasizedPlus>+</EmphasizedPlus>
          )}
        </TDef>
        <TDef style={{ width: 1, overflow: 'visible' }}>
          {getPriceContainer(isEditable, price, !isModifiable)}
        </TDef>
        {!submitting && isModifiable && price.pricingID ? (
          <ThinCenteredTableDef>
            <StyledDeleteIcon
              style={{
                color:
                  status === ConnectionStatus.connected ? undefined : 'grey',
              }}
              onClick={() => {
                handlePricingDelete(price)
              }}
            />
          </ThinCenteredTableDef>
        ) : null}
        {submitting && isEditable ? (
          <ThinCenteredTableDef>
            <CenteredSpan>
              <CircularProgress
                style={{
                  fontSize: '10px',
                  width: '10px',
                  height: '10px',
                }}
                color="inherit"
                variant="indeterminate"
              />
            </CenteredSpan>
          </ThinCenteredTableDef>
        ) : isADefaultPrice && isEditable ? (
          <ThinCenteredTableDef>
            <InformationIcon style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
              <h5>Default Pricing</h5>
              <span>
                This entry is a default pricing entry and cannot be edited. To
                overwrite this value, simply submit a new entry on the line
                below.
              </span>
            </InformationIcon>
          </ThinCenteredTableDef>
        ) : !price.pricingID ? (
          <ThinCenteredTableDef />
        ) : null}
      </tr>
    </>
  )
}

const StyledDeleteIcon = styled(HighlightOffIcon)`
  font-size: 20px;
  cursor: pointer;
  color: rgb(205, 25, 25);
`

const EmphasizedPlus = styled.span`
  font-weight: 100;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.5rem;
  display: inline-flex;
  align-items: center;
  position: absolute;
  height: 100%;
  margin-left: 2px;
`

const CenteredSpan = styled.span`
  text-align: center;
`

const TDef = styled.td`
  flex: 100;
  position: relative;
`

const ThinCenteredTableDef = styled.td`
  flex: 15;
  position: relative;
  text-align: center;
`

const StyledInput = styled.input`
  outline: none;
  border: none;
  width: 65%;
  :focus {
    border-bottom: thin solid rgba(0, 0, 0, 0.5);
  }
`
