import { Button, Input } from '@material-ui/core'
import TextField from '@mui/material/TextField'
import Loader from 'components/Loader'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileSingleSelect } from 'components/MobileSingleSelect/MobileSingleSelect'
import {
  ProgressStepper,
  TProgressMarker,
} from 'components/ProgressStepper/ProgressStepper'
import {
  useError,
  useLoading,
  usePromise,
  UsePromiseState,
} from 'hooks/usePromise'
import { useEffect, useMemo, useRef, useState } from 'react'
import APIService from 'services/api-service'
import {
  CustomerResponseDTO,
  GetMachinesResponse,
  GetPlantsResponse,
  GetPlantsResponseDTO,
  MachinesResponseDTO,
  PostPlantsResponseDTO,
  TAPIMachine,
  TAPIPlant,
  WarhammerSamplePutDTO,
} from 'types/api'
import { BottleDataLoadingError } from '../../Cards/BottleDataLoadingError'
import { PageCard } from '../PageContainers'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'

import * as Styles from './index.module.css'
import * as PageStyles from '../../page-styles.module.css'
import { BooleanFormValue } from 'utils/FormUtilities/FormValue'
import { stringIsNullOrWhitespace } from 'utils/string-utilities'
import { FancyTextArea } from 'components/FancyTextArea/FancyTextArea'
import { FetchError } from 'utils/Errors/FetchError'
import { CancellablePromise } from 'utils/CancellablePromise'
import { useIsBoundByWidths } from 'hooks/useIsBoundByWidths'
import { mobileWidthDefinitonMap } from '../constants'
import { Autocomplete } from '@mui/material'
// import { NotFoundCardModule } from '../../CardModules/NotFoundCardModule'
import { ElementDisplay } from './ElementDisplay'
import { DistinctSort } from 'utils/ArrayMethods'
import { useBottleRegistrationContext } from '../../registrationContext'
import { PlantRegistration } from '../../Cards/BottleInformationEntry/CreatePlant/PlantRegistration'
import { NotFoundIconBox } from '../../CardModules/NotFoundIconBox'
import { useIsLoggedIn } from 'hooks/useIsLoggedIn'
import { TUseCustomerInformation } from '../../Cards/BottleInformationEntry/CreatePlant/types'
import { CancelButton } from '../../CardModules/CancelButton/CancelButton'
import { MachineRegistration } from '../../Cards/BottleInformationEntry/CreateMachine/MachineRegistration'
import { PowerInputOutlined } from '@material-ui/icons'
import {
  TWarhammerSampleWithoutIdentifier,
  PageState,
  InformationPageState,
} from '../../types-and-enums'

type TProps = {
  customerID: number
  plantID?: number
  sampleDetails: TWarhammerSampleWithoutIdentifier | null
  setPageState: React.Dispatch<React.SetStateAction<PageState>>
  onSubmit: (data: Omit<WarhammerSamplePutDTO, 'bottleIdentifier'>) => void
  encryptedBottleIdentifier: string
  userProvidedBottleIdentifier: string
  clearUserBottleIDInput: () => void
}

type TFixedState = {
  page: InformationPageState
  newPlantCount: number
  newMachineCount: number
}

type TFreeFormState = {
  sampledAt: BooleanFormValue<Date>
  oilChangedAt: BooleanFormValue<Date>
  machineHours: BooleanFormValue<string>
  lubeHours: BooleanFormValue<string>
  comments: BooleanFormValue<string>
}

const intMax = 2 ** 31 - 1
const validateMachineHours = (hours: string, compareTo: string) => {
  if (stringIsNullOrWhitespace(hours)) return true
  const hoursNumber = Number.parseInt(hours)
  if (isNaN(hoursNumber)) {
    return false
  }

  if (hoursNumber > intMax) return false

  if (compareTo) {
    const compareToNumber = Number.parseInt(compareTo)
    if (isNaN(compareToNumber)) {
      return true
    }

    if (hoursNumber < compareToNumber) return false
  }

  return true
}
const validateLubricantHours = (hours: string, compareTo: string) => {
  if (stringIsNullOrWhitespace(hours)) return true
  const hoursNumber = Number.parseInt(hours)
  if (isNaN(hoursNumber)) {
    return false
  }

  if (hoursNumber > intMax) {
    return false
  }

  if (compareTo) {
    const compareToNumber = Number.parseInt(compareTo)
    if (isNaN(compareToNumber)) {
      return true
    }

    if (hoursNumber > compareToNumber) {
      return false
    }
  }
  return true
}

const minDate = new Date(0)
const maxDate = new Date()
const maxTextLength = 4000
const defaultFormState: Readonly<TFreeFormState> = {
  sampledAt: new BooleanFormValue(
    maxDate,
    date => date <= maxDate,
    'Sampled date must be before or on the present date.'
  ),
  oilChangedAt: new BooleanFormValue(
    null,
    date => !date || date <= maxDate,
    'Oil changed date must be before or on the present date.'
  ),
  comments: new BooleanFormValue(
    '',
    value => !value || (value && value.length < maxTextLength),
    `Comments must be 4,000 characters or less.`
  ),
  lubeHours: new BooleanFormValue(
    '',
    validateLubricantHours,
    `Lubricant hours must be a number, less than or equal to machine hours, and less than ${intMax}.`
  ),
  machineHours: new BooleanFormValue(
    '',
    validateMachineHours,
    `Machine hours must be a number, greater than or equal to lubricant hours, and less than ${intMax}.`
  ),
}

const defaultPageState: Readonly<TFixedState> = {
  page: InformationPageState.PlantSelect,
  newPlantCount: 0,
  newMachineCount: 0,
}

export const BottleDataInformationEntryScreen = (props: TProps) => {
  const [state, setState] = useState<TFixedState & TFreeFormState>({
    ...defaultPageState,
    ...defaultFormState,
  })

  const registrationContext = useBottleRegistrationContext()

  function updateFixedState<
    TKey extends keyof TFixedState,
    TValue extends TFixedState[TKey]
  >(key: TKey, value: TValue) {
    setState(prev => ({ ...prev, [key]: value }))
  }

  function updateFreeFormState<
    TKey extends keyof TFreeFormState,
    TValue extends TFreeFormState[TKey]['Value']
  >(key: TKey, value: TValue) {
    const stateValue = state[key]
    stateValue.Value = value

    setState(prev => ({ ...prev, [key]: stateValue }))
  }

  const isMobile = useIsBoundByWidths(mobileWidthDefinitonMap)['mobile']

  const moveToPage = (page: InformationPageState) => {
    setState(prev => ({ ...prev, page }))
  }

  const validateSubmitData = () => {
    for (let key in state) {
      const value = state[key]

      if (
        key == 'machineHours' &&
        !state.machineHours.isValid(state.lubeHours.Value)
      ) {
        return false
      } else if (
        key == 'lubeHours' &&
        !state.lubeHours.isValid(state.machineHours.Value)
      ) {
        return false
      }

      if (value instanceof BooleanFormValue) {
        if (!value.isValid()) {
          return false
        }
      }
    }

    return true
  }

  const allDataIsDefaultValue = () => {
    if (!props.sampleDetails) return false

    for (let key in state) {
      if (!props.sampleDetails[key]) return false

      switch (key) {
        case 'pointID': {
          let value = state[key]

          const defaultValue = props.sampleDetails[key]
          if (defaultValue !== value) return false
          break
        }
        case 'plantID': {
          let value = state[key]
          const defaultValue = props.sampleDetails[key]
          if (defaultValue !== value) return false
          break
        }
        case 'lubeHours': {
          let value = state[key]
          const defaultValue = props.sampleDetails[key]
          if (defaultValue?.toString() !== value.Value) return false
          break
        }
        case 'machineHours': {
          let value = state[key]
          const defaultValue = props.sampleDetails[key]
          if (defaultValue?.toString() !== value.Value) return false
          break
        }
        case 'sampledAt': {
          let value = state[key]
          const ticks = Date.parse(props.sampleDetails[key])
          const defaultValue = new Date(ticks)
          if (defaultValue !== value.Value) return false
          break
        }
        case 'oilChangedAt': {
          let value = state[key]
          const ticks = props.sampleDetails[key]
            ? Date.parse(props.sampleDetails[key])
            : 0
          const defaultValue = new Date(ticks)
          if (defaultValue !== value.Value) return false
          break
        }
        case 'comments': {
          let value = state[key]
          const defaultValue = props.sampleDetails[key]
          if (defaultValue !== value.Value) return false
          break
        }
      }
    }

    return true
  }

  const submit = () => {
    if (allDataIsDefaultValue()) {
      props.setPageState(PageState.Linked)
      return
    }

    const submitDataIsValid = validateSubmitData()
    if (!submitDataIsValid) return
    updateFixedState('page', InformationPageState.Complete)
    try {
      props.onSubmit({
        pointID: registrationContext.state.pointID,
        comments: state.comments.Value,
        lubeHours: Number.parseInt(state.lubeHours.Value),
        machineHours: Number.parseInt(state.machineHours.Value),
        oilChangedAt: state.oilChangedAt.Value?.toISOString(),
        sampledAt: state.sampledAt.Value?.toISOString(),
      })
    } catch (err) {
      updateFixedState('page', InformationPageState.InformationEntry)
    }
  }

  const plantQueryState = useCustomerPlants(
    state.page,
    props.customerID,
    props.encryptedBottleIdentifier,
    state.newPlantCount
  )
  const machineQueryState = usePlantMachines(
    state.page,
    props.customerID,
    registrationContext.state.plantID,
    state.newMachineCount,
    props.encryptedBottleIdentifier
  )

  const customerDataRequest = useCustomerInformation({
    customerID: props.customerID,
    encryptedBottleIdentifier: props.encryptedBottleIdentifier,
  })

  const queryStates = [plantQueryState, machineQueryState, customerDataRequest]
  const loadingState = useLoading(queryStates)
  const errorState = useError(queryStates)
  const inErrorState =
    (errorState && !(errorState instanceof FetchError)) ||
    (errorState instanceof FetchError && errorState.status !== 404)
  //only for non-404s

  useEffect(() => {
    ;(() => {
      if (props.plantID) {
        registrationContext.updateFixedState('plantID', props.plantID)
      }

      if (!props.sampleDetails) return
      for (let key in props.sampleDetails) {
        switch (key) {
          case 'pointID': {
            registrationContext.updateFixedState(
              'pointID',
              props.sampleDetails[key]
            )
            break
          }
          case 'plantID': {
            if (!props.plantID) {
              registrationContext.updateFixedState(
                'plantID',
                props.sampleDetails[key]
              )
            }

            break
          }
          case 'lubeHours': {
            updateFreeFormState(
              key,
              (props.sampleDetails[key] ?? '').toString()
            )
            break
          }
          case 'machineHours': {
            updateFreeFormState(
              key,
              (props.sampleDetails[key] ?? '').toString()
            )
            break
          }
          case 'sampledAt': {
            const ticks = Date.parse(props.sampleDetails[key])
            updateFreeFormState(key, new Date(ticks))
            break
          }
          case 'oilChangedAt': {
            const ticks = props.sampleDetails[key]
              ? Date.parse(props.sampleDetails[key])
              : null
            updateFreeFormState(
              key,
              props.sampleDetails[key] ? new Date(ticks) : null
            )
            break
          }
          case 'comments': {
            updateFreeFormState(key, props.sampleDetails[key])
            break
          }
        }
      }
    })()
  }, [])

  function handleNewPlantIDSubmit(plantID: number) {
    if (!!plantID) {
      registrationContext.updateFixedState('plantID', plantID)

      setState(prev => ({
        ...prev,
        page: InformationPageState.PlantSelect,
        newPlantCount: prev.newPlantCount + 1,
      }))
    }
  }

  function handleNewPointIDSubmit(pointID: number) {
    if (!!pointID) {
      registrationContext.updateFixedState('pointID', pointID)

      setState(prev => ({
        ...prev,
        page: InformationPageState.MachineSelect,
        newMachineCount: prev.newMachineCount + 1,
      }))
    }
  }

  const stepperMarkers: TProgressMarker[] = [
    {
      body: '1',
      label: 'Select Plant',
      loading: plantQueryState.loading,
    },
    {
      body: '2',
      label: 'Select Machine',
      loading: machineQueryState.loading,
    },
    {
      body: '3',
      label: 'Add Details',
      loading: false,
    },
  ]

  function handleCancel() {
    registrationContext.clear()

    if (!!props.userProvidedBottleIdentifier) {
      props.clearUserBottleIDInput()
      props.setPageState(PageState.BottleIdentifierEntry)
    } else {
      props.setPageState(PageState.Linked)
    }
  }

  const plantButtons = (
    <div className={PageStyles.ButtonContainer}>
      {!!props.sampleDetails && (
        <Button
          data-cancel
          variant="contained"
          color="primary"
          onClick={handleCancel}
          className={`${PageStyles.Button} ${PageStyles.Left}`}
        >
          Cancel
        </Button>
      )}
      <Button
        data-accept
        variant="contained"
        color="secondary"
        disabled={
          registrationContext.state.plantID == null ||
          !plantQueryState.data?.find(
            p => p.plantID === registrationContext.state.plantID
          )
        }
        onClick={() => moveToPage(InformationPageState.MachineSelect)}
        className={`${PageStyles.Button} ${PageStyles.Right}`}
      >
        Select Machine
      </Button>
    </div>
  )

  const plantStringifier = (p: GetPlantsResponseDTO) => p.plantName
  const plantElementificator = (p: GetPlantsResponseDTO) => (
    <ElementDisplay>{plantStringifier(p)}</ElementDisplay>
  )
  const plantPage = (
    <>
      <section>
        <ul
          className={`${PageStyles.Details} ${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer} ${PageStyles.Wrapper}`}
        >
          <li className={`${PageStyles.Wrappable}`}>
            <span className={PageStyles.PropertyLabel}>Customer</span>
            <span className={PageStyles.Property}>
              {customerDataRequest.data?.customerID}
              {' - '}
              {customerDataRequest.data?.customerName}
            </span>
          </li>
        </ul>
      </section>
      <section
        className={`${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer}`}
      >
        <div
          className={`${PageStyles.SpacedColumnContainer} ${
            state.page === InformationPageState.PlantRegistration
              ? PageStyles.DisabledWindow
              : ''
          }`}
        >
          {isMobile ? (
            <MobileSingleSelect
              getKey={plant => (plant.plantNumber ?? 0).toString()}
              options={DistinctSort(
                plantQueryState.data ?? [],
                (a, b) => a.plantName.localeCompare(b.plantName),
                (a, b) =>
                  a.plantName.toLowerCase() === b.plantName.toLowerCase()
              )}
              label="Plant"
              placeholder="Plant"
              stringify={plantStringifier}
              elementificate={plantElementificator}
              onChange={plant =>
                plant &&
                registrationContext.updateFixedState('plantID', plant?.plantID)
              }
              disabled={
                !!plantQueryState.error ||
                !plantQueryState.data ||
                plantQueryState.data.length === 0
              }
              default={
                plantQueryState.data?.find(
                  p => p.plantID === registrationContext.state.plantID
                ) ?? null
              }
            />
          ) : (
            <Autocomplete
              options={DistinctSort(
                plantQueryState.data ?? [],
                (a, b) => a.plantName.localeCompare(b.plantName),
                (a, b) =>
                  a.plantName.toLowerCase() === b.plantName.toLowerCase()
              )}
              value={plantQueryState.data?.find(
                p => p.plantID === registrationContext.state.plantID
              )}
              renderInput={params => (
                <TextField color="primary" {...params} label="Plants" />
              )}
              getOptionLabel={(p: GetPlantsResponseDTO) => `${p.plantName}`}
              onChange={(e, p: GetPlantsResponseDTO) => {
                if (p)
                  registrationContext.updateFixedState('plantID', p.plantID)
              }}
              disabled={
                !!plantQueryState.error ||
                !plantQueryState.data ||
                plantQueryState.data.length === 0
              }
            />
          )}
          <Button
            data-cancel
            variant="contained"
            color="primary"
            onClick={() => moveToPage(InformationPageState.PlantRegistration)}
            className={`${PageStyles.Button}`}
            fullWidth
          >
            Register New Plant
          </Button>
        </div>
      </section>
    </>
  )

  const machineButtons = (
    <div className={PageStyles.ButtonContainer}>
      <Button
        data-cancel
        variant="contained"
        color="primary"
        onClick={() => moveToPage(InformationPageState.PlantSelect)}
        className={`${PageStyles.Button} ${PageStyles.Left}`}
      >
        Select Plant
      </Button>

      <Button
        data-accept
        disabled={
          registrationContext.state.plantID == null ||
          registrationContext.state.pointID == null ||
          !machineQueryState.data?.find(
            m => m.pointID === registrationContext.state.pointID
          )
        }
        variant="contained"
        color="secondary"
        onClick={() => moveToPage(InformationPageState.InformationEntry)}
        className={`${PageStyles.Button} ${PageStyles.Right}`}
      >
        Enter Details
      </Button>
    </div>
  )

  const machStringifier = (m: MachinesResponseDTO) => m.machineName
  const machElementificator = (m: MachinesResponseDTO) => (
    <ElementDisplay>{machStringifier(m)}</ElementDisplay>
  )

  const selectedPlant = useMemo(
    () =>
      plantQueryState.data?.find(
        p => p.plantID === registrationContext.state.plantID
      ),
    [registrationContext.state.plantID, plantQueryState.data]
  )
  const machinePage = (
    <>
      <section>
        <ul
          className={`${PageStyles.Details} ${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer} ${PageStyles.Wrapper}`}
        >
          <li className={`${PageStyles.Wrappable}`} style={{ marginBottom: 0 }}>
            <span className={PageStyles.PropertyLabel}>Customer</span>
            <span className={PageStyles.Property}>
              {customerDataRequest.data?.customerID}
              {' - '}
              {customerDataRequest.data?.customerName}
            </span>
          </li>
          <li className={`${PageStyles.Wrappable}`}>
            <span className={PageStyles.PropertyLabel}>Plant</span>

            <span className={PageStyles.Property}>
              {selectedPlant?.plantNumber}
              {' - '}
              {selectedPlant?.plantName}
            </span>
          </li>
        </ul>
      </section>
      <section
        className={`${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer}`}
      >
        {isMobile ? (
          <MobileSingleSelect
            getKey={mach => (mach.pointID ?? 0).toString()}
            options={DistinctSort(
              machineQueryState.data ?? [],
              (a, b) => a.machineName.localeCompare(b.machineName),
              (a, b) =>
                a.machineName.toLowerCase() === b.machineName.toLowerCase()
            )}
            value={
              machineQueryState.data?.find(
                m => m.pointID === registrationContext.state.pointID
              ) ?? null
            }
            label="Machine"
            placeholder="Machine"
            stringify={machStringifier}
            elementificate={machElementificator}
            onChange={machine =>
              machine?.pointID &&
              registrationContext.updateFixedState('pointID', machine?.pointID)
            }
            disabled={
              !!machineQueryState.error ||
              !machineQueryState.data ||
              machineQueryState.data.length === 0
            }
            default={
              machineQueryState.data?.find(
                m => m.pointID === registrationContext.state.pointID
              ) ?? null
            }
          />
        ) : (
          <Autocomplete
            options={DistinctSort(
              machineQueryState.data ?? [],
              (a, b) => a.machineName.localeCompare(b.machineName),
              (a, b) =>
                a.machineName.toLowerCase() === b.machineName.toLowerCase()
            )}
            disabled={
              !!machineQueryState.error ||
              !machineQueryState.data ||
              machineQueryState.data.length === 0
            }
            renderInput={params => (
              <TextField color="primary" {...params} label="Machines" />
            )}
            getOptionLabel={(m: MachinesResponseDTO) => `${m.machineName}`}
            value={
              machineQueryState.data?.find(
                m => m.pointID === registrationContext.state.pointID
              ) ?? null
            }
            onChange={(e, m: MachinesResponseDTO) => {
              registrationContext.updateFixedState(
                'pointID',
                m?.pointID ?? null
              )
            }}
          />
        )}
        <div className={PageStyles.ButtonContainer}>
          <Button
            data-cancel
            disabled={
              registrationContext.state.plantID == null ||
              registrationContext.state.pointID == null ||
              !machineQueryState.data?.find(
                m => m.pointID === registrationContext.state.pointID
              )
            }
            variant="contained"
            color="primary"
            onClick={() => moveToPage(InformationPageState.MachineEdit)}
            className={`${PageStyles.Button} ${PageStyles.Left}`}
          >
            Modify Machine
          </Button>

          <Button
            data-accept
            disabled={registrationContext.state.plantID == null}
            variant="contained"
            color="secondary"
            onClick={() => {
              moveToPage(InformationPageState.MachineRegistration)
            }}
            className={`${PageStyles.Button} ${PageStyles.Right}`}
          >
            Register New
          </Button>
        </div>
      </section>
    </>
  )

  const detailButtons = (
    <div className={PageStyles.ButtonContainer}>
      <Button
        data-cancel
        variant="contained"
        color="primary"
        onClick={() => moveToPage(InformationPageState.MachineSelect)}
        className={`${PageStyles.Button} ${PageStyles.Left}`}
      >
        Select Machine
      </Button>

      <Button
        data-accept
        disabled={
          registrationContext.state.plantID == null ||
          registrationContext.state.pointID == null
        }
        variant="contained"
        color="secondary"
        onClick={submit}
        className={`${PageStyles.Button} ${PageStyles.Right}`}
      >
        Submit
      </Button>
    </div>
  )

  const selectedMachine = useMemo(
    () =>
      machineQueryState.data?.find(
        m => m.pointID === registrationContext.state.pointID
      ) ?? null,
    [registrationContext.state.pointID, machineQueryState.loading]
  )

  const detailEntryPage = (
    <>
      <section>
        <ul
          className={`${PageStyles.Details} ${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer} ${PageStyles.Wrapper}`}
        >
          <li className={`${PageStyles.Wrappable}`} style={{ marginBottom: 0 }}>
            <span className={PageStyles.PropertyLabel}>Customer</span>
            <span className={PageStyles.Property}>
              {customerDataRequest.data?.customerID}
              {' - '}
              {customerDataRequest.data?.customerName}
            </span>
          </li>
          <li className={`${PageStyles.Wrappable}`}>
            <span className={PageStyles.PropertyLabel}>Plant</span>

            <span className={PageStyles.Property}>
              {selectedPlant?.plantNumber}
              {' - '}
              {selectedPlant?.plantName}
            </span>
          </li>
          <li className={`${PageStyles.Wrappable}`}>
            <span className={PageStyles.PropertyLabel}>Machine</span>

            <span className={PageStyles.Property}>
              {selectedMachine?.machineName} (
              {selectedMachine?.customerEquipmentID})
            </span>
          </li>
        </ul>
      </section>
      <section>
        <div
          className={`${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer} ${PageStyles.Wrapper}`}
        >
          {/*@ts-ignore */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TextField
              error={!state.lubeHours.isValid(state.machineHours.Value)}
              helperText={
                !state.lubeHours.isValid(state.machineHours.Value) &&
                state.lubeHours.getValidationMessage(state.machineHours.Value)
              }
              id="outlined-basic"
              label="Lubricant Hours"
              variant="outlined"
              color="primary"
              value={state.lubeHours.Value || ''}
              onChange={event =>
                updateFreeFormState('lubeHours', event.target.value)
              }
              className={`${PageStyles.Wrappable}`}
            />

            <TextField
              error={!state.machineHours.isValid(state.lubeHours.Value)}
              helperText={
                !state.machineHours.isValid(state.lubeHours.Value) &&
                state.machineHours.getValidationMessage(state.lubeHours.Value)
              }
              id="outlined-basic"
              label="Machine Hours"
              variant="outlined"
              color="primary"
              value={state.machineHours.Value || ''}
              onChange={event =>
                updateFreeFormState('machineHours', event.target.value)
              }
              className={`${PageStyles.Wrappable}`}
            />

            {isMobile ? (
              <MobileDatePicker
                label="Oil Changed Date"
                inputFormat="MM/DD/YYYY"
                InputProps={{ color: 'primary' }}
                value={state.oilChangedAt.Value}
                maxDate={maxDate}
                minDate={minDate}
                onChange={date => updateFreeFormState('oilChangedAt', date)}
                renderInput={params => (
                  <TextField
                    error={!state.oilChangedAt.isValid()}
                    color="primary"
                    {...params}
                  />
                )}
                className={`${PageStyles.Wrappable}`}
              />
            ) : (
              <DesktopDatePicker
                label="Oil Changed Date"
                inputFormat="MM/DD/YYYY"
                value={state.oilChangedAt.Value}
                maxDate={maxDate}
                minDate={minDate}
                onChange={date => updateFreeFormState('oilChangedAt', date)}
                renderInput={params => (
                  <TextField
                    error={!state.oilChangedAt.isValid()}
                    color="primary"
                    {...params}
                  />
                )}
                className={`${PageStyles.Wrappable}`}
              />
            )}

            {isMobile ? (
              <MobileDatePicker
                label="Sample Date"
                inputFormat="MM/DD/YYYY"
                InputProps={{ color: 'primary' }}
                value={state.sampledAt.Value}
                maxDate={maxDate}
                minDate={minDate}
                onChange={date => updateFreeFormState('sampledAt', date)}
                renderInput={params => (
                  <TextField
                    {...params}
                    error={!state.sampledAt.isValid()}
                    className={`${PageStyles.Wrappable}`}
                  />
                )}
              />
            ) : (
              <DesktopDatePicker
                label="Sample Date"
                inputFormat="MM/DD/YYYY"
                InputProps={{ color: 'primary' }}
                value={state.sampledAt.Value}
                maxDate={maxDate}
                minDate={minDate}
                onChange={date => updateFreeFormState('sampledAt', date)}
                renderInput={params => (
                  <TextField error={!state.sampledAt.isValid()} {...params} />
                )}
                className={`${PageStyles.Wrappable}`}
              />
            )}

            <FancyTextArea
              borderColor="#007bff"
              labelColor="#007bff"
              label={'Comments'}
              value={state.comments.Value}
              onChange={event =>
                updateFreeFormState('comments', event.target.value)
              }
            />
          </LocalizationProvider>
        </div>
      </section>
    </>
  )

  const isLoggedIn = useIsLoggedIn()

  const step = useMemo(() => {
    switch (state.page) {
      case InformationPageState.PlantRegistration:
        return 1
      case InformationPageState.MachineRegistration:
        return 1
      case InformationPageState.MachineEdit:
        return 1
      case InformationPageState.PlantSelect:
        return 1
      case InformationPageState.MachineSelect:
        return 2
      case InformationPageState.InformationEntry:
        return 3
      case InformationPageState.Complete:
        return 4
    }
  }, [state.page])
  const progressStepper = (
    <>
      <ProgressStepper step={step} markers={stepperMarkers} />
    </>
  )

  let page: JSX.Element = null
  let buttons: JSX.Element = null
  if (loadingState || state.page === InformationPageState.Complete) {
    page = (
      <div>
        <Loader />
      </div>
    )
  } else if (
    state.page === InformationPageState.PlantSelect &&
    plantQueryState.error &&
    plantQueryState.error.status === 404
  ) {
    return (
      <PageCard>
        <div
          className={`${PageStyles.EntryScreen} ${
            isLoggedIn.loggedIn ? PageStyles.LoggedIn : ''
          }`}
        >
          <div>
            <h4 className={PageStyles.MainTitle}>Register Your Sample</h4>
            <div className={PageStyles.ProgressStepperBox}>
              {progressStepper}
            </div>
            {plantPage}
            <NotFoundIconBox>
              <sub className={PageStyles.SubTitle}>
                No plants could be found for the assigned customer.
              </sub>
            </NotFoundIconBox>
          </div>
          {plantButtons}
        </div>
      </PageCard>
    )
  } else if (
    state.page === InformationPageState.MachineSelect &&
    machineQueryState.error &&
    machineQueryState.error.status === 404
  ) {
    return (
      <PageCard>
        <div
          className={`${PageStyles.EntryScreen} ${
            isLoggedIn.loggedIn ? PageStyles.LoggedIn : ''
          }`}
        >
          <div>
            <h4 className={PageStyles.MainTitle}>Register Your Sample</h4>
            <div className={PageStyles.ProgressStepperBox}>
              {progressStepper}
            </div>
            {machinePage}
            <NotFoundIconBox>
              <sub className={PageStyles.SubTitle}>
                No machines could be found for the selected plant!
              </sub>
            </NotFoundIconBox>
          </div>
          {machineButtons}
        </div>
      </PageCard>
    )
  } else if (inErrorState) {
    return <BottleDataLoadingError />
  } else {
    // page = getPageFormBody(state.page, loadingState, inErrorState)
    switch (state.page) {
      case InformationPageState.PlantRegistration: {
        return (
          <PlantRegistration
            encryptedBottleIdentifier={props.encryptedBottleIdentifier}
            existingPlants={plantQueryState.data}
            customerData={customerDataRequest.data}
            cancel={() => moveToPage(InformationPageState.PlantSelect)}
            submit={plantID => handleNewPlantIDSubmit(plantID)}
          />
        )
      }
      case InformationPageState.MachineRegistration: {
        return (
          <MachineRegistration
            encryptedBottleIdentifier={props.encryptedBottleIdentifier}
            existingMachines={machineQueryState.data}
            plantData={plantQueryState.data.find(
              p => p.plantID === registrationContext.state.plantID
            )}
            customerData={customerDataRequest.data}
            cancel={() => {
              moveToPage(InformationPageState.MachineSelect)
            }}
            submit={pointID => handleNewPointIDSubmit(pointID)}
            plantMachines={machineQueryState.data}
          />
        )
      }
      case InformationPageState.MachineEdit: {
        return (
          <MachineRegistration
            encryptedBottleIdentifier={props.encryptedBottleIdentifier}
            existingMachines={machineQueryState.data}
            plantData={plantQueryState.data.find(
              p => p.plantID === registrationContext.state.plantID
            )}
            machineData={machineQueryState.data?.find(
              m => m.pointID === registrationContext.state.pointID
            )}
            customerData={customerDataRequest.data}
            cancel={() => moveToPage(InformationPageState.MachineSelect)}
            submit={pointID => handleNewPointIDSubmit(pointID)}
            plantMachines={machineQueryState.data}
          />
        )
      }
      case InformationPageState.PlantSelect: {
        page = plantPage
        buttons = plantButtons
        break
      }
      case InformationPageState.MachineSelect: {
        page = machinePage
        buttons = machineButtons
        break
      }
      case InformationPageState.InformationEntry: {
        page = detailEntryPage
        buttons = detailButtons
        break
      }
    }
  }

  return (
    <PageCard>
      <CancelButton onCancel={handleCancel} />
      <div
        className={`${PageStyles.EntryScreen} ${
          isLoggedIn.loggedIn ? PageStyles.LoggedIn : ''
        }`}
      >
        <div>
          <h4 className={PageStyles.MainTitle}>Register Your Sample</h4>
          <div className={PageStyles.ProgressStepperBox}>{progressStepper}</div>
          {page}
        </div>
        {buttons}
      </div>
    </PageCard>
  )
}

const useCustomerPlants = (
  page: InformationPageState,
  customerID: number,
  encryptedBottleIdentifier: string,
  newPlantCount: number
) => {
  const isLoggedIn = useIsLoggedIn()
  const loggedInAndInfoAvailable = customerID != null && isLoggedIn.loggedIn
  const useEncrypted = !!encryptedBottleIdentifier
  const shouldStartPromise =
    page === InformationPageState.PlantSelect &&
    (loggedInAndInfoAvailable || !!encryptedBottleIdentifier)

  const getPromise = () =>
    useEncrypted
      ? APIService.getPlantsByEncryptedBottleAsync({
          encryptedBottleIdentifier,
        })
      : APIService.getPlantsAsync({
          customerIDs: [customerID],
        })

  return usePromise<GetPlantsResponse>({
    shouldStartPromise: data => shouldStartPromise,
    promise: getPromise,
    dependencies: (data, issue) => [
      customerID,
      newPlantCount,
      loggedInAndInfoAvailable || !!encryptedBottleIdentifier,
    ],
  })
}

const usePlantMachines = (
  page: InformationPageState,
  customerID: number,
  plantID: number,
  newMachineCount: number,
  encryptedBottleIdentifier: string
) => {
  const lastLoadedMachineCount = useRef<number | null>(null)
  const lastLoadedPlantID = useRef<number | null>(null)
  const isLoggedIn = useIsLoggedIn()
  const loggedInAndInfoAvailable = customerID != null && isLoggedIn.loggedIn
  const useEncrypted = !!encryptedBottleIdentifier

  const machineCountChanged = lastLoadedMachineCount.current !== newMachineCount
  const selectedPlantChanged = lastLoadedPlantID.current !== plantID

  const shouldStartPromise =
    plantID != null &&
    page === InformationPageState.MachineSelect &&
    (machineCountChanged || selectedPlantChanged) &&
    (loggedInAndInfoAvailable || useEncrypted)

  const getPromise = () =>
    useEncrypted
      ? APIService.getMachinesWithEncryptedBottleIdentifierAsync({
          encryptedBottleIdentifier: encryptedBottleIdentifier,
          plantIDs: [plantID],
        })
      : APIService.getMachinesAsync({
          customerIDs: [customerID],
          plantIDs: [plantID],
        })

  return usePromise<GetMachinesResponse>({
    shouldStartPromise: data => shouldStartPromise,
    promise: () =>
      getPromise().finally(() => {
        lastLoadedMachineCount.current = newMachineCount
        lastLoadedPlantID.current = plantID
      }),
    dependencies: () => [
      plantID,
      page,
      newMachineCount,
      loggedInAndInfoAvailable || !!encryptedBottleIdentifier,
    ],
  })
}

function useCustomerInformation(props: TUseCustomerInformation) {
  const useEncrypted = !!props.encryptedBottleIdentifier
  const promise = useEncrypted
    ? () =>
        APIService.getCustomerByEncryptedBottleIdentifierAsync({
          encryptedBottleIdentifier: props.encryptedBottleIdentifier,
        })
    : () =>
        APIService.getCustomersAsync({
          customerID: [props.customerID],
        }).then(customers => customers[0])

  return usePromise<CustomerResponseDTO>({
    promise,
    dependencies: () => [props.customerID, props.encryptedBottleIdentifier],
    shouldStartPromise: () =>
      !!props.customerID || !!props.encryptedBottleIdentifier,
  })
}

function getPageFormBody() {
  // let page;
  // switch (state.page) {
  //   case PageState.PlantSelect: {
  //     page = plantPage
  //     break
  //   }
  //   case PageState.MachineSelect: {
  //     page = machinePage
  //     break
  //   }
  // }
}

function plantPage() {}

function machinePage() {}

function detailsPage() {}
