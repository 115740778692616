import React from 'react'

import { VirtualSelectFilter } from '../BaseFilters/VirtualSelectFilter'
import FilterTooltip from '../FilterTooltip/FilterTooltip'

// TODO: Refactor this to just wrap the standard props from the base Select component

type TCustomer = {
  custID: number
  custName: string
}
// type TProps = {
//   single?: boolean
//   required?: boolean
//   customers: Array<TCustomerIDName>
//   selectedCustID?: number
//   selectedCustIDs?: number[]
//   onCustIDChange: (_ids: number | number[]) => void
// }

type TProps = {
  single?: boolean
  required?: boolean
  customers: TCustomer[]
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange?: (props: number | number[]) => void
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setState?: (props: any) => void
  defaultValue?: TCustomer | TCustomer[]
  value?: TCustomer | TCustomer[]
  variant?: 'filled' | 'outlined' | 'standard'
  tooltip?: boolean
  style?: any
  disabled?: boolean
}

export const CustomerFilter = ({
  single,
  required,
  customers,
  setState,
  onChange,
  value,
  defaultValue,
  variant,
  tooltip,
  style,
  disabled,
}: TProps) => {
  if (setState == null && onChange == null) return null

  const handleChange = (customers: TCustomer | TCustomer[]) => {
    if (onChange != null) {
      if (single === true) {
        onChange((customers as TCustomer)?.custID)
      } else {
        onChange((customers as TCustomer[]).map(c => c.custID))
      }
    } else {
      if (single === true) {
        setState({ selectedCustomerID: (customers as TCustomer)?.custID })
      } else {
        setState({
          selectedCustomerIDs: (customers as TCustomer[])?.map(p => p.custID),
        })
      }
    }
  }
  if (customers.length <= 1) {
    return null
  } else {
    const filter = (
      <VirtualSelectFilter
        label="Customer"
        htmlFor="customerIDs"
        required={required}
        multiple={!single}
        id="customerIDs"
        disabled={disabled}
        variant={variant}
        getOptionSelected={(option, value) => option?.custID === value?.custID}
        renderValue={(selected: TCustomer) => selected?.custName}
        defaultValue={defaultValue}
        value={value}
        options={customers}
        onChange={handleChange}
        style={style}
      ></VirtualSelectFilter>
    )

    return tooltip ? (
      <FilterTooltip title="A TestOil Customer.">{filter}</FilterTooltip>
    ) : (
      filter
    )
  }
}
