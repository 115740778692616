import React from 'react'
import PageStyles from '../../page-styles.module.css'

const ReadOnlyForbiddenHeader = () => {
  return (
    <>
      <div>
        <header className={PageStyles.MainTitle}>Unauthorized</header>
        <sub className={PageStyles.SubTitle}>
          <span className={PageStyles.BlockSpan}>
            Read only users cannot access this page.
          </span>
        </sub>
      </div>
    </>
  )
}

export default ReadOnlyForbiddenHeader
