// eslint-disable-next-line simple-import-sort/imports
import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from 'components/ReportViewer/ReportDependencyWrapper.module.css'

import { ScriptDependency } from './ScriptDependency'

type Props = {
  children: any
}

export const ReportDependencyWrapper = (props: Props) => {
  const { children } = props
  return (
    <div style={styles}>
      <link
        href="//kendo.cdn.telerik.com/2018.2.620/styles/kendo.common.min.css"
        rel="stylesheet"
      />
      <link
        href="//kendo.cdn.telerik.com/2018.2.620/styles/kendo.default.min.css"
        rel="stylesheet"
      />
      <ScriptDependency src="/static/report-viewer-dependencies/JS/jquery-1.9.1.min.js">
        <ScriptDependency src="/static/report-viewer-dependencies/JS/telerikReportViewer.kendo-12.1.18.516.min.js">
          <ScriptDependency src="/static/report-viewer-dependencies/JS/telerikReportViewer-12.1.18.516.min.js">
            {children}
          </ScriptDependency>
        </ScriptDependency>
      </ScriptDependency>
    </div>
  )
}
