import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import GetAppIcon from '@material-ui/icons/GetApp'
import PropTypes from 'prop-types'

import AppLayout from 'components/AppLayout'
import NavCard from 'components/NavCard'
import SEO from 'components/SEO'

const pages = [
  {
    url: '/downloads/general',
    title: 'General Files',
    description:
      'Labels and forms that are applicable to any oil analysis program',
    background: '#446ca2',
    icon: <GetAppIcon />,
  },
  {
    url: '/downloads/customer',
    title: 'Customer Files',
    description:
      'Files that are specific to your program or oil analysis data files. ',
    background: '#452d12',
    icon: <FileCopyIcon />,
  },
]

function Downloads() {
  return (
    <React.Fragment>
      <SEO title="Downloads" />
      <Grid container direction="row" spacing={4}>
        <Grid item md={3}>
          <Typography variant="h1">Downloads</Typography>
          <Box style={{ marginTop: '15px' }}>
            <Typography paragraph>
              This is where you will find downloadable files like instructions,
              labels, and forms that can help you manage your Oil Analysis
              program.
            </Typography>
          </Box>
        </Grid>
        <Grid container item direction="row" md={9} spacing={4}>
          {pages.map((page, i) => (
            <NavCard
              key={i}
              icon={page.icon}
              title={page.title}
              description={page.description}
              url={page.url}
              background={page.background}
            ></NavCard>
          ))}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

Downloads.propTypes = {
  onError: PropTypes.func,
}

export default function Page() {
  return (
    <AppLayout tab="downloads">
      <Downloads />
    </AppLayout>
  )
}
