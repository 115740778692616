import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Chip, Grid, Link, Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ChatOutlined, EmailOutlined, PhoneOutlined } from '@material-ui/icons'
import {
  orderBy,
  process as kendoProcess,
  SortDescriptor,
} from '@progress/kendo-data-query'
import {
  Grid as KendoGrid,
  GridCellProps,
  GridColumn as Column,
} from '@progress/kendo-react-grid'

import { TAPICustomer, TSampleIssues } from 'types/api'

import EmailLinkButton from './buttons/EmailLinkButton'
import PhoneLinkButton from './buttons/PhoneLinkButton'
import CleanSortingButton from './CleanSortingButton'

interface OwnProps {
  customers: TAPICustomer[] // passed in from layout wrapper
  sampleIssues: TSampleIssues[]
  modalOpen: boolean
  onModalClose: () => void
}

export const SampleIssuesModal: React.FC<OwnProps> = ({
  customers,
  sampleIssues,
  modalOpen,
  onModalClose,
}: OwnProps) => {
  const getModalStyle = () => {
    const top = 50
    const left = 50

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      overflow: 'auto',
    }
  }

  const useStyles = makeStyles(_theme => ({
    paper: {
      position: 'absolute',
      width: '80vw',
      height: '80vh',
      backgroundColor: '#fff',
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '25px',
    },
    table: {
      '& .k-grid-header .k-header': {
        padding: 5,
        margin: 0,
        textAlign: 'center',
        '& .k-link': {
          justifyContent: 'center',
        },
        '& .MuiSvgIcon-root': {
          color: 'black',
          cursor: 'pointer',
        },
      },
      '& .k-grid-header': {
        '& col:nth-of-type(1)': {
          width: '10%',
        },
        '& col:nth-of-type(2)': {
          width: '25%',
        },
        '& col:nth-of-type(3)': {
          width: '25%',
        },
        '& col:nth-of-type(4)': {
          width: '10%',
        },
        '& col:nth-of-type(5)': {
          width: '30%',
        },
      },
      '& .k-grid-table': {
        '& col:nth-of-type(1)': {
          width: '10%',
        },
        '& col:nth-of-type(2)': {
          width: '25%',
        },
        '& col:nth-of-type(3)': {
          width: '25%',
        },
        '& col:nth-of-type(4)': {
          width: '10%',
        },
        '& col:nth-of-type(5)': {
          width: '30%',
        },
      },
      '& .k-grid-container td': {
        textAlign: 'center',
        padding: 5,
        margin: 0,
      },
      '& .k-grid-header .k-grid-header-wrap th.k-header': {
        textAlign: 'center',
        verticalAlign: 'middle',
        backgroundColor: '#F5F5F5',
        color: 'grey',
        fontWeight: 500,
      },
      marginTop: 15,
    },
  }))

  const getCustomerName = customerID =>
    customers.find(x => x.custID === customerID)?.custName ?? 'Unknown'

  const [state, setState] = useState([])
  const [sortConfig, setSortConfig] = useState<Array<SortDescriptor>>([])
  const [modalStyle] = useState(getModalStyle)

  const modalClasses = useStyles()

  useEffect(() => {
    if (sampleIssues) {
      setState(
        sampleIssues.map((x, i) => ({
          ...x,
          customerName: getCustomerName(x.customerID),
          index: i,
          checked: false,
        }))
      )
    }

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sampleIssues])

  const handleSelectAllSampleChange = event => {
    setState(prev => [
      ...prev.map(x => ({ ...x, checked: event.target.checked })),
    ])
  }

  const handleSelectedSampleChange = (index: number) => {
    const current = state.find(x => x.index === index)

    if (current !== null)
      setState(prev =>
        orderBy(
          [
            ...prev.filter(x => x !== current),
            { ...current, checked: !current.checked },
          ],
          [{ field: 'index', dir: 'asc' }]
        )
      )
  }

  const renderCheckbox = (ps: React.PropsWithChildren<GridCellProps>) => (
    <td>
      {Number(ps.dataItem.index) >= 0 && (
        <Checkbox
          checked={state.find(x => x.index === ps.dataItem.index)?.checked}
          onChange={() => handleSelectedSampleChange(ps.dataItem.index)}
        />
      )}
    </td>
  )

  const renderCheckboxHeader = _props => <>Enquire About</>

  const renderStringArray = (ps: React.PropsWithChildren<GridCellProps>) => {
    return (
      <td>
        {ps.dataItem.issues &&
          ps.dataItem.issues.map(label => (
            <Chip
              size="small"
              label={label}
              variant="outlined"
              style={{ marginRight: '5px' }}
            />
          ))}
      </td>
    )
  }

  const getMailToString = () => {
    const to = process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL
    let subject = 'Issue samples'
    let body = ''

    const checkedIssues = state.filter(x => x.checked)

    if (checkedIssues.some(x => x)) {
      body = `Customer Name\tPlant Name\tNumber of Issues\tIssues\n\n${checkedIssues
        .map(
          issue =>
            `${issue.customerName}\t${issue.plantName ?? '-'}\t${
              issue.numberOfIssues
            }\t${issue.issues.join('/')}\n\n`
        )
        .join('')}`

      subject += ` - Customer(s): ${[
        ...new Set(checkedIssues.map(x => x.customerName)),
      ]}`
    }

    return `mailto:${to}?subject=${encodeURI(subject)}&body=${encodeURI(body)}`
  }

  const onDismissAllSorting = () => {
    setSortConfig([] as SortDescriptor[])
  }

  const clearSortingButton = (
    <CleanSortingButton onDismissAllSorting={onDismissAllSorting} id="sample_issues_modal-clear_sorting_button" />
  )

  return (
    <Modal open={modalOpen} onClose={onModalClose}>
      <div style={modalStyle} className={modalClasses.paper}>
        <form>
          <Grid container direction="column" spacing={2}>
            <Grid
              container
              item
              direction="row"
              spacing={2}
              alignItems="center"
            >
              <Grid item xs={12}>
                <h1>Sample Issues</h1>
              </Grid>
              <Grid item xs={12}>
                <h5>
                  Your attention is needed to resolve the sample issues listed
                  in the table below. Please contact customer support.
                </h5>
              </Grid>
              <Grid item xs={12}>
                {clearSortingButton}
                <KendoGrid
                  style={{ height: '350px' }}
                  className={modalClasses.table}
                  reorderable={true}
                  sortable={true}
                  data={kendoProcess(state, {
                    sort: sortConfig,
                  })}
                  sort={sortConfig}
                  onSortChange={e => {
                    setSortConfig(e.sort)
                  }}
                >
                  <Column
                    cell={renderCheckbox}
                    headerCell={renderCheckboxHeader}
                  />
                  <Column field="customerName" title="Customer Name" />
                  <Column
                    field="plantName"
                    title="Plant Name"
                    cell={({ dataItem }) => (
                      <td>{dataItem.plantName ?? 'Unknown'}</td>
                    )}
                  />
                  <Column field="numberOfIssues" title="Qty. Issues" />
                  <Column
                    field="issues"
                    title="Issues"
                    cell={renderStringArray}
                  />
                </KendoGrid>
              </Grid>

              <Grid item xs={12}>
                <h1>Customer Support Contact Information</h1>
              </Grid>
              <Grid item xs={9}>
                <h5>
                  <EmailOutlined style={{ marginRight: '5px' }} />
                  <EmailLinkButton mailto={getMailToString()}>
                    Email: {process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL}
                  </EmailLinkButton>
                </h5>
                <h5>
                  <PhoneOutlined style={{ marginRight: '5px' }} />
                  <PhoneLinkButton phone="2162512510">
                    Phone: (216) 251-2510
                  </PhoneLinkButton>
                </h5>
              </Grid>
              <Grid item xs={3} style={{ alignSelf: 'flex-end' }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: '100%' }}
                  onClick={onModalClose}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
    </Modal>
  )
}
