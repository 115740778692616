import React from 'react'
import { Button, Dialog, DialogTitle, Grid } from '@material-ui/core'

import coreTheme from 'components/theme'

import * as Styles from './ReadOnlyDialog.module.css'

type OwnProps = {
  open: boolean
  onClose: () => void
  title?: string
  subtitle?: string
}

const ReadOnlyDialog: React.FC<OwnProps> = ({
  open,
  onClose,
  title,
  subtitle,
}: OwnProps) => {
  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title" style={{ textAlign: 'center' }}>
        {title || 'Read-Only Users Cannot Edit Program Information'}
      </DialogTitle>
      <p className={Styles.Details}>
        {subtitle ||
          'Please contact the program manager to modify your program.'}
      </p>
      <Grid container className={Styles.Content} alignItems="flex-end">
        <Grid
          container
          item
          justify="space-around"
          xs={12}
          className={Styles.Buttons}
        >
          <Button
            style={{
              backgroundColor: coreTheme.palette.secondary.main,
              color: coreTheme.palette.secondary.contrastText,
            }}
            className={Styles.Button}
            onClick={onClose}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default ReadOnlyDialog
