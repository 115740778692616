import React from 'react'

import styles from './FilterLabel.module.css'

type FilterLabelProps = {
  error?: boolean
}

const FilterLabel: React.FC<FilterLabelProps> = (
  props: React.PropsWithChildren<FilterLabelProps>
) => {
  const { children } = props

  return (
    <span className={`${styles.label} ${props.error ? styles.error : ''}`}>
      {children}
    </span>
  )
}

export default FilterLabel
