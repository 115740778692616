import React, { ReactNode } from 'react'
import {
  Grid,
  GridProps,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'

import CleanSortingButton from 'components/CleanSortingButton'
import { useTableStyles } from 'utils/tableStyles'

interface OwnProps extends GridProps {
  clearSortingButtonId?: string
  clearSortingButton?: ReactNode
  offline?: boolean
  children?: ReactNode
  className?: string
}

const TableGrid: React.FC<OwnProps> = ({
  clearSortingButtonId,
  offline,
  children,
  clearSortingButton,
  onSortChange,
  ...props
}: OwnProps) => {
  const classes = useTableStyles()

  const onDismissAllSorting = () => {
    onSortChange({ sort: [] } as GridSortChangeEvent)
  }

  return (
    <>
      {clearSortingButton || (
        <CleanSortingButton onDismissAllSorting={onDismissAllSorting} id={clearSortingButtonId} />
      )}

      <Grid
        className={classes.table}
        style={{ lineHeight: 1, maxHeight: window.screen.height * 0.55 }}
        pageable={{
          pageSizes: offline ? [100] : [10, 20, 50, 100, 250, 500],
        }}
        sortable={{
          allowUnsort: true,
          mode: 'multiple',
        }}
        onSortChange={onSortChange}
        {...props}
      >
        {children}
      </Grid>
    </>
  )
}

export default TableGrid
