import Moment from 'moment'

import { TAPISampleComplianceRecord } from 'types/api'
import { extendMoment } from 'moment-range'

const moment = extendMoment({ ...Moment, default: null })

export const tableFormatter = (
  data: TAPISampleComplianceRecord[],
  startDate: Date,
  endDate: Date,
  hasDivisions: boolean
): string[][] => {
  const headerValues = [
    ...(hasDivisions ? ['Division Name'] : []),
    'Customer Name',
    'Plant Name',
    'Route Name',
    'Machine Number',
    'Machine Name',
    'Equipment ID',
    'Test Package',
    'Tests',
  ]

  const range = moment.range(startDate, endDate)
  for (const month of range.by('month')) {
    headerValues.push(month.format('MMM YY') + ' Status')
    headerValues.push(month.format('MMM YY') + ' Condition')
  }

  const values = data.map(datum => {
    const months = []
    const range = moment.range(startDate, endDate)
    for (const month of range.by('month')) {
      months.push({
        year: month.year(),
        month: month.month() + 1,
      })
    }

    let results = [
      ...(hasDivisions ? [datum.machine.plant.customer.division.name] : []),
      datum.machine.plant.customer.custName,
      datum.machine.plant.plantName,
      datum.machine.route !== null
        ? `${datum.machine.route.routeNo} - ${datum.machine.route.name}`
        : '',
      datum.machine.machNo,
      datum.machine.machineName,
      datum.machine.custEquID,
      datum.testGroup.testGroupName,
      datum.testGroup.testGroupDefs
        .map(x => x.testCode.testCodeName)
        .join(', '),
    ]

    results = results.concat(
      months
        .map(x => {
          const count = datum.counts.find(
            y => y.month === x.month && y.year === x.year
          )

          const condition =
            typeof count === 'undefined'
              ? 'Unknown'
              : count.machCond > 2 || count.lubCond > 2
              ? 'Critical'
              : count.machCond === 2 || count.lubCond === 2
              ? 'Marginal'
              : count.machCond === 1 || count.lubCond === 1
              ? 'Normal'
              : 'Unknown'

          const status =
            typeof count === 'undefined'
              ? 'No Data'
              : count.tested > 0 && count.scheduled === 0
              ? 'Tested, not scheduled'
              : count.tested === 0 && count.scheduled > 0
              ? 'Scheduled, not tested'
              : count.tested === 0 && count.scheduled === 0
              ? ''
              : 'Scheduled and tested'

          return [status, condition]
        })
        .reduce((prev, cur) => prev.concat(cur), [])
    )

    return results
  })

  return [headerValues, ...values]
}
