import {
  LoadingState,
  LoadingStateWithoutReset,
} from 'pages/sample/registration/Cards/BottleInformationEntry/CreateMachine/types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CancellablePromise } from 'utils/CancellablePromise'
import { FetchError } from 'utils/Errors/FetchError'

export type UsePromiseState<Type> = {
  loading: boolean
  promise: Promise<Type> | CancellablePromise<Type> | null
} & LoadingState<Type, FetchError>

export type UsePromiseStateWithoutReset<Type> = {
  loading: boolean
  promise: Promise<Type> | CancellablePromise<Type> | null
} & LoadingStateWithoutReset<Type, FetchError>

const defaultFetchState: Readonly<Omit<UsePromiseState<null>, 'reset'>> = {
  data: null,
  error: null,
  loading: false,
  promise: null,
  loaded: false,
}

export type TUseQueryProps<ReturnType> = {
  /**Start the promise if this is true. Promises only rerun on dependency or change in this value. */
  shouldStartPromise: (
    state: UsePromiseStateWithoutReset<ReturnType>
  ) => boolean
  /**The promise to execute around. */
  promise: () => Promise<ReturnType> | CancellablePromise<ReturnType>
  cancel?: () => void
  /**Rerun promise and update component with result if any of these change. */
  dependencies?: (
    state: UsePromiseStateWithoutReset<ReturnType>,
    shouldStartPromise: boolean
  ) => any[]
  /**Override the loading status to anticipate loading before preconditions are met. */
  loadingOverride?: boolean
}

export function useLoading(queries: LoadingState<any, Error>[]) {
  const [loading, setloading] = useState<boolean>(
    queries.some(q => !!q.loading)
  )
  useEffect(() => {
    setloading(queries.some(q => q.loading))
  }, [...queries.map(q => !!q.loading)])

  return loading
}

export function useError(queries: LoadingState<any, Error>[]) {
  const [error, setError] = useState<Error>(null)

  useEffect(() => {
    if (queries.some(q => !!q.error)) {
      const errors = queries.map(q => q.error)
      let error = null
      while (!error) {
        error = errors.pop()
      }
      setError(error)
    } else {
      setError(null)
    }
  }, [...queries.map(q => !!q.error)])

  return error
}

export function usePromise<ReturnType>(props: TUseQueryProps<ReturnType>) {
  // const defaultReset = () => {
  //   if (
  //     fetchstate.loading &&
  //     fetchstate.promise &&
  //     fetchstate instanceof CancellablePromise
  //   ) {
  //     ;(
  //       fetchstate.promise as CancellablePromise<ReturnType>
  //     ).abortController.abort()
  //   }
  //   setFetchState(prev => ({
  //     ...defaultFetchState,
  //     // reset: prev.reset,
  //   }))
  // }

  const defaultState = {
    ...defaultFetchState,
  }

  const [fetchstate, setFetchState] = useState<
    UsePromiseStateWithoutReset<ReturnType>
  >({
    ...defaultState,
    loading: props.loadingOverride ?? defaultState.loading,
  })

  useEffect(() => {
    ;(async () => {
      if (!props.shouldStartPromise(fetchstate)) return

      try {
        setFetchState(prev => ({
          ...prev,
          error: null,
          data: null,
          loading: true,
          loaded: false,
        }))

        const promise = props.promise()
        if (promise && promise instanceof CancellablePromise) {
          setFetchState(prev => ({
            ...prev,
            error: null,
            data: null,
            loading: true,
            loaded: false,
          }))
        } else {
          setFetchState(prev => ({
            ...prev,
            error: null,
            data: null,
            loading: true,
            loaded: false,
          }))
        }

        let result = await promise
        setFetchState(prev => ({
          ...prev,
          error: null,
          data: result,
          loading: false,
          loaded: true,
        }))
      } catch (e) {
        const fetchError: FetchError = e
        setFetchState(prev => ({
          ...prev,
          error: fetchError,
          loading: false,
          loaded: true,
        }))
      }
    })()
  }, [
    // props.shouldStartPromise(fetchstate),
    props.loadingOverride,
    ...(props.dependencies
      ? props.dependencies(fetchstate, props.shouldStartPromise(fetchstate))
      : []),
  ])

  function reset() {
    setFetchState({ ...defaultFetchState })
  }

  return { ...fetchstate, reset }
}
