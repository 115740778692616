import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'
import Rating from '@mui/material/Rating'
import {
  ErrorFormValue,
  FormValue,
  ToErrorFormValues,
} from 'utils/FormUtilities/FormValue'
import { delay } from 'utils/general-utilities'
import * as PageStyles from '../../../../page-styles.module.css'
import TextField from '@mui/material/TextField'
import { Button, Checkbox, Input } from '@material-ui/core'
import { MobileSingleSelect } from 'components/MobileSingleSelect/MobileSingleSelect'
import { ElementDisplay } from 'pages/sample/registration/Pages/information-entry/ElementDisplay'
import { FancyTextArea } from 'components/FancyTextArea/FancyTextArea'
import { GetMachinesResponse, Route, TIdentityType, TListType } from 'types/api'
import { Autocomplete } from '@mui/material'
import { useIsBoundByWidths } from 'hooks/useIsBoundByWidths'
import {
  cacheTimeoutMS,
  mobileWidthDefinitonMap,
} from 'pages/sample/registration/Pages/constants'
import { TMachineInformation } from '../types'
import LocationSearchingIcon from '@mui/icons-material/LocationSearching'
import { useIsLoggedIn } from 'hooks/useIsLoggedIn'
import APIService from 'services/api-service'
import { useError, useLoading, usePromise } from 'hooks/usePromise'
import { LoadingCardModule } from 'pages/sample/registration/CardModules/LoadingCardModule'
import { useErrorPage } from '../../useErrorPage'
import { formatCoordinates } from 'pages/sample/registration/constants'
import { AllMachineManufacturersQuery } from 'services/queries/AllMachineManufacturersQuery'
import { FetchError } from 'utils/Errors/FetchError'
import { AllMachineManufacturersEncryptedQuery } from 'services/queries/AllMachineManufacturersEncryptedQuery'
import {
  getDatalessQueryResultFromCacheOrAPI,
  getQueryResultFromCacheOrAPI,
} from 'services/queries/Query'
import { AllMachineTypesEncryptedQuery } from 'services/queries/AllMachineTypesEncryptedQuery'
import { AllMachineTypesQuery } from 'services/queries/AllMachineTypesQuery'
import { AllPortTypesEncryptedQuery } from 'services/queries/AllPortTypesEncryptedQuery'
import { AllPortTypesQuery } from 'services/queries/AllPortTypesQuery'
import { GetRoutesQuery } from 'services/queries/GetRoutesQuery'
import { GetRoutesEncryptedQuery } from 'services/queries/GetRoutesEncryptedQuery'
const positiveIntegerRegex = /^[1-9]\d*$/m

type TProps = {
  back: (machineInformation: TMachineInformation) => void
  next: (machineInformation: TMachineInformation) => void
  encryptedBottleIdentifier: string | null
  customerID: number | null
  cancel: () => void
  cache: Cache
  addToCache: (url: string, data: any, timeoutMS: number) => Promise<void>
  plantMachines: GetMachinesResponse
} & Partial<TMachineInformation>

type TFixedState = {
  formUpdated: boolean
}

type TFreeFormState = ToErrorFormValues<TMachineInformation>

type TState = TFreeFormState & TFixedState

const debounceTimeMS = 0

export const MachineInformation = (props: TProps) => {
  const defaultState: Readonly<TState> = useMemo(
    () => ({
      name: new ErrorFormValue<string>(
        props.name ?? '',
        a1 => validateMachineName(a1, props.plantMachines, props.name),
        !!props.name
      ),
      notes: new ErrorFormValue<string>(
        props.notes ?? '',
        a1 => ErrorFormValue.validateOptionalString(a1, 'Notes', 2 ** 16 - 1),
        !!props.notes
      ),
      machineManufacturerShortCode: new ErrorFormValue<string>(
        props.machineManufacturerShortCode ?? null,
        a1 => ErrorFormValue.validateRequired(a1, 'Manufacturer'),
        !!props.machineManufacturerShortCode
      ),
      criticalityRating: new ErrorFormValue<number>(
        props.criticalityRating ?? null,
        a1 => ErrorFormValue.validateOptional(a1),
        !!props.criticalityRating
      ),
      sampleSourceRating: new ErrorFormValue<number>(
        props.sampleSourceRating ?? null,
        a1 => ErrorFormValue.validateOptional(a1),
        !!props.sampleSourceRating
      ),
      equipmentID: new ErrorFormValue<string>(
        props.equipmentID ?? '',
        a1 => validateEquipmentID(a1, props.plantMachines, props.equipmentID),
        !!props.equipmentID
      ),
      machineTypeShortCode: new ErrorFormValue<string>(
        props.machineTypeShortCode ?? null,
        a1 => ErrorFormValue.validateRequired(a1, 'Machine type'),
        !!props.machineTypeShortCode
      ),
      model: new ErrorFormValue<string>(
        props.equipmentID ?? '',
        a1 => ErrorFormValue.validateOptional(a1),
        !!props.equipmentID
      ),
      portTypeID: new ErrorFormValue<number>(
        props.portTypeID ?? null,
        a1 => ErrorFormValue.validateOptional(a1),
        !!props.portTypeID
      ),
      routeID: new ErrorFormValue<number>(
        props.routeID ?? null,
        a1 => ErrorFormValue.validateOptional(a1),
        !!props.routeID
      ),
      dutyCycle: new ErrorFormValue<string>(
        props.dutyCycle?.toString() ?? '',
        a1 => testDutyCycle(a1),
        !!props.dutyCycle
      ),
      location: new ErrorFormValue<GeolocationPosition>(
        props.location,
        c => null
      ),

      formUpdated: false,
    }),
    []
  )

  const [state, setState] = useState<TState>({ ...defaultState })
  const isMobile = useIsBoundByWidths(mobileWidthDefinitonMap)['mobile']

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        loc => {
          updateFreeFormState('location', loc)
        },
        err => {},
        { enableHighAccuracy: true }
      )
    }
  }

  function updateFixedState<
    TKey extends keyof TFixedState,
    TValue extends TFixedState[TKey]
  >(key: TKey, value: TValue) {
    setState(prev => ({ ...prev, [key]: value }))
  }

  function updateFreeFormState<
    TKey extends keyof TFreeFormState,
    TValue extends TFreeFormState[TKey]['Value']
  >(key: TKey, value: TValue, automaticUpdate: boolean = false) {
    const stateValue = state[key]
    stateValue.Value = value

    if (automaticUpdate) {
      stateValue.Modified = false
    }

    setState(prev => ({ ...prev, [key]: stateValue }))
  }

  const machineManufacturersRequest = useMachineManufacturers(props)

  const machineTypesRequest = useMachineTypes(props)
  const portTypesRequest = usePortTypes(props)
  const routesRequest = useRoutes(props)

  const queries = [
    machineManufacturersRequest,
    machineTypesRequest,
    portTypesRequest,
    routesRequest,
  ]
  const loading = useLoading(queries)
  const error = useError(queries)
  const errorPage = useErrorPage(error)

  async function issueWithData(
    func: (data: TMachineInformation) => void,
    ignoreErrors = false
  ) {
    await delay(debounceTimeMS)
    FormValue.modifyAllObjects(state)
    if (!ignoreErrors && !FormValue.allObjectParametersAreValid(state)) {
      updateFixedState('formUpdated', !state.formUpdated)
      return
    }

    if (func) {
      const vals = FormValue.getFormResultFromObject<TMachineInformation>(state)
      func(vals)
    }
  }

  const buttons = (
    <div className={PageStyles.ButtonContainer}>
      <Button
        data-cancel
        variant="contained"
        color="primary"
        onClick={() => issueWithData(props.back, true)}
        className={`${PageStyles.Button} ${PageStyles.Left}`}
      >
        About
      </Button>

      <Button
        data-accept
        disabled={!FormValue.allObjectParametersAreValid(state)}
        variant="contained"
        color="secondary"
        onClick={() => issueWithData(props.next)}
        className={`${PageStyles.Button} ${PageStyles.Right}`}
      >
        Fluid Info.
      </Button>
    </div>
  )

  const [criticalityRatingText, setCriticalityRatingText] = useState(
    criticalityRatingToText(0)
  )
  const [sampleSourceRatingText, setSampleSourceRatingText] = useState(
    sampleSourceRatingToText(0)
  )

  function handleMachineCriticalityRatingSet(value: number) {
    updateFreeFormState('criticalityRating', value)
    setCriticalityRatingText(
      criticalityRatingToText(value) ?? criticalityRatingToText(0)
    )
  }

  function handleSampleSourceRatingSet(value: number) {
    updateFreeFormState('sampleSourceRating', value)
    setSampleSourceRatingText(
      sampleSourceRatingToText(value) ?? sampleSourceRatingToText(0)
    )
  }

  function handleMachineCriticalityRatingHover(hovered: number) {
    setCriticalityRatingText(
      criticalityRatingToText(hovered) ??
        criticalityRatingToText(state.criticalityRating.Value) ??
        criticalityRatingToText(0)
    )
  }

  function handlesampleSourceRatingHover(hovered: number) {
    setSampleSourceRatingText(
      sampleSourceRatingToText(hovered) ??
        sampleSourceRatingToText(state.sampleSourceRating.Value) ??
        sampleSourceRatingToText(0)
    )
  }

  let page = <LoadingCardModule showHeader={false} />
  if (!!error) {
    const cancelButton = (
      <div className={PageStyles.ButtonContainer}>
        <Button
          data-cancel
          variant="contained"
          color="primary"
          onClick={props.cancel}
          fullWidth
          className={`${PageStyles.Button} `}
        >
          Cancel
        </Button>
      </div>
    )
    page = (
      <>
        <div>{errorPage}</div>
        {cancelButton}
      </>
    )
  }
  if (!error && !loading && queries.every(q => !!q.data)) {
    page = (
      <>
        <div>
          <h6 className={PageStyles.DetailSectionHeader}>
            Machine Information
          </h6>
          <section
            className={`${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer} ${PageStyles.Wrapper}`}
          >
            <TextField
              className={PageStyles.Wrappable}
              error={state.name.Modified && !!state.name.getError()}
              helperText={
                !!state.name.getError() &&
                state.name.Modified &&
                state.name.getValidationMessage()
              }
              label="Name"
              variant="outlined"
              color="primary"
              name={'Machine name'}
              required
              fullWidth
              value={state.name.Value}
              onChange={event =>
                updateFreeFormState('name', event.target.value)
              }
            />
            <TextField
              className={PageStyles.Wrappable}
              error={
                state.equipmentID.Modified && !!state.equipmentID.getError()
              }
              helperText={
                !!state.equipmentID.getError() &&
                state.equipmentID.Modified &&
                state.equipmentID.getValidationMessage()
              }
              label="Equipment ID"
              variant="outlined"
              color="primary"
              name={'Equipment ID'}
              required
              fullWidth
              value={state.equipmentID.Value}
              onChange={event =>
                updateFreeFormState('equipmentID', event.target.value)
              }
            />
            {isMobile ? (
              <MobileSingleSelect
                error={
                  state.machineTypeShortCode.Modified &&
                  !!state.machineTypeShortCode.getError()
                }
                helperText={
                  !!state.machineTypeShortCode.getError() &&
                  state.machineTypeShortCode.Modified &&
                  state.machineTypeShortCode.getValidationMessage()
                }
                default={
                  machineTypesRequest.data.find(
                    s => s.shortCode === state.machineTypeShortCode?.Value
                  ) ?? null
                }
                value={
                  machineTypesRequest.data.find(
                    s => s.shortCode === state.machineTypeShortCode?.Value
                  ) ?? null
                }
                required
                options={machineTypesRequest.data}
                stringify={opt => opt.name}
                label={'Type'}
                elementificate={opt => (
                  <ElementDisplay>{opt.name}</ElementDisplay>
                )}
                onChange={opt =>
                  opt &&
                  updateFreeFormState('machineTypeShortCode', opt.shortCode)
                }
              />
            ) : (
              <Autocomplete
                className={PageStyles.Wrappable}
                defaultValue={
                  machineTypesRequest.data.find(
                    s => s.shortCode === state.machineTypeShortCode?.Value
                  ) ?? null
                }
                value={
                  machineTypesRequest.data.find(
                    s => s.shortCode === state.machineTypeShortCode?.Value
                  ) ?? null
                }
                options={machineTypesRequest.data}
                renderInput={params => (
                  <TextField
                    color="primary"
                    {...params}
                    label="Type"
                    required
                  />
                )}
                getOptionLabel={(p: TListType) => `${p.name}`}
                onChange={(ev, opt: TListType) =>
                  updateFreeFormState(
                    'machineTypeShortCode',
                    opt?.shortCode ?? null
                  )
                }
              />
            )}

            {isMobile ? (
              <MobileSingleSelect
                error={
                  state.machineManufacturerShortCode.Modified &&
                  !!state.machineManufacturerShortCode.getError()
                }
                helperText={
                  !!state.machineManufacturerShortCode.getError() &&
                  state.machineManufacturerShortCode.Modified &&
                  state.machineManufacturerShortCode.getValidationMessage()
                }
                default={
                  machineManufacturersRequest.data.find(
                    s =>
                      s.shortCode === state.machineManufacturerShortCode?.Value
                  ) ?? null
                }
                value={
                  machineManufacturersRequest.data.find(
                    s =>
                      s.shortCode === state.machineManufacturerShortCode?.Value
                  ) ?? null
                }
                required
                options={machineManufacturersRequest.data}
                stringify={opt => opt.name}
                label={'Manufacturer'}
                elementificate={opt => {
                  const name = `${opt.name} (${opt.shortCode})`
                  return <ElementDisplay key={name}>{name}</ElementDisplay>
                }}
                onChange={opt =>
                  opt &&
                  updateFreeFormState(
                    'machineManufacturerShortCode',
                    opt.shortCode
                  )
                }
              />
            ) : (
              <Autocomplete
                className={PageStyles.Wrappable}
                defaultValue={
                  machineManufacturersRequest.data.find(
                    s =>
                      s.shortCode === state.machineManufacturerShortCode?.Value
                  ) ?? null
                }
                value={
                  machineManufacturersRequest.data.find(
                    s =>
                      s.shortCode === state.machineManufacturerShortCode?.Value
                  ) ?? null
                }
                options={machineManufacturersRequest.data}
                renderInput={params => {
                  return (
                    <TextField
                      color="primary"
                      {...params}
                      label="Manufacturer"
                      required
                    />
                  )
                }}
                aria-required
                getOptionLabel={(p: TListType) => {
                  const name = `${p.name} (${p.shortCode})`
                  return `${name}`
                }}
                onChange={(ev, opt: TListType) =>
                  updateFreeFormState(
                    'machineManufacturerShortCode',
                    opt?.shortCode || null
                  )
                }
              />
            )}

            <TextField
              className={PageStyles.Wrappable}
              error={state.model.Modified && !!state.model.getError()}
              helperText={
                !!state.model.getError() &&
                state.model.Modified &&
                state.model.getValidationMessage()
              }
              label="Model"
              variant="outlined"
              color="primary"
              name={'Model'}
              fullWidth
              value={state.model.Value}
              onChange={event =>
                updateFreeFormState('model', event.target.value)
              }
            />

            <TextField
              className={PageStyles.Wrappable}
              error={state.dutyCycle.Modified && !!state.dutyCycle.getError()}
              helperText={
                !!state.dutyCycle.getError() &&
                state.dutyCycle.Modified &&
                state.dutyCycle.getValidationMessage()
              }
              label="Duty Cycle"
              variant="outlined"
              type="number"
              color="primary"
              name={'Duty Cycle'}
              fullWidth
              value={state.dutyCycle.Value}
              onChange={event =>
                updateFreeFormState('dutyCycle', event.target.value)
              }
            />

            {isMobile ? (
              <MobileSingleSelect
                error={state.routeID.Modified && !!state.routeID.getError()}
                helperText={
                  !!state.routeID.getError() &&
                  state.routeID.Modified &&
                  state.routeID.getValidationMessage()
                }
                default={
                  routesRequest.data.find(s => s.id === state.routeID?.Value) ??
                  null
                }
                value={
                  routesRequest.data.find(s => s.id === state.routeID?.Value) ??
                  null
                }
                options={routesRequest.data}
                stringify={opt => opt.name}
                label={'Route'}
                elementificate={opt => (
                  <ElementDisplay>
                    {opt.name} ({opt.number})
                  </ElementDisplay>
                )}
                onChange={opt => opt && updateFreeFormState('routeID', opt.id)}
              />
            ) : (
              <Autocomplete
                className={PageStyles.Wrappable}
                defaultValue={
                  routesRequest.data.find(s => s.id === state.routeID?.Value) ??
                  null
                }
                value={
                  routesRequest.data.find(s => s.id === state.routeID?.Value) ??
                  null
                }
                options={routesRequest.data}
                renderInput={params => (
                  <TextField color="primary" {...params} label="Route" />
                )}
                getOptionLabel={(p: Route) => `${p.name} (${p.number})`}
                onChange={(ev, opt: Route) =>
                  updateFreeFormState('routeID', opt?.id || null)
                }
              />
            )}

            {isMobile ? (
              <MobileSingleSelect
                error={
                  state.portTypeID.Modified && !!state.portTypeID.getError()
                }
                helperText={
                  !!state.portTypeID.getError() &&
                  state.portTypeID.Modified &&
                  state.portTypeID.getValidationMessage()
                }
                default={
                  portTypesRequest.data.find(
                    s => s.id === state.portTypeID?.Value
                  ) ?? null
                }
                value={
                  portTypesRequest.data.find(
                    s => s.id === state.portTypeID?.Value
                  ) ?? null
                }
                options={portTypesRequest.data}
                stringify={opt => opt.name}
                label={'Port Type'}
                elementificate={opt => (
                  <ElementDisplay>{opt.name}</ElementDisplay>
                )}
                onChange={opt =>
                  opt && updateFreeFormState('portTypeID', opt.id)
                }
              />
            ) : (
              <Autocomplete
                className={PageStyles.Wrappable}
                defaultValue={
                  portTypesRequest.data.find(
                    s => s.id === state.portTypeID?.Value
                  ) ?? null
                }
                value={
                  portTypesRequest.data.find(
                    s => s.id === state.portTypeID?.Value
                  ) ?? null
                }
                options={portTypesRequest.data}
                renderInput={params => (
                  <TextField color="primary" {...params} label="Port Type" />
                )}
                getOptionLabel={(p: TIdentityType) => `${p.name}`}
                onChange={(ev, opt: TIdentityType) =>
                  updateFreeFormState('portTypeID', opt?.id || null)
                }
              />
            )}

            <div className={`${PageStyles.Flexbox}`}>
              <div style={{ flex: 85 }}>
                <TextField
                  className={PageStyles.Wrappable}
                  label="Coordinates"
                  variant="outlined"
                  color="primary"
                  inputProps={{ maxLength: 21 }}
                  fullWidth
                  value={formatCoordinates(state.location.Value?.coords)}
                  disabled={true}
                />
              </div>
              <div
                className={`${PageStyles.TargetButton}`}
                onClick={getLocation}
              >
                <LocationSearchingIcon />
              </div>
            </div>

            <div className={`${PageStyles.Rating} ${PageStyles.Wrappable}`}>
              <label htmlFor="criticalityRating">
                Machine Criticality Rating
              </label>
              <br />
              <Rating
                id="criticalityRating"
                name="Criticality Rating"
                value={state.criticalityRating.Value}
                onChangeActive={(_, newHover) => {
                  handleMachineCriticalityRatingHover(newHover)
                }}
                onChange={(_, newValue) => {
                  handleMachineCriticalityRatingSet(newValue)
                }}
                style={{ fontSize: '32px' }}
              />
              <p
                className={PageStyles.FinePrintParagraph}
                style={{ height: isMobile ? '35.2px' : '40.2px' }}
              >
                {criticalityRatingText}
              </p>
            </div>
            <div className={`${PageStyles.Rating} ${PageStyles.Wrappable}`}>
              <label htmlFor="sampleSourceRating">Sample Source Rating</label>
              <br />
              <Rating
                name="Sample Source Rating"
                id="sampleSourceRating"
                value={state.sampleSourceRating.Value}
                onChangeActive={(_, newHover) => {
                  handlesampleSourceRatingHover(newHover)
                }}
                onChange={(_, newValue) => {
                  handleSampleSourceRatingSet(newValue)
                }}
                style={{ fontSize: '32px' }}
              />
              <p
                className={PageStyles.FinePrintParagraph}
                style={{ height: isMobile ? '35.2px' : '40.2px' }}
              >
                {sampleSourceRatingText}
              </p>
            </div>
            <FancyTextArea
              borderColor="#007bff"
              labelColor="#007bff"
              label="Machine Notes"
              textAreaProps={{ maxLength: 65535 }}
              value={state.notes.Value}
              onChange={event =>
                updateFreeFormState('notes', event.target.value)
              }
            />
          </section>
        </div>
        {buttons}
      </>
    )
  }

  return page
}

/**0-5, -1 returns null. */
function criticalityRatingToText(rating: number) {
  if (rating === -1) return null

  rating = rating ?? 0

  switch (rating) {
    case 0:
      return "A rating for the criticality of the equipment's operative health. Hover over or select a rating for a description of the rating."
    case 1:
      return 'Non-production equipment.'
    case 2:
      return 'Less critical equipment with redundent equipment in place in the event of equipment failure.'
    case 3:
      return 'Less critical equipment without redundent equipment in place.'
    case 4:
      return 'Critical equipment with redundent equipment in place in the event of equipment failure.'
    case 5:
      return 'Critical equipment without redundent equipment in place.'
    default:
      throw new Error('Out of range.')
  }
}

/**0-5, -1 returns null. */
function sampleSourceRatingToText(rating: number) {
  if (rating === -1) return null
  rating = rating ?? 0

  switch (rating) {
    case 0:
      return 'A rating for the quality of the sample source. Hover over or select a rating for a description of the rating.'
    case 1:
      return 'A sample location increasing the potential for system contamination through its use, such as a hatch, breather, dipstick, or fill cap.'
    case 2:
      return 'Sampling from a drain, or sampling from the bottom of a sump or reservoir.'
    case 3:
      return 'A sampling location that is outside of an active zone and requires extensive flushing.'
    case 4:
      return 'A dedicated sample port that requires briefly shutting down the machine for access or extensive flushing to obtain the sample.'
    case 5:
      return 'A dedicated sample port where the sample can be pulled while the machine is running with minimal flushing, drawing from an active zone.'
    default:
      throw new Error('Out of range.')
  }
}

function useRoutes(props: TProps) {
  const isLoggedIn = useIsLoggedIn()
  const useEncrypted = !!props.encryptedBottleIdentifier
  const loggedInAndInfoAvailable =
    props?.customerID != null && isLoggedIn.loggedIn

  const issuePromise =
    loggedInAndInfoAvailable || !!props.encryptedBottleIdentifier

  async function getPromise() {
    const queryData = {
      encryptedIdentifier: props.encryptedBottleIdentifier,
    }

    try {
      if (useEncrypted) {
        return await getQueryResultFromCacheOrAPI(
          GetRoutesEncryptedQuery,
          queryData,
          props.cache,
          props.addToCache,
          cacheTimeoutMS
        )
      }

      return await getQueryResultFromCacheOrAPI(
        GetRoutesQuery,
        { customerID: props.customerID },
        props.cache,
        props.addToCache,
        cacheTimeoutMS
      )
    } catch (error) {
      if (error instanceof FetchError && error.status === 404) {
        return []
      }
    }
  }

  return usePromise({
    shouldStartPromise: data =>
      issuePromise && !data.data && !data.loading && !data.error,
    dependencies: _ => [issuePromise],
    promise: getPromise,
  })
}

function usePortTypes(props: TProps) {
  const isLoggedIn = useIsLoggedIn()
  const useEncrypted = !!props.encryptedBottleIdentifier
  const loggedInAndInfoAvailable =
    props?.customerID != null && isLoggedIn.loggedIn

  const issuePromise =
    loggedInAndInfoAvailable || !!props.encryptedBottleIdentifier

  async function getPromise() {
    if (useEncrypted) {
      const queryData = {
        encryptedIdentifier: props.encryptedBottleIdentifier,
      }

      return await getQueryResultFromCacheOrAPI(
        AllPortTypesEncryptedQuery,
        queryData,
        props.cache,
        props.addToCache,
        cacheTimeoutMS
      )
    }

    return await getDatalessQueryResultFromCacheOrAPI(
      AllPortTypesQuery,
      props.cache,
      props.addToCache,
      cacheTimeoutMS
    )
  }

  return usePromise({
    shouldStartPromise: data =>
      issuePromise && !data.data && !data.loading && !data.error,
    dependencies: () => [issuePromise],
    promise: getPromise,
  })
}

function useMachineTypes(props: TProps) {
  const isLoggedIn = useIsLoggedIn()
  const useEncrypted = !!props.encryptedBottleIdentifier
  const loggedInAndInfoAvailable =
    props?.customerID != null && isLoggedIn.loggedIn

  const issuePromise =
    loggedInAndInfoAvailable || !!props.encryptedBottleIdentifier

  async function getPromise() {
    if (useEncrypted) {
      const queryData = {
        encryptedIdentifier: props.encryptedBottleIdentifier,
      }

      return await getQueryResultFromCacheOrAPI(
        AllMachineTypesEncryptedQuery,
        queryData,
        props.cache,
        props.addToCache,
        cacheTimeoutMS
      )
    }

    return await getDatalessQueryResultFromCacheOrAPI(
      AllMachineTypesQuery,
      props.cache,
      props.addToCache,
      cacheTimeoutMS
    )
  }

  return usePromise({
    shouldStartPromise: data =>
      issuePromise && !data.data && !data.loading && !data.error,
    dependencies: () => [issuePromise],
    promise: getPromise,
  })
}

function useMachineManufacturers(props: TProps) {
  const isLoggedIn = useIsLoggedIn()
  const useEncrypted = !!props.encryptedBottleIdentifier
  const loggedInAndInfoAvailable =
    props?.customerID != null && isLoggedIn.loggedIn
  const issuePromise =
    loggedInAndInfoAvailable || !!props.encryptedBottleIdentifier

  async function getPromise() {
    if (useEncrypted) {
      const queryData = {
        encryptedIdentifier: props.encryptedBottleIdentifier,
      }

      return await getQueryResultFromCacheOrAPI(
        AllMachineManufacturersEncryptedQuery,
        queryData,
        props.cache,
        props.addToCache,
        cacheTimeoutMS
      )
    }

    return await getDatalessQueryResultFromCacheOrAPI(
      AllMachineManufacturersQuery,
      props.cache,
      props.addToCache,
      cacheTimeoutMS
    )
  }

  return usePromise({
    shouldStartPromise: data =>
      issuePromise && !data.data && !data.loading && !data.error,
    dependencies: () => [issuePromise],
    promise: () => getPromise(),
  })
}

const shortCap = 2 ** 16

function testDutyCycle(dutyCycle: string) {
  if (!dutyCycle) return

  const isPositiveInteger = positiveIntegerRegex.test(dutyCycle)
  if (!isPositiveInteger) {
    return new Error(`Duty cycle must be a positive integer.`)
  }

  if (!testDutyCycleNumber(dutyCycle)) {
    return new Error(`Duty cycle must be less than ${shortCap}.`)
  }

  return null
}

function testDutyCycleNumber(dutyCycle: string) {
  const num = Number(dutyCycle)
  return !isNaN(num) && num < shortCap
}

let machineDataArray: GetMachinesResponse = []
let machineNameSet: Set<string> = new Set()
let equipmentIDSet: Set<string> = new Set()
function validateMachineName(
  machineName: string,
  machineData: GetMachinesResponse,
  startingMachineName: string | null
) {
  if (machineDataArray != machineData || !machineNameSet) {
    machineDataArray = machineData
    machineNameSet = new Set(
      machineDataArray?.filter(m => !!m && !!m.machineName).map(md => md.machineName.trim().toLowerCase())
    )
  }

  const formattedMachineName = machineName.trim().toLowerCase()
  if (
    machineName &&
    startingMachineName?.trim()?.toLowerCase() !== formattedMachineName &&
    machineNameSet.has(formattedMachineName)
  ) {
    return new Error('Machine name must be unique within the selected plant!')
  }

  return ErrorFormValue.validateRequiredString(machineName, 'Machine name', 50)
}

function validateEquipmentID(
  equipmentID: string,
  machineData: GetMachinesResponse,
  startingEquipmentID: string | null
) {
  if (machineDataArray != machineData || !equipmentIDSet) {
    machineDataArray = machineData
    equipmentIDSet = new Set(
      machineDataArray?.filter(m => !!m && !!m.customerEquipmentID)?.map(md => md.customerEquipmentID.trim().toLowerCase())
    )
  }

  const formattedEquipmentID = equipmentID.trim().toLowerCase()

  if (
    equipmentID &&
    startingEquipmentID?.trim()?.toLowerCase() != formattedEquipmentID &&
    equipmentIDSet.has(formattedEquipmentID)
  ) {
    return new Error('Equipment ID must be unique within the selected plant!')
  }

  return ErrorFormValue.validateRequiredString(equipmentID, 'Equipment ID', 50)
}
