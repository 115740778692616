import React, { useEffect, useState, useContext } from 'react'
import { Typography } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'

import LandingLayout from 'components/landing/Layout'
import SEO from 'components/SEO'
import LoginService from 'services/login-service'
import { useProfile } from 'Contexts/ProfileContext'

const AuthPage: React.FC = () => {
  const profileContext = useProfile()
  const navigate = useNavigate()
  const [invalid, setInvalid] = useState(false)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const access_token = urlParams.get('a')
    const refresh_token = urlParams.get('r')
    const expires = urlParams.get('e')
    const postLoginURL = urlParams.get('PostLoginURL')

    if (access_token && refresh_token && expires) {
      ;(async () => {
        await LoginService.setTokens(access_token, refresh_token, expires)

        profileContext.utilities.clearProfile()

        navigate(postLoginURL ?? '/')
      })()
    } else {
      setInvalid(true)
    }
  }, [])

  return (
    <>
      {!invalid ? (
        ''
      ) : (
        <LandingLayout>
          <SEO title="Invalid auth token" />
          <Typography variant="h6">Invalid auth token provided</Typography>
        </LandingLayout>
      )}
    </>
  )
}

export default AuthPage
