import React from 'react'
import PropTypes from 'prop-types'
import { Alert, AlertTitle } from '@material-ui/lab'

const OfflineBanner = ({ pageTitle }) => {
  return (
    <Alert severity="warning">
      <AlertTitle>You are currently offline.</AlertTitle>
      Some of the functionality on this screen may be limited or unavailable
      until you reconnect to a network.
    </Alert>
  )
}

OfflineBanner.propTypes = {
  pageTitle: PropTypes.string.isRequired,
}

export default OfflineBanner
