import React from 'react'
import { Card, CardContent, Grid } from '@material-ui/core'

import ConditionElement from 'components/ConditionElement'
import {
  lubeConditionKeys,
  machineConditionKeys,
} from 'services/lookup-service'

import { useCardStyles } from './cardStyles'

interface Props {
  item: any
}
const MobileCardTotals: React.FC<Props> = ({ item }: Props) => {
  const cardClasses = useCardStyles()

  return (
    <Card
      variant="outlined"
      className={`${cardClasses.card} ${cardClasses.cardTotals}`}
    >
      <CardContent>
        <Grid container direction="row" spacing={1}>
          <Grid container direction="column" spacing={1} xs={12} md={12}>
            <Grid item xs={12} md={12}>
              Samples
            </Grid>
            <Grid item xs={12} md={12}>
              {item.samples}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={1}
            xs={12}
            md={6}
            className="condition-box"
          >
            <Grid
              container
              direction="column"
              spacing={1}
              xs={12}
              className="condition-title"
            >
              Machine Condition
            </Grid>
            <Grid container direction="column" spacing={1} xs={3}>
              <Grid item xs={12} md={12}>
                <ConditionElement condition={machineConditionKeys.normal} />
              </Grid>
              <Grid item xs={12} md={12} className="condition-value">
                {item.machineNormal}
              </Grid>
            </Grid>
            <Grid container direction="column" spacing={1} xs={3}>
              <Grid item xs={12} md={12}>
                <ConditionElement condition={machineConditionKeys.marginal} />
              </Grid>
              <Grid item xs={12} md={12} className="condition-value">
                {item.machineMarginal}
              </Grid>
            </Grid>
            <Grid container direction="column" spacing={1} xs={3}>
              <Grid item xs={12} md={12}>
                <ConditionElement condition={machineConditionKeys.critical} />
              </Grid>
              <Grid item xs={12} md={12} className="condition-value">
                {item.machineCritical}
              </Grid>
            </Grid>
            <Grid container direction="column" spacing={1} xs={3}>
              <Grid item xs={12} md={12}>
                <ConditionElement condition={machineConditionKeys.unknown} />
              </Grid>
              <Grid item xs={12} md={12} className="condition-value">
                {item.machineUnknown}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            spacing={1}
            xs={12}
            md={6}
            className="condition-box"
          >
            <Grid
              container
              direction="column"
              spacing={1}
              xs={12}
              className="condition-title"
            >
              Lubricant Condition
            </Grid>
            <Grid container direction="column" spacing={1} xs={3}>
              <Grid item xs={12} md={12}>
                <ConditionElement condition={lubeConditionKeys.normal} />
              </Grid>
              <Grid item xs={12} md={12} className="condition-value">
                {item.lubeNormal}
              </Grid>
            </Grid>
            <Grid container direction="column" spacing={1} xs={3}>
              <Grid item xs={12} md={12}>
                <ConditionElement condition={lubeConditionKeys.marginal} />
              </Grid>
              <Grid item xs={12} md={12} className="condition-value">
                {item.lubeMarginal}
              </Grid>
            </Grid>
            <Grid container direction="column" spacing={1} xs={3}>
              <Grid item xs={12} md={12}>
                <ConditionElement condition={lubeConditionKeys.critical} />
              </Grid>
              <Grid item xs={12} md={12} className="condition-value">
                {item.lubeCritical}
              </Grid>
            </Grid>
            <Grid container direction="column" spacing={1} xs={3}>
              <Grid item xs={12} md={12}>
                <ConditionElement condition={lubeConditionKeys.unknown} />
              </Grid>
              <Grid item xs={12} md={12} className="condition-value">
                {item.lubeUnknown}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default MobileCardTotals
