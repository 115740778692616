import React, { createContext, useContext } from 'react'

export type TEnvironmentContext = {
  isProduction: boolean
  isBeta: boolean
  isDevelopment: boolean
  languagePreference: string
}

const defaults: TEnvironmentContext = {
  isProduction: false,
  isBeta: false,
  isDevelopment: false,
  languagePreference: 'en-US',
}

export const useEnvironmentContext = () => {
  return useContext(EnvironmentContext)
}

export const EnvironmentContext = createContext({
  ...defaults,
} as TEnvironmentContext)

export const EnvironmentContextProvider: React.FC = props => {
  const isProduction =
    process.env.REACT_APP_ENVIRONMENT === process.env.REACT_APP_PRODUCTIONENV
  const isBeta =
    process.env.REACT_APP_ENVIRONMENT === process.env.REACT_APP_BETAENV
  const isDevelopment =
    process.env.REACT_APP_ENVIRONMENT === process.env.REACT_APP_DEVELOPMENTENV

  const languagePreference =
    navigator.languages != null
      ? navigator.languages[0]
      : navigator.language
      ? navigator.language
      : defaults.languagePreference

  const context = { isProduction, isBeta, isDevelopment, languagePreference }
  return (
    <EnvironmentContext.Provider value={context}>
      {props.children}
    </EnvironmentContext.Provider>
  )
}
