import Moment from 'moment'

import { TAPIMachine } from 'types/api'
import { extendMoment } from 'moment-range'

const moment = extendMoment({ ...Moment, default: null })

export const tableFormatter = (
  machines: TAPIMachine[],
  hasDivisions: boolean
): string[][] => {
  const headers = [
    ...(hasDivisions ? [{ id: 'divisionName', text: 'Division Name' }] : []),
    { id: 'plant.customer.custName', text: 'Customer Name' },
    { id: 'plant.plantName', text: 'Plant Name' },
    { id: 'route.routeNo', text: 'Route' },
    { id: 'machineName', text: 'Machine Name' },
    { id: 'machNo', text: 'Machine No.' },
    { id: 'custEquID', text: 'Equipment ID' },
    { id: 'testGroup.name', text: 'Test Packages', sortable: false },
    { id: 'testGroup.testCode.name', text: 'Tests', sortable: false },
  ]

  const headerValues = headers.map(x => x.text)

  const values = machines.map(machine => [
    ...(hasDivisions ? [machine.plant.customer.division.name] : []),
    machine.plant.customer.custName,
    machine.plant.plantName,
    machine.route !== null
      ? `${machine.route.routeNo} - ${machine.route.name}`
      : '',
    machine.machineName,
    machine.machNo,
    machine.custEquID,
    machine.machSchedules !== null
      ? machine.machSchedules.map(x => x.testGroup.testGroupName).join(', ')
      : '',
    machine.machSchedules !== null
      ? machine.machSchedules
          .map(y => y.testGroup.testGroupDefs.map(x => x.testCode.testCodeName))
          .reduce((prev, cur) => prev.concat(cur), [])
          .join(', ')
      : '',
  ])

  return [headerValues, ...values]
}
