import styled from '@emotion/styled'
import { useProductSocket } from 'hooks/useProductSocket'

import React, { useState } from 'react'
import { Product, ProductPricing } from 'types/api'
import { emptyPricing, freePricing } from 'utils/constants'

import { PricingEntry } from './PricingEntry'

type TProps = {
  customerID: number
  product: Product
  pricing: ProductPricing[]
  selected: ProductPricing | null
  isEditable: boolean
  onProductPricingDeleteRequest: (
    productPricing: ProductPricing
  ) => Promise<void>
  onProductPricingPostRequest: (productPricing: ProductPricing) => Promise<void>
}
export const PricingTable: React.FunctionComponent<TProps> = props => {
  let {
    pricing,
    isEditable,
    product,
    customerID,
    onProductPricingDeleteRequest,
    onProductPricingPostRequest,
  } = props

  let tempPricing: ProductPricing = null
  let displayPricing = [...pricing]

  //NOTE: If editable and last is not empty pricing ID, push a clone of it for price adding.
  if (isEditable) {
    if (
      !displayPricing ||
      displayPricing.length === 0 ||
      (displayPricing &&
        displayPricing.length > 0 &&
        displayPricing.at(-1).pricingID !== null)
    ) {
      tempPricing = {
        ...emptyPricing,
        currencyCode: 'USD',
        customerID: customerID,
      }
      displayPricing.push(tempPricing)
    }
  }

  //NOTE: If not editable, remove pricing post placeholder
  if (
    !isEditable &&
    displayPricing &&
    displayPricing.length > 0 &&
    displayPricing.at(-1).pricingID == null
  ) {
    displayPricing = displayPricing.filter(p => p !== tempPricing)
  }

  displayPricing.sort((a, b) =>
    a.pricingID < 0 || a.pricingID == null
      ? 1
      : b.pricingID < 0 || b.pricingID == null
      ? 1
      : a.minimumQuantity - b.minimumQuantity
  )

  //NOTE: add free pricing if the customer doesn't have a price at 1 quantity and if is not in edit mode.
  if (
    !isEditable &&
    displayPricing &&
    (displayPricing.length === 0 || displayPricing[0].minimumQuantity > 1)
  ) {
    displayPricing = [freePricing, ...displayPricing]
  }
  //NOTE: remove free pricing if first entry.
  if (
    isEditable &&
    displayPricing &&
    displayPricing.length !== 0 &&
    displayPricing[0] === freePricing
  ) {
    displayPricing = displayPricing.slice(1)
  }

  const hasPricing = displayPricing && displayPricing.length > 0

  return (
    <StyledTable>
      <thead>
        <tr>
          <THeader>Min. Quantity</THeader>
          <THeader>Unit Price</THeader>
          {isEditable && <ThinHeader></ThinHeader>}
        </tr>
      </thead>
      <tbody>
        {hasPricing &&
          displayPricing.map(p => (
            <PricingEntry
              product={product}
              isEmptyPricing={p === tempPricing}
              customerID={customerID}
              key={p.pricingID ?? -1}
              isEditable={isEditable}
              price={p}
              pricing={displayPricing}
              selected={props.selected}
              onProductPricingDeleteRequest={onProductPricingDeleteRequest}
              onProductPricingPostRequest={onProductPricingPostRequest}
            />
          ))}
      </tbody>
    </StyledTable>
  )
}

const StyledTable = styled.table`
  width: 100%;
  display: table;
  tr {
    display: flex;
    border-bottom: thin solid rgba(0, 0, 0, 0.2);
    border: 'none';
    padding: 5px 0;
  }
  tr.selected {
    font-weight: bold;
  }
  thead > tr:first-of-type {
    border-bottom: thin solid rgba(0, 0, 0, 0.4);
    padding: 0 0 5px 0;
  }
  tbody > tr:last-of-type {
    margin-bottom: 10px;
  }
`

const THeader = styled.th`
  flex: 100;
  position: relative;
`

const ThinHeader = styled.th`
  flex: 15;
  position: relative;
`
