import { NotFoundIconBox } from './NotFoundIconBox'

type TProps = {
  header: JSX.Element
  details?: JSX.Element
}
export const NotFoundCardModule = (props: TProps) => {
  return (
    <>
      <div>{props.header}</div>
      <NotFoundIconBox />

      <div>{props.details}</div>
    </>
  )
}
