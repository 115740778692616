import React from 'react'
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import { Link } from 'react-router-dom'
import moment from 'moment'

import FileLink from 'components/FileLink'
import { reportTypes } from 'services/lookup-service'

import ConditionElement from '../../ConditionElement'

interface OwnProps {
  sample: any
  showDivisions: boolean
}

export const MobileCard: React.FC<OwnProps> = ({
  sample,
  showDivisions,
}: OwnProps) => {
  return (
    <Card
      key={sample.labID}
      variant="outlined"
      style={{ marginBottom: '10px', marginTop: '20px' }}
    >
      <CardHeader title={sample.machine.plant.plantName} />
      <CardContent>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6} md={3}>
            Customer Name:
          </Grid>
          <Grid item xs={6} md={showDivisions ? 3 : 9}>
            {sample.machine.plant.customer.custName}
          </Grid>

          {showDivisions && (
            <Grid item xs={6} md={3}>
              Division Name:
            </Grid>
          )}
          {showDivisions && (
            <Grid item xs={6} md={3}>
              {sample.machine.plant?.customer?.division?.name}
            </Grid>
          )}

          <Grid item xs={6} md={3}>
            Plant Name:
          </Grid>
          <Grid item xs={6} md={3}>
            {sample.machine.plant.plantName}
          </Grid>
          <Grid item xs={6} md={3}>
            Route:
          </Grid>
          <Grid item xs={6} md={3}>
            {sample.machine.route &&
              `${sample.machine.route.routeNo} - ${sample.machine.route.name}`}
          </Grid>
          <Grid item xs={6} md={3}>
            Machine No:
          </Grid>
          <Grid item xs={6} md={3}>
            {sample.machine.machNo}
          </Grid>
          <Grid item xs={6} md={3}>
            Machine Name:
          </Grid>
          <Grid item xs={6} md={3}>
            <Link
              to={`/dart/data?labID=${sample.labID}`}
              target="_blank"
              rel="noreferrer"
            >
              {sample.machine.machineName}
            </Link>
          </Grid>
          <Grid item xs={6} md={3}>
            Equipment ID:
          </Grid>
          <Grid item xs={6} md={3}>
            {sample.machine.custEquID}
          </Grid>
          <Grid item xs={6} md={3}>
            Date Sampled:
          </Grid>
          <Grid item xs={6} md={3}>
            {moment(sample.sampleDate).format('l')}
          </Grid>
          <Grid item xs={6} md={3}>
            Mach:
          </Grid>
          <Grid item xs={6} md={3}>
            <ConditionElement nomargin={true} machCond={sample.machCond} />
          </Grid>
          <Grid item xs={6} md={3}>
            Lube:
          </Grid>
          <Grid item xs={6} md={3}>
            <ConditionElement nomargin={true} lubCond={sample.lubCond} />
          </Grid>

          <Grid item xs={6} md={3}>
            Lab No:
          </Grid>
          <Grid item xs={6} md={3}>
            {sample.batchID > 0 ? (
              <Grid direction="row" container style={{ width: '80px' }}>
                <Grid item xs={8}>
                  <FileLink
                    custID={sample.machine.plant.customer.custID}
                    fileName={`${sample.labID}.pdf`}
                  >
                    {sample.labID}
                  </FileLink>
                </Grid>
                <Grid item xs={4}>
                  <FileLink
                    custID={sample.machine.plant.customer.custID}
                    fileName={`${sample.labID}.pdf`}
                    download={true}
                  >
                    <GetAppIcon fontSize="small" />
                  </FileLink>
                </Grid>
              </Grid>
            ) : (
              sample.labID
            )}
          </Grid>
          <Grid item xs={6} md={3}>
            Report Type:
          </Grid>
          <Grid item xs={6} md={3}>
            {sample.reportType !== null
              ? reportTypes.find(y => y.value === sample.reportType).text
              : ''}
          </Grid>

          <Grid item xs={6} md={3}>
            Problems:
          </Grid>
          <Grid item xs={6} md={9}>
            {sample.problems}
          </Grid>

          <Grid item xs={6} md={3}>
            Recommendations:
          </Grid>
          <Grid item xs={6} md={9}>
            {sample.recommendations}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
