import React from 'react'
import { Card, CardContent, Grid } from '@material-ui/core'

interface Props {
  item: any
}

const RenderTotal = ({ label, total }: { label: string; total: number }) => (
  <Grid container direction="column" spacing={1} xs={6} sm={3}>
    <Grid item xs={12} md={12} style={{ maxWidth: '50%' }}>
      {label}
    </Grid>
    <Grid item xs={12} md={12}>
      {total}
    </Grid>
  </Grid>
)

const MobileCardTotals: React.FC<Props> = ({ item }: Props) => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container direction="row" spacing={1}>
          <RenderTotal label="Total Samples" total={item.totalSamples} />
          <RenderTotal
            label="Total Alarmed Samples"
            total={item.totalAlarmedSamples}
          />
          <RenderTotal
            label="Total Alarmed Machines"
            total={item.totalAlarmedMachines}
          />
          <RenderTotal label="Ferrous Wear" total={item.ferrousWear} />
          <RenderTotal label="Wear Metals" total={item.wearMetals} />
          <RenderTotal label="Particle Count" total={item.particleCount} />
          <RenderTotal label="Water" total={item.water} />
          <RenderTotal label="Viscosity" total={item.viscosity} />
          <RenderTotal label="Degradation" total={item.degradation} />
          <RenderTotal label="Other" total={item.other} />
        </Grid>
      </CardContent>
    </Card>
  )
}

export default MobileCardTotals
