import React, { useEffect, useRef, useState } from 'react'
import { Grid, Modal, TextField } from '@material-ui/core'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CancelIcon from '@material-ui/icons/Cancel'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { FormField } from 'components/FormField'
import { PaymentStatusEnum } from 'components/Financials/StripeJS/InvoiceList'
import APIService from 'services/api-service'
import {
  TBillingCustomer,
  TBillingInformation,
  TContactAddress,
  TCreditCardPaymentDetail,
  TPlantQuery,
  TPurchaseOrder,
  TSupplyOrderFree,
  TSupplyOrderItem,
} from 'types/api'
import { sendErrorMessageToSentry } from '../../Providers/SentryProvider'
import SuppliesList from 'components/Financials/StripeJS/SuppliesList'
import { useProfile } from 'Contexts/ProfileContext'
import styled from '@emotion/styled'
import { Button, createTheme } from '@mui/material'
import CreatePurchaseOrderModal from 'components/CreatePurchaseOrderModal'
import { emailRegex } from 'utils/common-regex'
import { CreditCardPaymentScreen } from './CreditCardPaymentScreen'
import { MissingFinancialsErrorScreen } from 'components/Financials/StripeJS/SuppliesList/MissingFinancialsErrorScreen'
import { LoadingErrorScreen } from 'components/Financials/StripeJS/SuppliesList/LoadingErrorScreen'
import { FetchError } from 'utils/Errors/FetchError'
import { breakDownPricing } from 'utils/products'

const poOrderSuccessTitle = 'Order submitted successfully!'
const credCardSuccessTitle = 'Order submitted successfully!'

const poOrderSuccessBody =
  'The purchase order has been submitted and it has been allocated a status of "pending." Please allow a full business day for our accounting team to verify the information provided. If there are issues with the submitted purchase order, you will be contacted by a representative via the information provided.'

const creditCardSuccessBody =
  'Order submission successful! Your order has been submitted and will be shipped the next business day. If there are any issues with the submitted payment or order, you will be contacted by a representative via the information provided.'

interface TProps {
  modalOpen: boolean
  supplies: TSupplyOrderItem[]
  onModalClose: (paymentProcessed: boolean) => void
  onOrderResponse: (orderID: number) => void
  onPaymentDone: () => void
  // billingCustomer: TBillingCustomer
  supplyOrder: TSupplyOrderFree
}

export default function SuppliesPaymentModal({
  modalOpen,
  supplies,
  onModalClose,
  // billingCustomer,
  onPaymentDone,
  supplyOrder,
  onOrderResponse,
}: TProps) {
  const [statusPayment, setStatusPayment] = React.useState<PaymentStatusEnum>(
    PaymentStatusEnum.Unpaid
  )
  const [orderIDResponse, setOrderIDResponse] = useState<number | null>(null)
  const [modalSubmitting, setModalSubmitting] = React.useState(false)
  const [modalError, setModalError] = React.useState(null)
  const [billingInformationMissing, setBillingInformationMissing] =
    useState(false)
  const [loadError, setLoadError] = useState<FetchError>()

  const [paymentProcessed, setPaymentProcessed] = React.useState(false)
  const [paymentMode, setPaymentMode] = useState<'po' | 'cc'>('cc')
  const suppliesRef = useRef(null)

  function getModalStyle() {
    const top = 50
    const left = 50
    const display = 'flex'

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    }
  }

  const useStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: '80vw',
      overflowY: 'auto',
      maxHeight: '80%',
      backgroundColor: '#fff',
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '25px',
    },
  }))

  const modalClasses = useStyles()
  const [modalStyle] = React.useState(getModalStyle)

  const beforeUnloadListener = event => {
    event.preventDefault()
    return (event.returnValue = '')
  }

  useEffect(() => {
    if (modalSubmitting) {
      addEventListener('beforeunload', beforeUnloadListener, { capture: true })
    }

    return () => {
      removeEventListener('beforeunload', beforeUnloadListener, {
        capture: true,
      })
    }
  }, [modalSubmitting])

  useEffect(() => {
    if (modalSubmitting) {
      if (statusPayment !== PaymentStatusEnum.Unpaid) {
        setModalSubmitting(false)
      }
    }
  }, [statusPayment])

  const handleModalClose = () => {
    if (modalSubmitting) return

    setLoadError(undefined)
    setBillingInformationMissing(false)
    resetPaymentStatusWithError()
    setModalError(null)
    setModalSubmitting(false)
    onModalClose(paymentProcessed)
  }

  const handleStripePayError = (message: string) => {
    setModalError(message)
  }

  const resetPaymentStatusWithError = () => {
    setStatusPayment(PaymentStatusEnum.Unpaid)
  }

  const handleCreditCardPayment = async (
    paymentMethodID: string,
    billingAddress: TContactAddress,
    financialID: string,
    billingEmail: string
  ) => {
    setModalSubmitting(true)
    resetPaymentStatusWithError()

    try {
      const creditCardPaymentDetails: TCreditCardPaymentDetail = {
        paymentMethodID: paymentMethodID,
        financialID: financialID,
        billingAddress,
        billingEmail,
      }
      const orderResponse = await APIService.orderSuppliesWithCreditCard({
        ...supplyOrder,
        ...creditCardPaymentDetails,
      })
      onOrderResponse(orderResponse?.orderID)
      setOrderIDResponse(orderResponse?.orderID)
      setStatusPayment(PaymentStatusEnum.Done)
      setPaymentProcessed(true)
      onPaymentDone()
    } catch (err) {
      setModalError(err?.message ?? err)
      setPaymentProcessed(false)
      setModalSubmitting(false)
      setStatusPayment(PaymentStatusEnum.Error)

      sendErrorMessageToSentry(
        `Unexpected error to process payments for supplies ${JSON.stringify(
          supplies
        )}\nError: ${JSON.stringify(err)}`
      )
    }
  }

  const handlePurchaseOrderPayment = async (purchaseOrder: TPurchaseOrder) => {
    setModalSubmitting(true)
    resetPaymentStatusWithError()
    try {
      const orderResponse = await APIService.orderSuppliesWithPurchaseOrder({
        ...supplyOrder,
        ...purchaseOrder,
      })

      setOrderIDResponse(orderResponse?.orderID)
      onOrderResponse(orderResponse?.orderID)
      setStatusPayment(PaymentStatusEnum.Done)
      setPaymentProcessed(true)
      onPaymentDone()
    } catch (err) {
      setModalError(err?.message ?? err)
      setPaymentProcessed(false)
      setModalSubmitting(false)
      setStatusPayment(PaymentStatusEnum.Error)

      sendErrorMessageToSentry(
        `Unexpected error to process payments for supplies ${JSON.stringify(
          supplies
        )}\nError: ${JSON.stringify(err)}`
      )
    }
  }

  const suppliesTotal = supplies
    .map(supply => {
      const pricingBreakdown = breakDownPricing(
        supply.productPricing,
        supply.quantity
      )
      return (
        pricingBreakdown.paidPricing.price *
        pricingBreakdown.paidPricing.quantity
      )
    })
    .reduce((prev, curr) => prev + curr, 0)

  const paymentErrorPage = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <HighlightOffIcon
        style={{ color: 'rgb(225, 25, 25)', fontSize: '350px' }}
      />
      <p>Something went wrong!</p>
      <p>
        {!!modalError
          ? modalError
          : 'Please try again later, contact customer service, or try another payment method to proceed.'}
      </p>
      <Button
        variant="contained"
        component="label"
        color="primary"
        style={{ height: '50px', width: '300px', marginBottom: '50px' }}
        onClick={handleModalClose}
      >
        Close
      </Button>
    </div>
  )

  const successPage = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <h3>Order Placed!</h3>
      <h4>Your Order ID: {orderIDResponse}</h4>
      <CheckCircleOutlineIcon
        style={{ color: 'rgb(25, 225, 25)', fontSize: '350px' }}
      />
      <p style={{ fontWeight: 'bold' }}>
        {paymentMode === 'cc' ? credCardSuccessTitle : poOrderSuccessTitle}
      </p>
      <Button
        variant="contained"
        component="label"
        color="primary"
        style={{ height: '50px', width: '300px', marginBottom: '50px' }}
        onClick={handleModalClose}
      >
        Close
      </Button>
      <p style={{ width: 'clamp(306px, 41%, 100%)', textAlign: 'justify' }}>
        {paymentMode === 'cc' ? creditCardSuccessBody : poOrderSuccessBody}
      </p>
    </div>
  )

  const handlePayWithButtonClick = () => {
    setPaymentMode(paymentMode === 'cc' ? 'po' : 'cc')
  }

  const getPaymentModeText = () => {
    return paymentMode === 'cc' ? 'Credit Card' : 'Purchase Order'
  }

  const getOtherPaymentModeText = () => {
    return paymentMode === 'cc' ? 'Purchase Order' : 'Credit Card'
  }

  const paymentPage = (
    <>
      <Grid container xs={12}>
        <Grid container alignItems="center" justify="flex-start" xs={10}>
          <h2>Supply Order Payment - {getPaymentModeText()}</h2>
        </Grid>
        <Grid container direction="row" xs={2}>
          <Grid container alignItems="center" justify="flex-end">
            {modalSubmitting ? (
              <CancelIcon
                style={{ width: '42px', cursor: 'pointer' }}
                color="disabled"
              />
            ) : (
              <CancelIcon
                style={{ width: '42px', cursor: 'pointer' }}
                onClick={event => handleModalClose()}
              />
            )}
          </Grid>
        </Grid>
        <Grid item container style={{ marginBottom: '15px' }}>
          <StyledTextButton
            aria-label="Switch payment method."
            onClick={handlePayWithButtonClick}
          >
            Pay {`with ${getOtherPaymentModeText()} instead.`.toLowerCase()}
          </StyledTextButton>
        </Grid>
      </Grid>
      {!!modalError && (
        <Grid container justifyContent="center" alignItems="center">
          <Alert severity="error" style={{ width: '100%' }}>
            {modalError}
          </Alert>
        </Grid>
      )}

      <Grid
        container
        spacing={3}
        justifyContent={paymentMode == 'po' ? 'center' : undefined}
      >
        <Grid
          xs={12}
          md={6}
          container
          item
          justifyContent="center"
          alignItems="flex-start"
          style={{ marginBottom: '35px' }}
        >
          <SuppliesList ref={suppliesRef} supplies={supplies} />
        </Grid>
        <Grid
          xs={12}
          md={paymentMode == 'po' ? 12 : 6}
          container
          item
          justifyContent="center"
        >
          {paymentMode == 'po' ? (
            <CreatePurchaseOrderModal
              canChooseDates={false}
              handlePurchaseOrderSubmitted={handlePurchaseOrderPayment}
              handleModalClose={handleModalClose}
              fixedAmount={suppliesTotal}
              onLoadError={(err: FetchError) => setLoadError(err)}
              onMissingBillingInformation={() =>
                setBillingInformationMissing(true)
              }
            />
          ) : (
            <CreditCardPaymentScreen
              closeModal={handleModalClose}
              handleCreditCardPayment={handleCreditCardPayment}
              handleCreditCardPaymentError={handleStripePayError}
              modalSubmitting={modalSubmitting}
              paymentStatus={statusPayment}
              resetPaymentStatusWithError={resetPaymentStatusWithError}
              suppliesTotal={suppliesTotal}
              onLoadError={(err: FetchError) => setLoadError(err)}
              onMissingBillingInformation={() =>
                setBillingInformationMissing(true)
              }
            />
          )}
        </Grid>
      </Grid>
    </>
  )

  return (
    <>
      <Modal open={modalOpen} onClose={handleModalClose}>
        <Grid
          container
          style={modalStyle}
          className={modalClasses.paper}
          justifyContent="center"
        >
          {loadError ? (
            <LoadingErrorScreen onClose={handleModalClose} error={loadError} />
          ) : billingInformationMissing ? (
            <MissingFinancialsErrorScreen onClose={handleModalClose} />
          ) : statusPayment === PaymentStatusEnum.Unpaid ? (
            paymentPage
          ) : statusPayment === PaymentStatusEnum.Error ? (
            paymentErrorPage
          ) : (
            successPage
          )}
        </Grid>
      </Modal>
    </>
  )
}

const PayWithButton = styled(Button)`
  width: 50%;
`
const StyledTextButton = styled('div')`
  cursor: pointer;
  margin: 10px 0 0 0;
  border: thin solid rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 5px 20px 0 20px;
`
