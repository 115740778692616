import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Box, Checkbox, Hidden, TablePagination } from '@material-ui/core'
import {
  GridColumn as Column,
  GridExpandChangeEvent,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'

import TableGrid from 'components/TableGrid'

import {
  RenderCustomerReviewed,
  RenderDate,
  RenderLabNo,
  RenderLubeCondition,
  RenderMachineCondition,
  RenderMachineName,
  RenderMachineType,
  RenderReportType,
  RenderRoute,
} from '../KendoUIGridRenders'

import DetailReportRow from './DetailReportRow'
import { ReportCardRow } from './ReportCardRow'
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
} from '@material-ui/icons'
import { ResettableCheckbox } from 'components/ResettableCheckbox'

export type TProps = {
  offline?: boolean
  readOnly: boolean
  selectAllSamples: any
  samples: any
  machineTypeOptions: any[]
  selectedSamples: any
  correctiveActions: any[]
  orderConfig: any[]
  page: number
  pageSize: number
  count: number
  showCorrectiveActions: boolean
  onFeedbackOpen: (_: any) => void
  onSelectAllSampleChange: (_: any) => void
  onSelectedSampleChange: (_: any) => void
  onCorrectiveActionSave: (_sample: any, _data: any) => void
  onCorrectiveActionDelete: (_sample: any, _data: any) => void
  onPageChange?: (_event: GridPageChangeEvent) => void
  onSortChange?: (_event: GridSortChangeEvent) => void
  onPageChangeMobile: (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    _page: number
  ) => void
  onPageSizeChangeMobile?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >
}
export const OilAnalysisReportTable = React.forwardRef<TCheckboxCollectionRef>(
  (
    {
      offline,
      readOnly,
      samples,
      machineTypeOptions,
      correctiveActions,
      orderConfig,
      page,
      pageSize,
      count,
      showCorrectiveActions,
      onFeedbackOpen,
      onCorrectiveActionSave,
      onCorrectiveActionDelete,
      onPageChange,
      onSortChange,
      onPageChangeMobile,
      onPageSizeChangeMobile,
    }: TProps,
    ref
  ) => {
    const [samplesCopy, setSamplesCopy] = useState(samples)

    useEffect(() => {
      samples = samples.map(sample => {
        sample.expanded = showCorrectiveActions
        return sample
      })
      setSamplesCopy(samples)
    }, [samples, showCorrectiveActions])

    const showDivisions = samples.some(s => s.machine.plant.customer.division)

    const onExpandChange = (event: GridExpandChangeEvent) => {
      const newSamples = samplesCopy.map(sample => {
        if (sample.sampleNo === event.dataItem.sampleNo)
          sample.expanded = event.value

        return sample
      })
      setSamplesCopy(newSamples)
    }

    const checkboxesRef = useRef<Map<number, TSettableCheckbox>>(new Map())
    const selectAllCheckboxesRef = useRef<TSettableCheckbox>()
    useImperativeHandle(ref, () => ({
      allSelected: selectAllCheckboxesRef.current?.checked ?? false,
      selectAllCheckbox: selectAllCheckboxesRef.current,
      checkboxes: checkboxesRef.current,
    }))

    const renderCheckbox = props => {
      return (
        <td>
          <ResettableCheckbox
            ref={el => {
              if (!el) return

              checkboxesRef.current?.set(props.dataItem.labID, el)
            }}
          />
        </td>
      )
    }

    function onSelectAllSampleChange(checked: boolean) {
      checkboxesRef.current.forEach(cb => {
        cb?.set(checked)
      })
    }

    const renderCheckboxHeader = _props => {
      const checkboxID = 'select-all_view-your-reports_checkbox'
      const iconID = 'select-all_view-your-reports_checkbox_icon'
      return (
        <ResettableCheckbox
          id={checkboxID}
          inputProps={{ id: checkboxID }}
          onChange={(e, c) => onSelectAllSampleChange(c)}
          ref={el => (selectAllCheckboxesRef.current = el)}
          icon={<CheckBoxOutlineBlank id={iconID} />}
          checkedIcon={<CheckBoxIcon id={iconID} />}
        />
      )
    }

    return (
      <>
        {offline && (
          <Box mb={2}>Your last 100 reports are available while offline.</Box>
        )}

        <Hidden lgUp>
          {!samples?.length && (
            <p style={{ textAlign: 'center' }}>No records available</p>
          )}
          {(() => {
            checkboxesRef.current.clear()

            return samples.map(sample => (
              <ReportCardRow
                ref={el => {
                  if (!el) return
                  checkboxesRef.current?.set(sample.labID, el)
                }}
                key={sample.labID}
                sample={sample}
                correctiveActions={correctiveActions}
                onFeedbackOpen={onFeedbackOpen}
                onCorrectiveActionDelete={onCorrectiveActionDelete}
                onCorrectiveActionSave={onCorrectiveActionSave}
                readOnly={readOnly}
                machineTypeOptions={machineTypeOptions}
                showDivisions={showDivisions}
              />
            ))
          })()}

          {!offline ? (
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 100, 250, 500]}
              component="div"
              count={count}
              rowsPerPage={pageSize}
              page={page > 0 ? page - 1 : page}
              onChangePage={onPageChangeMobile}
              onChangeRowsPerPage={onPageSizeChangeMobile}
            />
          ) : (
            ''
          )}
        </Hidden>

        <Hidden smDown>
          {(() => {
            checkboxesRef.current.clear()

            return (
              <TableGrid
                clearSortingButtonId="oil_analisys_report-clear_sorting_button"
                offline={offline}
                data={samplesCopy}
                sort={orderConfig}
                skip={(page - 1) * pageSize}
                take={pageSize}
                total={count}
                pageSize={pageSize}
                dataItemKey="labID"
                onPageChange={onPageChange}
                onSortChange={onSortChange}
                detail={({ dataItem }) => (
                  <div style={{ overflow: 'auto' }}>
                    <DetailReportRow
                      sample={dataItem}
                      correctiveActions={correctiveActions}
                      onFeedbackOpen={onFeedbackOpen}
                      onCorrectiveActionDelete={onCorrectiveActionDelete}
                      onCorrectiveActionSave={onCorrectiveActionSave}
                      readonly={readOnly}
                    />
                  </div>
                )}
                expandField="expanded"
                onExpandChange={onExpandChange}
              >
                <Column
                  cell={renderCheckbox}
                  headerCell={renderCheckboxHeader}
                  width={80}
                />

                <Column
                  field="reviewed"
                  title="Customer Reviewed"
                  cell={props => <RenderCustomerReviewed {...props} />}
                />
                <Column
                  field="sampleDate"
                  title="Sample Date"
                  cell={props => <RenderDate {...props} />}
                />
                <Column
                  field="reportDate"
                  title="Report Date"
                  cell={props => <RenderDate {...props} />}
                  width={100}
                />
                {showDivisions && (
                  <Column
                    field="machine.plant.customer.division.name"
                    title="Division Name"
                  />
                )}
                <Column
                  field="machine.plant.customer.custName"
                  title="Customer Name"
                />
                <Column field="machine.plant.plantName" title="Plant Name" />
                <Column
                  field="machine.route.routeNo"
                  title="Route"
                  cell={props => <RenderRoute {...props} />}
                />
                <Column field="machine.machNo" title="Mach No." width={60} />
                <Column
                  field="machine.machineName"
                  title="Machine Name"
                  cell={props => <RenderMachineName {...props} />}
                />
                <Column field="machine.custEquID" title="Equipment ID" />
                <Column
                  field="machine.machType"
                  title="Machine Type"
                  cell={props => (
                    <RenderMachineType
                      {...props}
                      machineTypeOptions={machineTypeOptions}
                    />
                  )}
                />
                <Column
                  field="machine.lubricant.lubricantName"
                  title="Lube Type"
                />
                <Column
                  field="labID"
                  title="Lab No."
                  cell={props => <RenderLabNo {...props} />}
                />
                <Column
                  field="reportType"
                  title="Report Type"
                  cell={props => <RenderReportType {...props} />}
                  width={80}
                />

                <Column
                  field="machCond"
                  title="Mach"
                  cell={props => <RenderMachineCondition {...props} />}
                  width={60}
                />
                <Column
                  field="lubCond"
                  title="Lube"
                  cell={props => <RenderLubeCondition {...props} />}
                  width={60}
                />
              </TableGrid>
            )
          })()}
        </Hidden>
      </>
    )
  }
)
