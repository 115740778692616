import React from 'react'
import { Grid } from '@material-ui/core'

import MobileCardContent from 'components/MobileCardContent'
import MobileCardItem from 'components/MobileCardItem'

import {
  RenderArrayAsStringCommas,
  RenderDate,
  RenderLubeCondition,
  RenderMachineCondition,
  RenderMachineName,
  RenderReportType,
  RenderRoute
} from '../KendoUIGridRenders'
import { BorderAll } from '@material-ui/icons'

interface OwnProps {
  sample: any
}

const MobileCard: React.FC<OwnProps> = ({ sample }: OwnProps) => {
  return (
    <MobileCardContent title={sample.plantName}>
      <Grid container direction="row" spacing={1}>
        <MobileCardItem title="Sample Date">
          <RenderDate dataItem={sample} field="sampleDate"/>
        </MobileCardItem>
        <MobileCardItem title="Received Date">
          <RenderDate dataItem={sample} field="receivedDate" />
        </MobileCardItem>
        <MobileCardItem title="Sample Status">
          {sample.sampleStatus}
        </MobileCardItem>
        <MobileCardItem title="Customer Name">{sample.custName}</MobileCardItem>
        <MobileCardItem title="Plant Name">{sample.plantName}</MobileCardItem>
        <MobileCardItem title="Route">
          <RenderRoute dataItem={sample} field="routeNo" />
        </MobileCardItem>
        <MobileCardItem title="Mach No.">{sample.machNo}</MobileCardItem>
        <MobileCardItem title="Machine Name">
          <RenderMachineName dataItem={sample} field="machName" />
        </MobileCardItem>
        <MobileCardItem title="Equipment ID">{sample.custEquID}</MobileCardItem>
        <MobileCardItem title="Report Type">
          <RenderReportType dataItem={sample} field="reportType" />
        </MobileCardItem>
        <MobileCardItem title="Mach">
          <RenderMachineCondition dataItem={sample} field="machineCondition" />
        </MobileCardItem>
        <MobileCardItem title="Lube">
          <RenderLubeCondition dataItem={sample} field="lubeCondition" />
        </MobileCardItem>
        <MobileCardItem title="Test Package">
          <RenderArrayAsStringCommas
            dataItem={sample}
            field="testPackageList"
          />
        </MobileCardItem>
        <MobileCardItem title="Hold Date">
          <RenderDate dataItem={sample} field="holdDate" />
        </MobileCardItem>
        <MobileCardItem title="Hold Reason">{sample.holdReason}</MobileCardItem>
        <MobileCardItem title="Hold Note">{sample.holdNotes}</MobileCardItem>
      </Grid>
    </MobileCardContent>
  )
}

export default MobileCard
