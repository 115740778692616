import { Button } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { useEffect } from 'react'
import { FetchError } from 'utils/Errors/FetchError'
type Props = {
  error: FetchError
  onClose: () => void
}
export const LoadingErrorScreen = ({ onClose, error }: Props) => {
  const undisclosedError = error.code === 500
  const message = undisclosedError ? error.message : `Something went wrong!`

  const errorMessage = `${error.code ? error.code + ':' : ''} ${error.message}`

  useEffect(() => {
    return () => {
      onClose()
    }
  }, [])
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <HighlightOffIcon
        style={{ color: 'rgb(225, 25, 25)', fontSize: '350px' }}
      />
      <p>{message}</p>
      {!undisclosedError && (
        <p style={{ width: 'clamp(306px, 41%, 100%)', textAlign: 'center' }}>
          {errorMessage}
        </p>
      )}
      <Button
        variant="contained"
        component="label"
        color="primary"
        style={{ height: '50px', width: '300px', marginBottom: '50px' }}
        onClick={onClose}
      >
        Close
      </Button>
    </div>
  )
}
