import { SharedTooltipContext } from '@progress/kendo-react-charts'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import React from 'react'
import { TSpendingEntity, TAccumulatedSpending } from 'types/api'
import { StringFormatter } from 'utils/string-utilities'

//List of POs - Display SO Number, amount, amount spent, and date of expiration.
//need  current date for filtering these?
//use category index to pull data from spending entity passed in. Base this upon spending entity
type TOverallSpendChartTooltipProps = {
  tooltipContext: SharedTooltipContext
  accumulatedSpending: TAccumulatedSpending[]
  spendingData: TSpendingEntity
}

export const OverallSpendChartTooltip: React.FC<TOverallSpendChartTooltipProps> =
  props => {
    const { tooltipContext, accumulatedSpending, spendingData } = props
    const categoryIndex = tooltipContext.points[0].categoryIndex
    const spending = accumulatedSpending[categoryIndex]

    const startDate = spending.dateStart
    const endDate = spending.dateEnd

    const posWithDates = spendingData.purchaseOrders.map(po => ({
      ...po,
      expirationDate: new Date(po.expirationDate),
      created: new Date(po.created),
    }))
    const applicablePOs = posWithDates.filter(
      po => po.expirationDate >= startDate && po.created <= endDate
    )

    const budgetIsDisplayed = tooltipContext.points.some(
      p => p.series.name.toLowerCase() === 'budget'
    )

    const poTableRows = applicablePOs.map(po => ({
      poNumber: po.poNumber,
      amount: StringFormatter.formatAsUSD(po.amount),
      amountSpent: StringFormatter.formatAsUSD(po.spent),
      created: po.created.toLocaleDateString(),
      expirationDate: po.expirationDate.toLocaleDateString(),
    }))

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '20px',
          flexWrap: 'wrap',
        }}
      >
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '20px',
            flexWrap: 'wrap',
          }}
        >
          {tooltipContext.points.map((pointContext, index) => {
            return (
              <div>
                <svg height={'5px'} width={'25px'}>
                  <line
                    x1="0"
                    y1="0"
                    x2="25"
                    y2="0"
                    style={{
                      //@ts-ignore
                      stroke: pointContext.point.color,
                      strokeWidth: 5,
                      strokeLinecap: 'round',
                    }}
                  />
                </svg>
                {
                  <div style={{ display: 'inline-block', fontSize: '0.9rem' }}>
                    <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                      {
                        //@ts-ignore
                        pointContext.point.series.name
                      }
                    </span>
                    <span>
                      :{' '}
                      {StringFormatter.formatAsUSD(
                        //@ts-ignore
                        pointContext.point.dataItem as number
                      )}
                    </span>
                  </div>
                }
              </div>
            )
          })}
        </div>

        {budgetIsDisplayed && poTableRows && poTableRows.length > 0 && (
          <Grid
            data={poTableRows}
            style={{ maxWidth: '575px', overflowY: 'auto', maxHeight: '400px' }}
          >
            <GridColumn field="poNumber" title="PO Number" width="100px" />
            <GridColumn field="amount" title="Budget" width="100px" />
            <GridColumn field="amountSpent" title="Total Spent" width="125px" />
            <GridColumn field="created" title="Created Date" width="100px" />
            <GridColumn
              field="expirationDate"
              title="Expiration Date"
              width="125px"
            />
          </Grid>
        )}
      </div>
    )
  }
