import React from 'react'
import * as Styles from '../page-styles.module.css'
import { ToastContainer } from 'react-toastify'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import coreTheme from 'components/theme'
import { useIsLoggedIn } from 'hooks/useIsLoggedIn'
import AppLayout from 'components/AppLayout'
import CssBaseline from '@material-ui/core/CssBaseline'

type Props = {
  children?: React.ReactNode
}
export const PageCard = React.forwardRef(
  (props: Props, ref: React.Ref<HTMLDivElement>) => {
    const { loggedIn: isLoggedIn, loading: isLoggedInLoading } = useIsLoggedIn()

    const classes = `${Styles.PageCard} ${isLoggedIn ? Styles.LoggedIn : ''}`
    return (
      <div className={classes} ref={ref}>
        {props.children}
      </div>
    )
  }
)

export const PageBody = (props: Props) => {
  const { loggedIn: isLoggedIn, loading: isLoggedInLoading } = useIsLoggedIn()
  const classes = `${Styles.PageContainer}`

  const theme = createTheme(
    {
      overrides: {
        MuiFormLabel: {
          root: {
            color: '#222',
            zIndex: 99,
            pointerEvents: 'none',
          },
        },
        MuiCheckbox: {
          root: {
            color: '#222',
          },
        },
      },
    },
    coreTheme
  )

  const bodyBase = (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes}>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          containerId={'sample-registration'}
          enableMultiContainer
        />
        {props.children}
      </div>
    </ThemeProvider>
  )

  if (!isLoggedInLoading && isLoggedIn) {
    return <AppLayout tab="sample">{bodyBase}</AppLayout>
  }

  return bodyBase
}

export const PageHeader = (props: Props) => {
  return <div className={Styles.PageHeader}>{props.children}</div>
}
