import { Card, CardContent } from '@material-ui/core'
import { InformationIcon } from 'components/InformationIcon'
import { useThemeContext } from 'Contexts/ThemeContext'
import { CSSProperties } from 'react'

type TNoDataCardProps = {
  style?: CSSProperties
}
export const NoDataCard = (props: TNoDataCardProps) => {
  const classes = useThemeContext()

  const text = 'No Data Available'
  return (
    <Card
      variant="outlined"
      className={classes.card}
      style={{
        display: 'inline-flex',
        flexDirection: 'column',
        height: '550px',
        minWidth: '450px',
        maxWidth: '1100px',
        width: '90%',
        ...props.style,
      }}
    >
      <CardContent
        className={classes.cardContent}
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          className={classes.cardHeader}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {text}
          <InformationIcon>
            <>
              <h5>{text}</h5>
              <hr />
              <p>
                This card is a placeholder to prevent unexpected layout shifts.
              </p>
            </>
          </InformationIcon>
        </div>

        <div
          style={{
            height: '100%',
            width: '100%',
            padding: '10px',
            flex: 90,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <strong>{text}</strong>
        </div>
      </CardContent>
    </Card>
  )
}
