import React from 'react'
import { Box, Card, CardContent, Grid, Link } from '@material-ui/core'

import ConditionElement from 'components/ConditionElement'
import { GatsbyMaterialLink } from 'components/GatsbyMaterialLink'
import styles from 'components/MachineCard.module.css'
import {
  lubeConditionKeys,
  machineConditionKeys,
} from 'services/lookup-service'

export type TSampleSnapshot = {
  plantName: string
  plantID: string | number
  samples: number
  machineNormal: number
  machineMarginal: number
  machineCritical: number
  lubeNormal: number
  lubeMarginal: number
  lubeCritical: number
}
export type TProps = {
  sampleSnapShot: TSampleSnapshot
  classes: { [style: string]: string }
  startDateParam: string
  startDate: any
  endDateParam: string
  cardColumns: any
}
function MachineCard({
  sampleSnapShot,
  classes,
  startDateParam,
  startDate,
  endDateParam,
  cardColumns,
}: TProps) {
  /**
   * Return the proper summary page link for sample statuses.
   * @param plantID
   * @param startDate
   * @param endDate
   * @param machineCondition
   * @param lubeCondition
   * @returns
   */
  const getSummaryLink = (
    plantID: number | string,
    startDate: string,
    endDate: string,
    machineCondition?: typeof machineConditionKeys | number | string,
    lubeCondition?: typeof lubeConditionKeys | number | string
  ) => {
    return `/reports/summary-report?plantID=${
      sampleSnapShot.plantID
    }&startDate=${startDateParam}&endDate=${endDateParam}&machineCondition=${
      machineCondition || ''
    }&lubeCondition=${lubeCondition || ''}`
  }

  return (
    <Grid item xs={cardColumns} className={styles.root}>
      <Card
        variant="outlined"
        className={classes.card}
        style={{ backgroundColor: '#f2f4f5', minHeight: '330px' }}
      >
        <CardContent
          className={classes.cardContent}
          style={{ padding: '15px' }}
        >
          <Grid container direction="row">
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <Grid item xs={10} sm={10} md={10} lg={10}>
                  <p className={styles.plantName}>
                    {sampleSnapShot.plantName} Samples
                  </p>
                  <Box>
                    {new Intl.DateTimeFormat('en-US', {
                      month: 'long',
                      year: 'numeric',
                    }).format(startDate.toDate())}
                  </Box>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <Link
                    component={GatsbyMaterialLink}
                    to={getSummaryLink(
                      sampleSnapShot.plantID,
                      startDateParam,
                      endDateParam
                    )}
                    style={{
                      display: 'block',
                      textAlign: 'right',
                      fontSize: '35px',
                      fontWeight: 600,
                    }}
                  >
                    {sampleSnapShot.samples}
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ marginTop: '15px' }}
            >
              {sampleSnapShot !== null ? (
                <>
                  <Box
                    style={{
                      textAlign: 'center',
                      fontSize: '16px',
                      fontWeight: 600,
                      marginBottom: '15px',
                    }}
                  >
                    Machine Condition
                  </Box>
                  <Box
                    style={{
                      backgroundColor: '#f2f4f5',
                      paddingLeft: '25px',
                      paddingRight: '25px',
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justify="center"
                      spacing={1}
                    >
                      <Grid item xs>
                        <ConditionElement
                          condition={machineConditionKeys.normal}
                        />
                        <Link
                          component={GatsbyMaterialLink}
                          to={getSummaryLink(
                            sampleSnapShot.plantID,
                            startDateParam,
                            endDateParam,
                            machineConditionKeys.normal
                          )}
                          style={{
                            display: 'block',
                            fontSize: '16px',
                            textAlign: 'center',
                          }}
                        >
                          {sampleSnapShot.machineNormal}
                        </Link>
                      </Grid>
                      <Grid item xs>
                        <ConditionElement
                          condition={machineConditionKeys.marginal}
                        />
                        <Link
                          component={GatsbyMaterialLink}
                          to={getSummaryLink(
                            sampleSnapShot.plantID,
                            startDateParam,
                            endDateParam,
                            machineConditionKeys.marginal
                          )}
                          style={{
                            display: 'block',
                            fontSize: '16px',
                            textAlign: 'center',
                          }}
                        >
                          {sampleSnapShot.machineMarginal}
                        </Link>
                      </Grid>
                      <Grid item xs>
                        <ConditionElement
                          condition={machineConditionKeys.critical}
                        />
                        <Link
                          component={GatsbyMaterialLink}
                          to={getSummaryLink(
                            sampleSnapShot.plantID,
                            startDateParam,
                            endDateParam,
                            machineConditionKeys.critical
                          )}
                          style={{
                            display: 'block',
                            fontSize: '16px',
                            textAlign: 'center',
                          }}
                        >
                          {sampleSnapShot.machineCritical}
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ) : (
                ''
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ marginTop: '15px', marginBottom: '15px' }}
            >
              {sampleSnapShot !== null ? (
                <>
                  <Box
                    style={{
                      textAlign: 'center',
                      fontSize: '16px',
                      fontWeight: 600,
                      marginBottom: '15px',
                    }}
                  >
                    Lubricant Condition
                  </Box>
                  <Box
                    style={{
                      backgroundColor: '#f2f4f5',
                      paddingLeft: '25px',
                      paddingRight: '25px',
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justify="center"
                      spacing={1}
                    >
                      <Grid item xs>
                        <ConditionElement
                          condition={lubeConditionKeys.normal}
                        />
                        <Link
                          component={GatsbyMaterialLink}
                          to={getSummaryLink(
                            sampleSnapShot.plantID,
                            startDateParam,
                            endDateParam,
                            undefined,
                            lubeConditionKeys.normal
                          )}
                          style={{
                            display: 'block',
                            fontSize: '16px',
                            textAlign: 'center',
                          }}
                        >
                          {sampleSnapShot.lubeNormal}
                        </Link>
                      </Grid>
                      <Grid item xs>
                        <ConditionElement
                          condition={lubeConditionKeys.marginal}
                        />
                        <Link
                          component={GatsbyMaterialLink}
                          to={getSummaryLink(
                            sampleSnapShot.plantID,
                            startDateParam,
                            endDateParam,
                            undefined,
                            lubeConditionKeys.marginal
                          )}
                          style={{
                            display: 'block',
                            fontSize: '16px',
                            textAlign: 'center',
                          }}
                        >
                          {sampleSnapShot.lubeMarginal}
                        </Link>
                      </Grid>
                      <Grid item xs>
                        <ConditionElement
                          condition={lubeConditionKeys.critical}
                        />
                        <Link
                          component={GatsbyMaterialLink}
                          to={getSummaryLink(
                            sampleSnapShot.plantID,
                            startDateParam,
                            endDateParam,
                            undefined,
                            lubeConditionKeys.critical
                          )}
                          style={{
                            display: 'block',
                            fontSize: '16px',
                            textAlign: 'center',
                          }}
                        >
                          {sampleSnapShot.lubeCritical}
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default MachineCard
