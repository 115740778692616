import React, { useMemo, useState } from 'react'
import {
  ErrorFormValue,
  FormValue,
  ToErrorFormValues,
} from 'utils/FormUtilities/FormValue'
import { delay } from 'utils/general-utilities'
import * as PageStyles from '../../../../../page-styles.module.css'
import { Button } from '@material-ui/core'
import { Autocomplete } from '@mui/material'
import TextField from '@mui/material/TextField'

import { FancyTextArea } from 'components/FancyTextArea/FancyTextArea'

import { useIsBoundByWidths } from 'hooks/useIsBoundByWidths'
import { mobileWidthDefinitonMap } from 'pages/sample/registration/Pages/constants'

import { useIsLoggedIn } from 'hooks/useIsLoggedIn'
import APIService from 'services/api-service'
import { useError, useLoading, usePromise } from 'hooks/usePromise'
import { LoadingCardModule } from 'pages/sample/registration/CardModules/LoadingCardModule'
import { ElementDisplay } from 'pages/sample/registration/Pages/information-entry/ElementDisplay'
import {
  FluidType,
  PostMachineTestScheduleDTO,
  TCustomerTestGroupPricingDetails,
  TListType,
} from 'types/api'
import { MobileSingleSelect } from 'components/MobileSingleSelect/MobileSingleSelect'

import Loader from 'components/Loader'

import { months } from 'services/lookup-service'
import { useUserPreferences } from 'Contexts/UserPreferencesContext'
import { StringFormatter } from 'utils/string-utilities'
import { frequencyOptions } from 'pages/sample/registration/constants'

const NO_TESTS_SCHEDULED_TEXT = 'No tests scheduled!'

type TProps = {
  onDelete: (testGroupID: number) => void
  consumedTestGroupIDs: number[]
  organizedCustomerPricing: [number, TCustomerTestGroupPricingDetails][]
  next: (machineInformation: PostMachineTestScheduleDTO) => void
  encryptedBottleIdentifier: string | null
  customerID: number | null
  onClose: () => void
} & Partial<PostMachineTestScheduleDTO>

type TFixedState = {
  formUpdated: boolean
}

type TFreeFormState = ToErrorFormValues<PostMachineTestScheduleDTO>

type TState = TFreeFormState & TFixedState

const debounceTimeMS = 0
export const TestPackageModal = React.forwardRef<HTMLDivElement, TProps>(
  (props: TProps, forwardedRef) => {
    const defaultState: Readonly<TState> = useMemo(
      () => ({
        testGroupID: new ErrorFormValue<number>(
          props.testGroupID ?? null,
          a1 => ErrorFormValue.validateRequired(a1, 'Test package'),
          !!props.testGroupID
        ),
        frequency: new ErrorFormValue<number>(
          props.frequency ?? null,
          a1 => ErrorFormValue.validateRequired(a1, 'Frequency'),
          !!props.frequency
        ),
        startMonth: new ErrorFormValue<number>(
          props.startMonth ?? null,
          a1 => ErrorFormValue.validateRequired(a1, 'Start month'),
          !!props.startMonth
        ),
        pointID: new ErrorFormValue<number>(
          props.pointID ?? null,
          a1 => ErrorFormValue.validateOptional(a1),
          !!props.pointID
        ),
        formUpdated: false,
      }),
      []
    )

    const [state, setState] = useState<TState>({ ...defaultState })
    const isMobile = useIsBoundByWidths(mobileWidthDefinitonMap)['mobile']

    function updateFixedState<
      TKey extends keyof TFixedState,
      TValue extends TFixedState[TKey]
    >(key: TKey, value: TValue) {
      setState(prev => ({ ...prev, [key]: value }))
    }

    function updateFreeFormState<
      TKey extends keyof TFreeFormState,
      TValue extends TFreeFormState[TKey]['Value']
    >(key: TKey, value: TValue, automaticUpdate: boolean = false) {
      const stateValue = state[key]
      stateValue.Value = value

      if (automaticUpdate) {
        stateValue.Modified = false
      }

      setState(prev => ({ ...prev, [key]: stateValue }))
    }

    function updateFreeFormStates<
      TKey extends keyof TFreeFormState,
      TValue extends TFreeFormState[TKey]['Value']
    >(pairs: { key: TKey; value: TValue }[], automaticUpdate: boolean = false) {
      setState(prev => {
        const newState = { ...prev }
        for (const pair of pairs) {
          const key = pair.key

          const stateValue = state[key]
          stateValue.Value = pair.value

          if (automaticUpdate) {
            stateValue.Modified = false
          }

          newState[key] = stateValue
        }

        return newState
      })
    }

    function reset() {
      setState(prev => ({ ...defaultState }))
    }

    async function issueWithData(
      func: (data: PostMachineTestScheduleDTO) => void,
      ignoreErrors = false
    ) {
      await delay(debounceTimeMS)
      FormValue.modifyAllObjects(state)
      if (!ignoreErrors && !FormValue.allObjectParametersAreValid(state)) {
        updateFixedState('formUpdated', !state.formUpdated)
        return
      }

      if (func) {
        if (!!props.onDelete && props.testGroupID) {
          props.onDelete(props.testGroupID)
        }

        const vals =
          FormValue.getFormResultFromObject<PostMachineTestScheduleDTO>(state)
        func(vals)
      }
    }

    const buttons = (
      <div className={PageStyles.ButtonContainer}>
        <Button
          data-cancel
          variant="contained"
          color="primary"
          onClick={() => {
            reset()
            props.onClose()
          }}
          className={`${PageStyles.Button} ${PageStyles.Left}`}
        >
          Close
        </Button>

        <Button
          data-accept
          disabled={!FormValue.allObjectParametersAreValid(state)}
          variant="contained"
          color="secondary"
          onClick={() => issueWithData(props.next)}
          className={`${PageStyles.Button} ${PageStyles.Right}`}
        >
          Complete
        </Button>
      </div>
    )

    const testPackages = useMemo(
      () =>
        props.organizedCustomerPricing
          ? props.organizedCustomerPricing
              .map(p => p[1])
              .filter(
                p =>
                  p.testGroup?.testGroupID &&
                  (!!props.testGroupID ||
                    !props.consumedTestGroupIDs.includes(
                      p.testGroup.testGroupID
                    ))
              )
          : [],
      [props.organizedCustomerPricing]
    )

    const thisTestPackage = useMemo(
      () =>
        state.testGroupID.Value && testPackages && testPackages.length > 0
          ? testPackages.find(
              tp => tp.testGroup?.testGroupID === state.testGroupID.Value
            )
          : null,
      [testPackages, state.testGroupID.Value]
    )

    let page = (
      <div
        ref={forwardedRef}
        tabIndex={-1}
        className={`${PageStyles.PageModal}`}
      >
        <h3 className={PageStyles.SectionTitle}>Schedule Test Package</h3>
        <div className={PageStyles.SplitContainer}>
          {props.organizedCustomerPricing &&
            props.organizedCustomerPricing.length > 0 && (
              <div>
                <section
                  className={`${PageStyles.Details} ${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer} ${PageStyles.Wrapper} ${PageStyles.NoBorders}`}
                >
                  {isMobile ? (
                    <MobileSingleSelect
                      error={
                        state.testGroupID.Modified &&
                        !!state.testGroupID.getError()
                      }
                      helperText={
                        !!state.testGroupID.getError() &&
                        state.testGroupID.Modified &&
                        state.testGroupID.getValidationMessage()
                      }
                      default={
                        testPackages.find(
                          s =>
                            s.testGroup.testGroupID === state.testGroupID?.Value
                        ) ?? null
                      }
                      value={
                        testPackages.find(
                          s =>
                            s.testGroup.testGroupID === state.testGroupID?.Value
                        ) ?? null
                      }
                      required
                      options={testPackages}
                      stringify={opt => opt?.testGroup.testGroup ?? ''}
                      label={'Test Package'}
                      elementificate={opt => (
                        <ElementDisplay>
                          {opt?.testGroup.testGroup ?? ''}
                        </ElementDisplay>
                      )}
                      onChange={opt =>
                        updateFreeFormState(
                          'testGroupID',
                          opt?.testGroup?.testGroupID ?? null,
                          !opt
                        )
                      }
                    />
                  ) : (
                    <Autocomplete
                      defaultValue={
                        testPackages.find(
                          s =>
                            s.testGroup.testGroupID === state.testGroupID?.Value
                        ) ?? null
                      }
                      value={
                        testPackages.find(
                          s =>
                            s.testGroup.testGroupID === state.testGroupID?.Value
                        ) ?? null
                      }
                      options={testPackages}
                      renderInput={params => (
                        <TextField
                          color="primary"
                          {...params}
                          label="Test Package"
                          required
                        />
                      )}
                      getOptionLabel={(p: TCustomerTestGroupPricingDetails) =>
                        `${p?.testGroup.testGroup ?? ''}`
                      }
                      onChange={(ev, opt: TCustomerTestGroupPricingDetails) => {
                        updateFreeFormState(
                          'testGroupID',
                          opt?.testGroup?.testGroupID ?? null
                        )
                      }}
                      className={`${PageStyles.Wrappable}`}
                    />
                  )}
                  {isMobile ? (
                    <MobileSingleSelect
                      error={
                        state.testGroupID.Modified &&
                        !!state.testGroupID.getError()
                      }
                      helperText={
                        !!state.frequency.getError() &&
                        state.frequency.Modified &&
                        state.frequency.getValidationMessage()
                      }
                      default={
                        frequencyOptions.find(
                          s => s.frequency === state.frequency?.Value
                        ) ?? null
                      }
                      value={
                        frequencyOptions.find(
                          s => s.frequency === state.frequency?.Value
                        ) ?? null
                      }
                      required
                      options={frequencyOptions}
                      stringify={opt => opt?.frequencyName}
                      label={'Test Frequency'}
                      elementificate={opt => (
                        <ElementDisplay>{opt?.frequencyName}</ElementDisplay>
                      )}
                      onChange={opt =>
                        updateFreeFormState(
                          'frequency',
                          opt ? opt.frequency : null,
                          !opt
                        )
                      }
                    />
                  ) : (
                    <Autocomplete
                      defaultValue={
                        frequencyOptions.find(
                          s => s.frequency === state.frequency?.Value
                        ) ?? null
                      }
                      value={
                        frequencyOptions.find(
                          s => s.frequency === state.frequency?.Value
                        ) ?? null
                      }
                      options={frequencyOptions}
                      renderInput={params => (
                        <TextField
                          color="primary"
                          {...params}
                          label="Test Frequency"
                          required
                        />
                      )}
                      getOptionLabel={(p: typeof frequencyOptions[number]) =>
                        `${p.frequencyName}`
                      }
                      onChange={(ev, opt: typeof frequencyOptions[number]) => {
                        updateFreeFormState('frequency', opt?.frequency ?? null)
                      }}
                      className={`${PageStyles.Wrappable}`}
                    />
                  )}
                  {isMobile ? (
                    <MobileSingleSelect
                      error={
                        state.startMonth.Modified &&
                        !!state.startMonth.getError()
                      }
                      helperText={
                        !!state.startMonth.getError() &&
                        state.startMonth.Modified &&
                        state.startMonth.getValidationMessage()
                      }
                      default={
                        months.find(s => s.id === state.startMonth?.Value) ??
                        null
                      }
                      value={
                        months.find(s => s.id === state.startMonth?.Value) ??
                        null
                      }
                      required
                      options={[...months]}
                      stringify={opt => opt?.text}
                      label={'Start Month'}
                      elementificate={opt => (
                        <ElementDisplay>{opt?.text}</ElementDisplay>
                      )}
                      onChange={opt =>
                        updateFreeFormState(
                          'startMonth',
                          opt ? opt.id : null,
                          !opt
                        )
                      }
                    />
                  ) : (
                    <Autocomplete
                      defaultValue={
                        months.find(s => s.id === state.startMonth?.Value) ??
                        null
                      }
                      value={
                        months.find(s => s.id === state.startMonth?.Value) ??
                        null
                      }
                      options={[...months]}
                      renderInput={params => (
                        <TextField
                          color="primary"
                          {...params}
                          label="Start Month"
                          required
                        />
                      )}
                      getOptionLabel={(p: typeof months[number]) => `${p.text}`}
                      onChange={(ev, opt: typeof months[number]) => {
                        updateFreeFormState('startMonth', opt ? opt.id : null)
                      }}
                      className={`${PageStyles.Wrappable}`}
                    />
                  )}
                </section>

                <section>
                  <h6 className={PageStyles.DetailSectionHeader}>
                    Test Package Details
                  </h6>
                  {!state.testGroupID.Value && (
                    <span className={PageStyles.Property}>
                      Select a test package to see more information.
                    </span>
                  )}
                  {state.testGroupID.Value && thisTestPackage && (
                    <>
                      <ul
                        className={`${PageStyles.Details} ${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer} ${PageStyles.Wrapper}`}
                        style={{
                          justifyContent: 'flex-start',
                        }}
                      >
                        <li
                          className={`${PageStyles.Wrappable} ${PageStyles.NoExpand}`}
                        >
                          <span className={PageStyles.PropertyLabel}>
                            Test Package
                          </span>
                          <span className={PageStyles.Property}>
                            {thisTestPackage.testGroup.testGroupID}
                            {' - '}
                            {thisTestPackage.testGroup.testGroup}
                          </span>
                        </li>
                        <li
                          className={`${PageStyles.Wrappable} ${PageStyles.NoExpand}`}
                        >
                          <span className={PageStyles.PropertyLabel}>
                            Test Package Price
                          </span>
                          <span className={PageStyles.Property}>
                            {StringFormatter.formatAsCurrency(
                              Number(thisTestPackage.pricePerUnitUSD)
                            )}
                          </span>
                        </li>
                      </ul>
                    </>
                  )}
                </section>
                {state.testGroupID.Value && thisTestPackage && (
                  <>
                    <h6 className={PageStyles.DetailSectionHeader}>
                      Included Tests
                    </h6>
                    <section
                      className={`${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer}`}
                    >
                      <ul className={PageStyles.Details}>
                        {thisTestPackage.testGroup.tests &&
                          thisTestPackage.testGroup.tests.map(test => {
                            return (
                              <li key={test.testID}>
                                <span className={PageStyles.PropertyLabel}>
                                  {test.testDesc} ({test.testCode})
                                </span>
                                <span className={PageStyles.Property}>
                                  {test.detailedDesc}
                                </span>
                              </li>
                            )
                          })}
                      </ul>
                    </section>
                  </>
                )}
              </div>
            )}
          {buttons}
        </div>
      </div>
    )

    return page
  }
)
