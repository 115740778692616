import React from 'react'
import { TextFieldProps } from '@material-ui/core'

import FilterTooltip from '../FilterTooltip/FilterTooltip'

import { TextFieldFilter } from './text-field-filter'

type TProps = {
  tooltip?: boolean
}

export const SerialNumberFilter: React.FC<TextFieldProps> = (
  props: TextFieldProps & TProps
) => {
  const { tooltip = true, ...rest } = props

  const filter = (
    <TextFieldFilter id="machine-name" placeholder="Serial No" {...rest} />
  )

  return tooltip ? (
    <FilterTooltip title="The serial number.">{filter}</FilterTooltip>
  ) : (
    filter
  )
}
