import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from '@microsoft/signalr'
import { ConnectionStatus } from 'types/custom.d'
import LoginService from 'services/login-service'

type ExposedConnection = Omit<
  HubConnection,
  'keepAliveIntervalInMilliseconds' | 'serverTimeoutInMilliseconds' | 'stop'
>

type TUserHubcontext = {
  status: ConnectionStatus
  connection: ExposedConnection | null
}

export const UserHubContext = createContext<TUserHubcontext>(
  {} as TUserHubcontext
)

export const useUserHub = () => {
  return useContext(UserHubContext)
}

export const UserHubContextProvider: React.FC = props => {
  let [connection, setConnection] = useState<null | HubConnection>(null)
  const [connectionStopped, setConnectionStopped] = useState<boolean>(false)
  const [connected, setConnected] = useState(false)
  const [initialized, setInitialized] = useState(false)

  const disconnect = useCallback(() => {
    setConnectionStopped(true)
    if (
      !connection ||
      connection.state === HubConnectionState.Disconnected ||
      connection.state === HubConnectionState.Disconnecting
    )
      return

    connection.stop()
    connection = null
    setConnection(null)
  }, [connection])

  useEffect(() => {
    if (!connection) {
      const accessToken = LoginService.getStoredAccessToken()

      const connect = new HubConnectionBuilder()
        .withUrl(
          `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}${process.env.REACT_APP_DS_EXPERIENCE_API_USER_HUB_ENDPOINT}`,
          { accessTokenFactory: () => accessToken }
        )
        .build()

      setConnection(connect)
    }
    return () => disconnect()
  }, [connection])

  const retryDelayMS = 5000
  let reconnectSocket = useCallback(async () => {
    if (
      connection &&
      connection.state != HubConnectionState.Connected &&
      !connected &&
      !connectionStopped
    ) {
      try {
        await connection.start()
        setConnected(true)
      } catch (err) {
        setConnected(false)
        if (reconnectSocket && !connectionStopped)
          setTimeout(reconnectSocket, retryDelayMS)
      }
    }
  }, [connection, connectionStopped, connected])

  useEffect(() => {
    ;(async () => {
      if (!connection) return

      try {
        await connection?.start()
        setConnected(true)
      } catch (err) {
        setConnected(false)
        reconnectSocket()
      }

      connection?.onreconnected(id => {
        setConnected(true)
      })
      connection?.onclose(err => {
        //NOTE: do not set connected to false here, as it may close for many reasons. Only set to false if it fails the reconnect attempt.
        //Only reconnect if closed via error
        if (err) setTimeout(reconnectSocket, retryDelayMS)
      })

      setInitialized(true)
    })()
  }, [connection])

  const getConnectionStatus = () => {
    return !initialized
      ? ConnectionStatus.pending
      : connected
      ? ConnectionStatus.connected
      : ConnectionStatus.disconnected
  }

  return (
    <UserHubContext.Provider
      value={{
        status: getConnectionStatus(),
        connection,
      }}
    >
      {props.children}
    </UserHubContext.Provider>
  )
}
