import React, { ReactNode } from 'react'
import { Link } from '@material-ui/core'

type TProps = {
  children?: ReactNode
  phone: string
}

const PhoneLinkButton = ({ children, phone }: TProps) => {
  return <Link href={`tel:${phone}`}>{children}</Link>
}

export default PhoneLinkButton
