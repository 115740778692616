import React, { useEffect, useState } from 'react'
import { Button, Grid, Hidden, Link, Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ChatOutlined, EmailOutlined, PhoneOutlined } from '@material-ui/icons'
import {
  groupBy,
  GroupDescriptor,
  GroupResult,
  orderBy,
  SortDescriptor,
} from '@progress/kendo-data-query'
import {
  Grid as KendoGrid,
  GridCell,
  GridCellProps,
  GridColumn as Column,
  GridExpandChangeEvent,
} from '@progress/kendo-react-grid'

import { TAPICustomer, TFinancialAlert, TMonthlySpending } from 'types/api'
import PhoneLinkButton from 'components/buttons/PhoneLinkButton'
import EmailLinkButton from 'components/buttons/EmailLinkButton'
import CleanSortingButton from 'components/CleanSortingButton'
import IssuesTabPicker from './IssuesTabPicker'
import moment from 'moment'
import { setExpandedState, setGroupIds } from '@progress/kendo-react-data-tools'

import { ReportCardRowSalesOrder } from './ReportCardRowSalesOrder'
import { ReportCardRowInvoice } from './ReportCardRowInvoice'

type TModalData = {
  customerName: string
  index: number
  checked: boolean
  plantName: string
  dueDate?: Date
} & TFinancialAlert

interface OwnProps {
  customers: TAPICustomer[]
  financialAlerts: TFinancialAlert[]
  modalOpen: boolean
  message: string
  title: string
  onModalClose: () => void
}

export const getAlertSeverityColor = (severity: 'error' | 'warning') => {
  if (severity === 'error') {
    return '#A80000'
  }

  return '#5C4300'
}

export const FinancialIssuesModal: React.FC<OwnProps> = ({
  customers,
  financialAlerts,
  modalOpen,
  onModalClose,
  message,
  title,
}: OwnProps) => {
  const getModalStyle = () => {
    const top = 50
    const left = 50

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    }
  }

  const useStyles = makeStyles(_theme => ({
    paper: {
      position: 'absolute',
      width: '80vw',
      backgroundColor: '#fff',
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '25px',
    },
    table: {
      '& .k-grid-header .k-header': {
        verticalAlign: 'middle',
        '& .k-link .k-column-title': {
          whiteSpace: 'pre-wrap',
        },
      },
    },
  }))

  const getCustomerName = customerID =>
    customers.find(x => x.custID === customerID)?.custName ?? 'Unknown'

  const getPlantName = plantID =>
    customers.flatMap(c => c.plants).find(p => p.plantID == plantID)
      ?.plantName || 'N/A'

  const processWithGroups = (
    data: any[],
    group: GroupDescriptor[],
    sort: SortDescriptor[]
  ) => {
    const newDataState = groupBy(orderBy(data, sort ?? []), group)

    setGroupIds({
      data: orderBy(newDataState, sort),
      group: group,
    })

    return newDataState
  }
  const [group, setGroup] = React.useState([{ field: 'financialID' }])
  const [collapsedState, setCollapsedState] = React.useState<string[]>([])

  const onExpandChange = React.useCallback(
    (event: GridExpandChangeEvent) => {
      const item = event.dataItem

      if (item.groupId) {
        const newCollapsedIds = !event.value
          ? [...collapsedState, item.groupId]
          : collapsedState.filter(groupId => groupId !== item.groupId)
        setCollapsedState(newCollapsedIds)
      }
    },
    [collapsedState]
  )

  const [state, setState] = useState<TModalData[]>([] as TModalData[])
  const [sortConfigSalesOrder, setSortConfigSalesOrder] = useState<
    Array<SortDescriptor>
  >([])
  const [sortConfigSalesInvoice, setSortConfigSalesInvoice] = useState<
    Array<SortDescriptor>
  >([])
  const [modalStyle] = useState(getModalStyle)

  const modalClasses = useStyles()

  useEffect(() => {
    if (financialAlerts) {
      setState(
        financialAlerts.map((x, i) => ({
          ...x,
          customerName: getCustomerName(x.customerID),
          plantName: getPlantName(x.plantID),
          index: i,
          issueCount: x.salesInvoiceIssues.length + x.salesOrderIssues.length,
          checked: false,
        }))
      )
    }

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financialAlerts])

  const getMailToString = () => {
    const to = process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL
    let subject = 'Financial Alert Resolution'
    let body = ''

    const checkedIssues = state.filter(x => x.checked)

    if (checkedIssues.some(x => x)) {
      body = `Customer Name\tPlant Name\tNumber of Issues\tIssues\n\n${checkedIssues
        .map(
          issue =>
            `${issue.customerName}\t${getPlantName(issue.plantID) ?? '-'}\t${
              issue.salesInvoiceIssues.length + issue.salesOrderIssues.length
            }\t${issue.salesInvoiceIssues
              .map(sii => sii.reason)
              .concat(issue.salesOrderIssues.map(soi => soi.reason))
              .join('/')}\n\n`
        )
        .join('')}`

      subject += ` - Customer(s): ${[
        ...new Set(checkedIssues.map(x => x.customerName)),
      ]}`
    }

    return `mailto:${to}?subject=${encodeURI(subject)}&body=${encodeURI(body)}`
  }

  const onDismissAllSorting = () => {
    setSortConfigSalesOrder([] as SortDescriptor[])
    setSortConfigSalesInvoice([] as SortDescriptor[])
  }

  const clearSortingButton = (
    <Hidden smDown>
      <CleanSortingButton onDismissAllSorting={onDismissAllSorting} id="financial_issues_modal-clear_sorting_button" />
    </Hidden>
  )

  const [issuesTab, setIssuesTab] = useState<'SalesInvoice' | 'SalesOrder'>(
    'SalesOrder'
  )

  const onTabclick = (value: 'SalesInvoice' | 'SalesOrder') => {
    setIssuesTab(value)
  }

  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  const [salesOrderResultState, setSalesOrderResultState] =
    React.useState<GroupResult[]>()

  const [
    salesOrderIssuesWithParentValuesState,
    setSalesOrderIssuesWithParentValuesState,
  ] = React.useState<[]>()

  useEffect(() => {
    const salesOrderIssuesWithParentValues = state.flatMap(s =>
      s.salesOrderIssues.map(soi => ({
        ...soi,
        customerName: s.customerName,
        financialID: s.financialID,
        plantName: s.plantName,
        reason: soi.reason ?? '',
        dueDate: soi.dueDate
          ? moment(soi.dueDate).format('MM/DD/yyyy')
          : 'Unknown',
        availableBalance: moneyFormatter.format(soi.availableBalance ?? 0),
        nextMonthProjectedSpending: moneyFormatter.format(
          (soi.nextMonthProjectedSpending as TMonthlySpending)?.amountSpent ?? 0
        ),
      }))
    )

    const data = processWithGroups(
      salesOrderIssuesWithParentValues,
      group,
      sortConfigSalesOrder
    )

    const newData = setExpandedState({
      data: data,
      collapsedIds: collapsedState,
    })

    setSalesOrderResultState(newData)
    setSalesOrderIssuesWithParentValuesState(salesOrderIssuesWithParentValues)
  }, [collapsedState, state, sortConfigSalesOrder])

  const salesOrderIssuesGrid = () => {
    return (
      <div>
        <Hidden mdUp>
          {!salesOrderIssuesWithParentValuesState?.length && (
            <p style={{ textAlign: 'center' }}>No records available</p>
          )}
          <div style={{ overflow: 'scroll', height: '30vh' }}>
            {salesOrderIssuesWithParentValuesState?.map(issue => (
              <ReportCardRowSalesOrder key={issue.number} issue={issue} />
            ))}
          </div>
        </Hidden>

        <Hidden smDown>
          <KendoGrid
            style={{ height: '350px' }}
            className={modalClasses.table}
            sortable={true}
            group={group}
            onExpandChange={onExpandChange}
            expandField="expanded"
            data={salesOrderResultState}
            sort={sortConfigSalesOrder}
            onSortChange={e => {
              setSortConfigSalesOrder(e.sort)
            }}
          >
            <Column field="financialID" title="Billing Contact ID" />
            <Column field="number" title="Sales Order ID" />
            <Column field="customerName" title="Customer Name" />
            <Column field="plantName" title="Plant Name" />
            <Column field="dueDate" title="Expiration Date" />
            <Column field="availableBalance" title="Available Balance" />
            <Column
              field="nextMonthProjectedSpending"
              title="Upcoming Spending"
            />
            <Column
              field="reason"
              title="Alert"
              cell={(p: GridCellProps) => (
                <GridCell
                  {...p}
                  style={{
                    // WebkitTextStrokeColor: '#000',
                    // WebkitTextStrokeWidth: '0.3px',
                    color: getAlertSeverityColor(
                      p.dataItem.issue === 'OverBudget' ||
                        p.dataItem.issue === 'PastDue'
                        ? 'error'
                        : 'warning'
                    ),
                  }}
                />
              )}
            />
          </KendoGrid>
        </Hidden>
      </div>
    )
  }

  const [salesInvoiceResultState, setSalesInvoiceResultState] =
    React.useState<GroupResult[]>()

  const [
    salesInvoiceIssuesWithParentValuesState,
    setSalesInvoiceIssuesWithParentValuesState,
  ] = React.useState<[]>()

  useEffect(() => {
    const salesInvoiceIssuesWithParentValues = state.flatMap(s =>
      s.salesInvoiceIssues.map(sii => ({
        ...sii,
        customerName: s.customerName,
        financialID: s.financialID,
        plantName: s.plantName,
        alertColor: '',
        dueDate: sii.dueDate
          ? moment(sii.dueDate).format('MM/DD/yyyy')
          : 'Unknown',
      }))
    )

    const data = processWithGroups(
      salesInvoiceIssuesWithParentValues,
      group,
      sortConfigSalesInvoice
    )

    const newData = setExpandedState({
      data,
      collapsedIds: collapsedState,
    })

    setSalesInvoiceResultState(newData)
    setSalesInvoiceIssuesWithParentValuesState(
      salesInvoiceIssuesWithParentValues
    )
  }, [collapsedState, state, sortConfigSalesInvoice])

  const salesInvoiceIssuesGrid = () => {
    return (
      <div>
        <Hidden mdUp>
          {!salesInvoiceIssuesWithParentValuesState?.length && (
            <p style={{ textAlign: 'center' }}>No records available</p>
          )}
          <div style={{ overflow: 'scroll', height: '30vh' }}>
            {salesInvoiceIssuesWithParentValuesState?.map(issue => (
              <ReportCardRowInvoice key={issue.number} issue={issue} />
            ))}
          </div>
        </Hidden>

        <Hidden smDown>
          <KendoGrid
            style={{ height: '350px' }}
            className={modalClasses.table}
            sortable={true}
            group={group}
            onExpandChange={onExpandChange}
            expandField="expanded"
            data={salesInvoiceResultState}
            sort={sortConfigSalesInvoice}
            onSortChange={e => {
              setSortConfigSalesInvoice(e.sort)
            }}
          >
            <Column field="financialID" title="Billing Contact ID" />
            <Column field="number" title="Invoice ID" />
            <Column field="customerName" title="Customer Name" />
            <Column field="plantName" title="Plant Name" />
            <Column field="dueDate" title="Payment Due Date" />
            <Column
              field="reason"
              title="Alert"
              cell={(p: GridCellProps) => (
                <GridCell
                  {...p}
                  style={{
                    color: getAlertSeverityColor(
                      p.dataItem.issue === 'OverBudget' ||
                        p.dataItem.issue === 'PastDue'
                        ? 'error'
                        : 'warning'
                    ),
                  }}
                />
              )}
            />
          </KendoGrid>
        </Hidden>
      </div>
    )
  }

  const salesOrderIssueCount =
    salesOrderIssuesWithParentValuesState?.length ?? 0
  const salesInvoiceIssueCount =
    salesInvoiceIssuesWithParentValuesState?.length ?? 0

  useEffect(() => {
    if (
      salesOrderIssueCount == 0 &&
      salesInvoiceIssueCount > 0 &&
      issuesTab == 'SalesOrder'
    ) {
      setIssuesTab('SalesInvoice')
    }
  }, [salesOrderIssueCount, salesInvoiceIssueCount])

  return (
    <Modal open={modalOpen} onClose={onModalClose}>
      <div style={modalStyle} className={modalClasses.paper}>
        <form>
          <Grid container direction="column" spacing={2}>
            <Grid
              container
              item
              direction="row"
              spacing={2}
              alignItems="center"
            >
              <Grid item xs={12}>
                <Hidden smDown>
                  <h1>
                    {issuesTab === 'SalesInvoice'
                      ? 'Sales Invoice Issues'
                      : 'Sales Order Issues'}
                  </h1>
                </Hidden>
                <Hidden mdUp>
                  <h3>
                    {issuesTab === 'SalesInvoice'
                      ? 'Sales Invoice Issues'
                      : 'Sales Order Issues'}
                  </h3>
                </Hidden>
              </Grid>
              <Grid item xs={12}>
                <Hidden smDown>
                  <h5>{message}</h5>
                </Hidden>
                <Hidden mdUp>
                  <h6>{message}</h6>
                </Hidden>
              </Grid>
              <Grid item xs={12}>
                {clearSortingButton}
              </Grid>
              <IssuesTabPicker
                onPick={onTabclick}
                tab={issuesTab}
                salesOrderIssueCount={salesOrderIssueCount}
                salesInvoiceIssueCount={salesInvoiceIssueCount}
              />
              {issuesTab === 'SalesInvoice' && salesInvoiceIssuesGrid()}
              {issuesTab === 'SalesOrder' && salesOrderIssuesGrid()}
              <Grid item xs={12}>
                <Hidden smDown>
                  <h1>Customer Support Contact Information</h1>
                </Hidden>
                <Hidden mdUp>
                  <h4>Customer Support Contact Information</h4>
                </Hidden>
              </Grid>
              <Grid item xs={9}>
                <h5>
                  <EmailOutlined style={{ marginRight: '5px' }} />
                  <EmailLinkButton mailto={getMailToString()}>
                    Email: {process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL}
                  </EmailLinkButton>
                </h5>
                <h5>
                  <PhoneOutlined style={{ marginRight: '5px' }} />
                  <PhoneLinkButton phone="2162512510">
                    Phone: (216) 251-2510
                  </PhoneLinkButton>
                </h5>
              </Grid>
              <Grid item xs={3} style={{ alignSelf: 'flex-end' }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: '100%' }}
                  onClick={onModalClose}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
    </Modal>
  )
}
