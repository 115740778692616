import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { unstable_batchedUpdates } from 'react-dom'
import { Box, Grid, Typography } from '@material-ui/core'
import { SortDescriptor } from '@progress/kendo-data-query'
import { GridSortChangeEvent } from '@progress/kendo-react-grid'
import { TAPIMachineSchedule } from 'types/api'
import AppLayout from 'components/AppLayout'
import { ExportButton, ThemeButton } from 'components/buttons'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import {
  ScheduleReportTable,
  tableFormatter,
} from 'components/tables/ScheduleReportTable'
import APIService, { TProfile } from 'services/api-service'
import { exportData } from 'services/export-service'
import { getSelectedCustomerIDs } from 'services/selection-service'
import { useProfile } from 'Contexts/ProfileContext'
import FilterAccordion, {
  TAccordionFilterValues,
} from 'components/FilterAccordion'
import { mapToPageParameters } from 'components/FilterAccordion/Utilities'
import {
  OptionsFilterSet,
  OrganizationalFilterSet,
  MachineFilterSet,
  SampleFilterSet,
} from 'components/FilterAccordion/types'
import { FormField } from 'components/FormField'

const defaults = {
  page: 1,
  pageSize: 10,
  orderConfig: [
    { field: 'machine.machineName', dir: 'asc' },
  ] as SortDescriptor[],
  selectedDivisionIDs: [],
  selectedCustomerIDs: [],
  selectedPlants: [],
  selectedRoutes: [],
  selectedMachineNames: [],
  selectedTestGroupIDs: [],
  selectedSchedules: [],
  selectedCustEquIDs: [],
  selectAllSchedules: false,
  exportFormat: '',
}
let isInitialLoad = true
interface OwnProps {
  onError?: (_err: Error) => void // passed in from layout wrapper
  offline?: boolean
  classes?: any
}

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

const Schedule: React.FC<OwnProps> = ({
  onError,
  offline,
  classes,
}: OwnProps) => {
  const navigate = useNavigate()
  const [state, setState] = useState({
    ...defaults,
    loaded: false,
    filterUpdated: false,
    schedules: [],
    resetSchedules: [],
    count: 0,
    submitting: false,
    performedInitialLoad: false,
    searching: false,
    loadedDefaults: false,
    formErrors: [],
  })

  useEffect(() => {
    return () => {
      isInitialLoad = true
    }
  }, [])

  const profileContext = useProfile()

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  useEffect(() => {
    if (profileContext.dependentData.filters.minimumFilterOptionsLoaded) {
      setState(prev => ({
        ...prev,
        selectedCustomerIDs:
          profileContext.dependentData.filters.initialFilterState.customers.map(
            c => c.custID
          ),
        selectedDivisionIDs:
          profileContext.dependentData.filters.initialFilterState.divisions.map(
            d => d.iD
          ),
        loadedDefaults: true,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext.dependentData.filters.minimumFilterOptionsLoaded])

  useEffect(() => {
    if (!state.loaded && state.loadedDefaults && !state.searching) {
      performSearch()
    }
    if (state.searching) {
      unstable_batchedUpdates(() => {
        performSearch()
        setState(prev => ({ ...prev, searching: false }))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loaded, state.loadedDefaults, state.searching])

  const fetchSamples = useCallback(
    async variables => {
      let promise
      try {
        promise = APIService.getMachSchedules(variables)

        ongoingCancellablePromises.push(promise)
        const data = await promise

        if (data.pagedMachSchedules !== null) {
          onError(null)

          const newSchedules: TAPIMachineSchedule[] = [].concat(
            data.pagedMachSchedules.items
          )

          state.selectedSchedules.forEach(schedule => {
            const index = newSchedules.findIndex(x => x.mSID === schedule.mSID)
            if (index !== -1) newSchedules[index] = schedule
          })

          setState(prev => ({
            ...prev,
            schedules: newSchedules,
            resetSchedules: data.pagedMachSchedules.items,
            count: data.pagedMachSchedules.count,
          }))
        }
      } catch (ex) {
        onError(ex)
      } finally {
        ongoingCancellablePromises.filter(p => p != promise)
        setState(prev => ({ ...prev, loaded: true }))
      }
    },
    [onError]
  )

  const performSearch = useCallback(async () => {
    await fetchSamples({
      page: state.page,
      pageSize: state.pageSize,
      orderList: state.orderConfig?.map(x => x.dir) ?? [],
      orderByList: state.orderConfig?.map(x => x.field) ?? [],
      customerIDs: getSelectedCustomerIDs(
        profileContext.profile.customers,
        profileContext.profile.divisions,
        state.selectedDivisionIDs,
        state.selectedCustomerIDs
      ),
      plantIDs: state.selectedPlants,
      machineNames: state.selectedMachineNames,
      custEquIDs: state.selectedCustEquIDs,
      routeIDs: state.selectedRoutes,
      testGroupIDs: state.selectedTestGroupIDs,
    })
  }, [
    fetchSamples,
    state.orderConfig,
    state.page,
    state.pageSize,
    state.selectedCustEquIDs,
    state.selectedCustomerIDs,
    state.selectedDivisionIDs,
    state.selectedMachineNames,
    state.selectedPlants,
    state.selectedRoutes,
    state.selectedTestGroupIDs,
  ])

  const handleResetClick = () => {
    setState(prev => ({
      ...prev,
      ...defaults,
      selectedCustomerIDs:
        profileContext.dependentData.filters.initialFilterState.customers.map(
          c => c.custID
        ),
      selectedDivisionIDs:
        profileContext.dependentData.filters.initialFilterState.divisions.map(
          d => d.iD
        ),
      count: 0,
      loaded: false,
    }))
  }

  const handleExportFormatChange = async (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    format
  ) => {
    let promise
    try {
      promise = APIService.getMachSchedules({
        page: 1,
        pageSize: 100000,
        orderList: state.orderConfig?.map(x => x.dir) ?? [],
        orderByList: state.orderConfig?.map(x => x.field) ?? [],
        customerIDs: getSelectedCustomerIDs(
          profileContext.profile.customers,
          profileContext.profile.divisions,
          state.selectedDivisionIDs,
          state.selectedCustomerIDs
        ),
        plantIDs: state.selectedPlants,
        machineNames: state.selectedMachineNames,
        custEquIDs: state.selectedCustEquIDs,
        routeIDs: state.selectedRoutes,
        testGroupIDs: state.selectedTestGroupIDs,
      })

      ongoingCancellablePromises.push(promise)
      const data = await promise

      const formatted = tableFormatter(
        data.pagedMachSchedules.items,
        profileContext.dependentData.userDetails.isDivisionUser
      )

      exportData('machine-schedule', formatted, format.key)
    } catch (ex) {
      onError(ex)
    } finally {
      ongoingCancellablePromises.filter(p => p != promise)
    }
  }

  const handlePageChange = event => {
    setState(prev => ({
      ...prev,
      page: event.page.skip / state.pageSize + 1,
      pageSize: event.page.take,
      loaded: false,
    }))
  }

  const handleSortChange = (event: GridSortChangeEvent) => {
    const isFieldRemoved = state.orderConfig.length > event.sort.length
    let isLastFieldRemoved = false

    if (isFieldRemoved) {
      const fieldRemoved = state.orderConfig.filter(
        x => !event.sort.includes(x)
      )[0]

      isLastFieldRemoved =
        state.orderConfig.indexOf(fieldRemoved) === state.orderConfig.length - 1
    }

    setState(prev => ({
      ...prev,
      orderConfig: [...event.sort],
      loaded: !(event.sort.length > 0 && !isLastFieldRemoved),
    }))
  }

  const handlePageChangeMobile = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage
  ) => {
    setState(prev => ({ ...prev, page: newPage + 1, loaded: false }))
  }

  const handlePageSizeChangeMobile = event => {
    setState(prev => ({
      ...prev,
      page: 1,
      pageSize: parseInt(event.target.value, 10),
      loaded: false,
    }))
  }

  const handleSelectedScheduleChange = (event, scheduleID) => {
    const selectedSchedules = [].concat(state.selectedSchedules)
    const selectedSchedule = state.schedules.find(x => x.mSID === scheduleID)
    const index = selectedSchedules.indexOf(selectedSchedule)
    if (index === -1) selectedSchedules.push(selectedSchedule)
    else {
      selectedSchedules.splice(index, 1)
      const resetSchedule = state.resetSchedules.find(
        x => x.mSID === scheduleID
      )
      const idx = state.schedules.indexOf(selectedSchedule)
      if (idx !== -1) {
        const newSchedules = [].concat(state.schedules)
        newSchedules[idx] = resetSchedule
        setState(prev => ({
          ...prev,
          schedules: newSchedules,
        }))
      }
    }
    setState(prev => ({
      ...prev,
      selectedSchedules: selectedSchedules,
      selectAllSchedules:
        selectedSchedules.length === prev.resetSchedules.length,
    }))
  }

  const handleSelectAllScheduleChange = event => {
    if (event.target.checked) {
      setState(prev => ({
        ...prev,
        selectAllSchedules: true,
        selectedSchedules: state.schedules,
      }))
    } else {
      setState(prev => ({
        ...prev,
        selectAllSchedules: false,
        selectedSchedules: [],
      }))
    }
  }

  const handleScheduleChange = schedule => {
    const newSchedules = [].concat(state.schedules)
    const index = newSchedules.findIndex(x => x.mSID === schedule.mSID)
    if (index !== -1) newSchedules[index] = schedule
    setState(prev => ({ ...prev, schedules: newSchedules }))
  }

  const handleClearSelectedSchedules = () => {
    handlePopulateOrClearSchedules(false)
  }

  const handlePopulateSelectedSchedules = () => {
    handlePopulateOrClearSchedules(true)
  }

  const handlePopulateOrClearSchedules = (populate: boolean) => {
    const selectedSchedules = [].concat(state.selectedSchedules)
    const schedulesFromAnotherPage = selectedSchedules.filter(
      x => !state.schedules.map(c => c.mSID).includes(x.mSID)
    )
    const newSchedules: TAPIMachineSchedule[] = [].concat(
      state.schedules,
      schedulesFromAnotherPage
    )
    const newSelectedSchedules = []
    selectedSchedules.forEach(schedule => {
      const newSchedule: TAPIMachineSchedule = {
        ...schedule,
        mSID: schedule.mSID,
        pointID: schedule.pointID,
        testGroupID: schedule.testGroupID,
        allMonths: populate,
        jan: populate,
        feb: populate,
        mar: populate,
        apr: populate,
        may: populate,
        jun: populate,
        jul: populate,
        aug: populate,
        sep: populate,
        oct: populate,
        nov: populate,
        dec: populate,
      }
      const index = newSchedules.findIndex(x => x.mSID === newSchedule.mSID)
      if (index !== -1) newSchedules[index] = newSchedule
      newSelectedSchedules.push(newSchedule)
    })

    setState(prev => ({
      ...prev,
      schedules: newSchedules,
      selectedSchedules: [].concat(newSelectedSchedules),
    }))
  }

  const handleSaveChangesClick = _event => {
    if (state.submitting === false) {
      setState(prev => ({ ...prev, submitting: true }))
      const schedulesFromAnotherPage = state.selectedSchedules.filter(
        x => !state.schedules.map(c => c.mSID).includes(x.mSID)
      )
      const schedulesConcatenated = state.schedules.concat(
        schedulesFromAnotherPage
      )
      const changed = schedulesConcatenated.filter(
        x => state.resetSchedules.indexOf(x) === -1
      )

      try {
        APIService.saveMachSchedules({
          machSchedulesList: {
            machSchedules: changed.map(x => {
              return {
                mSID: x.mSID,
                allMonths: x.allMonths,
                jan: x.jan,
                feb: x.feb,
                mar: x.mar,
                apr: x.apr,
                may: x.may,
                jun: x.jun,
                jul: x.jul,
                aug: x.aug,
                sep: x.sep,
                oct: x.oct,
                nov: x.nov,
                dec: x.dec,
              }
            }),
          },
        }).then(() => setState(prev => ({ ...prev, loaded: false })))
      } catch (ex) {
        onError(ex)
      } finally {
        setState(prev => ({ ...prev, submitting: false }))
      }
    }
  }

  const handleDeleteSelectedSchedulesClick = _event => {
    try {
      if (!state.selectedSchedules || !state.selectedSchedules.length)
        throw new Error('Please, select at least one item to delete')

      let result = false
      if (typeof window !== 'undefined') {
        result = window.confirm(
          'Are you sure you want to delete the selected schedules?'
        )
      }

      if (!result) return

      if (state.submitting === false) {
        setState(prev => ({ ...prev, submitting: true }))

        APIService.deleteMachSchedules({
          mSIDs: state.selectedSchedules.map(x => x.mSID),
        }).then(() => setState(prev => ({ ...prev, loaded: false })))
      }
    } catch (ex) {
      onError(ex)
    } finally {
      setState(prev => ({ ...prev, submitting: false }))
    }
  }

  const handleAddNewScheduleClick = _event => {
    navigate('/program-management/schedule/new', {
      state: {
        selectedSchedules: state.schedules.filter(x =>
          state.selectedSchedules.some(y => y.mSID === x.mSID)
        ),
      },
    })
  }

  const handleAddSchedule = () => {
    const formErrors = []

    if (
      profileContext.fullProfileLoaded &&
      profileContext.profile.machines.length < 1
    ) {
      formErrors.push({
        id: 'addSchedule',
        message: 'Machine needed to add a schedule.',
      })
    }

    setState(prev => ({ ...prev, formErrors: formErrors }))

    return !formErrors.some(x => x)
  }

  const handleCancelChangesClick = _event => {
    const newSchedules = [].concat(state.resetSchedules)
    setState(prev => ({ ...prev, schedules: newSchedules }))
  }

  const handleFilterSubmission = (data: TAccordionFilterValues) => {
    const mappedParameters = mapToPageParameters(data)

    setState(prev => ({
      ...prev,
      ...mappedParameters,
      page: 1,
      searching: true,
      loaded: false,
    }))
  }

  if (
    isInitialLoad &&
    ((profileContext.profile.divisions?.length > 0 &&
      state.selectedDivisionIDs.length === 0) ||
      (profileContext.profile.customers?.length > 0 &&
        state.selectedCustomerIDs.length === 0))
  ) {
    isInitialLoad = false
    return null
  }

  return (
    <>
      <Box mb={2}>
        <Typography variant="h1">Manage Schedule</Typography>
      </Box>
      {offline ? <OfflineBanner /> : <></>}

      <FilterAccordion
        pageName="Manage Schedule"
        defaultValues={profileContext.dependentData.filters.initialFilterState}
        onSubmit={handleFilterSubmission}
        onReset={handleResetClick}
        filters={{
          options: [OptionsFilterSet.Prefiltering],
          date: [],
          organization: [OrganizationalFilterSet.All],
          machine: [MachineFilterSet.Name, MachineFilterSet.EquipmentIDs],
          lubricant: [],
          sample: [SampleFilterSet.TestPackages],
          miscellaneous: [],
        }}
      />
      <form>
        <Grid
          container
          direction="row"
          xs={12}
          spacing={1}
          justify="flex-end"
          style={{ marginTop: '15px' }}
        >
          <Grid container item direction="row" spacing={1} justify="flex-end">
            <Grid item>
              <ExportButton
                onChange={handleExportFormatChange}
                disabled={state.schedules < 1}
              />
            </Grid>
          </Grid>

          {profileContext.profile?.user?.readOnly ? (
            ''
          ) : (
            <Grid
              style={{ marginTop: '10px' }}
              container
              direction="row"
              alignItems="flex-start"
              justify="flex-end"
              spacing={1}
            >
              <Grid
                container
                item
                direction="row"
                spacing={1}
                justify="flex-end"
              >
                <Grid item>
                  <ThemeButton
                    variant="contained"
                    color="primary"
                    onClick={handleDeleteSelectedSchedulesClick}
                    disabled={state.schedules < 1}
                  >
                    Delete Selected Schedules
                  </ThemeButton>
                </Grid>
                <Grid item>
                  <FormField id="addSchedule" formErrors={state.formErrors}>
                    <ThemeButton
                      variant="contained"
                      color="primary"
                      onClick={handleAddNewScheduleClick}
                      disabled={
                        !profileContext.fullProfileLoaded ||
                        profileContext.profile.machines.length < 1
                      }
                    >
                      Add Schedules
                    </ThemeButton>
                  </FormField>
                </Grid>
              </Grid>
              <Grid
                container
                item
                direction="row"
                spacing={1}
                justify="flex-end"
              >
                <Grid item>
                  <ThemeButton
                    variant="contained"
                    color="primary"
                    onClick={handleClearSelectedSchedules}
                    disabled={state.schedules < 1}
                  >
                    Clear Selected Schedules
                  </ThemeButton>
                </Grid>
                <Grid item>
                  <ThemeButton
                    variant="contained"
                    color="primary"
                    onClick={handlePopulateSelectedSchedules}
                    disabled={state.schedules < 1}
                  >
                    Populate Selected Schedules
                  </ThemeButton>
                </Grid>
              </Grid>
              <Grid
                container
                item
                direction="row"
                spacing={1}
                justify="flex-end"
              >
                <Grid item>
                  <ThemeButton
                    variant="contained"
                    color="primary"
                    onClick={handleCancelChangesClick}
                    disabled={state.schedules < 1}
                  >
                    Cancel Changes
                  </ThemeButton>
                </Grid>
                <Grid item>
                  <ThemeButton
                    variant="contained"
                    color="primary"
                    onClick={handleSaveChangesClick}
                    disabled={state.schedules < 1}
                  >
                    Save Changes
                  </ThemeButton>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
      <Box mt={4}>
        {!state.loaded ? (
          <Loader />
        ) : (
          <React.Fragment>
            <ScheduleReportTable
              classes={classes}
              readOnly={profileContext.profile?.user?.readOnly}
              offline={offline}
              schedules={state.schedules}
              selectAllSchedules={state.selectAllSchedules}
              selectedScheduleIDs={state.selectedSchedules.map(x => x.mSID)}
              page={state.page}
              pageSize={state.pageSize}
              count={state.count}
              orderConfig={state.orderConfig}
              onSortChange={handleSortChange}
              onPageChange={handlePageChange}
              onPageChangeMobile={handlePageChangeMobile}
              onPageSizeChangeMobile={handlePageSizeChangeMobile}
              onSelectAllScheduleChange={handleSelectAllScheduleChange}
              onSelectedScheduleChange={handleSelectedScheduleChange}
              onScheduleChange={handleScheduleChange}
            />
          </React.Fragment>
        )}
      </Box>
    </>
  )
}

export default function Page() {
  return (
    <AppLayout tab="program-management">
      <Schedule />
    </AppLayout>
  )
}
