//Date: (start, end, switch), Sample Date
//Machine: type, conditions, name, manufacturer, equipment ID, Serial number, Models, Numbers
//Lube: Type, conditions, name, manufacturer
//Organizational: Division, Customer, Routes, Plants
//Misc: Report Types, sample status, (Frequency, Fiscal Year Start), (Days Delinquent), Test Packages, Lab number, Exception Count, Omit Full Date, Load only machins scheduled..., Shipment Status

import { TFilterState } from 'components/filters/SmartFilter/SmartFilterTypes'
import { ProfileContext } from 'Contexts/ProfileContext'
import { useContext } from 'react'
import { TProfile } from 'services/api-service'
import { TAccordionFilterValues } from '..'

export type TMachineFilterStatuses = {
  machineTypeLoaded: boolean
  machineConditionsLoaded: boolean
  machineNameLoaded: boolean
  machineManufacturerLoaded: boolean
  machineEquipmentIDLoaded: boolean
  machineSerialNumberLoaded: boolean
  machineModelsLoaded: boolean
  machineNumbersLoaded: boolean
}

export type TLubeFiltersStatuses = {
  lubeTypeLoaded: boolean
  lubeConditionsLoaded: boolean
  lubeNamesLoaded: boolean
  lubeManufacturersLoaded: boolean
}

export type TSampleFilterStatuses = {
  testPackagesLoaded: boolean
  labNumbersLoaded: boolean
  exceptionCountsLoaded: boolean
}

export type TOrganizationFilterStatuses = {
  divisionsLoaded: boolean
  customersLoaded: boolean
  routesLoaded: boolean
  plantsLoaded: boolean
}

export type TMiscellaneousFilterStatuses = {
  reportTypesLoaded: boolean
  sampleStatusesLoaded: boolean
  frequenciesLoaded: boolean
  fiscalYearStartMonthsLoaded: boolean
  daysDelinquentLoaded: boolean
  shipmentStatusesLoaded: boolean
}

export type TFilterStatus = {
  machineDataStatuses: TMachineFilterStatuses
  lubeFilterStatuses: TLubeFiltersStatuses
  sampleFilterStatuses: TSampleFilterStatuses
  organizationalFilterStatuses: TOrganizationFilterStatuses
  miscFilterStatuses: TMiscellaneousFilterStatuses
}

// export type TMachineFilterValues = {
//     machineType: string[],
//     machineConditions: string[],
//     machines: TAPIMachine[]
// }

// export type TFilterValues = {

// }

export const getFilterStatuses = (
  profile: Partial<TProfile>,
  partialProfileLoaded: boolean,
  fullProfileLoaded: boolean
) => {
  return {
    machineDataStatuses: {
      machineConditionsLoaded: true,
      machineEquipmentIDLoaded: fullProfileLoaded,
      machineManufacturerLoaded: fullProfileLoaded,
      machineModelsLoaded: fullProfileLoaded,
      machineNameLoaded: fullProfileLoaded,
      machineNumbersLoaded: fullProfileLoaded,
      machineSerialNumberLoaded: true,
      machineTypeLoaded: fullProfileLoaded,
    },
    lubeFilterStatuses: {
      lubeConditionsLoaded: true,
      lubeManufacturersLoaded: fullProfileLoaded,
      lubeNamesLoaded: fullProfileLoaded,
      lubeTypeLoaded: fullProfileLoaded,
    },
    sampleFilterStatuses: {
      exceptionCountsLoaded: true,
      labNumbersLoaded: true,
      testPackagesLoaded: fullProfileLoaded,
    },
    organizationalFilterStatuses: {
      customersLoaded: partialProfileLoaded,
      divisionsLoaded: partialProfileLoaded,
      plantsLoaded: partialProfileLoaded,
      routesLoaded: partialProfileLoaded,
    },
    miscFilterStatuses: {
      daysDelinquentLoaded: true,
      fiscalYearStartMonthsLoaded: true,
      frequenciesLoaded: true,
      reportTypesLoaded: true,
      sampleStatusesLoaded: true,
      shipmentStatusesLoaded: true,
    },
  } as TFilterStatus
}

export const generateFilterOptions = (
  profile: Partial<TProfile>
): Partial<TFilterState> => {
  if (!profile) return {}

  return {
    customers: profile.customers,
    divisions: profile.divisions,
    machines: profile.machines,
    plants: profile.plants,
    routes: profile.routes,
    machineNames: profile.machineNames,
    lubricantTypes: profile.lubeTypes,
    machinePicklistTypes: profile?.machineTypeOptions?.filter(mt =>
      profile?.machineTypes?.includes(mt.listValue)
    ),
  } as Partial<TFilterState>
}

/**A temporary function to map filters variables over to state variables before refactor */
export const mapToPageParameters = (data: Partial<TAccordionFilterValues>) => {
  return {
    selectedCustomerIDs: data.customers?.map(c => c.custID),
    selectedDivisionIDs: data.divisions?.map(d => d.iD),
    selectedDivisions: data.divisions ? [...data.divisions] : [],
    selectedPlants: data.plants?.map(p => p.plantID),
    selectedMachineNames: data.machineNames,
    selectedCustEquIDs: data.customerEquipmentIDs,
    selectedRoutes: data.routes?.map(r => r.routeID),
    selectedMachineTypes: data.machinePicklistTypes?.map(m => m.listValue),
    selectedLubeTypes: data.lubricantTypes?.map(l => l.lubeTypeID),
    selectedMachineConditions: data.machineConditions,
    selectedLubeConditions: data.lubricantConditions,
    selectedReportTypes: data.reportTypes?.map(r => r.listValue),
    selectedTestGroupIDs: data.testPackages?.map(tp => tp.testGroupID),
    labNo: data.labNumber,
    serialNo: data.serialNumber,
    reviewed: !data.hideReviewed,
    hideReviewed: data.hideReviewed,
    selectedSampleStatuses:
      data.sampleStatus != null ? [data.sampleStatus] : [],
    frequency: data.frequency,
    fiscalYearStartMonth: data.fiscalYearStartMonth,
    startDate: data.startDate,
    endDate: data.endDate,
    daysDelinquent: data.daysDelinquent,
    exceptionCount: data.exceptionCount,
    hideCompleted: data.hideCompleted,
    selectedMachineNumbers: data.machineNumbers,
    selectedMachineMfgs: data.machineManufacturers?.map(mfg => mfg.listValue),
    selectedMachineModels: data.machineModels,
    onlyForSelectedMonth: data.onlyForSelectedMonth,
    selectedSampleDate: data.sampleDate,
    selectedShipmentStatuses: data.shipmentStatuses,
    omitDay: data.omitDayOfMonth,
    omitFullDate: data.omitFullDate,
    selectedOrderNo: data.orderNumber,
    selectedTrackingNo: data.trackingNumber,
    machineLubeConditionANDOR: data.machineLubeConditionANDOR,
  }
}
