import React, { useCallback, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import LoginService from 'services/login-service'

export default () => {
  const navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      const loggedIn = await LoginService.isLoggedIn()
      if (!loggedIn) {
        if (typeof window !== 'undefined') {
          const postLogin = `PostLoginURL=${encodeURIComponent(
            `/ReceiptDownloader${window.location.search}`
          )}`
          navigate(`/login?${postLogin}`)
        }
      } else {
        if (typeof window !== 'undefined') {
          const urlParams = new URLSearchParams(window.location.search)
          const invoiceNumber = urlParams.get('InvoiceNumber')

          const token = await LoginService.getStoredAccessToken()

          window.location.href = `${
                                    process.env.REACT_APP_API_URL
                                  }/receipts/${invoiceNumber}?authToken=${token}&download=true`
        }
      }
    })()
  }, [])

  return (<></>)
}
