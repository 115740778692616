import React from 'react'
import { TextFieldProps } from '@material-ui/core'

import FilterTooltip from '../FilterTooltip/FilterTooltip'

import { TextFieldFilter } from './text-field-filter'

type TProps = {
  tooltip?: boolean
}

const OrderNumberFilter: React.FC<TextFieldProps> = (
  props: TextFieldProps & TProps
) => {
  const { tooltip = true, ...rest } = props

  const filter = <TextFieldFilter id="orderNumber" placeholder="Order Number" {...rest} />

  return tooltip ? (
    <FilterTooltip title="Order number.">{filter}</FilterTooltip>
  ) : (
    filter
  )
}

export default OrderNumberFilter