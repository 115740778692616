import { TEncrypted, FluidType } from 'types/api'
import { DatalessQuery, Query } from './Query'
import { serialize } from 'utils/api-utilities'

export class AllFluidTypesEncryptedQuery extends Query<
  TEncrypted,
  FluidType[]
> {
  public get Url(): string {
    return `${
      process.env.REACT_APP_DS_EXPERIENCE_API_URL
    }/v1/Fluid/Types/All/Encrypted${serialize(this.Data)}`
  }
}
