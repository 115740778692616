import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import {
  TAPICustomer,
  TAPIPlant,
  TAPIProfileDivision,
  TGetSpendingQuery,
  TSpending,
  TSpendingEntity,
} from 'types/api'
import AppLayout from 'components/AppLayout'
import InvoiceList, {
  PaymentStatusEnum,
} from 'components/Financials/StripeJS/InvoiceList'
import APIService, { TProfile } from 'services/api-service'
import { CompareSpendChart } from 'components/Financials/CompareSpendChart'
import { TPieChartPoint } from 'components/Charts/PieChart'
import { PieChartWithPicker } from 'components/Charts/PieChartWithPicker'

import FinancialAlertBanner from 'components/Banners/IssueBanners/FinancialAlertBanner'
import { ProfileContext, useProfile } from 'Contexts/ProfileContext'
import { CancellablePromise } from 'utils/CancellablePromise'
import {
  LineChart,
  ResponsiveLineChart,
} from 'components/visualizations/brush-line/LineChart'
import Loader from 'components/Loader'
import {
  TLineDatum,
  TPoint,
} from 'components/visualizations/brush-line/LineChart/LineChart'
import { OverallSpendingChart } from 'components/Charts/Financials/OverallSpendingChart'
import { Slider } from 'components/MachineCardSlider/Slider'
import useWidth from 'hooks/useWidth'
import LoadingError from 'components/LoadingError'
import { OverallSpendingChartWithPicker } from 'components/Charts/Financials/OverallSpendingChart/OverallSpendingChartWithPicker'
import FilterAccordion, {
  TAccordionFilterValues,
} from 'components/FilterAccordion'
import {
  DateFilterSet,
  OrganizationalFilterSet,
} from 'components/FilterAccordion/types'
import { mapToPageParameters } from 'components/FilterAccordion/Utilities'
import * as Styles from './index.module.css'
import { Grid } from '@material-ui/core'
import { NoDataCard } from 'components/NoDataCard'
import { AvailableBudgetCard } from './ScoreCards/AvailableBudgetCard'
import { EstimatedSpendCard } from './ScoreCards/EstimatedSpendCard'
import { EstimatedSpendNextMonthCard } from './ScoreCards/EstimatedSpendNextMonthCard'
import { YearToDateSpendCard } from './ScoreCards/YearToDateSpendCard'
import { AmountDueCard } from './ScoreCards/AmountDueCard'
import { TopSpendingCard } from './ScoreCards/TopSpendingCard'
import { Navigate, useNavigate } from 'react-router-dom'
import { getAggregatedMachineTypeSpending } from 'utils/financial-utilities'
import { allOldValuesAreInNewObject } from 'utils/object-utilities'
import { useEnvironmentContext } from 'Contexts/EnvironmentContext'

const slowLoadDelayInMS = 20000
let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

const maxStartDateForQuery = new Date()
maxStartDateForQuery.setFullYear(maxStartDateForQuery.getFullYear() - 1)
maxStartDateForQuery.setMonth(0)
maxStartDateForQuery.setDate(1)

const startDate = new Date()
startDate.setFullYear(startDate.getFullYear())
startDate.setMonth(0)
startDate.setDate(1)

const endDate = new Date()
endDate.setMonth(endDate.getMonth() + 12)
interface StateProps {
  loadingError: boolean
  spendingLoaded: boolean
  searching: boolean
  searchHasOccurred: boolean
  customers?: TAPICustomer[]
  plants?: TAPIPlant[]
  pointIDs?: number[]
  startDate?: Date
  endDate?: Date
  spendings?: TSpendingEntity[]
  customerIDs: number[]
  selectedDivisions: TAPIProfileDivision[]
  selectedPlants: number[]
  plantIDs: number[]
  profileUpdated: boolean //indicates whether or not profile is available.
  spendingByMachine: TPieChartPoint[]
  selectedCustomerIDs: number[]
}

const FinancialsDashboard: React.FC = ({ onError }: any) => {
  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  const profileContext = useProfile()

  const isLargeCustomer = () => {
    if (!profileContext.fullProfileLoaded) return false
    return profileContext.profile.machines.length > 10000
  }

  const translateMachineTypeToName = (machineType: string) => {
    if (!profileContext.fullProfileLoaded) return machineType

    const machineName = profileContext.profile.machineTypeOptions.find(
      mto => mto.listValue.toLowerCase() === machineType.toLowerCase()
    )?.listDesc

    return machineName ?? machineType
  }

  useEffect(() => {
    if (profileContext.minimumProfileLoaded) {
      setState(prev => ({
        ...prev,
        customerIDs: profileContext.profile.customers.map(c => c.custID),
        plantIDs: profileContext.profile.plants.map(p => p.plantID),
        ...profileContext.profile,
      }))
    }
  }, [profileContext.minimumProfileLoaded, profileContext.profile])

  const stateDefaults = {
    spendingLoaded: false,
    customerIDs: profileContext.profile?.customers?.map(c => c.custID),
    plantIDs: profileContext.profile?.plants?.map(p => p.plantID),

    profileUpdated: profileContext.minimumProfileLoaded,
    selectedDivisions: [],
    ...profileContext.profile,
    ...mapToPageParameters(
      profileContext.dependentData.filters.initialFilterState
    ),
    startDate: startDate,
    endDate: endDate,
  }

  const [state, setState] = useState<Partial<StateProps>>({ ...stateDefaults })
  const [takingALongTimeToLoad, setTakingALongTimeToLoad] = useState(false)
  const slowLoadTimeout = useRef(null)

  const setTakingLongTimeToLoadtimer = () => {
    slowLoadTimeout.current = setTimeout(() => {
      setTakingALongTimeToLoad(true)
    }, slowLoadDelayInMS)
  }

  const clearTakingLongTimeToLoadtimer = () => {
    if (slowLoadTimeout.current) clearTimeout(slowLoadTimeout.current)
  }

  useEffect(() => {
    return clearTakingLongTimeToLoadtimer
  }, [])

  const spendingGetPromise = useRef<CancellablePromise<TSpendingEntity[]>>(null)

  const fetchData = useCallback(async () => {
    setTakingLongTimeToLoadtimer()

    const {
      pointIDs,
      customers,
      plants,
      startDate,
      endDate,
      selectedCustomerIDs,
      selectedPlants,
    } = state
    let customerIDs
    let plantIDs

    if (selectedCustomerIDs == null || selectedCustomerIDs.length === 0)
      customerIDs = profileContext.profile.customers.map(c => c.custID)
    else customerIDs = selectedCustomerIDs

    const filteredCustomerIDs =
      customerIDs?.filter(c =>
        state.selectedDivisions
          ?.flatMap(d => d.customers)
          ?.map(c => c.custID)
          ?.includes(c)
      ) ?? customerIDs

    customerIDs =
      filteredCustomerIDs == null || filteredCustomerIDs.length === 0
        ? customerIDs
        : filteredCustomerIDs

    if (selectedPlants == null || selectedPlants.length === 0) plantIDs = []
    else plantIDs = selectedPlants

    const filteredPlantIDs =
      plantIDs?.filter(p =>
        profileContext.profile.customers
          ?.filter(c => state.selectedCustomerIDs?.includes(c.custID))
          ?.flatMap(c => c.plants)
          ?.map(p => p.plantID)
          ?.includes(p)
      ) ?? plantIDs

    plantIDs =
      filteredPlantIDs == null || filteredPlantIDs.length === 0
        ? []
        : filteredPlantIDs

    //if the end date is greater, then the start date needs to be further back by the difference.
    let timeDifference =
      (endDate && startDate && endDate.getTime() - startDate.getTime()) || 0

    //This must be at MOST the start of last year.
    let adjustedStartDateMS = startDate?.getTime()
    if (timeDifference > 0) {
      adjustedStartDateMS -= timeDifference
    }

    let adjustedStartDate = new Date(adjustedStartDateMS)
    if (adjustedStartDate.getTime() > maxStartDateForQuery.getTime()) {
      adjustedStartDate = maxStartDateForQuery
    }

    const query: TGetSpendingQuery = {
      pointIDs,
      customerIDs,
      plantIDs,
      startDate: startDate && adjustedStartDate?.toISOString(),
      endDate: endDate?.toISOString(),
    }

    try {
      spendingGetPromise.current = APIService.getFinancialDashboardData(query)
      ongoingCancellablePromises.push(spendingGetPromise.current)
      const spendings = await spendingGetPromise.current

      setState(prev => ({
        ...prev,
        spendings,
        spendingLoaded: true,
        loadingError: false,
      }))
    } catch (error) {
      if (!(error instanceof DOMException)) {
        setState(prev => ({
          ...prev,
          spendingLoaded: true,
          loadingError: true,
        }))
        onError(error)
      }
    } finally {
      clearTakingLongTimeToLoadtimer()
      if (takingALongTimeToLoad) setTakingALongTimeToLoad(false)

      ongoingCancellablePromises.filter(p => p != spendingGetPromise.current)
    }
  }, [
    state.selectedCustomerIDs,
    state.customers,
    profileContext.profile.customers,
    state.selectedDivisions,
    state.selectedPlants,
    state.pointIDs,
    state.endDate,
    profileContext.profile.plants,
    state.spendingLoaded,
    state.loadingError,
    state.startDate,
  ])

  useEffect(() => {
    if (profileContext.minimumProfileLoaded && fetchData) fetchData()
  }, [profileContext.minimumProfileLoaded])

  const [width, pageWidthRef] = useWidth()

  const [customerOverviewSlideIndex, setCustomerOverviewSlideIndex] =
    useState(0)

  const [
    customerSpendingByMachineSlideIndex,
    setCustomerSpendingByMachineSlideIndex,
  ] = useState(0)

  const customerSpendingEntities = state.spendings?.sort((a, b) =>
    a.name.localeCompare(b.name)
  )

  const plantCumulativeSpendingEntities = (
    customerSpendingEntities &&
    customerSpendingEntities.length >= customerOverviewSlideIndex + 1 &&
    customerSpendingEntities.length !== 0
      ? customerSpendingEntities[customerOverviewSlideIndex].children.filter(
          pse =>
            !state.selectedPlants ||
            state.selectedPlants.length === 0 ||
            state.selectedPlants.includes(pse.id)
        )
      : []
  ).sort((a, b) => a.name.localeCompare(b.name))

  const plantSpendingByMachineTypeSpendingEntities = (
    customerSpendingEntities &&
    customerSpendingEntities.length >=
      customerSpendingByMachineSlideIndex + 1 &&
    customerSpendingEntities.length !== 0
      ? customerSpendingEntities[
          customerSpendingByMachineSlideIndex
        ].children.filter(
          pse =>
            !state.selectedPlants ||
            state.selectedPlants.length === 0 ||
            state.selectedPlants.includes(pse.id)
        )
      : []
  ).sort((a, b) => a.name.localeCompare(b.name))

  useEffect(() => {
    if (state.searching) {
      fetchData()
      setState(prev => ({ ...prev, spendingLoaded: false, searching: false }))
    }
  }, [state.searching])

  const abortOngoingSearch = () => {
    if (
      spendingGetPromise.current &&
      !spendingGetPromise.current.abortController.signal.aborted
    ) {
      //NOTE: This will invoke the finally that this promise exists in and will remove it from the ongoingCancellablePromises array.
      spendingGetPromise.current.abortController?.abort()
    }
  }

  const handleFilterSubmission = (data: TAccordionFilterValues) => {
    const mappedParameters = mapToPageParameters(data)
    const parametersAreIdentical = allOldValuesAreInNewObject(
      state,
      mappedParameters
    )

    if (!parametersAreIdentical) {
      abortOngoingSearch()

      setState(prev => ({
        ...prev,
        ...mappedParameters,
        page: 1,
        searching: true,
        searchHasOccurred: true,
        spendingLoaded: false,
      }))
    }
  }

  const handleResetClick = () => {
    if (state.searchHasOccurred) {
      abortOngoingSearch()
      const mappedParameters = mapToPageParameters({})
      setState(prev => ({
        ...prev,
        ...mappedParameters,
        ...stateDefaults,
        count: 0,
        searching: true,
        searchHasOccurred: false,
        spendingLoaded: false,
      }))
    }
  }

  const purchaseOrders = state?.spendings?.flatMap(s =>
    s.purchaseOrders.filter(
      po =>
        (po && ((po.amount && po.amount > 0) || (po.spent && po.spent > 0))) ||
        (po.amountDue && po.amountDue > 0)
    )
  )

  const alertBanner = useMemo(() => {
    return (
      <FinancialAlertBanner
        customers={profileContext.profile.customers}
        plants={[]}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext.profile.customers?.length])

  const purchaseOrderComparison = useMemo(() => {
    return (
      purchaseOrders &&
      purchaseOrders.length > 0 && (
        <Grid
          item
          xs={12}
          xl={6}
          justifyContent="center"
          className={Styles.GraphSection}
        >
          <section
            className={`${Styles.CenteredSection} ${Styles.POSpendingComparisonChart}`}
          >
            <CompareSpendChart
              title="Purchase Order Spending Comparison"
              data={purchaseOrders.map(po => ({
                category: `PO#: ${po.poNumber}`,
                values: [po.spent, po.amount - po.spent],
              }))}
              showLegend
            />
          </section>
        </Grid>
      )
    )
  }, [purchaseOrders?.length])

  const customerCumulativeSpendingReportCards = useMemo(() => {
    const cards =
      customerSpendingEntities && customerSpendingEntities.length > 0
        ? customerSpendingEntities.map((s: TSpendingEntity) => [
            <OverallSpendingChartWithPicker
              startDate={state.startDate}
              endDate={state.endDate}
              spendingData={s}
            />,
          ])
        : [[<NoDataCard />]]

    return (
      <Slider
        onIndexChange={setCustomerOverviewSlideIndex}
        outerRef={pageWidthRef}
        cardList={cards}
      />
    )
  }, [customerSpendingEntities?.length, state.startDate, state.endDate])

  const plantCumulativeSpendingReportCardSlider = useMemo(() => {
    const cards =
      plantCumulativeSpendingEntities &&
      plantCumulativeSpendingEntities.length > 0
        ? plantCumulativeSpendingEntities.map((s: TSpendingEntity) => [
            <OverallSpendingChartWithPicker
              startDate={state.startDate}
              endDate={state.endDate}
              spendingData={s}
            />,
          ])
        : [[<NoDataCard />]]

    return (
      <Slider
        key={customerOverviewSlideIndex}
        outerRef={pageWidthRef}
        cardList={cards}
      />
    )
  }, [
    customerOverviewSlideIndex,
    plantCumulativeSpendingEntities?.length,
    state.startDate,
    state.endDate,
  ])

  const customerSpendingByMachineTypeCardSlider = useMemo(() => {
    const cards =
      customerSpendingEntities && customerSpendingEntities.length > 0
        ? customerSpendingEntities.map((s: TSpendingEntity) => {
            const aggregatedMachineTypeSpending =
              getAggregatedMachineTypeSpending(s, state.startDate, new Date())

            if (aggregatedMachineTypeSpending.length === 0) return null

            aggregatedMachineTypeSpending.forEach(mts => {
              mts.machineType = translateMachineTypeToName(mts.machineType)
            })

            return [
              <PieChartWithPicker
                title={`${s.name} - Spending by Machine Type`}
                spendingData={aggregatedMachineTypeSpending}
                showLegend
              />,
            ]
          })
        : [[<NoDataCard />]]

    let nonNullCards = cards.filter(c => c != null && c.some(d => d != null))
    if (nonNullCards.length === 0) nonNullCards = [[<NoDataCard />]]
    return (
      <Slider
        onIndexChange={setCustomerSpendingByMachineSlideIndex}
        outerRef={pageWidthRef}
        cardList={nonNullCards}
      />
    )
  }, [
    customerSpendingEntities?.length,
    state.startDate,
    state.endDate,
    state.spendings?.length,
  ])

  const plantSpendingByMachineTypeCardSlider = useMemo(() => {
    const cards =
      plantSpendingByMachineTypeSpendingEntities &&
      plantSpendingByMachineTypeSpendingEntities.length > 0
        ? plantSpendingByMachineTypeSpendingEntities.map(
            (s: TSpendingEntity) => {
              const aggregatedMachineTypeSpending =
                getAggregatedMachineTypeSpending(s, state.startDate, new Date())

              if (aggregatedMachineTypeSpending.length === 0) return null

              aggregatedMachineTypeSpending.forEach(mts => {
                mts.machineType = translateMachineTypeToName(mts.machineType)
              })

              return [
                <PieChartWithPicker
                  title={`${s.name} - Spending by Machine Type`}
                  spendingData={aggregatedMachineTypeSpending}
                  showLegend
                />,
              ]
            }
          )
        : [[<NoDataCard />]]

    let nonNullCards = cards.filter(c => c != null && c.some(d => d != null))
    if (nonNullCards.length === 0) nonNullCards = [[<NoDataCard />]]

    return (
      <Slider
        key={customerSpendingByMachineSlideIndex}
        outerRef={pageWidthRef}
        cardList={nonNullCards}
      />
    )
  }, [
    customerSpendingByMachineSlideIndex,
    plantSpendingByMachineTypeSpendingEntities?.length,
    state.startDate,
    state.endDate,
    state.spendings?.length,
  ])

  const CardGridWrapper: React.FC = props => {
    return <div className={Styles.CardWrapper}>{props.children}</div>
  }

  const budgetEntities = useMemo(() => {
    let s: TSpendingEntity[]
    if (state.selectedPlants.length > 0) {
      return state.spendings?.flatMap(spending => {
        return spending?.children.filter(child =>
          state?.selectedPlants?.includes(child.id)
        )
      })
    }
    return state.spendings
  }, [state.spendings, state.selectedPlants])

  const navigate = useNavigate()

  const isFinancialRestricted =
    profileContext.dependentData.userDetails.isFinancialRestricted

  if (isFinancialRestricted) {
    navigate('/404')
    return null
  }

  if (!profileContext.minimumProfileLoaded) {
    return <Loader />
  }

  const takingALongTimeToLoadMessage = (
    <div className={Styles.LongLoadMessage}>
      <span>This is taking longer than expected.</span>
      <span>
        Either our servers are busy or you have a lot of data to work through.
      </span>
      <span>Please be patient or limit your query.</span>
    </div>
  )

  const largeCustomerMessage = (
    <div className={Styles.LongLoadMessage}>
      <span>You've got a lot of stuff!</span>
      <span>
        You are a large customer and loading time is impacted by the amount of
        equipment in your query.
      </span>
      <span>Please be patient or limit your query.</span>
    </div>
  )

  return (
    <>
      {alertBanner}
      <div style={{ margin: '15px 0' }}>
        <FilterAccordion
          pageName="Financial Dashboard"
          onSubmit={handleFilterSubmission}
          onReset={handleResetClick}
          defaultValues={{
            ...profileContext.dependentData.filters.initialFilterState,
          }}
          filters={{
            organization: [
              OrganizationalFilterSet.Divisions,
              OrganizationalFilterSet.Customers,
              OrganizationalFilterSet.Plants,
            ],
          }}
        />
      </div>

      {!state.spendingLoaded && <Loader />}
      {!state.spendingLoaded &&
        takingALongTimeToLoad &&
        !isLargeCustomer() &&
        takingALongTimeToLoadMessage}
      {!state.spendingLoaded && isLargeCustomer() && largeCustomerMessage}
      {state.spendingLoaded && state.loadingError && <LoadingError />}
      {state.spendingLoaded && !state.loadingError && (
        <React.Fragment>
          <Grid xs={12} container justifyContent="center">
            <div className={`${Styles.ScoreCardContainer}`}>
              <CardGridWrapper>
                <AvailableBudgetCard spendingData={budgetEntities} />
              </CardGridWrapper>
              <CardGridWrapper>
                <AmountDueCard spendingData={state.spendings} />
              </CardGridWrapper>
              <CardGridWrapper>
                <EstimatedSpendCard spendingData={state.spendings} />
              </CardGridWrapper>
              <CardGridWrapper>
                <EstimatedSpendNextMonthCard spendingData={state.spendings} />
              </CardGridWrapper>
            </div>
            <div
              className={`${Styles.SectionPad} ${Styles.ScoreCardContainer}`}
            >
              {profileContext.dependentData.userDetails.isMultiCustomerUser && (
                <CardGridWrapper>
                  <TopSpendingCard
                    top={5}
                    spenderType="Customer"
                    spendingData={state.spendings.filter(
                      s =>
                        !state.selectedCustomerIDs ||
                        state.selectedCustomerIDs.length == 0 ||
                        state.selectedCustomerIDs.includes(s.id)
                    )}
                  />
                </CardGridWrapper>
              )}
              <CardGridWrapper>
                <TopSpendingCard
                  top={5}
                  spenderType="Plant"
                  spendingData={state.spendings
                    .flatMap(se => se.children)
                    .filter(
                      s =>
                        !state.selectedPlants ||
                        state.selectedPlants.length == 0 ||
                        state.selectedPlants.includes(s.id)
                    )}
                />
              </CardGridWrapper>
            </div>
            {
              //NOTE: Graphs currently disabled until further notice. Do not delete. Yes it exists in gitHub, but I have a feeling we'll lose it somehow. - SM
              /* <h3 className={Styles.SectionHeader}>Cumulative Sample Spending</h3>
            <hr className={Styles.SectionUnderline} />
            <Grid
              item
              xs={12}
              lg={6}
              justifyContent="center"
              className={Styles.GraphSection}
            >
              <section
                className={`${Styles.CenteredSection} ${Styles.SpendingOverviewChart}`}
              >
                {customerCumulativeSpendingReportCards}
              </section>
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              justifyContent="center"
              className={Styles.GraphSection}
            >
              <section
                className={`${Styles.CenteredSection} ${Styles.SpendingOverviewChart}`}
              >
                {plantCumulativeSpendingReportCardSlider}
              </section>
            </Grid>
            <h3 className={Styles.SectionHeader}>Comparative Spending</h3>
            <hr className={Styles.SectionUnderline} />
            <Grid
              item
              xs={12}
              lg={6}
              justifyContent="center"
              className={Styles.GraphSection}
            >
              <section
                className={`${Styles.CenteredSection} ${Styles.SpendingOverviewChart}`}
              >
                {customerSpendingByMachineTypeCardSlider}
              </section>
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              justifyContent="center"
              className={Styles.GraphSection}
            >
              <section
                className={`${Styles.CenteredSection} ${Styles.SpendingOverviewChart}`}
              >
                {plantSpendingByMachineTypeCardSlider}
              </section>
            </Grid>

            <h3 className={Styles.SectionHeader}>Purchase Order Analysis</h3>
            <hr className={Styles.SectionUnderline} />

                    {purchaseOrderComparison} */
            }
          </Grid>
        </React.Fragment>
      )}
    </>
  )
}

export default function FinancialsDashboardPage() {
  return (
    <AppLayout tab="financials">
      <FinancialsDashboard />
    </AppLayout>
  )
}
