import React, { ReactNode, useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Loader from 'components/Loader'

interface OwnProps {
  tableLoaded: boolean
  headerTitle: string
  headerActions: any[]
  minimizable?: boolean
  children?: ReactNode
}

const TableWrapper: React.FC<OwnProps> = ({
  tableLoaded,
  headerTitle,
  headerActions,
  children,
  minimizable,
}: OwnProps) => {
  const [expanded, setExpanded] = useState(true)

  const handleExpandPanel = () => setExpanded(!expanded)

  return (
    <>
      <Grid container direction="column" spacing={0}>
        <Grid
          container
          item
          direction="row"
          spacing={0}
          alignItems="flex-end"
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Box mt={5} mb={2}>
              <Typography variant="h3">{headerTitle}</Typography>
            </Box>
          </Grid>
          <Grid container justify="flex-end" item xs={6} sm={6} md={6} lg={6}>
            {headerActions?.map(actionComponent => (
              <Box mt={5} mb={2} ml={2} textAlign="right">
                {actionComponent}
              </Box>
            ))}
            {minimizable && (
              <Box
                mt={5}
                mb={2}
                ml={2}
                textAlign="right"
                style={{ cursor: 'pointer' }}
                onClick={handleExpandPanel}
              >
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Box>
            )}
          </Grid>
        </Grid>
        {(!minimizable || expanded) && (
          <Grid item xs={12}>
            {tableLoaded ? children : <Loader />}
          </Grid>
        )}
      </Grid>
      <hr />
    </>
  )
}

export default TableWrapper
