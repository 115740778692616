import {
  Button,
  Checkbox,
  createTheme,
  debounce,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { FormField } from 'components/FormField'
import Loader from 'components/Loader'
import moment from 'moment'
import {
  FormEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import APIService from 'services/api-service'
import {
  TAPIPlant,
  TBillingCustomer,
  TBillingInformation,
  TPlantQuery,
  TPurchaseOrder,
} from 'types/api'
import {
  getContactName,
  nameToFirstAndLast,
  stringIsNullOrWhitespace,
} from 'utils/string-utilities'
import { BooleanFormValue } from 'utils/FormUtilities/FormValue'
import { useProfile } from 'Contexts/ProfileContext'
import { getDefaultBillingContact } from 'utils/financial-utilities'
import { Autocomplete } from '@mui/material'
import { getPlantsByID } from 'utils/plant-utilities'
import { TLoading } from 'types/custom'
import {
  fileToBase64,
  getBytesFromMebibytes,
  getBytesFromMegabytes,
} from 'utils/file-utilities'
import { emailRegex } from 'utils/common-regex'
import { useFinancialIDSelectFilter } from 'components/filters/ConfiguredFilters/useFinancialIDSelectFilter'
import { MissingFinancialsErrorScreen } from 'components/Financials/StripeJS/SuppliesList/MissingFinancialsErrorScreen'
import { FetchError } from 'utils/Errors/FetchError'
const decimalNumberRegex = /^\$?([0-9]*[\.,][0-9]{0,2}|[0-9]+)$/
const startsWithDollarSignRegex = /^\$/
const alphanumericRegex = /^[A-Za-z0-9_]+$/

interface TModifiableData<T> {
  value: BooleanFormValue<T>
  required: boolean
}

interface TModifiableValue<T> {
  value: T
  modified: boolean
}

interface FormState {
  purchaseOrderNumber: TModifiableData<string>
  amount: TModifiableData<string>
  startDate: TModifiableData<Date>
  expirationDate: TModifiableData<Date>
  financialID: TModifiableData<string>
  comments: TModifiableData<string>
  address1: TModifiableData<string>
  address2: TModifiableData<string>
  state: TModifiableData<string>
  city: TModifiableData<string>
  zip: TModifiableData<string>
  country: TModifiableData<string>
}

interface TSubmissionStatus {
  submitting: boolean
  submittedSuccesfully: boolean | null
  error: string
}

type TFormData = {
  plantsWithFinancials: TAPIPlant[] | null
}

const defaultFormData = {
  plantsWithFinancials: null,
}

const defaultSubmissionStatus: TSubmissionStatus = {
  submitting: false,
  submittedSuccesfully: null,
  error: '',
}
const commentLengthLimit = 250
interface FormError {
  id: string
  message: string
}

const defaultPdfFile = new BooleanFormValue<File>(
  null,
  v => v != null && v.size <= getBytesFromMegabytes(10),
  'A purchase order pdf is required. Max size: 10 MB.'
)

export interface PurchaseOrderProps {
  handlePurchaseOrderSubmitted: (purchaseOrder: TPurchaseOrder) => Promise<void>
  handleModalClose: () => void
  canChooseDates: boolean
  fixedAmount?: number | undefined
  onMissingBillingInformation: () => void
  onLoadError: (error: FetchError) => void
}

function CreatePurchaseOrderModal({
  handlePurchaseOrderSubmitted,
  handleModalClose,
  canChooseDates,
  fixedAmount,
  onMissingBillingInformation,
  onLoadError,
}: PurchaseOrderProps) {
  const profileContext = useProfile()

  const [formState, setFormState] = useState<FormState>({} as FormState)

  const handleChange =
    (prop: keyof FormState) =>
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string
    ) =>
      handleModifyFormState(prop, event)

  const financialIDFilterObject = useFinancialIDSelectFilter({
    onChange: handleChange('financialID'),
    value: formState['financialID']?.value?.Value || '',
  })

  useEffect(() => {
    if (financialIDFilterObject.loaded && financialIDFilterObject.isError) {
      financialIDFilterObject.reset()
    }
  }, [])

  const selectedBillingContactAddress = useMemo(
    () =>
      profileContext.profile.billingCustomers
        ?.filter(b => b && b.financialID)
        .sort((a, b) =>
          a.inactive && b.inactive ? 0 : a.inactive && !b.inactive ? -1 : 1
        )
        .find(bc => bc.financialID === formState.financialID?.value?.Value)
        ?.billToContact?.address ??
      getDefaultBillingContact(profileContext)?.billToContact?.address,
    [
      profileContext.billingCustomersLoaded &&
        formState.financialID?.value?.Value,
    ]
  )

  useEffect(() => {
    const billingInformationLoadingError = financialIDFilterObject.isError

    const noBillingContactInformationAvailable =
      financialIDFilterObject.options.size === 0 &&
      profileContext.minimumProfileLoaded &&
      financialIDFilterObject.loaded

    if (billingInformationLoadingError) {
      onLoadError(financialIDFilterObject.error)
    } else if (noBillingContactInformationAvailable) {
      onMissingBillingInformation()
    }
  }, [
    financialIDFilterObject.options,
    financialIDFilterObject.loaded,
    profileContext.minimumProfileLoaded,
  ])

  useEffect(() => {
    if (
      financialIDFilterObject.loaded &&
      selectedBillingContactAddress &&
      !useDefaultBillingContact.modified
    ) {
      setUseDefaultBillingContact({ value: true, modified: false })
    }
  }, [financialIDFilterObject.loaded, selectedBillingContactAddress])

  const handleModifyFormState = (
    prop: keyof FormState,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string
  ) => {
    let value: string
    if (typeof event === 'string') {
      value = event
    } else {
      value = event.target.value
    }

    if (formState[prop]) {
      formState[prop].value.Value = value
      setFormState({ ...formState, [prop]: formState[prop] })
    }
  }

  const getDefaultFormStateValues = (): FormState => {
    const fs = {
      purchaseOrderNumber: {
        value: new BooleanFormValue<string>(
          '',
          value =>
            !stringIsNullOrWhitespace(value) && alphanumericRegex.test(value),
          'Purchase Order Number is required and may contain only alphanumeric characters and underscores.'
        ),
        required: true,
      },

      amount: {
        value: new BooleanFormValue<string>(
          '',
          v => !stringIsNullOrWhitespace(v) && decimalNumberRegex.test(v),
          'Amount is required and must be must be a valid currency format.'
        ),
        required: true,
      },

      startDate: {
        value: new BooleanFormValue(
          moment().toDate(),
          (v, c) => (c ? v <= c : !!v),
          v => (!v ? 'Required' : null)
        ),
        required: true,
      },
      expirationDate: {
        value: new BooleanFormValue(
          moment().add(5, 'y').toDate(),
          (v, c) => (c ? v >= c : !!v),
          v => (!v ? 'Required' : null)
        ),
        required: true,
      },
      financialID: {
        value: new BooleanFormValue<string>(''),
        required: true,
      },
      comments: { value: new BooleanFormValue<string>(''), required: false },
      address1: {
        value: new BooleanFormValue<string>(
          '',
          v => !stringIsNullOrWhitespace(v)
        ),
        required: true,
      },
      address2: { value: new BooleanFormValue<string>(''), required: false },
      state: {
        value: new BooleanFormValue<string>(
          '',
          v => !stringIsNullOrWhitespace(v)
        ),
        required: true,
      },
      city: {
        value: new BooleanFormValue<string>(
          '',
          v => !stringIsNullOrWhitespace(v)
        ),
        required: true,
      },
      zip: {
        value: new BooleanFormValue<string>(
          '',
          v => !stringIsNullOrWhitespace(v)
        ),
        required: true,
      },
      country: {
        value: new BooleanFormValue<string>(
          '',
          v => !stringIsNullOrWhitespace(v)
        ),
        required: true,
      },
    }

    const newFormState = getFormStateWithDefaultBillingContactValues(fs)
    return newFormState
  }

  useEffect(() => {
    setFormState({ ...getDefaultFormStateValues() })
  }, [])

  const [pdfFile, setPdfFile] = useState<TModifiableData<File>>({
    required: true,
    value: defaultPdfFile,
  })
  const validPdfFile =
    pdfFile.value.Value && pdfFile.value.Value.type == 'application/pdf'
  const [useDefaultBillingContact, setUseDefaultBillingContact] = useState<
    TModifiableValue<boolean>
  >({ value: false, modified: false })
  const [formErrors, setFormErrors] = useState<Array<FormError>>([])

  // const defaultBillingContact = useMemo(
  //   () => getDefaultBillingContact(profileContext),
  //   [profileContext]
  // )

  const formErrorMessageStyle: React.CSSProperties = {
    fontSize: 12,
    marginLeft: 5,
    marginTop: 5,
  }

  const handleFormValidation = (
    errorEvenIfNotModified?: boolean | undefined
  ) => {
    const formErrors: Array<FormError> = []
    if (!formState) return

    const thingsThatRequireValidation = { ...formState, pdfFile }
    for (let key in thingsThatRequireValidation) {
      let formStateValue: TModifiableData<any>
      let valueIsUnacceptable = false

      if ((key as keyof FormState) === 'startDate') {
        formStateValue = thingsThatRequireValidation[
          key
        ] as TModifiableData<Date>

        valueIsUnacceptable =
          formStateValue.required &&
          (formStateValue.value.Modified || errorEvenIfNotModified) &&
          !formStateValue.value.isValid(formState.expirationDate?.value?.Value)
      } else if ((key as keyof FormState) === 'expirationDate') {
        formStateValue = thingsThatRequireValidation[
          key
        ] as TModifiableData<Date>

        valueIsUnacceptable =
          formStateValue.required &&
          (formStateValue.value.Modified || errorEvenIfNotModified) &&
          !formStateValue.value.isValid(formState.startDate?.value?.Value)
      } else {
        formStateValue = thingsThatRequireValidation[
          key
        ] as TModifiableData<unknown>

        valueIsUnacceptable =
          formStateValue.required &&
          (formStateValue.value.Modified || errorEvenIfNotModified) &&
          !formStateValue.value.isValid()
      }

      if (
        pdfFile.value.Value &&
        pdfFile.value.Value?.type !== 'application/pdf'
      ) {
        formErrors.push({
          id: 'pdfFile',
          message: 'File must be a .PDF',
        })
      }

      if (valueIsUnacceptable) {
        formErrors.push({
          id: key,
          message: formStateValue.value.getValidationMessage(),
        })
      }
    }

    if (errorEvenIfNotModified) setFormValuesToModified()
    setFormErrors(formErrors)
    return !(formErrors.length > 0)
  }

  const setFormValuesToModified = () => {
    Object.keys(formState).forEach((key: keyof FormState) => {
      const formValue = formState[key].value
      if (formValue) formValue.Modified = true
    })

    if (pdfFile.value) {
      pdfFile.value.Modified = true
      setPdfFile(prev => ({ ...pdfFile }))
    } else {
      const newPdfFile = defaultPdfFile
      newPdfFile.Modified = true

      setPdfFile({ required: true, value: newPdfFile })
    }
    setFormState(prev => ({ ...prev }))
  }

  useEffect(() => {
    handleFormValidation()
  }, [pdfFile, formState])

  //Update the form state with billing contact information
  const getFormStateWithDefaultBillingContactValues = (fs: FormState) => {
    Object.keys(fs).forEach((key: keyof FormState) => {
      if (
        selectedBillingContactAddress &&
        Object.keys(selectedBillingContactAddress).includes(key.toString())
      ) {
        let value = selectedBillingContactAddress[key]
        if (key == 'country') value = value || 'USA'
        fs[key].value.Value = value
      }
    })

    return fs
  }
  useEffect(() => {
    if (useDefaultBillingContact.value) {
      const newFormState =
        getFormStateWithDefaultBillingContactValues(formState)
      setFormState(prev => ({ ...prev, ...newFormState }))
    }
  }, [useDefaultBillingContact, selectedBillingContactAddress])

  useEffect(() => {
    handleFormValidation()
  }, [])

  const formatSubmittedAmount = (amount: string) => {
    const amountStartsWithCurrencySign = startsWithDollarSignRegex.test(
      formState.amount.value.Value
    )

    let submittedAmount = amountStartsWithCurrencySign
      ? amount.slice(1)
      : amount
    submittedAmount = submittedAmount.replace(',', '.')

    return Number(submittedAmount)
  }

  const [submitting, setSubmitting] = useState<TSubmissionStatus>({
    ...defaultSubmissionStatus,
  })

  const handleSubmitPurchaseOrder = async (
    event: FormEvent<HTMLFormElement>
  ) => {
    const isValid = handleFormValidation(true)
    event.preventDefault()
    if (!isValid) return

    const {
      financialID,
      amount,
      comments,
      address1,
      address2,
      city,
      state,
      zip,
      country,
      purchaseOrderNumber,
      startDate,
      expirationDate,
    } = formState

    const document = await fileToBase64(pdfFile.value.Value)

    const post: TPurchaseOrder = {
      financialID: financialID.value.Value,
      customerPONote: comments.value.Value,
      customerPONumber: purchaseOrderNumber.value.Value,
      amount: formatSubmittedAmount(amount.value.Value),
      document,
      startDate: startDate.value.Value,
      expirationDate: expirationDate.value.Value,
      billingAddress: {
        address1: address1.value.Value,
        address2: address2.value.Value,
        city: city.value.Value,
        zip: zip.value.Value,
        state: state.value.Value,
        country: country.value.Value,
        salesTaxCode: null,
      },
    }

    try {
      setSubmitting({ submitting: true, error: '', submittedSuccesfully: null })
      await handlePurchaseOrderSubmitted(post)
      setSubmitting(prev => ({
        ...prev,
        submitting: false,
        submittedSuccesfully: true,
      }))
    } catch (err) {
      const error = err as Error
      setSubmitting({
        submitting: false,
        error:
          'An error has occurred' +
          (error.message ? ': ' : '!') +
          error.message,
        submittedSuccesfully: false,
      })
    }
  }

  const onModalClose = () => {
    cleanup()
    handleModalClose()
  }

  const cleanup = () => {
    for (let key in formState) formState[key].value?.reset()
    pdfFile.value?.reset()
    setSubmitting({ ...defaultSubmissionStatus })
  }

  useEffect(() => {
    const arePlantsWithFinancialIDs = financialIDFilterObject.options.size > 0
    if (
      financialIDFilterObject.loaded &&
      (fixedAmount || arePlantsWithFinancialIDs)
    ) {
      const formattedAmount = fixedAmount ? `$${fixedAmount.toFixed(2)}` : ''
      const financialID = arePlantsWithFinancialIDs
        ? Array.from(financialIDFilterObject.options)[0][0]
        : ''

      setFormState(prev => ({
        ...prev,
        amount: {
          ...prev.amount,
          value: new BooleanFormValue<string>(
            formattedAmount,
            v => decimalNumberRegex.test(v),
            'Amount is required and must be must be a valid currency format.'
          ),
          required: true,
        },
        financialID: {
          ...prev.financialID,
          value: new BooleanFormValue<string>(financialID),
          required: true,
        },
      }))
    }
  }, [
    fixedAmount,
    financialIDFilterObject.loaded,
    financialIDFilterObject.options,
  ])

  useEffect(() => {
    return cleanup
  }, [])

  const showFinancialIDInput =
    financialIDFilterObject.loaded && financialIDFilterObject?.options?.size > 1

  const financialIDSelect = (
    <FormControl fullWidth>
      <InputLabel id={financialIDFilterObject.labelID}>Plants *</InputLabel>
      {financialIDFilterObject.FinancialIDSelectFilter}
    </FormControl>
  )

  const submissionPage = (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Loader />
      <p>Submitting purchase order...</p>
    </div>
  )

  const loadingPage = (
    <>
      <h3 style={{ marginBottom: '35px', width: '100%', textAlign: 'left' }}>
        Purchase Order Information
      </h3>
      <Loader />
    </>
  )

  const informationPage = (
    <>
      <h3 style={{ marginBottom: '35px', width: '100%', textAlign: 'left' }}>
        Purchase Order Information
      </h3>

      <Grid container direction="row" spacing={2}>
        <Grid
          container
          justifyContent="center"
          direction="row"
          item
          spacing={2}
          xs={12}
          md={6}
        >
          <Grid xs={12} sm={6} md={12} item container>
            <FormField
              id="purchaseOrderNumber"
              formErrors={formErrors}
              errorMessageStyle={formErrorMessageStyle}
              fullWidth
            >
              <TextField
                label="Purchase Order Number *"
                name="purchaseOrderNumber"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={formState.purchaseOrderNumber?.value.Value}
                type="text"
                onChange={handleChange('purchaseOrderNumber')}
                onBlur={event => handleFormValidation()}
                inputProps={{ maxlength: 20 }}
                fullWidth
              />
            </FormField>
          </Grid>
          <Grid xs={12} sm={6} md={12} item container>
            <FormField
              id="amount"
              formErrors={formErrors}
              errorMessageStyle={formErrorMessageStyle}
              fullWidth
            >
              <TextField
                label="Dollar Amount (USD) *"
                name="amount"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxlength: 20 }}
                disabled={!!fixedAmount}
                value={formState.amount?.value?.Value || fixedAmount || ''}
                fullWidth
                onChange={e => handleChange('amount')(e)}
                onBlur={event => handleFormValidation()}
              />
            </FormField>
          </Grid>

          {showFinancialIDInput && (
            <Grid xs={12} sm={6} md={12} item container>
              <FormField
                id="financialID"
                formErrors={formErrors}
                errorMessageStyle={formErrorMessageStyle}
                fullWidth
              >
                {financialIDSelect}
              </FormField>
            </Grid>
          )}
          {canChooseDates ? (
            <Grid xs={12} sm={6} md={12} item container>
              <FormField
                id="startDate"
                formErrors={formErrors}
                errorMessageStyle={formErrorMessageStyle}
                fullWidth
              >
                <KeyboardDatePicker
                  value={formState.startDate?.value.Value ?? null}
                  onChange={date => {
                    formState.startDate.value.Value = date?.toDate()
                    setFormState(prev => ({
                      ...prev,
                    }))
                  }}
                  required
                  clearable
                  autoOk
                  minDate={new Date()}
                  maxDate={formState?.expirationDate?.value?.Value || undefined}
                  label="Start Date"
                  format="MM/DD/yyyy"
                  fullWidth
                />
              </FormField>
            </Grid>
          ) : null}
          {canChooseDates ? (
            <Grid xs={12} sm={6} md={12} item container>
              <FormField
                id="expirationDate"
                formErrors={formErrors}
                errorMessageStyle={formErrorMessageStyle}
                fullWidth
              >
                <KeyboardDatePicker
                  value={formState.expirationDate?.value.Value ?? null}
                  onChange={date => {
                    formState.expirationDate.value.Value = date?.toDate()
                    setFormState(prev => ({
                      ...prev,
                    }))
                  }}
                  required
                  clearable
                  minDate={formState?.startDate?.value?.Value || new Date()}
                  autoOk
                  label="End Date"
                  format="MM/DD/yyyy"
                  fullWidth
                />
              </FormField>
            </Grid>
          ) : null}
          <Grid xs={12} sm={6} md={12} item container>
            <FormField
              id="pdfFile"
              formErrors={formErrors}
              errorMessageStyle={formErrorMessageStyle}
              fullWidth
            >
              <Button
                variant="contained"
                component="label"
                color="primary"
                fullWidth
                style={{ height: '50px' }}
              >
                {!!pdfFile?.value?.Value && validPdfFile
                  ? pdfFile.value?.Value?.name
                  : 'Upload PDF *'}
                <input
                  hidden
                  accept="application/pdf"
                  type="file"
                  name="pdfFile"
                  id={'pdfFile'}
                  onChange={({ target }) => {
                    pdfFile.value.Value = target.files.item(0)

                    setPdfFile({
                      ...pdfFile,
                    })
                  }}
                />
              </Button>
            </FormField>
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          direction="row"
          xs={12}
          md={6}
        >
          <FormField
            id="comments"
            formErrors={formErrors}
            errorMessageStyle={formErrorMessageStyle}
            fullWidth
          >
            <TextField
              label="Comments"
              name="comments"
              variant="outlined"
              onChange={event => {
                const comment = event.target.value
                if (comment.length > commentLengthLimit) return
                handleChange('comments')(comment)
              }}
              value={formState.comments?.value.Value}
              multiline
              fullWidth
              minRows={9}
            />
          </FormField>
        </Grid>
      </Grid>

      <h3
        style={{
          marginTop: '50px',
          width: '100%',
          textAlign: 'left',
          marginBottom: '10px',
        }}
      >
        Purchase Order Ship-To Information
      </h3>
      <div
        style={{
          marginBottom: '30px',
          width: '100%',
          textAlign: 'left',
          fontSize: '0.9rem',
        }}
      >
        <span style={{ display: 'block' }}>
          Shipping information for the submitted purchase order. This is{' '}
          <b>not</b> the shipping address for the supplies.
        </span>
        <span style={{ display: 'block' }}>
          Billing information, such as the bill-to address, is determined by the
          billing group created during account setup. To modify this
          information, please contact the customer support team via the support
          chat or by contacting{' '}
          <a href={`mailto:${process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL}`}>
            customer support
          </a>{' '}
          via email.
        </span>
      </div>
      <Grid container xs={12} justifyContent="flex-start">
        <FormControlLabel
          style={{ marginBottom: '25px' }}
          control={
            <Checkbox
              defaultChecked
              onChange={event =>
                setUseDefaultBillingContact({
                  value: event.target.checked,
                  modified: true,
                })
              }
              checked={useDefaultBillingContact?.value}
            />
          }
          label="Use Default Ship-To Information"
        />
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        direction="row"
        spacing={2}
      >
        {/* <Grid xs={12} sm={6} md={4} item container>
          <FormField
            id="name"
            formErrors={formErrors}
            errorMessageStyle={formErrorMessageStyle}
            fullWidth
          >
            <TextField
              label="Name *"
              name="name"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={formState.name?.value.Value}
              onChange={handleChange('name')}
              onBlur={event => handleFormValidation()}
              fullWidth
              disabled={useDefaultBillingContact}
            />
          </FormField>
        </Grid>

        <Grid xs={12} sm={6} md={4} item container>
          <FormField
            id="email"
            formErrors={formErrors}
            errorMessageStyle={formErrorMessageStyle}
            fullWidth
          >
            <TextField
              label="Email *"
              name="email"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={formState.email?.value.Value}
              onChange={handleChange('email')}
              onBlur={event => handleFormValidation()}
              fullWidth
              disabled={useDefaultBillingContact}
            />
          </FormField>
        </Grid> */}
        <Grid xs={12} sm={6} md={4} item container>
          <FormField
            id="address1"
            formErrors={formErrors}
            errorMessageStyle={formErrorMessageStyle}
            fullWidth
          >
            <TextField
              label="Address 1 *"
              name="address1"
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={formState.address1?.value.Value}
              onChange={handleChange('address1')}
              onBlur={event => handleFormValidation()}
              disabled={useDefaultBillingContact.value}
            />
          </FormField>
        </Grid>

        <Grid xs={12} sm={6} md={4} item container>
          <FormField
            id="address2"
            formErrors={formErrors}
            errorMessageStyle={formErrorMessageStyle}
            fullWidth
          >
            <TextField
              label="Address 2"
              name="address2"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={formState.address2?.value.Value}
              onChange={handleChange('address2')}
              onBlur={event => handleFormValidation()}
              disabled={useDefaultBillingContact.value}
              fullWidth
            />
          </FormField>
        </Grid>
        <Grid xs={12} sm={6} md={4} item container>
          <FormField
            id="city"
            formErrors={formErrors}
            errorMessageStyle={formErrorMessageStyle}
            fullWidth
          >
            <TextField
              label="City *"
              name="city"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={formState.city?.value.Value}
              onChange={handleChange('city')}
              onBlur={event => handleFormValidation()}
              disabled={useDefaultBillingContact.value}
              fullWidth
            />
          </FormField>
        </Grid>
        <Grid xs={12} sm={6} md={4} item container>
          <FormField
            id="state"
            formErrors={formErrors}
            errorMessageStyle={formErrorMessageStyle}
            fullWidth
          >
            <TextField
              label="State *"
              name="state"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={formState.state?.value.Value}
              onChange={handleChange('state')}
              onBlur={event => handleFormValidation()}
              disabled={useDefaultBillingContact.value}
              fullWidth
            />
          </FormField>
        </Grid>
        <Grid xs={12} sm={6} md={4} item container>
          <FormField
            id="country"
            formErrors={formErrors}
            errorMessageStyle={formErrorMessageStyle}
            fullWidth
          >
            <TextField
              label="Country *"
              name="country"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={formState.country?.value.Value}
              onChange={handleChange('country')}
              onBlur={event => handleFormValidation()}
              disabled={useDefaultBillingContact.value}
              fullWidth
            />
          </FormField>
        </Grid>
        <Grid xs={12} sm={6} md={4} item container>
          <FormField
            id="zip"
            formErrors={formErrors}
            errorMessageStyle={formErrorMessageStyle}
            fullWidth
          >
            <TextField
              label="Zip Code *"
              name="zip"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={formState.zip?.value.Value}
              onChange={handleChange('zip')}
              onBlur={event => handleFormValidation()}
              disabled={useDefaultBillingContact.value}
            />
          </FormField>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        direction="row"
        spacing={2}
        style={{ marginTop: '20px' }}
      >
        <Grid xs={6} justifyContent="flex-end" item container>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            type="submit"
            style={{ height: '50px' }}
          >
            SUBMIT
          </Button>
        </Grid>
      </Grid>
    </>
  )

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
      }}
      onSubmit={event => handleSubmitPurchaseOrder(event)}
    >
      <Grid container direction="row" xs={12}></Grid>
      {!profileContext.minimumProfileLoaded ||
      financialIDFilterObject?.isLoading
        ? loadingPage
        : submitting.submitting
        ? submissionPage
        : informationPage}
    </form>
  )
}

export default CreatePurchaseOrderModal
