import React from 'react'
import CapacitorFileService from '../services/capacitor-file-service'

const CapacitorDownloadLink = ({ url, children, ...props }) => {
  const handleClick = async event => {
    event.preventDefault()

    const fileName = url.substring(url.lastIndexOf('/') + 1)
    const response = await fetch(url)
    const mimeType = response.headers.get('Content-Type')
    const blob = await response.blob()

    await CapacitorFileService.saveAndOpen(
      fileName,
      mimeType,
      blob.arrayBuffer()
    )
  }

  return (
    <button
      style={{
        border: '0px',
        padding: '0px',
        margin: '0px',
        display: 'inline',
        cursor: 'pointer',
      }}
      className="link"
      onClick={handleClick}
      {...props}
    >
      {children}
    </button>
  )
}

export default CapacitorDownloadLink
