import React, { forwardRef, useImperativeHandle } from 'react'
import { Grid, Tooltip } from '@material-ui/core'

export interface TInvoice {
  index: number
  invoiceNumber: string
  amount: number
  status: PaymentStatusEnum
  message: string
  receiptNumber?: string
}

export enum PaymentStatusEnum {
  Unpaid,
  Done,
  Error,
}

interface OwnProps {
  invoices: TInvoice[]
}

const InvoiceList: React.FC<OwnProps> = ({ invoices }: OwnProps, ref) => {
  useImperativeHandle(ref, () => ({
    getTotal: () => {
      return (
        invoices
          ?.filter(y => y.status !== PaymentStatusEnum.Done)
          .map(x => x.amount)
          .reduce((prev, curr) => prev + curr, 0) || 0
      )
    },
  }))

  const getTotal = () =>
    invoices?.map(x => x.amount).reduce((prev, curr) => prev + curr, 0) || 0

  const getTotalUnpaid = () =>
    invoices
      ?.filter(y => y.status !== PaymentStatusEnum.Done)
      ?.map(x => x.amount)
      .reduce((prev, curr) => prev + curr, 0) || 0

  return (
    <>
      <Grid container direction="column"  style={{ height: 400, overflowY: 'auto' }}>
        <Grid item>
          <h4>Invoices</h4>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={4}>
            <strong>Invoice #</strong>
          </Grid>
          <Grid container xs={4} justify="flex-end">
            <strong>Amount</strong>
          </Grid>
          <Grid container xs={4} justify="flex-end">
            <strong>Status</strong>
          </Grid>
        </Grid>

        <Grid container>
          <Grid container style={{ height: 'fit-content' }}>
            {invoices
              ?.sort((a, b) => a.index - b.index)
              .map(({ invoiceNumber, amount, status, message }) => (
                <Grid container style={{ height: 20 }}>
                  <Grid item xs={4}>
                    {invoiceNumber}
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: 'right' }}>

                    {Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(Number(amount))}
                    
                  </Grid>
                  <Grid
                    container
                    xs={4}
                    justify="flex-end"
                  >
                    {status === PaymentStatusEnum.Error ? (
                      <Tooltip title={message}>
                        <strong
                          style={{
                            color: 'red',
                          }}
                        >
                          {PaymentStatusEnum[status]}
                        </strong>
                      </Tooltip>
                    ) : (
                      <strong
                        style={{
                          color:
                            status === PaymentStatusEnum.Done
                              ? 'green'
                              : 'darkgrey',
                        }}
                      >
                        {PaymentStatusEnum[status]}
                      </strong>
                    )}
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
        
        <Grid container direction="row" style={{borderTop: '2px solid black', marginTop: 10}}>
          
          <Grid item xs={6}>
          
            <strong style={{ fontSize: 23 }}>Total</strong>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <span style={{ fontSize: 23 }}>
              {Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(Number(getTotal()))}
            </span>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={6}>
            <strong style={{ fontSize: 16 }}>Unpaid</strong>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <span style={{ fontSize: 16 }}>
              {Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(Number(getTotalUnpaid()))}
            </span>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default forwardRef(InvoiceList)
