import { createStyles, makeStyles } from '@material-ui/core'

export const useCardStyles = makeStyles(() =>
  createStyles({
    card: {
      '& .MuiGrid-spacing-xs-1': {
        margin: '0px',
      },
      '& .condition-box': {
        marginTop: '50px',
        '& .condition-title': {
          textAlign: 'center',
          paddingBottom: '20px',
        },
        '& .condition-value': {
          textAlign: 'center',
          paddingBottom: '10px',
        },
      },
      '& .info-value': {
        fontWeight: 700,
      },
      marginBottom: '10px',
    },
    cardTotals: {
      backgroundColor: 'lightgray',
    },
  })
)
