import APIService from 'services/api-service'
import { CancellablePromise } from 'utils/CancellablePromise'
import { FetchError } from 'utils/Errors/FetchError'

export abstract class Query<TData, TReturn> {
  public abstract get Url(): string
  private data: TData | null
  public get Data() {
    return this.data
  }
  public set Data(data: TData | null) {
    this.data = data
  }

  protected fetcher:
    | null
    | ((_: string) => CancellablePromise<TReturn> | Promise<TReturn>)

  constructor(
    data: TData | null = null,
    fetcher:
      | null
      | ((_: string) => CancellablePromise<TReturn> | Promise<TReturn>) = null
  ) {
    this.data = data
    this.fetcher = fetcher
  }

  async Get() {
    if (!this.fetcher) throw new Error('No fetch mechanism provided.')
    return this.fetcher(this.Url)
  }
}

export abstract class DatalessQuery<TReturn> extends Query<null, TReturn> {
  public get Data() {
    return null
  }

  constructor(
    fetcher:
      | null
      | ((_: string) => CancellablePromise<TReturn> | Promise<TReturn>) = null
  ) {
    super(null, fetcher)
  }
}

export async function getQueryResultFromCacheOrAPI<TBody, TReturn>(
  classConstructor: new (
    body: TBody,
    fetcher: (_: string) => CancellablePromise<TReturn> | Promise<TReturn>
  ) => Query<TBody, TReturn>,
  body: TBody,
  cache: Cache,
  addToCache: (url: string, data: any, timeoutMS: number) => Promise<void>,
  cacheTimeoutMS: number
) {
  try {
    const query = new classConstructor(body, url =>
      APIService.cacheGet(url, cache)
    )
    const data = await query.Get()
    return data
  } catch (error) {
    try {
      if (error instanceof FetchError && error.status === 404) {
        const query = new classConstructor(body, url => APIService.apiGet(url))
        const data = await query.Get()
        await addToCache(query.Url, data, cacheTimeoutMS)
        return data
      } else {
        throw error
      }
    } catch (apiError) {
      throw apiError
    }
  }
}

export async function getDatalessQueryResultFromCacheOrAPI<TReturn>(
  classConstructor: new (
    fetcher: (_: string) => CancellablePromise<TReturn> | Promise<TReturn>
  ) => DatalessQuery<TReturn>,
  cache: Cache,
  addToCache: (url: string, data: any, timeoutMS: number) => Promise<void>,
  cacheTimeoutMS: number
) {
  try {
    const query = new classConstructor(url => APIService.cacheGet(url, cache))
    const data = await query.Get()
    return data
  } catch (error) {
    if (error instanceof FetchError && error.status === 404) {
      const query = new classConstructor(url => APIService.apiGet(url))

      const data = await query.Get()
      await addToCache(query.Url, data, cacheTimeoutMS)
      return data
    }
  }
}
