import React from "react";

export const GridEditContext = React.createContext<{
    onRowAction: (options: {
      rowIndex: number;
      operation: "save" | "remove" | "add";
      dataItem?: any;
    }) => void;
    setEditIndex: (rowIndex: number | undefined) => void;
    editIndex: number | undefined;
    dataItemKey: string
  }>({} as any);