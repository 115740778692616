import { CapitalizeFirst } from './string-utilities'

export function TestToTitle(test: string): string {
  switch (test) {
    case 'isiSpc': {
      return 'Elemental Spectroscopy (ppm) ASTM D5185 Mod (-) indicates below detection limit'
    }
    case 'isiIr24KtMethod': {
      return 'FTIR Spectroscopy (Indexing Numbers) IWI-110 24KT Method'
    }
    case 'isiIrJoapMethod': {
      return 'FTIR Spectroscopy (Indexing Numbers) ASTM E2412'
    }
    case 'isiIrTurbineMethod': {
      return 'FTIR Spectroscopy (Indexing Numbers) IWI-110 Turbine Method'
    }
    case 'isiPc': {
      return 'Particle Count (particles per ml) ISO 4406:99'
    }
    case 'isiPcl': {
      return 'Optical Particle Count (particles per ml) ISO 4406:99'
    }
    case 'isiV40': {
      return 'Viscosity (centistokes) ASTM D445'
    }
    case 'isiV100': {
      return 'Viscosity (centistokes) ASTM D445'
    }
    case 'isiTan': {
      return 'Acid Number (mg KOH/g) ASTM D974'
    }
    case 'isiTbn': {
      return 'Base Number (mg KOH/g) † - ASTM  D4739   ‡ - IWI-390'
    }
    case 'isiWp': {
      return 'Ferrous Wear Concentration (ppm)'
    }
    case 'isiColor': {
      return 'Color (clr) ASTM D 1500'
    }
    case 'isiRpvot': {
      return 'Rotating Pressure Vessel Oxidation Test (min.) ASTM D2272'
    }
    case 'isiRust': {
      return 'Rust (Pass/Fail) ASTM D665 A'
    }
    case 'isiMpc': {
      return 'Membrane Patch Colorimetry (%)'
    }
    case 'isiUc': {
      return 'Ultra Centrifuge (%)'
    }
    case 'isiRa': {
      return 'Reserve ALK (mL HCL)'
    }
    case 'isiFd': {
      return 'Fuel Dilution by GC (%)'
    }
    case 'isiPh': {
      return 'pH (pH)'
    }
    case 'isiPhd': {
      return 'pH (pH)'
    }
    case 'isiGlycol': {
      return 'Glycol (Pos/Neg) ASTM D2982'
    }
    case 'isiFlashpoint': {
      return 'Flash Point (°C) D92'
    }
    case 'isiRuler': {
      return 'Ruler (%)'
    }
    case 'isiKf': {
      return 'Water (%) a-ASTM D6304A b-IWI-133 c-ASTM D6304C d-IWI-134* e-IWI-135*( f-IWI-136* g-Crackle h-IWI-370*'
    }
    default: {
      return ''
    }
  }
}

export function FieldToSubtitle(
  test: string,
  field: string,
  other?: string
): string {
  switch (field) {
    case 'mineral': {
      return 'Mineral Oil Cont.'
    }
    case 'sullube': {
      return 'PAG / Ester Cont.'
    }
    case 'cleaner': {
      return 'Cleaner Cont.'
    }
    case 'oxidProd': {
      return 'Oxidation'
    }
    case 'nitraProd': {
      return 'Nitration'
    }
    case 'gasDil': {
      return 'Fuel Dilution'
    }
    case 'phosphateEsterOX': {
      return 'Phosphate Ester'
    }
    case 'polyolEster': {
      return 'Polyol Ester'
    }
    case 'phosphateEsterOH': {
      return 'Phosphate Ester Alcohol'
    }
    case 'antiWear': {
      return 'Anti Wear'
    }
    case 'product': {
      return 'Other Fluid'
    }
    case 'acidUnsatEst': {
      return 'Thermal Event Acid'
    }
    case 'aCIDOxid': {
      return 'Acid Oxidation'
    }
    case 'oxidationEster': {
      return 'Ester'
    }
    case 'aromatics': {
      return 'Aromatic Additive'
    }
    case 'olefins': {
      return 'Base Oil Aromatic'
    }
    case 'amines': {
      return 'Amine Antioxidants'
    }
    case 'phenolicOH': {
      return 'Phenolic Antioxidants'
    }
    case 'g2': {
      return '> 4 Micron'
    }
    case 'g4': {
      return '> 4 Micron'
    }
    case 'g5': {
      return '> 6 Micron'
    }
    case 'g6': {
      return '> 6 Micron'
    }
    case 'g14': {
      return '> 14 Micron'
    }
    case 'g15': {
      return '> 14 Micron'
    }
    case 'g50': {
      return '> 50 Micron'
    }
    case 'g68': {
      return '> 68 Micron'
    }
    case 'g100': {
      return '> 100 Micron'
    }
    case 'iso': {
      return 'ISO Code'
    }
    case 'results': {
      switch (test) {
        case 'isiV40': {
          return 'Viscosity @ 40C'
        }
        case 'isiV100': {
          return 'Viscosity @ 100C'
        }
        case 'isiTan': {
          return 'Acid Number'
        }
        case 'isiTbn': {
          return 'Base Number'
        }
        case 'isiColor': {
          return 'Color'
        }
        case 'isiRust': {
          return 'Rust'
        }
        case 'isiRa': {
          return 'Reserve Alkalinity'
        }
        case 'isiFd': {
          return 'Fuel Dilution'
        }
        case 'isiPh': {
          return 'pH'
        }
        case 'isiPhd': {
          return 'pH (Dow)'
        }
        case 'isiGlycol': {
          return 'Glycol'
        }
        case 'isiFlashpoint': {
          return 'Flash Point'
        }
        case 'isiKf': {
          return 'Water'
        }
        default: {
          return CapitalizeFirst(field)
        }
      }
    }
    case 'wPC': {
      return 'Ferrous Wear Concentration'
    }
    case 'mPC': {
      return 'Membrane Patch Colorimetry'
    }
    case 'uC': {
      return 'Ultra Cetrifuge'
    }
    case 'amine': {
      return other === 'Amine Peak Area'
        ? 'Amine Peak Area'
        : 'Amine % Remaining'
    }
    case 'phenolic': {
      return other === 'Phenolic Peak Area'
        ? 'Phenolic Peak Area'
        : 'Phenolic % Remaining'
    }
    case 'zddp': {
      return other === 'zddp Peak Area' ? 'zddp Peak Area' : 'zddp % Remaining'
    }
    default: {
      return field ? CapitalizeFirst(field) : ''
    }
  }
}
