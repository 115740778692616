import React, { useCallback, useEffect, useState } from 'react'
import { Button, Hidden, TablePagination } from '@material-ui/core'
import { GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid'

import {
    GridToolbar,
  } from "@progress/kendo-react-grid";

import { Product, TProductPricingEditing, TShipmentInformation } from 'types/api'
import TableGrid from 'components/TableGrid'
import { TProfile } from 'services/api-service'
import { DataResult, process, State } from "@progress/kendo-data-query";

import { GridEditContext } from 'Contexts/GridEditContext';
import { CommandCell, CurrencyCell, GridInlineFormRow, NumberCell, rowRender, TextCell } from 'components/KendoUIGridEditForm';

export type TProps = {
  pricings: TProductPricingEditing[]
  offline?: boolean,
  onPricingChange: (newPricings: TProductPricingEditing[]) => void
}

const ProductPricingDetailEditingTable: React.FC<TProps> = ({
  pricings,
    offline,
    onPricingChange 
}: TProps) => {

  const [dataState] = React.useState<State>({
    sort: [{ field: "minimumQuantity", dir: "asc" }],
  });

  // Form data index is used as an alternative to ID for rows after data operations
  const [editingPricings, setEditingPricings] = React.useState(
    pricings.map((dataItem, idx) => ({
      ...dataItem,
      formDataIndex: idx,
    }))
  );

  const [editIndex, setEditIndex] = React.useState<number | undefined>(
    undefined
  );

  const onRowAction = React.useCallback(
    (options: {
      rowIndex: number;
      operation: "save" | "remove" | "add";
      dataItem?: any;
    }) => {
      const newEditingPricings = [...editingPricings];
      switch (options.operation) {
        case "remove":
          newEditingPricings.splice(options.rowIndex, 1);
          break;
        case "save":
          newEditingPricings[options.rowIndex] = options.dataItem;
          setEditIndex(undefined);
          break;
        case "add":
          newEditingPricings.push({
            unitPrice: 0,
            currencyCode: 'USD',
            minimumQuantity: 0,
            formDataIndex: options.rowIndex,
          });        
          setEditIndex(options.rowIndex);
          break;
        default:
      }

      onPricingChange(newEditingPricings)

      const newEditingPricingsReindexed = newEditingPricings.map((dataItem, idx) => ({
        ...dataItem,
        formDataIndex: idx,
      }))

      setEditingPricings(newEditingPricingsReindexed);
    },
    [editingPricings]
  );

  const onAddClick = React.useCallback(() => {
    onRowAction({ rowIndex: editingPricings.length, operation: "add" });
  }, [onRowAction, editingPricings]);

  const minQuantityValidator = (value: string, valueGetter: (name: string) => any) => {
    const formDataIndex = valueGetter('formDataIndex')
    return Number(value) > 0 && !editingPricings.find(p => p.minimumQuantity === Number(value) && p.formDataIndex !== formDataIndex) ? "" : "required and must be unique";  
  }

  const unitPriceUniqueValidator = (value: string, valueGetter: (name: string) => any) => {
    const formDataIndex = valueGetter('formDataIndex')
    return !editingPricings.find(p => p.unitPrice === Number(value) && p.formDataIndex !== formDataIndex) ? "" : "required and must be unique";  
  }

  const unitPriceValidRangeValidator = (value: string, valueGetter: (name: string) => any) => {
    const formDataIndex = valueGetter('formDataIndex')
    const quantity = Number(valueGetter('minimumQuantity' ))
    const unitPrice = Number(value)

    const editingPricingsOrderedByMinimumQuantityAsc = editingPricings.filter(p => p.formDataIndex !== formDataIndex).sort((a,b) => a.minimumQuantity - b.minimumQuantity)
    const editingPricingsOrderedByMinimumQuantityDesc = editingPricings.filter(p => p.formDataIndex !== formDataIndex).sort((a,b) => b.minimumQuantity - a.minimumQuantity)

    const minimumPrice = editingPricingsOrderedByMinimumQuantityAsc.find(p => p.minimumQuantity > quantity)?.unitPrice
    const maximumPrice = editingPricingsOrderedByMinimumQuantityDesc.find(p => p.minimumQuantity < quantity)?.unitPrice

    if (unitPrice == 0)
      return ''

    const errorMessage = minimumPrice && maximumPrice ? `must be between ${minimumPrice} and ${maximumPrice}` : 
                      !minimumPrice ? `must be smaller than ${maximumPrice}` : `must be greater than ${minimumPrice}`

    return (!maximumPrice || unitPrice < maximumPrice) && (!minimumPrice || unitPrice > minimumPrice) ? "" : errorMessage;  
  }

  const requiredTextValidator = (value: string) =>
    value ? "" : "required";

  const requiredNumericValidator = (value: string) =>
    Number(value) > 0 ? "" : "required";  

  return (
    <>
     <GridEditContext.Provider value={{ onRowAction, editIndex, setEditIndex, dataItemKey: 'pricingID' }}>
        <TableGrid
        clearSortingButton={<></>}
          offline={offline}
          data={editingPricings}
          style={{
            lineHeight: 1,
            cursor: 'pointer',
            maxHeight: '700px',
          }}
          sortable={false}
          filterable={false}
          groupable={false}
          reorderable={true}
          pageable={false}
          {...dataState}
          dataItemKey={'pricingID'}
          rowRender={rowRender}
        >
          <GridToolbar>
            <Button
                variant="contained"
                color="secondary"
                onClick={onAddClick}
            >
                Add new
            </Button>             
          </GridToolbar>

          <Column
            field="minimumQuantity"
            title="Min. Quantity"
            cell={props => NumberCell({...props, validators: [minQuantityValidator]})} 
          />

          <Column
            field="unitPrice"
            title="Unit Price"
            cell={props => CurrencyCell({...props, validators: [unitPriceValidRangeValidator, unitPriceUniqueValidator]})} 
          />

          <Column
            field="currencyCode"
            title="Currency Code"
            cell={props => TextCell({...props, validators: [requiredTextValidator]})} 
          />

        <Column title="Command" cell={CommandCell} />

        </TableGrid>
      </GridEditContext.Provider>
    </>
  )
}

export default ProductPricingDetailEditingTable

