import * as Styles from './index.module.css'

type TProps = {
  children: React.ReactNode
  key?: string | number
}
export const ElementDisplay = (props: TProps) => {
  return (
    <div key={props.key} className={Styles.SingleSelectElement}>
      {props.children}
    </div>
  )
}
