/* eslint-disable filenames/match-exported */
import { Capacitor } from '@capacitor/core'
import { Storage } from '@capacitor/storage'
import moment from 'moment'
import { getIsInternalUserHeader } from 'utils/customer-utilities'
import { FetchError } from 'utils/Errors/FetchError'
import OfflineService from './offline-service'

export default class LoginService {
  static timerId = null

  static get accessTokenStorageKey() {
    return 'access_token'
  }

  static get refreshTokenStorageKey() {
    return 'refresh_token'
  }

  static get expirationStorageKey() {
    return 'expires'
  }

  static async _fetchTokens(model) {
    const response = await fetch(
      `${process.env.REACT_APP_IDENTITY_SERVER_URL}/connect/token`,
      {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/x-www-form-urlencoded',
          ...getIsInternalUserHeader(),
        },
        body: Object.keys(model)
          .map(
            key =>
              encodeURIComponent(key) + '=' + encodeURIComponent(model[key])
          )
          .join('&'),
      }
    )

    if (response.status >= 500 && response.status <= 599) {
      throw new Error('An unexpected error occurred')
    }

    const data = await response.json()

    if (response.status === 600) {
      throw new FetchError(data)
    }

    // {"error":"invalid_grant","error_description":"invalid custom credential"}
    if (typeof data.error_description !== 'undefined') {
      if (data.error_description === 'invalid custom credential') {
        throw new Error('Invalid login')
      } else {
        throw new Error(data.error_description)
      }
    }

    if (typeof data.error !== 'undefined') {
      if (data.error === 'invalid_grant') {
        throw new Error('Invalid login')
      } else {
        throw new Error(data.error)
      }
    }

    // { access_token: "access_token", expires_in: 3600, refresh_token: "refresh_token", token_type: "Bearer", scope: "api offline_access" }
    if (
      typeof data.access_token !== 'undefined' &&
      typeof data.refresh_token !== 'undefined' &&
      typeof data.expires_in !== 'undefined'
    ) {
      const tokens = {
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        expires: moment()
          .add(data.expires_in * 1, 'seconds')
          .unix(),
      }

      return tokens
    }

    return false
  }

  private static loginListeners: Set<() => void> = new Set()

  static async SubscribeToLogin(onLogin: () => void) {
    return LoginService.loginListeners.add(onLogin)
  }

  static async UnsubscribeToLogin(onLogin: () => void) {
    return LoginService.loginListeners.delete(onLogin)
  }

  static async requestTokens(username, password) {
    const model = {
      grant_type: 'password',
      username: username,
      password: password,
      client_id: 'datasight',
      client_secret: 'secret',
      scope: 'api offline_access',
    }

    const tokens = await this._fetchTokens(model)

    if (tokens !== false) {
      for (const listener of this.loginListeners) {
        listener()
      }

      if (Capacitor.isNativePlatform()) {
        const { value: lastLogin } = await Storage.get({ key: 'login' })
        if (lastLogin !== null && lastLogin !== username) {
          await OfflineService.purge()
        }

        await Storage.set({ key: 'login', value: username })
      }
    }

    return tokens
  }

  static async refreshTokens(refresh_token) {
    const model = {
      grant_type: 'refresh_token',
      refresh_token: refresh_token,
      client_id: 'datasight',
      client_secret: 'secret',
      scope: 'api offline_access',
    }

    return this._fetchTokens(model)
  }

  static async setTokens(access_token, refresh_token, expires) {
    if (Capacitor.isNativePlatform()) {
      await Storage.set({
        key: this.accessTokenStorageKey,
        value: access_token,
      })
      await Storage.set({
        key: this.refreshTokenStorageKey,
        value: refresh_token,
      })
      await Storage.set({
        key: this.expirationStorageKey,
        value: expires.toString(),
      })
    } else {
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(this.accessTokenStorageKey, access_token)
        window.localStorage.setItem(this.refreshTokenStorageKey, refresh_token)
        window.localStorage.setItem(this.expirationStorageKey, expires)
      }
    }
  }

  static async isLoggedIn() {
    const token = await this.getStoredAccessToken()
    return token !== null
  }

  static async isPrimaryUser() {
    try {
      const token = await this.getStoredAccessToken()
      const ret = JSON.parse(atob(token.split('.')[1]))['is_primary']

      return ret === undefined ? false : !!JSON.parse(String(ret).toLowerCase())
    } catch (ex) {
      return false
    }
  }

  static async getStoredAccessToken() {
    if (Capacitor.isNativePlatform()) {
      const { value } = await Storage.get({ key: this.accessTokenStorageKey })
      return value
    } else {
      return (
        typeof window !== 'undefined' &&
        window.localStorage.getItem(this.accessTokenStorageKey)
      )
    }
  }

  static async getStoredRefreshToken() {
    if (Capacitor.isNativePlatform()) {
      const { value } = await Storage.get({ key: this.refreshTokenStorageKey })
      return value
    } else {
      return (
        typeof window !== 'undefined' &&
        window.localStorage.getItem(this.refreshTokenStorageKey)
      )
    }
  }

  static async getStoredTokenExpiration() {
    if (Capacitor.isNativePlatform()) {
      const { value } = await Storage.get({ key: this.expirationStorageKey })
      return value * 1
    } else {
      return (
        typeof window !== 'undefined' &&
        window.localStorage.getItem(this.expirationStorageKey) * 1
      )
    }
  }

  static async pollRefreshAccessToken(cbError) {
    if (this.timerId !== null) {
      return
    }

    const loggedIn = await this.isLoggedIn()
    if (loggedIn) {
      const tokenExpiration = await this.getStoredTokenExpiration()
      const delta = tokenExpiration - moment().unix()

      const refreshFunc = async cbError => {
        this.timerId = null

        try {
          if (!OfflineService.isOffline()) {
            const refresh_token = await this.getStoredRefreshToken()
            const tokens = await this.refreshTokens(refresh_token)

            if (tokens === false) {
              return
            }

            if (
              typeof tokens.access_token !== 'undefined' &&
              typeof tokens.refresh_token !== 'undefined' &&
              typeof tokens.expires !== 'undefined'
            ) {
              await LoginService.setTokens(
                tokens.access_token,
                tokens.refresh_token,
                tokens.expires
              )
              setTimeout(async () => {
                await this.pollRefreshAccessToken(cbError)
              }, 1)
            }
          } else {
            setTimeout(async () => {
              await this.pollRefreshAccessToken(cbError)
            }, 1)
          }
        } catch (ex) {
          console.error(ex)

          // Recoverable, requeue the attempt:
          if (
            ex instanceof Error &&
            ex.message.indexOf('Failed to fetch') !== -1
          ) {
            setTimeout(async () => {
              await this.pollRefreshAccessToken(cbError)
            }, 1000 * 60)
            return
          }

          // Unrecoverable:
          if (cbError) {
            cbError(ex)
          }
        }
      }

      // Do it now if we don't have a lot of time to renew:
      if (delta < 90) {
        if (OfflineService.isOffline()) {
          this.timerId = setTimeout(async () => {
            await refreshFunc(cbError)
          }, 1000 * 60 * 5)
        } else {
          await refreshFunc(cbError)
        }
      } else {
        this.timerId = setTimeout(async () => {
          await refreshFunc(cbError)
        }, 1000 * (delta - 60))
      }
    }
  }

  private static logoutListeners: Set<() => void> = new Set()

  static async SubscribeToLogout(onLogout: () => void) {
    return LoginService.logoutListeners.add(onLogout)
  }

  static async UnsubscribeToLogout(onLogout: () => void) {
    return LoginService.logoutListeners.delete(onLogout)
  }

  static async logOut() {
    OfflineService.cancelAll()

    if (Capacitor.isNativePlatform()) {
      await Storage.remove({ key: this.accessTokenStorageKey })
      await Storage.remove({ key: this.refreshTokenStorageKey })
      await Storage.remove({ key: this.expirationStorageKey })
    } else {
      if (typeof window !== 'undefined') {
        window.localStorage.removeItem(this.accessTokenStorageKey)
        window.localStorage.removeItem(this.refreshTokenStorageKey)
        window.localStorage.removeItem(this.expirationStorageKey)
      }
    }

    for (const listener of LoginService.logoutListeners) {
      listener()
    }
  }

  static async resetPassword(name, company, email, city, state, country) {
    const model = {
      name: name,
      company: company,
      email: email,
      city: city,
      state: state,
      country: country,
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_IDENTITY_SERVER_URL}/password-reset`,
        {
          method: 'POST',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/x-www-form-urlencoded',
            ...getIsInternalUserHeader(),
          },
          body: Object.keys(model)
            .map(
              key =>
                encodeURIComponent(key) + '=' + encodeURIComponent(model[key])
            )
            .join('&'),
        }
      )

      if (response.status >= 500) {
        throw new Error('An unexpected error occurred')
      }

      const data = await response.json()

      if (typeof data.status !== 'undefined' && data.status === 'success') {
        return true
      }

      return false
    } catch (ex) {
      throw ex
    }
  }

  static async register(model) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_IDENTITY_SERVER_URL}/register`,
        {
          method: 'POST',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/x-www-form-urlencoded',
            ...getIsInternalUserHeader(),
          },
          body: Object.keys(model)
            .map(
              key =>
                encodeURIComponent(key) + '=' + encodeURIComponent(model[key])
            )
            .join('&'),
        }
      )

      if (response.status >= 500) {
        throw new Error('An unexpected error occurred')
      }

      const data = await response.json()

      if (typeof data.status !== 'undefined' && data.status === 'success') {
        return true
      }

      return false
    } catch (ex) {
      throw ex
    }
  }
}
