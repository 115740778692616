declare module '*.module.css' {
  const content: { [className: string]: string }
  export default content
}

declare module '*.svg' {
  const content: any
  export default content
}

declare module '*.png' {
  const content: string
  export default content
}

declare module '*.jpg' {
  const content: string
  export default content
}

declare module '*.gif' {
  const content: string
  export default content
}

export type TPdf = {
  fileName: string
  buffer: Buffer
}

export enum ConnectionStatus {
  pending,
  connected,
  disconnected,
}

export enum TCondition {
  unknown,
  critical,
  marginal,
  normal,
}

export type TOrderConfig = {
  field: string
  dir: string
}

export type TLoading = {
  loading: boolean
  error: Error
  loaded: boolean
}

export type SocketResponse<TData> = {
  data: TData
  isCallerResponse: boolean
}

export enum LinkLevel {
  Master = 0,
  Inherited = 1,
}

export enum LinkStatus {
  Unlinked = 0,
  Partial = 1,
  Full = 2,
}

export enum BottleStatusCode {
  Created = 1,
  ShippedToCustomer,
  Reserved,
  Linked,
  ShippedToLab,
  ReceivedByLab,
  BatchReceivedBylab,
  BatchReceiveIssue,
  Testing,
  TestingCompleted,
  CompletedWithIssue,
  Completed,
}

export enum SampleStatus {
  Incomplete = 'INC',
  Complete = 'COM',
}

export type WidthDefinition = {
  minWidth?: number
  maxWidth?: number
}

export type WidthDefinitionMap = {
  [key: string]: WidthDefinition
}

export type TUnitedStatesState = {
  name: string
  abbreviation: string
}
