import React from 'react'

import { SelectFilter } from '../BaseFilters/SelectFilter'
import FilterTooltip from '../FilterTooltip/FilterTooltip'

const exceptionCounts = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

type TProps = {
  single: boolean
  required?: boolean
  onChange?: (props: number | number[]) => void
  defaultValue?: number | number[]
  tooltip?: boolean
  variant?: 'filled' | 'outlined' | 'standard'
}

export const ExceptionCountFilter = ({
  single,
  required,
  onChange,
  defaultValue,
  tooltip = true,
  variant,
}: TProps) => {
  const filter = (
    <SelectFilter
      multiple={!single}
      required={required}
      label="Exception Count"
      htmlFor="ExceptionCount"
      variant={variant}
      id="exceptionCount"
      defaultValue={exceptionCounts.find(ec => ec === defaultValue) || 2}
      renderValue={(selected: number) => selected?.toString()}
      onChange={onChange}
      options={exceptionCounts}
    ></SelectFilter>
  )

  return tooltip ? (
    <FilterTooltip title="The number of alarm limits a machine, or its lubricant, has broken in the past year.">
      {filter}
    </FilterTooltip>
  ) : (
    filter
  )
}
