import { TProfileContext } from 'Contexts/ProfileContext'
import { TProfile } from 'services/api-service'
import {
  OrderType,
  TMachineGroup,
  TSpending,
  TSpendingEntity,
} from 'types/api.d'

export const getSmallestEntities = (spendingData: TSpendingEntity[]) => {
  return spendingData.flatMap(datum => spreadEntityToSmallestUnit(datum))
}

export const spreadEntityToSmallestUnit = (spendingDatum: TSpendingEntity) => {
  if (spendingDatum.children && spendingDatum.children.length > 0) {
    let allChildren: TSpendingEntity[] = []
    spendingDatum.children.forEach(child => {
      let children = spreadEntityToSmallestUnit(child)
      children.forEach(c => {
        allChildren.push(c)
      })
    })

    return allChildren
  } else {
    return [spendingDatum]
  }
}

export const getThisMonthsSpending = (
  spendingData: TSpendingEntity[],
  projected: boolean = false
) => {
  const now = new Date()
  let total = 0
  const allMinimumEntities = getSmallestEntities(spendingData)

  allMinimumEntities.forEach(datum => {
    let spending: TSpending

    if (!projected) spending = datum.currentMonthsSpending

    if (projected)
      spending = datum.projectedSpending.find(ps => {
        const psDate = new Date(ps.month)
        return compareDatesByMonthAndYear(psDate, now) === 0
      })

    total += spending?.amountSpent ?? 0
  })

  return total
}
export const getMonthsSpending = (
  spendingData: TSpendingEntity[],
  month: Date
) => {
  const startOfMonth = new Date(month.getFullYear(), month.getMonth(), 1)
  const now = new Date()
  let total = 0
  const allMinimumEntities = getSmallestEntities(spendingData)

  allMinimumEntities.forEach(datum => {
    let spending: TSpending = datum.historicalSpending.find(hs => {
      const hsDate = new Date(hs.month)
      return (
        compareDatesByMonthAndYear(hsDate, startOfMonth) === 0 &&
        compareDatesByMonthAndYear(hsDate, now) < 0
      )
    })
    if (!spending)
      spending =
        compareDatesByMonthAndYear(now, startOfMonth) === 0
          ? datum.currentMonthsSpending
          : null
    if (!spending)
      spending = datum.projectedSpending.find(ps => {
        const psDate = new Date(ps.month)
        return (
          compareDatesByMonthAndYear(psDate, startOfMonth) === 0 &&
          compareDatesByMonthAndYear(psDate, now) > 0
        )
      })

    total += spending?.amountSpent ?? 0
  })

  return total
}

export const getYearToDateSpending = (spendingData: TSpendingEntity[]) => {
  const now = new Date()
  const startOfYear = new Date(now.getFullYear(), 0, 1)
  let total = 0

  const allMinimumEntities = getSmallestEntities(spendingData)

  allMinimumEntities.forEach(datum => {
    let thisYearsHistoricalSpending = 0

    const thisYearsHistoricalSpendingData = datum.historicalSpending.filter(
      hs => {
        const hsDate = new Date(hs.month)
        return hsDate.getFullYear() === startOfYear.getFullYear()
      }
    )

    thisYearsHistoricalSpendingData.forEach(hs => {
      thisYearsHistoricalSpending += hs.amountSpent
    })

    total +=
      thisYearsHistoricalSpending + datum.currentMonthsSpending.amountSpent
  })

  return total
}

export const getRepresentativeOrderType = (spendingData: TSpendingEntity[]) => {
  const allChildren = getSmallestEntities(spendingData)

  const orderTypes = [...spendingData, ...allChildren]
    .flatMap(datum => datum.purchaseOrders)
    .map(po => po.orderType)

  const hasPartialFreeIssue = orderTypes.some(
    type => type === OrderType.FreeIssue
  )
  const allAreFreeIssue = orderTypes.every(type => type === OrderType.FreeIssue)
  const someAreDollarBalance = orderTypes.some(
    ot => ot === OrderType.DollarBalance
  )
  const someAreSampleBalance = orderTypes.some(ot => ot === OrderType.Sample)
  const isUnknown = orderTypes.some(type => type === OrderType.Unknown)

  const budgetIsNotApplicable = allAreFreeIssue || isUnknown
  const budgetIsDollarBudget = someAreDollarBalance && !budgetIsNotApplicable
  const budgetIsSampleBudget =
    someAreSampleBalance && !budgetIsNotApplicable && !hasPartialFreeIssue

  if (budgetIsDollarBudget) return OrderType.DollarBalance
  if (budgetIsSampleBudget) return OrderType.Sample
  if (hasPartialFreeIssue) return OrderType.FreeIssue
  return OrderType.Unknown
}

export const compareDatesByMonthAndYear = (a: Date, b: Date) => {
  const aYear = a.getFullYear()
  const bYear = b.getFullYear()
  const aMonth = a.getMonth()
  const bMonth = b.getMonth()

  if (aYear < bYear) {
    return -1
  } else if (aYear > bYear) {
    return 1
  } else if (aMonth < bMonth) {
    return -1
  } else if (aMonth > bMonth) {
    return 1
  } else {
    return 0
  }
}

export const getLastYearsSpending = (spendingData: TSpendingEntity[]) => {
  let total = 0
  const now = new Date()
  const startOfLastYear = new Date(now.getFullYear() - 1, 0, 1)

  spendingData.forEach(datum => {
    const lastYearsHistoricalSpendingData = datum.historicalSpending.filter(
      hs => {
        const hsDate = new Date(hs.month)
        return hsDate.getFullYear() === startOfLastYear.getFullYear()
      }
    )

    lastYearsHistoricalSpendingData.forEach(hs => {
      total += hs.amountSpent
    })
  })

  return total
}

export const getAggregatedMachineTypeSpending = (
  spendingDatum: TSpendingEntity,
  startDate: Date,
  endDate: Date
) => {
  const now = new Date()
  const machineTypeSpendingData: TMachineGroup[][] = []

  spendingDatum.historicalSpending.forEach(hs => {
    const hsDate = new Date(hs.month)
    if (compareDatesByMonthAndYear(hsDate, startDate) >= 0) {
      machineTypeSpendingData.push(
        hs.spendingByMachineType.map(v => ({ ...v }))
      )
    }
  })

  if (compareDatesByMonthAndYear(endDate, now) >= 0) {
    machineTypeSpendingData.push(
      spendingDatum.currentMonthsSpending.spendingByMachineType.map(v => ({
        ...v,
      }))
    )
  }

  spendingDatum.projectedSpending.forEach(ps => {
    const psDate = new Date(ps.month)
    if (compareDatesByMonthAndYear(psDate, startDate) >= 0) {
      machineTypeSpendingData.push(
        ps.spendingByMachineType.map(v => ({ ...v }))
      )
    }
  })

  const aggregatedData = new Map<string, TMachineGroup>()

  machineTypeSpendingData.forEach(arr => {
    arr.forEach(mg => {
      const key = mg.machineType

      if (aggregatedData.has(key)) {
        const existing = aggregatedData.get(key)
        existing.amount += mg.amount
        existing.percentBudgetSpend += mg.percentBudgetSpend
        existing.percentOfTotal += mg.percentOfTotal
      } else {
        aggregatedData.set(key, mg)
      }
    })
  })

  const dataWithValues: TMachineGroup[] = []
  aggregatedData.forEach((mg, key) => {
    if (!mg.amount) return
    dataWithValues.push(mg)
  })

  return dataWithValues
}

export const getDefaultBillingContact = (profileContext: TProfileContext) => {
  return profileContext?.billingCustomersLoaded &&
    profileContext?.profile?.billingCustomers &&
    profileContext?.profile?.billingCustomers?.length > 0
    ? profileContext?.profile?.billingCustomers?.filter(
        x => x.billToContact?.address !== null
      )[0]
    : null
}
