import React, { useCallback, useEffect, useContext, useState } from 'react'
import { Capacitor } from '@capacitor/core'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  Link as MaterialLink,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { createTheme, withStyles } from '@material-ui/core/styles'
import BusinessIcon from '@material-ui/icons/Business'
import CheckIcon from '@material-ui/icons/Check'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CommentIcon from '@material-ui/icons/Comment'
import GetAppIcon from '@material-ui/icons/GetApp'
import NotesIcon from '@material-ui/icons/Notes'
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot'
import GearIcon from '@material-ui/icons/Settings'
import WarningIcon from '@material-ui/icons/Warning'
import { Alert } from '@material-ui/lab'
import {
  Sparkline,
  ChartSeries,
  ChartSeriesItem,
} from '@progress/kendo-react-charts'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import PropTypes from 'prop-types'

import AnalystFeedbackModal from 'components/AnalystFeedbackModal'
import { ExportButton, SearchButton } from 'components/buttons'
import FeedbackModal from 'components/FeedbackModal'
import { FromDateFilter, ToDateFilter } from 'components/filters'
import FullscreenLayout from 'components/FullScreenLayout'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import SEO from 'components/SEO'
import coreTheme from 'components/theme'
import APIService from 'services/api-service'
import CapacitorFileService from 'services/capacitor-file-service'
import { exportData } from 'services/export-service'
import { handleDownloadFile } from 'utils/file-utilities'
import { ProfileContext, useProfile } from 'Contexts/ProfileContext'
import ConditionElement from 'components/ConditionElement'
import { CancellablePromise } from 'utils/CancellablePromise'

const TooltipNode = ({ children, title }) =>
  title ? (
    <Tooltip title={<h6>{title}</h6>}>
      <span>{children}</span>
    </Tooltip>
  ) : (
    <>{children}</>
  )

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]
let firstSample

const ResultTableCell = ({ sample, test, field, flag, isRef, tooltip }) => {
  return (
    <TableCell
      style={
        sample !== null && sample.labID == firstSample?.labID
          ? { borderRight: '2px solid black', borderLeft: '2px solid black' }
          : null &&
            sample[test] !== null &&
            typeof flag !== 'undefined' &&
            sample[test][flag] > 0
          ? { backgroundColor: 'rgb(255, 236, 18)' }
          : isRef === 'true'
          ? { backgroundColor: '#eee' }
          : {}
      }
    >
      {
        <TooltipNode title={tooltip}>
          {sample !== null &&
          sample[test] !== null &&
          (!isRef || test !== 'isiPc' || field === 'iso')
            ? sample[test][field]
            : ''}
        </TooltipNode>
      }
    </TableCell>
  )
}

const SparkLine = ({ samples, test, field, title, subtitle, other }) => {
  const filteredSamples =
    typeof samples === 'undefined'
      ? []
      : samples.filter(
          s => s[test] !== null && (!isNaN(s[test][field]) || field === 'iso')
        )

  const pointID = filteredSamples.length > 0 ? filteredSamples[0].pointID : null
  const data = filteredSamples.map(s => {
    return { x: moment(s.sampleDate).toDate(), y: s[test][field] }
  })

  const handleChartClick = () => {
    if (window) {
      window.open(
        `/dart/graphs?pointID=${pointID}&test=${test}&field=${field}`,
        '_blank',
        `name=${subtitle},resizable=yes,innerWidth=900px,innerHeight=700px,maxHeight=100%`
      )
    }
  }

  return (
    <>
      {pointID === null || data.length === 0 ? (
        <></>
      ) : (
        <>
          <div
            style={{ height: '35px', width: '100px', cursor: 'pointer' }}
            role="button"
            tabIndex={0}
            onClick={handleChartClick}
          >
            {field === 'iso' ? (
              <Sparkline type="area" seriesColors={['red', 'orange', 'green']}>
                <ChartSeries>
                  {['iSO1', 'iSO2', 'iSO3'].map(isoField => (
                    <ChartSeriesItem
                      type="area"
                      data={
                        typeof samples === 'undefined'
                          ? []
                          : samples
                              .filter(
                                s =>
                                  s[test] !== null && !isNaN(s[test][isoField])
                              )
                              .map(s => Number(s[test][isoField]))
                      }
                    />
                  ))}
                </ChartSeries>
              </Sparkline>
            ) : (
              <Sparkline
                seriesColors={['rgb(31, 119, 180)']}
                seriesDefaults={{ width: 2 }}
                data={
                  typeof samples === 'undefined'
                    ? []
                    : samples
                        .filter(s => s[test] !== null && !isNaN(s[test][field]))
                        .map(s => s[test][field])
                }
              />
            )}
          </div>

          <div
            style={{
              textAlign: 'center',
              fontSize: '13px',
              marginTop: '5px',
              color: '#999',
            }}
            role="button"
            tabIndex={0}
            onClick={handleChartClick}
          >
            View full history
          </div>
        </>
      )}
    </>
  )
}

const styles = theme => ({
  table: {
    '& thead': {
      '& tr': {
        '& th': {
          backgroundColor: '#a8afba',
          color: '#fff',
          padding: '2px',
        },
      },
    },
    '& tbody': {
      '& tr': {
        '& td': {
          padding: '2px',
          border: '0px',
        },
      },
    },
  },
  chartTable: {
    '& .k-sparkline': {
      width: '100px',
    },
  },
  sparkLineArea: {
    '& .k-sparkline': {
      width: '80px',
      marginLeft: '7px',
    },
  },
  avatar: {
    backgroundColor: coreTheme.palette.primary.main,
    color: coreTheme.palette.primary.contrastText,
  },

  sampleAvatar: {
    backgroundColor: coreTheme.palette.secondary.main,
    color: coreTheme.palette.secondary.contrastText,
  },

  cardHeader: {
    backgroundColor: coreTheme.palette.primary.main,
    color: coreTheme.palette.primary.contrastText,
    '& .MuiCardHeader-title': {
      fontSize: '18px',
    },
  },

  sampleCardHeader: {
    backgroundColor: coreTheme.palette.secondary.main,
    color: coreTheme.palette.secondary.contrastText,
    '& .MuiCardHeader-title': {
      fontSize: '18px',
    },
  },

  headingRow: {
    backgroundColor: coreTheme.palette.primary.main,
    color: coreTheme.palette.primary.contrastText,
  },

  machineFooterRow: {
    backgroundColor: coreTheme.palette.secondary.main,
    color: coreTheme.palette.secondary.contrastText,
    textAlign: 'center',
  },

  refLab: {
    backgroundColor: '#eee',
  },
})

const unique = function (value, index, self) {
  return self.indexOf(value) === index
}

const formatS1Time = s1Time => {
  const index = s1Time.indexOf(':')
  if (index !== -1) {
    return `${s1Time.substring(0, index - 1) * 60}${s1Time.substring(
      index + 1,
      index + 3
    )}`
  }

  return ''
}

function DARTData({ classes, offline, location }) {
  const navigate = useNavigate()
  const profileContext = useProfile()
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)
  const [refData, setRefData] = useState(null)
  const [samples, setSamples] = useState([])
  const [pcIsoLimit, setPcIsoLimit] = useState('')
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const [analystFeedbackOpen, setAnalystFeedbackOpen] = useState(false)

  const urlParams = new URLSearchParams(window.location.search)
  const labID = urlParams.get('labID')
  const fromDateParam = urlParams.get('fromDate')
  const toDateParam = urlParams.get('toDate')

  const [startDate, setStartDate] = useState(fromDateParam)
  const [endDate, setEndDate] = useState(toDateParam)

  const [navTimeout, setNavtimeout] = useState<null | NodeJS.Timeout>(null)

  useEffect(() => {
    if (location?.state?.update) {
      setLoaded(false)
    }
  }, [location])

  const formatISO = (iso1, iso2, iso3) => {
    if (iso1 === null) {
      return ''
    }

    return `${iso1}/${iso2}/${iso3}`
  }

  const navigateHome = () => {
    if (!navTimeout) setNavtimeout(setTimeout(() => navigate('/dart'), 3000))
  }

  useEffect(() => {
    return () => {
      if (navTimeout) clearTimeout(navTimeout)
    }
  }, [navTimeout])

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  useEffect(() => {
    if (loaded || labID === null) return
    let dataChanged = false
    ;(async variables => {
      let localData = null
      let pcIsoLimit = null
      let promise
      try {
        if (data && data.labID == labID) {
          // compare regardless of type
          localData = { sample: data }
        } else {
          promise = APIService.getDARTData({ labID: labID })

          ongoingCancellablePromises.push(promise)
          localData = await promise
          dataChanged = true
        }
      } catch (ex) {
        setError(ex.message)
        setLoaded(true)
        return
      } finally {
        ongoingCancellablePromises.filter(p => p != promise)
      }
      try {
        promise = APIService.getPcLimits({
          labID: labID,
        })

        ongoingCancellablePromises.push(promise)
        pcIsoLimit = await promise
      } catch (ex) {
        setError(ex.message)
      } finally {
        ongoingCancellablePromises.filter(p => p != promise)
      }
      if (pcIsoLimit.limitsForPc) {
        if (
          pcIsoLimit.limitsForPc.iSO1 &&
          pcIsoLimit.limitsForPc.iSO2 &&
          pcIsoLimit.limitsForPc.iSO3
        ) {
          const formattedIsoCode = formatISO(
            pcIsoLimit.limitsForPc.iSO1,
            pcIsoLimit.limitsForPc.iSO2,
            pcIsoLimit.limitsForPc.iSO3
          )
          setPcIsoLimit(formattedIsoCode)
        }
      }
      if (localData.sample !== null) {
        if (localData.sample.isiPc !== null) {
          localData.sample.isiPc.iso = formatISO(
            localData.sample.isiPc.iSO1,
            localData.sample.isiPc.iSO2,
            localData.sample.isiPc.iSO3
          )
        }

        if (localData.sample.isiPcl !== null) {
          localData.sample.isiPcl.iso = formatISO(
            localData.sample.isiPcl.iSO1,
            localData.sample.isiPcl.iSO2,
            localData.sample.isiPcl.iSO3
          )
        }

        setData(localData.sample)

        let refLabID = null
        if (localData.sample.isiCustRefOil !== null) {
          refLabID = localData.sample.isiCustRefOil.refLabID
        } else if (
          localData.sample.machine.lubricant.refLabID !== null &&
          localData.sample.machine.lubricant.refLabID !== 0
        ) {
          refLabID = localData.sample.machine.lubricant.refLabID
        }

        if (refLabID !== null) {
          let refData = null
          try {
            promise = APIService.getDARTData({
              labID: refLabID,
              isRef: true,
            })
            ongoingCancellablePromises.push(promise)
            refData = await promise
          } catch (ex) {
            setError(ex.message)
          } finally {
            ongoingCancellablePromises.filter(p => p != promise)
          }

          if (refData.sample !== null) {
            if (refData.sample.isiPc !== null) {
              refData.sample.isiPc.iso = formatISO(
                refData.sample.isiPc.iSO1,
                refData.sample.isiPc.iSO2,
                refData.sample.isiPc.iSO3
              )
            }

            if (refData.sample.isiPcl !== null) {
              refData.sample.isiPcl.iso = formatISO(
                refData.sample.isiPcl.iSO1,
                refData.sample.isiPcl.iSO2,
                refData.sample.isiPcl.iSO3
              )
            }

            setRefData(refData.sample)
          }
        }

        const newStartDate =
          startDate && !dataChanged
            ? startDate
            : location?.state?.startDate ??
              moment(localData.sample.sampleDate).subtract(1, 'year').toDate()

        const newEndDate =
          endDate && !dataChanged
            ? endDate
            : location?.state?.endDate ??
              moment(localData.sample.sampleDate).toDate()

        let sampleData = null
        try {
          const useLocalData =
            newStartDate !== startDate &&
            newEndDate !== endDate &&
            localData &&
            samples &&
            samples.length > 0 &&
            localData.sample.pointID === samples[0].pointID

          if (useLocalData) {
            sampleData = { pagedSamples: { items: samples } }
          } else {
            promise = APIService.getDARTSamples({
              pointID: localData.sample.pointID,
              startDate: newStartDate,
              endDate: newEndDate,
              pageSize: 100,
            })

            ongoingCancellablePromises.push(promise)
            sampleData = await promise
            firstSample = sampleData.pagedSamples.items[0]
          }

          for (const item of sampleData.pagedSamples.items) {
            if (item.isiPc !== null) {
              item.isiPc.iso = formatISO(
                item.isiPc.iSO1,
                item.isiPc.iSO2,
                item.isiPc.iSO3
              )
            }

            if (item.isiPcl !== null) {
              item.isiPcl.iso = formatISO(
                item.isiPcl.iSO1,
                item.isiPcl.iSO2,
                item.isiPcl.iSO3
              )
            }
          }

          setSamples(sampleData.pagedSamples.items as any[])
          setError(null)
          setLoaded(true)

          if (startDate == null || startDate == 'Invalid Date')
            setStartDate(newStartDate)
          if (endDate == null || endDate == 'Invalid Date')
            setEndDate(newEndDate)
        } catch (ex) {
          setError(ex.message)
          setLoaded(true)
        } finally {
          ongoingCancellablePromises.filter(p => p != promise)
        }
      } else {
        setError('Unable to retrieve sample data.')
        setLoaded(true)
      }
    })({
      labID: labID,
    })
  }, [loaded, labID])

  const validateForm = () =>
    moment(startDate, 'MM/DD/YYYY').isValid() &&
    moment(endDate, 'MM/DD/YYYY').isValid()

  const handleOnSearchClick = async pointID => {
    if (!validateForm()) return
    setLoaded(false)
  }

  const handleExportFormatChange = async (event, format) => {
    let testData = null
    let promise
    try {
      promise = APIService.getMachineTestData({
        pointIDs: [data.pointID],
        startDate: startDate,
        endDate: endDate,
      })

      ongoingCancellablePromises.push(promise)
      testData = await promise
    } catch (ex) {
      setError(ex.message)
      return
    } finally {
      ongoingCancellablePromises.filter(p => p != promise)
    }

    const headerValues = testData.machineTestData.keys
    const formatted = [
      headerValues,
      ...testData.machineTestData.values.map(x => x.values),
    ]

    exportData('dart-data', formatted, format.key)
  }

  const handleReviewedToggle = async (event, sample) => {
    try {
      sample.reviewed = event.target.checked
      setSamples(samples.slice())

      if (event.target.checked) {
        await APIService.markSamplesReviewed({ labIDs: [sample.labID * 1] })
      } else {
        await APIService.markSamplesUnreviewed({ labIDs: [sample.labID * 1] })
      }
    } catch (ex) {
      setError(ex.message)
    }
  }

  const handlePDFDownload = async event => {
    event.preventDefault()
    const fileName = `${data.labID}.pdf`
    const buffer = await APIService.getFile(
      data.machine.plant.customer.custID,
      fileName
    )

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(new Blob([buffer]), fileName)
    } else {
      if (Capacitor.isNative) {
        CapacitorFileService.saveAndOpen(fileName, 'application/pdf', buffer)
      } else {
        event.preventDefault()

        handleDownloadFile({ buffer, fileName })
      } // Use download attribute to set set desired file name
    }
  }

  const handleFeedbackClose = () => {
    setFeedbackOpen(false)
  }

  const handleAnalystFeedbackClose = () => {
    setAnalystFeedbackOpen(false)
  }

  const buttonTheme = createTheme(
    {
      overrides: {
        MuiButton: {
          root: {
            [coreTheme.breakpoints.up('xs')]: {
              width: '225px',
            },
          },
        },
      },
    },
    coreTheme
  )
  const getHours = useCallback((hours: number | null) => {
    if (hours && hours > 0) {
      return hours.toFixed(0)
    }
    return ''
  }, [])

  const cols = samples === null ? 5 : 5 + samples.length

  return (
    <>
      <SEO title={`DART Data ${labID}`} />
      {offline ? <OfflineBanner /> : <></>}
      <Box m={2}>
        {!loaded ? (
          <Loader />
        ) : data !== null ? (
          <React.Fragment>
            <Grid
              container
              style={{ width: '100%', marginBottom: '50px' }}
              justify="center"
            >
              <Grid
                container
                item
                xs={12}
                style={{ marginTop: '10px', textAlign: 'center' }}
                justify="center"
              >
                <Typography variant="h2" style={{ marginRight: '25px' }}>
                  Sampling History
                </Typography>
              </Grid>
              <Grid
                container
                direction="row"
                style={{ marginTop: '10px' }}
                item
                xs={12}
                justify="center"
                alignItems="center"
                spacing={4}
              >
                {startDate != null && endDate != null && (
                  <Grid container item xs={12} justify="center" spacing={2}>
                    <Grid item>
                      <FromDateFilter
                        onChange={date => {
                          if (date) setStartDate(date.toDate())
                        }}
                        defaultValue={startDate}
                        maxDate={endDate}
                      />
                    </Grid>
                    <Grid item>
                      <ToDateFilter
                        defaultValue={endDate}
                        onChange={date => {
                          if (date) setEndDate(date.toDate())
                        }}
                        minDate={startDate}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid container item justify="center" xs={12} spacing={2}>
                  <Grid item>
                    <SearchButton
                      onSearchClick={handleOnSearchClick}
                      theme={buttonTheme}
                    />
                  </Grid>
                  <Grid item>
                    <ExportButton onChange={handleExportFormatChange} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="column" spacing={2}>
              <Grid container item direction="row" spacing={2}>
                <Grid item xs={12} md={4}>
                  <Card style={{ height: '100%' }}>
                    <CardHeader
                      title="Customer Information"
                      className={classes.cardHeader}
                      avatar={
                        <Avatar className={classes.avatar}>
                          <BusinessIcon />
                        </Avatar>
                      }
                    />
                    <CardContent>
                      <div>{data.machine.plant.customer.custName}</div>
                      <div>{data.machine.plant.plantName}</div>
                      {data.machine.plant.plantContact && (
                        <>
                          <div>{data.machine.plant.plantContact.address1}</div>
                          <div>{data.machine.plant.plantContact.address2}</div>
                          <div>{data.machine.plant.plantContact.address3}</div>
                          <div>
                            {data.machine.plant.plantContact.city},{' '}
                            {data.machine.plant.plantContact.state}{' '}
                            {data.machine.plant.plantContact.zip}
                          </div>
                          <div>
                            Contact: {data.machine.plant.plantContact.firstName}{' '}
                            {data.machine.plant.plantContact.lastName}
                          </div>
                        </>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card style={{ height: '100%' }}>
                    <CardHeader
                      title="Component Information"
                      className={classes.cardHeader}
                      avatar={
                        <Avatar className={classes.avatar}>
                          <GearIcon />
                        </Avatar>
                      }
                    />
                    <CardContent>
                      <Table className={classes.table}>
                        <TableBody>
                          <TableRow>
                            <TableCell>Machine Name:</TableCell>
                            <TableCell>{data.machine.machineName}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Equipment ID:</TableCell>
                            <TableCell>{data.machine.custEquID}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Machine Type:</TableCell>
                            <TableCell>{data.machine.machType}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Lubricant:</TableCell>
                            <TableCell>
                              {data.machine.lubricant?.lubricantName}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Machine MFG:</TableCell>
                            <TableCell>{data.machine.machMFG}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Machine MOD:</TableCell>
                            <TableCell>{data.machine.modelName}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Sump Size:</TableCell>
                            <TableCell>{data.machine.sumpCap}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card style={{ height: '100%' }}>
                    <CardHeader
                      title={`Selected Sample Information for: ${labID}`}
                      className={classes.sampleCardHeader}
                      avatar={
                        <Avatar className={classes.sampleAvatar}>
                          <ScatterPlotIcon />
                        </Avatar>
                      }
                    />
                    <CardContent>
                      <Table className={classes.table}>
                        <TableBody>
                          <TableRow>
                            <TableCell>Sample Date:</TableCell>
                            <TableCell>
                              {moment(data.sampleDate).format('l')}
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => setFeedbackOpen(true)}
                              >
                                Add Feedback
                              </Button>
                              <FeedbackModal
                                modalOpen={feedbackOpen}
                                modalSample={data}
                                onModalClose={handleFeedbackClose}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Received Date:</TableCell>
                            <TableCell colSpan={2}>
                              {moment(data.receivedDate).format('l')}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Report Date:</TableCell>
                            <TableCell colSpan={2}>
                              {moment(data.reportDate).format('l')}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Sampled Lube:</TableCell>
                            <TableCell colSpan={2}>
                              {data.lubricant !== null
                                ? data.lubricant?.lubricantName
                                : ''}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Sample No:</TableCell>
                            <TableCell colSpan={2}>
                              {data.machine.plant.customer.custID}-
                              {data.machine.plant.plantNo}-{data.machine.machNo}
                              -{data.seqNo}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Data Analyst:</TableCell>
                            <TableCell colSpan={2}>
                              <MaterialLink
                                component="button"
                                onClick={() => setAnalystFeedbackOpen(true)}
                              >
                                {data.analystIsiUser !== null
                                  ? data.analystIsiUser.name
                                  : data.analyst}
                              </MaterialLink>
                              <AnalystFeedbackModal
                                modalOpen={analystFeedbackOpen}
                                modalSample={data}
                                onModalClose={handleAnalystFeedbackClose}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Test Package:</TableCell>
                            <TableCell colSpan={2}>
                              {data.sampleTests
                                .map(x => x.testGroup.testGroupName)
                                .filter(unique)
                                .join(', ')}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>PDF:</TableCell>
                            <TableCell colSpan={2}>
                              {data.batchID > 0 ? (
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={handlePDFDownload}
                                >
                                  <GetAppIcon />
                                  Download
                                </Button>
                              ) : (
                                'Not Available'
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid container item direction="row" spacing={2}>
                <Grid item xs={12} md={4}>
                  <Card style={{ height: '100%' }}>
                    <CardHeader
                      title="Comments"
                      className={classes.cardHeader}
                      avatar={
                        <Avatar className={classes.avatar}>
                          <CommentIcon />
                        </Avatar>
                      }
                    />
                    <CardContent>{data.recommendations}</CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card style={{ height: '100%' }}>
                    <CardHeader
                      title="Problems"
                      className={classes.cardHeader}
                      avatar={
                        <Avatar className={classes.avatar}>
                          <WarningIcon />
                        </Avatar>
                      }
                    />
                    <CardContent>{data.problems}</CardContent>
                  </Card>
                </Grid>
                <Grid
                  container
                  item
                  direction="row"
                  xs={12}
                  md={4}
                  style={{ margin: '0px', padding: '0px', height: '100%' }}
                  spacing={2}
                >
                  <Grid item xs={12} sm={6} md={12}>
                    <Card style={{ height: '100%' }}>
                      <CardHeader
                        title="Corrective Actions"
                        style={{ overflow: 'hidden' }}
                        className={classes.cardHeader}
                        avatar={
                          <Avatar className={classes.avatar}>
                            <CheckCircleIcon />
                          </Avatar>
                        }
                      />
                      <CardContent>
                        {data.cuCorrAct !== null ? (
                          <div>
                            <div>
                              {moment(data.cuCorrAct.cADate).format('l')}
                            </div>
                            <div>{data.cuCorrAct.cAType}</div>
                            <div>{data.cuCorrAct.cANote}</div>
                            <div>{data.cuCorrAct.workOrderNumber}</div>
                          </div>
                        ) : (
                          ''
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={12}>
                    <Card style={{ height: '100%' }}>
                      <CardHeader
                        title="Customer Notes"
                        className={classes.cardHeader}
                        avatar={
                          <Avatar className={classes.avatar}>
                            <NotesIcon />
                          </Avatar>
                        }
                      />
                      <CardContent>{data.rptNotes}</CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item>
                <Table
                  style={{ marginTop: '25px' }}
                  className={classes.chartTable}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell
                        colSpan={3}
                        style={{ border: 'none' }}
                      ></TableCell>
                      <TableCell />
                      <TableCell
                        style={{ paddingBottom: '0px', fontWeight: 'bold' }}
                      >
                        SELECTED
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={3}>Date Sampled</TableCell>
                      <TableCell
                        className={classes.refLab}
                        style={{ width: '96px' }}
                      >
                        New Oil
                      </TableCell>
                      {samples.map((x, i) => (
                        <TableCell
                          key={i}
                          style={
                            i == 0
                              ? {
                                  borderTop: '2px solid black',
                                  borderRight: '2px solid black',
                                  borderLeft: '2px solid black',
                                }
                              : null
                          }
                        >
                          {moment(x.sampleDate).format('l')}
                        </TableCell>
                      ))}
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={3}>Lab No.</TableCell>
                      <TableCell className={classes.refLab}>
                        {refData !== null ? <>{refData.labID}</> : ''}
                      </TableCell>
                      {samples.map((x, i) => (
                        <TableCell
                          key={i}
                          style={
                            i == 0
                              ? {
                                  borderRight: '2px solid black',
                                  borderLeft: '2px solid black',
                                }
                              : null
                          }
                        >
                          <a
                            href={`/dart/data?labID=${
                              x.labID
                            }&fromDate=${moment(startDate).format(
                              'l'
                            )}&toDate=${moment(endDate).format('l')}`}
                            state={{
                              update: true,
                              startDate: startDate,
                              endDate: endDate,
                            }}
                          >
                            {x.labID}
                          </a>
                        </TableCell>
                      ))}
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={3}>Machine/Lube Cond</TableCell>
                      <TableCell className={classes.refLab}>
                        {refData !== null ? (
                          <>
                            <div style={{ display: 'inline-block' }}>
                              <ConditionElement condition={refData.machCond} />
                            </div>
                            <div
                              style={{
                                display: 'inline-block',
                                marginLeft: '5px',
                              }}
                            >
                              <ConditionElement condition={refData.lubCond} />
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                      </TableCell>
                      {samples.map((x, i) => (
                        <TableCell
                          key={i}
                          style={
                            i == 0
                              ? {
                                  borderRight: '2px solid black',
                                  borderLeft: '2px solid black',
                                }
                              : null
                          }
                        >
                          <div style={{ display: 'inline-block' }}>
                            <ConditionElement condition={x.machCond} />
                          </div>
                          <div
                            style={{
                              display: 'inline-block',
                              marginLeft: '5px',
                            }}
                          >
                            <ConditionElement condition={x.lubCond} />
                          </div>
                        </TableCell>
                      ))}
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={3}>Oil Change?</TableCell>
                      <TableCell className={classes.refLab}>
                        {refData !== null
                          ? refData.oilChange === 1
                            ? 'Yes'
                            : 'No'
                          : ''}
                      </TableCell>
                      {samples.map((x, i) => (
                        <TableCell
                          key={i}
                          style={
                            i == 0
                              ? {
                                  borderRight: '2px solid black',
                                  borderLeft: '2px solid black',
                                }
                              : null
                          }
                        >
                          {x.oilChange === 1 ? 'Yes' : 'No'}
                        </TableCell>
                      ))}
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={3}>Lube Hours</TableCell>
                      <TableCell className={classes.refLab}>
                        {refData !== null ? getHours(refData.timeSinceOC) : ''}
                      </TableCell>
                      {samples.map((x, i) => (
                        <TableCell
                          key={i}
                          style={
                            i == 0
                              ? {
                                  borderRight: '2px solid black',
                                  borderLeft: '2px solid black',
                                }
                              : null
                          }
                        >
                          {getHours(x.timeSinceOC)}
                        </TableCell>
                      ))}
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={3}>Machine Hours</TableCell>
                      <TableCell className={classes.refLab}>
                        {refData !== null ? getHours(refData.timeSinceOH) : ''}
                      </TableCell>
                      {samples.map((x, i) => (
                        <TableCell
                          key={i}
                          style={
                            i == 0
                              ? {
                                  borderRight: '2px solid black',
                                  borderLeft: '2px solid black',
                                }
                              : null
                          }
                        >
                          {getHours(x.timeSinceOH)}
                        </TableCell>
                      ))}
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={3}>Reviewed ?</TableCell>
                      <TableCell className={classes.refLab}>
                        {refData !== null
                          ? refData.reviewed
                            ? 'Yes'
                            : 'No'
                          : ''}
                      </TableCell>
                      {samples.map((x, i) => (
                        <TableCell
                          key={i}
                          style={
                            i == 0
                              ? {
                                  borderRight: '2px solid black',
                                  borderLeft: '2px solid black',
                                }
                              : null
                          }
                        >
                          {profileContext.profile.user?.readOnly ? (
                            x.reviewed ? (
                              <CheckIcon />
                            ) : (
                              'No'
                            )
                          ) : (
                            <FormControlLabel
                              control={
                                <Switch
                                  name="reviewed"
                                  color="secondary"
                                  checked={x.reviewed}
                                  onChange={event =>
                                    handleReviewedToggle(event, x)
                                  }
                                />
                              }
                              label=""
                            />
                          )}
                        </TableCell>
                      ))}
                      <TableCell />
                    </TableRow>
                    {data.isiSpc !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Elemental Spectroscopy (ppm) ASTM D5185 Mod (-)
                            indicates below detection limit
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            rowSpan={10}
                            style={{ width: '15px', padding: '0px' }}
                          >
                            <div
                              style={{
                                transform: 'rotate(90deg)',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Wear Metals
                            </div>
                          </TableCell>
                          <TableCell>Iron</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiSpc"
                              field="iron"
                              title="Elemental Spectroscopy (ppm) ASTM D5185 Mod (-) indicates below detection limit"
                              subtitle="Iron"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiSpc"
                            field="iron"
                            flag="fEflg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiSpc"
                              field="iron"
                              flag="fEflg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell>Copper</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiSpc"
                              field="copper"
                              title="Elemental Spectroscopy (ppm) ASTM D5185 Mod (-) indicates below detection limit"
                              subtitle="Copper"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiSpc"
                            field="copper"
                            flag="cUflg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiSpc"
                              field="copper"
                              flag="cUflg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell>Lead</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiSpc"
                              field="lead"
                              title="Elemental Spectroscopy (ppm) ASTM D5185 Mod (-) indicates below detection limit"
                              subtitle="Lead"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiSpc"
                            field="lead"
                            flag="pBflg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiSpc"
                              field="lead"
                              flag="pBflg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell>Aluminum</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiSpc"
                              field="aluminum"
                              title="Elemental Spectroscopy (ppm) ASTM D5185 Mod (-) indicates below detection limit"
                              subtitle="Aluminum"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiSpc"
                            field="aluminum"
                            flag="aLflg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiSpc"
                              field="aluminum"
                              flag="aLflg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell>Tin</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiSpc"
                              field="tin"
                              title="Elemental Spectroscopy (ppm) ASTM D5185 Mod (-) indicates below detection limit"
                              subtitle="Tin"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiSpc"
                            field="tin"
                            flag="sNflg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiSpc"
                              field="tin"
                              flag="sNflg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell>Nickel</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiSpc"
                              field="nickel"
                              title="Elemental Spectroscopy (ppm) ASTM D5185 Mod (-) indicates below detection limit"
                              subtitle="Nickel"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiSpc"
                            field="nickel"
                            flag="nIflg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiSpc"
                              field="nickel"
                              flag="nIflg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell>Chromium</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiSpc"
                              field="chromium"
                              title="Elemental Spectroscopy (ppm) ASTM D5185 Mod (-) indicates below detection limit"
                              subtitle="Chromium"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiSpc"
                            field="chromium"
                            flag="cRflg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiSpc"
                              field="chromium"
                              flag="cRflg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell>Titanium</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiSpc"
                              field="titanium"
                              title="Elemental Spectroscopy (ppm) ASTM D5185 Mod (-) indicates below detection limit"
                              subtitle="Titanium"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiSpc"
                            field="titanium"
                            flag="tIflg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiSpc"
                              field="titanium"
                              flag="tIflg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell>Vanadium</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiSpc"
                              field="vanadium"
                              title="Elemental Spectroscopy (ppm) ASTM D5185 Mod (-) indicates below detection limit"
                              subtitle="Vanadium"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiSpc"
                            field="vanadium"
                            flag="vflg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiSpc"
                              field="vanadium"
                              flag="vflg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell>Silver</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiSpc"
                              field="silver"
                              title="Elemental Spectroscopy (ppm) ASTM D5185 Mod (-) indicates below detection limit"
                              subtitle="Silver"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiSpc"
                            field="silver"
                            flag="aGflg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiSpc"
                              field="silver"
                              flag="aGflg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell
                            rowSpan={6}
                            style={{ width: '15px', padding: '0px' }}
                          >
                            <div
                              style={{
                                transform: 'rotate(90deg)',
                                whiteSpace: 'noWrap',
                              }}
                            >
                              Additives
                            </div>
                          </TableCell>
                          <TableCell>Calcium</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiSpc"
                              field="calcium"
                              title="Elemental Spectroscopy (ppm) ASTM D5185 Mod (-) indicates below detection limit"
                              subtitle="Calcium"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiSpc"
                            field="calcium"
                            flag="cAflg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiSpc"
                              field="calcium"
                              flag="cAflg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell>Magnesium</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiSpc"
                              field="magnesium"
                              title="Elemental Spectroscopy (ppm) ASTM D5185 Mod (-) indicates below detection limit"
                              subtitle="Magnesium"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiSpc"
                            field="magnesium"
                            flag="mGflg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiSpc"
                              field="magnesium"
                              flag="mGflg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell>Phosphorus</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiSpc"
                              field="phosphorus"
                              title="Elemental Spectroscopy (ppm) ASTM D5185 Mod (-) indicates below detection limit"
                              subtitle="Phosphorus"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiSpc"
                            field="phosphorus"
                            flag="pflg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiSpc"
                              field="phosphorus"
                              flag="pflg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell>Zinc</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiSpc"
                              field="zinc"
                              title="Elemental Spectroscopy (ppm) ASTM D5185 Mod (-) indicates below detection limit"
                              subtitle="Zinc"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiSpc"
                            field="zinc"
                            flag="zNflg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiSpc"
                              field="zinc"
                              flag="zNflg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell>Barium</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiSpc"
                              field="barium"
                              title="Elemental Spectroscopy (ppm) ASTM D5185 Mod (-) indicates below detection limit"
                              subtitle="Barium"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiSpc"
                            field="barium"
                            flag="bAflg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiSpc"
                              field="barium"
                              flag="bAflg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell>Molybdenum</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiSpc"
                              field="molybdenum"
                              title="Elemental Spectroscopy (ppm) ASTM D5185 Mod (-) indicates below detection limit"
                              subtitle="Molybdenum"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiSpc"
                            field="molybdenum"
                            flag="mOflg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiSpc"
                              field="molybdenum"
                              flag="mOflg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell
                            rowSpan={5}
                            style={{ width: '15px', padding: '0px' }}
                          >
                            <div
                              style={{
                                transform: 'rotate(90deg)',
                                whiteSpace: 'noWrap',
                              }}
                            >
                              Contaminants
                            </div>
                          </TableCell>
                          <TableCell>Silicon</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiSpc"
                              field="silicon"
                              title="Elemental Spectroscopy (ppm) ASTM D5185 Mod (-) indicates below detection limit"
                              subtitle="Silicon"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiSpc"
                            field="silicon"
                            flag="sIflg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiSpc"
                              field="silicon"
                              flag="sIflg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell>Boron</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiSpc"
                              field="boron"
                              title="Elemental Spectroscopy (ppm) ASTM D5185 Mod (-) indicates below detection limit"
                              subtitle="Boron"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiSpc"
                            field="boron"
                            flag="bflg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiSpc"
                              field="boron"
                              flag="bflg"
                            />
                          ))}
                        </TableRow>
                        {samples.some(x => x.isiSpc?.lithium !== null) ? (
                          <TableRow>
                            <TableCell>Lithium</TableCell>
                            <TableCell>
                              <SparkLine
                                samples={samples}
                                test="isiSpc"
                                field="lithium"
                                title="Elemental Spectroscopy (ppm) ASTM D5185 Mod (-) indicates below detection limit"
                                subtitle="Lithium"
                              />
                            </TableCell>
                            <ResultTableCell
                              sample={refData}
                              test="isiSpc"
                              field="lithium"
                              flag="lIflg"
                              isRef="true"
                            />
                            {samples.map((x, i) => (
                              <ResultTableCell
                                key={i}
                                sample={x}
                                test="isiSpc"
                                field="lithium"
                                flag="lIflg"
                              />
                            ))}
                          </TableRow>
                        ) : null}
                        <TableRow>
                          <TableCell>Sodium</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiSpc"
                              field="sodium"
                              title="Elemental Spectroscopy (ppm) ASTM D5185 Mod (-) indicates below detection limit"
                              subtitle="Sodium"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiSpc"
                            field="sodium"
                            flag="nAflg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiSpc"
                              field="sodium"
                              flag="nAflg"
                            />
                          ))}
                        </TableRow>
                        {samples.some(x => x.isiSpc?.potassium !== null) ? (
                          <TableRow>
                            <TableCell>Potassium</TableCell>
                            <TableCell>
                              <SparkLine
                                samples={samples}
                                test="isiSpc"
                                field="potassium"
                                title="Elemental Spectroscopy (ppm) ASTM D5185 Mod (-) indicates below detection limit"
                                subtitle="Potassium"
                              />
                            </TableCell>
                            <ResultTableCell
                              sample={refData}
                              test="isiSpc"
                              field="potassium"
                              flag="kflg"
                              isRef="true"
                            />
                            {samples.map((x, i) => (
                              <ResultTableCell
                                key={i}
                                sample={x}
                                test="isiSpc"
                                field="potassium"
                                flag="kflg"
                              />
                            ))}
                          </TableRow>
                        ) : null}
                      </>
                    ) : (
                      ''
                    )}
                    {data.machine.lubricant.iRMethod === 2 &&
                    data.isiIr24KtMethod !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            FTIR Spectroscopy (Indexing Numbers) IWI-110 24KT
                            Method
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Additive</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiIr24KtMethod"
                              field="additive"
                              title="FTIR Spectroscopy (Indexing Numbers) IWI-110 24KT Method"
                              subtitle="Additive"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiIr24KtMethod"
                            field="additive"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiIr24KtMethod"
                              field="additive"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Mineral Oil Cont.</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiIr24KtMethod"
                              field="mineral"
                              title="FTIR Spectroscopy (Indexing Numbers) IWI-110 24KT Method"
                              subtitle="Mineral Oil Cont."
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiIr24KtMethod"
                            field="mineral"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiIr24KtMethod"
                              field="mineral"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>PAG / Ester Cont.</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiIr24KtMethod"
                              field="sullube"
                              title="FTIR Spectroscopy (Indexing Numbers) IWI-110 24KT Method"
                              subtitle="PAG / Ester Cont."
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiIr24KtMethod"
                            field="sollube"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiIr24KtMethod"
                              field="sollube"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Cleaner Cont.</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiIr24KtMethod"
                              field="cleaner"
                              title="FTIR Spectroscopy (Indexing Numbers) IWI-110 24KT Method"
                              subtitle="Cleaner Cont."
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiIr24KtMethod"
                            field="cleaner"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiIr24KtMethod"
                              field="cleaner"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.machine.lubricant.iRMethod === 0 &&
                    data.isiIrJoapMethod !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            FTIR Spectroscopy (Indexing Numbers) ASTM E2412
                          </TableCell>
                        </TableRow>
                        {data.machine.machType === 'ENG' ||
                        data.machine.machType === 'NAT' ||
                        data.machine.machType === 'GAS' ? (
                          <>
                            <TableRow>
                              <TableCell colSpan={2}>Oxidation</TableCell>
                              <TableCell>
                                <SparkLine
                                  samples={samples}
                                  test="isiIrJoapMethod"
                                  field="oxidProd"
                                  title="FTIR Spectroscopy (Indexing Numbers) ASTM E2412"
                                  subtitle="Oxidation"
                                />
                              </TableCell>
                              <ResultTableCell
                                sample={refData}
                                test="isiIrJoapMethod"
                                field="oxidProd"
                                isRef="true"
                              />
                              {samples.map((x, i) => (
                                <ResultTableCell
                                  key={i}
                                  sample={x}
                                  test="isiIrJoapMethod"
                                  field="oxidProd"
                                />
                              ))}
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={2}>Glycol</TableCell>
                              <TableCell>
                                <SparkLine
                                  samples={samples}
                                  test="isiIrJoapMethod"
                                  field="glycol"
                                  title="FTIR Spectroscopy (Indexing Numbers) ASTM E2412"
                                  subtitle="Glycol"
                                />
                              </TableCell>
                              <ResultTableCell
                                sample={refData}
                                test="isiIrJoapMethod"
                                field="glycol"
                                isRef="true"
                              />
                              {samples.map((x, i) => (
                                <ResultTableCell
                                  key={i}
                                  sample={x}
                                  test="isiIrJoapMethod"
                                  field="glycol"
                                />
                              ))}
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={2}>Soot</TableCell>
                              <TableCell>
                                <SparkLine
                                  samples={samples}
                                  test="isiIrJoapMethod"
                                  field="soot"
                                  title="FTIR Spectroscopy (Indexing Numbers) ASTM E2412"
                                  subtitle="Soot"
                                />
                              </TableCell>
                              <ResultTableCell
                                sample={refData}
                                test="isiIrJoapMethod"
                                field="soot"
                                isRef="true"
                              />
                              {samples.map((x, i) => (
                                <ResultTableCell
                                  key={i}
                                  sample={x}
                                  test="isiIrJoapMethod"
                                  field="soot"
                                />
                              ))}
                            </TableRow>
                            {data.machine.machType === 'NAT' ||
                            data.machine.machType === 'ENG' ? (
                              <TableRow>
                                <TableCell colSpan={2}>Nitration</TableCell>
                                <TableCell>
                                  <SparkLine
                                    samples={samples}
                                    test="isiIrJoapMethod"
                                    field="nitraProd"
                                    title="FTIR Spectroscopy (Indexing Numbers) ASTM E2412"
                                    subtitle="Nitration"
                                  />
                                </TableCell>
                                <ResultTableCell
                                  sample={refData}
                                  test="isiIrJoapMethod"
                                  field="nitraProd"
                                  isRef="true"
                                />
                                {samples.map((x, i) => (
                                  <ResultTableCell
                                    key={i}
                                    sample={x}
                                    test="isiIrJoapMethod"
                                    field="nitraProd"
                                  />
                                ))}
                              </TableRow>
                            ) : (
                              <TableRow>
                                <TableCell colSpan={2}>Fuel Dilution</TableCell>
                                <TableCell>
                                  <SparkLine
                                    samples={samples}
                                    test="isiIrJoapMethod"
                                    field="gasDil"
                                    title="FTIR Spectroscopy (Indexing Numbers) ASTM E2412"
                                    subtitle="Fuel Dilution"
                                  />
                                </TableCell>
                                <ResultTableCell
                                  sample={refData}
                                  test="isiIrJoapMethod"
                                  field="gasDil"
                                  isRef="true"
                                />
                                {samples.map((x, i) => (
                                  <ResultTableCell
                                    key={i}
                                    sample={x}
                                    test="isiIrJoapMethod"
                                    field="gasDil"
                                  />
                                ))}
                              </TableRow>
                            )}
                          </>
                        ) : (
                          <>
                            {data.machine.lubricant.fluidType ===
                            'Phosphate Ester' ? (
                              <TableRow>
                                <TableCell colSpan={2}>
                                  Phosphate Ester Oxidation
                                </TableCell>
                                <TableCell>
                                  <SparkLine
                                    samples={samples}
                                    test="isiIrJoapMethod"
                                    field="phosphateEsterOX"
                                    title="FTIR Spectroscopy (Indexing Numbers) ASTM E2412"
                                    subtitle="Phosphate Ester"
                                  />
                                </TableCell>
                                <ResultTableCell
                                  sample={refData}
                                  test="isiIrJoapMethod"
                                  field="phosphateEsterOX"
                                  isRef="true"
                                />
                                {samples.map((x, i) => (
                                  <ResultTableCell
                                    key={i}
                                    sample={x}
                                    test="isiIrJoapMethod"
                                    field="phosphateEsterOX"
                                  />
                                ))}
                              </TableRow>
                            ) : (
                              <TableRow>
                                <TableCell colSpan={2}>Oxidation</TableCell>
                                <TableCell>
                                  <SparkLine
                                    samples={samples}
                                    test="isiIrJoapMethod"
                                    field="oxidProd"
                                    title="FTIR Spectroscopy (Indexing Numbers) ASTM E2412"
                                    subtitle="Oxidation"
                                  />
                                </TableCell>
                                <ResultTableCell
                                  sample={refData}
                                  test="isiIrJoapMethod"
                                  field="oxidProd"
                                  isRef="true"
                                />
                                {samples.map((x, i) => (
                                  <ResultTableCell
                                    key={i}
                                    sample={x}
                                    test="isiIrJoapMethod"
                                    field="oxidProd"
                                  />
                                ))}
                              </TableRow>
                            )}
                            {data.machine.lubricant.fluidType ===
                            'Polyol Ester' ? (
                              <TableRow>
                                <TableCell colSpan={2}>
                                  Polyol Ester Alcohol
                                </TableCell>
                                <TableCell>
                                  <SparkLine
                                    samples={samples}
                                    test="isiIrJoapMethod"
                                    field="polyolEster"
                                    title="FTIR Spectroscopy (Indexing Numbers) ASTM E2412"
                                    subtitle="Polyol Ester"
                                  />
                                </TableCell>
                                <ResultTableCell
                                  sample={refData}
                                  test="isiIrJoapMethod"
                                  field="polyolEster"
                                  isRef="true"
                                />
                                {samples.map((x, i) => (
                                  <ResultTableCell
                                    key={i}
                                    sample={x}
                                    test="isiIrJoapMethod"
                                    field="polyolEster"
                                  />
                                ))}
                              </TableRow>
                            ) : data.machine.lubricant.fluidType ===
                              'Phosphate Ester' ? (
                              <TableRow>
                                <TableCell colSpan={2}>
                                  Phosphate Ester Alcohol
                                </TableCell>
                                <TableCell>
                                  <SparkLine
                                    samples={samples}
                                    test="isiIrJoapMethod"
                                    field="phosphateEsterOH"
                                    title="FTIR Spectroscopy (Indexing Numbers) ASTM E2412"
                                    subtitle="Phosphate Ester Alcohol"
                                  />
                                </TableCell>
                                <ResultTableCell
                                  sample={refData}
                                  test="isiIrJoapMethod"
                                  field="phosphateEsterOH"
                                  isRef="true"
                                />
                                {samples.map((x, i) => (
                                  <ResultTableCell
                                    key={i}
                                    sample={x}
                                    test="isiIrJoapMethod"
                                    field="phosphateEsterOH"
                                  />
                                ))}
                              </TableRow>
                            ) : (
                              <TableRow>
                                <TableCell colSpan={2}>Nitration</TableCell>
                                <TableCell>
                                  <SparkLine
                                    samples={samples}
                                    test="isiIrJoapMethod"
                                    field="nitraProd"
                                    title="FTIR Spectroscopy (Indexing Numbers) ASTM E2412"
                                    subtitle="Nitration"
                                  />
                                </TableCell>
                                <ResultTableCell
                                  sample={refData}
                                  test="isiIrJoapMethod"
                                  field="nitraProd"
                                  isRef="true"
                                />
                                {samples.map((x, i) => (
                                  <ResultTableCell
                                    key={i}
                                    sample={x}
                                    test="isiIrJoapMethod"
                                    field="nitraProd"
                                  />
                                ))}
                              </TableRow>
                            )}
                          </>
                        )}
                        <TableRow>
                          <TableCell colSpan={2}>Anti Wear</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiIrJoapMethod"
                              field="antiWear"
                              title="FTIR Spectroscopy (Indexing Numbers) ASTM E2412"
                              subtitle="Anti Wear"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiIrJoapMethod"
                            field="antiWear"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiIrJoapMethod"
                              field="antiWear"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Other Fluid</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiIrJoapMethod"
                              field="product"
                              title="FTIR Spectroscopy (Indexing Numbers) ASTM E2412"
                              subtitle="Other Fluid"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiIrJoapMethod"
                            field="product"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiIrJoapMethod"
                              field="product"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.machine.lubricant.iRMethod === 1 &&
                    data.isIrTurbineMethod !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            FTIR Spectroscopy (Indexing Numbers) IWI-110 Turbine
                            Method
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Thermal Event Acid</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiIrTurbineMethod"
                              field="acidUnsatEst"
                              title="FTIR Spectroscopy (Indexing Numbers) IWI-110 Turbine Method"
                              subtitle="Thermal Event Acid"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiIrTurbineMethod"
                            field="acidUnsatEst"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiIrTurbineMethod"
                              field="acidUnsatEst"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Acid Oxidation</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiIrTurbineMethod"
                              field="aCIDOxid"
                              title="FTIR Spectroscopy (Indexing Numbers) IWI-110 Turbine Method"
                              subtitle="Acid Oxidation"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiIrTurbineMethod"
                            field="aCIDOxid"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiIrTurbineMethod"
                              field="aCIDOxid"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Ester</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiIrTurbineMethod"
                              field="oxidationEster"
                              title="FTIR Spectroscopy (Indexing Numbers) IWI-110 Turbine Method"
                              subtitle="Ester"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiIrTurbineMethod"
                            field="oxidationEster"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiIrTurbineMethod"
                              field="oxidationEster"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Aromatic Additive</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiIrTurbineMethod"
                              field="aromatics"
                              title="FTIR Spectroscopy (Indexing Numbers) IWI-110 Turbine Method"
                              subtitle="Aromatic Additive"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiIrTurbineMethod"
                            field="aromatics"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiIrTurbineMethod"
                              field="aromatics"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Base Oil Aromatic</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiIrTurbineMethod"
                              field="olefins"
                              title="FTIR Spectroscopy (Indexing Numbers) IWI-110 Turbine Method"
                              subtitle="Base Oil Aromatic"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiIrTurbineMethod"
                            field="olefins"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiIrTurbineMethod"
                              field="olefins"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Amine Antioxidants</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiIrTurbineMethod"
                              field="amines"
                              title="FTIR Spectroscopy (Indexing Numbers) IWI-110 Turbine Method"
                              subtitle="Amine Antioxidants"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiIrTurbineMethod"
                            field="amines"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiIrTurbineMethod"
                              field="amines"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>
                            Phenolic Antioxidants
                          </TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiIrTurbineMethod"
                              field="phenolicOH"
                              title="FTIR Spectroscopy (Indexing Numbers) IWI-110 Turbine Method"
                              subtitle="Phenolic Antioxidants"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiIrTurbineMethod"
                            field="phenolicOH"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiIrTurbineMethod"
                              field="phenolicOH"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiPc !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Particle Count (particles per ml) ISO 4406:99
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>ISO Code</TableCell>
                          <TableCell className={classes.sparkLineArea}>
                            <SparkLine
                              samples={samples}
                              test="isiPc"
                              field="iso"
                              title="Particle Count (particles per ml) ISO 4406:99"
                              subtitle="ISO Code"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiPc"
                            field="iso"
                            flag="pCFlg"
                            isRef="true"
                            tooltip={`Marginal Alarm Limits: ${pcIsoLimit}`}
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiPc"
                              field="iso"
                              flag="pCFlg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>&gt; 4 Micron</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiPc"
                              field="g2"
                              title="Particle Count (particles per ml) ISO 4406:99"
                              subtitle="> 4 Micron"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiPc"
                            field="g2"
                            flag="pCFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiPc"
                              field="g2"
                              flag="pCFlg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>&gt; 6 Micron</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiPc"
                              field="g5"
                              title="Particle Count (particles per ml) ISO 4406:99"
                              subtitle="> 6 Micron"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiPc"
                            field="g5"
                            flag="pCFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiPc"
                              field="g5"
                              flag="pCFlg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>&gt; 14 Micron</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiPc"
                              field="g15"
                              title="Particle Count (particles per ml) ISO 4406:99"
                              subtitle="> 14 Micron"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiPc"
                            field="g15"
                            flag="pCFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiPc"
                              field="g15"
                              flag="pCFlg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>&gt; 50 Micron</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiPc"
                              field="g50"
                              title="Particle Count (particles per ml) ISO 4406:99"
                              subtitle="> 50 Micron"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiPc"
                            field="g50"
                            flag="pCFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiPc"
                              field="g50"
                              flag="pCFlg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>&gt; 100 Micron</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiPc"
                              field="g100"
                              title="Particle Count (particles per ml) ISO 4406:99"
                              subtitle="> 100 Micron"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiPc"
                            field="g100"
                            flag="pCFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiPc"
                              field="g100"
                              flag="pCFlg"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiPcl !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Optical Particle Count (particles per ml) ISO
                            4406:99
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={3}>ISO Code</TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiPcl"
                            field="iso"
                            flag="pCLFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiPcl"
                              field="iso"
                              flag="pCLFlg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>&gt; 4 Micron</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiPcl"
                              field="g4"
                              title="Optical Particle Count (particles per ml) ISO 4406:99"
                              subtitle="> 4 Micron"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiPcl"
                            field="g4"
                            flag="pCLFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiPcl"
                              field="g4"
                              flag="pCLFlg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>&gt; 6 Micron</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiPcl"
                              field="g6"
                              title="Optical Particle Count (particles per ml) ISO 4406:99"
                              subtitle="> 6 Micron"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiPcl"
                            field="g6"
                            flag="pCLFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiPcl"
                              field="g6"
                              flag="pCLFlg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>&gt; 14 Micron</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiPcl"
                              field="g14"
                              title="Optical Particle Count (particles per ml) ISO 4406:99"
                              subtitle="> 14 Micron"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiPcl"
                            field="g14"
                            flag="pCLFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiPcl"
                              field="g14"
                              flag="pCLFlg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>&gt; 50 Micron</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiPcl"
                              field="g50"
                              title="Optical Particle Count (particles per ml) ISO 4406:99"
                              subtitle="> 50 Micron"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiPcl"
                            field="g50"
                            flag="pCLFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiPcl"
                              field="g50"
                              flag="pCLFlg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>&gt; 68 Micron</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiPcl"
                              field="g68"
                              title="Optical Particle Count (particles per ml) ISO 4406:99"
                              subtitle="> 68 Micron"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiPcl"
                            field="g68"
                            flag="pCLFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiPcl"
                              field="g68"
                              flag="pCLFlg"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiV40 !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Viscosity (centistokes) ASTM D445
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Viscosity @ 40C</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiV40"
                              field="results"
                              title="Viscosity (centistokes) ASTM D445"
                              subtitle="Viscosity @ 40C"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiV40"
                            field="results"
                            flag="visFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiV40"
                              field="results"
                              flag="visFlg"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiV100 !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Viscosity (centistokes) ASTM D445
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Viscosity @ 100C</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiV100"
                              field="results"
                              title="Viscosity (centistokes) ASTM D445"
                              subtitle="Viscosity @ 100C"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiV100"
                            field="results"
                            flag="visFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiV100"
                              field="results"
                              flag="visFlg"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiTan !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Acid Number (mg KOH/g) ASTM D974
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Acid Number</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiTan"
                              field="result"
                              title="Acid Number (mg KOH/g) ASTM D974"
                              subtitle="Acid Number"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiTan"
                            field="result"
                            flag="resultFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiTan"
                              field="result"
                              flag="resultFlg"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiTbn !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Base Number (mg KOH/g) † - ASTM D4739 ‡ - IWI-390
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Base Number</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiTbn"
                              field="result"
                              title="Base Number (mg KOH/g) † - ASTM  D4739   ‡ - IWI-390"
                              subtitle="Base Number"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiTbn"
                            field="result"
                            flag="resultFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiTbn"
                              field="result"
                              flag="resultFlg"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiWp !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Ferrous Wear Concentration (ppm)
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>
                            Ferrous Wear Concentration
                          </TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiWp"
                              field="wPC"
                              title="Ferrous Wear Concentration (ppm)"
                              subtitle="Ferrous Wear Concentration"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiWp"
                            field="wPC"
                            flag="wPFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiWp"
                              field="wPC"
                              flag="wPFlg"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiDem !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Demulsibility (%) (ASTM D1401)
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Demulsibility</TableCell>
                          <TableCell>&nbsp;</TableCell>
                          <TableCell
                            style={
                              refData !== null &&
                              refData.isiDem !== null &&
                              refData.isiDem.demFlg > 0
                                ? { backgroundColor: 'rgb(255, 236, 18)' }
                                : { backgroundColor: '#eee' }
                            }
                          >
                            {refData !== null && refData.isiDem !== null
                              ? `${refData.isiDem.oil}/${refData.isiDem.water}/${refData.isiDem.emulsion}/${refData.isiDem.demTime}`
                              : ''}
                          </TableCell>
                          {samples.map((x, i) => (
                            <TableCell
                              key={i}
                              style={
                                x !== null && i == 0
                                  ? {
                                      borderRight: '2px solid black',
                                      borderLeft: '2px solid black',
                                    }
                                  : null &&
                                    x.isiDem !== null &&
                                    x.isiDem.demFlg > 0
                                  ? { backgroundColor: 'rgb(255, 236, 18)' }
                                  : {}
                              }
                            >
                              {x !== null && x.isiDem !== null
                                ? `${x.isiDem.oil}/${x.isiDem.water}/${x.isiDem.emulsion}/${x.isiDem.demTime}`
                                : ''}
                            </TableCell>
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiColor !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Color (clr) ASTM D 1500
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Color</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiColor"
                              field="result"
                              title="Color (clr) ASTM D 1500"
                              subtitle="Color"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiColor"
                            field="result"
                            flag="resultFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiColor"
                              field="result"
                              flag="resultFlg"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiRpvot !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Rotating Pressure Vessel Oxidation Test (min.) ASTM
                            D2272
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>RPVOT</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiRpvot"
                              field="rPVOT"
                              title="Rotating Pressure Vessel Oxidation Test (min.) ASTM D2272"
                              subtitle="RPVOT"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiRpvot"
                            field="rPVOT"
                            flag="rPVOTFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiRpvot"
                              field="rPVOT"
                              flag="rPVOTFlg"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiFoam !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Foam Seq 1 ASTM D892
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Foam Seq 1</TableCell>
                          <TableCell>&nbsp;</TableCell>
                          <TableCell
                            style={
                              refData !== null &&
                              refData.isiFoam !== null &&
                              refData.isiFoam.foamFlg > 0
                                ? { backgroundColor: 'rgb(255, 236, 18)' }
                                : { backgroundColor: '#eee' }
                            }
                          >
                            {refData !== null && refData.isiFoam !== null
                              ? formatS1Time(refData.isiFoam.s1Time)
                              : ''}
                          </TableCell>
                          {samples.map((x, i) => (
                            <TableCell
                              key={i}
                              style={
                                x !== null &&
                                x.isiFoam !== null &&
                                x.isiFoam.foamFlg > 0
                                  ? { backgroundColor: 'rgb(255, 236, 18)' }
                                  : {}
                              }
                            >
                              {x !== null && x.isiFoam !== null
                                ? formatS1Time(x.isiFoam.s1Time)
                                : ''}
                            </TableCell>
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiRust !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Rust (Pass/Fail) ASTM D665 A
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Rust</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiRust"
                              field="result"
                              title="Rust (Pass/Fail) ASTM D665 A"
                              subtitle="Rust"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiRust"
                            field="result"
                            flag="resultFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiRust"
                              field="result"
                              flag="resultFlg"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {samples.some(x => x.isiMpc !== null) ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Membrane Patch Colorimetry (%)
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>
                            Membrane Patch Colorimetry
                          </TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiMpc"
                              field="mPC"
                              title="Membrane Patch Colorimetry (%)"
                              subtitle="Membrane Patch Colorimetry"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiMpc"
                            field="mPC"
                            flag="mPCflg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiMpc"
                              field="mPC"
                              flag="mPCflg"
                            />
                          ))}
                        </TableRow>

                        {['l', 'a', 'b'].map(field => (
                          <TableRow>
                            <TableCell colSpan={2}>
                              {field.toUpperCase()}
                            </TableCell>
                            <TableCell>
                              <SparkLine
                                samples={samples}
                                test="isiMpc"
                                field={field}
                                title="Membrane Patch Colorimetry (%)"
                                subtitle="Membrane Patch Colorimetry"
                              />
                            </TableCell>
                            <ResultTableCell
                              sample={refData}
                              test="isiMpc"
                              field={field}
                              flag="mPCflg"
                              isRef="true"
                            />
                            {samples.map((x, i) => (
                              <ResultTableCell
                                key={i}
                                sample={x}
                                test="isiMpc"
                                field={field}
                                flag="mPCflg"
                              />
                            ))}
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiUc !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Ultra Centrifuge (%)
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Ultra Centrifuge</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiUc"
                              field="uC"
                              title="Ultra Centrifuge (%)"
                              subtitle="Ultra Cetrifuge"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiUc"
                            field="uC"
                            flag="uCFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiUc"
                              field="uC"
                              flag="uCFlg"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiRa !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Reserve ALK (mL HCL)
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Reserve Alkalinity</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiRa"
                              field="result"
                              title="Reserve ALK (mL HCL)"
                              subtitle="Reserve Alkalinity"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiRa"
                            field="result"
                            flag="resultFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiRa"
                              field="result"
                              flag="resultFlg"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiFd !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Fuel Dilution by GC (%)
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Fuel Dilution</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiFd"
                              field="result"
                              title="Fuel Dilution by GC (%)"
                              subtitle="Fuel Dilution"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiFd"
                            field="result"
                            flag="resultFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiFd"
                              field="result"
                              flag="resultFlg"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiPh !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            pH (pH)
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>pH</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiPh"
                              field="result"
                              title="pH (pH)"
                              subtitle="pH"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiPh"
                            field="result"
                            flag="resultFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiPh"
                              field="result"
                              flag="resultFlg"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiPhd !== null ? (
                      <>
                        <TableRow>
                          <TableCell colSpan={2}>pH (Dow)</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiPhd"
                              field="result"
                              title="pH (pH)"
                              subtitle="pH (Dow)"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiPhd"
                            field="result"
                            flag="resultFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiPhd"
                              field="result"
                              flag="resultFlg"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiGlycol !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Glycol (Pos/Neg) ASTM D2982
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Glycol</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiGlycol"
                              field="result"
                              title="Glycol (Pos/Neg) ASTM D2982"
                              subtitle="Glycol"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiGlycol"
                            field="result"
                            flag="resultFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiGlycol"
                              field="result"
                              flag="resultFlg"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiFlashpoint !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Flash Point (°C) D92
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Flash Point</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiFlashpoint"
                              field="result"
                              title="Flash Point (°C) D92"
                              subtitle="Flash Point"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiFlashpoint"
                            field="result"
                            flag="resultFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiFlashpoint"
                              field="result"
                              flag="resultFlg"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiRuler !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Ruler (%)
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Amine Peak Area</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiRuler"
                              field="amine"
                              title="Ruler (%)"
                              subtitle="Amine Peak Area"
                              other="Amine Peak Area"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiRuler"
                            field="amine"
                            flag="rulerFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiRuler"
                              field="amine"
                              flag="rulerFlg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Amine % Remaining</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiRuler"
                              field="amine"
                              title="Ruler (%)"
                              subtitle="Amine % Remaining"
                              other="Amine % Remaining"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiRuler"
                            field="amine"
                            flag="rulerFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiRuler"
                              field="amine"
                              flag="rulerFlg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Phenolic Peak Area</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiRuler"
                              field="phenolic"
                              title="Ruler (%)"
                              subtitle="Phenolic Peak Area"
                              other="Phenolic Peak Area"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiRuler"
                            field="phenolic"
                            flag="rulerFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiRuler"
                              field="phenolic"
                              flag="rulerFlg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>
                            Phenolic % Remaining
                          </TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiRuler"
                              field="phenolic"
                              title="Ruler (%)"
                              subtitle="Phenolic % Remaining"
                              other="Phenolic % Remaining"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiRuler"
                            field="phenolic"
                            flag="rulerFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiRuler"
                              field="phenolic"
                              flag="rulerFlg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>zddp Peak Area</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiRuler"
                              field="zddp"
                              title="Ruler (%)"
                              subtitle="zddp Peak Area"
                              other="zddp Peak Area"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiRuler"
                            field="zddp"
                            flag="rulerFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiRuler"
                              field="zddp"
                              flag="rulerFlg"
                            />
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>zddp % Remaining</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiRuler"
                              field="zddp"
                              title="Ruler (%)"
                              subtitle="zddp % Remaining"
                              other="zddp % Remaining"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiRuler"
                            field="zddp"
                            flag="rulerFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiRuler"
                              field="zddp"
                              flag="rulerFlg"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiKf !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Water (%) a-ASTM D6304A b-IWI-133 c-ASTM D6304C
                            d-IWI-134* e-IWI-135*( f-IWI-136* g-Crackle
                            h-IWI-370*
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Water</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiKf"
                              field="result"
                              title="Water (%) a-ASTM D6304A b-IWI-133 c-ASTM D6304C d-IWI-134* e-IWI-135*( f-IWI-136* g-Crackle h-IWI-370*"
                              subtitle="Water"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiKf"
                            field="result"
                            flag="resultFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiKf"
                              field="result"
                              flag="resultFlg"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiAf !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Analytical Ferrography
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={3}>Test Run</TableCell>
                          <TableCell style={{ backgroundColor: '#eee' }}>
                            {refData === null
                              ? ''
                              : refData.isiAf !== null
                              ? 'Yes'
                              : 'No'}
                          </TableCell>
                          {samples.map((x, i) => (
                            <TableCell
                              key={i}
                              style={
                                i == 0
                                  ? {
                                      borderRight: '2px solid black',
                                      borderLeft: '2px solid black',
                                    }
                                  : null
                              }
                            >
                              {x.isiAf !== null ? 'Yes' : 'No'}
                            </TableCell>
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    {data.isiResistivity !== null ? (
                      <>
                        <TableRow>
                          <TableCell
                            colSpan={cols}
                            className={classes.headingRow}
                          >
                            Resistivity ( G.ohm -cm) ASTM D1169
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2}>Resistivty</TableCell>
                          <TableCell>
                            <SparkLine
                              samples={samples}
                              test="isiResistivity"
                              field="result"
                              title="Resistivity ( G.ohm -cm) ASTM D1169"
                              subtitle="Resistivity"
                              other="Resistivity"
                            />
                          </TableCell>
                          <ResultTableCell
                            sample={refData}
                            test="isiResistivity"
                            field="result"
                            flag="resultFlg"
                            isRef="true"
                          />
                          {samples.map((x, i) => (
                            <ResultTableCell
                              key={i}
                              sample={x}
                              test="isiResistivity"
                              field="result"
                              flag="resultFlg"
                            />
                          ))}
                        </TableRow>
                      </>
                    ) : (
                      ''
                    )}
                    <TableRow>
                      <TableCell
                        colSpan={cols}
                        className={classes.machineFooterRow}
                      >
                        DART Component Analysis For Machine:{' '}
                        {data.machine.machineName} Equipment ID:{' '}
                        {data.machine.custEquID}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
            <Box my={2}>
              <div style={{ textAlign: 'center' }}>
                Insight Services, Inc. &bull; 20338 Progress Drive &bull;
                Strongsville, OH 44149 &bull; Phone: 216-251-2510 &bull; Fax:
                216-251-2515
              </div>
              <div style={{ textAlign: 'center' }}>
                Copyright Insight Services, Inc. 2014-{moment().format('YYYY')}
              </div>
            </Box>
          </React.Fragment>
        ) : (
          <>
            <Alert severity="error">Unable to load sample</Alert>
            {navigateHome()}
          </>
        )}
      </Box>
      <Snackbar open={error !== null} onClose={() => setError(null)}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </>
  )
}

DARTData.propTypes = {
  classes: PropTypes.object.isRequired,
  offline: PropTypes.bool,
}

interface TProps {
  classes: any
  location?: any
}

function Page({ classes, location }: TProps) {
  return (
    <FullscreenLayout>
      <DARTData classes={classes} location={location} />
    </FullscreenLayout>
  )
}

Page.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Page)
