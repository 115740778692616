/* eslint-disable no-undef */
/* eslint-disable filenames/match-exported */
import { TSmartFilter, TSmartFilterProps } from './SmartFilterTypes'
import useSmartFilters from './useSmartFilters'

type TProps = {
  children?: (_filterProps: TSmartFilter) => JSX.Element
} & TSmartFilterProps

const WithSmartFilters = (props: TProps) => {
  const smartFilters: TSmartFilter = useSmartFilters(props)

  return props.children(smartFilters)
}

export default WithSmartFilters
