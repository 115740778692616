import { PageBody, PageCard } from '../Pages/PageContainers'
import * as PageStyles from '../page-styles.module.css'
import { TWarhammerSampleWithoutIdentifier } from '../types-and-enums'
import { formatDateString } from '../formatters'
import { Button } from '@material-ui/core'
import { Dispatch, ReactText, SetStateAction, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Sample } from 'types/api'
import ConditionElement from 'components/ConditionElement'
import { machineConditions } from 'services/lookup-service'
import {
  getFormattedSampleRecommendations,
  getSampleProblems,
} from 'utils/api-utilities'
import { useIsLoggedIn } from 'hooks/useIsLoggedIn'

type TProps = {
  bottleData: TWarhammerSampleWithoutIdentifier
  sampleData: Sample
}

export const BottleComplete = (props: TProps) => {
  const [sampleDownloading, setSampleDownloading] = useState(false)
  const isLoggedIn = useIsLoggedIn()
  return (
    <PageCard>
      <div
        className={`${PageStyles.EntryScreen} ${PageStyles.WithBottleButton} ${
          isLoggedIn.loggedIn ? PageStyles.LoggedIn : ''
        }`}
      >
        <div>
          <div>
            <header className={PageStyles.MainTitle}>Analysis Complete</header>
            <sub className={PageStyles.SubTitle}>
              Your results are available!
            </sub>
          </div>
          <div className={PageStyles.SectionSeparator}>
            <div>
              <section>
                <h6 className={PageStyles.DetailSectionHeader}>
                  Result Summary
                </h6>
                <ul
                  className={`${PageStyles.Details} ${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer} ${PageStyles.Wrapper}`}
                >
                  <li className={`${PageStyles.Wrappable}`}>
                    <span className={PageStyles.PropertyLabel}>Lab Number</span>
                    <span className={PageStyles.Property}>
                      {props.sampleData?.labID}
                    </span>
                  </li>
                  <li className={`${PageStyles.Wrappable}`}>
                    <span className={PageStyles.PropertyLabel}>
                      Sample Date
                    </span>
                    <span className={PageStyles.Property}>
                      {formatDateString(props.sampleData?.sampleDate)}
                    </span>
                  </li>
                  <li className={`${PageStyles.Wrappable}`}>
                    <span className={PageStyles.PropertyLabel}>
                      Lubricant Condition
                    </span>
                    <div
                      className={`${PageStyles.PropertyBox} ${PageStyles.ConditionBox}`}
                    >
                      <ConditionElement
                        condition={props.sampleData?.lubeCondition}
                        issueContributors={getSampleProblems(props.sampleData)}
                      />
                    </div>
                    {!!props.sampleData && (
                      <span className={PageStyles.Property}>
                        {
                          machineConditions[props.sampleData?.lubeCondition - 1]
                            ?.text
                        }
                      </span>
                    )}
                  </li>
                  <li className={`${PageStyles.Wrappable}`}>
                    <span className={PageStyles.PropertyLabel}>
                      Machine Condition
                    </span>
                    <div>
                      <div
                        className={`${PageStyles.PropertyBox} ${PageStyles.ConditionBox}`}
                      >
                        <ConditionElement
                          condition={props.sampleData?.machineCondition}
                          issueContributors={getSampleProblems(
                            props.sampleData
                          )}
                        />
                      </div>
                      {!!props.sampleData && (
                        <span className={PageStyles.Property}>
                          {
                            machineConditions[
                              props.sampleData?.machineCondition - 1
                            ]?.text
                          }
                        </span>
                      )}
                    </div>
                  </li>
                  <li className={`${PageStyles.Wrappable}`}>
                    <span className={PageStyles.PropertyLabel}>
                      Report Date
                    </span>
                    <span className={PageStyles.Property}>
                      {formatDateString(props.sampleData?.reportDate)}
                    </span>
                  </li>
                  <li className={`${PageStyles.FullWidth}`}>
                    <span className={PageStyles.PropertyLabel}>
                      Laboratory Comments
                    </span>
                    <p className={`${PageStyles.Comments}`}>
                      {getFormattedSampleRecommendations(props.sampleData).map(
                        recommendation => (
                          <span
                            key={recommendation}
                            className={`${PageStyles.Property}`}
                          >
                            {recommendation}
                          </span>
                        )
                      )}
                    </p>
                  </li>
                </ul>
                <Button
                  disabled={
                    !props.bottleData ||
                    !props.bottleData.customerID ||
                    !props.bottleData.labID
                  }
                  onClick={() =>
                    handleDownloadClick(props.bottleData, setSampleDownloading)
                  }
                  data-accept
                  variant="contained"
                  color="secondary"
                  fullWidth
                >
                  {sampleDownloading ? 'Downloading...' : 'Download Report'}
                </Button>
              </section>
            </div>
            <div>
              <section>
                <h6 className={PageStyles.DetailSectionHeader}>
                  Sample Details
                </h6>
                <ul
                  className={`${PageStyles.Details} ${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer} ${PageStyles.Wrapper}`}
                >
                  <li className={`${PageStyles.Wrappable}`}>
                    <span className={PageStyles.PropertyLabel}>Customer</span>
                    <span className={PageStyles.Property}>
                      {props.bottleData?.customerID}
                      {' - '}
                      {props.bottleData?.customerName}
                    </span>
                  </li>

                  <li className={`${PageStyles.Wrappable}`}>
                    <span className={PageStyles.PropertyLabel}>Plant</span>
                    <span className={PageStyles.Property}>
                      {props.bottleData?.plantNumber}
                      {' - '}
                      {props.bottleData?.plantName}
                    </span>
                  </li>

                  <li className={`${PageStyles.Wrappable}`}>
                    <span className={PageStyles.PropertyLabel}>Machine</span>
                    <span className={PageStyles.Property}>
                      {props.bottleData?.pointID}
                      {' - '}
                      {props.bottleData?.machineName}
                    </span>
                  </li>

                  <li className={`${PageStyles.Wrappable}`}>
                    <span className={PageStyles.PropertyLabel}>
                      Lubricant Hours
                    </span>
                    <span className={PageStyles.Property}>
                      {props.bottleData?.lubeHours}
                    </span>
                  </li>

                  <li className={`${PageStyles.Wrappable}`}>
                    <span className={PageStyles.PropertyLabel}>
                      Machine Hours
                    </span>
                    <span className={PageStyles.Property}>
                      {props.bottleData?.machineHours}
                    </span>
                  </li>

                  <li className={`${PageStyles.Wrappable}`}>
                    <span className={PageStyles.PropertyLabel}>Sampled On</span>
                    <span className={PageStyles.Property}>
                      {formatDateString(props.bottleData?.sampledAt)}
                    </span>
                  </li>

                  <li className={`${PageStyles.Wrappable}`}>
                    <span className={PageStyles.PropertyLabel}>
                      Oil Changed At
                    </span>
                    <span className={PageStyles.Property}>
                      {formatDateString(props.bottleData?.oilChangedAt)}
                    </span>
                  </li>

                  <li className={`${PageStyles.FullWidth}`}>
                    <span className={PageStyles.PropertyLabel}>Comments</span>
                    <span
                      className={`${PageStyles.Property} ${PageStyles.Comments}`}
                    >
                      {props.bottleData?.comments}
                    </span>
                  </li>
                </ul>
              </section>
            </div>
          </div>
        </div>
      </div>
    </PageCard>
  )
}

const handleDownloadClick = async (
  data: TWarhammerSampleWithoutIdentifier,
  setDownloadingState: Dispatch<SetStateAction<boolean>>
) => {
  if (!data || !data.customerID || !data.labID) {
    setDownloadingState(false)
    return
  }
  setDownloadingState(true)

  const url = `${process.env.REACT_APP_API_URL}/datafiles/${data.customerID}/${data.labID}.pdf`
  const infoToast = toast.info(<span>Downloading Report - {data.labID}</span>, {
    containerId: 'sample-registration',
    autoClose: 1500,
  })

  let headers = new Headers()
  headers.append('Accept', 'application/pdf')

  return await fetch(url, { headers })
    .then(response => {
      if (!response.ok) {
        if (response.statusText) {
          return Promise.reject(
            new Error(`${response.status}: ${response.statusText}`)
          )
        }
        return Promise.reject()
      }

      return response
        .blob()
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.download = `${data.labID}.pdf`
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .then(() => {
          toast.dismiss(infoToast)
          toast.success(
            <span>Downloaded report for sample {data.labID}!</span>,
            {
              containerId: 'sample-registration',
              autoClose: 1500,
            }
          )
        })
    })
    .catch(err => handleDownloadError(err, infoToast))
    .finally(() => {
      setDownloadingState(false)
    })
}

const handleDownloadError = (
  err: Error | undefined,
  toastToDismiss: ReactText
) => {
  if (toastToDismiss) toast.dismiss(toastToDismiss)

  if (!err) {
    toast.error(<span>Download failed :(.</span>, {
      containerId: 'sample-registration',
      autoClose: 1500,
    })
    return
  }
  const error = err as Error
  toast.error(<span>Download failed: {error.message}</span>, {
    containerId: 'sample-registration',
    autoClose: 1500,
  })
}
