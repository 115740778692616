import { createContext, useContext, useState } from 'react'
import { BooleanFormValue } from 'utils/FormUtilities/FormValue'

type TFreeFormBottleInputState = {
  bottleIdentifier: BooleanFormValue<string>
}

type TFixedInputState = {
  pointID: number
  plantID: number
  validEncryptedIdentifier: boolean
}

type TBottleRegistrationContext = {
  clear: () => void
  state: TFreeFormBottleInputState & TFixedInputState
  updateFormStateValue: <
    TKey extends keyof TFreeFormBottleInputState,
    TValue extends TFreeFormBottleInputState[TKey]['Value']
  >(
    key: TKey,
    value: TValue
  ) => void
  updateFixedState: <
    TKey extends keyof TFixedInputState,
    TValue extends TFixedInputState[TKey]
  >(
    key: TKey,
    value: TValue
  ) => void
  validateFormValues: () => boolean
}

const maxTextLength = 1000
const defaultFormState: Readonly<TFreeFormBottleInputState & TFixedInputState> =
  {
    bottleIdentifier: new BooleanFormValue(
      '',
      str => str.length < maxTextLength,
      'A bottle identifier must be provided and the bottle identifier provided cannot be longer than 1,000 characters.'
    ),
    plantID: null,
    pointID: null,
    validEncryptedIdentifier: false,
  }

export const BottleRegistrationContext =
  createContext<TBottleRegistrationContext>({} as TBottleRegistrationContext)

export const useBottleRegistrationContext = () => {
  return useContext(BottleRegistrationContext)
}

export const BottleRegistrationContextProvider: React.FC = props => {
  const [bottleInputState, setBottleInputState] = useState<
    TFreeFormBottleInputState & TFixedInputState
  >({ ...defaultFormState })

  function clear() {
    setBottleInputState({ ...defaultFormState })
  }
  /**Update values used across forms. These values ARE subject to form validations and are of the FormValue class. */
  function updateFormStateValue<
    TKey extends keyof TFreeFormBottleInputState,
    TValue extends TFreeFormBottleInputState[TKey]['Value']
  >(key: TKey, value: TValue) {
    const stateValue = bottleInputState[key]
    stateValue.Value = value

    setBottleInputState(prev => ({ ...prev, [key]: stateValue }))
  }

  /**Update values used across forms. These values are not subject to form validations. */
  function updateFixedStateValue<
    TKey extends keyof TFixedInputState,
    TValue extends TFixedInputState[TKey]
  >(key: TKey, value: TValue) {
    setBottleInputState(prev => ({ ...prev, [key]: value }))
  }

  const validateFormValues = () => {
    for (let key in bottleInputState) {
      const value = bottleInputState[key]

      if (value instanceof BooleanFormValue) {
        if (!value.isValid()) {
          return false
        }
      }
    }

    return true
  }

  return (
    <BottleRegistrationContext.Provider
      value={{
        clear,
        state: bottleInputState,
        updateFormStateValue,
        updateFixedState: updateFixedStateValue,
        validateFormValues,
      }}
    >
      {props.children}
    </BottleRegistrationContext.Provider>
  )
}
