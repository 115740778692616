import React from 'react'
import { Grid } from '@material-ui/core'

import MobileCardContent from 'components/MobileCardContent'
import MobileCardItem from 'components/MobileCardItem'

import {
  RenderRoute,
  RenderTestGroupCodesAsStringCommas,
  RenderTestGroupNamesAsStringCommas,
} from '../KendoUIGridRenders'

interface OwnProps {
  machine: any
  readOnly: boolean
  showDivisions: boolean
  onSelectedChange?: (_machine, _checked) => void
  handleCheckboxChecked?: (hashtagPointID: string, event: React.ChangeEvent<HTMLInputElement>) => void
  index: number
}

const MobileCard: React.FC<OwnProps> = React.forwardRef(
  (
    { machine, showDivisions, readOnly, handleCheckboxChecked, index }: OwnProps,
    ref: React.MutableRefObject<HTMLInputElement[]>
  ) => {
    const oldChecked = !!ref.current[index]?.checked

    return (
      <MobileCardContent title={machine.plantName}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12} sm={12}>
            <Grid container alignItems="center">
              Selected
              <input
                type="checkbox"
                data-indeterminate="false"
                key={machine.pointID}
                ref={el => {
                  if (el) {
                    el.id = machine.pointID
                    el.checked = oldChecked
                    ref.current[index] = el
                  }
                }}
                onChange={event => {
                  handleCheckboxChecked(machine.pointID, event)
                }}
                style={{
                  marginLeft: 10,
                }}
              />
            </Grid>
          </Grid>

          {showDivisions && (
            <MobileCardItem title="Division Name">
              {machine.plant.customer.division.name}
            </MobileCardItem>
          )}
          <MobileCardItem title="Customer Name">
            {machine.plant.customer.custName}
          </MobileCardItem>
          <MobileCardItem title="Plant Name">
            {machine.plant.plantName}
          </MobileCardItem>
          <MobileCardItem title="Machine No">{machine.machNo}</MobileCardItem>
          <MobileCardItem title="Machine Name">
            {machine.machineName}
          </MobileCardItem>
          <MobileCardItem title="Equipment ID">
            {machine.custEquID}
          </MobileCardItem>
          <MobileCardItem title="Route">
            <RenderRoute dataItem={machine} field="route" />
          </MobileCardItem>
          <MobileCardItem title="Test Packages">
            {
              <RenderTestGroupNamesAsStringCommas
                dataItem={machine}
                field="machSchedules"
              />
            }
          </MobileCardItem>
          <MobileCardItem title="Tests">
            <RenderTestGroupCodesAsStringCommas
              dataItem={machine}
              field="machSchedules"
            />
          </MobileCardItem>
        </Grid>
      </MobileCardContent>
    )
  }
)

export default MobileCard
