import React, { useCallback, useEffect, useState } from 'react'
// eslint-disable-next-line camelcase
import { Box, Grid, Typography } from '@material-ui/core'
import { SortDescriptor } from '@progress/kendo-data-query'
import { GridSortChangeEvent } from '@progress/kendo-react-grid'
import moment from 'moment'
import AppLayout from 'components/AppLayout'
import { ExportButton } from 'components/buttons'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import SEO from 'components/SEO'
import { LastSamplesTable, tableFormatter } from 'components/tables/LastSamples'
import APIService from 'services/api-service'
import { exportData } from 'services/export-service'
import { getSelectedCustomerIDs } from 'services/selection-service'
import { useProfile } from 'Contexts/ProfileContext'
import FilterAccordion, {
  TAccordionFilterValues,
} from 'components/FilterAccordion'
import { mapToPageParameters } from 'components/FilterAccordion/Utilities'
import {
  ANDOR2,
  DateFilterSet,
  LubricantFilterSet,
  MachineFilterSet,
  OptionsFilterSet,
  OrganizationalFilterSet,
} from 'components/FilterAccordion/types'
import { useLocation } from 'react-router-dom'
import { CancellablePromise } from 'utils/CancellablePromise'

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

const initialStartDate = moment()
  .subtract(3, 'months')
  .startOf('month')
  .startOf('day')
  .toDate()
const initialEndDate = moment().endOf('month').endOf('day').toDate()
let isInitialLoad = true
const defaults = {
  count: 0,
  page: 1,
  pageSize: 10,
  orderConfig: [{ field: 'sampleDate', dir: 'desc' }] as SortDescriptor[],
  exporting: false,
  startDate: initialStartDate,
  endDate: initialEndDate,
  selectedDivisionIDs: [],
  selectedCustomerIDs: [],
  selectedPlants: [],
  selectedCustEquIDs: [],
  selectedMachineNames: [],
  selectedRoutes: [],
  selectedMachineTypes: [],
  selectedLubeTypes: [],
  selectedMachineConditions: [],
  selectedLubeConditions: [],
  selectedReportTypes: [],
  hideReviewed: false,
  exportFormat: '',
  searching: false,
  machineLubeConditionANDOR: 'AND' as ANDOR2,
}

const LastSampleReport = ({ offline, onError, props }: any) => {
  const profileContext = useProfile()
  const data = useLocation()

  const filtersFromParent =
    data.state?.filterOptionsForLastHundredSamples.customers != null &&
    data.state?.filterOptionsForLastHundredSamples.divisions != null &&
    data.state?.filterOptionsForLastHundredSamples.plants != null

  const [state, setState] = useState({
    ...defaults,
    samplesLoaded: false,
    machineTypeOptions: [],
    count: 0,
    samples: [],
    searching: false as boolean,
    loadedDefaults: false,
  })

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  useEffect(() => {
    filtersFromParent
      ? setState(prev => ({
          ...prev,
          selectedDivisionIDs:
            data.state?.filterOptionsForLastHundredSamples.divisions?.map(
              x => x.iD
            ) ?? [],
          selectedCustomerIDs:
            data.state?.filterOptionsForLastHundredSamples.customers?.map(
              x => x.custID
            ) ?? [],
          selectedPlants:
            data.state?.filterOptionsForLastHundredSamples.plants?.map(
              x => x.plantID
            ) ?? [],
          loadedDefaults: true,
        }))
      : setState(prev => ({
          ...prev,
          selectedDivisionIDs:
            profileContext.dependentData.filters.initialFilterState.divisions.map(
              x => x.iD
            ),
          selectedCustomerIDs:
            profileContext.dependentData.filters.initialFilterState.customers.map(
              x => x.custID
            ),
          loadedDefaults: true,
        }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext.dependentData.filters.minimumFilterOptionsLoaded])

  const fetchSamples = useCallback(
    async variables => {
      setState(prev => ({
        ...prev,
        samplesLoaded: false,
      }))
      let promise
      try {
        promise = APIService.getLastSamples(variables)

        ongoingCancellablePromises.push(promise)
        const data = await promise

        if (data.pagedSamples !== null) {
          onError(null)
          setState(prev => ({
            ...prev,
            samples: data.pagedSamples.items,
            count: data.pagedSamples.count,
            samplesLoaded: true,
            searching: false,
          }))
        }
      } catch (ex) {
        onError(ex)
      } finally {
        ongoingCancellablePromises.filter(p => p != promise)
      }
    },
    [onError]
  )

  const performSearch = useCallback(() => {
    fetchSamples({
      machineLubeConditionANDOR: state.machineLubeConditionANDOR,
      page: state.page,
      pageSize: state.pageSize,
      orderList: state.orderConfig?.map(x => x.dir) ?? [],
      orderByList: state.orderConfig?.map(x => x.field) ?? [],
      startDate: state.startDate,
      endDate: state.endDate,
      customerIDs: state.selectedCustomerIDs,
      plantIDs: state.selectedPlants,
      machineNames: state.selectedMachineNames,
      routeIDs: state.selectedRoutes,
      machineTypes: state.selectedMachineTypes,
      lubeTypes: state.selectedLubeTypes,
      machineConditions: state.selectedMachineConditions,
      lubeConditions: state.selectedLubeConditions,
      custEquIDs: state.selectedCustEquIDs,
      reviewed: state.hideReviewed ? false : null,
    })
  }, [
    fetchSamples,
    state.endDate,
    state.hideReviewed,
    state.orderConfig,
    state.page,
    state.pageSize,
    state.selectedCustEquIDs,
    state.selectedCustomerIDs,
    state.selectedDivisionIDs,
    state.selectedLubeConditions,
    state.selectedLubeTypes,
    state.selectedMachineConditions,
    state.selectedMachineNames,
    state.selectedMachineTypes,
    state.selectedPlants,
    state.selectedRoutes,
    state.startDate,
  ])

  useEffect(() => {
    return () => {
      isInitialLoad = true
    }
  }, [])

  useEffect(() => {
    if (!state.samplesLoaded && !state.searching && state.loadedDefaults) {
      performSearch()
    }
    if (state.searching) {
      performSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.samplesLoaded, state.loadedDefaults, state.searching])

  const handleResetClick = () => {
    setState(prev => ({
      ...prev,
      ...defaults,
      count: 0,
      selectedCustomerIDs:
        profileContext.dependentData.filters.initialFilterState.customers.map(
          c => c.custID
        ),
      selectedDivisionIDs:
        profileContext.dependentData.filters.initialFilterState.divisions.map(
          d => d.iD
        ),
      searching: true,
    }))
  }

  const handleExportFormatChange = async (event, format) => {
    let promise
    try {
      setState(prev => ({ ...prev, exporting: true }))
      promise = APIService.getLastSamples({
        page: 1,
        pageSize: 999999,
        orderList: state.orderConfig?.map(x => x.dir) ?? [],
        orderByList: state.orderConfig?.map(x => x.field) ?? [],
        startDate: state.startDate,
        endDate: state.endDate,
        customerIDs: getSelectedCustomerIDs(
          profileContext.profile.customers,
          profileContext.profile.divisions,
          state.selectedDivisionIDs,
          state.selectedCustomerIDs
        ),
        plantIDs: state.selectedPlants,
        machineNames: state.selectedMachineNames,
        routeIDs: state.selectedRoutes,
        machineTypes: state.selectedMachineTypes,
        lubeTypes: state.selectedLubeTypes,
        machineConditions: state.selectedMachineConditions,
        lubeConditions: state.selectedLubeConditions,
        custEquIDs: state.selectedCustEquIDs,
        reviewed: state.hideReviewed ? false : null,
      })

      ongoingCancellablePromises.push(promise)
      const data = await promise

      const formatted = tableFormatter(
        data.pagedSamples.items,
        state.machineTypeOptions,
        profileContext.dependentData.userDetails.isDivisionUser
      )

      exportData('last-sample', formatted, format.key)
    } catch (ex) {
      onError(ex)
    } finally {
      ongoingCancellablePromises.filter(p => p != promise)
      setState(prev => ({ ...prev, exporting: false }))
    }
  }

  const handlePageChange = event => {
    setState(prev => ({
      ...prev,
      page: event.page.skip / state.pageSize + 1,
      pageSize: event.page.take,
      samplesLoaded: false,
      searching: true,
    }))
  }

  const handleSortChange = (event: GridSortChangeEvent) => {
    const isFieldRemoved = state.orderConfig.length > event.sort.length
    let isLastFieldRemoved = false

    if (isFieldRemoved) {
      const fieldRemoved = state.orderConfig.filter(
        x => !event.sort.includes(x)
      )[0]

      isLastFieldRemoved =
        state.orderConfig.indexOf(fieldRemoved) === state.orderConfig.length - 1
    }

    setState(prev => ({
      ...prev,
      orderConfig: [...event.sort],
      samplesLoaded: !(event.sort.length > 0 && !isLastFieldRemoved),
      searching: event.sort.length > 0 && !isLastFieldRemoved,
    }))
  }

  const handlePageChangeMobile = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage
  ) => {
    setState(prev => ({ ...prev, page: newPage + 1, samplesLoaded: false }))
  }

  const handlePageSizeChangeMobile = event => {
    setState(prev => ({
      ...prev,
      page: 1,
      pageSize: parseInt(event.target.value, 10),
      samplesLoaded: false,
    }))
  }

  const handleFilterSubmission = (data: TAccordionFilterValues) => {
    const mappedParameters = mapToPageParameters(data)

    setState(prev => ({
      ...prev,
      ...mappedParameters,
      page: 1,
      searching: true,
      samplesLoaded: false,
    }))
  }

  if (
    isInitialLoad &&
    ((profileContext.profile.divisions?.length > 0 &&
      state.selectedDivisionIDs.length === 0) ||
      (profileContext.profile.customers?.length > 0 &&
        state.selectedCustomerIDs.length === 0))
  ) {
    isInitialLoad = false
    return null
  }

  return (
    <>
      <SEO title="Last Sample Report" />
      <Box mb={2}>
        <Typography variant="h1">Last Sample Report</Typography>
      </Box>
      {offline ? <OfflineBanner /> : <></>}
      <FilterAccordion
        pageName="Last Sample Report"
        defaultValues={
          filtersFromParent
            ? data.state?.filterOptionsForLastHundredSamples
            : profileContext.dependentData.filters.initialFilterState
        }
        onSubmit={handleFilterSubmission}
        onReset={handleResetClick}
        filters={{
          options: [
            OptionsFilterSet.Prefiltering,
            OptionsFilterSet.HideReviewed,
          ],
          date: [DateFilterSet.FromDate, DateFilterSet.ToDate],
          organization: [OrganizationalFilterSet.All],
          machine: [
            MachineFilterSet.Name,
            MachineFilterSet.Type,
            MachineFilterSet.Conditions,
            MachineFilterSet.EquipmentIDs,
          ],
          lubricant: [LubricantFilterSet.Type, LubricantFilterSet.Conditions],
          miscellaneous: [],
        }}
      />
      <form>
        <Grid
          container
          direction="row"
          xs={12}
          spacing={1}
          justify="flex-end"
          style={{ marginTop: '15px' }}
        >
          <Grid container item direction="row" spacing={1} justify="flex-end">
            <Grid item>
              <ExportButton
                onChange={handleExportFormatChange}
                isExporting={state.exporting}
                disabled={state.samples.length < 1}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>

      <Box mt={4}>
        {!state.samplesLoaded ? (
          <Loader />
        ) : (
          <LastSamplesTable
            samples={state.samples}
            offline={offline}
            orderConfig={state.orderConfig}
            page={state.page}
            count={state.count}
            pageSize={state.pageSize}
            onPageChange={handlePageChange}
            onPageChangeMobile={handlePageChangeMobile}
            onPageSizeChangeMobile={handlePageSizeChangeMobile}
            onSortChange={handleSortChange}
            machineTypeOptions={state.machineTypeOptions}
          />
        )}
      </Box>
    </>
  )
}

export default function Page() {
  return (
    <AppLayout tab="reports">
      <LastSampleReport />
    </AppLayout>
  )
}
