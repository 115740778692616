import { Product, ProductPricing } from 'types/api'
import { emptyPricing, freePricing } from './constants'

export const productHasPrice = (product: Product) => {
  if (!product || !product.productPricing) return false
  return product.productPricing.length != 0
}

export const productHasFreePrice = (product: Product) => {
  return productPricingHasFreePrice(product.productPricing)
}

export const productPricingHasFreePrice = (pricing: ProductPricing[]) => {
  const validPrices = pricing.filter(p => p)
  if (!validPrices || validPrices.length === 0) return false

  const hasFreePricing =
    !!validPrices.find(p => p.minimumQuantity === 1 && p.unitPrice === 0) ||
    !validPrices.some(p => p.minimumQuantity === 1)

  return hasFreePricing
}

export type TPricedQuantity = {
  /**the unit price of the item at this quantity */
  price: number
  /**The number of items allowed to be at this price */
  quantity: number
}
export type TFreePriceBreakdown = {
  freePricing: TPricedQuantity
  paidPricing: TPricedQuantity
}

export const breakDownPricing = (
  pricing: ProductPricing[],
  quantity: number
): TFreePriceBreakdown => {
  const nextHighestAmount = getLowestNonFreePricing(pricing)
  const applicablePricing = getApplicableProductPricing(pricing, quantity)
  const hasPaidPricing = !!nextHighestAmount

  let freeQuantity = quantity
  let paidQuantity = 0
  if (hasPaidPricing) {
    const numberOfFreeAvailable = nextHighestAmount.minimumQuantity - 1
    if (numberOfFreeAvailable >= quantity) {
      freeQuantity = quantity
      paidQuantity = 0
    } else {
      freeQuantity = numberOfFreeAvailable
      paidQuantity = quantity - numberOfFreeAvailable
    }
  }

  return {
    freePricing: {
      quantity: freeQuantity,
      price: 0,
    },
    paidPricing: {
      quantity: paidQuantity,
      price: applicablePricing?.unitPrice,
    },
  }
}

export const getLowestNonFreePricing = (pricing: ProductPricing[]) => {
  const validPrices = pricing.filter(p => p)
  if (!validPrices || validPrices.length === 0) return null

  const sortedPrices = [...validPrices].sort(
    (a, b) => a.minimumQuantity - b.minimumQuantity
  )
  if (!productPricingHasFreePrice(pricing)) return sortedPrices[0]

  let lowestPricing = validPrices.find(p => p && p.minimumQuantity !== 1)
  for (const p of validPrices) {
    if (p.minimumQuantity === 1) continue
    if (!lowestPricing || p.minimumQuantity < lowestPricing.minimumQuantity)
      lowestPricing = p
  }

  if (!lowestPricing) return null
  return lowestPricing
}

export const getApplicableProductPricing = (
  productPricing: ProductPricing[],
  quantity: number
) => {
  const hasPrices = productPricing?.length > 0
  if (!hasPrices) return null

  const productPricingMinimumQuantityDesc = [...productPricing].sort(
    (a, b) => b.minimumQuantity - a.minimumQuantity
  )

  for (let price of productPricingMinimumQuantityDesc) {
    if (price.minimumQuantity <= quantity) return price
  }

  return null
}
export const getProductPrice = (product: Product, quantity: number) => {
  const hasPrices = product?.productPricing?.length > 0
  if (!hasPrices) return 0

  const productPricingMinimumQuantityDesc = [...product.productPricing].sort(
    (a, b) => b.minimumQuantity - a.minimumQuantity
  )

  if (
    productPricingMinimumQuantityDesc &&
    productPricingMinimumQuantityDesc.at(-1).minimumQuantity !== 1
  ) {
    productPricingMinimumQuantityDesc.push(freePricing)
  }

  for (let price of productPricingMinimumQuantityDesc) {
    if (price.minimumQuantity <= quantity) {
      const lowerQuantityWithLowerPricesAsc = productPricingMinimumQuantityDesc
        .filter(
          p =>
            p.minimumQuantity < price.minimumQuantity &&
            p.unitPrice < price.unitPrice
        )
        .sort((a, b) => a.minimumQuantity - b.minimumQuantity)
      if (lowerQuantityWithLowerPricesAsc.length === 0) {
        return price.unitPrice * quantity
      }

      const productPricingMinimumQuantityAsc = [...product.productPricing].sort(
        (a, b) => a.minimumQuantity - b.minimumQuantity
      )

      let quantityRemaining = quantity
      let total = 0
      for (const lowerQuantityLowerPriceEntry of lowerQuantityWithLowerPricesAsc) {
        const maxQuantityExclusive =
          productPricingMinimumQuantityAsc[
            productPricingMinimumQuantityAsc.indexOf(
              lowerQuantityLowerPriceEntry
            ) + 1
          ].minimumQuantity
        let pricingQuantityRemaining = maxQuantityExclusive - 1

        while (pricingQuantityRemaining) {
          total += lowerQuantityLowerPriceEntry.unitPrice
          pricingQuantityRemaining--
          quantityRemaining--
        }
      }

      total += quantityRemaining * price.unitPrice
      return total
    }
  }

  return null
}
