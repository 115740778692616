import React from 'react'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

interface OwnProps {
  errorMessage?: string
}

const FileNotFoundError: React.FC<OwnProps> = (props: OwnProps) => {
  return (
    <>
      <HighlightOffIcon
        style={{ color: 'rgb(225, 25, 25)', fontSize: '350px' }}
      />
      <h4>{props.errorMessage || 'File Not Found'}</h4>
    </>
  )
}

export default FileNotFoundError
