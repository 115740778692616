import { useState, useRef, useLayoutEffect, useEffect } from 'react'
import * as PageStyles from '../../../page-styles.module.css'
import { Button } from '@material-ui/core'
import {
  MachineRegistrationPageState,
  readOnlySignedInErrorHeaderMap,
} from 'pages/sample/registration/types-and-enums'
import { PageCard } from 'pages/sample/registration/Pages/PageContainers'
import {
  CustomerResponseDTO,
  GetMachinesResponse,
  GetPlantsResponseDTO,
  MachinesResponseDTO,
  PatchMachinesDTO,
  PostMachinesDTO,
} from 'types/api'
import { useError, useLoading, usePromise } from 'hooks/usePromise'
import APIService from 'services/api-service'

import {
  ProgressStepper,
  TProgressMarker,
} from 'components/ProgressStepper/ProgressStepper'
import {
  LoadingStateWithoutReset,
  TMachinePostInformation,
  TMachineTestingInformation,
} from './types'
import { FetchError } from 'utils/Errors/FetchError'
import { useIsLoggedIn } from 'hooks/useIsLoggedIn'
import { LoadingCardModule } from 'pages/sample/registration/CardModules/LoadingCardModule'
import { CancelButton } from 'pages/sample/registration/CardModules/CancelButton/CancelButton'
import { MachineInformation } from './Pages/MachineInformation'
import { AboutMachineEdit } from './Pages/AboutMachineEdit'
import { AboutMachineCreation } from './Pages/AboutMachineCreation'
import { MachineSamplingInformation } from './Pages/MachineSamplingInformation'
import { MachineTestingInformation } from './Pages/MachineTestingInformation'
import useWebCache from 'hooks/useWebCache'
import { useErrorPage } from '../useErrorPage'
import ReadOnlyUserForbiddenHeader from 'pages/sample/registration/CardModules/CardModuleHeaders/ReadOnlyForbiddenHeader'
import { useProfile } from 'Contexts/ProfileContext'
import { useIsBoundByWidths } from 'hooks/useIsBoundByWidths'
import { mobileWidthDefinitonMap } from 'pages/sample/registration/Pages/constants'
import { useBottleRegistrationContext } from 'pages/sample/registration/registrationContext'
import { useIsReadOnlyError } from 'pages/sample/hooks/useIsReadOnlyError'

type TProps = {
  existingMachines: GetMachinesResponse
  customerData: CustomerResponseDTO
  plantData: GetPlantsResponseDTO
  plantMachines: GetMachinesResponse
  machineData?: MachinesResponseDTO
  encryptedBottleIdentifier: string
  cancel: () => void
  submit: (pointID: number) => void
}

type TPageState = {
  page: MachineRegistrationPageState
} & TMachinePostInformation &
  TMachineTestingInformation

const CACHE_NAME = 'machine-registration-cache'

const defaultPageState: Readonly<TPageState> = {
  page: MachineRegistrationPageState.About,
  criticalityRating: null,
  equipmentID: null,
  fluidID: null,
  model: null,
  name: null,
  sampleSourceRating: null,
  fluidManufacturerCode: null,
  machineManufacturerShortCode: null,
  machineTypeShortCode: null,
  notes: null,
  portTypeID: null,
  samplingInstructions: null,
  testSchedules: null,
  dutyCycle: null,
  routeID: null,
  location: null,
}

export const MachineRegistration = (props: TProps) => {
  const [state, setPageState] = useState<TPageState>({
    ...defaultPageState,
  })

  const header = useRef<HTMLDivElement>()
  const [remainderHeight, setremainderHeight] = useState(0)
  useLayoutEffect(() => {
    setremainderHeight(header.current?.offsetHeight ?? 0)
  }, [])

  const { state: cacheRequest, addToCache } = useWebCache(CACHE_NAME)
  const isMobile = useIsBoundByWidths(mobileWidthDefinitonMap)['mobile']
  const isTabView = useIsBoundByWidths(mobileWidthDefinitonMap)['tabView']

  const remainderHeightCalculationMobile = `calc(100% - ${remainderHeight}px)`
  const remainderHeightCalculation = `calc(100vh - ${166}px)`
  const remainderHeightCalculationLoggedIn = `calc(100vh - (${remainderHeight}px + ${
    isTabView ? 182 : 109
  }px))`

  const stateProps = { ...state, ...props }

  const isLoggedIn = useIsLoggedIn()
  const isReadonlyError = useIsReadOnlyError()
  const machinePostRequest = useMachinePost(stateProps)
  const machinePatchRequest = useMachinePatch(stateProps)
  const machineDataRequest = useExistingMachineData({
    ...stateProps,
    shouldIssue: !isReadonlyError.loading && !isReadonlyError.data,
  })

  const queries = [
    machinePostRequest,
    machinePatchRequest,
    cacheRequest,
    machineDataRequest,
    isReadonlyError,
  ]
  const loading = useLoading(queries)
  const error = useError(queries)
  const errorPage = useErrorPage(error, readOnlySignedInErrorHeaderMap)

  function moveToPage(
    page: MachineRegistrationPageState,
    newData: Partial<TPageState> = {},
    clearError: boolean = false
  ) {
    if (clearError) {
      queries.forEach(q => {
        q.reset()
      })
    }
    const spreadable = newData ?? {}
    setPageState(prev => ({ ...prev, ...spreadable, page }))
  }

  let page = <LoadingCardModule showHeader={false} />

  if (!!error) {
    const cancelButton = (
      <div className={PageStyles.ButtonContainer}>
        <Button
          data-cancel
          variant="contained"
          color="primary"
          onClick={props.cancel}
          fullWidth
          className={`${PageStyles.Button} `}
        >
          Cancel
        </Button>
        {state.page === MachineRegistrationPageState.Complete && (
          <Button
            data-cancel
            variant="contained"
            color="secondary"
            onClick={() =>
              moveToPage(
                MachineRegistrationPageState.MachineTesting,
                null,
                true
              )
            }
            fullWidth
            className={`${PageStyles.Button} `}
          >
            Try Again
          </Button>
        )}
      </div>
    )
    page = (
      <>
        <div>{errorPage}</div>
        {cancelButton}
      </>
    )
  }

  if (!loading && !error) {
    switch (state.page) {
      case MachineRegistrationPageState.About: {
        if (!!props.machineData) {
          page = (
            <AboutMachineEdit
              back={props.cancel}
              next={() =>
                moveToPage(MachineRegistrationPageState.MachineInformation)
              }
              customerData={props.customerData}
              plantData={props.plantData}
              machineData={props.machineData}
              {...state}
              {...thiisOrThat<Partial<TMachinePostInformation>>(
                state,
                toMachineInformation(machineDataRequest.data)
              )}
            />
          )
          break
        }

        page = (
          <AboutMachineCreation
            back={props.cancel}
            next={() =>
              moveToPage(MachineRegistrationPageState.MachineInformation)
            }
            customerData={props.customerData}
            plantData={props.plantData}
            {...state}
            {...thiisOrThat<Partial<TMachinePostInformation>>(
              state,
              toMachineInformation(machineDataRequest.data)
            )}
          />
        )
        break
      }

      case MachineRegistrationPageState.MachineInformation: {
        // const spreadableData = props.machineData || {}
        page = (
          <MachineInformation
            customerID={props.customerData?.customerID}
            encryptedBottleIdentifier={props.encryptedBottleIdentifier}
            cancel={props.cancel}
            back={data => {
              moveToPage(MachineRegistrationPageState.About, data)
            }}
            next={data => {
              moveToPage(
                MachineRegistrationPageState.MachineFluidAndSampling,
                data
              )
            }}
            cache={cacheRequest.data}
            addToCache={addToCache}
            criticalityRating={
              state.criticalityRating ??
              machineDataRequest.data?.criticalityRating
            }
            plantMachines={props.plantMachines}
            {...state}
            {...thiisOrThat<Partial<TMachinePostInformation>>(
              state,
              toMachineInformation(machineDataRequest.data)
            )}
          />
        )

        break
      }

      case MachineRegistrationPageState.MachineFluidAndSampling: {
        page = (
          <MachineSamplingInformation
            customerID={props.customerData?.customerID}
            encryptedBottleIdentifier={props.encryptedBottleIdentifier}
            cancel={props.cancel}
            back={data => {
              moveToPage(MachineRegistrationPageState.MachineInformation, data)
            }}
            next={data => {
              moveToPage(MachineRegistrationPageState.MachineTesting, data)
            }}
            cache={cacheRequest.data}
            addToCache={addToCache}
            {...state}
            {...thiisOrThat<Partial<TMachinePostInformation>>(
              state,
              toMachineInformation(machineDataRequest.data)
            )}
          />
        )
        break
      }

      case MachineRegistrationPageState.MachineTesting: {
        {
          page = (
            <MachineTestingInformation
              customerID={props.customerData?.customerID}
              encryptedBottleIdentifier={props.encryptedBottleIdentifier}
              cancel={props.cancel}
              back={data => {
                moveToPage(
                  MachineRegistrationPageState.MachineFluidAndSampling,
                  data
                )
              }}
              next={data => {
                moveToPage(MachineRegistrationPageState.Complete, data)
              }}
              cache={cacheRequest.data}
              addToCache={addToCache}
              {...state}
              {...thiisOrThat<Partial<TMachinePostInformation>>(
                state,
                toMachineInformation(machineDataRequest.data)
              )}
            />
          )
          break
        }
      }
    }
  }

  const stepperMarkers: TProgressMarker[] = [
    {
      body: '1',
      label: 'About',
      loading: false,
    },
    {
      body: '2',
      label: 'Machine',
      loading: false,
    },
    {
      body: '3',
      label: 'Fluid',
      loading: false,
    },
    {
      body: '4',
      label: 'Test Plan',
      loading: false,
    },
  ]

  let step: number =
    state.page + 1 < MachineRegistrationPageState.Complete
      ? state.page + 1
      : MachineRegistrationPageState.MachineTesting + 1
  const progressStepper = (
    <>
      <ProgressStepper step={step} markers={stepperMarkers} />
    </>
  )

  return (
    <PageCard>
      <div ref={header} style={{ margin: 0 }}>
        <CancelButton onCancel={props.cancel} />
        <header className={PageStyles.MainTitle}>{getTitle(stateProps)}</header>
        <div className={PageStyles.ProgressStepperBox}>{progressStepper}</div>
      </div>
      <div
        className={`${PageStyles.EntryScreen} ${
          isLoggedIn.loggedIn ? PageStyles.LoggedIn : ''
        }`}
        style={
          isMobile
            ? { minHeight: remainderHeightCalculationMobile }
            : isLoggedIn.loggedIn
            ? {
                height: 'inherit',
                minHeight: remainderHeightCalculationLoggedIn,
              }
            : {
                height: 'inherit',
                minHeight: remainderHeightCalculation,
              }
        }
      >
        {page}
      </div>
    </PageCard>
  )
}

function getTitle(props: TProps) {
  return props.machineData ? 'Modify Existing Machine' : 'Register New Machine'
}

function useExistingMachineData(
  props: TPageState & TProps & { shouldIssue: boolean }
) {
  const isLoggedIn = useIsLoggedIn()
  const useEncrypted = !!props.encryptedBottleIdentifier
  const loggedInAndInfoAvailable =
    props.customerData?.customerID != null && isLoggedIn.loggedIn
  const issuePromise =
    props.shouldIssue &&
    props.page === MachineRegistrationPageState.About &&
    !!props.machineData &&
    (loggedInAndInfoAvailable || !!props.encryptedBottleIdentifier)

  const getPromise = () => {
    return useEncrypted
      ? APIService.getMachineDetailEncryptedAsync({
          pointID: props.machineData.pointID,
          encryptedIdentifier: props.encryptedBottleIdentifier,
        })
      : APIService.getMachineDetailAsync({
          pointID: props.machineData.pointID,
        })
  }
  return usePromise({
    shouldStartPromise: _data => issuePromise,
    dependencies: () => [props.page, issuePromise],
    promise: getPromise,
  })
}

function thiisOrThat<TType>(thiis: TType, that: TType) {
  if (typeof thiis !== 'object' || !thiis || !that) return thiis ?? that

  const combination: Partial<TType> = {}
  for (const key in thiis) {
    combination[key] = thiis[key] ?? that[key]
  }

  return combination
}

function toMachinePatchDTO(
  props: TMachinePostInformation,
  plantID: number,
  pointID: number
): PatchMachinesDTO {
  return {
    ...toMachinePostDTO(props, plantID),
    pointID: pointID,
  }
}

function toMachinePostDTO(
  props: TMachinePostInformation,
  plantID: number
): PostMachinesDTO {
  return {
    customerEquipmentID: props.equipmentID,
    lubricantID: props.fluidID,
    name: props.name,
    plantID: plantID,
    manufacturer: props.machineManufacturerShortCode,
    machineType: props.machineTypeShortCode,
    identifier: props.equipmentID,
    testSchedules: props.testSchedules,
    notes: props.notes,
    modelName: props.model,
    number: null,
    routeID: props.routeID,
    dutyCycle: Number(props.dutyCycle),
    latitude: props.location?.coords?.latitude ?? null,
    longitude: props.location?.coords?.longitude ?? null,
    portTypeID: props.portTypeID,
    customerEquipmentType: props.equipmentID,
    criticalityRating: props.criticalityRating,
    sampleSourceRating: props.sampleSourceRating,
    samplingInstructions: props.samplingInstructions,
    lubricantManufacturer: props.fluidManufacturerCode,
    builder: null,
  }
}

function toMachineInformation(
  props: PostMachinesDTO
): Partial<TMachinePostInformation> {
  if (!props) return {}

  return {
    criticalityRating: props.criticalityRating,
    dutyCycle: props.dutyCycle?.toString() ?? '',
    equipmentID: props.customerEquipmentID,
    fluidID: props.lubricantID,
    fluidManufacturerCode: props.lubricantManufacturer,
    location: {
      coords: {
        latitude: props.latitude,
        longitude: props.longitude,
      } as GeolocationCoordinates,
    } as GeolocationPosition,
    machineManufacturerShortCode: props.manufacturer,
    machineTypeShortCode: props.machineType,
    model: props.modelName,
    name: props.name,
    notes: props.notes,
    portTypeID: props.portTypeID,
    routeID: props.routeID,
    sampleSourceRating: props.sampleSourceRating,
    samplingInstructions: props.samplingInstructions,
    testSchedules: props.testSchedules,
  }
}

function useMachinePatch(props: TPageState & TProps) {
  const isLoggedIn = useIsLoggedIn()
  const useEncrypted = !!props.encryptedBottleIdentifier
  const loggedInAndInfoAvailable =
    props.customerData?.customerID != null && isLoggedIn.loggedIn

  const issuePromise =
    props.page === MachineRegistrationPageState.Complete &&
    !!props.machineData &&
    (loggedInAndInfoAvailable || !!props.encryptedBottleIdentifier)

  const getPromise = () => {
    const machines: PatchMachinesDTO[] = [
      toMachinePatchDTO(
        props,
        props.plantData.plantID,
        props.machineData.pointID
      ),
    ]

    return useEncrypted
      ? APIService.patchMachinesByEncryptedBottleAsync({
          machines,
          encryptedIdentifier: props.encryptedBottleIdentifier,
        })
      : APIService.patchMachinesAsync({
          machines,
        })
  }
  return usePromise({
    shouldStartPromise: _data => issuePromise,
    dependencies: () => [props.page],
    promise: () =>
      getPromise().then(res => {
        const posted = res[0]
        props.submit(posted.pointID)
        return posted
      }),
  })
}

function useMachinePost(props: TPageState & TProps) {
  const isLoggedIn = useIsLoggedIn()
  const useEncrypted = !!props.encryptedBottleIdentifier
  const loggedInAndInfoAvailable =
    props.customerData?.customerID != null && isLoggedIn.loggedIn
  const issuePromise =
    props.page === MachineRegistrationPageState.Complete &&
    !props.machineData &&
    (loggedInAndInfoAvailable || !!props.encryptedBottleIdentifier)

  const getPromise = () => {
    const machines: PostMachinesDTO[] = [
      toMachinePostDTO(props, props.plantData.plantID),
    ]

    return useEncrypted
      ? APIService.postMachinesByEncryptedBottleAsync({
          machines,
          encryptedIdentifier: props.encryptedBottleIdentifier,
        })
      : APIService.postMachinesAsync({
          machines,
        })
  }
  return usePromise({
    shouldStartPromise: _data => issuePromise,
    dependencies: () => [props.page],
    promise: () =>
      getPromise().then(res => {
        const posted = res[0]
        props.submit(posted.pointID)
        return posted
      }),
  })
}
