import React from 'react'
import { Box, Grid, Hidden, TablePagination } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'
import {
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'
import moment from 'moment'

import Condition from 'components/ConditionElement'
import FileLink from 'components/FileLink'
import TableGrid from 'components/TableGrid'
import { getSampleProblems } from 'utils/api-utilities'

import { MobileCard } from './MobileCard'

export type TProps = {
  offline?: boolean
  changeOfConditions: any[]
  orderConfig: any[]
  page: number
  pageSize: number
  count: number
  onPageChange?: (_event: GridPageChangeEvent) => void
  onSortChange?: (_event: GridSortChangeEvent) => void
  onPageChangeMobile: (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    _page: number
  ) => void
  onPageSizeChangeMobile?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >
}

export const ChangeOfConditionsReport: React.FC<TProps> = ({
  offline,
  changeOfConditions,
  orderConfig,
  page,
  pageSize,
  count,
  onPageChange,
  onSortChange,
  onPageChangeMobile,
  onPageSizeChangeMobile,
}: TProps) => {
  const RenderLabNo = ({ dataItem: data, from }: any) => (
    <td>
      {data[from].batchID > 0 ? (
        <Grid direction="row" container justify="center">
          <Grid item>
            <FileLink
              custID={data.machine.plant.customer.custID}
              fileName={`${data[from].labID}.pdf`}
            >
              {data[from].labID}
            </FileLink>
          </Grid>
          <Grid item>
            <FileLink
              custID={data.machine.plant.customer.custID}
              fileName={`${data[from].labID}.pdf`}
              download={true}
            >
              <GetApp fontSize="small" />
            </FileLink>
          </Grid>
        </Grid>
      ) : (
        data[from].labID
      )}
    </td>
  )

  const RenderMachineName = ({ dataItem: data }: any) => (
    <td style={{ color: '#6200EE' }}>
      <a
        href={`/dart/data?labID=${data.currentSample.labID}`}
        target="_blank"
        rel="noreferrer"
      >
        {data.machine?.machineName}
      </a>
    </td>
  )

  const renderColumns = (from: string) => [
    <Column
      field={`${from}.sampleDate`}
      title="Sample Date"
      cell={({ dataItem }) => (
        <td>
          {!dataItem[from].sampleDate
            ? ''
            : moment(dataItem[from].sampleDate).format('l')}
        </td>
      )}
    />,
    <Column
      field={`${from}.machCond`}
      title="Mach"
      cell={({ dataItem }) => (
        <td>
          <Condition
            condition={dataItem[from].machCond}
            issueContributors={getSampleProblems(dataItem[from])}
          />
        </td>
      )}
    />,
    <Column
      field={`${from}.lubCond`}
      title="Lube"
      cell={({ dataItem }) => (
        <td>
          <Condition
            condition={dataItem[from].lubCond}
            issueContributors={getSampleProblems(dataItem[from])}
          />
        </td>
      )}
    />,
    <Column
      field={`${from}.labID`}
      title="Lab No."
      cell={props => <RenderLabNo {...props} from={from} />}
    />,
  ]

  const showDivisions = changeOfConditions.some(
    d => d.machine.plant.customer.division
  )

  return (
    <>
      {offline && (
        <Box mb={2}>Your last 100 reports are available while offline.</Box>
      )}

      <Hidden mdUp>
        {!changeOfConditions?.length && (
          <p style={{ textAlign: 'center' }}>No records available</p>
        )}
        {changeOfConditions.map(changeOfCondition => (
          <MobileCard
            key={changeOfCondition.labID}
            changeOfCondition={changeOfCondition}
            showDivisions={showDivisions}
          />
        ))}

        {!offline ? (
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100, 250, 500]}
            component="div"
            count={count}
            rowsPerPage={pageSize}
            page={page > 0 ? page - 1 : page}
            onChangePage={onPageChangeMobile}
            onChangeRowsPerPage={onPageSizeChangeMobile}
          />
        ) : (
          ''
        )}
      </Hidden>

      <Hidden smDown>
        <TableGrid
          clearSortingButtonId="change_of_conditions-clear_sorting_button"
          data={changeOfConditions}
          offline={offline}
          sort={orderConfig}
          skip={(page - 1) * pageSize}
          take={pageSize}
          total={count}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
        >
          {showDivisions && (
            <Column
              field="machine.plant.customer.division.name"
              title="Division Name"
            />
          )}
          <Column
            field="machine.plant.customer.custName"
            title="Customer Name"
          />
          <Column field="machine.plant.plantName" title="Plant Name" />
          <Column field="machine.machNo" title="Mach No." />
          <Column
            field="machine.machineName"
            title="Machine Name"
            cell={props => <RenderMachineName {...props} />}
          />
          <Column
            title="Current Sample"
            sortable={false}
            children={renderColumns('currentSample')}
          />
          <Column
            title="Previous Sample"
            sortable={false}
            children={renderColumns('previousSample')}
          />
        </TableGrid>
      </Hidden>
    </>
  )
}
