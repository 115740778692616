import { usePrimaryHubContext } from 'Contexts/PrimaryHubContext'
import React, { useEffect, useState } from 'react'
import { ConnectionStatus } from 'types/custom.d'
import Styles from './index.module.css'

export const DisconnectedBanner: React.FC = props => {
  const primaryHubContext = usePrimaryHubContext()
  const [showWarning, setShowWarning] = useState(true)

  useEffect(() => {
    const disconnected =
      primaryHubContext.status === ConnectionStatus.disconnected
    setShowWarning(disconnected)
  }, [primaryHubContext.status])

  if (!showWarning) return null
  return (
    <div id={Styles.Banner} onClick={() => setShowWarning(false)}>
      <p id={Styles.PrimaryFont}>
        {' '}
        Datasight is experiencing technical difficulties and may be unavailable.
      </p>
      <p id={Styles.SecondaryFont}>Click to continue.</p>
    </div>
  )
}
