import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY)

type TOwnProps = {}

const StripeProvider: React.FC<TOwnProps> = props => {
  return <Elements stripe={stripePromise}>{props.children}</Elements>
}

export default StripeProvider
