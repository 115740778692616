import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'

import AppLayout from 'components/AppLayout'
import NavCard from 'components/NavCard'
import SEO from 'components/SEO'

const pages = [
  {
    url: '/static/general-files/BlankLabels.pdf',
    title: 'Blank Labels',
    description:
      'These are generic sample labels that allow you to handwrite the machine information.',
    background: '#1d2b3e',
    icon: <GetAppIcon />,
    useHref: true,
    enableFileOpen: true,
  },
  {
    url: '/static/general-files/Equipment%20Registration%20Form.pdf',
    title: 'Equipment Registration Form',
    description:
      'This form can be filled out and submitted to our customer service team who will add them to your database.',
    background: '#c18949',
    icon: <GetAppIcon />,
    useHref: true,
    enableFileOpen: true,
  },
  {
    url: '/static/general-files/FAXBACK%20REV5.pdf',
    title: 'Faxback Sampling Supply Request Form',
    description:
      'This form is used for customers who would like to fax us their request for more sampling supplies.',
    background: '#d8d8d8',
    icon: <GetAppIcon />,
    useHref: true,
    enableFileOpen: true,
  },
  {
    url: '/static/general-files/Shipping%20from%20Canada%20Instructions.pdf',
    title: 'Shipping from Canada Instructions',
    description:
      'This document contains instructions on how to ship your samples internationally.',
    background: '#d85611',
    icon: <GetAppIcon />,
    useHref: true,
    enableFileOpen: true,
  },
  {
    url: '/static/general-files/Testoil%20MIA%20Customer%20Requisition%20-%20REV%2003.pdf',
    title: 'Material Identification Analysis Requisition',
    description:
      'This form can be filled out when you would like to request a Material Identification Analysis done.',
    background: '#efcd3c',
    icon: <GetAppIcon />,
    useHref: true,
    enableFileOpen: true,
  },
  {
    url: '/static/general-files/MachineDataExportTemplate.csv',
    title: 'Machine Data Export Template',
    description:
      'A template for historical machine data. This form is used to provide historical machine test information to Testoil for tracking and trending.',
    background: '#0a8c3a',
    icon: <GetAppIcon />,
    useHref: true,
    enableFileOpen: true,
  },
  {
    url: '/static/general-files/Equipment Registration Spreadsheet.xls',
    title: 'Bulk Equipment Registration Spreadsheet',
    description:
      'A spreadsheet for registering multiple plants or units of equipment at once. Once submitted to the Testoil customer service team, the information will be added to your database in 1 to 3 business days.',
    background: '#7800F7',
    icon: <GetAppIcon />,
    useHref: true,
    enableFileOpen: true,
  },
  {
    url: '/static/general-files/FDA_FORM_V2.pdf',
    title: 'First Time Filter Debris Analysis Form',
    description:
      'This form must be completed and included with filters you are sending for the first time. If the filter has been tested previously you may skip this form and simply complete and affix a Filter Debris Analysis label.',
    background: '#0654F7',
    icon: <GetAppIcon />,
    useHref: true,
    enableFileOpen: true,
  },
  {
    url: '/static/general-files/FDA_Repeat_Sample_Labels.pdf',
    title: 'Repeat Filter Debris Analysis Labels',
    description:
      'This label must be completed and included with filters you have previously submitted for testing. If a filter is being submitted for the first time the FDA Requisition form must be completed.',
    background: '#FF54F7',
    icon: <GetAppIcon />,
    useHref: true,
    enableFileOpen: true,
  },
]

function GeneralFiles() {
  return (
    <React.Fragment>
      <SEO title="General Files" />
      <Grid container direction="row" spacing={4}>
        <Grid item md={3}>
          <Typography variant="h1">General Files</Typography>
          <Box style={{ marginTop: '15px' }}>
            <Typography paragraph>
              These are labels and forms that are applicable to any oil analysis
              program
            </Typography>
          </Box>
        </Grid>
        <Grid container item direction="row" md={9} spacing={4}>
          {pages.map((page, i) => (
            <NavCard
              key={i}
              icon={page.icon}
              title={page.title}
              description={page.description}
              url={page.url}
              background={page.background}
              useHref={page.useHref}
              enableFileOpen={page.enableFileOpen}
              openInNewWindow={true}
            ></NavCard>
          ))}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default function Page() {
  return (
    <AppLayout tab="downloads">
      <GeneralFiles />
    </AppLayout>
  )
}
