import React from 'react'
import { Alert, AlertTitle } from '@material-ui/lab'
type IssueSeverity = 'error' | 'info' | 'success' | 'warning'

interface OwnProps {
  title: string
  severity: IssueSeverity
  showBanner: boolean
  onBannerClick: () => void
}
const IssueBanner: React.FC<OwnProps> = (
  props: React.PropsWithChildren<OwnProps>
) => {
  return props.showBanner ? (
    <>
      <Alert
        severity={props.severity}
        color={props.severity}
        variant={props.severity == 'error' ? 'filled' : undefined}
        style={{
          background:
            props.severity == 'error' ? 'rgb(200, 40, 40)' : undefined,
        }}
      >
        <AlertTitle>{props.title}</AlertTitle>
        {props.children}
      </Alert>
    </>
  ) : null
}

export default IssueBanner
