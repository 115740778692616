import Moment from 'moment'

import { TAPISampleComplianceRecord, TAPIScheduledSample } from 'types/api'
import { extendMoment } from 'moment-range'

const moment = extendMoment({ ...Moment, default: null })

export const tableFormatter = (
  data: TAPIScheduledSample[],
  hasDivisions: boolean
): string[][] => {
  const headerValues = [
    ...(hasDivisions ? ['Division Name'] : []),
    'Customer Name',
    'Plant Name',
    'Route Name',
    'Machine Number',
    'Machine Name',
    'Equipment ID',
    'Test Package',
    'Tests',
    'All Months',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const values = data.map(datum => [
    ...(hasDivisions ? [datum.machine.plant.customer.division.name] : []),
    datum.machine.plant.customer.custName,
    datum.machine.plant.plantName,
    datum.machine.route !== null
      ? `${datum.machine.route.routeNo} - ${datum.machine.route.name}`
      : '',
    datum.machine.machNo,
    datum.machine.machineName,
    datum.machine.custEquID,
    datum.testGroup.testGroupName,
    datum.testGroup.testGroupDefs.map(x => x.testCode.testCodeName).join(', '),
    datum.allMonths ? 'Yes' : '',
    datum.jan ? 'Yes' : '',
    datum.feb ? 'Yes' : '',
    datum.mar ? 'Yes' : '',
    datum.apr ? 'Yes' : '',
    datum.may ? 'Yes' : '',
    datum.jun ? 'Yes' : '',
    datum.jul ? 'Yes' : '',
    datum.aug ? 'Yes' : '',
    datum.sep ? 'Yes' : '',
    datum.oct ? 'Yes' : '',
    datum.nov ? 'Yes' : '',
    datum.dec ? 'Yes' : '',
  ])

  return [headerValues, ...values]
}
