// An in-depth comparison is as much work as actually changing the state anyway! Make this a fairly shallow compare.
export function statesAreEqual<
  T extends Object | null,
  U extends Object | null
>(newState: T, previousState: U): boolean {
  if (newState == null && previousState == null) {
    // true if both null
    return true
  }
  if (newState == null || previousState == null) {
    // false if one is null and other is not
    return false
  }
  const areEqual = Object.keys(newState)?.every(key => {
    if (Array.isArray(newState[key]) && Array.isArray(previousState[key]))
      return newState[key]?.length === previousState[key]?.length
    else {
      if (newState[key] === previousState[key]) return true

      const newStateString = JSON.stringify(newState[key])
      const previousStateString = JSON.stringify(previousState[key])
      return newStateString === previousStateString
    }
  })
  return areEqual
}
