import FilterTooltip from 'components/filters/FilterTooltip/FilterTooltip'
import React, { useMemo, useState } from 'react'
import {
  ErrorFormValue,
  BooleanFormValue,
  FormValue,
  FormValueTypes,
} from 'utils/FormUtilities/FormValue'
import * as PageStyles from '../../../../page-styles.module.css'
import TextField from '@mui/material/TextField'
import { Button } from '@material-ui/core'
import { TPlantContactInformation } from '../types'
import { ContactDTO } from 'types/api'
import { delay } from 'utils/general-utilities'
import { emailRegex } from 'utils/common-regex'

type TProps = {
  back: (shippingInformation: TPlantContactInformation) => void
  next: (shippingInformation: TPlantContactInformation) => void
} & Partial<FormValueTypes<TFreeFormState>>

type TFixedState = {
  formUpdated: boolean
}

type TFreeFormState = {
  firstName: ErrorFormValue<string>
  lastName: ErrorFormValue<string>
  email: ErrorFormValue<string>
}

type TState = TFreeFormState & TFixedState

const debounceTimeMS = 0

export const ContactInformation = (props: TProps) => {
  const defaultState: Readonly<TState> = useMemo(
    () => ({
      firstName: new ErrorFormValue<string>(
        props.firstName ?? '',
        a1 => validateFirstName(a1),
        !!props.firstName
      ),
      lastName: new ErrorFormValue<string>(
        props.lastName ?? '',
        a1 => validateLastName(a1),
        !!props.lastName
      ),
      email: new ErrorFormValue<string>(
        props.email ?? '',
        a1 => validateEmail(a1),
        !!props.email
      ),
      formUpdated: false,
    }),
    []
  )

  const [state, setState] = useState<TState>({ ...defaultState })

  function updateFixedState<
    TKey extends keyof TFixedState,
    TValue extends TFixedState[TKey]
  >(key: TKey, value: TValue) {
    setState(prev => ({ ...prev, [key]: value }))
  }

  function updateFreeFormState<
    TKey extends keyof TFreeFormState,
    TValue extends TFreeFormState[TKey]['Value']
  >(key: TKey, value: TValue, automaticUpdate: boolean = false) {
    const stateValue = state[key]
    stateValue.Value = value

    if (automaticUpdate) {
      stateValue.Modified = false
    }

    setState(prev => ({ ...prev, [key]: stateValue }))
  }

  async function submit() {
    await delay(debounceTimeMS)

    FormValue.modifyAllObjects(state)
    if (!FormValue.allObjectParametersAreValid(state)) {
      updateFixedState('formUpdated', !state.formUpdated)
      return
    }

    if (props.next) {
      props.next({
        email: state.email.Value,
        firstName: state.firstName.Value,
        lastName: state.lastName.Value,
      })
    }
  }

  const buttons = (
    <div className={PageStyles.ButtonContainer}>
      <Button
        data-cancel
        variant="contained"
        color="primary"
        onClick={() =>
          props.back({
            email: state.email.Value,
            firstName: state.firstName.Value,
            lastName: state.lastName.Value,
          })
        }
        className={`${PageStyles.Button} ${PageStyles.Left}`}
      >
        General Info.
      </Button>

      <Button
        data-accept
        disabled={!FormValue.allObjectParametersAreValid(state)}
        variant="contained"
        color="secondary"
        onClick={submit}
        className={`${PageStyles.Button} ${PageStyles.Right}`}
      >
        Shipping Info.
      </Button>
    </div>
  )

  const page = (
    <>
      <div>
        <h6 className={PageStyles.DetailSectionHeader}>
          Plant Contact Information
        </h6>
        <section
          className={`${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer} ${PageStyles.Wrapper}`}
        >
          <TextField
            error={state.firstName.Modified && !!state.firstName.getError()}
            helperText={
              !!state.firstName.getError() &&
              state.firstName.Modified &&
              state.firstName.getValidationMessage()
            }
            label="First Name"
            variant="outlined"
            color="primary"
            name={'firstName'}
            required
            fullWidth
            value={state.firstName.Value}
            onChange={event =>
              updateFreeFormState('firstName', event.target.value)
            }
            className={`${PageStyles.Wrappable}`}
          />

          <TextField
            error={state.lastName.Modified && !!state.lastName.getError()}
            helperText={
              !!state.lastName.getError() &&
              state.lastName.Modified &&
              state.lastName.getValidationMessage()
            }
            label="Last Name"
            variant="outlined"
            color="primary"
            name={'lastName'}
            required
            fullWidth
            value={state.lastName.Value}
            onChange={event =>
              updateFreeFormState('lastName', event.target.value)
            }
            className={`${PageStyles.Wrappable}`}
          />

          <TextField
            error={state.email.Modified && !!state.email.getError()}
            helperText={
              !!state.email.getError() &&
              state.email.Modified &&
              state.email.getValidationMessage()
            }
            label="Email"
            variant="outlined"
            color="primary"
            name={'email'}
            required
            fullWidth
            value={state.email.Value}
            onChange={event =>
              updateFreeFormState(
                'email',
                event.target.value,
                !state['email'].Modified
              )
            }
            onBlur={event => updateFreeFormState('email', event.target.value)}
            className={`${PageStyles.Wrappable}`}
          />
        </section>
        <h6 className={PageStyles.DetailSectionHeader}>
          About Plant Contact Information
        </h6>
        <section className={PageStyles.DetailSection}>
          <p className={PageStyles.FinePrintParagraph}>
            Default contact information is pulled from the customer’s primary
            contact. This may be changed to any valid value and may be changed
            later at any point by contacting customer support at{' '}
            <a href={`mailto: ${process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL}`}>
              {process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL!}
            </a>
            {'.'} The information provided will be used as the default contact
            for the registered plant. If there are any issues with this plant’s
            registration information, billing information, reports, etc., the
            plant contact will be the foremost notified party.
          </p>
        </section>
      </div>
      {buttons}
    </>
  )

  return page
}

function validateAddressLine1(a1: string) {
  if (!a1) return new Error('Address line 1 is required.')
  if (a1.length > 50) {
    return new Error(
      'Address line 1 must be less than 50 characters in length.'
    )
  }
}

function validateEmail(email: string) {
  if (!email) return new Error('An email address is required.')
  if (email.length > 255) return new Error('Email address is too long!')
  if (!emailRegex.test(email)) return new Error('Email address is invalid.')
}

function validateFirstName(name: string) {
  if (!name) return new Error('First name is required.')
  if (name.length > 255) return new Error('First name is too long!')
}

function validateLastName(name: string) {
  if (!name) return new Error('Last name is required.')
  if (name.length > 255) return new Error('Last name is too long!')
}

function validateCity(city: string) {
  if (!city) return new Error('City is required.')
  if (city.length > 255) return new Error('City is too long!')
}
