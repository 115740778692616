import { useEffect, useRef, useState, useCallback } from 'react'
import * as Styles from './index.module.css'
import { v4 as uuid } from 'uuid'
import { Button } from '@material-ui/core'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useIsLoggedIn } from 'hooks/useIsLoggedIn'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import React from 'react'
import ReactDOM from 'react-dom'

type Props<TOption> = {
  options: TOption[]
  placeholder?: string
  label: string
  elementificate: (opt: TOption) => JSX.Element
  stringify: (opt: TOption) => string
  getKey?: (opt: TOption) => string
  onChange: (opt: TOption) => void
  default?: TOption
  value?: TOption
  disabled?: boolean
  error?: boolean
  helperText?: string
  required?: boolean
}

type State<TOption> = {
  showSelect: boolean
  selected: TOption
  searchString: string
}

const defaultState: State<null> = {
  showSelect: false,
  selected: null,
  searchString: '',
}
export function MobileSingleSelect<TOption>(props: Props<TOption>) {
  const [state, setState] = useState<State<TOption>>({
    ...defaultState,
    selected: !!props.value
      ? props.value
      : !!props.default
      ? props.default
      : null,
  })
  const { loggedIn } = useIsLoggedIn()

  const openSelect = () => {
    if (props.disabled) return

    setState(prev => ({ ...prev, showSelect: true }))
  }

  const lastValue = useRef<TOption>(
    !!props.value ? props.value : !!props.default ? props.default : null
  )
  const closeSelect = () => {
    lastValue.current = props.value ?? state.selected
    setState(prev => ({ ...prev, showSelect: false, searchString: '' }))
  }

  const cancelSelect = () => {
    lastValue.current = lastValue.current ?? props.value ?? props.default
    setState(prev => ({
      ...prev,
      showSelect: false,
      selected: lastValue.current,
      searchString: '',
    }))
  }

  const onSearch = (searchString: string) => {
    setState(prev => ({
      ...prev,
      searchString: searchString,
    }))
  }

  function selectValue(option: TOption) {
    setState(prev => ({ ...prev, selected: option }))
  }

  const placeholder = (
    <span
      className={`${Styles.PlaceholderText} ${
        props.disabled ? Styles.Disabled : ''
      }`}
    >
      {props.placeholder}
    </span>
  )

  const displayValue = (props.value || state.selected || props.default) && (
    <span
      className={`${Styles.ValueText} ${Styles.DisplayText} ${
        props.disabled ? Styles.Disabled : ''
      }`}
    >
      {props.stringify(props.value ?? state.selected ?? props.default)}
    </span>
  )
  const showDisplayValue = props.value || state.selected || props.default
  const showPlaceholder = false
  const renderOptions = !!props.options && state.showSelect
  const id = uuid()

  const displayOptions = useSearch({
    options: props.options,
    stringifier: props.stringify,
    searchString: state.searchString,
  })

  manageSelected({
    selected: state.selected ?? props.value,
    onSelect: props.onChange,
  })

  function compareToCurrentValue(option: TOption) {
    return props.value ? option === props.value : option === state.selected
  }

  const searchRef = useRef<HTMLInputElement>()
  const focusSearchInput = (event: React.PointerEvent<HTMLDivElement>) => {
    if (!searchRef.current) return
    event.stopPropagation()
    searchRef.current.focus()
  }

  const clearInput = useCallback(() => {
    setState(prev => ({ ...prev, searchString: '' }))
  }, [])

  const optionListRef = useRef<HTMLUListElement>()
  const optionrefs = useRef<HTMLLIElement[]>([])
  // const viableOptions = optionrefs.current.filter(i => !!i)
  // const averageHeight =
  //   viableOptions.length === 0
  //     ? 50
  //     : viableOptions
  //         .map(i => i.clientHeight)
  //         .reduce((p, n, a, b) => p + n / b.length, 0)

  const optionListOverflowing =
    optionListRef.current &&
    optionListRef.current.clientWidth < optionListRef.current.scrollWidth
  const optionListClasses = `${Styles.OptionList} ${
    optionListOverflowing ? Styles.FancyScrollBar : ''
  }`
  const inputStyles: React.CSSProperties = {
    borderColor: props.error
      ? 'var(--error-border-color)'
      : 'var(--input-border-color)',
    color: props.error ? 'var(--error-color)' : 'var(--input-color)',
  }

  const labelClass = `${Styles.Label} ${!!state.selected ? Styles.Shifted : ''}`

  const optionsElement = (
    <>
      <div
        className={Styles.OptionSelectExternal}
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
          closeSelect()
        }}
      ></div>
      <div
        className={`${Styles.OptionSelector} ${
          loggedIn ? Styles.LoggedIn : ''
        }`}
      >
        <div className={Styles.OptionSelectorInputBox}>
          <div
            className={Styles.IconContainer}
            onClick={focusSearchInput}
            onMouseDown={focusSearchInput}
            onMouseUp={focusSearchInput}
          >
            <SearchIcon style={{ fontSize: '2em' }} />
          </div>
          <input
            ref={searchRef}
            className={`${Styles.OptionSearch} ${Styles.DisplayText}`}
            onChange={e => onSearch(e.target.value)}
            value={state.searchString}
            type="text"
            placeholder="Search"
            data-search
          />
          <div
            className={`${Styles.IconContainer} ${Styles.ClearIconContainer}`}
            onClick={e => clearInput()}
          >
            <ClearIcon style={{ fontSize: '2em' }} />
          </div>
        </div>

        <ul className={optionListClasses} ref={optionListRef}>
          {displayOptions?.map((option, index) => {
            const Element = () => props.elementificate(option)
            const element = <Element data-value={option} />

            const isSelected = compareToCurrentValue(option)
            const className = `${Styles.Option} ${
              isSelected ? Styles.Selected : ''
            }`

            return (
              <li
                className={className}
                ref={r => (optionrefs.current[index] = r)}
                key={props.getKey ? props.getKey(option) : index}
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                  e.bubbles = false
                  selectValue(option)
                  closeSelect()
                }}
              >
                {element}
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )

  useEffect(() => {
    const newElement = document.createElement('div')
    newElement.className = Styles.RenderPage
    ;(() => {
      if (!renderOptions) return

      document.body.appendChild(newElement)
      ReactDOM.render(optionsElement, newElement)
    })()

    return () => {
      if (!renderOptions) return

      document.body.removeChild(newElement)
    }
  }, [renderOptions])

  return (
    <div className={Styles.Container}>
      {/* <label className={Styles.Label} htmlFor={id} style={inputStyles}>
        {props.label}
        {!!props.required && !!props.label && ' *'}
      </label> */}
      <div
        id={id}
        className={Styles.Input}
        onClick={openSelect}
        style={inputStyles}
      >
        {props.label && (
          <label className={labelClass} htmlFor={id}>
            {props.label}
            {!!props.required && !!props.label && ' *'}
          </label>
        )}
        <div className={Styles.InputText}>
          {showPlaceholder && placeholder}
          {showDisplayValue && displayValue}
        </div>
        <div className={Styles.InputArrow}>
          {renderOptions ? (
            <KeyboardArrowUpIcon
              className={props.disabled ? Styles.Disabled : ''}
            />
          ) : (
            <KeyboardArrowDownIcon
              className={props.disabled ? Styles.Disabled : ''}
            />
          )}
        </div>
      </div>
      {props.helperText && (
        <p className={Styles.HelperText} style={inputStyles}>
          {props.helperText}
        </p>
      )}
      {/* {renderOptions && optionsElement} */}
    </div>
  )
}

function manageSelected<TOption>({
  selected,
  onSelect,
}: {
  selected: TOption
  onSelect: (option: TOption) => void
}) {
  useEffect(() => {
    ;(() => {
      if (!onSelect) return
      onSelect(selected)
    })()
  }, [selected])
}

function useSearch<TOption>({
  options,
  stringifier,
  searchString,
}: {
  options: TOption[]
  stringifier: (opt: TOption) => string
  searchString: string
}) {
  const [stringOptions, setStringOptions] = useState<TOption[]>([])

  useEffect(() => {
    ;(() => {
      if (!searchString) {
        setStringOptions(options)
      } else if (!options) {
      } else {
        const caselessSearchString = searchString.toLowerCase()
        setStringOptions(
          options
            .map(o => ({ s: stringifier(o).toLowerCase(), option: o }))
            .filter(o => o.s.includes(caselessSearchString))
            .map(o => o.option)
        )
      }
    })()
  }, [searchString, options])

  return stringOptions
}
