export default class OrderedList<T> {
  private data: T[]

  private orderFields: {
    delegate: (_sortee: T) => object
    descending: boolean
  }[]

  public constructor(data: T[]) {
    this.data = data
  }

  public orderBy(
    delegate: (_sortee: T) => object,
    desc?: boolean
  ): OrderedList<T> {
    this.orderFields = [{ delegate, descending: desc || false }]
    return this
  }

  public thenBy(
    delegate: (_sortee: T) => object,
    desc?: boolean
  ): OrderedList<T> {
    this.orderFields.push({ delegate, descending: desc || false })
    return this
  }

  public toArray(): T[] {
    return this.data?.sort(this.sortFunction.bind(this))
  }

  private sortFunction(a: T, b: T): number {
    for (let i = 0; i < this.orderFields.length; i++) {
      const orderProperties = this.orderFields[i]

      if (orderProperties.delegate(a) > orderProperties.delegate(b)) {
        return orderProperties.descending ? -1 : 1
      } else if (orderProperties.delegate(a) < orderProperties.delegate(b)) {
        return orderProperties.descending ? 1 : -1
      } else if (i === this.orderFields.length - 1) return 0
    }
  }
}
