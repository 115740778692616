export function handleDownloadFile({ buffer, fileName, download = true }) {
  if (download) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(new Blob([buffer]), fileName)
    } else {
      // Create an invisible A element
      const a = document.createElement('a')
      a.style.display = 'none'
      document.body.appendChild(a)

      // Set the HREF to a Blob representation of the buffer to be downloaded
      a.href = window.URL.createObjectURL(new Blob([buffer]))
      // Use download attribute to set set desired file name
      a.setAttribute('download', fileName)

      // Trigger the download by simulating click
      a.click()

      // Cleanup
      window.URL.revokeObjectURL(a.href)
      document.body.removeChild(a)
    }
  } else {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(new Blob([buffer]), fileName)
    } else {
      const a = document.createElement('a')
      a.style.display = 'none'
      document.body.appendChild(a)
      a.onclick = () => {
        window.open(
          window.URL.createObjectURL(
            new Blob([buffer], {
              endings: 'native',
              type: 'application/pdf',
            })
          ),
          '_blank'
        )
      }
      a.click()
      window.URL.revokeObjectURL(a.href)
      document.body.removeChild(a)
    }
  }
}

export function fileToBase64(file: File): Promise<string> {
  const textToSkip = `data:${file.type};base64,`
  const reader = new FileReader()
  reader.readAsDataURL(file)
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result.toString().slice(textToSkip.length))
    }
  })
}

export function getBytesFromMebibytes(mebibytes: number) {
  return mebibytes * 2 ** 20
}

export function getBytesFromMegabytes(megabytes: number) {
  return megabytes * 10 ** 6
}
