import React from 'react'

import { TAPIMachine } from 'types/api'

import { VirtualSelectFilter } from '../BaseFilters/VirtualSelectFilter'

type TProps = {
  single: boolean
  required: boolean
  machines: TAPIMachine[]
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange?: (props: TAPIMachine | TAPIMachine[]) => void
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setState?: (props: any) => void
  value?: null | number | number[] | TAPIMachine | TAPIMachine[]
  id?: string
}

export const MachineFilter = ({
  machines,
  onChange,
  required,
  single,
  setState,
  value,
  id,
}: TProps) => {
  if (setState == null && onChange == null) return

  const handleMachineChange = (selected: TAPIMachine | TAPIMachine[]) => {
    if (onChange != null) {
      if (single === true) {
        onChange(selected as TAPIMachine)
      } else {
        onChange(selected as TAPIMachine[])
      }
    } else {
      if (single === true) {
        setState({ selectedMachineIDs: [selected] })
      } else {
        setState({
          selectedMachineIDs: selected,
        })
      }
    }
  }
  const getValue = (val: number | number[] | TAPIMachine | TAPIMachine[]) => {
    if (typeof val === 'number') {
      const machine =
        machines.find(x => x.pointID === (val as number)) ?? undefined
      return machine
    } else if (Array.isArray(val)) {
      const machs = machines.filter(x => (val as number[]).includes(x.pointID))
      return machs
    } else {
      return val
    }
  }
  return (
    <VirtualSelectFilter
      label="Machine"
      htmlFor={id ?? 'machine'}
      id={id ?? 'machine'}
      required={required}
      multiple={!single}
      value={getValue(value)}
      renderValue={(selected: TAPIMachine) =>
        machines.findIndex(x => x.pointID === selected.pointID) !== -1
          ? machines.find(x => x.pointID === selected.pointID)?.machineName
          : ''
      }
      options={machines}
      onChange={handleMachineChange}
    ></VirtualSelectFilter>
  )
}
