import React, { useCallback, useEffect, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import {
  Box,
  createTheme,
  Grid,
  ThemeProvider,
  Typography,
} from '@material-ui/core'
import moment from 'moment'
import { stringify } from 'query-string'
import { encodeQueryParams } from 'use-query-params'
import AppLayout from 'components/AppLayout'
import { ExportButton } from 'components/buttons'
import ViewReportButton from 'components/buttons/ViewReportButton'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import { IReportParametersCommonExceptions } from 'components/ReportViewer/TelerikReportViewer'
import {
  compactReportParams,
  reportViewerQueryParamConfig,
} from 'components/ReportViewerFromURLParams'
import SEO from 'components/SEO'
import {
  CommonExceptionsTable,
  tableFormatter,
} from 'components/tables/CommonExceptions'
import APIService from 'services/api-service'
import { exportData } from 'services/export-service'
import { getSelectedCustomerIDs } from 'services/selection-service'
import coreTheme from '../../components/theme'
import { useProfile } from 'Contexts/ProfileContext'
import { mapToPageParameters } from 'components/FilterAccordion/Utilities'
import FilterAccordion, {
  TAccordionFilterValues,
} from 'components/FilterAccordion'
import {
  DateFilterSet,
  OptionsFilterSet,
  OrganizationalFilterSet,
} from 'components/FilterAccordion/types'
import { CancellablePromise } from 'utils/CancellablePromise'
import { useNavigate } from 'react-router-dom'

interface OwnProps {
  onError?: (_err: Error) => void // passed in from layout wrapper
  offline?: boolean
}
let isInitialLoad = true

const initialStartDate = moment().subtract(90, 'days').toDate()
const initialEndDate = moment().toDate()

const defaultFilterValues = {
  startDate: initialStartDate,
  endDate: initialEndDate,
}

const defaults = {
  page: 1,
  pageSize: 10,
  orderConfig: [{ field: 'totalSamples', dir: 'desc' }],
  startDate: initialStartDate,
  endDate: initialEndDate,
  selectedDivisionIDs: [],
  selectedCustomerIDs: [],
  selectedPlants: [],
  exportFormat: '',
  searching: false as boolean,
  order: '' as string,
  orderBy: '' as string,
}

const theme = createTheme(
  {
    overrides: {
      MuiGrid: {
        root: {
          justifyContent: 'flex-end',
          [coreTheme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
          },
        },
      },
    },
  },
  coreTheme
)

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

const CommonExceptionsReport: React.FC<OwnProps> = ({
  onError,
  offline,
}: OwnProps) => {
  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  const [state, setState] = useState({
    ...defaults,
    dataLoaded: false,
    data: [],
    loadedDefaults: false,
  })

  const [dataTotals, setDataTotals] = useState({
    totalSamples: 0,
    totalAlarmedSamples: 0,
    totalAlarmedMachines: 0,
    ferrousWear: 0,
    wearMetals: 0,
    particleCount: 0,
    water: 0,
    viscosity: 0,
    degradation: 0,
    other: 0,
  })

  const navigate = useNavigate()

  const getTotal = useCallback(
    (field: string) =>
      state.data?.map(x => x[field]).reduce((prev, cur) => prev + cur, 0),
    [state.data]
  )

  useEffect(() => {
    setDataTotals({
      totalSamples: getTotal('totalSamples'),
      totalAlarmedSamples: getTotal('totalAlarmedSamples'),
      totalAlarmedMachines: getTotal('totalAlarmedMachines'),
      ferrousWear: getTotal('ferrousWear'),
      wearMetals: getTotal('wearMetals'),
      particleCount: getTotal('particleCount'),
      water: getTotal('water'),
      viscosity: getTotal('viscosity'),
      degradation: getTotal('degradation'),
      other: getTotal('other'),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.data])

  useEffect(() => {
    return () => {
      isInitialLoad = true
    }
  }, [])

  const fetchSamples = useCallback(
    async variables => {
      setState(prev => ({ ...prev, dataLoaded: false }))
      let promise
      try {
        promise = APIService.getCommonExceptions(variables)
        ongoingCancellablePromises.push(promise)
        const { analytics } = await promise
        setState(prev => ({ ...prev, data: analytics.exceptions }))
      } catch (ex) {
        onError(ex)
      } finally {
        ongoingCancellablePromises.filter(p => p != promise)
        setState(prev => ({ ...prev, dataLoaded: true }))
      }
    },
    [onError]
  )

  const performSearch = useCallback(() => {
    if (!validateForm()) return
    const variables = {
      startDate: state.startDate,
      endDate: state.endDate,
      customerIDs: getSelectedCustomerIDs(
        profileContext.profile.customers,
        profileContext.profile.divisions,
        state.selectedDivisionIDs,
        state.selectedCustomerIDs
      ),
      plantIDs: state.selectedPlants,
      orderList: state.orderConfig.map(x => x.dir),
      orderByList: state.orderConfig.map(x => x.field),
    }
    fetchSamples(variables)
  }, [
    fetchSamples,
    state.endDate,
    state.orderConfig,
    state.selectedCustomerIDs,
    state.selectedDivisionIDs,
    state.selectedPlants,
    state.startDate,
  ])

  const profileContext = useProfile()

  useEffect(() => {
    if (profileContext.dependentData.filters.minimumFilterOptionsLoaded) {
      setState(prev => ({
        ...prev,
        selectedCustomerIDs:
          profileContext.dependentData.filters.initialFilterState.customers.map(
            c => c.custID
          ),
        selectedDivisionIDs:
          profileContext.dependentData.filters.initialFilterState.divisions.map(
            d => d.iD
          ),
        loadedDefaults: true,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext.dependentData.filters.minimumFilterOptionsLoaded])

  useEffect(() => {
    if (!state.dataLoaded && state.loadedDefaults) {
      performSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dataLoaded, state.loadedDefaults])

  useEffect(() => {
    if (state.searching) {
      unstable_batchedUpdates(() => {
        performSearch()
        setState(prev => ({ ...prev, searching: false }))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searching])

  const handleResetClick = () => {
    setState(prev => ({
      ...prev,
      ...defaults,
      count: 0,
      selectedCustomerIDs:
        profileContext.dependentData.filters.initialFilterState.customers.map(
          c => c.custID
        ),
      selectedDivisionIDs:
        profileContext.dependentData.filters.initialFilterState.divisions.map(
          d => d.iD
        ),
      dataLoaded: false,
    }))
  }

  const validateForm = () =>
    moment(state.startDate, 'MM/DD/YYYY').isValid() &&
    moment(state.endDate, 'MM/DD/YYYY').isValid()

  const handleExportFormatChange = async (_event, format) => {
    let promise

    try {
      promise = APIService.getCommonExceptions({
        startDate: state.startDate,
        endDate: state.endDate,
        customerIDs: getSelectedCustomerIDs(
          profileContext.profile.customers,
          profileContext.profile.divisions,
          state.selectedDivisionIDs,
          state.selectedCustomerIDs
        ),
        plantIDs: state.selectedPlants,
        orderList: state.orderConfig.map(x => x.dir),
        orderByList: state.orderConfig.map(x => x.field),
      })

      ongoingCancellablePromises.push(promise)
      const { analytics } = await promise
      debugger
      const formatted = tableFormatter(
        analytics.exceptions,
        profileContext.dependentData.userDetails.isDivisionUser
      )
      exportData('common-exceptions', formatted, format.key)
    } catch (ex) {
      onError(ex)
    } finally {
      ongoingCancellablePromises.filter(p => p != promise)
    }
  }

  const getReportQueryParams = () => {
    const {
      selectedDivisionIDs,
      selectedCustomerIDs,
      selectedPlants,
      startDate,
      endDate,
      order,
      orderBy,
    } = state

    const reportParams: IReportParametersCommonExceptions = {
      Customers: getSelectedCustomerIDs(
        profileContext.profile.customers,
        profileContext.profile.divisions,
        selectedDivisionIDs,
        selectedCustomerIDs
      ),
      Plants: selectedPlants.length === 0 ? [-1] : selectedPlants,
      To: endDate,
      From: startDate,
      SortColumn: orderBy,
      SortDirection: order,
      CorporateContactID: 0,
    }

    return encodeQueryParams(reportViewerQueryParamConfig, {
      dbg: false,
      r: 'commonExceptions',
      ...compactReportParams(reportParams),
    })
  }

  const handleSortChange = event => {
    const isFieldRemoved = state.orderConfig.length > event.sort.length
    let isLastFieldRemoved = false

    if (isFieldRemoved) {
      const fieldRemoved = state.orderConfig.filter(
        x => !event.sort.includes(x)
      )[0]

      isLastFieldRemoved =
        state.orderConfig.indexOf(fieldRemoved) === state.orderConfig.length - 1
    }

    setState(prev => ({
      ...prev,
      orderConfig: [...event.sort],
      dataLoaded: !(event.sort.length > 0 && !isLastFieldRemoved),
      searching:
        (event.sort.length > 0 && !isLastFieldRemoved) || !event.sort?.length,
    }))
  }

  const handleFilterSubmission = (data: TAccordionFilterValues) => {
    const mappedParameters = mapToPageParameters(data)

    setState(prev => ({
      ...prev,
      ...mappedParameters,
      page: 1,
      searching: true,
      samplesLoaded: false,
    }))
  }

  if (
    isInitialLoad &&
    ((profileContext.profile.divisions?.length > 0 &&
      state.selectedDivisionIDs.length === 0) ||
      (profileContext.profile.customers?.length > 0 &&
        state.selectedCustomerIDs.length === 0))
  ) {
    isInitialLoad = false
    return null
  }

  return (
    <>
      <SEO title="Common Exceptions Report" />
      <Box mb={2}>
        <Typography variant="h1">Common Exceptions</Typography>
      </Box>
      {offline ? <OfflineBanner /> : <></>}
      <FilterAccordion
        pageName="Common Exceptions"
        defaultValues={defaultFilterValues}
        onSubmit={handleFilterSubmission}
        onReset={handleResetClick}
        filters={{
          options: [OptionsFilterSet.Prefiltering],
          date: [DateFilterSet.FromDate, DateFilterSet.ToDate],
          organization: [
            OrganizationalFilterSet.Divisions,
            OrganizationalFilterSet.Customers,
            OrganizationalFilterSet.Plants,
          ],
          machine: [],
          lubricant: [],
          sample: [],
          miscellaneous: [],
        }}
      />
      <form>
        <ThemeProvider theme={theme}>
          <Grid
            container
            direction="row"
            xs={12}
            spacing={1}
            style={{ marginTop: '15px' }}
          >
            <Grid container item direction="row" spacing={1}>
              <Grid item style={{ maxWidth: 242, marginLeft: 15 }}>
                <ViewReportButton
                  disabled={!state.data.length}
                  url={`/reports/viewer?${stringify(getReportQueryParams())}`}
                />
              </Grid>

              <Grid item>
                <ExportButton
                  disabled={!state.data.length}
                  onChange={handleExportFormatChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
      </form>

      <Box mt={4}>
        {!state.dataLoaded ? (
          <Loader />
        ) : (
          <CommonExceptionsTable
            data={state.data}
            startDate={state.startDate}
            endDate={state.endDate}
            orderConfig={state.orderConfig}
            onSortChange={handleSortChange}
          />
        )}
      </Box>
    </>
  )
}

export default function Page() {
  return (
    <AppLayout tab="reports">
      <CommonExceptionsReport />
    </AppLayout>
  )
}
