import React, { useContext, useState } from 'react'

export interface TSelectionContext {
  fromDate?: Date
  setFromDate: (date: Date) => void
  toDate?: Date
  setToDate: (date: Date) => void
}

export const useSelections = () => {
  return useContext(SelectionContext)
}

export const SelectionContext = React.createContext<TSelectionContext>(
  {} as TSelectionContext
)
SelectionContext.displayName = 'SelectionContext'

export const SelectionContextProvider: React.FC = props => {
  const [fromDate, setFromDate] = useState<Date>()
  const [toDate, setToDate] = useState<Date>()

  return (
    <SelectionContext.Provider
      value={{
        fromDate,
        setFromDate,
        toDate,
        setToDate,
      }}
    >
      {props.children}
    </SelectionContext.Provider>
  )
}
