import React, { useState } from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
} from '@material-ui/core'
import { getAlertSeverityColor } from './FinancialIssuesModal'

interface OwnProps {
  issue: any
}

export const ReportCardRowSalesOrder: React.FC<OwnProps> = ({
  issue,
}: OwnProps) => {
  return (
    <Card
      //key={sample.labID}
      variant="outlined"
      style={{ marginBottom: '10px', marginTop: '20px' }}
    >
      <CardHeader title={issue.financialID} />
      <CardContent>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6} md={3}>
            Customer Name:
          </Grid>
          <Grid item xs={6} md={9}>
            {issue.customerName}
          </Grid>
          <Grid item xs={6} md={3}>
            Plant Name:
          </Grid>
          <Grid item xs={6} md={3}>
            {issue.plantName}
          </Grid>
          <Grid item xs={6} md={3}>
            Reason:
          </Grid>
          <Grid item xs={6} md={3}>
            {issue.reason}
          </Grid>
          <Grid item xs={6} md={3}>
            Due Date:
          </Grid>
          <Grid item xs={6} md={3}>
            {issue.dueDate}
          </Grid>
          <Grid item xs={6} md={3}>
            Available Balance:
          </Grid>
          <Grid item xs={6} md={3}>
            {issue.availableBalance}
          </Grid>
          <Grid item xs={6} md={3}>
            Upcoming Spending:
          </Grid>
          <Grid item xs={6} md={3}>
            {issue.nextMonthProjectedSpending}
          </Grid>
          <Grid item xs={6} md={3}>
            Alert:
          </Grid>
          <Grid item xs={6} md={3}>
          <span                 style={{
                  color: getAlertSeverityColor(
                    issue.issue === 'OverBudget' ||
                    issue.issue === 'PastDue'
                      ? 'error'
                      : 'warning'
                  ),
                }}>{ 
                    issue.issue === 'PastDue' ? 'Past Due' : issue.issue === 'DueSoon' ? 'Due Soon' : issue.issue
                    }</span>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
