import debounce, { Cancelable } from '@material-ui/core/utils/debounce'
import { borderColor } from '@mui/system'
import React, { useRef, useState } from 'react'
import { FaBullseye } from 'react-icons/fa'
import { v4 as uuid } from 'uuid'
import * as Styles from './index.module.css'

type TProps = {
  label?: string
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  debounce?: number
  onBlur?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  value?: string
  labelColor?: string
  borderColor?: string
  textAreaProps?: React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >
}
export const FancyTextArea = React.forwardRef<HTMLTextAreaElement, TProps>(
  (props: TProps, ref) => {
    const [labelState, setLabelState] = useState({
      focused: false,
    })

    const [textAreaState, setTextAreaState] = useState({
      value: props.value ?? '',
    })

    const debounceChangeRef = useRef<
      ((event: React.ChangeEvent<HTMLTextAreaElement>) => void) & Cancelable
    >(null)
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (debounceChangeRef.current) {
        debounceChangeRef.current.clear()
      }

      if (!!props.onChange) {
        let func = props.onChange
        if (props.debounce) {
          debounceChangeRef.current = func = debounce(
            props.onChange,
            props.debounce
          )
        }

        func(event)
      }

      setTextAreaState(prev => ({ ...prev, value: event.target.value }))
    }

    const textArea =
      (ref as React.MutableRefObject<HTMLTextAreaElement>) ??
      useRef<HTMLTextAreaElement>(null)
    const textAreaStyle = {
      height: !!textAreaState.value
        ? textArea.current?.scrollHeight +
          (textArea.current?.offsetHeight - textArea.current?.clientHeight) +
          'px'
        : '4rem',
      minHeight: '4rem',
    }

    const areaID = uuid()
    const labelClass = `${labelState.focused ? Styles.Focused : ''} ${
      labelState.focused || !!textAreaState.value ? Styles.Shifted : ''
    }`

    const areaClass = `${labelState.focused ? Styles.Focused : ''}`
    return (
      <div className={Styles.TextArea}>
        {props.label && (
          <label
            className={labelClass}
            htmlFor={areaID}
            style={{ color: labelState.focused ? props.labelColor : undefined }}
          >
            {props.label}
          </label>
        )}
        <textarea
          {...props.textAreaProps}
          ref={textArea}
          style={{
            borderColor: labelState.focused ? props.borderColor : undefined,
            ...textAreaStyle,
          }}
          className={areaClass}
          onFocus={() => setLabelState(prev => ({ ...prev, focused: true }))}
          onBlur={event => {
            setLabelState(prev => ({ ...prev, focused: false }))
            if (props.onBlur) {
              props.onBlur(event)
            }
          }}
          onChange={handleChange}
          value={props.value}
          id={areaID}
        />
      </div>
    )
  }
)
