import React, { ReactNode } from 'react'
import { Link } from '@material-ui/core'

type TProps = {
  children?: ReactNode
  mailto: string
}

const EmailLinkButton = ({ mailto, children }: TProps) => {
  return (
    <Link
      href="#"
      onClick={e => {
        window.location.href = mailto
        e.preventDefault()
      }}
    >
      {children}
    </Link>
  )
}

export default EmailLinkButton
