import { Card, CardContent, CardHeader, Grid } from '@material-ui/core'
import { useProfile } from 'Contexts/ProfileContext'
import moment from 'moment'
import React from 'react'

interface Props {
    data: any
}

const MobileCard: React.FC<Props> = ({ data }: Props) => {
    const profileContext = useProfile()

    const renderCurrency = (value: number) => (
        <>
            {Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(Number(value.toFixed(2)))}
        </>
    )

    return (
        <Card variant="outlined" style={{ marginBottom: '10px', marginTop: '10px' }}>
            <CardContent>
                <Grid container direction="row" spacing={1}>
                    <Grid item xs={6} md={3}>
                        Status:
                    </Grid>
                    <Grid item xs={6} md={3}>
                        {data.status}
                    </Grid>
                    <Grid item xs={6} md={3}>
                        Customer Name:
                    </Grid>
                    <Grid item xs={6} md={3}>
                        {profileContext.profile.customers?.map(x => x.custName)}
                    </Grid>
                    <Grid item xs={6} md={3}>
                        Invoice Number:
                    </Grid>
                    <Grid item xs={6} md={3}>
                        {data.invoiceNumber}
                    </Grid>
                    <Grid item xs={6} md={3}>
                        Due Date:
                    </Grid>
                    <Grid item xs={6} md={3}>
                        {moment(data.dueDate).format('l')}
                    </Grid>
                    <Grid item xs={6} md={3}>
                        Date:
                    </Grid>
                    <Grid item xs={6} md={3}>
                        {moment(data.date).format('l')}
                    </Grid>
                    <Grid item xs={6} md={3}>
                        Amount Due:
                    </Grid>
                    <Grid item xs={6} md={3}>
                        {renderCurrency(data.amountDue)}
                    </Grid>
                    <Grid item xs={6} md={3}>
                        Amount:
                    </Grid>
                    <Grid item xs={6} md={3}>
                        {renderCurrency(data.amount)}
                    </Grid>
                    <Grid item xs={6} md={3}>
                        PO:
                    </Grid>
                    <Grid item xs={6} md={3}>
                        {data.customerPONumber}
                    </Grid>
                </Grid>
            </CardContent>
        </Card >
    )
}

export default MobileCard
