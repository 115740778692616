import React from 'react'
import { FaChrome, FaEdge, FaFirefox, FaSafari } from 'react-icons/fa'
import { Box, Grid, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import LargeIcon from './LargeIcon'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.up('md')]: {
        maxWidth: theme.breakpoints.width('sm'),
      },
    },
  })
)
export const IncompatibleBrowser = () => {
  const styles = useStyles()
  const iconProps = {
    style: {
      fontSize: '3rem',
      color: 'inherit',
    },
  }
  return (
    <Box textAlign="center" p={4} className={styles.root}>
      <LargeIcon>📠</LargeIcon>
      <Box mb={3}>
        <Typography variant="h2">Your browser is too... classic.</Typography>
      </Box>
      <Box my={3}>
        <Typography>
          Try using a more modern browser like one of these:
        </Typography>
      </Box>
      <Grid container spacing={4} justify="center" alignContent="space-between">
        <Grid item>
          <a href="https://www.mozilla.org/en-US/firefox/" {...iconProps}>
            <FaFirefox />
          </a>
        </Grid>
        <Grid item>
          <a href="https://www.google.com/chrome/" {...iconProps}>
            <FaChrome />
          </a>
        </Grid>
        <Grid item>
          <a href="https://www.apple.com/safari/" {...iconProps}>
            <FaSafari />
          </a>
        </Grid>
        <Grid item>
          <a
            href="https://www.microsoft.com/en-us/windows/microsoft-edge"
            {...iconProps}
          >
            <FaEdge />
          </a>
        </Grid>
      </Grid>
    </Box>
  )
}

export default IncompatibleBrowser
