import moment from 'moment'

import { TAPIMachineException } from 'types/api'

export const tableFormatter = (
  machines: TAPIMachineException[],
  hasDivisions: boolean
): string[][] => {
  const headerValues = [
    ...(hasDivisions ? ['Division Name'] : []),
    'Customer Name',
    'Plant Name',
    'Machine Number',
    'Machine Name',
    'Equipment ID',
    'Last Sample Date',
    'Last Lab No',
    'Tests',
    'Test Packages',
    'Lubricant',
    'Wear Metal',
    'Ferrous Wear',
    'Particle Count',
    'Water',
    'Viscosity',
    'Degradation',
    'Other',
  ]

  const values = machines.map(machine => [
    ...(hasDivisions ? [machine.divisionName] : []),
    machine.customerName,
    machine.plantName,
    machine.machineNumber?.toString(),
    machine.machineName,
    machine.custEquID,
    moment(machine.lastSampleDate).format('l'),
    machine.labID?.toString(),
    machine.tests,
    machine.testPackages,
    machine.lubricant,
    machine.wearMetalCount?.toString(),
    machine.ferrousCount?.toString(),
    machine.particleCountCount?.toString(),
    machine.waterCount?.toString(),
    machine.viscosityCount?.toString(),
    machine.degradationCount?.toString(),
    machine.otherCount?.toString(),
  ])

  return [headerValues, ...values]
}
