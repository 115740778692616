import React from 'react'
import { Checkbox, Grid } from '@material-ui/core'

import MobileCardContent from 'components/MobileCardContent'
import MobileCardItem from 'components/MobileCardItem'

import {
  RenderRoute,
  RenderScheduleMonthCheckbox,
  RenderTestGroupsAsStringCommas,
} from '../KendoUIGridRenders'

import { scheduleColumns } from './ScheduleColumns'

interface OwnProps {
  schedule: any
  readOnly: boolean
  showDivisions: boolean
  selected: boolean
  onScheduleChange: (_event, _schedule) => void
  onSelectedScheduleChange: (_event: any, _scheduleID: any) => void
}

const MobileCard: React.FC<OwnProps> = ({
  schedule,
  readOnly,
  showDivisions,
  selected,
  onScheduleChange,
  onSelectedScheduleChange,
}: OwnProps) => {
  return (
    <MobileCardContent title={schedule.machine.plantName}>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12} sm={12}>
          Selected
          <Checkbox
            checked={selected}
            onChange={event => onSelectedScheduleChange(event, schedule.mSID)}
          />
        </Grid>

        {showDivisions && (
          <MobileCardItem title="Division Name">
            {schedule.machine.plant.customer.division.name}
          </MobileCardItem>
        )}
        <MobileCardItem title="Customer Name">
          {schedule.machine.plant.customer.custName}
        </MobileCardItem>
        <MobileCardItem title="Plant Name">
          {schedule.machine.plant.plantName}
        </MobileCardItem>
        <MobileCardItem title="Mach No.">
          {schedule.machine.machNo}
        </MobileCardItem>
        <MobileCardItem title="Machine Name">
          {schedule.machine.machineName}
        </MobileCardItem>
        <MobileCardItem title="Equipment ID">
          {schedule.machine.custEquID}
        </MobileCardItem>
        <MobileCardItem title="Route">
          <RenderRoute dataItem={schedule.machine} field="route" />
        </MobileCardItem>
        <MobileCardItem title="Test Package">
          {schedule.testGroup.testGroupName}
        </MobileCardItem>
        <MobileCardItem title="Tests">
          <RenderTestGroupsAsStringCommas
            dataItem={schedule.testGroup}
            field="testGroupDefs"
          />
        </MobileCardItem>

        <Grid
          container
          style={{
            border: 1,
            borderWidth: 1,
            borderColor: 'lightgray',
            borderStyle: 'solid',
            padding: '15px',
            marginTop: '15px',
          }}
        >
          <Grid container xs={12} sm={12} alignItems="center" justify="center">
            {scheduleColumns.slice(0, 1).map(column => (
              <div style={{ textAlign: 'center', display: 'grid' }}>
                <RenderScheduleMonthCheckbox
                  dataItem={schedule}
                  field={column.id}
                  readOnly={readOnly}
                  onScheduleChange={onScheduleChange}
                />
                <span>All Months</span>
              </div>
            ))}

            {scheduleColumns.slice(1, 7).map(column => (
              <div style={{ textAlign: 'center' }}>
                <RenderScheduleMonthCheckbox
                  dataItem={schedule}
                  field={column.id}
                  readOnly={readOnly}
                  onScheduleChange={onScheduleChange}
                />
                <span>{column.text}</span>
              </div>
            ))}

            {scheduleColumns.slice(7).map(column => (
              <div style={{ textAlign: 'center' }}>
                <RenderScheduleMonthCheckbox
                  dataItem={schedule}
                  field={column.id}
                  readOnly={readOnly}
                  onScheduleChange={onScheduleChange}
                />
                <span>{column.text}</span>
              </div>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </MobileCardContent>
  )
}

export default MobileCard
