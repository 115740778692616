import { ReactText } from 'react'
import { toast } from 'react-toastify'
import { TInvoiceQuery, TShipmentInformation } from 'types/api'
import { serialize } from 'utils/api-utilities'
import { DistinctSort } from 'utils/ArrayMethods'
import { getIsInternalUserHeader } from 'utils/customer-utilities'

export const invoiceNumbersToDownloadLink = (
  invoiceNumberOrNumbers: string | number | string[] | number[],
  customerIDs: number[]
) => {
  const isArray = Array.isArray(invoiceNumberOrNumbers)
  if (
    !customerIDs ||
    !invoiceNumberOrNumbers ||
    (isArray && invoiceNumberOrNumbers.length === 0)
  )
    return null

  const stringifiedNumbers = isArray
    ? (invoiceNumberOrNumbers as number[]).map(i => i.toString())
    : [invoiceNumberOrNumbers.toString()]
  const query: Partial<TInvoiceQuery> = {
    customerIDs,
    invoiceNumbers: stringifiedNumbers,
  }

  return new URL(
    `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Invoices/pdf` +
      serialize(query)
  )
}

const handleInvoiceDownloadError = (
  err: Error | undefined,
  toastToDismiss: ReactText
) => {
  if (toastToDismiss) toast.dismiss(toastToDismiss)

  if (!err) {
    toast.error(<span>Download failed :(.</span>, {
      containerId: 'global',
    })
    return
  }
  const error = err as Error
  toast.error(<span>Download failed: {error.message}</span>, {
    containerId: 'global',
  })
}

export const downloadInvoiceUrlWithNotifications = (
  url: URL,
  toastText: string,
  fileName: string
) => {
  const infoToast = toast.info(<span>Downloading {toastText}</span>, {
    containerId: 'global',
  })

  return fetch(url, {
    headers: { ...getIsInternalUserHeader(), Accept: 'application/pdf' },
  })
    .then(response => {
      if (!response.ok) {
        if (response.statusText)
          return Promise.reject(
            new Error(`${response.status}: ${response.statusText}`)
          )
        return Promise.reject()
      }

      response
        .blob()
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.download = `${fileName}.pdf`
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .then(() => {
          toast.dismiss(infoToast)
          toast.success(<span>Downloaded {toastText}!</span>, {
            containerId: 'global',
            autoClose: 1500,
          })
        })
        .catch(err => handleInvoiceDownloadError(err, infoToast))
    })
    .catch(err => handleInvoiceDownloadError(err, infoToast))
}

export const getOrderInvoiceNumberLink = (
  order: TShipmentInformation,
  customerIDs: number[]
): [element: JSX.Element, clickable: boolean] => {
  const invoiceNumbers = order.orderDetails
    .map(od => od.invoiceNo)
    .filter(i => !!i)
  let distinctInvoiceNumbers = DistinctSort(invoiceNumbers)
  const hasSingleInvoice = distinctInvoiceNumbers.length === 1
  const hasMultipleInvoices = distinctInvoiceNumbers.length > 1
  const url = invoiceNumbersToDownloadLink(distinctInvoiceNumbers, customerIDs)

  const onClick = (
    toastText: string,
    fileName: string,
    event: React.MouseEvent
  ) => {
    event.stopPropagation()

    if (!url) return
    downloadInvoiceUrlWithNotifications(url, toastText, fileName)
  }

  let element: JSX.Element
  let clickable = false
  if (hasSingleInvoice) {
    const invoiceNumber = distinctInvoiceNumbers[0].toString()
    element = (
      <a
        onClick={e => onClick(`invoice ${invoiceNumber}`, invoiceNumber, e)}
        style={{
          color: 'rgb(98, 0, 238)',
          textDecoration: 'none',
        }}
      >
        {invoiceNumber}
      </a>
    )
    clickable = true
  } else if (hasMultipleInvoices) {
    element = (
      <a onClick={e => onClick('invoices', 'MultipleInvoices', e)}>Multiple</a>
    )
    clickable = true
  } else {
    element = <span>N/A</span>
  }

  return [element, clickable]
}
