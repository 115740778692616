import React from 'react'
import { FormControl, TextField } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

import coreTheme from '../../theme'

const theme = createTheme(
  {
    overrides: {
      MuiFormControl: {
        root: {
          [coreTheme.breakpoints.down('sm')]: {
            // width: '100%'
            width: '225px',
          },
          [coreTheme.breakpoints.up('sm')]: {
            width: '225px',
          },
        },
      },
      MuiFormLabel: {
        root: {
          color: '#222',
          zIndex: 99,
        },
      },
      MuiSelect: {
        root: {
          [coreTheme.breakpoints.down('sm')]: {
            // width: '100%'
            width: '225px',
          },
          [coreTheme.breakpoints.up('sm')]: {
            width: '225px',
          },
        },
      },
      MuiCheckbox: {
        root: {
          color: '#222',
        },
      },
    },
  },
  coreTheme
)

export const TextFieldFilter = props => {
  const { required, ...other } = props

  return (
    <ThemeProvider theme={theme}>
      <FormControl required={required}>
        <TextField  {...other}/>
      </FormControl>
    </ThemeProvider>
  )
}
