import React from 'react'
import { Grid } from '@material-ui/core'
import MobileCardContent from 'components/MobileCardContent'
import MobileCardItem from 'components/MobileCardItem'
import {
    RenderDate,
    RenderLubeCondition,
    RenderMachineCondition,
    RenderMachineName,
    RenderRoute,
    RenderLabNo,
    RenderMachineType
  } from '../tables/KendoUIGridRenders'

interface OwnProps {
    sample: any
    machineTypeOptions: any[]
}

  const MobileCard: React.FC<OwnProps> = ({ sample, machineTypeOptions }: OwnProps ) => {
    return (
      <MobileCardContent title={sample.plantName}>
        <Grid container direction="row" spacing={1}>
          <MobileCardItem title="Customer Review">
              {sample.reviewed ? 'Yes' : 'No'}
          </MobileCardItem>
          <MobileCardItem title="Date Reviewed">
              <RenderDate dataItem={sample} field="custReviewDte" />
          </MobileCardItem>
          <MobileCardItem title="Date Last Sampled">
              <RenderDate dataItem={sample} field="sampleDate" />
          </MobileCardItem>
          <MobileCardItem title="Route">
            <RenderRoute dataItem={sample} field="routeNo" />
          </MobileCardItem>
          <MobileCardItem title="Plant Name">
              {sample.machine.plant.plantName}
          </MobileCardItem>
          <MobileCardItem title="Mach No.">
            {sample.machine.machNo}
          </MobileCardItem>
          <MobileCardItem title="Machine Name">
            <RenderMachineName dataItem={sample} field="machine.machineName" />
          </MobileCardItem>
          <MobileCardItem title="Equipment ID">
              {sample.machine.custEquID}
          </MobileCardItem>
          <MobileCardItem title="Mach">
              <RenderMachineCondition dataItem={sample} field="machCond" />
          </MobileCardItem>
          <MobileCardItem title="Lube">
            <RenderLubeCondition dataItem={sample} field="lubCond" />
          </MobileCardItem>
          <MobileCardItem title="Lab No.">
              <RenderLabNo dataItem={sample} field="labID" />
          </MobileCardItem>
          <MobileCardItem title="Machine Type">
            <RenderMachineType 
                dataItem={sample.machine}
                field="machine.machType"
                machineTypeOptions={machineTypeOptions}
            />
          </MobileCardItem>
          <MobileCardItem title="Lube Type">
            {sample.machine.lubricant.lubricantName}
          </MobileCardItem>
        </Grid>
      </MobileCardContent>
    )
  }

export default MobileCard