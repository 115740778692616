import React, { useImperativeHandle, useState } from 'react'
import { FormControlLabel, InputLabel, Switch } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

import coreTheme from '../../theme'

const theme = createTheme(
  {
    overrides: {
      MuiFormControlLabel: {
        root: {
          [coreTheme.breakpoints.down('sm')]: {
            // width: '100%'
            margin: 0,
            width: '225px',
          },
          [coreTheme.breakpoints.up('sm')]: {
            margin: 0,
            width: '225px',
          },
        },
      },
      MuiFormLabel: {
        root: {
          color: '#222',
          margin: 0,
          zIndex: 99,
        },
      },
    },
  },
  coreTheme
)

export type TRefSwitch = {
  title: string
  value1: any
  value1Text: string
  value2: any
  value2Text: string
  defaultChecked?: boolean
  onChange?: (val: any) => void
}

export const RefSwitch: React.FC<TRefSwitch> = React.memo(
  React.forwardRef((props, ref) => {
    const [selectedValue, setSelectedValue] = useState(
      props.defaultChecked ? props.value2 : props.value1
    )

    useImperativeHandle(ref, () => ({
      value: selectedValue,
    }))

    const onChange = () => {
      var newValue =
        selectedValue === props.value1 ? props.value2 : props.value1

      if (props.onChange) props.onChange(newValue)

      setSelectedValue(newValue)
    }

    const getID = () => {
      return `${props.title}-${props.value1Text}-${props.value2Text}`
    }

    return (
      <ThemeProvider theme={theme}>
        <FormControlLabel
          control={
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '0',
                marginBottom: '-10px',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <label
                style={{
                  fontSize: '1.1rem',
                  marginBottom: '-0.2rem',
                  cursor: 'pointer',
                }}
                htmlFor={getID()}
              >
                {props.title}
              </label>
              {/* <hr
                style={{
                  height: '1px',
                  border: 0,
                  background: 'rgba(0, 0, 0, 0.5)',
                  margin: '-7.5px 0 0 0',
                  width: '70%',
                }}
              /> */}
              <div>
                <InputLabel
                  htmlFor={getID()}
                  // onClick={() => setSelectedValue(props.value1)}
                  style={{
                    display: 'inline-block',
                    cursor: 'pointer',
                    margin: '0 0.3rem 0 0',
                    color: selectedValue === props.value1 ? '#FF6600' : 'black',
                  }}
                >
                  {props.value1Text}
                </InputLabel>
                <Switch
                  onChange={onChange}
                  defaultChecked={props.defaultChecked}
                  style={{ display: 'inline-block' }}
                  name={getID()}
                  id={getID()}
                  color="secondary"
                />
                <InputLabel
                  htmlFor={getID()}
                  // onClick={() => setSelectedValue(props.value2)}
                  style={{
                    display: 'inline-block',
                    cursor: 'pointer',
                    margin: '0 0 0 0.3rem',
                    color: selectedValue === props.value2 ? '#FF6600' : 'black',
                  }}
                >
                  {props.value2Text}
                </InputLabel>
              </div>
            </div>
          }
          label=""
          htmlFor={getID()}
          style={{ display: 'inline-block' }}
        />
      </ThemeProvider>
    )
  }),
  (p, n) => true
)
