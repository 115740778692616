import { Card, CardContent, Fade, Popper } from '@material-ui/core'
import { useThemeContext } from 'Contexts/ThemeContext'
import { CheckboxListPicker } from 'components/Pickers/CheckboxListPicker/CheckboxListPicker'
import React, { useState } from 'react'
import { TSpendingEntity } from 'types/api'
import { OverallSpendingChart } from '..'
import * as styles from './index.module.css'
import { ProjectionPicker } from './Pickers/ProjectionBasisPicker'
import { IntervalPicker } from './Pickers/IntervalPicker'
import InfoIcon from '@mui/icons-material/Info'

import * as Styles from './index.module.css'
import { InformationIcon } from 'components/InformationIcon'

type TOverallSpendingWithPickerProps = {
  spendingData: TSpendingEntity
  startDate: Date
  endDate: Date
}

export const OverallSpendingChartWithPicker: React.FC<TOverallSpendingWithPickerProps> =
  props => {
    const [interval, setInterval] = React.useState<'Monthly' | 'Quarterly'>(
      'Monthly'
    )

    const [projectionBasis, setProjectionBasis] = React.useState<
      'Scheduled' | 'Historical'
    >('Scheduled')

    const classes = useThemeContext()

    return (
      <>
        <Card
          variant="outlined"
          className={classes.card}
          style={{
            display: 'inline-flex',
            flexDirection: 'column',
            height: '550px',
            minWidth: '450px',
            maxWidth: '1100px',
            width: '90%',
          }}
        >
          <CardContent
            className={classes.cardContent}
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              className={classes.cardHeader}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {`${props.spendingData.name} Cumulative Spending Report`}{' '}
              <InformationIcon>
                <>
                  <h5>Spending Overview</h5>
                  <hr />
                  <p>
                    The Accumulated Spending Overview chart shows the previous
                    months of spending, the current month's spending, and a
                    projection of the next months of spending, spanning the
                    range selected in the filter accordion. This chart may be
                    adjusted to aggregate data by month or by quarter. Spending
                    projections may be adjusted to predict spending based upon
                    machine schedule data or historical sampling data.
                  </p>
                </>
              </InformationIcon>
            </div>

            <div
              style={{
                height: '100%',
                width: '100%',
                padding: '10px',
                flex: 90,
              }}
            >
              <OverallSpendingChart
                startDate={props.startDate}
                endDate={props.endDate}
                spendingData={props.spendingData}
                interval={interval}
                projectionBasis={projectionBasis}
                style={{ paddingBottom: '0 !important' }}
              />
            </div>

            <div
              style={{
                display: 'flex',
                flex: 10,
                margin: '0 10px 0 10px',
                padding: '0',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div style={{ display: 'flex', width: '100%' }}>
                <IntervalPicker
                  interval={interval}
                  onIntervalChange={e => setInterval(e)}
                />
                <ProjectionPicker
                  projectionBasis={projectionBasis}
                  onProjectionBasisChange={e => setProjectionBasis(e)}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </>
    )
  }
