import React from 'react'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

import coreTheme from 'components/theme'

const theme = createTheme(
  {
    overrides: {
      MuiInputBase: {
        root: {
          color: '#ffffff',
          width: '190px',
        },
        input: {
          color: '#ffffff',
          backgroundColor: '#a8afba',
          '&::placeholder': {
            color: '#ffffff',
          },
          borderRadius: 3,
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 30px #a8afba inset !important',
            WebkitTextFillColor: '#ffffff !important',
          },
        },
      },
      MuiFormLabel: {
        root: {
          color: '#eeeeee',
          zIndex: 99,
        },
      },
    },
  },
  coreTheme
)

const LandingTextField = props => {
  return (
    <ThemeProvider theme={theme}>
      <TextField {...props} />
    </ThemeProvider>
  )
}

export default LandingTextField
