import { Route, TCustomerTestGroupPricing, TListType } from 'types/api'
import { DatalessQuery } from './Query'

export class GetAllCustomerTestGroupPricingQuery extends DatalessQuery<
  TCustomerTestGroupPricing[]
> {
  public get Url(): string {
    return `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}/v1/Customer/TestGroupPricing/All`
  }
}
