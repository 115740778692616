import React from 'react'
import { Router } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import AppLayout from 'components/AppLayout'
import { ReportViewerFromURLParams } from 'components/ReportViewerFromURLParams'

export default function Page() {
  return (
    <AppLayout tab="reports">
      <QueryParamProvider {...{ path: '/' }}>
        <ReportViewerFromURLParams default />
      </QueryParamProvider>
    </AppLayout>
  )
}
