import { CheckboxListPicker } from 'components/Pickers/CheckboxListPicker/CheckboxListPicker'
import React, { useState } from 'react'
import { StackChart, TStackChartSerie } from 'components/Charts/StackChart'

import { Card, CardContent, Grid } from '@material-ui/core'
import { useThemeContext } from 'Contexts/ThemeContext'
import { InformationIcon } from 'components/InformationIcon'

interface TCompareSpendChart {
  title: string
  data: any[]
  showLegend: boolean
}

interface TSeriesSettings {
  name: string
  color: string
}

export const CompareSpendChart: React.FC<TCompareSpendChart> = (
  props: TCompareSpendChart
) => {
  const { data, ...rest } = props

  const theme = useThemeContext()

  const seriesSettings: TSeriesSettings[] = [
    { name: 'Current', color: theme.palette.charts.withinBudget },
    { name: 'Budget Remaining', color: theme.palette.charts.budget },
  ]

  const initialSeries: TStackChartSerie[] = []

  for (let i = 0; i < seriesSettings.length; i++) {
    initialSeries.push({
      values: data.map(x => x.values[i]),
      name: seriesSettings[i].name,
      color: seriesSettings[i].color,
    })
  }

  const [selectedSeries, setSelectedSeries] =
    useState<TStackChartSerie[]>(initialSeries)

  const [selectedCategories, setSelectedCategories] = useState<string[]>(
    data?.map(x => x.category)
  )

  const handleSelectedSeriesValuesChange = (categories: string[]) => {
    const indexCategories = data
      .map((c, i) => (categories.includes(c.category) ? i : null))
      .filter(x => x !== null)

    const updatedSeries: TStackChartSerie[] = []

    for (let i = 0; i < seriesSettings.length; i++) {
      updatedSeries.push({
        values: data
          .map(x => x.values[i])
          .map((x, i) => (indexCategories.includes(i) ? x : null))
          .filter(y => y != null),
        name: seriesSettings[i].name,
        color: seriesSettings[i].color,
      })
    }

    setSelectedSeries(updatedSeries)
    setSelectedCategories(categories)
  }

  return (
    <Card
      variant="outlined"
      className={theme.card}
      style={{
        display: 'inline-flex',
        flexDirection: 'column',
        minHeight: '500px',
        minWidth: '450px',
        maxWidth: '1100px',
        width: '90%',
      }}
    >
      <CardContent
        className={theme.cardContent}
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div
          className={theme.cardHeader}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {`${props.title}`}
          <InformationIcon>
            <h5>Purchase Order Spending</h5>
            <hr />
            <p>
              Compare purchase orders against one another and against their
              total budgeted value. The "Current" amount displayed is the total
              spending against the purchase order over the entirity of its
              lifetime. When reporting purchase order data, the chart does not
              account for the date range selected in the filter accordion. The
              date range will only affect the purchase orders that appear in the
              chart.
            </p>
          </InformationIcon>
        </div>

        <Grid
          container
          direction="row"
          style={{
            display: 'flex',
            flexWrap: 'wrap-reverse',
            height: '100%',
            minHeight: '450px',
            width: '100%',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          <Grid container alignItems="center" justify="center" md={3}>
            <CheckboxListPicker
              title="PO #"
              values={data.map(x => x.category)}
              onChange={handleSelectedSeriesValuesChange}
            />
          </Grid>
          <Grid
            container
            alignItems="center"
            justify="center"
            md={7}
            style={{ padding: '10px' }}
          >
            <StackChart
              dataCategories={selectedCategories}
              dataSeries={selectedSeries}
              labelsFormat="c0"
              {...rest}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
