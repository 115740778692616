import React, { useState } from 'react'
import { Box, Checkbox, Hidden, TablePagination } from '@material-ui/core'
import {
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'

import { TAPIMachineSchedule } from 'types/api'
import TableGrid from 'components/TableGrid'
import { useTableStyles } from 'utils/tableStyles'

import {
  RenderScheduleMonthCheckbox,
  RenderTestGroupsAsStringCommas,
} from '../KendoUIGridRenders'

import MobileCard from './MobileCard'
import { useCustomTableStyles } from './tableStyles'
import ReadOnlyDialog from 'components/Modals/ReadOnlyDialog'
import { scheduleColumns } from './ScheduleColumns'

interface OwnProps {
  readOnly: boolean
  offline?: boolean
  selectAllSchedules: boolean
  selectedScheduleIDs: any[]
  schedules: any[]
  classes: any
  orderConfig: any[]
  page: number
  pageSize: number
  count: number
  onPageChange?: (_event: GridPageChangeEvent) => void
  onSortChange?: (_event: GridSortChangeEvent) => void
  onPageChangeMobile: (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    _page: number
  ) => void
  onPageSizeChangeMobile?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >
  onSelectAllScheduleChange: (_event: any) => void
  onSelectedScheduleChange: (_event: any, _scheduleID: any) => void
  onScheduleChange: (_schedule: any) => void
}

export const ScheduleReportTable: React.FC<OwnProps> = ({
  offline,
  readOnly,
  selectAllSchedules,
  selectedScheduleIDs,
  schedules,
  orderConfig,
  page,
  pageSize,
  count,
  onPageChange,
  onSortChange,
  onPageChangeMobile,
  onPageSizeChangeMobile,
  onSelectAllScheduleChange,
  onSelectedScheduleChange,
  onScheduleChange,
}: OwnProps) => {
  const classes = useTableStyles()
  const customClasses = useCustomTableStyles()

  const showDivisions = schedules.some(s => s.machine.plant.customer.division)

  const [showReadOnlyDialog, setShowReadonlyDialog] = useState(false)

  const handleShowReadonlyDialog = () => {
    setShowReadonlyDialog(true)
  }

  const handleScheduleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    schedule: any
  ) => {
    if (readOnly) {
      handleShowReadonlyDialog()
      return
    }

    const newSchedule = Object.assign({}, schedule)

    const keyName: keyof TAPIMachineSchedule & string = event.target
      .name as keyof TAPIMachineSchedule & string

    const value: boolean = event.target.checked

    newSchedule[event.target.name] = value

    if (keyName === 'allMonths') {
      Object.keys(newSchedule).forEach(key => {
        if (typeof newSchedule[key] === 'boolean') {
          newSchedule[key] = value
        }
      })
    } else {
      const filteredKeys = Object.keys(newSchedule).filter(
        key => typeof newSchedule[key] === 'boolean' && key !== 'allMonths'
      ) as Array<keyof TAPIMachineSchedule & string>

      if (filteredKeys.every(key => newSchedule[key] === true)) {
        newSchedule.allMonths = true
      } else if (filteredKeys.some(key => newSchedule[key] === false)) {
        newSchedule.allMonths = false
      }
    }

    onScheduleChange(newSchedule)
  }

  const renderCheckboxHeader = _props => (
    <>
      <Checkbox
        checked={selectAllSchedules}
        onChange={onSelectAllScheduleChange}
      />
    </>
  )

  const renderCheckbox = props => {
    return (
      <td>
        <Checkbox
          checked={selectedScheduleIDs.indexOf(props.dataItem.mSID) !== -1}
          onChange={event =>
            onSelectedScheduleChange(event, props.dataItem.mSID)
          }
        />
      </td>
    )
  }

  return (
    <>
      <ReadOnlyDialog
        open={showReadOnlyDialog}
        onClose={() => setShowReadonlyDialog(false)}
      />
      {offline && (
        <Box mb={2}>Your last 100 reports are available while offline.</Box>
      )}
      <Hidden lgUp>
        {!schedules?.length && (
          <p style={{ textAlign: 'center' }}>No records available</p>
        )}
        {schedules.map(schedule => (
          <MobileCard
            selected={selectedScheduleIDs.indexOf(schedule.mSID) !== -1}
            onSelectedScheduleChange={onSelectedScheduleChange}
            showDivisions={showDivisions}
            key={schedule.mSID}
            schedule={schedule}
            readOnly={readOnly}
            onScheduleChange={handleScheduleChange}
          />
        ))}

        {!offline && (
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100, 250, 500]}
            component="div"
            count={count}
            rowsPerPage={pageSize}
            page={page > 0 ? page - 1 : page}
            onChangePage={onPageChangeMobile}
            onChangeRowsPerPage={onPageSizeChangeMobile}
          />
        )}
      </Hidden>

      <Hidden mdDown>
        <TableGrid
          clearSortingButtonId="schedule_report-clear_sorting_button"
          className={[classes.table, customClasses.table].join(' ')}
          offline={offline}
          data={schedules}
          sort={orderConfig}
          skip={(page - 1) * pageSize}
          take={pageSize}
          total={count}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
          expandField="expanded"
        >
          <Column cell={renderCheckbox} headerCell={renderCheckboxHeader} />

          {showDivisions && (
            <Column
              field="machine.plant.customer.division.name"
              title="Division"
            />
          )}
          <Column
            field="machine.plant.customer.custName"
            title="Customer Name"
          />
          <Column field="machine.plant.plantName" title="Plant Name" />
          <Column
            field="machine.route.routeNo"
            title="Route"
            cell={props => (
              <td>
                {props.dataItem.machine.route &&
                  `${props.dataItem.machine.route.routeNo} - ${props.dataItem.machine.route.name}`}
              </td>
            )}
          />
          <Column field="machine.machNo" title="Mach No." width={50} />
          <Column field="machine.machineName" title="Machine Name" />
          <Column field="machine.custEquID" title="Equipment ID" />

          <Column
            field="testGroup.testGroupName"
            title="Test Package"
            width={75}
          />

          <Column
            field="testGroupDefs"
            title="Tests"
            cell={props => (
              <RenderTestGroupsAsStringCommas
                {...props}
                dataItem={props.dataItem.testGroup}
              />
            )}
            sortable={false}
          />

          {scheduleColumns.map((column, index) => (
            <Column
              field={column.id}
              title={column.text}
              width={index === 0 ? 60 : 40}
              cell={props => (
                <RenderScheduleMonthCheckbox
                  {...props}
                  readOnly={readOnly}
                  onScheduleChange={handleScheduleChange}
                />
              )}
            />
          ))}
        </TableGrid>
      </Hidden>
    </>
  )
}
