import { Button } from '@material-ui/core'
import { createTheme, Theme, ThemeProvider } from '@material-ui/core/styles'

import coreTheme from '../theme'

const thisTheme = createTheme(
  {
    overrides: {
      MuiButton: {
        root: {
          [coreTheme.breakpoints.down('sm')]: {
            width: '200px',
          },
          [coreTheme.breakpoints.down('xs')]: {
            width: '100px',
          },
          [coreTheme.breakpoints.up('sm')]: {
            width: '225px',
          },
        },
      },
    },
  },
  coreTheme
)

type TProps = {
  onSearchClick?: () => void
  disabled?: boolean
  theme?: Theme
  id?: string
}

export default function SearchButton({
  theme,
  disabled,
  id,
  onSearchClick,
}: TProps) {
  return (
    <ThemeProvider theme={theme || thisTheme}>
      <Button
        id={id}
        disabled={disabled}
        onClick={onSearchClick ? onSearchClick : undefined}
        type={onSearchClick ? undefined : 'submit'}
        variant="contained"
        color="secondary"
      >
        Search
      </Button>
    </ThemeProvider>
  )
}
