import React from 'react'

import { TAPITestGroup } from 'types/api'

import { VirtualSelectFilter } from '../BaseFilters/VirtualSelectFilter'
import FilterTooltip from '../FilterTooltip/FilterTooltip'

type TProps = {
  single: boolean
  required: boolean
  testGroups: TAPITestGroup[]
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange?: (props: TAPITestGroup | TAPITestGroup[]) => void
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setState?: (props: any) => void
  tooltip?: boolean
  value?: TAPITestGroup
  id: string
}

export const TestPackageFilter = ({
  single,
  testGroups,
  required,
  onChange,
  setState,
  tooltip = true,
  value,
  id,
}: TProps) => {
  if (setState == null && onChange == null) return

  const handleTestGroupIDsChange = (
    testGroups: TAPITestGroup | TAPITestGroup[]
  ) => {
    if (onChange != null) {
      if (single === true) {
        onChange(testGroups as TAPITestGroup)
      } else {
        onChange(testGroups as TAPITestGroup[])
      }
    } else {
      if (single === true) {
        setState({
          selectedTestGroupID: (testGroups as TAPITestGroup)?.testGroupID,
        })
      } else {
        setState({
          selectedTestGroupIDs: (testGroups as TAPITestGroup[]).map(
            g => g.testGroupID
          ),
        })
      }
    }
  }
  const getValue = (
    val: number | number[] | TAPITestGroup | TAPITestGroup[]
  ) => {
    if (typeof val === 'number') {
      const testGroup =
        testGroups.find(x => x.testGroupID === (val as number)) ?? undefined
      return testGroup
    } else if (Array.isArray(val)) {
      const tGroups = testGroups.filter(x =>
        (val as number[]).includes(x.testGroupID)
      )
      return tGroups
    } else {
      return val
    }
  }
  const filter = (
    <VirtualSelectFilter
      label="Test Package"
      required={required}
      multiple={!single}
      htmlFor={id ?? 'testGroupIDs'}
      id={id ?? 'testGroupIDs'}
      value={getValue(value)}
      renderValue={(selected: TAPITestGroup) =>
        testGroups.find(group => group.testGroupID === selected.testGroupID)
          ?.testGroupName ?? ''
      }
      onChange={handleTestGroupIDsChange}
      options={testGroups}
      getOptionSelected={(option, value) =>
        option.testGroupID === value.testGroupID
      }
    ></VirtualSelectFilter>
  )

  return tooltip ? (
    <FilterTooltip title="The test or set of tests associated with a particular machine's testing plan.">
      {filter}
    </FilterTooltip>
  ) : (
    filter
  )
}
