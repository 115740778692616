import { StylesProvider } from '@material-ui/core'
import React from 'react'
import * as Styles from './index.module.css'

type TOwnProps = {
  onPick: (_value: 'SalesInvoice' | 'SalesOrder') => void
  tab: 'SalesInvoice' | 'SalesOrder'
  salesOrderIssueCount: number
  salesInvoiceIssueCount: number
}
const IssuesTabPicker = (props: TOwnProps) => {
  return (
    <div className={Styles.PickerBody}>
      <div
        className={`${Styles.PickerTab} ${
          props.tab === 'SalesOrder' ? Styles.SelectedTab : ''
        }`}
        onClick={() => {
          props.salesOrderIssueCount > 0 && props.onPick('SalesOrder')
        }}
      >
        Sales Order Issues{' ' + `[${props.salesOrderIssueCount}]`}
      </div>
      <div
        className={`${Styles.PickerTab} ${
          props.tab === 'SalesInvoice' ? Styles.SelectedTab : ''
        }`}
        onClick={() => {
          props.salesInvoiceIssueCount > 0 && props.onPick('SalesInvoice')
        }}
      >
        Sales Invoice Issues{' ' + `[${props.salesInvoiceIssueCount}]`}
      </div>
    </div>
  )
}

export default IssuesTabPicker
