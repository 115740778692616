export interface IFormValue<T> {
  Modified: boolean
  Value: T
  isValid: (compareTo?: T) => boolean
  reset: () => void
  getValidationMessage: (compareTo?: T) => string | ((v?: T) => string)
}

// export abstract class FormValue<T> implements IFormValue<T> {
//   static allAreValid(values: IFormValue<any>[]){
//     return values && values.every(v => v.)
//   }
// }

export type UniformFormObject<T> = {
  [key: string]: IFormValue<T>
}

export type FormObject<T> = { [key in keyof T]: IFormValue<T[key]> }

export type FormValueTypes<T extends UniformFormObject<unknown>> = {
  [P in keyof T]: T[P]['Value']
}

export type ToErrorFormValues<T> = {
  [P in keyof T]: ErrorFormValue<T[P]>
}

export type ToBooleanFormValues<T> = {
  [P in keyof T]: BooleanFormValue<T[P]>
}

export type ToFormValues<T> = {
  [P in keyof T]: IFormValue<T[P]>
}

export interface ClassErrorFormValue<T> extends Function {
  new (...args: any[]): T
}

export const FormValue = {
  allAreValid(values: IFormValue<any>[]) {
    return values && values.every(v => v.isValid())
  },
  allObjectParametersAreValid(values: {
    [key: string]: IFormValue<any> | any
  }) {
    return (
      values &&
      this.allAreValid(
        Object.keys(values)
          .map(key => values[key])
          .filter(
            v => v instanceof ErrorFormValue || v instanceof BooleanFormValue
          )
      )
    )
  },
  modifyAll(values: IFormValue<any>[]) {
    if (!values || !Array.isArray(values)) return
    values.forEach(v => (v.Modified = true))
  },
  modifyAllObjects(values: { [key: string]: IFormValue<any> | any }) {
    return (
      values &&
      this.modifyAll(
        Object.keys(values)
          .map(key => values[key])
          .filter(
            v => v instanceof ErrorFormValue || v instanceof BooleanFormValue
          )
      )
    )
  },

  getFormObject<TResult>(obj: object) {
    return Object.keys(obj)
      .filter(key => 'Value' in obj[key])
      .map(key => ({ [key]: obj[key] }))
      .reduce((prev, next) => ({ ...prev, ...next }), {}) as FormObject<TResult>
  },

  getFormResult<TResult>(formObject: FormObject<TResult>) {
    return Object.keys(formObject)
      .map(key => ({ [key]: formObject[key].Value }))
      .reduce((prev, next) => ({ ...prev, ...next }), {}) as TResult
  },

  getFormResultFromObject<TResult>(partialFormObject: object) {
    return !partialFormObject
      ? ({} as TResult)
      : (Object.keys(partialFormObject)
          .filter(
            key =>
              partialFormObject[key] != null &&
              typeof partialFormObject[key] === 'object' &&
              'Value' in partialFormObject[key]
          )
          .map(key => ({ [key]: partialFormObject[key].Value }))
          .reduce((prev, next) => ({ ...prev, ...next }), {}) as TResult)
  },
}

export class BooleanFormValue<T> implements IFormValue<T> {
  private modified: boolean
  public get Modified() {
    return this.modified
  }

  public set Modified(modified: boolean) {
    this.modified = modified
  }

  private value: T
  private defaultValue: T
  public get Value() {
    return this.value
  }

  public set Value(value: T) {
    this.value = value
    this.modified = true
  }

  #isValid: (value: T, compareTo?: T) => boolean
  public isValid(compareTo?: T) {
    return this.#isValid(this.value, compareTo)
  }

  public reset() {
    this.value = this.defaultValue
    this.modified = false
  }

  #validationMessage: string | null | ((v: T) => string)
  public getValidationMessage(compareTo?: T) {
    if (this.#validationMessage === null) return ''
    if (this.#validationMessage == null) return 'Required'
    if (typeof this.#validationMessage === 'string')
      return this.#validationMessage
    return this.#validationMessage(this.Value)
  }

  private isValidDefault = (v: T) => !!v
  public constructor(
    defaultValue: T,
    isValid?: (value: T, compareTo?: T) => boolean,
    validationMessage?: string | null | ((v: T) => string)
  ) {
    this.defaultValue = defaultValue
    this.value = defaultValue
    this.modified = false
    this.#isValid = isValid || this.isValidDefault
    this.#validationMessage = validationMessage
  }
}

export class ErrorFormValue<T> implements IFormValue<T> {
  private modified: boolean
  public get Modified() {
    return this.modified
  }

  public set Modified(modified: boolean) {
    this.modified = modified
  }

  private value: T
  private defaultValue: T
  public get Value() {
    return this.value
  }

  public set Value(value: T) {
    this.value = value
    this.modified = true
  }

  #isValid: (value: T, compareTo?: T) => Error
  public isValid(compareTo?: T) {
    return !this.#isValid(this.value, compareTo)
  }

  public getError(compareTo?: T) {
    return this.#isValid(this.value, compareTo)
  }

  public reset() {
    this.value = this.defaultValue
    this.modified = false
  }

  #validationMessage: string | null | ((v: T) => string)
  public getValidationMessage(compareTo?: T) {
    const error = this.getError(compareTo)

    if (this.#validationMessage != null)
      return this.#validationMessage as string
    if (error && error.message) return error.message
    if (this.#validationMessage == null) return 'Required'
    if (typeof this.#validationMessage === 'string')
      return this.#validationMessage
    return this.#validationMessage(this.Value)
  }

  private isValidDefault = (v: T) => (!!v ? null : new Error('Required 2'))
  public constructor(
    defaultValue: T,
    isValid?: (value: T, compareTo?: T) => Error,
    startModified?: boolean,
    validationMessage?: string | null | ((v: T) => string)
  ) {
    this.defaultValue = defaultValue
    this.value = defaultValue
    this.modified = !!startModified
    this.#isValid = isValid || this.isValidDefault
    this.#validationMessage = validationMessage
  }

  static validateRequiredString(
    str: string,
    propertyName: string,
    maxLength: number
  ) {
    if (!str) return new Error(`${propertyName} is required.`)
    if (str.length > maxLength) return new Error(`${propertyName} is too long!`)
  }

  static validateOptionalString(
    str: string,
    propertyName: string,
    maxLength: number
  ) {
    if (str.length > maxLength) return new Error(`${propertyName} is too long!`)
  }

  static validateRequired(value: any, propertyName: string) {
    if (value == null || (typeof value == 'string' && value == ''))
      return new Error(`${propertyName} is required.`)
  }

  static validateOptional(value: any) {
    return null
  }
}
