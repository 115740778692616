import React from 'react'
import { Grid } from '@material-ui/core'

import MobileCardContent from 'components/MobileCardContent'
import MobileCardItem from 'components/MobileCardItem'

import {
  RenderDate,
  RenderLabNo,
  RenderLubeCondition,
  RenderMachineCondition,
  RenderMachineName,
  RenderRoute,
} from '../KendoUIGridRenders'

interface Props {
  sample: any
  showDivisions: boolean
}
const MobileCard: React.FC<Props> = ({ sample, showDivisions }: Props) => {
  return (
    <MobileCardContent title={sample.machine.plant.plantName}>
      <Grid container direction="row" spacing={1}>
        <MobileCardItem title="Sample Date">
          <RenderDate dataItem={sample} field="sampleDate" />
        </MobileCardItem>
        {showDivisions && (
          <MobileCardItem title="Division">
            {sample.machine.plant.customer.division.name}
          </MobileCardItem>
        )}
        <MobileCardItem title="Customer Name">
          {sample.machine.plant.customer.custName}
        </MobileCardItem>
        <MobileCardItem title="Plant Name">
          {sample.machine.plant.plantName}
        </MobileCardItem>
        <MobileCardItem title="Route">
          <RenderRoute dataItem={sample} field="machine.route.routeID" />
        </MobileCardItem>
        <MobileCardItem title="Mach No.">
          {sample.machine.machNo}
        </MobileCardItem>
        <MobileCardItem title="Machine Name">
          <RenderMachineName dataItem={sample} field="machine.machineName" />
        </MobileCardItem>
        <MobileCardItem title="Equipament ID">
          {sample.machine.custEquID}
        </MobileCardItem>
        <MobileCardItem title="Mach">
          <RenderMachineCondition dataItem={sample} field="machCond" />
        </MobileCardItem>
        <MobileCardItem title="Lube">
          <RenderLubeCondition dataItem={sample} field="lubCond" />
        </MobileCardItem>
        <MobileCardItem title="Problems">{sample.problems}</MobileCardItem>
        <MobileCardItem title="Lab No.">
          <RenderLabNo dataItem={sample} field="labID" />{' '}
        </MobileCardItem>
        <MobileCardItem title="Reviewed">
          {sample.reviewed ? 'Yes' : 'No'}
        </MobileCardItem>
        <MobileCardItem title="Reviewed Date">
          <RenderDate dataItem={sample} field="custReviewDte" />
        </MobileCardItem>
        <MobileCardItem title="Machine Type">
          {sample.machine.machType}
        </MobileCardItem>
        <MobileCardItem title="Lube Type">
          {sample.machine.lubricant?.lubricantName}
        </MobileCardItem>
      </Grid>
    </MobileCardContent>
  )
}

export default MobileCard
