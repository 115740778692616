import React, { useRef, useState } from 'react'
import { Grid, ThemeProvider } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import moment from 'moment'

import { ThemeButton } from 'components/buttons'
import coreTheme from 'components/theme'
import { LineChart } from 'components/visualizations/brush-line/LineChart'
import APIService from 'services/api-service'
import { dateComparator } from 'utils/date-utilities'
import { FieldToSubtitle, TestToTitle } from 'utils/test-utilities'

import HelpPopper from './help-popper'

import * as Styles from './graphs.module.css'

const Chart = () => {
  const [loaded, setLoaded] = React.useState(false)
  const [data, setData] = React.useState(null)
  const [minY, setMinY] = React.useState(0)
  const [maxY, setMaxY] = React.useState(100)
  const [error, setError] = useState(false)
  const chartRef = useRef(undefined)
  const innerChartRef = useRef<HTMLDivElement>(undefined)

  const [reverseData, setReverseData] = useState(false)

  const [graphDataState, setGraphDataState] = useState({
    pointID: undefined,
    test: undefined,
    field: undefined,
    title: undefined,
    subtitle: undefined,
  })

  React.useEffect(() => {
    if (window) {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)

      const pointID = urlParams.get('pointID')
      const test = urlParams.get('test')
      const field = urlParams.get('field')
      const other = urlParams.get('other')
      setGraphDataState({
        pointID,
        test,
        field,
        title: TestToTitle(test),
        subtitle: FieldToSubtitle(test, field, other),
      })
    }
  }, [])

  React.useEffect(() => {
    if (loaded || graphDataState.pointID == null) return
    ;(async variables => {
      const test = variables.test
      const field = variables.field

      let result = null
      try {
        result = await APIService.getDARTHistory(
          {
            pointID: variables.pointID,
            startDate: variables.startDate,
            endDate: variables.endDate,
            pageSize: variables.pageSize,
          },
          test,
          field === 'iso' ? 'iSO1,iSO2,iSO3' : field
        )

        const filteredSamples =
          typeof result.pagedSamples.items === 'undefined'
            ? []
            : result.pagedSamples.items
                .filter(
                  s =>
                    s[test] !== null &&
                    (!isNaN(s[test][field]) || field === 'iso')
                )
                .reverse()

        const chartData =
          field !== 'iso'
            ? [
                {
                  id: graphDataState.subtitle,
                  data: filteredSamples.map(s => {
                    return {
                      x: moment(s.sampleDate).format('YYYY-MM-DD'),
                      y: s[test][field] * 1.0,
                      correctiveAction: s.cuCorrAct,
                    }
                  }),
                },
              ]
            : [
                { id: 'ISO1', color: 'red', isoField: 'iSO1' },
                { id: 'ISO2', color: 'orange', isoField: 'iSO2' },
                { id: 'ISO3', color: 'green', isoField: 'iSO3' },
              ].map(item => ({
                id: graphDataState.subtitle,
                data: filteredSamples.map(s => {
                  return {
                    x: moment(s.sampleDate).format('YYYY-MM-DD'),
                    y: s[test][item.isoField] * 1.0,
                    correctiveAction: s.cuCorrAct,
                  }
                }),
                color: item.color,
              }))

        const minY = chartData[0].data
          .map(x => x.y)
          .reduce((prev, cur) => (cur < prev ? cur : prev), 0)
        const maxY = chartData[0].data
          .map(x => x.y)
          .reduce((prev, cur) => (cur > prev ? cur : prev), 0)

        setData(chartData)
        setMinY(minY)
        setMaxY(maxY)
        setLoaded(true)
      } catch (ex) {
        setError(true)
        setLoaded(true)
      }
    })({
      pointID: graphDataState.pointID,
      startDate: new Date(2000, 0, 1),
      endDate: new Date(),
      pageSize: 10000,
      test: graphDataState.test,
      field: graphDataState.field,
    })
  }, [
    loaded,
    graphDataState.pointID,
    graphDataState.test,
    graphDataState.field,
    graphDataState.subtitle,
  ])

  type TPoint = {
    x: Date
    y: number
  }

  const helpIconRef = useRef(undefined)
  const printButtonRef = useRef(undefined)
  const reverseButtonRef = useRef(undefined)

  const handlePrintClick = () => {
    if (window && printButtonRef.current && chartRef.current) {
      const button = printButtonRef.current as HTMLInputElement
      const chart = chartRef.current as HTMLDivElement
      const helpIcon = helpIconRef.current as HTMLDivElement
      const reverseButton = reverseButtonRef.current as HTMLDivElement

      window.onbeforeprint = (_this, _ev) => {
        button.style.visibility = 'hidden'
        chart.style.border = '1px solid rgba(0, 0, 0, 0)'
        helpIcon.style.visibility = 'hidden'
        reverseButton.style.visibility = 'hidden'
        setTimeout(() => {
          button.style.visibility = 'visible'
          chart.style.border = '1px solid rgba(0, 0, 0, 0.25)'
          helpIcon.style.visibility = 'visible'
          reverseButton.style.visibility = 'visible'
        }, 10)
      }

      window.print()
    }
  }

  const handleReverseData = () => {
    setReverseData(!reverseData)
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100vw',
        height: '100vh',
      }}
    >
      <div
        ref={chartRef}
        style={{
          minWidth: '200px',
          border: '1px solid rgba(0, 0, 0, 0.25)',
          padding: '10px',
          margin: '10px',
          display: 'flex',
          overflow: 'visible',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          // overflow: "auto",
          height: '100%',
          width: '90%',
          maxWidth: '816px', // Limit for properly scaled printing.
        }}
      >
        <h2
          style={{
            display: 'inline-block',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {graphDataState.subtitle} - Full History
        </h2>
        <span
          style={{
            display: 'inline-block',
            width: '100%',
            textAlign: 'center',
            marginBottom: '5px',
          }}
        >
          {graphDataState.title}
        </span>
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            overflow: 'visible',
          }}
        >
          {error ? (
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <div id={Styles.ErrorBody}></div>
              <div id={Styles.ErrorBody2}></div>
              <br />
              <p id={Styles.NotFound}>Data Not Found</p>
            </div>
          ) : loaded ? (
            <LineChart
              type={graphDataState.field === 'iso' ? 'area' : 'line'}
              height="100%"
              width="100%"
              maxDateGroups={12}
              chartRef={innerChartRef}
              tooltipAlwaysVisible
              reverse={reverseData}
              brush
              lineData={data?.map(d => {
                return {
                  id: d.id,
                  points: (
                    d.data.map(value => {
                      let date = new Date(value?.x)
                      date = new Date(
                        date?.setHours(
                          date?.getHours() +
                            (date?.getTimezoneOffset() ?? 0) / 60
                        )
                      )
                      return {
                        x: date,
                        y: value.y > 0 ? value.y : '0',
                        correctiveAction: value.correctiveAction,
                      } as TPoint
                    }) as TPoint[]
                  ).sort((p1, p2) => dateComparator(p1.x, p2.x)),
                  color: d.color || '#FF6600',
                }
              })}
            />
          ) : (
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          )}
        </div>
      </div>

      <Grid
        container
        justify="space-around"
        style={{
          marginTop: '15px',
          marginBottom: '15px',
          position: 'relative',
        }}
      >
        <div className={Styles.ButtonContainer}>
          <ThemeButton
            variant="contained"
            color="primary"
            disabled={!loaded || error}
            onClick={handleReverseData}
            buttonRef={reverseButtonRef}
          >
            Reverse Date Axis
          </ThemeButton>

          <div ref={helpIconRef}>
            <HelpPopper anchorEl={helpIconRef.current} />
          </div>

          <ThemeButton
            variant="contained"
            color="primary"
            disabled={!loaded || error}
            onClick={handlePrintClick}
            buttonRef={printButtonRef}
          >
            Print
          </ThemeButton>
        </div>
      </Grid>
    </div>
  )
}

// function Page({ classes, location }) {
//   return (
//     <FullscreenLayout>
//       <Chart classes={classes} location={location} />
//     </FullscreenLayout>
//   );
// }

// export default withStyles(styles)(Page);
export default () => (
  <ThemeProvider theme={coreTheme}>
    <Chart />
  </ThemeProvider>
)
