import { Card, CardContent, CardHeader, Grid } from '@material-ui/core'
import { useProfile } from 'Contexts/ProfileContext'
import moment from 'moment'
import React from 'react'

interface Props {
    salesOrder: any
}

const MobileCard: React.FC<Props> = ({ salesOrder }: Props) => {
    const profileContext = useProfile()

    const renderCurrency = (value: number) => (
        <>
            {Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(Number(value.toFixed(2)))}
        </>
    )

    return (
        <Card variant="outlined" style={{ marginBottom: '10px', marginTop: '10px' }}>
            <CardContent>
                <Grid container direction="row" spacing={1}>
                    <Grid item xs={6} md={3}>
                        PO#:
                    </Grid>
                    <Grid item xs={6} md={3}>
                        {salesOrder?.purchaseOrderNumber}
                    </Grid>
                    <Grid item xs={6} md={3}>
                        Status:
                    </Grid>
                    <Grid item xs={6} md={3}>
                        {salesOrder?.status}
                    </Grid>
                    <Grid item xs={6} md={3}>
                        Customer Name:
                    </Grid>
                    <Grid item xs={6} md={3}>
                        {profileContext.profile.customers?.map(x => x.custName)}
                    </Grid>
                    <Grid item xs={6} md={3}>
                        Location:
                    </Grid>
                    <Grid item xs={6} md={3}>
                        {salesOrder?.plants.join(', ')}
                    </Grid>
                    <Grid item xs={6} md={3}>
                        Expiration Date:
                    </Grid>
                    <Grid item xs={6} md={3}>
                        {/* {salesOrder?.expirationDate} */}
                        {moment(salesOrder?.expirationDate).format('l')}
                    </Grid>
                    <Grid item xs={6} md={3}>
                        Inititial Balance:
                    </Grid>
                    <Grid item xs={6} md={3}>
                        {renderCurrency(salesOrder?.initialBalance)}
                    </Grid>
                    <Grid item xs={6} md={3}>
                        Available Balance:
                    </Grid>
                    <Grid item xs={6} md={3}>
                        {renderCurrency(salesOrder?.availableBalance)}
                    </Grid>
                </Grid>
            </CardContent>
        </Card >
    )
}

export default MobileCard
