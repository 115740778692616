import { useProfile } from 'Contexts/ProfileContext'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface TProps {
  isPrimaryUser: boolean
}

export const AppSecurity: React.FC<TProps> = ({ isPrimaryUser }: TProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const profileContext = useProfile()

    
  useEffect(() => {
    if (
        location.pathname.startsWith('/financials')
         && profileContext.dependentData.userDetails.isFinancialRestricted
    ) {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrimaryUser])

  return null
}
