import moment from 'moment'

import { TAPISample } from 'types/api'
import { lubeConditions, machineConditions } from 'services/lookup-service'
import getMachineTypeFromOptions, {
  TMachineTypeList,
} from 'utils/getMachineTypeFromOptions'

export const tableFormatter = (
  samples: TAPISample[],
  machineTypeOptions: TMachineTypeList[],
  hasDivisions: boolean
): string[][] => {
  const headerValues = [
    'Last Sample',
    ...(hasDivisions ? ['Division Name'] : []),
    'Customer Name',
    'Plant Name',
    'Route Name',
    'Machine Number',
    'Machine Name',
    'Equipment ID',
    'Machine Condition',
    'Lube Condition',
    'Problems',
    'Lab No.',
    'Reviewed',
    'Reviewed Date',
    'Machine Type',
    'Lube Type',
  ]

  const formattedData = samples.map(sample => [
    moment(sample.sampleDate).format('l'),
    ...(hasDivisions ? [sample.machine.plant.customer.division.name] : []),
    sample.machine.plant.customer.custName,
    sample.machine.plant.plantName,
    sample.machine.route !== null
      ? `${sample.machine.route.routeNo} - ${sample.machine.route.name}`
      : '',
    sample.machine.machNo,
    sample.machine.machineName,
    sample.machine.custEquID,
    machineConditions.find(x => x.value === sample.machCond).text,
    lubeConditions.find(x => x.value === sample.lubCond).text,
    sample.problems,
    sample.labID.toString(),
    sample.reviewed ? 'Yes' : 'No',
    sample.custReviewDte !== null
      ? moment(sample.custReviewDte).format('l')
      : '',
    getMachineTypeFromOptions({
      machineType: sample.machine.machType,
      machineTypeOptions: machineTypeOptions,
    }),
    sample.machine.lubricant?.lubricantName,
  ])

  return [headerValues, ...formattedData]
}
