import React from 'react'

import { ReportDependencyWrapper } from './ReportDependencyWrapper'
import { TelerikReportViewer, TProps } from './TelerikReportViewer'

export const ReportViewer = (props: TProps) => (
  <div style={{ width: '90%', height: '100%' }}>
    <ReportDependencyWrapper>
      <TelerikReportViewer {...props} />
    </ReportDependencyWrapper>
  </div>
)

export default ReportViewer
