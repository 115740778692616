import React, { CSSProperties, useState } from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { KeyboardArrowUp } from "@mui/icons-material";
import { Box, Zoom, Fab } from "@mui/material";

const ScrollToTopButton: React.FC = () => {
  const trigger = useScrollTrigger({
    threshold: 100,
  });

  const scrollToTop = React.useCallback(() => {
    document.getElementById("root")?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <Zoom in={trigger}>
      <Box
        role="presentation"
        sx={{
          position: "fixed",
          bottom: 100,
          right: 32,
          zIndex: 1,
        }}
      >
        <Fab
          onClick={scrollToTop}
          size="small"
          aria-label="scroll back to top"
          style={{ backgroundColor: "#505F74" }}
        >
          <KeyboardArrowUp />
        </Fab>
      </Box>
    </Zoom>
  );
};

export default ScrollToTopButton;
