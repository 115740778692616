import { Route, Routes } from 'react-router-dom'

import AuthPage from 'pages/auth'

import 'globals/KendoDependencies/Bootstrap.css'
import 'globals/KendoDependencies/KendoStyles.css'

import Dashboard from './pages/index'
import Login from './pages/login'
import FinancialsPage from 'pages/financials'
import FinancialInvoicesPage from 'pages/financials/invoices'
import FinancialCustomerOrdersPage from 'pages/financials/CustomerOrders'
import FinancialsDashboardPage from 'pages/financials/dashboard'
import ReportsPage from 'pages/reports'
import DartPage from 'pages/dart'
import DartDataPage from 'pages/dart/data'
import DartGraphsPage from 'pages/dart/graphs'
import ProgramManagementPage from 'pages/program-management'
import DownloadsPage from 'pages/downloads'
import GeneralDownloadsPage from 'pages/downloads/general'
import CustomerDownloadsPage from 'pages/downloads/customer'
import TechnicalLibraryPage from 'pages/technical-library'
import OilSamplingInstructions from 'pages/technical-library/oil-sampling-instructions'
import TechnicalLibraryAssets from 'pages/technical-library/assets'
import Glossary from 'pages/technical-library/glossary'
import ChangeOfConditionPage from 'pages/reports/change-of-condition'
import CommonExceptionsPage from 'pages/reports/common-exceptions'
import ConditionSummaryPage from 'pages/reports/condition-summary'
import DelinquentSamplesPage from 'pages/reports/delinquent-samples'
import LastSamplePage from 'pages/reports/last-sample'
import OilAnalysisPage from 'pages/reports/oil-analysis'
import ProgramSnapshotPage from 'pages/reports/program-snapshot'
import ProgramSnapshotResultPage from 'pages/reports/program-snapshot-result'
import RecurringExceptionsPage from 'pages/reports/recurring-exceptions'
import SampleStatusPage from 'pages/reports/sample-status'
import ScheduleCompliancePage from 'pages/reports/schedule-compliance'
import ScheduledSamplesDuePage from 'pages/reports/scheduled-samples-due'
import SummaryReportPage from 'pages/reports/summary-report'
import JobReportPage from 'pages/reports/job-report'
import ReportsViewerPage from 'pages/reports/viewer'
import ReportNotFoundPage from 'pages/report-not-found'
import ManageSchedulePage from 'pages/program-management/schedule'
import NewManageSchedulePage from 'pages/program-management/schedule/new'
import ManageRoutesPage from 'pages/program-management/routes'
import ManageRoutesEditPage from 'pages/program-management/routes/manage'
import ManageEquipmentDatabasePage from 'pages/program-management/equipment'
import ManageEquipmentDatabaseNewPage from 'pages/program-management/equipment/new'
import ManageEquipmentDatabaseEditPage from 'pages/program-management/equipment/manage'
import StandardSampleLabelsPage from 'pages/program-management/sample-labels/standard'
import OrderSuppliesPage from 'pages/program-management/supplies'
import ProductPricingEditingPage from 'pages/program-management/product-pricing-editing'
import OrderInformationPage from 'pages/program-management/order-information'
import { ProfileContextProvider } from 'Contexts/ProfileContext'
import { SelectionContextProvider } from 'Contexts/SelectionContext'
import FileDownloaderAsp from 'pages/FileDownloader.asp'
import ReceiptDownloader from 'pages/ReceiptDownloader'
import { ThemeContextProvider } from 'Contexts/ThemeContext'
import { PrimaryHubContextProvider } from 'Contexts/PrimaryHubContext'
import Debug from 'pages/Debug/Debug'
import AnalyticsPage from 'pages/analytics'
import MachineHealthPage from 'pages/analytics/MachineHealthPage'
import ReceiptNotFoundPage from 'pages/receipt-not-found'
import RequestInProgressPage from 'pages/request-in-progress'

import './App.css'
import { MachineHealthDashboard } from 'pages/analytics/MachineHealthPage/MachineHealthDashboard'
import NotFoundPage from 'pages/404'
import { EnvironmentContextProvider } from 'Contexts/EnvironmentContext'
import { UserHubContextProvider } from 'Contexts/UserHubContext'
import InvoiceNotFoundPage from 'pages/invoice-not-found'
import SampleRegistration from 'pages/sample/registration/Pages/SampleRegistration'
import { BottleRegistrationContextProvider } from 'pages/sample/registration/registrationContext'
import Sample from 'pages/sample/Sample'
import { ScrollToTopButton } from 'components/buttons'
import SampleRegistrationRedirectPage from 'pages/sample/registration/Pages/SampleRegistrationRedirectPage'
import RequestResetPaswordLinkPage from 'pages/ResetPassword/RequestResetPaswordLinkPage'
import ResetPasswordPage from 'pages/ResetPassword/ResetPasswordPage'


export const routes = [
  { path: '/', element: Dashboard },
  { path: '/login', element: Login },
  { path: '/reset-password', element: ResetPasswordPage },
  { path: '/fileDownloader.asp', element: FileDownloaderAsp },
  { path: '/ReceiptDownloader', element: ReceiptDownloader },
  { path: '/forgot-password', element: RequestResetPaswordLinkPage },
  { path: '/reset-password/:token/:username', element: ResetPasswordPage },
  { path: '/auth', element: AuthPage },
  { path: '/reports', element: ReportsPage },
  { path: '/reports/change-of-condition', element: ChangeOfConditionPage },
  { path: '/reports/common-exceptions', element: CommonExceptionsPage },
  { path: '/reports/condition-summary', element: ConditionSummaryPage },
  { path: '/reports/delinquent-samples', element: DelinquentSamplesPage },
  { path: '/reports/last-sample', element: LastSamplePage },
  {
    path: '/reports/oil-analysis',
    element: OilAnalysisPage,
  },
  { path: '/reports/program-snapshot', element: ProgramSnapshotPage },
  {
    path: '/reports/program-snapshot-result',
    element: ProgramSnapshotResultPage,
  },
  {
    path: '/reports/recurring-exceptions',
    element: RecurringExceptionsPage,
  },
  { path: '/reports/sample-status', element: SampleStatusPage },
  { path: '/reports/schedule-compliance', element: ScheduleCompliancePage },
  {
    path: '/reports/scheduled-samples-due',
    element: ScheduledSamplesDuePage,
  },
  { path: '/reports/summary-report', element: SummaryReportPage },
  { path: '/reports/job-report', element: JobReportPage },
  { path: '/reports/viewer', element: ReportsViewerPage },
  { path: '/financials', element: FinancialsPage },
  { path: '/dart', element: DartPage },
  { path: '/dart/data', element: DartDataPage },
  { path: '/dart/graphs', element: DartGraphsPage },
  { path: '/program-management', element: ProgramManagementPage },
  { path: '/program-management/schedule', element: ManageSchedulePage },
  { path: '/sample', element: Sample },
  { path: '/sample/registration', element: SampleRegistration },
  { path: '/SR', element: SampleRegistrationRedirectPage },
  {
    path: '/program-management/schedule/new',
    element: NewManageSchedulePage,
  },
  { path: '/program-management/routes', element: ManageRoutesPage },
  {
    path: '/program-management/routes/manage',
    element: ManageRoutesEditPage,
  },
  {
    path: '/program-management/equipment',
    element: ManageEquipmentDatabasePage,
  },
  {
    path: '/program-management/equipment/new',
    element: ManageEquipmentDatabaseNewPage,
  },
  {
    path: '/program-management/equipment/manage',
    element: ManageEquipmentDatabaseEditPage,
  },
  {
    path: '/program-management/sample-labels/standard',
    element: StandardSampleLabelsPage,
  },

  {
    path: '/program-management/supplies',
    element: () => (
      <UserHubContextProvider>
        <OrderSuppliesPage />
      </UserHubContextProvider>
    ),
  },
  {
    path: '/program-management/order-information',
    element: OrderInformationPage,
  },
  {
    path: '/program-management/product-pricing-editing',
    element: ProductPricingEditingPage,
  },
  { path: '/downloads', element: DownloadsPage },
  { path: '/downloads/general', element: GeneralDownloadsPage },
  { path: '/downloads/customer', element: CustomerDownloadsPage },
  { path: '/technical-library', element: TechnicalLibraryPage },
  { path: '/technical-library/glossary', element: Glossary },
  {
    path: '/technical-library-assets/:fileName',
    element: TechnicalLibraryAssets,
  },

  { path: '/analytics', element: AnalyticsPage },
  { path: '/analytics/machine-health', element: MachineHealthPage },
  {
    path: '/analytics/machine-health/dashboard',
    element: MachineHealthDashboard,
  },

  {
    path: '/technical-library/oil-sampling-instructions',
    element: OilSamplingInstructions,
  },
  { path: '/financials/invoices', element: FinancialInvoicesPage },
  {
    path: '/financials/customer-orders',
    element: FinancialCustomerOrdersPage,
  },
  { path: '/financials/dashboard', element: FinancialsDashboardPage },

  { path: '/report-not-found', element: ReportNotFoundPage },
  { path: '/404', element: NotFoundPage },

  { path: '/debug', element: Debug },
  { path: '/receipt-not-found', element: ReceiptNotFoundPage },
  { path: '/invoice-not-found', element: InvoiceNotFoundPage },
  { path: '/request-in-progress', element: RequestInProgressPage },
] as { path: string; element: () => JSX.Element }[]

function App() {
  return (
    <>
      <PrimaryHubContextProvider>
        {/* <DSPrimaryHubContextProvider> */}
        <EnvironmentContextProvider>
          <ThemeContextProvider>
            <ProfileContextProvider>
              <SelectionContextProvider>
                <BottleRegistrationContextProvider>
                  <div className="App">
                    <Routes>
                      {routes.map(route => (
                        <Route
                          key={route.path}
                          {...route}
                          element={<route.element />}
                        />
                      ))}
                    </Routes>
                  </div>
                </BottleRegistrationContextProvider>
              </SelectionContextProvider>
            </ProfileContextProvider>
          </ThemeContextProvider>
        </EnvironmentContextProvider>
        {/* </DSPrimaryHubContextProvider> */}
      </PrimaryHubContextProvider>
      <ScrollToTopButton />
    </>
  )
}

export default App
