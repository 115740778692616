import { WarhammerSample, WarhammerSampleResponse } from 'types/api'
import ReadOnlyUserForbiddenHeader from 'pages/sample/registration/CardModules/CardModuleHeaders/ReadOnlyForbiddenHeader'
import NotLoggedInForbiddenHeader from './CardModules/CardModuleHeaders/NotLoggedInForbiddenHeader'

export enum PageState {
  BottleIdentifierEntry,
  Forbidden,
  BottleDataLoading,
  ServerError,
  DataNotFound,
  BottleRequestInvalid,
  NoCustomerAssignedToBottle,
  Unlinked, //User should supply information
  Linked, //user supplied information, we have not received it yet
  OnIssue,
  Testing, //under test
  Complete, //complete and has report available
  CompleteWithIssue,
  Unknown, //an unexpected state that should not be reached, but may be reached due to completed sampled with missing labID information
}

type ImmutableSet<T> = Omit<Set<T>, 'delete' | 'add' | 'set' | 'clear'>

export const PageStateDoNotShowFindAnotherBottleSet: ImmutableSet<PageState> =
  new Set([PageState.BottleIdentifierEntry, PageState.Unlinked])

export const readOnlySignedInErrorHeaderMap = new Map()
readOnlySignedInErrorHeaderMap.set(450, <ReadOnlyUserForbiddenHeader />)
readOnlySignedInErrorHeaderMap.set(451, <NotLoggedInForbiddenHeader />)

export type TWarhammerSampleWithoutIdentifier = Omit<
  WarhammerSample,
  'bottleIdentifier'
>

export enum InformationPageState {
  PlantSelect,
  MachineSelect,
  InformationEntry,
  Complete,
  PlantRegistration,
  MachineRegistration,
  MachineEdit,
}

export enum PlantRegistrationPageState {
  About,
  GeneralInformation,
  ContactInformation,
  ShippingInformation,
  Complete,
}

export enum MachineRegistrationPageState {
  About,
  MachineInformation,
  MachineFluidAndSampling,
  MachineTesting,
  Complete,
}
