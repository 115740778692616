import React from 'react'
import { TextFieldProps } from '@material-ui/core'

import FilterTooltip from '../FilterTooltip/FilterTooltip'

import { TextFieldFilter } from './text-field-filter'

type TProps = {
  tooltip?: boolean
}

export const InvoiceNumberFilter: React.FC<TextFieldProps> = (
  props: TextFieldProps & TProps
) => {
  const { tooltip = true, ...rest } = props

  const filter = (
    <TextFieldFilter id="invoiceNo" placeholder="Invoice No" {...rest} />
  )

  return tooltip ? (
    <FilterTooltip title="Invoice number.">{filter}</FilterTooltip>
  ) : (
    filter
  )
}
