import { Button, ButtonProps } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { useNavigate } from 'react-router-dom'

import coreTheme from '../theme'

type TProps = {
  children?: string
  disabled?: boolean
  url?: string
} & ButtonProps &
  any

const theme = createTheme(
  {
    overrides: {
      MuiButton: {
        root: {
          [coreTheme.breakpoints.down('sm')]: {
            width: '175px',
          },
          [coreTheme.breakpoints.up('sm')]: {
            width: '225px',
          },
        },
      },
    },
  },
  coreTheme
)

export default (props: TProps) => {
  const navigate = useNavigate()

  const handleClick = event => {
    navigate(props.url)
  }

  return (
    <ThemeProvider theme={theme}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClick}
        {...props}
      >
        {props.children || 'View Report'}
      </Button>
    </ThemeProvider>
  )
}
