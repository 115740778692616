import React from 'react'
import TroubleshootIcon from '@mui/icons-material/Troubleshoot'
import PageStyles from '../page-styles.module.css'

type TProps = {
  children?: React.ReactNode
}
export const NotFoundIconBox = (props: TProps) => {
  return (
    <div className={PageStyles.NotFoundModule}>
      {props.children}
      <div className={PageStyles.LargeIconBox}>
        <TroubleshootIcon
          className={PageStyles.TroubleshootIcon}
          style={{ fontSize: '9rem' }}
        />
      </div>
    </div>
  )
}
