import { TAPICustomer, TAPIMachine, TAPIProfileDivision } from 'types/api'

/**
 * Resolves all customer IDs from selected divisions and
 * @param divisions array of divisions from the profile
 * @param selectedDivisionIDs array of selected division IDs
 * @param selectedCustomerIDs array of selected customer IDs
 */
export const getSelectedCustomerIDs = (
  customers: TAPICustomer[],
  divisions: TAPIProfileDivision[],
  selectedDivisionIDs: number[],
  selectedCustomerIDs: number[]
): number[] => {
  if (selectedCustomerIDs?.length > 0) {
    return selectedCustomerIDs
  }

  if (
    (!selectedCustomerIDs || selectedCustomerIDs?.length === 0) &&
    (!selectedDivisionIDs || selectedDivisionIDs?.length === 0)
  ) {
    // NOTE: Whether a bug or not, divisions can have more custoemrs than the customer array, or customerws can be independent of a division.
    return [
      ...new Set(
        divisions
          ?.map(x => x.customers.map(y => y.custID))
          ?.flat()
          .concat(customers?.map(c => c.custID) ?? []) ?? []
      ),
    ]
  }

  let custIDsFromDivisions = divisions
    ?.filter(
      x =>
        selectedDivisionIDs.indexOf(x.iD) !== -1 ||
        selectedDivisionIDs.length === 0
    )
    ?.map(x => x.customers.map(y => y.custID))
    ?.flat() ?? [-1]

  custIDsFromDivisions =
    custIDsFromDivisions.length === 0 ? [-1] : custIDsFromDivisions

  return [...new Set(custIDsFromDivisions)]
}

/**
 * Resolves all point IDs from selected machine names and cust equipment IDs
 * @param machines array of machines from the profile
 * @param selectedCustomerEquipmentIDs array of selected customer equipment IDs
 * @param selectedMachineNames array of selected machine names
 */
export const getSelectedMachinePointIDs = (
  machines: TAPIMachine[],
  selectedCustomerEquipmentIDs: string[],
  selectedMachineNames: string[]
): number[] => {
  return Array.from(
    new Set([
      ...(selectedCustomerEquipmentIDs?.map(
        equipId => machines.find(m => m.custEquID === equipId)?.pointID
      ) ?? []),
      ...(selectedMachineNames?.map(
        name => machines.find(m => m.machineName === name)?.pointID
      ) ?? []),
    ])
  ).filter(a => a)
}
