import { Link } from 'react-router-dom'
import { Capacitor } from '@capacitor/core'
import { Box, Card, CardContent, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CapacitorDownloadLink from './capacitor-download-link'
import FileLink from './FileLink'

const SometimesLink = ({
  useHref,
  useFileLink,
  enableFileOpen,
  url,
  custID,
  folder,
  fileName,
  children,
  openInNewWindow,
  ...props
}) => {
  if (useHref) {
    if (Capacitor.isNative) {
      return (
        <CapacitorDownloadLink url={url} {...props}>
          {children}
        </CapacitorDownloadLink>
      )
    }

    return (
      <Link
        to={url}
        {...props}
        target={openInNewWindow === true ? '_blank' : ''}
      >
        {children}
      </Link>
    )
  }

  if (useFileLink) {
    return (
      <FileLink
        custID={custID}
        folder={folder}
        fileName={fileName}
        download={true}
        style={{ width: '100%' }}
        {...props}
      >
        {children}
      </FileLink>
    )
  }

  return (
    <Link to={url} {...props}>
      {children}
    </Link>
  )
}

const useStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
  },
  card: {
    backgroundColor: '#fff',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    '& svg': {
      fontSize: '48px',
    },
  },
  textBox: {
    padding: '15px',
  },
  title: {
    color: '#222',
    textDecoration: 'none',
    fontWeight: 'bold',
    fontSize: '1.1em',
    wordWrap: 'break-word'
  },
  description: {
    marginTop: '5px',
    marginBottom: '5px',
  },
})

const NavCard = ({
  key,
  url,
  icon,
  title,
  description,
  background,
  useHref,
  useFileLink,
  custID,
  fileName,
  folder,
  children,
  openInNewWindow,
  ...props
}: any) => {
  const classes = useStyles()

  return (
    <Grid key={key} item xs={12} md={6} lg={4} {...props}>
      <SometimesLink
        enableFileOpen={true}
        useHref={useHref}
        url={url}
        useFileLink={useFileLink}
        custID={custID}
        fileName={fileName}
        folder={folder}
        openInNewWindow={openInNewWindow}
        className="cardHover"
      >
        <Card variant="outlined" style={{ height: '100%' }}>
          <CardContent style={{ padding: 0, height: '100%' }}>
            <Grid container item style={{ height: '100%' }} direction="row">
              <Grid
                item
                xs={3}
                className={classes.iconContainer}
                style={{ background: background, maxWidth: '140px' }}
              >
                {icon}
              </Grid>
              <Grid item xs={9} className={classes.textBox}>
                <Box className={classes.title}>{title}</Box>
                <Box className={classes.description}>
                  {description}
                  {children}
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </SometimesLink>
    </Grid>
  )
}

export default NavCard
