import * as PageStyles from '../../../../page-styles.module.css'
import { Button } from '@material-ui/core'

import { CustomerResponseDTO } from 'types/api'

type TProps = {
  customerData: CustomerResponseDTO
  back: () => void
  next: () => void
}

export const About = (props: TProps) => {
  const buttons = (
    <div className={PageStyles.ButtonContainer}>
      <Button
        data-cancel
        variant="contained"
        color="primary"
        onClick={props.back}
        className={`${PageStyles.Button} ${PageStyles.Left}`}
      >
        Cancel
      </Button>

      <Button
        data-accept
        variant="contained"
        color="secondary"
        onClick={props.next}
        className={`${PageStyles.Button} ${PageStyles.Right}`}
      >
        General Info.
      </Button>
    </div>
  )

  return (
    <>
      <div>
        <h6 className={PageStyles.DetailSectionHeader}>Customer Information</h6>
        <section
          className={`${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer}`}
        >
          <ul className={PageStyles.Details}>
            <li>
              <span className={PageStyles.Property}>
                {props.customerData?.customerID}
                {' - '}
                {props.customerData?.customerName}
              </span>
            </li>
          </ul>
        </section>
        <h6 className={PageStyles.DetailSectionHeader}>About Registration</h6>
        <section className={PageStyles.DetailSection}>
          <p className={PageStyles.FinePrintParagraph}>
            Plant registration will register a plant for the displayed customer.
            This registered plant will be treated as any other plant and will
            extend beyond sample registration: it will be visible and used for
            sample classification on Datasight, it will be retained for future
            sampling, and it will be considered a billable and shippable entity.
            Please ensure all information is accurate and authorized before
            proceeding. Please note that entities created and sampled against
            without any financials will require further action, and samples
            received will be held until appropriate action is taken. For
            questions, concerns, or assistance, please contact customer support
            at{' '}
            <a href={`mailto: ${process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL}`}>
              {process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL!}
            </a>
            {'.'}
          </p>
        </section>
      </div>
      {buttons}
    </>
  )
}
