import React, { useEffect, useRef, useState } from 'react'
import { Grid } from '@material-ui/core'

import { TAPILubricant, TAPILubricantManufacturer } from 'types/api'
import APIService from 'services/api-service'

import { LubeManufacturerFilter, LubeTypeFilter } from '..'
import { CancellablePromise } from 'utils/CancellablePromise'

type TProps = {
  LubeTypeID: number
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onLubeTypeChange: (value: any) => void
}

export const PairedFluidTypeManufacturerFilter = React.memo(
  ({ LubeTypeID, onLubeTypeChange }: TProps) => {
    const [lubeTypes, setLubeTypes] = useState<TAPILubricant[]>(null)
    const [filteredLubeTypes, setFilteredLubeTypes] =
      useState<TAPILubricant[]>(null)
    const [lubeManufacturers, setLubeManufacturers] =
      useState<TAPILubricantManufacturer[]>(null)

    const [mounted, setMounted] = useState(false)

    const [selectedLubeMfg, setSelectedLubeMfg] = useState<string>(null)

    const [selectedLubeType, setSelectedLubeType] = useState<number>(null)

    let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

    const getAllLubricantManufacturers = async () => {
      const promise = APIService.getLubricantManufacturers()
      ongoingCancellablePromises.push(promise)

      const response = await promise
      ongoingCancellablePromises.filter(p => p != promise)

      setLubeManufacturers(response as TAPILubricantManufacturer[])
    }
    const getAllLubricantTypes = async () => {
      const promise = APIService.getLubricantTypes()
      ongoingCancellablePromises.push(promise)

      const response = await promise
      ongoingCancellablePromises.filter(p => p != promise)

      setLubeTypes(response as TAPILubricant[])
    }
    useEffect(() => {
      getAllLubricantTypes()
      getAllLubricantManufacturers()
    }, [])

    useEffect(() => {
      return () => {
        while (ongoingCancellablePromises.length > 0) {
          const promise = ongoingCancellablePromises.pop()
          promise.abortController?.abort()
        }
      }
    }, [])

    useEffect(() => {
      if (!mounted && lubeTypes && lubeManufacturers) {
        if (LubeTypeID) {
          // TODO: Trim data in API, Not front-end
          const lubeTypeMFG = lubeTypes
            .filter(lt => lt.lubeTypeID === LubeTypeID)[0]
            .lubeMFG.trim()

          const lubeMFG = lubeManufacturers.filter(
            lm => lm.pickListValue.trim() === lubeTypeMFG.trim()
          )[0].pickListValue
          setSelectedLubeMfg(lubeMFG)
          setFilteredLubeTypes(
            lubeMFG
              ? lubeTypes.filter(lt => lt.lubeMFG.trim() === lubeMFG.trim())
              : lubeTypes
          )
          setMounted(true)
        }
      }
      setSelectedLubeType(LubeTypeID)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lubeTypes, lubeManufacturers, LubeTypeID])

    const handleLubeTypeChange = value => {
      setSelectedLubeType(value?.lubeTypeID)
      if (!selectedLubeMfg) {
        setSelectedLubeMfg(value?.lubeMFG)
        setFilteredLubeTypes(
          value?.lubeMFG
            ? lubeTypes.filter(lt => lt.lubeMFG.trim() === value.lubeMFG.trim())
            : lubeTypes
        )
      }
      onLubeTypeChange(value ?? '')
    }
    const onLubeMfrChange = value => {
      setSelectedLubeMfg(value)
      setFilteredLubeTypes(
        value
          ? lubeTypes.filter(
              lt => lt.lubeMFG.trim() === value?.pickListValue.trim()
            )
          : lubeTypes
      )
      onLubeTypeChange('')
    }
    return (
      <>
        <Grid item style={{ marginBottom: 15 }}>
          <LubeManufacturerFilter
            required={true}
            single={true}
            value={selectedLubeMfg}
            label={
              !selectedLubeMfg && !selectedLubeType
                ? 'Select a Fluid Manufacturer'
                : !lubeManufacturers
                ? 'Loading Fluid Manufacturers...'
                : 'Fluid Manufacturer'
            }
            lubeMfrs={lubeManufacturers}
            onChange={onLubeMfrChange}
            disabled={!lubeTypes && !filteredLubeTypes && !mounted}
          />
        </Grid>
        <Grid item>
          <LubeTypeFilter
            required={true}
            single={true}
            disabled={!lubeTypes && !filteredLubeTypes && !mounted}
            label={
              !selectedLubeMfg && !selectedLubeType
                ? 'Select a Fluid Type'
                : !lubeTypes
                ? 'Loading Fluid Types...'
                : 'Fluid Type'
            }
            lubeTypes={filteredLubeTypes || lubeTypes}
            value={mounted ? selectedLubeType : null}
            onChange={handleLubeTypeChange}
          />
        </Grid>
      </>
    )
  },
  (prev, next) => prev.LubeTypeID === next.LubeTypeID
)
