import {
  createTheme,
  ThemeProvider,
  AccordionActions,
  Grid,
} from '@material-ui/core'
import { SearchButton, ResetButton } from 'components/buttons'
import coreTheme from 'components/theme'
import * as Styles from './index.module.css'
import React, { useContext, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import FilterTooltip from 'components/filters/FilterTooltip/FilterTooltip'
import { TextFieldFilter } from 'components/filters'
import { useProfile } from 'Contexts/ProfileContext'

const theme = createTheme(
  {
    overrides: {
      MuiGrid: {
        root: {
          justifyContent: 'flex-end',
          [coreTheme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
          },
        },
      },
    },
  },
  coreTheme
)

const removeIllegalCharacters = (s: string) => {
  return s.replaceAll('%', '')
}

/**Memoized. This component never changes, ever. */
export const ViewYourReportsBox: React.FC = React.memo(
  props => {
    const machineNameRef = useRef<HTMLInputElement>()

    const profileContext = useProfile()

    const navigate = useNavigate()

    const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      let value = machineNameRef.current.value
      if (value) {
        value = removeIllegalCharacters(value)
        navigate(
          `/reports/oil-analysis?machineName=${encodeURIComponent(value)}`
        )
      }
    }

    const handleReset = () => {
      machineNameRef.current.value = ''
    }

    return (
      <form
        style={{ width: '100%', marginBottom: '10px' }}
        onSubmit={e => handleSearch(e)}
      >
        <p
          style={{
            padding: '2rem',
            fontSize: '1.7rem',
            margin: '0',
            textAlign: 'center',
          }}
        >
          Find Your Reports
        </p>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <FilterTooltip
            id="find_your_reports-filter_tooltip"
            title={
              profileContext.dependentData.userDetails.isSullairUser
                ? 'The serial number of your machine. This only finds exact matches.'
                : 'The name of your machine. This only finds exact matches.'
            }
          >
            <TextFieldFilter
              inputProps={{ maxLength: 50 }}
              id="machine-name"
              inputRef={machineNameRef}
              placeholder={
                profileContext.dependentData.userDetails.isSullairUser
                  ? 'Serial No'
                  : 'Machine Name'
              }
            />
          </FilterTooltip>
        </div>

        <ThemeProvider theme={theme}>
          <div style={{ marginTop: '2rem' }}>
            <AccordionActions style={{ justifyContent: 'center' }}>
              <Grid
                container
                item
                direction="row"
                spacing={3}
                xs={12}
                justify={'center'}
              >
                <Grid item>
                  <SearchButton id="find_your_reports-search_button" />
                </Grid>
                <Grid item>
                  <ResetButton
                    id="find_your_reports-reset_button"
                    onResetClick={handleReset}
                  />
                </Grid>
              </Grid>
            </AccordionActions>
          </div>
        </ThemeProvider>
      </form>
    )
  },
  (prev, next) => true
)
