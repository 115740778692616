import React, { HTMLProps, useLayoutEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useKeenSlider } from 'keen-slider/react'

//We break the ui with too many of these guys.
const maxSlides = 100

export type TSliderProps = {
  cardList: React.ReactNode[][]
  outerRef: React.Ref<HTMLDivElement>
  onIndexChange?: (index: number) => void
}
//**Note that these sliders must render twice. The second render obtains the slider render size and sets the card sizes appropriately. */
export const Slider = (props: TSliderProps) => {
  const { cardList, outerRef } = props

  const slides = cardList.slice(0, maxSlides).map((list, i) => {
    return (
      <Grid
        container
        item
        className={`keen-slider__slide number-slide${i}`}
        xs={12}
        spacing={1}
        key={i}
      >
        {list}
      </Grid>
    )
  })

  const options = {
    initial: 0,
    slideChanged(s) {
      onSlideChange(s.details().relativeSlide)
    },
  }
  const [currentSlide, setCurrentSlide] = React.useState(0)

  const onSlideChange = (slide: number) => {
    props.onIndexChange && props.onIndexChange(slide)
    setCurrentSlide(slide)
  }

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>(options)

  useLayoutEffect(() => {
    if (slides.length !== slider?.details().size) {
      slider?.refresh()
    }
  }, [slides.length, slider])

  return (
    <>
      <div className="navigation-wrapper" ref={outerRef}>
        <div ref={sliderRef} className="keen-slider">
          {slides}
        </div>
        {slider && (
          <>
            <ArrowLeft
              onClick={e => {
                e.stopPropagation()
                slider.prev()
              }}
              disabled={currentSlide === 0}
            />
            <ArrowRight
              onClick={e => {
                e.stopPropagation()
                slider.next()
              }}
              disabled={currentSlide === slides.length - 1}
            />
          </>
        )}
      </div>
      {slider && (
        <div className="dots">
          {slides.map((_card, idx) => (
            <button
              type="button"
              key={idx}
              onClick={() => {
                slider.moveToSlideRelative(idx)
              }}
              className={'dot' + (currentSlide === idx ? ' active' : '')}
            />
          ))}
        </div>
      )}
    </>
  )
}

type TArrowProps = {
  disabled?: boolean
} & HTMLProps<SVGElement>
const ArrowLeft = (props: TArrowProps) => {
  const disabled = props.disabled ? ' arrow--disabled' : ''
  return (
    <svg
      onClick={props.onClick}
      className={'arrow arrow--left' + disabled}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
    </svg>
  )
}

const ArrowRight = (props: TArrowProps) => {
  const disabled = props.disabled ? ' arrow--disabled' : ''
  return (
    <svg
      onClick={props.onClick}
      className={'arrow arrow--right' + disabled}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
    </svg>
  )
}
