import React, { useCallback, useEffect, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { Box, Grid, Typography } from '@material-ui/core'
import { SortDescriptor } from '@progress/kendo-data-query'
import { GridSortChangeEvent } from '@progress/kendo-react-grid'
import moment from 'moment'
import {
  TAPICustomer,
  TAPILubricant,
  TAPIMachine,
  TAPIPlant,
  TAPISample,
} from 'types/api'
import AppLayout from 'components/AppLayout'
import { ExportButton } from 'components/buttons'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import SEO from 'components/SEO'
import {
  SampleStatusReportTable,
  tableFormatter,
} from 'components/tables/SampleStatusReport'
import APIService, { TProfile } from 'services/api-service'
import { exportData } from 'services/export-service'
import { getSelectedCustomerIDs } from 'services/selection-service'
import { useProfile } from 'Contexts/ProfileContext'
import FilterAccordion, {
  TAccordionFilterValues,
} from 'components/FilterAccordion'
import { mapToPageParameters } from 'components/FilterAccordion/Utilities'
import {
  ANDOR2,
  DateFilterSet,
  LubricantFilterSet,
  MachineFilterSet,
  OptionsFilterSet,
  OrganizationalFilterSet,
  SampleFilterSet,
} from 'components/FilterAccordion/types'
import { CancellablePromise } from 'utils/CancellablePromise'

const initialStartDate = moment()
  .subtract(3, 'months')
  .startOf('month')
  .startOf('day')
  .toDate()
const initialEndDate = moment().endOf('month').endOf('day').toDate()

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

const defaults = {
  page: 1,
  pageSize: 10,
  startDate: initialStartDate,
  endDate: initialEndDate,
  selectedDivisionIDs: [] as number[],
  selectedCustomerIDs: [] as number[],
  selectedPlants: [] as number[],
  selectedCustEquIDs: [] as string[],
  selectedMachineNames: [] as string[],
  selectedRoutes: [],
  selectedMachineConditions: [],
  selectedLubeConditions: [],
  selectedReportTypes: [],
  selectedSampleStatuses: [],
  exportFormat: '',
  orderConfig: [{ field: 'sampleDate', dir: 'desc' }] as SortDescriptor[],
  lastOrderConfig: [] as SortDescriptor[],
  machineLubeConditionANDOR: 'AND' as ANDOR2,
}

let isInitialLoad = true
interface OwnProps {
  onError?: (_err: Error) => void // passed in from layout wrapper
  offline?: boolean
}

const SampleStatusReport: React.FC<OwnProps> = ({
  onError,
  offline,
}: OwnProps) => {
  const [state, setState] = useState({
    ...defaults,
    samplesLoaded: false,
    count: 0,
    samples: [] as TAPISample[],
    searching: false as boolean,
    loadedDefaults: false,
  })

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  const fetchSamples = useCallback(
    async variables => {
      try {
        const promise = APIService.getSampleStatus(variables)

        ongoingCancellablePromises.push(promise)
        const data = await promise

        ongoingCancellablePromises.filter(p => p != promise)

        if (data.sampleStatus !== null) {
          onError(null)
          setState(prev => ({
            ...prev,
            samples: data.sampleStatus.items,
            count: data.sampleStatus.count,
          }))
        }
      } catch (ex) {
        onError(ex)
      } finally {
        setState(prev => ({ ...prev, samplesLoaded: true }))
      }
    },
    [onError]
  )

  const performSearch = useCallback(async () => {
    setState(prev => ({ ...prev, searching: false, samplesLoaded: false }))
    await fetchSamples({
      machineLubeConditionANDOR: state.machineLubeConditionANDOR,
      page: state.page,
      pageSize: state.pageSize,
      orderList: state.orderConfig?.map(x => x.dir) ?? [],
      orderByList: state.orderConfig?.map(x => x.field) ?? [],
      startDate: state.startDate,
      endDate: state.endDate,
      customerIDs: getSelectedCustomerIDs(
        profileContext.profile.customers,
        profileContext.profile.divisions,
        state.selectedDivisionIDs,
        state.selectedCustomerIDs
      ),
      plantIDs: state.selectedPlants,
      machineNames: state.selectedMachineNames,
      custEquIDs: state.selectedCustEquIDs,
      routeIDs: state.selectedRoutes,
      machineConditions: state.selectedMachineConditions,
      lubeConditions: state.selectedLubeConditions,
      reportTypes: state.selectedReportTypes,
      sampleStatusCodes: state.selectedSampleStatuses,
    })
  }, [
    fetchSamples,
    state.endDate,
    state.orderConfig,
    state.page,
    state.pageSize,
    state.selectedCustEquIDs,
    state.selectedCustomerIDs,
    state.selectedDivisionIDs,
    state.selectedLubeConditions,
    state.selectedMachineConditions,
    state.selectedMachineNames,
    state.selectedPlants,
    state.selectedReportTypes,
    state.selectedRoutes,
    state.selectedSampleStatuses,
    state.startDate,
  ])

  useEffect(() => {
    return () => {
      isInitialLoad = true
    }
  }, [])

  const profileContext = useProfile()

  useEffect(() => {
    if (profileContext.dependentData.filters.minimumFilterOptionsLoaded) {
      setState(prev => ({
        ...prev,
        selectedCustomerIDs:
          profileContext.dependentData.filters.initialFilterState.customers.map(
            c => c.custID
          ),
        selectedDivisionIDs:
          profileContext.dependentData.filters.initialFilterState.divisions.map(
            d => d.iD
          ),
        loadedDefaults: true,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext.dependentData.filters.minimumFilterOptionsLoaded])

  useEffect(() => {
    if (!state.samplesLoaded && !state.searching && state.loadedDefaults) {
      performSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.samplesLoaded, state.loadedDefaults])

  useEffect(() => {
    if (state.searching) {
      unstable_batchedUpdates(() => {
        setState(prev => ({ ...prev, searching: false }))
        performSearch()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searching])

  const handleResetClick = () => {
    setState(prev => ({
      ...prev,
      ...defaults,
      selectedCustomerIDs:
        profileContext.dependentData.filters.initialFilterState.customers.map(
          c => c.custID
        ),
      selectedDivisionIDs:
        profileContext.dependentData.filters.initialFilterState.divisions.map(
          d => d.iD
        ),
      count: 0,
      samplesLoaded: false,
    }))
  }

  const handleExportFormatChange = async (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    format
  ) => {
    try {
      const promise = APIService.getSampleStatus({
        page: 1,
        pageSize: 100000,
        orderList: state.orderConfig?.map(x => x.dir) ?? [],
        orderByList: state.orderConfig?.map(x => x.field) ?? [],
        startDate: state.startDate,
        endDate: state.endDate,
        customerIDs: getSelectedCustomerIDs(
          profileContext.profile.customers,
          profileContext.profile.divisions,
          state.selectedDivisionIDs,
          state.selectedCustomerIDs
        ),
        plantIDs: state.selectedPlants,
        machineNames: state.selectedMachineNames,
        custEquIDs: state.selectedCustEquIDs,
        routeIDs: state.selectedRoutes,
        machineConditions: state.selectedMachineConditions,
        lubeConditions: state.selectedLubeConditions,
        reportTypes: state.selectedReportTypes,
        sampleStatusCodes: state.selectedSampleStatuses,
      })

      ongoingCancellablePromises.push(promise)
      const data = await promise

      ongoingCancellablePromises.filter(p => p != promise)

      const formatted = tableFormatter(
        data.sampleStatus.items,
        profileContext.dependentData.userDetails.isDivisionUser
      )
      exportData('sample-status', formatted, format.key)
    } catch (ex) {
      onError(ex)
    }
  }

  const handlePageChange = event => {
    setState(prev => ({
      ...prev,
      page: event.page.skip / state.pageSize + 1,
      pageSize: event.page.take,
      samplesLoaded: false,
    }))
  }

  const handleSortChange = (event: GridSortChangeEvent) => {
    const isFieldRemoved = state.orderConfig.length > event.sort.length
    let isLastFieldRemoved = false

    if (isFieldRemoved) {
      const fieldRemoved = state.orderConfig.filter(
        x => !event.sort.includes(x)
      )[0]

      isLastFieldRemoved =
        state.orderConfig.indexOf(fieldRemoved) === state.orderConfig.length - 1
    }

    setState(prev => ({
      ...prev,
      orderConfig: [...event.sort],
      samplesLoaded: !(event.sort.length > 0 && !isLastFieldRemoved),
    }))
  }

  const handlePageChangeMobile = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage
  ) => {
    setState(prev => ({ ...prev, page: newPage + 1 }))
  }

  const handlePageSizeChangeMobile = event => {
    setState(prev => ({
      ...prev,
      page: 1,
      pageSize: parseInt(event.target.value, 10),
    }))
  }

  const handleFilterSubmission = (data: TAccordionFilterValues) => {
    const mappedParameters = mapToPageParameters(data)

    setState(prev => ({
      ...prev,
      ...mappedParameters,
      page: 1,
      searching: true,
      samplesLoaded: false,
    }))
  }

  if (
    isInitialLoad &&
    ((profileContext.profile.divisions?.length > 0 &&
      state.selectedDivisionIDs.length === 0) ||
      (profileContext.profile.customers?.length > 0 &&
        state.selectedCustomerIDs.length === 0))
  ) {
    isInitialLoad = false
    return null
  }

  return (
    <>
      <SEO title="Sample Status Report" />

      <Box mb={2}>
        <Typography variant="h1">Sample Status</Typography>
      </Box>
      {offline ? <OfflineBanner /> : <></>}

      <FilterAccordion
        pageName="Sample Status"
        defaultValues={profileContext.dependentData.filters.initialFilterState}
        onSubmit={handleFilterSubmission}
        onReset={handleResetClick}
        filters={{
          options: [OptionsFilterSet.Prefiltering],
          date: [DateFilterSet.FromDate, DateFilterSet.ToDate],
          organization: [OrganizationalFilterSet.All],
          machine: [
            MachineFilterSet.Name,
            MachineFilterSet.Conditions,
            MachineFilterSet.EquipmentIDs,
          ],
          lubricant: [LubricantFilterSet.Conditions],
          sample: [SampleFilterSet.ReportTypes, SampleFilterSet.SampleStatus],
          miscellaneous: [],
        }}
      />
      <form>
        <Grid
          container
          direction="row"
          xs={12}
          spacing={1}
          justify="flex-end"
          style={{ marginTop: '15px' }}
        >
          <Grid container item direction="row" spacing={1} justify="flex-end">
            <Grid item>
              <ExportButton
                onChange={handleExportFormatChange}
                disabled={state.samples.length < 1}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>

      <Box mt={4}>
        {!state.samplesLoaded && <Loader />}
        {state.samplesLoaded && (
          <SampleStatusReportTable
            offline={offline}
            samples={state.samples}
            page={state.page}
            pageSize={state.pageSize}
            count={state.count}
            orderConfig={state.orderConfig}
            onSortChange={handleSortChange}
            onPageChange={handlePageChange}
            onPageChangeMobile={handlePageChangeMobile}
            onPageSizeChangeMobile={handlePageSizeChangeMobile}
          />
        )}
      </Box>
    </>
  )
}

export default function Page() {
  return (
    <AppLayout tab="reports">
      <SampleStatusReport />
    </AppLayout>
  )
}
