import styled from '@emotion/styled'

function TrainingBanner() {
    return (
        <StyledDiv>
            <TitleText>
                Join our next Comprehensive Oil Analysis Training in Cleveland, OH a 3 Day oil analysis training course that includes visit to our lab.  Seats are limited.
            </TitleText>
            <SubtitleText>
                Follow this <a
                    style={{ cursor: 'pointer', color: '#6200EE' }}
                    href='https://forms.testoil.com/acton/fs/blocks/showLandingPage/a/4748/p/p-013f/t/page/fm/0' target='_blank'>link</a> to sign up.
            </SubtitleText>
        </StyledDiv>
    )
}

export default TrainingBanner


const StyledDiv = styled.div`
    text-align: center;
    padding: 1rem;
    background-color: rgba(215, 217, 232, .75); 
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    margin-bottom: 10px;
    border-radius: 4px;

    @media (max-width: 800px) {
      row-gap: 1rem;
    } 
`

const TitleText = styled.div`
    font-size: 1.5rem;

    @media (max-width: 800px) {
      font-size: 1.2rem;
    }   
`;

const SubtitleText = styled.div`
    font-size: 1.2rem;

    @media (max-width: 800px) {
      font-size: 1rem;
      font-weight: 500;
    } 
`;