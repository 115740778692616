import { Tooltip } from '@material-ui/core'
import React, { useState } from 'react'

import { TAPIPlant } from 'types/api'

import { VirtualSelectFilter } from '../BaseFilters/VirtualSelectFilter'
import FilterTooltip from '../FilterTooltip/FilterTooltip'

type TProps = {
  single: boolean
  required: boolean
  plants: TAPIPlant[]
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange?: (props: TAPIPlant | TAPIPlant[]) => void
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setState?: (props: any) => void
  defaultValue?: number | number[] | TAPIPlant | TAPIPlant[]
  value?: number | number[] | TAPIPlant | TAPIPlant[]
  disabled?: boolean
  tooltip?: boolean
  variant?: 'filled' | 'outlined' | 'standard'
  onBlur?: (props: TAPIPlant | TAPIPlant[]) => void
  getOptionDisabled?: (option: any) => boolean
  // resetKey: any
}

/** 
Filter requires either onChange handler which accepts an array of plants or setState which sets the currently selected plant or plants.
Key accepts a key that, if changed, resets the filters.
*/
export const PlantNameFilter = ({
  single,
  required,
  plants,
  onChange,
  defaultValue,
  value,
  tooltip = true,
  ...rest
}: TProps) => {
  const filter = (
    <VirtualSelectFilter
      label="Plant"
      htmlFor="plantIDs"
      required={required}
      multiple={!single}
      id="plants"
      {...rest}
      getOptionSelected={(option, value) => option?.plantID === value?.plantID}
      defaultValue={defaultValue}
      value={value}
      renderValue={(selected: TAPIPlant) => selected?.plantName}
      options={plants}
      onChange={onChange}
    ></VirtualSelectFilter>
  )

  return tooltip ? (
    <FilterTooltip title="A facility that contains the sampled machine.">
      {filter}
    </FilterTooltip>
  ) : (
    filter
  )
}
