import React from 'react'
import { Link } from 'react-router-dom'
import { Checkbox, Grid, Tooltip } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import GetAppIcon from '@material-ui/icons/GetApp'
import { GridCellProps } from '@progress/kendo-react-grid'
import moment from 'moment'

import PayButton from 'components/buttons/PayButton'
import FileLink from 'components/FileLink'
import { reportTypes } from 'services/lookup-service'
import { getSampleProblems } from 'utils/api-utilities'
import getMachineManufacturerFromOptions, {
  TMachineManufacturersListItem,
} from 'utils/getMachineManufacturerFromOptions'
import getMachineTypeFromOptions, {
  TMachineTypeList,
} from 'utils/getMachineTypeFromOptions'
import {
  getSalesInvoiceStatusDescription,
  SalesInvoiceStatus,
} from 'utils/getSalesInvoiceStatusDescription'
import { openInNewTab } from 'utils/openInNewTab'

import Condition from '../ConditionElement'
import { TSalesInvoiceItem } from 'types/api'
import ReceiptButton from 'components/buttons/ReceiptButton'

type TDefaultOwnProps =
  | {
      dataItem?: any
      field?: string
    }
  | GridCellProps

type RenderMachineTypeProps = {
  machineTypeOptions: TMachineTypeList[]
} & TDefaultOwnProps

type RenderMachineManufacturerProps = {
  machineMfgOptions: TMachineManufacturersListItem[]
} & TDefaultOwnProps

type RenderMachineNameEditProps = {
  readOnly: boolean
  onMachineEditClick: (_pointID: number) => void
} & TDefaultOwnProps

type RenderScheduleMonthCheckboxProps = {
  onScheduleChange: (_event, _schedule) => void
  readOnly: boolean
} & TDefaultOwnProps

type TRenderSampleStatusProps = {
  dataItem: {
    sampleStatus:
      | 'COM'
      | 'ENT'
      | 'ER1'
      | 'ER2'
      | 'ER3'
      | 'ERR'
      | 'INC'
      | 'RSV'
      | 'REV'
      | 'QTY'
  }
}

interface RenderSamplesSummaryLinkProps extends GridCellProps {
  startDate: Date
  endDate: Date
}

interface RenderSalesInvoiceOperationsProps extends GridCellProps {
  selectedInvoices: TSalesInvoiceItem[]
  onSelectInvoice: (invoice: TSalesInvoiceItem, checked: boolean) => void
  onPayClick: (invoice: any) => void
  onViewReceiptClick: (invoiceNumber: string) => void
}

interface RenderMachineConditionSummaryLinkProps extends GridCellProps {
  startDate: Date
  endDate: Date
  machineCondition: Number
}

interface RenderLubeConditionSummaryLinkProps extends GridCellProps {
  startDate: Date
  endDate: Date
  lubeCondition: Number
}

export const RenderDate = ({ dataItem, field }: TDefaultOwnProps) => {
  return <td>{!dataItem[field] ? '' : moment(dataItem[field]).format('l')}</td>
}

export const RenderSpecificDate = ({ date }: { date: Date }) => {
  return <td>{moment(date).format('l')}</td>
}

export const RenderSampleStatus = (props: TRenderSampleStatusProps) => {
  const { sampleStatus } = props.dataItem
  console.log(sampleStatus);
  switch (sampleStatus) {
    case 'ENT':
    case 'ER1':
    case 'ER2':
    case 'ER3':
    case 'ERR':
    case 'INC':
    case 'RSV':
    case 'QTY':
      return <td>In Progress</td>
    case 'COM':
      return <td>Completed</td>
    case 'REV':
      return <td>On Hold</td>
    default:
      return <td>{sampleStatus}</td>
  }
}

export const RenderRoute = ({ dataItem, field }: TDefaultOwnProps) => {
  const route = dataItem[field] ?? dataItem.machine?.route
  return (
    <td>
      {route &&
        `${route?.routeNo ?? dataItem.routeNo} - ${
          route?.name ?? dataItem.routeName
        }`}
    </td>
  )
}

export const RenderCustomerReviewed = (props: TDefaultOwnProps) => (
  <td>{props.dataItem[props.field] ? <CheckIcon /> : ' '}</td>
)

export const RenderMachineName = (props: TDefaultOwnProps) =>
  !props.dataItem.sampleStatus || props.dataItem.sampleStatus === 'COM' ? (
    <td style={{ color: '#6200EE' }}>
      <a
        href={`/dart/data?labID=${props.dataItem.labID}`}
        target="_blank"
        rel="noreferrer"
      >
        {props.dataItem.machine?.machineName ?? props.dataItem[props.field]}
      </a>
    </td>
  ) : (
    <Tooltip
      title={
        <h6>
          This sample is still in process and the data will be available once
          analysis has been completed
        </h6>
      }
    >
      <td>
        {props.dataItem.machine?.machineName ?? props.dataItem[props.field]}
      </td>
    </Tooltip>
  )

export const RenderMachineNameEdit = (props: RenderMachineNameEditProps) => {
  return (
    <td>
      {props.readOnly ? (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => props.onMachineEditClick(props.dataItem.pointID)}
        >
          {props.dataItem.machineName}
        </span>
      ) : (
        <a
          style={{ color: '#6200EE' }}
          href="#"
          onClick={() => props.onMachineEditClick(props.dataItem.pointID)}
        >
          {props.dataItem.machineName}
        </a>
      )}
    </td>
  )
}

export const RenderMachineType = ({
  dataItem,
  machineTypeOptions,
}: RenderMachineTypeProps) => {
  return (
    <td>
      {getMachineTypeFromOptions({
        machineType: dataItem.machine?.machType ?? dataItem.machType,
        machineTypeOptions,
      })}
    </td>
  )
}

export const RenderMachineTypeMobile = ({
  dataItem,
  machineTypeOptions,
}: RenderMachineTypeProps) => {
  return (
    <td style={{ border: 'none', paddingLeft: '0px' }}>
      {getMachineTypeFromOptions({
        machineType: dataItem.machine?.machType ?? dataItem.machType,
        machineTypeOptions,
      })}
    </td>
  )
}

export const RenderMachineManufacturer = ({
  dataItem,
  machineMfgOptions,
}: RenderMachineManufacturerProps) => {
  return (
    <td>
      {getMachineManufacturerFromOptions({
        machineManufacturer: dataItem.machMFG,
        machineManufacturereOptions: machineMfgOptions,
      })}
    </td>
  )
}

export const RenderReportType = ({ dataItem, field }: TDefaultOwnProps) => {
  const reportType = dataItem[field]
  return (
    <td>
      {(reportType >= 0 &&
        reportTypes.find(y => y.value === reportType).text) ??
        ''}
    </td>
  )
}

export const RenderMachineCondition = (props: TDefaultOwnProps) => (
  <td>
    <Condition
      condition={props.dataItem[props.field]}
      issueContributors={getSampleProblems(props.dataItem)}
    />
  </td>
)

export const RenderLubeCondition = (props: TDefaultOwnProps) => (
  <td>
    <Condition
      condition={props.dataItem[props.field]}
      issueContributors={getSampleProblems(props.dataItem)}
    />
  </td>
)

export const RenderEmptyIfZero = (props: TDefaultOwnProps) => (
  <td>
    {props.dataItem[props.field] === 0 ? '' : props.dataItem[props.field]}
  </td>
)

export const RenderLabNo = ({ dataItem, field }: TDefaultOwnProps) => {
  const value = dataItem[field]
  let custID = dataItem.machine?.plant?.customer?.custID ?? dataItem.custID

  if (!custID) custID = dataItem.customerID

  // There should always be a sample status, but if there isn't, allow the labID link to post. Only do not post if it is actively not complete.
  return (
    <>
      {/* {dataItem.batchID > 0 && */}
      {(dataItem.batchID > 0 && !dataItem.sampleStatus) ||
      dataItem.sampleStatus === 'COM' ? (
        <td style={{ color: '#6200EE' }}>
          <Grid direction="row" container justify="center">
            <a
              rel="noreferrer"
              target="_blank"
              href={`${process.env.REACT_APP_API_URL}/datafiles/${custID}/${value}.pdf`}
            >
              {value}
            </a>
            <FileLink custID={custID} fileName={`${value}.pdf`} download={true}>
              <GetAppIcon fontSize="small" />
            </FileLink>
          </Grid>
        </td>
      ) : (
        <Tooltip
          title={
            <h6>
              This sample is still in process and the data will be available
              once analysis has been completed
            </h6>
          }
        >
          <td>{value}</td>
        </Tooltip>
      )}
    </>
  )
}

export const RenderLabNoMobile = ({ dataItem, field }: TDefaultOwnProps) => {
  const value = dataItem[field]
  const custID = dataItem.machine?.plant?.customer?.custID ?? dataItem.custID

  // There should always be a sample status, but if there isn't, allow the labID link to post. Only do not post if it is actively not complete.
  return (
    <>
      {dataItem.batchID > 0 &&
      (!dataItem.sampleStatus || dataItem.sampleStatus === 'COM') ? (
        <td style={{ color: '#6200EE', border: 'none', paddingLeft: '0px' }}>
          <Grid direction="row" container justify="center">
            <a
              rel="noreferrer"
              target="_blank"
              href={`${process.env.REACT_APP_API_URL}/datafiles/${custID}/${value}.pdf`}
            >
              {value}
            </a>
            <FileLink custID={custID} fileName={`${value}.pdf`} download={true}>
              <GetAppIcon fontSize="small" />
            </FileLink>
          </Grid>
        </td>
      ) : (
        <Tooltip
          title={
            <h6>
              This sample is still in process and the data will be available
              once analysis has been completed
            </h6>
          }
        >
          <td style={{ border: 'none', paddingLeft: '0px' }}>{value}</td>
        </Tooltip>
      )}
    </>
  )
}

export const RenderArrayAsStringCommas = ({
  dataItem,
  field,
}: TDefaultOwnProps) => <td>{dataItem[field]?.join(', ')}</td>

export const RenderTestGroupsAsStringCommas = ({
  dataItem,
  field,
}: TDefaultOwnProps) => (
  <td>
    {dataItem[field]
      .map(x => x.testCode.testCodeName)
      .sort()
      .join(', ')}
  </td>
)

export const RenderTestGroupNamesAsStringCommas = ({
  dataItem,
}: TDefaultOwnProps) => (
  <td>
    {dataItem.machSchedules !== null
      ? dataItem.machSchedules
          .map(x => x.testGroup.testGroupName)
          .sort()
          .join(', ')
      : ''}
  </td>
)

export const RenderTestGroupCodesAsStringCommas = ({
  dataItem,
}: TDefaultOwnProps) => (
  <td>
    {dataItem.machSchedules !== null
      ? dataItem.machSchedules
          .map(y =>
            y.testGroup.testGroupDefs.map(x => x.testCode.testCodeName).sort()
          )
          .reduce((prev, cur) => prev.concat(cur), [])
          .join(', ')
      : ''}
  </td>
)

export const RenderScheduleMonthCheckbox = ({
  dataItem,
  field,
  readOnly,
  onScheduleChange,
}: RenderScheduleMonthCheckboxProps) => {
  return (
    <td>
      {readOnly ? (
        dataItem[field] ? (
          <CheckIcon
            style={{ width: '42px' }}
            onClick={event => onScheduleChange(event, dataItem)}
          />
        ) : (
          <CheckIcon
            style={{ visibility: 'hidden', width: '42px' }}
            onClick={event => onScheduleChange(event, dataItem)}
          />
        )
      ) : (
        <Checkbox
          name={field}
          checked={dataItem[field]}
          onChange={event => onScheduleChange(event, dataItem)}
        />
      )}
    </td>
  )
}

export const RenderSamplesSummaryLink = ({
  dataItem,
  field,
  startDate,
  endDate,
}: RenderSamplesSummaryLinkProps) => (
  <td>
    <Link
      to={`/reports/summary-report?custID=${
        dataItem.plant.customer.custID
      }&plantID=${dataItem.plant.plantID}&startDate=${moment(
        startDate
      ).format()}&endDate=${moment(endDate).format()}`}
    >
      {dataItem[field]}
    </Link>
  </td>
)

export const RenderMachineConditionSummaryLink = ({
  dataItem,
  field,
  startDate,
  endDate,
  machineCondition,
}: RenderMachineConditionSummaryLinkProps) => (
  <td>
    <Link
      to={`/reports/summary-report?custID=${
        dataItem.plant.customer.custID
      }&plantID=${dataItem.plant.plantID}&startDate=${moment(
        startDate
      ).format()}&endDate=${moment(
        endDate
      ).format()}&machineCondition=${machineCondition}`}
    >
      {dataItem[field]}
    </Link>
  </td>
)

export const RenderLubeConditionSummaryLink = ({
  dataItem,
  field,
  startDate,
  endDate,
  lubeCondition,
}: RenderLubeConditionSummaryLinkProps) => (
  <td>
    <Link
      to={`/reports/summary-report?custID=${
        dataItem.plant.customer.custID
      }&plantID=${dataItem.plant.plantID}&startDate=${moment(
        startDate
      ).format()}&endDate=${moment(
        endDate
      ).format()}&lubeCondition=${lubeCondition}`}
    >
      {dataItem[field]}
    </Link>
  </td>
)

export const RenderSalesInvoiceStatusLink = ({
  dataItem,
  field,
}: GridCellProps) => (
  <td>
    <span
      className={
        SalesInvoiceStatus[dataItem[field]] === SalesInvoiceStatus.PastDue
          ? 'highlight'
          : ''
      }
    >
      {getSalesInvoiceStatusDescription(dataItem[field])}
    </span>
  </td>
)

export const RenderSalesInvoiceOperations = ({
  dataItem,
  selectedInvoices,
  onSelectInvoice,
  onViewReceiptClick,
}: RenderSalesInvoiceOperationsProps) => {
  return (
    <td>
      {dataItem.status !== SalesInvoiceStatus.Paid ? (
        <Checkbox
          checked={
            selectedInvoices
              ?.map(x => x.invoiceNumber)
              .includes(dataItem.invoiceNumber) ?? false
          }
          onChange={(e, checked) => {
            onSelectInvoice(dataItem, checked)
          }}
        />
      ) : (
        <ReceiptButton
          onClick={() => onViewReceiptClick(dataItem.invoiceNumber)}
        />
      )}
    </td>
  )
}

export const RenderSalesInvoiceNumberLink = ({
  dataItem,
  field,
  onInvoiceClick,
}: GridCellProps & { onInvoiceClick: (invoiceNumber: string) => void }) => (
  <td>
    <a href="#" onClick={() => onInvoiceClick(dataItem.invoiceNumber)}>
      {dataItem[field]}
    </a>
  </td>
)
