import { routes } from 'App'
import SampleRegistration from './Pages/SampleRegistration'

export const getSampleRegistrationBaseURL = () =>
  routes.find(r => r.element === SampleRegistration)?.path

export function formatCoordinates(loc: GeolocationCoordinates) {
  if (!loc || !loc.latitude || !loc.longitude) return ''
  return `${loc.latitude}, ${loc.longitude}`
}

export const frequencyOptions = [
  { frequencyName: 'Monthly', frequency: 12 },
  { frequencyName: 'Quarterly', frequency: 4 },
  { frequencyName: 'Biannual', frequency: 2 },
  { frequencyName: 'Yearly', frequency: 1 },
]

export const bottleIDRegex =
  /((?<=BottleID=)(?<bottleID>[^&]+)|(?<=EncryptedID=)(?<encryptedID>[^&]+)|(?<=B=)(?<reducedBottleID>[^&]+)|(?<=E=)(?<reducedEncryptedID>[^&]+))/g
