import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'

if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
  // SECTION Tool Details
  console.log(
    '%cDatasight',
    'font-size:30px;font-weight:600;text-decoration:underline'
  )
  console.log('%cConfiguration Details', 'font-size:20px;font-weight:600')

  console.log(
    `%cActive Environment:%c ${process.env.REACT_APP_ENVIRONMENT}`,
    'font-size:16px;font-weight:500;',
    'font-size:16px;font-weight:500;color: red;text-transform: uppercase;'
  )

  // !SECTION Tool Details

  // SECTION: API Details

  // !SECTION: API Details
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
