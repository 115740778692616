import React, { useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  createStyles,
  FormControl,
  Grid,
  Hidden,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import { KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'

const useStyles = makeStyles(() =>
  createStyles({
    innerTable: {
      '& thead': {
        '& tr': {
          '& th': {
            padding: '5px',
          },
        },
      },
      '& tbody': {
        '& tr': {
          '& td': {
            padding: '5px',
          },
        },
      },
    },
  })
)

interface OwnProps {
  sample: any
  correctiveActions: any[]
  onCorrectiveActionSave: any
  onFeedbackOpen: any
  onCorrectiveActionDelete: any
  readonly: boolean
}

const DetailReportRow: React.FC<OwnProps> = ({
  sample,
  correctiveActions,
  onCorrectiveActionSave,
  onFeedbackOpen,
  onCorrectiveActionDelete,
  readonly,
}: OwnProps) => {
  const classes = useStyles()

  const [edit, setEdit] = useState(false)
  const [correctiveAction, setCorrectiveAction] = useState(
    sample.cuCorrAct === null
      ? {
          action: 19,
          note: '',
          date: new Date(),
          oilChangeDate: null,
          newOil: '',
          workOrderNumber: '',
        }
      : {
          action: sample.cuCorrAct.cACode,
          note: sample.cuCorrAct.cANote,
          date: sample.cuCorrAct.cADate,
          oilChangeDate: sample.cuCorrAct.oCDate,
          newOil: sample.cuCorrAct.newOil,
          workOrderNumber: sample.cuCorrAct.workOrderNumber,
        }
  )

  const handleCorrectiveActionChange = (
    event: React.ChangeEvent<{
      name?: string
      value: unknown
    }>
  ) => {
    setCorrectiveAction({
      ...correctiveAction,
      [event.target.name]: event.target.value,
    })
  }

  const handleCorrectiveActionDateChange = (
    name: string,
    value: moment.Moment
  ) => {
    setCorrectiveAction({
      ...correctiveAction,
      [name]: value,
    })
  }

  const handleCorrectiveActionSaveClick = () => {
    setEdit(false)
    onCorrectiveActionSave(sample, correctiveAction)
  }

  const handleCorrectiveActionDeleteClick = () => {
    onCorrectiveActionDelete(sample, () => setEdit(false))
  }

  return (
    <>
      <Hidden mdUp>
        <Card
          variant="outlined"
          style={{ marginBottom: '10px', marginTop: '20px' }}
        >
          <CardContent>
            <Grid container direction="row" spacing={1}>
              {sample.latestFeedback?.feedback && (
                <Grid item xs={6} md={3}>
                  Feedback
                </Grid>
              )}
              {sample.latestFeedback?.feedback && (
                <Grid item xs={6} md={3}>
                  {sample.latestFeedback.feedback}
                </Grid>
              )}
              <Grid item xs={6} md={3}>
                Notes
              </Grid>
              <Grid item xs={6} md={3}>
                {sample.rptNotes}
              </Grid>
              <Grid item xs={6} md={3}>
                Action
              </Grid>
              <Grid item xs={6} md={3}>
                {!edit && (
                  <>
                    {sample.cuCorrAct !== null &&
                    correctiveActions.findIndex(
                      x => x.listValue === sample.cuCorrAct.cACode
                    ) !== -1
                      ? correctiveActions.find(
                          x => x.listValue === sample.cuCorrAct.cACode
                        ).listDesc
                      : ''}
                  </>
                )}
                {edit && (
                  <>
                    <Select
                      style={{ width: '200px' }}
                      name="action"
                      value={correctiveAction.action}
                      onChange={handleCorrectiveActionChange}
                    >
                      {correctiveActions.map((x, i) => (
                        <MenuItem key={i} value={x.listValue}>
                          {x.listDesc}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </Grid>
              <Grid item xs={6} md={3}>
                Note
              </Grid>
              <Grid item xs={6} md={3}>
                {!edit && (
                  <>
                    {sample.cuCorrAct !== null ? sample.cuCorrAct.cANote : ''}
                  </>
                )}
                {edit && (
                  <>
                    {' '}
                    <TextField
                      style={{ width: '200px' }}
                      name="note"
                      value={correctiveAction.note}
                      onChange={handleCorrectiveActionChange}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={6} md={3}>
                Wo#
              </Grid>
              <Grid item xs={6} md={3}>
                {!edit && (
                  <>
                    {sample.cuCorrAct !== null
                      ? sample.cuCorrAct.workOrderNumber
                      : ''}
                  </>
                )}
                {edit && (
                  <>
                    {' '}
                    <TextField
                      style={{ width: '75px' }}
                      name="workOrderNumber"
                      value={correctiveAction.workOrderNumber}
                      onChange={handleCorrectiveActionChange}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={6} md={3}>
                Date
              </Grid>
              <Grid item xs={6} md={3}>
                {sample.cuCorrAct !== null
                  ? moment(sample.cuCorrAct.cADate).format('l')
                  : ''}
              </Grid>
              <Grid item xs={6} md={3}>
                Oil Change Date
              </Grid>
              <Grid item xs={6} md={3}>
                {!edit && (
                  <>
                    {sample.cuCorrAct !== null
                      ? sample.cuCorrAct.oCDate !== null
                        ? moment(sample.cuCorrAct.oCDate).format('l')
                        : ''
                      : ''}
                  </>
                )}
                {edit && (
                  <>
                    {' '}
                    <KeyboardDatePicker
                      style={{ width: '150px' }}
                      clearable
                      placeholder=""
                      name="oilChangeDate"
                      value={correctiveAction.oilChangeDate}
                      onChange={value =>
                        handleCorrectiveActionDateChange('oilChangeDate', value)
                      }
                      format="MM/DD/yyyy"
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={6} md={3}>
                New Oil
              </Grid>
              <Grid item xs={6} md={3}>
                {!edit && (
                  <>
                    {sample.cuCorrAct !== null ? sample.cuCorrAct.newOil : ''}
                  </>
                )}
                {edit && (
                  <>
                    {' '}
                    <TextField
                      style={{ width: '100px' }}
                      name="newOil"
                      value={correctiveAction.newOil}
                      onChange={handleCorrectiveActionChange}
                    />
                  </>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              md={12}
              direction="row"
              style={{ marginTop: '20px' }}
            >
              <Grid container xs={6} md={6} justify="flex-start">
                {sample.latestFeedback === null ||
                sample.latestFeedback.feedback === null ||
                sample.latestFeedback.feedback === '' ? (
                  <Button
                    type="button"
                    onClick={() => onFeedbackOpen(sample)}
                    variant="outlined"
                    color="primary"
                    style={{ height: 'auto' }}
                  >
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                    >
                      <EmailIcon />
                      Feedback
                    </Grid>
                  </Button>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid
                container
                justify="flex-end"
                alignItems="center"
                xs={6}
                md={6}
              >
                {!edit ? (
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setEdit(true)}
                    >
                      Add/Edit
                    </Button>
                    &nbsp;
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCorrectiveActionDeleteClick}
                      disabled={sample.cuCorrAct === null}
                    >
                      Delete
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleCorrectiveActionSaveClick}
                    >
                      Save
                    </Button>
                    &nbsp;
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setEdit(false)}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Hidden>

      <Hidden smDown>
        <Table className={classes.innerTable}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '200px' }}>
                {sample.latestFeedback?.feedback ? 'Feedback' : ''}
              </TableCell>
              <TableCell style={{ width: '200px' }}>Notes</TableCell>
              <TableCell style={{ width: '200px' }}>Action</TableCell>
              <TableCell style={{ width: '200px' }}>Note</TableCell>
              <TableCell style={{ width: '75px' }}>Wo#</TableCell>
              <TableCell style={{ width: '150px' }}>Date</TableCell>
              <TableCell style={{ width: '150px' }}>Oil Change Date</TableCell>
              <TableCell style={{ width: '100px' }}>New Oil</TableCell>
              <TableCell style={{ width: '175px' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={{ textAlign: 'left' }}>
                {sample.latestFeedback === null ||
                sample.latestFeedback.feedback === null ||
                sample.latestFeedback.feedback === '' ? (
                  <Button
                    type="button"
                    onClick={() => onFeedbackOpen(sample)}
                    variant="outlined"
                    color="primary"
                    style={{ height: 'auto' }}
                  >
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                    >
                      <EmailIcon />
                      Feedback
                    </Grid>
                  </Button>
                ) : (
                  <>
                    <Button style={{ pointerEvents: 'none' }}>
                      <EmailIcon style={{ color: '#f07d7d' }} />
                    </Button>
                    {sample.latestFeedback.feedback}
                  </>
                )}
              </TableCell>
              <TableCell>{sample.rptNotes}</TableCell>
              {!edit ? (
                <>
                  <TableCell>
                    {sample.cuCorrAct !== null &&
                    correctiveActions.findIndex(
                      x => x.listValue === sample.cuCorrAct.cACode
                    ) !== -1
                      ? correctiveActions.find(
                          x => x.listValue === sample.cuCorrAct.cACode
                        ).listDesc
                      : ''}
                  </TableCell>
                  <TableCell>
                    {sample.cuCorrAct !== null ? sample.cuCorrAct.cANote : ''}
                  </TableCell>
                  <TableCell>
                    {sample.cuCorrAct !== null
                      ? sample.cuCorrAct.workOrderNumber
                      : ''}
                  </TableCell>
                  <TableCell>
                    {sample.cuCorrAct !== null
                      ? moment(sample.cuCorrAct.cADate).format('l')
                      : ''}
                  </TableCell>
                  <TableCell>
                    {sample.cuCorrAct !== null
                      ? sample.cuCorrAct.oCDate !== null
                        ? moment(sample.cuCorrAct.oCDate).format('l')
                        : ''
                      : ''}
                  </TableCell>
                  <TableCell>
                    {sample.cuCorrAct !== null ? sample.cuCorrAct.newOil : ''}
                  </TableCell>
                  <TableCell
                    style={{
                      width: 300,
                      verticalAlign: 'top',
                      paddingTop: '10px',
                    }}
                  >
                    {!readonly && (
                      <>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => setEdit(true)}
                        >
                          Add/Edit
                        </Button>
                        &nbsp;
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleCorrectiveActionDeleteClick}
                          disabled={sample.cuCorrAct === null}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell>
                    <FormControl>
                      <InputLabel>Corrective Action</InputLabel>
                      <Select
                        style={{ width: '200px' }}
                        name="action"
                        value={correctiveAction.action}
                        onChange={handleCorrectiveActionChange}
                      >
                        {correctiveActions.map((x, i) => (
                          <MenuItem key={i} value={x.listValue}>
                            {x.listDesc}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl>
                      <TextField
                        style={{ width: '200px' }}
                        name="note"
                        value={correctiveAction.note}
                        label="Note"
                        onChange={handleCorrectiveActionChange}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl>
                      <TextField
                        style={{ width: '75px' }}
                        name="workOrderNumber"
                        value={correctiveAction.workOrderNumber}
                        label="Wo#"
                        onChange={handleCorrectiveActionChange}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    {moment(correctiveAction.date).format('l')}
                  </TableCell>
                  <TableCell>
                    <KeyboardDatePicker
                      style={{ width: '150px' }}
                      clearable
                      label="Oil Change Date"
                      placeholder=""
                      name="oilChangeDate"
                      value={correctiveAction.oilChangeDate}
                      autoOk
                      onChange={value =>
                        handleCorrectiveActionDateChange('oilChangeDate', value)
                      }
                      format="MM/DD/yyyy"
                    />
                  </TableCell>
                  <TableCell>
                    <FormControl>
                      <TextField
                        style={{ width: '100px' }}
                        name="newOil"
                        value={correctiveAction.newOil}
                        label="New Oil"
                        onChange={handleCorrectiveActionChange}
                        inputProps={{ maxLength: 50 }}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell style={{ width: 300 }}>
                    <>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleCorrectiveActionSaveClick}
                      >
                        Save
                      </Button>
                      &nbsp;
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setEdit(false)}
                      >
                        Cancel
                      </Button>
                    </>
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </Hidden>
    </>
  )
}

export default DetailReportRow
