import React, { useEffect, useState } from 'react'

interface IReportParametersSortable {
  SortColumn?: string
  SortDirection?: string
}

interface IReportParametersCommonFilters {
  Customers: number[]
  Plants: number[]
  From: Date
  To: Date
  CorporateContactID?: number
}

export interface IReportParametersCommonExceptions
  extends IReportParametersSortable,
    IReportParametersCommonFilters {}

export interface IReportParametersConditionSummary
  extends IReportParametersSortable,
    IReportParametersCommonFilters {}

export interface IReportParametersLabelsList extends IReportParametersSortable {
  Customers: number[]
  machines: number[]
  month: number | string
}

export interface IReportParametersLabelsSheet {
  batchID: string
  isAvery: boolean
}

export interface IReportParametersLabelsDymo
  extends IReportParametersLabelsSheet {}

export interface IReportParametersMVC
  extends IReportParametersSortable,
    IReportParametersCommonFilters {
  CustomerName?: string
  ReportComments?: string
  ReportDate: Date
}

export interface IReportParametersProgramSnapshot
  extends IReportParametersSortable {
  DisplayName?: string
  TitleDateString?: string
  CategoryIDs: number[]
  StartDate?: Date
  EndDate?: Date
  CorporateContactID?: number
  CustomerID?: number
  FiscalYearStartMonth?: number
  MinimumExceptionCount?: number
}

export interface IReportParametersRecurringException
  extends IReportParametersSortable,
    IReportParametersCommonFilters {
  numberOfExceptions?: number
}
export interface IReportParametersScheduleCompliance
  extends IReportParametersSortable,
    Omit<IReportParametersCommonFilters, 'From' | 'To'> {
  machines: number[]
  routes: number[]
  testPackages: number[]
}

export interface IReportParametersScheduledSamplesDue
  extends IReportParametersScheduleCompliance {}

export interface IReportParametersSummary
  extends IReportParametersSortable,
    IReportParametersCommonFilters {
  Routes?: number[]
  ReportTypes?: string[]
  MachineConditions?: number[]
  LubeConditions?: number[]
}
export interface IReportParameters extends IReportParametersSortable {}

export type TTestoilReportAssemblies = keyof typeof reportAssembliesMap

export type TReportParametersAll = IReportParametersProgramSnapshot &
  IReportParametersCommonExceptions &
  IReportParametersConditionSummary &
  IReportParametersMVC &
  IReportParametersRecurringException &
  IReportParametersScheduleCompliance &
  IReportParametersScheduledSamplesDue &
  IReportParametersSummary &
  IReportParametersLabelsList &
  IReportParametersLabelsSheet

export type TReportSource =
  | {
      report: 'programSnapshot'
      parameters: IReportParametersProgramSnapshot
    }
  | {
      report: 'commonExceptions'
      parameters: IReportParametersCommonExceptions
    }
  | {
      report: 'conditionSummary'
      parameters: IReportParametersConditionSummary
    }
  | {
      report: 'mvc'
      parameters: IReportParametersMVC
    }
  | {
      report: 'recurringException'
      parameters: IReportParametersRecurringException
    }
  | {
      report: 'scheduleCompliance'
      parameters: IReportParametersScheduleCompliance
    }
  | {
      report: 'scheduledSamplesDue'
      parameters: IReportParametersScheduledSamplesDue
    }
  | {
      report: 'summary'
      parameters: IReportParametersSummary
    }
  | {
      report: 'labelsList'
      parameters: IReportParametersLabelsList
    }
  | {
      report: 'labelsSheet'
      parameters: IReportParametersLabelsSheet
    }
  | {
      report: 'labelsDymo'
      parameters: IReportParametersLabelsDymo
    }

export type TTelerikReportViewerOptions = {
  reportSource: Omit<TReportSource, 'report'> & { report: string }
  serviceUrl: string
  scale?: number
  viewMode?: 'INTERACTIVE' | 'PRINT_PREVIEW'
  pageMode?: 'SINGLE_PAGE' | 'CONTINUOUS_SCROLL'
  printMode?: 'AUTO_SELECT' | 'FORCE_PDF_PLUGIN' | 'FORCE_PDF_FILE'
  scaleMode?: 'FIT_PAGE' | 'FIT_PAGE_WIDTH'
  sendEmail?: { enabled: boolean }
}
export type TProps = {
  reportSource: TReportSource
}

/**
 * A map of simplified report names to C# namespace.classname. This is used to
 * type the `reportSource.report` props. Note: the report viewer requires the
 * full assembly name, but the common parts are omitted here and cat'ed in the
 * component implementation.
 */
const reportAssembliesMap = {
  programSnapshot: 'Program_Snapshot.ReportBook',
  commonExceptions: 'CommonExceptionReport',
  conditionSummary: 'ConditionSummary',
  mvc: 'MVC.MVCReportBook',
  recurringException: 'Recurring_Exception.RecurringException',
  scheduleCompliance: 'Schedule_Compliance.ScheduleCompliance',
  scheduledSamplesDue: 'Scheduled_Samples_Due.ScheduledSamplesDue',
  summary: 'Summary.Summary',
  labelsList: 'Labels.LabelsList',
  labelsSheet: 'Labels.LabelsSheet',
  labelsDymo: 'Labels.LabelsDymo',
}

export const availableReports = Object.keys(reportAssembliesMap)

export const TelerikReportViewer = (props: TProps) => {
  const reportSource: Omit<TReportSource, 'report'> & { report: string } = {
    ...props.reportSource,
    report: `TelerikReports.${
      reportAssembliesMap[props.reportSource.report]
    }, TelerikReports, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null`,
  }
  const [viewerInitialized, setViewerInitialized] = useState(false)
  const [
    authorizationHeadersFilterInitialized,
    setAuthorizationHeadersFilterInitialized,
  ] = useState(false)
  // this is required because the janky profile injection in AppLayout completely hoses the render scheduler.
  const jankyMemo = JSON.stringify(reportSource)

  // Attach authorization headers to jQuery requrests
  useEffect(() => {
    if (!authorizationHeadersFilterInitialized) {
      if (typeof window !== 'undefined') {
        // Attaching jQ prefilter for authorization headers
        window.jQuery.ajaxPrefilter(options => {
          if (!options.beforeSend) {
            options.beforeSend = function (xhr) {
              xhr.setRequestHeader(
                'Authorization',
                localStorage.getItem('access_token')
              )
            }
          }
        })
        setAuthorizationHeadersFilterInitialized(true)
      }
    }
  }, [authorizationHeadersFilterInitialized])

  useEffect(() => {
    if (viewerInitialized) {
      // REFRESHING existing report viewer...
      const reportViewer = window
        .jQuery('#ReportViewer')
        .data('telerik_ReportViewer')
      reportViewer.reportSource(reportSource)
      reportViewer.refreshReport()
    }
  }, [jankyMemo]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authorizationHeadersFilterInitialized) {
      if (!viewerInitialized) {
        // logRendering inital report viewer...'
        const telerikReportConfig: TTelerikReportViewerOptions = {
          serviceUrl: process.env.REACT_APP_REPORTING_API_URL,
          reportSource: reportSource,
          scale: 1.0,
          scaleMode: 'FIT_PAGE_WIDTH',
          viewMode: 'PRINT_PREVIEW',
          printMode: 'FORCE_PDF_FILE',
          sendEmail: { enabled: true },
        }
        window.jQuery('#ReportViewer').telerik_ReportViewer(telerikReportConfig)
        setViewerInitialized(true)
      }
    }
  }, [authorizationHeadersFilterInitialized]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      style={{ width: '95vw', height: '95vh', marginTop: '2%' }}
      id="ReportViewer"
    ></div>
  )
  // The componentDidMount() method runs after the component output has been rendered to the DOM.
}
