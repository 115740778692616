import React, { useLayoutEffect, useRef, useState } from 'react'

function useMinWidth(minWidth: number) {
  const ref = useRef<HTMLElement>(null)
  const [belowMinWidth, setBelowMinWidth] = useState(false)

  const resizeEvent = () => {
    const width = ref.current?.getBoundingClientRect().width ?? 0
    if (width < minWidth) setBelowMinWidth(true)
    else if (width >= minWidth) setBelowMinWidth(false)
  }

  useLayoutEffect(() => {
    resizeEvent()
    window?.addEventListener('resize', resizeEvent)
    return () => window.removeEventListener('resize', resizeEvent)
  }, [ref])

  return [belowMinWidth, ref]
}

export default useMinWidth
