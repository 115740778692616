import { usePromise, UsePromiseState } from 'hooks/usePromise'
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import APIService from 'services/api-service'
import {
  BottleStatusDetails,
  GetBottlesBaseResponseDTO,
  PatchEncryptedBottlesResponse,
  Sample,
} from 'types/api'
import { BottleStatusCode, TLoading } from 'types/custom.d'
import { FetchError } from 'utils/Errors/FetchError'
import { BottleComplete } from '../Cards/BottleComplete'
import { BottleDataLoading } from '../Cards/BottleDataLoading'
import { BottleDataLoadingError } from '../Cards/BottleDataLoadingError'
import { BottleDataNoCustomer } from '../Cards/BottleDataNoCustomer'
import { BottleDataNotFound } from '../Cards/BottleDataNotFound'
import { BottleInTest } from '../Cards/BottleInTest'
import { BottleOnIssue } from '../Cards/BottleOnIssue'
import { BottleLinked } from '../Cards/BottleLinked'
import {
  PageState,
  PageStateDoNotShowFindAnotherBottleSet,
  TWarhammerSampleWithoutIdentifier,
} from '../types-and-enums'
import { BottleDataInformationEntryScreen } from './information-entry/BottleDataInformationEntryScreen'
import { PageBody } from './PageContainers'
import { BottleIdentifierInput } from '../Cards/BottleIdentifierInput'
import { useIsLoggedIn } from 'hooks/useIsLoggedIn'
import { LoggedInNavButtons } from '../Cards/LoggedInNavButtons'
import { CancellablePromise } from 'utils/CancellablePromise'
import { useBottleRegistrationContext } from '../registrationContext'
import { BottleForbidden } from '../Cards/BottleForbidden'
import { useProfile } from 'Contexts/ProfileContext'
import { bottleIDRegex } from '../constants'
import { routes } from 'App'
import {
  LoadingState,
  LoadingStateWithoutReset,
} from '../Cards/BottleInformationEntry/CreateMachine/types'

const SampleRegistration = () => {
  const [pageState, setPageState] = useState(PageState.BottleDataLoading)
  const [userProvidedBottleIdentifier, setUserProvidedBottleIdentifier] =
    useState<string>('')

  const bottleIDParamState = useBottleParamID({
    setUserProvidedBottleIdentifier,
  })

  useBottleIDStateNavigator({ bottleIDParamState, setPageState })

  const { loggedIn: isLoggedIn, loading: loggedInLoading } = useIsLoggedIn()
  const profileContext = useProfile()

  const { handleSubmit: handleBottleDetailSubmit, ...bottleSubmitState } =
    useBottleDetailSubmitter(
      userProvidedBottleIdentifier,
      bottleIDParamState?.data
    )

  const bottleStatusFetchState = useBottleStatus(
    userProvidedBottleIdentifier,
    bottleIDParamState?.data
  )
  const customerAllowedChange = useCustomerConfirmation(
    userProvidedBottleIdentifier,
    bottleStatusFetchState,
    bottleIDParamState.reset,
    isLoggedIn && profileContext.minimumProfileLoaded
      ? profileContext.profile.customers[0].custID
      : null,
    setPageState
  )

  const bottleCustomerIDAttenuationStatus = useBottleCustomerIDAttenuator(
    userProvidedBottleIdentifier,
    customerAllowedChange,
    bottleIDParamState?.data,
    isLoggedIn,
    bottleStatusFetchState
  )

  const customerID =
    isLoggedIn && profileContext.minimumProfileLoaded
      ? profileContext.profile.customers[0].custID
      : bottleStatusFetchState?.data?.customerID ??
        (bottleCustomerIDAttenuationStatus.data &&
        bottleCustomerIDAttenuationStatus.data?.length > 0
          ? bottleCustomerIDAttenuationStatus.data[0].customerID
          : null)

  const bottleData = useBottleData(
    pageState,
    bottleSubmitState,
    userProvidedBottleIdentifier,
    bottleIDParamState?.data
  )

  const bottleResult = bottleData.data?.length > 0 ? bottleData.data[0] : null
  const sampleData = useSampleData(pageState, bottleResult)

  useLoginNavigator(
    userProvidedBottleIdentifier,
    bottleIDParamState?.data,
    isLoggedIn,
    loggedInLoading,
    pageState
  )

  const bottleRegistrationContext = useBottleRegistrationContext()
  const reset = () => {
    bottleIDParamState.reset()
    bottleSubmitState.reset()
    bottleStatusFetchState.reset()
    bottleCustomerIDAttenuationStatus.reset()
    bottleData.reset()
    sampleData.reset()
    setUserProvidedBottleIdentifier('')
    setPageState(PageState.BottleIdentifierEntry)
    bottleRegistrationContext.clear()
  }

  const loading = useLoadingState(
    bottleIDParamState,
    isLoggedIn,
    loggedInLoading,
    bottleData,
    sampleData,
    pageState,
    bottleStatusFetchState,
    bottleSubmitState,
    bottleCustomerIDAttenuationStatus,
    customerID
  )

  useDataStateManager(
    userProvidedBottleIdentifier,
    bottleIDParamState?.data,
    isLoggedIn,
    loggedInLoading,
    bottleStatusFetchState,
    bottleSubmitState,
    bottleCustomerIDAttenuationStatus,
    setPageState,
    customerID,
    customerAllowedChange,
    profileContext.minimumProfileLoaded
      ? profileContext.profile.customers[0].custID
      : null
  )

  useErrorStateManager(
    bottleData,
    sampleData,
    bottleStatusFetchState,
    bottleSubmitState,
    bottleCustomerIDAttenuationStatus,
    setPageState
  )

  const pageBody = getPageBody({
    loading,
    bottleStatusFetchState,
    customerID,
    plantID: bottleRegistrationContext.state.plantID,
    bottleData,
    handleBottleDetailSubmit,
    pageState,
    sampleData,
    setPageState,
    setUserProvidedBottleIdentifier,
    userProvidedBottleIdentifier,
    bottleIDParam: bottleIDParamState?.data,
    bottleCustomerIDAttenuationStatus,
    reset,
  })

  const navigate = useNavigate()

  return (
    <PageBody>
      {pageBody}
      {!PageStateDoNotShowFindAnotherBottleSet.has(pageState) && (
        <LoggedInNavButtons
          page={pageState}
          moveToInputPage={() => {
            reset()

            const route = routes.find(r => r.element === SampleRegistration)
            if (route && route.path) {
              navigate(route.path)
            }
          }}
          loading={loading}
        />
      )}
    </PageBody>
  )
}

export default SampleRegistration

type TPageBodyParams = {
  loading: boolean
  bottleStatusFetchState: UsePromiseState<BottleStatusDetails>
  pageState: PageState
  setUserProvidedBottleIdentifier: React.Dispatch<React.SetStateAction<string>>
  setPageState: React.Dispatch<React.SetStateAction<PageState>>
  handleBottleDetailSubmit: (
    submitData: TWarhammerSampleWithoutIdentifier
  ) => void
  bottleData: UsePromiseState<TWarhammerSampleWithoutIdentifier[] | null>
  sampleData: UsePromiseState<Sample[] | null>
  customerID: number | null
  plantID: number | null
  userProvidedBottleIdentifier: string
  bottleIDParam: string
  bottleCustomerIDAttenuationStatus: UsePromiseState<
    GetBottlesBaseResponseDTO[]
  >
  reset: () => void
}
function getPageBody(params: TPageBodyParams) {
  const {
    loading,
    bottleData,
    customerID,
    plantID,
    pageState,
    handleBottleDetailSubmit,
    setPageState,
    setUserProvidedBottleIdentifier,
    userProvidedBottleIdentifier,
    sampleData,
    bottleIDParam,
    reset,
  } = params

  const bottleResult = bottleData.data?.length > 0 ? bottleData.data[0] : null
  const sampleResult = sampleData?.data?.length > 0 ? sampleData.data[0] : null

  if (loading) {
    return <BottleDataLoading />
  }
  switch (pageState) {
    case PageState.BottleDataLoading: {
      return <BottleDataLoading />
    }
    case PageState.BottleIdentifierEntry: {
      return (
        <BottleIdentifierInput onSubmit={setUserProvidedBottleIdentifier} />
      )
    }
    case PageState.BottleRequestInvalid: {
      return <BottleDataNotFound reset={reset} />
    }
    case PageState.Forbidden: {
      return <BottleForbidden />
    }
    case PageState.ServerError: {
      return <BottleDataLoadingError />
    }
    case PageState.DataNotFound: {
      return <BottleDataNotFound reset={reset} />
    }
    case PageState.NoCustomerAssignedToBottle: {
      return (
        <BottleDataNoCustomer
          encryptedBottleIdentifier={bottleIDParam}
          canLoginToAssign={!!bottleIDParam}
        />
      )
    }
    case PageState.Unlinked: {
      return (
        <BottleDataInformationEntryScreen
          encryptedBottleIdentifier={bottleIDParam}
          userProvidedBottleIdentifier={userProvidedBottleIdentifier}
          sampleDetails={bottleResult}
          setPageState={setPageState}
          customerID={customerID}
          onSubmit={handleBottleDetailSubmit}
          plantID={plantID}
          clearUserBottleIDInput={() => setUserProvidedBottleIdentifier('')}
        />
      )
    }
    case PageState.Linked: {
      if (!bottleResult) {
        return <BottleDataLoading />
      }

      return (
        <BottleLinked
          bottleData={bottleResult}
          navigateToSampleSubmissionPage={() =>
            setPageState(PageState.Unlinked)
          }
        />
      )
    }
    case PageState.OnIssue: {
      return <BottleOnIssue />
    }
    case PageState.Testing: {
      return <BottleInTest bottleData={bottleResult} />
    }
    case PageState.Complete: {
      return (
        <BottleComplete bottleData={bottleResult} sampleData={sampleResult} />
      )
    }
    case PageState.CompleteWithIssue: {
      return <BottleOnIssue />
    }
    case PageState.Unknown: {
      return <BottleDataLoadingError />
    }
    default: {
      return <BottleDataLoadingError />
    }
  }
}

function useBottleIDStateNavigator({
  bottleIDParamState,
  setPageState,
}: {
  bottleIDParamState: LoadingStateWithoutReset<string, Error>
  setPageState: React.Dispatch<React.SetStateAction<PageState>>
}) {
  useEffect(() => {
    if (bottleIDParamState.loaded && !!bottleIDParamState.error) {
      setPageState(PageState.BottleIdentifierEntry)
    }
  }, [bottleIDParamState.loaded])
}

function useBottleCustomerIDAttenuator(
  bottleIDParam: string,
  moveAllowed: boolean,
  encryptedBottleIDParam: string,
  isLoggedIn: boolean,
  bottleData: LoadingState<BottleStatusDetails, FetchError>
): UsePromiseState<GetBottlesBaseResponseDTO[]> {
  const profile = useProfile()

  const profileCustomerID =
    profile.profile.customers.length > 0
      ? profile.profile.customers[0].custID
      : null

  const shouldIssuePromise =
    !!isLoggedIn &&
    !bottleData.loading &&
    !bottleData.error &&
    bottleData.loaded &&
    (!bottleData.data?.customerID ||
      bottleData.data?.customerID !== profileCustomerID) &&
    (!!encryptedBottleIDParam || (bottleIDParam && moveAllowed)) &&
    !!profileCustomerID

  let promiseFunc: () => Promise<GetBottlesBaseResponseDTO[]>
  if (
    !!encryptedBottleIDParam &&
    bottleData.data &&
    profileCustomerID !== bottleData.data.customerID
  ) {
    promiseFunc = () =>
      APIService.patchEncryptedBottlesAsync({
        bottles: [
          {
            encryptedIdentifier: encryptedBottleIDParam,
            customerID: profileCustomerID,
          },
        ],
      })
  } else if (
    bottleIDParam &&
    bottleData.data &&
    bottleData.data.customerID &&
    profileCustomerID &&
    profileCustomerID !== bottleData.data.customerID
  ) {
    promiseFunc = () =>
      APIService.patchBottlesAsync({
        bottles: [
          {
            identifier: bottleIDParam,
            customerID: profileCustomerID,
          },
        ],
      })
  }

  return usePromise({
    shouldStartPromise: data => shouldIssuePromise && !!promiseFunc,
    dependencies: (_, issue) => [
      issue,
      isLoggedIn,
      bottleData.loading,
      encryptedBottleIDParam,
    ],
    promise: promiseFunc,
  })
}

function useCustomerConfirmation(
  userProvidedBottleIdentifier: string,
  bottleData: LoadingState<BottleStatusDetails, FetchError>,
  onCancel: () => void,
  profileCustomerID: number,
  setPageState: React.Dispatch<React.SetStateAction<PageState>>
) {
  const [customerConfirmed, setCustomerConfirmed] = useState(null)
  const { loggedIn: isLoggedIn, loading: loggedInLoading } = useIsLoggedIn()

  useEffect(() => {
    if (
      bottleData.loaded &&
      bottleData.data &&
      bottleData.data?.customerID &&
      profileCustomerID &&
      isLoggedIn &&
      !loggedInLoading &&
      !!userProvidedBottleIdentifier
    ) {
      const changeAllowable = bottleData.data?.status < BottleStatusCode.Linked
      const confirmationRequired =
        bottleData.data?.customerID !== profileCustomerID

      if (!confirmationRequired) {
        setCustomerConfirmed(true)
      } else if (changeAllowable) {
        requestConfirmation(
          `Assigned customer: ${bottleData.data?.customerID}\nYour customer: ${profileCustomerID}\n\nPress OK to assign it to your customer ID of ${profileCustomerID}, or press cancel to return.`
        )
      }
    } else if (bottleData.loaded && !isLoggedIn && !loggedInLoading) {
      setCustomerConfirmed(true)
    }
  }, [
    bottleData.loading,
    bottleData.data,
    isLoggedIn,
    loggedInLoading,
    bottleData.data?.customerID,
    profileCustomerID,
    userProvidedBottleIdentifier,
  ])

  function requestConfirmation(message: string) {
    const confirmation = window.confirm(message)

    if (!confirmation) {
      onCancel()
      setPageState(PageState.BottleIdentifierEntry)
    }

    setCustomerConfirmed(confirmation)
  }

  return customerConfirmed
}

function useBottleDetailSubmitter(
  userProvidedBottleIdentifier: string,
  bottleIDParam: string
) {
  const [submitting, setSubmitting] = useState(false)

  let promiseFunction =
    useRef<() => Promise<TWarhammerSampleWithoutIdentifier[]>>(null)
  const issuePromise = promiseFunction !== null && submitting

  const res = usePromise<TWarhammerSampleWithoutIdentifier[]>({
    shouldStartPromise: data => issuePromise,
    promise: promiseFunction.current,
    dependencies: (_, issue) => [issue, submitting],
  })

  const invalidUpdateError = new FetchError({
    status: 403,
    title: 'Update Outdated',
    detail:
      'The requested update is no longer valid for this sample. Its details may no longer be modified.',
  })

  function handleSubmit(submitData: TWarhammerSampleWithoutIdentifier) {
    if (!!bottleIDParam) {
      //if it is encrypted bottle identifier then send to encrypted.
      promiseFunction.current = () =>
        APIService.putEncryptedWarhammerSamples({
          userID: 22,
          samples: [
            {
              ...submitData,
              encryptedBottleIdentifier: bottleIDParam,
            },
          ],
        }).then(async r => {
          const bottleStatus = await APIService.getEncryptedBottleStatusAsync({
            encryptedIdentifier: bottleIDParam,
          })

          if (
            !bottleStatus.customerID ||
            bottleStatus.status >= BottleStatusCode.ReceivedByLab
          ) {
            throw invalidUpdateError
          }

          await APIService.patchEncryptedBottlesAsync({
            bottles: [
              {
                encryptedIdentifier: bottleIDParam,
                status: BottleStatusCode.ShippedToLab,
                customerID: submitData.customerID,
              },
            ],
          })
          setSubmitting(false)
          return r
        })
    } else if (userProvidedBottleIdentifier) {
      //they entered it manually.
      promiseFunction.current = () =>
        APIService.putWarhammerSamples({
          userID: 22,
          samples: [
            {
              ...submitData,
              bottleIdentifier: userProvidedBottleIdentifier,
            },
          ],
        }).then(async r => {
          const bottleStatus = await APIService.getBottleStatusAsync({
            identifier: userProvidedBottleIdentifier,
          })

          if (
            !bottleStatus.customerID ||
            bottleStatus.status >= BottleStatusCode.ReceivedByLab
          ) {
            throw invalidUpdateError
          }

          await APIService.patchBottlesAsync({
            bottles: [
              {
                identifier: userProvidedBottleIdentifier,
                status: BottleStatusCode.Linked,
                customerID: submitData.customerID,
              },
            ],
          })
          setSubmitting(false)
          return r
        })
    }

    setSubmitting(true)
  }

  return { handleSubmit, ...res }
}

function useDataStateManager(
  userProvidedBottleIdentifier: string | null,
  bottleIDParam: string | null | undefined,
  isLoggedIn: boolean,
  loggedInLoading: boolean,
  bottleStatusFetchState: UsePromiseState<BottleStatusDetails>,
  bottleSubmitState: UsePromiseState<TWarhammerSampleWithoutIdentifier[]>,
  bottleCustomerIDAttenuationStatus: UsePromiseState<
    GetBottlesBaseResponseDTO[]
  >,
  setPageState: React.Dispatch<React.SetStateAction<PageState>>,
  customerID: number,
  customerAllowedChange: boolean,
  profileCustomerID: number
) {
  useEffect(() => {
    ;(async () => {
      const wrongCustomerID =
        !!bottleStatusFetchState.data?.customerID &&
        !!profileCustomerID &&
        bottleStatusFetchState.data?.customerID !== profileCustomerID
      const cannotChangeCustomerIDs =
        bottleStatusFetchState.data?.status >= BottleStatusCode.Linked
      if (wrongCustomerID && cannotChangeCustomerIDs) {
        setPageState(PageState.Forbidden)
        return
      }

      if (!customerAllowedChange) {
        return
      }

      if (
        !!bottleCustomerIDAttenuationStatus.data &&
        bottleCustomerIDAttenuationStatus.data.length > 0 &&
        bottleCustomerIDAttenuationStatus.data[0].customerID == null
      ) {
        setPageState(PageState.ServerError)
        return
      }

      if (!!bottleSubmitState.data) {
        setPageState(PageState.Linked)
        return
      }

      if (
        isLoggedIn === true &&
        bottleIDParam == null &&
        !userProvidedBottleIdentifier
      ) {
        setPageState(PageState.BottleIdentifierEntry)
        return
      }

      if (bottleStatusFetchState.data) {
        const missingCustomerID = customerID == null
        if (missingCustomerID) {
          setPageState(PageState.NoCustomerAssignedToBottle)
          return
        }

        const sampleUnlinked =
          bottleStatusFetchState.data.status < BottleStatusCode.Linked
        if (sampleUnlinked) {
          setPageState(PageState.Unlinked)
          return
        }

        switch (bottleStatusFetchState.data.status) {
          case BottleStatusCode.Linked: {
            setPageState(PageState.Linked)
            return
          }
          case BottleStatusCode.ShippedToLab: {
            setPageState(PageState.Linked)
            return
          }
          case BottleStatusCode.BatchReceiveIssue: {
            setPageState(PageState.OnIssue)
            return
          }
          case BottleStatusCode.ReceivedByLab: {
            setPageState(PageState.Testing)
            return
          }
          case BottleStatusCode.BatchReceivedBylab: {
            setPageState(PageState.Testing)
            return
          }
          case BottleStatusCode.Testing: {
            setPageState(PageState.Testing)
            return
          }
          //Assuming this means it is still in a testing phase and not yet customer result-bearing.
          case BottleStatusCode.TestingCompleted: {
            setPageState(PageState.Testing)
            return
          }
          case BottleStatusCode.Completed: {
            setPageState(PageState.Complete)
            return
          }

          case BottleStatusCode.CompletedWithIssue: {
            setPageState(PageState.OnIssue)
            return
          }
          default: {
            setPageState(PageState.Unknown)
            return
          }
        }
      }
    })()
  }, [
    isLoggedIn,
    customerAllowedChange,
    loggedInLoading,
    userProvidedBottleIdentifier,
    bottleIDParam,
    customerID,
    bottleStatusFetchState.loading,
    bottleStatusFetchState.error,
    bottleStatusFetchState.data,
    bottleSubmitState.loading,
    bottleSubmitState.error,
    bottleSubmitState.data,
    bottleCustomerIDAttenuationStatus.error,
    bottleCustomerIDAttenuationStatus.data,
    bottleCustomerIDAttenuationStatus.loading,
  ])
}

function useErrorStateManager(
  bottleData: UsePromiseState<TWarhammerSampleWithoutIdentifier[] | null>,
  sampleData: UsePromiseState<Sample[] | null>,
  bottleStatusFetchState: UsePromiseState<BottleStatusDetails>,
  bottleInformationPutState: UsePromiseState<
    TWarhammerSampleWithoutIdentifier[]
  >,
  bottleCustomerIDAttenuationStatus: UsePromiseState<
    GetBottlesBaseResponseDTO[]
  >,
  setPageState: React.Dispatch<React.SetStateAction<PageState>>
) {
  useEffect(() => {
    ;(() => {
      const errors = [
        bottleData.error,
        sampleData.error,
        bottleInformationPutState.error,
        bottleStatusFetchState.error,
        bottleCustomerIDAttenuationStatus.error,
      ].filter(e => !!e)

      if (errors.length === 0) return

      if (errors.some(e => e.status === 400)) {
        setPageState(PageState.BottleRequestInvalid)
        return
      }

      if (errors.some(e => e.status === 401 || e.status === 403)) {
        setPageState(PageState.Forbidden)
        return
      }

      if (errors.some(e => e.status === 428)) {
        setPageState(PageState.NoCustomerAssignedToBottle)
        return
      }

      if (errors.some(e => e.status === 404)) {
        setPageState(PageState.DataNotFound)
        return
      }

      setPageState(PageState.ServerError)
      return
    })()
  }, [
    bottleData.error,
    sampleData.error,
    bottleInformationPutState.error,
    bottleStatusFetchState.error,
    bottleCustomerIDAttenuationStatus.error,
  ])
}

function useLoadingState(
  bottleIDParamState: LoadingState<string, Error>,
  isLoggedIn: boolean,
  loggedInLoading: boolean,
  bottleData: UsePromiseState<TWarhammerSampleWithoutIdentifier[] | null>,
  sampleData: UsePromiseState<Sample[] | null>,
  pageState,
  bottleStatusFetchState: UsePromiseState<BottleStatusDetails>,
  bottleInformationPutState: UsePromiseState<
    TWarhammerSampleWithoutIdentifier[]
  >,
  bottleCustomerIDAttenuationStatus: UsePromiseState<
    GetBottlesBaseResponseDTO[]
  >,
  // setPageState: React.Dispatch<React.SetStateAction<PageState>>,
  customerID: number
) {
  const [loading, setloading] = useState(false)

  const profile = useProfile()
  useEffect(() => {
    const customerDataPending =
      !!isLoggedIn && !profile.minimumProfileLoaded && !customerID

    const loading =
      bottleIDParamState.loading ||
      bottleStatusFetchState.loading ||
      bottleInformationPutState.loading ||
      bottleCustomerIDAttenuationStatus?.loading ||
      loggedInLoading ||
      sampleData?.loading ||
      bottleData?.loading ||
      customerDataPending

    setloading(loading)
  }, [
    PageState,
    bottleStatusFetchState.loading,
    bottleIDParamState.loading,
    bottleInformationPutState.loading,
    bottleCustomerIDAttenuationStatus?.loading,
    loggedInLoading,
    sampleData?.loading,
    bottleData?.loading,
    isLoggedIn,
    profile.minimumProfileLoaded,
    customerID,
  ])

  return loading
}

function useSampleData(
  pageState: PageState,
  bottleData: TWarhammerSampleWithoutIdentifier
) {
  const shouldStartPromise =
    pageState == PageState.Complete && !!bottleData && !!bottleData.labID

  return usePromise<Sample[]>({
    shouldStartPromise: data =>
      shouldStartPromise && !data.loading && !data.data && !data.error,
    promise: () =>
      APIService.getSamplesAsync({
        labIDs: [bottleData.labID],
      }),
    dependencies: (_, issue) => [issue, bottleData?.labID],
  })
}

function useBottleData(
  pageState: PageState,
  bottleSubmitState: UsePromiseState<TWarhammerSampleWithoutIdentifier[]>,
  userProvidedBottleIdentifier: string,
  bottleIDParam: string
) {
  const shouldStartPromise =
    pageState >= PageState.Linked &&
    (!!userProvidedBottleIdentifier || !!bottleIDParam)

  let promiseFunction: () => Promise<TWarhammerSampleWithoutIdentifier[]> = null

  if (!!bottleIDParam) {
    //if it is encrypted bottle identifier then send to encrypted.
    promiseFunction = () =>
      APIService.getEncryptedWarhammerSamplesAsync({
        encryptedBottleIdentifiers: [bottleIDParam],
      })
  } else if (!!userProvidedBottleIdentifier) {
    //they entered it manually.
    promiseFunction = () =>
      APIService.getWarhammerSamplesAsync({
        bottleIdentifiers: [userProvidedBottleIdentifier],
      })
  }

  const res = usePromise<TWarhammerSampleWithoutIdentifier[]>({
    shouldStartPromise: data =>
      !bottleSubmitState.loading &&
      !bottleSubmitState.data &&
      !bottleSubmitState.error &&
      shouldStartPromise,
    promise: promiseFunction,
    dependencies: (_, issue) => [
      issue,
      userProvidedBottleIdentifier,
      bottleIDParam,
    ],
  })

  const finalResponse = {
    data: bottleSubmitState?.data ?? res?.data,
    error: bottleSubmitState?.error ?? res?.error,
    loading: bottleSubmitState?.loading || res?.loading,
    reset: res?.reset,
  } as UsePromiseState<TWarhammerSampleWithoutIdentifier[]>

  const bottleRegistrationContext = useBottleRegistrationContext()
  useEffect(() => {
    if (res.loaded) {
      bottleRegistrationContext.updateFixedState(
        'validEncryptedIdentifier',
        !!bottleIDParam && !finalResponse.error && !!finalResponse.data
      )
    }
  }, [res.loading, bottleIDParam])

  return finalResponse
}

const useBottleStatus = (
  userProvidedBottleIdentifier: string | null,
  encryptedBottleID: string | null | undefined
) => {
  let getPromise: () => CancellablePromise<BottleStatusDetails> = null
  let dependencies = [userProvidedBottleIdentifier, encryptedBottleID]

  if (!!encryptedBottleID) {
    getPromise = () =>
      APIService.getEncryptedBottleStatusAsync({
        encryptedIdentifier: encryptedBottleID,
      })
  } else if (!!userProvidedBottleIdentifier) {
    getPromise = () =>
      APIService.getBottleStatusAsync({
        identifier: userProvidedBottleIdentifier,
      })
  }

  const issuePromise = !!getPromise
  return usePromise<BottleStatusDetails>({
    shouldStartPromise: data =>
      issuePromise && !data.loading && !data.data && !data.error,
    promise: getPromise,
    dependencies: (_, issue) => [...dependencies, issue],
  })
}

const useLoginNavigator = (
  userProvidedBottleIdentifier: string | null,
  encryptedBottleID: string | null | undefined,
  isLoggedIn: boolean,
  loggedInLoading: boolean,
  pageState?: PageState
) => {
  const navigate = useNavigate()

  function navigateLogin() {
    navigate(
      `/login?PostLoginURL=${window.location.pathname}${window.location.search}`
    )
  }

  useEffect(() => {
    ;(() => {
      if (
        !userProvidedBottleIdentifier &&
        !encryptedBottleID &&
        loggedInLoading
      )
        return

      if (
        !userProvidedBottleIdentifier &&
        !encryptedBottleID &&
        !isLoggedIn &&
        !loggedInLoading
      ) {
        navigateLogin()
      }

      if (
        !!userProvidedBottleIdentifier &&
        !encryptedBottleID &&
        !isLoggedIn &&
        !loggedInLoading
      ) {
        navigateLogin()
        return
      }

      if (!encryptedBottleID && !isLoggedIn && !loggedInLoading) {
        navigateLogin()
        return
      }
    })()
  }, [
    encryptedBottleID,
    isLoggedIn,
    pageState,
    loggedInLoading,
    userProvidedBottleIdentifier,
  ])
}

const defaultBottleParamState: Readonly<
  LoadingStateWithoutReset<string, Error>
> = {
  data: '',
  error: null,
  loaded: false,
  loading: false,
}
/**Returns undefined if the bottleID has not yet been searched for, null or string if it has been searched for and not found or found. */
const useBottleParamID = ({ setUserProvidedBottleIdentifier }) => {
  const [bottleIDState, setBottleIDState] = useState<
    LoadingStateWithoutReset<string, Error>
  >({
    ...defaultBottleParamState,
    loading: true,
  })

  let searchString = window.location.search
  useEffect(() => {
    let match: RegExpExecArray = null
    let bottleID: string = null
    let encryptedID: string = null

    while (!!searchString && (match = bottleIDRegex.exec(searchString))) {
      bottleID =
        bottleID ?? match.groups.bottleID ?? match.groups.reducedBottleID
      encryptedID =
        encryptedID ??
        match.groups.encryptedID ??
        match.groups.reducedEncryptedID
    }

    if (!!encryptedID) {
      setBottleIDState(prev => ({
        ...prev,
        data: encryptedID,
        loading: false,
        loaded: true,
      }))
    } else if (!!bottleID) {
      setUserProvidedBottleIdentifier(bottleID)
      setBottleIDState(prev => ({
        ...prev,
        data: null,
        loading: false,
        loaded: true,
      }))
    } else {
      setBottleIDState(prev => ({
        ...prev,
        data: '',
        error: new Error('No bottle ID provided.'),
        loading: false,
        loaded: true,
      }))
      setUserProvidedBottleIdentifier('')
    }
  }, [searchString])

  function reset() {
    setBottleIDState({
      ...defaultBottleParamState,
    })
    setUserProvidedBottleIdentifier('')
  }

  return { ...bottleIDState, reset }
}
