import React, { useRef, useState } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import * as Styles from '../../index.module.css'
import { Popper, Fade } from '@material-ui/core'
import { v4 as uuid } from 'uuid'
import { InformationIcon } from 'components/InformationIcon'

type TProjectionPicker = {
  projectionBasis: 'Scheduled' | 'Historical'
  onProjectionBasisChange: (projectionBasis: 'Scheduled' | 'Historical') => void
}

export const ProjectionPicker: React.FC<TProjectionPicker> = props => {
  const projectionsAnchorRef = React.useRef<HTMLDivElement>(null)
  const [showProjectionsTooltip, setShowProjectionsTooltip] = useState(false)

  const historicalPickerGUID = useRef(uuid())
  const scheduledPickerGUID = useRef(uuid())

  const ProjectionInformationPopper = () => {
    return (
      <InformationIcon style={{ color: 'rgb(50, 50, 50)' }}>
        <h5>Spending Projection Basis</h5>
        <hr />
        <p>Adjust basis upon which spending projections are calculated.</p>
        <h6>Scheduled</h6>
        <p>
          Display projections based upon machine schedules established with
          TestOil.
        </p>
        <h6>Historical</h6>
        <p>
          Project spending based upon the previous year's spending per
          corresponding month.
        </p>
      </InformationIcon>
    )
  }

  return (
    <>
      <div className={Styles.SpendingChartPicker}>
        <div className={Styles.PickerHeader}>
          <h6 className={Styles.PickerHeaderText}>Spending Projection Basis</h6>
          <ProjectionInformationPopper />
        </div>
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div className={Styles.LabeledInput}>
              <input
                type="radio"
                checked={props.projectionBasis === 'Scheduled'}
                name={`projectionBasis-${scheduledPickerGUID.current}`}
                value="Scheduled"
                id={scheduledPickerGUID.current}
                onChange={e =>
                  props.onProjectionBasisChange(e.target.value as 'Scheduled')
                }
              />{' '}
              <label htmlFor={scheduledPickerGUID.current}>Scheduled</label>
            </div>
            <div className={Styles.LabeledInput}>
              <input
                type="radio"
                checked={props.projectionBasis === 'Historical'}
                name={`projectionBasis-${scheduledPickerGUID.current}`}
                value="Historical"
                id={historicalPickerGUID.current}
                onChange={e =>
                  props.onProjectionBasisChange(e.target.value as 'Historical')
                }
              />
              <label htmlFor={historicalPickerGUID.current}>Historical</label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
