import React, { ReactNode, useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import Debug from 'debug'

import useSiteMetadata from 'hooks/useSiteMetadata'
import { Severity } from '@sentry/react'

const log = Debug('DS:SentryProvider')

export const SentryProvider = (props: { children?: ReactNode }) => {
  const { children } = props
  const siteMetadata = useSiteMetadata()
  useEffect(() => {
    const env = process.env.NODE_ENV || 'development'
    if (env !== 'development') {
      log(
        `Initializing sentry with environment: "${env}" @${siteMetadata.package.version}`
      )
      Sentry.init({
        dsn:
        'https://512237f1c97c436cb45515b06cb1a688@o284887.ingest.sentry.io/5594517',
        environment: env,
        release: siteMetadata.package.version,
        maxValueLength: 1024
      })
    }
  }, [siteMetadata.package.version])

  return <>{children}</>
}

export const sendErrorMessageToSentry = (message: string) => {
  Sentry.captureMessage(message, Severity.Error)
}

export default SentryProvider
