import React from 'react'
import { isEdge } from 'react-device-detect'
import { makeStyles, Popover, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  popup: {
    backgroundColor: 'rgb(201.6,210,223.2)',
    padding: '15px',
    border: 'solid',
    borderColor: 'black',
    borderWidth: '1px',
    borderRadius: '3px',
  },
}))

const HelpPopupLink: React.FC = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleHelpClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleHelpClose = () => {
    setAnchorEl(null)
  }

  // if (!isEdge) return null

  return (
    <>
      <a href="#" onClick={handleHelpClick}>
        Having trouble?
      </a>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleHelpClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.popup}>
          {isEdge && (
            <>
              <Typography variant="h6" gutterBottom>
                Microsoft Edge Has Known Issues
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Edge printing instructions can be found{' '}
                <a
                  href="/static/general-files/Datasight_Edge_Browser_Issues.pdf"
                  target="_blank"
                >
                  here
                </a>
                .
              </Typography>
            </>
          )}
          <>
            <Typography
              variant="h6"
              gutterBottom
              style={{ marginTop: isEdge ? '15px' : undefined }}
            >
              Dymo Labels Require Additional Software
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Dymo label printing instructions can be found{' '}
              <a
                href="/static/general-files/Printing Dymo Labels.pdf"
                target="_blank"
              >
                here
              </a>
              .
            </Typography>
          </>
        </div>
      </Popover>
    </>
  )
}

export default HelpPopupLink
