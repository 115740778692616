import React from 'react'
import { TextFieldProps } from '@material-ui/core'

import FilterTooltip from '../FilterTooltip/FilterTooltip'

import { TextFieldFilter } from './text-field-filter'

type TProps = {
  tooltip?: boolean
}

export const LabNumberFilter: React.FC<TextFieldProps> = (
  props: TextFieldProps & TProps
) => {
  const { tooltip = true, ...rest } = props

  const filter = <TextFieldFilter id="labNo" placeholder="Lab No" {...rest} />

  return tooltip ? (
    <FilterTooltip title="A sample's unique reference number.">
      {filter}
    </FilterTooltip>
  ) : (
    filter
  )
}
