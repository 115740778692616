import React, { useState } from 'react'
import {
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
} from '@material-ui/core'
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import clsx from 'clsx'

import coreTheme from '../theme'

const theme = createTheme(
  {
    overrides: {
      MuiInputBase: {
        root: {
          color: '#ffffff',
          width: '190px',
        },
        input: {
          color: '#ffffff',
          backgroundColor: '#a8afba',
          '&::placeholder': {
            color: '#ffffff',
          },
          borderRadius: 3,
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 30px #a8afba inset !important',
            WebkitTextFillColor: '#ffffff !important',
          },
        },
      },
      MuiFormControl: {
        root: {
          backgroundColor: '#a8afba',
        },
      },
      MuiFilledInput: {
        root: {
          color: '#ffffff',
          backgroundColor: '#a8afba',
        },
      },
      MuiFormLabel: {
        root: {
          color: '#eeeeee',
          zIndex: 99,
        },
      },
    },
  },
  coreTheme
)

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '190px',
    borderRadius: 3,
  },
}))

const PasswordInput = props => {
  const classes = useStyles()

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  return (
    <ThemeProvider theme={theme}>
      <FormControl className={clsx(classes.textField)} variant="filled">
        <InputLabel htmlFor="password">
          {props.label}
          {props.required ? ' *' : ''}
        </InputLabel>
        <FilledInput
          {...props}
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </ThemeProvider>
  )
}

export default PasswordInput
