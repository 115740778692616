import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'

import { TAPIMachine, TAPIPlant } from 'types/api'

import {
  CustomerFilter,
  MachineFilter,
  MachineNumberFilter,
  PlantNameFilter,
} from '..'

type TProps = {
  allCustomers: any[]
  allPlants: any[]
  allMachines: any[]
  allMachineNos: any[]
  onMachineChange: (value: any) => void
  initialMachine: any
}

export const DetailedMachineFilter = ({
  allCustomers,
  allPlants,
  allMachines,
  allMachineNos,
  onMachineChange,
  initialMachine,
}: TProps) => {
  const [filteredPlants, setFilteredPlants] = useState<TAPIPlant[]>(allPlants)
  const [filteredMachines, setFilteredMachines] = useState<TAPIMachine[]>(
    allMachines
  )
  const [filteredMachineNos, setFilteredMachineNos] = useState<number[]>(
    allMachineNos
  )

  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [selectedPlant, setSelectedPlant] = useState(null)
  const [selectedMachine, setSelectedMachine] = useState(null)
  const [selectedMachineNo, setSelectedMachineNo] = useState(null)

  const filterMachinesByCustomer = customer => {
    return customer?.plants
      ? allMachines.filter(x =>
          customer.plants
            .reduce((list, item) => list.concat(item.machines), [])
            .some(y => y.pointID === x.pointID)
        )
      : allMachines
  }
  const filterMachineNosByCustomer = customer =>
    customer?.plants
      ? [
          ...new Set(
            customer.plants
              .reduce((list, item) => list.concat(item.machines), [])
              .map(y => y.machNo)
          ),
        ]
      : allMachineNos

  const filterMachinesByNumber = machineNo =>
    allMachines.filter(x => x.machNo === machineNo)

  useEffect(() => {
    if (initialMachine) {
      const plant = allPlants.find(
        x => x.plantID === initialMachine.plant?.plantID
      )

      handleCustomerChange(initialMachine.plant?.customer?.custID)
      handlePlantChange(plant)
      handleMachineNoChange(initialMachine.machNo, plant)
      handleMachineChange(
        allMachines.find(
          x =>
            x.machNo === initialMachine.machNo &&
            x.machineName === initialMachine.machineName
        )
      )
    }
  }, [])

  const handleCustomerChange = value => {
    const customer = allCustomers.find(x => x.custID === value)

    setSelectedCustomer(customer ?? '')

    setSelectedPlant(null)
    setSelectedMachine(null)
    setSelectedMachineNo(null)

    setFilteredPlants(customer ? customer.plants : allPlants)
    setFilteredMachineNos(filterMachineNosByCustomer(customer))

    setFilteredMachines(filterMachinesByCustomer(customer))
  }

  const handlePlantChange = value => {
    setSelectedPlant(value)
    setSelectedMachine(null)
    setSelectedMachineNo(null)

    setFilteredMachineNos(
      value && value.machines
        ? value.machines.map(x => x.machNo)
        : allMachineNos
    )

    setFilteredMachines(
      value
        ? value.machines
        : selectedCustomer
        ? filterMachinesByCustomer(selectedCustomer)
        : allMachines
    )
  }

  const handleMachineNoChange = (value, plant) => {
    setSelectedMachineNo(value)
    setSelectedMachine(null)

    const machines = value ? filterMachinesByNumber(value) : allMachines

    setFilteredMachines(
      machines.filter(x => !plant || x.plant?.plantID === plant.plantID)
    )
  }

  const handleMachineChange = value => {
    setSelectedMachine(value)
    onMachineChange(value)
  }

  return (
    <>
      <Grid item>
        <CustomerFilter
          single={true}
          required={false}
          customers={allCustomers}
          onChange={handleCustomerChange}
          value={selectedCustomer}
        />
      </Grid>
      <Grid item>
        <PlantNameFilter
          single={true}
          required={false}
          plants={filteredPlants}
          onChange={handlePlantChange}
          value={selectedPlant}
        />
      </Grid>
      <Grid item>
        <MachineNumberFilter
          machineNumbers={filteredMachineNos}
          onChange={value => handleMachineNoChange(value, selectedPlant)}
          single={true}
          required={false}
          value={selectedMachineNo}
        />
      </Grid>
      <Grid item>
        <MachineFilter
          machines={filteredMachines}
          onChange={handleMachineChange}
          single={true}
          required={false}
          value={selectedMachine}
        />
      </Grid>
    </>
  )
}
