import React from 'react'

import { SelectFilter } from '../BaseFilters/SelectFilter'
import FilterTooltip from '../FilterTooltip/FilterTooltip'
import { frequencies, months } from 'services/lookup-service'

export type TFrequency = {
  id: string
  text: string
}

type TProps = {
  single: boolean
  required: boolean
  onChange?: (_props: TFrequency | TFrequency[]) => void
  defaultValue?: TFrequency
  tooltip?: boolean
  variant?: 'filled' | 'outlined' | 'standard'
}

export const FrequencyFilter = ({
  single,
  onChange,
  required,
  defaultValue,
  tooltip = true,
  variant,
}: TProps) => {
  const filter = (
    <SelectFilter
      multiple={!single}
      required={required}
      label="Frequency"
      htmlFor="frequency"
      id="frequency"
      variant={variant}
      defaultValue={
        frequencies.find(f => f.text === defaultValue.id) ||
        frequencies.find(f => f.text === 'Yearly')
      }
      // value={defaultValue || 'yearly'}
      renderValue={(selected: TFrequency) => {
        return frequencies?.find(x => x.id === selected.id)?.text ?? ''
      }}
      onChange={onChange}
      options={frequencies}
      getOptionSelected={(option, value) => option.id === value.id}
    ></SelectFilter>
  )
  return tooltip ? (
    <FilterTooltip title="How often a machine has samples analyzed.">
      {filter}
    </FilterTooltip>
  ) : (
    filter
  )
}
