import React, { useCallback, useEffect, useState } from 'react'
import { Box, createTheme, Grid, Typography } from '@material-ui/core'
import { SortDescriptor } from '@progress/kendo-data-query'
import { GridSortChangeEvent } from '@progress/kendo-react-grid'
import moment from 'moment'
import AppLayout from 'components/AppLayout'
import { ExportButton } from 'components/buttons'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import SEO from 'components/SEO'
import {
  ChangeOfConditionsReport,
  tableFormatter,
} from 'components/tables/ChangeOfConditionsReport'
import APIService from 'services/api-service'
import { exportData } from 'services/export-service'
import { frequencies, months } from 'services/lookup-service'
import { getSelectedCustomerIDs } from 'services/selection-service'
import { useProfile } from 'Contexts/ProfileContext'
import FilterAccordion, {
  TAccordionFilterValues,
} from 'components/FilterAccordion'
import { mapToPageParameters } from 'components/FilterAccordion/Utilities'
import { TMonth } from 'components/filters/ConfiguredFilters/FiscalYearStartMonthFilter'
import {
  OptionsFilterSet,
  DateFilterSet,
  OrganizationalFilterSet,
  MachineFilterSet,
} from 'components/FilterAccordion/types'
import { CancellablePromise } from 'utils/CancellablePromise'
import { unstable_batchedUpdates } from 'react-dom'

const initialEndDate = (() => {
  const now = new Date(Date.now())
  return new Date(now.getFullYear(), now.getMonth() + 1, 0)
})()

const initialStartDate = moment()
  .subtract(3, 'months')
  .startOf('month')
  .startOf('day')
  .toDate()

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

let isInitialLoad = true
const defaults = {
  startDate: initialStartDate,
  endDate: initialEndDate,
  selectedDivisionIDs: [],
  selectedCustomerIDs: [],
  selectedCustEquIDs: [],
  selectedPlants: [],
  selectedMachineNames: [],
  exportFormat: '',
  count: 0,
  page: 1,
  pageSize: 10,
  orderConfig: [
    { field: 'currentSample.sampleDate', dir: 'desc' },
  ] as SortDescriptor[],
}

const ChangeOfConditionReport = ({ onError, offline }: any) => {
  const [state, setState] = useState({
    ...defaults,
    exporting: false,
    dataLoaded: false,
    custEquIDs: [],
    machineNames: [],
    equipmentIDs: [],
    data: [],
    frequency: frequencies[2],
    fiscalYearStartMonth: months[0] as TMonth,
    loaded: false as boolean,
    searching: false as boolean,
    loadedDefaults: false,
  })

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  useEffect(() => {
    return () => {
      isInitialLoad = true
    }
  }, [])

  const fetchSamples = useCallback(
    async variables => {
      setState(prev => ({
        ...prev,
        dataLoaded: false,
      }))

      let promise
      try {
        promise = APIService.getChangeOfConditions(variables)

        ongoingCancellablePromises.push(promise)
        const data = await promise

        onError(null)
        setState(prev => ({
          ...prev,
          data: data.conditionChanges.items,
          count: data.conditionChanges.count,
          dataLoaded: true,
          loaded: true,
          searching: false,
        }))
      } catch (ex) {
        onError(ex)
      } finally {
        ongoingCancellablePromises.filter(p => p != promise)
      }
    },
    [onError]
  )

  const performSearch = useCallback(() => {
    fetchSamples({
      customerIDs: getSelectedCustomerIDs(
        profileContext.profile.customers,
        profileContext.profile.divisions,
        state.selectedDivisionIDs,
        state.selectedCustomerIDs
      ),
      plantIDs: state.selectedPlants,
      machineNames: state.selectedMachineNames,
      custEquIDs: state.selectedCustEquIDs,
      frequency: state.frequency?.text?.toLowerCase(),
      fiscalYearStartMonth: state.fiscalYearStartMonth?.id,
      startDate: state.startDate,
      endDate: state.endDate,
      page: state.page,
      pageSize: state.pageSize,
      orderList: state.orderConfig?.map(x => x.dir) ?? [],
      orderByList: state.orderConfig?.map(x => x.field) ?? [],
    })
  }, [
    fetchSamples,
    state.selectedDivisionIDs,
    state.selectedCustomerIDs,
    state.selectedPlants,
    state.selectedMachineNames,
    state.selectedCustEquIDs,
    state.frequency,
    state.fiscalYearStartMonth,
    state.startDate,
    state.endDate,
    state.page,
    state.pageSize,
    state.orderConfig,
  ])

  useEffect(() => {
    if (!state.loaded && !state.searching && state.loadedDefaults) {
      performSearch()
    }
    if (state.searching) {
      unstable_batchedUpdates(() => {
        performSearch()
        setState(prev => ({ ...prev, searching: false }))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loaded, state.loadedDefaults, state.searching])

  const profileContext = useProfile()

  useEffect(() => {
    if (profileContext.dependentData.filters.minimumFilterOptionsLoaded) {
      setState(prev => ({
        ...prev,
        selectedCustomerIDs:
          profileContext.dependentData.filters.initialFilterState.customers.map(
            c => c.custID
          ),
        selectedDivisionIDs:
          profileContext.dependentData.filters.initialFilterState.divisions.map(
            d => d.iD
          ),
        loadedDefaults: true,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext.dependentData.filters.minimumFilterOptionsLoaded])

  const handlePageChange = event => {
    if (!dateValidation()) return
    setState(prev => ({
      ...prev,
      page: event.page.skip / state.pageSize + 1,
      pageSize: event.page.take,
      dataLoaded: false,
      searching: true,
    }))
  }

  const handleSortChange = (event: GridSortChangeEvent) => {
    if (!dateValidation()) return
    const isFieldRemoved = state.orderConfig.length > event.sort.length
    let isLastFieldRemoved = false

    if (isFieldRemoved) {
      const fieldRemoved = state.orderConfig.filter(
        x => !event.sort.includes(x)
      )[0]

      isLastFieldRemoved =
        state.orderConfig.indexOf(fieldRemoved) === state.orderConfig.length - 1
    }

    setState(prev => ({
      ...prev,
      orderConfig: [...event.sort],
      dataLoaded: !(event.sort.length > 0 && !isLastFieldRemoved),
      searching: event.sort.length > 0 && !isLastFieldRemoved,
    }))
  }

  const handlePageChangeMobile = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage
  ) => {
    if (!dateValidation()) return
    setState(prev => ({ ...prev, page: newPage + 1, dataLoaded: false }))
  }

  const handlePageSizeChangeMobile = event => {
    if (!dateValidation()) return
    setState(prev => ({
      ...prev,
      page: 1,
      pageSize: parseInt(event.target.value, 10),
      dataLoaded: false,
    }))
  }

  const dateValidation = () => {
    if (isNaN(state.endDate?.getTime())) {
      onError('Invalid Date Format')
      return false
    }
    return true
  }

  const handleResetClick = () => {
    setState(prev => ({
      ...prev,
      ...defaults,
      count: 0,
      dataLoaded: false,
      selectedCustomerIDs:
        profileContext.dependentData.filters.initialFilterState.customers.map(
          c => c.custID
        ),
      selectedDivisionIDs:
        profileContext.dependentData.filters.initialFilterState.divisions.map(
          d => d.iD
        ),
      loaded: false,
    }))
  }

  const handleExportFormatChange = async (_event, format) => {
    if (!dateValidation()) return
    let promise
    try {
      setState(prev => ({ ...prev, exporting: true }))
      promise = APIService.getChangeOfConditions({
        customerIDs: getSelectedCustomerIDs(
          profileContext.profile.customers,
          profileContext.profile.divisions,
          state.selectedDivisionIDs,
          state.selectedCustomerIDs
        ),
        plantIDs: state.selectedPlants,
        machineNames: state.selectedMachineNames,
        custEquIDs: state.selectedCustEquIDs,
        frequency: state.frequency?.text,
        fiscalYearStartMonth: state.fiscalYearStartMonth?.id,
        startDate: state.startDate,
        endDate: state.endDate,
        page: 1,
        pageSize: 100000,
        orderList: state.orderConfig?.map(x => x.dir) ?? [],
        orderByList: state.orderConfig?.map(x => x.field) ?? [],
      })

      ongoingCancellablePromises.push(promise)
      const data = await promise

      const formatted = tableFormatter(
        data.conditionChanges.items,
        profileContext.dependentData.userDetails.isDivisionUser
      )

      exportData('change-of-condition', formatted, format.key)
      setState(prev => ({ ...prev, exporting: false }))
    } catch (ex) {
      onError(ex)
    } finally {
      ongoingCancellablePromises.filter(p => p != promise)
    }
  }

  const handleFilterSubmission = (data: TAccordionFilterValues) => {
    const mappedParameters = mapToPageParameters(data)

    setState(prev => ({
      ...prev,
      ...mappedParameters,
      page: 1,
      searching: true,
      samplesLoaded: false,
    }))
  }

  if (
    isInitialLoad &&
    ((profileContext.profile.divisions?.length > 0 &&
      state.selectedDivisionIDs.length === 0) ||
      (profileContext.profile.customers?.length > 0 &&
        state.selectedCustomerIDs.length === 0))
  ) {
    isInitialLoad = false
    return null
  }
  return (
    <>
      <SEO title="Change of Conditions Report" />
      <Box mb={2}>
        <Typography variant="h1">Change of Condition</Typography>
      </Box>
      {offline ? <OfflineBanner /> : <></>}

      <FilterAccordion
        pageName="Change of Conditions"
        defaultValues={profileContext.dependentData.filters.initialFilterState}
        onSubmit={handleFilterSubmission}
        onReset={handleResetClick}
        filters={{
          options: [OptionsFilterSet.Prefiltering],
          date: [DateFilterSet.ToDate, DateFilterSet.FromDate],
          organization: [
            OrganizationalFilterSet.Divisions,
            OrganizationalFilterSet.Customers,
            OrganizationalFilterSet.Plants,
          ],
          machine: [MachineFilterSet.Name, MachineFilterSet.EquipmentIDs],
          lubricant: [],
          sample: [],
          miscellaneous: [],
        }}
      />
      <form>
        <Grid
          container
          direction="row"
          xs={12}
          spacing={1}
          justify="flex-end"
          style={{ marginTop: '15px' }}
        >
          <Grid container item direction="row" spacing={1} justify="flex-end">
            <Grid item>
              <ExportButton
                isExporting={state.exporting}
                onChange={handleExportFormatChange}
                disabled={state.data.length < 1}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Box mt={4}>
        {!state.dataLoaded ? (
          <Loader />
        ) : (
          <ChangeOfConditionsReport
            changeOfConditions={state.data}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange}
            onPageSizeChangeMobile={handlePageSizeChangeMobile}
            onPageChangeMobile={handlePageChangeMobile}
            orderConfig={state.orderConfig}
            count={state.count}
            page={state.page}
            pageSize={state.pageSize}
            offline={offline}
          />
        )}
      </Box>
    </>
  )
}

export default function Page() {
  return (
    <AppLayout tab="reports">
      <ChangeOfConditionReport />
    </AppLayout>
  )
}
