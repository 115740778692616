import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Snackbar,
} from '@material-ui/core'
import TextField from 'components/landing/LandingTextField'
import { Alert } from '@material-ui/lab'
import { DisconnectedBanner } from 'components/Banners/DisconnectedBanner'
import { ConnectionStatusMonitor } from 'components/ConnectionStatusMonitor'
import { FormEvent, useEffect, useState } from 'react'
import LandingLayout from 'components/landing/Layout'
import APIService from 'services/api-service'
import { useNavigate, useSearchParams } from 'react-router-dom'

const ResetPasswordPage: React.FC = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()

  const token = params.get('token')?.replaceAll(' ', '+')
  const username = params.get('username')

  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  useEffect(() => {
    if (!token || !username) {
      navigate('/forgot-password')
    }
  }, [])

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()
    try {
      if (!newPassword || newPassword.length < 8) {
        setError('Password must have 8 characters or more')
        return
      }

      if (newPassword != confirmNewPassword) {
        setError('Passwords does not match')
        return
      }

      setLoading(true)
      await APIService.requestPasswordReset({ username, token, newPassword })
      setError(null)
      setSuccess(true)
      setNewPassword('')
      setConfirmNewPassword('')
    } catch (ex) {
      if (ex.message.indexOf('Failed to fetch') !== -1) {
        setError(
          'An error occurred while communicating with the server.  Please try your request again.'
        )
      } else {
        setError(ex.message)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <LandingLayout>
      <DisconnectedBanner />

      <form method="post" onSubmit={e => handleSubmit(e)}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="flex-start"
          spacing={2}
        >
          <Grid item>
            {success && (
              <Alert severity="success">
                Your password was successfuly changed.{' '}
                <a href="/login">Click here to go to Login Page</a>
              </Alert>
            )}
          </Grid>

          {!success && (
            <>
              <Grid item>
                <FormControl>
                  <TextField
                    id="newPassword"
                    name="newPassword"
                    label="New Password"
                    variant="filled"
                    type="password"
                    value={newPassword}
                    disabled={loading}
                    onChange={e => setNewPassword(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl>
                  <TextField
                    id="confirmNewPassword"
                    name="confirmNewPassword"
                    label="Confirm New Password"
                    variant="filled"
                    type="password"
                    value={confirmNewPassword}
                    disabled={loading}
                    onChange={e => setConfirmNewPassword(e.target.value)}
                  />
                </FormControl>
              </Grid>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  visibility: loading ? 'visible' : 'hidden',
                }}
              >
                <CircularProgress color="secondary" />
              </div>

              <Grid item>
                <FormControl>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={loading}
                  >
                    Change Password
                  </Button>
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
      </form>

      <Snackbar open={error !== null} onClose={() => setError(null)}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
      <ConnectionStatusMonitor />
    </LandingLayout>
  )
}

export default ResetPasswordPage
