import React, { useState } from 'react'
import { FormControl } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers'

import coreTheme from '../../theme'
import moment from 'moment'

const theme = createTheme(
  {
    overrides: {
      MuiFormControl: {
        root: {
          [coreTheme.breakpoints.down('sm')]: {
            // width: '100%'
            width: '225px',
          },
          [coreTheme.breakpoints.up('sm')]: {
            width: '225px',
          },
        },
      },
      MuiFormLabel: {
        root: {
          color: 'black',
        },
      },
    },
  },
  coreTheme
)

export const SampleDateFilter: React.FC<Partial<KeyboardDatePickerProps>> = (
  props: KeyboardDatePickerProps
) => {
  const [value, setValue] = useState(null)

  const handleChange = (val: moment.Moment) => {
    props.onChange(val)
    setValue(val)
  }

  return (
    <ThemeProvider theme={theme}>
      <FormControl>
        <KeyboardDatePicker {...props} onChange={handleChange} value={value} />
      </FormControl>
    </ThemeProvider>
  )
}
