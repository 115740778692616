import React from 'react'
import { Grid } from '@material-ui/core'

import ConditionElement from 'components/ConditionElement'
import MobileCardContent from 'components/MobileCardContent'
import MobileCardItem from 'components/MobileCardItem'

import {
  RenderDate,
  RenderLabNo,
  RenderMachineName,
} from '../KendoUIGridRenders'

interface OwnProps {
  changeOfCondition: any
  showDivisions: boolean
}

export const MobileCard: React.FC<OwnProps> = ({
  changeOfCondition,
  showDivisions,
}: OwnProps) => {
  const renderColumns = (from: string) => [
    <MobileCardItem title="Sample Date">
      <RenderDate dataItem={changeOfCondition[from]} field="sampleDate" />
    </MobileCardItem>,
    <MobileCardItem title="Mach">
      <ConditionElement noMargin condition={changeOfCondition[from].machCond} />
    </MobileCardItem>,
    <MobileCardItem title="Lube">
      <ConditionElement noMargin condition={changeOfCondition[from].lubCond} />
    </MobileCardItem>,
    <MobileCardItem title="Lube">
      <RenderLabNo dataItem={changeOfCondition[from]} field="labID" />
    </MobileCardItem>,
  ]

  return (
    <MobileCardContent title={changeOfCondition.machine.plant.plantName}>
      <Grid container direction="row" spacing={1}>
        {showDivisions && (
          <MobileCardItem title="Division">
            {changeOfCondition.machine.plant.customer.division.name}
          </MobileCardItem>
        )}
        <MobileCardItem title="Customer">
          {changeOfCondition.machine.plant.customer.custName}
        </MobileCardItem>
        <MobileCardItem title="Plant Name">
          {changeOfCondition.machine.plant.plantName}
        </MobileCardItem>
        <MobileCardItem title="Mach No.">
          {changeOfCondition.machine.machNo}
        </MobileCardItem>
        <MobileCardItem title="Machine Name">
          <RenderMachineName
            dataItem={changeOfCondition.machine}
            field="machineName"
          />
        </MobileCardItem>
        <MobileCardContent title="Current Sample">
          <Grid container direction="row" spacing={1}>
            {renderColumns('currentSample')}
          </Grid>
        </MobileCardContent>
        <MobileCardContent title="Previous Sample">
          <Grid container direction="row" spacing={1}>
            {renderColumns('previousSample')}
          </Grid>
        </MobileCardContent>
      </Grid>
    </MobileCardContent>
  )
}
