import { createStyles, makeStyles } from '@material-ui/core'

export const useTableStyles = makeStyles(() =>
  createStyles({
    table: {
      '& .k-grid-header .k-header': {
        padding: 5,
        margin: 0,
        textAlign: 'center',
        height: 50,
        fontWeight: 'bolder',
        '& .k-cell-inner': {
          margin: 0,
        },
        '& .k-link': {
          margin: 0,
          padding: 0,
          display: 'block',
          '& span': {
            whiteSpace: 'initial',
          },
        },
        '& .MuiSvgIcon-root': {
          color: 'black',
          cursor: 'pointer',
        },
      },
      '& .k-grid-container td': {
        textAlign: 'center',
        padding: 5,
        margin: 0,
      },
      '& .k-grouping-row p': {
        marginLeft: '30px !important'
      }      ,
      '& .k-grid-header .k-grid-header-wrap th.k-header': {
        textAlign: 'center',
        verticalAlign: 'middle',
        backgroundColor: '#A8AFBA',
        color: 'white',
        fontWeight: 'none',
      },
      marginTop: 15,
      '& .k-numerictextbox .k-input': {
        textAlign: 'center',
      },
      '& .k-dropdown .k-input': {
        justifyContent: 'center',
      },      
      '& .k-textbox': {
        textAlign: 'center',
      },
      '& .k-form-error': {
        justifyContent: 'center',
      },      
      '& .k-toolbar': {
        justifyContent: 'flex-end'
      },
      '& .k-animation-container': {
        zIndex: 10003
      },
      '& .k-dialog-wrapper': {
        zIndex: 10003
    }      
    },
  })
)
