import { useEffect, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import {
  Box,
  createTheme,
  Grid,
  ThemeProvider,
  Typography,
} from '@material-ui/core'
import { orderBy } from '@progress/kendo-data-query'
import moment from 'moment'
import { stringify } from 'query-string'
import { encodeQueryParams } from 'use-query-params'
import AppLayout from 'components/AppLayout'
import { ExportButton, ResetButton, SearchButton } from 'components/buttons'
import ViewReportButton from 'components/buttons/ViewReportButton'
import { TFilterState } from 'components/filters/SmartFilter/SmartFilterTypes'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import { IReportParametersRecurringException } from 'components/ReportViewer/TelerikReportViewer'
import {
  compactReportParams,
  reportViewerQueryParamConfig,
} from 'components/ReportViewerFromURLParams'
import SEO from 'components/SEO'
import {
  RecurringExceptionsTable,
  tableFormatter,
} from 'components/tables/RecurringExceptions'
import APIService from 'services/api-service'
import { exportData } from 'services/export-service'
import { getSelectedCustomerIDs } from 'services/selection-service'
import coreTheme from '../../components/theme'
import { useProfile } from 'Contexts/ProfileContext'
import { mapToPageParameters } from 'components/FilterAccordion/Utilities'
import FilterAccordion, {
  TAccordionFilterValues,
} from 'components/FilterAccordion'
import {
  DateFilterSet,
  LubricantFilterSet,
  MachineFilterSet,
  OptionsFilterSet,
  OrganizationalFilterSet,
  SampleFilterSet,
} from 'components/FilterAccordion/types'
import { CancellablePromise } from 'utils/CancellablePromise'

const initialStartDate = moment().subtract(1, 'year').startOf('day').toDate()
const initialEndDate = moment().endOf('day').toDate()
let isInitialLoad = true

const defaults = {
  startDate: new Date(
    initialStartDate.getUTCFullYear(),
    initialStartDate.getMonth(),
    initialStartDate.getDate(),
    0,
    0,
    0,
    0
  ),
  endDate: new Date(
    initialEndDate.getUTCFullYear(),
    initialEndDate.getMonth(),
    initialEndDate.getDate(),
    0,
    0,
    0,
    0
  ),
  exceptionCount: 2,
  selectedDivisionIDs: [],
  selectedCustomerIDs: [],
  selectedPlants: [],
  selectedLubeTypes: [] as number[],
  selectedMachineTypes: [] as string[],
  exportFormat: '',
  orderConfig: [{ field: 'lastSampleDate', dir: 'desc' }],
}

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

const theme = createTheme(
  {
    overrides: {
      MuiGrid: {
        root: {
          justifyContent: 'flex-end',
          [coreTheme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
          },
        },
      },
    },
  },
  coreTheme
)

const RecurringExceptionsReport = ({ onError, classes, offline }: any) => {
  const [state, setState] = useState({
    ...defaults,
    loaded: false,
    dataLoaded: false,
    data: [],
    page: 1,
    searching: false as boolean,
    loadedDefaults: false,
  })

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  useEffect(() => {
    return () => {
      isInitialLoad = true
    }
  }, [])

  const profileContext = useProfile()

  useEffect(() => {
    if (profileContext.dependentData.filters.minimumFilterOptionsLoaded) {
      setState(prev => ({
        ...prev,
        selectedCustomerIDs:
          profileContext.dependentData.filters.initialFilterState.customers.map(
            c => c.custID
          ),
        selectedDivisionIDs:
          profileContext.dependentData.filters.initialFilterState.divisions.map(
            d => d.iD
          ),
        loadedDefaults: true,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext.dependentData.filters.minimumFilterOptionsLoaded])

  useEffect(() => {
    if (!state.dataLoaded && !state.searching && state.loadedDefaults) {
      performSearch()
    }
    if (state.searching) {
      unstable_batchedUpdates(() => {
        performSearch()
        setState(prev => ({ ...prev, searching: false }))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dataLoaded, state.loadedDefaults, state.searching])

  const performSearch = () => {
    fetchSamples({
      startDate: state.startDate,
      endDate: state.endDate,
      customerIDs: getSelectedCustomerIDs(
        profileContext.profile.customers,
        profileContext.profile.divisions,
        state.selectedDivisionIDs,
        state.selectedCustomerIDs
      ),
      plantIDs: state.selectedPlants,
      lubeTypes: state.selectedLubeTypes,
      machineTypes: state.selectedMachineTypes,
      exceptionCount: state.exceptionCount,
    })
  }

  const fetchSamples = async variables => {
    setState(prev => ({
      ...prev,
      dataLoaded: false,
    }))
    let promise
    try {
      promise = APIService.getRecurringExceptions(variables)

      ongoingCancellablePromises.push(promise)
      const data = await promise

      if (data.analytics?.recurringExceptions) {
        onError(null)
        setState(prev => ({
          ...prev,
          data: data.analytics?.recurringExceptions ?? [],
          dataLoaded: true,
        }))
      }
    } catch (ex) {
      onError(ex)
    } finally {
      ongoingCancellablePromises.filter(p => p != promise)
    }
  }

  const handleOrderChanged = (orderConfig: any[]) => {
    setState(prev => ({ ...prev, orderConfig }))
  }

  const handleStartDateChange = (value: moment.Moment) => {
    setState(prev => ({
      ...prev,
      startDate: value?.toDate() ?? prev.startDate ?? defaults.startDate,
    }))
  }

  const handleEndDateChange = (value: moment.Moment) => {
    setState(prev => ({
      ...prev,
      endDate: value?.toDate() ?? prev.endDate ?? defaults.endDate,
    }))
  }

  const handleSearchClick = async (filterState: TFilterState) => {
    await injectSmartFilterState(filterState)
    setState(prev => ({
      ...prev,
      page: 1,
      searching: true,
      dataLoaded: false,
    }))
  }

  const handleResetClick = () => {
    setState(prev => ({
      ...prev,
      ...defaults,
      selectedCustomerIDs:
        profileContext.dependentData.filters.initialFilterState.customers.map(
          c => c.custID
        ),
      selectedDivisionIDs:
        profileContext.dependentData.filters.initialFilterState.divisions.map(
          d => d.iD
        ),
      dataLoaded: false,
      searching: true,
      count: 0,
    }))
  }

  const handleExportFormatChange = async (event, format) => {
    let data = null

    try {
      data = await APIService.getRecurringExceptions({
        page: 1,
        pageSize: 100000,
        orderList: state.orderConfig.map(x => x.dir),
        orderByList: state.orderConfig.map(x => x.field),
        startDate: state.startDate,
        endDate: state.endDate,
        customerIDs: getSelectedCustomerIDs(
          profileContext.profile.customers,
          profileContext.profile.divisions,
          state.selectedDivisionIDs,
          state.selectedCustomerIDs
        ),
        plantIDs: state.selectedPlants,
        exceptionCount: state.exceptionCount,
        lubeTypes: state.selectedLubeTypes,
        machineTypes: state.selectedMachineTypes,
      })
    } catch (ex) {
      onError(ex)
      return
    }

    const formatted = tableFormatter(
      orderBy(data.analytics?.recurringExceptions ?? [], state.orderConfig),
      profileContext.dependentData.userDetails.isDivisionUser
    )
    exportData('recurring-exceptions', formatted, format.key)
  }

  const getReportQueryParams = () => {
    const {
      selectedDivisionIDs,
      selectedCustomerIDs,
      selectedPlants,
      startDate,
      endDate,
      exceptionCount,
    } = state

    const reportParams: IReportParametersRecurringException = {
      Customers: getSelectedCustomerIDs(
        profileContext.profile.customers,
        profileContext.profile.divisions,
        selectedDivisionIDs,
        selectedCustomerIDs
      ),
      Plants: selectedPlants,
      To: endDate,
      From: startDate,
      SortColumn: state.orderConfig[0]?.field,
      SortDirection: state.orderConfig[0]?.dir,
      numberOfExceptions: exceptionCount,
      CorporateContactID: 0,
    }

    return encodeQueryParams(reportViewerQueryParamConfig, {
      dbg: false,
      r: 'recurringException',
      ...compactReportParams(reportParams),
    })
  }

  const injectSmartFilterState = async (smartState: TFilterState) => {
    setState(prev => ({
      ...prev,
      selectedCustomerIDs: smartState.customers.map(cust => cust.custID),
      selectedDivisionIDs: smartState.divisions.map(division => division.iD),
      selectedLubeConditions: smartState.lubricantConditions,
      selectedMachineConditions: smartState.machineConditions,
      selectedMachineNames: smartState.machineNames,
      selectedMachineTypes: smartState.machinePicklistTypes.map(
        m => m.listValue
      ),
      selectedLubeTypes: smartState.lubricantTypes.map(l => l.lubeTypeID),
      selectedCustEquIDs: smartState.customerEquipmentIDs,
      selectedRoutes: smartState.routes.map(r => r.routeID),
      selectedPlants: smartState.plants.map(p => p.plantID),
      selectedReportTypes: smartState.reportTypes.map(r => r.listValue),
    }))
  }

  const handleFilterSubmission = (data: TAccordionFilterValues) => {
    const mappedParameters = mapToPageParameters(data)

    setState(prev => ({
      ...prev,
      ...mappedParameters,
      page: 1,
      searching: true,
      dataLoaded: false,
    }))
  }

  const renderFilters = () => {
    if (
      isInitialLoad &&
      ((profileContext.profile.divisions?.length > 0 &&
        state.selectedDivisionIDs.length === 0) ||
        (profileContext.profile.customers?.length > 0 &&
          state.selectedCustomerIDs.length === 0))
    ) {
      isInitialLoad = false
      return null
    }

    return (
      <>
        <FilterAccordion
          pageName="Recurring Exceptions"
          defaultValues={{
            ...profileContext.dependentData.filters.initialFilterState,
            startDate: initialStartDate,
            endDate: initialEndDate,
          }}
          onSubmit={handleFilterSubmission}
          onReset={handleResetClick}
          filters={{
            options: [OptionsFilterSet.Prefiltering],
            date: [DateFilterSet.FromDate, DateFilterSet.ToDate],
            organization: [OrganizationalFilterSet.All],
            machine: [MachineFilterSet.Type],
            lubricant: [LubricantFilterSet.Type],
            sample: [SampleFilterSet.ExceptionCount],
            miscellaneous: [],
          }}
        />
        <form>
          <ThemeProvider theme={theme}>
            <Grid
              container
              direction="row"
              xs={12}
              spacing={1}
              style={{ marginTop: '15px' }}
            >
              <Grid container item direction="row" spacing={1}>
                <Grid item style={{ maxWidth: 242, marginLeft: 15 }}>
                  <ViewReportButton
                    url={`/reports/viewer?${stringify(getReportQueryParams())}`}
                    disabled={state.data.length < 1} 
                  />
                </Grid>

                <Grid item>
                  <ExportButton onChange={handleExportFormatChange} disabled={state.data.length < 1} />
                </Grid>
              </Grid>
            </Grid>
          </ThemeProvider>
        </form>
      </>
    )
  }

  return (
    <>
      <SEO title="Recurring Exceptions Report" />
      <Box mb={2}>
        <Typography variant="h1">Recurring Exceptions</Typography>
      </Box>
      {offline && <OfflineBanner />}
      {renderFilters()}
      <Box mt={4}>
        {!state.dataLoaded ? (
          <Loader />
        ) : (
          <RecurringExceptionsTable
            recurringExceptions={state.data}
            showDivisions={state.data.some(d => d.divisionName)}
            showOthers
            showCustomers
            onOrderChanged={handleOrderChanged}
            loaded={state.loaded}
          />
        )}
      </Box>
    </>
  )
}

export default function Page() {
  return (
    <AppLayout tab="reports">
      <RecurringExceptionsReport />
    </AppLayout>
  )
}
