import React from 'react'

import { VirtualSelectFilter } from '../BaseFilters/VirtualSelectFilter'
import FilterTooltip from '../FilterTooltip/FilterTooltip'

type TRouteType = {
  name: string
  routeID: number
}

export type TProps = {
  single?: boolean
  required: boolean
  routes: TRouteType[]
  selectedRoute?: number
  selectedRoutes?: number[]
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange?: (props: TRouteType | TRouteType[]) => void
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setState?: (props: any) => void
  value?: number | number[] | TRouteType | TRouteType[]
  defaultValue?: number | number[] | TRouteType | TRouteType[]
  tooltip?: boolean
}

export const RouteNameFilter = ({
  single,
  routes,
  required,
  onChange,
  setState,
  value,
  defaultValue,
  tooltip = true,
}: TProps) => {
  if (setState == null && onChange == null) return

  const handleRouteNameChange = (selectedRoutes: TRouteType | TRouteType[]) => {
    if (onChange != null) {
      if (single === true) {
        onChange(selectedRoutes as TRouteType)
      } else {
        onChange(selectedRoutes as TRouteType[])
      }
    } else {
      if (single === true) {
        setState({ selectedRoute: (selectedRoutes as TRouteType)?.routeID })
      } else {
        setState({
          selectedRoutes: (selectedRoutes as TRouteType[]).map(p => p.routeID),
        })
      }
    }
  }

  const getValue = (
    val: number | number[] | TRouteType | TRouteType[]
  ): TRouteType | TRouteType[] => {
    if (typeof val === 'number') {
      const route = routes.find(x => x.routeID === (val as number)) ?? undefined
      return {
        name: route?.name,
        routeID: route?.routeID,
      } as TRouteType
    } else if (Array.isArray(val)) {
      const manyRoutes = routes.filter(x =>
        (val as number[]).includes(x.routeID)
      )
      return manyRoutes
    } else {
      return val
    }
  }

  const filter = (
    <VirtualSelectFilter
      label="Route"
      htmlFor="routes"
      multiple={!single}
      required={required}
      id="routes"
      defaultValue={getValue(defaultValue)}
      value={getValue(value)}
      getOptionSelected={(option, value) => option?.routeID === value?.routeID}
      renderValue={(selected: TRouteType) => {
        // const route = routes.find(r => r.routeID === selected.routeID)

        return selected && selected?.name && selected?.routeNo
          ? `${selected?.routeNo} - ${selected?.name}`
          : null
      }}
      onChange={handleRouteNameChange}
      options={routes}
    ></VirtualSelectFilter>
  )
  return tooltip ? (
    <FilterTooltip title="A named grouping of machines or sample points.">
      {filter}
    </FilterTooltip>
  ) : (
    filter
  )
}
