import AppLayout from 'components/AppLayout'
import Loader from 'components/Loader'
import { useProfile } from 'Contexts/ProfileContext'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Styles from './index.module.css'

const usSteelDataStudioURL =
  'https://datastudio.google.com/embed/reporting/7328e54b-0f0d-47a1-b388-dd43db7167b4/page/p_c37y8laapc'

export const MachineHealthDashboard = () => {
  const profile = useProfile()
  const navigate = useNavigate()
  if (!profile.dependentData.userDetails.isAnalyticsCustomer) {
    navigate('/404')
    return null
  }

  const frameRef = useRef<HTMLIFrameElement>(null)

  const [loaded, setLoaded] = useState(false)

  const timeout = setInterval(() => {
    if (frameRef.current) {
      clearInterval(timeout)
      frameRef.current.onload = () => setLoaded(true)
    }
  }, 100)

  return (
    <AppLayout tab="analytics">
      <div className={Styles.FrameContainer}>
        {!loaded && <Loader />}
        <iframe
          ref={frameRef}
          style={{
            display: loaded ? undefined : 'none',
          }}
          allowFullScreen
          frameBorder={0}
          src={usSteelDataStudioURL}
          title="Google Data Studio"
          id="machine-health-dash_iframe"
        />
      </div>
    </AppLayout>
  )
}
