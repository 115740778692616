/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import MomentUtils from '@date-io/moment'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import AssessmentIcon from '@mui/icons-material/Assessment'

import {
  AppBar,
  Box,
  CircularProgress,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Snackbar,
  Tab,
  Tabs,
  Toolbar,
} from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import AdjustIcon from '@material-ui/icons/Adjust'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import BarChartIcon from '@material-ui/icons/BarChart'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import GetAppIcon from '@material-ui/icons/GetApp'
import MenuIcon from '@material-ui/icons/Menu'
import HelpIcon from '@material-ui/icons/Help'
import TrafficIcon from '@material-ui/icons/Traffic'
import OfflineIcon from '@material-ui/icons/OfflineBolt'
import SyncIcon from '@material-ui/icons/Sync'
import { Alert, AlertTitle, TabContext } from '@material-ui/lab'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import SentryProvider, {
  sendErrorMessageToSentry,
} from './Providers/SentryProvider'
import coreTheme from 'components/theme'
import LoginService from 'services/login-service'
import OfflineService from 'services/offline-service'
import { MonetizationOn } from '@material-ui/icons'
import { AppSecurity } from './AppSecurity'
import { HelpDrawer } from './HelpDrawer'
import StripeProvider from './Providers/StripeProvider'
import { useProfile } from 'Contexts/ProfileContext'
import { useNavigate } from 'react-router-dom'
import EqualizerIcon from '@mui/icons-material/Equalizer'

const drawerWidth = 280

const theme = createTheme(
  {
    overrides: {
      MuiFormLabel: {
        root: {
          color: '#222',
          zIndex: 99,
        },
      },
      MuiCheckbox: {
        root: {
          color: '#222',
        },
      },
    },
  },
  coreTheme
)

const AppLayout = ({ tab, children }) => {
  const profileContext = useProfile()
  const navigate = useNavigate()

  const [error, setError] = React.useState(null)
  const [notification, setNotification] = React.useState(null)
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [helpOpen, setHelpOpen] = React.useState(false)
  const [isLoggedIn, setIsLoggedIn] = React.useState(false)
  const [offline, setOffline] = React.useState(false)
  const [syncing, setSyncing] = React.useState(false)
  const [canSync, setCanSync] = React.useState(false)
  const [isBusy, setIsBusy] = React.useState(false)
  const [isPrimaryUser, setIsPrimaryUser] = React.useState(null)
  const [innerHeight, setInnerHeight] = React.useState(0)

  const GlobalToast = useMemo(() => {
    return (
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        containerId={'global'}
        enableMultiContainer
      />
    )
  }, [])

  useConfiguredOmniChatWindow()

  const showAnalytics =
    profileContext.dependentData.userDetails.isAnalyticsCustomer

  React.useEffect(() => {
    //Trigger the initial profile load so profile is only loaded for pages within the app layout.
    profileContext.utilities.constructProfile()

    if (window) {
      setInnerHeight(window.innerHeight)
    }
  }, [])

  const useStyles = React.useCallback(
    makeStyles(theme => ({
      root: {
        display: 'flex',
        width: '100%',
        backgroundColor: coreTheme.palette.primary.main,
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: coreTheme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
      navItem: {
        fontSize: '10px',
        minWidth: '125px',
        color: '#fff',
      },
      tabContainer: {
        width: '100%',
      },
      tabContainerMobile: {
        width: '100%',
      },
      navItemMobile: {
        fontSize: '20px',
        minWidth: '40px',
        height: `${innerHeight / 20 + 26}px`,
        maxHeight: '60px',
        flex: 1,
      },
      navIcon: {
        fontSize: `18px`,
        flex: 1,
      },
      navIconMobile: {
        fontSize: `${innerHeight / 75 + 17}px`,
      },
      selectedNavText: {
        color: '#fff !important',
      },
      navIndicator: {
        // backgroundColor: '#fff'
      },
      drawer: {},
      menuButton: {
        marginRight: theme.spacing(2),
      },
      toolbar: theme.mixins.toolbar,
      drawerPaper: {
        width: drawerWidth,
        backgroundColor: coreTheme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRight: '0px',
      },
      drawerContent: {
        marginRight: '15px',
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
      },
      drawerCompanyName: {
        fontWeight: 600,
      },
      drawerUserID: {},
      content: {
        flexGrow: 1,
        [theme.breakpoints.up('sm')]: {
          width: `calc(100% - ${drawerWidth}px)`,
        },
        width: `calc(100% - ${drawerWidth}px)`,
      },
      childContent: {
        padding: theme.spacing(3),
        //[theme.breakpoints.down('md')]: {
        paddingBottom: '100px',
        //},
        backgroundColor: coreTheme.palette.background.default,
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        marginTop: '15px',
      },
    })),
    [innerHeight]
  )

  const classes = useStyles()

  //add resize event to control variable size of tab sizes.
  React.useEffect(() => {
    const onResize = (e: Event) => {
      setInnerHeight((e.currentTarget as Window).innerHeight)
    }

    if (window) {
      window.addEventListener('resize', e => onResize(e))
      return () => window.removeEventListener('resize', e => onResize(e))
    }
  }, [])

  React.useEffect(() => {
    if (isBusy) return

    setIsBusy(true)
    const handleOfflineChange = async nowOffline => {
      if (!nowOffline && offline) {
        const hasSyncWorkToDo = await OfflineService.hasSyncWorkToDo()
        setCanSync(hasSyncWorkToDo)
      }

      setOffline(nowOffline)
    }

    const handleSyncChange = async nowSyncing => {
      if (nowSyncing && !syncing) {
        setCanSync(false)
      }

      setSyncing(nowSyncing)
    }

    ;(async () => {
      const loggedIn = await LoginService.isLoggedIn()
      if (!loggedIn) {
        if (typeof window !== 'undefined') {
          navigate(
            `/login?PostLoginURL=${
              window.location.pathname
            }${encodeURIComponent(window.location.search)}`
          )
          return
        }
      }
      profileContext.utilities.setUserLoggedIn(loggedIn)
      setIsLoggedIn(loggedIn)

      //setIsPrimaryUser(false)
      setIsPrimaryUser(await LoginService.isPrimaryUser())

      if (
        profileContext.fullProfileLoaded ||
        profileContext.minimumProfileLoaded
      )
        return
      try {
        // Set up offline services:
        await OfflineService.init(
          handleOfflineChange,
          setNotification,
          handleSyncChange,
          handleError
        )
        await OfflineService.registerFetchInterceptor()

        if (!OfflineService.isOffline()) {
          await OfflineService.forceOfflineCheck()
        }
        handleOfflineChange(OfflineService.isOffline())

        if (typeof window !== 'undefined') {
          if (!loggedIn) {
            profileContext.utilities.clearProfile()

            navigate('/login')
          } else {
            await LoginService.pollRefreshAccessToken(ex => {
              ;(async () => {
                await LoginService.logOut()

                // profileContext.utilities.clearProfile()

                navigate('/login')
              })()
            })
          }
        }

        // Perform prefetching:
        // await OfflineService.prefetch(data)

        // Sync:
        // await OfflineService.sync()
      } catch (ex) {
        handleError(ex)
      } finally {
        setIsBusy(false)
      }
    })()
  }, [isLoggedIn, offline, syncing])

  const logOut = () => {
    ;(async () => {
      await LoginService.logOut()
      profileContext.utilities.setUserLoggedIn(false)
      profileContext.utilities.clearProfile()
      if (typeof window !== 'undefined') {
        navigate('/login')
      }
    })()
  }

  const handleError = error => {
    let failedAuth = false

    if (error === null) {
      setError(null)
    } else if (typeof error.errors !== 'undefined') {
      failedAuth =
        error.errors.filter(
          x =>
            typeof x.extensions !== 'undefined' &&
            x.extensions.code === 'authorization'
        ).length > 0
      if (!failedAuth) {
        const message = error.errors
          .map(x =>
            typeof x.extensions !== 'undefined' ? x.extensions.code : x.message
          )
          .join(', ')
        setError(message)
        sendErrorMessageToSentry(message)
      }
    } else if (error instanceof Error) {
      if (error.message.indexOf('Failed to fetch') !== -1) {
        setError(
          'An error occurred while communicating with the server.  Please try your request again.'
        )
        return
      }

      failedAuth = error.message === 'authorization'
      if (!failedAuth) {
        setError(error.message)
        sendErrorMessageToSentry(error.message)
      }
    } else {
      if (error.indexOf('Failed to fetch') !== -1) {
        setError(
          'An error occurred while communicating with the server.  Please try your request again.'
        )
        return
      }

      setError(error)
    }

    if (failedAuth) {
      setError('Your session has expired.')
      ;(async () => {
        await LoginService.logOut()
        navigate('/login')
      })()
    }
  }

  const handleSampleStatusClicked = () => {
    navigate('/reports/sample-status')
  }

  const handleDownloadsClicked = () => {
    navigate('/downloads')
  }
  const handleAnalyticsClicked = () => {
    navigate('/analytics')
  }

  const handleLibraryClicked = () => {
    navigate('/technical-library')
  }

  const handleFinancialsClicked = () => {
    navigate('/financials')
  }

  const handleSampleManagementClicked = () => {
    navigate('/sample')
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleHelpDrawerToggle = () => {
    setHelpOpen(!helpOpen)
  }

  const handleHomeClick = (): void => {
    navigate('/')
  }

  const handleTabsChange = (event, newValue) => {
    if (typeof window !== 'undefined') {
      if (newValue === 'dashboard') {
        navigate('/')
      } else if (newValue === 'reports') {
        navigate('/reports')
      } else if (newValue === 'dart') {
        navigate('/dart')
      } else if (newValue === 'program-management') {
        navigate('/program-management')
      } else if (newValue === 'sample') {
        navigate('/sample')
      } else if (newValue === 'downloads') {
        navigate('/downloads')
      } else if (newValue === 'technical-library') {
        navigate('/technical-library')
      } else if (newValue === 'financials') {
        navigate('/financials')
      } else if (newValue === 'analytics') {
        handleAnalyticsClicked()
      }
    }
  }

  const renderAccountName = () => {
    let name = ''

    if (
      profileContext.profile.customers !== undefined &&
      profileContext.profile.customers !== null &&
      profileContext.profile.divisions !== undefined &&
      profileContext.profile.divisions !== null
    ) {
      if (
        profileContext.profile.customers.length > 1 &&
        profileContext.profile.divisions.length === 0
      ) {
        name = 'Corporate Account'
      } else if (profileContext.profile.divisions.length > 0) {
        name = 'Division Account'
      } else if (profileContext.profile.customers.length > 0) {
        name = profileContext.profile.customers[0].custName
      }
    }

    return name
  }

  const handleContactUs = () => {
    const customer = profileContext.profile.customers[0]
    const isDivisionUser = profileContext.profile.divisions?.length > 1
    const isCorporate =
      !isDivisionUser && profileContext.profile.customers?.length > 1
    let from = `${customer.custID} - ${customer.custName}`
    if (isDivisionUser)
      from = `Division Account - ${profileContext.profile.user.loginID}`
    if (isCorporate)
      from = `Corporate Account - ${profileContext.profile.user.loginID}`
    const email = process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL!
    window.location.href = `mailto:${email}?Subject=Customer%20Inquiry from ${from}`
  }

  const LoadingProfileMessage = () => {
    if (profileContext.minimumProfileLoaded) return null

    return (
      <ListItem
        key="User Profile Loading"
        style={{
          opacity: 0.5,
          marginLeft: '-13px',
        }}
      >
        <ListItemIcon>
          <CircularProgress size={'22px'} />
        </ListItemIcon>
        <ListItemText primary="User Profile Loading" />
      </ListItem>
    )
  }

  const isDivisionUser = profileContext.profile.divisions?.length > 1
  const isCorporateUser =
    !isDivisionUser && profileContext.profile.customers?.length > 1

  const isFinancialRestricted =
    profileContext.dependentData.userDetails.isFinancialRestricted

  const drawer = (
    <div>
      <div style={{ height: '15px' }}></div>
      <Paper
        className={classes.drawerContent}
        elevation={3}
        style={{ paddingBottom: '25px' }}
      >
        <List>
          <ListItem
            key="Company"
            className={classes.drawerCompanyName}
            style={{
              marginTop: '25px',
              marginBottom: '0px',
              paddingBottom: '0px',
            }}
          >
            {typeof profileContext.profile !== 'undefined' &&
              profileContext.profile !== null &&
              renderAccountName()}
          </ListItem>
          <ListItem
            key="UserID"
            className={classes.drawerUserID}
            style={{ marginTop: '0px', paddingTop: '0px' }}
          >
            {typeof profileContext.profile !== 'undefined' &&
            profileContext.profile !== null &&
            typeof profileContext.profile.user !== 'undefined' &&
            profileContext.profile.user !== null ? (
              <div>
                <div>
                  {profileContext.profile.user.firstName}{' '}
                  {profileContext.profile.user.lastName}
                </div>
                <div>USER ID: {profileContext.profile.user.loginID}</div>
              </div>
            ) : (
              <LoadingProfileMessage />
            )}
          </ListItem>
        </List>
        <hr
          style={{
            color: 'gray',
            height: '10px',
            width: '85%',
            marginTop: '0',
            marginBottom: '0',
          }}
        />
        <List>
          <ListItem
            onClick={handleSampleStatusClicked}
            button
            key="Sample Status"
          >
            <ListItemIcon>
              <TrafficIcon />
            </ListItemIcon>
            <ListItemText primary="Sample Status" />
          </ListItem>

          <ListItem onClick={handleSampleManagementClicked} button key="sample">
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary="Sample Management" />
          </ListItem>

          <ListItem onClick={handleDownloadsClicked} button key="Downloads">
            <ListItemIcon>
              <GetAppIcon />
            </ListItemIcon>
            <ListItemText primary="Downloads" />
          </ListItem>
          <ListItem
            onClick={handleLibraryClicked}
            button
            key="Technical Library"
          >
            <ListItemIcon>
              <AccountBalanceIcon />
            </ListItemIcon>
            <ListItemText primary="Technical Library" />
          </ListItem>

          {profileContext.minimumProfileLoaded && !isFinancialRestricted ? (
            <ListItem onClick={handleFinancialsClicked} button key="Financials">
              <ListItemIcon>
                <MonetizationOn />
              </ListItemIcon>
              <ListItemText primary="Financials" />
            </ListItem>
          ) : null}

          {showAnalytics && (
            <ListItem onClick={handleAnalyticsClicked} button key="Analytics">
              <ListItemIcon>
                <EqualizerIcon />
              </ListItemIcon>
              <ListItemText primary="Analytics" />
            </ListItem>
          )}

          <ListItem button key="Contact us" onClick={handleContactUs}>
            <ListItemIcon>
              <ContactMailIcon />
            </ListItemIcon>
            <ListItemText primary="Contact us" />
          </ListItem>
          <ListItem button key="Log Out" onClick={logOut}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Log Out" />
          </ListItem>
        </List>
      </Paper>
    </div>
  )

  let container
  if (typeof window !== 'undefined') {
    container = window !== undefined ? () => window.document.body : undefined
  }

  return (
    <>
      {GlobalToast}
      {!isLoggedIn ? (
        <div />
      ) : (
        <>
          <AppSecurity isPrimaryUser={isPrimaryUser} />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <CssBaseline />
            <Helmet>
              <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width"
              />
              <link
                href="https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@300;400;600;700&display=swap"
                rel="stylesheet"
              />
              <style>
                {
                  'html { background-color: white; } body { font-family: "Fira Sans Condensed", sans-serif; } .Mui-selected > .MuiTab-wrapper > .MuiSvgIcon-root { color: #FF6600; } a, .link { color: #6200EE; text-decoration: none; } a:hover, .link:hover { text-decoration: underline; opacity: 0.75; } .cardHover:hover > .MuiPaper-outlined { border: 1px solid #222; opacity: 0.75; } '
                }
              </style>
            </Helmet>
            <ThemeProvider theme={theme}>
              <div className={classes.root}>
                <Hidden mdUp>
                  <AppBar
                    position="fixed"
                    elevation={0}
                    style={{ top: 'auto', bottom: 0 }}
                  >
                    <TabContext value={tab}>
                      <Tabs
                        value={tab}
                        onChange={handleTabsChange}
                        centered
                        textColor="secondary"
                        classes={{ indicator: classes.navIndicator }}
                        style={{ width: '100%' }}
                      >
                        <Tab
                          value="dashboard"
                          icon={
                            <DashboardIcon className={classes.navIconMobile} />
                          }
                          className={classes.navItemMobile}
                          classes={{ selected: classes.selectedNavText }}
                          label={'dashboard'}
                          style={{ fontSize: '0.7em' }}
                        />
                        <Tab
                          value="reports"
                          icon={
                            <BarChartIcon className={classes.navIconMobile} />
                          }
                          className={classes.navItemMobile}
                          classes={{ selected: classes.selectedNavText }}
                          label={'reports'}
                          style={{ fontSize: '0.7em' }}
                        />
                        <Tab
                          value="dart"
                          icon={
                            <AdjustIcon className={classes.navIconMobile} />
                          }
                          className={classes.navItemMobile}
                          classes={{ selected: classes.selectedNavText }}
                          label={'dart'}
                          style={{ fontSize: '0.7em' }}
                        />
                        <Tab
                          value="program-management"
                          icon={
                            <AssignmentTurnedInIcon
                              className={classes.navIconMobile}
                            />
                          }
                          className={classes.navItemMobile}
                          classes={{ selected: classes.selectedNavText }}
                          label={'program management'}
                          style={{ fontSize: '0.6em' }}
                        />
                        <Tab
                          value="sample"
                          icon={null}
                          className={classes.navItemMobile}
                          classes={{ selected: classes.selectedNavText }}
                          label={'sample'}
                          style={{ fontSize: '0.7em', display: 'none' }}
                        />
                      </Tabs>
                    </TabContext>
                  </AppBar>
                </Hidden>

                <AppBar
                  position="fixed"
                  elevation={0}
                  className={classes.appBar}
                >
                  <Toolbar style={{ paddingBottom: '7px' }}>
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="open drawer"
                      className={clsx(classes.menuButton)}
                      onClick={handleDrawerToggle}
                      id="app_layout-ds_icon_button"
                    >
                      <MenuIcon />
                    </IconButton>
                    <img
                      onClick={handleHomeClick}
                      src="/static/images/login/ds-name-inv-1.png"
                      alt="datasight logo"
                      style={{
                        cursor: 'pointer',
                        flex: 3,
                        height: '50px',
                        marginTop: '10px',
                        width: '150px',
                        objectFit: 'contain',
                      }}
                    />
                    <Hidden
                      smDown
                      implementation="css"
                      className={classes.tabContainer}
                    >
                      <TabContext value={tab}>
                        <Tabs
                          value={tab}
                          onChange={handleTabsChange}
                          centered
                          textColor="secondary"
                          classes={{ indicator: classes.navIndicator }}
                          style={{ width: '100%', height: '65px' }}
                        >
                          <Tab
                            value="dashboard"
                            label="DASHBOARD"
                            icon={<DashboardIcon className={classes.navIcon} />}
                            className={classes.navItem}
                            classes={{ selected: classes.selectedNavText }}
                          />
                          <Tab
                            value="reports"
                            label="REPORTS"
                            icon={<BarChartIcon className={classes.navIcon} />}
                            className={classes.navItem}
                            classes={{ selected: classes.selectedNavText }}
                          />
                          <Tab
                            value="dart"
                            label="DART"
                            icon={<AdjustIcon className={classes.navIcon} />}
                            className={classes.navItem}
                            classes={{ selected: classes.selectedNavText }}
                          />
                          <Tab
                            value="program-management"
                            label="PROGRAM MANAGEMENT"
                            icon={
                              <AssignmentTurnedInIcon
                                className={classes.navIcon}
                              />
                            }
                            className={classes.navItem}
                            classes={{ selected: classes.selectedNavText }}
                          />
                          <Tab
                            value="downloads"
                            label="DOWNLOADS"
                            icon={<GetAppIcon className={classes.navIcon} />}
                            className={classes.navItem}
                            classes={{ selected: classes.selectedNavText }}
                          />
                          <Tab
                            value="technical-library"
                            label="TECHNICAL LIBRARY"
                            icon={
                              <AccountBalanceIcon className={classes.navIcon} />
                            }
                            className={classes.navItem}
                            classes={{ selected: classes.selectedNavText }}
                          />
                          (
                          <Tab
                            value="sample"
                            label="Sample"
                            icon={null}
                            className={classes.navItem}
                            classes={{ selected: classes.selectedNavText }}
                            style={{ display: 'none' }}
                          />
                          {!isFinancialRestricted && (
                            <Tab
                              value="financials"
                              label="FINANCIALS"
                              icon={
                                <MonetizationOn className={classes.navIcon} />
                              }
                              className={classes.navItem}
                              classes={{ selected: classes.selectedNavText }}
                            />
                          )}
                          {showAnalytics && (
                            <Tab
                              value="analytics"
                              label="ANALYTICS"
                              icon={
                                <EqualizerIcon className={classes.navIcon} />
                              }
                              className={classes.navItem}
                              classes={{ selected: classes.selectedNavText }}
                            />
                          )}
                        </Tabs>
                      </TabContext>
                    </Hidden>
                    <Hidden smDown>
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleHelpDrawerToggle}
                        id="app_layout-help_icon_button"
                      >
                        <HelpIcon />
                      </IconButton>
                    </Hidden>
                    {offline ? (
                      <Box
                        style={{
                          width: '100px',
                          height: '50px',
                          textAlign: 'right',
                        }}
                      >
                        <IconButton
                          id="app_layout-offiline_icon_button"
                          onClick={async () =>
                            await OfflineService.forceOfflineCheck()
                          }
                          style={{
                            color: '#FF6600',
                            display: 'block',
                            marginTop: '10px',
                            marginLeft: 'auto',
                            marginRight: '15px',
                            marginBottom: '0px',
                            padding: '0px',
                          }}
                        >
                          <OfflineIcon
                            className={classes.navIcon}
                            style={{ color: '#FF6600' }}
                          />
                        </IconButton>
                        <Box
                          style={{
                            width: '100px',
                            color: '#fff',
                            fontSize: '10px',
                            marginTop: '5px',
                            paddingRight: '7px',
                          }}
                        >
                          OFFLINE
                        </Box>
                      </Box>
                    ) : canSync ? (
                      <Box
                        style={{
                          width: '100px',
                          height: '50px',
                          textAlign: 'right',
                        }}
                      >
                        <IconButton
                          id="app_layout-sync_icon_button"
                          onClick={async () => await OfflineService.forceSync()}
                          style={{
                            color: '#FF6600',
                            display: 'block',
                            marginTop: '10px',
                            marginLeft: 'auto',
                            marginRight: '15px',
                            marginBottom: '0px',
                            padding: '0px',
                          }}
                        >
                          <SyncIcon
                            className={classes.navIcon}
                            style={{ color: '#FF6600' }}
                          />
                        </IconButton>
                        <Box
                          style={{
                            width: '100px',
                            color: '#fff',
                            fontSize: '10px',
                            marginTop: '5px',
                            paddingRight: '7px',
                          }}
                        >
                          SYNC NOW
                        </Box>
                      </Box>
                    ) : (
                      <></>
                    )}
                    {syncing ? (
                      <Box
                        style={{
                          width: '100px',
                          height: '50px',
                          textAlign: 'right',
                        }}
                      >
                        <SyncIcon
                          className={classes.navIcon}
                          style={{
                            color: '#FF6600',
                            display: 'block',
                            marginTop: '10px',
                            marginLeft: 'auto',
                            marginRight: '20px',
                          }}
                        />
                        <Box
                          style={{
                            width: '100px',
                            color: '#fff',
                            fontSize: '10px',
                            marginTop: '5px',
                            paddingRight: '7px',
                          }}
                        >
                          SYNCING...
                        </Box>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Toolbar>
                </AppBar>
                <nav className={classes.drawer} aria-label="navigation">
                  <Drawer
                    container={container}
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                      keepMounted: true,
                    }}
                  >
                    {drawer}
                  </Drawer>
                </nav>
                <HelpDrawer open={helpOpen} onClose={handleHelpDrawerToggle} />
                <SentryProvider>
                  <StripeProvider>
                    <main className={classes.content}>
                      <div className={classes.toolbar} />
                      <Box className={classes.childContent}>
                        {React.Children.map(children, child => {
                          if (React.isValidElement(child)) {
                            const props = {
                              offline: offline,
                              onError: handleError,
                            }
                            return React.cloneElement(child, props)
                          }

                          return child
                        })}
                      </Box>
                      <Snackbar
                        open={error !== null}
                        onClose={() => setError(null)}
                      >
                        <Alert severity="error">{error}</Alert>
                      </Snackbar>
                      <Snackbar
                        open={notification !== null}
                        onClose={() => setNotification(null)}
                      >
                        <Alert severity="info">
                          {notification !== null ? (
                            <>
                              <AlertTitle>{notification.title}</AlertTitle>
                              {notification.message}
                            </>
                          ) : (
                            ''
                          )}
                        </Alert>
                      </Snackbar>
                    </main>
                  </StripeProvider>
                </SentryProvider>
              </div>
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </>
      )}
    </>
  )
}

AppLayout.propTypes = {
  tab: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default AppLayout

function useConfiguredOmniChatWindow() {
  const profileContext = useProfile()

  const [lcwReady, setlcwReady] = useState(false)

  useEffect(() => {
    const func = () => setlcwReady(true)
    window.addEventListener('lcw:ready', func)

    return () => {
      window.removeEventListener('lcw:ready', func)
    }
  }, [])

  useEffect(() => {
    ;(() => {
      if (!profileContext.minimumProfileLoaded || !lcwReady) {
        return
      }
      const profileInformation = {
        fullName: `${profileContext?.profile?.user?.firstName ?? ''}${
          !!profileContext?.profile?.user?.lastName ? ' ' : ''
        }${profileContext?.profile?.user?.lastName ?? ''}`,
        email: profileContext?.profile?.user?.email ?? '',
        phone:
          profileContext?.profile?.user?.phone ??
          profileContext?.profile?.user?.cell ??
          '',
      }

      handleLivechatReadyEvent(profileInformation)
    })()
  }, [profileContext.minimumProfileLoaded, lcwReady])
}

function handleLivechatReadyEvent(userInformation) {
  // Set the custom context provider
  // console.log('AH!')

  // Throws error if contextProvider is not a function
  // console.log(Microsoft.Omnichannel.LiveChatWidget.SDK)

  Microsoft.Omnichannel.LiveChatWidget.SDK.setContextProvider(
    function contextProvider() {
      //passing params that are defined as context variables in the workstream

      return {}
    }
  )
}
