import { TProfile, User } from 'services/api-service'
import { TAPICustomer } from 'types/api'
import {
  analyticsUserLoginIDs,
  sullairCustomerIDs,
  usSteelCustomerIDs,
} from './constants'
export const isSullairCustomer = (customerIDs: number[]) => {
  return customerIDs.some(cid => sullairCustomerIDs.includes(cid))
}

export const isUSSteelCustomer = (customerIDs: number[]) => {
  return customerIDs.some(cid => usSteelCustomerIDs.includes(cid))
}

export const isAnalyticsCustomer = (profile: {
  user?: User
  customers: TAPICustomer[]
}) => {
  return (
    profile.user &&
    (analyticsUserLoginIDs.includes(profile.user.loginID) ||
      isUSSteelCustomer(profile.customers.map(c => c.custID)))
  )
}

export const getIsInternalUser = () => {
  return document.cookie.includes('INT_USR=1')
}

export const getIsInternalUserHeader = () => {
  return getIsInternalUser() ? { 'internal-user': '1' } : {}
}

export const isFinancialRestricted = (
  customerIDs: number[],
  industryCodes: string[]
) => {
  const customerIDsExists = !!process.env.REACT_APP_FINANCIAL_RESTRICTED_CUSTIDS

  const parsedCustomerIDs =
    customerIDsExists &&
    JSON.parse(process.env.REACT_APP_FINANCIAL_RESTRICTED_CUSTIDS)

  const customerIDsIsArray =
    parsedCustomerIDs && Array.isArray(parsedCustomerIDs)

  const industryCodesExists =
    !!process.env.REACT_APP_FINANCIAL_RESTRICTED_INDUSTRIES

  const parsedIndustryCodes =
    industryCodesExists &&
    JSON.parse(process.env.REACT_APP_FINANCIAL_RESTRICTED_INDUSTRIES)

  const industryCodesIsArray =
    parsedIndustryCodes && Array.isArray(parsedIndustryCodes)

  const isCustomerRestricted =
    customerIDsExists &&
    customerIDsIsArray &&
    customerIDs.some(cid => parsedCustomerIDs.includes(cid))

  const isIndustryRestricted =
    industryCodesExists &&
    industryCodesIsArray &&
    industryCodes.some(ic => parsedIndustryCodes.includes(ic))

  return !getIsInternalUser()
}
