import React, { useContext, useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import moment from 'moment'

import AppLayout from 'components/AppLayout'
import NavCard from 'components/NavCard'
import OfflineBanner from 'components/offline-banner'
import SEO from 'components/SEO'
import APIService from 'services/api-service'
import { ProfileContext, useProfile } from 'Contexts/ProfileContext'
import Loader from 'components/Loader'

const colors = [
  'linear-gradient(#1d2b3e 0%, #505f74 100%)',
  'linear-gradient(#c18949 0%, #d06c2b 100%)',
  'linear-gradient(#d8d8d8 0%, #808080 100%)',
  'linear-gradient(#83590a 0%, #bc850a 100%)',
  'linear-gradient(#d85611 0%, #a05757 100%)',
  'linear-gradient(#efcd3c 0%, #808080 100%)',
  'linear-gradient(#383434 0%, #808080 100%)',
  'linear-gradient(#d5731f 0%, #3b2c20 100%)',
  'linear-gradient(#466fa8 0%, #142791 100%)',
  'linear-gradient(#a5a5a5 0%, #1f3c65 100%)',
  'linear-gradient(#d6d49f 0%, #76713a 100%)',
  'linear-gradient(#1c298e 0%, #6990be 100%)',
]

const prettySize = size => {
  if (typeof size === 'undefined') return '0B'
  if (size < 1000) return `${size}B`
  if (size < 1000000) return `${Math.round(size / 1000)}KB`
  if (size < 1000000000) return `${Math.round(size / 1000000)}MB`
  return `${Math.round(size / 1000000000)}GB`
}

const _prefetch = async profile => {
  if (profile) {
    const data = await APIService.getFiles(
      profile?.customers?.map(x => x.custID)
    )
    return data
  }
  return null
}

function CustomerFiles({ offline }) {
  const [loaded, setLoaded] = useState(false)
  const [pages, setPages] = useState([])

  const profileContext = useProfile()

  useEffect(() => {
    if (profileContext.minimumProfileLoaded) {
      ;(async () => {
        if (!loaded) {
          setPages(
            (
              await APIService.getFiles(
                profileContext.profile.customers.map(x => x.custID)
              )
            ).map((x, i) => {
              const color = colors[i % colors.length]
              return {
                title: x.Name,
                custID: x.CustID,
                fileName: x.Name,
                icon: <GetAppIcon />,
                size: prettySize(x.Size),
                modifiedTime: x.ModifiedTime,
                background: color,
                download: true,
              }
            })
          )

          setLoaded(true)
        }
      })()
    }
  }, [
    loaded,
    profileContext.minimumProfileLoaded,
    profileContext.profile.customers,
  ])

  return (
    <React.Fragment>
      <SEO title="Customer Files" />
      <Grid container direction="row" spacing={4}>
        <Grid item md={3}>
          <Typography variant="h1">Customer Files</Typography>
          {offline ? <OfflineBanner /> : <></>}
          <Box style={{ marginTop: '15px' }}>
            <Typography paragraph>
              This is where you will find specific datafiles from your Oil
              Analysis program. Contact our customer service team if you would
              like to set up datafile generation.
            </Typography>
          </Box>
        </Grid>
        {profileContext.minimumProfileLoaded ? (
          <Grid container item direction="row" md={9} spacing={4}>
            {pages.map((page, i) => (
              <NavCard
                key={i}
                icon={page.icon}
                title={page.title}
                description={page.description}
                useFileLink={true}
                custID={page.custID}
                fileName={page.fileName}
                background={page.background}
              >
                <Box style={{ marginTop: '5px', marginBottom: '5px' }}>
                  Modified: {moment(page.modifiedTime).format('l')}
                </Box>
                <Box style={{ marginTop: '5px', marginBottom: '5px' }}>
                  Size: {page.size}
                </Box>
              </NavCard>
            ))}
          </Grid>
        ) : (
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <Loader />
          </div>
        )}
      </Grid>
    </React.Fragment>
  )
}

export default function Page() {
  //if (prefetch) return _prefetch

  return (
    <AppLayout tab="downloads">
      <CustomerFiles />
    </AppLayout>
  )
}
