import KendoReactCharts, {
  Chart,
  PlotBand,
  SharedTooltipContext,
} from '@progress/kendo-react-charts'
import { useThemeContext } from 'Contexts/ThemeContext'

import React, { useEffect, useState } from 'react'
import { TSpending, TSpendingEntity, TAccumulatedSpending } from 'types/api'
import { compareDatesByMonthAndYear } from 'utils/financial-utilities'
import { OverallSpendChartTooltip } from './OverallSpendChartTooltip'

type KendoCharts = typeof KendoReactCharts

type TOverallSpendingChartProps = {
  startDate: Date
  endDate: Date
  spendingData: TSpendingEntity
  interval: 'Monthly' | 'Quarterly'
  projectionBasis: 'Historical' | 'Scheduled'
  style?: React.CSSProperties
} & Partial<React.PropsWithoutRef<Chart>>

type TBudgetedSpending = {
  overBudget: number
  withinBudget: number
}

const now = new Date()

const getSpendingDistribution = (
  spendingDatum: TSpending
): TBudgetedSpending => {
  const amountInExcessOfBudget =
    spendingDatum.budget == null
      ? 0
      : spendingDatum.amountSpent - spendingDatum.budget
  const overBudget = amountInExcessOfBudget > 0
  return {
    overBudget: overBudget ? amountInExcessOfBudget : 0,
    withinBudget: overBudget ? spendingDatum.budget : spendingDatum.amountSpent,
  }
}

const sumSpending = (spendingData: TSpending[]): TBudgetedSpending => {
  const spendingDistribution = {
    overBudget: 0,
    withinBudget: 0,
  }

  for (const datum of spendingData) {
    const distribution = getSpendingDistribution(datum)
    spendingDistribution.overBudget += distribution.overBudget
    spendingDistribution.withinBudget += distribution.withinBudget
  }

  return spendingDistribution
}

export const OverallSpendingChart: React.FC<TOverallSpendingChartProps> =
  props => {
    const [Kendo, setKendo] = useState<KendoCharts>(null)

    const {
      spendingData,
      interval,
      projectionBasis,
      startDate,
      endDate,
      ...chartProps
    } = props

    useEffect(() => {
      require('hammerjs')
      setKendo(require('@progress/kendo-react-charts'))
    }, [Kendo])

    if (spendingData == null) return null

    const accumulatedSpending: TAccumulatedSpending[] = getAccumulatedSpending(
      spendingData,
      props.interval,
      props.projectionBasis,
      startDate,
      endDate
    )

    const overBudgetseries = []
    const underBudgetSeries = []
    accumulatedSpending.forEach(s => {
      underBudgetSeries.push(s.withinBudgetSpent)
      overBudgetseries.push(s.overBudgetSpent)
    })

    const spendingSeriesPointsAboveThisMonth = accumulatedSpending.filter(
      s => s.dateStart.getTime() > now.getTime()
    ).length

    const projectedSpendingPlotBand: PlotBand[] = [
      {
        from:
          accumulatedSpending.length - spendingSeriesPointsAboveThisMonth + 1,
        to: accumulatedSpending.length + 1,
        color: 'blue',
        opacity: 0.05,
        label: { text: 'Total Projected Spending' },
      },
    ]
    const theme = useThemeContext()
    if (!Kendo || accumulatedSpending.length == 0) return null
    const graph = (
      <>
        <Kendo.Chart
          pannable
          {...chartProps}
          style={{ height: '100%', padding: 0, ...chartProps.style }}
        >
          <Kendo.ChartSeriesLabels
            format="c0"
            content={e =>
              `
                ${e.value}
                ${e.value}
              `
            }
          />
          <Kendo.ChartLegend position="bottom" />
          <Kendo.ChartAxisDefaults
            labels={{
              format: 'c0',
              rotation: -45,
              // content: e =>
              //   `
              //   ${e.value}
              //   ${e.value}
              // `,
            }}
          />
          <Kendo.ChartTooltip
            shared={true}
            format={'bar'}
            render={(context: SharedTooltipContext) => (
              <OverallSpendChartTooltip
                tooltipContext={context}
                accumulatedSpending={accumulatedSpending}
                spendingData={spendingData}
              />
            )}
          />
          <Kendo.ChartCategoryAxis>
            <Kendo.ChartCategoryAxisItem
              labels={{
                rotation: -45,
                step: props.interval == 'Monthly' ? 2 : 1,
              }}
              categories={accumulatedSpending.map(s => s.label)}
              plotBands={projectedSpendingPlotBand}
            />
          </Kendo.ChartCategoryAxis>
          <Kendo.ChartSeries>
            <Kendo.ChartSeriesItem
              name="Within-Budget Spending"
              data={underBudgetSeries}
              missingValues="gap"
              stack
              color={theme.palette.charts.withinBudget}
            ></Kendo.ChartSeriesItem>
            <Kendo.ChartSeriesItem
              name="Over-Budget Spending"
              data={overBudgetseries}
              missingValues="gap"
              stack
              color={theme.palette.charts.overBudget}
            ></Kendo.ChartSeriesItem>

            <Kendo.ChartSeriesItem
              color={theme.palette.charts.budget}
              markers={{ visible: false }}
              labels={{}}
              type="line"
              style="step"
              dashType="solid"
              data={accumulatedSpending.map(s => s.budget)}
              name={'Budget'}
            />
          </Kendo.ChartSeries>
        </Kendo.Chart>
      </>
    )

    return graph
  }

const sum = (values: number[]) => {
  let total = 0
  values.forEach(num => (total += num))
  return total
}

const getAccumulatedSpending = (
  spendingData: TSpendingEntity,
  interval: 'Monthly' | 'Quarterly',
  projectionBasis: 'Historical' | 'Scheduled',
  startDate: Date,
  endDate: Date
): TAccumulatedSpending[] => {
  const currentDate = new Date()
  const adjustedHistoricalSpending = spendingData.historicalSpending
    .filter(
      hs => compareDatesByMonthAndYear(new Date(hs.month), startDate) >= 0
    )
    .sort((a, b) => new Date(a.month).getTime() - new Date(b.month).getTime())

  const descendingOrderedHistoricalSpending =
    spendingData.historicalSpending.sort(
      (a, b) => new Date(b.month).getTime() - new Date(a.month).getTime()
    )

  const historicalSpendingTotal = sumSpending(adjustedHistoricalSpending)

  let projectedSpending = spendingData.projectedSpending.filter(
    ps => new Date(ps.month) > currentDate
  )

  if (projectionBasis == 'Historical') {
    projectedSpending = projectedSpending.map((s, i) => {
      const matchingHistoricalSpend = descendingOrderedHistoricalSpending.find(
        hs => new Date(hs.month).getMonth() == new Date(s.month).getMonth()
      )

      return {
        ...matchingHistoricalSpend,
        month: s.month,
        budget: s.budget,
      }
    })
  }

  if (interval == 'Monthly') {
    const monthlySpending: TAccumulatedSpending[] = []

    adjustedHistoricalSpending.forEach((spending, i) => {
      const month = new Date(spending.month)
      const budgetedSpending = sumSpending(
        adjustedHistoricalSpending.slice(0, i + 1)
      )

      monthlySpending.push({
        withinBudgetSpent: budgetedSpending.withinBudget,
        overBudgetSpent: budgetedSpending.overBudget,
        dateStart: month,
        dateEnd: new Date(month.getFullYear(), month.getMonth() + 1, 0),
        label: getCategoryLabel(month),
        index: i,
        budget: spending.budget,
      })
    })
    ;(() => {
      const budgetedSpending = sumSpending([
        ...adjustedHistoricalSpending,
        spendingData.currentMonthsSpending,
      ])

      monthlySpending.push({
        withinBudgetSpent: budgetedSpending.withinBudget,
        overBudgetSpent: budgetedSpending.overBudget,
        dateStart: currentDate,
        dateEnd: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        ),
        label: getCategoryLabel(currentDate),
        index: adjustedHistoricalSpending.length,
        budget: spendingData.currentMonthsSpending.budget,
      })
    })()

    projectedSpending.forEach((spending, i) => {
      const month = new Date(spending.month)
      const lastIndex = monthlySpending.length - 1
      const thisMonthsSpending = sumSpending([spending])
      const previousSpending = monthlySpending[lastIndex]

      monthlySpending.push({
        withinBudgetSpent:
          thisMonthsSpending.withinBudget + previousSpending.withinBudgetSpent,
        overBudgetSpent:
          thisMonthsSpending.overBudget + previousSpending.overBudgetSpent,
        dateStart: month,
        dateEnd: new Date(month.getFullYear(), month.getMonth() + 1, 0),
        label: getCategoryLabel(month),
        index: monthlySpending.length,
        budget: spending.budget,
      })
    })
    return monthlySpending
  }

  const unsummedMonthlySpending: TAccumulatedSpending[] = []

  adjustedHistoricalSpending.forEach((spending, i) => {
    const month = new Date(spending.month)

    const budgetedSpending = sumSpending([spending])

    unsummedMonthlySpending.push({
      withinBudgetSpent: budgetedSpending.withinBudget,
      overBudgetSpent: budgetedSpending.overBudget,
      dateStart: month,
      dateEnd: new Date(month.getFullYear(), month.getMonth() + 1, 0),
      label: '',
      index: i,
      budget: spending.budget,
    })
  })
  ;(() => {
    const budgetedSpending = sumSpending([spendingData.currentMonthsSpending])

    unsummedMonthlySpending.push({
      withinBudgetSpent: budgetedSpending.withinBudget,
      overBudgetSpent: budgetedSpending.overBudget,
      dateStart: currentDate,
      dateEnd: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ),
      label: '',
      index: adjustedHistoricalSpending.length,
      budget: spendingData.currentMonthsSpending.budget,
    })
  })()

  projectedSpending.forEach((spending, i) => {
    const month = new Date(spending.month)
    const budgetedSpending = sumSpending([spending])
    unsummedMonthlySpending.push({
      withinBudgetSpent: budgetedSpending.withinBudget,
      overBudgetSpent: budgetedSpending.overBudget,
      dateStart: month,
      dateEnd: new Date(month.getFullYear(), month.getMonth() + 1, 0),
      label: '',
      index: adjustedHistoricalSpending.length + 1 + i,
      budget: spending.budget,
    })
  })

  const quarterMonths = 3
  const quarterlySpending: Map<string, TBudgetedSpending> = new Map()
  const quarterlyBudget: Map<string, number> = new Map()
  const quarterlyStartDate: Map<string, Date> = new Map()
  let previousQuarter: string = ''

  for (const month of unsummedMonthlySpending) {
    const quarter = getQuarterIdentifier(month.dateStart)

    if (!quarterlySpending.has(quarter)) {
      if (previousQuarter != '') {
        quarterlySpending.set(quarter, {
          withinBudget:
            month.withinBudgetSpent +
            quarterlySpending.get(previousQuarter).withinBudget,
          overBudget:
            month.overBudgetSpent +
            quarterlySpending.get(previousQuarter).overBudget,
        })
      } else {
        quarterlySpending.set(quarter, {
          withinBudget: month.withinBudgetSpent,
          overBudget: month.overBudgetSpent,
        })
      }
    } else {
      quarterlySpending.set(quarter, {
        withinBudget:
          month.withinBudgetSpent + quarterlySpending.get(quarter).withinBudget,
        overBudget:
          month.overBudgetSpent + quarterlySpending.get(quarter).overBudget,
      })
    }

    if (!quarterlyStartDate.has(quarter)) {
      quarterlyStartDate.set(quarter, month.dateStart)
    }

    if (!quarterlyBudget.has(quarter)) {
      quarterlyBudget.set(quarter, month.budget)
    } else {
      const quarterBudger = quarterlyBudget.get(quarter)
      if (month.budget < quarterBudger)
        quarterlyBudget.set(quarter, month.budget)
    }

    previousQuarter = quarter
  }

  const quarterlySpendingArray: TAccumulatedSpending[] = []
  quarterlySpending.forEach((spent, quarter) => {
    const startDate = quarterlyStartDate.get(quarter)
    const endDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth() + quarterMonths,
      0
    )

    quarterlySpendingArray.push({
      withinBudgetSpent: spent.withinBudget,
      overBudgetSpent: spent.overBudget,
      dateStart: startDate,
      dateEnd: endDate,
      label: quarter,
      index: quarterlySpendingArray.length,
      budget: quarterlyBudget.get(quarter),
    })
  })

  return quarterlySpendingArray
}

const getCategoryLabel = (date: Date) => {
  return `${date.getMonth() + 1}/${date.getFullYear()}`
}

const getQuarterIdentifier = (date: Date) => {
  const quarter = Math.floor((date.getMonth() + 1) / 4) + 1
  const year = date.getFullYear()
  return `Q${quarter} - ${year}`
}
