import React from 'react'
import { Hidden, TablePagination } from '@material-ui/core'
import {
  GridCellProps,
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'
import TableGrid from 'components/TableGrid'
import { useTableStyles } from 'utils/tableStyles'
import {
  RenderMachineManufacturer,
  RenderMachineNameEdit,
  RenderMachineType,
} from '../KendoUIGridRenders'
import MobileCard from './MobileCard'
import { useCustomTableStyles } from './tableStyles'
import { useProfile } from 'Contexts/ProfileContext'

interface OwnProps {
  machines: any[]
  orderConfig: any[]
  page: number
  pageSize: number
  count: number
  onPageChange?: (_event: GridPageChangeEvent) => void
  onSortChange?: (_event: GridSortChangeEvent) => void
  onPageChangeMobile: (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    _page: number
  ) => void
  onPageSizeChangeMobile?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >
  onMachineEditClick: (_pointID: number) => void
}

export const ManageEquipmentTable: React.FC<OwnProps> = ({
  machines,
  orderConfig,
  page,
  pageSize,
  count,
  onPageChange,
  onSortChange,
  onPageChangeMobile,
  onPageSizeChangeMobile,
  onMachineEditClick,
}: OwnProps) => {
  const classes = useTableStyles()
  const customClasses = useCustomTableStyles()

  const profileContext = useProfile()

  return (
    <>
      <Hidden mdUp>
        {!machines?.length && (
          <p style={{ textAlign: 'center' }}>No records available</p>
        )}
        {machines.map(machine => (
          <MobileCard
            key={machine.labID}
            machine={machine}
            machineTypeOptions={profileContext.profile.machineTypeOptions}
            machineMfgOptions={profileContext.profile.machineMfgOptions}
            onMachineEditClick={onMachineEditClick}
            readOnly={profileContext.profile.user.readOnly}
          />
        ))}

        {
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100, 250, 500]}
            component="div"
            count={count}
            rowsPerPage={pageSize}
            page={page > 0 ? page - 1 : page}
            onChangePage={onPageChangeMobile}
            onChangeRowsPerPage={onPageSizeChangeMobile}
          />
        }
      </Hidden>

      <Hidden smDown>
        <TableGrid
          className={[classes.table, customClasses.table].join(' ')}
          data={machines}
          sort={orderConfig}
          skip={(page - 1) * pageSize}
          take={pageSize}
          total={count}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
          expandField="expanded"
        >
          {profileContext.dependentData.userDetails.isDivisionUser && (
            <Column
              field="divisionName"
              title="Division"
              cell={(props: React.PropsWithChildren<GridCellProps>) => (
                <td>{props.dataItem.plant?.customer.division?.name}</td>
              )}
            />
          )}
          <Column field="plant.customer.custName" title="Customer Name" />
          <Column field="plant.plantName" title="Plant Name" />
          <Column field="machNo" title="Mach No." />
          <Column
            field="machineName"
            title="Machine Name"
            cell={props => (
              <RenderMachineNameEdit
                {...props}
                readOnly={profileContext.profile.user.readOnly}
                onMachineEditClick={onMachineEditClick}
              />
            )}
          />
          <Column field="custEquID" title="Equipment ID" />
          <Column
            field="route.routeNo"
            title="Route"
            cell={(props: React.PropsWithChildren<GridCellProps>) => (
              <td>
                {props.dataItem.route &&
                  `${props.dataItem.route.routeNo} - ${props.dataItem.route.name}`}
              </td>
            )}
          />

          <Column field="lubricant.lubricantName" title="Lubricant" />

          <Column
            field="machType"
            title="Machine Type"
            cell={props => (
              <RenderMachineType
                {...props}
                machineTypeOptions={profileContext.profile.machineTypeOptions}
              />
            )}
          />

          <Column
            field="machMFG"
            title="Manufacturer"
            cell={props => (
              <RenderMachineManufacturer
                {...props}
                machineMfgOptions={profileContext.profile.machineMfgOptions}
              />
            )}
          />

          <Column field="modelName" title="Model Name" />
          <Column field="sumpCap" title="Sump" />
        </TableGrid>
      </Hidden>
    </>
  )
}
