import { useIsLoggedIn } from 'hooks/useIsLoggedIn'
import { LoadingCardModule } from '../CardModules/LoadingCardModule'
import { PageCard } from '../Pages/PageContainers'
import * as PageStyles from '../page-styles.module.css'

export const BottleDataLoading = () => {
  const isLoggedIn = useIsLoggedIn()
  return (
    <PageCard>
      <div
        className={`${PageStyles.EntryScreen}  ${PageStyles.WithBottleButton} ${
          isLoggedIn.loggedIn ? PageStyles.LoggedIn : ''
        }`}
      >
        <LoadingCardModule />
      </div>
    </PageCard>
  )
}
