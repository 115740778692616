import React, { useMemo } from 'react'
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core'

import { TShipmentInformation } from 'types/api'
import { TProfile } from 'services/api-service'
import {
  deliveryDateFromTrackingNumber,
  shippingAnchorFromOrder,
  ShippingStatusTitleFromEnum,
} from 'utils/api-utilities'
import { getOrderInvoiceNumberLink } from './order-information-tools'
import { useProfile } from 'Contexts/ProfileContext'

interface Props {
  order: TShipmentInformation
  onClick: () => void
}
const MobileCard: React.FC<Props> = ({ order, onClick }: Props) => {
  const profileContext = useProfile()
  const profile = profileContext.profile
  const customerIDs = useMemo(
    () => profile.customers.map(c => c.custID),
    [profile.customers]
  )

  const invoiceDownloadLink = useMemo(
    () => getOrderInvoiceNumberLink(order, customerIDs),
    [order, customerIDs]
  )

  return (
    <Card
      variant="outlined"
      style={{ marginBottom: '10px', cursor: 'pointer' }}
      onClick={onClick}
    >
      <CardHeader title={`Order ${order?.orderId}`} />
      <CardContent>
        <Grid container direction="row" spacing={1}>
          {profile.divisions.length > 0 && (
            <Grid item xs={6} md={3}>
              {profile.divisions.find(d =>
                d.customers.map(c => c.custID).includes(order?.custId)
              )?.name || ''}
            </Grid>
          )}
          {!!order?.shipFirstName && (
            <Grid item xs={6} md={3}>
              {`${order?.shipFirstName} ${order?.shipLastName ?? ''}`}
            </Grid>
          )}
          {profile.customers.length > 0 && (
            <Grid item xs={6} md={3}>
              {profile.customers.find(
                customer => customer.custID === order?.custId
              )?.custName || ''}
            </Grid>
          )}
          <Grid item xs={6} md={3}>
            {profile.customers
              .find(customer => customer.custID === order?.custId)
              ?.plants.find(plant => plant.plantNo === order?.plantNo)
              ?.plantName || ''}
          </Grid>

          <Grid item xs={6} md={3}>
            Ordered on{' '}
            {new Date(order?.orderDate).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </Grid>
          <Grid item xs={6} md={3}>
            Shipped on{' '}
            {new Date(order?.shipDate).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </Grid>
          <Grid item xs={6} md={3}>
            Status: {ShippingStatusTitleFromEnum(order?.status)}
          </Grid>
          <Grid item xs={6} md={3}>
            Tracking number: {shippingAnchorFromOrder(order)}
          </Grid>
          <Grid item xs={6} md={3}>
            Delivery Date:{' '}
            {deliveryDateFromTrackingNumber(
              order?.trackingNumber
            ).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </Grid>
          <Grid item xs={6} md={3}>
            Invoice: {invoiceDownloadLink}
          </Grid>

          {/* <Grid item xs={6} md={3}>
            {moment(x.sampleDate).format('l')}
          </Grid>
          <Grid item xs={6} md={3}>
            Plant Name:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.machine.plant.plantName}
          </Grid>
          <Grid item xs={6} md={3}>
            Machine No:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.machine.machNo}
          </Grid>
          <Grid item xs={6} md={3}>
            Machine Name:
          </Grid>
          <Grid item xs={6} md={3}>
            <Link
              to={`/dart/data?labID=${x.labID}`}
              target="_blank"
              rel="noreferrer"
            >
              {x.machine.machineName}
            </Link>
          </Grid>
          <Grid item xs={6} md={3}>
            Equipment ID:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.machine.custEquID}
          </Grid>
          <Grid item xs={6} md={3}>
            Mach:
          </Grid>
          <Grid item xs={6} md={3}>
            <ConditionElement nomargin={true} machCond={x.machCond} />
          </Grid>
          <Grid item xs={6} md={3}>
            Lube:
          </Grid>
          <Grid item xs={6} md={3}>
            <ConditionElement nomargin={true} lubCond={x.lubCond} />
          </Grid>
          <Grid item xs={6} md={3}>
            Lube No:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.labID}
          </Grid>
          <Grid item xs={6} md={3}>
            Machine Type:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.machine.machType}
          </Grid>
          <Grid item xs={6} md={3}>
            Lube Type:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.machine.lubricant?.lubricantName}
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default MobileCard
