import React from 'react'

import Loader from 'components/Loader'
import useScript from 'hooks/useScript'

type Props = {
  src: string
  children?: any
}

export const ScriptDependency = (props: Props) => {
  const { src, children } = props
  const status = useScript(src)
  switch (status) {
    case 'ready':
      return <>{children}</>
    case 'loading':
      return <Loader />
    case 'error':
      return <span>Something bad happened</span>
    default:
      return <span>?</span>
  }
}
