import React, { ReactNode } from 'react'
import { Card, CardContent, CardHeader } from '@material-ui/core'

interface OwnProps {
  title: string
  children?: ReactNode
}

const MobileCardContent: React.FC<OwnProps> = ({
  title,
  children,
}: OwnProps) => {
  return (
    <Card
      variant="outlined"
      style={{ marginBottom: '10px', marginTop: '20px', width: '100%' }}
    >
      <CardHeader title={title} />
      <CardContent>{children}</CardContent>
    </Card>
  )
}

export default MobileCardContent
