import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import { SortDescriptor } from '@progress/kendo-data-query'
import { GridSortChangeEvent } from '@progress/kendo-react-grid'

import { TSalesOrders } from 'types/api'
import AppLayout from 'components/AppLayout'
import CurrentBalance from 'components/CurrentBalance'
import EstimatedRemainingMonths from 'components/EstimatedRemainingMonths'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import SEO from 'components/SEO'
import CustomerOrdersTable from 'components/tables/CustomerOrdersTable'
import APIService, { TProfile } from 'services/api-service'
import { useContext } from 'react'
import { ProfileContext, useProfile } from 'Contexts/ProfileContext'
import { Navigate, useNavigate } from 'react-router-dom'
import { BudgetCard } from './ScoreCards/BudgetCard'
import { useEnvironmentContext } from 'Contexts/EnvironmentContext'
import { CancellablePromise } from 'utils/CancellablePromise'
import { CreateNewPOModal } from './CreateNewPOModal'

interface TState extends Partial<TProfile> {
  dataLoaded: boolean
  data: TSalesOrders
  selectedDivisionIDs: number[]
  selectedCustomerIDs: number[]
  count: number
  page: number
  pageSize: number
  orderConfig: SortDescriptor[]
}

const defaults: TState = {
  dataLoaded: false,
  data: {} as TSalesOrders,
  selectedDivisionIDs: [],
  selectedCustomerIDs: [],
  count: 0,
  page: 1,
  pageSize: 10,
  orderConfig: [{ field: 'expirationDate', dir: 'desc' }],
}

interface TProps {
  profile?: TProfile // passed in from layout wrapper
  onError?: (_err: Error) => void // passed in from layout wrapper
  offline?: boolean
}

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

const CustomerOrdersPage: React.FC<TProps> = ({ onError, offline }: TProps) => {
  const [state, setState] = useState<TState>({ ...defaults })
  const [modalOpen, setModalOpen] = useState(false)

  const profileContext = useProfile()

  useEffect(() => {
    if (profileContext.minimumProfileLoaded) {
      setState(prev => ({ ...prev, ...profileContext.profile }))
    }
  }, [profileContext.minimumProfileLoaded, profileContext.profile])

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  const fetchData = useCallback(async () => {
    try {
      const promise = APIService.getOrders(
        profileContext.profile.divisions.map(x => x.iD),
        profileContext.profile.customers.map(x => x.custID)
      )

      ongoingCancellablePromises.push(promise)
      const response = await promise

      ongoingCancellablePromises.filter(p => p != promise)

      setState(prev => ({
        ...prev,
        data: response,
      }))
    } catch (error) {
      onError(error)
    } finally {
      setState(prev => ({ ...prev, dataLoaded: true }))
    }
  }, [
    onError,
    profileContext.profile.customers,
    profileContext.profile.divisions,
  ])

  useEffect(() => {
    if (profileContext.minimumProfileLoaded) fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext.minimumProfileLoaded])

  const handleSortChange = (event: GridSortChangeEvent) => {
    setState(prev => ({
      ...prev,
      orderConfig: event.sort,
    }))
  }

  const navigate = useNavigate()
  const isFinancialRestricted =
    profileContext.dependentData.userDetails.isFinancialRestricted

  if (isFinancialRestricted) {
    navigate('/404')
    return null
  }

  if (!profileContext.minimumProfileLoaded) {
    return <Loader />
  }

  const handlePageChangeMobile = (event, newPage) => {
    setState(prev => ({ ...prev, page: newPage + 1 }))
  }

  const handlePageSizeChangeMobile = event => {
    setState(prev => ({
      ...prev,
      page: 1,
      pageSize: parseInt(event.target.value, 10),
    }))
  }

  return (
    <>
      <CreateNewPOModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSubmitSuccess={fetchData}
      />
      <SEO title="Orders" />
      <Box mb={2}>
        <Typography variant="h1">Customer Orders</Typography>
      </Box>
      {offline ? <OfflineBanner /> : <></>}

      <Box mt={4}>
        {!state.dataLoaded ? (
          <Loader />
        ) : (
          <>
            <Grid container justify="center" style={{marginBottom: '10px'}}>
              <BudgetCard salesOrders={state.data} />
              {/* <Grid item style={{ marginRight: 50 }}>
                <CurrentBalance currentBalance={state.data.currentBalance} />
              </Grid>
              <Grid item>
                <EstimatedRemainingMonths
                  value={state.data.maxEstimatedRemainingMonths}
                />
              </Grid> */}
            </Grid>
            <Grid container justifyContent="flex-end">
              <Button
                variant="contained"
                style={{ width: '250px', height: '50px' }}
                color="secondary"
                onClick={event => setModalOpen(true)}
              >
                Create New Purchase Order
              </Button>
            </Grid>
            <CustomerOrdersTable
              salesOrders={state.data?.salesOrders ?? []}
              offline={offline}
              page={state.page}
              pageSize={state.pageSize}
              count={state.count}
              orderConfig={state.orderConfig}
              onSortChange={handleSortChange}
              onPageChangeMobile={handlePageChangeMobile}
              onPageSizeChangeMobile={handlePageSizeChangeMobile}
            />
          </>
        )}
      </Box>
    </>
  )
}

export default function FinancialCustomerOrdersPage() {
  return (
    <AppLayout tab="financials">
      <CustomerOrdersPage />
    </AppLayout>
  )
}
