export const machineConditions = [
  { value: 1, text: 'Normal' },
  { value: 2, text: 'Marginal' },
  { value: 3, text: 'Critical' },
  { value: 0, text: 'Unknown' },
]

export const machineConditionKeys = {
  normal: 1,
  marginal: 2,
  critical: 3,
  unknown: 0,
}

export enum ConditionType {
  InProgress = -1,
  Unknown = 0,
  Normal = 1,
  Marginal = 2,
  Critical = 3,
  AtRisk = 4,
}

export class ConditionC {
  type: ConditionType
  color: string
  description: string

  private constructor(type: ConditionType, color: string, description: string) {
    this.type = type
    this.color = color
    this.description = description
  }

  public static GetCondition(conditionType: ConditionType): ConditionC {
    return new ConditionC(
      conditionType,
      ConditionC.colorFromCondition(conditionType),
      ConditionC.conditionNameFromCondition(conditionType)
    )
  }

  private static colorFromCondition(condition: ConditionType) {
    switch (condition) {
      case ConditionType.InProgress: {
        return '#ffec12'
      }
      case ConditionType.Unknown: {
        return '#444'
      }
      case ConditionType.Normal: {
        return '#8af07d'
      }
      case ConditionType.Marginal: {
        return '#ffec12'
      }
      case ConditionType.Critical: {
        return '#f07d7d'
      }
      case ConditionType.AtRisk: {
        return '#ffec12'
      }
      default: {
        return '#444'
      }
    }
  }

  private static conditionNameFromCondition(condition: ConditionType) {
    switch (condition) {
      case ConditionType.InProgress: {
        return 'In Progress'
      }
      case ConditionType.Unknown: {
        return 'Unknown'
      }
      case ConditionType.Normal: {
        return 'Normal'
      }
      case ConditionType.Marginal: {
        return 'Marginal'
      }
      case ConditionType.Critical: {
        return 'Critical'
      }
      case ConditionType.AtRisk: {
        return 'At Risk'
      }
      default: {
        return null
      }
    }
  }
}

export enum Condition {
  InProgress = -1,
  Unknown = 0,
  Normal = 1,
  Marginal = 2,
  Critical = 3,
  AtRisk = 4,
}

export function colorFromCondition(condition: Condition) {
  switch (condition) {
    case Condition.InProgress: {
      return '#ffec12'
    }
    case Condition.Unknown: {
      return '#444'
    }
    case Condition.Normal: {
      return '#8af07d'
    }
    case Condition.Marginal: {
      return '#ffec12'
    }
    case Condition.Critical: {
      return '#f07d7d'
    }
    case Condition.AtRisk: {
      return '#ffec12'
    }
    default: {
      return '#444'
    }
  }
}

export function conditionNameFromCondition(condition: Condition) {
  switch (condition) {
    case Condition.InProgress: {
      return 'In Progress'
    }
    case Condition.Unknown: {
      return 'Unknown'
    }
    case Condition.Normal: {
      return 'Normal'
    }
    case Condition.Marginal: {
      return 'Marginal'
    }
    case Condition.Critical: {
      return 'Critical'
    }
    case Condition.AtRisk: {
      return 'At Risk'
    }
    default: {
      return 'Unknown'
    }
  }
}

export const lubeConditions = [
  { value: 1, text: 'Normal' },
  { value: 2, text: 'Marginal' },
  { value: 3, text: 'Critical' },
  { value: 4, text: 'At-Risk' },
  { value: 0, text: 'Unknown' },
]

export const lubeConditionKeys = {
  normal: 1,
  marginal: 2,
  critical: 3,
  unknown: 0,
}

export const reportTypes = [
  { value: 0, text: 'Oil' },
  { value: 1, text: 'Varnish' },
  { value: 2, text: 'Turbine' },
  { value: 3, text: 'EHC' },
]

export const sampleStatuses = [
  {
    value: 0,
    text: 'In Progress',
    codes: ['ENT', 'ER1', 'ER2', 'ER3', 'ERR', 'INC', 'QTY', 'RSV'],
  },
  { value: 1, text: 'On Hold', codes: ['REV'] },
  { value: 2, text: 'Completed', codes: ['COM'] },
]

export const frequencies = [
  { id: 'monthly', text: 'Monthly' },
  { id: 'quarterly', text: 'Quarterly' },
  { id: 'yearly', text: 'Yearly' },
]

export const months = [
  { id: 1, text: 'January' },
  { id: 2, text: 'February' },
  { id: 3, text: 'March' },
  { id: 4, text: 'April' },
  { id: 5, text: 'May' },
  { id: 6, text: 'June' },
  { id: 7, text: 'July' },
  { id: 8, text: 'August' },
  { id: 9, text: 'September' },
  { id: 10, text: 'October' },
  { id: 11, text: 'November' },
  { id: 12, text: 'December' },
] as const

export const daysDelinquentOptions = [
  0, 30, 45, 60, 75, 90, 120, 240, 360,
] as const

export const monthAbbreviations = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
