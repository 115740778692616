/* eslint-disable eqeqeq */
/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'

import { TAPIMachine, TAPIPlant } from 'types/api'
import AppLayout from 'components/AppLayout'
import {
  MachineManufacturerFilter,
  MachineTypeFilter,
  PairedFluidTypeManufacturerFilter,
  PlantNameFilter,
} from 'components/filters'
import FilterLabel from 'components/filters/FilterLabel/FilterLabel'
import WithDisableFiltersBox from 'components/filters/SmartFilter/DisableFiltersBox/WithDisableFiltersBox'
import {
  Filters,
  TFilterState,
  TSmartFilter,
} from 'components/filters/SmartFilter/SmartFilterTypes'
import WithSmartFilters from 'components/filters/SmartFilter/WithSmartFilters'
import { FormField } from 'components/FormField'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import SEO from 'components/SEO'
import APIService, { TProfile } from 'services/api-service'
import { binarySearch, IsSorted } from 'utils/ArrayMethods'
import Styles from './manage-equipment.module.css'
import { ProfileContext, useProfile } from 'Contexts/ProfileContext'
import { CancellablePromise } from 'utils/CancellablePromise'
import { profile } from 'console'

const defaults = {
  selectedCustomers: [],
  selectedPlants: [],
  pointID: '',
  selectedCustomer: -1,
  selectedPlant: null as TAPIPlant,
  routeID: -1,
  selectedMachineType: '',
  custEquID: '',
  machineName: '',
  selectedMachineMfg: '',
  modelName: '',
  sumpCap: '',
  modelNumber: '',
  machineNames: [],
  machines: [],
  machine: null as TAPIMachine,
  formErrors: [],
  plants: [],
  routes: [],
  machineTypes: [],
  machineTypeOptions: [],
  machineMfgs: [],
  machineMfgOptions: [],
  lubeTypes: [],
  loaded: false,
  lubeMfrOptions: [],
  lubeMfg: '',
  lubeNameAndGrade: '',
  sampleMonths: [],
  testGroups: [],
  selectedLubeMfg: null,
  selectedLubeType: null,
  lubeTypeID: null,
  selectedRoute: null,
  submitting: false,
  profileUpdated: false,
  dataLoaded: false,
  filterState: null,
  initialState: null,
  finalizing: false,
}

interface OwnProps {
  onError?: (_err: Error) => void // passed in from layout wrapper
  offline?: boolean
  classes?: any
}

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

const ManageEquipment: React.FC<OwnProps> = ({
  onError,
  offline,
}: OwnProps) => {
  const navigate = useNavigate()

  const [state, setState] = useState<typeof defaults & Partial<TFilterState>>({
    ...defaults,
  })

  const modelNameRef = useRef('')
  const inputModelNameRef = useRef(null)

  const custEquIDRef = useRef('')
  const inputCustEquIDRef = useRef(null)

  const machineNameRef = useRef('')
  const inputMachineNameRef = useRef(null)

  const lubricantTypeRef = useRef('')

  let pointID = null
  if (window.location.search !== null) {
    const params = new URLSearchParams(window.location.search)

    if (params.has('pointID')) {
      pointID = params.get('pointID')
    }
  }

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  const profileContext = useProfile()

  useEffect(() => {
    setState(prev => ({
      ...prev,
      ...profileContext.profile,
      profileUpdated: profileContext.fullProfileLoaded,
    }))
  }, [profileContext])

  useEffect(() => {
    if (!state.loaded && state.profileUpdated) {
      performSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loaded, state.profileUpdated])

  useEffect(() => {
    if (!state.dataLoaded) {
      fetchMachine(pointID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dataLoaded])

  useEffect(() => {
    if (state.dataLoaded) {
      if (inputModelNameRef.current) {
        inputModelNameRef.current.value = state.modelName
        modelNameRef.current = state.modelName
      }
      if (inputCustEquIDRef.current) {
        inputCustEquIDRef.current.value = state.custEquID
        custEquIDRef.current = state.custEquID
      }
      if (inputMachineNameRef.current) {
        inputMachineNameRef.current.value = state.machineName
        machineNameRef.current = state.machineName
      }

      lubricantTypeRef.current = state.lubeTypeID
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.profileUpdated, state.dataLoaded])

  useEffect(() => {
    handleFormValidation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedMachineType, state.selectedMachineMfg])

  const performSearch = async () => {
    if (pointID !== null) {
      setState(prev => ({ ...prev, dataLoaded: false }))
    } else {
      setState(prev => ({
        ...prev,
        ...defaults,
        loaded: true,
        dataLoaded: true,
      }))
    }
  }

  const fetchMachine = async pointID => {
    let data = null
    let promise

    try {
      promise = APIService.getMachine(pointID)
      ongoingCancellablePromises.push(promise)
      data = await promise
    } catch (ex) {
      onError(ex)
      if (ex.message == 'UNAUTHORIZED_ACCESS') {
        navigate('/login?sessionexpired=true')
      }
      return
    } finally {
      ongoingCancellablePromises.filter(p => p != promise)
    }

    if (data !== null) {
      onError(null)
      setState(prev => ({
        ...prev,
        machine: data.machine,
        pointID: data.machine.pointID,
        selectedPlant: data.machine.plant,
        selectedCustomer: data.machine.plant?.customer?.custID,
        routeID: data.machine.route?.routeID ?? -1,
        selectedRoute: data.machine.route?.routeID ?? null,
        machineNumber: data.machine.machineNumber,
        machineName: data.machine.machineName,
        custEquID: data.machine.custEquID,
        lubeTypeID: data.machine.lubeTypeID,
        selectedMachineType: data.machine.machType,
        selectedMachineMfg: data.machine.machMFG,
        modelName: data.machine.modelName,
        selectedLubeType: data.machine.lubeTypeID,
        sumpCap: data.machine.sumpCap,
        dataLoaded: true,
        loaded: true,
      }))
    }
  }

  const handleModelNameChange = event => {
    modelNameRef.current = event.target.value
  }

  const handleMachineNameChange = event => {
    machineNameRef.current = event.target.value
  }

  const handleCustEquIDChange = event => {
    custEquIDRef.current = event.target.value
  }

  const handleLubeTypeChange = value => {
    lubricantTypeRef.current = value.lubeTypeID
    handleFormValidation()
  }

  const handlePlantChange = value => {
    setState(prev => ({
      ...prev,
      selectedPlant: value,
    }))
    handleFormValidation()
  }

  const handleMachineTypeChange = machineType => {
    setState(prev => ({
      ...prev,
      selectedMachineType: machineType?.listValue ?? '',
    }))
  }

  const handleMachineMfgChange = machineMfg => {
    setState(prev => ({
      ...prev,
      selectedMachineMfg: machineMfg?.listValue ?? '',
    }))
  }

  const handleChange = event => {
    setState(prev => ({ ...prev, [event.target.name]: event.target.value }))
  }

  const handleCancelClick = event => {
    navigate('/program-management/equipment')
  }

  const handleFormValidation = (plants?: TAPIPlant[]) => {
    const formErrors = []

    const machineNameExists = profileContext.profile.plants.some(plant => {
      if (plant.plantID === state.selectedPlant?.plantID) {
        const comparator = (a, b) => (a > b ? 1 : a == b ? 0 : -1)

        if (plant.machines?.length > 0) {
          const machineNames = plant.machines.map(machine => {
            return machine.machineName.toUpperCase().trim()
          })

          let sortedMachineNames = machineNames

          if (!IsSorted(machineNames, comparator)) {
            sortedMachineNames = machineNames.sort((a, b) => {
              return comparator(a, b)
            })
          }

          const index = binarySearch(
            sortedMachineNames,
            machineNameRef.current.toUpperCase().trim(),
            comparator
          )

          return index !== -1
        }
      }
      return false
    })

    if (plants && plants.length <= 0) {
      formErrors.push({ id: 'plant', message: 'Required' })
    }
    if (!machineNameRef.current) {
      formErrors.push({ id: 'machineName', message: 'Required' })
    }
    if (machineNameExists && machineNameRef.current !== state.machineName) {
      formErrors.push({
        id: 'machineName',
        message: 'Machine name already exists for this plant.',
      })
    }
    if (machineNameRef.current.length > 50) {
      formErrors.push({ id: 'machineName', message: 'Max of 50 characters' })
    }
    if (modelNameRef.current !== null && modelNameRef.current.length > 50) {
      formErrors.push({ id: 'modelName', message: 'Max of 50 characters' })
    }
    if (custEquIDRef.current !== null && custEquIDRef.current.length > 50) {
      formErrors.push({ id: 'custEquID', message: 'Max of 50 characters' })
    }
    if (!state.selectedMachineType) {
      formErrors.push({ id: 'machineType', message: 'Required' })
    }
    if (!state.selectedMachineMfg) {
      formErrors.push({ id: 'machineMfg', message: 'Required' })
    }
    if (!lubricantTypeRef.current) {
      formErrors.push({ id: 'lubeType', message: 'Required' })
    }

    setState(prev => ({ ...prev, formErrors: formErrors }))

    return !formErrors.some(x => x)
  }

  const handleSubmit = async (event, filterState: TFilterState) => {
    if (!handleFormValidation(filterState.plants)) return

    if (state.submitting === false) {
      const postBody = {
        pointID: state.pointID,
        plantID: state.selectedPlant?.plantID,
        routeID:
          filterState.routes.length > 0 ? filterState.routes[0]?.routeID : null,
        machineName: machineNameRef.current,
        custEquID: custEquIDRef.current,
        machType: state.selectedMachineType,
        machMFG: state.selectedMachineMfg,
        modelName: modelNameRef.current,
        lubeTypeID: lubricantTypeRef.current,
        sumpCap: state.sumpCap,
      }
      try {
        APIService.saveMachine(postBody)
          .then(() => {
            const pointID = Number(state.pointID)
            const lubeID = Number(lubricantTypeRef.current)
            let machine = profileContext.profile?.machines?.find(
              m => m.pointID === pointID
            )

            if (machine == null) {
              // const nextMachNumber =
              //   Number(
              //     profileContext.profile?.machines.sort(
              //       (a, b) => Number(b.machNo) - Number(a.machNo)
              //     )[0]?.machNo ?? -2
              //   ) + 1
              // machine = {
              //   ...postBody,
              //   pointID: Number(postBody.pointID),
              //   sumpCap: Number(postBody.sumpCap),
              //   machNo: nextMachNumber.toString(),
              //   lubricant: profileContext.profile?.lubeTypes.find(
              //     l => l.lubeTypeID === lubeID
              //   ),
              // }
              // profileContext.profile?.machines.push(machine)
              profileContext.utilities.reloadProfile()
            } else {
              machine.custEquID = custEquIDRef.current ?? machine.custEquID
              machine.lubricant =
                profileContext.profile?.lubeTypes?.find(
                  lt => lt.lubeTypeID === lubeID
                ) ?? machine.lubricant
              machine.machType = state.selectedMachineType ?? machine.machType
              machine.machMFG = state.selectedMachineMfg ?? machine.machMFG
              machine.machineName =
                machineNameRef.current ?? machine.machineName
              machine.modelName = modelNameRef.current ?? machine.modelName

              profileContext.utilities.reparseMachineLists()
            }
            navigate('/program-management/equipment')
          })
          .catch(ex => {
            setState(prev => ({ ...prev, finalizing: false }))
            onError(ex)
          })

        setState(prev => ({ ...prev, finalizing: true }))
      } catch (ex) {
        onError(ex)
        setState(prev => ({ ...prev, submitting: false }))
      }
    }
  }

  const generateOptions = (): Partial<TFilterState> => {
    return {
      customers: state.customers,
      divisions: state.divisions,
      machines: state.machines,
      plants: state.customers?.find(c => c.custID === state.selectedCustomer)
        ?.plants,
      routes: state.customers?.find(c => c.custID === state.selectedCustomer)
        ?.routes,
      machineManufacturers: state.machineMfgOptions,
      machinePicklistTypes: state.machineTypeOptions,
    } as Partial<TFilterState>
  }

  const generatedFilterOptions = generateOptions()

  const initialState: Partial<TFilterState> = {
    customers: state.selectedCustomer
      ? [state.customers?.find(c => c.custID === state.selectedCustomer)]
      : [],
    plants: state.selectedPlant
      ? [state.plants.find(p => p.plantID === state.selectedPlant.plantID)]
      : [],
    routes:
      state.selectedRoute &&
      state.customers
        ?.find(c => c.custID === state.selectedCustomer)
        ?.routes.find(r => r.routeID === state.selectedRoute)
        ? [
            state.customers
              ?.find(c => c.custID === state.selectedCustomer)
              ?.routes.find(r => r.routeID === state.selectedRoute),
          ]
        : [],
    machineNames: state.machineName ? [state.machineName] : [],
    customerEquipmentIDs: state.custEquID ? [state.custEquID] : [],
  }

  const date = new Date()
  const ohioTime = date.getUTCHours() - 4
  let greeting = ''

  if (ohioTime < 12) {
    greeting = 'Good morning,\n'
  } else if (ohioTime >= 12 && ohioTime < 17) {
    greeting = 'Good afternoon,\n'
  } else {
    greeting = 'Good evening,\n'
  }

  // title
  const deleteEquipmentTitleText =
    'Delete Equipment Request' +
    (profileContext.profile?.user?.loginID
      ? ` - ${profileContext.profile?.user?.loginID}`
      : '')

  let deleteEquipmentBodyText = ''

  // Location Information
  deleteEquipmentBodyText +=
    greeting + 'Please delete the following machine:\n\n'
  deleteEquipmentBodyText += 'Location Information\n'
  deleteEquipmentBodyText +=
    initialState?.divisions?.length > 0 && initialState.divisions[0]
      ? `Division: ${initialState.divisions[0].name} (${initialState.divisions[0].iD})\n`
      : ''
  deleteEquipmentBodyText +=
    initialState?.customers?.length > 0 && initialState.customers[0]
      ? `Customer: ${initialState.customers[0].custName} (${initialState.customers[0].custID})\n`
      : ''
  deleteEquipmentBodyText +=
    initialState?.plants?.length > 0 && initialState.plants[0]
      ? `Plant: ${initialState.plants[0].plantName} (${initialState.plants[0].plantNo})\n`
      : ''
  deleteEquipmentBodyText += state.machine?.machNo
    ? `Machine Number: ${state.machine.machNo}\n`
    : ''

  deleteEquipmentBodyText +=
    state.machine?.machNo &&
    initialState?.customers?.length > 0 &&
    initialState.customers[0] &&
    initialState?.plants?.length > 0 &&
    initialState.plants[0]
      ? `${initialState.customers[0].custID}-${initialState.plants[0].plantNo}-${state.machine.machNo}\n`
      : ''

  // machine information
  deleteEquipmentBodyText += state.machine ? '\nMachine Information\n' : ''
  deleteEquipmentBodyText += state.machine?.machineName
    ? `Name: ${state.machine.machineName}\n`
    : ''
  deleteEquipmentBodyText += state.machine?.custEquID
    ? `Equipment ID: ${state.machine.custEquID}\n`
    : ''
  deleteEquipmentBodyText += state.machine?.modelName
    ? `Model: ${state.machine.modelName}\n`
    : ''
  deleteEquipmentBodyText += state.machine?.machNo
    ? `Number: ${state.machine.machNo}\n`
    : ''
  deleteEquipmentBodyText += state.machine?.machMFG
    ? `Manufacturer: ${state.machine.machMFG}\n`
    : ''
  deleteEquipmentBodyText += state.machine?.machType
    ? `Type: ${state.machine.machType}\n`
    : ''

  deleteEquipmentBodyText = encodeURIComponent(deleteEquipmentBodyText)

  return (
    <React.Fragment>
      <SEO title="Manage Equipment" />
      <Grid container justify="center">
        <Grid item md={4} lg={2}>
          <Box mb={2}>
            {state.finalizing ? (
              <Typography variant="h1">Saving Equipment Changes...</Typography>
            ) : (
              <Typography variant="h1">Manage Equipment</Typography>
            )}

            {offline ? <OfflineBanner /> : <></>}

            {!state.loaded || !state.profileUpdated || state.finalizing ? (
              <Loader />
            ) : (
              <WithDisableFiltersBox>
                {(useFilters, filtersInfoBox) => (
                  <WithSmartFilters
                    filterOptions={generatedFilterOptions}
                    disableOptionFiltering={true}
                    initialState={initialState}
                    filters={[
                      Filters.Divisions,
                      Filters.Customers,
                      Filters.Plant,
                      Filters.Route,
                      Filters.MachineType,
                      Filters.MachineManufacturer,
                    ]}
                  >
                    {({
                      filterState,
                      filterOptions,
                      reset,
                      filters,
                    }: TSmartFilter) => (
                      <form>
                        {profileContext.minimumProfileLoaded ? (
                          <Grid
                            container
                            item
                            style={{ marginTop: '15px' }}
                            direction="column"
                            spacing={2}
                          >
                            {state.customers.length > 1 ? (
                              <Grid item>
                                <filters.SmartCustomerFilter
                                  multiple={false}
                                  required={true}
                                disabled
                                />
                                <FilterLabel>
                                  To move equipment across customers, please{' '}
                                  <a
                                    className={Styles.standardAnchor}
                                    href={`mailto: ${
                                      process.env
                                        .REACT_APP_CUSTOMER_SERVICE_EMAIL
                                    }?Subject=${encodeURI(
                                      'Equipment Move Request'
                                    )}`}
                                  >
                                    contact customer service.
                                  </a>
                                </FilterLabel>
                              </Grid>
                            ) : null}
                            {state.plants.length > 1 ? (
                              <Grid item>
                                <FormField
                                  id="plant"
                                  formErrors={state.formErrors}
                                >
                                  <PlantNameFilter
                                    single={true}
                                    required={true}
                                    plants={state.plants}
                                    onChange={handlePlantChange}
                                    value={state.selectedPlant}
                                  />
                                </FormField>
                              </Grid>
                            ) : null}
                            {state.routes.length === 0 ? null : (
                              <Grid item>
                                <filters.SmartRouteFilter
                                  multiple={false}
                                />
                              </Grid>
                            )}
                            <Grid item>
                              <FormField
                                id="machineName"
                                formErrors={state.formErrors}
                              >
                                <TextField
                                  required
                                  label="Machine Name"
                                  name="machineName"
                                  inputProps={{ maxLength: 50 }}
                                  inputRef={inputMachineNameRef}
                                  onChange={handleMachineNameChange}
                                  onBlur={_ => handleFormValidation()}
                                  style={{ width: 225 }}
                                />
                              </FormField>
                              <FilterLabel>
                                An identifiable name associated with a machine
                                or a sample point.
                              </FilterLabel>
                            </Grid>
                            <Grid item>
                              <FormField
                                id="machineMfg"
                                formErrors={state.formErrors}
                              >
                                <MachineManufacturerFilter
                                  single={true}
                                  required={true}
                                  machineMfgs={state.machineMfgOptions}
                                  onChange={handleMachineMfgChange}
                                  value={state.selectedMachineMfg}
                                />
                              </FormField>
                            </Grid>
                            <Grid item>
                              <FormField
                                id="modelName"
                                formErrors={state.formErrors}
                              >
                                <TextField
                                  label="Model"
                                  name="modelName"
                                  inputProps={{ maxLength: 50 }}
                                  inputRef={inputModelNameRef}
                                  onChange={handleModelNameChange}
                                  onBlur={_ => handleFormValidation()}
                                  style={{ width: 225 }}
                                />
                              </FormField>
                            </Grid>
                            <Grid item>
                              <FormField
                                id="machineType"
                                formErrors={state.formErrors}
                              >
                                <MachineTypeFilter
                                  single={true}
                                  required={true}
                                  machineTypes={state.machineTypeOptions}
                                  onChange={handleMachineTypeChange}
                                  value={state.selectedMachineType}
                                />
                              </FormField>
                            </Grid>
                            <Grid item>
                              <FormField
                                id="custEquID"
                                formErrors={state.formErrors}
                              >
                                <TextField
                                  label="Equipment ID"
                                  name="custEquID"
                                  inputProps={{ maxLength: 50 }}
                                  inputRef={inputCustEquIDRef}
                                  onChange={handleCustEquIDChange}
                                  onBlur={_ => handleFormValidation()}
                                  style={{ width: 225 }}
                                />
                              </FormField>
                              <FilterLabel>
                                An alternative name used to identify this
                                equipment.
                              </FilterLabel>
                            </Grid>
                            <Grid item>
                              <FormField
                                id="lubeType"
                                formErrors={state.formErrors}
                              >
                                <PairedFluidTypeManufacturerFilter
                                  LubeTypeID={state.selectedLubeType}
                                  onLubeTypeChange={handleLubeTypeChange}
                                />
                              </FormField>
                            </Grid>
                            <Grid
                              container
                              item
                              direction="row"
                              style={{ marginTop: '15px' }}
                              spacing={2}
                            >
                              <Grid item xs={6}>
                                <Button
                                  variant="contained"
                                  style={{ width: '100%' }}
                                  color="secondary"
                                  onClick={event => {
                                    handleSubmit(event, filterState)
                                  }}
                                >
                                  Save
                                </Button>
                              </Grid>
                              <Grid item xs={6}>
                                <Button
                                  variant="contained"
                                  style={{ width: '100%' }}
                                  color="secondary"
                                  onClick={handleCancelClick}
                                >
                                  Cancel
                                </Button>
                              </Grid>
                              <Grid item xs={12}>
                                <a
                                  href={`mailto:${process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL}?Subject=${deleteEquipmentTitleText}&Body=${deleteEquipmentBodyText}`}
                                  style={{ textDecoration: 'none' }}
                                >
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ width: '100%' }}
                                  >
                                    Request Delete Equipment
                                  </Button>
                                </a>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          <Loader />
                        )}
                      </form>
                    )}
                  </WithSmartFilters>
                )}
              </WithDisableFiltersBox>
            )}
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default function Page() {
  return (
    <AppLayout tab="program-management">
      <ManageEquipment />
    </AppLayout>
  )
}
