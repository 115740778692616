import { createStyles, makeStyles } from '@material-ui/core'

export const useCustomTableStyles = makeStyles(() =>
  createStyles({
    table: {
      '& .k-grid-header': {
        '& col:nth-last-of-type(12)': {
          width: '10%',
        },
        '& col:nth-last-of-type(11)': {
          width: '10%',
        },
        '& col:nth-last-of-type(10)': {
          width: '10%',
        },
        '& col:nth-last-of-type(9)': {
          width: '5%',
        },
        '& col:nth-last-of-type(8)': {
          width: '10%',
        },
        '& col:nth-last-of-type(7)': {
          width: '5%',
        },
        '& col:nth-last-of-type(6)': {
          width: '5%',
        },
        '& col:nth-last-of-type(5)': {
          width: '10%',
        },
        '& col:nth-last-of-type(4)': {
          width: '10%',
        },
        '& col:nth-last-of-type(3)': {
          width: '10%',
        },
        '& col:nth-last-of-type(2)': {
          width: '5%',
        },
        '& col:nth-last-of-type(1)': {
          width: '5%',
        },
      },
      '& .k-grid-table': {
        '& col:nth-last-of-type(12)': {
          width: '10%',
        },
        '& col:nth-last-of-type(11)': {
          width: '10%',
        },
        '& col:nth-last-of-type(10)': {
          width: '10%',
        },
        '& col:nth-last-of-type(9)': {
          width: '5%',
        },
        '& col:nth-last-of-type(8)': {
          width: '10%',
        },
        '& col:nth-last-of-type(7)': {
          width: '5%',
        },
        '& col:nth-last-of-type(6)': {
          width: '5%',
        },
        '& col:nth-last-of-type(5)': {
          width: '10%',
        },
        '& col:nth-last-of-type(4)': {
          width: '10%',
        },
        '& col:nth-last-of-type(3)': {
          width: '10%',
        },
        '& col:nth-last-of-type(2)': {
          width: '5%',
        },
        '& col:nth-last-of-type(1)': {
          width: '5%',
        },
      },
    },
  })
)
