import { FetchError } from 'utils/Errors/FetchError'
import { ForbiddenCardModule } from '../../CardModules/ForbiddenCardModule'
import { LoadingErrorCardModule } from '../../CardModules/LoadingErrorCardModule'
import { NotFoundCardModule } from '../../CardModules/NotFoundCardModule'
import * as PageStyles from '../../page-styles.module.css'

export function useErrorPage(
  error: Error,
  errorHeaderMap: Map<number, JSX.Element> | null = null
) {
  if (!error) return null

  if (!errorHeaderMap) errorHeaderMap = new Map()

  if (error instanceof FetchError) {
    switch (error.status) {
      case 404: {
        const header = errorHeaderMap.has(404) ? (
          errorHeaderMap.get(404)
        ) : (
          <>
            {' '}
            <header className={PageStyles.MainTitle}>Not Found</header>
            <sub className={PageStyles.SubTitle}>
              <span className={PageStyles.BlockSpan}>
                {error.message}
              </span>
            </sub>
          </>
        )
        return <NotFoundCardModule header={header} />
      }
      case 401: {
        const header = errorHeaderMap.has(401) ? errorHeaderMap.get(401) : null
        return <ForbiddenCardModule header={header} hideFooter />
      }
      case 403: {
        return <ForbiddenCardModule hideFooter />
      }
      case 450: {
        const header = errorHeaderMap.has(450) ? errorHeaderMap.get(450) : null
        return <ForbiddenCardModule header={header} hideFooter />
      }
      case 451: {
        const header = errorHeaderMap.has(451) ? errorHeaderMap.get(451) : null
        return <ForbiddenCardModule header={header} hideFooter />
      }
      default: {
        return <LoadingErrorCardModule hideFooter />
      }
    }
  } else {
    return <LoadingErrorCardModule hideFooter />
  }
}
