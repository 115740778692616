import React from 'react'
import PageStyles from '../page-styles.module.css'
import Button from '@material-ui/core/Button'
import HeartBrokenIcon from '@mui/icons-material/HeartBroken'

type TProps = {
  hideFooter?: boolean
}
export const LoadingErrorCardModule = (props: TProps) => {
  return (
    <>
      <div>
        <header className={PageStyles.MainTitle}>...Uh Oh...</header>
        <sub className={PageStyles.SubTitle}>
          <span className={PageStyles.BlockSpan}>Something went wrong!</span>
        </sub>
      </div>
      <div className={PageStyles.LargeIconBox}>
        <HeartBrokenIcon
          className={PageStyles.BrokenHeartIcon}
          style={{ fontSize: '9rem' }}
        />
      </div>
      <div>
        {!props.hideFooter && (
          <>
            <section className={PageStyles.DetailSection}>
              <p className={PageStyles.FinePrintParagraph}>
                There was an error while loading or updating your information.
                This may be an issue with our server, or the server simply could
                not be reached due to a lack of connection. Please try again
                later or in a location with an available connection. You can
                click the button below to refresh the page.
              </p>
            </section>

            <Button
              variant="contained"
              color="primary"
              data-cancel
              fullWidth
              onClick={() => {
                window?.location.reload()
              }}
            >
              Refresh
            </Button>
          </>
        )}
      </div>
    </>
  )
}
