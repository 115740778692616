import React from 'react'
import {
  Box,
  Button,
  Hidden,
  TablePagination,
  Tooltip,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import {
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'
import * as Moment from 'moment'
import { extendMoment } from 'moment-range'

import TableGrid from 'components/TableGrid'
import { machineConditionKeys } from 'services/lookup-service'

import { MobileCard } from './MobileCard'

type TProps = {
  offline?: boolean
  startDate: Date
  endDate: Date
  data: any[]
  orderConfig: any[]
  page: number
  pageSize: number
  count: number
  onPageChange?: (_event: GridPageChangeEvent) => void
  onSortChange?: (_event: GridSortChangeEvent) => void
  onPageChangeMobile: (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    _page: number
  ) => void
  onPageSizeChangeMobile?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >
}

const moment = extendMoment(Moment)

export const ScheduleComplianceReportTable: React.FC<TProps> = ({
  offline,
  startDate,
  endDate,
  data,
  orderConfig,
  page,
  pageSize,
  count,
  onPageChange,
  onSortChange,
  onPageSizeChangeMobile,
  onPageChangeMobile,
}: TProps) => {
  const showDivisions = data.some(s => s.machine.plant.customer.division)

  const months = []
  const range = moment.range(startDate, endDate)
  for (const month of range.by('month')) {
    months.push({
      year: month.year(),
      month: month.month() + 1,
      columnTitle: month.format('MMM YY'),
    })
  }

  return (
    <>
      {offline && (
        <Box mb={2}>Your last 100 reports are available while offline.</Box>
      )}

      <Hidden mdUp>
        {!data?.length && (
          <p style={{ textAlign: 'center' }}>No records available</p>
        )}
        {data.map(item => (
          <MobileCard
            key={item.labID}
            data={item}
            showDivisions={showDivisions}
            months={months}
          />
        ))}

        {!offline && (
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100, 250, 500]}
            component="div"
            count={count}
            rowsPerPage={pageSize}
            page={page > 0 ? page - 1 : page}
            onChangePage={onPageChangeMobile}
            onChangeRowsPerPage={onPageSizeChangeMobile}
          />
        )}
      </Hidden>

      <Hidden smDown>
        <TableGrid
          clearSortingButtonId="schedule_compliance_report-clear_sorting_button"
          offline={offline}
          data={data}
          sort={orderConfig}
          skip={(page - 1) * pageSize}
          take={pageSize}
          total={count}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
        >
          {showDivisions && (
            <Column
              field="machine.plant.customer.division.name"
              title="Division Name"
            />
          )}
          <Column
            field="machine.plant.customer.custName"
            title="Customer Name"
          />
          <Column field="machine.plant.plantName" title="Plant Name" />
          <Column
            field="machine.route.routeNo"
            title="Route"
            // sortable={false}
            cell={({ dataItem: { machine: data } }) => (
              <td>
                {data.route && `${data.route.routeNo} - ${data.route.name}`}
              </td>
            )}
          />
          <Column field="machine.machNo" title="Mach No." />
          <Column
            field="latestLabID"
            title="Machine Name"
            cell={({ dataItem }) =>
              dataItem.latestLabID ? (
                <td style={{ color: '#6200EE' }}>
                  <a
                    href={`/dart/data?labID=${dataItem.latestLabID}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {dataItem.machine.machineName}
                  </a>
                </td>
              ) : (
                <td>{dataItem.machine.machineName}</td>
              )
            }
          />
          <Column field="machine.custEquID" title="Equipment ID" />
          <Column field="testGroup.testGroupName" title="Test Package" />
          <Column
            field="testGroup"
            title="Tests"
            sortable={false}
            cell={({
              dataItem: {
                testGroup: { testGroupDefs },
              },
            }) => (
              <td>
                {testGroupDefs.map(x => x.testCode.testCodeName).join(', ')}
              </td>
            )}
          />
          {months.map(({ month, year, columnTitle }, i) => {
            return (
              <Column
                key={i}
                field={columnTitle}
                title={columnTitle}
                sortable={false}
                cell={({ dataItem: data }) => (
                  <RenderMonthsDynamicColumns
                    data={data}
                    month={month}
                    year={year}
                  />
                )}
              />
            )
          })}
          <Column
            field="scheduled"
            title="Scheduled"
            cell={({ dataItem: data }) => (
              <td>
                {data.counts
                  .map(x => x.scheduled)
                  .reduce((prev, cur) => prev + cur, 0)}
              </td>
            )}
          />
          <Column
            field="completed"
            title="Completed"
            cell={({ dataItem: data }) => (
              <td>
                {data.counts
                  .map(x => x.tested)
                  .reduce((prev, cur) => prev + cur, 0)}
              </td>
            )}
          />
          <Column
            field="compliance"
            title="Compliance"
            cell={({ dataItem: data }) => (
              <td>
                {data.counts
                  .map(x => x.scheduled)
                  .reduce((prev, cur) => prev + cur, 0) === 0
                  ? 100
                  : data.counts
                      .map(x => x.tested)
                      .reduce((prev, cur) => prev + cur, 0) >=
                    data.counts
                      .map(x => x.scheduled)
                      .reduce((prev, cur) => prev + cur, 0)
                  ? 100
                  : Math.round(
                      (data.counts
                        .map(x => x.tested)
                        .reduce((prev, cur) => prev + cur, 0) /
                        data.counts
                          .map(x => x.scheduled)
                          .reduce((prev, cur) => prev + cur, 0)) *
                        100
                    )}
                %
              </td>
            )}
          />
        </TableGrid>
      </Hidden>
    </>
  )
}

export const RenderMonthsDynamicColumns = ({
  data,
  month,
  year,
  isMobile = false,
}: any) => {
  const count = data.counts.find(y => y.month === month && y.year === year)
  const condition =
    typeof count === 'undefined'
      ? 0
      : count.machCond > 2 || count.lubCond > 2
      ? 3
      : count.machCond === 2 || count.lubCond === 2
      ? 2
      : count.machCond === 1 || count.lubCond === 1
      ? 1
      : 0

  const renderCheckBoxes = () =>
    count.tested > 0 && count.scheduled === 0 ? (
      <Tooltip title="Tested, not scheduled">
        <CheckIcon />
      </Tooltip>
    ) : count.tested === 0 && count.scheduled > 0 ? (
      <Tooltip title="Scheduled, not tested">
        <CheckBoxOutlineBlankIcon />
      </Tooltip>
    ) : count.tested === 0 && count.scheduled === 0 ? (
      ''
    ) : (
      <Tooltip title="Scheduled and tested">
        <CheckBoxIcon />
      </Tooltip>
    )

  const styles = {
    backgroundColor:
      condition === 1
        ? '#8af07d'
        : condition === 2
        ? '#ffec12'
        : condition === 3
        ? '#f07d7d'
        : '',
  }

  if (isMobile) {
    if (!count) return <div>No content</div>
    return <div style={styles}>{renderCheckBoxes()}</div>
  }

  if (!count) return <td>No content</td>

  return <td style={styles}>{renderCheckBoxes()}</td>
}
