import React from 'react'
import { TextFieldProps } from '@material-ui/core'

import FilterTooltip from '../FilterTooltip/FilterTooltip'

import { TextFieldFilter } from './text-field-filter'

type TProps = {
  tooltip?: boolean
}

export const PONumberFilter: React.FC<TextFieldProps> = (
  props: TextFieldProps & TProps
) => {
  const { tooltip = true, ...rest } = props

  const filter = <TextFieldFilter id="poNo" placeholder="PO No" {...rest} />

  return tooltip ? (
    <FilterTooltip title="PO number.">{filter}</FilterTooltip>
  ) : (
    filter
  )
}
