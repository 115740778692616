import { ScoreCard } from 'components/ScoreCard'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { OrderType, TInvoiceInformation, TSpendingEntity } from 'types/api.d'
import { useThemeContext } from 'Contexts/ThemeContext'
import { StringFormatter } from 'utils/string-utilities'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

import * as Styles from '../index.module.css'
import * as CardStyles from './index.module.css'
import { Button } from '@material-ui/core'
import { getRepresentativeOrderType } from 'utils/financial-utilities'

type TAvailableBudgetCardProps = {
  spendingData: TSpendingEntity[]
}

export const AmountDueCard: React.FC<TAvailableBudgetCardProps> = props => {
  const theme = useThemeContext()

  const now = new Date()

  let amountDue = 0
  let amountPastDue = 0

  const representiativeOrderType = getRepresentativeOrderType(
    props.spendingData
  )

  const dollarBalanced = representiativeOrderType === OrderType.DollarBalance
  const sampleBalanced = representiativeOrderType === OrderType.Sample

  props.spendingData.forEach(datum => {
    const uniqueInvoices: Map<string, TInvoiceInformation> = new Map()

    for (let invoice of props.spendingData.flatMap(datum => datum.invoices)) {
      if (!uniqueInvoices.has(invoice.invoiceNumber)) {
        uniqueInvoices.set(invoice.invoiceNumber, invoice)
      }
    }

    uniqueInvoices.forEach(invoice => {
      const invoiceDate = new Date(invoice.dueDate)
      if (invoiceDate < now) amountPastDue += invoice.amountDue

      amountDue += invoice.amountDue
    })
  })

  const getOverdueIcon = (amountOverDue: number) => {
    return amountOverDue > 0 ? (
      <ErrorOutlineIcon style={{ color: theme.palette.charts.overBudget }} />
    ) : (
      <CheckCircleOutlineIcon
        style={{ color: theme.palette.charts.withinBudget }}
      />
    )
  }

  const navigator = useNavigate()
  const navigateToPaymentPage = () => {
    navigator('/financials/invoices', { replace: false })
  }

  const nowDateString = `${now.getMonth() + 1}/${now.getFullYear()}`
  const title = `Total Amount Due`

  return (
    <ScoreCard
      toolTip={{
        title: title,
        text:
          `The total amount due across all invoices relevant to selected business units.` +
          ` Please note that because charges are independent of business units, such as plants or customers,` +
          ` this amount may encompass the amount due for multiple business units and may not reflect units selected in the filters.`,
      }}
    >
      <div className={Styles.Header}>
        <h1
        // style={{
        //   color:
        //     amountDue > 0
        //       ? theme.palette.charts.overBudget
        //       : theme.palette.charts.withinBudget,
        // }}
        >
          <strong>
            {dollarBalanced
              ? StringFormatter.formatAsUSD(amountDue)
              : sampleBalanced
              ? `${Math.round(amountDue).toFixed(0)} Samples`
              : 'N/A'}
          </strong>
        </h1>
        <h6>
          <strong>{title}</strong>
        </h6>
      </div>
      <div style={{ width: '100%' }}>
        <div className={Styles.DifferenceContainer}>
          <span>
            {getOverdueIcon(amountPastDue)} Amount overdue: {'\t'}
          </span>
          <span>
            {dollarBalanced
              ? StringFormatter.formatAsUSD(amountPastDue)
              : sampleBalanced
              ? `${Math.round(amountPastDue).toFixed(0)} Samples`
              : 'N/A'}
          </span>
        </div>
        <div className={CardStyles.PaymentPageButtonContainer}>
          <a
            href="/financials/invoices"
            target="_blank"
            style={{ textDecoration: 'none' }}
          >
            <Button
              // onClick={navigateToPaymentPage}
              variant="contained"
              color="secondary"
            >
              View Invoices
            </Button>
          </a>
        </div>
      </div>
    </ScoreCard>
  )
}
