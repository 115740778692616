import { Checkbox, CheckboxProps, InputProps } from '@material-ui/core'
import React, { Ref, forwardRef, useImperativeHandle, useState } from 'react'

type TProps = Omit<CheckboxProps, 'ref'>

export const ResettableCheckbox = forwardRef<TSettableCheckbox, TProps>(
  (props, ref) => {
    useImperativeHandle(ref, () => ({
      reset,
      set,
      checked: value,
    }))

    const [value, setValue] = useState<boolean>(!!props.value)
    function onChange(
      event: React.ChangeEvent<HTMLInputElement>,
      checked: boolean
    ) {
      setValue(checked)

      if (typeof props.onChange === 'function') {
        props.onChange(event, checked)
      }
    }

    function reset() {
      setValue(!!props.value)
    }

    function set(value: boolean) {
      setValue(value)
    }

    return (
      <Checkbox
        {...props}
        onChange={(e, c) => onChange(e, c)}
        value={value}
        checked={value}
      />
    )
  }
)
