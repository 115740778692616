import { Box, Typography } from '@material-ui/core'
import AppLayout from 'components/AppLayout'
import OilAnalysisReport from 'components/OilAnalysisReport'

// TODO: verify if this is necessary
// const initialStartDate = moment()
//   .subtract(3, 'months')
//   .startOf('month')
//   .startOf('day')
//   .toDate()
// const initialEndDate = moment().endOf('month').endOf('day').toDate()

// const defaults = {
//   page: 1,
//   pageSize: 100,
//   orderConfig: [{ field: 'sampleDate', dir: 'desc' }],
//   /**
//    * Why the hell is this not a simple union type?
//    * TODO: refactor to `type searchDate = 'sample' | 'report'`
//    */
//   sampleDate: true,
//   reportDate: false,
//   startDate: initialStartDate,
//   endDate: initialEndDate,
//   selectedDivisionIDs: [],
//   selectedCustomerIDs: [],
//   selectedPlants: [],
//   selectedMachineNames: [],
//   selectedRoutes: [],
//   selectedMachineTypes: [],
//   selectedLubeTypes: [],
//   selectedMachineConditions: [],
//   selectedLubeConditions: [],
//   selectedReportTypes: [],
//   selectedSamples: [],
//   labNo: '',
//   hideReviewedReports: false,
//   exportFormat: '',
//   selectAllSamples: false,
//   samples: [],
// }

// const _prefetch = async _profile => {
//   const data = await APIService.getOilAnalysis({
//     page: defaults.page,
//     pageSize: defaults.pageSize,
//     orderConfig: defaults.orderConfig,
//     startDate: defaults.startDate,
//     endDate: defaults.endDate,
//     sampleDate: defaults.sampleDate,
//     reportDate: defaults.reportDate,
//     customerIDs: defaults.selectedCustomerIDs,
//     plantIDs: defaults.selectedPlants,
//     machineNames: defaults.selectedMachineNames,
//     routeIDs: defaults.selectedRoutes,
//     machineTypes: defaults.selectedMachineTypes,
//     lubeTypes: defaults.selectedLubeTypes,
//     machineConditions: defaults.selectedMachineConditions,
//     lubeConditions: defaults.selectedLubeConditions,
//     reportTypes: defaults.selectedReportTypes,
//     labNo: defaults.labNo,
//     reviewed: defaults.hideReviewedReports === true ? false : null,
//   })

//   return data
// }

export default function Page() {
  // TODO: if (prefetch) return _prefetch

  return (
    <AppLayout tab="reports">
      <Box mb={2}>
        <Typography variant="h1">View Your Reports</Typography>
      </Box>
      <OilAnalysisReport />
    </AppLayout>
  )
}
