import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import CropFreeIcon from '@material-ui/icons/CropFree'
import DateRangeIcon from '@material-ui/icons/DateRange'
import LabelIcon from '@material-ui/icons/Label'
import LocalDrinkIcon from '@material-ui/icons/LocalDrink'
import SettingsIcon from '@material-ui/icons/Settings'
import ViewListIcon from '@material-ui/icons/ViewList'
import PropTypes from 'prop-types'

import AppLayout from 'components/AppLayout'
import NavCard from 'components/NavCard'
import SEO from 'components/SEO'

const pages = [
  {
    url: '/program-management/schedule',
    title: 'Manage Schedule',
    description: 'Create and edit sampling schedules and test packages.',
    background: '#446ca2',
    icon: <DateRangeIcon />,
  },
  {
    url: '/program-management/routes',
    title: 'Manage Routes',
    description:
      'Design lubrication task routes to optimize sampling efficiency.',
    background: '#452d12',
    icon: <ViewListIcon />,
  },
  {
    url: '/program-management/equipment',
    title: 'Manage Equipment Database',
    description:
      'Edit your equipment and lubricant information to ensure a detailed analysis.',
    background: '#861d1d',
    icon: <SettingsIcon />,
  },
  {
    url: '/program-management/sample-labels/standard',
    title: 'Standard Sample Labels',
    description: 'View and print the sample bottle labels.',
    background: '#131553',
    icon: <LabelIcon />,
  },
  {
    url: '/program-management/supplies',
    title: 'Order Supplies',
    description:
      'Select items needed to pull oil samples, from bottles to tubing.',
    background: '#ef6a2f',
    icon: <LocalDrinkIcon />,
  },
  {
    url: '/program-management/order-information',
    title: 'Order Information',
    description:
      'View previous and incoming order details, track shipments, and view other key information.',
    background: '#ef6a2f',
    icon: <LocalDrinkIcon />,
  },
]

function ProgramManagement() {
  return (
    <React.Fragment>
      <SEO title="Program Management" />
      <Grid container direction="row" spacing={4}>
        <Grid item md={3}>
          <Typography variant="h1">Program Management</Typography>
          <Box style={{ marginTop: '15px' }}>
            <Typography paragraph>
              A detailed analysis starts with great information. Keep your
              program's database and information up-to-date using a variety of
              tools.
            </Typography>
          </Box>
        </Grid>
        <Grid container item direction="row" md={9} spacing={4}>
          {pages.map((page, i) => (
            <NavCard
              key={i}
              icon={page.icon}
              title={page.title}
              description={page.description}
              url={page.url}
              background={page.background}
            ></NavCard>
          ))}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

ProgramManagement.propTypes = {
  onError: PropTypes.func,
}

export default function Page() {
  return (
    <AppLayout tab="program-management">
      <ProgramManagement />
    </AppLayout>
  )
}
