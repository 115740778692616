import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import MomentUtils from '@date-io/moment'
import { Snackbar } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useNavigate } from 'react-router-dom'

import PropTypes from 'prop-types'

import Loader from 'components/Loader'
import coreTheme from 'components/theme'
import APIService, { TProfile } from 'services/api-service'
import LoginService from 'services/login-service'
import { ProfileContext, useProfile } from 'Contexts/ProfileContext'

const theme = createTheme(
  {
    overrides: {
      MuiFormLabel: {
        root: {
          color: '#222',
          zIndex: 99,
        },
      },
    },
  },
  coreTheme
)

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
  },
}))

const FullScreenLayout = ({ children }) => {
  const classes = useStyles()
  const [error, setError] = React.useState(null)
  const [isLoggedIn, setIsLoggedIn] = React.useState(false)
  const profileContext = useProfile()
  const navigate = useNavigate()

  React.useEffect(() => {
    ;(async () => {
      const loggedIn = await LoginService.isLoggedIn()

      if (!loggedIn) {
        if (typeof window !== 'undefined') {
          profileContext.utilities.clearProfile()
          navigate(
            `/login?PostLoginURL=${
              window.location.pathname
            }${encodeURIComponent(window.location.search)}`
          )
        }
      }
      profileContext.utilities.setUserLoggedIn(loggedIn)
      setIsLoggedIn(loggedIn)

      // if (profile !== null) return

      // try {
      //   // TODO: This could probably be stashed in localStorage:
      //   let data: TProfile

      //   const localProfile: TProfile = await webStorage.getItem('profile')

      //   if (localProfile != null) {
      //     data = localProfile
      //   } else {
      //     data = await APIService.getProfile()
      //     webStorage.setItem('profile', data)
      //   }
      //   setProfile(data)
      // } catch (ex) {
      //   handleError(ex)
      // }
    })()
  }, [isLoggedIn])

  const handleError = error => {
    let failedAuth = false

    if (error === null) {
      setError(null)
    } else if (typeof error.errors !== 'undefined') {
      failedAuth =
        error.errors.filter(
          x =>
            typeof x.extensions !== 'undefined' &&
            x.extensions.code === 'authorization'
        ).length > 0
      if (!failedAuth) {
        setError(
          error.errors
            .map(x =>
              typeof x.extensions !== 'undefined'
                ? x.extensions.code
                : x.message
            )
            .join(', ')
        )
      }
    } else if (error instanceof Error) {
      failedAuth = error.message === 'authorization'
      if (!failedAuth) {
        setError(error.message)
      }
    } else {
      setError(error)
    }

    if (failedAuth) {
      setError('Your session has expired.')
      ;(async () => {
        await LoginService.logOut()
        navigate('/login')
      })()
    }
  }

  return (
    <>
      {!isLoggedIn ? (
        <div />
      ) : (
        <>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <CssBaseline />
            <Helmet>
              <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width"
              />
              <link
                href="https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@300;400;600;700&display=swap"
                rel="stylesheet"
              />
              <style>
                {
                  'html { background-color: white; } body { font-family: "Fira Sans Condensed", sans-serif; } '
                }
              </style>
            </Helmet>
            <ThemeProvider theme={theme}>
              <div className={classes.root}>
                {profileContext.profile === undefined ||
                typeof profileContext.profile === 'undefined' ||
                profileContext.profile === null ? (
                  <Loader />
                ) : (
                  React.Children.map(children, child => {
                    if (React.isValidElement(child)) {
                      const props = { onError: handleError }
                      return React.cloneElement(child, props)
                    }

                    return child
                  })
                )}
              </div>
              <Snackbar open={error !== null} onClose={() => setError(null)}>
                <Alert severity="error">{error}</Alert>
              </Snackbar>
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </>
      )}
    </>
  )
}

FullScreenLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FullScreenLayout
