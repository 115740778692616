import { createStyles, makeStyles } from '@material-ui/core'

export const useCustomTableStyles = makeStyles(() =>
  createStyles({
    table: {
      '& .k-grid-header': {
        '& col:nth-of-type(1)': {
          width: '5%',
        },
        '& col:nth-of-type(2)': {
          width: '15%',
        },
        '& col:nth-last-of-type(8)': {
          width: '15%',
        },
        '& col:nth-last-of-type(7)': {
          width: '15%',
        },
        '& col:nth-last-of-type(6)': {
          width: '5%',
        },
        '& col:nth-last-of-type(5)': {
          width: '10%',
        },
        '& col:nth-last-of-type(4)': {
          width: '10%',
        },
        '& col:nth-last-of-type(3)': {
          width: '10%',
        },
        '& col:nth-last-of-type(2)': {
          width: '10%',
        },
        '& col:nth-last-of-type(1)': {
          width: '10%',
        },
      },
      '& .k-grid-table': {
        '& col:nth-of-type(1)': {
          width: '5%',
        },
        '& col:nth-of-type(2)': {
          width: '15%',
        },
        '& col:nth-last-of-type(8)': {
          width: '15%',
        },
        '& col:nth-last-of-type(7)': {
          width: '15%',
        },
        '& col:nth-last-of-type(6)': {
          width: '5%',
        },
        '& col:nth-last-of-type(5)': {
          width: '10%',
        },
        '& col:nth-last-of-type(4)': {
          width: '10%',
        },
        '& col:nth-last-of-type(3)': {
          width: '10%',
        },
        '& col:nth-last-of-type(2)': {
          width: '10%',
        },
        '& col:nth-last-of-type(1)': {
          width: '10%',
        },
      },
      '& input[type=checkbox]': {
        width: 20,
        height: 20,
        cursor: 'pointer',
        filter: 'hue-rotate(150deg)',
      },
    },
    mobile: {
      '& input[type=checkbox]': {
        width: 20,
        height: 20,
        cursor: 'pointer',
        filter: 'hue-rotate(150deg)',
      },
    },
  })
)
