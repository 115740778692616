import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { DashType } from '@progress/kendo-drawing'
import KendoReactCharts, {
  SelectEndEvent,
  ZoomEvent,
} from '@progress/kendo-react-charts'
import { SeriesType } from '@progress/kendo-react-charts/dist/npm/field-types/series-type'

type KendoCharts = typeof KendoReactCharts

export type TPoint = {
  x: Date
  y: number
  correctiveAction: TCorrectiveAction
}

export type TCorrectiveAction = {
  caDate: Date
  caNote: string
}

export type TLineDatum = {
  id: string
  unit: string
  points: TPoint[]
  color?: string
  dashType?: string
}

export type TProps = {
  width: number | string
  height: number | string
  lineData: TLineDatum[]
  title?: string
  from?: Date
  reverse?: boolean
  to?: Date
  tooltipAlwaysVisible?: boolean
  maxDateGroups?: number
  chartRef?: React.MutableRefObject<any>
  type?: SeriesType
  brush?: boolean
}

function LineChart(props: TProps) {
  const { lineData, width, height, title, chartRef, type, reverse, brush } =
    props
  const { from, to } = props

  const [fromDate, setFrom] = React.useState(from)
  const [toDate, setTo] = React.useState(to)

  useEffect(() => {
    if (lineData?.length > 0 && lineData[0].points?.length > 0) {
      const sortedCategories = lineData[0].points
        .map(point => point.x)
        .slice()
        .sort((a, b) => a.getTime() - b.getTime())
    }
  }, [from, lineData, reverse, to])

  const [data, setData] = useState<TLineDatum[]>()

  const [Kendo, setKendo] = useState<KendoCharts>()

  useEffect(() => {
    require('hammerjs')
    setKendo(require('@progress/kendo-react-charts'))
  }, [Kendo])

  const [min, setMin] = React.useState(fromDate)
  const [max, setMax] = React.useState(toDate)

  const resetRange = (rangeFrom, rangeTo) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setFrom(rangeFrom)
      setTo(rangeTo)
      setMin(rangeFrom)
      setMax(rangeTo)
    })
  }

  useEffect(() => {
    setData([...lineData])
  }, [lineData])

  const onSelectEnd = (args: SelectEndEvent) => {
    if (brush) {
      resetRange(args.from, args.to)
    }
  }

  const handleZoom = (event: ZoomEvent) => {
    if (brush) {
      const range = Object.entries(event.axisRanges)[0][1]

      event.preventDefault()

      if (range) {
        // Do not move top unless bottom cannot move/is at minimum:
        if ((range.min as Date).getTime() !== fromDate.getTime()) {
          let newMin = new Date(toDate.valueOf())
          newMin.setDate(newMin.getDate() - 12 * 30)

          if ((range.min as Date).valueOf() <= newMin.valueOf()) {
            newMin = range.min as Date
          }
          resetRange(event.delta < 0 ? newMin : range.min, toDate)
        } else resetRange(range.min, range.max)
      }
    }
  }

  const onDragEnd = (event: KendoReactCharts.DragEvent) => {
    if (brush) {
      const range = Object.entries(event.axisRanges)[0][1]

      if (range) {
        resetRange(range.min, range.max)
      }
    }
  }

  if (Kendo && data?.length > 0 && data[0]?.points?.length > 0) {
    const allCategories = data[0]?.points.map(p => p.x)

    const allValues = data.map(datum => datum.points)

    return (
      <>
        <Kendo.Chart
          onSelectEnd={onSelectEnd}
          onZoom={handleZoom}
          transitions={!brush}
          onDragEnd={onDragEnd}
          style={{
            height: height,
            width: width,
            margin: 0,
          }}
          ref={chartRef}
          pannable={{ lock: 'y' }}
          zoomable={{ selection: { lock: 'y' }, mousewheel: { lock: 'y' } }}
        >
          {title ? <Kendo.ChartTitle text={title} /> : null}
          <Kendo.ChartCategoryAxis>
            <Kendo.ChartCategoryAxisItem
              title={{ text: 'Date' }}
              categories={allCategories}
              name={data[0]?.id}
              background={'none'}
              baseUnitStep={'auto'}
              maxDateGroups={props.maxDateGroups || 20}
              baseUnit={props.brush ? 'months' : 'auto'}
              autoBaseUnitSteps={{
                months: [1],
                years: props.brush ? undefined : [1],
              }}
              min={min}
              max={max}
              pane={'main'}
              reverse={reverse}
              roundToBaseUnit={false}
              labels={{
                rotation: 'auto',
                padding: 10,
                dateFormats: {
                  seconds: 'dd MMM yyyy',
                  minutes: 'dd MMM yyyy',
                  hours: 'dd MMM yyyy',
                  milliseconds: 'dd MMM yyyy',
                  days: 'dd MMM yyyy',
                  weeks: 'MMM yyyy',
                  months: 'MMM yyyy',
                  years: 'yyyy',
                },
              }}
            />
            {brush && (
              <Kendo.ChartCategoryAxisItem
                categories={allCategories}
                reverse={reverse}
                name="navigatorAxis"
                baseUnitStep={'auto'}
                maxDateGroups={120}
                baseUnit="months"
                labels={{ visible: false }}
                // majorGridLines={allCategories.length / 10}
                // majorTicks={allCategories.length / 10}
                pane="navigator"
                select={{
                  from: fromDate,
                  to: toDate,
                }}
              />
            )}
          </Kendo.ChartCategoryAxis>
          {brush && (
            <>
              <Kendo.ChartPanes>
                <Kendo.ChartPane name={'main'} />
                <Kendo.ChartPane name={'navigator'} height={100} />
              </Kendo.ChartPanes>
              <Kendo.ChartValueAxis>
                <Kendo.ChartValueAxisItem />
                <Kendo.ChartValueAxisItem
                  minorGridLines={{ visible: false }}
                  majorGridLines={{ visible: false }}
                  labels={{ visible: false }}
                  name="valueNavigatorAxis"
                  pane="navigator"
                />
              </Kendo.ChartValueAxis>
            </>
          )}
          <Kendo.ChartTooltip />
          <Kendo.ChartLegend position="bottom" orientation="horizontal" />
          <Kendo.ChartSeries>
            {brush && (
              <Kendo.ChartSeriesItem
                aggregate="max"
                type="area"
                style="smooth"
                missingValues="interpolate"
                data={allValues[0]}
                color={'#505F74'}
                field="y"
                axis="valueNavigatorAxis"
                categoryAxis="navigatorAxis"
                labels={{ visible: false }}
              />
            )}
            {data.map((datum, index) => {
              return (
                <Kendo.ChartSeriesItem
                  extremes={{ type: 'square' }}
                  aggregate="max"
                  key={datum.id}
                  type={type || 'line'}
                  color={datum.color}
                  data={allValues[index]}
                  field="y"
                  xAxis={datum.id}
                  name={datum.id}
                  margin={1}
                  tooltip={{
                    color: 'purple',
                  }}
                  missingValues={'interpolate'}
                  dashType={
                    (datum.dashType as DashType) ?? ('solid' as DashType)
                  }
                  labels={{
                    format: '{0:n0}',
                    visible: props.tooltipAlwaysVisible,
                    margin: { top: 0, bottom: 0 },
                    background: 'rgba(0, 0, 0, 0)',
                    content: v => {
                      const prototype = Object.getPrototypeOf(v.dataItem)
                      const correctiveAction = prototype.correctiveAction
                      return correctiveAction
                        ? `${v?.dataItem?.y ?? ''}*`
                        : v?.dataItem?.y ?? ''
                    },
                  }}
                >
                  <Kendo.ChartSeriesItemTooltip
                    format={'{0}' + datum.unit ? ` ${datum.unit}` : ''}
                    visible={true}
                    render={v => {
                      const prototype = Object.getPrototypeOf(v.point.dataItem)
                      const correctiveAction = prototype.correctiveAction
                      return correctiveAction ? (
                        <div style={{ width: '100%', height: '100%' }}>
                          <p
                            style={{
                              width: '100%',
                              textAlign: 'center',
                              fontSize: '18px',
                              marginBottom: '10px',
                              visibility: v.point.dataItem.y
                                ? 'visible'
                                : 'hidden',
                            }}
                          >
                            {v.point.dataItem.y}
                          </p>
                          <p
                            style={{
                              width: '100%',
                              textAlign: 'center',
                              fontSize: '18px',
                              margin: 0,
                            }}
                          >
                            Corrective Action - {correctiveAction?.cADate}
                          </p>
                          <hr
                            style={{
                              width: '100%',
                              height: '1px',
                              backgroundColor: 'white',
                              margin: '3px',
                            }}
                          />
                          <p
                            style={{
                              width: '100%',
                              textAlign: 'center',
                              fontSize: '14px',
                              marginBottom: '5px',
                              visibility: v.point.dataItem.y
                                ? 'visible'
                                : 'hidden',
                            }}
                          >
                            {correctiveAction?.cANote ?? ''}
                          </p>
                        </div>
                      ) : (
                        <p
                          style={{
                            width: '100%',
                            textAlign: 'center',
                            fontSize: '16px',
                            margin: 0,
                            visibility: v.point.dataItem.y
                              ? 'visible'
                              : 'hidden',
                          }}
                        >
                          {v?.point?.dataItem?.y ?? ''}
                        </p>
                      )
                    }}
                  />
                </Kendo.ChartSeriesItem>
              )
            })}
          </Kendo.ChartSeries>
        </Kendo.Chart>
      </>
    )
  } else {
    return (
      <span
        style={{
          display: 'inline-block',
          textAlign: 'center',
        }}
      >
        Not enough data to plot.
      </span>
    )
  }
}

export default LineChart
