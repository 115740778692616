import {
  createTheme,
  makeStyles,
  ThemeProvider,
  withStyles,
} from '@material-ui/core/styles'
import coreTheme from 'components/theme'
import React, { useCallback, useContext } from 'react'
const drawerWidth = 280

const theme = createTheme(
  {
    overrides: {
      MuiFormLabel: {
        root: {
          color: '#222',
          zIndex: 99,
        },
      },
      MuiCheckbox: {
        root: {
          color: '#222',
        },
      },
    },
  },
  coreTheme
)

const styles = theme => ({
  card: {
    padding: '0px',
  },
  cardHeader: {
    backgroundColor: coreTheme.palette.primary.main,
    color: coreTheme.palette.primary.contrastText,
    padding: '10px',
  },
  cardHeaderLink: {
    color: coreTheme.palette.primary.contrastText,
    '&:hover': {
      color: coreTheme.palette.secondary.contrastText,
    },
  },
  cardContent: {
    padding: '0px',
  },
  orgFilter: {
    width: '300px',
    display: 'inline-block',
    marginLeft: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'block',
      marginLeft: '0px',
    },
  },
  DashboardConditionDate: {
    width: '300px',
  },
})

export const useThemeContext = () => {
  return useContext(ThemeContext)
}

const ThemeContext = React.createContext<Record<string, any>>({})

export const ThemeContextProvider: React.FC = props => {
  const useStyles = useCallback(
    makeStyles(theme => ({
      root: {
        display: 'flex',
        width: '100%',
        backgroundColor: coreTheme.palette.primary.main,
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: coreTheme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
      navItem: {
        fontSize: '10px',
        minWidth: '125px',
        color: '#fff',
      },
      tabContainer: {
        width: '100%',
      },
      tabContainerMobile: {
        width: '100%',
      },
      navItemMobile: {
        fontSize: '20px',
        minWidth: '40px',
        height: `${innerHeight / 20 + 26}px`,
        maxHeight: '60px',
        flex: 1,
      },
      navIcon: {
        fontSize: `18px`,
        flex: 1,
      },
      navIconMobile: {
        fontSize: `${innerHeight / 75 + 17}px`,
      },
      selectedNavText: {
        color: '#fff !important',
      },
      navIndicator: {
        // backgroundColor: '#fff'
      },
      drawer: {},
      menuButton: {
        marginRight: theme.spacing(2),
      },
      toolbar: theme.mixins.toolbar,
      drawerPaper: {
        width: drawerWidth,
        backgroundColor: coreTheme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRight: '0px',
      },
      drawerContent: {
        marginRight: '15px',
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
      },
      drawerCompanyName: {
        fontWeight: 600,
      },
      drawerUserID: {},
      content: {
        flexGrow: 1,
        [theme.breakpoints.up('sm')]: {
          width: `calc(100% - ${drawerWidth}px)`,
        },
        width: `calc(100% - ${drawerWidth}px)`,
      },
      childContent: {
        padding: theme.spacing(3),
        //[theme.breakpoints.down('md')]: {
        paddingBottom: '100px',
        //},
        backgroundColor: coreTheme.palette.background.default,
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        marginTop: '15px',
      },
    })),
    [innerHeight]
  )

  const classes = useStyles()

  const palette = {
    charts: {
      budget: 'rgba(0, 50, 255, 0.5)',
      withinBudget: '#1ddb82',
      overBudget: 'rgba(200,25,25,1)',
    },
    pickers: {
      pickerBlue: '#0075FF',
      pickerOrange: '#FF6600',
    },
  }

  return (
    <ThemeContext.Provider
      value={{ palette, ...classes, ...makeStyles(styles(theme))() }}
    >
      {props.children}
    </ThemeContext.Provider>
  )
}
