import React, { useMemo, useState } from 'react'
import {
  ErrorFormValue,
  FormValue,
  ToErrorFormValues,
} from 'utils/FormUtilities/FormValue'
import { delay } from 'utils/general-utilities'
import * as PageStyles from '../../../../page-styles.module.css'
import { Button } from '@material-ui/core'
import Modal from '@mui/material/Modal'

import { useIsBoundByWidths } from 'hooks/useIsBoundByWidths'
import {
  cacheTimeoutMS,
  mobileWidthDefinitonMap,
} from 'pages/sample/registration/Pages/constants'
import { TMachineTestingInformation } from '../types'

import { useIsLoggedIn } from 'hooks/useIsLoggedIn'
import APIService from 'services/api-service'
import { useError, useLoading, usePromise } from 'hooks/usePromise'
import { LoadingCardModule } from 'pages/sample/registration/CardModules/LoadingCardModule'
import { useErrorPage } from '../../useErrorPage'
import {
  PostMachineTestScheduleDTO,
  TCustomerTestGroupPricing,
  TCustomerTestGroupPricingDetails,
  TListType,
} from 'types/api'
import TestSchedule from './TestSchedule/TestSchedule'
import { TestPackageModal } from './TestPackageModal/TestPackageModal'
import {
  getDatalessQueryResultFromCacheOrAPI,
  getQueryResultFromCacheOrAPI,
} from 'services/queries/Query'
import { GetAllCustomerTestGroupPricingQuery } from 'services/queries/GetAllCustomerTestGroupPricingQuery'
import { GetAllCustomerTestGroupPricingEncryptedQuery } from 'services/queries/GetAllCustomerTestGroupPricingEncryptedQuery'

const NO_TESTS_SCHEDULED_TEXT = 'No tests scheduled!'

type TProps = {
  back: (machineInformation: TMachineTestingInformation) => void
  next: (machineInformation: TMachineTestingInformation) => void
  encryptedBottleIdentifier: string | null
  customerID: number | null
  cancel: () => void
  cache: Cache
  addToCache: (url: string, data: any, timeoutMS: number) => Promise<void>
} & Partial<TMachineTestingInformation>

type TFixedState = {
  formUpdated: boolean
  testPackageModalOpen: boolean
} & Partial<PostMachineTestScheduleDTO>

type TFreeFormState = ToErrorFormValues<TMachineTestingInformation>

type TState = TFreeFormState & TFixedState

const debounceTimeMS = 0

export const MachineTestingInformation = (props: TProps) => {
  const defaultState: Readonly<TState> = useMemo(
    () => ({
      testSchedules: new ErrorFormValue<PostMachineTestScheduleDTO[]>(
        props.testSchedules ?? [],
        a1 => ErrorFormValue.validateOptional(a1),
        !!props.testSchedules && props.testSchedules.length > 0
      ),
      formUpdated: false,
      testPackageModalOpen: false,
    }),
    []
  )

  const [state, setState] = useState<TState>({ ...defaultState })
  const isMobile = useIsBoundByWidths(mobileWidthDefinitonMap)['mobile']

  function updateFixedState<
    TKey extends keyof TFixedState,
    TValue extends TFixedState[TKey]
  >(key: TKey, value: TValue) {
    setState(prev => ({ ...prev, [key]: value }))
  }

  function updateFreeFormState<
    TKey extends keyof TFreeFormState,
    TValue extends TFreeFormState[TKey]['Value']
  >(key: TKey, value: TValue, automaticUpdate: boolean = false) {
    const stateValue = state[key]
    stateValue.Value = value

    if (automaticUpdate) {
      stateValue.Modified = false
    }

    setState(prev => ({ ...prev, [key]: stateValue }))
  }

  function updateFreeFormStates<
    TKey extends keyof TFreeFormState,
    TValue extends TFreeFormState[TKey]['Value']
  >(pairs: { key: TKey; value: TValue }[], automaticUpdate: boolean = false) {
    setState(prev => {
      const newState = { ...prev }
      for (const pair of pairs) {
        const key = pair.key

        const stateValue = state[key]
        stateValue.Value = pair.value

        if (automaticUpdate) {
          stateValue.Modified = false
        }

        newState[key] = stateValue
      }

      return newState
    })
  }

  const customerPricingRequest = useCustomerPricing(props)
  const organizedCustomerPricing = useMemo(
    () =>
      organizeCustomerPricingData(
        customerPricingRequest.data,
        props.customerID
      ),
    [customerPricingRequest.loading, customerPricingRequest.data]
  )

  const queries = [customerPricingRequest]
  const loading = useLoading(queries)
  const error = useError(queries)
  const errorPage = useErrorPage(error)

  async function issueWithData(
    func: (data: TMachineTestingInformation) => void,
    ignoreErrors = false
  ) {
    await delay(debounceTimeMS)
    FormValue.modifyAllObjects(state)
    if (!ignoreErrors && !FormValue.allObjectParametersAreValid(state)) {
      updateFixedState('formUpdated', !state.formUpdated)
      return
    }

    if (func) {
      const vals =
        FormValue.getFormResultFromObject<TMachineTestingInformation>(state)
      func(vals)
    }
  }

  const buttons = (
    <div className={PageStyles.ButtonContainer}>
      <Button
        data-cancel
        variant="contained"
        color="primary"
        onClick={() => issueWithData(props.back, true)}
        className={`${PageStyles.Button} ${PageStyles.Left}`}
      >
        Fluid Info.
      </Button>

      <Button
        data-accept
        disabled={!FormValue.allObjectParametersAreValid(state)}
        variant="contained"
        color="secondary"
        onClick={() => issueWithData(props.next)}
        className={`${PageStyles.Button} ${PageStyles.Right}`}
      >
        Submit
      </Button>
    </div>
  )

  let page = <LoadingCardModule showHeader={false} />
  if (!!error) {
    const cancelButton = (
      <div className={PageStyles.ButtonContainer}>
        <Button
          data-cancel
          variant="contained"
          color="primary"
          onClick={props.cancel}
          fullWidth
          className={`${PageStyles.Button} `}
        >
          Cancel
        </Button>
      </div>
    )
    page = (
      <>
        <div>{errorPage}</div>
        {cancelButton}
      </>
    )
  }

  function deleteTestGroup(testGroupID: number) {
    updateFreeFormState(
      'testSchedules',
      state.testSchedules?.Value?.filter(
        ts => ts?.testGroupID !== testGroupID
      ) ?? []
    )
  }

  if (!error && !loading && queries.every(q => !!q.data)) {
    const noTests =
      !state.testSchedules.Value || state.testSchedules.Value.length === 0

    const noTestDisplay = noTests && (
      <p className={PageStyles.Comments}>{NO_TESTS_SCHEDULED_TEXT}</p>
    )

    page = (
      <>
        <div
          style={{
            marginTop: '1em',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <Button
            data-cancel
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              updateFixedState('testPackageModalOpen', true)
            }}
            className={`${PageStyles.Button} ${PageStyles.Left} ${PageStyles.BotGap}`}
          >
            Schedule New Test Package
          </Button>
          <h6
            className={PageStyles.DetailSectionHeader}
            style={{ marginTop: '1em' }}
          >
            Scheduled Tests
          </h6>
          <section
            className={`${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer} ${PageStyles.Wrapper}`}
            style={{
              overflowX: 'hidden',
              overflowY: 'auto',
              alignItems: 'flex-start',
            }}
          >
            {noTestDisplay}
            <Modal
              open={state.testPackageModalOpen}
              onClose={() => {
                setState(prev => ({
                  ...prev,
                  testGroupID: null,
                  testPackageModalOpen: false,
                  frequency: null,
                  startMonth: null,
                  pointID: null,
                }))
              }}
            >
              <TestPackageModal
                {...props}
                {...state}
                onDelete={testGroupID => deleteTestGroup(testGroupID)}
                consumedTestGroupIDs={
                  state.testSchedules?.Value?.map(s => s.testGroupID) ?? []
                }
                organizedCustomerPricing={organizedCustomerPricing}
                onClose={() => updateFixedState('testPackageModalOpen', false)}
                next={data => {
                  setState(prev => {
                    state.testSchedules.Value = [
                      ...prev.testSchedules.Value,
                      data,
                    ]
                    return {
                      ...prev,
                      testSchedules: state.testSchedules,
                      testPackageModalOpen: false,
                    }
                  })
                }}
              />
            </Modal>
            {state.testSchedules?.Value?.map(schedule => (
              <TestSchedule
                className={PageStyles.Wrappable}
                onEdit={schedule =>
                  setState(prev => ({
                    ...prev,
                    ...schedule,
                    testPackageModalOpen: true,
                  }))
                }
                onDelete={schedule => deleteTestGroup(schedule?.testGroupID)}
                key={schedule.testGroupID}
                schedule={schedule}
                organizedCustomerPricing={organizedCustomerPricing}
              />
            ))}
          </section>
        </div>
        {buttons}
      </>
    )
  }

  return page
}

function useCustomerPricing(props: TProps) {
  const isLoggedIn = useIsLoggedIn()
  const useEncrypted = !!props.encryptedBottleIdentifier
  const loggedInAndInfoAvailable =
    props?.customerID != null && isLoggedIn.loggedIn
  const issuePromise =
    loggedInAndInfoAvailable || !!props.encryptedBottleIdentifier

  async function getPromise() {
    if (useEncrypted) {
      const queryData = {
        encryptedIdentifier: props.encryptedBottleIdentifier,
      }

      return await getQueryResultFromCacheOrAPI(
        GetAllCustomerTestGroupPricingEncryptedQuery,
        queryData,
        props.cache,
        props.addToCache,
        cacheTimeoutMS
      )
    }

    return await getDatalessQueryResultFromCacheOrAPI(
      GetAllCustomerTestGroupPricingQuery,
      props.cache,
      props.addToCache,
      cacheTimeoutMS
    )
  }
  return usePromise({
    shouldStartPromise: data =>
      issuePromise && !data.data && !data.loading && !data.error,
    dependencies: () => [issuePromise],
    promise: getPromise,
  })
}

function organizeCustomerPricingData(
  pricingInfo: TCustomerTestGroupPricing[],
  customerID: number | null
) {
  if (!pricingInfo) return []

  if (customerID != null)
    pricingInfo = pricingInfo.filter(pi => customerID == pi.customerID)

  const detailMap = new Map<number, TCustomerTestGroupPricingDetails>()
  pricingInfo
    .flatMap(p => p.pricing)
    .filter(p => p && p.testGroup && p.pricePerUnitUSD > 0) //Is this fortrue?
    .forEach(p => {
      let match: TCustomerTestGroupPricingDetails | null
      let newValue = p
      if (!!(match = detailMap.get(p.testGroup.testGroupID))) {
        newValue = p.pricePerUnitUSD < match.pricePerUnitUSD ? p : match
      }

      detailMap.set(p.testGroup.testGroupID, newValue)
    })

  const entries = Array.from(detailMap.entries())
  entries.sort((a, b) => {
    const aTG = a[1].testGroup?.testGroup
    const bTG = b[1].testGroup?.testGroup
    return aTG > bTG ? 1 : aTG < bTG ? -1 : 0
  })

  return entries
}
