/* eslint-disable prefer-const */
import { useEffect, useState } from 'react'

import { TFilterState } from './SmartFilterTypes'
import { statesAreEqual } from './SmartFilterUtilities'
import { TAPILubricant, TAPIMachine, TAPIPlant } from 'types/api'
import { TAPICustomer } from 'types/api'
import { binarySearch, isNotSameAsLast } from 'utils/ArrayMethods'

function isNullOrEmpty(input: any | any[]) {
  if (Array.isArray(input)) {
    return !(input && input.length !== 0)
  } else {
    return !input
  }
}

const getMachinePropertiesFromMachines = (params: {
  machines: TAPIMachine[]
  allOptions: TFilterState
}) => {
  let lubricantManufacturers = []
  let lubricantTypes = [] as TAPILubricant[]
  let machineNames = []
  let machineManufacturers = []
  let machineNumbers = [] as number[]
  let machineModels = [] as string[]
  let machineTypes = []
  let customerEquipmentIDs = [] as string[]

  params.machines?.forEach(machine => {
    if (!machine) return

    lubricantTypes.push(machine.lubricant)

    machineNames.push(machine.machineName)

    if (
      !machineManufacturers
        ?.map(mfr => (mfr ? mfr?.listValue : ''))
        .includes(machine.machMFG)
    ) {
      const mfr = params.allOptions.machineManufacturers.find(
        mfr => mfr?.listValue === machine.machMFG
      )
      if (mfr) machineManufacturers.push(mfr)
    }

    machineNumbers.push(parseInt(machine.machNo))

    machineModels.push(machine.modelName)

    if (
      !machineTypes
        ?.map(t => (t ? t?.listValue : ''))
        .includes(machine.machType)
    ) {
      const type = params.allOptions.machinePicklistTypes.find(
        t => t?.listValue === machine.machType
      )
      if (type) machineTypes.push(type)
    }

    if (machine.custEquID !== null && machine.custEquID !== ''){
      customerEquipmentIDs.push(machine.custEquID)
    }

    if (
      !lubricantManufacturers
        ?.map(manu => (manu ? manu?.listValue : ''))
        .includes(machine.lubricant?.lubeMFG)
    ) {
      const mfr = params.allOptions.lubricantManufacturers.find(
        mfr => mfr?.listValue === machine?.lubricant?.lubeMFG
      )
      if (mfr) lubricantManufacturers.push(mfr)
    }
  })

  //SECTION Perform duplicate removal
  //NOTE Performing duplicate removal is far more efficient than verifying an item does not exist within a list before pushing
  //NOTE: This approach is about twice as fast as a set-based approach.
  lubricantTypes = lubricantTypes
    .sort((a, b) =>
      a.lubeTypeID > b.lubeTypeID ? 1 : a.lubeTypeID < b.lubeTypeID ? -1 : 0
    )
    .filter((x, index, arr) => {
      return (
        index === 0 || isNotSameAsLast(x.lubeTypeID, arr[index - 1].lubeTypeID)
      )
    })

  machineNumbers = machineNumbers.sort().filter((x, index, arr) => {
    return index === 0 || isNotSameAsLast(x, arr[index - 1])
  })

  machineNames = machineNames.sort().filter((x, index, arr) => {
    return index === 0 || isNotSameAsLast(x, arr[index - 1])
  })

  machineModels = machineModels.sort().filter((x, index, arr) => {
    return index === 0 || isNotSameAsLast(x, arr[index - 1])
  })

  customerEquipmentIDs = customerEquipmentIDs.sort().filter((x, index, arr) => {
    return index === 0 || isNotSameAsLast(x, arr[index - 1])
  })

  return {
    lubricantManufacturers,
    lubricantTypes,
    machineNames,
    machineManufacturers,
    machineNumbers,
    machineModels,
    machineTypes,
    customerEquipmentIDs,
  }
}

const getApplicableCustomersFromPlants = (
  customers: TAPICustomer[],
  plants: TAPIPlant[]
) => {
  const plantIDs = plants?.map(r => r.plantID)

  return customers.filter(customer =>
    customer.plants
      ?.map(r => (r ? r.plantID : -1))
      .find(id => plantIDs.includes(id))
  )
}
/**An efficient method for retrieving applicable plants from a list of curated machines */
const getApplicablePlantsFromMachines = (
  machines: TAPIMachine[],
  plants: TAPIPlant[]
) => {
  const pointIDs = machines?.map(m => m.pointID).sort()
  const comparator = (a, b) => a - b

  const selectedPlants = []
  for (let index = 0; index < plants.length; index++) {
    const plant = plants[index]
    const plantPointIDs = plant.machines.map(machine => machine.pointID)

    //Iterate through each pointID in a plant
    for (
      let plantPointIDIndex = 0;
      plantPointIDIndex < plantPointIDs.length;
      plantPointIDIndex++
    ) {
      const plantPointID = plantPointIDs[plantPointIDIndex]

      const plantIndex = binarySearch(pointIDs, plantPointID, comparator)
      if (plantIndex > -1) {
        selectedPlants.push(plant)
        break
      }
    }

    //Continue to next plant
  }

  return selectedPlants

  //The code below is about 100 ms slower for massive customer lists. For loops are a bit faster, though far more painful to read.
  // return plants.filter(plant => {
  //   return plant.machines.some(machine => pointIDs.includes(machine.pointID))
  // })
}

function filterOnDivisions(
  selectedState: TFilterState,
  newOptions: TFilterState,
  allOptions: TFilterState
) {
  if (!isNullOrEmpty(selectedState.divisions)) {
    // UP - None
    // DOWN - customer
    if (newOptions.customers?.length === 0) return newOptions
    const customers = newOptions.customers?.filter(customer =>
      selectedState.divisions
        .filter(x => x !== null && x !== undefined)
        ?.map(d => d.customers)
        .some(listOfCustomers =>
          listOfCustomers?.map(c => c.custID).includes(customer.custID)
        )
    )

    newOptions.customers = customers ?? []

    const plants = newOptions.plants?.filter(plant =>
      customers
        ?.map(c => c.plants)
        .some(listOfPlants =>
          listOfPlants?.map(p => p.plantID).includes(plant.plantID)
        )
    )

    newOptions.plants = plants ?? []

    const routes = newOptions.routes?.filter(route =>
      customers
        ?.map(c => c.routes)
        .some(listOfRoutes =>
          listOfRoutes?.map(r => r.routeID).includes(route?.routeID)
        )
    )
    newOptions.routes = routes ?? []

    const machines = plants?.flatMap(plant => plant?.machines) ?? []
    newOptions.machines = machines

    const machineProperties = getMachinePropertiesFromMachines({
      machines,
      allOptions,
    })
    newOptions.lubricantManufacturers = machineProperties.lubricantManufacturers
    newOptions.lubricantTypes = machineProperties.lubricantTypes
    newOptions.machineNames = machineProperties.machineNames
    newOptions.machineManufacturers = machineProperties.machineManufacturers
    newOptions.machineNumbers = machineProperties.machineNumbers
    newOptions.machineModels = machineProperties.machineModels
    newOptions.machinePicklistTypes = machineProperties.machineTypes
    newOptions.customerEquipmentIDs = machineProperties.customerEquipmentIDs
  }
  return newOptions
}

function filterOnCustomers(
  selectedState: TFilterState,
  newOptions: TFilterState,
  allOptions: TFilterState
) {
  if (!isNullOrEmpty(selectedState.customers)) {
    // UP - Division
    const divisions = newOptions?.divisions?.filter(division =>
      division?.customers
        ?.map(divisionCust => divisionCust.custID)
        ?.some(
          id =>
            selectedState?.customers?.map(c => c.custID)?.includes(id) ?? false
        )
    )
    newOptions.divisions = divisions

    const plants = newOptions?.plants?.filter(plant =>
      selectedState?.customers
        ?.map(c => c.plants)
        ?.some(
          listOfPlants =>
            listOfPlants?.map(p => p.plantID)?.includes(plant.plantID) ?? false
        )
    )
    newOptions.plants = plants

    const routes = newOptions?.routes?.filter(route =>
      selectedState?.customers
        ?.map(c => c.routes)
        ?.some(
          listOfRoutes =>
            listOfRoutes?.map(r => r.routeID)?.includes(route?.routeID) ?? false
        )
    )
    newOptions.routes = routes

    // DOWN - Plant and Route

    const machines = plants?.flatMap(plant => plant?.machines) ?? []
    newOptions.machines = machines

    const machineProperties = getMachinePropertiesFromMachines({
      machines,
      allOptions,
    })
    newOptions.lubricantManufacturers = machineProperties.lubricantManufacturers
    newOptions.lubricantTypes = machineProperties.lubricantTypes
    newOptions.machineNames = machineProperties.machineNames
    newOptions.machineManufacturers = machineProperties.machineManufacturers
    newOptions.machineNumbers = machineProperties.machineNumbers
    newOptions.machineModels = machineProperties.machineModels
    newOptions.machinePicklistTypes = machineProperties.machineTypes
    newOptions.customerEquipmentIDs = machineProperties.customerEquipmentIDs
  }
  return newOptions
}

function filterOnPlants(
  selectedState: TFilterState,
  newOptions: TFilterState,
  allOptions: TFilterState
) {
  if (!isNullOrEmpty(selectedState.plants)) {
    // plant attaches to machine. Therefore machines, machineName, EquipmentID, machineType, lubricant type?, lubricant mfr?, machine condition
    if (newOptions.customers?.length === 0) return newOptions
    // UP customer - division
    const customers = getApplicableCustomersFromPlants(
      newOptions.customers,
      selectedState.plants
    )
    newOptions.customers = customers

    const divisions = newOptions.divisions.filter(division => {
      return division.customers
        ?.map(divisionCust => divisionCust.custID)
        .find(divisionCustID => {
          return customers?.map(cust => cust.custID).includes(divisionCustID)
        })
    })
    newOptions.divisions = divisions

    // DOWN machine stuff

    const machines =
      selectedState.plants?.flatMap(plant => plant?.machines) ?? []
    newOptions.machines = machines

    const machineProperties = getMachinePropertiesFromMachines({
      machines,
      allOptions,
    })
    newOptions.lubricantManufacturers = machineProperties.lubricantManufacturers
    newOptions.lubricantTypes = machineProperties.lubricantTypes
    newOptions.machineNames = machineProperties.machineNames
    newOptions.machineManufacturers = machineProperties.machineManufacturers
    newOptions.machineNumbers = machineProperties.machineNumbers
    newOptions.machineModels = machineProperties.machineModels
    newOptions.machinePicklistTypes = machineProperties.machineTypes
    newOptions.customerEquipmentIDs = machineProperties.customerEquipmentIDs
  }
  return newOptions
}

function filterOnMachines(
  selectedState: TFilterState,
  newOptions: TFilterState,
  allOptions: TFilterState
) {
  if (!isNullOrEmpty(selectedState.machines)) {
    // UP Machine - Plant - Customer - Division
    // DOWN None
    if (newOptions.plants.length === 0) return newOptions

    newOptions.plants = getApplicablePlantsFromMachines(
      selectedState.machines,
      newOptions.plants
    )
    const machineProperties = getMachinePropertiesFromMachines({
      machines: selectedState.machines,
      allOptions,
    })
    newOptions.lubricantManufacturers = machineProperties.lubricantManufacturers
    newOptions.lubricantTypes = machineProperties.lubricantTypes
    newOptions.machineNames = machineProperties.machineNames
    newOptions.machineManufacturers = machineProperties.machineManufacturers
    newOptions.machineNumbers = machineProperties.machineNumbers
    newOptions.machineModels = machineProperties.machineModels
    newOptions.machinePicklistTypes = machineProperties.machineTypes
    newOptions.customerEquipmentIDs = machineProperties.customerEquipmentIDs

    const customers = getApplicableCustomersFromPlants(
      newOptions.customers,
      newOptions.plants
    )
    newOptions.customers = customers

    const divisions = newOptions.divisions.filter(division => {
      return division.customers
        ?.map(divisionCust => (divisionCust ? divisionCust.custID : -1))
        .find(divisionCustID => {
          return customers?.map(cust => cust.custID).includes(divisionCustID)
        })
    })
    newOptions.divisions = divisions
  }
  return newOptions
}

function filterOnCustomerEquipmentIDs(
  selectedState: TFilterState,
  newOptions: TFilterState,
  allOptions: TFilterState
) {
  if (!isNullOrEmpty(selectedState.customerEquipmentIDs)) {
    // UP Machine - Plant - Customer - Division
    // DOWN None
    if (newOptions.machines.length === 0) return newOptions
    const machines = []
    for (let index = 0; index < newOptions.machines.length; index++) {
      const machine = newOptions.machines[index]
      if (
        selectedState.customerEquipmentIDs.some(id => id === machine.custEquID)
      )
        machines.push(machine)
    }

    // const machines = newOptions.machines.filter(machine => {
    //   machine.custEquID
    //   return selectedState.customerEquipmentIDs.includes(machine.custEquID)
    // })
    newOptions.machines = machines

    //Find ALL plants where any of that plants machines are in the machine list.
    newOptions.plants = getApplicablePlantsFromMachines(
      machines,
      newOptions.plants
    )

    const machineProperties = getMachinePropertiesFromMachines({
      machines,
      allOptions,
    })
    newOptions.lubricantManufacturers = machineProperties.lubricantManufacturers
    newOptions.lubricantTypes = machineProperties.lubricantTypes
    newOptions.machineNames = machineProperties.machineNames
    newOptions.machineManufacturers = machineProperties.machineManufacturers
    newOptions.machineNumbers = machineProperties.machineNumbers
    newOptions.machineModels = machineProperties.machineModels
    newOptions.machinePicklistTypes = machineProperties.machineTypes

    const customers = getApplicableCustomersFromPlants(
      newOptions.customers,
      newOptions.plants
    )
    newOptions.customers = customers

    const divisions = newOptions.divisions.filter(division => {
      return division.customers
        ?.map(divisionCust => (divisionCust ? divisionCust.custID : -1))
        .find(divisionCustID => {
          return customers?.map(cust => cust.custID).includes(divisionCustID)
        })
    })
    newOptions.divisions = divisions
  }
  return newOptions
}

function filterOnMachineNames(
  selectedState: TFilterState,
  newOptions: TFilterState,
  allOptions: TFilterState
) {
  if (!isNullOrEmpty(selectedState.machineNames)) {
    // UP Machine - Plant - Customer - Division
    // DOWN None
    if (newOptions.machines.length === 0) return newOptions
    const machines = newOptions.machines.filter(machine => {
      return selectedState.machineNames.includes(machine.machineName)
    })
    newOptions.machines = machines

    newOptions.plants = getApplicablePlantsFromMachines(
      machines,
      newOptions.plants
    )

    const machineProperties = getMachinePropertiesFromMachines({
      machines,
      allOptions,
    })
    newOptions.lubricantManufacturers = machineProperties.lubricantManufacturers
    newOptions.lubricantTypes = machineProperties.lubricantTypes
    newOptions.machineManufacturers = machineProperties.machineManufacturers
    newOptions.machineNumbers = machineProperties.machineNumbers
    newOptions.machineModels = machineProperties.machineModels
    newOptions.machinePicklistTypes = machineProperties.machineTypes
    newOptions.customerEquipmentIDs = machineProperties.customerEquipmentIDs

    const customers = getApplicableCustomersFromPlants(
      newOptions.customers,
      newOptions.plants
    )
    newOptions.customers = customers

    const divisions = newOptions.divisions.filter(division => {
      return division.customers
        ?.map(divisionCust => (divisionCust ? divisionCust.custID : -1))
        .find(divisionCustID => {
          return customers?.map(cust => cust.custID).includes(divisionCustID)
        })
    })
    newOptions.divisions = divisions
  }
  return newOptions
}

function filterOnMachineNumbers(
  selectedState: TFilterState,
  newOptions: TFilterState,
  allOptions: TFilterState
) {
  if (!isNullOrEmpty(selectedState.machineNumbers)) {
    // UP Machine - Plant - Customer - Division
    // DOWN None
    if (newOptions.machines.length === 0) return newOptions
    const machines = newOptions.machines.filter(machine => {
      return selectedState.machineNumbers.includes(parseInt(machine.machNo))
    })
    newOptions.machines = machines

    newOptions.plants = getApplicablePlantsFromMachines(
      machines,
      newOptions.plants
    )

    const machineProperties = getMachinePropertiesFromMachines({
      machines,
      allOptions,
    })
    newOptions.lubricantManufacturers = machineProperties.lubricantManufacturers
    newOptions.lubricantTypes = machineProperties.lubricantTypes
    newOptions.machineNames = machineProperties.machineNames
    newOptions.machineManufacturers = machineProperties.machineManufacturers
    newOptions.machineModels = machineProperties.machineModels
    newOptions.machinePicklistTypes = machineProperties.machineTypes
    newOptions.customerEquipmentIDs = machineProperties.customerEquipmentIDs

    const customers = getApplicableCustomersFromPlants(
      newOptions.customers,
      newOptions.plants
    )
    newOptions.customers = customers

    const divisions = newOptions.divisions.filter(division => {
      return division.customers
        ?.map(divisionCust => (divisionCust ? divisionCust.custID : -1))
        .find(divisionCustID => {
          return customers?.map(cust => cust.custID).includes(divisionCustID)
        })
    })
    newOptions.divisions = divisions
  }
  return newOptions
}

function filterOnMachineTypes(
  selectedState: TFilterState,
  newOptions: TFilterState,
  allOptions: TFilterState
): TFilterState {
  if (!isNullOrEmpty(selectedState.machinePicklistTypes)) {
    // UP Machine - Plant - Customer - Division
    // DOWN None
    if (newOptions.machines.length === 0) return newOptions
    const machTypes = selectedState.machinePicklistTypes?.map(
      mt => mt?.listValue
    )
    const machines = newOptions.machines.filter(machine =>
      machTypes.includes(machine.machType)
    )
    newOptions.machines = machines

    newOptions.plants = getApplicablePlantsFromMachines(
      machines,
      newOptions.plants
    )

    const machineProperties = getMachinePropertiesFromMachines({
      machines,
      allOptions,
    })
    newOptions.lubricantManufacturers = machineProperties.lubricantManufacturers
    newOptions.lubricantTypes = machineProperties.lubricantTypes
    newOptions.machineNames = machineProperties.machineNames
    newOptions.machineManufacturers = machineProperties.machineManufacturers
    newOptions.machineNumbers = machineProperties.machineNumbers
    newOptions.machineModels = machineProperties.machineModels
    newOptions.customerEquipmentIDs = machineProperties.customerEquipmentIDs

    const customers = getApplicableCustomersFromPlants(
      newOptions.customers,
      newOptions.plants
    )
    newOptions.customers = customers

    const divisions = newOptions.divisions.filter(division => {
      return division.customers
        ?.map(divisionCust => (divisionCust ? divisionCust.custID : -1))
        .find(divisionCustID => {
          return customers?.map(cust => cust.custID).includes(divisionCustID)
        })
    })
    newOptions.divisions = divisions
  }
  return newOptions
}

function filterOnMachineModels(
  selectedState: TFilterState,
  newOptions: TFilterState,
  allOptions: TFilterState
) {
  if (!isNullOrEmpty(selectedState.machineModels)) {
    // UP Machine - Plant - Customer - Division
    // DOWN None
    if (newOptions.machines.length === 0) return newOptions
    const machines = newOptions.machines.filter(machine => {
      return selectedState.machineModels.includes(machine.modelName)
    })
    newOptions.machines = machines

    newOptions.plants = getApplicablePlantsFromMachines(
      machines,
      newOptions.plants
    )

    const machineProperties = getMachinePropertiesFromMachines({
      machines,
      allOptions,
    })
    newOptions.lubricantManufacturers = machineProperties.lubricantManufacturers
    newOptions.lubricantTypes = machineProperties.lubricantTypes
    newOptions.machineNames = machineProperties.machineNames
    newOptions.machineManufacturers = machineProperties.machineManufacturers
    newOptions.machineNumbers = machineProperties.machineNumbers
    newOptions.machinePicklistTypes = machineProperties.machineTypes
    newOptions.customerEquipmentIDs = machineProperties.customerEquipmentIDs

    const customers = getApplicableCustomersFromPlants(
      newOptions.customers,
      newOptions.plants
    )
    newOptions.customers = customers

    const divisions = newOptions.divisions.filter(division => {
      return division.customers
        ?.map(divisionCust => (divisionCust ? divisionCust.custID : -1))
        .find(divisionCustID => {
          return customers?.map(cust => cust.custID).includes(divisionCustID)
        })
    })
    newOptions.divisions = divisions
  }
  return newOptions
}

function filterOnMachineManufacturers(
  selectedState: TFilterState,
  newOptions: TFilterState,
  allOptions: TFilterState
) {
  if (!isNullOrEmpty(selectedState.machineManufacturers)) {
    // UP Machine - Plant - Customer - Division
    // DOWN None
    if (newOptions.machines.length === 0) return newOptions
    const machines = newOptions.machines.filter(machine => {
      return selectedState.machineManufacturers
        ?.map(mf => mf?.listValue)
        .includes(machine.machMFG)
    })
    newOptions.machines = machines

    newOptions.plants = getApplicablePlantsFromMachines(
      machines,
      newOptions.plants
    )

    const machineProperties = getMachinePropertiesFromMachines({
      machines,
      allOptions,
    })
    newOptions.lubricantManufacturers = machineProperties.lubricantManufacturers
    newOptions.lubricantTypes = machineProperties.lubricantTypes
    newOptions.machineNames = machineProperties.machineNames
    newOptions.machineNumbers = machineProperties.machineNumbers
    newOptions.machineModels = machineProperties.machineModels
    newOptions.machinePicklistTypes = machineProperties.machineTypes
    newOptions.customerEquipmentIDs = machineProperties.customerEquipmentIDs

    const customers = getApplicableCustomersFromPlants(
      newOptions.customers,
      newOptions.plants
    )
    newOptions.customers = customers

    const divisions = newOptions.divisions.filter(division => {
      return division.customers
        ?.map(divisionCust => (divisionCust ? divisionCust.custID : -1))
        .find(divisionCustID => {
          return customers?.map(cust => cust.custID).includes(divisionCustID)
        })
    })
    newOptions.divisions = divisions
  }
  return newOptions
}

function filterOnLubricantManufacturer(
  selectedState: TFilterState,
  newOptions: TFilterState,
  allOptions: TFilterState,
  relyUponMachines: boolean
) {
  if (!isNullOrEmpty(selectedState.lubricantManufacturers)) {
    if (newOptions.lubricantManufacturers.length === 0) return newOptions
    const lubricants = newOptions.lubricantTypes.filter(t =>
      selectedState.lubricantManufacturers
        ?.map(mfg => mfg?.listValue)
        .includes(t?.lubeMFG)
    )
    newOptions.lubricantTypes =
      lubricants?.length === 0 &&
      !relyUponMachines &&
      (selectedState.lubricantManufacturers.length === 0 ||
        (selectedState.lubricantManufacturers.length === 1 &&
          selectedState.lubricantManufacturers[0] == null))
        ? allOptions.lubricantTypes
        : lubricants

    if (relyUponMachines) {
      const machines = newOptions.machines.filter(machine => {
        return lubricants
          ?.map(x => x?.lubeTypeID)
          .includes(machine.lubricant?.lubeTypeID)
      })
      newOptions.machines = machines

      newOptions.plants = getApplicablePlantsFromMachines(
        machines,
        newOptions.plants
      )

      const machineProperties = getMachinePropertiesFromMachines({
        machines,
        allOptions,
      })
      newOptions.lubricantTypes = machineProperties.lubricantTypes
      newOptions.machineNames = machineProperties.machineNames
      newOptions.machineManufacturers = machineProperties.machineManufacturers
      newOptions.machineNumbers = machineProperties.machineNumbers
      newOptions.machineModels = machineProperties.machineModels
      newOptions.machinePicklistTypes = machineProperties.machineTypes
      newOptions.customerEquipmentIDs = machineProperties.customerEquipmentIDs

      const customers = getApplicableCustomersFromPlants(
        newOptions.customers,
        newOptions.plants
      )
      newOptions.customers = customers

      const divisions = newOptions.divisions.filter(division => {
        return division.customers
          ?.map(divisionCust => (divisionCust ? divisionCust.custID : -1))
          .find(divisionCustID => {
            return customers?.map(cust => cust.custID).includes(divisionCustID)
          })
      })
      newOptions.divisions = divisions
    }
    return newOptions
  }
}

function filterOnLubricantTypes(
  selectedState: TFilterState,
  newOptions: TFilterState,
  allOptions: TFilterState,
  relyUponMachines: boolean
) {
  if (!isNullOrEmpty(selectedState.lubricantTypes)) {
    // UP Machine - Plant - Customer - Division
    // DOWN None

    const machines = newOptions.machines.filter(machine => {
      return selectedState.lubricantTypes
        ?.map(x => x?.lubeTypeID)
        .includes(machine.lubricant.lubeTypeID)
    })
    newOptions.machines = machines

    newOptions.plants = getApplicablePlantsFromMachines(
      machines,
      newOptions.plants
    )

    if (relyUponMachines) {
      const machineProperties = getMachinePropertiesFromMachines({
        machines,
        allOptions,
      })
      newOptions.lubricantManufacturers =
        machineProperties.lubricantManufacturers
      newOptions.machineNames = machineProperties.machineNames
      newOptions.machineManufacturers = machineProperties.machineManufacturers
      newOptions.machineNumbers = machineProperties.machineNumbers
      newOptions.machineModels = machineProperties.machineModels
      newOptions.machinePicklistTypes = machineProperties.machineTypes
      newOptions.customerEquipmentIDs = machineProperties.customerEquipmentIDs
    } else {
      const lubeManufacturers = newOptions.lubricantManufacturers.filter(mfr =>
        selectedState.lubricantTypes
          ?.map(lt => lt?.lubeMFG)
          .includes(mfr?.listValue)
      )

      newOptions.lubricantManufacturers =
        lubeManufacturers?.length === 0 &&
        (selectedState.lubricantTypes?.length === 0 ||
          (selectedState.lubricantTypes.length === 1 &&
            selectedState.lubricantTypes[0] == null))
          ? allOptions.lubricantManufacturers
          : lubeManufacturers
    }

    const customers = getApplicableCustomersFromPlants(
      newOptions.customers,
      newOptions.plants
    )
    newOptions.customers = customers

    const divisions = newOptions.divisions.filter(division => {
      return division.customers
        ?.map(divisionCust => (divisionCust ? divisionCust.custID : -1))
        .find(divisionCustID => {
          return customers?.map(cust => cust.custID).includes(divisionCustID)
        })
    })
    newOptions.divisions = divisions
  }
  return newOptions
}

function filterOnRoutes(selectedState: TFilterState, newOptions: TFilterState) {
  if (!isNullOrEmpty(selectedState.routes)) {
    // UP customer -  division
    if (newOptions.customers?.length === 0) return newOptions
    const customers = newOptions.customers.filter(customer =>
      customer.routes
        ?.map(r => r?.routeID)
        .find(id => selectedState.routes?.map(r => r?.routeID).includes(id))
    )
    newOptions.customers = customers

    const divisions = newOptions.divisions.filter(division => {
      return division.customers
        ?.map(divisionCust => divisionCust.custID)
        .find(divisionCustID => {
          return customers?.map(cust => cust.custID).includes(divisionCustID)
        })
    })
    newOptions.divisions = divisions

    // DOWN none
  }
  return newOptions
}

function filterOnMachineConditions(
  selectedState: TFilterState,
  newOptions: TFilterState,
  allOptions: TFilterState
) {
  if (!isNullOrEmpty(selectedState.machineConditions)) {
    if (newOptions.samples.length === 0) return newOptions
    const samples = newOptions.samples.filter(s =>
      selectedState.machineConditions.includes(s.machCond)
    )
    newOptions.samples = samples

    const machines = newOptions.machines.filter(m =>
      samples?.map(s => s.machine.pointID).includes(m.pointID)
    )
    newOptions.machines = machines

    newOptions.plants = getApplicablePlantsFromMachines(
      machines,
      newOptions.plants
    )

    const machineProperties = getMachinePropertiesFromMachines({
      machines,
      allOptions,
    })
    newOptions.lubricantManufacturers = machineProperties.lubricantManufacturers
    newOptions.lubricantTypes = machineProperties.lubricantTypes
    newOptions.machineNames = machineProperties.machineNames
    newOptions.machineManufacturers = machineProperties.machineManufacturers
    newOptions.machineNumbers = machineProperties.machineNumbers
    newOptions.machineModels = machineProperties.machineModels
    newOptions.machinePicklistTypes = machineProperties.machineTypes
    newOptions.customerEquipmentIDs = machineProperties.customerEquipmentIDs

    const customers = getApplicableCustomersFromPlants(
      newOptions.customers,
      newOptions.plants
    )
    newOptions.customers = customers

    const divisions = newOptions.divisions.filter(division => {
      return division.customers
        ?.map(divisionCust => (divisionCust ? divisionCust.custID : -1))
        .find(divisionCustID => {
          return customers?.map(cust => cust.custID).includes(divisionCustID)
        })
    })
    newOptions.divisions = divisions
  }
  return newOptions
}

function filterOnLubricantCondition(
  selectedState: TFilterState,
  newOptions: TFilterState,
  allOptions: TFilterState
) {
  if (!isNullOrEmpty(selectedState.lubricantConditions)) {
    if (newOptions.samples.length === 0) return newOptions
    const samples = newOptions.samples.filter(s =>
      selectedState.lubricantConditions.includes(s.lubCond)
    )
    newOptions.samples = samples

    const machines = newOptions.machines.filter(m =>
      samples?.map(s => s.machine.pointID).includes(m.pointID)
    )
    newOptions.machines = machines

    newOptions.plants = getApplicablePlantsFromMachines(
      machines,
      newOptions.plants
    )

    const machineProperties = getMachinePropertiesFromMachines({
      machines,
      allOptions,
    })
    newOptions.lubricantManufacturers = machineProperties.lubricantManufacturers
    newOptions.lubricantTypes = machineProperties.lubricantTypes
    newOptions.machineNames = machineProperties.machineNames
    newOptions.machineManufacturers = machineProperties.machineManufacturers
    newOptions.machineNumbers = machineProperties.machineNumbers
    newOptions.machineModels = machineProperties.machineModels
    newOptions.machinePicklistTypes = machineProperties.machineTypes
    newOptions.customerEquipmentIDs = machineProperties.customerEquipmentIDs

    const customers = getApplicableCustomersFromPlants(
      newOptions.customers,
      newOptions.plants
    )
    newOptions.customers = customers

    const divisions = newOptions.divisions.filter(division => {
      return division.customers
        ?.map(divisionCust => (divisionCust ? divisionCust.custID : -1))
        .find(divisionCustID => {
          return customers?.map(cust => cust.custID).includes(divisionCustID)
        })
    })
    newOptions.divisions = divisions
  }
  return newOptions
}

// implement this!
function filterOnSample(
  selectedState: TFilterState,
  newOptions: TFilterState,
  allOptions: TFilterState
) {
  if (!isNullOrEmpty(selectedState.samples)) {
    // UP machine - everything
    if (newOptions.machines.length === 0) return newOptions
    const machines = newOptions.machines.filter(m =>
      selectedState.samples?.map(s => s.machine.pointID).includes(m.pointID)
    )
    newOptions.machines = machines

    newOptions.plants = getApplicablePlantsFromMachines(
      machines,
      newOptions.plants
    )

    // add lubricant manufacturers, samples, and mach and lub options

    const machineProperties = getMachinePropertiesFromMachines({
      machines,
      allOptions,
    })
    newOptions.lubricantManufacturers = machineProperties.lubricantManufacturers
    newOptions.lubricantTypes = machineProperties.lubricantTypes
    newOptions.machineNames = machineProperties.machineNames
    newOptions.machineManufacturers = machineProperties.machineManufacturers
    newOptions.machineNumbers = machineProperties.machineNumbers
    newOptions.machineModels = machineProperties.machineModels
    newOptions.machinePicklistTypes = machineProperties.machineTypes
    newOptions.customerEquipmentIDs = machineProperties.customerEquipmentIDs

    const customers = getApplicableCustomersFromPlants(
      newOptions.customers,
      newOptions.plants
    )
    newOptions.customers = customers

    const divisions = newOptions.divisions.filter(division => {
      return division.customers
        ?.map(divisionCust => (divisionCust ? divisionCust.custID : -1))
        .find(divisionCustID => {
          return customers?.map(cust => cust.custID).includes(divisionCustID)
        })
    })
    newOptions.divisions = divisions
  }
  return newOptions
}

const defaultState: TFilterState = {
  reportTypes: [],
  customerEquipmentIDs: [],
  customers: [],
  divisions: [],
  lubricantConditions: [],
  lubricantManufacturers: [],
  lubricantTypes: [],
  machineConditions: [],
  machineManufacturers: [],
  machineModels: [],
  machineNames: [],
  machineNumbers: [],
  machinePicklistTypes: [],
  machines: [],
  plants: [],
  routes: [],
  testPackages: [],
  samples: [],
}

let innerOptions

let previousState = defaultState
/** A SmartFilter hook that remembers the previous TFilterState and uses a semi-shallow comparison to determine if a re-sort is necessary. */
function useOptionFilter(
  currentOptions: TFilterState,
  allOptions: TFilterState
): [(_selectedState: TFilterState) => TFilterState, () => void] {
  innerOptions = currentOptions
  const [relyUponMachines, setRelyUponMachines] = useState(true)

  const machinesChanged = allOptions?.machines.length
  useEffect(() => {
    if (allOptions?.machines.length === 0) {
      setRelyUponMachines(false)
    } else {
      setRelyUponMachines(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machinesChanged])

  useEffect(() => {
    previousState = defaultState
  }, [allOptions])

  const reset = () => {
    previousState = defaultState
  }

  const filterOptions = (selectedState: TFilterState) => {
    if (statesAreEqual(selectedState, previousState)) {
      return innerOptions
    } else {
      let newOptions = { ...allOptions }
      const filters: (() => TFilterState)[] = []
      filters.push(() =>
        filterOnDivisions(selectedState, newOptions, allOptions)
      )
      filters.push(() =>
        filterOnCustomers(selectedState, newOptions, allOptions)
      )
      filters.push(() => filterOnPlants(selectedState, newOptions, allOptions))
      filters.push(() =>
        filterOnMachines(selectedState, newOptions, allOptions)
      )
      filters.push(() =>
        filterOnCustomerEquipmentIDs(selectedState, newOptions, allOptions)
      )
      filters.push(() =>
        filterOnMachineNames(selectedState, newOptions, allOptions)
      )
      filters.push(() =>
        filterOnMachineNumbers(selectedState, newOptions, allOptions)
      )
      filters.push(() =>
        filterOnMachineTypes(selectedState, newOptions, allOptions)
      )
      filters.push(() =>
        filterOnMachineModels(selectedState, newOptions, allOptions)
      )
      filters.push(() =>
        filterOnMachineManufacturers(selectedState, newOptions, allOptions)
      )
      filters.push(() =>
        filterOnLubricantTypes(
          selectedState,
          newOptions,
          allOptions,
          relyUponMachines
        )
      )
      filters.push(() => filterOnRoutes(selectedState, newOptions))
      filters.push(() =>
        filterOnLubricantManufacturer(
          selectedState,
          newOptions,
          allOptions,
          relyUponMachines
        )
      )
      filters.push(() =>
        filterOnMachineConditions(selectedState, newOptions, allOptions)
      )
      filters.push(() =>
        filterOnLubricantCondition(selectedState, newOptions, allOptions)
      )
      filters.push(() => filterOnSample(selectedState, newOptions, allOptions))

      filters?.forEach(async filter => {
        newOptions = await filter()
      })

      previousState = { ...selectedState }
      innerOptions = newOptions
      return newOptions
    }
  }

  return [filterOptions, reset]
}

export default useOptionFilter
