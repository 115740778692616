import React, { useState } from 'react'
import { Button, Grid, Modal, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import { FormField } from 'components/FormField'

import APIService from 'services/api-service'

import Loader from './Loader'
import { emailRegex } from 'utils/common-regex'

export default function FeedbackModal({
  modalOpen,
  modalSample,
  onModalClose,
}) {
  const feedbackDefaults = {
    company: '',
    firstName: '',
    lastName: '',
    email: '',
    feedback: '',
  }

  const [feedback, setFeedback] = useState(feedbackDefaults)
  const [modalSubmitting, setModalSubmitting] = useState(false)
  const [modalError, setModalError] = useState(null)
  const [state, setState] = useState({ formErrors: [] })

  function getModalStyle() {
    const top = 50
    const left = 50

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    }
  }

  const useStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: '80vw',
      backgroundColor: '#fff',
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '25px',
    },
  }))

  const modalClasses = useStyles()
  const [modalStyle] = React.useState(getModalStyle)

  const handleFormValidation = () => {
    const formErrors = []

    if (!feedback.company) {
      formErrors.push({ id: 'company', message: 'Required' })
    }
    if (!feedback.firstName) {
      formErrors.push({ id: 'firstName', message: 'Required' })
    }
    if (!feedback.lastName) {
      formErrors.push({ id: 'lastName', message: 'Required' })
    }
    if (!feedback.email) {
      formErrors.push({ id: 'email', message: 'Required' })
    }
    if (!emailRegex.test(feedback.email)) {
      formErrors.push({ id: 'email', message: 'Invalid' })
    }
    if (!feedback.feedback) {
      formErrors.push({ id: 'feedback', message: 'Required' })
    }
    if (feedback.company.length > 40) {
      formErrors.push({
        id: 'company',
        message: 'Please do not go over 40 characters.',
      })
    }
    if (feedback.firstName.length > 40) {
      formErrors.push({
        id: 'firstName',
        message: 'Please do not go over 40 characters.',
      })
    }
    if (feedback.lastName.length > 40) {
      formErrors.push({
        id: 'lastName',
        message: 'Please do not go over 40 characters.',
      })
    }
    if (feedback.email.length > 40) {
      formErrors.push({
        id: 'email',
        message: 'Please do not go over 40 characters.',
      })
    }

    setState(prev => ({ ...prev, formErrors: formErrors }))

    return !formErrors.some(x => x)
  }

  const handleFeedbackClose = () => {
    setModalError(null)
    setFeedback(feedbackDefaults)
    setModalSubmitting(false)
    onModalClose()
  }

  const handleFeedbackChange = event => {
    const update = Object.assign({}, feedback)
    update[event.target.name] = event.target.value
    setFeedback(update)
    handleFormValidation()
  }

  const handleFeedbackSave = event => {
    if (!handleFormValidation()) return
    setModalError(null)
    setModalSubmitting(true)
    ;(async (feedback, sample, cb) => {
      let data = null
      try {
        data = await APIService.createFeedback({
          labID: sample.labID,
          custID: sample.machine.plant.customer.custID,
          plantNo: sample.machine.plant.plantNo,
          machNo: sample.machine.machNo,
          company: feedback.company,
          firstName: feedback.firstName,
          lastName: feedback.lastName,
          email: feedback.email,
          feedback: feedback.feedback,
        })
        cb(data)
      } catch (ex) {
        setModalSubmitting(false)
        setModalError(ex.message)
      }
    })(feedback, modalSample, (_data: any) => {
      setFeedback(feedbackDefaults)
      handleFeedbackClose()
    })
  }

  return (
    <Modal open={modalOpen} onClose={handleFeedbackClose}>
      <div style={modalStyle} className={modalClasses.paper}>
        {modalSubmitting ? (
          <React.Fragment>
            <Loader />
            Submitting...
          </React.Fragment>
        ) : (
          <form>
            <Grid container direction="column" spacing={2}>
              <Grid
                container
                item
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <Grid item xs={2}>
                  Company
                </Grid>
                <Grid item xs={10}>
                  <FormField id="company" formErrors={state.formErrors}>
                    <TextField
                      name="company"
                      variant="outlined"
                      value={feedback.company}
                      onChange={handleFeedbackChange}
                      onBlur={handleFormValidation}
                      style={{ width: '100%' }}
                      required
                    />
                  </FormField>
                </Grid>
              </Grid>
              <Grid
                container
                item
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <Grid item xs={2}>
                  First Name
                </Grid>
                <Grid item xs={10}>
                  <FormField id="firstName" formErrors={state.formErrors}>
                    <TextField
                      name="firstName"
                      variant="outlined"
                      value={feedback.firstName}
                      onChange={handleFeedbackChange}
                      onBlur={handleFormValidation}
                      style={{ width: '100%' }}
                      required
                    />
                  </FormField>
                </Grid>
              </Grid>
              <Grid
                container
                item
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <Grid item xs={2}>
                  Last Name
                </Grid>
                <Grid item xs={10}>
                  <FormField id="lastName" formErrors={state.formErrors}>
                    <TextField
                      name="lastName"
                      variant="outlined"
                      value={feedback.lastName}
                      onChange={handleFeedbackChange}
                      onBlur={handleFormValidation}
                      style={{ width: '100%' }}
                      required
                    />
                  </FormField>
                </Grid>
              </Grid>
              <Grid
                container
                item
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <Grid item xs={2}>
                  Email
                </Grid>
                <Grid item xs={10}>
                  <FormField id="email" formErrors={state.formErrors}>
                    <TextField
                      name="email"
                      variant="outlined"
                      value={feedback.email}
                      onChange={handleFeedbackChange}
                      onBlur={handleFormValidation}
                      style={{ width: '100%' }}
                      required
                    />
                  </FormField>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                Feedback
              </Grid>
              <Grid item xs={12}>
                <FormField id="feedback" formErrors={state.formErrors}>
                  <TextField
                    name="feedback"
                    variant="outlined"
                    multiline={true}
                    rows={10}
                    value={feedback.feedback}
                    onChange={handleFeedbackChange}
                    style={{ width: '100%' }}
                    required
                  />
                </FormField>
              </Grid>
              <Grid container item direction="row" spacing={2}>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ width: '100%' }}
                    onClick={handleFeedbackSave}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: '100%' }}
                    onClick={handleFeedbackClose}
                  >
                    Close
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}

        {modalError !== null ? (
          <Alert severity="error" style={{ marginTop: '15px' }}>
            {modalError}
          </Alert>
        ) : (
          ''
        )}
      </div>
    </Modal>
  )
}
