import {
  Chart,
  ChartTitle,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
} from '@progress/kendo-react-charts'
import useMinWidth from 'hooks/useMinWidth'
import React, { CSSProperties } from 'react'

export type TPieChartPoint = {
  category: string
  value: any
}

export type TPieChartProps = {
  dataSeries?: TPieChartPoint[]
  title: string
  showLegend?: boolean
  containerStyles?: CSSProperties
}

const formatNumber = (num: number) =>
  Number(num).toLocaleString(undefined, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })

export const PieChart: React.FC<TPieChartProps> = (props: TPieChartProps) => {
  const labelContent = props => {
    return `${props.dataItem.category}: ${formatNumber(props.dataItem.value)}`
  }

  const SharedTooltip = stt => {
    return (
      <div>
        <div>{stt.point.category}</div>
        <div>{formatNumber(stt.point.value)}</div>
      </div>
    )
  }

  const dataSeriesIsEmpty = props.dataSeries?.length === 0 ?? true

  const displayData = dataSeriesIsEmpty
    ? [{ category: 'No Data', value: 5000 }]
    : props.dataSeries

  const sharedTooltipRender = context => <SharedTooltip {...context} />

  const [useSmallLabels, widthRef] = useMinWidth(550)

  return (
    <div
      ref={widthRef}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'visible',
        width: '100%',
        margin: 0,
        ...props.containerStyles,
      }}
    >
      <Chart
        style={{ width: '110%', overflow: 'visible', transform: 'scale(0.88)' }}
      >
        {!dataSeriesIsEmpty && (
          <ChartTooltip
            shared={true}
            format={'pie'}
            render={sharedTooltipRender}
          />
        )}
        <ChartLegend position="bottom" visible={props.showLegend ?? false} />
        <ChartSeries>
          <ChartSeriesItem
            type="pie"
            data={displayData}
            field="value"
            categoryField="category"
            color="rgb(0, 0, 0)"
            labels={{
              visible: !dataSeriesIsEmpty,
              content: labelContent,
              distance: 5,
              position: useSmallLabels ? 'insideEnd' : 'outsideEnd',
            }}
          />
        </ChartSeries>
      </Chart>
    </div>
  )
}
