/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */
import React, { ComponentType } from 'react'

import Styles from './Banner.module.css'

interface OwnProps {
  title: string
  titleStyle?: React.CSSProperties
  bodyStyle?: React.CSSProperties
  bannerProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
}

const Banner: React.FC<OwnProps> = React.memo(props => {
  const { className, ...restBannerProps } = props?.bannerProps ?? {
    className: '',
  }

  return (
    <div className={`${Styles.Banner} ${className}`} {...restBannerProps}>
      <div className={Styles.Contents}>
        <p className={Styles.TextHeader} style={props.titleStyle}>
          {props.title}
        </p>
        <p className={Styles.Text} style={props.bodyStyle}>
          {props.children}
        </p>
      </div>
    </div>
  )
})

export default Banner
