import { useState, useEffect } from 'react'
import LoginService from 'services/login-service'

export function useIsLoggedIn() {
  const [loggedInState, setLoggedInState] = useState<{
    loading: boolean
    loggedIn: boolean
  }>({ loading: true, loggedIn: false })

  function setLoading(loading: boolean, loggedIn: boolean) {
    setLoggedInState(_prev => ({ loading, loggedIn }))
  }

  function setLoggedIn(loggedIn: boolean) {
    setLoggedInState(_prev => ({ loading: false, loggedIn }))
  }
  useEffect(() => {
    function onLogout() {
      setLoggedIn(false)
    }

    function onLogin() {
      setLoggedIn(true)
    }

    ;(async () => {
      LoginService.SubscribeToLogout(onLogout)
      LoginService.SubscribeToLogin(onLogin)
      setLoggedInState({ loading: true, loggedIn: false })
      const result = await LoginService.isLoggedIn()
      setLoggedInState({ loading: false, loggedIn: result })
    })()

    return () => {
      LoginService.UnsubscribeToLogout(onLogout)
      LoginService.UnsubscribeToLogin(onLogin)
    }
  }, [])

  return {
    loggedIn: loggedInState.loggedIn,
    loading: loggedInState.loading,
    setLoading,
    setLoggedIn,
  }
}
