import { FluidDetails, GetFluidDetails } from 'types/api'
import { DatalessQuery, Query } from './Query'
import { serialize } from 'utils/api-utilities'

export class GetFluidDetailsQuery extends Query<GetFluidDetails, FluidDetails> {
  public get Url(): string {
    return `${
      process.env.REACT_APP_DS_EXPERIENCE_API_URL
    }/v1/Fluid/Details${serialize(this.Data)}`
  }
}
