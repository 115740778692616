import React, { ReactNode } from 'react'
import { Grid } from '@material-ui/core'

interface OwnProps {
  title: string
  isScheduleCompliance?: boolean
  children?: ReactNode
}

const MobileCardItem: React.FC<OwnProps> = ({
  title,
  isScheduleCompliance,
  children,
}: OwnProps) => {
  if (isScheduleCompliance)
    return (
      <Grid item xs={2} sm={2}>
        {title}
        {children}
      </Grid>
    )

  return (
    <>
      <Grid item xs={6} sm={3}>
        {title}
      </Grid>
      <Grid item xs={6} sm={3}>
        {children}
      </Grid>
    </>
  )
}

export default MobileCardItem
