import React from 'react'

import CenteredContainer from 'components/CenteredContainer'
import FileNotFoundError from 'components/FileNotFoundError'

const ReportNotFoundPage: React.FC = () => {
  return (
    <CenteredContainer>
      <FileNotFoundError errorMessage="Report Currently Unavailable. We may still be processing this report. Contact our Customer Service Team for more information" />
    </CenteredContainer>
  )
}

export default ReportNotFoundPage
