import React from 'react'
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import moment from 'moment'

import ConditionElement from 'components/ConditionElement'
import {
  lubeConditionKeys,
  machineConditionKeys,
} from 'services/lookup-service'

import { useCardStyles } from './cardStyles'

interface Props {
  item: any
  startDate: Date
  endDate: Date
}
const MobileCard: React.FC<Props> = ({ item, startDate, endDate }: Props) => {
  const cardClasses = useCardStyles()

  interface RenderSamplesSummaryLinkProps {
    custID: Number
    plantID: Number
    startDate: Date
    endDate: Date
    total: Number
  }

  interface RenderMachineConditionSummaryLinkProps
    extends RenderSamplesSummaryLinkProps {
    machineCondition: Number
  }

  interface RenderLubeConditionSummaryLinkProps
    extends RenderSamplesSummaryLinkProps {
    lubeCondition: Number
  }

  const RenderSamplesSummaryLink = ({
    custID,
    plantID,
    startDate,
    endDate,
    total,
  }: RenderSamplesSummaryLinkProps) => (
    <Link
      to={`/reports/summary-report?custID=${custID}&plantID=${plantID}&startDate=${moment(
        startDate
      ).format()}&endDate=${moment(endDate).format()}`}
    >
      {total}
    </Link>
  )

  const RenderMachineConditionSummaryLink = ({
    custID,
    plantID,
    startDate,
    endDate,
    total,
    machineCondition,
  }: RenderMachineConditionSummaryLinkProps) => (
    <Link
      to={`/reports/summary-report?custID=${custID}&plantID=${plantID}&startDate=${moment(
        startDate
      ).format()}&endDate=${moment(
        endDate
      ).format()}&machineCondition=${machineCondition}`}
    >
      {total}
    </Link>
  )

  const RenderLubeConditionSummaryLink = ({
    custID,
    plantID,
    startDate,
    endDate,
    total,
    lubeCondition,
  }: RenderLubeConditionSummaryLinkProps) => (
    <Link
      to={`/reports/summary-report?custID=${custID}&plantID=${plantID}&startDate=${moment(
        startDate
      ).format()}&endDate=${moment(
        endDate
      ).format()}&machineCondition=${lubeCondition}`}
    >
      {total}
    </Link>
  )

  return (
    <Card variant="outlined" className={cardClasses.card}>
      {item.division && <CardHeader title={item.division} />}
      <CardContent>
        <Grid container direction="row" spacing={1}>
          <Grid container direction="column" spacing={1} xs={12} md={6}>
            <Grid item xs={12} md={12}>
              Customer
            </Grid>
            <Grid item xs={12} md={12} className="info-value">
              {item.custName}
            </Grid>
          </Grid>
          <Grid container direction="column" spacing={1} xs={10} md={4}>
            <Grid item xs={12} md={12}>
              Plant Name
            </Grid>
            <Grid item xs={12} md={12} className="info-value">
              {item.plantName}
            </Grid>
          </Grid>
          <Grid container direction="column" spacing={1} xs={2} md={2}>
            <Grid item xs={12} md={12}>
              Samples
            </Grid>
            <Grid item xs={12} md={12}>
              {RenderSamplesSummaryLink({
                custID: item.custId,
                plantID: item.plantId,
                startDate,
                endDate,
                total: item.samples,
              })}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={1}
            xs={12}
            md={6}
            className="condition-box"
          >
            <Grid
              container
              direction="column"
              spacing={1}
              xs={12}
              className="condition-title"
            >
              Machine Condition
            </Grid>
            <Grid container direction="column" spacing={1} xs={3}>
              <Grid item xs={12} md={12}>
                <ConditionElement condition={machineConditionKeys.normal} />
              </Grid>
              <Grid item xs={12} md={12} className="condition-value">
                {RenderMachineConditionSummaryLink({
                  custID: item.custId,
                  plantID: item.plantId,
                  startDate,
                  endDate,
                  total: item.machineNormal,
                  machineCondition: machineConditionKeys.normal,
                })}
              </Grid>
            </Grid>
            <Grid container direction="column" spacing={1} xs={3}>
              <Grid item xs={12} md={12}>
                <ConditionElement condition={machineConditionKeys.marginal} />
              </Grid>
              <Grid item xs={12} md={12} className="condition-value">
                {RenderMachineConditionSummaryLink({
                  custID: item.custId,
                  plantID: item.plantId,
                  startDate,
                  endDate,
                  total: item.machineMarginal,
                  machineCondition: machineConditionKeys.marginal,
                })}
              </Grid>
            </Grid>
            <Grid container direction="column" spacing={1} xs={3}>
              <Grid item xs={12} md={12}>
                <ConditionElement condition={machineConditionKeys.critical} />
              </Grid>
              <Grid item xs={12} md={12} className="condition-value">
                {RenderMachineConditionSummaryLink({
                  custID: item.custId,
                  plantID: item.plantId,
                  startDate,
                  endDate,
                  total: item.machineCritical,
                  machineCondition: machineConditionKeys.critical,
                })}
              </Grid>
            </Grid>
            <Grid container direction="column" spacing={1} xs={3}>
              <Grid item xs={12} md={12}>
                <ConditionElement condition={machineConditionKeys.unknown} />
              </Grid>
              <Grid item xs={12} md={12} className="condition-value">
                {RenderMachineConditionSummaryLink({
                  custID: item.custId,
                  plantID: item.plantId,
                  startDate,
                  endDate,
                  total: item.machineUnknown,
                  machineCondition: machineConditionKeys.unknown,
                })}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            spacing={1}
            xs={12}
            md={6}
            className="condition-box"
          >
            <Grid
              container
              direction="column"
              spacing={1}
              xs={12}
              className="condition-title"
            >
              Lubricant Condition
            </Grid>
            <Grid container direction="column" spacing={1} xs={3}>
              <Grid item xs={12} md={12}>
                <ConditionElement condition={lubeConditionKeys.normal} />
              </Grid>
              <Grid item xs={12} md={12} className="condition-value">
                {RenderLubeConditionSummaryLink({
                  custID: item.custId,
                  plantID: item.plantId,
                  startDate,
                  endDate,
                  total: item.lubeNormal,
                  lubeCondition: lubeConditionKeys.normal,
                })}
              </Grid>
            </Grid>
            <Grid container direction="column" spacing={1} xs={3}>
              <Grid item xs={12} md={12}>
                <ConditionElement condition={lubeConditionKeys.marginal} />
              </Grid>
              <Grid item xs={12} md={12} className="condition-value">
                {RenderLubeConditionSummaryLink({
                  custID: item.custId,
                  plantID: item.plantId,
                  startDate,
                  endDate,
                  total: item.lubeMarginal,
                  lubeCondition: lubeConditionKeys.marginal,
                })}
              </Grid>
            </Grid>
            <Grid container direction="column" spacing={1} xs={3}>
              <Grid item xs={12} md={12}>
                <ConditionElement condition={lubeConditionKeys.critical} />
              </Grid>
              <Grid item xs={12} md={12} className="condition-value">
                {RenderLubeConditionSummaryLink({
                  custID: item.custId,
                  plantID: item.plantId,
                  startDate,
                  endDate,
                  total: item.lubeCritical,
                  lubeCondition: lubeConditionKeys.critical,
                })}
              </Grid>
            </Grid>
            <Grid container direction="column" spacing={1} xs={3}>
              <Grid item xs={12} md={12}>
                <ConditionElement condition={lubeConditionKeys.unknown} />
              </Grid>
              <Grid item xs={12} md={12} className="condition-value">
                {RenderLubeConditionSummaryLink({
                  custID: item.custId,
                  plantID: item.plantId,
                  startDate,
                  endDate,
                  total: item.lubeUnknown,
                  lubeCondition: lubeConditionKeys.unknown,
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default MobileCard
