import React from 'react'

import CenteredContainer from 'components/CenteredContainer'
import FileNotFoundError from 'components/FileNotFoundError'

const InvoiceNotFoundPage: React.FC = () => {
  return (
    <CenteredContainer>
      <FileNotFoundError errorMessage="Invoice Currently Unavailable. We may still be processing this invoice. Contact our Customer Service Team for more information" />
    </CenteredContainer>
  )
}

export default InvoiceNotFoundPage
