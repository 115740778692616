import { createStyles, makeStyles } from '@material-ui/core'

export const useCustomTableStyles = makeStyles(() =>
  createStyles({
    table: {
      '& .k-grid-header': {
        '& col:nth-last-of-type(22)': {
          width: '10%',
        },
        '& col:nth-last-of-type(21)': {
          width: '10%',
        },
        '& col:nth-last-of-type(20)': {
          width: '8%',
        },
        '& col:nth-last-of-type(19)': {
          width: '10%',
        },
        '& col:nth-last-of-type(17)': {
          width: '8%',
        },
        '& col:nth-last-of-type(16)': {
          width: '8%',
        },
        '& col:nth-last-of-type(14)': {
          width: '8%',
        },
        '& col:nth-of-type(1)': {
          width: '5%',
        },
      },
      '& .k-grid-table': {
        '& col:nth-last-of-type(22)': {
          width: '10%',
        },
        '& col:nth-last-of-type(21)': {
          width: '10%',
        },
        '& col:nth-last-of-type(20)': {
          width: '8%',
        },
        '& col:nth-last-of-type(19)': {
          width: '10%',
        },
        '& col:nth-last-of-type(17)': {
          width: '8%',
        },
        '& col:nth-last-of-type(16)': {
          width: '8%',
        },
        '& col:nth-last-of-type(14)': {
          width: '8%',
        },
        '& col:nth-of-type(1)': {
          width: '5%',
        },
      },
    },
  })
)
