import { ProblemDetails } from 'types/api'

export class FetchError extends Error {
  type?: string
  status?: number
  instance?: string
  extensions?: Map<string, any>

  public constructor(problemDetails: ProblemDetails) {
    super()
    this.name = problemDetails.title ?? problemDetails.Title
    this.message = problemDetails.detail ?? problemDetails.Detail
    this.cause = null
    this.type = problemDetails.type ?? problemDetails.Type
    this.status = problemDetails.status ?? problemDetails.Status
    this.instance = problemDetails.instance ?? problemDetails.Instance
    this.extensions = problemDetails.extensions ?? problemDetails.Extensions // is this really a map?
  }

  toString() {
    return `${this.status}: ${this.name} - ${this.message}`
  }
}
