import { AccordionActions, AccordionSummary, Grid } from '@material-ui/core'
import { SearchButton, ResetButton } from 'components/buttons'
import FilterListIcon from '@material-ui/icons/FilterList'
import React from 'react'
import {
  useSelections,
  SelectionContextProvider,
} from '../../../Contexts/SelectionContext'
import * as Styles from './index.module.css'

type TAccordionButtonProps = {
  onReset: () => void
}

export const AccordionButtons: React.FC<TAccordionButtonProps> = (
  props: TAccordionButtonProps
) => {
  const { onReset } = props

  return (
    <AccordionActions id="FilterAccordionActions">
      <Grid item>
        <SearchButton id="FilterAccordionSearchButton" />
      </Grid>
      <Grid item>
        <ResetButton id="FilterAccordionResetButton" onResetClick={onReset} />
      </Grid>
    </AccordionActions>
  )
}

type TAccordionHeaderProps = {
  onClick?: () => void
  showUpperDateRange?: boolean
  showLowerDateRange?: boolean
}

export const AccordionHeader: React.FC<TAccordionHeaderProps> = props => {
  const selections = useSelections()

  const buildDateRangeString = (from?: Date, to?: Date) => {
    if (
      !(props.showLowerDateRange && from) &&
      !(props.showUpperDateRange && to)
    )
      return 'Searching All Dates'

    let dateRangeString = 'Searching'

    if (props.showLowerDateRange && from) {
      dateRangeString += ` from ${getFormattedDate(from)}`
    }

    if (props.showUpperDateRange && to) {
      if (!(props.showLowerDateRange && from)) {
        dateRangeString += ' up'
      }

      dateRangeString += ` to ${getFormattedDate(to)}`
    }

    return dateRangeString
  }

  return (
    <AccordionSummary
      onClick={props.onClick}
      id="FilterAccordionSummary"
      className={Styles.FilterAccordionSummary}
      expandIcon={<FilterListIcon style={{ color: 'white' }} />}
    >
      <div className={Styles.HeaderTextBox}>
        <span className={Styles.HeaderTextEntry}>Search and Filter</span>{' '}
        <span className={Styles.HeaderTextEntry}>
          {props.showLowerDateRange || props.showUpperDateRange
            ? buildDateRangeString(selections.fromDate, selections.toDate)
            : ''}
        </span>
      </div>
    </AccordionSummary>
  )
}

const getFormattedDate = (date: Date) => {
  if (isNaN(date.getTime())) {
    return ' -'
  }

  const year = date.getFullYear()

  let month = (1 + date.getMonth()).toString()
  month = month.length > 1 ? month : '0' + month

  let day = date.getDate().toString()
  day = day.length > 1 ? day : '0' + day

  return month + '/' + day + '/' + year
}
