import React from 'react'

//import logo from '../images/ds-ico-inv2.png'
//import logo from `${process.env.PUBLIC_URL}/static/images/ds-ico-inv2.png`

const Loader = () => {
  return (
    <>
      <style>
        {`
#loader-wrapper{
  width:100%;
  height:100%;
  z-index:1000;
  background-color: rgba(234, 234, 241, 0);
}
.ico{
  width:75px;
  height:75px;
  display: block;
  position: relative;
  left:50%;
  margin-left: -35px;
  margin-top: 135px;
  opacity:1;
}

#loader{
  display: block;
  position: relative;
  left:50%;
  top: -135px;
  margin-left: -100px;
  width:200px;
  height:200px;
  border-top:2px solid rgb(255, 129, 67);
  border-radius:50%;
  -webkit-animation: spin 3.5s linear infinite;
  animation: spin 3.5s linear infinite;
}

#loader:before{
  content:"";
  position:absolute;
  top:5px;
  left:5px;
  bottom:5px;
  right:5px;
  border-top: 1px solid rgb(46, 70, 99);
  border-radius:50%;
  -webkit-animation: spin 2.5s linear infinite;
  animation: spin 2.5s linear infinite;
}

#loader:after{
  content:"";
  position:absolute;
  top:15px;
  left:15px;
  bottom:15px;
  right:15px;
  border-top: 1px solid rgba(5, 5, 5, 20%);
  border-radius:50%;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0%   {
    -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
    }
  100% {
    -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
          transform: rotate(360deg);
  }
}
@keyframes spin {
  0%   {
    -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
    }
  100% {
    -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
          transform: rotate(360deg);
  }
}`}
      </style>
      <div className="loading">
        <div id="content">
          <div id="loader-wrapper">
            <img
              className="ico"
              src="/static/images/ds-ico-inv2.png"
              alt="Loading"
            />
            <div id="loader"></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Loader
