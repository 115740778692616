/* eslint-disable filenames/match-exported */
import ProfileParseWorker from 'workerize-loader!workers/profile-parser.worker.js'  // eslint-disable-line import/no-webpack-loader-syntax

let worker = null

export default function getProfileParser() {
  if (!worker) {
    worker = new ProfileParseWorker()
  }

  return worker
}
