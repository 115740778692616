import React from 'react'
import { Checkbox } from '@material-ui/core'

type TProps = {
  children: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
}

export const Toggle = (props: TProps) => {
  return (
    <>
      {props.children || 'Toggle'}
      <Checkbox onChange={props.onChange} color="default" />
    </>
  )
}

export default Toggle
