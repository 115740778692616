import { Tooltip } from '@material-ui/core'
import React, { useContext, useMemo, useRef, useState } from 'react'
import { VariableSizeList } from 'react-window'
import { Checkbox } from '@material-ui/core'
import ListSubheader from '@material-ui/core/ListSubheader'
import { createTheme, ThemeProvider, useTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'

import ControlContext from '../../../Contexts/ControlContext'
import coreTheme from '../../theme'
import { statesAreEqual } from '../SmartFilter/SmartFilterUtilities'

const LISTBOX_PADDING = 10 // px

type TRowProps = {
  data: Array<any>
  index: number
  style: any
}

/**
 * Render a row of data with default styling props.
 * @param props data, index of data, and data style.
 */
const renderRow = (
  props: TRowProps
): React.DetailedReactHTMLElement<{ style: any }, HTMLElement> => {
  const { data, index, style } = props
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
      marginTop: '5px',
      marginBottom: '5px',
      width: '100%',
      whiteSpace: 'no-wrap',
      overflow: 'hidden',
    },
  })
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return (
    <div
      ref={ref as React.LegacyRef<HTMLDivElement>}
      {...props}
      {...outerProps}
    />
  )
})

const useResetCache = (data: number) => {
  const ref = React.useRef(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponent: React.ComponentType<React.HTMLAttributes<HTMLElement>> =
  React.forwardRef((props, ref) => {
    const { children, ...other } = props
    const itemData = React.Children.toArray(children)
    const theme = useTheme()
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
    const itemCount = itemData.length
    const itemSize = smUp ? 36 : 48
    /**
     * Get the size of a child element div.
     */
    const getChildSize = child => {
      if (React.isValidElement(child) && child.type === ListSubheader) {
        return 48
      }

      return itemSize
    }

    /**
     * Get the height of the list of items of children supplied to ListboxComponent
     */
    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
    }

    const gridRef = useResetCache(itemCount)

    return (
      <div ref={ref as React.LegacyRef<HTMLDivElement>}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 4 * LISTBOX_PADDING}
            width="445px"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="div"
            itemSize={index => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    )
  })

const theme = createTheme(
  {
    overrides: {
      // MuiInput: {
      //   root: {
      //     height: '45px',
      //     overflowY: 'hidden',
      //     overflowX: 'hidden',
      // This is ugly but kinda what I'm going for? Need a better solution for the effect.
      //   },
      // },

      MuiPaper: {
        root: {
          width: '445px',
        },
      },
      MuiFormLabel: {
        root: {
          height: 'calc(100% + 20px)',
          color: '#222',
          pointerEvents: 'none',
          zIndex: 99,
        },
      },
      MuiCheckbox: {
        root: {
          color: '#222',
        },
      },
    },
  },
  coreTheme
)

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

type TRendererProps = {
  option: any
  SelectedProps: { inputValue: string; selected: boolean }
  renderOption: (option: any) => string
}

type TProps = {
  options: any[]
  required: boolean
  htmlFor: string
  label: string
  id?: string
  defaultValue?: any
  value?: any
  onClose?: (_value: any) => void
  onClick?: (_value: any) => void
  onInput?: (_value: any) => void
  getOptionSelected?: (_option: any, _value: any) => boolean
  onChange: (_value: any) => void
  multiple: boolean
  renderValue: (_value: any) => string
  disabled?: boolean
  style?: React.CSSProperties
  variant?: 'filled' | 'outlined' | 'standard'
  getOptionDisabled?: (option: any) => boolean
  autoCompletefilterOptions?: (option) => string
}

export const VirtualSelectFilter = ({
  options,
  renderValue,
  label,
  required,
  multiple,
  onChange,
  value,
  defaultValue,
  disabled,
  onInput,
  onClose,
  style,
  onClick,
  variant,
  getOptionDisabled,
  autoCompletefilterOptions,
  ...rest
}: TProps) => {
  const controlContext = useContext(ControlContext)

  const [controlledValue, setControlledValue] = useState(
    defaultValue ?? value ?? null
  )
  const [inputValue, setInputValue] = React.useState('')

  const handleChange = vals => {
    onChange(vals)
    setControlledValue(vals)
  }

  const renderedItems = useRef<
    Map<string, { state: any; node: React.ReactNode }>
  >(new Map<string, { state: any; node: React.ReactNode }>())

  const optionRenderer = (rendererProps: TRendererProps) => {
    const { option, SelectedProps, renderOption } = rendererProps
    const { inputValue, selected } = SelectedProps
    const stringifiedOption = renderOption(option)

    let renderedOption = renderedItems.current.get(stringifiedOption)
    if (
      !renderedOption ||
      !statesAreEqual(renderedOption.state, SelectedProps)
    ) {
      const matches = match(stringifiedOption ?? '', inputValue)
      const parts = parse(stringifiedOption ?? '', matches)
      renderedOption = {
        state: SelectedProps,
        node: (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
            <div>
              {parts.map((part, index) => (
                <Tooltip title={part.text} style={{ display: 'flex' }}>
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                      width: '445px',
                    }}
                  >
                    {part.text.length > 56
                      ? part.text.substring(0, 56) + ' ...'
                      : part.text}
                  </span>
                </Tooltip>
              ))}
            </div>
          </React.Fragment>
        ),
      }

      renderedItems.current.set(stringifiedOption, renderedOption)
    }

    return renderedOption.node
  }

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        getOptionDisabled={getOptionDisabled}
        onClose={onClose}
        multiple={multiple}
        includeInputInList
        openOnFocus
        key={controlContext.resetKey}
        disableListWrap={true}
        disabled={disabled}
        inputValue={inputValue}
        onInputChange={(event, value) => {
          onInput && onInput(event)
          if (event && event.type === 'blur') {
            setInputValue('')
          } else if (!multiple) {
            setInputValue(value)
          } else if (event && event.type === 'change') {
            setInputValue(value)
          }
        }}
        forcePopupIcon={true}
        noOptionsText={'There are no options available.'}
        defaultValue={defaultValue}
        value={controlledValue}
        {...rest}
        ListboxComponent={ListboxComponent}
        disableCloseOnSelect={multiple}
        options={options}
        getOptionLabel={option => renderValue(option) ?? ''}
        filterOptions={
          autoCompletefilterOptions
            ? createFilterOptions({
                stringify: autoCompletefilterOptions,
              })
            : undefined
        }
        limitTags={1}
        onChange={(event, value) => handleChange(value)}
        style={style}
        renderInput={params => {
          return (
            <TextField
              required={required}
              onClick={onClick || onClose}
              {...params}
              label={label}
              variant={variant || 'outlined'}
              size="small"
            />
          )
        }}
        renderOption={(option, { inputValue, selected }) => {
          return optionRenderer({
            option,
            SelectedProps: { inputValue, selected },
            renderOption: renderValue,
          })
        }}
      />
    </ThemeProvider>
  )
}
