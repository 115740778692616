import PageStyles from '../page-styles.module.css'
import LockPersonIcon from '@mui/icons-material/LockPerson'
import { useIsLoggedIn } from 'hooks/useIsLoggedIn'
import { LoadingCardModule } from './LoadingCardModule'

const loggedInParagraph = (
  <span>
    The bottle identifier provided belongs to someone else or does not exist; if
    the bottle does exist, your account does not have access to it. If you've
    entered the incorrect bottle identifier or code, feel free to try again. If
    you think this is a mistake, please contact customer support at{' '}
    <a href={`mailto: ${process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL}`}>
      {process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL!}
    </a>{' '}
    for further help.
  </span>
)

const loggedOutParagraph = (
  <span>
    You are not logged in and do not have direct access to bottle information
    via bottle identifiers. When manually providing a bottle identifier to the
    URL, please log in first to see your data; offline searching for bottle
    identifiers is not supported for security purposes, unless via an encrypted
    bottle identifier.
  </span>
)

type TProps = {
  hideFooter?: boolean
  header?: JSX.Element
}

export const ForbiddenCardModule = (props: TProps) => {
  const loggedInStatus = useIsLoggedIn()

  if (loggedInStatus.loading) return <LoadingCardModule />

  const paragraph = loggedInStatus.loggedIn
    ? loggedInParagraph
    : loggedOutParagraph

  const header = props.header ? (
    props.header
  ) : (
    <>
      <div>
        <header className={PageStyles.MainTitle}>Forbidden</header>
        <sub className={PageStyles.SubTitle}>
          <span className={PageStyles.BlockSpan}>Access Not Permitted</span>
        </sub>
      </div>
    </>
  )

  return (
    <>
      {header}
      <div className={PageStyles.LargeIconBox}>
        <LockPersonIcon
          className={PageStyles.LockPersonIcon}
          style={{ fontSize: '9rem' }}
        />
      </div>
      <div>
        {!props.hideFooter && (
          <section className={PageStyles.DetailSection}>
            <p className={PageStyles.FinePrintParagraph}>{paragraph}</p>
          </section>
        )}
      </div>
    </>
  )
}
