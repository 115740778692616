import { TEncrypted, TIdentityType, TListType } from 'types/api'
import { DatalessQuery, Query } from './Query'
import { serialize } from 'utils/api-utilities'

export class AllPortTypesEncryptedQuery extends Query<
  TEncrypted,
  TIdentityType[]
> {
  public get Url(): string {
    return `${
      process.env.REACT_APP_DS_EXPERIENCE_API_URL
    }/v1/Machine/PortTypes/All/Encrypted${serialize(this.Data)}`
  }
}
