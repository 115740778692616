import React from 'react'
import {
  Collapse,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  MenuItem,
  Paper,
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import CancelIcon from '@material-ui/icons/Cancel'

import coreTheme from 'components/theme'

import HelpView from './HelpView'

let container
if (typeof window !== 'undefined') {
  container = window !== undefined ? () => window.document.body : undefined
}

interface TGroup {
  title: string
  items: TItem[]
}

export interface TItem {
  title: string
  link: string
  details: string
}

const groups: TGroup[] = [
  {
    title: 'Reports',
    items: [
      {
        title: 'How to view Your Reports',
        link: '/static/help-images/View-Your-Reports.webm',
        details: 'How to view Your Reports',
      },
      {
        title: 'How to view Sample Status Report',
        link: '/static/help-images/Sample-Status-Report.webm',
        details: 'How to view Sample Status Report',
      },
      {
        title: 'How to view Recurring Exceptions Report',
        link: '/static/help-images/Recurring-Exceptions-Report.webm',
        details: 'How to view Recurring Exceptions Report',
      },
      {
        title: 'How to view Last Sample Report',
        link: '/static/help-images/Last-Sample-Report.webm',
        details: 'How to view Last Sample Report',
      },
      {
        title: 'How to view Delinquent Samples Report',
        link: '/static/help-images/Delinquent-Samples-Report.webm',
        details: 'How to view Delinquent Samples Report',
      },
      {
        title: 'How to view Condition Summary Report',
        link: '/static/help-images/Condition-Summary-Report.webm',
        details: 'How to view Condition Summary Report',
      },
      {
        title: 'How to view Common Exceptions Report',
        link: '/static/help-images/Common-Exceptions-Report.webm',
        details: 'How to view Common Exceptions Report',
      },
      {
        title: 'How to view Change of Condition Report',
        link: '/static/help-images/Change-of-Condition-Report.webm',
        details: 'How to view Change of Condition Report',
      },
      {
        title: 'How to view Scheduled Samples Due Report',
        link: '/static/help-images/Scheduled-Samples-Due-Report.webm',
        details: 'How to view Scheduled Samples Due Report',
      },
      {
        title: 'How to view Schedule Compliance Report',
        link: '/static/help-images/Schedule-Compliance-Report.webm',
        details: 'How to view Schedule Compliance Report',
      },
      {
        title: 'How to view Summary Report',
        link: '/static/help-images/Summary-Report.webm',
        details: 'How to view Summary Report',
      },
      {
        title: 'How to view Program Snapshot Report',
        link: '/static/help-images/Program-Snapshot-Report.webm',
        details: 'How to view Program Snapshot Report',
      },
    ],
  },
  {
    title: 'Program Management',
    items: [
      {
        title: 'How to add New Equipment',
        link: '/static/help-images/Add-New-Equipment.webm',
        details: 'How to add New Equipment',
      },
      {
        title: 'How to manage Equipment Database',
        link: '/static/help-images/Managing-Equipment-Database.webm',
        details: 'How to manage Equipment Database',
      },
      {
        title: 'How to view Standard Sample Labels with List',
        link: '/static/help-images/Standard-Sample-Labels-with-List.webm',
        details: 'How to view Standard Sample Labels with List',
      },
      {
        title: 'How to view Standard Sample Labels',
        link: '/static/help-images/Standard-Sample-Labels.webm',
        details: 'How to view Standard Sample Labels',
      },
      {
        title: 'How to order Supplies',
        link: '/static/help-images/Ordering-Supplies.webm',
        details: 'How to order Supplies',
      },
    ],
  },
  {
    title: 'DART System',
    items: [
      {
        title: 'How to export Historical Data to Excel',
        link: '/static/help-images/DART-Historical-Data-Exported-to-Excel.webm',
        details: 'How to export Historical Data to Excel',
      },
      {
        title: 'How to view Historical Data in Graph Form',
        link: '/static/help-images/DART-Historical-Data-in-Graph-Form.webm',
        details: 'How to view Historical Data in Graph Form',
      },
    ],
  },
  {
    title: 'Search and Filter',
    items: [
      {
        title: 'How to use Search and Filter',
        link: '/static/help-images/Using-Search-and-Filter.webm',
        details: 'How to use Search and Filter',
      },
    ],
  },
  {
    title: 'Miscellaneous',
    items: [
      {
        title: 'How to use Multi-Column Sorting',
        link: '/static/help-images/Multi-Column-Sorting.webm',
        details: 'How to use Multi-Column Sorting',
      },
      {
        title: 'How to export a Report',
        link: '/static/help-images/Exporting-a-Report.webm',
        details: 'How to export a Report',
      },
      {
        title: 'How to expand Rows per Page',
        link: '/static/help-images/Expanding-Rows-per-Page.webm',
        details: 'How to expand Rows per Page',
      },
    ],
  },
]

interface TProps {
  open: boolean
  onClose: () => void
}

export const HelpDrawer = ({ open, onClose }: TProps) => {
  const useStyles = React.useCallback(
    makeStyles(theme => ({
      helpDrawerPaper: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        color: coreTheme.palette.primary.contrastText,
        height: 'auto',
        position: 'initial',
      },
      drawerContent: {
        width: '15vw',
        paddingBottom: '25px',
        maxHeight: '85vh',
        overflow: 'auto',
      },
      group: {
        marginTop: '25px',
        marginBottom: '0px',
        paddingBottom: '0px',
      },
      nested: {
        paddingLeft: theme.spacing(4),
        whiteSpace: 'normal',
      },
      instructionsBox: {
        backgroundColor: 'white',
        borderRadius: 4,
        marginBottom: 5,
        width: '15vw',
        boxShadow:
          '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
      },
      instructionsText: {
        color: 'black',
        fontSize: 18,
      },
      drawerPanel: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        width: '17vw',
        height: '100vh',
        backgroundColor: coreTheme.palette.primary.main,
        zIndex: -1,
      },
      modalBox: {
        height: '100vh',
      },
    })),
    [innerHeight]
  )

  const [expand, setExpand] = React.useState(groups.map(x => false))
  const [modalOpen, setModalOpen] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState<TItem>(null)

  const handleExpandClick = index => {
    const newExpand = expand.map(x => false)
    newExpand[index] = !expand[index]
    setExpand(newExpand)
  }

  const handleItemClick = (item: TItem) => {
    setModalOpen(true)
    setSelectedItem(item)
  }

  const handleModalClose = () => {
    setModalOpen(false)
    setSelectedItem(null)
  }

  const classes = useStyles()

  const drawer = (
    <>
      <div className={classes.drawerPanel}></div>
      <div style={{ margin: 15 }}>
        <Grid container direction="row" wrap="nowrap">
          {modalOpen && (
            <Grid
              container
              justify="center"
              alignItems="center"
              md={10}
              className={classes.modalBox}
            >
              <HelpView
                show={modalOpen}
                item={selectedItem}
                onClose={handleModalClose}
              />
            </Grid>
          )}
          <Grid
            container
            md={modalOpen ? 2 : 12}
            alignItems="flex-end"
            direction="column"
          >
            <Grid container direction="row" className={classes.instructionsBox}>
              <Grid
                container
                md={9}
                alignItems="center"
                justify="center"
                className={classes.instructionsText}
              >
                Instructions
              </Grid>
              <Grid container justify="flex-end" md={3}>
                <IconButton id="help-drawer_cancel-icon-button" onClick={onClose}>
                  <CancelIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Paper className={classes.drawerContent} elevation={3}>
              <List>
                {groups.map((group, index) => (
                  <>
                    <ListItem
                      button
                      className={classes.group}
                      onClick={event => handleExpandClick(index)}
                    >
                      {group.title}
                      {expand[index] ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={expand[index]} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {group.items.map(item => (
                          <MenuItem
                            button
                            className={classes.nested}
                            selected={item === selectedItem}
                            onClick={event => handleItemClick(item)}
                          >
                            <ListItemText primary={item.title} />
                          </MenuItem>
                        ))}
                      </List>
                    </Collapse>
                  </>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  )

  return (
    <>
      <Drawer
        disableEnforceFocus
        container={container}
        variant="temporary"
        anchor={'right'}
        open={open}
        onClose={onClose}
        elevation={0}
        classes={{
          paper: classes.helpDrawerPaper,
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {drawer}
      </Drawer>
    </>
  )
}
