import { Route, TEncrypted, TListType } from 'types/api'
import { DatalessQuery, Query } from './Query'
import { serialize } from 'utils/api-utilities'

export class GetRoutesEncryptedQuery extends Query<TEncrypted, Route[]> {
  public get Url(): string {
    return `${
      process.env.REACT_APP_DS_EXPERIENCE_API_URL
    }/v1/Route/Encrypted${serialize(this.Data)}`
  }
}
