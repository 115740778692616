import moment from 'moment'

import { TAPIConditionSet } from 'types/api'
import { lubeConditions, machineConditions } from 'services/lookup-service'

export function tableFormatter(
  data: TAPIConditionSet[],
  isDivisionCustomer: boolean
) {
  const headerValues = [
    ...(isDivisionCustomer ? ['Division Name'] : []),
    'Customer Name',
    'Plant Name',
    'Machine Number',
    'Machine Name',
    'Current Sample Date',
    'Current Sample Machine Condition',
    'Current Sample Lube Condition',
    'Current Sample Lab No.',
    'Previous Sample Date',
    'Previous Sample Machine Condition',
    'Previous Sample Lube Condition',
    'Previous Sample Lab No.',
  ]

  const dataValues = data?.map(datum => [
    ...(isDivisionCustomer ? [datum.machine.plant.customer.division.name] : []),
    datum.machine.plant.customer.custName,
    datum.machine.plant.plantName,
    datum.machine.machNo,
    datum.machine.machineName,
    moment(datum.currentSample.sampleDate).format('l'),
    machineConditions.find(x => x.value === datum.currentSample.machCond).text,
    lubeConditions.find(x => x.value === datum.currentSample.lubCond).text,
    datum.currentSample.labID?.toString(),
    moment(datum.previousSample.sampleDate).format('l'),
    machineConditions.find(x => x.value === datum.previousSample.machCond).text,
    lubeConditions.find(x => x.value === datum.previousSample.lubCond).text,
    datum.previousSample.labID?.toString(),
  ])

  return [headerValues, ...dataValues]
}
