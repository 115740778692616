import React from 'react'
import { Box, Hidden, TablePagination } from '@material-ui/core'
import {
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'

import TableGrid from 'components/TableGrid'

import { MobileCard } from './MobileCard'
import { useProfile } from 'Contexts/ProfileContext'

import { RenderDate } from '../KendoUIGridRenders'

interface OwnProps {
  data: any[]
  onSortChange?: (_event: GridSortChangeEvent) => void
  onPageChange?: (_event: GridPageChangeEvent) => void
  onPageChangeMobile: (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    _page: number
  ) => void
  onPageSizeChangeMobile?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >
  orderConfig: any[]
  page: number
  pageSize: number
  count: number
  offline?: boolean
}

export const DelinquentSamplesTable: React.FC<OwnProps> = ({
  data,
  onSortChange,
  onPageChange,
  onPageChangeMobile,
  onPageSizeChangeMobile,
  orderConfig,
  page,
  pageSize,
  count,
  offline,
}: OwnProps) => {
  const profileContext = useProfile()

  return (
    <>
      {offline && (
        <Box mb={2}>Your last 100 reports are available while offline.</Box>
      )}

      <Hidden mdUp>
        {!data?.length && (
          <p style={{ textAlign: 'center' }}>No records available</p>
        )}
        {data.map(item => (
          <MobileCard
            key={item.machine.machineNo}
            data={item}
            showDivisions={
              profileContext.dependentData.userDetails.isDivisionUser
            }
          />
        ))}

        {!offline && (
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100, 250, 500]}
            component="div"
            count={count}
            rowsPerPage={pageSize}
            page={page > 0 ? page - 1 : page}
            onPageChange={onPageChangeMobile}
            onRowsPerPageChange={onPageSizeChangeMobile}
          />
        )}
      </Hidden>

      <Hidden smDown>
        <TableGrid
          data={data}
          offline={offline}
          sort={orderConfig}
          skip={(page - 1) * pageSize}
          take={pageSize}
          total={count}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
        >
          {profileContext.dependentData.userDetails.isDivisionUser && (
            <Column
              field="machine.plant.customer.division.name"
              title="Division"
            />
          )}
          <Column field="machine.plant.customer.custName" title="Customer" />
          <Column field="machine.plant.plantName" title="Plant" />
          <Column
            field="machine.route.routeNo"
            title="Route"
            cell={({
              dataItem: {
                machine: { route },
              },
            }) => <td>{route && `${route.routeNo} - ${route.name}`}</td>}
          />
          <Column field="machine.machNo" title="Mach No." />
          <Column
            field="lastSampleLabID"
            title="Machine Name"
            cell={({ dataItem }) => (
              <td style={{ color: '#6200EE' }}>
                <a
                  href={`/dart/data?labID=${dataItem.lastSampleLabID}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {dataItem.machine.machineName}
                </a>
              </td>
            )}
          />
          <Column field="machine.custEquID" title="Equipment ID" />
          <Column
            field="lastSampleDate"
            title="Date Last Sampled"
            cell={props => <RenderDate {...props} />}
          />
          <Column field="daysSinceLastSample" title="Days Since Last Sample" />
        </TableGrid>
      </Hidden>
    </>
  )
}
