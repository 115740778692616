import * as PageStyles from '../page-styles.module.css'
import SettingsIcon from '@mui/icons-material/Settings'
import { formatDateString } from '../formatters'
import { TWarhammerSampleWithoutIdentifier } from '../types-and-enums'

type TProps = {
  bottleData: TWarhammerSampleWithoutIdentifier
}
export const InTestCardModule = (props: TProps) => {
  return (
    <>
      <div>
        <header className={PageStyles.MainTitle}>Science in Progress</header>
        <sub className={PageStyles.SubTitle}>
          <span className={PageStyles.BlockSpan}>
            This sample is being tested at this very moment!
          </span>
        </sub>

        <div className={PageStyles.LargeIconBox}>
          <SettingsIcon
            className={PageStyles.SettingsIcon}
            style={{ fontSize: '9rem' }}
          />
        </div>
      </div>
      <section>
        <h6 className={PageStyles.DetailSectionHeader}>Sample Details</h6>
        <ul
          className={`${PageStyles.Details} ${PageStyles.DetailSection} ${PageStyles.DetailEntryContainer} ${PageStyles.Wrapper}`}
        >
          <li className={`${PageStyles.Wrappable}`}>
            <span className={PageStyles.PropertyLabel}>Customer</span>
            <span className={PageStyles.Property}>
              {props.bottleData?.customerID}
              {' - '}
              {props.bottleData?.customerName}
            </span>
          </li>

          <li className={`${PageStyles.Wrappable}`}>
            <span className={PageStyles.PropertyLabel}>Plant</span>
            <span className={PageStyles.Property}>
              {props.bottleData?.plantNumber}
              {' - '}
              {props.bottleData?.plantName}
            </span>
          </li>

          <li className={`${PageStyles.Wrappable}`}>
            <span className={PageStyles.PropertyLabel}>Machine</span>
            <span className={PageStyles.Property}>
              {props.bottleData?.pointID}
              {' - '}
              {props.bottleData?.machineName}
            </span>
          </li>

          <li className={`${PageStyles.Wrappable}`}>
            <span className={PageStyles.PropertyLabel}>Lubricant Hours</span>
            <span className={PageStyles.Property}>
              {props.bottleData?.lubeHours}
            </span>
          </li>

          <li className={`${PageStyles.Wrappable}`}>
            <span className={PageStyles.PropertyLabel}>Machine Hours</span>
            <span className={PageStyles.Property}>
              {props.bottleData?.machineHours}
            </span>
          </li>

          <li className={`${PageStyles.Wrappable}`}>
            <span className={PageStyles.PropertyLabel}>Sampled On</span>
            <span className={PageStyles.Property}>
              {formatDateString(props.bottleData?.sampledAt)}
            </span>
          </li>

          <li className={`${PageStyles.Wrappable}`}>
            <span className={PageStyles.PropertyLabel}>Oil Changed At</span>
            <span className={PageStyles.Property}>
              {formatDateString(props.bottleData?.oilChangedAt)}
            </span>
          </li>

          <li className={`${PageStyles.FullWidth}`}>
            <span className={PageStyles.PropertyLabel}>Comments</span>
            <span className={`${PageStyles.Property} ${PageStyles.Comments}`}>
              {props.bottleData?.comments}
            </span>
          </li>
        </ul>
      </section>
      <div>
        <section className={PageStyles.DetailSection}>
          <p className={PageStyles.FinePrintParagraph}>
            Sit back, relax, and your sample results will be ready by the end of
            the day!
          </p>
          <p className={PageStyles.FinePrintParagraph}>
            Have questions? <br />
            Contact customer support at{' '}
            <a href={`mailto: ${process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL}`}>
              {process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL!}
            </a>
            .
          </p>
        </section>
      </div>
    </>
  )
}
