import { Card, CardContent } from '@material-ui/core'

import { Link } from 'react-router-dom'
import { ResponsiveBar } from '@nivo/bar'

import React from 'react'

export type TWrappedResponsiveBarChart = {
  classes: Record<string, any>
  title: string
  loaded: boolean
  motionStiffness: number
  motionDamping: number
} & React.ComponentPropsWithoutRef<typeof ResponsiveBar>

export const WrappedResponsiveBarChart: React.FC<TWrappedResponsiveBarChart> =
  React.memo(
    props => {
      const { classes, title, loaded, ...barChartProps } = props
      return (
        <Card variant="outlined" className={classes.card}>
          <CardContent className={classes.cardContent}>
            <div className={classes.cardHeader}>
              <Link
                className={classes.cardHeaderLink}
                to={'/reports/common-exceptions'}
              >
                {props.title}
                {props.children}
              </Link>
            </div>
            {props.loaded ? (
              <div style={{ height: '350px' }}>
                <ResponsiveBar {...barChartProps} />
              </div>
            ) : (
              ''
            )}
          </CardContent>
        </Card>
      )
    },
    (prev, next) => {
      return prev.loaded === next.loaded
    }
  )
