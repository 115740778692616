/* eslint-disable prettier/prettier */
import React, { useState } from 'react'
import Switch from '@material-ui/core/Switch'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'

import styles from './DisableFilters.module.css'

const useDisableFiltersBox = (): [boolean, (_reset: () => void) => JSX.Element] => {
  const [useFilters, setUseFilters] = useState(true)

  const title =
    "Dropdown contents are filtered by selections made in other dropdowns to help you find what you're looking for. To toggle this pre-filtering, flip this switch."
  const FiltersInfoBox = (reset: () => void) => {
    const handleSwitch = () => {
      reset()
      setUseFilters(!useFilters)
    }

    return (
      <div className={styles.main}>
        <div className={styles.switchContainer}>
          Pre-Filtering {useFilters ? 'Enabled' : 'Disabled'}
          <Switch
            checked={useFilters}
            onChange={handleSwitch}
            name="Use Filters Switch"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </div>
        <div className={styles.info}>
          <Tooltip
            title={<span className={styles.title}>{title}</span>}
            placement="bottom-end"
          >
            <HelpOutlineOutlinedIcon fontSize="small" />
          </Tooltip>
          <span className={styles.mainText}>
            Don't see what you're looking for?
          </span>
        </div>
      </div>
    )
  }

  return [useFilters, FiltersInfoBox]
}

export default useDisableFiltersBox
