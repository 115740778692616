import React from 'react'
import { ResponsiveWrapper } from '@nivo/core'

import LineChart from './LineChart'

type OwnProps = Omit<
  React.ComponentPropsWithoutRef<typeof LineChart>,
  'width' | 'height'
>

const ResponsiveLineChart: React.FC<OwnProps> = (props: OwnProps) => (
  <ResponsiveWrapper>
    {({ width, height }) => (
      <LineChart {...props} width={width} height={height} />
    )}
  </ResponsiveWrapper>
)

export default ResponsiveLineChart
