import React, { useEffect, useState } from 'react'
import { FormControl } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import {
  useSelections,
  SelectionContextProvider,
} from '../../../Contexts/SelectionContext'
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers'

import coreTheme from '../../theme'
import moment from 'moment'

const theme = createTheme(
  {
    overrides: {
      MuiFormControl: {
        root: {
          [coreTheme.breakpoints.down('sm')]: {
            // width: '100%'
            width: '225px',
          },
          [coreTheme.breakpoints.up('sm')]: {
            width: '225px',
          },
        },
      },
      MuiFormLabel: {
        root: {
          color: 'black',
        },
      },
    },
  },
  coreTheme
)

export const ToDateFilter: React.FC<Partial<KeyboardDatePickerProps>> = (
  props: KeyboardDatePickerProps
) => {
  const [value, setValue] = useState<Date | moment.Moment>(
    props.defaultValue as Date
  )
  const selections = useSelections()

  useEffect(() => {
    selections.setToDate(props.defaultValue as Date | undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!props.defaultValue])

  const handleChange = (val: moment.Moment) => {
    if (props.onChange) props.onChange(val)
    selections.setToDate(val?.toDate())
    setValue(val?.toDate())
  }

  return (
    <ThemeProvider theme={theme}>
      <FormControl>
        <KeyboardDatePicker
          {...props}
          minDate={selections.fromDate}
          value={value ?? null}
          onChange={handleChange}
          clearable
          autoOk
          label="To Date"
          placeholder="To Date"
          emptyLabel="To Date"
          format="MM/DD/yyyy"
        />
      </FormControl>
    </ThemeProvider>
  )
}
