import { TFilterState } from './SmartFilterTypes'

export const defaultFilterState: TFilterState = {
  reportTypes: [],
  customerEquipmentIDs: [],
  customers: [],
  divisions: [],
  lubricantConditions: [],
  lubricantManufacturers: [],
  lubricantTypes: [],
  machineConditions: [],
  machineManufacturers: [],
  machineModels: [],
  machineNames: [],
  machineNumbers: [],
  machinePicklistTypes: [],
  machines: [],
  plants: [],
  routes: [],
  testPackages: [],
  samples: [],
}

export const filterStateKeys = Object.keys(defaultFilterState)
