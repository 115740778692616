import React from 'react'
import { DisconnectedBanner } from 'components/Banners/DisconnectedBanner'
import { usePrimaryHubContext } from 'Contexts/PrimaryHubContext'
import { ConnectionStatus } from 'types/custom.d'
import { ConnectionStatusMonitor } from 'components/ConnectionStatusMonitor'

export default function LoadingError() {
  const primaryHubContext = usePrimaryHubContext()

  const connected = primaryHubContext.status === ConnectionStatus.connected
  const message = connected
    ? 'TestOil is available. Please refresh the page or try again later.'
    : 'TestOil could not be contacted. Please try again later.'
  return (
    <>
      <div>There was an error loading the page. {message}</div>
      <DisconnectedBanner />
      <ConnectionStatusMonitor />
    </>
  )
}
