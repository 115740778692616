import React from 'react'
import { Tooltip } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { Link } from 'react-router-dom'
import Moment from 'moment'

export const renderMachineName = data => (
  <td>
    {data.latestLabID !== null ? (
      <Link
        to={`/dart/data?labID=${data.latestLabID}`}
        target="_blank"
        rel="noreferrer"
      >
        {data.machine.machineName}
      </Link>
    ) : (
      data.machine.machineName
    )}
  </td>
)

export const renderMachineNameMobile = data => (
  <td style={{border: 'none', paddingLeft: '0px' }}>
    {data.latestLabID !== null ? (
      <Link
        to={`/dart/data?labID=${data.latestLabID}`}
        target="_blank"
        rel="noreferrer"
      >
        {data.machine.machineName}
      </Link>
    ) : (
      data.machine.machineName
    )}
  </td>
)

export const renderMonthHeader = month => {
  return Moment(month).format('MMM YY')
}

export const renderMonth = (data, month) => {
  const count = data.counts.find(
    y => y.month === month.month + 1 && y.year === month.year
  )
  const condition =
    typeof count === 'undefined'
      ? 0
      : count.machCond > 2 || count.lubCond > 2
      ? 3
      : count.machCond === 2 || count.lubCond === 2
      ? 2
      : count.machCond === 1 || count.lubCond === 1
      ? 1
      : 0

  return (
    <td
      align="center"
      style={{
        padding: 5,
        backgroundColor:
          condition === 1
            ? '#8af07d'
            : condition === 2
            ? '#ffec12'
            : condition === 3
            ? '#f07d7d'
            : '',
      }}
    >
      {typeof count === 'undefined' ? (
        'No Data'
      ) : count.tested > 0 && count.scheduled === 0 ? (
        <Tooltip title="Tested, not scheduled">
          <CheckIcon />
        </Tooltip>
      ) : count.tested === 0 && count.scheduled > 0 ? (
        <Tooltip title="Scheduled, not tested">
          <CheckBoxOutlineBlankIcon />
        </Tooltip>
      ) : count.tested === 0 && count.scheduled === 0 ? (
        ''
      ) : (
        <Tooltip title="Scheduled and tested">
          <CheckBoxIcon />
        </Tooltip>
      )}
    </td>
  )
}

export const renderMonthMobile = (data, month) => {
  const count = data.counts.find(
    y => y.month === month.month + 1 && y.year === month.year
  )
  const condition =
    typeof count === 'undefined'
      ? 0
      : count.machCond > 2 || count.lubCond > 2
      ? 3
      : count.machCond === 2 || count.lubCond === 2
      ? 2
      : count.machCond === 1 || count.lubCond === 1
      ? 1
      : 0

  return (
    <td
      align="center"
      style={{
        border: 'none',
        padding: 5,
        backgroundColor:
          condition === 1
            ? '#8af07d'
            : condition === 2
            ? '#ffec12'
            : condition === 3
            ? '#f07d7d'
            : '',
      }}
    >
      {typeof count === 'undefined' ? (
        'No Data'
      ) : count.tested > 0 && count.scheduled === 0 ? (
        <Tooltip title="Tested, not scheduled">
          <CheckIcon />
        </Tooltip>
      ) : count.tested === 0 && count.scheduled > 0 ? (
        <Tooltip title="Scheduled, not tested">
          <CheckBoxOutlineBlankIcon />
        </Tooltip>
      ) : count.tested === 0 && count.scheduled === 0 ? (
        ''
      ) : (
        <Tooltip title="Scheduled and tested">
          <CheckBoxIcon />
        </Tooltip>
      )}
    </td>
  )
}
