import React from 'react'
import { FormControlLabel, InputLabel, Switch } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

import coreTheme from '../../theme'

const theme = createTheme(
  {
    overrides: {
      MuiFormControlLabel: {
        root: {
          [coreTheme.breakpoints.down('sm')]: {
            // width: '100%'
            width: '225px',
          },
          [coreTheme.breakpoints.up('sm')]: {
            width: '225px',
          },
        },
      },
      MuiFormLabel: {
        root: {
          color: '#222',
          zIndex: 99,
        },
      },
    },
  },
  coreTheme
)

export const SampleOrReportDateFilter = ({
  checked,
  onReportDateChange,
  onDateSwitchChange,
  onSampleDateChange,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <FormControlLabel
        control={
          <>
            <InputLabel
              htmlFor="sampleDate"
              onClick={onReportDateChange}
              style={{ display: 'inline-block', cursor: 'pointer' }}
            >
              Report Date
            </InputLabel>
            <Switch
              checked={checked}
              onChange={onDateSwitchChange}
              style={{ display: 'inline-block' }}
              name="sampleDate"
              color="secondary"
            />
            <InputLabel
              htmlFor="sampleDate"
              onClick={onSampleDateChange}
              style={{ display: 'inline-block', cursor: 'pointer' }}
            >
              Sample Date
            </InputLabel>
          </>
        }
        label=""
        style={{ display: 'inline-block' }}
      />
    </ThemeProvider>
  )
}
