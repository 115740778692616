import { useProfile } from 'Contexts/ProfileContext'
import { useIsLoggedIn } from 'hooks/useIsLoggedIn'
import { useState, useEffect } from 'react'
import { FetchError } from 'utils/Errors/FetchError'
import { LoadingStateWithoutReset } from '../registration/Cards/BottleInformationEntry/CreateMachine/types'

const defaultState: Readonly<LoadingStateWithoutReset<boolean, FetchError>> = {
  data: false,
  error: null,
  loading: true,
  loaded: false,
}
type TProps = {
  unless?: boolean
}
export function useIsReadOnlyError(props: TProps = {}) {
  const isLoggedIn = useIsLoggedIn()
  const profile = useProfile()

  const [state, setState] = useState<
    LoadingStateWithoutReset<boolean, FetchError>
  >({ ...defaultState })

  useEffect(() => {
    ;(() => {
      if (!isLoggedIn.loggedIn && !isLoggedIn.loading) {
        setState(prev => ({
          ...prev,
          error: new FetchError({
            status: 451,
            detail: 'User not signed in.',
          }),
          loading: false,
          data: true,
          loaded: true,
        }))
      } else if (!!props.unless) {
        setState(prev => ({
          ...prev,
          error: null,
          loading: false,
          loaded: true,
          data: false,
        }))
      } else if (
        profile.minimumProfileLoaded &&
        profile?.profile?.user?.readOnly
      ) {
        setState(prev => ({
          ...prev,
          error: new FetchError({
            status: 450,
            detail: 'User is read-only.',
          }),
          loading: false,
          data: true,
          loaded: true,
        }))
      } else if (
        !isLoggedIn.loading &&
        isLoggedIn.loggedIn &&
        profile.minimumProfileLoaded &&
        !profile?.profile?.user?.readOnly
      ) {
        setState(prev => ({
          ...prev,
          error: null,
          loading: false,
          data: false,
          loaded: true,
        }))
      }
    })()
  }, [
    isLoggedIn.loggedIn,
    isLoggedIn.loading,
    profile?.profile?.user?.readOnly,
    profile.minimumProfileLoaded,
  ])

  function reset() {
    setState({ ...defaultState })
  }

  return { ...state, reset }
}
