export type TMachineManufacturersListItem = {
  listValue: string
  listDesc: string
}
export default function getMachineManufacturerFromOptions({
  machineManufacturer,
  machineManufacturereOptions,
}: {
  machineManufacturer: string
  machineManufacturereOptions: TMachineManufacturersListItem[]
}): string {
  return machineManufacturereOptions?.findIndex(
    x => x.listValue === machineManufacturer
  ) !== -1
    ? machineManufacturereOptions?.find(
        x => x.listValue === machineManufacturer
      )?.listDesc
    : ''
}
