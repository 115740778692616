import React from 'react'
type Props = { style?: {} }
const ImageAppName: React.FC<Props> = (props: Props) => {
  return (
    <img
      style={props.style}
      src="/static/images/login/ds-name-inv-1.png"
      alt="Loading"
    />
  )
}

export default ImageAppName
