import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import APIService, { TProfile } from 'services/api-service'
import getProfileParser from 'workers/get-profile-parser.js'
import * as ProfileUtilities from './ProfileUtilities'
import { parseMachineLists } from 'utils/api-utilities'
import { TFilterState } from 'components/filters/SmartFilter/SmartFilterTypes'
import { frequencies, months } from 'services/lookup-service'
import { TMonth } from 'components/filters/ConfiguredFilters/FiscalYearStartMonthFilter'
import ReactDOM from 'react-dom'
import { InvoiceStatus as InvoicePaymentStatus } from 'components/FilterAccordion/types'
import {
  getIsInternalUser,
  isAnalyticsCustomer,
  isFinancialRestricted,
  isSullairCustomer,
  isUSSteelCustomer,
} from 'utils/customer-utilities'
import { CancellablePromise } from 'utils/CancellablePromise'

export type TProfileUtilities = {
  reloadProfile: () => Promise<void>
  clearProfile: () => Promise<void>
  constructProfile: () => Promise<void>
  setUserLoggedIn: (loggedIn: boolean) => void
  reparseMachineLists: () => Promise<void>
  getFilterOptions: () => Promise<Partial<TFilterState>>
}

export type TUserDetails = {
  isDivisionUser: boolean
  isMultiCustomerUser: boolean
  isMultiPlantUser: boolean
  isReadonlyUser: boolean
  isUPSUser: boolean
  isSullairUser: boolean
  isUSSteelUser: boolean
  isAnalyticsCustomer: boolean
  hasCompany: boolean
  isFinancialRestricted: boolean
  isInternalUser: boolean
}

export type TFilterInformation = {
  filterOptions: Partial<TFilterState>
  initialFilterOptionsLoaded: boolean
  minimumFilterOptionsLoaded: boolean
  fullFilterOptionsLoaded: boolean
  initialFilterState: Partial<TFilterState>
  defaultValues: Partial<TAccordionDefaults>
}

export type TProfileDependentData = {
  filters: TFilterInformation
  userDetails: Partial<TUserDetails>
}

export type TOnLogoutEvents = {
  AddEvent: (func: () => void) => void
  RemoveEvent: (func: () => void) => void
}

export type TUserSessionManagement = {
  onLogoutEvents: TOnLogoutEvents
}

export interface TProfileContext {
  minimumProfileLoaded: boolean
  fullProfileLoaded: boolean
  billingCustomersLoaded: boolean
  profile: Partial<TProfile>
  dependentData: TProfileDependentData
  utilities: TProfileUtilities
  userSessionManagement: TUserSessionManagement
}

const ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

export const profileDefaults: Partial<TProfile> = {
  user: null,
  correctiveActions: [],
  custEquIDs: [],
  customers: [],
  divisions: [],
  error: '',
  lubeMfrOptions: [],
  lubeTypes: [],
  machineMfgOptions: [],
  machineMfgs: [],
  machineModels: [],
  machineNames: [],
  machineNos: [],
  machineTypeOptions: [],
  machineTypes: [],
  machines: [],
  plants: [],
  routes: [],
  shippingInformation: [],
  testGroups: [],
  billingCustomers: [],
}

export const defaultValues = {
  startDate: (() => {
    const now = new Date(Date.now())
    const startOfMonth = new Date(now.getFullYear(), now.getMonth() - 3, 1)
    return startOfMonth
  })(),

  endDate: (() => {
    const now = new Date(Date.now())
    return new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999)
  })(),
  hideCompleted: false,
  sampleDate: null,
  frequency: frequencies.find(f => f.text === 'Yearly'),
  reportDateSampleDate: 'Sample Date',
  hideReviewed: false,
  showReportDate: false,
  showSampleDate: true,
  labNumber: '',
  serialNumber: '',
  sampleStatus: null,
  fiscalYearStartMonth: months[0] as TMonth,
  daysDelinquent: 90 as 0 | 90 | 30 | 45 | 60 | 75 | 120 | 240 | 360,
  exceptionCount: 2,
  omitDayOfMonth: true,
  omitFullDate: false,
  shipmentStatuses: [],
  orderNumber: null,
  trackingNumber: null,
  smartFilterPrefiltering: true,
  onlyForSelectedMonth: false,
  invoiceStatus: InvoicePaymentStatus.Unpaid as InvoicePaymentStatus,
}

export type TAccordionDefaults = typeof defaultValues

export const useProfile = () => {
  return useContext(ProfileContext)
}

export const ProfileContext = React.createContext<TProfileContext>(
  {} as TProfileContext
)
ProfileContext.displayName = 'ProfileContext'

let logoutEvents: Array<() => void> = []
export const ProfileContextProvider: React.FC = props => {
  const [profile, setProfile] = useState<Partial<TProfile>>(profileDefaults)

  const [filterOptions, setFilterOptions] = useState<TFilterState>()
  const [initialFilterOptionsLoaded, setInitialFilterOptionsLoaded] =
    useState(false)
  const [minimumFilterOptionsLoaded, setMinimumFilterOptionsLoaded] =
    useState(false)
  const [fullFilterOptionsLoaded, setFullFilterOptionsLoaded] = useState(false)
  const [userDetails, setUserDetails] = useState<Partial<TUserDetails>>({})

  const [minimumProfileLoaded, setMinimumProfileLoaded] = useState(false)
  const [fullProfileLoaded, setFullProfileLoaded] = useState(false)
  const [billingCustomersLoaded, setBillingCustomersLoaded] = useState(false)
  const [loggedIn, setUserLoggedIn] = useState(false)

  const [profileLoading, setProfileLoading] = useState(false)

  useEffect(() => {
    if (!loggedIn) {
      let event: () => void
      while ((event = logoutEvents.pop())) {
        event()
      }
    }
  }, [loggedIn])

  const initialFilterState = useMemo(
    () => {
      // if ((minimumProfileLoaded && !fullProfileLoaded) || fullProfileLoaded)
      return getInitialSmartFilterState(profile)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      minimumProfileLoaded,
      fullProfileLoaded,
      minimumFilterOptionsLoaded,
      fullFilterOptionsLoaded,
    ]
  )

  const getFilterOptions = useCallback(
    async (data?: Partial<TProfile>): Promise<TFilterState> => {
      const options = await ProfileUtilities.getOptionsFromProfile(
        data || profile
      )
      setFilterOptions(options)
      return options
    },
    [profile]
  )

  const onMinimumProfileLoad = useCallback(
    async (data: Partial<TProfile>) => {
      ReactDOM.unstable_batchedUpdates(async () => {
        await getFilterOptions(data)
        setProfile(prev => ({ ...prev, ...data }))

        const isDivisionUser = data.divisions?.length > 0
        const isMultiCustomerUser = data.customers?.length > 1
        const isMultiPlantUser = data.plants.length > 1
        const isFinancialRestrictedUser = isFinancialRestricted(
          data.customers.map(c => c.custID),
          data.customers.map(c => c.industryCode)
        )

        const isInternalUser = getIsInternalUser()

        setUserDetails(prev => ({
          ...prev,
          isDivisionUser,
          isUPSUser: data.customers?.some(customer => customer.uPSSI),
          isSullairUser: isSullairCustomer(data.customers.map(c => c.custID)),
          hasCompany: data.customers?.some(c => c.companyID !== null),
          isMultiCustomerUser,
          isMultiPlantUser,
          isReadonlyUser: data.user.readOnly,
          isUSSteelUser: isUSSteelCustomer(data.customers.map(c => c.custID)),
          isAnalyticsCustomer: isAnalyticsCustomer(data),
          isFinancialRestricted: isFinancialRestrictedUser,
          isInternalUser,
        }))
        setMinimumFilterOptionsLoaded(true)
        setMinimumProfileLoaded(true)
      })
    },
    [getFilterOptions]
  )

  const onFullProfileLoad = useCallback(
    async (data: Partial<TProfile>) => {
      ReactDOM.unstable_batchedUpdates(async () => {
        await getFilterOptions(data)
        setFullFilterOptionsLoaded(true)
      })
    },
    [getFilterOptions]
  )

  const loadProfile = useCallback(async () => {
    setProfileLoading(true)

    await getFilterOptions() //Wait because of race condition with previous calls may lead to missing values.
    setInitialFilterOptionsLoaded(true)

    let promise = APIService.getMinimumProfile()
    let billingCustomerPromise = APIService.getBillingCustomerPromise()
    billingCustomerPromise
      .then(bcData => {
        setProfile(prev => ({ ...prev, ...bcData }))
      })
      .catch(err => {})
      .finally(() => {
        ongoingCancellablePromises.filter(p => p != promise)
        setBillingCustomersLoaded(true)
      })
    ongoingCancellablePromises.push(promise)
    ongoingCancellablePromises.push(billingCustomerPromise)
    let data = await promise
    ongoingCancellablePromises.filter(p => p != promise)

    await onMinimumProfileLoad(data) //Wait because of race condition with previous calls may lead to missing values.

    promise = APIService.getFullProfile(data)
    ongoingCancellablePromises.push(promise)
    data = await promise
    ongoingCancellablePromises.filter(p => p != promise)

    setProfile(prev => ({ ...prev, ...data }))
    setFullProfileLoaded(true)
    await onFullProfileLoad(data) //Wait because of race condition with previous calls may lead to missing values.
    setProfileLoading(false)
  }, [getFilterOptions, onFullProfileLoad, onMinimumProfileLoad])

  const clearProfile = useCallback(async () => {
    ReactDOM.unstable_batchedUpdates(() => {
      cancelAllPendingAPICalls()
      setMinimumProfileLoaded(false)
      setFullProfileLoaded(false)
      setProfileLoading(false)
      setProfile({ ...profileDefaults })
      setFilterOptions(undefined)
      setUserDetails({})
      setBillingCustomersLoaded(false)
    })
  }, [])

  const reloadProfile = useCallback(async () => {
    clearProfile()
    loadProfile()
  }, [clearProfile, loadProfile])

  /**Load the profile under the condition that it is not already loading and has not already loaded. */
  const constructProfile = useCallback(async () => {
    if (!profileLoading && !minimumProfileLoaded) {
      clearProfile()
      loadProfile()
    }
  }, [clearProfile, loadProfile, minimumProfileLoaded, profileLoading])

  //SECTION Load Profile on App Startup

  //NOTE No longer triggering on mount! Triggered manually via the reloadprofile function.
  //This is to prevent unnecessary reloads of the profile on specific tabs
  // useEffect(() => {
  //   /*NOTE: The only required logic here is the "loggedIn" check, because this provider is only created once to wrap the project.
  //    **If it was used multiple times, the others would be required and have been added for prudence.
  //    */
  //   if (
  //     loggedIn &&
  //     !minimumProfileLoaded &&
  //     !fullProfileLoaded &&
  //     !profileLoading
  //   )
  //     reloadProfile()
  // }, [
  //   fullProfileLoaded,
  //   loadProfile,
  //   loggedIn,
  //   minimumProfileLoaded,
  //   profileLoading,
  //   reloadProfile,
  // ])

  const cancelAllPendingAPICalls = () => {
    while (ongoingCancellablePromises.length > 0) {
      const promise = ongoingCancellablePromises.pop()
      promise.abortController?.abort()
    }
  }

  //NOTE This removes all ongoing, cancellable promises when the application is exited.
  useEffect(() => {
    return () => {
      cancelAllPendingAPICalls()
    }
  }, [])

  //!SECTION

  //SECTION Parse Dependent Data

  //!SECTION

  const reparseMachineLists = async () => {
    if (fullProfileLoaded) {
      setFullProfileLoaded(false)

      let tempProfile
      if (window?.Worker) {
        tempProfile = await getProfileParser().parseMachineLists(profile)
      } else {
        tempProfile = (await parseMachineLists(profile)) as TProfile
      }

      setProfile(prev => ({ ...prev, tempProfile }))
      setFilterOptions(await ProfileUtilities.getOptionsFromProfile(profile))
      setFullProfileLoaded(true)
    }
  }

  const onLogoutEvents: TOnLogoutEvents = {
    AddEvent: func => {
      logoutEvents.push(func)
    },
    RemoveEvent: func => {
      logoutEvents = logoutEvents.filter(e => e !== func)
    },
  }

  return (
    <ProfileContext.Provider
      value={{
        fullProfileLoaded,
        minimumProfileLoaded,
        billingCustomersLoaded,
        profile,
        userSessionManagement: {
          onLogoutEvents,
        },
        dependentData: {
          userDetails,
          filters: {
            filterOptions,
            defaultValues: defaultValues,
            initialFilterOptionsLoaded,
            minimumFilterOptionsLoaded,
            fullFilterOptionsLoaded,
            initialFilterState,
          },
        },
        utilities: {
          reloadProfile,
          constructProfile,
          clearProfile,
          setUserLoggedIn,
          getFilterOptions,
          reparseMachineLists,
        },
      }}
    >
      {props.children}
    </ProfileContext.Provider>
  )
}

const getInitialSmartFilterState = (profile: Partial<TProfile>) => {
  const division = profile?.divisions?.find(x =>
    x.customers.some(y => y.custID === profile?.customers[0].custID)
  )
  const selectedDivisionIDs =
    profile?.divisions?.length > 0 &&
    profile?.customers?.length > 0 &&
    division !== null &&
    division !== undefined
      ? [division.iD]
      : []

  const selectedCustomerIDs =
    profile?.customers?.length > 0 ? [profile?.customers[0].custID] : []

  return {
    divisions:
      profile?.divisions?.filter(d => selectedDivisionIDs.includes(d.iD)) || [],
    customers:
      profile?.customers?.filter(c => selectedCustomerIDs.includes(c.custID)) ||
      [],
    customerEquipmentIDs: [],
    lubricantConditions: [],
    lubricantManufacturers: [],
    lubricantTypes: [],
    machineConditions: [],
    machineManufacturers: [],
    machineModels: [],
    machineNames: [],
    machineNumbers: [],
    machinePicklistTypes: [],
    machines: [],
    plants: [],
    reportTypes: [],
    routes: [],
    samples: [],
    testPackages: [],
  } as Partial<TFilterState>
}
