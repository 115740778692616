import React, { useState } from 'react'
import { Hidden, TablePagination } from '@material-ui/core'
import {
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'
import { Link } from 'react-router-dom'

import ReadOnlyDialog from 'components/Modals/ReadOnlyDialog'
import TableGrid from 'components/TableGrid'

import MobileCard from './MobileCard'

export type TProps = {
  offline?: boolean
  readOnly: boolean
  routes: any[]
  orderConfig: any[]
  page: number
  pageSize: number
  count: number
  onPageChange?: (_event: GridPageChangeEvent) => void
  onSortChange?: (_event: GridSortChangeEvent) => void
  onPageChangeMobile: (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    _page: number
  ) => void
  onPageSizeChangeMobile?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >
}

export const ManageRoutesReportTable: React.FC<TProps> = ({
  offline,
  readOnly,
  routes,
  orderConfig,
  page,
  pageSize,
  count,
  onPageChange,
  onSortChange,
  onPageChangeMobile,
  onPageSizeChangeMobile,
}: TProps) => {
  const showDivisions = routes.some(r => r.customer.division)

  const [showReadOnlyDialog, setShowReadonlyDialog] = useState(false)

  const handleShowReadonlyDialog = () => {
    setShowReadonlyDialog(true)
  }

  const RenderRouteName = ({ dataItem: route }: any) => (
    <td style={{ cursor: 'pointer' }}>
      {readOnly ? (
        <span onClick={handleShowReadonlyDialog}>{route.name}</span>
      ) : (
        <Link to={`/program-management/routes/manage?routeID=${route.routeID}`}>
          <span style={{ color: '#6200EE' }}>{route.name}</span>
        </Link>
      )}
    </td>
  )

  const RenderRouteNameMobile = ({ dataItem: route }: any) => (
    <td style={{ cursor: 'pointer', border: 'none', paddingLeft: '0px' }}>
      {readOnly ? (
        <span onClick={handleShowReadonlyDialog}>{route.name}</span>
      ) : (
        <Link to={`/program-management/routes/manage?routeID=${route.routeID}`}>
          <span style={{ color: '#6200EE' }}>{route.name}</span>
        </Link>
      )}
    </td>
  )

  return (
    <>
      <ReadOnlyDialog
        open={showReadOnlyDialog}
        onClose={() => setShowReadonlyDialog(false)}
      />
      <Hidden mdUp>
        {!routes?.length && (
          <p style={{ textAlign: 'center' }}>No records available</p>
        )}
        {routes.map(route => (
          <MobileCard
            key={route.routeNo}
            showDivisions={showDivisions}
            route={route}
            renderRouteNameMobile={<RenderRouteNameMobile dataItem={route} />}
          />
        ))}

        {!offline ? (
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100, 250, 500]}
            component="div"
            count={count}
            rowsPerPage={pageSize}
            page={page > 0 ? page - 1 : page}
            onChangePage={onPageChangeMobile}
            onChangeRowsPerPage={onPageSizeChangeMobile}
          />
        ) : (
          ''
        )}
      </Hidden>

      <Hidden smDown>
        <TableGrid
          clearSortingButtonId="manage_routes_report-clear_sorting_button"
          data={routes}
          offline={offline}
          sort={orderConfig}
          skip={(page - 1) * pageSize}
          take={pageSize}
          total={count}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
        >
          {showDivisions && (
            <Column field="customer.division.name" title="Division Name" />
          )}
          <Column field="customer.custName" title="Customer Name" />
          <Column field="routeNo" title="Route Number" />
          <Column
            field="name"
            title="Route Name"
            cell={props => <RenderRouteName {...props} />}
          />
        </TableGrid>
      </Hidden>
    </>
  )
}
