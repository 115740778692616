import React from 'react'
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import { Link } from 'react-router-dom'
import moment from 'moment'

import ConditionElement from 'components/ConditionElement'
import FileLink from 'components/FileLink'

interface Props {
  sample: any
}
const MobileCard: React.FC<Props> = ({ sample: x }) => {
  return (
    <Card variant="outlined" style={{ marginBottom: '10px' }}>
      <CardHeader title={x.machine.plant.plantName} />
      <CardContent>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6} md={3}>
            Date Sampled:
          </Grid>
          <Grid item xs={6} md={3}>
            {moment(x.sampleDate).format('l')}
          </Grid>
          <Grid item xs={6} md={3}>
            Plant Name:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.machine.plant.plantName}
          </Grid>
          <Grid item xs={6} md={3}>
            Machine No:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.machine.machNo}
          </Grid>
          <Grid item xs={6} md={3}>
            Machine Name:
          </Grid>
          <Grid item xs={6} md={3}>
            <Link
              to={`/dart/data?labID=${x.labID}`}
              target="_blank"
              rel="noreferrer"
            >
              {x.machine.machineName}
            </Link>
          </Grid>
          <Grid item xs={6} md={3}>
            Equipment ID:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.machine.custEquID}
          </Grid>
          <Grid item xs={6} md={3}>
            Mach:
          </Grid>
          <Grid item xs={6} md={3}>
            <ConditionElement noMargin={true} condition={x.machCond} />
          </Grid>
          <Grid item xs={6} md={3}>
            Lube:
          </Grid>
          <Grid item xs={6} md={3}>
            <ConditionElement noMargin={true} condition={x.lubCond} />
          </Grid>
          <Grid item xs={6} md={3}>
            Lab No:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.batchID > 0 ? (
              <Grid direction="row" container style={{ width: '80px' }}>
                <Grid item xs={8}>
                  <FileLink
                    custID={x.machine.plant.customer.custID}
                    fileName={`${x.labID}.pdf`}
                  >
                    {x.labID}
                  </FileLink>
                </Grid>
                <Grid item xs={4}>
                  <FileLink
                    custID={x.machine.plant.customer.custID}
                    fileName={`${x.labID}.pdf`}
                    download={true}
                  >
                    <GetAppIcon fontSize="small" />
                  </FileLink>
                </Grid>
              </Grid>
            ) : (
              x.labID
            )}
          </Grid>
          <Grid item xs={6} md={3}>
            Machine Type:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.machine.machType}
          </Grid>
          <Grid item xs={6} md={3}>
            Lube Type:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.machine.lubricant?.lubricantName}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default MobileCard
