import React from 'react'
import { PageBody, PageCard } from '../Pages/PageContainers'
import * as PageStyles from '../page-styles.module.css'
import Button from '@material-ui/core/Button'
import { ForbiddenCardModule } from '../CardModules/ForbiddenCardModule'
import { useIsLoggedIn } from 'hooks/useIsLoggedIn'

export const BottleForbidden = () => {
  const isLoggedIn = useIsLoggedIn()

  return (
    <PageCard>
      <div
        className={`${PageStyles.EntryScreen}  ${PageStyles.WithBottleButton} ${
          isLoggedIn.loggedIn ? PageStyles.LoggedIn : ''
        }`}
      >
        <ForbiddenCardModule />
      </div>
    </PageCard>
  )
}
