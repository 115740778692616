import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import GetAppIcon from '@material-ui/icons/GetApp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import moment from 'moment'

import FileLink from 'components/FileLink'
import { reportTypes } from 'services/lookup-service'
import getMachineTypeFromOptions from 'utils/getMachineTypeFromOptions'

import ConditionElement from '../../ConditionElement'

import DetailReportRow from './DetailReportRow'
import { ResettableCheckbox } from 'components/ResettableCheckbox'

interface OwnProps {
  readOnly: boolean
  sample: any
  correctiveActions: any[]
  onCorrectiveActionSave: any
  onFeedbackOpen: any
  onCorrectiveActionDelete: any
  machineTypeOptions: any[]
  showDivisions: boolean
}

export const ReportCardRow = forwardRef<TSettableCheckbox, OwnProps>(
  (
    {
      readOnly,
      sample,
      machineTypeOptions,
      correctiveActions,
      onFeedbackOpen,
      onCorrectiveActionSave,
      onCorrectiveActionDelete,
      showDivisions,
    }: OwnProps,
    ref
  ) => {
    const [open, setOpen] = useState(false)

    const renderCheckbox = labID => {
      return (
        <td>
          <ResettableCheckbox ref={ref} />
        </td>
      )
    }

    return (
      <Card
        key={sample.labID}
        variant="outlined"
        style={{ marginBottom: '10px', marginTop: '20px' }}
      >
        <CardHeader title={sample.machine.plant.plantName} />
        <CardContent>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={6} md={3}>
              <FormControlLabel
                control={renderCheckbox(sample.labID)}
                label="Selected"
              />
            </Grid>
            <Grid container justify="flex-end" xs={6} md={9}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Grid>
            {open && (
              <Grid item xs={12} md={12}>
                <DetailReportRow
                  sample={sample}
                  correctiveActions={correctiveActions}
                  onFeedbackOpen={onFeedbackOpen}
                  onCorrectiveActionDelete={onCorrectiveActionDelete}
                  onCorrectiveActionSave={onCorrectiveActionSave}
                  readonly={readOnly}
                />
              </Grid>
            )}

            <Grid item xs={6} md={3}>
              Customer reviewed:
            </Grid>
            <Grid item xs={6} md={9}>
              {sample.reviewed ? <CheckIcon /> : ''}
            </Grid>
            <Grid item xs={6} md={3}>
              Date Sampled:
            </Grid>
            <Grid item xs={6} md={3}>
              {moment(sample.sampleDate).format('l')}
            </Grid>
            <Grid item xs={6} md={3}>
              Report Date:
            </Grid>
            <Grid item xs={6} md={3}>
              {moment(sample.reportDate).format('l')}
            </Grid>
            {showDivisions && (
              <Grid item xs={6} md={3}>
                Division Name:
              </Grid>
            )}
            {showDivisions && (
              <Grid item xs={6} md={3}>
                {sample.machine.plant?.customer?.division?.name}
              </Grid>
            )}
            <Grid item xs={6} md={3}>
              Customer Name:
            </Grid>
            <Grid item xs={6} md={3}>
              {sample.machine.plant.customer.custName}
            </Grid>
            <Grid item xs={6} md={3}>
              Plant Name:
            </Grid>
            <Grid item xs={6} md={3}>
              {sample.machine.plant.plantName}
            </Grid>
            <Grid item xs={6} md={3}>
              Route:
            </Grid>
            <Grid item xs={6} md={3}>
              {sample.machine.route &&
                `${sample.machine.route.routeNo} - ${sample.machine.route.name}`}
            </Grid>
            <Grid item xs={6} md={3}>
              Machine No:
            </Grid>
            <Grid item xs={6} md={3}>
              {sample.machine.machNo}
            </Grid>
            <Grid item xs={6} md={3}>
              Machine Name:
            </Grid>
            <Grid item xs={6} md={3}>
              <Link
                to={`/dart/data?labID=${sample.labID}`}
                target="_blank"
                rel="noreferrer"
              >
                {sample.machine.machineName}
              </Link>
            </Grid>
            <Grid item xs={6} md={3}>
              Equipment ID:
            </Grid>
            <Grid item xs={6} md={3}>
              {sample.machine.custEquID}
            </Grid>
            <Grid item xs={6} md={3}>
              Machine Type:
            </Grid>
            <Grid item xs={6} md={3}>
              {getMachineTypeFromOptions({
                machineType: sample.machine.machType,
                machineTypeOptions,
              })}
            </Grid>
            <Grid item xs={6} md={3}>
              Lube Type:
            </Grid>
            <Grid item xs={6} md={3}>
              {sample.machine.lubricant?.lubricantName}
            </Grid>
            <Grid item xs={6} md={3}>
              Lab No:
            </Grid>
            <Grid item xs={6} md={3}>
              {sample.batchID > 0 ? (
                <Grid direction="row" container style={{ width: '80px' }}>
                  <Grid item xs={8}>
                    <FileLink
                      custID={sample.machine.plant.customer.custID}
                      fileName={`${sample.labID}.pdf`}
                    >
                      {sample.labID}
                    </FileLink>
                  </Grid>
                  <Grid item xs={4}>
                    <FileLink
                      custID={sample.machine.plant.customer.custID}
                      fileName={`${sample.labID}.pdf`}
                      download={true}
                    >
                      <GetAppIcon fontSize="small" />
                    </FileLink>
                  </Grid>
                </Grid>
              ) : (
                sample.labID
              )}
            </Grid>
            <Grid item xs={6} md={3}>
              Report Type:
            </Grid>
            <Grid item xs={6} md={3}>
              {sample.reportType !== null
                ? reportTypes.find(y => y.value === sample.reportType).text
                : ''}
            </Grid>
            <Grid item xs={6} md={3}>
              Mach:
            </Grid>
            <Grid item xs={6} md={3}>
              <ConditionElement nomargin={true} condition={sample.machCond} />
            </Grid>
            <Grid item xs={6} md={3}>
              Lube:
            </Grid>
            <Grid item xs={6} md={3}>
              <ConditionElement nomargin={true} condition={sample.lubCond} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }
)
