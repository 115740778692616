import React from 'react'
import { Button } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

import coreTheme from '../theme'

const theme = createTheme(
  {
    overrides: {
      MuiButton: {
        root: {
          [coreTheme.breakpoints.down('sm')]: {
            width: '150px',
            alignSelf: 'flex-end',
          },
          [coreTheme.breakpoints.up('sm')]: {
            width: '150px',
          },
        },
      },
    },
  },
  coreTheme
)

type TProps = {
  onClick: () => void
  disabled?: boolean
}

const PayInvoicesButton: React.FC<TProps> = ({
  children,
  onClick,
  disabled,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Button
        onClick={onClick}
        variant="contained"
        color="secondary"
        disabled={disabled}
      >
        {children}
      </Button>
    </ThemeProvider>
  )
}

export default PayInvoicesButton
