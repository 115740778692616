import { Button, Grid, makeStyles, Modal } from '@material-ui/core'
import CreatePurchaseOrderModal from 'components/CreatePurchaseOrderModal'
import { PaymentStatusEnum } from 'components/Financials/StripeJS/InvoiceList'
import { sendErrorMessageToSentry } from 'components/Providers/SentryProvider'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CancelIcon from '@material-ui/icons/Cancel'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import supplies from 'pages/program-management/supplies'
import React, { useState } from 'react'
import APIService from 'services/api-service'
import { TPurchaseOrder } from 'types/api'
type TModalProps = {
  open: boolean
  onClose: () => void
  onSubmitSuccess: () => void
}

function getModalStyle() {
  const top = 50
  const left = 50
  const display = 'flex'

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const defaultErrorMessage =
  'Unexpected error while processing purchase order creation.'
export const CreateNewPOModal = ({
  open,
  onClose,
  onSubmitSuccess,
}: TModalProps) => {
  const [modalSubmitting, setModalSubmitting] = React.useState(false)
  const [modalError, setModalError] = React.useState(null)
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatusEnum>(
    PaymentStatusEnum.Unpaid
  )

  const handleModalClose = () => {
    if (modalSubmitting) return

    setPaymentStatus(PaymentStatusEnum.Unpaid)
    setModalError(null)
    setModalSubmitting(false)
    onClose()
  }

  const handlePurchaseOrderPayment = async (purchaseOrder: TPurchaseOrder) => {
    setModalSubmitting(true)
    setPaymentStatus(PaymentStatusEnum.Unpaid)

    try {
      await APIService.postPurchaseOrder(purchaseOrder)
      setPaymentStatus(PaymentStatusEnum.Done)
      onSubmitSuccess()
    } catch (err) {
      setModalError(err?.message ?? err ?? defaultErrorMessage)
      setPaymentStatus(PaymentStatusEnum.Error)

      sendErrorMessageToSentry(
        `${defaultErrorMessage}\nError: ${JSON.stringify(err)}`
      )
    } finally {
      setModalSubmitting(false)
    }
  }

  const useStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: '80vw',
      overflowY: 'auto',
      height: '80%',
      backgroundColor: '#fff',
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '25px',
    },
  }))

  const modalClasses = useStyles()
  const modalStyle = getModalStyle()

  const errorPage = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <HighlightOffIcon
        style={{ color: 'rgb(225, 25, 25)', fontSize: '350px' }}
      />
      <p>Something went wrong!</p>
      <p>Please try again later or contact customer service to proceed.</p>
      <Button
        variant="contained"
        component="label"
        color="primary"
        style={{ height: '50px', width: '300px', marginBottom: '50px' }}
        onClick={handleModalClose}
      >
        Close
      </Button>
    </div>
  )

  const successPage = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <CheckCircleOutlineIcon
        style={{ color: 'rgb(25, 225, 25)', fontSize: '350px' }}
      />
      <p style={{ fontWeight: 'bold' }}>
        Purchase order submitted successfully!
      </p>
      <Button
        variant="contained"
        component="label"
        color="primary"
        style={{ height: '50px', width: '300px', marginBottom: '50px' }}
        onClick={handleModalClose}
      >
        Close
      </Button>
      <p style={{ width: 'clamp(306px, 41%, 100%)', textAlign: 'justify' }}>
        The purchase order has been submitted and it has been allocated a status
        of "pending." Please allow a full business day for our accounting team
        to verify the information provided.
      </p>
    </div>
  )

  const paymentPage = (
    <CreatePurchaseOrderModal
      canChooseDates={true}
      handlePurchaseOrderSubmitted={handlePurchaseOrderPayment}
      handleModalClose={handleModalClose}
    />
  )

  return (
    <Modal open={open} onClose={handleModalClose}>
      <Grid
        container
        style={modalStyle}
        className={modalClasses.paper}
        justifyContent="center"
        alignItems="flex-start"
      >
        {paymentStatus === PaymentStatusEnum.Unpaid
          ? paymentPage
          : paymentStatus === PaymentStatusEnum.Error
          ? errorPage
          : successPage}
      </Grid>
    </Modal>
  )
}
