import React from 'react'

import { TAPILubricantManufacturer } from 'types/api'

import { VirtualSelectFilter } from '../BaseFilters/VirtualSelectFilter'
import FilterTooltip from '../FilterTooltip/FilterTooltip'

type TProps = {
  single: boolean
  required: boolean
  lubeMfrs: TAPILubricantManufacturer[]
  onChange?: (
    _prop: TAPILubricantManufacturer | TAPILubricantManufacturer[]
  ) => void
  setState?: (_props: any) => void
  label?: string
  value: string
  tooltip?: boolean
  disabled?: boolean
}

export const LubeManufacturerFilter = ({
  lubeMfrs,
  setState,
  onChange,
  single,
  required,
  label,
  value,
  disabled,
  tooltip = true,
}: TProps) => {
  if (setState == null && onChange == null) return
  const handleChange = (
    manufacturer: TAPILubricantManufacturer | TAPILubricantManufacturer[]
  ) => {
    if (onChange != null) {
      if (single === true) {
        onChange(manufacturer as TAPILubricantManufacturer)
      } else {
        onChange(manufacturer as TAPILubricantManufacturer[])
      }
    } else {
      if (single === true) {
        setState({
          selectedLubeMfg: (manufacturer as TAPILubricantManufacturer)
            ?.pickListValue,
        })
      } else {
        setState({
          selectedLubeMfgs: (manufacturer as TAPILubricantManufacturer[]).map(
            p => p?.pickListValue
          ),
        })
      }
    }
  }
  const getValue = (
    val:
      | string
      | string[]
      | TAPILubricantManufacturer
      | TAPILubricantManufacturer[]
  ) => {
    if (typeof val === 'string') {
      const lube =
        lubeMfrs.find(x => x.pickListValue === (val as string)) ?? undefined
      return {
        lubemfr: lube?.lubemfr,
        pickListValue: lube?.pickListValue,
      } as TAPILubricantManufacturer
    } else if (Array.isArray(val)) {
      const lubes = lubeMfrs.filter(x =>
        (val as string[]).includes(x.pickListValue)
      )
      return lubes
    } else {
      return val
    }
  }

  const filter = (
    <VirtualSelectFilter
      label={label ?? 'Lube Manufacturers'}
      htmlFor="lubeMfrs"
      multiple={!single}
      required={required}
      disabled={disabled}
      id="lubeMfrs"
      value={getValue(value)}
      getOptionSelected={(option, value) => option?.lubemfr === value.lubemfr}
      renderValue={(selected: TAPILubricantManufacturer) =>
        lubeMfrs.find(x => x.lubemfr === selected?.lubemfr)?.lubemfr ?? ''
      }
      onChange={handleChange}
      options={lubeMfrs}
    />
  )

  return tooltip ? (
    <FilterTooltip title="The manufacturer of a fluid utilized by a machine.">
      {filter}
    </FilterTooltip>
  ) : (
    filter
  )
}
