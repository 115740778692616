import { ScoreCard } from 'components/ScoreCard'
import React from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import {
  OrderType,
  TPurchaseOrderInformation,
  TSpendingEntity,
} from 'types/api.d'
import { useThemeContext } from 'Contexts/ThemeContext'
import { StringFormatter } from 'utils/string-utilities'

import * as Styles from '../index.module.css'
import { getRepresentativeOrderType } from 'utils/financial-utilities'

type TAvailableBudgetCardProps = {
  spendingData: TSpendingEntity[]
}

export const AvailableBudgetCard: React.FC<TAvailableBudgetCardProps> =
  props => {
    const theme = useThemeContext()
    const representiativeOrderType = getRepresentativeOrderType(
      props.spendingData
    )
    const budgetIsNotApplicable = representiativeOrderType === OrderType.Unknown
    const dollarBalanced = representiativeOrderType === OrderType.DollarBalance
    const sampleBalanced = representiativeOrderType === OrderType.Sample

    const now = new Date()
    const beginningOfMonth = new Date(now.getFullYear(), now.getMonth(), 1)
    const beginningOfNextMonth = new Date(
      now.getFullYear(),
      now.getMonth() + 1,
      1
    )

    let availableBudget = 0
    let changeSinceLastMonth = 0
    let changeNextMonth = 0

    if (!budgetIsNotApplicable) {
      const uniquePOs: Map<string, TPurchaseOrderInformation> = new Map()

      for (let po of props.spendingData.flatMap(
        datum => datum.purchaseOrders
      )) {
        if (!uniquePOs.has(po.poNumber)) {
          uniquePOs.set(po.poNumber, po)
        }
      }

      uniquePOs.forEach(po => {
        const poRemainingBudget = po.amount - po.spent
        const createdDate = new Date(po.created)
        const expirationDate = new Date(po.expirationDate)

        if (createdDate >= now || expirationDate <= now) return

        availableBudget += poRemainingBudget
        if (createdDate >= beginningOfMonth) {
          changeSinceLastMonth += poRemainingBudget
        }
        if (expirationDate <= beginningOfMonth) {
          changeSinceLastMonth -= poRemainingBudget
        }
        if (createdDate >= beginningOfNextMonth) {
          changeNextMonth += poRemainingBudget
        }
        if (expirationDate <= beginningOfNextMonth) {
          changeNextMonth -= poRemainingBudget
        }
      })
    }

    const getChangeIcon = (change: number) => {
      return change > 0 ? (
        <ArrowUpwardIcon style={{ color: theme.palette.charts.withinBudget }} />
      ) : change < 0 ? (
        <ArrowDownwardIcon style={{ color: theme.palette.charts.overBudget }} />
      ) : (
        <DragHandleIcon style={{ color: theme.palette.charts.budget }} />
      )
    }

    const changeSinceLastMonthIcon = budgetIsNotApplicable
      ? getChangeIcon(0)
      : getChangeIcon(changeSinceLastMonth)
    const changeNextMonthIcon = budgetIsNotApplicable
      ? getChangeIcon(0)
      : getChangeIcon(changeNextMonth)
    const title = `Available Budget as of ${now.toLocaleDateString()}`

    const helpTooltipText =
      'The available budget at the present date. Budget is only applicable to customers that use a single order type (dollar balanced or sample balanced). Customers with only free issue purchase orders, utilizing blanket orders, or with otherwise unknown purchase order statuses do not have an applicable budget. Customers utilizing free issue purchase orders in-part will see an asterisk (*) displayed next to the budget. Budgets marked with an asterisk are not necessarily constraints for in-budget spending and should be considered to be estimates of available budget. Budgets with asterisks may be modified retroactively.' +
      ` Please note that because charges are independent of business units, such as plants or customers,` +
      ` this amount may encompass the budget for multiple business units and may not reflect units selected in the filters.`
    return (
      <ScoreCard
        toolTip={{
          title: title,
          text: helpTooltipText,
        }}
      >
        <div className={Styles.Header}>
          <h1>
            <strong>
              {budgetIsNotApplicable
                ? 'N/A'
                : representiativeOrderType === OrderType.DollarBalance ||
                  representiativeOrderType === OrderType.FreeIssue
                ? `${StringFormatter.formatAsUSD(availableBudget)}${
                    representiativeOrderType === OrderType.FreeIssue ? '*' : ''
                  }`
                : representiativeOrderType === OrderType.Sample
                ? `${Math.round(availableBudget).toFixed(0)} Samples`
                : 'N/A'}
            </strong>
          </h1>
          <h6>
            <strong>{title}</strong>
          </h6>
        </div>
        <div style={{ width: '100%' }}>
          <div className={Styles.DifferenceContainer}>
            <span>{changeSinceLastMonthIcon} Change since last month:</span>
            <span>
              {budgetIsNotApplicable
                ? 'N/A'
                : dollarBalanced
                ? StringFormatter.formatAsUSD(changeSinceLastMonth)
                : sampleBalanced
                ? `${Math.round(changeSinceLastMonth ?? 0).toFixed(0)} Samples`
                : 'N/A'}
            </span>
          </div>
          <div className={Styles.DifferenceContainer}>
            <span>{changeNextMonthIcon} Change next month:</span>
            <span>
              {budgetIsNotApplicable
                ? 'N/A'
                : dollarBalanced
                ? StringFormatter.formatAsUSD(changeNextMonth)
                : sampleBalanced
                ? `${Math.round(changeNextMonth ?? 0).toFixed(0)} Samples`
                : 'N/A'}
            </span>
          </div>
        </div>
      </ScoreCard>
    )
  }
