import moment from 'moment'

import { TAPIDelinquentSample } from 'types/api'

export const tableFormatter = (
  data: TAPIDelinquentSample[],
  hasDivisions: boolean
): string[][] => {
  const headerValues = [
    ...(hasDivisions ? ['Division Name'] : []),
    'Customer Name',
    'Plant Name',
    'Route Name',
    'Machine Number',
    'Machine Name',
    'Equipment ID',
    'Date Last Sampled',
    'Days Since Last Sample',
  ]

  const formattedData = data.map(datum => [
    ...(hasDivisions ? [datum.machine.plant.customer.division?.name] : []),
    datum.machine.plant.customer.custName,
    datum.machine.plant.plantName,
    datum.machine.route !== null
      ? `${datum.machine.route.routeNo} - ${datum.machine.route.name}`
      : '',
    datum.machine.machNo,
    datum.machine.machineName,
    datum.machine.custEquID,
    moment(datum.lastSampleDate).format('l'),
    datum.daysSinceLastSample?.toString(),
  ])

  return [headerValues, ...formattedData]
}
