import React from 'react'
import { Box, Hidden, TablePagination } from '@material-ui/core'
import {
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'

import TableGrid from 'components/TableGrid'

import {
  RenderDate,
  RenderLabNo,
  RenderLubeCondition,
  RenderMachineCondition,
  RenderMachineName,
  RenderMachineType,
} from '../KendoUIGridRenders'

import MobileCard from './MobileCard'
import { TMachineTypeList } from 'utils/getMachineTypeFromOptions'

export const headerValues = [
  'Last Sample',
  'Division Name',
  'Customer Name',
  'Plant Name',
  'Route Name',
  'Machine Number',
  'Machine Name',
  'Equipment ID',
  'Machine Condition',
  'Lube Condition',
  'Problems',
  'Lab No.',
  'Reviewed',
  'Reviewed Date',
  'Machine Type',
  'Lube Type',
]

export type TProps = {
  samples: any[]
  offline?: boolean
  orderConfig: any[]
  page: number
  pageSize: number
  count: number
  onPageChange?: (_event: GridPageChangeEvent) => void
  onSortChange?: (_event: GridSortChangeEvent) => void
  onPageChangeMobile: (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    _page: number
  ) => void
  onPageSizeChangeMobile?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >
  machineTypeOptions: TMachineTypeList[]
}

export const LastSamplesTable: React.FC<TProps> = ({
  samples,
  offline,
  orderConfig,
  page,
  pageSize,
  count,
  onPageChange,
  onSortChange,
  onPageChangeMobile,
  onPageSizeChangeMobile,
  machineTypeOptions
}: TProps) => {
  const showDivisions = samples.some(d => d.machine.plant.customer.division)

  return (
    <>
      {offline && (
        <Box mb={2}>Your last 100 reports are available while offline.</Box>
      )}
      <Hidden mdUp>
        {!samples?.length && (
          <p style={{ textAlign: 'center' }}>No records available</p>
        )}
        {samples.map(sample => (
          <MobileCard
            key={sample.labID}
            sample={sample}
            showDivisions={showDivisions}
          />
        ))}

        {!offline ? (
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100, 250, 500]}
            component="div"
            count={count}
            rowsPerPage={pageSize}
            page={page > 0 ? page - 1 : page}
            onPageChange={onPageChangeMobile}
            onRowsPerPageChange={onPageSizeChangeMobile}
          />
        ) : (
          ''
        )}
      </Hidden>

      <Hidden smDown>
        <TableGrid
          clearSortingButtonId="last_samples-clear_sorting_button"
          offline={offline}
          data={samples}
          sort={orderConfig}
          skip={(page - 1) * pageSize}
          take={pageSize}
          total={count}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
        >
          <Column
            field="sampleDate"
            title="Sample Date"
            cell={props => <RenderDate {...props} />}
          />
          {showDivisions && (
            <Column
              field="machine.plant.customer.division.name"
              title="Division"
            />
          )}
          <Column
            field="machine.plant.customer.custName"
            title="Customer Name"
          />
          <Column field="machine.plant.plantName" title="Plant Name" />
          <Column
            field="machine.route.routeNo"
            title="Route"
            cell={({ dataItem: sample }) => (
              <td>
                {sample.machine.route &&
                  `${sample.machine.route?.routeNo} - ${sample.machine.route?.name}`}
              </td>
            )}
          />
          <Column field="machine.machNo" title="Mach No." />
          <Column
            field="machine.machineName"
            title="Machine Name"
            cell={props => <RenderMachineName {...props} />}
          />
          <Column field="machine.custEquID" title="Equipment ID" />
          <Column
            field="machCond"
            title="Mach"
            cell={props => <RenderMachineCondition {...props} />}
          />
          <Column
            field="lubCond"
            title="Lube"
            cell={props => <RenderLubeCondition {...props} />}
          />
          <Column field="problems" title="Problems" />
          <Column
            field="labID"
            title="Lab No."
            cell={props => <RenderLabNo {...props} />}
          />
          <Column
            field="reviewed"
            title="Reviewed"
            cell={props => <td>{props.dataItem.reviewed ? 'Yes' : 'No'}</td>}
          />
          <Column
            field="custReviewDte"
            title="Reviewed Date"
            cell={props => <RenderDate {...props} />}
          />
          <Column
            field="machine.machType"
            title="Machine Type"
            cell={props => (
              <RenderMachineType
                {...props}
                machineTypeOptions={machineTypeOptions}
              />
            )}
          />          
          <Column field="machine.lubricant.lubricantName" title="Lube Type" />
        </TableGrid>
      </Hidden>
    </>
  )
}
