import React, { useCallback, useEffect, useState } from 'react'
import { Button, Hidden, TablePagination } from '@material-ui/core'
import { GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid'

import { Form, Field, FieldRenderProps, FieldValidatorType } from "@progress/kendo-react-form";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";

import { GridEditContext } from 'Contexts/GridEditContext';
import { FormSubmitContext } from 'Contexts/FormSubmitContext';
import { DropDownList } from '@progress/kendo-react-dropdowns';

const FORM_DATA_INDEX = "formDataIndex";

interface DropDownProps {
    data: any[], 
    textField: string, 
    dataItemKey: string,
}

const rowRender = (row, props) => {
    return <GridInlineFormRow dataItem={props.dataItem}>{row}</GridInlineFormRow>;
  };

const DropDownListWithValidation = (fieldRenderProps: FieldRenderProps & DropDownProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
      <div>
        <DropDownList  {...others} />
        {visited && validationMessage && (
          <div role="alert" className="k-form-error k-text-start">
            {validationMessage}
          </div>
        )}
      </div>
    );
  };

const TextInputWithValidation = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {visited && validationMessage && (
        <div role="alert" className="k-form-error k-text-start">
          {validationMessage}
        </div>
      )}
    </div>
  );
};

const NumericInputWithValidation = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
       <NumericTextBox
          {...others}
        />
      {visited && validationMessage && (
        <div role="alert" className="k-form-error k-text-start">
          {validationMessage}
        </div>
      )}
    </div>
  );
};

const GridInlineFormRow = (props: { children: any; dataItem: any }) => {
  const { onRowAction, editIndex } = React.useContext(GridEditContext);
  const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;

  const onSubmit = React.useCallback(
    (e) => {
      onRowAction({ rowIndex: editIndex!, operation: "save", dataItem: e });
    },
    [onRowAction, editIndex]
  );

  if (isInEdit) {
    return (
      <Form
        key={JSON.stringify(props.dataItem)}
        initialValues={props.dataItem}
        onSubmit={onSubmit}
        render={(formRenderProps) => {
          return (
            <FormSubmitContext.Provider value={formRenderProps.onSubmit}>
              {props.children}
            </FormSubmitContext.Provider>
          );
        }}
      />
    );
  }

  return props.children;
};

const DropDownListCell = (props: GridCellProps & DropDownProps & {validators: FieldValidatorType | FieldValidatorType[]}) => {
    const { editIndex } = React.useContext(GridEditContext);
    const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
    const { data, dataItemKey, textField } = props

    return (
      <td>
        {isInEdit ? (
          <Field
            component={props => DropDownListWithValidation({...props, data, dataItemKey, textField})}
            name={`${props.field}`}
            validator={props.validators}
          />
        ) : (
          props.dataItem[props.field] ? props.dataItem[props.field][props.textField || ""] : ''
        )}
      </td>
    );
  };

const TextCell = (props: GridCellProps & {validators: FieldValidatorType | FieldValidatorType[]}) => {
  const { editIndex } = React.useContext(GridEditContext);
  const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;

  return (
    <td>
      {isInEdit ? (
        <Field
          component={props => TextInputWithValidation({...props, maxlength: 3})}
          name={`${props.field}`}
          validator={props.validators}
        />
      ) : (
        props.dataItem[props.field || ""]
      )}
    </td>
  );
};

const NumberCell = (props: GridCellProps & {validators: FieldValidatorType | FieldValidatorType[]}) => {
  const { editIndex } = React.useContext(GridEditContext);
  const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;

  return (
    <td>
      {isInEdit ? (
        <Field
          component={props => NumericInputWithValidation({...props, format: "#", min: 1, restrictDecimals: true})}
          name={`${props.field}`}
          validator={props.validators}
        />
      ) : (
        props.dataItem[props.field || ""]
      )}
    </td>
  );
};

const CurrencyCell = (props: GridCellProps & {validators: FieldValidatorType | FieldValidatorType[]}) => {
  const { editIndex } = React.useContext(GridEditContext);
  const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;

  return (
    <td>
      {isInEdit ? (
        <Field
          component={prev => NumericInputWithValidation({...prev, format: "c2"})}
          name={`${props.field}`}
          validator={props.validators}
        />
      ) : (
        props.dataItem[props.field || ""]
      )}
    </td>
  );
};

const CommandCell = (props: GridCellProps) => {
  const onSubmit = React.useContext(FormSubmitContext);
  const { onRowAction, setEditIndex, editIndex, dataItemKey } =
    React.useContext(GridEditContext);

  const rowIndex = props.dataItem[FORM_DATA_INDEX];
  const isInEdit = rowIndex === editIndex;
  const isNewItem = !props.dataItem[dataItemKey];

  const onRemoveClick = React.useCallback(
    (e) => {
      e.preventDefault();
      onRowAction({ rowIndex, operation: "remove" });
    },
    [rowIndex, onRowAction]
  );

  const onSaveClick = React.useCallback(
    (e) => {
      e.preventDefault();
      onSubmit(e);
    },
    [onSubmit]
  );

  const onEditClick = React.useCallback(
    (e) => {
      e.preventDefault();
      setEditIndex(rowIndex);
    },
    [rowIndex, setEditIndex]
  );

  const onCancelClick = React.useCallback(
    (e) => {
      e.preventDefault();
      setEditIndex(undefined);
    },
    [setEditIndex]
  );

  return isInEdit ? (
    <td className="k-command-cell">
        <Button
            variant="contained"
            color="secondary"
            onClick={onSaveClick}
            style={{marginRight: 5}}
        >
            {isNewItem ? "Add" : "Update"}
        </Button>      
        <Button
            variant="contained"
            color="primary"
            onClick={isNewItem ? onRemoveClick : onCancelClick}
        >
            {isNewItem ? "Discard" : "Cancel"}
        </Button>  
    </td>
  ) : (
    <td className="k-command-cell">
        <Button
            variant="contained"
            color="secondary"
            onClick={onEditClick}
            style={{marginRight: 5}}
        >
            Edit
        </Button>  

        <Button
            variant="contained"
            color="primary"
            onClick={onRemoveClick}
        >
            Remove
        </Button>                
    </td>
  );
};

export { GridInlineFormRow, TextCell, NumberCell, CurrencyCell, CommandCell, DropDownListCell, rowRender}