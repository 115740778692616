import React, { ReactNode } from 'react'
import { Grid } from '@material-ui/core'

import MobileCardContent from 'components/MobileCardContent'
import MobileCardItem from 'components/MobileCardItem'

interface Props {
  route: any
  showDivisions: boolean
  renderRouteNameMobile: ReactNode
}
const MobileCard: React.FC<Props> = ({
  route,
  showDivisions,
  renderRouteNameMobile,
}: Props) => {
  return (
    <MobileCardContent title={route.name}>
      <Grid container direction="row" spacing={1}>
        {showDivisions && (
          <MobileCardItem title="Division Name">
            {route.customer?.division?.name}
          </MobileCardItem>
        )}
        <MobileCardItem title="Customer Name">
          {route.customer?.custName}
        </MobileCardItem>
        <MobileCardItem title="Route Number">{route.routeNo}</MobileCardItem>
        <MobileCardItem title="Route Name">
          {renderRouteNameMobile}
        </MobileCardItem>
      </Grid>
    </MobileCardContent>
  )
}

export default MobileCard
