import React, { useCallback, useEffect, useState } from 'react'
import APIService from 'services/api-service'
import IssueBanner from '../IssueBanner'
import { FinancialIssuesModal } from 'components/Modals/FinancialIssuesModal/FinancialIssuesModal'
import {
  TAPICustomer,
  TAPIPlant,
  TFinancialAlert,
  TFinancialAlertsQuery,
} from '../../../../types/api.d'
import { CancellablePromise } from 'utils/CancellablePromise'

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]
interface OwnProps {
  customers: TAPICustomer[]
  plants: TAPIPlant[]
}
const FinancialAlertBanner: React.FC<OwnProps> = (props: OwnProps) => {
  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()

        promise.abortController?.abort()
      }
    }
  }, [])

  const [showAlertBanner, setShowAlertBanner] = useState(false)
  const [showFinancialAlertModal, setShowFinancialAlertModal] = useState(false)
  const [financialAlerts, setFinancialAlerts] = useState<TFinancialAlert[]>([])

  const fetchFinancialAlerts = useCallback(async () => {
    if (!props.customers?.length) return

    let promise

    try {
      promise = APIService.getFinancialAlerts({
        customerIDs: props.customers.map(c => c.custID),
        plantIDs: [],
      } as TFinancialAlertsQuery)

      ongoingCancellablePromises.push(promise)
      const response = await promise
      setShowAlertBanner(!!response?.length)
      setFinancialAlerts(response)
    } catch (error) {
      console.error()
    } finally {
      ongoingCancellablePromises = ongoingCancellablePromises.filter(
        p => p !== promise
      )
    }
  }, [props.customers, props.plants])

  useEffect(() => {
    fetchFinancialAlerts()
  }, [fetchFinancialAlerts])

  const handleFinancialAlertModalClose = () => setShowFinancialAlertModal(false)

  const handleFinancialAlertBannerClick = () => setShowFinancialAlertModal(true)

  const aresalesOrderIssues = financialAlerts.some(
    fa => fa.salesOrderIssues.length > 0
  )
  const areSalesInvoiceIssues = financialAlerts.some(
    fa => fa.salesInvoiceIssues.length > 0
  )

  const getTitle = () =>
    aresalesOrderIssues && areSalesInvoiceIssues
      ? 'Financial Alerts'
      : areSalesInvoiceIssues
      ? 'Sales Invoice Issues'
      : 'Sales Order Issues'

  const allIssues = (
    financialAlerts?.flatMap(fa =>
      fa.salesInvoiceIssues.map(sii => sii.issue)
    ) ?? []
  ).concat(
    financialAlerts?.flatMap(fa => fa.salesOrderIssues.map(soi => soi.issue)) ??
      []
  )
  const severity = allIssues.some(
    issue => issue == 'OverBudget' || issue == 'PastDue'
  )
    ? 'error'
    : 'warning'

  return (
    <IssueBanner
      onBannerClick={handleFinancialAlertBannerClick}
      severity={severity}
      title="Financial Alerts"
      showBanner={showAlertBanner}
    >
      <FinancialIssuesModal
        customers={props.customers}
        modalOpen={showFinancialAlertModal}
        onModalClose={handleFinancialAlertModalClose}
        financialAlerts={financialAlerts}
        title={getTitle()}
        message={
          'Please note the alerts displayed in the table below. If you are aware of the listed alerts, feel free to ignore this notice. To resolve these potential issues, please contact customer support.'
        }
      />
      <>
        <span style={{ display: 'block', marginBottom: '5px' }}>
          {severity == 'error'
            ? 'There are financial issues that require your review.'
            : 'There are financial warnings available for your review.'}
        </span>
        <a
          style={{
            cursor: 'pointer',
            color: severity == 'error' ? '#61fcff' : 'blue',
          }}
          onClick={handleFinancialAlertBannerClick}
        >
          Click here for more information.
        </a>
      </>
    </IssueBanner>
  )
}

export default FinancialAlertBanner
