export class CancellablePromise<T> extends Promise<T> {
  #_abortController: AbortController | null
  get abortController(): AbortController {
    if (this.#_abortController == null)
      this.#_abortController = new AbortController()
    return this.#_abortController
  }

  set abortController(controller: AbortController) {
    this.#_abortController = controller
  }

  constructor(
    executor: (
      resolve: (value?: T | PromiseLike<T>) => void,
      reject: (reason?: any) => void
    ) => void
  ) {
    super(executor)
  }
}
