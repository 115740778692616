import React, { useCallback, useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { Box, Grid, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import { useNavigate } from 'react-router-dom'
import LoginService from 'services/login-service'

import { TSalesInvoice, TSalesInvoiceItem } from 'types/api'
import AppLayout from 'components/AppLayout'
import PayInvoicesButton from 'components/buttons/PayInvoicesButton'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import SEO from 'components/SEO'
import SalesInvoices from 'components/tables/SalesInvoices'
import APIService, { TProfile } from 'services/api-service'
import { SalesInvoiceStatus } from 'utils/getSalesInvoiceStatusDescription'

import { useProfile } from 'Contexts/ProfileContext'
import {
  DateFilterSet,
  FinancialsFilterSet,
  InvoiceStatus,
} from 'components/FilterAccordion/types'
import FilterAccordion, {
  TAccordionFilterValues,
} from 'components/FilterAccordion'
import { CancellablePromise } from 'utils/CancellablePromise'
import InvoicesPaymentModal from 'components/Modals/InvoicesPaymentModal'

type TState = Partial<TProfile> &
  TAccordionFilterValues & {
    dataLoaded: boolean
    dataUpdated: boolean
    data: TSalesInvoice
    selectedDivisionIDs: number[]
    selectedCustomerIDs: number[]
    invoices: TSalesInvoiceItem[]
    paymentInvoices: any[]
    selectedInvoices: TSalesInvoiceItem[]
    selectedUnpaid: number
    startDate: Date
    endDate: Date
    invoiceStatuses: InvoiceStatus
  }

interface TProps {
  onError?: (_err: Error) => void // passed in from layout wrapper
  offline?: boolean
}

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

const InvoicesFinancials: React.FC<TProps> = ({ onError, offline }: TProps) => {
  const profileContext = useProfile()

  const defaults = {
    ...profileContext.dependentData.filters.defaultValues,
    dataLoaded: false,
    dataUpdated: false,
    data: {
      currentUnpaid: 0,
      isDivision: false,
      isMultiCustomer: false,
      isPastDue: false,
      invoices: [],
    },
    selectedDivisionIDs: [],
    selectedCustomerIDs: [],
    invoices: [],
    selectedInvoices: [],
    paymentInvoices: [],
    selectedUnpaid: 0,
    startDate: null,
    endDate: null,
  }

  const [state, setState] = useState<Partial<TState>>({
    ...defaults,
  })
  const navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      const loggedIn = await LoginService.isLoggedIn()
      if (!loggedIn) {
        if (typeof window !== 'undefined') {
          const postLogin = `PostLoginURL=${encodeURIComponent(
            `/Financials/Invoices${window.location.search}`
          )}`
          navigate(`/login?${postLogin}`)
        }
      }
    })()
  }, [])

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  useEffect(() => {
    if (!state.dataLoaded && profileContext.minimumProfileLoaded) {
      fetchData()
    } else {
      if (typeof window !== 'undefined') {
        const urlParams = new URLSearchParams(window.location.search)
        const invoiceNumbers = urlParams.getAll('n')

        if (invoiceNumbers.some(invoice => invoice)) {
          const invoices = state.invoices.filter(
            invoice =>
              invoiceNumbers.some(
                invoiceNumber => invoiceNumber === invoice.invoiceNumber
              ) && invoice.status !== SalesInvoiceStatus.Paid
          )
          if (invoices.some(invoice => invoice))
            setState(prev => ({
              ...prev,
              paymentInvoices: invoices,
            }))
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dataLoaded, profileContext.minimumProfileLoaded])

  useEffect(() => {
    if (!state.dataUpdated) {
      updateData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dataUpdated])

  const updateData = () => {
    setState(prev => ({
      ...prev,
      selectedUnpaid: 0,
      selectedInvoices: [],
      invoices: state.data.invoices.filter(
        x =>
          (state.selectedCustomerIDs.some(z => z)
            ? state.selectedCustomerIDs.some(y => x.customerIDs?.includes(y))
            : true) &&
          (state.selectedDivisionIDs.some(z => z)
            ? state.selectedDivisionIDs.some(y => y === x.divisionID)
            : true) &&
          (state.invoiceNumber
            ? x.invoiceNumber === state.invoiceNumber
            : true) &&
          (state.purchaseOrderNumber
            ? x.customerPONumber === state.purchaseOrderNumber
            : true) &&
          x.amount > 0
      ),
      dataUpdated: true,
    }))
  }

  const fetchData = useCallback(async () => {
    try {
      const query = {
        divisionIDs: profileContext.profile.divisions.map(x => x.iD),
        customerIDs: profileContext.profile.customers.map(x => x.custID),
        createdStartDate: state.startDate?.toISOString(),
        createdEndDate: state.endDate?.toISOString(),
        invoiceNumbers: state.invoiceNumber,
        customerPONumber: state.purchaseOrderNumber,
        statuses: state.invoiceStatus ? [state.invoiceStatus] : undefined,
      }
      const promise = APIService.getSalesInvoices(query)

      ongoingCancellablePromises.push(promise)
      const response = await promise

      ongoingCancellablePromises.filter(p => p != promise)

      const completeResponse = response
        ? {
            ...response,
            invoices: response.invoices.map(x => ({
              ...x,
              customerName: profileContext.profile.customers.find(y =>
                x.customerIDs?.includes(y.custID)
              )?.custName,
              divisionName: profileContext.profile.divisions.some(x => x)
                ? profileContext.profile.divisions.find(y =>
                    y.customers.some(z => x.customerIDs?.includes(z.custID))
                  )?.name
                : null,
              divisionID: profileContext.profile.divisions.some(x => x)
                ? profileContext.profile.divisions.find(y =>
                    y.customers.some(z => x.customerIDs?.includes(z.custID))
                  )?.iD
                : null,
            })),
            isDivision: profileContext.profile.divisions.some(x => x),
            isMultiCustomer: profileContext.profile.customers.length > 1,
          }
        : { ...response, invoices: [] }

      setState(prev => ({
        ...prev,
        dataLoaded: true,
        data: completeResponse,
        invoices: completeResponse.invoices,
        dataUpdated: false,
        selectedInvoicesForPayment: [],
      }))
    } catch (error) {
      onError(error)
      console.error()
    } finally {
      setState(prev => ({ ...prev, dataLoaded: true }))
    }
  }, [
    onError,
    profileContext.profile.customers,
    profileContext.profile.divisions,
    state.startDate,
    state.endDate,
    state.dataLoaded,
  ])

  const handleSelectInvoice = (
    invoice: TSalesInvoiceItem,
    checked: boolean
  ) => {
    let newSelectedInvoices = state.selectedInvoices

    if (checked) {
      newSelectedInvoices.push(invoice)
    } else {
      newSelectedInvoices = state.selectedInvoices.filter(
        x => x.invoiceNumber !== invoice.invoiceNumber
      )
    }

    setState(prev => ({
      ...prev,
      selectedInvoices: newSelectedInvoices,
    }))
  }

  const handleViewReceiptClick = async (invoiceNumber: string) => {
    const token = await LoginService.getStoredAccessToken()

    window.open(
      `${process.env.REACT_APP_API_URL}/receipts/${invoiceNumber}?authToken=${token}&download=false`
    )
  }

  const handleInvoiceClick = async (invoiceNumber: string) => {
    const token = await LoginService.getStoredAccessToken()

    window.open(
      `${process.env.REACT_APP_API_URL}/invoices/${invoiceNumber}?authToken=${token}&download=true`,
      '_self'
    )
  }

  let selectedInvoiceCounter = 0
  state.selectedInvoices.forEach(x => {
    selectedInvoiceCounter += x.amountDue
  })

  useEffect(() => {
    setState(prev => ({
      ...prev,
      selectedUnpaid: selectedInvoiceCounter,
    }))
  }, [selectedInvoiceCounter])

  const handlePayAllClick = () => {
    setState(prev => ({
      ...prev,
      paymentInvoices: state.data.invoices
        .filter(x => !!x.invoiceNumber && x.status !== SalesInvoiceStatus.Paid)
        .map(y => ({
          invoiceNumber: y.invoiceNumber,
          amount: y.amountDue,
        })),
    }))
  }

  const handlePaySelectedInvoices = () => {
    setState(prev => ({
      ...prev,
      paymentInvoices: state.selectedInvoices.map(y => ({
        invoiceNumber: y.invoiceNumber,
        amount: y.amountDue,
      })),
    }))
  }

  const handlePaymentModalClose = (paymentProcessed: boolean) => {
    setState(prev => ({
      ...prev,
      paymentInvoices: null,
      dataLoaded: !paymentProcessed,
    }))
  }

  const handleFilterSubmission = (data: TAccordionFilterValues) => {
    // const mappedParameters = mapToPageParameters(data)
    setState(prev => ({
      ...prev,
      ...data,
      page: 1,
      dataUpdated: false,
      dataLoaded: false,
    }))
  }

  const handleResetClick = () => {
    setState(prev => ({
      ...prev,
      ...defaults,
      count: 0,
      dataUpdated: false,
    }))
  }

  const isFinancialRestricted =
    profileContext.dependentData.userDetails.isFinancialRestricted

  if (isFinancialRestricted) {
    navigate('/404')
    return null
  }

  if (!profileContext.minimumProfileLoaded) {
    return <Loader />
  }

  return (
    <React.Fragment>
      <SEO title="Sales Invoices" />
      {state.data.isPastDue && (
        <Alert severity="warning" style={{ marginBottom: '20px' }}>
          <AlertTitle>Payment issues.</AlertTitle>
          There are overdue payments.
        </Alert>
      )}
      <Box mb={2}>
        <Typography variant="h1">Sales Invoices</Typography>
      </Box>
      {offline ? <OfflineBanner /> : <></>}

      <Box mt={4}>
        <React.Fragment>
          {profileContext.minimumProfileLoaded && (
            <FilterAccordion
              pageName="Financials"
              defaultValues={defaults}
              onSubmit={handleFilterSubmission}
              onReset={handleResetClick}
              filters={{
                financials: [FinancialsFilterSet.All],
                date: [DateFilterSet.FromDate, DateFilterSet.ToDate],
              }}
            />
          )}
          {state.dataLoaded && state.dataUpdated ? (
            <>
              <Grid container direction="row" style={{ marginTop: '20px' }}>
                <Grid container direction="column" md={4}></Grid>
                <Grid container direction="column" md={4}></Grid>
                <Grid
                  container
                  direction="row"
                  md={4}
                  alignItems="center"
                  justify="flex-end"
                >
                  <Grid item>
                    <Grid item style={{ alignSelf: 'flex-end' }}>
                      {state.selectedInvoices.length > 0 ? (
                        <h5>Selected Unpaid</h5>
                      ) : (
                        <h5>Total Unpaid</h5>
                      )}
                    </Grid>
                    <Grid
                      item
                      style={{ alignSelf: 'flex-end', textAlign: 'right' }}
                    >
                      <h5>
                        <b>
                          <NumberFormat
                            value={
                              state.selectedInvoices.length > 0
                                ? state.selectedUnpaid
                                : state.data.currentUnpaid
                            }
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </b>
                      </h5>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    style={{ marginLeft: '20px', marginRight: '20px' }}
                  >
                    {process.env.REACT_APP_ENVIRONMENT !== 'production' &&
                      state.data.currentUnpaid > 0 && (
                        <Grid item style={{ alignSelf: 'center' }}>
                          <PayInvoicesButton
                            disabled={
                              !state.data.invoices.some(
                                x =>
                                  !!x.invoiceNumber &&
                                  x.status !== SalesInvoiceStatus.Paid
                              ) || !profileContext.billingCustomersLoaded
                            }
                            onClick={() =>
                              state.selectedInvoices.length > 0
                                ? handlePaySelectedInvoices()
                                : handlePayAllClick()
                            }
                          >
                            {state.selectedInvoices.length > 0
                              ? `Pay Selected`
                              : 'Pay All'}
                          </PayInvoicesButton>
                          <InvoicesPaymentModal
                            modalOpen={
                              state.paymentInvoices?.some(x => x) ?? false
                            }
                            invoices={state.paymentInvoices}
                            onModalClose={handlePaymentModalClose}
                            billingCustomer={
                              profileContext.billingCustomersLoaded
                                ? profileContext.profile.billingCustomers.filter(
                                    x => x.billToContact?.address !== null
                                  )[0]
                                : null
                            }
                          />
                        </Grid>
                      )}
                  </Grid>
                </Grid>
              </Grid>
              {state.dataLoaded ? (
                <SalesInvoices
                  data={state.invoices}
                  selectedInvoices={state.selectedInvoices}
                  isDivision={state.data?.isDivision}
                  isMultiCustomer={state.data?.isMultiCustomer}
                  onSelectInvoice={handleSelectInvoice}
                  onViewReceiptClick={handleViewReceiptClick}
                  onInvoiceClick={handleInvoiceClick}
                />
              ) : (
                <Loader />
              )}
            </>
          ) : (
            <Loader />
          )}
        </React.Fragment>
      </Box>
    </React.Fragment>
  )
}

export default function FinancialInvoicesPage() {
  return (
    <AppLayout tab="financials">
      <InvoicesFinancials />
    </AppLayout>
  )
}
