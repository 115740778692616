import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  createTheme,
  Grid,
  ThemeProvider,
  Typography,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { SortDescriptor } from '@progress/kendo-data-query'
import { GridSortChangeEvent } from '@progress/kendo-react-grid'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { stringify } from 'query-string'
import { encodeQueryParams } from 'use-query-params'
import AppLayout from 'components/AppLayout'
import { ExportButton } from 'components/buttons'
import ViewReportButton from 'components/buttons/ViewReportButton'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import { IReportParametersScheduledSamplesDue } from 'components/ReportViewer/TelerikReportViewer'
import {
  compactReportParams,
  reportViewerQueryParamConfig,
} from 'components/ReportViewerFromURLParams'
import SEO from 'components/SEO'
import {
  ScheduledSamplesDueTable,
  tableFormatter,
} from 'components/tables/ScheduledSamplesDue'
import APIService, { TProfile } from 'services/api-service'
import { exportData } from 'services/export-service'
import {
  getSelectedCustomerIDs,
  getSelectedMachinePointIDs,
} from 'services/selection-service'
import coreTheme from '../../components/theme'
import { useProfile } from 'Contexts/ProfileContext'
import FilterAccordion, {
  TAccordionFilterValues,
} from 'components/FilterAccordion'
import { mapToPageParameters } from 'components/FilterAccordion/Utilities'
import {
  MachineFilterSet,
  OptionsFilterSet,
  OrganizationalFilterSet,
  SampleFilterSet,
} from 'components/FilterAccordion/types'
import { CancellablePromise } from 'types/api'

const moment = extendMoment(Moment)

const startDate = moment().date(1).startOf('day').toDate()
const endDate = moment(startDate)
  .add(1, 'month')
  .subtract(1, 'day')
  .endOf('day')
  .toDate()

const defaults = {
  page: 1,
  pageSize: 10,
  orderConfig: [{ field: 'scheduled', dir: 'desc' }] as SortDescriptor[],
  selectedDivisionIDs: [],
  selectedCustomerIDs: [],
  selectedPlants: [],
  selectedCustEquIDs: [],
  selectedMachineNames: [],
  selectedRoutes: [],
  selectedTestGroupIDs: [],
  hideCompleted: false,
  exportFormat: '',
}

let isInitialLoad = true

interface OwnProps {
  onError?: (_err: Error) => void // passed in from layout wrapper
  offline?: boolean
  classes?: any
}

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

const ScheduledSamplesDueReport: React.FC<OwnProps> = ({
  onError,
  offline,
  classes,
}: OwnProps) => {
  const profileContext = useProfile()

  const [state, setState] = useState({
    ...defaults,
    loaded: false,
    count: 0,
    data: [],
    searching: false,
    loadedDefaults: false,
  })

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  useEffect(() => {
    if (profileContext.dependentData.filters.minimumFilterOptionsLoaded) {
      setState(prev => ({
        ...prev,
        selectedCustomerIDs:
          profileContext.dependentData.filters.initialFilterState.customers.map(
            c => c.custID
          ),
        selectedDivisionIDs:
          profileContext.dependentData.filters.initialFilterState.divisions.map(
            d => d.iD
          ),
        loadedDefaults: true,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext.dependentData.filters.minimumFilterOptionsLoaded])

  useEffect(() => {
    return () => {
      isInitialLoad = true
    }
  }, [])

  useEffect(() => {
    if (!state.loaded && !state.searching && state.loadedDefaults) {
      performSearch()
    }
    if (state.searching) {
      performSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loaded, state.loadedDefaults, state.searching])

  const fetchSamples = useCallback(
    async variables => {
      setState(prev => ({ ...prev, loaded: false }))
      let promise
      try {
        promise = APIService.getScheduledSamplesDue(variables)

        ongoingCancellablePromises.push(promise)
        const data = await promise

        if (data.analytics.pagedScheduleCompliance !== null) {
          onError(null)
          setState(prev => ({
            ...prev,
            data: data.analytics.pagedScheduleCompliance.items,
            count: data.analytics.pagedScheduleCompliance.count,
            searching: false,
          }))
        }
      } catch (ex) {
        onError(ex)
      } finally {
        ongoingCancellablePromises.filter(p => p != promise)

        setState(prev => ({ ...prev, loaded: true }))
      }
    },
    [onError]
  )

  const performSearch = useCallback(async () => {
    await fetchSamples({
      page: state.page,
      pageSize: state.pageSize,
      orderList: state.orderConfig?.map(x => x.dir) ?? [],
      orderByList: state.orderConfig?.map(x => x.field) ?? [],
      customerIDs: getSelectedCustomerIDs(
        profileContext.profile.customers,
        profileContext.profile.divisions,
        state.selectedDivisionIDs,
        state.selectedCustomerIDs
      ),
      plantIDs: state.selectedPlants,
      machineNames: state.selectedMachineNames,
      custEquIDs: state.selectedCustEquIDs,
      routeIDs: state.selectedRoutes,
      testGroupIDs: state.selectedTestGroupIDs,
      startDate: startDate,
      endDate: endDate,
      hideCompleted: state.hideCompleted,
    })
  }, [
    fetchSamples,
    state.orderConfig,
    state.page,
    state.pageSize,
    state.selectedCustEquIDs,
    state.selectedCustomerIDs,
    state.selectedDivisionIDs,
    state.selectedMachineNames,
    state.selectedPlants,
    state.selectedRoutes,
    state.selectedTestGroupIDs,
    state.hideCompleted,
  ])

  const handleResetClick = () => {
    setState(prev => ({
      ...prev,
      ...defaults,
      count: 0,
      searching: true,
      selectedCustomerIDs:
        profileContext.dependentData.filters.initialFilterState.customers.map(
          c => c.custID
        ),
      selectedDivisionIDs:
        profileContext.dependentData.filters.initialFilterState.divisions.map(
          d => d.iD
        ),
    }))
  }

  const handleExportFormatChange = async (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    format
  ) => {
    let promise
    try {
      promise = APIService.getScheduledSamplesDue({
        page: 1,
        pageSize: 100000,
        orderList: state.orderConfig?.map(x => x.dir) ?? [],
        orderByList: state.orderConfig?.map(x => x.field) ?? [],
        customerIDs: getSelectedCustomerIDs(
          profileContext.profile.customers,
          profileContext.profile.divisions,
          state.selectedDivisionIDs,
          state.selectedCustomerIDs
        ),
        plantIDs: state.selectedPlants,
        machineNames: state.selectedMachineNames,
        custEquIDs: state.selectedCustEquIDs,
        routeIDs: state.selectedRoutes,
        testGroupIDs: state.selectedTestGroupIDs,
        startDate: startDate,
        endDate: endDate,
        hideCompleted: state.hideCompleted,
      })

      ongoingCancellablePromises.push(promise)
      const data = await promise

      const formatted = tableFormatter(
        data.analytics.pagedScheduleCompliance.items,
        startDate,
        endDate,
        profileContext.dependentData.userDetails.isDivisionUser
      )

      exportData('scheduled-samples-due', formatted, format.key)
    } catch (ex) {
      onError(ex)
    } finally {
      ongoingCancellablePromises.filter(p => p != promise)
    }
  }

  const handlePageChange = event => {
    setState(prev => ({
      ...prev,
      page: event.page.skip / state.pageSize + 1,
      pageSize: event.page.take,
      loaded: false,
    }))
  }

  const handleSortChange = (event: GridSortChangeEvent) => {
    const isFieldRemoved = state.orderConfig.length > event.sort.length
    let isLastFieldRemoved = false

    if (isFieldRemoved) {
      const fieldRemoved = state.orderConfig.filter(
        x => !event.sort.includes(x)
      )[0]

      isLastFieldRemoved =
        state.orderConfig.indexOf(fieldRemoved) === state.orderConfig.length - 1
    }

    setState(prev => ({
      ...prev,
      orderConfig: [...event.sort],
      loaded: !(event.sort.length > 0 && !isLastFieldRemoved),
    }))
  }

  const handlePageChangeMobile = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage
  ) => {
    setState(prev => ({ ...prev, page: newPage + 1, loaded: false }))
  }

  const handlePageSizeChangeMobile = event => {
    setState(prev => ({
      ...prev,
      page: 1,
      pageSize: parseInt(event.target.value, 10),
      loaded: false,
    }))
  }

  const getReportQueryParams = () => {
    const {
      selectedDivisionIDs,
      selectedCustomerIDs,
      selectedPlants,
      selectedRoutes,
      selectedCustEquIDs,
      selectedTestGroupIDs,
      orderConfig,
      selectedMachineNames,
    } = state

    const reportParams: IReportParametersScheduledSamplesDue = {
      Customers: getSelectedCustomerIDs(
        profileContext.profile.customers,
        profileContext.profile.divisions,
        selectedDivisionIDs,
        selectedCustomerIDs
      ),
      Plants: selectedPlants,
      SortColumn: orderConfig[0]?.field,
      SortDirection: orderConfig[0]?.dir,
      routes: selectedRoutes,
      machines: getSelectedMachinePointIDs(
        profileContext.profile.machines,
        selectedCustEquIDs,
        selectedMachineNames
      ),
      CorporateContactID: 0,
      testPackages: selectedTestGroupIDs,
    }

    return encodeQueryParams(reportViewerQueryParamConfig, {
      dbg: false,
      r: 'scheduledSamplesDue',
      ...compactReportParams(reportParams),
    })
  }

  if (
    isInitialLoad &&
    ((profileContext.profile.divisions?.length > 0 &&
      state.selectedDivisionIDs.length === 0) ||
      (profileContext.profile.customers?.length > 0 &&
        state.selectedCustomerIDs.length === 0))
  ) {
    isInitialLoad = false
    return null
  }

  const handleFilterSubmission = (data: TAccordionFilterValues) => {
    const mappedParameters = mapToPageParameters(data)

    setState(prev => ({
      ...prev,
      ...mappedParameters,
      page: 1,
      searching: true,
      loaded: false,
    }))
  }

  const theme = createTheme(
    {
      overrides: {
        MuiGrid: {
          root: {
            justifyContent: 'flex-end',
            marginRight: 50,
            [coreTheme.breakpoints.down('sm')]: {
              justifyContent: 'space-between',
            },
          },
        },
      },
    },
    coreTheme
  )

  return (
    <React.Fragment>
      <SEO title="Scheduled Samples Due Report" />
      <Box mb={2}>
        <Typography variant="h1">
          Scheduled Samples Due for {moment(startDate).format('MMMM YYYY')}
        </Typography>
      </Box>
      {offline ? <OfflineBanner /> : <></>}

      <FilterAccordion
        pageName="Scheduled Samples Due"
        defaultValues={{
          ...profileContext.dependentData.filters.initialFilterState,
          hideCompleted: state.hideCompleted,
        }}
        onSubmit={handleFilterSubmission}
        onReset={handleResetClick}
        filters={{
          options: [
            OptionsFilterSet.Prefiltering,
            OptionsFilterSet.HideCompleted,
          ],
          date: [],
          organization: [OrganizationalFilterSet.All],
          machine: [MachineFilterSet.Name, MachineFilterSet.EquipmentIDs],
          lubricant: [],
          sample: [SampleFilterSet.TestPackages],
          miscellaneous: [],
        }}
      />
      <form>
        <ThemeProvider theme={theme}>
          <Grid
            container
            direction="row"
            xs={12}
            spacing={1}
            style={{ marginTop: '15px' }}
          >
            <Grid container item direction="row" spacing={1}>
              <Grid item style={{ maxWidth: 242, marginLeft: 15 }}>
                <ViewReportButton
                  url={`/reports/viewer?${stringify(getReportQueryParams())}`}
                  disabled={state.data.length < 1}
                />
              </Grid>
              <Grid item>
                <ExportButton onChange={handleExportFormatChange} disabled={state.data.length < 1}/>
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>

        <Grid
          item
          justify={'flex-end'}
          direction="row"
          style={{ marginRight: 0, marginTop: 15 }}
        >
          <div style={{ textAlign: 'end' }}>
            <CheckIcon /> Tested, not scheduled
          </div>
          <div style={{ textAlign: 'end' }}>
            <CheckBoxOutlineBlankIcon /> Scheduled, not tested
          </div>
          <div style={{ textAlign: 'end' }}>
            <CheckBoxIcon /> Scheduled and tested
          </div>
        </Grid>
      </form>

      <Box mt={4}>
        {!state.loaded ? (
          <Loader />
        ) : (
          <React.Fragment>
            <ScheduledSamplesDueTable
              startDate={startDate}
              endDate={endDate}
              classes={classes}
              readOnly={profileContext.profile?.user?.readOnly}
              offline={offline}
              data={state.data}
              page={state.page}
              pageSize={state.pageSize}
              count={state.count}
              orderConfig={state.orderConfig}
              onSortChange={handleSortChange}
              onPageChange={handlePageChange}
              onPageChangeMobile={handlePageChangeMobile}
              onPageSizeChangeMobile={handlePageSizeChangeMobile}
            />
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  )
}

export default function Page() {
  return (
    <AppLayout tab="reports">
      <ScheduledSamplesDueReport />
    </AppLayout>
  )
}
