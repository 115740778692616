import { useEffect, useState } from 'react'
import { Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { TItem } from './HelpDrawer'

interface TProps {
  show: boolean
  item: TItem
  onClose: () => void
}

export default function HelpView({ show, item, onClose }: TProps) {
  const useStyles = makeStyles(theme => ({
    paper: {
      width: '75vw',
      textAlign: 'center',
      backgroundColor: 'white',
      borderRadius: 4,
      padding: 15,
      color: 'black',
      marginBottom: 25,
      marginRight: 15,
      boxShadow:
        '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    },
    imageBox: {
      border: 'solid',
      borderWidth: 1,
      borderColor: 'lightgray',
      marginTop: 15,
      marginBottom: 15,
    },
    image: {
      maxHeight: '75vh',
    },
    button: {
      width: 150,
      marginTop: 15,
      marginBottom: 5,
      marginLeft: 5,
      marginRight: 5,
    },
  }))

  const modalClasses = useStyles()

  const [error, setError] = useState(false)

  useEffect(() => {
    setError(false)
  }, [item])

  const handleNeedMoreHelpClick = () => {
    Microsoft.Omnichannel.LiveChatWidget.SDK.startChat();
  }

  return (
    show &&
    item?.link && (
      <Grid
        container
        className={modalClasses.paper}
        justify="center"
        direction="column"
        alignItems="center"
      >
        <h4>{item.title}</h4>
        {!error ? (
          <div className={modalClasses.imageBox}>
            <video
              src={item.link}
              width="100%"
              onError={event => setError(true)}
              className={modalClasses.image}
              controls
              loop
              autoPlay
            />
          </div>
        ) : (
          <h3>Video unavailable!</h3>
        )}
        <span>{item.details}</span>

        <Grid container direction="row" justify="center">
          <Button
            id="help_view-close-button"
            title="Close"
            variant="contained"
            color="secondary"
            onClick={onClose}
            className={modalClasses.button}
          >
            Close
          </Button>
          <Button
            id="help_view-help-button"
            title="Need more help?"
            variant="contained"
            color="secondary"
            onClick={handleNeedMoreHelpClick}
            className={modalClasses.button}
          >
            Need more help?
          </Button>
        </Grid>
      </Grid>
    )
  )
}
