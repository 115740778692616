import React from 'react'

import Banner from '../Banner'

const AddEquipmentBanner = React.memo(() => {
  return (
    <Banner title={'Adding more than one unit or a new plant?'}>
      Download and complete an{' '}
      <a
        href="/static/general-files/Equipment Registration Spreadsheet.xls"
        download
      >
        equipment registration spreadsheet
      </a>
      {', '}
      then send it to the Testoil{' '}
      <a
        href={`mailto: ${process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL}?Subject=Equipment%20Registration`}
      >
        support team
      </a>
      ; they'll take care of everything.
    </Banner>
  )
})

export default AddEquipmentBanner
