import React from 'react'

import { VirtualSelectFilter } from '../BaseFilters/VirtualSelectFilter'
import FilterTooltip from '../FilterTooltip/FilterTooltip'
import { sampleStatuses } from 'services/lookup-service'

export type TSampleStatus = {
  value: number
  text: string
  codes: string[]
}

type TProps = {
  single: boolean
  required: boolean
  onChange?: (_props: TSampleStatus | TSampleStatus[]) => void
  tooltip?: boolean
  defaultValue?: number | number[]
}

export const SampleStatusFilter = ({
  single,
  required,
  onChange,
  tooltip = true,
  defaultValue,
}: TProps) => {
  const filter = (
    <VirtualSelectFilter
      label="Sample Status"
      htmlFor="sampleStatuses"
      multiple={!single}
      required={required}
      id="sampleStatuses"
      renderValue={(selected: TSampleStatus) =>
        sampleStatuses.find(status => status.value === selected.value)?.text
      }
      onChange={onChange}
      options={sampleStatuses}
      value={defaultValue}
      defaultValue={defaultValue}
      getOptionSelected={(option, value) => option.value === value.value}
      autoCompletefilterOptions={({ text, codes }) => `${text} ${codes.join(' ')}`}
    ></VirtualSelectFilter>
  )

  return tooltip ? (
    <FilterTooltip title="The current processing state of the sample inside of our laboratory">
      {filter}
    </FilterTooltip>
  ) : (
    filter
  )
}
