import Loader from 'components/Loader'
import React, { useState, useEffect } from 'react'
import * as PageStyles from '../page-styles.module.css'

type TProps = {
  notificationTime?: number
  showHeader?: boolean
}
export function LoadingCardModule({ notificationTime, showHeader }: TProps) {
  const [tooLong, setTooLong] = useState(false)
  useEffect(() => {
    notificationTime = notificationTime ?? 30000
    setTimeout(() => setTooLong(true), notificationTime)
  }, [])

  return (
    <>
      <div>
        {(typeof showHeader === 'undefined' || !!showHeader) && (
          <div>
            <header className={PageStyles.MainTitle}>Loading</header>
            <sub className={PageStyles.SubTitle}>
              <span className={PageStyles.BlockSpan}>
                This may take awhile depending upon your connection speed.
              </span>
            </sub>
          </div>
        )}
        <Loader />
      </div>
      {tooLong && (
        <>
          <sub className={PageStyles.SubTitle}>
            <span
              className={`${PageStyles.FinePrintParagraph} ${PageStyles.ErrorText}`}
            >
              This is taking much longer than expected.
            </span>
          </sub>
          <br />
          <section className={PageStyles.DetailSection}>
            <span className={`${PageStyles.FinePrintParagraph}`}>
              While we are still waiting for a response from the server, we
              aren't hopeful. Feel free to refresh the page and try again, or
              try again later.
            </span>
          </section>
        </>
      )}
    </>
  )
}
