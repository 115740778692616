import { MenuItem, Select } from '@material-ui/core'
import useId from '@mui/utils/useId'
import { useProfile } from 'Contexts/ProfileContext'
import { useCallback, useEffect, useMemo, useState } from 'react'
import APIService from 'services/api-service'
import {
  TPlantQuery,
  TAPIPlant,
  GetBillingPlantsResponse,
  GetBillingPlantsResponseDTO,
} from 'types/api'
import { TLoading } from 'types/custom'
import { FetchError } from 'utils/Errors/FetchError'

type TFormData = {
  plantsWithFinancials: GetBillingPlantsResponse | null
}

type Props<Key, OptionType> = {
  onChange: (_financialID: string) => void
  value: string
  options?: Map<Key, OptionType>
}

interface QueryElement<Key, OptionType> {
  isLoading: boolean
  loaded: boolean
  error: FetchError
  isError: boolean
  options: Map<Key, OptionType>
  FinancialIDSelectFilter: JSX.Element
  labelID: string
  reset: () => void
}

const defaultFormData = {
  plantsWithFinancials: null,
}

let plantCache: GetBillingPlantsResponse = null
let clearFunctionAdded = false
const clearCache = () => {
  clearFunctionAdded = false
  plantCache = null
}

/** Use the financial ID select filter, which loads plants from a different information source containing financial link information not in the profile.
 * @TODO: Modify the profile in the future to include this information and remove the loading feature of this filter hook.
 */
export function useFinancialIDSelectFilter({
  onChange,
  value,
  options,
}: Props<string, GetBillingPlantsResponse>): QueryElement<
  string,
  GetBillingPlantsResponse
> {
  const profileContext = useProfile()
  const id = useId()

  if (options && options.size > 0 && (!plantCache || plantCache.length === 0)) {
    plantCache = []

    for (const pair of options) {
      const values = pair[1]
      for (const value of values) plantCache.push(value)
    }
  }

  if (!clearFunctionAdded) {
    profileContext.userSessionManagement.onLogoutEvents.AddEvent(
      clearCache.bind(this)
    )
    clearFunctionAdded = true
  }

  const [formData, setFormData] = useState<TFormData & TLoading>({
    ...defaultFormData,
    error: undefined,
    loading: false,
    loaded: false,
    plantsWithFinancials: plantCache,
  })

  const reset = () => {
    clearCache()
    setFormData(_prev => ({
      plantsWithFinancials: [],
      error: undefined,
      loading: false,
      loaded: false,
    }))
  }

  useEffect(() => {
    if (plantCache == null && profileContext.minimumProfileLoaded) {
      setFormData(prev => ({
        ...prev,
        error: undefined,
        loading: true,
        loaded: false,
      }))

      const loadPlants = async () => {
        try {
          const plantsQuery: TPlantQuery = {
            customerIDs: profileContext.profile.customers.map(x => x.custID),
          }

          const plantResponse = await APIService.getBillingPlantsAsync(
            plantsQuery
          )
          setFormData(prev => ({
            ...prev,
            error: undefined,
            loading: false,
            loaded: true,
            plantsWithFinancials: plantResponse,
          }))
          plantCache = plantResponse
        } catch (err) {
          const error = err as FetchError
          setFormData(prev => ({
            ...prev,
            error: error,
            loading: false,
            loaded: true,
          }))
        }
      }
      loadPlants()
    } else if (!profileContext.minimumProfileLoaded) {
    } else {
      setFormData(prev => ({
        ...prev,
        error: undefined,
        loading: false,
        loaded: true,
        plantsWithFinancials: plantCache,
      }))
      setFormData(prev => ({
        ...prev,
        error: undefined,
        loading: false,
        loaded: true,
      }))
    }
  }, [profileContext.minimumProfileLoaded])

  const plantsByFinancialID = useMemo(() => {
    const financialIDPlantsMap = new Map<string, GetBillingPlantsResponse>()

    if (!formData.loaded) return financialIDPlantsMap

    const activeFinancialIDs = new Set(
      profileContext.profile.billingCustomers
        ?.filter(bc => !bc.inactive && bc.financialID)
        .map(bc => bc.financialID)
    )

    const plants = formData.plantsWithFinancials ?? []
    for (const plant of plants) {
      if (plant.financialID) activeFinancialIDs.add(plant.financialID)
    }

    for (let plant of plants) {
      const plantHasActiveFinancialID = activeFinancialIDs.has(
        plant.financialID
      )
      if (!plantHasActiveFinancialID) continue

      if (financialIDPlantsMap.has(plant.financialID)) {
        financialIDPlantsMap.get(plant.financialID).push(plant)
      } else {
        financialIDPlantsMap.set(plant.financialID, [plant])
      }
    }

    return financialIDPlantsMap
  }, [
    profileContext.profile,
    profileContext.billingCustomersLoaded,
    formData.plantsWithFinancials,
    formData.loaded,
  ])

  const renderBCSelectOption = useCallback(
    (pair: [string, (GetBillingPlantsResponseDTO | TAPIPlant)[]]) => {
      if (!pair) return ''
      const [_financialID, plants] = pair

      const displayLimit = 2
      let arr = Array.from(plants)
      let isLimitedInLength = arr.length > displayLimit
      let limitedArr = isLimitedInLength ? arr.slice(0, displayLimit) : arr

      let str = limitedArr.map(p => `(${p.plantName})`).join(', ')

      return isLimitedInLength
        ? str + `, ... + ${arr.length - displayLimit} more.`
        : str
    },
    [profileContext.profile]
  )

  const element = (
    <Select
      labelId={id}
      label={'Plants *'}
      multiple={false}
      fullWidth
      onChange={event => {
        const financialID = (event.target.value as string) || ''
        onChange(financialID)
      }}
      value={value}
    >
      {Array.from(plantsByFinancialID).map(
        (option: [string, GetBillingPlantsResponse]) => {
          const selected = value === option[0]
          return (
            <MenuItem
              value={option[0]}
              selected={selected}
              style={{ width: '100%' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  padding: '0',
                  alignItems: 'center',
                  textOverflow: 'ellipses',
                  whiteSpace: 'pre-wrap',
                  textAlign: 'justify',
                }}
              >
                {renderBCSelectOption(option)}
              </div>
            </MenuItem>
          )
        }
      )}
    </Select>
  )

  return {
    reset,
    FinancialIDSelectFilter: element,
    isLoading: formData.loading,
    loaded:
      formData.loaded &&
      profileContext.billingCustomersLoaded &&
      profileContext.minimumProfileLoaded,
    error: formData.error,
    isError: !!formData.error,
    options: plantsByFinancialID,
    labelID: id,
  } as QueryElement<string, GetBillingPlantsResponse>
}
