import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core'
import moment from 'moment'

interface OwnProps {
  recurringException: any
  showDivisions?: boolean
}

const MobileCard: React.FC<OwnProps> = ({
  recurringException: x,
  showDivisions,
}) => {
  return (
    <Card variant="outlined" style={{ marginBottom: '10px' }}>
      <CardHeader title={x.plantName} />
      <CardContent>
        <Grid container direction="row" spacing={1}>
          {showDivisions && (
            <Grid item xs={6} md={3}>
              Division Name:
            </Grid>
          )}
          {showDivisions && (
            <Grid item xs={6} md={3}>
              {x.divisionName}
            </Grid>
          )}
          <Grid item xs={6} md={3}>
            Plant Name:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.plantName}
          </Grid>
          <Grid item xs={6} md={3}>
            Machine No:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.machineNumber}
          </Grid>
          <Grid item xs={6} md={3}>
            Machine Name:
          </Grid>
          <Grid item xs={6} md={3}>
            <Link
              to={`/dart/data?labID=${x.labID}`}
              target="_blank"
              rel="noreferrer"
            >
              {x.machineName}
            </Link>
          </Grid>
          <Grid item xs={6} md={3}>
            Equipment ID:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.machType}
          </Grid>
          <Grid item xs={6} md={3}>
            Last Sample Date:
          </Grid>
          <Grid item xs={6} md={3}>
            {moment(x.lastSampleDate).format('l')}
          </Grid>
          <Grid item xs={6} md={3}>
            Test Lab No:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.labID}
          </Grid>
          <Grid item xs={6} md={3}>
            Tests:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.tests}
          </Grid>
          <Grid item xs={6} md={3}>
            Tests Packages:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.testPackages}
          </Grid>
          <Grid item xs={6} md={3}>
            Lubricant:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.lubricant}
          </Grid>
          <Grid item xs={6} md={3}>
            Wear Metal:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.wearMetalCount === 0 ? '' : x.wearMetalCount}
          </Grid>
          <Grid item xs={6} md={3}>
            Ferrous Density:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.ferrousCount === 0 ? '' : x.ferrousCount}
          </Grid>
          <Grid item xs={6} md={3}>
            Particle Count:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.particleCountCount === 0 ? '' : x.particleCountCount}
          </Grid>
          <Grid item xs={6} md={3}>
            Water:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.waterCount === 0 ? '' : x.waterCount}
          </Grid>
          <Grid item xs={6} md={3}>
            Viscosity:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.viscosityCount === 0 ? '' : x.viscosityCount}
          </Grid>
          <Grid item xs={6} md={3}>
            Degradation:
          </Grid>
          <Grid item xs={6} md={3}>
            {x.degredationCount === 0 ? '' : x.degredationCount}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default MobileCard
