/* eslint-disable react-hooks/exhaustive-deps */
import { stringify } from 'querystring'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Box,
  createTheme,
  Grid,
  ThemeProvider,
  Typography,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { SortDescriptor } from '@progress/kendo-data-query'
import {
  ExcelExport,
  ExcelExportColumn,
} from '@progress/kendo-react-excel-export'
import { GridSortChangeEvent } from '@progress/kendo-react-grid'
import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
import { encodeQueryParams } from 'use-query-params'
import AppLayout from 'components/AppLayout'
import { ExportButton } from 'components/buttons'
import { TProps as TExportButtonProps } from 'components/buttons/ExportButton'
import ViewReportButton from 'components/buttons/ViewReportButton'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import { IReportParametersScheduleCompliance } from 'components/ReportViewer/TelerikReportViewer'
import {
  compactReportParams,
  reportViewerQueryParamConfig,
} from 'components/ReportViewerFromURLParams'
import SEO from 'components/SEO'
import {
  ScheduleComplianceReportTable,
  tableFormatter,
} from 'components/tables/ScheduleComplianceReport'
import APIService, { TProfile } from 'services/api-service'
import { exportData } from 'services/export-service'
import {
  getSelectedCustomerIDs,
  getSelectedMachinePointIDs,
} from 'services/selection-service'
import coreTheme from '../../components/theme'
import { useProfile } from 'Contexts/ProfileContext'
import FilterAccordion, {
  TAccordionFilterValues,
} from 'components/FilterAccordion'
import { mapToPageParameters } from 'components/FilterAccordion/Utilities'
import {
  MachineFilterSet,
  OptionsFilterSet,
  OrganizationalFilterSet,
  SampleFilterSet,
} from 'components/FilterAccordion/types'

const moment = extendMoment(Moment)

const endDate = moment().date(1).subtract(1, 'month').endOf('day').toDate()
const startDate = moment(endDate).subtract(11, 'months').startOf('day').toDate()

let isInitialLoad = true

const defaults = {
  count: 0,
  page: 1,
  pageSize: 10,
  orderConfig: [{ field: 'latestLabID', dir: 'asc' }] as SortDescriptor[],
  exporting: false,
  selectedDivisionIDs: [] as number[],
  selectedCustomerIDs: [] as number[],
  selectedPlants: [] as number[],
  selectedCustEquIDs: [] as string[],
  selectedMachineNames: [] as string[],
  selectedRoutes: [] as number[],
  selectedTestGroupIDs: [] as number[],
  exportFormat: '',
  dataLoaded: false,
  data: [],
  fullData: [],
  dataExcel: [],
  searching: false,
  loadedDefaults: false,
}

type TState = {
  dataLoaded: boolean
  showDivisions: boolean
  count: number
  data: any[]
  fullData: any[]
  dataExcel: any[]
  searching: boolean
  loadedDefaults: boolean
} & typeof defaults &
  Partial<TProfile>

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

const ScheduleComplianceReport = ({ onError, offline }: any) => {
  const excelFileName = `schedule-compliance-${moment().format(
    'YYYYMMDDHHmmss'
  )}.xlsx`
  const _export = useRef<ExcelExport | null>(null)

  const months = []
  const range = moment.range(startDate, endDate)
  for (const month of range.by('month')) {
    months.push({
      year: month.year(),
      month: month.month() + 1,
      columnTitle: month.format('MMM YY'),
    })
  }

  const [state, setState] = useState<Partial<TState>>({
    ...defaults,
  })

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  const profileContext = useProfile()

  useEffect(() => {
    if (profileContext.dependentData.filters.minimumFilterOptionsLoaded) {
      setState(prev => ({
        ...prev,
        selectedCustomerIDs:
          profileContext.dependentData.filters.initialFilterState.customers.map(
            c => c.custID
          ),
        selectedDivisionIDs:
          profileContext.dependentData.filters.initialFilterState.divisions.map(
            d => d.iD
          ),
        loadedDefaults: true,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext.dependentData.filters.minimumFilterOptionsLoaded])

  useEffect(() => {
    return () => {
      isInitialLoad = true
    }
  }, [])

  useEffect(() => {
    setState(prev => ({
      ...prev,
      showDivisions: state.data.some(s => s.machine.plant.customer.division),
    }))
  }, [state.data])

  const handlePageChange = event => {
    setState(prev => ({
      ...prev,
      page: event.page.skip / state.pageSize + 1,
      pageSize: event.page.take,
      dataLoaded: false,
      searching: true,
    }))
  }

  const handleSortChange = (event: GridSortChangeEvent) => {
    const isFieldRemoved = state.orderConfig.length > event.sort.length
    let isLastFieldRemoved = false

    if (isFieldRemoved) {
      const fieldRemoved = state.orderConfig.filter(
        x => !event.sort.includes(x)
      )[0]

      isLastFieldRemoved =
        state.orderConfig.indexOf(fieldRemoved) === state.orderConfig.length - 1
    }

    setState(prev => ({
      ...prev,
      orderConfig: [...event.sort],
      dataLoaded: !(event.sort.length > 0 && !isLastFieldRemoved),
      searching: event.sort.length > 0 && !isLastFieldRemoved,
    }))
  }

  const handlePageChangeMobile = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage
  ) => {
    setState(prev => ({
      ...prev,
      page: newPage + 1,
      dataLoaded: false,
      searching: true,
    }))
  }

  const handlePageSizeChangeMobile = event => {
    setState(prev => ({
      ...prev,
      page: 1,
      pageSize: parseInt(event.target.value, 10),
      dataLoaded: false,
      searching: true,
    }))
  }

  const fetchSamples = useCallback(
    async variables => {
      setState(prev => ({
        ...prev,
        dataLoaded: false,
      }))
      let promise
      try {
        promise = APIService.getScheduleCompliance(variables)

        ongoingCancellablePromises.push(promise)
        const data = await promise

        if (data.analytics.pagedScheduleCompliance !== null) {
          onError(null)
          setState(prev => ({
            ...prev,
            data: data.analytics.pagedScheduleCompliance.items,
            count: data.analytics.pagedScheduleCompliance.count,
            dataLoaded: true,
            searching: false,
          }))
        }
      } catch (ex) {
        onError(ex)
      } finally {
        ongoingCancellablePromises.filter(p => p != promise)
      }
    },
    [onError]
  )

  const performSearch = useCallback(async () => {
    fetchSamples({
      page: state.page,
      pageSize: state.pageSize,
      orderList: state.orderConfig?.map(x => x.dir) ?? [],
      orderByList: state.orderConfig?.map(x => x.field) ?? [],
      customerIDs: getSelectedCustomerIDs(
        profileContext.profile.customers,
        profileContext.profile.divisions,
        state.selectedDivisionIDs,
        state.selectedCustomerIDs
      ),
      plantIDs: state.selectedPlants,
      machineNames: state.selectedMachineNames,
      custEquIDs: state.selectedCustEquIDs,
      routeIDs: state.selectedRoutes,
      testGroupIDs: state.selectedTestGroupIDs,
    })
  }, [
    fetchSamples,
    state.orderConfig,
    state.page,
    state.pageSize,
    state.selectedCustEquIDs,
    state.selectedCustomerIDs,
    state.selectedDivisionIDs,
    state.selectedMachineNames,
    state.selectedPlants,
    state.selectedRoutes,
    state.selectedTestGroupIDs,
  ])

  useEffect(() => {
    if (!state.dataLoaded && !state.searching && state.loadedDefaults) {
      performSearch()
    }
    if (state.searching) {
      performSearch()
    }
  }, [state.dataLoaded, state.loadedDefaults, state.searching])

  const excelExport = () => {
    if (_export.current !== null) {
      const options = _export.current.workbookOptions()
      const rows = options.sheets[0].rows

      rows.forEach((row, index) => {
        for (let i = 0; i < months.length; i++) {
          const month = months[i]
          if (row.type !== 'data')
            row.cells.push({
              value: month.columnTitle,
              background: '#707070',
              color: '#FFF',
            })
          else {
            const item = state.fullData[index - 1]
            const count = item.counts.find(
              y => y.month === month.month && y.year === month.year
            )
            const condition =
              typeof count === 'undefined'
                ? 0
                : count.machCond > 2 || count.lubCond > 2
                ? 3
                : count.machCond === 2 || count.lubCond === 2
                ? 2
                : count.machCond === 1 || count.lubCond === 1
                ? 1
                : 0

            const val =
              count.tested > 0 && count.scheduled === 0
                ? '(1-0)'
                : count.tested === 0 && count.scheduled > 0
                ? '(0-1)'
                : count.tested === 0 && count.scheduled === 0
                ? '-'
                : '(1-1)'

            row.cells.push({
              value: val,
              background:
                condition === 1
                  ? '#8af07d'
                  : condition === 2
                  ? '#ffec12'
                  : condition === 3
                  ? '#f07d7d'
                  : '',
            })
          }
        }
      })

      const headers = ['Scheduled', 'Completed', 'Compliance']
      rows.forEach((row, index) => {
        if (row.type !== 'data')
          headers.forEach(x => {
            row.cells.push({
              value: x,
              background: '#707070',
              color: '#FFF',
            })
          })
        else {
          const item = state.dataExcel[index - 1]
          const { scheduled, tested, scheduledX } = item
          row.cells.push({
            value: scheduled,
          })
          row.cells.push({
            value: tested,
          })
          row.cells.push({
            value: scheduledX + '%',
          })
        }
      })

      _export.current.save(options)
    }
  }

  useEffect(() => {
    if (state.fullData?.length > 0 && state.dataExcel?.length > 0) {
      excelExport()
      setState(prev => ({ ...prev, dataExcel: [], fullData: [] }))
    }
  }, [state.fullData, state.dataExcel])

  const handleResetClick = () => {
    setState(prev => ({
      ...prev,
      ...defaults,
      count: 0,
      selectedCustomerIDs:
        profileContext.dependentData.filters.initialFilterState.customers.map(
          c => c.custID
        ),
      selectedDivisionIDs:
        profileContext.dependentData.filters.initialFilterState.divisions.map(
          d => d.iD
        ),
      dataLoaded: false,
      searching: true,
    }))
  }

  const handleExportFormatChange: TExportButtonProps['onChange'] = async (
    _event,
    format
  ) => {
    let promise
    try {
      setState(prev => ({ ...prev, exporting: true }))
      promise = APIService.getScheduleCompliance({
        page: 1,
        pageSize: 999999,
        orderList: state.orderConfig?.map(x => x.dir) ?? [],
        orderByList: state.orderConfig?.map(x => x.field) ?? [],
        customerIDs: getSelectedCustomerIDs(
          profileContext.profile.customers,
          profileContext.profile.divisions,
          state.selectedDivisionIDs,
          state.selectedCustomerIDs
        ),
        plantIDs: state.selectedPlants,
        machineNames: state.selectedMachineNames,
        routeIDs: state.selectedRoutes,
        custEquIDs: state.selectedCustEquIDs,
        testGroupIDs: state.selectedTestGroupIDs,
      })

      ongoingCancellablePromises.push(promise)
      const response = await promise
      const data = response.analytics.pagedScheduleCompliance.items

      const formatted = tableFormatter(
        data,
        profileContext.dependentData.userDetails.isDivisionUser,
        startDate,
        endDate
      )

      if (format.key === 'csv') {
        exportData('schedule-compliance', formatted, format.key)
        return
      }

      const toExportExcel = data?.map(x => ({
        division:
          x.machine.plant.customer.division !== null
            ? x.machine.plant.customer.division.name
            : '',
        custName: x.machine.plant.customer.custName,
        plantName: x.machine.plant.plantName,
        route:
          x.machine.route !== null
            ? `${x.machine.route.routeNo} - ${x.machine.route.name}`
            : '',
        machNo: x.machine.machNo,
        machineName: x.machine.machineName,
        custEquID: x.machine.custEquID,
        testGroupName: x.testGroup.testGroupName,
        testGroups: x.testGroup.testGroupDefs
          .map(x => x.testCode.testCodeName)
          .join(', '),
        scheduled: x.counts
          .map(e => e.scheduled)
          .reduce((prev, cur) => prev + cur, 0),
        tested: x.counts
          .map(e => e.tested)
          .reduce((prev, cur) => prev + cur, 0),
        scheduledX:
          x.counts
            .map(e => e.scheduled)
            .reduce((prev, cur) => prev + cur, 0) === 0
            ? '-'
            : x.counts
                .map(e => e.tested)
                .reduce((prev, cur) => prev + cur, 0) >=
              x.counts
                .map(e => e.scheduled)
                .reduce((prev, cur) => prev + cur, 0)
            ? 100
            : Math.round(
                (x.counts
                  .map(e => e.tested)
                  .reduce((prev, cur) => prev + cur, 0) /
                  x.counts
                    .map(e => e.scheduled)
                    .reduce((prev, cur) => prev + cur, 0)) *
                  100
              ),
      }))
      setState(prev => ({
        ...prev,
        fullData: data,
        dataExcel: toExportExcel,
      }))
    } catch (ex) {
      onError(ex)
    } finally {
      setState(prev => ({ ...prev, exporting: false }))
      ongoingCancellablePromises.filter(p => p != promise)
    }
  }

  const getReportQueryParams = () => {
    const {
      selectedDivisionIDs,
      selectedCustomerIDs,
      selectedPlants,
      selectedCustEquIDs,
      selectedMachineNames,
      selectedRoutes,
      selectedTestGroupIDs,
      user,
      orderConfig,
    } = state

    const reportParams: IReportParametersScheduleCompliance = {
      Customers: getSelectedCustomerIDs(
        profileContext.profile.customers,
        profileContext.profile.divisions,
        selectedDivisionIDs,
        selectedCustomerIDs
      ),
      Plants: selectedPlants,
      machines: getSelectedMachinePointIDs(
        profileContext.profile.machines,
        selectedCustEquIDs,
        selectedMachineNames
      ),
      routes: selectedRoutes,
      testPackages: selectedTestGroupIDs,
      CorporateContactID: user?.cCID || 0,
      SortColumn: orderConfig[0]?.dir,
      SortDirection: orderConfig[0]?.field,
    }

    return encodeQueryParams(reportViewerQueryParamConfig, {
      dbg: false,
      r: 'scheduleCompliance',
      ...compactReportParams(reportParams),
    })
  }

  const theme = createTheme(
    {
      overrides: {
        MuiGrid: {
          root: {
            justifyContent: 'flex-end',
            [coreTheme.breakpoints.down('sm')]: {
              justifyContent: 'space-between',
            },
          },
        },
      },
    },
    coreTheme
  )

  const handleFilterSubmission = (data: TAccordionFilterValues) => {
    const mappedParameters = mapToPageParameters(data)

    setState(prev => ({
      ...prev,
      ...mappedParameters,
      page: 1,
      searching: true,
      dataLoaded: false,
    }))
  }

  if (
    isInitialLoad &&
    ((profileContext.profile.divisions?.length > 0 &&
      state.selectedDivisionIDs.length === 0) ||
      (profileContext.profile.customers?.length > 0 &&
        state.selectedCustomerIDs.length === 0))
  ) {
    isInitialLoad = false
    return null
  }

  return (
    <>
      <SEO title="Schedule Compliance Report" />
      <Box mb={2}>
        <Typography variant="h1">Schedule Compliance</Typography>
      </Box>
      {offline ? <OfflineBanner /> : <></>}

      <FilterAccordion
        pageName="Schedule Compliance"
        defaultValues={profileContext.dependentData.filters.initialFilterState}
        onSubmit={handleFilterSubmission}
        onReset={handleResetClick}
        filters={{
          options: [OptionsFilterSet.Prefiltering],
          date: [],
          organization: [OrganizationalFilterSet.All],
          machine: [MachineFilterSet.Name, MachineFilterSet.EquipmentIDs],
          lubricant: [],
          sample: [SampleFilterSet.TestPackages],
          miscellaneous: [],
        }}
      />
      <form>
        <ThemeProvider theme={theme}>
          <Grid
            container
            direction="row"
            xs={12}
            spacing={1}
            style={{ marginTop: '15px' }}
          >
            <Grid container item direction="row" spacing={1}>
              <Grid item style={{ maxWidth: 242, marginLeft: 15 }}>
                <ViewReportButton
                  url={`/reports/viewer?${stringify(getReportQueryParams())}`}
                  disabled={state.data.length < 1}
                />
              </Grid>

              <Grid item>
                <ExportButton
                  onChange={handleExportFormatChange}
                  isExporting={state.exporting}
                  disabled={state.data.length < 1}
                />
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
        <Grid
          item
          justify={'flex-end'}
          direction="row"
          style={{ marginRight: 0, marginTop: 15 }}
        >
          <div style={{ textAlign: 'end' }}>
            <CheckIcon /> Tested, not scheduled
          </div>
          <div style={{ textAlign: 'end' }}>
            <CheckBoxOutlineBlankIcon /> Scheduled, not tested
          </div>
          <div style={{ textAlign: 'end' }}>
            <CheckBoxIcon /> Scheduled and tested
          </div>
        </Grid>
      </form>

      <Box mt={4}>
        {!state.dataLoaded ? (
          <Loader />
        ) : (
          <>
            <ExcelExport
              data={state.dataExcel}
              ref={_export}
              fileName={excelFileName}
            >
              {state.showDivisions && (
                <ExcelExportColumn field="division" title="Division Name" />
              )}
              <ExcelExportColumn field="custName" title="Customer Name" />
              <ExcelExportColumn field="plantName" title="Plant Name" />
              <ExcelExportColumn field="route" title="Route" />
              <ExcelExportColumn field="machNo" title="Mach No." />
              <ExcelExportColumn field="machineName" title="Machine Name" />
              <ExcelExportColumn field="custEquID" title="Equipment ID" />
              <ExcelExportColumn field="testGroupName" title="Test Package" />
              <ExcelExportColumn field="testGroups" title="Tests" />
            </ExcelExport>
            <ScheduleComplianceReportTable
              startDate={startDate}
              endDate={endDate}
              data={state.data}
              count={state.count}
              offline={offline}
              orderConfig={state.orderConfig}
              page={state.page}
              pageSize={state.pageSize}
              onPageChange={handlePageChange}
              onSortChange={handleSortChange}
              onPageChangeMobile={handlePageChangeMobile}
              onPageSizeChangeMobile={handlePageSizeChangeMobile}
            />
          </>
        )}
      </Box>
    </>
  )
}

export default function Page() {
  return (
    <AppLayout tab="reports">
      <ScheduleComplianceReport />
    </AppLayout>
  )
}
