import * as Styles from './CancelButton.module.css'
import { useIsLoggedIn } from 'hooks/useIsLoggedIn'
import { useIsBoundByWidths } from 'hooks/useIsBoundByWidths'
import { mobileWidthDefinitonMap } from '../../Pages/constants'
import ReplyIcon from '@mui/icons-material/Reply'
type TProps = {
  onCancel: () => void
}
export const CancelButton = (props: TProps) => {
  const loggedInResult = useIsLoggedIn()
  const isMobile = useIsBoundByWidths(mobileWidthDefinitonMap)['mobile']

  return (
    <div
      className={`${Styles.Button} ${
        loggedInResult.loggedIn && Styles.LoggedIn
      } ${isMobile && Styles.Mobile}`}
      onClick={() => {
        props.onCancel()
      }}
    >
      <ReplyIcon style={{ fontSize: '32px' }} />
    </div>
  )
}
