import React from 'react'

import CenteredContainer from 'components/CenteredContainer'
import Loader from 'components/Loader'

const RequestInProgressPage: React.FC = () => {
  return (
    <CenteredContainer>
      <Loader />
      <h3>Please wait...</h3>
      <h4>Your request is in progress...</h4>
    </CenteredContainer>
  )
}

export default RequestInProgressPage
