import { mobileWidthDefinition, tabViewWidthDefinition } from 'utils/constants'

export const mobileWidthDefinitonMap = {
  mobile: mobileWidthDefinition,
  tabView: tabViewWidthDefinition,
}

export const cacheTimeoutMS = 5 * 60 * 1000

export const getNiceBottleIDUrlSearch = (
  bottleID: string,
  encryptedID: string
) => {
  if (!bottleID && !encryptedID) {
    return ''
  }

  let search = `?`
  if (!!bottleID) {
    search += `BottleID=${bottleID}`
  }

  if (!!bottleID && !!encryptedID) {
    search += '&'
  }

  if (!!encryptedID) {
    search += `EncryptedID=${encryptedID}`
  }

  return search
}
