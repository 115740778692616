import { Box, Grid, Typography } from '@material-ui/core'
import { Description, Person } from '@material-ui/icons'
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart'

import AppLayout from 'components/AppLayout'
import NavCard from 'components/NavCard'
import { useProfile } from 'Contexts/ProfileContext'
import { Navigate, useNavigate } from 'react-router-dom'

const pages = [
  {
    url: '/analytics/machine-health',
    title: 'Machine Health',
    description: 'View and monitor trends in machine health.',
    background: '#1d2b3e',
    icon: <MonitorHeartIcon />,
  },
]

function Analytics() {
  //NOTE: Temporarily restrict this page to only US Steel users.
  const profile = useProfile()
  const navigate = useNavigate()
  if (!profile.dependentData.userDetails.isAnalyticsCustomer) {
    navigate('/404')
    return null
  }

  return (
    <Grid container direction="row" spacing={4} xs={12}>
      <Grid item md={3} xs={12}>
        <Typography variant="h1">Analytics</Typography>
        <Box style={{ marginTop: '15px' }}>
          <Typography paragraph>
            View machine health, monitor trends, and make informed decisions
            around your equipment.
          </Typography>
        </Box>
      </Grid>
      <Grid container item direction="row" md={9} spacing={4} xs={12}>
        {pages?.map(page => (
          <NavCard
            keyValue={page.url}
            icon={page.icon}
            title={page.title}
            description={page.description}
            url={page.url}
            background={page.background}
          />
        ))}
      </Grid>
    </Grid>
  )
}

export default function AnalyticsPage() {
  return (
    <AppLayout tab="analytics">
      <Analytics />
    </AppLayout>
  )
}
