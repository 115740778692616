import { useState, useContext, useEffect, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Button, Card, Grid, Typography } from '@material-ui/core'
import { TAPIMachineSchedule } from 'types/api'
import AppLayout from 'components/AppLayout'
import Loader from 'components/Loader'
import OfflineBanner from 'components/offline-banner'
import { NewScheduleReportTable } from 'components/tables/NewScheduleReportTable'
import APIService, { TProfile } from 'services/api-service'
import { ProfileContext, useProfile } from 'Contexts/ProfileContext'
import { CancellablePromise } from 'utils/CancellablePromise'

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

const defaults = {
  page: 1,
  pageSize: 10,
  order: 'asc',
  orderBy: 'machine.machineName',
  selectedCustomers: [],
  selectedPlants: [],
  selectedRoutes: [],
  selectedMachineNames: [],
  selectedTestGroupID: null,
  selectedScheduleIDs: [],
  selectAllSchedules: false,
}

const NewSchedule = ({ classes, offline, onError }: any) => {
  const location = useLocation()
  const navigate = useNavigate()

  const [state, setState] = useState({
    ...defaults,
    loaded: false,
    showSpinner: false,
    customers: [],
    plants: [],
    routes: [],
    machines: [],
    machineNames: [],
    machineNos: [],
    testGroups: [],
    schedules: [] as TAPIMachineSchedule[],
    resetSchedules: [] as TAPIMachineSchedule[],
    count: 0,
    applySearch: false,
    saved: false,
    submitting: false,
  })

  const profileContext = useProfile()

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  useEffect(() => {
    setState(prev => ({
      ...prev,
      ...profileContext.profile,
      profileUpdated: profileContext.fullProfileLoaded,
    }))
  }, [profileContext])

  const fetchMachines = useCallback(
    async (variables, schedules) => {
      let promise
      try {
        promise = APIService.getMachines(variables)

        ongoingCancellablePromises.push(promise)
        const data = await promise

        if (data.pagedMachines !== null) {
          onError(null)
          setState(prev => ({
            ...prev,
            machines: data.pagedMachines.items,
            count: data.pagedMachines.count,
            showSpinner: false,
            applySearch: true,
            schedules: schedules,
            resetSchedules: [].concat(schedules),
          }))
        }
      } catch (ex) {
        onError(ex)
      } finally {
        ongoingCancellablePromises.filter(p => p != promise)
      }
    },
    [onError]
  )

  const performSearch = useCallback(() => {
    const initial = location?.state?.selectedSchedules

    setState(prev => ({ ...prev, showSpinner: true, applySearch: false }))

    const schedules: TAPIMachineSchedule[] = []
    let initialPointID

    try {
      for (let i = 0; i < 10; i++) {
        initialPointID = initial[i]
          ? state.machines.find(
              x =>
                x.machNo === initial[i].machine?.machNo &&
                x.machineName === initial[i].machine?.machineName
            )?.pointID
          : null

        schedules.push({
          pointID: initialPointID ?? '',
          testGroupID: null,
          allMonths: false,
          jan: false,
          feb: false,
          mar: false,
          apr: false,
          may: false,
          jun: false,
          jul: false,
          aug: false,
          sep: false,
          oct: false,
          nov: false,
          dec: false,
        })
      }

      fetchMachines(
        {
          page: 1,
          pageSize: 100000,
          order: 'asc',
          orderBy: 'machineName',
          plantIDs: state.selectedPlants,
          routeIDs: state.selectedRoutes,
        },
        schedules
      )
    } catch (ex) {
      navigate('/program-management/schedule')
    }
  }, [
    fetchMachines,
    location?.state?.selectedSchedules,
    state.machines,
    state.selectedPlants,
    state.selectedRoutes,
  ])

  useEffect(() => {
    if (!state.loaded) performSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loaded])

  const handleCancelChangesClick = () => {
    navigate('/program-management/schedule')
  }

  const handleSaveChangesClick = async () => {
    try {
      if (state.submitting === false) {
        const selectedMachine = state.schedules.some(schedule =>
          Number(schedule.pointID)
        )

        const selectedTestPackage = state.schedules.some(schedule =>
          Number(schedule.testGroupID)
        )

        const selectedMonth = state.schedules.some(
          schedule =>
            schedule.allMonths ||
            schedule.jan ||
            schedule.feb ||
            schedule.mar ||
            schedule.apr ||
            schedule.may ||
            schedule.jun ||
            schedule.jul ||
            schedule.aug ||
            schedule.sep ||
            schedule.oct ||
            schedule.nov ||
            schedule.dec
        )

        if (!selectedMachine && !selectedTestPackage && !selectedMonth) {
          throw new Error('At least one schedule is needed to submit.')
        } else if (!selectedMachine) {
          throw new Error('Machine is required.')
        } else if (!selectedTestPackage) {
          throw new Error('Test Package required.')
        } else if (!selectedMonth) {
          throw new Error('At least one month required.')
        }

        const changed = state.schedules.filter(
          x => x.pointID !== '' && x.pointID && x.testGroupID
        )

        try {
          setState(prev => ({ ...prev, submitting: true, showSpinner: true }))
          await APIService.saveMachSchedules({
            machSchedulesList: {
              machSchedules: changed.map(x => {
                return {
                  pointID: x.pointID,
                  testGroupID: x.testGroupID,
                  allMonths: x.allMonths,
                  jan: x.jan,
                  feb: x.feb,
                  mar: x.mar,
                  apr: x.apr,
                  may: x.may,
                  jun: x.jun,
                  jul: x.jul,
                  aug: x.aug,
                  sep: x.sep,
                  oct: x.oct,
                  nov: x.nov,
                  dec: x.dec,
                }
              }),
            },
          })
          navigate('/program-management/schedule')
        } catch (ex) {
          onError(ex)
          setState(prev => ({ ...prev, submitting: false, showSpinner: false }))
        }
      }
    } catch (error) {
      onError(error)
    }
  }

  const handleScheduleChange = (event, newSchedule, rowIndex) => {
    setState(prev => ({
      ...prev,
      schedules: state.schedules.map((originalSchedule, mapIndex) =>
        mapIndex === rowIndex ? newSchedule : originalSchedule
      ),
    }))
  }

  return (
    <>
      <Box mb={5}>
        <Typography variant="h1">Add New Schedule</Typography>
      </Box>
      {offline ? <OfflineBanner /> : <></>}
      <form>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          spacing={2}
        ></Grid>
      </form>
      {(!state.machines && !state.testGroups) ||
      state.showSpinner ||
      state?.machines?.length == null ||
      state?.machines?.length === 0 ? (
        <Loader />
      ) : (
        <>
          <Card style={{ padding: '10px' }} elevation={2}>
            <Typography variant="h2">Instructions</Typography>
            <Typography variant="body1">
              To Create a new schedule:
              <ul>
                <li>
                  Select the equipment that you would like to apply a sampling
                  schedule to.
                </li>
                <li>
                  Select the Test Package that you would like for this schedule.
                </li>
                <li>
                  Select the months that you would like to sample this
                  equipment.
                </li>
                <li>Click the Save Changes button to commit these changes.</li>
              </ul>
            </Typography>
          </Card>

          <Box mt={4}>
            <NewScheduleReportTable
              customers={state.customers}
              plants={state.plants}
              machines={state.machines}
              machineNos={state.machineNos}
              testGroups={state.testGroups}
              schedules={state.schedules}
              selectedSchedules={location?.state?.selectedSchedules}
              classes={classes}
              onScheduleChange={handleScheduleChange}
            />
          </Box>
          <form>
            <Box mt={5}>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveChangesClick}
                  >
                    Save Changes
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCancelChangesClick}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </>
      )}
    </>
  )
}

export default function Page(w) {
  return (
    <AppLayout tab="program-management">
      <NewSchedule />
    </AppLayout>
  )
}
