import React from 'react'
import { daysDelinquentOptions } from 'services/lookup-service'

import { SelectFilter } from '../BaseFilters/SelectFilter'
import FilterTooltip from '../FilterTooltip/FilterTooltip'

type TProps = {
  single: boolean
  required: boolean
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange?: (props: number | number[]) => void
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setState?: (props: any) => void
  value?: number | number[]
  defaultValue?: number | number[]
  tooltip?: boolean
}

export const DaysDelinquentFilter = ({
  single,
  required,
  onChange,
  setState,
  defaultValue,
  value,
  tooltip = true,
}: TProps) => {
  const filter = (
    <SelectFilter
      label="Days Delinquent"
      multiple={!single}
      required={required}
      defaultValue={
        daysDelinquentOptions.find(opt => opt === defaultValue) || null
      }
      id="days"
      options={[...daysDelinquentOptions]}
      renderValue={selected => selected?.toString()}
      onChange={onChange}
      htmlFor="DaysDelinquent"
    ></SelectFilter>
  )

  return tooltip ? (
    <FilterTooltip title="The number of days a machine has gone beyond its last scheduled sample test date.">
      {filter}
    </FilterTooltip>
  ) : (
    filter
  )
}
