import { TAPIRoute } from 'types/api'

export const tableFormatter = (
  routes: TAPIRoute[],
  hasDivisions: boolean
): string[][] => {
  const headerValues = [
    ...(hasDivisions ? ['Division Name'] : []),
    'Customer Name',
    'Route Number',
    'Route Name',
  ]

  const formattedData = routes.map(datum => [
    ...(hasDivisions ? [datum.customer.division.name] : []),
    datum.customer.custName,
    datum.routeNo?.toString(),
    datum.name,
  ])

  return [headerValues, ...formattedData]
}
