import * as PageStyles from '../page-styles.module.css'
import { PageCard } from '../Pages/PageContainers'
import { TWarhammerSampleWithoutIdentifier } from '../types-and-enums'
import { InTestCardModule } from '../CardModules/InTestCardModule'
import { useIsLoggedIn } from 'hooks/useIsLoggedIn'

type TProps = {
  bottleData: TWarhammerSampleWithoutIdentifier
}
export const BottleInTest = (props: TProps) => {
  const isLoggedIn = useIsLoggedIn()

  return (
    <PageCard>
      <div
        className={`${PageStyles.EntryScreen}  ${PageStyles.WithBottleButton} ${
          isLoggedIn.loggedIn ? PageStyles.LoggedIn : ''
        }`}
      >
        <InTestCardModule bottleData={props.bottleData} />
      </div>
    </PageCard>
  )
}
