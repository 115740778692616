import { Button } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import React from 'react'
type Props = {
  onClose: () => void
}
export const MissingFinancialsErrorScreen = ({ onClose }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <HighlightOffIcon
        style={{ color: 'rgb(225, 25, 25)', fontSize: '350px' }}
      />
      <p>No billing information registered!</p>
      <p style={{ width: 'clamp(306px, 41%, 100%)', textAlign: 'justify' }}>
        This customer or scoped user account does not have any billing contact
        information registered with {process.env.REACT_APP_COMPANY_NAME}. Please
        contact customer service via the chat or email customer service at{' '}
        <a
          href={`mailto: ${process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL}?Subject=Billing%20Information%20Registration`}
        >
          {process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL}
        </a>
        .
      </p>
      <Button
        variant="contained"
        component="label"
        color="primary"
        style={{ height: '50px', width: '300px', marginBottom: '50px' }}
        onClick={onClose}
      >
        Close
      </Button>
    </div>
  )
}
