import React, { useState } from 'react'
import { Box, Grid, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import PropTypes from 'prop-types'

import AppLayout from 'components/AppLayout'
import SEO from 'components/SEO'

const terms = [
  {
    name: 'Abrasion',
    value:
      'a general wearing away of a surface by constant scratching, usually due to the presence of foreign matter such as dirt, grit, or metallic particles in the lubricant. It may also cause a break down of the material (such as the tooth surfaces of gears). Lack of proper lubrication may result in abrasion.',
  },

  {
    name: 'Abrasive wear',
    value:
      '(or cutting wear) comes about when hard surface asperities or hard particles that have embedded themselves into a soft surface and plough grooves into the opposing harder surface, e.g., a journal.',
  },

  {
    name: 'Absolute filtration rating',
    value:
      'the diameter of the largest hard spherical particle that will pass through a filter under specified test conditions. This is an indication of the largest opening in the filter elements.',
  },

  {
    name: 'Absolute Viscosity',
    value:
      "a term used interchangeably with viscosity to distinguish it from either kinematic viscosity or commercial viscosity. Absolute viscosity is the ratio of shear stress to shear rate. It is a fluid's internal resistance to flow. The common unit of absolute viscosity is the poise. Absolute viscosity divided by fluid density equals kinematic viscosity. It is occasionally referred to as dynamic viscosity. Absolute viscosity and kinematic viscosity are expressed in fundamental units. Commercial viscosity such as Saybolt viscosity is expressed in arbitrary units of time, usually seconds.",
  },

  {
    name: 'Absorbent filter',
    value: 'a filter medium that holds contaminant by mechanical means.',
  },

  {
    name: 'Acidity',
    value:
      'in lubricants, acidity denotes the presence of acid-type constituents whose concentration is usually defined in terms of total acid number. The constituents vary in nature and may or may not markedly influence the behavior of the lubricant.',
  },

  {
    name: 'Additive',
    value:
      'a compound that enhances some property of, or imparts some new property to, the base fluid.  he more important types of additives include anti-oxidants, anti-wear additives, corrosion inhibitors, viscosity index improvers, and foam suppressants.',
  },

  {
    name: 'Additive stability',
    value:
      'the ability of additives in the fluid to resist changes in their performance during storage or use.',
  },

  {
    name: 'Adhesive wear',
    value:
      'is often referred to as galling, scuffing, scoring, or seizing.  It happens when sliding surfaces contact one another, causing fragments to be pulled from one surface and to adhere to the other.',
  },

  {
    name: 'Adsorbent filter',
    value:
      'a filter medium primarily intended to hold soluble and insoluble contaminants on its surface by molecular adhesion.',
  },

  {
    name: 'Aeration',
    value:
      'the state of air being suspended in a liquid such as a lubricant or hydraulic fluid.',
  },

  {
    name: 'A.G.M.A.',
    value:
      'abbreviation for "American Gear Manufacturers Associations", an organization serving the gear industry.',
  },

  {
    name: 'Air Breather',
    value:
      'a device permitting air movement between atmosphere and the component in/on which it is installed.',
  },

  {
    name: 'Alarm Setpoint',
    value:
      'Any value beyond which is considered unacceptable or dangerous to machinery operation.',
  },

  {
    name: 'Alkali',
    value:
      'any substance having basic (as opposed to acidic) properties. In a restricted sense it is applied to the hydroxides of ammonium, lithium, potassium and sodium. Alkaline materials in lubricating oils neutralize acids to prevent acidic and corrosive wear in internal combustion engines.',
  },

  {
    name: 'Analytical ferrography',
    value:
      'the magnetic precipitation and subsequent analysis of wear debris from a fluid sample This approach involves passing a volume of fluid over a chemically treated microscope slide which is supported over a magnetic field. Permanent magnets are arranged in such a way as to create varying field strength over the length of the substrate. This varying strength causes wear debris to precipitate in a distribution with respect to size and mass over the Ferrogram. Once rinsed and fixed to the substrate, this debris deposit serves as an excellent media for optical analysis of the composite wear particulates.',
  },

  {
    name: 'Anhydrous',
    value: 'devoid of water.',
  },

  {
    name: 'Anti-foam agent',
    value:
      'one of two types of additives used to reduce foaming in petroleum products: silicone oil to break up large surface bubbles, and various kinds of polymers that decrease the amount of small bubbles entrained in the oils.',
  },

  {
    name: 'Anti-friction bearing',
    value:
      'a rolling contact type bearing in which the rotating or moving member is supported or guided by means of ball or roller elements. Does not mean without friction.',
  },

  {
    name: 'Anti-oxidants',
    value:
      'prolong the induction period of a base oil in the presence of oxidizing conditions and catalyst metals at elevated temperatures. The additive is consumed and degradation products increase not only with increasing and sustained temperature, but also with increases in mechanical agitation or turbulence and contamination -- air, water, metallic particles, and dust.',
  },

  {
    name: 'Antistatic additive',
    value:
      'an additive that increases the conductivity of a hydrocarbon fuel to hasten the dissipation of electrostatic charges during high-speed dispensing, thereby reducing the fire/explosion hazard.',
  },

  {
    name: 'Antiwear additives',
    value:
      'improve the service life of tribological elements operating in the boundary lubrication regime. Antiwear compounds (for example, ZDDP and TCP) start decomposing at 90? to 100?C and even at a lower temperature if water (25 to 50 ppm) is present.',
  },

  {
    name: 'API gravity',
    value:
      'a gravity scale established by the American Petroleum Institute and in general use in the petroleum industry, the unit being called "the A.P.I.degree." This unit is defined in terms of specific gravity as follows: 141.5 - 131.5 > (Specific gravity 60? F/60? F )',
  },

  {
    name: 'Ash',
    value:
      'a measure of the amount of inorganic material in lubricating oil.  Determined by burning the oil and weighing the residue. Results expressed as percent by weight.',
  },

  {
    name: 'Asperities',
    value:
      'microscopic projections on metal surfaces resulting from normal surface-finishing processes. Interference between opposing asperities in sliding or rolling applications is a source of friction, and can lead to metal welding and scoring. Ideally, the lubricating film between two moving surfaces should be thicker than the combined height of the opposing asperities.',
  },

  {
    name: 'A.S.T.M.',
    value:
      '"American Society for Testing Materials" a society for developing standards for materials and test methods.',
  },

  {
    name: 'Atomic absorption spectroscopy',
    value:
      'measures the radiation absorbed by chemically unbound atoms by analyzing the transmitted energy relative to the incident energy at each frequency. The procedure consists of diluting the fluid sample with methyl isobutyl ketone (MIBK) and directly aspirating the solution. The actual process of atomization involves reducing the solution to a fine spray, dissolving it, and finally vaporizing it with a flame. The vaporization of the metal particles depends upon their time in the flame, the flame temperature, and the composition of the flame gas. The spectrum occurs because atoms in the vapor state can absorb radiation at certain well-defined characteristic wave lengths. The wave length bands absorbed are very narrow and differ for each element. In addition, the absorption of radiant energy by electronic transitions from ground to excited state is essentially and absolute measure of the number of atoms in the flame and is, therefore, the concentration of the element in a sample.',
  },

  {
    name: 'Axis',
    value:
      'The reference plane used in plotting routines. Plots can be 2D (X-axis / Y-axis) or 3D (X-axis / Y-axis / Z-axis ).',
  },

  {
    name: 'Babbitt',
    value:
      'a soft, white, non-ferrous alloy bearing material composed principally of copper, antimony, tin and lead.',
  },

  {
    name: 'Bactericide',
    value:
      'additive included in the formulations of water-mixed cutting fluids to inhibit the growth of bacteria promoted by the presence of water, thus preventing odors that can result from bacterial action.',
  },

  {
    name: 'Base',
    value:
      'a material which neutralizes acids. An oil additive containing colloidally dispersed metal carbonate, used to reduce corrosive wear.',
  },

  {
    name: 'Base stock',
    value:
      'the base fluid, usually a refined petroleum fraction or a selected synthetic material, into which additives are blended to produce finished lubricants.',
  },

  {
    name: 'Baseline Value',
    value:
      'A measurement taken when a machine is in good operating condition; used as a reference for monitoring and analysis.',
  },

  {
    name: 'Baud Rate',
    value:
      'Adjustable serial communication transfer rate. Measured in bits per second.',
  },

  {
    name: 'Beta Rating',
    value:
      'the method of comparing filter performance based on efficiency. This is done using the Multi-Pass Test which counts the number of particles of a given size before and after fluid passes through a filter.',
  },

  {
    name: 'Beta-Ratio (?-Ratio)',
    value:
      'the ratio of the number of particles greater than a given size in the influent fluid to the number of particles greater than the same size in the effluent fluid, under specified test conditions (see "Multi - Pass Test").',
  },

  {
    name: 'Black oils',
    value:
      'lubricants containing asphaltic materials, which impart extra adhesiveness, that are used for open gears and steel cables.',
  },

  {
    name: 'Blow-by',
    value:
      'passage of unburned fuel and combustion gases past the piston rings of internal combustion engines, resulting in fuel dilution and contamination of the crankcase oil.',
  },

  {
    name: 'Boundary lubrication',
    value:
      'form of lubrication between two rubbing surfaces without development of a full-fluid lubricating film. Boundary lubrication can be made more effective by including additives in the lubricating oil that provide a stronger oil film, thus preventing excessive friction and possible scoring. There are varying degrees of boundary lubrication, depending on the severity of service. For mild conditions, oiliness agents may be used; by plating out on metal surfaces in a thin but durable film, oiliness agents prevent scoring under some conditions that are too severe for a straight mineral oil.  Compounded oils, which are formulated with polar fatty oils, are sometimes used for this purpose. Anti-wear additives are commonly used in more severe boundary lubrication applications. The more severe cases of boundary lubrication are defined as extreme pressure conditions; they are met with lubricants containing EP additives that prevent sliding surfaces from fusing together at high local temperatures and pressures.',
  },

  {
    name: 'Brookfield viscosity',
    value:
      'apparent viscosity in cp. determined by Brookfield viscometer, which measures the torque required to rotate a spindle at constant speed in oil of a given temperature. Basis for ASTM Method D 2983; used for measuring low temperature viscosity of lubricants.',
  },

  {
    name: 'Bypass Filtration',
    value:
      'a system of filtration in which only a portion of the total flow of a circulating fluid system passes through a filter at any instant or in which a filter having its own circulating pump operates in parallel to the main flow.',
  },

  {
    name: 'Buffer',
    value: 'An area in computer memory set aside as working space.',
  },

  {
    name: 'Capacity',
    value:
      'the amount of contaminants a filter will hold before an excessive pressure drop is caused. Most filters have bypass valves which open when a filter reaches its rated capacity.',
  },

  {
    name: 'Carbon residue',
    value:
      'coked material remaining after oil has been exposed to high temperatures under controlled conditions.',
  },

  {
    name: 'Carbonyl iron powder',
    value: 'a contaminant that consists of up to 99.5% pure iron spheres.',
  },

  {
    name: 'Case drain filter',
    value:
      'a filter located in a line conducting fluid from a pump or motor housing to reservoir.',
  },

  {
    name: 'Catalyst',
    value:
      'a substance which speeds a chemical action without undergoing a chemical change itself during the process. Now used in catalytic converters to control amount of unburned hydrocarbons and CO in automobile exhaust.',
  },

  {
    name: 'Caution: High',
    value: 'A pre-shutdown alarm operating in the high cautionary region.',
  },

  {
    name: 'Caution: Low',
    value: 'A pre-shutdown alarm operating in the low cautionary region.',
  },

  {
    name: 'Cavitation',
    value:
      'formation of an air or vapor pocket (or bubble) due to lowering of pressure in a liquid, often as a result of a solid body, such as a propeller or piston, moving through the liquid; also, the pitting or wearing away of a solid surface as a result of the collapse of a vapor bubble. Cavitation can occur in a hydraulic system as a result of low fluid levels that draw air into the system, producing tiny bubbles that expand explosively at the pump outlet, causing metal erosion and eventual pump destruction.',
  },

  {
    name: 'Cavitation erosion',
    value:
      'a material-damaging process which occurs as a result of vaporous cavitation. "Cavitation" refers to the occurrence or formation of gas- or vapor- filled pockets in flowing liquids due to the hydrodynamic generation of low pressure (below atmospheric pressure). This damage results from the hammering action when cavitation bubbles implode in the flow stream. Ultra-high pressures caused by the collapse of the vapor bubbles produce deformation, material failure and, finally, erosion of the surfaces.',
  },

  {
    name: 'Centipoise (cp)',
    value: 'a unit of absolute viscosity. 1 centipoise = 0.01 poise.',
  },

  {
    name: 'Centistoke (cst)',
    value: 'a unit of kinematic viscosity. 1 centistoke = 0.01 stoke.',
  },

  {
    name: 'Centralized lubrication',
    value:
      'a system of lubrication in which a metered amount of lubricant or lubricants for the bearing surfaces of a machine or group of machines are supplied from a central location.',
  },

  {
    name: 'Centrifugal separator',
    value:
      'a separator that removes immiscible fluid and solid contaminants that have a different specific gravity than the fluid being purified by accelerating the fluid mechanically in a circular path and using the radial acceleration component to isolate these contaminants.',
  },

  {
    name: 'Circulating lubrication',
    value:
      'a system of lubrication in which the lubricant, after having passed through a bearing or group of bearings, is recirculated by means of a pump.',
  },

  {
    name: 'Cleanable',
    value:
      'a filter element which, when loaded, can be restored by a suitable process, to an acceptable percentage of its original dirt capacity.',
  },

  {
    name: 'Clean room',
    value:
      'a facility or enclosure in which air content and other conditions (such as temperature, humidity, and pressure) are controlled and maintained at a specific level by special facilities and operating processes and by trained personnel.',
  },

  {
    name: 'Cleanliness level (CL)',
    value: 'a measure of relative freedom from contaminants.',
  },

  {
    name: 'Cloud point',
    value:
      'the temperature at which waxy crystals in an oil or fuel form a cloudy appearance.',
  },

  {
    name: 'Coefficient of friction',
    value:
      'the number obtained by dividing the friction force resisting motion between two bodies by the normal force pressing the bodies together.',
  },

  {
    name: 'Cohesion',
    value:
      'that property of a substance that causes it to resist being pulled apart by mechanical means.',
  },

  {
    name: 'Compounded oil',
    value: 'a petroleum oil to which has been added other chemical substances.',
  },

  {
    name: 'Compressibility',
    value:
      'the change in volume of a unit volume of a fluid when subjected to a unit change of pressure.',
  },

  {
    name: 'Compression ratio',
    value:
      'in an internal combustion engine, the ratio of the volume of combustion space at bottom dead center to that at top dead center.',
  },

  {
    name: 'Compressor',
    value:
      'a device which converts mechanical force and motion into pneumatic fluid power.',
  },

  {
    name: 'Condition Monitoring',
    value:
      'Determining the condition of a machine by interpretation of measurements taken either periodically or continuously while the machine is running.',
  },

  {
    name: 'Consistency',
    value:
      'the degree to which a semisolid material such as grease resists deformation. (See ASTM designation D 217.) Sometimes used qualitatively to denote viscosity of liquids.',
  },

  {
    name: 'Contaminant',
    value:
      'any foreign or unwanted substance that can have a negative effect on system operation, life or reliability.',
  },

  {
    name: 'Contaminant (Dirt, ACFTD) capacity',
    value:
      'the weight of a specified artificial contaminant that must be added to the influent to produce a given differential pressure across a filter at specified conditions. Used as an indication of relative service life.',
  },

  {
    name: 'Contaminant Failure',
    value: `
                <p className="custparag2">
                    any loss of performance due to the presence of contamination. Two basic types of contamination failure are:

                <ul className="arbag2">
                    <li className="tro">Perceptible - gradual loss of efficiency or performance</li>
                    <li className="tro">Catastrophic - dramatic, unexpected failure.</li>
                </ul>
        `,
  },

  {
    name: 'Contamination control',
    value:
      'a broad subject that applies to all types of material systems (including both biological and engineering). It is concerned with planning, organizing, managing, and implementing all activities required to determine, achieve and maintain a specified contamination level.',
  },

  {
    name: 'Coolant',
    value: 'a fluid used to remove heat. See Cutting fluid.',
  },

  {
    name: '% Correlation',
    value:
      'the percentage of peaks in the used oil infrared spectrum which match those in the reference oil. A sudden decrease in this value usually means that the oil was mixed with a different type.',
  },

  {
    name: 'Corrosion',
    value:
      'the decay and loss of a metal due to a chemical reaction between the metal and its environment. It is a transformation process in which the metal passes from its elemental form to a combined (or compound) form.',
  },

  {
    name: 'Corrosion inhibitor',
    value:
      'additive for protecting lubricated metal surfaces against chemical attack by water or other contaminants. There are several types of corrosion inhibitors. Polar compounds wet the metal surface preferentially, protecting it with a film of oil. Other compounds may absorb water by incorporating it in a water-in-oil emulsion so that only the oil touches the metal surface. Another type of corrosion inhibitor combines chemically with the metal to present a non-reactive surface.',
  },

  {
    name: 'Cracking',
    value:
      'the process whereby large molecules are broken down by the application of heat and pressure to form smaller molecules.',
  },

  {
    name: 'Critical: High',
    value: 'A shutdown or trip alarm operating in the high danger region.',
  },

  {
    name: 'Critical: Low',
    value: 'A shutdown or trip alarm operating in the low danger region.',
  },

  {
    name: 'Cursor',
    value:
      'The indication on a plot for which data is displayed. The cursor is positioned with the target mouse pointer.',
  },

  {
    name: 'Curve fitting',
    value:
      'The process whereby coefficients of an arbitrary function are computed such that the evaluated function approximates the values in a given data set. A mathematical function, such as the minimum mean squared error, is used to judge the goodness of fit.',
  },

  {
    name: 'Cutting fluid',
    value:
      'any fluid applied to a cutting tool to assist in the cutting operation by cooling, lubricating or other means.',
  },

  {
    name: 'Cycle',
    value:
      'a single complete operation consisting of progressive phases starting and ending at the neutral position.',
  },

  {
    name: 'Cylinder',
    value:
      'a device which converts fluid power into linear mechanical force and motion. It usually consists of a moveable element such as a piston and piston rod, plunger rod, plunger or ram, operating with in a cylindrical bore.',
  },

  {
    name: 'Dehydrator',
    value: 'a separator that removes water from the system fluid.',
  },

  {
    name: 'Delamination wear',
    value:
      'a complex wear process where a machine surface is peeled away or otherwise removed by forces of another surface acting on it in a sliding motion.',
  },

  {
    name: 'Demulsibility',
    value:
      'the ability of a fluid that is insoluble in water to separate from water with which it may be mixed in the form of an emulsion.',
  },

  {
    name: 'Deposits',
    value:
      'oil-insoluble materials that result from oxidation and decomposition of lube oil and contamination from external sources and engine blow-by. These can settle out on machine or engine parts. Examples are sludge, varnish, lacquer and carbon.',
  },

  {
    name: 'Detergent',
    value:
      'in lubrication, either an additive or a compounded lubricant having the property of keeping insoluble matter in suspension thus preventing its deposition where it would be harmful. A detergent may also redisperse deposits already formed.',
  },

  {
    name: 'Dielectric Strength',
    value:
      'a measure of the ability of an insulating material to withstand electric stress (voltage) without failure. Fluids with high dielectric strength (usually expressed in volts or kilovolts) are good electrical insulators. (ASTM Designation D 877.)',
  },

  {
    name: 'Dirt capacity (dust capacity) (contaminant capacity)',
    value:
      'the weight of a specified artificial contaminant which must be added to the influent to produce a given differential pressure across a filter at specified conditions. Used as an indication of relative service life.',
  },

  {
    name: 'Dispersant',
    value:
      'in lubrication, a term usually used interchangeably with detergent. An additive, usually nonmetallic ("ashless"), which keeps fine particles of insoluble materials in a homogeneous solution. Hence, particles are not permitted to settle out and accumulate.',
  },

  {
    name: 'Dissolved gases',
    value:
      'those gases that enter into solution with a fluid and are neither free nor entrained gases.',
  },

  {
    name: 'Distillation method (ASTM D-95)',
    value:
      'a method involving distilling the fluid sample in the presence of a solvent that is miscible in the sample but immiscible in water. The water distilled from the fluid is condensed and segregated in a specially-designed receiving tube or tray graduated to directly indicate the volume of water distilled.',
  },

  {
    name: 'Download',
    value:
      'Transferring information to the monitoring equipment from the host computer.',
  },

  {
    name: 'Effluent',
    value: 'the fluid leaving a component.',
  },

  {
    name: 'Elastohydrodynamic lubrication',
    value:
      'in rolling element bearings, the elastic deformation of the bearing (flattening) as it rolls, under load, in the bearing race. This momentary flattening improves the hydrodynamic lubrication properties by converting point or line contact to surface-to-surface contact.',
  },

  {
    name: 'Electrostatic separator',
    value:
      'a separator that removes contaminant from dielectric fluids by applying an electrical charge to the contaminant that is then attracted to a collection device of different electrical charge.',
  },

  {
    name: 'Element (Cartridge)',
    value: 'the porous device that performs the actual process of filtration.',
  },

  {
    name: 'Emission spectrometer',
    value:
      'works on the basis that atoms of metallic and other particular elements emit light at characteristic wavelengths when they are excited in a flame, arc, or spark. Excited light is directed through an entrance slit in the spectrometer. This light penetrates the slit, falls on a grate, and is dispersed and reflected. The spectrometer is calibrated by a series of standard samples containing known amounts of the elements of interest. By exciting these standard samples, an analytical curve can be established which gives the relationship between the light intensity and its concentration in the fluid.',
  },

  {
    name: 'Emulsibility',
    value:
      'the ability of a non-water-soluble fluid to form an emulsion with water.',
  },

  {
    name: 'Emulsifier',
    value:
      'additive that promotes the formation of a stable mixture, or emulsion, of oil and water. Common emulsifiers are: metallic soaps, certain animal and vegetable oils, and various polar compounds.',
  },

  {
    name: 'Emulsion',
    value:
      'intimate mixture of oil and water, generally of a milky or cloudy appearance. Emulsions may be of two types: oil-in water (where water is the continuous phase) and water-in-oil (where water is the discontinuous phase).',
  },

  {
    name: 'End cap',
    value: 'a ported or closed cover for the end of a filter element.',
  },

  {
    name: 'Engine deposits',
    value:
      'hard or persistent accumulation of sludge, varnish and carbonaceous residues due to blow-by of unburned and partially burned fuel, or the partial breakdown of the crankcase lubricant. Water from the condensation of combustion products, carbon, residues from fuel or lubricating oil additives, dust and metal particles also contribute.',
  },

  {
    name: 'Environmental contaminant',
    value:
      'all material and energy present in and around an operating system, such as dust, air moisture, chemicals, and thermal energy.',
  },

  {
    name: 'EP (Extreme Pressure) lubricants',
    value:
      'lubricants that impart to rubbing surfaces the ability to carry appreciably greater loads than would be possible with ordinary lubricants without excessive wear or damage.',
  },

  {
    name: 'Erosion',
    value:
      'the progressive removal of a machine surface by cavitation or by particle impingement at high velocities.',
  },

  {
    name: 'Extreme pressure (EP) additive',
    value:
      'lubricant additive that prevents sliding metal surfaces from seizing under conditions of extreme pressure. At the high local temperatures associated with metal-to-metal contact, an EP additive combines chemically with the metal to form a surface film that prevents the welding of opposing asperities, and the consequent scoring that is destructive to sliding surfaces under high loads. Reactive compounds of sulfur, chlorine, or phosphorus are used to form these inorganic films.',
  },

  {
    name: 'False brinelling',
    value:
      'false brinelling of needle roller bearings is actually a fretting corrosion of the surface since the rollers are the I.D. of the bearing. Although its appearance is similar to that of brinelling, false brinelling is characterized by attrition of the steel, and the load on the bearing is less than that required to produce the resulting impression. It is the result of a combination of mechanical and chemical action that is not completely understood, and occurs when a small relative motion or vibration is accompanied by some loading, in the presence of oxygen.',
  },

  {
    name: 'Fatigue chunks',
    value:
      'thick three-dimensional particles exceeding 50 microns indicating severe wear of gear teeth.',
  },

  {
    name: 'Fatigue platelets',
    value:
      'normal particles between 20 and 40 microns found in gear box and rolling element bearing oil samples observed by analytical ferrography. A sudden increase in the size and quantity of these particles indicates excessive wear.',
  },

  {
    name: 'Fatigued',
    value:
      'a structural failure of the filter medium due to flexing caused by cyclic differential pressure.',
  },

  {
    name: 'Ferrography',
    value:
      'an analytical method of assessing machine health by quantifying and examining ferrous wear particles suspended in the lubricant or hydraulic fluid.',
  },

  {
    name: 'Field',
    value:
      'One data item. Examples of fields are machine name, node #, long ID, etc.',
  },

  {
    name: 'Film strength',
    value:
      'property of a lubricant that acts to prevent scuffing or scoring of metal parts.',
  },

  {
    name: 'Filter',
    value:
      'any device or porous substance used as a strainer for cleaning fluids by removing suspended matter.',
  },

  {
    name: 'Filter Efficiency',
    value:
      "method of expressing a filter's ability to trap and retain contaminants of a given size.",
  },

  {
    name: 'Filter element',
    value: 'the porous device which performs the actual process of filtration.',
  },

  {
    name: 'Filter head',
    value:
      'an end closure for the filter case or bowl that contains one or more ports.',
  },

  {
    name: 'Filter housing',
    value:
      'a ported enclosure that directs the flow of fluid through the filter element.',
  },

  {
    name: 'Filter life test',
    value:
      'a type of filter capacity test in which a clogging contaminant is added to the influent of a filter, under specified test conditions, to produce a given rise in pressure drop across the filter or until a specified reduction of flow is reached. Filter life may be expressed as test time required to reach terminal conditions at a specified contaminant addition rate.',
  },

  {
    name: 'Filter media, depth',
    value:
      'porous materials which primarily retain contaminants within a tortuous path, performing the actual process of filtration.',
  },

  {
    name: 'Filter media, surface',
    value:
      'porous materials which primarily retain contaminants on the influent face, performing the actual process of filtration.',
  },

  {
    name: 'Filtration (Beta) ratio',
    value:
      'the ratio of the number of particles greater than a given size in the influent fluid to the number of particles greater than the same size in the effluent fluid.',
  },

  {
    name: 'Filtration',
    value:
      'the physical or mechanical process of separating insoluble particulate matter from a fluid, such as air or liquid, by passing the fluid through a filter medium that will not allow the particulates to pass through it.',
  },

  {
    name: 'Fire point (Cleveland Open Cup)',
    value:
      'the temperature to which a combustible liquid must be heated so that the released vapor will burn continuously when ignited under specified conditions.',
  },

  {
    name: 'Fire-resistant fluid',
    value:
      'lubricant used especially in high-temperature or hazardous hydraulic applications. Three common types of fire-resistant fluids are: (1) water-petroleum oil emulsions, in which the water prevents burning of the petroleum constituent; (2) water-glycol fluids; and (3) non-aqueous fluids of low volatility, such as phosphate esters, silicones, and halogenated hydrocarbon-type fluids.',
  },

  {
    name: 'Flash point (Cleveland Open Cup)',
    value:
      'the temperature to which a combustible liquid must be heated to give off sufficient vapor to form momentarily a flammable mixture with air when a small flame is applied under specified conditions. (ASTM Designation D 92.)',
  },

  {
    name: 'Flow, laminar',
    value:
      'a flow situation in which fluid moves in parallel lamina or layers.',
  },

  {
    name: 'Flow, turbulent',
    value:
      'a flow situation in which the fluid particles move in a random manner.',
  },

  {
    name: 'Flow fatigue rating',
    value:
      'the ability of a filter element to resist a structural failure of the filter medium due to flexing caused by cyclic differential pressure.',
  },

  {
    name: 'Flow rate',
    value:
      'the volume, mass, or weight of a fluid passing through any conductor per unit of time.',
  },

  {
    name: 'Flowmeter',
    value:
      'a device which indicates either flow rate, total flow, or a combination of both.',
  },

  {
    name: 'Fluid',
    value: 'a general classification including liquids and gases.',
  },

  {
    name: 'Fluid compatibility',
    value:
      'the suitability of filtration medium and seal materials for service with the fluid involved.',
  },

  {
    name: 'Fluid friction',
    value: 'friction due to the viscosity of fluids.',
  },

  {
    name: 'Fluid opacity',
    value: 'related to the ability of a fluid to pass light.',
  },

  {
    name: 'Flushing',
    value:
      'a fluid circulation process designed to remove contamination from the wetted surfaces of a fluid system.',
  },

  {
    name: 'Force feed lubrication',
    value:
      'a system of lubrication in which the lubricant is supplied to the bearing surface under pressure.',
  },

  {
    name: 'Fretting',
    value:
      'wear phenomena taking place between two surfaces having oscillatory relative motion of small amplitude.',
  },

  {
    name: 'Fretting corrosion',
    value:
      'can take place when two metals are held in contact and subjected to repeated small sliding, relative motions. Other names for this type of corrosion include wear oxidation, friction oxidation, chafing, and brinelling.',
  },

  {
    name: 'Friction',
    value:
      'the resisting force encountered at the common boundary between two bodies when, under the action of an external force, one body, moves or tends to move relative to the surface of the other.',
  },

  {
    name: 'FTIR = Fourier Transform Infrared Spectroscopy',
    value:
      'a test where infrared light absorption is used for assessing levels of soot, sulfates, oxidation, nitro-oxidation, glycol, fuel, and water contaminants.',
  },

  {
    name: 'Full flow filter',
    value:
      'a filter that, under specified conditions, filters all influent flow.',
  },

  {
    name: 'Full-flow filtration',
    value:
      'a system of filtration in which the total flow of a circulating fluid system passes through a filter.',
  },

  {
    name: 'Full-fluid-film lubrication',
    value:
      'presence of a continuous lubricating film sufficient to completely separate two surfaces, as distinct from boundary lubrication. Full-fluid-film lubrication is normally hydrodynamic lubrication, whereby the oil adheres to the moving part and is drawn into the area between the sliding surfaces, where it forms a pressure or hydrodynamic wedge.',
  },

  {
    name: 'Galling',
    value:
      'a form of wear in which seizing or tearing of the gear or bearing surface occurs.',
  },

  {
    name: 'Generated contaminant',
    value:
      'caused by a deterioration of critical wetted surfaces and materials or by a breakdown of the fluid itself.',
  },

  {
    name: 'Gravimetric analysis',
    value:
      'a method of analysis whereby the dry weight of contaminant per unit volume of fluid can be measured showing the degree of contamination in terms of milligrams of contaminant per liter of fluid.',
  },

  {
    name: 'Gravity',
    value: 'See Specific Gravity; API Gravity.',
  },

  {
    name: 'Grease',
    value:
      'a lubricant composed of an oil or oils thickened with a soap, soaps or other thickener to a semisolid or solid consistency.',
  },

  {
    name: 'Heat exchanger',
    value:
      'a device which transfers heat through a conducting wall from one fluid to another.',
  },

  {
    name: 'Hierarchy',
    value:
      'A method of organizing equipment into logical groups or physical areas. This format can have many levels, but it usually has consists of four or five levels consisting of plant names, physical areas, machine IDs, machinery component IDs, and measurement points.',
  },

  {
    name: 'Housing',
    value:
      'a ported enclosure which directs the flow of fluid through the filter element.',
  },

  {
    name: 'Hydraulic Fluid',
    value:
      'fluid serving as the power transmission medium in a hydraulic system. The most commonly used fluids are petroleum oils, synthetic lubricants, oil-water emulsions, and water-glycol mixtures. The principal requirements of a premium hydraulic fluid are proper viscosity, high viscosity index, anti-wear protection (if needed), good oxidation stability, adequate pour point, good demulsibility, rust inhibition, resistance to foaming, and compatibility with seal materials. Anti-wear oils are frequently used in compact, high-pressure, and capacity pumps that require extra lubrication protection.',
  },

  {
    name: 'Hydraulic Oil',
    value:
      'an oil specially suited for use as either the specific gravity or the API gravity of a liquid.',
  },

  {
    name: 'Hydrodynamic lubrication',
    value:
      'a system of lubrication in which the shape and relative motion of the sliding surfaces causes the formation of a fluid film having sufficient pressure to separate the surfaces.',
  },

  {
    name: 'Hydrofinishing',
    value:
      'a process for treating raw extracted base stocks with hydrogen to saturate them for improved stability.',
  },

  {
    name: 'Hydrolysis',
    value:
      'breakdown process that occurs in anhydrous hydraulic fluids as a result of heat, water, and metal catalysts (iron, steel, copper, etc.)',
  },

  {
    name: 'Hydrolytic stability',
    value:
      'ability of additives and certain synthetic lubricants toresist chemical decomposition (hydrolysis) in the presence of water.',
  },

  {
    name: 'Hydrometer',
    value:
      'an instrument for determining either the specific gravity of a liquid or the API gravity.',
  },

  {
    name: 'Hydrostatic lubrication',
    value:
      'a system of lubrication in which the lubricant is supplied under sufficient external pressure to separate the opposing surfaces by a fluid film.',
  },

  {
    name: 'Immiscible',
    value:
      'incapable of being mixed without separation of phases. Water and petroleum oil are immiscible under most conditions, although they can be made miscible with the addition of an emulsifier.',
  },

  {
    name: 'In-line filter',
    value:
      'a filter assembly in which the inlet, outlet and filter element axes are in a straight line.',
  },

  {
    name: 'Influent',
    value: 'the fluid entering a component.',
  },

  {
    name: 'Infrared spectroscopy',
    value:
      'an analytical method using infrared absorption for assessing the properties of used oil and certain contaminants suspended therein. See FTIR.',
  },

  {
    name: 'Infrared spectra',
    value:
      'a graph of infrared energy absorbed at various frequencies in the additive region of the infrared spectrum. The current sample, the reference oil and the previous samples are usually compared.',
  },

  {
    name: 'Ingested contaminants',
    value:
      'environmental contaminant that ingress?s due to the action of the system or machine.',
  },

  {
    name: 'Ingression level',
    value: 'particles added per unit of circulating fluid volume.',
  },

  {
    name: 'Inhibitor',
    value:
      'any substance that slows or prevents such chemical reactions as corrosion or oxidation.',
  },

  {
    name: 'Insolubles',
    value:
      'particles of carbon or agglomerates of carbon and other material. Indicates deposition or dispersant drop-out in an engine. Not serious in a compressor or gearbox unless there has been a rapid increase in these particles.',
  },

  {
    name: 'Intensifier',
    value:
      'a device which converts low pressure fluid power into higher pressure fluid power.',
  },

  {
    name: 'Interfacial tension (IFT)',
    value:
      'the energy per unit area present at the boundary of two immiscible liquids. It is usually expressed in dynes/cm (ASTM Designation D 971.)',
  },

  {
    name: 'ISO Solid Contaminant Code (ISO 4406)',
    value:
      'a code assigned on the basis of the number of particles per unit volume greater than 5 and 15 micrometers in size. Range numbers identify each increment in the particle population throughout the spectrum of levels.',
  },

  {
    name: 'ISO Standard 4021',
    value:
      'the accepted procedure for extracting samples from dynamic fluid lines.',
  },

  {
    name: 'ISO viscosity grade',
    value:
      'a number indicating the nominal viscosity of an industrial fluid lubricant at 40?C (104?F) as defined by ASTM Standard Viscosity System for Industrial Fluid Lubricants D 2422. Essentially identical to ISO Standard 3448.',
  },

  {
    name: 'Journal',
    value:
      'that part of a shaft or axle that rotates or angularly oscillates in or against a bearing or about which a bearing rotates or angularly oscillates.',
  },

  {
    name: 'Journal bearing',
    value:
      'a sliding type of bearing having either rotating or oscillatory motion and in conjunction with which a journal operates. In a full or sleeve type journal bearing, the bearing surface is 360? in extent. In a partial bearing, the bearing surface is less than 360? in extent, i.e., 150?, 120?, etc.',
  },

  {
    name: 'Karl Fischer Reagent Method (ASTM D-1744-64)',
    value:
      'the standard laboratory test to measure the water content of mineral base fluids. In this method, water reacts quantitatively with the Karl Fischer reagent. This reagent is a mixture of iodine, sulfur dioxide, pyridine, and methanol. When excess iodine exists, electric current can pass between two platinum electrodes or plates. The water in the sample reacts with the iodine. When the water is no longer free to react with iodine, an excess of iodine depolarizes the electrodes, signaling the end of the test.',
  },

  {
    name: 'Kinematic viscosity',
    value:
      'the time required for a fixed amount of an oil to flow through a capillary tube under the force of gravity. The unit of kinematic viscosity is the stoke or centistoke (1/100 of a stoke). Kinematic viscosity may be defined as the quotient of the absolute viscosity in centipoises divided by the specific gravity of a fluid, both at the same temperature-- Centipoises / Specific Gravity = Centistokes',
  },

  {
    name: 'Lacquer',
    value:
      'a deposit resulting from the oxidation and polymerization of fuels and lubricants when exposed to high temperatures. Similar to, but harder, than varnish.',
  },

  {
    name: 'Laminar particles',
    value:
      'particles generated in rolling element bearings which have been flattened out by a rolling contact.',
  },

  {
    name: 'Lead naphthenate',
    value:
      'a lead soap of naphthenic acids, the latter occurring naturally in petroleum.',
  },

  {
    name: 'Light obscuration',
    value:
      'the degree of light blockage as reflected in the transmitted light impinging on the photodiode.',
  },

  {
    name: 'Liquid',
    value:
      'any substance that flows readily or changes in response to the smallest influence. More generally, any substance in which the force required to produce a deformation depends on the rate of deformation rather than on the magnitude of the deformation.',
  },

  {
    name: 'Load-carrying capacity',
    value:
      'property of a lubricant to form a film on the lubricated surface, which resists rupture under given load conditions. Expressed as maximum load the lubricated system can support without failure or excessive wear.',
  },

  {
    name: 'Lubricant',
    value:
      'any substance interposed between two surfaces in relative motion for the purpose of reducing the friction and/or the wear between them.',
  },

  {
    name: 'Lubricity',
    value:
      'ability of an oil or grease to lubricate; also called film strength.',
  },

  {
    name: 'Machine Group',
    value:
      'Identifies the monitoring of a machine case, machine train, or plant process unit.',
  },

  {
    name: 'Magnetic',
    value:
      'a separator that uses a magnetic field to attract and hold ferromagnetic particles.',
  },

  {
    name: 'Magnetic filter',
    value:
      'a filter element that, in addition to its filter medium, has a magnet or magnets incorporated into its structure to attract and hold ferromagnetic particles.',
  },

  {
    name: 'Magnetic plug',
    value:
      'strategically located in the flow stream to collect a representative sample of wear debris circulating in the system: for example, engine swarf, bearing flakes, and fatigue chunks. The rate of buildup of wear debris reflects degradation of critical surfaces.',
  },

  {
    name: 'Manifold',
    value:
      'a filter assembly containing multiple ports and integral relating components which services more than one fluid circuit.',
  },

  {
    name: 'Manifold filter',
    value:
      'a filter in which the inlet and outlet port axes are at right angles, and the filter element axis is parallel to either port axis.',
  },

  {
    name: 'Media migration',
    value:
      'material passed into the effluent stream composed of the materials making up the filter medium.',
  },

  {
    name: 'Medium',
    value:
      'the porous material that performs the actual process of filtration. The plural of this word is "media".',
  },

  {
    name: 'Metal oxides',
    value:
      'oxidized ferrous particles which are very old or have been recently produced by conditions of inadequate lubrication. Trend is important.',
  },

  {
    name: 'Micrometre (?m)',
    value: 'See Micron.',
  },

  {
    name: 'Micron',
    value:
      'a unit of length. One Micron = 39 millionths of an inch (.000039"). Contaminant size is usually described in microns.Relatively speaking, a grain of salt is about 60 microns and the eye can see particles to about 40 microns.Many hydraulic filters are required to be efficient in capturing a substantial percentage of contaminant particles as small as 5 microns.A micron is also known as a micrometre, and exhibited as?m',
  },

  {
    name: 'Microscope method',
    value:
      'a method of particle counting which measures or sizes particles using an optical microscope.',
  },

  {
    name: 'Mineral oil',
    value:
      'oil derived from a mineral source, such as petroleum, as opposed to oils derived from plants and animals.',
  },

  {
    name: 'Miscible',
    value:
      'capable of being mixed in any concentration without separation of phases; e.g., water and ethyl alcohol are miscible.',
  },

  {
    name: 'Moly',
    value:
      'Molybdenum disulfide, a solid lubricant and friction reducer, colloidally dispersed in some oils and greases.',
  },

  {
    name: 'Motor',
    value:
      'a device that converts fluid power into mechanical force and motion. It usually provides rotary mechanical motion.',
  },

  {
    name: 'Multigrade oil',
    value:
      'an oil meeting the requirements of more than one SAE viscosity grade classification, and may therefore be suitable for use over a wider temperature range than a single-grade oil.',
  },

  {
    name: 'Multipass or recirculation test',
    value:
      'filter performance tests in which the contaminated fluid is allowed to recirculate through the filter for the duration of the test. Contaminant is usually added to the test fluid during the test. The test is used to determine the Beta-Ratio (q.v.) of an element.',
  },

  {
    name: 'Naphthenic',
    value:
      'a type of petroleum fluid derived from naphthenic crude oil, containing a high proportion of closed-ring methylene groups.',
  },

  {
    name: 'Neutralization number',
    value:
      'a measure of the total acidity or basicity of an oil; this includes organic or inorganic acids or bases or a combination thereof (ASTM Designation D974-58T)',
  },

  {
    name: 'Newtonian fluid',
    value:
      'a fluid with a constant viscosity at a given temperature regardless of the rate of shear. Single-grade oils are Newtonian fluids. Multigrade oils are NON-Newtonian fluids because viscosity varies with shear rate.',
  },

  {
    name: 'Nitration',
    value:
      'nitration products are formed during the fuel combustion process in internal combustion engines. Most nitration products are formed when an excess of oxygen is present. These products are highly acidic, form deposits in combustion areas and rapidly accelerate oxidation.',
  },

  {
    name: 'Nominal filtration rating',
    value:
      'an arbitrary micrometer value indicated by a filter manufacturer. Due to lack of reproducibility this rating is deprecated.',
  },

  {
    name: 'Non-Newtonian fluid',
    value:
      'fluid, such as a grease or a polymer-containing oil (e.g., multi-grade oil), in which shear stress is not proportional to shear rate.',
  },

  {
    name: 'Nonwoven medium',
    value: 'a filter medium composed of a mat of fibers.',
  },

  {
    name: 'Oil',
    value:
      'a greasy, unctuous liquid of vegetable, animal, mineral or synthetic origin.',
  },

  {
    name: 'Oiliness',
    value:
      'that property of a lubricant that produces low friction under conditions of boundary lubrication. The lower the friction, the greater the oiliness.',
  },

  {
    name: 'Oil ring',
    value:
      'a loose ring, the inner surface of which rides a shaft or journal and dips into a reservoir of lubricant from which it carries the lubricant to the top of a bearing by its rotation with the shaft.',
  },

  {
    name: 'Open bubble point (boil point)',
    value:
      'the differential gas pressure at which gas bubbles are profusely emitted from the entire surface of a wetted filter element under specified test conditions.',
  },

  {
    name: 'Oxidation',
    value:
      'occurs when oxygen attacks petroleum fluids. The process is accelerated by heat, light, metal catalysts and the presence of water, acids, or solid contaminants. It leads to increased viscosity and deposit formation.',
  },

  {
    name: 'Oxidation inhibitor',
    value:
      'substance added in small quantities to a petroleum product to increase its oxidation resistance, thereby lengthening its service or storage life; also called anti-oxidant. An oxidation inhibitor may work in one of these ways: (1) by combining with and modifying peroxides (initial oxidation products) to render them harmless, (2) by decomposing the peroxides, or (3) by rendering an oxidation catalyst inert.',
  },

  {
    name: 'Paper chromatography',
    value:
      'a method which involves placing a drop of fluid on a permeable piece of paper and noting the development and nature of the halos, or rings, surrounding the drop through time. The roots of this test can be traced to the 1940s, when railroads used the "blotter spot" tests.',
  },

  {
    name: 'Paraffinic',
    value:
      'a type of petroleum fluid derived from paraffinic crude oil and containing a high proportion of straight chain saturated hydrocarbons. Often susceptible to cold flow problems.',
  },

  {
    name: 'Particle count',
    value:
      'the number of particles present greater than a particular micron size per unit volume of fluid often stated as particles > 10 microns per milliliter.',
  },

  {
    name: 'Particle density',
    value:
      "an important parameter in establishing an entrained particle's potential to impinge on control surfaces and cause erosion.",
  },

  {
    name: 'Particle erosion',
    value:
      'occurs when fluid-entrained particles moving at high velocity pass through orifices or impinge on metering surfaces or sharp angle turns.',
  },

  {
    name: 'Particle impingement erosion',
    value:
      'a particulate wear process where high velocity, fluid-entrained particles are directed at target surfaces.',
  },

  {
    name: 'Patch test',
    value:
      'a method by which a specified volume of fluid is filtered through a membrane filter of known pore structure. All particulate matter in excess of an "average size," determined by the membrane characteristics, is retained on its surface. Thus, the membrane is discolored by an amount proportional to the particulate level of the fluid sample. Visually comparing the test filter with standard patches of known contamination levels determines acceptability for a given fluid.',
  },

  {
    name: 'Permeability',
    value:
      'the relationship of flow per unit area to differential pressure across a filter medium.',
  },

  {
    name: 'pH',
    value:
      'measure of alkalinity or acidity in water and water-containing fluids. pH can be used to determine the corrosion-inhibiting characteristic in water-based fluids. Typically, pH > 8.0 is required to inhibit corrosion of iron and ferrous alloys in water-based fluids.',
  },

  {
    name: 'Pitting',
    value:
      'a form of extremely localized attack characterized by holes in the metal. Pitting is one of the most destructive and insidious forms of corrosion. Depending on the environment and the material, a pit may take months, or even years, to become visible.',
  },

  {
    name: 'Pleated filter',
    value:
      'a filter element whose medium consists of a series of uniform folds and has the geometric form of a cylinder, cone, disc, plate, etc. Synonymous with "convoluted" and "corrugated".',
  },

  {
    name: 'Point',
    value:
      'A single position for data collection.  A point can be general (in thermography, a point might be ?the back end of the motor?) but it is usually highly specific (?the drive end bearing of the motor in the horizontal direction?).  Multiple data sets can be taken from that same position, but they are generally referred to as different points.',
  },

  {
    name: 'Poise (absolute viscosity)',
    value:
      'a measure of viscosity numerically equal to the force required to move a plane surface of one square centimeter per second when the surfaces are separated by a layer of fluid one centimeter in thickness. It is the ratio of the shearing stress to the shear rate of a fluid and is expressed in dyne seconds per square centimeter (DYNE SEC/CM2); 1 centipoise equals .01 poise.',
  },

  {
    name: 'Polar compound',
    value:
      'a chemical compound whose molecules exhibit electrically positive characteristics at one extremity and negative characteristics at the other. Polar compounds are used as additives in many petroleum products. Polarity gives certain molecules a strong affinity for solid surfaces; as lubricant additives (oiliness agents), such molecules plate out to form a tenacious, friction-reducing film. Some polar molecules are oil-soluble at one end and water-soluble at the other end; in lubricants, they act as emulsifiers, helping to form stable oil-water emulsions. Such lubricants are said to have good metal-wetting properties. Polar compounds with a strong attraction for solid contaminants act as detergents in engine oils by keeping contaminants finely dispersed.',
  },

  {
    name: 'Polishing (bore)',
    value:
      'excessive smoothing of the surface finish of the cylinder bore or cylinder liner in an engine to a mirror-like appearance, resulting in depreciation of ring sealing and oil consumption performance.',
  },

  {
    name: 'Polymerization',
    value:
      'the chemical combination of similar-type molecules to form larger molecules.',
  },

  {
    name: 'Pore',
    value:
      'a small channel or opening in a filter medium which allows passage of fluid.',
  },

  {
    name: 'Pore size distribution',
    value:
      'the ratio of the number of effective holes of a given size to the total number of effective holes per unit area expressed as a percent and as a function of hole size.',
  },

  {
    name: 'Porosity',
    value:
      'the ratio of pore volume to total volume of a filter medium expressed as a percent.',
  },

  {
    name: 'Positive crankcase ventilation (PCV)',
    value:
      'system for removing blow-by gases from the crankcase and returning them through the carburetor intake manifold to the combustion chamber where the recirculated hydrocarbons are burned. A PC valve controls the flow of gases from the crankcase to reduce hydrocarbon emissions.',
  },

  {
    name: 'Pour point',
    value:
      'lowest temperature at which an oil or distillate fuel is observed to flow, when cooled under conditions prescribed by test method ASTM D 97. The pour point is 3?C (5?F) above the temperature at which the oil in a test vessel shows no movement when the container is held horizontally for five seconds.',
  },

  {
    name: 'Pour point depressant',
    value:
      'an additive which retards the adverse effects of wax crystallization, and lowers the pour point.',
  },

  {
    name: 'Power unit',
    value:
      'a combination of pump, pump drive, reservoir, controls and conditioning components which may be required for its application.',
  },

  {
    name: 'Pressure',
    value: 'force per unit area, usually expressed in pounds per square inch.',
  },

  {
    name: 'Pressure, absolute',
    value: 'the sum of atmospheric and gage pressures.',
  },

  {
    name: 'Pressure, atmospheric',
    value:
      'pressure exerted by the atmosphere at any specific location. (Sea level pressure is approximately 14.7 pounds per square inch absolute.)',
  },

  {
    name: 'Pressure, back',
    value: 'the pressure encountered on the return side of a system.',
  },

  {
    name: 'Pressure, cracking',
    value:
      'the pressure at which a pressure operated valve begins to pass fluid.',
  },

  {
    name: 'Pressure, rated',
    value:
      'the qualified operating pressure which is recommended for a component or a system by the manufacturer.',
  },

  {
    name: 'Pressure, system',
    value:
      'the pressure which overcomes the total resistances in a system. It includes all losses as well as useful work.',
  },

  {
    name: 'Pressure Drop',
    value:
      'Resistance to flow created by the element (media) in a filter. Defined as the difference in pressure upstream (inlet side of the filter) and downstream (outlet side of the filter).',
  },

  {
    name: 'Pressure line filter',
    value:
      'a filter located in a line conducting working fluid to a working device or devices.',
  },

  {
    name: 'Pull Down Menu',
    value: 'A drop-down window displaying options of a menu.',
  },

  {
    name: 'Pumpability',
    value:
      'the low temperature, low shear stress-shear rate viscosity characteristics of an oil that permit satisfactory flow to and from the engine oil pump and subsequent lubrication of moving components.',
  },

  {
    name: 'Rate of shear',
    value:
      'the difference between the velocities along the parallel faces of a fluid element divided by the distance between the faces.',
  },

  {
    name: 'Rerefining',
    value:
      'a process of reclaiming used lubricant oils and restoring them to a condition similar to that of virgin stocks by filtration, clay adsorption or more elaborate methods.',
  },

  {
    name: 'Reservoir',
    value: 'a container for storage of liquid in a fluid power system.',
  },

  {
    name: 'Reservoir (sump) filter',
    value:
      'a filter installed in a reservoir in series with a suction or return line.',
  },

  {
    name: 'Residual dirt capacity',
    value:
      'the dirt capacity remaining in a service loaded filter element after use, but before cleaning, measured under the same conditions as the dirt capacity of a new filter element.',
  },

  {
    name: 'Return line',
    value:
      'a location in a line conducting fluid from working device to reservoir.',
  },

  {
    name: 'Return Line Filtration',
    value:
      "filters located upstream of the reservoir but after fluid has passed through the system's output components (cylinders, motors, etc.).",
  },

  {
    name: 'Ring lubrication',
    value:
      'a system of lubrication in which the lubricant is supplied to the bearing by an oil ring.',
  },

  {
    name: 'Rings',
    value:
      'circular metallic elements that ride in the grooves of a piston and provide compression sealing during combustion. Also used to spread oil for lubrication.',
  },

  {
    name: 'Ring sticking',
    value:
      'freezing of a piston ring in its groove in a piston engine or reciprocating compressor due to heavy deposits in the piston ring zone.',
  },

  {
    name: 'Roll-off cleanliness',
    value:
      'the fluid system contamination level at the time of release from an assembly or overhaul line. Fluid system life can be shortened significantly by full-load operation under a high fluid contamination condition for just a few hours. Contaminant implanted and generated during the break-in period can devastate critical components unless removed under controlled operating and high performance filtering conditions.',
  },

  {
    name: 'Route',
    value:
      'An ordered list of inspection points and preventive maintenance tasks.  The implication of a routes ordering is that the data should be collected or the task performed in the order specified.',
  },

  {
    name: 'Rust prevention test (turbine oils)',
    value:
      'a test for determining the ability of an oil to aid in preventing the rusting of ferrous parts in the presence of water.',
  },

  {
    name: 'Sample preparation',
    value:
      'fluid factors that can enhance the accuracy of the particulate analysis. Such factors include particle dispersion, particle settling, and sample dilution.',
  },

  {
    name: 'Saturation level',
    value: 'the amount of water that can dissolve in a fluid.',
  },

  {
    name:
      'Saybolt Universal Viscosity (SUV) or Saybolt Universal Seconds, (SUS)',
    value:
      'the time in seconds required for 60 cubic centimeters of a fluid to flow through the orifice of the Standard Saybolt Universal Viscometer at a given temperature under specified conditions. (ASTM Designation D 88.)',
  },

  {
    name: 'Scuffing',
    value:
      'abnormal engine wear due to localized welding and fracture. It can be prevented through the use of antiwear, extreme-pressure and friction modifier additives.',
  },

  {
    name: 'Scuffing particles',
    value:
      'large twisted and discolored metallic particles resulting from adhesive wear due to complete lubricant film breakdown.',
  },

  {
    name: 'Semisolid',
    value:
      'any substance having the attributes of both a solid and a liquid. Similar to semiliquid but being more closely related to a solid than a liquid. More generally, any substance in which the force required to produce a deformation depends both on the magnitude and on the rate of the deformation.',
  },

  {
    name: 'Sleeve bearing',
    value: 'a journal bearing, usually a full journal bearing.',
  },

  {
    name: 'Shear rate',
    value:
      'rate at which adjacent layers of fluid move with respect to each other, usually expressed as reciprocal seconds.',
  },

  {
    name: 'Shear stress',
    value:
      'frictional force overcome in sliding one "layer" of fluid along another, as in any fluid flow. The shear stress of a petroleum oil or other Newtonian fluid at a given temperature varies directly with shear rate (velocity). The ratio between shear stress and shear rate is constant; this ratio is termed viscosity of a Newtonian fluid, the greater the shear stress as a function of rate of shear. In a non-Newtonian fluid such as a grease or a polymer-containing oil (e.g. multi-grade oil) shear stress is not proportional to the rate of shear. A non-Newtonian fluid may be said to have an apparent viscosity, a viscosity that holds only for the shear rate (and temperature) at which the viscosity is determined.',
  },

  {
    name: 'Silt',
    value: 'contaminant particles 5 ?m and less in size.',
  },

  {
    name: 'Silting',
    value:
      'a failure generally associated with a valve which movements are restricted due to small particles that have wedged in between critical clearances (e.g., the spool and bore.)',
  },

  {
    name: 'Single-pass test',
    value:
      'filter performance tests in which contaminant which passes through a test filter is not allowed to recirculate back to the test filter.',
  },

  {
    name: 'Sludge',
    value:
      'insoluble material formed as a result either of deterioration reactions in an oil or of contamination of an oil, or both.',
  },

  {
    name: 'Solid',
    value:
      'any substance having a definite shape which it does not readily relinquish. More generally, any substance in which the force required to produce a deformation depends upon the magnitude of the deformation rather than upon the rate of deformation.',
  },

  {
    name: 'Solvency',
    value:
      'ability of a fluid to dissolve inorganic materials and polymers, which is a function of aromaticity.',
  },

  {
    name: 'Specific gravity (liquid)',
    value:
      'the ratio of the weight of a given volume of liquid to the weight of an equal volume of water.',
  },

  {
    name: 'Specific gravity',
    value:
      'the ratio of the weight of a given volume of material to the weight of an equal volume of water.',
  },

  {
    name: 'Spectrographic analysis',
    value:
      'determines the concentration of elements represented in the entrained fluid contaminant.',
  },

  {
    name: 'Spectrographic Oil Analysis Program (SOAP)',
    value:
      'procedures for extracting fluid samples from operating systems and analyzing them spectrographically for the presence of key elements.',
  },

  {
    name: 'Spin-on filter',
    value:
      'a throw-away type bowl and element assembly that mates with a permanently installed head.',
  },

  {
    name: 'Spindle oil',
    value:
      'a light-bodied oil used principally for lubricating textile spindles and for light, high-speed machinery.',
  },

  {
    name: 'Splash lubrication',
    value:
      'a system of lubrication in which parts of a mechanism dip into and splash the lubricant onto themselves and/or other parts of the mechanism.',
  },

  {
    name: 'Static friction',
    value:
      'the force just sufficient to initiate relative motion between two bodies under load. The value of the static friction at the instant relative motion begins is termed break-away friction.',
  },

  {
    name: 'Stoke (St)',
    value:
      "kinematic measurement of a fluid's resistance to flow defined by the ratio of the fluid's dynamic viscosity to its density.",
  },

  {
    name: 'Strainer',
    value: 'a coarse filter element (pore size over approximately 40 ?m)',
  },

  {
    name: 'Suction filter',
    value:
      'a pump intake-line filter in which the fluid is below atmospheric pressure.',
  },

  {
    name: 'Sulfated ash',
    value:
      'the ash content of fresh, compounded lubricating oil as determined by ASTM Method D 874. Indicates level of metallic additives in the oil.',
  },

  {
    name: 'Sulfurized oil',
    value: 'oil to which sulfur or sulfur compounds have been added.',
  },

  {
    name: 'Surface fatigue wear',
    value:
      'the formation of surface or subsurface cracks and fatigue crack propagation. It results from cyclic loading of a surface.',
  },

  {
    name: 'Surface filtration',
    value:
      'filtration which primarily retains contaminant on the influent surface.',
  },

  {
    name: 'Surface tension',
    value:
      'the contractile surface force of a liquid by which it tends to assume a spherical form and to present the least possible surface. It is expressed in dynes/cm or ergs/cm2.',
  },

  {
    name: 'Surfactant',
    value:
      "surface-active agent that reduces interfacial tension of a liquid. A surfactant used in a petroleum oil may increase the oil's affinity for metals and other materials.",
  },

  {
    name: 'Surge',
    value: 'a momentary rise of pressure in a circuit.',
  },

  {
    name: 'Swarf',
    value:
      'the cuttings, and grinding fines that result from metal working operations.',
  },

  {
    name: 'Synthetic lubricant',
    value:
      'a lubricant produced by chemical synthesis rather than by extraction or refinement of petroleum to produce a compound with planned and predictable properties.',
  },

  {
    name: 'Synthetic hydrocarbon',
    value:
      'oil molecule with superior oxidation quality tailored primarily out of paraffinic materials.',
  },

  {
    name: 'Thermal conductivity',
    value: 'measure of the ability of a solid or liquid to transfer heat.',
  },

  {
    name: 'Thermal stability',
    value:
      'ability of a fuel or lubricant to resist oxidation under high temperature operating conditions.',
  },

  {
    name: 'Thermography',
    value:
      'the use of infrared thermography whereby temperatures of a wide variety of targets can be measured remotely and without contact. This is accomplished by measuring the infrared energy radiating from the surface of the target and converting this measurement to an equivalent surface temperature.',
  },

  {
    name: 'Thin film lubrication',
    value:
      'a condition of lubrication in which the film thickness of the lubricant is such that the friction between the surfaces is determined by the properties of the surfaces as well as by the viscosity of the lubricant.',
  },

  {
    name: 'Thixotropy',
    value:
      'that property of a lubricating grease which is manifested by a softening in consistency as a result of shearing followed by a hardening in consistency starting immediately after the shearing is stopped.',
  },

  {
    name: 'Three-body abrasion',
    value:
      'a particulate wear process by which particles are pressed between two sliding surfaces.',
  },

  {
    name: 'Thrust Bearing',
    value: 'an axial-load bearing.',
  },

  {
    name: 'Timken OK Load',
    value:
      'the heaviest load that a test lubricant will sustain without scoring the test block in the Timken Test procedures, ASTM Methods D 2509 (greases) and D 2782 (oils).',
  },

  {
    name: 'Total Acid Number (TAN)',
    value:
      'the quantity of base, expressed in milligrams of potassium hydroxide, that is required to neutralize all acidic constituents present in 1 gram of sample. (ASTM Designation D 974.)',
  },

  {
    name: 'Total Base Number (TBN)',
    value:
      'the quantity of acid, expressed in terms of the equivalent number of milligrams of potassium hydroxide that is required to neutralize all basic constituents present in 1 gram of sample. (ASTM Designation D 974.)',
  },

  {
    name: 'Tribology',
    value:
      'the science and technology of interacting surfaces in relative motion, including the study of lubrication, friction and wear. Tribological wear is wear that occurs as a result of relative motion at the surface.',
  },

  {
    name: 'Trend',
    value: 'the measurement of a variable vs. time.',
  },

  {
    name: 'Turbidity',
    value: 'the degree of opacity of a fluid.',
  },

  {
    name: 'Turbulent flow sampler',
    value:
      'a sampler that contains a flow path in which turbulence is induced in the main stream by abruptly changing the direction of the fluid.',
  },

  {
    name: 'Unloading',
    value:
      'the release of contaminant that was initially captured by the filter medium.',
  },

  {
    name: 'Upload',
    value:
      'Transferring data from the monitoring equipment to the host computer.',
  },

  {
    name: 'Vacuum separator',
    value:
      'a separator that utilizes subatmospheric pressure to remove certain gases and liquids from another liquid because of their difference in vapor pressure.',
  },

  {
    name: 'Value',
    value: 'An individual data measurement.',
  },

  {
    name: 'Valve',
    value:
      'a device which controls fluid flow direction, pressure, or flow rate.',
  },

  {
    name: 'Valve, by-pass',
    value:
      'a valve whose primary function is to provide an alternate flow path.',
  },

  {
    name: 'Valve, directional control',
    value:
      'a valve whose primary function is to direct or prevent flow through selected passages.',
  },

  {
    name: 'Valve, directional control, servo',
    value:
      'a directional control valve which modulates flow or pressure as a function of its input signal.',
  },

  {
    name: 'Valve, flow control',
    value: 'a valve whose primary function is to control flow rate.',
  },

  {
    name: 'Valve lifter',
    value:
      'sometimes called a "cam follower," a component in engine designs that use a linkage system between a cam and the valve it operates. The lifter typically translates the rotational motion of the cam to a reciprocating linear motion in the linkage system.',
  },

  {
    name: 'Valve, pressure control, relief',
    value:
      'a pressure control valve whose primary function is to limit system pressure.',
  },

  {
    name: 'Valve, relief, differential pressure',
    value: 'a valve whose primary function is to limit differential pressure.',
  },

  {
    name: 'Vapor pressure',
    value:
      "pressure of a confined vapor in equilibrium with its liquid at specified temperature thus, a measure of a liquid's volatility.",
  },

  {
    name: 'Vapor Pressure-Reid (RVP)',
    value:
      "measure of the pressure of vapor accumulated above a sample of gasoline or other volatile fuel in a standard bomb at 100?F (37.8?C). Used to predict the vapor locking tendencies of the fuel in a vehicle's fuel system. Controlled by law in some areas to limit air pollution from hydrocarbon evaporation while dispensing.",
  },

  {
    name: 'Varnish',
    value:
      'when applied to lubrication, a thin, insoluble, nonwipeable film deposit occurring on interior parts, resulting from the oxidation and polymerization of fuels and lubricants. Can cause sticking and malfunction of close-clearance moving parts. Similar to, but softer, than lacquer.',
  },

  {
    name: 'Viscometer or Viscosimeter',
    value: 'an apparatus for determining the viscosity of a fluid.',
  },

  {
    name: 'Viscosity',
    value:
      "measurement of a fluid's resistance to flow. The common metric unit of absolute viscosity is the poise, which is defined as the force in dynes required to move a surface one square centimeter in area past a parallel surface at a speed of one centimeter per second, with the surfaces separated by a fluid film one centimeter thick. In addition to kinematic viscosity, there are other methods for determining viscosity, including Saybolt Universal Viscosity (SUV), Saybolt Furol viscosity, Engier viscosity, and Redwood viscosity. Since viscosity varies in inversely with temperature, its value is meaningless until the temperature at which it is determined is reported.",
  },

  {
    name: 'Viscosity, absolute',
    value:
      'the ration of the shearing stress to the shear rate of a fluid. It is usually expressed in centipoise.',
  },

  {
    name: 'Viscosity, kinematic',
    value:
      'the absolute viscosity divided by the density of the fluid. It is usually expressed in centistokes.',
  },

  {
    name: 'Viscosity, SUS',
    value:
      'Saybolt Universal Seconds (SUS), which is the time in seconds for 60 milliliters of oil to flow through a standard orifice at a given temperature. (ASTM Designation D88-56.)',
  },

  {
    name: 'Viscosity grade',
    value:
      'any of a number of systems which characterize lubricants according to viscosity for particular applications, such as industrial oils, gear oils, automotive engine oils, automotive gear oils, and aircraft piston engine oils.',
  },

  {
    name: 'Viscosity index (VI)',
    value:
      "a commonly used measure of a fluid's change of viscosity with temperature. The higher the viscosity index, the smaller the relative change in viscosity with temperature.",
  },

  {
    name: 'Viscosity index improvers',
    value:
      'additives that increase the viscosity of the fluid throughout its useful temperature range. Such additives are polymers that possess thickening power as a result of their high molecular weight and are necessary for formulation of multi-grade engine oils.',
  },

  {
    name: 'Viscosity modifier',
    value:
      "lubricant additive, usually a high molecular weight polymer, that reduces the tendency of an oil's viscosity to change with temperature.",
  },

  {
    name: 'Viscous',
    value: 'possessing viscosity. Frequently used to imply high viscosity.',
  },

  {
    name: 'Volatility',
    value:
      'this property describes the degree and rate at which a liquid will vaporize under given conditions of temperature and pressure. When liquid stability changes, this property is often reduced in value.',
  },

  {
    name: 'Wear',
    value:
      'the attrition or rubbing away of the surface of a material as a result of mechanical action.',
  },

  {
    name: 'ZDDP',
    value:
      'an antiwear additive found in many types of hydraulic and lubricating fluids. Zinc dialkyldithiophosphate.',
  },
]

function Glossary() {
  const [results, setResults] = useState(terms)

  const handleGlossaryChange = (event, value, reason) => {
    setResults(
      terms.filter(
        x =>
          x.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
          x.value.toLowerCase().indexOf(value.toLowerCase()) !== -1
      )
    )
  }

  return (
    <React.Fragment>
      <SEO title="Glossary" />

      <Grid container justify="center">
        <Grid item xs={12} md={6}>
          <Box mt={1} mb={2} style={{ textAlign: 'center' }}>
            <Typography variant="h1">Glossary</Typography>
          </Box>

          <Box mt={2} mb={6}>
            <Autocomplete
              id="glossary-search"
              options={results}
              getOptionLabel={option =>
                typeof option.name !== 'undefined' ? option.name : option
              }
              freeSolo
              style={{ width: 600, marginLeft: 'auto', marginRight: 'auto' }}
              renderInput={params => (
                <TextField {...params} label="Search for terms" />
              )}
              onInputChange={handleGlossaryChange}
            />
          </Box>

          <Grid container direction="column" spacing={6}>
            {results.map((x, i) => (
              <Grid
                container
                item
                direction="row"
                spacing={4}
                alignItems="flex-start"
              >
                <Grid item xs={4}>
                  <div style={{ textAlign: 'right' }}>
                    <Typography variant="h2">{x.name}</Typography>
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <div style={{ fontSize: '16px' }}>{x.value}</div>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

Glossary.propTypes = {
  profile: PropTypes.object,
  onError: PropTypes.func,
}

export default function Page() {
  return (
    <AppLayout tab="technical-library">
      <Glossary />
    </AppLayout>
  )
}
