/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { Input } from '@material-ui/core'

import { defaultFilterState } from './constants'

import {
  TAPICustomer,
  TAPILubricant,
  TAPIMachine,
  TAPIPicklistItem,
  TAPIPlant,
  TAPIProfileDivision,
  TAPIRoute,
  TAPISample,
  TAPITestGroup,
} from 'types/api'

import { SelectFilter } from '../BaseFilters/SelectFilter'
import FilterTooltip from '../FilterTooltip/FilterTooltip'
import { VirtualSelectFilter } from '..'

import {
  Filters,
  TCondition,
  TestOilType,
  TFilters,
  TFilterState,
  TFilterType,
  TSelectProps,
  TSmartFilterProps,
} from './SmartFilterTypes'
import { statesAreEqual } from './SmartFilterUtilities'
import useOptionFilter from './useOptionFilter'

// import { TCondition } from '../../../types/custom'

type TFilterParams<T> = {
  filterKey: keyof TFilterState & string
  comparator: (_opt1: T, _opt2: T) => boolean
  stringifier: (_s: T) => string | null
  filterLabel: string
  TooltipText?: string
  type?: TFilterType
  heirarchicalFiltering?: boolean
  autoCompletefilterOptions: (_option: T) => string
}

let startingState = { ...defaultFilterState }

interface TAction<TObj, T extends keyof TObj> {
  key: T
  payload: TObj[T]
}

const useSmartFilters = ({
  filterOptions,
  initialState,
  autoSelectSingleValues,
  disableOptionFiltering,
  filters,
}: TSmartFilterProps) => {
  const [options, setOptions] = useState<TFilterState>()

  const reducer = (
    state: TFilterState,
    action: TAction<TFilterState, keyof TFilterState>
  ): TFilterState => {
    Object.keys(state).forEach(key => {
      if (key === action.key) {
        //@ts-ignore //This is because we cannot technically  verify the type is of matching type to the property's type for every given key of the object. Ignore issue.
        state[key] = action.payload
        // return state
        const newState: TFilterState = { ...state }
        return newState
      }
    })
    return state
  }

  const [state, dispatch] = useReducer(reducer, {
    ...defaultFilterState,
  } as TFilterState)
  const [allOptions, setAllOptions] = useState<TFilterState>(filterOptions)
  const [filteredOptions, optionFilterReset] = useOptionFilter(
    options,
    allOptions
  )

  const previousOptions = useRef(null)
  const previousInitialStateRef = useRef<Partial<TFilterState>>()

  const handleReset = () => {
    Object.keys(startingState).forEach(key => {
      dispatch({
        payload: startingState[key] || [],
        key: key,
      })
    })
    setOptions(allOptions || filterOptions || previousOptions.current)
    setAllOptions(allOptions || filterOptions || previousOptions.current)
    previousInitialStateRef.current = null
    refreshState()
    optionFilterReset()
  }

  Object.keys(startingState).forEach(keyInState => {
    if (filterOptions && !filterOptions[keyInState]) {
      filterOptions[keyInState] = []
    }
  })

  useEffect(() => {
    if (!statesAreEqual(previousOptions.current, filterOptions)) {
      setAllOptions(filterOptions)
      setOptions(filterOptions)
    }
    previousOptions.current = { ...filterOptions }
  }, [statesAreEqual(previousOptions.current, filterOptions)])

  // spread in the initial state
  const [componentReset, setComponentReset] = useState(false)

  const setInitialState = () => {
    startingState = { ...defaultFilterState, ...initialState }
    previousInitialStateRef.current = initialState
    Object.keys(startingState).forEach(key => {
      const defaultStateHasValues = startingState[key].length > 0
      const currentStateHasValues = state[key].length > 0
      dispatch({
        payload: defaultStateHasValues
          ? startingState[key]
          : currentStateHasValues
          ? state[key]
          : [],
        key: key,
      })
    })

    setComponentReset(!componentReset)
  }

  const refreshState = () => {
    startingState = { ...defaultFilterState, ...initialState }
    previousInitialStateRef.current = initialState
    Object.keys(startingState).forEach(key => {
      dispatch({
        payload: startingState[key] || [],
        key: key,
      })
    })

    setComponentReset(!componentReset)
  }

  useEffect(() => {
    if (!statesAreEqual(initialState, previousInitialStateRef.current)) {
      setInitialState()
      return () => {
        startingState = { ...defaultFilterState }
      }
    }
  }, [initialState])

  useEffect(() => {
    if (filterOptions && !statesAreEqual(allOptions, filterOptions)) {
      setAllOptions(filterOptions)

      if (!options) {
        setOptions(filterOptions)
      }
    }
  }, [options])

  function formatSelection<T>(value: T | T[]): T[] {
    return Array.isArray(value) ? value : [value]
  }

  function ConfiguredFilter<T extends TestOilType | TestOilType[]>(
    params: TFilterParams<T>,
    props: Partial<TSelectProps>
  ) {
    // const [internalOptions, setInternalOptions] = useState(null)
    const [internalReset, setInternalReset] = useState(false)

    const heirarchicalFiltering: boolean =
      !disableOptionFiltering ||
      props.heirarchicalFiltering ||
      params.heirarchicalFiltering

    useEffect(() => {
      Object.keys(state).forEach(key => {
        state[key] = state[key]?.every(value => value === null)
          ? []
          : state[key]
      })
    })

    useEffect(() => {
      handleFilter()
    }, [])

    const handleFilter = () => {
      if (heirarchicalFiltering) {
        const newOptions = filteredOptions(state)

        if (
          autoSelectSingleValues &&
          options &&
          newOptions &&
          !statesAreEqual(options, newOptions)
        ) {
          Object.keys(newOptions).forEach(key => {
            if (newOptions[key]?.length === 1 && state[key]?.length !== 1) {
              dispatch({
                payload: newOptions[key] as TestOilType[],
                key: key,
              })
            }
          })
        }

        if (!statesAreEqual(options, newOptions)) {
          setOptions(newOptions)
          setInternalReset(!internalReset)
        }
      }
    }

    let filterType = params.type ? params.type : TFilterType.Smart
    if (filterType === TFilterType.Smart) {
      if (options) {
        filterType =
          options[params.filterKey]?.length > 20
            ? TFilterType.VirtualSelect
            : TFilterType.Select
      } else {
        setAllOptions(filterOptions)
        setOptions(filterOptions)
      }
    }

    const multiple: boolean =
      props.multiple === undefined || props.multiple === true

    let filter

    if (filterType === TFilterType.VirtualSelect) {
      filter = (
        <VirtualSelectFilter
          {...props}
          htmlFor={params.filterLabel}
          id={params.filterLabel}
          onChange={(value: T | T[]) => {
            dispatch({
              payload: formatSelection(value) as TestOilType[],
              key: params.filterKey,
            })
            setInternalReset(!internalReset)
          }}
          onClose={() => {
            if (heirarchicalFiltering) {
              handleFilter()
            }
          }}
          getOptionSelected={(option: T, value: T) =>
            params.comparator(option, value)
          }
          label={params.filterLabel}
          required={false}
          multiple={multiple}
          renderValue={(selected: T) => params.stringifier(selected) ?? ''}
          options={
            options && options[params.filterKey]
              ? options[params.filterKey].sort(
                  (a, b) =>
                    params
                      .stringifier(a as T | null)
                      ?.localeCompare(
                        params.stringifier(b as T | null) ?? ''
                      ) ?? -1
                )
              : []
          }
          value={
            multiple
              ? state[params.filterKey]
              : state[params.filterKey][0] ?? null
          }
        />
      )
    } else if (filterType === TFilterType.Select) {
      filter = (
        <SelectFilter
          {...props}
          htmlFor={params.filterLabel}
          id={params.filterLabel}
          onChange={(value: T | T[]) => {
            dispatch({
              payload: formatSelection(value) as TestOilType[],
              key: params.filterKey,
            })
            setInternalReset(!internalReset)
          }}
          onClose={() => {
            if (heirarchicalFiltering) {
              handleFilter()
            }
          }}
          getOptionSelected={(option: T, value: T) =>
            params.comparator(option, value)
          }
          label={params.filterLabel}
          required={false}
          multiple={multiple}
          renderValue={(selected: T) => params.stringifier(selected) ?? ''}
          options={
            options && options[params.filterKey]
              ? options[params.filterKey].sort(
                  (a, b) =>
                    params
                      .stringifier(a as T | null)
                      ?.localeCompare(
                        params.stringifier(b as T | null) ?? ''
                      ) ?? -1
                )
              : []
          }
          value={
            multiple
              ? state[params.filterKey]
              : state[params.filterKey][0] ?? null
          }
          autoCompletefilterOptions={params.autoCompletefilterOptions}
        />
      )
    } else if (
      filterType === TFilterType.Number ||
      filterType === TFilterType.Text
    ) {
      filter = (
        <Input
          label={params.filterLabel}
          {...props}
          type={filterType === TFilterType.Number ? 'number' : 'text'}
          value={state[params.filterKey]}
          htmlFor={params.filterLabel}
          id={params.filterLabel}
          onChange={event => {
            dispatch({
              payload: formatSelection(event.target.value) as TestOilType[],
              key: params.filterKey,
            })
            setInternalReset(!internalReset)
          }}
          {...props}
        />
      )
    } else {
      return null
    }

    let responseElement

    if (props.TooltipText || params.TooltipText) {
      responseElement = (
        <FilterTooltip
          title={props.TooltipText ? props.TooltipText : params.TooltipText}
        >
          {filter}
        </FilterTooltip>
      )
    } else {
      responseElement = filter
    }

    const memoizedElement = React.useMemo(
      () => responseElement,
      [state[params.filterKey]?.length, options[params.filterKey]?.length]
    )
    return <>{memoizedElement}</>
  }

  function PresetFactory<T extends TestOilType | TestOilType[]>(
    filterKey: keyof TFilterState,
    filterLabel: string,
    stringifier: (_s: T) => string | null,
    comparator: (_opt1: T, _opt2: T) => boolean,
    TooltipText?: string,
    heirarchicalFiltering?: boolean,
    type?: TFilterType,
    autoCompletefilterOptions?: (_option: T) => string
  ): TFilterParams<T> {
    return {
      filterKey: filterKey,
      filterLabel: filterLabel,
      stringifier: stringifier,
      comparator: comparator,
      TooltipText: TooltipText,
      heirarchicalFiltering: heirarchicalFiltering,
      type: type,
      autoCompletefilterOptions: autoCompletefilterOptions,
    }
  }

  const SmartDivisionFilterPresets = PresetFactory<TAPIProfileDivision>(
    'divisions',
    'Division',
    selected => selected?.name ?? '',
    (option1, option2) => option1?.iD === option2?.iD
  )

  const SmartCustomerFilterPresets = PresetFactory<TAPICustomer>(
    'customers',
    'Customer',
    selected => selected?.custName ?? '',
    (option1, option2) => option1?.custID === option2?.custID
  )

  const SmartMachineFilterPresets = PresetFactory<TAPIMachine>(
    'machines',
    'Machines',
    selected => selected?.machineName ?? '',
    (option1, option2) => option1?.pointID === option2?.pointID,
    'A machine identified by machine name.'
  )

  const SmartLubricantFilterPresets = PresetFactory<TAPILubricant>(
    'lubricantTypes',
    'Lubricant Type',
    selected => selected?.lubricantName ?? '',
    (option1, option2) => option1?.lubeTypeID === option2?.lubeTypeID,
    'Fluid used by a machine. NOTE: Various manufacturers may produce a similarly or identically named fluid.'
  )

  const SmartPlantFilterPresets = PresetFactory<TAPIPlant>(
    'plants',
    'Plant',
    selected => selected?.plantName ?? '',
    (option1, option2) => option1?.plantID === option2?.plantID,
    'A facility that contains the sampled machine.'
  )

  const SmartMachineConditionFilterPresets = PresetFactory<TCondition>(
    'machineConditions',
    'Machine Conditions',
    selected => TCondition[selected],
    (option1, option2) => option1 === option2,
    'Overall condition of the machine determined by lubricant contents.'
  )

  const SmartLubricantConditionFilterPresets = PresetFactory<TCondition>(
    'lubricantConditions',
    'Lubricant Conditions',
    selected => TCondition[selected],
    (option1, option2) => option1 === option2,
    'Overall condition of the lubricant determined during testing.'
  )

  const SmartCustomerEquipmentIDFilterPresets = PresetFactory<string>(
    'customerEquipmentIDs',
    'Equipment ID',
    selected => selected,
    (option1, option2) => option1 === option2,
    'An alternative name used to identify equipment.'
  )

  const SmartReportTypeFilterPresets = PresetFactory<TAPIPicklistItem>(
    'reportTypes',
    'Report Types',
    selected => selected?.listDesc ?? '',
    (option1, option2) => option1?.listValue === option2?.listValue,
    'The type of report generated based upon the type of sample analyzed.'
  )

  const SmartMachineNameFilterPresets = PresetFactory<string>(
    'machineNames',
    'Machine Names',
    selected => selected ?? '',
    (option1, option2) => option1 === option2,
    'An identifiable name associated with a machine or a sample point.'
  )

  const SmartRouteFilterPresets = PresetFactory<TAPIRoute>(
    'routes',
    'Routes',
    selected => `${selected.routeNo} - ${selected.name}` ?? '',
    (option1, option2) => option1?.routeID === option2?.routeID,
    'A named grouping of machines or sample points.'
  )

  const SmartMachineTypeFilterPresets = PresetFactory<TAPIPicklistItem>(
    'machinePicklistTypes',
    'Machine Type',
    selected => selected?.listDesc ?? '',
    (option1, option2) => option1.listValue === option2.listValue,
    'The manufacturer-designated classification of a machine.',
    undefined,
    undefined,
    ({ listValue, listDesc }) => `${listValue} ${listDesc}`
  )

  const SmartSampleFilterPresets = PresetFactory<TAPISample>(
    'samples',
    'Sample',
    selected => selected.labID.toString() ?? '',
    (option1, option2) => option1.labID === option2.labID,
    'A lubricant sample from a machine, referenced by ID.'
  )

  const SmartLubricantManufacturerFilterPresets =
    PresetFactory<TAPIPicklistItem>(
      'lubricantManufacturers',
      'Fluid Manufacturer',
      selected => selected.listDesc ?? '',
      (option1, option2) => option1.listValue === option2.listValue,
      'A lubricant manufacturer.'
    )

  const SmartMachineManufacturerFilterPresets = PresetFactory<TAPIPicklistItem>(
    'machineManufacturers',
    'Machine Manufacturer',
    selected => selected.listDesc ?? '',
    (option1, option2) => option1.listValue === option2.listValue,
    'A machine manufacturer.'
  )

  const SmartMachineModelFilterPresets = PresetFactory<string>(
    'machineModels',
    'Machine Models',
    selected => selected ?? '',
    (option1, option2) => option1 === option2,
    'A model name associated with a machine.'
  )

  const SmartMachineNumberFilterPresets = PresetFactory<number>(
    'machineNumbers',
    'Machine Numbers',
    selected => selected.toString() ?? '',
    (option1, option2) => option1 === option2,
    'A model number associated with a machine.'
  )

  const SmartTestPackageFilterPresets = PresetFactory<TAPITestGroup>(
    'testPackages',
    'Test Packages',
    selected => selected.testGroupName ?? '',
    (option1, option2) => option1.testGroupID === option2.testGroupID,
    'A package of tests associated with a machine.'
  )

  const allFilters = {
    SmartDivisionFilter: (props: Partial<TSelectProps>) =>
      ConfiguredFilter<TAPIProfileDivision>(SmartDivisionFilterPresets, props),

    SmartCustomerFilter: (props: Partial<TSelectProps>) =>
      ConfiguredFilter<TAPICustomer>(SmartCustomerFilterPresets, props),

    SmartCustomerEquipmentIDFilter: (props: Partial<TSelectProps>) =>
      ConfiguredFilter<string>(SmartCustomerEquipmentIDFilterPresets, props),

    SmartLubricantFilter: (props: Partial<TSelectProps>) =>
      ConfiguredFilter<TAPILubricant>(SmartLubricantFilterPresets, props),

    SmartMachineFilter: (props: Partial<TSelectProps>) =>
      ConfiguredFilter<TAPIMachine>(SmartMachineFilterPresets, props),

    SmartPlantFilter: (props: Partial<TSelectProps>) =>
      ConfiguredFilter<TAPIPlant>(SmartPlantFilterPresets, props),

    SmartMachineConditionFilter: (props: Partial<TSelectProps>) =>
      ConfiguredFilter<TCondition>(SmartMachineConditionFilterPresets, props),

    SmartLubricantConditionFilter: (props: Partial<TSelectProps>) =>
      ConfiguredFilter<TCondition>(SmartLubricantConditionFilterPresets, props),

    SmartLubricantManufacturerFilter: (props: Partial<TSelectProps>) =>
      ConfiguredFilter<TAPIPicklistItem>(
        SmartLubricantManufacturerFilterPresets,
        props
      ),

    SmartReportTypeFilter: (props: Partial<TSelectProps>) =>
      ConfiguredFilter<TAPIPicklistItem>(SmartReportTypeFilterPresets, props),

    SmartSampleFilter: (props: Partial<TSelectProps>) =>
      ConfiguredFilter<TAPISample>(SmartSampleFilterPresets, props),

    SmartRouteFilter: (props: Partial<TSelectProps>) =>
      ConfiguredFilter<TAPIRoute>(SmartRouteFilterPresets, props),

    SmartMachineTypeFilter: (props: Partial<TSelectProps>) =>
      ConfiguredFilter<TAPIPicklistItem>(SmartMachineTypeFilterPresets, props),

    SmartMachineNameFilter: (props: Partial<TSelectProps>) =>
      ConfiguredFilter<string>(SmartMachineNameFilterPresets, props),

    SmartMachineManufacturerFilter: (props: Partial<TSelectProps>) =>
      ConfiguredFilter<TAPIPicklistItem>(
        SmartMachineManufacturerFilterPresets,
        props
      ),
    SmartMachineModelFilter: (props: Partial<TSelectProps>) =>
      ConfiguredFilter<string>(SmartMachineModelFilterPresets, props),

    SmartMachineNumberFilter: (props: Partial<TSelectProps>) =>
      ConfiguredFilter<number>(SmartMachineNumberFilterPresets, props),

    SmartTestPackageFilter: (props: Partial<TSelectProps>) =>
      ConfiguredFilter<TAPITestGroup>(SmartTestPackageFilterPresets, props),
  } as TFilters

  let returnedFilters = {} as TFilters
  if (filters) {
    filters.forEach(type => {
      switch (type) {
        case Filters.CustomerEquipmentID: {
          returnedFilters = {
            ...returnedFilters,
            SmartCustomerEquipmentIDFilter:
              allFilters.SmartCustomerEquipmentIDFilter,
          }
          break
        }
        case Filters.Customers: {
          returnedFilters = {
            ...returnedFilters,
            SmartCustomerFilter: allFilters.SmartCustomerFilter,
          }
          break
        }
        case Filters.Divisions: {
          returnedFilters = {
            ...returnedFilters,
            SmartDivisionFilter: allFilters.SmartDivisionFilter,
          }
          break
        }
        case Filters.LubricantCondition: {
          returnedFilters = {
            ...returnedFilters,
            SmartLubricantConditionFilter:
              allFilters.SmartLubricantConditionFilter,
          }
          break
        }
        case Filters.LubricantManufacturer: {
          returnedFilters = {
            ...returnedFilters,
            SmartLubricantManufacturerFilter:
              allFilters.SmartLubricantManufacturerFilter,
          }
          break
        }
        case Filters.LubricantType: {
          returnedFilters = {
            ...returnedFilters,
            SmartLubricantFilter: allFilters.SmartLubricantFilter,
          }
          break
        }
        case Filters.Machine: {
          returnedFilters = {
            ...returnedFilters,
            SmartMachineFilter: allFilters.SmartMachineFilter,
          }
          break
        }
        case Filters.MachineCondition: {
          returnedFilters = {
            ...returnedFilters,
            SmartMachineConditionFilter: allFilters.SmartMachineConditionFilter,
          }
          break
        }
        case Filters.MachineManufacturer: {
          returnedFilters = {
            ...returnedFilters,
            SmartMachineManufacturerFilter:
              allFilters.SmartMachineManufacturerFilter,
          }
          break
        }
        case Filters.MachineModel: {
          returnedFilters = {
            ...returnedFilters,
            SmartMachineModelFilter: allFilters.SmartMachineModelFilter,
          }
          break
        }
        case Filters.MachineName: {
          returnedFilters = {
            ...returnedFilters,
            SmartMachineNameFilter: allFilters.SmartMachineNameFilter,
          }
          break
        }
        case Filters.MachineNumber: {
          returnedFilters = {
            ...returnedFilters,
            SmartMachineNumberFilter: allFilters.SmartMachineNumberFilter,
          }
          break
        }
        case Filters.MachineType: {
          returnedFilters = {
            ...returnedFilters,
            SmartMachineTypeFilter: allFilters.SmartMachineTypeFilter,
          }
          break
        }
        case Filters.Plant: {
          returnedFilters = {
            ...returnedFilters,
            SmartPlantFilter: allFilters.SmartPlantFilter,
          }
          break
        }
        case Filters.ReportType: {
          returnedFilters = {
            ...returnedFilters,
            SmartReportTypeFilter: allFilters.SmartReportTypeFilter,
          }
          break
        }
        case Filters.Route: {
          returnedFilters = {
            ...returnedFilters,
            SmartRouteFilter: allFilters.SmartRouteFilter,
          }
          break
        }
        case Filters.Sample: {
          returnedFilters = {
            ...returnedFilters,
            SmartSampleFilter: allFilters.SmartSampleFilter,
          }
          break
        }
        case Filters.TestPackage: {
          returnedFilters = {
            ...returnedFilters,
            SmartTestPackageFilter: allFilters.SmartTestPackageFilter,
          }
          break
        }
      }
    })
  } else {
    returnedFilters = allFilters
  }

  return {
    filterState: state,
    filterOptions: options,
    reset: handleReset,
    filters: returnedFilters,
  }
}

export default useSmartFilters
