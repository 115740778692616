import React, { useRef, useState } from 'react'
import { Fade, makeStyles, Popper } from '@material-ui/core'

import coreTheme from 'components/theme'
import {
  colorFromCondition,
  Condition,
  conditionNameFromCondition,
} from 'services/lookup-service'
import { DistinctSort } from 'utils/ArrayMethods'

type TCond = {
  condition: Condition
  noMargin?: boolean
  issueContributors?: string[]
}

export default function ConditionElement({
  condition,
  noMargin,
  issueContributors,
}: TCond) {
  const hasIssues =
    issueContributors &&
    issueContributors.length > 0 &&
    condition !== Condition.Normal &&
    condition !== Condition.Unknown

  issueContributors = DistinctSort(issueContributors)

  const useStyles = makeStyles(theme => ({
    popper: {
      border: 'thin solid rgb(100, 100, 100)',
      borderRadius: '5px',
      backgroundColor: theme.palette.background.paper,
      pointerEvents: 'none',
      padding: hasIssues ? '10px 15px 0px 15px' : '5px 15px 5px 15px',
    },
    condition: {
      height: '25px',
      width: '25px',
      borderRadius: '50%',
      backgroundColor: colorFromCondition(condition),
      margin: noMargin ? '0px' : 'auto',
    },
    title: {
      fontSize: '16px',
      fontWeight: 600,
      width: '100%',
      textAlign: 'center',
      margin: hasIssues ? '0 0 10px 0' : '0',
    },
    subtitle: {
      fontSize: '14px',
      fontWeight: 600,
      margin: '0 0 0 0',
    },
    bullet: {
      fontSize: '14px',
      margin: '0 0 0 16px',
    },
  }))

  const styles = useStyles(coreTheme)

  const title: string = conditionNameFromCondition(condition)

  const anchorRef = useRef<HTMLDivElement>(null)

  const id = 'condition-popper'

  const [show, setShow] = useState(false)

  const onHover = () => {
    setShow(true)
  }

  return (
    <>
      <Popper
        id={id}
        open={show}
        anchorEl={anchorRef.current}
        placement={'top'}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div className={styles.popper}>
              <p className={styles.title}>{title}</p>
              {hasIssues && (
                <>
                  <p className={styles.subtitle}>Contributing Issues</p>
                  <ul>
                    {issueContributors.map(issue => (
                      <li className={styles.bullet}>{issue}</li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </Fade>
        )}
      </Popper>
      <div
        ref={anchorRef}
        onMouseEnter={onHover}
        onMouseLeave={() => setShow(false)}
        className={styles.condition}
      />
    </>
  )
}
