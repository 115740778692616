import React from 'react'
import { Link } from 'react-router-dom'

const LandingLink = props => {
  return (
    <Link {...props} style={{ textDecoration: 'none', color: '#ffffff' }} />
  )
}

export default LandingLink
