import React, { createContext, useContext, useEffect, useState } from 'react'
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from '@microsoft/signalr'
import { ConnectionStatus } from 'types/custom.d'

type ExposedConnection = Omit<
  HubConnection,
  'keepAliveIntervalInMilliseconds' | 'serverTimeoutInMilliseconds' | 'stop'
>

type TPrimaryHubcontext = {
  status: ConnectionStatus
  connection: ExposedConnection | null
}

export const PrimaryHubContext = createContext<TPrimaryHubcontext>(
  {} as TPrimaryHubcontext
)

export const usePrimaryHubContext = () => {
  return useContext(PrimaryHubContext)
}

export const PrimaryHubContextProvider: React.FC = props => {
  const [connection, setConnection] = useState<null | HubConnection>(null)
  const [connected, setConnected] = useState(false)
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(
        `${process.env.REACT_APP_DS_EXPERIENCE_API_URL}${process.env.REACT_APP_DS_EXPERIENCE_API_PRIMARY_HUB_ENDPOINT}`
      )
      .build()

    setConnection(connect)
  }, [])

  const retryDelayMS = 5000

  const reconnectSocket = async () => {
    if (connection.state != HubConnectionState.Connected) {
      try {
        await connection.start()
        setConnected(true)
      } catch (err) {
        setConnected(false)
        setTimeout(reconnectSocket, retryDelayMS)
      }
    }
  }

  const manageConnection = async (connection: HubConnection) => {
    if (!connection) return

    try {
      await connection.start()
      setConnected(true)
    } catch (ex) {
      setConnected(false)
      reconnectSocket()
    }

    connection.onreconnected(id => {
      setConnected(true)
    })
    connection.onclose(err => {
      //NOTE: do not set connected to false here, as it may close for many reasons. Only set to false if it fails the reconnect attempt.
      if (err) if (err) setTimeout(reconnectSocket, retryDelayMS)
    })

    setInitialized(true)
  }

  const getConnectionStatus = () => {
    return !initialized
      ? ConnectionStatus.pending
      : connected
      ? ConnectionStatus.connected
      : ConnectionStatus.disconnected
  }

  useEffect(() => {
    manageConnection(connection)
  }, [connection])

  return (
    <PrimaryHubContext.Provider
      value={{
        status: getConnectionStatus(),
        connection,
      }}
    >
      {props.children}
    </PrimaryHubContext.Provider>
  )
}
