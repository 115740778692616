import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'

const useStyles = makeStyles(_theme => ({
  tooltip: {
    maxWidth: '500px',
    backgroundColor: 'rgba(100, 100, 100, 0.85)',
  },
}))

export default (props: React.ComponentProps<typeof Tooltip>) => {
  const styles = useStyles()
  const [showTooltip, setShowTooltip] = useState(false)
  const [timeouts] = useState<NodeJS.Timeout[]>([])

  const handleTimeout = useCallback(() => {
    timeouts.forEach(t => clearTimeout(t))
    setShowTooltip(false)
  }, [timeouts])

  const handleMouseEnter = () => {
    timeouts.push(
      setTimeout(() => {
        setShowTooltip(true)
        timeouts.push(
          setTimeout(() => {
            handleTimeout()
          }, 10000)
        )
      }, 1500)
    )
  }

  const handleMouseMove = () => {
    if (showTooltip) {
      handleTimeout()
    }
  }

  const handleMouseLeave = () => {
    handleTimeout()
  }

  const handleClick = () => {
    handleTimeout()
  }

  useEffect(() => {
    return handleTimeout
  }, [handleTimeout])

  return (
    <Tooltip
      classes={{ tooltip: styles.tooltip }}
      placement="top-start"
      open={showTooltip}
      title={
        <span
          style={{
            fontSize: '0.825rem',
          }}
        >
          {props.title}
        </span>
      }
      disableFocusListener={true}
    >
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        onScroll={handleMouseLeave}
        onClick={handleClick}
      >
        {props.children}
      </div>
    </Tooltip>
  )
}
