import React, { ReactNode, useState } from 'react'
import { Capacitor } from '@capacitor/core'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { handleDownloadFile } from 'utils/file-utilities'

import APIService from '../services/api-service'
import CapacitorFileService from '../services/capacitor-file-service'

type Props = {
  custID: string | number
  folder: string
  fileName: string
  children?: ReactNode
  download?: boolean
}
const FileLink: React.FC<Props> = ({
  custID,
  folder,
  fileName,
  children,
  download,
  ...props
}: Props) => {
  const [error, setError] = useState(null)

  const handleClick = async event => {
    event.preventDefault()
    event.stopPropagation()

    try {
      const { buffer, mimeType } = await APIService.getFileAndMimeType(
        custID,
        fileName,
        folder
      )
      if (buffer.byteLength < 1600 && mimeType !== 'application/pdf') {
        throw new Error()
      }

      if (Capacitor.isNative) {
        CapacitorFileService.saveAndOpen(fileName, mimeType, buffer)
      } else {
        handleDownloadFile({ buffer, fileName, download: !!download })
      }
      event.stopPropagation()
    } catch (err) {
      setError(
        'Report Currently Unavailable. We may still be processing this report. Contact our Customer Service Team for more information'
      )
      return
    }
  }

  return (
    <>
      <button
        type="button"
        className="link"
        onClick={handleClick}
        {...props}
        style={{
          border: '0px',
          padding: '0px',
          margin: '0px',
          display: 'inline',
          cursor: 'pointer',
          backgroundColor: 'rgb(255, 255, 255, 0)',

          ...props.style,
        }}
      >
        {children}
      </button>
      <Snackbar open={error !== null} onClose={() => setError(null)}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </>
  )
}

export default FileLink
