import React from 'react'
import { Button } from '@material-ui/core'
import { SwapVert } from '@material-ui/icons'

interface OwnProps {
  id?: string
  onDismissAllSorting: (
    _event: React.MouseEventHandler<HTMLButtonElement>
  ) => void
}

const CleanSortingButton: React.FC<OwnProps> = ({
  id,
  onDismissAllSorting,
}: OwnProps) => (
  <Button id={id} onClick={onDismissAllSorting}>
    Clear Sorting <SwapVert />
  </Button>
)

export default CleanSortingButton
