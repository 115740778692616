import MaterialSizes from './MaterialSizes'

class MaterialScreen {
  static GetMaterialBreakpoint(width: number): MaterialSizes {
    if (width < 600) {
      return MaterialSizes.xs
    } else if (width < 960) {
      return MaterialSizes.sm
    } else if (width < 1280) {
      return MaterialSizes.md
    } else if (width < 1920) {
      return MaterialSizes.lg
    } else {
      return MaterialSizes.xl
    }
  }

  static GetMachineCardCount(screenSize: MaterialSizes): number {
    switch (screenSize) {
      case MaterialSizes.xs: {
        return 12
      }
      case MaterialSizes.sm: {
        return 6
      }
      case MaterialSizes.md: {
        return 4
      }
      default: {
        return 3
      }
    }
  }
}

export default MaterialScreen
