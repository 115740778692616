import { ScoreCard } from 'components/ScoreCard'
import React from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import { OrderType, TSpendingEntity } from 'types/api.d'
import { useThemeContext } from 'Contexts/ThemeContext'
import { StringFormatter } from 'utils/string-utilities'

import * as Styles from '../index.module.css'
import {
  getMonthsSpending,
  getRepresentativeOrderType,
  getThisMonthsSpending,
} from 'utils/financial-utilities'

type TAvailableBudgetCardProps = {
  spendingData: TSpendingEntity[]
}

export const EstimatedSpendCard: React.FC<TAvailableBudgetCardProps> =
  props => {
    const theme = useThemeContext()

    const representiativeOrderType = getRepresentativeOrderType(
      props.spendingData
    )

    const dollarBalanced = representiativeOrderType === OrderType.DollarBalance
    const sampleBalanced = representiativeOrderType === OrderType.Sample

    const now = new Date()
    const beginningOfMonth = new Date(now.getFullYear(), now.getMonth(), 1)
    const beginningOfNextMonth = new Date(now.getFullYear(), now.getMonth(), 1)
    beginningOfNextMonth.setMonth(beginningOfNextMonth.getMonth() + 1)

    const lastMonth = new Date(now.getFullYear(), now.getMonth(), 1)
    lastMonth.setMonth(lastMonth.getMonth() - 1)

    let projectedSpending = getThisMonthsSpending(props.spendingData, true)
    let amountSpentSoFar = getThisMonthsSpending(props.spendingData)
    let spendingLastMonth = getMonthsSpending(props.spendingData, lastMonth)

    const estimatedTotalSpend =
      projectedSpending > amountSpentSoFar
        ? projectedSpending
        : amountSpentSoFar
    let estimatedRemainingSpend = estimatedTotalSpend - amountSpentSoFar

    const changeSinceLastMonth = estimatedTotalSpend - spendingLastMonth

    const getChangeIcon = (change: number) => {
      return change > 0 ? (
        <ArrowUpwardIcon style={{ color: theme.palette.charts.withinBudget }} />
      ) : change < 0 ? (
        <ArrowDownwardIcon style={{ color: theme.palette.charts.overBudget }} />
      ) : (
        <DragHandleIcon style={{ color: theme.palette.charts.budget }} />
      )
    }

    const dateString = `${now.getMonth() + 1}/${now.getFullYear()}`

    const title = `Estimated Spending for ${dateString}`

    return (
      <ScoreCard
        toolTip={{
          title: title,
          text: `An estimation of the total spending of the selected business units for the current month: ${dateString}. Spending is estimated based upon machine schedules established with TestOil.`,
        }}
      >
        <div className={Styles.Header}>
          <h1>
            <strong>
              {dollarBalanced
                ? StringFormatter.formatAsUSD(estimatedTotalSpend)
                : sampleBalanced
                ? `${Math.round(estimatedTotalSpend ?? 0).toFixed(0)} Samples`
                : 'N/A'}
            </strong>
          </h1>
          <h6>
            <strong>{title}</strong>
          </h6>
        </div>
        <div style={{ width: '100%' }}>
          <div className={Styles.DifferenceContainer}>
            <span>{getChangeIcon(0)} Amount spent so far:</span>
            <span>
              {dollarBalanced
                ? StringFormatter.formatAsUSD(amountSpentSoFar ?? 0)
                : sampleBalanced
                ? `${Math.round(amountSpentSoFar).toFixed(0)} Samples`
                : 'N/A'}
            </span>
          </div>
          <div className={Styles.DifferenceContainer}>
            <span>
              {getChangeIcon(estimatedRemainingSpend ?? 0)} Estimated remaining
              spending:
            </span>
            <span>
              {dollarBalanced
                ? StringFormatter.formatAsUSD(estimatedRemainingSpend ?? 0)
                : sampleBalanced
                ? `${Math.round(estimatedRemainingSpend ?? 0).toFixed(
                    0
                  )} Samples`
                : 'N/A'}
            </span>
          </div>
          <div className={Styles.DifferenceContainer}>
            <span>
              {getChangeIcon(changeSinceLastMonth ?? 0)} Change since last
              month:
            </span>
            <span>
              {dollarBalanced
                ? StringFormatter.formatAsUSD(changeSinceLastMonth ?? 0)
                : sampleBalanced
                ? `${Math.round(changeSinceLastMonth ?? 0).toFixed(
                    0
                  )} Samples` ?? 0
                : 'N/A'}
            </span>
          </div>
        </div>
      </ScoreCard>
    )
  }
