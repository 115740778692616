import { usePrimaryHubContext } from 'Contexts/PrimaryHubContext'
import React, { useState } from 'react'
import { ConnectionStatus } from 'types/custom.d'
import Styles from './index.module.css'

export const ConnectionStatusMonitor: React.FC = props => {
  const primaryHubContext = usePrimaryHubContext()

  const statusText =
    primaryHubContext.status === ConnectionStatus.connected
      ? 'Connected'
      : primaryHubContext.status === ConnectionStatus.disconnected
      ? 'Disconnected'
      : 'Pending'

  const color =
    primaryHubContext.status === ConnectionStatus.connected
      ? 'green'
      : primaryHubContext.status === ConnectionStatus.disconnected
      ? 'red'
      : 'yellow'

  return (
    <div id={Styles.MonitorContainer}>
      <svg height="40" width="40">
        <circle
          cx="18"
          cy="19"
          r="10"
          stroke="black"
          stroke-width="1"
          fill={color}
        />
      </svg>
      <span>Status: {statusText}</span>
    </div>
  )
}
