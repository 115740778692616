export enum SalesInvoiceStatus {
  Paid = 'Paid',
  UnPaid = 'Unpaid',
  PartialPayment = 'Partial Payment',
  PastDue = 'Past Due',
}

export const getSalesInvoiceStatusDescription = (
  statusType: SalesInvoiceStatus
) => SalesInvoiceStatus[statusType]
