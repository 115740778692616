import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  createTheme,
  Grid,
  ThemeProvider,
  Typography,
} from '@material-ui/core'
import { SortDescriptor } from '@progress/kendo-data-query'
import { GridSortChangeEvent } from '@progress/kendo-react-grid'
import AppLayout from 'components/AppLayout'
import { ExportButton, ThemeButton } from 'components/buttons'
import Loader from 'components/Loader'
import ReadOnlyDialog from 'components/Modals/ReadOnlyDialog'
import OfflineBanner from 'components/offline-banner'
import SEO from 'components/SEO'
import {
  ManageEquipmentTable,
  tableFormatter,
} from 'components/tables/ManageEquipment'
import APIService from 'services/api-service'
import { exportData } from 'services/export-service'
import { getSelectedCustomerIDs } from 'services/selection-service'
import coreTheme from '../../../components/theme'
import { useProfile } from 'Contexts/ProfileContext'
import FilterAccordion, {
  TAccordionFilterValues,
} from 'components/FilterAccordion'
import { mapToPageParameters } from 'components/FilterAccordion/Utilities'
import {
  LubricantFilterSet,
  MachineFilterSet,
  OptionsFilterSet,
  OrganizationalFilterSet,
} from 'components/FilterAccordion/types'
import { CancellablePromise } from 'utils/CancellablePromise'

const savedPageState = new Map<string, any>()

const defaults = {
  page: 1,
  pageSize: 10,
  orderConfig: [{ field: 'machineName', dir: 'asc' }] as SortDescriptor[],
  selectedDivisionIDs: [],
  selectedCustomerIDs: [],
  selectedPlants: [],
  selectedRoutes: [],
  selectedCustEquIDs: [],
  selectedMachineNames: [],
  selectedMachineTypes: [],
  selectedMachineNos: [],
  selectedMachineMfgs: [],
  selectedMachineModels: [],
  selectedMachineNumbers: [],
  selectedLubeTypes: [],
}
let isInitialLoad = true
interface OwnProps {
  onError?: (_err: Error) => void // passed in from layout wrapper
  offline?: boolean
}

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

const Equipment: React.FC<OwnProps> = ({ onError, offline }: OwnProps) => {
  const navigate = useNavigate()

  const [state, setState] = useState({
    ...defaults,
    machines: [],
    loaded: false,
    filterUpdated: false,
    count: 0,
    filterExpanded: false,
    searching: false as boolean,
    loadedDefaults: false,
  })

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  useEffect(() => {
    return () => {
      isInitialLoad = true
    }
  }, [])

  const profileContext = useProfile()

  useEffect(() => {
    if (profileContext.dependentData.filters.minimumFilterOptionsLoaded) {
      setState(prev => ({
        ...prev,
        selectedCustomerIDs:
          profileContext.dependentData.filters.initialFilterState.customers.map(
            c => c.custID
          ),
        selectedDivisionIDs:
          profileContext.dependentData.filters.initialFilterState.divisions.map(
            d => d.iD
          ),
        loadedDefaults: true,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext.dependentData.filters.minimumFilterOptionsLoaded])

  useEffect(() => {
    if (!state.loaded) {
      const res = savedPageState.get('equipment-page')
      if (res !== null && res !== undefined) {
        savedPageState.set('equipment-page', null)
        setState(prev => ({
          ...prev,
          ...res,
          filterUpdated: true,
          loaded: false,
        }))
      } else {
        setState(prev => ({
          ...prev,
          filterUpdated: true,
        }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loaded])

  useEffect(() => {
    if (state.searching) {
      setState(prev => ({ ...prev, loaded: false }))
      performSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searching])

  useEffect(() => {
    if (!state.loaded && !state.searching && state.loadedDefaults) {
      performSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filterUpdated, state.loaded, state.loadedDefaults])

  const fetchSamples = useCallback(
    async variables => {
      setState(prev => ({ ...prev, loaded: false }))
      let promise
      try {
        promise = APIService.getMachines(variables)

        ongoingCancellablePromises.push(promise)
        const data = await promise

        if (data.pagedMachines !== null) {
          onError(null)
          setState(prev => ({
            ...prev,
            machines: data.pagedMachines.items,
            count: data.pagedMachines.count,
          }))
        }
      } catch (ex) {
        onError(ex)
      } finally {
        ongoingCancellablePromises.filter(p => p != promise)
        setState(prev => ({ ...prev, loaded: true, searching: false }))
      }
    },
    [onError]
  )

  const performSearch = useCallback(async () => {
    await fetchSamples({
      page: state.page,
      pageSize: state.pageSize,
      orderList: state.orderConfig?.map(x => x.dir) ?? [],
      orderByList: state.orderConfig?.map(x => x.field) ?? [],
      customerIDs: getSelectedCustomerIDs(
        profileContext.profile.customers,
        profileContext.profile.divisions,
        state.selectedDivisionIDs,
        state.selectedCustomerIDs
      ),
      plantIDs: state.selectedPlants,
      machineNames: state.selectedMachineNames,
      custEquIDs: state.selectedCustEquIDs,
      routeIDs: state.selectedRoutes,
      machineNumbers: state.selectedMachineNumbers,
      machineTypes: state.selectedMachineTypes,
      lubeTypeIDs: state.selectedLubeTypes,
      machineMfgs: state.selectedMachineMfgs,
      machineModels: state.selectedMachineModels,
    })
  }, [
    fetchSamples,
    state.page,
    state.pageSize,
    state.orderConfig,
    state.selectedDivisionIDs,
    state.selectedCustomerIDs,
    state.selectedPlants,
    state.selectedMachineNames,
    state.selectedCustEquIDs,
    state.selectedRoutes,
    state.selectedMachineNumbers,
    state.selectedMachineTypes,
    state.selectedLubeTypes,
    state.selectedMachineMfgs,
    state.selectedMachineModels,
  ])

  const handleResetClick = () => {
    setState(prev => ({
      ...prev,
      ...defaults,
      selectedCustomerIDs:
        profileContext.dependentData.filters.initialFilterState.customers.map(
          c => c.custID
        ),
      selectedDivisionIDs:
        profileContext.dependentData.filters.initialFilterState.divisions.map(
          d => d.iD
        ),
      count: 0,
      loaded: false,
    }))
  }

  const handleExportFormatChange = async (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    format
  ) => {
    let data = null
    let promise
    try {
      promise = APIService.getMachines({
        page: 1,
        pageSize: 100000,
        orderList: state.orderConfig?.map(x => x.dir) ?? [],
        orderByList: state.orderConfig?.map(x => x.field) ?? [],
        customerIDs: getSelectedCustomerIDs(
          profileContext.profile.customers,
          profileContext.profile.divisions,
          state.selectedDivisionIDs,
          state.selectedCustomerIDs
        ),
        plantIDs: state.selectedPlants,
        routeIDs: state.selectedRoutes,
        machineNumbers: state.selectedMachineNos,
        machineNames: state.selectedMachineNames,
        custEquIDs: state.selectedCustEquIDs,
        machineTypes: state.selectedMachineTypes,
        lubeTypeIDs: state.selectedLubeTypes,
        machineMfgs: state.selectedMachineMfgs,
        machineModels: state.selectedMachineModels,
      })

      ongoingCancellablePromises.push(promise)
      data = await promise
    } catch (ex) {
      onError(ex)
      return
    } finally {
      ongoingCancellablePromises.filter(p => p != promise)
    }

    const formatted = tableFormatter(
      data.pagedMachines.items,
      profileContext.profile.machineTypeOptions,
      profileContext.profile.machineMfgOptions,
      profileContext.dependentData.userDetails.isDivisionUser
    )

    exportData('equipment', formatted, format.key)
  }

  const handlePageChange = event => {
    setState(prev => ({
      ...prev,
      page: event.page.skip / state.pageSize + 1,
      pageSize: event.page.take,
      loaded: false,
    }))
  }

  const handleSortChange = (event: GridSortChangeEvent) => {
    const isFieldRemoved = state.orderConfig.length > event.sort.length
    let isLastFieldRemoved = false

    if (isFieldRemoved) {
      const fieldRemoved = state.orderConfig.filter(
        x => !event.sort.includes(x)
      )[0]

      isLastFieldRemoved =
        state.orderConfig.indexOf(fieldRemoved) === state.orderConfig.length - 1
    }

    setState(prev => ({
      ...prev,
      orderConfig: [...event.sort],
      loaded: !(event.sort.length > 0 && !isLastFieldRemoved),
    }))
  }

  const handlePageChangeMobile = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    newPage
  ) => {
    setState(prev => ({ ...prev, page: newPage + 1, loaded: false }))
  }

  const handlePageSizeChangeMobile = event => {
    setState(prev => ({
      ...prev,
      page: 1,
      pageSize: parseInt(event.target.value, 10),
      loaded: false,
    }))
  }

  const handleAddNewEquipmentClick = _event => {
    savedPageState.set('equipment-page', state)
    navigate('/program-management/equipment/new')
  }

  const theme = createTheme(
    {
      overrides: {
        MuiGrid: {
          root: {
            justifyContent: 'flex-end',
            [coreTheme.breakpoints.down('sm')]: {
              justifyContent: 'space-between',
            },
          },
        },
        MuiButton: {
          root: {
            [coreTheme.breakpoints.down('sm')]: {
              width: '175px',
            },
            [coreTheme.breakpoints.up('sm')]: {
              width: '225px',
            },
          },
        },
      },
    },
    coreTheme
  )

  const [showReadOnlyDialog, setShowReadOnlyDialog] = useState(false)

  const handleEditEquipmentClick = async pointID => {
    if (profileContext.profile.user.readOnly) {
      setShowReadOnlyDialog(true)
    } else {
      savedPageState.set('equipment-page', state)
      navigate(`/program-management/equipment/manage?pointID=${pointID}`)
    }
  }

  const handleFilterSubmission = (data: TAccordionFilterValues) => {
    const mappedParameters = mapToPageParameters(data)

    setState(prev => ({
      ...prev,
      ...mappedParameters,
      page: 1,
      searching: true,
      loaded: false,
    }))
  }

  return (
    <>
      <React.Fragment>
        <SEO title="Manage Equipment" />
        <ReadOnlyDialog
          open={showReadOnlyDialog}
          onClose={() => setShowReadOnlyDialog(false)}
        />
        <Box mb={2}>
          <Typography variant="h1">Manage Equipment</Typography>
        </Box>
        {offline ? <OfflineBanner /> : <></>}

        <FilterAccordion
          pageName="Manage Equipment Database"
          defaultValues={
            profileContext.dependentData.filters.initialFilterState
          }
          onSubmit={handleFilterSubmission}
          onReset={handleResetClick}
          filters={{
            options: [OptionsFilterSet.Prefiltering],
            date: [],
            organization: [OrganizationalFilterSet.All],
            machine: [
              MachineFilterSet.Name,
              MachineFilterSet.EquipmentIDs,
              MachineFilterSet.Number,
              MachineFilterSet.Type,
              MachineFilterSet.Manufacturers,
              MachineFilterSet.Models,
            ],
            lubricant: [LubricantFilterSet.Type],
            miscellaneous: [],
          }}
        />
        <form>
          <ThemeProvider theme={theme}>
            <Grid
              container
              direction="column"
              xs={12}
              spacing={1}
              justify="flex-end"
              style={{ marginTop: '15px' }}
            >
              <Grid
                container
                item
                direction="row"
                alignItems="flex-start"
                justify="flex-end"
                spacing={2}
              >
                <Grid item>
                  <ThemeButton
                    variant="contained"
                    color="primary"
                    onClick={handleAddNewEquipmentClick}
                  >
                    Request New Equipment
                  </ThemeButton>
                </Grid>
              </Grid>
              <Grid
                container
                item
                direction="row"
                spacing={1}
                justify="flex-end"
              >
                <Grid item>
                  <ExportButton onChange={handleExportFormatChange} disabled={state.machines.length < 1} />
                </Grid>
              </Grid>
            </Grid>
          </ThemeProvider>
        </form>

        <Box mt={4}>
          {!state.loaded ? (
            <Loader />
          ) : (
            <React.Fragment>
              <ManageEquipmentTable
                machines={state.machines}
                page={state.page}
                pageSize={state.pageSize}
                count={state.count}
                orderConfig={state.orderConfig}
                onSortChange={handleSortChange}
                onPageChange={handlePageChange}
                onPageChangeMobile={handlePageChangeMobile}
                onPageSizeChangeMobile={handlePageSizeChangeMobile}
                onMachineEditClick={handleEditEquipmentClick}
              />
            </React.Fragment>
          )}
        </Box>
      </React.Fragment>
    </>
  )
}

export default function Page() {
  return (
    <AppLayout tab="program-management">
      <Equipment />
    </AppLayout>
  )
}
