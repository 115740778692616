import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  BooleanParam,
  DelimitedNumericArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from 'use-query-params'

import ReportViewer from './ReportViewer/ReportViewer'
import {
  availableReports,
  TReportParametersAll,
  TReportSource,
  TTestoilReportAssemblies,
} from './ReportViewer/TelerikReportViewer'

const compactParamMap: {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [key in keyof TReportParametersAll | 'debug' | 'report']: string
} = {
  debug: 'dbg',
  report: 'r',
  SortColumn: 'sort',
  SortDirection: 'dir',
  From: 'from',
  To: 'to',
  Customers: 'c',
  Plants: 'p',
  CorporateContactID: 'cc',
  CustomerName: 'cn',
  ReportComments: 'com',
  ReportDate: 'rd',
  DisplayName: 'dn',
  TitleDateString: 'td',
  CategoryIDs: 'cid',
  StartDate: 'sd',
  EndDate: 'ed',
  CustomerID: 'ci',
  FiscalYearStartMonth: 'fys',
  MinimumExceptionCount: 'mec',
  numberOfExceptions: 'me',
  machines: 'm',
  routes: 'ro',
  testPackages: 'tp',
  Routes: 'rs',
  ReportTypes: 'srt',
  MachineConditions: 'mc',
  LubeConditions: 'lc',
  month: 'mn',
  batchID: 'b',
  isAvery: 'ia',
}

const invertedParamMap = Object.entries(compactParamMap).reduce(
  (acc, [k, v]) => {
    acc[v] = k
    return acc
  },
  {}
)

const StripTimeZone = {
  encode(value) {
    return moment(value).format('YYYY-MM-DD')
  },
  decode(value) {
    return moment(value).format('YYYY-MM-DD')
  },
}

export const reportViewerQueryParamConfig = {
  dbg: BooleanParam, // debug
  r: StringParam, // reportName
  sort: StringParam, // sortColumn
  dir: StringParam, // sortDirection
  from: StripTimeZone, // date from
  to: StripTimeZone, // date to
  c: DelimitedNumericArrayParam, // customer IDs
  p: DelimitedNumericArrayParam, // plant IDs
  cc: NumberParam, // CorporateContactID
  cn: StringParam, // customer name
  mn: StringParam, // month name
  com: StringParam, // comments
  rd: StripTimeZone, // report date
  dn: StringParam, // display name
  td: StripTimeZone, // title date
  cid: DelimitedNumericArrayParam, // category ids
  sd: StripTimeZone, // start date
  ed: StripTimeZone, // end date
  ci: NumberParam, // customer ID
  fys: NumberParam, // fiscal year start month
  mec: NumberParam,
  me: NumberParam,
  m: DelimitedNumericArrayParam, // selected equipment IDs
  tp: DelimitedNumericArrayParam, // selected test package IDs
  ro: DelimitedNumericArrayParam, // selected route IDs
  srt: DelimitedNumericArrayParam, // report types
  mc: DelimitedNumericArrayParam, // machine conditions
  lc: DelimitedNumericArrayParam, // lube conditions
  rs: DelimitedNumericArrayParam, // Routes
  b: StringParam, // BatchID
  ia: BooleanParam, // IsAvery
}

export const compactReportParams = (
  params: TReportSource['parameters']
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
): { [k in keyof typeof reportViewerQueryParamConfig]: any } => {
  return Object.entries(params).reduce((acc, [k, v]) => {
    const newKey = compactParamMap[k]
    acc[newKey] = v
    return acc
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  }, {} as { [k in keyof typeof reportViewerQueryParamConfig]: any })
}

/**
 * Parses parameters from the URL query string and passes renders a `<ReportViewer>` with them.
 *
 * To show the constructed parameters object, pass something truthy to the `dbg` param.
 *
 * See where `query` is defined below for the abbreviated parameter names.
 *
 * Build your query strings like:
 * ```ts
 * const q = `/reports/viewer?r=programSnapshot&c=${customers.join('_')}&plants=${plants.join('_')}`
 * ```
 */
export const ReportViewerFromURLParams = (_props) => {
  const [query] = useQueryParams(reportViewerQueryParamConfig)
  const allParams = Object.entries(query).reduce((acc, [k, v]) => {
    const newKey = invertedParamMap[k]
    acc[newKey] = v
    return acc
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  }, {} as { [k in keyof typeof compactParamMap]: any })

  const [error, setError] = useState(false)
  const { report, debug, ...parameters } = allParams

  useEffect(() => {
    try {
      if (!validateReportName(report)) {
        throw new Error(`Unknown report name: ${report}`)
      }

      const errors = validateParameters(parameters)

      if (errors.length > 0) {
        throw new Error(`Parameter validation errors found: ${errors}`)
      }
    } catch (err) {
      setError(true)
    }
  }, [])

  const reportSource = {
    report,
    parameters,
  } as TReportSource
  if (debug) {
    // eslint-disable-next-line no-console
  }
  return (
    <div>
      {debug && <pre>{JSON.stringify(reportSource, null, 2)}</pre>}

      {!error ? (
        <ReportViewer reportSource={reportSource} />
      ) : (
        <h5 style={{ textAlign: 'center' }}>Unable to generate report</h5>
      )}
    </div>
  )
}

const validateReportName = (
  reportName: string
): reportName is TTestoilReportAssemblies => {
  return availableReports.includes(reportName)
}

const validateParameters = (parameters: any): Array<string> => {
  const errors: Array<string> = []

  Object.entries(parameters).forEach(item => {
    const key = item[0]
    const value = item[1]

    if (
      value !== undefined &&
      (value === 'Invalid date' ||
        (isNaN(value as number) && !(value as string)) ||
        (Array.isArray(value) &&
          (value as Array<any>).some(x => isNaN(x as number))))
    ) {
      errors.push(key)
    }
  })

  return errors
}
