import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import SampleRegistration from './SampleRegistration'
import { bottleIDRegex, getSampleRegistrationBaseURL } from '../constants'
import { getNiceBottleIDUrlSearch } from './constants'

function SampleRegistrationRedirectPage() {
  //Parse url on load, then nav with new parameters.
  const navigator = useNavigate()

  useEffect(() => {
    ;(() => {
      if (!navigator) return

      let navigationRoute = getSampleRegistrationBaseURL()
      if (!navigationRoute) return

      let searchString = window.location.search
      let match: RegExpExecArray = null
      let bottleID: string = null
      let encryptedID: string = null

      while ((match = bottleIDRegex.exec(searchString))) {
        bottleID =
          bottleID ?? match.groups.bottleID ?? match.groups.reducedBottleID
        encryptedID =
          encryptedID ??
          match.groups.encryptedID ??
          match.groups.reducedEncryptedID
      }

      navigationRoute += getNiceBottleIDUrlSearch(bottleID, encryptedID)
      navigator(navigationRoute)
    })()
  }, [])
  return null
}

export default SampleRegistrationRedirectPage
