export function IsNullOrEmpty<T>(array: T[]) {
  return array == null || array.length === 0
}
/**Binary search that returns index of element. Returns -1 if not found. */

export function binarySearch<T>(
  array: T[],
  target: T,
  comparator: (a: T, b: T) => number
) {
  let startIndex = 0
  let endIndex = array.length - 1
  while (startIndex <= endIndex) {
    const middleIndex = Math.floor((startIndex + endIndex) / 2)
    if (comparator(target, array[middleIndex]) === 0) {
      return middleIndex
    }
    if (comparator(target, array[middleIndex]) > 0) {
      startIndex = middleIndex + 1
    }
    if (comparator(target, array[middleIndex]) < 0) {
      endIndex = middleIndex - 1
    }
  }

  return -1
}

export function IsSorted<T>(array: T[], comparator: (a: T, b: T) => number) {
  let order = false
  let foundOrder = false

  return array.every((value, index) => {
    if (index > 0) {
      const comparisonResult = comparator(value, array[index - 1])
      const valuesAreEqual = comparisonResult === 0
      const currentOrder = comparisonResult > 0

      if (!foundOrder && !valuesAreEqual) {
        foundOrder = true
        order = currentOrder
      }

      return valuesAreEqual || currentOrder === order
    }
    return true
  })
}

export const isNotSameAsLast = (
  current: any,
  last: any,
  comparator?: (_v1: any, _v2: any) => boolean
) => {
  return comparator ? !comparator(current, last) : current !== last
}

export function DistinctSort<T>(
  array: T[],
  sortComparator?: (_a: T, _b: T) => number,
  itemComparator?: (_a: T, _b: T) => boolean
) {
  if (!array || !Array.isArray(array)) return array

  const sorted = !!sortComparator
    ? [...array].sort(sortComparator)
    : [...array].sort()
  const filtered = sorted.filter(
    (x, index, arr) =>
      index === 0 || isNotSameAsLast(x, arr[index - 1], itemComparator)
  )

  return filtered
}

export class Range {
  constructor(
    public start: number,
    public end: number,
    public step: number = 1
  ) {}

  includes(value: number) {
    return value >= this.start && value < this.end
  }

  *[Symbol.iterator]() {
    for (let i = this.start; i < this.end; i += this.step) {
      yield i
    }
  }
}
